import PropTypes from 'prop-types';

import { ProductTable } from '../../../product/ProductTable/ProductTable';
import { InitialEnquiryPopup } from '../common/components/InitialEnquiryPopup';
import { useInitialEnquiry } from '../common/hooks/useInitialEnquiry';

export const LubesInitialEnquiryPopup = ({ isOpen, onDismiss, offerId }) => {
    const initialEnquiryQuery = useInitialEnquiry(offerId);
    const offer = initialEnquiryQuery.data;

    return (
        <InitialEnquiryPopup
            isLoading={initialEnquiryQuery.isPending}
            offer={offer}
            isOpen={isOpen}
            onDismiss={onDismiss}
        >
            <ProductTable offer={offer} showPrices={false} />
        </InitialEnquiryPopup>
    );
};

LubesInitialEnquiryPopup.propTypes = {
    offerId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    onDismiss: PropTypes.func,
};
