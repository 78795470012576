import { BudgetMessage } from '../../../../types/BudgetMessage';
import { translate } from '../../../common/helpers/translate.helper';
import { LiteOverlay } from '../../../common/LiteOverlay/LiteOverlay';
import { Section } from '../../../common/Section/Section';
import { Box } from '../../../common/ui/Box';

import { BudgetInformation } from './BudgetInformation';

export const LiteVesselBudget = () => {
    const budgetData: BudgetMessage = {
        budget: {
            amount: 2_000,
            currency: 'USD',
        },
        startDate: '2023-01-01T00:00:00.000+0000',
        remainingBudget: {
            amount: 1_000,
            currency: 'USD',
        },
        spentBudget: {
            amount: 1_000,
            currency: 'USD',
        },
    };

    return (
        <Section title={translate('budgets.title')} fullHeight={false} paddingY={0} paddingX={0}>
            <LiteOverlay variant="medium" title={translate('budgets.title')}>
                <Box paddingX={7} paddingY={6}>
                    <BudgetInformation budgetData={budgetData} />
                </Box>
            </LiteOverlay>
        </Section>
    );
};
