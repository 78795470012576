import { Button } from '../../../../cdl/Button/Button';
import { Text } from '../../../../cdl/Text/Text';
import { FormattedNumberInput } from '../../../../common/FormattedNumberInput/FormattedNumberInput';
import { checkPreference } from '../../../../flags';

interface InternalConsumptionConfigProps {
    preferredNumberOfPoints: number;
    resolution: number;
    setPreferredNumberOfPoints: (preferredNumberOfPoints: number) => void;
}

export const InternalConsumptionConfig = (props: InternalConsumptionConfigProps) => {
    if (!checkPreference('PreferredNumberOfPoints')) {
        return null;
    }

    return (
        <div>
            <Text>
                Preferred number of points: {props.preferredNumberOfPoints}, calculated resolution: {props.resolution}
            </Text>

            <form
                onSubmit={(event) => {
                    event.preventDefault();

                    const formData = new FormData(event.target as HTMLFormElement);
                    const preferredNumberOfPoints = formData.get('preferredNumberOfPoints');

                    props.setPreferredNumberOfPoints(Number(preferredNumberOfPoints));
                }}
            >
                <FormattedNumberInput
                    label="Allows you to override preferred number points, defaults to 30"
                    allowNegativeValue={false}
                    allowDecimals={false}
                    name="preferredNumberOfPoints"
                />

                <Button type="submit">Save</Button>
            </form>
        </div>
    );
};
