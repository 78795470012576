import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { LubesOfferDetailPage as OriginalLubesOfferDetailPage } from './LubesOfferDetailPage';

const LubesOfferDetailPage = (props) => {
    return (
        <AppProviders>
            <OriginalLubesOfferDetailPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(LubesOfferDetailPage, 'lubesOfferDetailPage');
