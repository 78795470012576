import { useState } from 'react';

import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { useAngularRouterLocation } from '../../common/hooks/useAngularRouterLocation';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useRole } from '../../common/hooks/useRole';
import { Page } from '../../common/Page/Page';
import { PageGrid } from '../../common/PageGrid';
import { Section } from '../../common/Section/Section';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';
import { Greeting } from '../common/components/Greeting';
import { QuickStats } from '../common/components/QuickStats';
import { WelcomePopup } from '../common/components/WelcomePopup';

import { FuelCustomerOfferTable } from './components/FuelCustomerOfferTable';
import { FuelSupplierRecentActivities } from './components/FuelSupplierRecentActivities';

const TableSection = () => {
    const role = useRole();

    if (role.isAdmin()) {
        return null;
    }

    return (
        <Box>
            {role.isCustomer() ? <FuelCustomerOfferTable /> : null}
            {role.isSupplier() ? <FuelSupplierRecentActivities /> : null}
        </Box>
    );
};

export const FuelDashboard = () => {
    const { showWelcomePopup: welcomePopupFromRouter } = useAngularRouterLocation<{ showWelcomePopup: boolean }>();

    const [showWelcomePopup, setShowWelcomePopup] = useState<boolean>(welcomePopupFromRouter);

    useDocumentTitle(translate('page.dashboardoverview'));

    return (
        <Page>
            <PageGrid>
                <Flex alignItems="center">
                    <Text variant="headline" as="h1" marginRight={4}>
                        {translate('dashboard.fuelHeadline')}
                    </Text>
                    <Greeting />
                </Flex>

                <Section title={translate('dashboard.quickstats.headline')} paddingX={0} paddingY={0}>
                    <QuickStats />
                </Section>

                <TableSection />
            </PageGrid>
            <WelcomePopup isOpen={showWelcomePopup} onDismiss={() => setShowWelcomePopup(false)} />
        </Page>
    );
};
