import { IconButton } from '../../../cdl/IconButton/IconButton';
import { LogoHeader } from '../../../common/headers/LogoHeader';
import { translate } from '../../../common/helpers/translate.helper';
import { useAngularRouterLocation } from '../../../common/hooks/useAngularRouterLocation';
import { useNavigation } from '../../../common/hooks/useNavigation';
import { IconX } from '../../../common/icons/cdl/X';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { createOverlay } from '../../../common/Overlay/Overlay';
import { Box } from '../../../common/ui/Box';
import { OfferState } from '../../model/OfferState';
import { useCreateQuoteDocumentTitle } from '../hooks/useCreateQuoteDocumentTitle';

import { QuoteFormWrapper } from './components/QuoteFormWrapper';
import { useQuotationState } from './hooks/useQuotationState';

export const FuelCreateQuoteFlow = () => {
    return createOverlay(<FuelCreateQuote />);
};

const FuelCreateQuote = () => {
    const { id } = useAngularRouterLocation();
    const { navigate } = useNavigation();
    const quotationState = useQuotationState(id);

    useCreateQuoteDocumentTitle(quotationState.data);

    const handleCloseClick = () => {
        navigate('base.fuel-offerlist-offer', {
            id,
        });
    };

    if (quotationState.isPending) {
        return (
            <Box minHeight="100%" backgroundColor="background.subtle">
                <LogoHeader rightElement={<IconButton icon={IconX} emphasis="medium" onClick={handleCloseClick} />} />
                <LoadingIndicator variant="page" />
            </Box>
        );
    }

    const title =
        quotationState.data?.state === OfferState.QUOTED
            ? translate('offer.quotationProcess.fuel.update')
            : translate('offer.quotationProcess.fuel.headerTitle');

    return (
        <Box paddingBottom={11} minHeight="100%" backgroundColor="background.subtle">
            <LogoHeader
                title={title}
                rightElement={<IconButton icon={IconX} emphasis="medium" onClick={handleCloseClick} />}
            />
            <QuoteFormWrapper data={quotationState.data} />
        </Box>
    );
};
