import { useQuery } from '@tanstack/react-query';

import { getBunkerMetricBunkerCaseSolution } from '../../../common/api/clients/bunkerMetric.api';
import { queryKeys } from '../../../common/api/queryKeys';

interface UseBunkerMetricBunkerCaseParams {
    vesselId: string;
    caseNumber: string;
    solutionNumber: string;
}

export const useBunkerMetricBunkerCaseSolution = (params: UseBunkerMetricBunkerCaseParams) => {
    return useQuery({
        queryKey: queryKeys.bunkerMetricBunkerCaseSolution(params),
        queryFn: () => getBunkerMetricBunkerCaseSolution(params),
    });
};
