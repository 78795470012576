import { translate } from '../../helpers/translate.helper';
import { SidebarNavigationItem } from '../components/SidebarNavigationItem';

export const FuelAdminSidebarItems = () => {
    return (
        <>
            <SidebarNavigationItem to="base.fuel-dashboard">{translate('navigation.dashboard')}</SidebarNavigationItem>

            <SidebarNavigationItem to="base.fuel-orderlist">{translate('navigation.orders')}</SidebarNavigationItem>

            <SidebarNavigationItem to="base.fuel-offerlist">{translate('navigation.offers')}</SidebarNavigationItem>

            <SidebarNavigationItem to="base.fuel-key-ports">{translate('navigation.keyPorts')}</SidebarNavigationItem>

            <SidebarNavigationItem to="base.fuel-planning">{translate('navigation.planning')}</SidebarNavigationItem>

            <SidebarNavigationItem to="base.fuel-contracts">{translate('navigation.contracts')}</SidebarNavigationItem>

            <SidebarNavigationItem to="base.fuel-reporting">{translate('navigation.reporting')}</SidebarNavigationItem>
        </>
    );
};
