import { useToasts } from '../../cdl/Toast/useToasts';
import { DeleteConfirmPopup } from '../../common/DeleteConfirmPopup/DeleteConfirmPopup';
import { translate } from '../../common/helpers/translate.helper';
import { useBunkerStopDeletion } from '../hooks/useBunkerStopDeletion';

interface DeleteBunkerStopPopupProps {
    onDismiss: () => void;
    isOpen: boolean;
    bunkerStopId: string;
}

export const DeleteBunkerStopPopup = (props: DeleteBunkerStopPopupProps) => {
    const bunkerStopDeletion = useBunkerStopDeletion();
    const { addErrorToast } = useToasts();

    const onDeletePopupConfirm = async () => {
        await bunkerStopDeletion.mutateAsync(
            { bunkerStopId: props.bunkerStopId },
            {
                onError: () => {
                    props.onDismiss();
                    addErrorToast(translate('global.genericToastError'));
                },
            }
        );
        props.onDismiss();
    };

    return (
        <DeleteConfirmPopup
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            onSubmit={() => onDeletePopupConfirm()}
            headline={translate('bunkerPlan.table.deletePopup.headline')}
            isSubmitting={bunkerStopDeletion.isPending}
            text={translate('bunkerPlan.table.deletePopup.text')}
            deleteButtonText={translate('bunkerPlan.table.deletePopup.delete')}
        />
    );
};
