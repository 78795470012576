import { BunkerStopMessage, BunkerStopState } from '../../../types/BunkerStopsMessage';
import { DiscoverableButton } from '../../cdl/Button/DiscoverableButton';
import { translate } from '../../common/helpers/translate.helper';
import { useNavigation } from '../../common/hooks/useNavigation';

import { BunkerPlanCreateEnquiryButton } from './BunkerPlanCreateEnquiryButton';

const BunkerPlanViewOrderButton = ({ offerId }: { offerId: string }) => {
    const { navigate } = useNavigation();

    return (
        <DiscoverableButton
            emphasis="high"
            onClick={(event) => {
                event.stopPropagation();
                navigate('base.fuel-offerlist-offer', {
                    id: offerId,
                });
            }}
        >
            {translate('bunkerPlan.table.orderBtn')}
        </DiscoverableButton>
    );
};

const BunkerPlanViewDraftButton = ({ orderId }: { orderId: string }) => {
    const { navigate } = useNavigation();

    return (
        <DiscoverableButton
            emphasis="high"
            onClick={(event) => {
                event.stopPropagation();
                navigate('base.fuel-enquiry', {
                    orderId: orderId,
                    origin: {
                        stateName: 'base.fuel-planning-bunker-plan',
                    },
                });
            }}
        >
            {translate('bunkerPlan.table.draftBtn')}
        </DiscoverableButton>
    );
};

const BunkerPlanViewEnquiryButton = ({ orderId }: { orderId: string }) => {
    const { navigate } = useNavigation();

    return (
        <DiscoverableButton
            emphasis="high"
            onClick={(event) => {
                event.stopPropagation();
                navigate('base.fuel-orderlist-order', {
                    id: orderId,
                });
            }}
        >
            {translate('bunkerPlan.table.enquiryBtn')}
        </DiscoverableButton>
    );
};

export const BunkerPlanStateButton = ({ bunkerStop }: { bunkerStop: BunkerStopMessage }) => {
    if (bunkerStop.state === BunkerStopState.DRAFT && bunkerStop.orderId) {
        return <BunkerPlanViewDraftButton orderId={bunkerStop.orderId}></BunkerPlanViewDraftButton>;
    }

    if (bunkerStop.state === BunkerStopState.ENQUIRY && bunkerStop.orderId) {
        return <BunkerPlanViewEnquiryButton orderId={bunkerStop.orderId}></BunkerPlanViewEnquiryButton>;
    }

    if (bunkerStop.state === BunkerStopState.OFFER_CHOSEN && bunkerStop.offerId) {
        return <BunkerPlanViewOrderButton offerId={bunkerStop.offerId}></BunkerPlanViewOrderButton>;
    }

    return <BunkerPlanCreateEnquiryButton bunkerStopId={bunkerStop.id}></BunkerPlanCreateEnquiryButton>;
};
