import { UserInviteMessage } from '../../../../types/UserInviteMessage';
import { UserMessage } from '../../../../types/UserMessage';
import { UserUpdateMessage } from '../../../../types/UserUpdateMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const getUser = async (): Promise<UserMessage> => {
    const response = await authorizedAxiosInstance.get(`/v1/user`);

    return response.data;
};

export const resendInvite = async (userId: string): Promise<any> => {
    const response = await authorizedAxiosInstance.put(`/v1/user/management/${userId}/resend-invitation`);

    return response.data;
};

export const createInvite = async (userInviteMessage: UserInviteMessage): Promise<any> => {
    const response = await authorizedAxiosInstance.post(`/v1/user/management`, userInviteMessage);

    return response.data;
};

export interface UpdateManagementUserConfig {
    userId: string;
    userMessage: UserUpdateMessage;
}

export interface UpdateManagementUserRequestErrorResponse {
    errorCode: 'MAIL_NOT_UNIQUE' | 'INVALID_EMAIL';
}

export const updateManagementUser = ({ userId, userMessage }: UpdateManagementUserConfig): Promise<UserMessage> => {
    return authorizedAxiosInstance.put(`/v1/user/management/${userId}`, userMessage);
};

export const updateUser = async (userId: string, data: UserUpdateMessage): Promise<UserMessage> => {
    const response = await authorizedAxiosInstance.put<UserMessage>(`/v1/user`, data);

    return response.data;
};
