import { useField } from 'formik';
import React from 'react';

import { ReadOnlyField } from '../../../../../cdl/ReadOnlyField/ReadOnlyField';
import { Text } from '../../../../../cdl/Text/Text';
import { formatFuelMoney } from '../../../../../common/helpers/formatFuelMoney.helper';
import { translateSulphurContent } from '../../../../../common/helpers/translateSulphurContent';
import { Td } from '../../../../../common/Table/Td';
import { Tr } from '../../../../../common/Table/Tr';

import { CounterOfferReviewFormStateItem } from './CounterOfferReviewForm';

interface ItemRowProps {
    name: string;
}

const ItemRow = (props: ItemRowProps) => {
    const [field] = useField(props.name);

    const translatedSulphurContent = translateSulphurContent(field.value.sulphurContent);

    return (
        <Tr key={props.name} inactive={false} focused={false} unread={false}>
            <Td width="50%">
                <Text variant="body">{`${field.value.productName} ${translatedSulphurContent}`}</Text>
            </Td>
            <Td width="25%">
                <Text variant="body">
                    {field.value
                        ? formatFuelMoney({
                              value: field.value.price.value,
                              currency: field.value.price.currency,
                          })
                        : '-'}
                </Text>
            </Td>
            <Td width="25%">
                <ReadOnlyField
                    value={
                        field.value
                            ? formatFuelMoney({
                                  value: field.value.counterPrice.value,
                                  currency: field.value.counterPrice.currency,
                              })
                            : '-'
                    }
                ></ReadOnlyField>
            </Td>
        </Tr>
    );
};

export const CounterOfferReviewItems = () => {
    const [field] = useField<CounterOfferReviewFormStateItem[]>('items');
    const items = field.value;

    return <>{items.map((item, index) => (!item.deleted ? <ItemRow key={index} name={`items.${index}`} /> : null))}</>;
};
