import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconCircleDotted = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12 2a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0V3a1 1 0 0 1 1-1ZM7.5 3.21a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0v-.01a1 1 0 0 1 1-1Zm9 0a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0v-.01a1 1 0 0 1 1-1ZM4.21 6.5a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0V7.5a1 1 0 0 1 1-1Zm15.58 0a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0V7.5a1 1 0 0 1 1-1ZM3 11a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0V12a1 1 0 0 1 1-1Zm18 0a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0V12a1 1 0 0 1 1-1ZM4.21 15.5a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0v-.01a1 1 0 0 1 1-1Zm15.58 0a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0v-.01a1 1 0 0 1 1-1ZM7.5 18.79a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0v-.01a1 1 0 0 1 1-1Zm9 0a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0v-.01a1 1 0 0 1 1-1ZM12 20a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0V21a1 1 0 0 1 1-1Z"
            clipRule="evenodd"
        />
    </svg>
);
