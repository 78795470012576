import {
    LubesConsumptionPreset,
    LubesConsumptionPresetsRequestMessage,
} from '../../../types/LubesConsumptionPresetsRequestMessage';
import { useToasts } from '../../cdl/Toast/useToasts';
import { translate } from '../../common/helpers/translate.helper';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { createOverlay } from '../../common/Overlay/Overlay';
import { Box } from '../../common/ui/Box';

import { EditPresetsForm } from './form/EditPresetsForm';
import { useLubesConsumptionPresets } from './hooks/useLubesConsumptionPresets';
import { useVesselConsumptionPresetsMutation } from './hooks/useVesselConsumptionPresetsMutation';

interface EditPresetsProps {
    vesselId: string;
    customerId: string;
    onClose: () => void;
}

export const EditPresetsOverlay = (props: EditPresetsProps) => {
    return createOverlay(<EditPresets {...props} />);
};

const EditPresets = ({ onClose, vesselId, customerId }: EditPresetsProps) => {
    const presetsQuery = useLubesConsumptionPresets({ vesselId });
    const presetsMutation = useVesselConsumptionPresetsMutation(vesselId);
    const { addToast, addErrorToast } = useToasts();

    const onSubmit = async (updatedPresets: LubesConsumptionPreset[]) => {
        const presets: LubesConsumptionPresetsRequestMessage = {
            vesselId: vesselId,
            customerId: customerId,
            presets: updatedPresets,
        };
        await presetsMutation.mutateAsync(
            { vesselId, presets },
            {
                onSuccess: () => {
                    addToast(translate('consumption.editPresets.presetsUpdateSuccessToast'));
                    onClose();
                },
                onError: () => {
                    addErrorToast(translate('consumption.editPresets.presetsUpdateErrorToast'));
                },
            }
        );
    };

    if (!presetsQuery.data) {
        return <LoadingIndicator variant="full-height" />;
    }

    return (
        <Box paddingBottom={11} minHeight="100%" maxWidth="800px" marginX="auto">
            <EditPresetsForm
                vesselId={vesselId}
                presets={presetsQuery.data.presets}
                onClose={onClose}
                onSubmit={onSubmit}
            />
        </Box>
    );
};
