import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconColumns = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M3 6a1 1 0 0 1 1-1h5.5a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Zm10.5 0a1 1 0 0 1 1-1H20a1 1 0 1 1 0 2h-5.5a1 1 0 0 1-1-1ZM3 10a1 1 0 0 1 1-1h5.5a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Zm10.5 0a1 1 0 0 1 1-1H20a1 1 0 1 1 0 2h-5.5a1 1 0 0 1-1-1ZM3 14a1 1 0 0 1 1-1h5.5a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Zm10.5 0a1 1 0 0 1 1-1H20a1 1 0 1 1 0 2h-5.5a1 1 0 0 1-1-1ZM3 18a1 1 0 0 1 1-1h5.5a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Zm10.5 0a1 1 0 0 1 1-1H20a1 1 0 1 1 0 2h-5.5a1 1 0 0 1-1-1Z"
            clipRule="evenodd"
        />
    </svg>
);
