import { useField } from 'formik';

import { Switch, SwitchProps } from './Switch';

interface FormikSwitchProps extends Omit<SwitchProps, 'onChange' | 'checked'> {
    name: string;
    invert?: boolean;
}

export const FormikSwitch = (props: FormikSwitchProps) => {
    const [field, , helpers] = useField(props.name);
    const checked = props.invert ? !field.value : field.value;

    return (
        <Switch
            {...props}
            checked={checked}
            onChange={(checked) => helpers.setValue(props.invert ? !checked : checked)}
        />
    );
};
