/**
 *
 * Helper to easily call functions, if defined. Usefull to call an
 * Event-Handler from the props and an internal one.
 *
 * Example:
 * function Example = ({onChange}) => {
 *   const [state, setState] = React.useState('')
 *
 *   return <input onChange={callAll(event => setState(event.target.value), onChange)} />
 * }
 *
 * @param  {...any} fns
 * @returns fn
 *
 * Credits: KCD
 */
export const callAll =
    (...fns) =>
    (...args) =>
        fns.forEach((fn) => fn?.(...args));
