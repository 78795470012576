import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { LubesCreateQuotePage } from './LubesCreateQuotePage';

const LubesCreateQuotePageDirective = (props: any) => {
    return (
        <AppProviders>
            <LubesCreateQuotePage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(LubesCreateQuotePageDirective, 'reactLubesCreateQuotePage');
