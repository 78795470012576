import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';

import { updateOffer } from '../../../common/api/clients/order.api';
import { queryKeys } from '../../../common/api/queryKeys';

export function useOfferEdit() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateOffer,
        onSuccess: (data) => {
            const offerId = data.id;
            return queryClient.refetchQueries({
                predicate: (query) => {
                    return (
                        isEqual(query.queryKey, queryKeys.offerApprovalSettings(offerId)) ||
                        isEqual(query.queryKey, queryKeys.offer(offerId))
                    );
                },
            });
        },
    });
}
