export const findDefaultUnitSize = (packType) => {
    switch (packType) {
        case 'BOTTLE':
            return 1;
        case 'BULK':
            return 1;
        case 'BULK_EX_IBC':
            return 1000;
        case 'BULK_EX_DRUMS':
            return 208;
        case 'DRUMS':
            return 208;
        case 'IBCS':
            return 1000;
        case 'PACK':
            return 1;
        case 'CARTON':
            return 10;
        case 'PAIL':
            return 20;
        default:
            return 1;
    }
};

export const calculateTotal = (items, surcharges, samplekits) => {
    const money = newMoney();
    if (items && items.length > 0) {
        for (const index in items) {
            const item = items[index];
            if (!item.deleted && item.total) {
                money.value += item.total.value;
                if (!money.currency && item.total.currency) {
                    money.currency = item.total.currency;
                }
            }
        }
    }
    money.value += calculateSurchargeTotal(surcharges).value;
    money.value += calculateSamplekitTotal(samplekits).value;
    return money;
};

const calculateSamplekitTotal = (samplekits) => {
    const money = newMoney();
    if (samplekits && samplekits.length > 0) {
        for (const index in samplekits) {
            const samplekit = samplekits[index];
            if (!samplekit.deleted && samplekit.value && samplekit.quantity) {
                if (typeof samplekit.value?.value === 'string') {
                    money.value += parseFloat(samplekit.value.value);
                } else {
                    money.value += samplekit.value.value;
                }
                if (!money.currency) {
                    money.currency = samplekit.value.currency;
                }
            }
        }
    }
    return money;
};

const calculateSurchargeTotal = (surcharges) => {
    const money = newMoney();
    if (surcharges && surcharges.length > 0) {
        for (const index in surcharges) {
            const surcharge = surcharges[index];
            if (!surcharge.deleted && surcharge.value) {
                if (typeof surcharge.value?.value === 'string') {
                    money.value += parseFloat(surcharge.value?.value);
                } else {
                    money.value += surcharge.value.value;
                }
                if (!money.currency) {
                    money.currency = surcharge.value.currency;
                }
            }
        }
    }
    return money;
};

export const calculateVolume = (items) => {
    let volume = 0;
    items.forEach((item) => {
        if (item.volume && !item.deleted) volume += item.volume;
    });
    return volume;
};

export const calculateVolumeWithPrices = (items) => {
    let volume = 0;
    items.forEach((item) => {
        if (item.volume && item.price && item.price.value && !item.deleted) {
            volume += item.volume;
        }
    });
    return volume;
};

export function calculatePpl(total, volume) {
    const money = newMoney();
    if (total && volume) {
        money.value = total.value / volume;
        money.currency = total.currency;
    }
    return money;
}

const newMoney = () => ({
    value: 0,
    currency: undefined,
});
