import { useFormikContext } from 'formik';

import { Select, SelectProps } from '../../../../cdl/Select/Select';
import { translate } from '../../../../common/helpers/translate.helper';
import { useEnums } from '../../../../common/hooks/useEnums';
import { useFormatFuelCategory } from '../utils/useFormatFuelCategory';

import { FormikVesselConsumptionPresetsState } from './EditVesselConsumptionPresetsForm';

interface SelectedCategoryOption {
    label: string;
    value: string;
    data: {
        productGroup: string;
        sulphurContent: string;
    };
}

interface FormikVesselConsumptionPresetCategorySelectProps {
    index: number;
}

export const FormikVesselConsumptionPresetCategorySelect = (
    props: FormikVesselConsumptionPresetCategorySelectProps
) => {
    const { values, setFieldValue } = useFormikContext<FormikVesselConsumptionPresetsState>();
    const { getEnumsForTypeAndContext } = useEnums();
    const formatFuelCategory = useFormatFuelCategory();

    const preset = values.presets[props.index];

    let selectedCategory: SelectedCategoryOption | null = null;

    if (!preset.productGroup) {
        selectedCategory = null;
    } else {
        selectedCategory = {
            label: formatFuelCategory(preset.productGroup, preset.sulphurContent),
            value: formatFuelCategory(preset.productGroup, preset.sulphurContent),
            data: {
                productGroup: preset.productGroup,
                sulphurContent: preset.sulphurContent,
            },
        };
    }

    const makeOptions = () => {
        const sulphurContent = getEnumsForTypeAndContext('sulphurContent', 'FUEL');
        const productGroups = getEnumsForTypeAndContext('productGroup', 'FUEL');

        const categories: SelectedCategoryOption[] = [];
        Object.keys(productGroups)
            .filter((productGroup) => ['HFO', 'MDO', 'MGO'].includes(productGroup))
            .forEach((productGroup) =>
                Object.keys(sulphurContent).forEach((sulphurContent) =>
                    categories.push({
                        label: formatFuelCategory(productGroup, sulphurContent),
                        value: formatFuelCategory(productGroup, sulphurContent),
                        data: {
                            productGroup,
                            sulphurContent,
                        },
                    })
                )
            );

        const selectedCategories = values.presets
            .map((preset) => {
                return formatFuelCategory(preset.productGroup, preset.sulphurContent);
            })
            .filter((category) => {
                if (!selectedCategory) {
                    return true;
                }
                return category !== selectedCategory.value;
            });

        return categories.filter((category) => {
            return !selectedCategories.includes(category.value);
        });
    };

    const handleChange: SelectProps<SelectedCategoryOption>['onChange'] = (newValue) => {
        if (newValue === null) {
            return;
        }

        const newPreset = {
            ...values.presets[props.index],
            productGroup: newValue.data.productGroup,
            sulphurContent: newValue.data.sulphurContent,
        };

        const newPresets = Array.from(values.presets);
        newPresets[props.index] = newPreset;

        setFieldValue('presets', newPresets);
    };

    return (
        <Select<SelectedCategoryOption>
            value={selectedCategory}
            onChange={handleChange}
            label={translate('vessel.fuelStocks.editPresetsForm.category')}
            placeholder={translate('vessel.fuelStocks.editPresetsForm.select')}
            options={makeOptions()}
        />
    );
};
