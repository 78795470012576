import styled from 'styled-components';

import { Box } from '../ui/Box';

// Component definition until the CDL component is implemented

export const HeaderWithLine = styled(Box).attrs({
    paddingBottom: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderColor: 'border.muted',
})``;
