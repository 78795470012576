import { translate } from '../../common/helpers/translate.helper';
import { useProductContext } from '../../common/hooks/useProductContext';

interface ChartTitleTranslations {
    completedOrders: string;
    pricePerUnit: string;
    volume: string;
    total: string;
}

export const useChartTitleTranslations = (): ChartTitleTranslations => {
    const { isLubes } = useProductContext();

    return {
        completedOrders: translate('reporting.completed'),
        pricePerUnit: isLubes ? translate('reporting.ppl') : translate('reporting.ppmt'),
        volume: isLubes ? translate('reporting.volume') : translate('reporting.volumeMt'),
        total: translate('reporting.total'),
    };
};
