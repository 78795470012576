import { UserGatewayMessage } from '../../../types/UserGatewayMessage';
import { UserMessage } from '../../../types/UserMessage';
import { UserRole } from '../../../types/UserRole';

export const identifyUserRole = (user: UserMessage | UserGatewayMessage) => {
    if (user.accessLevel === 'ADMIN') {
        return UserRole.GROUP_ADMIN;
    }

    if (user.companyAccesses && user.companyAccesses.length) {
        if (user.accessLevel === 'WRITE' && user.companyAccesses[0].accessLevel === 'ADMIN')
            return UserRole.COMPANY_ADMIN;

        if (user.accessLevel === 'READ' && user.companyAccesses[0].accessLevel === 'WRITE')
            return UserRole.COMPANY_EMPLOYEE;

        if (user.accessLevel === 'READ' && user.companyAccesses[0].accessLevel === 'READ')
            return UserRole.COMPANY_INSPECTOR;
    }

    return UserRole.CUSTOM;
};
