import { useQuery, useQueryClient } from '@tanstack/react-query';

import { queries } from '../../../../common/api/queryKeys/queries';

export const useCustomerGroupDetails = (groupId: string) => {
    const queryClient = useQueryClient();

    queryClient.prefetchQuery(queries.customers.list({ groupId }));

    return useQuery(queries.customerGroups.detail(groupId));
};
