export enum ProductCategoryLubes {
    'BOB' = 'BOB',
    'ME_CYL_HS' = 'ME_CYL_HS',
    ME_CYL_HS_140_BN = 'ME_CYL_HS_140_BN',
    ME_CYL_HS_100_BN = 'ME_CYL_HS_100_BN',
    ME_CYL_HS_70_BN = 'ME_CYL_HS_70_BN',
    'ME_CYL_LS' = 'ME_CYL_LS',
    'ME_CYL_ULS' = 'ME_CYL_ULS',
    ME_CYL_ULS_17_BN = 'ME_CYL_ULS_17_BN',
    ME_CYL_ULS_25_BN = 'ME_CYL_ULS_25_BN',
    'ME_CIRC' = 'ME_CIRC',
    'AE_CIRC' = 'AE_CIRC',
    AE_CIRC_20_BN = 'AE_CIRC_20_BN',
    AE_CIRC_30_BN = 'AE_CIRC_30_BN',
    AE_CIRC_40_BN = 'AE_CIRC_40_BN',
    AE_CIRC_50_BN = 'AE_CIRC_50_BN',
    'AE_CIRC_ULS' = 'AE_CIRC_ULS',
    'RED_GEAR' = 'RED_GEAR',
    'STERNTUBE' = 'STERNTUBE',
    'ST_GEAR' = 'ST_GEAR',
    'CPP' = 'CPP',
    'BOW_THRUST' = 'BOW_THRUST',
    'GEAR_OIL' = 'GEAR_OIL',
    'HYDR_OIL' = 'HYDR_OIL',
    'THERM_OIL' = 'THERM_OIL',
    'OTHER' = 'OTHER',
}
