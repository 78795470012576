import PropTypes from 'prop-types';

import { Box } from '../../../common/ui/Box';
import { PriceBoxContent } from '../PriceBoxContent';

export const PriceBox = ({ price }) => {
    return (
        <Box
            position="relative"
            paddingTop={5}
            paddingLeft={5}
            paddingRight={5}
            backgroundColor="white"
            width="240px"
            height="220px"
            borderRadius="4px"
        >
            <PriceBoxContent price={price} />
        </Box>
    );
};

PriceBox.propTypes = {
    price: PropTypes.object,
};
