import styled from 'styled-components';

import { get } from '../../../../common/ui/get';

interface PillProps {
    hasValue: boolean;
    isOpen: boolean;
}

export const Pill = styled.div<PillProps>`
    display: inline-flex;
    align-items: center;
    border: 1px ${(props) => (props.hasValue ? 'solid' : 'dashed')} ${get('colors.border.default')};
    border-radius: 24px;
    height: 24px;
    padding: 0 ${(props) => (props.hasValue ? '3' : '7')}px 0 3px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    background-color: ${(props) => (props.isOpen ? get('colors.background.subtle') : get('colors.background.default'))};
    transition: background-color 75ms ease;

    &:hover {
        background-color: ${get('colors.background.subtle')};
    }
`;
