import { useQuery } from '@tanstack/react-query';

import { queries } from '../../../common/api/queryKeys/queries';

interface UseVesselTanksParams {
    vesselId: string;
    enabled: boolean;
}

export const useVesselTanks = ({ vesselId, enabled }: UseVesselTanksParams) => {
    return useQuery({ ...queries.vessels.detail(vesselId)._ctx.tanks, enabled: enabled });
};
