import moment from 'moment/moment';

import { CalendarRangePreset } from '../../cdl/DateRangePicker/types/CalendarRangePreset';
import { RangeDirection } from '../../cdl/DateRangePicker/types/RangeDirection';

import { translate } from './translate.helper';

export function generateStatisticsDateRangePresets() {
    const presets: CalendarRangePreset[] = [];

    // Get the current year and month
    const currentYear = moment().year();
    const currentMonth = moment().month();

    const currentDate = moment().endOf('day').toDate();

    const currentDateSubtractedBySixMonths = moment().subtract(6, 'months').endOf('day').toDate();

    // Generate the last six months preset
    presets.push({
        label: translate('reporting.dateRangePresets.lastSixMonths'),
        range: [currentDateSubtractedBySixMonths, currentDate],
        direction: RangeDirection.Backward,
    });

    const currentYearStartDate = moment().year(currentYear).startOf('year').toDate();

    // Generate the current month preset
    const currentMonthStartDate = moment().month(currentMonth).startOf('month').toDate();
    presets.push({
        label: translate('reporting.dateRangePresets.currentMonth'),
        range: [currentMonthStartDate, currentDate],
        direction: RangeDirection.Forward,
    });

    // Generate the last month preset
    const lastMonthStartDate = moment()
        .month(currentMonth - 1)
        .startOf('month')
        .toDate();
    const lastMonthEndDate = moment()
        .month(currentMonth - 1)
        .endOf('month')
        .toDate();
    presets.push({
        label: translate('reporting.dateRangePresets.lastMonth'),
        range: [lastMonthStartDate, lastMonthEndDate],
        direction: RangeDirection.Backward,
    });

    // Generate the current year preset
    presets.push({
        label: translate('reporting.dateRangePresets.currentYear', {
            year: currentYear.toString(),
        }),
        range: [currentYearStartDate, currentDate],
        direction: RangeDirection.Backward,
    });

    // Generate the last year preset
    const lastYearStartDate = moment()
        .year(currentYear - 1)
        .startOf('year')
        .toDate();
    const lastYearEndDate = moment()
        .year(currentYear - 1)
        .endOf('year')
        .toDate();

    presets.push({
        label: translate('reporting.dateRangePresets.lastYear', {
            year: (currentYear - 1).toString(),
        }),
        range: [lastYearStartDate, lastYearEndDate],
        direction: RangeDirection.Backward,
    });

    return presets;
}
