import { useDebounce } from '../../common/hooks/useDebounce';

import { useCompareState } from './useCompareState';
import { useMappedPriceCompare } from './useMappedPriceCompare';

export const usePriceCompareState = ({ initializer, enabled = true }) => {
    const { state, actions } = useCompareState(initializer);
    const debouncedState = useDebounce(state, 500);

    const items = debouncedState.items.filter((item) => item.isComplete());
    const portIds = debouncedState.portInformation.filter((port) => Boolean(port.portId)).map((it) => it.portId);
    const customerId = debouncedState.customerId;

    const priceCompare = useMappedPriceCompare({
        items,
        portIds,
        customerIds: [customerId],
        enabled: !!items.length && !!portIds.length && !!customerId && enabled,
    });

    return {
        state,
        actions,
        ...priceCompare,
    };
};
