import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

import { get } from '../../common/ui/get';

export const DropdownMenuItem = styled(RadixDropdownMenu.Item)`
    color: white;
    padding: 4px 16px;
    outline: none;
    cursor: pointer;

    &:hover {
        background: ${get('colors.accent.emphasis')};
    }
`;
