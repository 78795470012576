import { useField } from 'formik';
import { OnChangeValue } from 'react-select';

import { SupplierGroupSelect, SupplierGroupSelectProps } from '../SupplierGroupSelect/SupplierGroupSelect';

interface FormikSupplierGroupSelectProps<IsMulti extends boolean>
    extends Omit<SupplierGroupSelectProps<IsMulti>, 'onChange'> {
    name: string;
    onGroupChange?: (value: OnChangeValue<string, IsMulti>) => void;
}

export function FormikSupplierGroupSelect<IsMulti extends boolean = false>(
    props: FormikSupplierGroupSelectProps<IsMulti>
) {
    const [field, meta, helpers] = useField(props.name);

    const onChange: SupplierGroupSelectProps<IsMulti>['onChange'] = (option) => {
        if (field.value !== option) {
            props.onGroupChange?.(option);
        }

        helpers.setValue(option);
    };

    return (
        <SupplierGroupSelect
            {...props}
            onChange={onChange}
            value={field.value}
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : props.caption}
            onBlur={() => {
                helpers.setTouched(true);
            }}
        />
    );
}
