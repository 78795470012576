import { useQuery } from '@tanstack/react-query';

import { getBunkerMetricGlobalPrices } from '../../common/api/clients/bunkerMetric.api';
import { queryKeys } from '../../common/api/queryKeys';

export const useGlobalPrices = (enabled: boolean) => {
    return useQuery({
        queryKey: queryKeys.globalPrices(),
        queryFn: () => getBunkerMetricGlobalPrices(),
        enabled,
    });
};
