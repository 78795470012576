import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconEyeOff = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2.293 2.293a1 1 0 0 1 1.414 0l14.276 14.276a.994.994 0 0 1 .153.152l3.571 3.572a1 1 0 0 1-1.414 1.414l-3.087-3.087A10.32 10.32 0 0 1 12 20c-4.48 0-8.093-2.647-10.868-7.504a1 1 0 0 1 0-.992C2.31 9.444 3.637 7.777 5.127 6.54L2.293 3.707a1 1 0 0 1 0-1.414Zm4.256 5.67C5.346 8.926 4.212 10.26 3.159 12c2.499 4.13 5.454 6 8.841 6a8.31 8.31 0 0 0 3.728-.858l-2.434-2.434a3 3 0 0 1-4.002-4.002L6.549 7.963Zm4.687 4.687a1 1 0 0 0 .114.114l-.114-.114Zm.76-6.65a8.467 8.467 0 0 0-2.358.326 1 1 0 1 1-.55-1.922A10.466 10.466 0 0 1 12.002 4c4.479 0 8.091 2.648 10.866 7.504a1 1 0 0 1 0 .992c-.808 1.414-1.685 2.641-2.637 3.67a1 1 0 0 1-1.468-1.357c.723-.782 1.416-1.717 2.077-2.81C18.342 7.87 15.387 6 12 6h-.004Z"
            clipRule="evenodd"
        />
    </svg>
);
