import { Form, useFormikContext } from 'formik';

import { Button } from '../../cdl/Button/Button';
import { FormikCheckbox } from '../../cdl/Checkbox/FormikCheckbox';
import { FormikDebug } from '../../common/form-elements/formik/FormikDebug';
import { FormikMultipleEntriesInput } from '../../common/form-elements/FormMultipleEntriesInput/FormikMultipleEntriesInput';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';

import { ShareSupplierBunkerPlanFormState } from './ShareSupplierBunkerStopPopup';

interface ShareSupplierBunkerPlanFormProps {
    onCancel: () => void;
}

export const ShareSupplierBunkerStopForm = ({ onCancel }: ShareSupplierBunkerPlanFormProps) => {
    const formikContext = useFormikContext<ShareSupplierBunkerPlanFormState>();

    return (
        <Form>
            <FormikMultipleEntriesInput
                inputLabel={translate('bunkerPlan.table.supplierPopup.emailAddressInputLabel')}
                inputPlaceholder={translate('bunkerPlan.table.supplierPopup.emailAddressInputPlaceholder')}
                name="receiverEmailAddresses"
                pattern="^\S+@\S+$"
            />

            <Box display="flex" justifyContent="space-between" marginTop={6}>
                <FormikCheckbox name="copyToSelf" label={translate('bunkerPlan.table.supplierPopup.copyToSelf')} />
                <Box display="flex" gap={4}>
                    <Button type="button" emphasis="medium" disabled={formikContext.isSubmitting} onClick={onCancel}>
                        {translate('btn.cancel')}
                    </Button>
                    <Button
                        type="submit"
                        emphasis="high"
                        isLoading={formikContext.isSubmitting}
                        disabled={!formikContext.isValid}
                    >
                        {translate('btn.send')}
                    </Button>
                </Box>
            </Box>

            <FormikDebug />
        </Form>
    );
};
