import { MouseEventHandler } from 'react';

import { Box } from '../../../../common/ui/Box';
import { OfferModel } from '../../../../offer/model/OfferModel';
import { OfferSummaryContainer } from '../../common/OfferCard/OfferSummaryContainer';

import { OfferCoreData } from './OfferCoreData';
import { OfferProductSummary } from './OfferProductSummary';
import { OfferSupplierAndTotals } from './OfferSupplierAndTotals';

interface OfferInformationProps {
    offer: OfferModel;
    onClick?: MouseEventHandler<HTMLDivElement>;
    isBestPrice?: boolean;
}

export const OfferSummary = ({ offer, onClick, isBestPrice }: OfferInformationProps) => {
    return (
        <OfferSummaryContainer onClick={onClick} positiveHover={isBestPrice}>
            <OfferSupplierAndTotals offer={offer} isBestPrice={isBestPrice} />
            <Box display="grid" gridTemplateColumns="1fr 1fr" gridColumnGap="16px" marginTop="4">
                <OfferCoreData offer={offer} />
                <OfferProductSummary offer={offer} />
            </Box>
        </OfferSummaryContainer>
    );
};
