import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createApprovalRequest } from '../../../common/api/clients/offerApproval.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { queries } from '../../../common/api/queryKeys/queries';

export const useApprovalRequestCreate = (offerId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createApprovalRequest,
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries({ queryKey: queries.offers.list._def }),
                queryClient.invalidateQueries({ queryKey: queryKeys.offer(offerId) }),
            ]);
        },
    });
};
