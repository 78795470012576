import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import { ScheduleMessage } from '../../../types/ScheduleMessage';
import { getScheduleList } from '../api/clients/gateway.api';
import { queryKeys } from '../api/queryKeys';

import { useRole } from './useRole';

export interface Schedule extends ScheduleMessage {
    timeZoneId: string;
    rank: number;
}

export const useSchedules = (imo?: string) => {
    const role = useRole();

    const etdFrom = moment.utc(moment()).startOf('day').toISOString();

    const etdTo = moment.utc(moment().add(120, 'days')).endOf('day').toISOString();

    const query = useQuery({
        queryKey: queryKeys.schedulesWithPrices(imo),
        queryFn: () => getScheduleList({ imo: imo!, etdFrom, etdTo }),
        enabled: !!imo && !role.isSupplier(),
    });

    return {
        ...query,
        schedules:
            query.data
                ?.filter((schedule) => schedule.port)
                .map((schedule, index): Schedule => {
                    return {
                        ...schedule,
                        timeZoneId: schedule.port!.timeZoneId,
                        rank: index + 1,
                    };
                }) ?? [],
    };
};
