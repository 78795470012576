import { useFormikContext } from 'formik';

import { checkPreference } from '../../../flags';
import { Box } from '../../ui/Box';

export const FormikDebug = () => {
    const { values, errors } = useFormikContext();

    if (!checkPreference('FormikDebugTools')) {
        return null;
    }

    return (
        <Box marginTop={8} paddingX={5} overflow="auto">
            <Box>
                Values
                <pre>{JSON.stringify(values, null, 2)}</pre>
            </Box>

            <Box>
                Errors
                <pre>{JSON.stringify(errors, null, 2)}</pre>
            </Box>
        </Box>
    );
};
