import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getAssignmentList } from '../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../common/api/queryKeys';

export const useAssignmentList = (customerId, portId, testOrder) => {
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: queryKeys.assignments({ customerIds: [customerId] }),
        queryFn: () => getAssignmentList({ customerIds: [customerId] }),
        enabled: !!customerId,
        select: (assignments) => {
            if (!portId) {
                return assignments;
            }

            const portLimitationFilteredAssignments = assignments.filter((assignment) => {
                // Lubes assignments do not have port limitations and should not be filtered out
                if (!assignment.portLimitations?.length) {
                    return true;
                }

                return assignment.portLimitations.some((limitationPortId) => limitationPortId === portId);
            });

            return portLimitationFilteredAssignments.filter(
                (assignment) => assignment.supplier?.testCompany === testOrder
            );
        },
    });

    const prefetch = (customerId) => {
        queryClient.prefetchQuery({
            queryKey: queryKeys.assignments({ customerIds: [customerId] }),
            queryFn: () => getAssignmentList({ customerIds: [customerId] }),
        });
    };

    return {
        ...query,
        prefetch,
    };
};
