import { useQuery } from '@tanstack/react-query';

import { ProductContext } from '../../../types/ProductContext';
import { AdminStatisticsMessage } from '../../../types/StatisticsMessage';
import {
    getCustomerReports,
    getPortReports,
    getProductReports,
    getSupplierReports,
    getVesselReports,
} from '../../common/api/clients/gateway.api';
import { getGeneralReport, getProductGroupReport } from '../../common/api/clients/report.api';
import { queries } from '../../common/api/queryKeys/queries';
import { useProductContext } from '../../common/hooks/useProductContext';
import { formatFuelProductGroupReports } from '../utils/formatFuelProductGroupReports';
import { mapProductGroupsReports } from '../utils/mapProductGroupsReports';
import { sortReportsByName } from '../utils/sortReports';

interface FetchAdminStatisticsParams {
    from: number;
    to: number;
    type: ProductContext;
    customerIds?: string[];
    supplierIds?: string[];
}

export const useAdminStatistics = (params: FetchAdminStatisticsParams) => {
    const { isLubes } = useProductContext();

    const fetchAdminStatistics = async (params: FetchAdminStatisticsParams): Promise<AdminStatisticsMessage> => {
        const [general, vessel, supplier, customer, product, port, productGroup] = await Promise.all([
            getGeneralReport(params),
            getVesselReports(params),
            getSupplierReports(params),
            getCustomerReports(params),
            getProductReports(params),
            getPortReports(params),
            getProductGroupReport(params),
        ]);

        const mappedVesselReports = Object.values(vessel.reports);
        const mappedSupplierReports = Object.values(supplier.reports);
        const mappedCustomerReports = Object.values(customer.reports);
        const mappedPortReports = Object.values(port.reports);
        const mappedProductReports = Object.values(product.reports);

        const mappedAndSortedProductGroupReports = isLubes
            ? mapProductGroupsReports(productGroup.reports).sort((a, b) => a.productGroup.localeCompare(b.productGroup))
            : formatFuelProductGroupReports(mapProductGroupsReports(productGroup.reports)).sort((a, b) =>
                  a.productGroup.localeCompare(b.productGroup)
              );

        return {
            period: general.period,
            general: general.item,
            vessel: mappedVesselReports.sort(sortReportsByName),
            supplier: mappedSupplierReports.sort(sortReportsByName),
            customer: mappedCustomerReports.sort(sortReportsByName),
            port: mappedPortReports.sort(sortReportsByName),
            product: mappedProductReports.sort(sortReportsByName),
            productGroup: mappedAndSortedProductGroupReports,
        };
    };

    const fiveMinutesInMilliseconds = 5 * 60 * 1000;

    return useQuery({
        queryKey: queries.reports.all(params).queryKey,
        queryFn: () => fetchAdminStatistics(params),
        staleTime: fiveMinutesInMilliseconds,
    });
};
