import { ReactNode, useState } from 'react';
import styled from 'styled-components';

import { Modal } from '../../../../../cdl/Modal/Modal';
import { TextButton } from '../../../../../common/buttons/TextButton';
import { translate } from '../../../../../common/helpers/translate.helper';
import { LabelText } from '../../../../../common/LabelText/LabelText';
import { Box } from '../../../../../common/ui/Box';
import { get } from '../../../../../common/ui/get';

const InvolvedPartyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: ${get('space.6')}px;
    padding-right: ${get('space.6')}px;
    border-left: 1px solid ${get('colors.border.muted')};
    box-sizing: border-box;
    max-width: 292px;
    flex: 1;

    &:first-of-type {
        padding-left: 0;
        border-left: none;
        max-width: 268px;
    }
`;

interface InvolvedPartyProps {
    label: string;
    disabled?: boolean;
    children?: ReactNode;
    modalContent?: ReactNode;
}

export const InvolvedParty = ({ label, children, modalContent, disabled = false }: InvolvedPartyProps) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <InvolvedPartyWrapper>
            <Box display="flex" flexDirection="column">
                <LabelText>{label}</LabelText>
                {children}
            </Box>

            {modalContent ? (
                <>
                    <TextButton disabled={disabled} onClick={() => setIsOpen(true)}>
                        {translate('global.coreData.viewDetails')}
                    </TextButton>
                    <Modal isOpen={isOpen} onDismiss={() => setIsOpen(false)}>
                        {modalContent}
                    </Modal>
                </>
            ) : null}
        </InvolvedPartyWrapper>
    );
};
