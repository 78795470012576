import { ReactNode } from 'react';

import { LocationStateProvider } from '../../../common/LocationState/LocationStateProvider';

interface UserOverviewStateProps {
    children: ReactNode;
}

export const UserOverviewState = (props: UserOverviewStateProps) => {
    const stateConfig = {
        searchQuery: '',
        customerGroupIds: [],
        supplierGroupIds: [],
        customerIds: [],
        supplierIds: [],
        page: 0,
    };

    return <LocationStateProvider stateConfig={stateConfig}>{props.children}</LocationStateProvider>;
};
