import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconLogoLite = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 67 21"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12.864 18.71a2.16 2.16 0 0 1 3.072 0l.476.481c1.507 1.424 3.789 1.424 5.196 0l6.136-6.205c1.408-1.524 1.408-3.831 0-5.255l-6.136-6.205c-1.506-1.423-3.788-1.423-5.196 0l-.476.482a2.16 2.16 0 0 1-3.072 0l-.476-.482C10.88.103 8.599.103 7.192 1.526L1.056 7.731c-1.408 1.524-1.408 3.832 0 5.255l6.136 6.205c1.506 1.424 3.788 1.424 5.196 0l.476-.481Zm4.761-11.888a1.62 1.62 0 0 1 0-2.278l.886-.895c.26-.264.719-.264 1.036.036l6.099 6.169c.26.263.26.727-.036 1.047l-6.1 6.168c-.26.263-.719.263-1.036-.036l-6.1-6.169c-.26-.263-.26-.727.036-1.048a1.513 1.513 0 0 0-.072-2.12 1.472 1.472 0 0 0-2.097.073c-1.372 1.486-1.372 3.794.035 5.217l.899.91a1.62 1.62 0 0 1 0 2.277l-.886.896c-.26.263-.718.263-1.036-.036l-6.099-6.169c-.26-.263-.26-.727.036-1.048l6.1-6.167c.26-.264.72-.264 1.036.036l6.1 6.169c.26.263.26.727-.036 1.047a1.513 1.513 0 0 0 .072 2.121c.6.566 1.538.533 2.097-.073 1.372-1.487 1.372-3.794-.035-5.218l-.899-.909Z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            d="M36.9 14.72a7.39 7.39 0 0 0-.29.711 2.277 2.277 0 0 0-.133.712c0 .132.027.238.08.317.052.079.153.118.303.118.184 0 .413-.052.685-.158.281-.114.58-.26.897-.435a16.5 16.5 0 0 0 1.002-.62c.351-.237.698-.479 1.041-.725a34.83 34.83 0 0 0 1.82-1.45.624.624 0 0 1 .25-.132.977.977 0 0 1 .25-.04c.176 0 .321.062.435.185a.689.689 0 0 1 .172.488c0 .185-.062.391-.185.62-.123.228-.338.452-.646.672-.562.519-1.13 1.01-1.7 1.477-.572.465-1.143.878-1.714 1.239a9.452 9.452 0 0 1-1.688.844 4.54 4.54 0 0 1-1.621.316c-.36 0-.664-.048-.91-.145a1.545 1.545 0 0 1-.58-.395 1.521 1.521 0 0 1-.303-.594 2.78 2.78 0 0 1-.093-.725c0-.43.062-.87.185-1.318.132-.457.286-.875.461-1.253.308-.685.62-1.366.936-2.043l.87-1.859 3.995-8.503c.158-.343.37-.585.633-.726.273-.14.545-.21.817-.21.273 0 .519.074.739.224.228.15.342.39.342.725 0 .158-.04.325-.118.5-.07.176-.163.365-.277.568-.22.413-.483.918-.791 1.516l-.976 1.95c-.342.704-.698 1.438-1.067 2.203a274.4 274.4 0 0 1-1.068 2.214l-.976 2.057-.778 1.674Z"
        />
        <path
            fill="currentColor"
            d="M44.822 7.126c0-.203.036-.41.106-.62.08-.22.193-.418.343-.593.15-.176.334-.321.553-.435.229-.115.497-.172.805-.172.51 0 .865.101 1.068.303.202.194.303.44.303.739a1.7 1.7 0 0 1-.106.553 1.824 1.824 0 0 1-.29.567 1.556 1.556 0 0 1-1.266.593c-.544 0-.927-.083-1.146-.25a.959.959 0 0 1-.37-.685Zm-1.45 2.966c.15-.246.321-.422.514-.527.194-.106.392-.159.594-.159.334 0 .62.11.857.33.246.21.369.483.369.817-.009.08-.022.158-.04.238a.632.632 0 0 1-.079.237c-.123.229-.224.422-.303.58l-.224.448-.185.396a50.321 50.321 0 0 1-.58 1.2c-.132.254-.26.518-.382.79-.114.273-.215.541-.303.805a2.54 2.54 0 0 0-.119.725c0 .237.057.395.171.474.123.07.295.106.514.106.247 0 .502-.053.765-.158.273-.106.545-.242.817-.409.273-.167.541-.356.805-.567.263-.22.51-.44.738-.66.237-.219.453-.43.646-.632.202-.202.37-.382.501-.54.176-.167.352-.25.527-.25.158 0 .286.07.383.21.105.14.158.32.158.54 0 .203-.053.418-.158.646a1.82 1.82 0 0 1-.488.647c-.448.457-.905.896-1.371 1.318a12.81 12.81 0 0 1-1.437 1.107 8.053 8.053 0 0 1-1.464.765c-.492.193-.988.29-1.49.29-.711 0-1.274-.18-1.687-.54-.404-.37-.606-.941-.606-1.714 0-.405.057-.8.171-1.187.114-.387.25-.76.409-1.12.158-.37.325-.721.5-1.055.176-.343.326-.668.449-.976.044-.114.119-.29.224-.527.114-.237.233-.48.356-.725l.448-.923Z"
        />
        <path
            fill="currentColor"
            d="M51.902 11.2a73.039 73.039 0 0 0-.923 1.858c-.29.598-.562 1.152-.817 1.661-.106.211-.211.457-.316.739a2.205 2.205 0 0 0-.146.725c0 .123.027.22.08.29.061.07.158.105.29.105.184 0 .417-.052.698-.158.282-.114.585-.26.91-.435.325-.185.668-.391 1.028-.62a31.708 31.708 0 0 0 2.057-1.476c.316-.255.598-.488.844-.699a.709.709 0 0 1 .395-.145.44.44 0 0 1 .37.198.825.825 0 0 1 .144.5c0 .203-.048.41-.145.62-.096.211-.272.418-.527.62a33.667 33.667 0 0 1-1.74 1.477c-.58.465-1.16.878-1.74 1.239a9.86 9.86 0 0 1-1.701.844 4.645 4.645 0 0 1-1.635.316c-.352 0-.646-.048-.883-.145a1.496 1.496 0 0 1-.58-.37 1.539 1.539 0 0 1-.303-.566 2.582 2.582 0 0 1-.093-.699c0-.44.075-.896.224-1.37.15-.476.317-.902.501-1.28.264-.545.523-1.085.778-1.621.264-.536.519-1.05.765-1.543h-.567c-.492 0-.83-.079-1.015-.237-.185-.158-.277-.338-.277-.54a.83.83 0 0 1 .079-.317c.062-.132.145-.25.25-.356.115-.114.251-.21.41-.29.157-.08.337-.119.54-.119h1.424l2.781-5.524c.176-.334.391-.57.646-.711.264-.15.536-.225.818-.225.263 0 .505.07.725.211.22.141.33.374.33.7 0 .166-.04.337-.12.513a7.07 7.07 0 0 1-.29.593c-.078.15-.175.33-.29.541a99.53 99.53 0 0 0-.368.66c-.124.228-.255.47-.396.724-.132.255-.264.501-.396.739a642.69 642.69 0 0 1-.949 1.78h4.298c.211 0 .404.013.58.039.176.018.325.061.448.132.132.061.233.15.304.263.07.115.105.269.105.462a.843.843 0 0 1-.053.29.55.55 0 0 1-.171.264.904.904 0 0 1-.343.197c-.14.053-.325.088-.553.106l-5.485.04Z"
        />
        <path
            fill="currentColor"
            d="M60.38 10.751a.61.61 0 0 0-.12-.013h-.118c-.334 0-.646.066-.936.198-.29.132-.558.307-.804.527-.238.21-.453.453-.646.725-.185.273-.352.545-.501.817-.15.264-.277.519-.383.765a9.897 9.897 0 0 0-.224.62c.308-.158.624-.339.95-.54.333-.203.645-.396.935-.58.29-.185.541-.352.752-.502.22-.15.373-.25.461-.303.396-.246.668-.475.818-.686.15-.22.224-.408.224-.566a.405.405 0 0 0-.132-.317.5.5 0 0 0-.277-.145Zm.474-1.82c.73 0 1.274.177 1.635.528.36.352.54.84.54 1.463 0 .37-.097.756-.29 1.16-.193.405-.527.818-1.002 1.24-.475.413-1.112.84-1.912 1.279-.79.43-1.784.865-2.98 1.305a.88.88 0 0 0 .41.606c.228.141.531.211.91.211a5.34 5.34 0 0 0 1.291-.171c.466-.123.923-.277 1.371-.462.457-.184.901-.382 1.332-.593.43-.22.817-.426 1.16-.62.352-.193.642-.36.87-.5.238-.141.396-.229.475-.264a.532.532 0 0 1 .21-.092 1.51 1.51 0 0 1 .185-.014c.106 0 .194.027.264.08.07.043.127.1.171.17a.48.48 0 0 1 .106.212c.017.07.026.132.026.184 0 .08-.009.176-.026.29-.018.106-.084.229-.198.37a6.822 6.822 0 0 1-.448.356c-.246.193-.567.421-.962.685-.396.264-.857.545-1.385.844-.518.29-1.072.558-1.661.804-.58.246-1.186.448-1.82.607a7.315 7.315 0 0 1-1.871.25 3.17 3.17 0 0 1-1.411-.303 3.259 3.259 0 0 1-1.041-.818 3.69 3.69 0 0 1-.646-1.186 4.432 4.432 0 0 1-.225-1.41c0-.379.075-.792.224-1.24.15-.457.365-.914.647-1.371.28-.457.623-.901 1.028-1.332.413-.44.874-.826 1.384-1.16a7.186 7.186 0 0 1 1.674-.804 6.254 6.254 0 0 1 1.965-.303Z"
        />
    </svg>
);
