import { useQuery, useQueryClient } from '@tanstack/react-query';

import { queries } from '../../../common/api/queryKeys/queries';

export const useAgentDetails = (id) => {
    const queryClient = useQueryClient();
    const query = useQuery({ ...queries.agents.detail(id), enabled: !!id });

    const prefetch = (id) => {
        queryClient.prefetchQuery(queries.agents.detail(id));
    };

    return {
        ...query,
        prefetch,
    };
};
