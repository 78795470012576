import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateVessel } from '../../common/api/clients/vessel.api';
import { queryKeys } from '../../common/api/queryKeys';
import { queries } from '../../common/api/queryKeys/queries';

export const useBunkerPlanVesselMutation = (vesselId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateVessel,
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries({ queryKey: queries.vessels.detail(vesselId).queryKey }),
                queryClient.invalidateQueries({ queryKey: queryKeys.bunkerStops() }),
            ]);
        },
    });
};
