import { CounterOffer } from '../../../../../../types/CounterOffer';
import { CounterOfferState } from '../../../../../../types/CounterOfferState';
import { translate } from '../../../../../common/helpers/translate.helper';
import { MetaInformationCaption } from '../../OfferCard/MetaInformationCaption';

const selectDescription = (counterOffer: CounterOffer) => {
    switch (counterOffer.state) {
        case CounterOfferState.OPEN:
            return translate('counterOffer.stateDescription.counterSent');
        case CounterOfferState.EXPIRED:
            return translate('counterOffer.stateDescription.expired');
        case CounterOfferState.DECLINED:
            return translate('counterOffer.stateDescription.declined');
        case CounterOfferState.OFFER_ADJUSTED:
            return translate('counterOffer.stateDescription.offerAdjusted');
        case CounterOfferState.ACCEPTED:
        case CounterOfferState.CANCELED:
            return null;
    }
};

interface CounterOfferStateDescriptionProps {
    counterOffers: CounterOffer[];
}

export const CounterOfferStateDescription = ({ counterOffers }: CounterOfferStateDescriptionProps) => {
    if (!counterOffers.length) {
        return null;
    }

    const latestCounterOffer = counterOffers[0];
    const description = selectDescription(latestCounterOffer);

    if (description === null) {
        return null;
    }

    return <MetaInformationCaption as="p">{description}</MetaInformationCaption>;
};
