import { Modal } from '../../cdl/Modal/Modal';
import { useAngularDependencies } from '../../common/hooks/useAngularDependencies';
import { useRole } from '../../common/hooks/useRole';

import { GroupAdminModalContent } from './GroupAdminModalContent';
import { useAcceptGtc } from './useAcceptGtc';
import { UserModalContent } from './UserModalContent';

interface AcceptTermsProps {
    onClose: () => void;
}

export const AcceptTerms = (props: AcceptTermsProps) => {
    const role = useRole();
    const { SessionService, $state } = useAngularDependencies();
    const acceptGtc = useAcceptGtc();

    const logOut = () => {
        props.onClose();
        SessionService.logout();
        $state.go('login');
    };

    const onAccept = async (newsletter: boolean) => {
        acceptGtc(newsletter)
            .then(() => props.onClose())
            .catch(() => logOut());
    };

    return (
        <Modal isOpen={true} dismissible={false}>
            {role.hasAdminRights() ? (
                <GroupAdminModalContent onLogOut={logOut} onAccept={onAccept} />
            ) : (
                <UserModalContent onLogOut={logOut} />
            )}
        </Modal>
    );
};
