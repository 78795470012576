import { BunkerCaseSolutionResponse } from '../../../../../../types/BunkerCaseSolutionResponse';
import { BunkerStopsRequestMessage } from '../../../../../../types/BunkerStopsRequestMessage';
import { Button } from '../../../../../cdl/Button/Button';
import { useToasts } from '../../../../../cdl/Toast/useToasts';
import { translate } from '../../../../../common/helpers/translate.helper';
import { useNavigation } from '../../../../../common/hooks/useNavigation';
import { useBunkerStopsCreation } from '../../../../hooks/useBunkerStopsCreation';

const createBunkerStopsRequest = (solution: BunkerCaseSolutionResponse): BunkerStopsRequestMessage => {
    return {
        bunkerStops: solution.bunkerSchedules
            .filter(
                (bunkerSchedule) =>
                    bunkerSchedule.purchases.length > 0 &&
                    bunkerSchedule.purchases.some((purchase) => purchase.quantity > 0)
            )
            .map((bunkerSchedule) => {
                return {
                    customerId: solution.vessel.customerId,
                    portId: bunkerSchedule.portId,
                    vesselId: solution.vesselId,
                    eta: bunkerSchedule.eta,
                    etd: bunkerSchedule.etd,
                    items: bunkerSchedule.purchases.map((purchase) => {
                        return {
                            productGroup: purchase.productCategory,
                            sulphurContent: purchase.sulphurContent,
                            volume: purchase.quantity,
                        };
                    }),
                };
            }),
    };
};

export const CreateBunkerStopsButton = ({ solution }: { solution: BunkerCaseSolutionResponse }) => {
    const bunkerStopsCreation = useBunkerStopsCreation();
    const { addErrorToast } = useToasts();
    const { navigate } = useNavigation();

    const onCreateClick = async () => {
        await bunkerStopsCreation.mutateAsync(
            { bunkerStops: createBunkerStopsRequest(solution) },
            {
                onSuccess: (response) => {
                    const bunkerStopIds = response.data.bunkerStops.map((stop) => stop.id);
                    navigate('base.fuel-planning-bunker-plan', { bunkerStopIds });
                },
                onError: () => {
                    addErrorToast(translate('global.genericToastError'));
                },
            }
        );
    };

    return (
        <Button emphasis="high" onClick={onCreateClick} isLoading={bunkerStopsCreation.isPending}>
            {translate('prePlanning.solutionDetails.addToBunkerPlanBtn')}
        </Button>
    );
};
