import { cloneDeep, set } from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { Box } from '../../ui/Box';

import { FormContextProvider } from './FormContext';

const StyledForm = styled(Box)``;

export const Form = ({ data, children, onSubmit, onChange, isValid = (v) => v, ...rest }) => {
    const [formData, setFormData] = React.useState(cloneDeep(data));
    const formRef = React.createRef();

    useEffect(() => {
        setFormData(cloneDeep(data));
        checkValidity();
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    const submit = (event) => {
        if (onSubmit) {
            onSubmit({
                data: formData,
                event,
            });
        }
    };

    const checkValidity = useCallback(() => {
        const formRefCurrent = formRef.current;

        if (formRefCurrent && typeof isValid === 'function') {
            isValid(formRefCurrent.checkValidity());
        }
    }, [isValid, formRef]);

    const updateDataPath = (dataPath, value) => {
        const newData = set({ ...formData }, dataPath, value);
        setFormData(newData);
        return newData;
    };

    const onChangeHandler = (args) => {
        if (onChange) {
            onChange(args);
        }

        checkValidity();
    };

    useEffect(() => {
        checkValidity();
    }, [formRef.current, formData]); // eslint-disable-line react-hooks/exhaustive-deps

    const contextValue = {
        data: formData,
        updateDataPath,
        onChange: onChangeHandler,
        setFormData: (data) => {
            setFormData(data);
            onChangeHandler({ data });
        },
    };

    return (
        <FormContextProvider value={contextValue}>
            <Box height="100%" className="reset">
                <StyledForm as="form" onSubmit={submit} ref={formRef} {...rest}>
                    {children}
                </StyledForm>
            </Box>
        </FormContextProvider>
    );
};

Form.propTypes = {
    data: PropTypes.object,
    children: PropTypes.node,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    isValid: PropTypes.func,
};
