import { Text } from '../../../cdl/Text/Text';
import { Tooltip } from '../../../cdl/Tooltip/Tooltip';
import { translate } from '../../../common/helpers/translate.helper';

interface OfferCountProps {
    offerCount: number;
    totalOffers: number;
    includesSpot: boolean;
}

export const OfferCount = ({ offerCount, totalOffers, includesSpot }: OfferCountProps) => {
    if (includesSpot) {
        return (
            <Tooltip label={translate('order.detail.includingSpotTooltip')}>
                <Text color="foreground.subtle">
                    <Text color="foreground.default">{offerCount}</Text> / {totalOffers}+
                </Text>
            </Tooltip>
        );
    }

    return (
        <Text color="foreground.subtle">
            <Text color="foreground.default">{offerCount}</Text> / {totalOffers}
        </Text>
    );
};
