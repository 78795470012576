import { Form, useFormikContext } from 'formik';

import { Button } from '../../../../cdl/Button/Button';
import { FormikCheckbox } from '../../../../cdl/Checkbox/FormikCheckbox';
import { FormikInput } from '../../../../cdl/Input/FormikInput';
import { Text } from '../../../../cdl/Text/Text';
import { FormikDebug } from '../../../../common/form-elements/formik/FormikDebug';
import { FormikEnumComboBox } from '../../../../common/form-elements/formik/FormikEnumComboBox';
import { translate } from '../../../../common/helpers/translate.helper';
import { useProductContext } from '../../../../common/hooks/useProductContext';
import { useRole } from '../../../../common/hooks/useRole';
import { Box } from '../../../../common/ui/Box';

import { FormikCustomerCreateState } from './CreateCustomerCompanyForm';

export const FormikCustomerCreateForm = () => {
    const { isSubmitting } = useFormikContext<FormikCustomerCreateState>();
    const role = useRole();
    const { possibleContexts } = useProductContext();

    return (
        <Form style={{ height: '100%' }}>
            <Box display="grid" gridTemplateRows="auto 1fr auto" height="100%" gridTemplateColumns="100%">
                <Box padding={7} paddingBottom={6}>
                    <Text variant="title" marginBottom={6}>
                        {translate('customer.createtitle')}
                    </Text>
                </Box>

                <Box paddingX={7} display="flex" flexDirection="column" overflowY="auto" rowGap={5}>
                    {role.isAdmin() || possibleContexts.length > 1 ? (
                        <FormikEnumComboBox
                            name="type"
                            enumType="companyType"
                            label={translate('customer.type')}
                            markAsRequired
                        />
                    ) : null}

                    <FormikInput name="name" label={translate('company.name')} markAsRequired />
                    <FormikInput name="email" label={translate('company.email')} markAsRequired />
                    <FormikInput name="phone" label={translate('company.phone')} />
                    <FormikInput name="fax" label={translate('company.fax')} />
                    <FormikInput name="invoiceMail" label={translate('global.contact.invoicemail')} />

                    <Text variant="subtitle1" marginTop={5}>
                        {translate('company.address')}
                    </Text>

                    <FormikInput name="address.companyName" label={translate('company.name')} />
                    <Box display="grid" gridTemplateColumns="2fr 1fr" columnGap={5}>
                        <FormikInput name="address.street" label={translate('global.address.street')} />
                        <FormikInput name="address.streetNumber" label={translate('global.address.streetnumber')} />
                    </Box>
                    <Box display="grid" gridTemplateColumns="1fr 2fr" columnGap={5}>
                        <FormikInput name="address.zipcode" label={translate('global.address.zipcode')} />
                        <FormikInput name="address.city" label={translate('global.address.city')} />
                    </Box>
                    <FormikInput name="address.country" label={translate('global.address.country')} />

                    {role.isAdmin() ? (
                        <Box display="flex" flexDirection="column" rowGap={4} alignItems="flex-start">
                            <Text variant="subtitle1" marginTop={5}>
                                {translate('company.settings')}
                            </Text>
                            <FormikCheckbox name="testCompany" label={translate('company.testCompany')} />
                            <FormikCheckbox name="spotAllowed" label={translate('company.spotallowed')} />
                            <FormikCheckbox name="trial" label={translate('company.trial')} />
                        </Box>
                    ) : null}
                </Box>

                <Box padding={7}>
                    <Button emphasis="high" type="submit" size="large" isLoading={isSubmitting} width="100%">
                        {translate('btn.save.default')}
                    </Button>
                </Box>
                <FormikDebug />
            </Box>
        </Form>
    );
};
