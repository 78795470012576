import PropTypes from 'prop-types';

import { FuelContractPriceDisplay } from '../../../common/FuelPriceDisplay/FuelContractPriceDisplay';
import { FuelMarketPriceDisplay } from '../../../common/FuelPriceDisplay/FuelMarketPriceDisplay';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box.tsx';

import { Category } from './components/Category';
import { CheapestTag } from './components/CheapestTag';
import { DaysLeftSailing } from './components/DaysLeftSailing';
import { FormattedPrice } from './components/FormattedPrice';
import { FuelCategoryHeading } from './components/FuelCategoryHeading';

FormattedPrice.propTypes = {
    priceDetail: PropTypes.object,
};

export const FuelPriceCompareCategory = ({ priceData, reachInformation, hasPlattsCredentials, customerId }) => {
    const findSpecificPriceData = (key) => {
        return priceData.find((priceData) => priceData[key] === true);
    };

    const marketPrice = findSpecificPriceData('marketPrice');
    const bestContractPrice = findSpecificPriceData('bestContract');
    const viscosity = marketPrice?.viscosity;
    const isCheapestOption = !!marketPrice?.bestPriceCategory || !!bestContractPrice?.bestPriceCategory;

    return (
        <Category padding={6} isCheapest={isCheapestOption}>
            <Box marginBottom={2} display="flex" justifyContent="space-between" alignItems="baseline">
                <FuelCategoryHeading
                    productCategory={priceData[0].productCategory}
                    sulphurContent={priceData[0].sulphurContent}
                    viscosity={viscosity}
                />
                {isCheapestOption ? (
                    <CheapestTag>{translate('schedule.priceCompare.cheapestOption')}</CheapestTag>
                ) : null}
            </Box>

            <Box marginBottom={5}>
                {reachInformation !== undefined ? <DaysLeftSailing reachInformation={reachInformation} /> : <>&nbsp;</>}
            </Box>

            <Box display="flex" flexDirection="row">
                <Box width="60%">
                    <FuelMarketPriceDisplay
                        pricingCode={marketPrice?.priceCode}
                        priceDate={marketPrice?.priceDate}
                        price={marketPrice?.price}
                        hasPlattsCredentials={hasPlattsCredentials}
                        customerId={customerId}
                        positive={marketPrice?.bestPriceCategory}
                        label={translate('schedule.priceCompare.marketPrice')}
                    />
                </Box>
                <Box>
                    <FuelContractPriceDisplay
                        pricingCode={bestContractPrice?.priceCode}
                        priceDate={bestContractPrice?.priceDate}
                        price={bestContractPrice?.price}
                        contractBasePriceDifference={bestContractPrice?.contractBasePriceDifference}
                        contractBasePrice={bestContractPrice?.contractBasePrice}
                        supplierName={bestContractPrice?.supplier?.name}
                        contract={bestContractPrice?.contract}
                        marketPriceDifference={bestContractPrice?.marketPriceDifference}
                        positive={bestContractPrice?.bestPriceCategory}
                        hasPlattsCredentials={hasPlattsCredentials}
                        customerId={customerId}
                        label={translate('schedule.priceCompare.bestContract')}
                    />
                </Box>
            </Box>
        </Category>
    );
};

FuelPriceCompareCategory.propTypes = {
    priceData: PropTypes.array.isRequired,
    reachInformation: PropTypes.object,
    hasPlattsCredentials: PropTypes.bool,
    customerId: PropTypes.string.isRequired,
};
