import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { markOfferRead } from '../../../common/api/clients/order.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { translate } from '../../../common/helpers/translate.helper';

export const useOfferMarkRead = () => {
    const queryClient = useQueryClient();
    const { addToast, addErrorToast } = useToasts();

    return useMutation({
        mutationFn: markOfferRead,
        onMutate: (variables) => {
            //Normally we would change the actual query data, but because of the complex queryKey we are not able to find them here.
            variables.offer.supplierRead = variables.read;
        },
        onSuccess: (_, variables) => {
            if (variables.read) {
                addToast(translate(`order.markReadToast.success`));
            } else {
                addToast(translate(`order.markUnreadToast.success`));
            }
        },
        onError: (_, variables) => {
            if (variables.read) {
                addErrorToast(translate(`order.markReadToast.error`));
            } else {
                addErrorToast(translate(`order.markUnreadToast.error`));
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.offers() });
        },
    });
};
