import { ButtonProps } from '../Button';

export function getSpinnerSize(size: NonNullable<ButtonProps['size']>): number {
    switch (size) {
        case 'large':
            return 16;
        case 'medium':
            return 14;
        case 'small':
            return 12;
    }
}
