import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Text } from '../../../cdl/Text/Text';
import { getProductsByIds, getProductsByQuery } from '../../../common/api/clients/product.api';
import { AsyncSearchSelect } from '../../../common/form-elements/AsyncSearchSelect/AsyncSearchSelect';
import { useFormData } from '../../../common/form-elements/Form/useFormData';
import { Label } from '../../../common/form-elements/Label/Label';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';

export const Option = ({ data, innerProps, getStyles, ...props }) => {
    return (
        <Box {...innerProps} style={getStyles('option', props)}>
            <Text>{data.label}</Text>
        </Box>
    );
};

Option.propTypes = {
    data: PropTypes.object,
    innerProps: PropTypes.object,
    getStyles: PropTypes.func,
};

function ProductSelect({ productId, onChange, ...props }) {
    const [selectedValue, setSelectedValue] = useState(null);
    const [isDirty, setIsDirty] = useState(false);

    const loadProductsBySearchQuery = (searchQuery) => {
        return new Promise((resolve) => {
            getProductsByQuery(searchQuery)
                .then((response) => {
                    return response.content.map((product) => ({
                        label: product.name,
                        value: product.id,
                        product,
                    }));
                })

                .then((ports) => resolve(ports));
        });
    };

    const loadProductById = (productId) => {
        return new Promise((resolve) => {
            getProductsByIds([productId])
                .then((products) => {
                    const product = products[0];
                    return {
                        label: product.name,
                        value: product.id,
                        product,
                    };
                })

                .then((port) => resolve(port));
        });
    };

    useEffect(() => {
        let mounted = true;
        if (productId) {
            loadProductById(productId).then((result) => {
                if (mounted) {
                    setSelectedValue(result);
                }
            });
        }

        return () => (mounted = false);
    }, [productId]);

    const updateSelectedValue = (option) => {
        onChange(option);
    };

    return (
        <Label required={props.required}>
            <AsyncSearchSelect
                placeholder={translate('productSelect.placeholder')}
                values={selectedValue}
                onChange={updateSelectedValue}
                loadOptions={loadProductsBySearchQuery}
                className={isDirty ? 'dirty' : ''}
                onBlur={() => setIsDirty(true)}
                components={{
                    Option,
                }}
                {...props}
            />
        </Label>
    );
}

ProductSelect.propTypes = {
    productId: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    label: PropTypes.string,
};

export const FormProductSelect = ({ dataPath, onSelect, ...props }) => {
    const { value, onChange } = useFormData(dataPath, { default: null });

    const onChangeHandle = (option) => {
        onChange({
            target: { value: option.value },
        });
        onSelect(option);
    };

    return <ProductSelect key={value} productId={value} onChange={onChangeHandle} {...props} />;
};

FormProductSelect.propTypes = {
    dataPath: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
};
