import { useQuery } from '@tanstack/react-query';

import { getSupplierSettings } from '../../../common/api/clients/company.api';
import { queryKeys } from '../../../common/api/queryKeys';

export const useSupplierSettings = (supplierId: string) => {
    return useQuery({
        queryKey: queryKeys.supplierSettings(supplierId),
        queryFn: () => getSupplierSettings(supplierId),
    });
};
