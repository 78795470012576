import { animated, config, useTransition } from '@react-spring/web';
import styled from 'styled-components';

import { IconCheck } from '../../common/icons/cdl/Check';
import { Spinner } from '../../common/Spinner/Spinner';
import { get } from '../../common/ui/get';
import { Text } from '../Text/Text';

const ToastContentWrapper = styled.div<Pick<ToastProps, 'isError'>>`
    display: flex;
    align-items: center;
    gap: ${get('space.4')}px;
    background-color: ${(props) =>
        props.isError ? get('colors.negative.emphasis') : get('colors.background.emphasis')};
    color: ${get('colors.foreground.onEmphasis')};
    border-radius: ${get('radii.1')}px;
    padding: ${get('space.4')}px ${get('space.5')}px;
`;

interface ToastProps {
    isError?: boolean;
    isLoading?: boolean;
    isSuccess?: boolean;
    isVisible: boolean;
    children: string;
}

export const Toast = ({ isVisible, isError, isLoading, isSuccess, children }: ToastProps) => {
    const transitions = useTransition(isVisible, {
        from: { y: +50, opacity: 0 },
        enter: { y: 0, opacity: 1 },
        leave: { opacity: 0 },
        config: {
            ...config.default,
            tension: 300,
            clamp: true,
        },
    });

    return transitions((style, show) => {
        return show ? (
            <animated.div style={{ ...style }}>
                <ToastContentWrapper isError={isError}>
                    {isLoading ? <Spinner color="#ffffff" size={18} strokeWidth={4} /> : null}
                    {isSuccess ? <IconCheck width={20} height={20} color="#ffffff" /> : null}
                    <Text variant="small">{children}</Text>
                </ToastContentWrapper>
            </animated.div>
        ) : null;
    });
};
