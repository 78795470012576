import { useState } from 'react';

import { Button } from '../../../../../../cdl/Button/Button';
import { Checkbox } from '../../../../../../cdl/Checkbox/Checkbox';
import { Link } from '../../../../../../cdl/Link/Link';
import { Modal } from '../../../../../../cdl/Modal/Modal';
import { Text } from '../../../../../../cdl/Text/Text';
import { DataSheet } from '../../../../../../common/DataSheet/DataSheet';
import { formatDateDelivery } from '../../../../../../common/helpers/formatDateDelivery.helper';
import { formatLubesTotalPrice } from '../../../../../../common/helpers/formatLubesTotal.helper';
import { formatPort } from '../../../../../../common/helpers/formatPort.helper';
import { translate } from '../../../../../../common/helpers/translate.helper';
import { Characters } from '../../../../../../common/helpers/unicode.helper';
import { Box } from '../../../../../../common/ui/Box';
import { useOfferCardContext } from '../hooks/useOfferCardContext';

interface ConfirmOrderModalProps {
    isOpen: boolean;
    onDismiss: () => void;
    onConfirm: () => void;
    isLoading: boolean;
}

export const ConfirmOrderModal = (props: ConfirmOrderModalProps) => {
    const [isChecked, setIsChecked] = useState(false);
    const { offer } = useOfferCardContext();

    const showGtc = offer.isSpot() || offer.supplier.showGtc;

    const onCancel = () => {
        props.onDismiss();
        setIsChecked(false);
    };

    return (
        <Modal isOpen={props.isOpen} onDismiss={onCancel} scrollable={false}>
            <Box padding={6} display="grid" rowGap={5}>
                <Text variant="title">{translate('order.detail.orderModal.offerAcceptance')}</Text>

                <DataSheet>
                    <DataSheet.Label>{translate('offer.supplier')}</DataSheet.Label>
                    <Text>{offer.supplier.name}</Text>

                    <DataSheet.Label>{translate('global.coreData.vessel')}</DataSheet.Label>
                    <Text>{offer.vessel.name}</Text>

                    <DataSheet.Label>{translate('global.coreData.port')}</DataSheet.Label>
                    <Text>{formatPort({ port: offer.port })}</Text>

                    <DataSheet.Label>{translate('global.coreData.estimatedDelivery')}</DataSheet.Label>
                    <Text>
                        {formatDateDelivery({
                            date: offer.dateDelivery,
                        })}
                    </Text>

                    <DataSheet.Label>{translate('order.detail.orderModal.total')}</DataSheet.Label>
                    <Text>{formatLubesTotalPrice(offer.total)}</Text>
                </DataSheet>

                {showGtc ? (
                    <Box>
                        <Text variant="small" color="foreground.muted">
                            {translate('order.detail.orderModal.termsAndConditions1')}
                        </Text>
                        {Characters.NO_BREAK_SPACE}
                        <Link href={offer.supplier.supplierGroup.gtcUrl} target="_blank" size="small">
                            {translate('order.detail.orderModal.termsAndConditionsLink')}
                        </Link>
                        {Characters.NO_BREAK_SPACE}
                        <Text variant="small" color="foreground.muted">
                            {translate('order.detail.orderModal.termsAndConditions2', {
                                supplier: offer.supplier.name,
                            })}
                        </Text>
                    </Box>
                ) : null}

                <Text variant="small" color="foreground.muted">
                    {translate('order.detail.orderModal.onAcceptance')}
                </Text>

                {showGtc ? (
                    <Checkbox
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                        label={translate('order.detail.orderModal.acceptLabel')}
                    />
                ) : null}

                <Box display="flex" justifyContent="end" columnGap={4} marginTop={4}>
                    <Button onClick={onCancel} disabled={props.isLoading}>
                        {translate('btn.cancel')}
                    </Button>
                    <Button
                        emphasis="high"
                        isLoading={props.isLoading}
                        onClick={props.onConfirm}
                        disabled={showGtc ? !isChecked : false}
                    >
                        {translate('order.detail.orderModal.placeOrder')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
