import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createSupplier } from '../../../../common/api/clients/company.api';
import { queries } from '../../../../common/api/queryKeys/queries';
import { useAngularDependencies } from '../../../../common/hooks/useAngularDependencies';

export const useSupplierCreate = () => {
    const queryClient = useQueryClient();
    const { SessionService } = useAngularDependencies();

    return useMutation({
        mutationFn: createSupplier,
        onSuccess: async () => {
            await SessionService.renewSession();
            return queryClient.invalidateQueries({ queryKey: queries.suppliers._def });
        },
    });
};
