import { ProductContext } from '../../types/ProductContext';
import { getOrders } from '../common/api/clients/gateway.api';
import { queryKeys } from '../common/api/queryKeys';
import { usePaginationQuery } from '../common/hooks/usePaginationQuery';

import { OrderModel } from './model/OrderModel';

interface useOrderPaginationProps {
    page?: number;
    searchQuery?: string;
    customerIds?: string[];
    supplierIds?: string[];
    offerStates?: string[];
    types?: ProductContext[];
    states?: string[];
    sortField?: string;
    sortDirection?: string;
}

export const useOrderPagination = ({
    page = 0,
    searchQuery,
    customerIds,
    supplierIds,
    offerStates,
    types,
    states,
    sortField,
    sortDirection,
}: useOrderPaginationProps) => {
    return usePaginationQuery<OrderModel>({
        queryKey: queryKeys.orders({
            page,
            searchQuery,
            customerIds,
            supplierIds,
            offerStates,
            types,
            states,
            sortField,
            sortDirection,
        }),
        queryFn: () =>
            getOrders({
                page,
                searchQuery,
                customerIds,
                supplierIds,
                offerStates,
                types,
                states,
                sortField,
                sortDirection,
            }),
        prefetchQueryKey: queryKeys.orders({
            page: page + 1,
            searchQuery,
            customerIds,
            supplierIds,
            offerStates,
            types,
            states,
            sortField,
            sortDirection,
        }),
        prefetchQueryFn: () =>
            getOrders({
                page: page + 1,
                searchQuery,
                customerIds,
                supplierIds,
                offerStates,
                types,
                states,
                sortField,
                sortDirection,
            }),
    });
};
