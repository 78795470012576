import PropTypes from 'prop-types';

import { Button } from '../../../common/buttons/Button';
import { Error } from '../../../common/Error/Error';
import { LogoHeader } from '../../../common/headers/LogoHeader';
import { translate } from '../../../common/helpers/translate.helper';
import { useAngularRouterLocation } from '../../../common/hooks/useAngularRouterLocation';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useNavigation } from '../../../common/hooks/useNavigation';
import { IconX } from '../../../common/icons/cdl/X';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { createOverlay } from '../../../common/Overlay/Overlay';
import { Section } from '../../../common/Section/Section';
import { Box } from '../../../common/ui/Box';
import { useOrderView } from '../hooks/useOrderView';

import { OrderEditFormikWrapper } from './OrderEditFormikWrapper';

const PageWithHeader = ({ children }) => {
    const { id } = useAngularRouterLocation();
    const { navigate } = useNavigation();

    const onCancelClick = () => {
        navigate('base.fuel-orderlist-order', { id });
    };

    return (
        <Box minHeight="100%" backgroundColor="background.subtle">
            <LogoHeader
                title={translate('order.edit.fuel.headline')}
                rightElement={
                    <Button color="outline-dark" onClick={onCancelClick}>
                        <IconX style={{ marginTop: '2px' }} />
                    </Button>
                }
            />

            {children}
        </Box>
    );
};

PageWithHeader.propTypes = {
    children: PropTypes.node,
};

const FuelOrderEdit = () => {
    const { id } = useAngularRouterLocation();
    const orderQuery = useOrderView(id);

    const documentTitle = orderQuery.data
        ? translate('page.fuelOrderEdit', {
              vesselName: orderQuery.data.vessel.name,
              portName: orderQuery.data.port.name,
          })
        : null;

    useDocumentTitle(documentTitle);

    if (orderQuery.isError) {
        return (
            <PageWithHeader>
                <Box padding={6}>
                    <Section>
                        <Error />
                    </Section>
                </Box>
            </PageWithHeader>
        );
    }

    if (orderQuery.isPending) {
        return (
            <PageWithHeader>
                <LoadingIndicator variant="page" />
            </PageWithHeader>
        );
    }

    return (
        <PageWithHeader>
            <OrderEditFormikWrapper data={orderQuery.data} />
        </PageWithHeader>
    );
};

export const FuelOrderEditFlow = () => {
    return createOverlay(<FuelOrderEdit />);
};
