import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconCircle4 = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11.29-4.957A1 1 0 0 1 14 8v5a1 1 0 1 1 0 2v1a1 1 0 1 1-2 0v-1H9a1 1 0 0 1-.832-1.555l4-6a1 1 0 0 1 1.122-.402ZM12 13v-1.697L10.868 13H12Z"
            clipRule="evenodd"
        />
    </svg>
);
