import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { calculatePackagePrices, PackagePriceRequestMessage } from '../../../../common/api/clients/packagePrice.api';
import { queries } from '../../../../common/api/queryKeys/queries';

export interface PackageItemRequest {
    productId: string;
    packType: string;
    volume?: number;
}

export function usePackagePrices(
    portId?: string,
    customerId?: string,
    supplierGroupIds?: string[],
    items?: PackageItemRequest[]
) {
    const requestData: PackagePriceRequestMessage[] = (supplierGroupIds || []).flatMap((supplierGroupId) => {
        return (items || []).map((item) => {
            return {
                portId: portId!!,
                customerId: customerId!!,
                supplierGroupId,
                packType: item.packType,
                productId: item.productId,
            };
        });
    });

    return useQuery({
        queryKey: queries.packagePrices.prices(customerId || '', requestData).queryKey,
        queryFn: () => calculatePackagePrices(customerId!!, requestData),
        enabled:
            !!portId &&
            !!customerId &&
            !!supplierGroupIds &&
            items?.every((it) => !!it.volume) &&
            requestData.length > 0,
        gcTime: 5000,
        placeholderData: keepPreviousData,
    });
}
