import { useMutation } from '@tanstack/react-query';

import { BunkerCaseSolutionResponse } from '../../../../types/BunkerCaseSolutionResponse';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { formatFuelMoney } from '../../../common/helpers/formatFuelMoney.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useFuelVolumeFormat } from '../../../common/hooks/useFuelVolumeFormat';
import { downloadCsv } from '../../utils/dowloadCsv';
import { generateFileDate } from '../../utils/generateFileDate';
import { buildEntriesForBunkerSchedule } from '../components/BunkerSolutionDetails/components/BunkerSolutionStopTable/util/buildEntriesForBunkerSchedule';
import { findIncludedProductCategories } from '../components/BunkerSolutionDetails/components/BunkerSolutionStopTable/util/findIncludedProductCategories';
import { getBunkerModeText } from '../components/BunkerSolutionDetails/util/getBunkerModeText';

const useRowGeneration = () => {
    const formatVolume = useFuelVolumeFormat(false);

    return (solution: BunkerCaseSolutionResponse): Map<string, string>[] => {
        const rows: Map<string, string>[] = [];

        solution.bunkerSchedules.forEach((bunkerSchedule) => {
            const includedProductCategories = findIncludedProductCategories(bunkerSchedule);
            const entries = buildEntriesForBunkerSchedule(bunkerSchedule, includedProductCategories);

            const row: Map<string, string> = new Map();

            row.set(translate('prePlanning.solutionDetails.csvHeaders.port'), bunkerSchedule.port.name);
            row.set(translate('prePlanning.solutionDetails.csvHeaders.mode'), getBunkerModeText(bunkerSchedule.mode));
            row.set(
                translate('prePlanning.solutionDetails.csvHeaders.eta'),
                formatDateTime({
                    date: bunkerSchedule.eta,
                    format: 'MMM D, h:mm A',
                })
            );
            row.set(
                translate('prePlanning.solutionDetails.csvHeaders.etd'),
                formatDateTime({
                    date: bunkerSchedule.etd,
                    format: 'MMM D, h:mm A',
                })
            );

            entries
                .filter((entry) => !entry.isAdditional)
                .forEach((entry) => {
                    row.set(
                        `${entry.name} - ${translate('prePlanning.solutionDetails.csvHeaders.quantityStart')}`,
                        !entry.isAdditional && entry.quantityStart?.value
                            ? formatVolume({ volume: entry.quantityStart.value })
                            : '-'
                    );
                    row.set(
                        `${entry.name} - ${translate('prePlanning.solutionDetails.csvHeaders.consumption')}`,
                        !entry.isAdditional && entry.consumption?.value
                            ? formatVolume({ volume: entry.consumption.value })
                            : '-'
                    );

                    row.set(
                        `${entry.name} - ${translate('prePlanning.solutionDetails.csvHeaders.purchaseQuantity')}`,
                        !entry.isAdditional && entry.purchaseQuantity?.value
                            ? formatVolume({ volume: entry.purchaseQuantity.value })
                            : '-'
                    );
                    row.set(
                        `${entry.name} - ${translate('prePlanning.solutionDetails.csvHeaders.price')}`,
                        entry.isAdditional || entry.price?.value === undefined || entry.price?.value?.value <= 0
                            ? '-'
                            : formatFuelMoney(entry.price.value, false)
                    );
                });

            row.set(
                translate('prePlanning.solutionDetails.csvHeaders.portCosts'),
                bunkerSchedule.bunkerScheduleCosts.canalCost
                    ? formatFuelMoney(bunkerSchedule.bunkerScheduleCosts.canalCost, false)
                    : '-'
            );

            row.set(
                translate('prePlanning.solutionDetails.csvHeaders.bargeCosts'),
                bunkerSchedule.bunkerScheduleCosts.bargeCost
                    ? formatFuelMoney(bunkerSchedule.bunkerScheduleCosts.bargeCost, false)
                    : '-'
            );

            row.set(
                translate('prePlanning.solutionDetails.csvHeaders.canalCosts'),
                bunkerSchedule.bunkerScheduleCosts.canalCost
                    ? formatFuelMoney(bunkerSchedule.bunkerScheduleCosts.canalCost, false)
                    : '-'
            );
            row.set(
                translate('prePlanning.solutionDetails.csvHeaders.tcePenaltyCosts'),
                bunkerSchedule.bunkerScheduleCosts.tcePenaltyCost
                    ? formatFuelMoney(bunkerSchedule.bunkerScheduleCosts.tcePenaltyCost, false)
                    : '-'
            );

            rows.push(row);
        });

        return rows;
    };
};

const generateFilename = (solution: BunkerCaseSolutionResponse): string => {
    const vesselName = solution.vessel.name;
    const rank = solution.solutionNumber;
    const formattedDate = generateFileDate();

    return `${vesselName}_recommendation_${rank}_${formattedDate}.csv`;
};

export const useSolutionCsvDownload = (solution: BunkerCaseSolutionResponse) => {
    const generateRows = useRowGeneration();
    const rows = generateRows(solution);
    const filename = generateFilename(solution);

    return useMutation({
        mutationFn: () =>
            downloadCsv({
                rows,
                filename,
            }),
    });
};
