import { DPDay, DPPropGetter } from '@rehookify/datepicker';
import styled, { css } from 'styled-components';

import { get } from '../../../common/ui/get';

const notInMonthStyles = css`
    color: ${get('colors.foreground.disabled')};
    background-color: white;
    font-weight: 400;
`;

const todayStyles = css`
    color: ${get('colors.foreground.onEmphasis')};
    background-color: ${get('colors.background.emphasis')};
    font-weight: 600;
`;

const inRangeStyles = css`
    color: ${get('colors.accent.foreground')};
    background-color: ${get('colors.accent.background')};
    font-weight: 600;
    border-radius: 0;
`;

const rangeStartStyles = css`
    color: ${get('colors.foreground.onEmphasis')};
    background-color: ${get('colors.accent.emphasis')};
    border-radius: 4px 0 0 4px;
    font-weight: 600;
`;

const rangeEndStyles = css`
    color: ${get('colors.foreground.onEmphasis')};
    background-color: ${get('colors.accent.emphasis')};
    border-radius: 0 4px 4px 0;
    font-weight: 600;
`;

const rangeSameDayStyles = css`
    color: ${get('colors.foreground.onEmphasis')};
    background-color: ${get('colors.accent.emphasis')};
    border-radius: 4px;
    font-weight: 600;
`;

const inRangeFirstDayNotInMonthStyles = css`
    background: linear-gradient(90deg, ${get('colors.accent.background')} 0%, rgba(255, 255, 255, 0) 100%);
`;

const inRangeLastDayNotInMonthStyles = css`
    background: linear-gradient(270deg, ${get('colors.accent.background')} 0%, rgba(255, 255, 255, 0) 100%);
`;

function isFirstDayOfOtherMonthInRange(day: DPDay): boolean {
    return (
        (day.range === 'in-range' ||
            day.range === 'will-be-in-range' ||
            day.range === 'range-end' ||
            day.range === 'will-be-range-end') &&
        day.$date.getDate() === 1 &&
        !day.inCurrentMonth
    );
}

function isLastDayOfMonth(dt: Date): boolean {
    return new Date(dt.getTime() + 86400000).getDate() === 1;
}

function isLastDayOfOtherMonthInRange(day: DPDay): boolean {
    return (
        (day.range === 'in-range' ||
            day.range === 'will-be-in-range' ||
            day.range === 'range-start' ||
            day.range === 'will-be-range-start') &&
        isLastDayOfMonth(day.$date) &&
        !day.inCurrentMonth
    );
}

interface DayButtonProps extends DPPropGetter {
    children: string;
    day: DPDay;
}

export const DayButton = styled.button<DayButtonProps>`
    width: 40px;
    height: 40px;
    padding: 8px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;

    border-radius: ${get('radii.1')}px;
    border: none;

    ${(props) => (props.day.selected ? rangeSameDayStyles : '')}
    ${(props) => (props.day.range === 'in-range' || props.day.range === 'will-be-in-range' ? inRangeStyles : '')}
    ${(props) =>
        props.day.range === 'range-start' || props.day.range === 'will-be-range-start' ? rangeStartStyles : ''}
    ${(props) => (props.day.range === 'range-end' || props.day.range === 'will-be-range-end' ? rangeEndStyles : '')}
    ${(props) => (props.day.range === 'range-start range-end' ? rangeSameDayStyles : '')}
    ${(props) => (!props.day.inCurrentMonth ? notInMonthStyles : '')}
    ${(props) =>
        props.day.now &&
        props.day.inCurrentMonth &&
        !props.day.selected &&
        props.day.range !== 'range-start' &&
        props.day.range !== 'range-end' &&
        props.day.range !== 'range-start range-end' &&
        props.day.range !== 'will-be-range-start' &&
        props.day.range !== 'will-be-range-end'
            ? todayStyles
            : ''}
    ${(props) => (isFirstDayOfOtherMonthInRange(props.day) ? inRangeFirstDayNotInMonthStyles : '')}
    ${(props) => (isLastDayOfOtherMonthInRange(props.day) ? inRangeLastDayNotInMonthStyles : '')}
    &:hover {
        ${(props) =>
            (!props.day.selected &&
                !props.day.now &&
                props.day.range !== 'in-range' &&
                props.day.range !== 'range-start' &&
                props.day.range !== 'range-end' &&
                props.day.range !== 'range-start range-end' &&
                props.day.range !== 'will-be-range-start' &&
                props.day.range !== 'will-be-range-end') ||
            !props.day.inCurrentMonth
                ? css`
                      background-color: ${get('colors.background.inset')};
                      border-radius: ${get('radii.1')}px;
                  `
                : ''}
    }
`;
