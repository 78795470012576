import { ApprovalSettings } from '../../../../../../types/CustomerSettingsMessage';
import { formatMoney } from '../../../../../common/helpers/formatMoney.helper';
import { translate } from '../../../../../common/helpers/translate.helper';
import { getAdditionalApproverCount } from '../util/getAdditionalApproverCount';

interface MultipleApproverSettingsSummaryProps {
    approvalSettings: ApprovalSettings;
}

const PreventMultipleApprovers = ({ approvalSettings }: MultipleApproverSettingsSummaryProps) => {
    if (!approvalSettings.preventMultipleApprovers) {
        return null;
    }

    return <div>{translate('multipleApproverSettings.settingsSummary.singleApproverPerRequest')}</div>;
};

const MinimumApprovalThreshold = ({ approvalSettings }: MultipleApproverSettingsSummaryProps) => {
    const minimumThresholdValue = approvalSettings.minimumThreshold?.value;
    const minimumThresholdCurrency = approvalSettings.minimumThreshold?.currency;

    if (!minimumThresholdValue || !minimumThresholdCurrency) {
        return null;
    }

    return (
        <div>
            {translate('multipleApproverSettings.settingsSummary.upToAmountWithoutApproval', {
                minimumThresholdAmount: formatMoney({
                    value: minimumThresholdValue,
                    currency: minimumThresholdCurrency,
                }),
            })}
        </div>
    );
};

const TopLevelApprover = ({ approvalSettings }: MultipleApproverSettingsSummaryProps) => {
    if (!approvalSettings.defaultControllerEmailAddress) {
        return null;
    }

    return (
        <div>
            {translate('multipleApproverSettings.settingsSummary.topLevelApproverSpecifiedWithEmail', {
                emailAddress: approvalSettings.defaultControllerEmailAddress,
            })}
        </div>
    );
};

const ApprovalLimits = ({ approvalSettings }: MultipleApproverSettingsSummaryProps) => {
    const approvalLimitCount = approvalSettings.additionalApprovers?.length;

    if (approvalLimitCount === 0 || approvalLimitCount === undefined) {
        return null;
    }

    if (approvalLimitCount === 1) {
        return (
            <div>
                {translate('multipleApproverSettings.settingsSummary.singleApprovalLimitSpecified', {
                    approvalLimitCount: approvalLimitCount.toString(),
                })}
            </div>
        );
    }

    return (
        <div>
            {translate('multipleApproverSettings.settingsSummary.multipleApprovalLimitsSpecified', {
                approvalLimitCount: approvalLimitCount.toString(),
            })}
        </div>
    );
};

const AdditionalApprovers = ({ approvalSettings }: MultipleApproverSettingsSummaryProps) => {
    const additionalApproverCount = getAdditionalApproverCount(approvalSettings);

    if (additionalApproverCount === 0) {
        return null;
    }

    if (additionalApproverCount === 1) {
        return (
            <div>
                {translate('multipleApproverSettings.settingsSummary.amountSingleAdditionalApprover', {
                    additionalApproverCount: additionalApproverCount.toString(),
                })}
            </div>
        );
    }

    return (
        <div>
            {translate('multipleApproverSettings.settingsSummary.amountMultipleAdditionalApprover', {
                additionalApproverCount: additionalApproverCount.toString(),
            })}
        </div>
    );
};

export const MultipleApproverSettingsSummary = ({ approvalSettings }: MultipleApproverSettingsSummaryProps) => {
    return (
        <div>
            <TopLevelApprover approvalSettings={approvalSettings} />
            <ApprovalLimits approvalSettings={approvalSettings} />
            <AdditionalApprovers approvalSettings={approvalSettings} />
            <PreventMultipleApprovers approvalSettings={approvalSettings} />
            <MinimumApprovalThreshold approvalSettings={approvalSettings} />
        </div>
    );
};
