import styled from 'styled-components';

import { Box } from '../../../../common/ui/Box';
import { get } from '../../../../common/ui/get';

interface CategoryProps {
    isCheapest: boolean;
}

export const Category = styled(Box)<CategoryProps>`
    position: relative;
    padding: ${get('space.5')}px;

    background: ${(props) =>
        props.isCheapest
            ? 'linear-gradient(212.33deg, rgba(0, 204, 105, 0.2) 0%, rgba(255, 255, 255, 0) 40%)'
            : 'linear-gradient(0deg, rgba(1, 1, 1, 0))'};

    &:not(:last-child):after {
        content: '';
        position: absolute;

        bottom: 0;

        left: 16px;
        right: 16px;

        border-bottom: 1px solid ${get('colors.border.muted')};
    }
`;
