import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useAngularDependencies } from '../hooks/useAngularDependencies';
import { useRole } from '../hooks/useRole';

import { Link } from './Link';

export const NavigationLink = ({ to, children, className = '' }) => {
    const { $state, $rootScope } = useAngularDependencies();
    const [currentState, setCurrentState] = useState($state.current);
    const role = useRole();

    const onStateChangeSuccess = (event, toState) => setCurrentState(toState);

    useEffect(() => {
        const deregister = $rootScope.$on('$stateChangeSuccess', onStateChangeSuccess);

        return () => deregister();
    }, [$rootScope]);

    const isRouteInCategory = () => {
        // In case of the user is customer, all the offerlist subroutes are
        // handled like orderlist subroutes, because offers and all subroutes
        // of them are part of the Orders category in this case.
        if (role.isCustomer() && currentState.name.includes('base.offerlist')) {
            return to.includes('base.orderlist');
        }

        if (role.isCustomer() && currentState.name.includes('base.fuel-offerlist')) {
            return to.includes('base.fuel-orderlist');
        }

        return currentState.name.includes(to);
    };

    return (
        <Link
            onClick={(event) => event.target.blur()}
            className={[className, isRouteInCategory() ? 'active' : null].filter(Boolean).join(' ')}
            to={to}
        >
            {children}
        </Link>
    );
};

NavigationLink.propTypes = {
    to: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};
