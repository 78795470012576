import styled from 'styled-components';

import { get } from '../../../common/ui/get';

interface StyledInlineTableBodyRowProps {
    disabled?: boolean;
}

export const StyledInlineTableBodyRow = styled.tr<StyledInlineTableBodyRowProps>`
    color: ${(props) => (props.disabled ? get('colors.foreground.disabled') : get('colors.foreground.default'))};

    &:hover {
        background-color: ${get('colors.background.subtle')};
    }

    td:first-child {
        border-top-left-radius: ${get('radii.1')}px;
        border-bottom-left-radius: ${get('radii.1')}px;
    }

    td:last-child {
        border-top-right-radius: ${get('radii.1')}px;
        border-bottom-right-radius: ${get('radii.1')}px;
    }
`;
