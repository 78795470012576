import { SupplierSettingsMessage } from '../../../../types/SupplierSettingsMessage';
import { Switch } from '../../../cdl/Switch/Switch';
import { updateSupplierSettings } from '../../../common/api/clients/company.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { translate } from '../../../common/helpers/translate.helper';
import { SettingsSection } from '../../SettingsSection';
import { useOptimisticSettingsEdit } from '../../useOptimisticSettingsEdit';

interface SupplierNotificationSettingsProps {
    supplierSettings: SupplierSettingsMessage;
    supplierId: string;
}

export const SupplierNotificationSettings = ({ supplierSettings, supplierId }: SupplierNotificationSettingsProps) => {
    const editCustomerSettingsMutation = useOptimisticSettingsEdit({
        updateFunction: updateSupplierSettings,
        queryKey: queryKeys.supplierSettings(supplierId),
    });

    const updateEmailSettings = (newSettings: Partial<SupplierSettingsMessage['emailCategorySettings']>) => {
        // @ts-ignore
        editCustomerSettingsMutation.mutate({
            id: supplierId,
            settings: {
                ...supplierSettings,
                emailCategorySettings: {
                    ...supplierSettings.emailCategorySettings,
                    ...newSettings,
                },
            },
        });
    };

    const emailCategorySettings = supplierSettings.emailCategorySettings;

    return (
        <SettingsSection label={translate('company.notificationSettings.emailNotification')}>
            <Switch
                checked={emailCategorySettings.offerStateChange}
                onChange={(checked) =>
                    updateEmailSettings({
                        offerStateChange: checked,
                    })
                }
                label={translate('company.notificationSettings.statusUpdate')}
                description={translate('company.notificationSettings.statusUpdateDescription')}
            />
            <Switch
                checked={emailCategorySettings.offerUpdate}
                onChange={(checked) =>
                    updateEmailSettings({
                        offerUpdate: checked,
                    })
                }
                label={translate('company.notificationSettings.newDetails')}
                description={translate('company.notificationSettings.newDetailsDescription')}
            />
            <Switch
                checked={emailCategorySettings.newChatMessage}
                onChange={(checked) =>
                    updateEmailSettings({
                        newChatMessage: checked,
                    })
                }
                label={translate('company.notificationSettings.newChatMessages')}
            />
            <Switch
                checked={emailCategorySettings.systemReminder}
                onChange={(checked) =>
                    updateEmailSettings({
                        systemReminder: checked,
                    })
                }
                label={translate('company.notificationSettings.systemReminders')}
            />
        </SettingsSection>
    );
};
