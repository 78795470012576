import { FieldArray, useFormikContext } from 'formik';
import { useEffect } from 'react';

import { FormikInput } from '../../../../../cdl/Input/FormikInput';
import { translate } from '../../../../../common/helpers/translate.helper';
import { FormikMoneyInput } from '../../../../../common/MoneyInput/FormikMoneyInput';
import { Box } from '../../../../../common/ui/Box';
import { FormikApprovalSettingsState } from '../MultipleApproversSettingsForm';

import { DeletableComponent } from './DeletableComponent';

interface FormikApprovalLimitWithApproversProps {
    index: number;
    onDeleteClick: () => void;
}

export const FormikSingleApprovalLimitWithApprovers = ({
    index,
    onDeleteClick,
}: FormikApprovalLimitWithApproversProps) => {
    const { values, setFieldValue } = useFormikContext<FormikApprovalSettingsState>();
    const approvers = values.additionalApprovers[index].approvers;

    useEffect(() => {
        // unfortunately this setTimeout is necessary to work with Formik
        setTimeout(() => {
            if (approvers.length > 0) {
                const lastItem = approvers[approvers.length - 1];

                if (lastItem.emailAddress.length > 0) {
                    const newValues = [...approvers, { emailAddress: '' }];

                    setFieldValue(`additionalApprovers.${index}.approvers`, newValues);
                }

                if (approvers.length > 1) {
                    const secondLastItem = approvers[approvers.length - 2];

                    if (secondLastItem.emailAddress.length === 0) {
                        const newValues = approvers.slice(0, approvers.length - 1);

                        setFieldValue(`additionalApprovers.${index}.approvers`, newValues);
                    }
                }
            } else {
                setFieldValue(`additionalApprovers.${index}.approvers`, [{ emailAddress: '' }]);
            }
        }, 0);
    }, [approvers, index, setFieldValue]);

    return (
        <Box>
            <DeletableComponent
                onDeleteClick={onDeleteClick}
                isDeletable={values.additionalApprovers[index].approvalLimit.length > 0}
            >
                <FormikMoneyInput
                    name={`additionalApprovers.${index}.approvalLimit`}
                    label={translate('multipleApproverSettings.approvalLimit')}
                    placeholder={translate('multipleApproverSettings.approvalLimitPlaceholder')}
                />
            </DeletableComponent>
            <FieldArray
                name={`additionalApprovers.${index}.approvers`}
                render={(arrayHelpers) => (
                    <Box marginTop={5} display="flex" flexDirection="column" style={{ rowGap: '16px' }}>
                        {values.additionalApprovers[index].approvers.map((approver, approverIndex) => (
                            <div key={approverIndex}>
                                <DeletableComponent
                                    isDeletable={approver.emailAddress.length > 0}
                                    onDeleteClick={() => {
                                        arrayHelpers.remove(approverIndex);
                                    }}
                                >
                                    <FormikInput
                                        name={`additionalApprovers.${index}.approvers.${approverIndex}.emailAddress`}
                                        placeholder={translate('multipleApproverSettings.emailAddressPlaceholder')}
                                        label={translate('multipleApproverSettings.emailAddress')}
                                        autoComplete="off"
                                    />
                                </DeletableComponent>
                            </div>
                        ))}
                    </Box>
                )}
            />
        </Box>
    );
};
