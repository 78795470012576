import { CustomerSettingsMessage } from '../../../../types/CustomerSettingsMessage';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { Switch } from '../../../cdl/Switch/Switch';
import { updateCustomerSettings } from '../../../common/api/clients/companySettings.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { TextButton } from '../../../common/buttons/TextButton';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';
import { SettingsSection } from '../../SettingsSection';
import { useOptimisticSettingsEdit } from '../../useOptimisticSettingsEdit';

import { MultipleApproverSettingsSummary } from './MultipleApproversSettingsForm/components/MultipleApproverSettingsSummary';
import { MultipleApproversSettingsForm } from './MultipleApproversSettingsForm/MultipleApproversSettingsForm';
import { useCustomerSettingsEdit } from './useCustomerSettingsEdit';

interface LubesApprovalSettingsProps {
    customerSettings: CustomerSettingsMessage;
    customerId: string;
}

export const LubesApprovalSettings = (props: LubesApprovalSettingsProps) => {
    const { push, pop } = useDrawer();

    const updateCustomerSettingsMutation = useCustomerSettingsEdit();

    const editCustomerSettingsMutation = useOptimisticSettingsEdit({
        updateFunction: updateCustomerSettings,
        queryKey: queryKeys.customerSettings(props.customerId),
    });

    const onSubmit = (customerSettings: CustomerSettingsMessage) => {
        updateCustomerSettingsMutation.mutate(
            { id: props.customerId, settings: customerSettings },
            { onSuccess: () => pop() }
        );
    };

    const openApprovalSettingsModal = () => {
        push({
            content: <MultipleApproversSettingsForm customerSettings={props.customerSettings} onSubmit={onSubmit} />,
        });
    };

    const handleApprovalSettingsToggle = (checked: boolean) => {
        if (!props.customerSettings.approvalSettings.defaultControllerEmailAddress) {
            openApprovalSettingsModal();
        } else {
            const newCustomerSettings = props.customerSettings;
            newCustomerSettings.approvalSettings.activated = checked;
            updateCustomerSettingsMutation.mutate({
                id: props.customerId,
                settings: newCustomerSettings,
            });
        }
    };

    const updateSettings = (newSettings: Partial<CustomerSettingsMessage>) => {
        // @ts-ignore
        editCustomerSettingsMutation.mutate({
            id: props.customerId,
            settings: {
                ...props.customerSettings,
                ...newSettings,
            },
        });
    };

    return (
        <SettingsSection label={translate('approvalSettings.order')}>
            <Switch
                checked={props.customerSettings.approvalSettings.activated}
                onChange={handleApprovalSettingsToggle}
                label={translate('approvalSettings.activatedLabel')}
                description={
                    <div>
                        {props.customerSettings.approvalSettings.activated ? (
                            <MultipleApproverSettingsSummary
                                approvalSettings={props.customerSettings.approvalSettings}
                            />
                        ) : null}
                        {props.customerSettings.approvalSettings.defaultControllerEmailAddress ? (
                            <Box marginTop={props.customerSettings.approvalSettings.activated ? 4 : 0}>
                                <TextButton onClick={openApprovalSettingsModal}>
                                    {translate('multipleApproverSettings.editSettings')}
                                </TextButton>
                            </Box>
                        ) : null}
                    </div>
                }
            />
            <Switch
                checked={props.customerSettings.automaticallyMarkAsDelivered}
                onChange={(checked) =>
                    updateSettings({
                        automaticallyMarkAsDelivered: checked,
                    })
                }
                label={translate('customers.details.automaticallyMarkAsDelivered')}
                description={translate('customers.details.automaticallyMarkAsDeliveredDescription')}
            />
        </SettingsSection>
    );
};
