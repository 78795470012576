import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useAttachmentsConfig } from './useAttachmentsConfig';

export const useFileDownload = (): UseMutationResult<AxiosResponse, unknown, string> => {
    const { downloadFile } = useAttachmentsConfig();
    return useMutation({
        mutationFn: (fileId: string) => downloadFile(fileId),
    });
};
