import styled from 'styled-components';

import { ApproverMessage } from '../../../../types/ApproverMessage';
import { get } from '../../../common/ui/get';

import { ApproverLabel } from './ApproverLabel';

const TopLevelApproverList = styled.ul`
    // Override ul standards
    padding-left: ${get('space.6')}px;
    margin-bottom: 0;
`;

interface TopLevelApproverProps {
    approver: ApproverMessage;
}

export const TopLevelApprover = ({ approver }: TopLevelApproverProps) => {
    return (
        <TopLevelApproverList>
            <li>
                <ApproverLabel approver={approver} />
            </li>
        </TopLevelApproverList>
    );
};
