import { PaymentTermReference } from './PaymentTermReference';
import { PortMessage } from './PortMessage';
import { SupplierMessage } from './SupplierMessage';

export enum AssignmentStatus {
    REQUESTED = 'REQUESTED',
    CONFIRMED = 'CONFIRMED',
}

export interface AssignmentMessage {
    id: string;
    dateCreated: number;
    dateUpdated: number;
    supplierId: string;
    customerId: string;
    status: AssignmentStatus;
    inviteMail: string;
    inviteCompany: string;
    dateLastUploaded: number;
    dateAdminLastUploaded: number;
    attributes: Map<string, string>;
    portLimitations?: string[];
    portLimitationsPorts?: PortMessage[];
    defaultPaymentTermReference?: PaymentTermReference;
    defaultPaymentTermReferenceDays?: number;
    supplier?: SupplierMessage;
}
