import { useState } from 'react';

export const useStateSet = () => {
    const [value, setValue] = useState(new Set());

    const add = (item: string) => {
        setValue((prevItems) => new Set([...prevItems, item]));
    };

    const remove = (newItem: string) => {
        setValue((prevItems) => new Set([...prevItems].filter((item) => item !== newItem)));
    };

    const replace = (items: string[]) => {
        setValue(new Set(items));
    };

    return {
        add,
        remove,
        replace,
        value,
    };
};
