import styled from 'styled-components';

import { get } from '../../../../common/ui/get';

export const OfferCardContainer = styled.div`
    background-color: ${get('colors.white')};
    display: flex;
    flex-direction: column;
    min-height: 145px;
    position: relative;
    z-index: 2;
    border-radius: 8px;
    border: 1px solid ${get('colors.border.muted')};
`;
