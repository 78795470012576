import { AgentMessage, AgentsMessage } from '../../../../types/AgentMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

interface CreateAgentParams {
    agent: unknown;
}

export const createAgent = async ({ agent }: CreateAgentParams): Promise<AgentMessage> => {
    const response = await authorizedAxiosInstance.post('/v1/agents', agent);

    return response.data;
};

interface UpdateAgentParams {
    agentId: string;
    agent: unknown;
}

export const updateAgent = async ({ agent, agentId }: UpdateAgentParams): Promise<AgentMessage> => {
    const response = await authorizedAxiosInstance.put(`/v1/agents/${agentId}`, agent);

    return response.data;
};

export interface GetAgentListParams {
    ids?: string[];
    portIds?: string[];
    customerIds?: string[];
    searchQuery?: string;
}

export const getAgentList = async ({
    ids,
    searchQuery,
    portIds,
    customerIds,
}: GetAgentListParams): Promise<AgentsMessage> => {
    const params = new URLSearchParams();

    if (ids) {
        params.append('ids', ids.join(','));
    }

    if (portIds) {
        params.append('portIds', portIds.join(','));
    }

    if (customerIds) {
        params.append('customerIds', customerIds.join(','));
    }

    if (searchQuery) {
        params.append('searchQuery', searchQuery);
    }

    params.append('active', 'true');

    const response = await authorizedAxiosInstance.get(`/v1/agents?${params.toString()}`);

    return response.data;
};
