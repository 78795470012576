import { FieldArray, useFormikContext } from 'formik';

import { FormikCheckbox } from '../../../../../cdl/Checkbox/FormikCheckbox';
import { Text } from '../../../../../cdl/Text/Text';
import { translate } from '../../../../../common/helpers/translate.helper';
import { Box } from '../../../../../common/ui/Box';
import { useManageNewApprovalLimitInputFields } from '../hooks/useManageNewApprovalLimitInputFields';
import { FormikApprovalSettingsState } from '../MultipleApproversSettingsForm';
import { hasMultipleUniqueApprovers } from '../util/HasMultipleUniqueApprovers';

import { FormikSingleApprovalLimitWithApprovers } from './FormikSingleApprovalLimitWithApprovers';

export const FormikAdditionalApproversForm = () => {
    const { values } = useFormikContext<FormikApprovalSettingsState>();

    useManageNewApprovalLimitInputFields();

    if (!values.enableAdditionalApprovers) {
        return null;
    }

    return (
        <Box display="flex" flexDirection="column" style={{ rowGap: '16px' }}>
            <Text variant="body">{translate('multipleApproverSettings.additionalApproversDescription')}</Text>

            <FieldArray
                name="additionalApprovers"
                render={(arrayHelpers) => (
                    <Box display="flex" flexDirection="column" style={{ rowGap: '40px' }}>
                        {values.additionalApprovers.map((additionalApproverLimit, index) => (
                            <div key={index}>
                                <FormikSingleApprovalLimitWithApprovers
                                    index={index}
                                    onDeleteClick={() => {
                                        arrayHelpers.remove(index);
                                    }}
                                />
                            </div>
                        ))}
                    </Box>
                )}
            />
            <div>
                <FormikCheckbox
                    name="preventMultipleApprovers"
                    disabled={!hasMultipleUniqueApprovers(values)}
                    label={translate('multipleApproverSettings.preventMultipleApprovers')}
                />
            </div>
        </Box>
    );
};
