import { IconButtonProps } from '../IconButton';

export function getIconSize(size: NonNullable<IconButtonProps['size']>) {
    switch (size) {
        case 'small':
            return 12;
        case 'medium':
            return 16;
        case 'large':
            return 20;
    }
}
