import styled from 'styled-components';

import { get } from '../../../../common/ui/get';

interface OfferSummaryContainerProps {
    positiveHover?: boolean;
}

export const OfferSummaryContainer = styled.div<OfferSummaryContainerProps>`
    position: relative;
    cursor: pointer;
    padding: ${get('space.5')}px;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(212.33deg, rgba(0, 204, 105, 0.2) 0%, rgba(255, 255, 255, 0) 40%);
        opacity: 0;
        transition: opacity 150ms ease-in;
        pointer-events: none;
    }

    &:hover::after {
        opacity: ${(props) => (props.positiveHover ? 1 : 0)};
    }
`;
