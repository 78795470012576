import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { LocationStateProvider } from '../../common/LocationState/LocationStateProvider';
import { Page } from '../../common/Page/Page';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';

import { DownloadPriceListButton } from './components/DownloadPriceListButton';
import { InviteSupplierButton } from './components/InviteSupplierButton';
import { SupplierFilterActions } from './components/SupplierFilterActions';
import { SuppliersOverviewTable } from './components/SuppliersOverviewTable';

export interface SuppliersOverviewStateConfig {
    page: number;
    customerIds: string[];
}

export const SuppliersOverviewPage = () => {
    useDocumentTitle(translate('page.suppliers'));

    const stateConfig: SuppliersOverviewStateConfig = {
        page: 0,
        customerIds: [],
    };

    return (
        <LocationStateProvider stateConfig={stateConfig}>
            <Page>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <Text variant="headline" as="h1">
                            {translate('assignments.suppliers.subheadline')}
                        </Text>
                    </Box>

                    <Flex alignItems="center" gap={4}>
                        <SupplierFilterActions />

                        <DownloadPriceListButton />

                        <InviteSupplierButton />
                    </Flex>
                </Box>

                <SuppliersOverviewTable />
            </Page>
        </LocationStateProvider>
    );
};
