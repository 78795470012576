import { AppProviders } from '../common/AppProviders';
import { createDirectiveFromReactComponent } from '../common/helpers/createDirectiveFromReactComponent';

import { KeyPortOverviewPage as OriginalOverviewPage } from './KeyPortOverviewPage';

const KeyPortOverviewPage = (props) => {
    return (
        <AppProviders>
            <OriginalOverviewPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(KeyPortOverviewPage, 'reactKeyPortOverview');
