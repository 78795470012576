import { BunkerMode } from '../../../../../../types/BunkerCaseSolutionResponse';
import { translate } from '../../../../../common/helpers/translate.helper';

export const getBunkerModeText = (mode: BunkerMode): string => {
    switch (mode) {
        case BunkerMode.BUNKERING:
            return translate('prePlanning.solutionDetails.mode.BUNKERING');
        case BunkerMode.CANAL_TRANSIT:
            return translate('prePlanning.solutionDetails.mode.CANAL_TRANSIT');
        case BunkerMode.CLEANING:
            return translate('prePlanning.solutionDetails.mode.CLEANING');
        case BunkerMode.CONTRACTUAL:
            return translate('prePlanning.solutionDetails.mode.CONTRACTUAL');
        case BunkerMode.DISCHARGE:
            return translate('prePlanning.solutionDetails.mode.DISCHARGE');
        case BunkerMode.DRYDOCK:
            return translate('prePlanning.solutionDetails.mode.DRYDOCK');
        case BunkerMode.IDLING:
            return translate('prePlanning.solutionDetails.mode.IDLING');
        case BunkerMode.LOADING:
            return translate('prePlanning.solutionDetails.mode.LOADING');
        case BunkerMode.MULTI_PURPOSE:
            return translate('prePlanning.solutionDetails.mode.MULTI_PURPOSE');
        case BunkerMode.LADEN:
            return translate('prePlanning.solutionDetails.mode.LADEN');
        case BunkerMode.BALLAST:
            return translate('prePlanning.solutionDetails.mode.BALLAST');
        case BunkerMode.BUFFER_NO_BUNKERING:
            return translate('prePlanning.solutionDetails.mode.BUFFER_NO_BUNKERING');
        case BunkerMode.REDELIVERY:
            return translate('prePlanning.solutionDetails.mode.REDELIVERY');
    }
};
