import { useContextDatePickerOffsetPropGetters } from '@rehookify/datepicker';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { IconChevronLeft } from '../../../common/icons/cdl/ChevronLeft';
import { IconChevronRight } from '../../../common/icons/cdl/ChevronRight';
import { get } from '../../../common/ui/get';
import { Text } from '../../Text/Text';

interface MonthHeaderProps {
    children: ReactNode;
    isFirst?: boolean;
    isLast?: boolean;
}

const IconButton = styled.button`
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg path {
        fill: ${get('colors.foreground.subtle')};
    }

    &:hover {
        background-color: ${get('colors.background.subtle')};

        svg path {
            fill: ${get('colors.foreground.default')};
        }
    }
`;

export const MonthHeader = ({ children, isFirst, isLast }: MonthHeaderProps) => {
    const { addOffset, subtractOffset } = useContextDatePickerOffsetPropGetters();

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '32px 1fr 32px', height: '32px', alignItems: 'center' }}>
            {isFirst ? (
                <IconButton {...subtractOffset({ months: 1 })}>
                    <IconChevronLeft width={16} height={16} />
                </IconButton>
            ) : (
                <div />
            )}
            <Text variant="small" fontWeight={600} textAlign="center">
                {children}
            </Text>
            {isLast ? (
                <IconButton {...addOffset({ months: 1 })}>
                    <IconChevronRight width={16} height={16} />
                </IconButton>
            ) : (
                <div />
            )}
        </div>
    );
};
