import styled from 'styled-components';

import { Box } from '../../../../common/ui/Box';

interface LineActivePrefixProps {
    borderColor: string;
}

export const LineActivePrefix = styled(Box)<LineActivePrefixProps>({
    position: 'absolute',
    top: '14px',
    right: '99%',
    left: '-1000%',
    borderTopWidth: '4px',
    borderTopStyle: 'solid',
});
