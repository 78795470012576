import { animated } from '@react-spring/web';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';
import { CheckboxProps } from '../Checkbox';

interface CheckmarkBackgroundProps extends Pick<CheckboxProps, 'disabled' | 'checked'> {}

export const CheckmarkBackground = styled(animated.div)<CheckmarkBackgroundProps>`
    position: relative;
    width: 18px;
    height: 18px;
    background-color: ${(props) => {
        if (props.disabled) {
            return get('colors.background.disabled');
        }

        return props.checked ? get('colors.accent.emphasis') : get('colors.background.muted');
    }};
    border-radius: ${get('radii.1')}px;
    transition: background-color 100ms ease-out;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;
