import * as Sentry from '@sentry/browser';

CloselinkConfig.$inject = ['$httpProvider', '$translateProvider', 'AppSettings'];

function CloselinkConfig($httpProvider, $translateProvider, AppSettings) {
    $httpProvider.interceptors.push('TokenInterceptor');
    $httpProvider.interceptors.push('AuthErrorInterceptor');
    $httpProvider.interceptors.push('ExceptionInterceptor');

    $translateProvider.useStaticFilesLoader({
        prefix: '',
        suffix: '/translation.json',
    });

    $translateProvider.useLoaderCache('$templateCache');

    $translateProvider
        .registerAvailableLanguageKeys(['en'], {
            'en*': 'en',
        })
        .preferredLanguage('en')
        .fallbackLanguage('en');

    $translateProvider.useSanitizeValueStrategy('escapeParameters');

    Sentry.init({
        dsn: AppSettings.SENTRY_DSN,
        environment: AppSettings.ENV,
        sanitizeKeys: [/auth_token/, /password/i, 'user', /ip/i],
        integrations: [
            new Sentry.BrowserTracing(),
            new Sentry.Replay({
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
        tracesSampleRate: 0.2,
        replaysOnErrorSampleRate: 1.0,
    });
}

export default CloselinkConfig;
