import { PricingCodeMessage } from '../../../../types/ContractMessage';
import { FuelPricingCodeFrequency } from '../../../../types/FuelPricingCodeFrequency';

// Checks if the pricing code frequency matches at least one of the given frequencies
export const hasValidPricingCode = (
    pricingCodeFrequencies: FuelPricingCodeFrequency[],
    pricingCode: PricingCodeMessage
): boolean => {
    if (!pricingCodeFrequencies?.length) {
        return true;
    }

    return pricingCode ? pricingCodeFrequencies.includes(pricingCode.frequency) : false;
};
