export const Characters = {
    MIDDLE_DOT: '\u00B7',
    EN_SPACE: '\u2002',
    BULLET: '\u2022',
    BLACK_FOUR_POINTED_STAR: '\u2726',
    NO_BREAK_SPACE: '\u00A0',
    SOLIDUS: '\u002F',
    LEFT_DOUBLE_QUOTATION_MARK: '\u201C',
    RIGHT_DOUBLE_QUOTATION_MARK: '\u201D',
};
