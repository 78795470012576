import { translate } from '../../helpers/translate.helper';

interface SurchargeWaiverDisplayProps {
    value: number;
}

export const SurchargeWaiverDisplay = ({ value }: SurchargeWaiverDisplayProps) => {
    if (!value) {
        return null;
    }

    if (value > 0) {
        return translate('offer.quotationProcess.fuel.surcharge');
    }

    return translate('offer.quotationProcess.fuel.waiver');
};
