import {
    BunkerSchedule,
    CategoryWarning,
    PortLocationType,
} from '../../../../../../../../types/BunkerCaseSolutionResponse';
import { MoneyMessage } from '../../../../../../../../types/MoneyMessage';
import { translate } from '../../../../../../../common/helpers/translate.helper';
import { RecommendedContract } from '../components/RecommendedContractPopup';

interface BunkerStopProduct {
    name: string;
    isAdditional: false;
    quantityStart?: {
        value: number;
        warnings: CategoryWarning[];
    };
    consumption?: {
        value: number;
        warnings: CategoryWarning[];
    };
    recommendedContract?: RecommendedContract;
    purchaseQuantity?: {
        value: number;
        latestDeliveryDate?: string;
    };
    price?: {
        value: MoneyMessage;
        priceDate?: string;
    };
    total?: MoneyMessage;
}

interface BunkerStopAdditional {
    name: string;
    isAdditional: true;
    startingRob?: never;
    consumption?: never;
    recommendedContract?: never;
    purchaseQuantity?: never;
    price?: never;
    total?: MoneyMessage;
}

export type BunkerStopData = BunkerStopProduct | BunkerStopAdditional;

export function buildEntriesForBunkerSchedule(
    bunkerSchedule: BunkerSchedule,
    includedProductGroups: Map<string, { productCategory: string; sulphurContent: string }>
): BunkerStopData[] {
    const tableEntries: BunkerStopData[] = [];

    includedProductGroups.forEach((value) => {
        const purchaseRecommendation = bunkerSchedule.purchases.find(
            (purchase) =>
                purchase.productCategory === value.productCategory && purchase.sulphurContent === value.sulphurContent
        );

        const consumption = bunkerSchedule.categoryConsumption.find(
            (categoryConsumption) =>
                categoryConsumption.productCategory === value.productCategory &&
                categoryConsumption.sulphurContent === value.sulphurContent
        );

        const stopData: BunkerStopData = {
            name: value.productCategory + ' ' + value.sulphurContent,
            isAdditional: false,
        };

        if (consumption !== undefined) {
            stopData.quantityStart = {
                value: consumption.quantityStart,
                warnings: consumption.quantityStartWarnings,
            };

            stopData.consumption = {
                value: consumption.consumption,
                warnings: consumption.consumptionWarnings,
            };

            if (consumption.recommendedContractId !== undefined) {
                stopData.recommendedContract = {
                    contractId: consumption.recommendedContractId,
                    productCategory: value.productCategory,
                    sulphurContent: value.sulphurContent,
                };
            }
        }

        if (purchaseRecommendation !== undefined) {
            stopData.purchaseQuantity = {
                value: purchaseRecommendation.quantity,
                latestDeliveryDate: purchaseRecommendation.latestDeliveryDate,
            };

            stopData.price = {
                value: purchaseRecommendation.price,
                priceDate: purchaseRecommendation.priceDate,
            };

            stopData.total = purchaseRecommendation.total;
        }

        tableEntries.push(stopData);
    });

    if (bunkerSchedule.bunkerScheduleCosts.bargeCost !== undefined) {
        tableEntries.push({
            name: translate('prePlanning.solutionDetails.stopTable.barge'),
            isAdditional: true,
            total: bunkerSchedule.bunkerScheduleCosts.bargeCost,
        });
    }

    if (bunkerSchedule.bunkerScheduleCosts.portCost !== undefined) {
        let portLocationType: string | undefined;

        switch (bunkerSchedule.portLocationType) {
            case PortLocationType.BERTH:
                portLocationType = translate('prePlanning.solutionDetails.stopTable.atBerth');

                break;
            case PortLocationType.ANCHOR:
                portLocationType = translate('prePlanning.solutionDetails.stopTable.atAnchor');
                break;
        }

        const portName =
            portLocationType === undefined
                ? translate('prePlanning.solutionDetails.stopTable.portCall')
                : translate('prePlanning.solutionDetails.stopTable.portCallWithLocationType', {
                      locationType: portLocationType,
                  });

        tableEntries.push({
            name: portName,
            isAdditional: true,
            total: bunkerSchedule.bunkerScheduleCosts.portCost,
        });
    }

    if (bunkerSchedule.bunkerScheduleCosts.canalCost !== undefined) {
        tableEntries.push({
            name: translate('prePlanning.solutionDetails.stopTable.canal'),
            isAdditional: true,
            total: bunkerSchedule.bunkerScheduleCosts.canalCost,
        });
    }

    if (bunkerSchedule.bunkerScheduleCosts.tcePenaltyCost !== undefined) {
        tableEntries.push({
            name: translate('prePlanning.solutionDetails.stopTable.tcePenalty'),
            isAdditional: true,
            total: bunkerSchedule.bunkerScheduleCosts.tcePenaltyCost,
        });
    }

    return tableEntries;
}
