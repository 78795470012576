import { useQuery } from '@tanstack/react-query';

import { getPaginatedVessels } from '../../common/api/clients/gateway.api';
import { queries } from '../../common/api/queryKeys/queries';

export interface VesselPaginationTotalsParams {
    searchQuery?: string;
    customerIds?: string[] | string;
}

export const useVesselPaginationTotals = ({ searchQuery, customerIds }: VesselPaginationTotalsParams) => {
    const fetchTotals = ({ searchQuery, customerIds }: VesselPaginationTotalsParams) => {
        return Promise.all([
            getPaginatedVessels({
                page: 0,
                searchQuery,
                customerIds,
                active: true,
            }),
            getPaginatedVessels({
                page: 0,
                searchQuery,
                customerIds,
                active: false,
            }),
        ])
            .then((values) => values.map((value) => value.total))
            .then((vesselTotals) => {
                return {
                    active: vesselTotals[0],
                    inactive: vesselTotals[1],
                };
            });
    };

    return useQuery({
        queryKey: queries.vessels.totals({ searchQuery, customerIds }).queryKey,
        queryFn: () =>
            fetchTotals({
                searchQuery,
                customerIds,
            }),
        refetchOnWindowFocus: false,
    });
};
