import { useQuery } from '@tanstack/react-query';
import FileDownload from 'js-file-download';
import moment from 'moment';

import { getMessagesForOfferId } from '../../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../../common/api/queryKeys';
import { formatUser } from '../../../../common/helpers/formatUser.helper';

const formatDate = (date, format) => {
    return moment(date).format(format);
};

const useMessagesForOfferId = (offerId) => {
    return useQuery({
        queryKey: queryKeys.offerMessages(offerId),
        queryFn: () => getMessagesForOfferId(offerId),
        enabled: false,
    });
};

export const useMessagesExport = (offerId) => {
    const messagesForOfferQuery = useMessagesForOfferId(offerId);

    const createExportMessage = (message) => {
        let messageTxt = '';
        messageTxt += '[' + formatDate(message.dateCreated, 'MMM d, y h:mm a') + '] ';
        if (message.senderType !== 'SYSTEM' && message.user) {
            messageTxt += formatUser(message.user) + ': ';
        }
        messageTxt += message.message.replace('<strong>', '').replace('</strong>', '');
        return messageTxt;
    };

    const downloadMessages = async (offerNumber) => {
        const result = await messagesForOfferQuery.refetch();
        const messages = result.data;

        const messageTxtArray = messages.map((message) => {
            return createExportMessage(message);
        });

        const messageBlob = new Blob([messageTxtArray.join('\r\n')], {
            type: 'application/octet-stream',
        });

        FileDownload(messageBlob, `${offerNumber}_messages.txt`);
    };

    return {
        downloadMessages,
    };
};
