import { Form, useFormikContext } from 'formik';

import { PaymentTermReference } from '../../../../types/PaymentTermReference';
import { Button } from '../../../cdl/Button/Button';
import { Text } from '../../../cdl/Text/Text';
import { FormikCustomerCompanySelect } from '../../../common/form-elements/formik/FormikCustomerCompanySelect';
import { FormikDebug } from '../../../common/form-elements/formik/FormikDebug';
import { FormikEnumComboBox } from '../../../common/form-elements/formik/FormikEnumComboBox';
import { FormikPortSelect } from '../../../common/form-elements/formik/FormikPortSelect';
import { FormikSupplierCompanySelect } from '../../../common/form-elements/formik/FormikSupplierCompanySelect';
import { FormikFormattedNumberInput } from '../../../common/FormattedNumberInput/FormikFormattedNumberInput';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';

export interface FormikAssignmentState {
    customerId: string;
    supplierId: string;
    portLimitations: string[];
    defaultPaymentTermReference?: PaymentTermReference;
    defaultPaymentTermReferenceDays: string;
}

interface AssignmentBaseFormProps {
    title: string;
}

export const AssignmentBaseForm = (props: AssignmentBaseFormProps) => {
    const { isValid, isSubmitting, values } = useFormikContext<FormikAssignmentState>();
    return (
        <Form style={{ height: '100%' }}>
            <Box display="grid" gridTemplateRows="auto 1fr auto" height="100%" gridTemplateColumns="100%">
                <Box padding={7} paddingBottom={6}>
                    <Text variant="title" marginBottom={6}>
                        {props.title}
                    </Text>
                </Box>

                <Box paddingX={7} display="flex" flexDirection="column" overflowY="auto" rowGap={5}>
                    <FormikCustomerCompanySelect name="customerId" label={translate('assignments.customer')} />
                    <FormikSupplierCompanySelect name="supplierId" label={translate('assignments.supplier')} />

                    <Text variant="subtitle1" marginTop={5}>
                        {translate('assignments.fuelOptions')}
                    </Text>

                    <FormikPortSelect
                        name="portLimitations"
                        label={translate('assignments.portLimitationsLabel')}
                        isMulti
                    />

                    <Box display="grid" gridTemplateColumns="3fr 1fr" alignItems="start" gap={5}>
                        <FormikEnumComboBox
                            name="defaultPaymentTermReference"
                            label={translate('assignments.defaultPaymentTermsLabel')}
                            enumType="paymentTermReference"
                        />
                        <FormikFormattedNumberInput
                            name="defaultPaymentTermReferenceDays"
                            label={translate('assignments.defaultPaymentTermDays')}
                            disabled={values.defaultPaymentTermReference === PaymentTermReference.PREPAYMENT}
                        />
                    </Box>
                    <FormikDebug />
                </Box>

                <Box padding={7}>
                    <Button
                        emphasis="high"
                        type="submit"
                        size="large"
                        disabled={!isValid}
                        isLoading={isSubmitting}
                        width="100%"
                    >
                        {translate('btn.save.default')}
                    </Button>
                </Box>
            </Box>
        </Form>
    );
};
