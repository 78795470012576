import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconBell = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M13.899 5.37a2 2 0 0 0-3.798 0C7.42 6.386 5 9.315 5 12l-.522 2.608A2 2 0 0 0 6.44 17h11.12a2 2 0 0 0 1.962-2.392L19 12c0-2.685-2.42-5.614-5.101-6.63ZM12 20a2 2 0 0 1-2-2h4a2 2 0 0 1-2 2Zm5-6v-1c0-1-1-5-5-5s-5 4-5 5v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1Z"
            clipRule="evenodd"
        />
    </svg>
);
