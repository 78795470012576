import styled from 'styled-components';

import {
    AdminStatisticsMessage,
    CustomerStatisticsMessage,
    SupplierStatisticsMessage,
} from '../../../types/StatisticsMessage';
import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';
import { Box } from '../../common/ui/Box';
import { get } from '../../common/ui/get';
import { sortReportsByVolume } from '../utils/sortReports';

import { GeneralStats } from './GeneralStats';
import { VolumesBarDiagram } from './VolumesBarDiagram';

const VolumesWrapper = styled.div`
    margin-top: ${get('space.8')}px;
    gap: ${get('space.8')}px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: ${get('breakpoints.2')}) {
        grid-template-columns: 1fr;
    }
`;

interface GeneralTabContentProps {
    statistics: AdminStatisticsMessage | SupplierStatisticsMessage | CustomerStatisticsMessage;
}

export const GeneralTabContent = ({ statistics }: GeneralTabContentProps) => {
    const role = useRole();
    return (
        <Box>
            <GeneralStats statisticsData={statistics.general} />

            <VolumesWrapper>
                {role.isSupplier() && 'customer' in statistics ? (
                    <VolumesBarDiagram
                        title={translate('reporting.general.customersvol')}
                        generalVolume={statistics.general.volume}
                        statistics={statistics.customer.sort(sortReportsByVolume)}
                    />
                ) : null}
                {(role.isCustomer() || role.isAdmin()) && 'vessel' in statistics ? (
                    <VolumesBarDiagram
                        title={translate('reporting.general.vesselsvol')}
                        generalVolume={statistics.general.volume}
                        statistics={statistics.vessel.sort(sortReportsByVolume)}
                    />
                ) : null}

                <VolumesBarDiagram
                    title={translate('reporting.general.portsvol')}
                    generalVolume={statistics.general.volume}
                    statistics={statistics.port.sort(sortReportsByVolume)}
                />

                <VolumesBarDiagram
                    title={translate('reporting.general.productgroupsvol')}
                    generalVolume={statistics.general.volume}
                    statistics={statistics.productGroup.sort(sortReportsByVolume)}
                />
            </VolumesWrapper>
        </Box>
    );
};
