import styled from 'styled-components';

import { get } from '../../../../../../common/ui/get';

export const DashedOfferCardOutline = styled.div`
    height: 145px;
    justify-content: center;
    align-items: center;
    color: ${get('colors.foreground.subtle')};
    display: flex;

    // Styles for a custom dashed border with 6px dash and 6px gap
    background-image: repeating-linear-gradient(
            0deg,
            ${get('colors.border.muted')},
            ${get('colors.border.muted')} 6px,
            transparent 6px,
            transparent 12px,
            ${get('colors.border.muted')} 12px
        ),
        repeating-linear-gradient(
            90deg,
            ${get('colors.border.muted')},
            ${get('colors.border.muted')} 6px,
            transparent 6px,
            transparent 12px,
            ${get('colors.border.muted')} 12px
        ),
        repeating-linear-gradient(
            180deg,
            ${get('colors.border.muted')},
            ${get('colors.border.muted')} 6px,
            transparent 6px,
            transparent 12px,
            ${get('colors.border.muted')} 12px
        ),
        repeating-linear-gradient(
            270deg,
            ${get('colors.border.muted')},
            ${get('colors.border.muted')} 6px,
            transparent 6px,
            transparent 12px,
            ${get('colors.border.muted')} 12px
        );
    background-size:
        1px 100%,
        100% 1px,
        1px 100%,
        100% 1px;
    background-position:
        0 0,
        0 0,
        100% 0,
        0 100%;
    background-repeat: no-repeat;
`;
