import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { LubesOfferEditFlow as OriginalLubesOfferEditFlow } from './LubesOfferEditFlow';

const LubesOfferEditFlow = (props: any) => {
    return (
        <AppProviders>
            <OriginalLubesOfferEditFlow {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(LubesOfferEditFlow, 'reactLubesOfferEditFlow');
