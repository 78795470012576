import { Text } from '../../cdl/Text/Text';
import { CustomerCompanySelect } from '../../common/form-elements/CompanySelect/CustomerCompanySelect';
import { SupplierCompanySelect } from '../../common/form-elements/CompanySelect/SupplierCompanySelect';
import { CustomerGroupSelect } from '../../common/form-elements/CustomerGroupSelect/CustomerGroupSelect';
import { SupplierGroupSelect } from '../../common/form-elements/SupplierGroupSelect/SupplierGroupSelect';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useRole } from '../../common/hooks/useRole';
import { useLocationState } from '../../common/LocationState/useLocationState';
import { Page } from '../../common/Page/Page';
import { DebouncedSearchTextInput } from '../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../common/ui/Box';

import { InviteUserButton } from './components/InviteUserButton';
import { UserOverviewState } from './components/UserOverviewState';
import { UserOverviewTable } from './components/UserOverviewTable';

export const UserOverviewPage = () => {
    return (
        <UserOverviewState>
            <UserOverviewPageContent />
        </UserOverviewState>
    );
};

const UserOverviewPageContent = () => {
    const role = useRole();
    const [locationState, { setLocationFieldValue }] = useLocationState();

    useDocumentTitle(translate('page.useroverview'));

    return (
        <Page>
            <Box display="grid" rowGap={5}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Text variant="headline" as="h1">
                        {translate('user.headline')}
                    </Text>
                    {role.hasAnyAdminRights() ? <InviteUserButton /> : null}
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Box display="flex" columnGap={4} alignItems="center">
                        <Box minWidth="250px">
                            <DebouncedSearchTextInput
                                placeholder={translate('user.searchPlaceholder')}
                                onChange={(searchQuery) => setLocationFieldValue('searchQuery', searchQuery)}
                                value={locationState.searchQuery}
                            />
                        </Box>

                        {role.isAdmin() ? (
                            <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4} minWidth="600px">
                                <CustomerGroupSelect
                                    onChange={(customerGroupIds) =>
                                        setLocationFieldValue('customerGroupIds', customerGroupIds)
                                    }
                                    value={locationState.customerGroupIds}
                                    placeholder={translate('customerGroupSelect.placeholder')}
                                    isMulti
                                />

                                <SupplierGroupSelect
                                    onChange={(supplierGroupIds) => {
                                        if (Array.isArray(supplierGroupIds)) {
                                            setLocationFieldValue('supplierGroupIds', supplierGroupIds);
                                        }
                                    }}
                                    value={locationState.supplierGroupIds}
                                    placeholder={translate('supplierGroupSelect.placeholder')}
                                    isMulti
                                />
                            </Box>
                        ) : null}

                        {role.isCustomer() && !role.isOneCompanyUser() ? (
                            <Box minWidth="400px" maxWidth="50%">
                                <CustomerCompanySelect
                                    value={locationState.customerIds}
                                    onChange={(customerIds) => {
                                        setLocationFieldValue('customerIds', customerIds);
                                    }}
                                    placeholder={translate('placeholder.filter.company')}
                                    isMulti
                                />
                            </Box>
                        ) : null}

                        {role.isSupplier() && !role.isOneCompanyUser() ? (
                            <Box minWidth="400px" maxWidth="50%">
                                <SupplierCompanySelect
                                    value={locationState.supplierIds}
                                    placeholder={translate('placeholder.filter.company')}
                                    onChange={(supplierIds) => {
                                        setLocationFieldValue('supplierIds', supplierIds);
                                    }}
                                    isMulti
                                />
                            </Box>
                        ) : null}
                    </Box>
                </Box>

                <UserOverviewTable />
            </Box>
        </Page>
    );
};
