import styled from 'styled-components';

import { CountryMessage } from '../../../../../types/CountryMessage';
import { Switch } from '../../../../cdl/Switch/Switch';
import { theme } from '../../../../common/ui/theme';

interface CountryListProps {
    countries: CountryMessage[];
    isCountryActive: (countryCode: string) => boolean;
    onCountrySwitchChange: (checked: boolean, countryCode: string) => void;
}

const List = styled.ul`
    list-style: none;
    margin-left: 70px;

    li:not(:last-child) {
        margin-bottom: ${theme.radii[2]}px;
    }
`;

export const CountryList = (props: CountryListProps) => {
    return (
        <List>
            {props.countries.map((country) => {
                return (
                    <li key={country.id}>
                        <Switch
                            checked={props.isCountryActive(country.code)}
                            onChange={(checked) => props.onCountrySwitchChange(checked, country.code)}
                            label={country.name}
                        />
                    </li>
                );
            })}
        </List>
    );
};
