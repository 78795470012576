import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { LubesOfferOverviewPage as OriginalOverviewPage } from './LubesOfferOverviewPage';

const LubesOfferOverviewPage = (props) => {
    return (
        <AppProviders>
            <OriginalOverviewPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(LubesOfferOverviewPage, 'reactLubesOfferOverviewPage');
