import { useQuery } from '@tanstack/react-query';

import { getInitialEnquiry } from '../../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../../common/api/queryKeys';

export const useInitialEnquiry = (offerId) => {
    return useQuery({
        queryKey: queryKeys.initialEnquiry(offerId),
        queryFn: () => getInitialEnquiry(offerId),
    });
};
