import PropTypes from 'prop-types';

import { useRole } from '../../common/hooks/useRole';
import { Flex } from '../../common/ui/Flex';

import { OfferChatInfo } from './OfferChatInfo';
import { OfferChatInput } from './OfferChatInput';
import { OfferChatMessages } from './OfferChatMessages';

export const OfferChat = ({ offer }) => {
    const role = useRole();

    const hasWriteRights = role.hasWriteRights(offer.customerId) || role.hasWriteRights(offer.supplierId);

    const isNonPlatformSupplier = offer.enquiryType === 'FORWARDED' || offer.enquiryType === 'ANONYMOUS';

    const showForwardedOfferInfoText = role.isCustomer() && isNonPlatformSupplier;

    const showInfoText = role.isSupplier() && offer.enquiryType === 'SPOT' && offer.state === 'ENQUIRY';

    const showAnyInfoText = showForwardedOfferInfoText || showInfoText;

    const isMessagingAllowed = !role.isAdmin() && hasWriteRights && !showAnyInfoText;

    return (
        <Flex flexDirection="column" backgroundColor="white" height="100%">
            <OfferChatMessages offer={offer} />
            <OfferChatInfo showForwardedOfferInfoText={showForwardedOfferInfoText} showInfoText={showInfoText} />
            {isMessagingAllowed ? <OfferChatInput offerId={offer.id} /> : null}
        </Flex>
    );
};

OfferChat.propTypes = {
    offer: PropTypes.object.isRequired,
};
