import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getCustomerGroup } from '../clients/customerGroups.api';

export const customerGroups = createQueryKeys('customerGroups', {
    detail: (id: string) => ({
        queryKey: [id],
        queryFn: () => getCustomerGroup(id),
    }),
});
