import PropTypes from 'prop-types';

import { HumanReadableEnumValue } from '../enums/HumanReadableEnumValue';
import { formatFuelMoney } from '../helpers/formatFuelMoney.helper';
import { formatNumber } from '../helpers/formatNumber.helper';
import { translate } from '../helpers/translate.helper';
import { Td } from '../Table/Td';
import { Tr } from '../Table/Tr';
import { Box } from '../ui/Box';

const ProductRow = ({ item, position, showPrices, showOnlyInitialValues }) => {
    const translatedSulphurContent = translate(`sulphurContent.${item.product.sulphurContent}`);

    return (
        <Tr inactive={item.deleted} focused={item.added && !item.deleted}>
            <Td>{position}</Td>
            <Td danger={item.changes?.productIdChanged && !item.deleted}>
                {item.product.name} {translatedSulphurContent}
            </Td>
            <Td>
                <HumanReadableEnumValue enumType="productGroup" value={item.product.group} />{' '}
                <HumanReadableEnumValue enumType="sulphurContent" value={item.product.sulphurContent} />
            </Td>
            <Td danger={item.changes?.isoSpecChanged && !item.deleted}>
                {item.isoSpec ? <HumanReadableEnumValue enumType="fuelIsoSpec" value={item.isoSpec} /> : '-'}
            </Td>

            {!showOnlyInitialValues ? (
                <Td danger={item.changes?.energyContentChanged && !item.deleted}>
                    {item.energyContent
                        ? translate('order.energyContentValue', {
                              energyContent: formatNumber({
                                  number: item.energyContent.value,
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                              }).toString(),
                          })
                        : '-'}
                </Td>
            ) : null}

            {!showOnlyInitialValues ? <Td>{item.physicalSupplierName ? item.physicalSupplierName : '-'}</Td> : null}

            <Td danger={item.changes?.unitsChanged && !item.deleted}>
                <Box style={{ whiteSpace: 'nowrap' }}>
                    {formatNumber({
                        number: item.volume,
                        maximumFractionDigits: 1,
                    })}{' '}
                    <HumanReadableEnumValue enumType="productUnit" value={item.unit} />
                </Box>
            </Td>

            {showPrices ? (
                <Td danger={item.changes?.priceChanged && !item.deleted}>
                    {item.price
                        ? formatFuelMoney({
                              value: item.price.value,
                              currency: item.price.currency,
                          })
                        : '-'}
                </Td>
            ) : null}

            {showPrices ? (
                <Td>
                    {item.total && item.total.value
                        ? formatFuelMoney({
                              value: item.total.value,
                              currency: item.total.currency,
                          })
                        : '-'}
                </Td>
            ) : null}
        </Tr>
    );
};

ProductRow.propTypes = {
    item: PropTypes.object,
    position: PropTypes.number,
    showPrices: PropTypes.bool,
    showOnlyInitialValues: PropTypes.bool,
};

export const Products = ({ items, showPrices, showOnlyInitialValues }) => {
    return items.map((item, index) => (
        <ProductRow
            key={index}
            item={item}
            showPrices={showPrices}
            showOnlyInitialValues={showOnlyInitialValues}
            position={index + 1}
        />
    ));
};
