import css from '@styled-system/css';
import styled from 'styled-components';

import { Flex } from '../../../common/ui/Flex';

export const EmptyState = styled(Flex)(
    css({
        justifyContent: 'center',
        alignItems: 'center',
        height: '150px',
        width: '100%',
        borderRadius: 1,
        border: 4,
        color: 'dark-blue.3',
    })
);
