import { DiscoverableButton } from '../../cdl/Button/DiscoverableButton';
import { useToasts } from '../../cdl/Toast/useToasts';
import { translate } from '../../common/helpers/translate.helper';
import { useNavigation } from '../../common/hooks/useNavigation';
import { useBunkerStopEnquiryCreation } from '../hooks/useBunkerStopEnquiryCreation';

export const BunkerPlanCreateEnquiryButton = ({ bunkerStopId }: { bunkerStopId: string }) => {
    const bunkerStopEnquiryCreation = useBunkerStopEnquiryCreation();
    const { addErrorToast } = useToasts();
    const { navigate } = useNavigation();

    const onCreateEnquiryClick = async (bunkerStopId: string) => {
        await bunkerStopEnquiryCreation.mutateAsync(
            { bunkerStopId },
            {
                onSuccess: (bunkerStopMessage) => {
                    navigate('base.fuel-enquiry', {
                        orderId: bunkerStopMessage.orderId,
                        origin: {
                            stateName: 'base.fuel-planning-bunker-plan',
                        },
                    });
                },
                onError: () => {
                    addErrorToast(translate('global.genericToastError'));
                },
            }
        );
    };

    return (
        <DiscoverableButton
            emphasis="high"
            onClick={(event) => {
                event.stopPropagation();
                onCreateEnquiryClick(bunkerStopId);
            }}
            isLoading={bunkerStopEnquiryCreation.isPending}
        >
            {translate('bunkerPlan.table.createEnquiryBtn')}
        </DiscoverableButton>
    );
};
