import { AssignmentGatewayMessage } from '../../../../types/AssignmentGatewayMessage';
import { formatCompanyName } from '../../../common/helpers/formatCompanyName.helper';
import { useEnums } from '../../../common/hooks/useEnums';

interface AssignmentSupplierProps {
    assignment: AssignmentGatewayMessage;
}

export const AssignmentSupplier = (props: AssignmentSupplierProps) => {
    const { getHumanReadableValue } = useEnums();

    if (props.assignment.supplier?.name) {
        return (
            <>
                {formatCompanyName({
                    company: props.assignment.supplier,
                })}
            </>
        );
    }

    if (props.assignment.inviteMail && props.assignment.inviteCompany) {
        const inviteCompanyReadableValue = getHumanReadableValue(
            'assignmentInviteCompany',
            props.assignment.inviteCompany
        );

        return (
            <>
                {inviteCompanyReadableValue}: {props.assignment.inviteMail}
            </>
        );
    }

    return <>{props.assignment.inviteMail}</>;
};
