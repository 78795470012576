import styled from 'styled-components';

import { PositionData } from '../types/PositionData';

import { Position } from './Position';

const List = styled.ul`
    list-style-type: none;
    overflow: auto;
    height: calc(100% - 180px);
    padding-left: 0;
`;

interface PositionListProps {
    positionData: PositionData[];
    onActivePositionChange: (newActivePosition: PositionData) => void;
}

export const PositionList = ({ positionData, onActivePositionChange }: PositionListProps) => {
    return (
        <List>
            {positionData.map((position) => {
                return (
                    <Position
                        key={position.id}
                        position={position}
                        onClick={() => {
                            onActivePositionChange(position);
                        }}
                    />
                );
            })}
        </List>
    );
};
