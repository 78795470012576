import css from '@styled-system/css';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text.tsx';
import { translate } from '../../common/helpers/translate.helper';
import { IconArrowRight } from '../../common/icons/cdl/ArrowRight';
import { Box } from '../../common/ui/Box.tsx';
import { KeyPortHighlight } from '../../keyport/KeyPortHighlight';

import { PortAndLocode } from './PriceCompare/components/PortAndLocode';
import { ScheduleStopTime } from './ScheduleStopTime';

export const Frame = styled(Box).attrs((props) => ({
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 1,
    borderColor: 'border.muted',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    display: 'flex',
    ...props,
}))({});

const Line = styled('div')(
    css({
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'border.muted',
        width: '100%',
    })
);

//Inspired by https://stackoverflow.com/a/13627586

const ordinalSuffixOf = (position) => {
    const lastDigit = position % 10;

    if (lastDigit === 1 && position !== 11) {
        return position + 'st';
    }
    if (lastDigit === 2 && position !== 12) {
        return position + 'nd';
    }
    if (lastDigit === 3 && position !== 13) {
        return position + 'rd';
    }

    return position + 'th';
};

export const ScheduleStop = ({ stop, vesselId, index }) => {
    const position = index + 1;
    const eta = new Date(stop.eta);
    const etd = new Date(stop.etd);
    const today = new Date();
    const nextOrCurrent = today > eta && etd > today ? translate('schedule.current') : translate('schedule.next');

    const nthStop = translate('schedule.nthStop', {
        position: position === 1 ? nextOrCurrent : ordinalSuffixOf(position),
    });

    return (
        <Frame flex="1">
            <Box margin={5}>
                <Box>
                    <Text variant="extraSmall" color="foreground.subtle">
                        {nthStop}
                        &nbsp;
                        <KeyPortHighlight portId={stop.port?.id} vesselId={vesselId} showTooltip />
                    </Text>
                    {stop.port ? (
                        <PortAndLocode name={stop.port.name} locode={stop.port.locCode} />
                    ) : (
                        <PortAndLocode name={stop.locode} />
                    )}
                </Box>

                <Box marginBottom={5} marginTop={5}>
                    <Line />
                </Box>

                <Box display="flex" alignItems="center">
                    <Box width="40%">
                        <ScheduleStopTime title={translate('schedule.arrival')} stop={stop} date={stop.eta} />
                    </Box>
                    <Box width="20%" display="flex" justifyContent="center" color="foreground.subtle">
                        <IconArrowRight />
                    </Box>
                    <Box width="40%">
                        <ScheduleStopTime title={translate('schedule.departure')} stop={stop} date={stop.etd} />
                    </Box>
                </Box>
            </Box>
        </Frame>
    );
};

ScheduleStop.propTypes = {
    stop: PropTypes.object.isRequired,
    vesselId: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
};
