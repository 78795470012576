import { createContext } from 'react';

export interface LubesProductTableConfig {
    showPrices: boolean;
    editable: boolean;
    editSurcharges: boolean;
    editPrices: boolean;
    fetchProducts?: () => void;
}

const defaultContextValue: LubesProductTableConfig = {
    showPrices: true,
    editable: true,
    editSurcharges: true,
    editPrices: true,
    fetchProducts: undefined,
};

export const LubesProductTableContext = createContext(defaultContextValue);
