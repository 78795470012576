import React from 'react';

import { UserGatewayMessage } from '../../../../types/UserGatewayMessage';
import { Button } from '../../../cdl/Button/Button';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { translate } from '../../../common/helpers/translate.helper';
import { EditUser } from '../../components/EditUser';

interface EditUserButtonProps {
    user: UserGatewayMessage;
}

export const EditUserButton = ({ user }: EditUserButtonProps) => {
    const { push, pop } = useDrawer();

    return (
        <Button
            emphasis="high"
            onClick={() => {
                push({
                    content: <EditUser user={user} onSuccess={pop} />,
                });
            }}
        >
            {translate('user.edit')}
        </Button>
    );
};
