import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { ConditionalRenderer } from '../../common/ConditionalRenderer/ConditionalRenderer';
import { HumanReadableEnumValue } from '../../common/enums/HumanReadableEnumValue';
import { formatMoney } from '../../common/helpers/formatMoney.helper';
import { formatNumber } from '../../common/helpers/formatNumber.helper';
import { translate } from '../../common/helpers/translate.helper';
import { Td } from '../../common/Table/Td';
import { Tr } from '../../common/Table/Tr';
import { useLubesProductStringFormat } from '../../order/detail/lubes/hooks/useLubesProductStringFormat';

const TranslationMissing = () => {
    return (
        <span>
            <i className="fas fa-exclamation-circle" aria-hidden="true" />
            <i>
                <span>{translate('global.translationmissing')}</span>
            </i>
        </span>
    );
};

const ProductInformation = ({ item }) => {
    return (
        <Fragment>
            <div>
                <small>
                    <strong>
                        {translate('order.requestedproduct')} <Product product={item.baseProduct} />
                    </strong>
                </small>
            </div>
            <div>
                <ConditionalRenderer condition={!item.productId}>
                    <small>
                        <HumanReadableEnumValue enumType="productGroup" value={item.baseProduct.group} />
                    </small>
                </ConditionalRenderer>
            </div>
        </Fragment>
    );
};

ProductInformation.propTypes = {
    item: PropTypes.object,
};

const Product = ({ product }) => {
    const formatProductString = useLubesProductStringFormat();

    return <span>{formatProductString(product)}</span>;
};

Product.propTypes = {
    product: PropTypes.object,
};

const ProductColumn = ({ item }) => {
    return (
        <Td danger={item.changes && item.changes.productChanged && !item.deleted}>
            {item.isTranslationMissing() ? <TranslationMissing /> : <Product product={item.product} />}
            {item.baseProduct && item.baseProductId !== item.productId ? <ProductInformation item={item} /> : null}
            {item.product && item.product.group ? (
                <div>
                    <small>
                        <HumanReadableEnumValue enumType={'productGroup'} value={item.product.group} />
                    </small>
                </div>
            ) : null}
        </Td>
    );
};

ProductColumn.propTypes = {
    item: PropTypes.object,
};

const ProductRow = ({ item, position, showPrices }) => {
    return (
        <Tr inactive={item.deleted} focused={item.added && !item.deleted}>
            <Td>{position}</Td>
            <ProductColumn item={item} />
            <Td danger={item.changes && item.changes.packTypeChanged && !item.deleted}>
                <HumanReadableEnumValue enumType="packType" value={item.packType} />
            </Td>
            <Td danger={item.changes && item.changes.unitSizeChanged && !item.deleted}>
                {formatNumber({
                    number: item.unitSize,
                })}
            </Td>
            <Td danger={item.changes && item.changes.unitsChanged && !item.deleted}>
                {formatNumber({
                    number: item.units,
                })}
            </Td>
            <Td>
                {formatNumber({
                    number: item.volume,
                })}
            </Td>
            <Td danger={item.changes && item.changes.unitChanged && !item.deleted}>
                <HumanReadableEnumValue enumType="productUnit" value={item.unit} />
            </Td>
            {showPrices ? (
                <>
                    <Td danger={item.changes && item.changes.priceChanged && !item.deleted}>
                        {item.price
                            ? formatMoney({
                                  value: item.price.value,
                                  currency: item.price.currency,
                                  maximumFractionDigits: 4,
                              })
                            : translate('global.notavailable')}
                    </Td>
                    <Td>
                        {item.total && item.total.value !== undefined
                            ? formatMoney({
                                  value: item.total.value,
                                  currency: item.total.currency,
                                  minimumFractionDigits: 2,
                              })
                            : translate('global.notavailable')}
                    </Td>
                </>
            ) : null}
        </Tr>
    );
};

ProductRow.propTypes = {
    item: PropTypes.object,
    position: PropTypes.number,
    showPrices: PropTypes.bool,
};

export const Products = ({ items, showPrices }) => {
    return items.map((item, index) => (
        <ProductRow key={index} item={item} position={index + 1} showPrices={showPrices} />
    ));
};

Products.propTypes = {
    items: PropTypes.array,
    showPrices: PropTypes.bool,
};
