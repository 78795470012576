import { queries } from '../common/api/queryKeys/queries';
import { usePaginationQuery } from '../common/hooks/usePaginationQuery';

export const useAgentPagination = ({ portIds, customerIds, searchQuery, page, active }) => {
    return usePaginationQuery({
        queryKey: queries.agents.pagination({ portIds, customerIds, searchQuery, page, active }).queryKey,
        queryFn: queries.agents.pagination({ portIds, customerIds, searchQuery, page, active }).queryFn,
        prefetchQueryKey: queries.agents.pagination({ page: page + 1, portIds, customerIds, searchQuery, active })
            .queryKey,
        prefetchQueryFn: queries.agents.pagination({ page: page + 1, portIds, customerIds, searchQuery, active })
            .queryFn,
    });
};
