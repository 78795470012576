import { useEffect, useState } from 'react';

import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';
import { Button } from '../../Button/Button';
import { Checkbox } from '../../Checkbox/Checkbox';
import { Text } from '../../Text/Text';
import { FilterPill, FilterPillProps } from '../FilterPill/FilterPill';

import { getFilterValueLabel } from './util/getFilterValueLabel';

export interface FilterMultiSelectOption<T extends string> {
    label: string;
    value: T;
}

export interface FilterMultiSelectShortProps<T extends string> {
    label: FilterPillProps['label'];
    filterByLabel: string;
    options: FilterMultiSelectOption<T>[];
    values?: T[];
    onChange?: (values: T[]) => void;
}

export function FilterMultiSelectShort<T extends string>({
    label,
    filterByLabel,
    values,
    options,
    onChange,
}: FilterMultiSelectShortProps<T>) {
    const [visibleOptions, setVisibleOptions] = useState<T[]>(values || []);

    useEffect(() => {
        setVisibleOptions(values || []);
    }, [values]);

    const sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label));

    return (
        <FilterPill
            label={label}
            value={getFilterValueLabel(options, visibleOptions)}
            onClear={() => onChange?.([])}
            onPopoverClose={() => {
                setVisibleOptions(values || []);
            }}
        >
            {(closePopover) => (
                <Box padding="12px" display="flex" flexDirection="column" gap={5}>
                    <Text variant="body" fontWeight="semiBold">
                        {filterByLabel}
                    </Text>
                    <Box display="flex" flexDirection="column" gap={4}>
                        {sortedOptions.map((option, index) => {
                            const isChecked = visibleOptions.find((it) => it === option.value) !== undefined;

                            return (
                                <Checkbox
                                    key={index}
                                    label={option.label}
                                    onChange={() => {
                                        if (!isChecked) {
                                            setVisibleOptions([...visibleOptions, option.value]);
                                        } else {
                                            setVisibleOptions(visibleOptions.filter((it) => it !== option.value));
                                        }
                                    }}
                                    checked={isChecked}
                                />
                            );
                        })}
                    </Box>
                    <Button
                        type="submit"
                        width="100%"
                        emphasis="high"
                        onClick={() => {
                            onChange?.(visibleOptions);
                            closePopover();
                        }}
                    >
                        {translate('global.filterMultiSelect.apply')}
                    </Button>
                </Box>
            )}
        </FilterPill>
    );
}
