import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconChartLine = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M15.945 5.002a1 1 0 0 1 .762.29l4 4a1 1 0 0 1-1.414 1.415l-3.21-3.21-3.302 4.128a1 1 0 0 1-1.228.27l-3.21-1.606-3.511 5.266a1 1 0 0 1-1.664-1.11l4-6a1 1 0 0 1 1.28-.34l3.276 1.639 3.495-4.369a1 1 0 0 1 .726-.373ZM3 19a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Z"
            clipRule="evenodd"
        />
    </svg>
);
