import { DemandInformationCategory, DemandInformationVessel } from '../../../../types/DemandInformationMessage';
import { ProductContext } from '../../../../types/ProductContext';
import { HumanReadableEnumValue } from '../../../common/enums/HumanReadableEnumValue';
import { translate } from '../../../common/helpers/translate.helper';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { Box } from '../../../common/ui/Box';
import { DemandLink } from '../DemandLink';

interface ProductValueProps {
    row: DemandInformationCategory;
    vessel: DemandInformationVessel;
}

const getDemandLinkPath = (context: ProductContext) => {
    switch (context) {
        case ProductContext.FUEL:
            return 'base.fuel-offerlist-offer';
        case ProductContext.LUBES:
            return 'base.offerlist-offer';
    }
};

export const ProductValue = ({ row, vessel }: ProductValueProps) => {
    const { context: productContext } = useProductContext();

    const offerId = vessel.offers.find((offer) =>
        offer.items.some((item) => {
            switch (productContext) {
                case ProductContext.FUEL:
                    return item.productGroup === row.productGroup && item.sulphurContent === row.sulphurContent;
                case ProductContext.LUBES:
                    return item.tankCategory === row.tankCategory;
            }
        })
    )?.id;

    return (
        <Box display="flex">
            {productContext === ProductContext.FUEL ? (
                <>
                    <HumanReadableEnumValue enumType="productGroup" value={row.productGroup} />{' '}
                    <HumanReadableEnumValue enumType="sulphurContent" value={row.sulphurContent} />
                </>
            ) : (
                <HumanReadableEnumValue enumType="vesselTankCategory" value={row.tankCategory} />
            )}
            {offerId ? (
                <Box marginLeft={4}>
                    <DemandLink
                        variant="extraSmall"
                        fontWeight="medium"
                        to={getDemandLinkPath(productContext)}
                        params={{ id: offerId }}
                    >
                        {translate('demand.showOrder')}
                    </DemandLink>
                </Box>
            ) : null}
        </Box>
    );
};
