import { components, MenuListProps } from 'react-select';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const CustomPlaceholder = styled(components.Placeholder)<MenuListProps>`
    && {
        font-size: 16px;
        color: ${get('colors.foreground.subtle')};
        font-weight: ${get('fontWeights.medium')};
    }
`;
