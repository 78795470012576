import { useField } from 'formik';
import React from 'react';

import { Select } from '../../../../cdl/Select/Select';
import { sortLubesCategory } from '../../../../common/helpers/sortByCategory.helper';
import { useEnums } from '../../../../common/hooks/useEnums';

interface EnumType {
    enums: {
        vesselTankCategory: {
            [enumValue: string]: {
                humanReadableValue: string;
            };
        };
    };
}

interface FormikProductCategorySelectProps {
    name: string;
    label?: string;
}

export const FormikProductCategorySelect = ({ name, label }: FormikProductCategorySelectProps) => {
    const [field, meta, helpers] = useField(name);

    const { enums }: EnumType = useEnums();

    const categoryOptions = Object.keys(enums.vesselTankCategory)
        .sort(sortLubesCategory)
        .map((key) => {
            return {
                label: enums.vesselTankCategory[key].humanReadableValue,
                value: key,
            };
        });

    const selectedValue = categoryOptions.find((option) => option.value === field.value);

    return (
        <Select
            {...field}
            value={selectedValue}
            label={label}
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : undefined}
            onChange={(updatedValue) => helpers.setValue(updatedValue?.value)}
            options={categoryOptions}
        />
    );
};
