import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { createVesselBudget } from '../../../common/api/clients/vesselBudgets.api';
import { queries } from '../../../common/api/queryKeys/queries';
import { translate } from '../../../common/helpers/translate.helper';

export const useVesselBudgetCreate = (vesselId) => {
    const queryClient = useQueryClient();
    const { addToast, addErrorToast } = useToasts();

    return useMutation({
        mutationFn: createVesselBudget,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queries.vessels.detail(vesselId)._ctx.budgets.queryKey });

            addToast(translate('budgets.toast.createBudget.success'));
        },
        onError: () => {
            addErrorToast(translate('budgets.toast.createBudget.error'));
        },
    });
};
