import { components, MenuListProps } from 'react-select';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const CustomMenuList = styled(components.MenuList)<MenuListProps>`
    && {
        background: ${get('colors.background.default')};
        margin-top: ${get('space.2')};
        border-radius: ${get('radii.1')}px;
        padding: ${get('space.2')}px;
        box-shadow: ${get('shadows.large')};
    }
`;
