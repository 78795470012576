import { ProductContext } from '../../../../types/ProductContext';
import { ProductUnit } from '../../../../types/ProductUnit';
import { Button } from '../../../cdl/Button/Button';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../../common/helpers/translate.helper';
import { useProductCreate } from '../hooks/useProductCreate';

import { ProductFormState } from './ProductBaseForm';
import { ProductForm } from './ProductForm';

export const CreateProductButton = () => {
    const { push, pop } = useDrawer();
    const { addToast, addErrorToast } = useToasts();
    const productCreateMutation = useProductCreate();

    const onSubmit = async (values: ProductFormState) => {
        return productCreateMutation.mutateAsync(
            {
                supplierProductGroup: values.supplierProductGroup,
                name: values.name,
                group: values.group,
                packTypeDefault: values.packTypeDefault,
                unitDefault: values.unitDefault as ProductUnit,
                type: ProductContext.LUBES,
            },
            {
                onSuccess: () => {
                    addToast(translate('productsOverview.createSuccessToast'));
                    pop();
                },
                onError: () => {
                    addErrorToast(translate('productsOverview.createErrorToast'));
                },
            }
        );
    };

    const onCreateButtonClick = () => {
        push({
            content: (
                <ProductForm
                    onSubmit={onSubmit}
                    title={translate('productsOverview.createDrawerTitle')}
                    initialState={{
                        supplierProductGroup: '',
                        name: '',
                        group: '',
                        packTypeDefault: '',
                        unitDefault: '',
                    }}
                />
            ),
        });
    };

    return (
        <Button emphasis="high" onClick={onCreateButtonClick}>
            {translate('productsOverview.create')}
        </Button>
    );
};
