import { translate } from '../../helpers/translate.helper';
import { SidebarNavigationItem } from '../components/SidebarNavigationItem';

export const LubesAdminSidebarItems = () => {
    return (
        <>
            <SidebarNavigationItem to="base.dashboard">{translate('navigation.dashboard')}</SidebarNavigationItem>

            <SidebarNavigationItem to="base.orderlist">{translate('navigation.orders')}</SidebarNavigationItem>

            <SidebarNavigationItem to="base.offerlist">{translate('navigation.offers')}</SidebarNavigationItem>

            <SidebarNavigationItem to="base.key-ports">{translate('navigation.keyPorts')}</SidebarNavigationItem>

            <SidebarNavigationItem to="base.lubes-demand-overview">
                {translate('navigation.demandOverview')}
            </SidebarNavigationItem>

            <SidebarNavigationItem to="base.reporting">{translate('navigation.reporting')}</SidebarNavigationItem>

            <SidebarNavigationItem to="base.compare">{translate('navigation.compare')}</SidebarNavigationItem>

            <SidebarNavigationItem to="base.products">{translate('navigation.products')}</SidebarNavigationItem>

            <SidebarNavigationItem to="base.integrations">{translate('navigation.integrations')}</SidebarNavigationItem>
        </>
    );
};
