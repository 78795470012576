import { IconSelector } from '../../../common/icons/cdl/Selector';
import { Box } from '../../../common/ui/Box';

interface CustomDropdownIndicatorProps {
    isDisabled: boolean;
}

export const CustomDropdownIndicator = ({ isDisabled }: CustomDropdownIndicatorProps) => (
    <Box color={isDisabled ? 'foreground.disabled' : 'foreground.subtle'}>
        <IconSelector />
    </Box>
);
