import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconWorldOff = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M7.5 3.067A10 10 0 0 1 20.937 16.49 1 1 0 0 1 19.045 16H19a1 1 0 1 1 0-2h.749a8.002 8.002 0 0 0 .002-4h-2.845c.062.648.094 1.317.094 2a1 1 0 1 1-2 0c0-.693-.037-1.362-.104-2H13a1 1 0 1 1 0-2h1.558a11.24 11.24 0 0 0-.643-1.958c-.328-.738-.693-1.273-1.047-1.61C12.518 4.099 12.228 4 12 4c-.426 0-1.118.4-1.79 1.776a1 1 0 1 1-1.797-.878 9.07 9.07 0 0 1 .033-.068A1 1 0 1 1 7.5 3.067Zm8.051 1.758c.067.132.13.267.192.405.36.81.652 1.747.864 2.77h2.328a8 8 0 0 0-3.384-3.175ZM2.293 2.293a1 1 0 0 1 1.414 0L6.205 4.79c.054.036.105.078.153.126l9.823 9.85 5.526 5.526a1 1 0 0 1-1.414 1.414l-1.96-1.96a10.001 10.001 0 0 1-14.08-14.08l-1.96-1.96a1 1 0 0 1 0-1.414Zm3.382 4.796A8 8 0 0 0 5.053 8h1.533l-.911-.91ZM7.095 10H4.22a8 8 0 0 0-.024 4h2.898A21.017 21.017 0 0 1 7 12.002c-.002-.67.03-1.337.094-2.002Zm.298 6H4.999a7.999 7.999 0 0 0 3.491 3.258 9.738 9.738 0 0 1-.233-.488A13.71 13.71 0 0 1 7.393 16Zm8.147 3.198a7.995 7.995 0 0 0 1.368-.87l-.722-.724a11.317 11.317 0 0 1-.646 1.594Zm-6.484-8.674c-.039.49-.058.981-.056 1.473V12c0 .693.036 1.362.104 2h3.424l-3.472-3.476ZM14.526 16H9.441c.174.732.393 1.392.644 1.958.328.738.693 1.273 1.047 1.61.35.333.64.432.868.432.328 0 .819-.224 1.367-1.036.47-.695.889-1.699 1.185-2.938L14.526 16Z"
            clipRule="evenodd"
        />
    </svg>
);
