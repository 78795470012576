import { Box, BoxProps } from '../ui/Box';

interface DividerProps
    extends Pick<
        BoxProps,
        'width' | 'margin' | 'marginX' | 'marginY' | 'marginLeft' | 'marginTop' | 'marginRight' | 'marginBottom'
    > {}

export const Divider = (props: DividerProps) => {
    return <Box height="1px" backgroundColor="border.muted" width="100%" {...props} />;
};
