import PropTypes from 'prop-types';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../../common/helpers/translate.helper';

import { SimpleConfirmPopup } from './components/SimpleConfirmPopup';

export const ConfirmOrderPopup = ({ onConfirm, onDismiss, isOpen, isSubmitting }) => {
    const { addToast, addErrorToast } = useToasts();

    const onSubmit = async () => {
        try {
            await onConfirm();
            addToast(translate('alert.state.CONFIRMED.title'));
        } catch {
            addErrorToast(translate('global.genericToastError'));
        }
    };

    return (
        <SimpleConfirmPopup
            isOpen={isOpen}
            onSubmit={onSubmit}
            onDismiss={onDismiss}
            headline={translate('alert.confirmorder.title')}
            description={translate('alert.confirmorder.message')}
            buttonText={translate('alert.confirmorder.btnok')}
            isSubmitting={isSubmitting}
        />
    );
};

ConfirmOrderPopup.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
};
