import { useFormikContext } from 'formik';

import { useFormikDirtyWarning } from '../../common/form-elements/formik/useFormikDirtyWarning';

import { FirstStep } from './FirstStep';
import { useEnquiryCreation } from './hooks/useEnquiryCreation';
import { SecondStep } from './SecondStep';
import { ThirdStep } from './ThirdStep';
import { useDebouncedValidate } from './useDebouncedValidate';

export const FuelEnquiryFormStep = () => {
    const { page } = useEnquiryCreation();
    const formik = useFormikContext();

    useFormikDirtyWarning();
    useDebouncedValidate({
        values: formik.values,
        validate: formik.validateForm,
        debounceTime: 200,
        page,
    });

    const selectComponent = (page) => {
        switch (page) {
            case 1:
                return FirstStep;
            case 2:
                return SecondStep;
            case 3:
                return ThirdStep;
            default:
                return FirstStep;
        }
    };

    const Component = selectComponent(page);

    return <Component {...formik} />;
};
