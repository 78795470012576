import { useQuery } from '@tanstack/react-query';

import { getContracts } from '../../common/api/clients/gateway.api';
import { queryKeys } from '../../common/api/queryKeys';

import { ContractTab, contractTabFilter } from './contractTabsHelper';

export const useContractPaginationTotals = ({ searchQuery, customerGroupIds }) => {
    const fetchTotals = ({ searchQuery, customerGroupIds }) => {
        return Promise.all(
            Object.values(ContractTab).map((tab) =>
                getContracts({
                    searchQuery,
                    customerGroupIds,
                    tender: contractTabFilter[tab].tender,
                    active: contractTabFilter[tab].active,
                })
            )
        )
            .then((values) => values.map((value) => value.total))
            .then((contractTotals) => {
                return Object.values(ContractTab).reduce((acc, value, index) => {
                    acc[value] = contractTotals[index];
                    return acc;
                }, {});
            });
    };

    return useQuery({
        queryKey: queryKeys.contractsTotals({
            searchQuery,
            customerGroupIds,
        }),
        queryFn: () =>
            fetchTotals({
                searchQuery,
                customerGroupIds,
            }),
        refetchOnWindowFocus: false,
    });
};
