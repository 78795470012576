import { useState } from 'react';

import { BunkerSchedule } from '../../../../../../types/BunkerCaseSolutionResponse';
import { Collapsable } from '../../../../../cdl/Collapsable/Collapsable';
import { Box } from '../../../../../common/ui/Box';

import { BunkerSolutionStopAccordionHeader } from './BunkerSolutionStopAccordionHeader';
import { BunkerSolutionStopTable } from './BunkerSolutionStopTable/BunkerSolutionStopTable';

interface BunkerStopAccordionProps {
    bunkerSchedule: BunkerSchedule;
    purchaseCategories: Map<string, { productCategory: string; sulphurContent: string }>;
}

export const BunkerSolutionStopAccordion = ({ bunkerSchedule, purchaseCategories }: BunkerStopAccordionProps) => {
    const includesPurchasesAtStop = bunkerSchedule.purchases.some((purchase) => purchase.quantity > 0);
    const [isOpen, setIsOpen] = useState(includesPurchasesAtStop);

    return (
        <Collapsable
            isOpen={isOpen}
            header={
                <BunkerSolutionStopAccordionHeader
                    bunkerSchedule={bunkerSchedule}
                    purchaseCategories={purchaseCategories}
                    isOpen={isOpen}
                    includesPurchasesAtStop={includesPurchasesAtStop}
                    onClick={() => setIsOpen((currentIsOpen) => !currentIsOpen)}
                />
            }
        >
            {() => (
                <Box paddingY={4} paddingX={5}>
                    <BunkerSolutionStopTable bunkerSchedule={bunkerSchedule} />
                </Box>
            )}
        </Collapsable>
    );
};
