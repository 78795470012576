import styled from 'styled-components';

import { Modal } from '../../../cdl/Modal/Modal';
import { Text } from '../../../cdl/Text/Text';
import { TextButton } from '../../../common/buttons/TextButton';
import { Error } from '../../../common/Error/Error';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { IconDownload } from '../../../common/icons/cdl/Download';
import { Box } from '../../../common/ui/Box';
import { get } from '../../../common/ui/get';
import { theme } from '../../../common/ui/theme';
import { usePriceLists } from '../hooks/usePriceLists';

const PriceListGrid = styled.div`
    display: grid;
    grid-template-rows: min-content min-content;
    grid-template-columns: 24px 1fr;
    width: 100%;
    column-gap: ${get('space.4')}px;
`;

interface PriceListPopupProps {
    isOpen: boolean;
    onDismiss: () => void;
    supplierGroupId: string;
    customerId: string;
}

export const PriceListPopup = ({ isOpen, onDismiss, supplierGroupId, customerId }: PriceListPopupProps) => {
    const priceListQuery = usePriceLists({
        supplierGroupId,
        customerId,
    });

    if (priceListQuery.isPending) {
        return null;
    }

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} scrollable={false}>
            <Box padding={6} display="grid" gap={6}>
                <Text variant="title">{translate('assignments.priceListPopup.title')}</Text>
                {priceListQuery.isError ? <Error /> : null}
                {priceListQuery.isSuccess
                    ? priceListQuery.data
                          .sort(
                              (a, b) =>
                                  new Date(b.file.lastModified).getTime() - new Date(a.file.lastModified).getTime()
                          )
                          .map((priceList, index) => {
                              return (
                                  <PriceListGrid key={index}>
                                      <Box gridColumn="1/3">
                                          <a
                                              href={priceList.downloadUrl}
                                              download
                                              target="_blank"
                                              rel="noreferrer"
                                              style={{ display: 'flex', gap: theme.space[4], textDecoration: 'none' }}
                                          >
                                              <IconDownload
                                                  height={24}
                                                  width={24}
                                                  color={theme.colors.accent.foreground}
                                              />

                                              <TextButton
                                                  style={{
                                                      wordBreak: 'break-all',
                                                  }}
                                              >
                                                  {priceList.file.filename}
                                              </TextButton>
                                          </a>
                                      </Box>
                                      <Box gridColumn="2/3">
                                          <Text variant="extraSmall" color="foreground.muted">
                                              {translate('assignments.priceListPopup.uploaded', {
                                                  date: formatDateTime({ date: priceList.file.lastModified }),
                                              })}
                                          </Text>
                                      </Box>
                                  </PriceListGrid>
                              );
                          })
                    : null}
            </Box>
        </Modal>
    );
};
