import { createdBy } from '../../common/helpers/createdBy.helper';
import { formatDate } from '../../common/helpers/formatDate.helper';
import { formatDateTime } from '../../common/helpers/formatDateTime.helper';
import { formatVessel } from '../../common/helpers/formatVessel.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { usePaymentTermsFormat } from '../../common/hooks/usePaymentTermsFormat';
import { Port } from '../../port/Port';

export const useApprovalOfferFields = () => {
    const { getHumanReadableValue } = useEnums();
    const formatPaymentTerms = usePaymentTermsFormat();

    return {
        vessel: {
            label: translate('offer.detail.coreData.vessel'),
            path: 'vessel',
            render: (vessel) => {
                return formatVessel({ vessel });
            },
        },
        customer: {
            path: 'customer.name',
            label: translate('offer.detail.coreData.company'),
        },
        supplier: {
            path: 'supplier.name',
            label: translate('offer.detail.coreData.supplier'),
        },
        agent: {
            label: translate('offer.detail.coreData.agent'),
            path: 'agentInformation.name',
        },
        legacyAgent: {
            label: translate('order.detail.coreData.agent'),
            path: 'agent',
        },
        dateDelivery: {
            path: 'dateDelivery',
            label: translate('offer.detail.coreData.dateDelivery'),
            render: (date) => {
                if (!date) {
                    return null;
                }

                return formatDate({ date, timeZone: 'UTC' });
            },
        },
        eta: {
            label: translate('order.detail.coreData.eta'),
            render: (date, _, offer) => {
                if (!offer.eta) {
                    return null;
                }
                return formatDateTime({
                    date: offer.eta,
                    timeZoneId: offer.port.timeZoneId,
                });
            },
        },
        etd: {
            label: translate('order.detail.coreData.etd'),
            render: (date, _, offer) => {
                if (!offer.etd) {
                    return null;
                }
                return formatDateTime({
                    date: offer.etd,
                    timeZoneId: offer.port.timeZoneId,
                });
            },
        },
        port: {
            label: translate('offer.detail.coreData.port'),
            render: function renderPort(port, _, offer) {
                return <Port port={offer.port} vesselId={offer.vesselId} showTooltip />;
            },
        },
        created: {
            path: 'dateCreated',
            label: translate('offer.detail.coreData.dateCreated'),
            render: (date, _, offer) => {
                return createdBy({ order: offer });
            },
        },
        buyerReference: {
            path: 'buyerReference',
            label: translate('offer.detail.coreData.buyerReference'),
        },
        supplyMode: {
            path: 'supplyMode',
            label: translate('offer.detail.coreData.supplyMode'),
            render: (supplyMode) => {
                if (!supplyMode) {
                    return null;
                }
                return getHumanReadableValue('supplyMode', supplyMode);
            },
        },
        noticeDays: {
            path: 'noticeDays',
            label: translate('offer.detail.coreData.noticeDays'),
        },
        paymentTerms: {
            path: 'paymentTermReference',
            label: translate('offer.detail.coreData.paymentTermsLabel'),
            render: (paymentTermReference, _, offer) => {
                if (!paymentTermReference) {
                    return '-';
                }
                return formatPaymentTerms(paymentTermReference, offer.paymentTermReferenceDays);
            },
        },
        offerNumber: {
            path: 'offerNumber',
            label: translate('offer.detail.coreData.offerNumber'),
        },
    };
};
