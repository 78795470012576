import { useState } from 'react';

import { Button } from '../cdl/Button/Button';
import { Callout } from '../cdl/Callout/Callout';
import { DatePicker } from '../cdl/DatePicker/DatePicker';
import { Input } from '../cdl/Input/Input';
import { Text } from '../cdl/Text/Text';
import { useToasts } from '../cdl/Toast/useToasts';
import { confirmOffer, exportOffer, importPurchaseRequest } from '../common/api/clients/maersk.api';
import { translate } from '../common/helpers/translate.helper';
import { Box } from '../common/ui/Box';

import { IntegrationOfferSelect } from './IntegrationOfferSelect';

export const MaerskPageContent = () => {
    return (
        <div>
            <Callout
                heading={translate('integrationsOverview.calloutMaersk.headline')}
                description={translate('integrationsOverview.calloutMaersk.description')}
            />
            <Box display="grid" rowGap={8} maxWidth="400px" marginTop={6}>
                <ExportSection />
                <ConfirmSection />
                <ImportSection />
            </Box>
        </div>
    );
};

const ExportSection = () => {
    const [offerId, setOfferId] = useState('');
    const { addToast } = useToasts();

    return (
        <Box display="flex" gap={4} flexDirection="column" alignItems="flex-start">
            <Text variant="title" as="h2">
                {translate('integrationsOverview.exportTitle')}
            </Text>

            <Text variant="small" color="foreground.subtle">
                {translate('integrationsOverview.exportDescription')}
            </Text>

            <Box width="100%">
                <IntegrationOfferSelect
                    label={translate('integrationsOverview.selectLabel')}
                    value={offerId}
                    onChange={(option) => setOfferId(option.value)}
                />
            </Box>

            <Button
                disabled={!offerId}
                onClick={() => {
                    exportOffer(offerId);
                    addToast(translate('integrationsOverview.exportTriggered'));
                }}
            >
                {translate('integrationsOverview.exportButtonText')}
            </Button>
        </Box>
    );
};

const ConfirmSection = () => {
    const [offerId, setOfferId] = useState('');
    const [customerId, setCustomerId] = useState('');
    const { addToast } = useToasts();

    return (
        <Box display="flex" gap={4} flexDirection="column" alignItems="flex-start">
            <Text variant="title" as="h2">
                {translate('integrationsOverview.confirmTitle')}
            </Text>

            <Box width="100%">
                <IntegrationOfferSelect
                    label={translate('integrationsOverview.selectLabel')}
                    value={offerId}
                    onChange={(option) => {
                        setOfferId(option.value);
                        setCustomerId(option.offer.customerId);
                    }}
                />
            </Box>

            <Button
                disabled={!offerId}
                onClick={() => {
                    confirmOffer(offerId, customerId);
                    addToast(translate('integrationsOverview.confirmTriggered'));
                }}
            >
                {translate('integrationsOverview.confirmButtonText')}
            </Button>
        </Box>
    );
};

const ImportSection = () => {
    const [purchaseRequestCode, setPurchaseRequestCode] = useState('');
    const [date, setDate] = useState<Date | undefined>(undefined);
    const { addToast } = useToasts();

    return (
        <Box display="flex" gap={3} flexDirection="column" alignItems="flex-start">
            <Text variant="title" as="h2">
                {translate('integrationsOverview.importTitle')}
            </Text>

            <Box width="100%">
                <DatePicker
                    label={translate('integrationsOverview.datePicker')}
                    date={date}
                    onDateChange={(date) => setDate(date)}
                />

                <Input
                    label={translate('integrationsOverview.code')}
                    value={purchaseRequestCode}
                    onChange={(e) => setPurchaseRequestCode(e.target.value)}
                />
            </Box>

            <Button
                disabled={!purchaseRequestCode || !date}
                emphasis="medium"
                onClick={() => {
                    importPurchaseRequest(purchaseRequestCode, date || new Date());
                    addToast(translate('integrationsOverview.importTriggered'));
                }}
            >
                {translate('integrationsOverview.importButtonText')}
            </Button>
        </Box>
    );
};
