import PropTypes from 'prop-types';

import { SelectBox } from '../../../common/SelectBox/SelectBox';
import { PriceBoxContent } from '../PriceBoxContent';

export const ComparePriceButton = ({ selected, price, onClick }) => {
    return (
        <SelectBox onClick={onClick} selected={selected} height="220px">
            <PriceBoxContent price={price} selected={selected} />
        </SelectBox>
    );
};

ComparePriceButton.propTypes = {
    selected: PropTypes.bool,
    price: PropTypes.object,
    onClick: PropTypes.func.isRequired,
};
