import { Formik } from 'formik';

import { CounterOffer } from '../../../../../../types/CounterOffer';
import { Modal } from '../../../../../cdl/Modal/Modal';
import { Text } from '../../../../../cdl/Text/Text';
import { translate } from '../../../../../common/helpers/translate.helper';
import { LabelText } from '../../../../../common/LabelText/LabelText';
import { Box } from '../../../../../common/ui/Box';
import { CircleValidity } from '../../../../../common/Validity/CircleValidity';

import { CounterOfferReviewForm, CounterOfferReviewFormState } from './CounterOfferReviewForm';

interface CounterOfferReviewPopupProps {
    onDismiss: () => void;
    onUpdate: () => void;
    onSubmit: (values: CounterOfferReviewFormState) => void;
    data: CounterOfferReviewFormState;
    open: boolean;
    latestCounterOffer: CounterOffer;
    isCounterOfferExpired: boolean;
}

export const CounterOfferReviewPopup = ({
    onDismiss,
    data,
    onSubmit,
    open,
    latestCounterOffer,
    isCounterOfferExpired,
    onUpdate,
}: CounterOfferReviewPopupProps) => {
    return (
        <Modal isOpen={open} onDismiss={onDismiss} dismissible={true}>
            <Box padding={6} display="grid" gridRowGap={5}>
                <Text variant="title">{translate('counterOffer.reviewPopup.headline')}</Text>

                <Text variant="small" color="foreground.muted">
                    {translate('counterOffer.reviewPopup.text')}
                </Text>

                {!!latestCounterOffer.validUntil && !!latestCounterOffer.validityTimeMinutes ? (
                    <Box>
                        <LabelText>{translate('counterOffer.reviewPopup.validityHeadline')}</LabelText>
                        <CircleValidity
                            validUntil={latestCounterOffer.validUntil}
                            validityTime={latestCounterOffer.validityTimeMinutes}
                        />
                    </Box>
                ) : null}

                <Formik
                    initialValues={data}
                    onSubmit={onSubmit}
                    component={(props) => (
                        <CounterOfferReviewForm
                            onUpdate={onUpdate}
                            isCounterOfferExpired={isCounterOfferExpired}
                            {...props}
                        />
                    )}
                />
            </Box>
        </Modal>
    );
};
