import { Chart as ChartJS, ChartData, Filler, LineElement, PointElement } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { AverageConsumption } from '../../../../../types/AverageConsumption';
import { LubesConsumptionPreset } from '../../../../../types/LubesConsumptionPresetsRequestMessage';
import { theme } from '../../../../common/ui/theme';
import { useChartOptions } from '../hooks/useChartOptions';
import { useChartPlugins } from '../hooks/useChartPlugins';
import { useChartUtils } from '../hooks/useChartUtils';
import { generateDaysArrayFromRange } from '../utils/generateDaysArrayFromRange';
import { mapAverageConsumptionToRange } from '../utils/mapAverageConsumptionToRange';

ChartJS.register(PointElement, LineElement, Filler);

interface AverageConsumptionLineChartProps {
    consumptions: AverageConsumption[];
    preset: LubesConsumptionPreset;
    dateRange: Date[];
    onActiveElementChange: (averageConsumption?: AverageConsumption) => void;
}

export const AverageConsumptionLineChart = ({
    consumptions,
    dateRange,
    preset,
    onActiveElementChange,
}: AverageConsumptionLineChartProps) => {
    const daysArrayFromRange: Date[] = generateDaysArrayFromRange(dateRange);
    const dailyConsumptionDataOfRange: (AverageConsumption | undefined)[] = mapAverageConsumptionToRange({
        consumptions,
        daysArrayFromRange,
    });

    const dailyConsumptionsOfRange = dailyConsumptionDataOfRange.map((averageConsumption) =>
        preset.excludePortCalls
            ? averageConsumption?.averageConsumptionExclDaysAtPort
            : averageConsumption?.averageConsumption
    );

    const isFirstValueMissing = dailyConsumptionsOfRange.at(0) === undefined;
    const firstValidValue = dailyConsumptionsOfRange.find((value) => value !== undefined);
    const indexOfFirstValidValue = dailyConsumptionsOfRange.indexOf(firstValidValue);

    const isLastValueMissing = dailyConsumptionsOfRange.at(-1) === undefined;
    const indexOfLastValidValue = dailyConsumptionsOfRange.findLastIndex((value) => value !== undefined);

    const handleActiveElementChange = (consumptionIndex?: number) => {
        if (!consumptionIndex) {
            onActiveElementChange();
            return;
        }

        const consumptionOfDay = dailyConsumptionDataOfRange.at(consumptionIndex);
        onActiveElementChange(consumptionOfDay ?? undefined);
    };

    const { getSegmentBorderColor, getSegmentBorderDash, getRadius } = useChartUtils({
        isFirstValueMissing,
        indexOfFirstValidValue,
        isLastValueMissing,
        indexOfLastValidValue,
    });

    const chartPlugins = useChartPlugins({
        onActiveElementChange: handleActiveElementChange,
        isFirstValueMissing,
        isLastValueMissing,
    });

    const chartOptions = useChartOptions({
        onActiveElementChange: handleActiveElementChange,
        isLastValueMissing,
        isFirstValueMissing,
        consumptions,
        preset,
    });

    // Replace the first missing value with the first valid value
    if (isFirstValueMissing) {
        dailyConsumptionsOfRange.splice(0, 1, firstValidValue);
    }

    // Replace the last missing value with the last valid value
    if (isLastValueMissing) {
        dailyConsumptionsOfRange.splice(-1, 1, dailyConsumptionsOfRange[indexOfLastValidValue]);
    }

    const data: ChartData<'line', number[], Date> = {
        labels: daysArrayFromRange,
        datasets: [
            {
                label: 'dynamic',
                data: dailyConsumptionsOfRange as number[],
                fill: true,
                segment: {
                    borderColor: (ctx) => getSegmentBorderColor(ctx),
                    borderDash: (ctx) => getSegmentBorderDash(ctx),
                },
                spanGaps: true,
                pointRadius: (ctx) => getRadius(ctx),
                pointHitRadius: (ctx) => getRadius(ctx),
                pointHoverRadius: (ctx) => getRadius(ctx),
                pointBackgroundColor: 'transparent',
                pointBorderColor: 'transparent',
                pointBorderWidth: 2,
                pointHoverBackgroundColor: theme.colors.accent.emphasis,
                pointHoverBorderColor: theme.colors.background.default,
                pointHoverBorderWidth: 2,
            },
        ],
    };

    if (preset.fixedAverageDailyConsumption) {
        const fixedAverageConsumptionOfRange: number[] = daysArrayFromRange.map((_, index) =>
            index === 0 || index === daysArrayFromRange.length - 1 ? preset.fixedAverageDailyConsumption ?? NaN : NaN
        );

        data.datasets.push({
            label: 'fixed',
            data: fixedAverageConsumptionOfRange,
            spanGaps: true,
            borderColor: theme.colors.border.muted,
            borderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 0,
            pointHoverRadius: 0,
        });
    }

    return (
        <div>
            <Line data={data} options={chartOptions} plugins={chartPlugins} />
        </div>
    );
};
