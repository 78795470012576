import { Checkbox } from '../../cdl/Checkbox/Checkbox';
import { Text } from '../../cdl/Text/Text';
import { Box } from '../../common/ui/Box';

interface ProductCategoryFilterProps {
    productCategories: string[];
    onChange: (category: string) => void;
}

export const ProductCategoryFilter = ({ productCategories, onChange }: ProductCategoryFilterProps) => {
    return (
        <Box display="flex" flexDirection="row" justifyContent="center" columnGap={5}>
            {productCategories
                ? productCategories.map((category) => {
                      return (
                          <Checkbox
                              key={category}
                              label={<Text variant="body">{category}</Text>}
                              onChange={() => onChange(category)}
                          />
                      );
                  })
                : null}
        </Box>
    );
};
