import { ComponentType } from 'react';

import { Text } from '../../cdl/Text/Text';
import { IconColumns } from '../icons/cdl/Columns';
import { Box } from '../ui/Box';

interface TableEmptyStateProps {
    Icon?: ComponentType<any>;
    text: string;
}

export const TableEmptyState = ({ Icon = IconColumns, text }: TableEmptyStateProps) => {
    return (
        <Box color="dark-blue.2">
            <Icon width={32} height={32} style={{ margin: '0 auto' }} />
            <Text marginTop={4} size={14} textAlign="center" as="h5">
                {text}
            </Text>
        </Box>
    );
};
