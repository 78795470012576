import { createContext } from 'react';

import { OfferModel } from '../../../../../../offer/model/OfferModel';
import { OrderModel } from '../../../../../model/OrderModel';

interface OfferCardContextInterface {
    offer: OfferModel;
    order: OrderModel;
}

export const OfferCardContext = createContext<OfferCardContextInterface>({
    offer: {} as OfferModel,
    order: {} as OrderModel,
});
