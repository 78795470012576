import styled from 'styled-components';

import { get } from '../../../common/ui/get';
import { CheckboxProps } from '../Checkbox';

type LabelWrapperProps = Pick<CheckboxProps, 'disabled'>;

export const LabelWrapper = styled.label<LabelWrapperProps>`
    grid-column-start: 1;
    grid-column-end: 3;
    display: grid;
    grid-template-columns: 18px auto;
    grid-column-gap: ${get('space.4')}px;
    position: relative;
    align-items: center;
    user-select: none;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    // required to override global styles
    margin-bottom: 0;
    font-weight: 500;
`;
