import { ReactNode, useState } from 'react';

import { Button } from '../../../cdl/Button/Button';
import { OfferModel } from '../../model/OfferModel';
import { useOfferApprovalSettings } from '../../MultipleApprovers/hooks/useOfferApprovalSettings';
import { RequestApprovalMultipleApproversPopup } from '../../MultipleApprovers/RequestApprovalMultipleApproversPopup';

interface RequestApprovalButtonProps {
    offer: OfferModel;
    children: ReactNode;
}

export const RequestApprovalButton = (props: RequestApprovalButtonProps) => {
    const offerApprovalSettingsQuery = useOfferApprovalSettings(props.offer.id, true);
    const [showPopup, setShowPopup] = useState(false);

    const onRequestApprovalButtonClicked = () => {
        setShowPopup(true);
    };

    const onPopupDismiss = () => {
        setShowPopup(false);
    };

    if (!offerApprovalSettingsQuery.isSuccess) {
        return null;
    }

    return (
        <>
            <Button emphasis="high" onClick={onRequestApprovalButtonClicked}>
                {props.children}
            </Button>

            <RequestApprovalMultipleApproversPopup
                offerApprovalSettings={offerApprovalSettingsQuery.data}
                open={showPopup}
                onDismiss={onPopupDismiss}
                offerId={props.offer.id}
                customerId={props.offer.customerId}
            />
        </>
    );
};
