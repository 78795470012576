import { useField } from 'formik';
import PropTypes from 'prop-types';

import { Callout } from '../../../cdl/Callout/Callout';
import { translate } from '../../helpers/translate.helper';
import { Box } from '../../ui/Box';
import { DateTimePicker } from '../DateTimePicker/DateTimePicker';

export const FormikDateTimePicker = (props) => {
    const [field, meta, helpers] = useField(props.name);

    const onChange = (event) => {
        helpers.setValue(event.target.value);
        props.onChange?.(event.target.value);
    };

    return (
        <Box>
            <DateTimePicker {...props} {...field} value={field.value || ''} onChange={onChange} />
            {meta.touched && meta.error === 'ETD invalid' ? (
                <Callout variant="negative" description={translate('fuelEnquiry.first.etdError')} />
            ) : null}
        </Box>
    );
};

FormikDateTimePicker.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};
