import { useField } from 'formik';

import { SupplierCompanySelect, SupplierCompanySelectProps } from '../CompanySelect/SupplierCompanySelect';

interface FormikSupplierCompanySelectProps<IsMulti extends boolean>
    extends Omit<SupplierCompanySelectProps<IsMulti>, 'onChange' | 'value'> {
    name: string;
}

export function FormikSupplierCompanySelect<IsMulti extends boolean>(props: FormikSupplierCompanySelectProps<IsMulti>) {
    const [field, meta, helpers] = useField(props.name);

    const onChange = (supplierIds: IsMulti extends true ? string[] : string) => {
        helpers.setValue(supplierIds);
    };

    return (
        <SupplierCompanySelect
            {...props}
            onChange={onChange}
            value={field.value}
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : props.caption}
            onBlur={() => {
                helpers.setTouched(true);
            }}
        />
    );
}
