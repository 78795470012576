import { Form, useFormikContext } from 'formik';

import { Button } from '../../../cdl/Button/Button';
import { FormikCheckbox } from '../../../cdl/Checkbox/FormikCheckbox';
import { FormikInput } from '../../../cdl/Input/FormikInput';
import { Text } from '../../../cdl/Text/Text';
import { FormikDebug } from '../../../common/form-elements/formik/FormikDebug';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { Box } from '../../../common/ui/Box';

import { FormikCreateCustomerGroupState } from './CreateCustomerGroupForm';

interface CustomerGroupBaseFormProps {
    title: string;
}

export const CustomerGroupBaseForm = ({ title }: CustomerGroupBaseFormProps) => {
    const { isSubmitting, isValid } = useFormikContext<FormikCreateCustomerGroupState>();
    const role = useRole();

    return (
        <Form style={{ height: '100%' }}>
            <Box display="grid" gridTemplateRows="auto 1fr auto" height="100%" gridTemplateColumns="100%">
                <Box padding={7} paddingBottom={6}>
                    <Text variant="title" marginBottom={6}>
                        {title}
                    </Text>
                </Box>

                <Box paddingX={7} display="flex" flexDirection="column" overflowY="auto" rowGap={5}>
                    <FormikInput name="name" label={translate('company.name')} markAsRequired />
                    <FormikInput name="email" label={translate('company.email')} />
                    <FormikInput name="phone" label={translate('company.phone')} />
                    <FormikInput name="fax" label={translate('company.fax')} />

                    <Text variant="subtitle1" marginTop={5}>
                        {translate('company.address')}
                    </Text>

                    <FormikInput name="address.companyName" label={translate('company.name')} />
                    <Box display="grid" gridTemplateColumns="2fr 1fr" columnGap={5}>
                        <FormikInput name="address.street" label={translate('global.address.street')} />
                        <FormikInput name="address.streetNumber" label={translate('global.address.streetnumber')} />
                    </Box>
                    <Box display="grid" gridTemplateColumns="1fr 2fr" columnGap={5}>
                        <FormikInput name="address.zipcode" label={translate('global.address.zipcode')} />
                        <FormikInput name="address.city" label={translate('global.address.city')} />
                    </Box>
                    <FormikInput name="address.country" label={translate('global.address.country')} />

                    <Text variant="subtitle1" marginTop={5}>
                        {translate('company.information')}
                    </Text>

                    <FormikInput
                        name="gtcUrl"
                        label={translate('company.gtcUrl')}
                        placeholder={translate('company.placeholder.gtcUrl')}
                    />

                    {role.isAdmin() ? (
                        <Box display="flex" flexDirection="column" rowGap={4} alignItems="flex-start">
                            <Text variant="subtitle1" marginTop={5}>
                                {translate('company.settings')}
                            </Text>

                            <FormikCheckbox name="testCompany" label={translate('company.testCompany')} />
                            <FormikCheckbox name="closelinkLite" label={translate('company.closelinkLite')} />
                        </Box>
                    ) : null}
                </Box>

                <Box padding={7}>
                    <Button
                        emphasis="high"
                        type="submit"
                        size="large"
                        disabled={!isValid}
                        isLoading={isSubmitting}
                        width="100%"
                    >
                        {translate('btn.save.default')}
                    </Button>
                </Box>
                <FormikDebug />
            </Box>
        </Form>
    );
};
