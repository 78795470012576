import { AgentMessage } from '../../../../types/AgentMessage';
import { Button } from '../../../cdl/Button/Button';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { translate } from '../../../common/helpers/translate.helper';
import { UpdateAgentForm } from '../../UpdateAgentForm';

interface EditAgentButtonProps {
    agent: AgentMessage;
}

export const EditAgentButton = ({ agent }: EditAgentButtonProps) => {
    const { pop, push } = useDrawer();

    const openPortEditModal = () =>
        push({
            content: <UpdateAgentForm agentId={agent.id} onSuccess={pop} />,
        });

    return (
        <Button emphasis="high" onClick={openPortEditModal}>
            {translate('agents.editButton')}
        </Button>
    );
};
