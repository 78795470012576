import { AxiosPromise } from 'axios';

import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

interface ExportOfferAsPdfParams {
    id: string;
}

interface ExportOrderAsPdfParams {
    id: string;
}

export const exportOfferAsPdf = async ({ id }: ExportOfferAsPdfParams) =>
    authorizedAxiosInstance.post<Blob>(`/v2/export/offer/${id}`, undefined, { responseType: 'blob' });

export const exportOrderAsPdf = async ({ id }: ExportOrderAsPdfParams) =>
    authorizedAxiosInstance.post<Blob>(`/v2/export/order/${id}`, undefined, { responseType: 'blob' });

export enum ReceiverType {
    VESSEL = 'VESSEL',
    AGENT = 'AGENT',
    SURVEYOR = 'SURVEYOR',
    OTHERS = 'OTHERS',
}

export interface ShareOfferRequestMessage {
    receiverEmailAddresses: string[];
    receiverTypes: ReceiverType[];
    copyToSelf: boolean;
}

interface ShareOfferParams {
    offerId: string;
    shareOfferRequestMessage: ShareOfferRequestMessage;
}

export const shareOffer = async ({ offerId, shareOfferRequestMessage }: ShareOfferParams) => {
    const response = await authorizedAxiosInstance.post(`/v2/export/offer/${offerId}/share`, shareOfferRequestMessage);

    return response.data;
};

type Iso8601DateTimeString = string;

interface ExportLubesCustomerReportParams {
    customerGroupId?: string;
    dateDeliveryFrom: Iso8601DateTimeString;
    dateDeliveryTo: Iso8601DateTimeString;
}

export const exportLubesCustomerReport = async (params: ExportLubesCustomerReportParams): AxiosPromise<Blob> => {
    return await authorizedAxiosInstance.post(`/v1/export/customer-reports`, params, {
        responseType: 'blob',
    });
};
