import { memo, useState } from 'react';

import { CounterOffer } from '../../../../../types/CounterOffer';
import { CounterOfferState } from '../../../../../types/CounterOfferState';
import { Button } from '../../../../cdl/Button/Button';
import { translate } from '../../../../common/helpers/translate.helper';
import { useNavigation } from '../../../../common/hooks/useNavigation';
import { useCounterOfferEdit } from '../../../../order/detail/fuel/CounterOffer/hooks/useCounterOfferEdit';
import { useOfferEdit } from '../../../edit/hooks/useOfferEdit';
import { OfferModel } from '../../../model/OfferModel';

import { CounterOfferReviewFormState } from './components/CounterOfferReviewForm';
import { CounterOfferReviewPopup } from './components/CounterOfferReviewPopup';

interface CounterOfferReviewButtonProps {
    latestCounterOffer: CounterOffer;
    offer: OfferModel;
    isCounterOfferExpired: boolean;
}

export const CounterOfferReviewButton = memo(function CounterOfferReviewButton({
    latestCounterOffer,
    offer,
    isCounterOfferExpired,
}: CounterOfferReviewButtonProps) {
    const [showPopup, setShowPopup] = useState(false);
    const { navigate } = useNavigation();
    const counterOfferEditMutation = useCounterOfferEdit();
    const offerEditMutation = useOfferEdit();

    const counterOfferReviewFormState: CounterOfferReviewFormState = {
        state: latestCounterOffer.state,
        responseMessage: '',
        items: latestCounterOffer.items.map((counterOfferItem, index) => {
            const offerItem = offer.items[index];

            return {
                productId: counterOfferItem.productId,
                productName: offerItem.product?.name,
                sulphurContent: offerItem.product?.sulphurContent,
                price: counterOfferItem.price,
                counterPrice: counterOfferItem.counterPrice,
                deleted: counterOfferItem.deleted || false,
            };
        }),
    };

    const onSubmit = async (values: CounterOfferReviewFormState) => {
        await counterOfferEditMutation.mutateAsync({
            counterOfferId: latestCounterOffer.id,
            counterOffer: {
                ...latestCounterOffer,
                state: values.state,
                responseMessage: values.responseMessage || undefined,
            },
        });
        if (values.state === CounterOfferState.ACCEPTED) {
            await offerEditMutation.mutateAsync({
                offerId: offer.id,
                offer: {
                    ...offer,
                    items: offer.items.map((item, index) => {
                        if (item.deleted || values.items[index].deleted) {
                            return item;
                        }
                        return { ...item, price: values.items[index].counterPrice };
                    }),
                },
            });
        }

        setShowPopup(false);
    };

    return (
        <>
            <Button
                emphasis={isCounterOfferExpired ? 'medium' : 'high'}
                onClick={() => {
                    setShowPopup(true);
                }}
            >
                {translate('statusbar.supplier.fuel.counterOfferReceived.button')}
            </Button>

            <CounterOfferReviewPopup
                open={showPopup}
                data={counterOfferReviewFormState}
                onDismiss={() => {
                    setShowPopup(false);
                }}
                onSubmit={onSubmit}
                latestCounterOffer={latestCounterOffer}
                isCounterOfferExpired={isCounterOfferExpired}
                onUpdate={() => {
                    setShowPopup(false);
                    navigate('base.fuel-quote', {
                        id: offer.id,
                    });
                }}
            />
        </>
    );
});
