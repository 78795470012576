import styled from 'styled-components';

import { Button } from '../../cdl/Button/Button';
import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { useStartUserlikeChat } from '../../common/hooks/useStartUserlikeChat';
import { IconChartLine } from '../../common/icons/cdl/ChartLine';
import { Box } from '../../common/ui/Box.tsx';
import { get } from '../../common/ui/get';
import { theme } from '../../common/ui/theme';

const IconWrapper = styled.div`
    background-color: ${get('colors.background.subtle')};
    padding: ${get('space.4')}px;
    border-radius: ${get('radii.2')}px;
`;

export const VesselTanksPlaceholder = () => {
    const startUserLike = useStartUserlikeChat();

    return (
        <Box display="flex" height="100%" justifyContent="center" alignItems="center">
            <Box display="flex" rowGap={4} flexDirection="column" width="70%" maxWidth="500px" alignItems="start">
                <IconWrapper>
                    <IconChartLine color={theme.colors.foreground.subtle} />
                </IconWrapper>
                <Text variant="subtitle1">{translate('vessel.remainingOnBoard.placeholderTitle')}</Text>
                <Text variant="small" color="foreground.subtle">
                    {translate('vessel.remainingOnBoard.placeholder')}
                </Text>
                <Button onClick={startUserLike}>{translate('vessel.remainingOnBoard.contactUs')}</Button>
            </Box>
        </Box>
    );
};
