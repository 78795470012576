import styled from 'styled-components';

import { get } from '../../../common/ui/get';
import { Text } from '../../Text/Text';

interface CollapsableDescriptionProps {
    active?: boolean;
}

export const CollapsableDescription = styled(Text)<CollapsableDescriptionProps>`
    color: ${(props) => (props.active ? get('colors.accent.foreground') : get('colors.foreground.muted'))};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
