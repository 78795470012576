import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { ProductContext } from '../../../../types/ProductContext';
import { getOfferList } from '../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { useAbly } from '../../../common/hooks/useAbly';
import { sortOffers } from '../common/sortOffers';

export const useFuelOrderOffers = (order) => {
    const { subscribe, unsubscribe } = useAbly();

    const types = ['FUEL'];

    const offerListQuery = useQuery({
        queryKey: queryKeys.fuelOrderOffers(order.id),
        queryFn: () => getOfferList({ orderIds: order.id, types }),
        enabled: !!order.id,
        select: (data) => data.sort(sortOffers(ProductContext.FUEL)),
    });

    useEffect(() => {
        const offerTopic = `customers:${order.customerId}:orders:${order.id}:offers`;

        subscribe(offerTopic, () => {
            offerListQuery.refetch();
        });

        return () => {
            unsubscribe(offerTopic);
        };
    }, [offerListQuery, order, subscribe, unsubscribe]);

    return offerListQuery;
};
