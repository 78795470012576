import { useQuery, useQueryClient } from '@tanstack/react-query';
import { UseQueryResult } from '@tanstack/react-query/src/types';

import { PlattsCredentialsResponseMessage } from '../../../../../../types/PlattsCredentialsResponseMessage';
import { getPlattsCredentials } from '../../../../../common/api/clients/plattsCredentials.api';
import { queryKeys } from '../../../../../common/api/queryKeys';

interface PrefetchFunction {
    prefetch: (customerId: string) => void;
}

export const usePlattsCredentials = (
    customerId?: string
): UseQueryResult<PlattsCredentialsResponseMessage | null> & PrefetchFunction => {
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: queryKeys.plattsCredentials(customerId),
        queryFn: () => getPlattsCredentials(customerId),
        enabled: !!customerId,
    });

    const prefetch = (customerId: string) => {
        queryClient.prefetchQuery({
            queryKey: queryKeys.plattsCredentials(customerId),
            queryFn: () => getPlattsCredentials(customerId),
        });
    };

    return {
        ...query,
        prefetch,
    };
};
