import Skeleton from 'react-loading-skeleton';

import { Button } from '../../../cdl/Button/Button';
import { Callout } from '../../../cdl/Callout/Callout';
import { translate } from '../../../common/helpers/translate.helper';
import { IconDiscount2 } from '../../../common/icons/cdl/Discount2';
import { Box } from '../../../common/ui/Box';

import { CompareCalloutProps } from './CompareCallout';
import { DiscoverBestPricesCallout } from './DiscoverBestPricesCallout';
import { usePriceCompare } from './hooks/usePriceCompare';
import { SingleBestPriceCallout } from './SingleBestPriceCallout';

interface CompareScheduleCalloutProps extends CompareCalloutProps {}

export const CompareWithScheduleCallout = ({
    schedules,
    order,
    onCompareClick,
    onApply,
    trackingId,
}: CompareScheduleCalloutProps) => {
    const portIds = Array.from(
        new Set(schedules.filter((schedule) => schedule.port).map((schedule) => schedule.port!.id))
    );

    const priceCompare = usePriceCompare({
        items: order.items.filter((item) => item.isComplete()),
        portIds: [order.portId, ...portIds],
        customerIds: [order.customerId],
    });

    if (!priceCompare.data) {
        return (
            <Callout
                heading={<Skeleton width="250px" />}
                description={<Skeleton width="400px" />}
                leadingVisual={(props) => <IconDiscount2 {...props} />}
            >
                <Box display="flex" gap={4}>
                    <Skeleton width="100px" height="24px" />
                    <Skeleton width="100px" height="24px" />
                </Box>
            </Callout>
        );
    }

    const bestPrices = priceCompare.data.prices.filter((price) => price.bestPrice);

    const firstBestPrice = bestPrices[0];

    const hasSingleBestPrice =
        bestPrices.length === 1 &&
        !(firstBestPrice.portId === order.portId && order.receiverSupplierIds.includes(firstBestPrice.supplierId));

    if (hasSingleBestPrice) {
        return (
            <SingleBestPriceCallout
                onCompareClick={onCompareClick}
                onApply={onApply}
                trackingId={trackingId}
                bestPriceOption={firstBestPrice}
            />
        );
    }

    const hasMultipleBetterPrices =
        bestPrices.length > 1 &&
        !(
            bestPrices.some((price) => price.portId === order.portId) &&
            bestPrices.some((price) => order.receiverSupplierIds.includes(price.supplierId))
        );

    if (hasMultipleBetterPrices) {
        return (
            <Callout
                variant="accent"
                heading={translate('priceCompare.lowerOptionsAvailable.heading')}
                description={translate('priceCompare.lowerOptionsAvailable.description')}
                leadingVisual={(props) => <IconDiscount2 {...props} />}
            >
                <Button type="button" emphasis="high" onClick={onCompareClick}>
                    {translate('priceCompare.compareBtn')}
                </Button>
            </Callout>
        );
    }

    return <DiscoverBestPricesCallout onCompareClick={onCompareClick} />;
};
