import { Form, Formik, FormikErrors } from 'formik';

import { Button } from '../../../../cdl/Button/Button';
import { FormikInput } from '../../../../cdl/Input/FormikInput';
import { Text } from '../../../../cdl/Text/Text';
import { FormikFormattedNumberInput } from '../../../FormattedNumberInput/FormikFormattedNumberInput';
import { translate } from '../../../helpers/translate.helper';
import { Box } from '../../../ui/Box';

export interface SampleKit {
    name: string;
    quantity: number;
}

interface SampleKitFormState {
    name: string;
    quantity: string;
}

interface SampleKitFormProps {
    onSubmit: (samplekit: SampleKit) => void;
    onDismiss: () => void;
}

export const SampleKitForm = ({ onSubmit, onDismiss }: SampleKitFormProps) => {
    const initialValues: SampleKitFormState = {
        name: '',
        quantity: '',
    };

    const validate = (values: SampleKitFormState) => {
        const errors: FormikErrors<SampleKitFormState> = {};

        if (!values.name) {
            errors.name = translate('offer.edit.lubes.oilSampleKit.nameRequired');
        }

        if (!values.quantity) {
            errors.quantity = translate('offer.edit.lubes.oilSampleKit.quantityRequired');
        }

        return errors;
    };

    const handleSubmit = (values: SampleKitFormState) => {
        onSubmit({
            name: values.name,
            quantity: Number(values.quantity),
        });
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
            <Form>
                <Box display="flex" flexDirection="column" gap={5} padding={6}>
                    <Text variant="title">{translate('offer.edit.lubes.oilSampleKit.title')}</Text>

                    <Box display="flex" flexDirection="column" gap={4}>
                        <FormikInput
                            name="name"
                            autoComplete="off"
                            label={translate('offer.edit.lubes.oilSampleKit.name')}
                        />
                        <FormikFormattedNumberInput
                            name="quantity"
                            label={translate('offer.edit.lubes.oilSampleKit.quantity')}
                        />
                    </Box>

                    <Box display="flex" justifyContent="flex-end" width="100%" gap={4}>
                        <Button emphasis="low" type="button" onClick={onDismiss}>
                            {translate('btn.cancel')}
                        </Button>
                        <Button emphasis="high" type="submit">
                            {translate('btn.save.default')}
                        </Button>
                    </Box>
                </Box>
            </Form>
        </Formik>
    );
};
