import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { PlanningPage as OriginalPlanningPage } from './PlanningPage';

const PlanningPage = (props) => {
    return (
        <AppProviders>
            <OriginalPlanningPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(PlanningPage, 'reactPlanning');
