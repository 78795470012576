import { ProductContext } from '../../../types/ProductContext';
import { CenteredPagination } from '../../cdl/Pagination/CenteredPagination';
import { Text } from '../../cdl/Text/Text';
import { Error } from '../../common/Error/Error';
import { translate } from '../../common/helpers/translate.helper';
import { useUpdateEffect } from '../../common/hooks/useUpdateEffect';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../common/LocationState/useLocationState';
import { NoSearchResults } from '../../common/Search/NoSearchResults/NoSearchResults';
import { useOfferPagination } from '../../offer/useOfferPagination';

import { SearchResultOfferTable } from './SearchResultOfferTable';

export const AdminSearchResultOfferTable = () => {
    const [locationState, { setLocationFieldValue }] = useLocationState();

    const paginatedOffersQuery = useOfferPagination({
        page: locationState.offersPage,
        searchQuery: locationState.query,
        types: [ProductContext.FUEL, ProductContext.LUBES],
    });

    useUpdateEffect(() => {
        setLocationFieldValue('offersPage', 0);
        setLocationFieldValue('ordersPage', 0);
    }, [locationState.query]);

    if (paginatedOffersQuery.isPending) {
        return (
            <div>
                <Text as="h2" variant="subtitle1">
                    {translate('searchResult.enquiries')}
                </Text>

                <LoadingIndicator />
            </div>
        );
    }

    if (paginatedOffersQuery.isError) {
        return (
            <div>
                <Text as="h2" variant="subtitle1">
                    {translate('searchResult.enquiries')}
                </Text>

                <Error />
            </div>
        );
    }

    if (paginatedOffersQuery.data.content.length === 0) {
        return (
            <div>
                <Text as="h2" variant="subtitle1">
                    {translate('searchResult.enquiries')}
                </Text>

                <NoSearchResults />
            </div>
        );
    }

    return (
        <div>
            <Text as="h2" variant="subtitle1">
                {translate('searchResult.enquiries')}
            </Text>

            <div>
                <SearchResultOfferTable offers={paginatedOffersQuery.data.content} />
                <CenteredPagination
                    pageTotal={paginatedOffersQuery.pageTotal}
                    currentPage={locationState.offersPage}
                    onPageChange={(page) => setLocationFieldValue('offersPage', page)}
                />
            </div>
        </div>
    );
};
