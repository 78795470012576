import { ProductContext } from '../../types/ProductContext';
import { getOffers } from '../common/api/clients/gateway.api';
import { queryKeys } from '../common/api/queryKeys';
import { usePaginationQuery } from '../common/hooks/usePaginationQuery';

import { OfferModel } from './model/OfferModel';

interface UseOfferPaginationConfig {
    page: number;
    searchQuery?: string;
    supplierIds?: string[];
    customerIds?: string[];
    types: ProductContext[];
    states?: string[];
    sortField?: string;
    sortDirection?: string;
}

export const useOfferPagination = ({
    page,
    searchQuery,
    supplierIds,
    customerIds,
    types,
    states,
    sortField,
    sortDirection,
}: UseOfferPaginationConfig) => {
    return usePaginationQuery<OfferModel>({
        queryKey: queryKeys.offers({
            page,
            searchQuery,
            supplierIds,
            customerIds,
            types,
            states,
            sortField,
            sortDirection,
        }),
        queryFn: () =>
            getOffers({
                page,
                searchQuery,
                supplierIds,
                customerIds,
                types,
                states,
                sortField,
                sortDirection,
            }),
        prefetchQueryKey: queryKeys.offers({
            page: page + 1,
            searchQuery,
            supplierIds,
            customerIds,
            types,
            states,
            sortField,
            sortDirection,
        }),
        prefetchQueryFn: () =>
            getOffers({
                page: page + 1,
                searchQuery,
                supplierIds,
                customerIds,
                types,
                states,
                sortField,
                sortDirection,
            }),
    });
};
