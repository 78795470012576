import { CounterOfferState } from '../../../../../types/CounterOfferState';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconInfoCircle } from '../../../../common/icons/cdl/InfoCircle';
import { StatusBar } from '../../../../common/StatusBar/StatusBar';
import { StatusBarType } from '../../../../common/StatusBar/StatusBarType';
import { OfferModel } from '../../../model/OfferModel';
import { OfferState } from '../../../model/OfferState';

interface OfferEditStatusBarProps {
    offer: OfferModel;
}

const determineWordings = (offer: OfferModel): { headline: string; subHeadline: string } => {
    if (offer.counterOffers.length) {
        const latestCounterOffer = offer.counterOffers[0];
        if ([CounterOfferState.OPEN, CounterOfferState.EXPIRED].includes(latestCounterOffer.state)) {
            return {
                headline: translate('offer.quotationProcess.fuel.statusBar.counterOfferHeadline'),
                subHeadline: translate('offer.quotationProcess.fuel.statusBar.counterOfferSubHeadline'),
            };
        }
    }

    if (offer.state === OfferState.QUOTED) {
        return {
            headline: translate('offer.quotationProcess.fuel.statusBar.quotedHeadline'),
            subHeadline: translate('offer.quotationProcess.fuel.statusBar.quotedSubHeadline'),
        };
    }

    return {
        headline: translate('offer.quotationProcess.fuel.statusBar.headline'),
        subHeadline: translate('offer.quotationProcess.fuel.statusBar.subHeadline'),
    };
};

export const OfferQuoteStatusBar = ({ offer }: OfferEditStatusBarProps) => {
    const { headline, subHeadline } = determineWordings(offer);

    return (
        <StatusBar headline={headline} subHeadline={subHeadline} type={StatusBarType.WHITE} icon={<IconInfoCircle />} />
    );
};
