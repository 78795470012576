import PropTypes from 'prop-types';

import { formatPort } from '../common/helpers/formatPort.helper';
import { Box } from '../common/ui/Box';
import { KeyPortHighlight } from '../keyport/KeyPortHighlight';

export const Port = ({ port, vesselId, short, country, locode, highlightColor, showTooltip }) => {
    const formattedPort = formatPort({ port, short, country, locode });

    return (
        <Box>
            {formattedPort}
            &nbsp;
            <KeyPortHighlight portId={port.id} vesselId={vesselId} color={highlightColor} showTooltip={showTooltip} />
        </Box>
    );
};

Port.propTypes = {
    port: PropTypes.object.isRequired,
    vesselId: PropTypes.string,
    short: PropTypes.bool,
    country: PropTypes.bool,
    locode: PropTypes.bool,
    highlightColor: PropTypes.string,
    showTooltip: PropTypes.bool,
};
