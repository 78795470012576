import { CounterOffer } from '../../../types/CounterOffer';
import { CounterOfferState } from '../../../types/CounterOfferState';
import { StatusBar } from '../../cdl/StatusBar/StatusBar';
import { translate } from '../../common/helpers/translate.helper';
import { CircleValidity } from '../../common/Validity/CircleValidity';
import { useCountdown } from '../../common/Validity/hooks/useCountdown';
import { OfferModel } from '../../offer/model/OfferModel';
import { OfferState } from '../../offer/model/OfferState';

import { AnonymousCounterOfferReviewButton } from './AnonymousCounterOfferReviewButton';

interface CounterOfferReceivedStatusBarProps {
    latestCounterOffer: CounterOffer;
    offer: OfferModel;
    anonymousQuotationId: string;
}

const CounterOfferReceivedStatusBar = (props: CounterOfferReceivedStatusBarProps) => {
    const latestCounterOffer = props.latestCounterOffer;
    const remainingTime = useCountdown(latestCounterOffer.validUntil);
    const isCounterOfferExpired = remainingTime <= 0;

    return (
        <StatusBar
            variant="neutral"
            headline={translate('anonymousQuotation.offerStatus.counterOfferReceived.label')}
            subHeadline={translate('anonymousQuotation.offerStatus.counterOfferReceived.subtitle')}
            trailingVisual={
                <>
                    {!!latestCounterOffer.validUntil && !!latestCounterOffer.validityTimeMinutes ? (
                        <CircleValidity
                            validUntil={latestCounterOffer.validUntil}
                            validityTime={latestCounterOffer.validityTimeMinutes}
                            background="transparent"
                        />
                    ) : null}

                    <AnonymousCounterOfferReviewButton
                        anonymousQuotationId={props.anonymousQuotationId}
                        isCounterOfferExpired={isCounterOfferExpired}
                        latestCounterOffer={latestCounterOffer}
                        offer={props.offer}
                    />
                </>
            }
        />
    );
};

interface AnonymousQuotationOfferStatusBarProps {
    offer: OfferModel;
    anonymousQuotationId: string;
}

export const AnonymousQuotationOfferStatusBar = ({
    offer,
    anonymousQuotationId,
}: AnonymousQuotationOfferStatusBarProps) => {
    switch (offer.state) {
        case OfferState.QUOTE_CANCELED:
        case OfferState.ENQUIRY_CANCELED: {
            return (
                <StatusBar
                    variant="attention"
                    headline={translate('anonymousQuotation.offerStatus.enquirycanceled.label')}
                    subHeadline={translate('anonymousQuotation.offerStatus.enquirycanceled.subtitle')}
                />
            );
        }
        case OfferState.ENQUIRY_EXPIRED: {
            return (
                <StatusBar
                    variant="attention"
                    headline={translate('anonymousQuotation.offerStatus.enquiryexpired.label')}
                    subHeadline={translate('anonymousQuotation.offerStatus.enquiryexpired.subtitle')}
                />
            );
        }
        case OfferState.QUOTE_DECLINED: {
            return (
                <StatusBar
                    variant="attention"
                    headline={translate('anonymousQuotation.offerStatus.qoutedeclined.label')}
                    subHeadline={translate('anonymousQuotation.offerStatus.qoutedeclined.subtitle')}
                />
            );
        }
        case OfferState.QUOTED:
            if (offer.counterOffers.length) {
                const latestCounterOffer = offer.counterOffers[0];

                switch (latestCounterOffer.state) {
                    case CounterOfferState.ACCEPTED: {
                        return (
                            <StatusBar
                                variant="neutral"
                                headline={translate('anonymousQuotation.offerStatus.counterOfferAccepted.label')}
                                subHeadline={translate('anonymousQuotation.offerStatus.counterOfferAccepted.subtitle')}
                            />
                        );
                    }
                    case CounterOfferState.EXPIRED:
                    case CounterOfferState.OPEN: {
                        return (
                            <CounterOfferReceivedStatusBar
                                latestCounterOffer={latestCounterOffer}
                                offer={offer}
                                anonymousQuotationId={anonymousQuotationId}
                            />
                        );
                    }
                }
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('anonymousQuotation.offerStatus.quoted.label')}
                    subHeadline={translate('anonymousQuotation.offerStatus.quoted.subtitle')}
                    trailingVisual={
                        <>
                            {!!offer.quoteValidUntil && !!offer.quoteValidityTime ? (
                                <CircleValidity
                                    validUntil={offer.quoteValidUntil}
                                    validityTime={offer.quoteValidityTime}
                                    background="transparent"
                                />
                            ) : null}
                        </>
                    }
                />
            );
        case OfferState.ENQUIRY_DECLINED:
            return (
                <StatusBar
                    variant="attention"
                    headline={translate('anonymousQuotation.offerStatus.offerDeclined.label')}
                    subHeadline={translate('anonymousQuotation.offerStatus.offerDeclined.subtitle')}
                />
            );
        case OfferState.CUSTOMER_ADJUSTED: {
            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.supplier.fuel.customerAdjusted.headline')}
                    subHeadline={translate('statusbar.supplier.fuel.customerAdjusted.subHeadline')}
                />
            );
        }
        case OfferState.SUPPLIER_ADJUSTED: {
            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.supplier.fuel.supplierAdjusted.headline')}
                    subHeadline={translate('statusbar.supplier.fuel.supplierAdjusted.subHeadline')}
                />
            );
        }
        case OfferState.ORDER:
        case OfferState.CONFIRMED:
        case OfferState.ACKNOWLEDGED:
        case OfferState.DELIVERED:
        case OfferState.INVOICED:
        case OfferState.CANCELED:
            return (
                <StatusBar
                    variant="positive"
                    headline={translate('anonymousQuotation.offerStatus.ordered.label')}
                    subHeadline={translate('anonymousQuotation.offerStatus.ordered.subtitle')}
                />
            );
        case OfferState.ENQUIRY:
            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('anonymousQuotation.offerStatus.enquiry.label')}
                    subHeadline={translate('anonymousQuotation.offerStatus.enquiry.subtitle')}
                    trailingVisual={
                        <>
                            {!!offer.validUntil && !!offer.validityTime ? (
                                <CircleValidity
                                    validUntil={offer.validUntil}
                                    validityTime={offer.validityTime}
                                    background="transparent"
                                />
                            ) : null}
                        </>
                    }
                />
            );
    }
    return null;
};
