import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CL_CLEAR_BLUE_100 } from '../colors';

const IconWrapper = styled.span`
    display: inline-flex;
    margin-right: 4px;
`;

const StyledButton = styled.button`
    display: flex;
    align-items: center;

    padding: 0;
    margin: 0;
    border: none;
    outline: none;

    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: ${CL_CLEAR_BLUE_100};

    &:hover {
        text-decoration: underline;
    }
`;

export const InfoIconButton = (props) => {
    return (
        <StyledButton onClick={props.onClick}>
            <IconWrapper>{props.icon}</IconWrapper>
            {props.children}
        </StyledButton>
    );
};

InfoIconButton.propTypes = {
    icon: PropTypes.node,
    children: PropTypes.node,
    onClick: PropTypes.func,
};
