import { useAngularDependencies } from '../../common/hooks/useAngularDependencies';
import { useNavigation } from '../../common/hooks/useNavigation';
import { useStats } from '../../dashboard/common/hooks/useStats';

export const useRedirectAfterLogin = (showWelcomePopup: boolean = false): (() => Promise<void>) => {
    const statsQuery = useStats(false);
    const { RoleService, $stateParams } = useAngularDependencies();
    const { navigate } = useNavigation();

    const navigateToDestination = (destination: string, params: {}) => {
        navigate(
            destination,
            {
                ...params,
                showWelcomePopup,
            },
            {
                location: 'replace',
            }
        );
    };

    return () => {
        return RoleService.getPromise().then(async () => {
            const context = RoleService.get().context;

            let destination = context === 'FUEL' ? 'base.fuel-dashboard' : 'base.dashboard';

            let params: {} = {};

            if ($stateParams.redirect) {
                destination = $stateParams.redirect.state;
                params = $stateParams.redirect.params;
            }

            if (destination === 'base.fuel-dashboard' || destination === 'base.dashboard') {
                try {
                    await statsQuery.prefetch(context);
                } catch {
                    navigateToDestination(destination, params);
                }
            }

            navigateToDestination(destination, params);
        });
    };
};
