import { UserStatus } from '../../../../types/UserStatus';
import { Text } from '../../../cdl/Text/Text';
import { useEnums } from '../../../common/hooks/useEnums';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex';

interface UserStatusProps {
    status: UserStatus;
}

export const UserStatusDisplay = ({ status }: UserStatusProps) => {
    const { getHumanReadableValue } = useEnums();
    return (
        <Flex alignItems="center">
            <Text variant="body">{getHumanReadableValue('status', status)}</Text>
            {status !== UserStatus.INVITED ? (
                <Box
                    backgroundColor={status === UserStatus.ACTIVE ? 'positive.emphasis' : 'negative.emphasis'}
                    width="6px"
                    height="6px"
                    marginTop={1} // to align the dot with the text more pleasing
                    marginLeft={4}
                    borderRadius="100%"
                />
            ) : null}
        </Flex>
    );
};
