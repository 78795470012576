import storage from 'local-storage-fallback';

export function loadDevTools(callback: () => void) {
    // this allows you to explicitly disable it in development for example
    const explicitlyDisabled =
        window.location.search.includes('dev-tools=false') || storage.getItem('dev-tools') === 'false';

    const explicitlyEnabled =
        window.location.search.includes('dev-tools=true') || storage.getItem('dev-tools') === 'true';

    if (explicitlyDisabled) {
        callback();
        return;
    }

    if (explicitlyEnabled) {
        import('./installDevTools').then((devTools) => devTools.install()).finally(callback);

        return;
    }

    callback();
}
