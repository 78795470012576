import { MouseEvent, useState } from 'react';

import { AgentMessage } from '../../../../types/AgentMessage';
import { Button } from '../../../cdl/Button/Button';
import { translate } from '../../../common/helpers/translate.helper';
import { IconArchive } from '../../../common/icons/cdl/Archive';
import { useAgentStateChange } from '../../useAgentStateChange';

import { ArchiveAgentConfirmPopup } from './ArchiveAgentConfirmPopup';

interface ArchiveAgentButtonProps {
    agent: AgentMessage;
}

export const ArchiveAgentButton = ({ agent }: ArchiveAgentButtonProps) => {
    const agentStateChangeMutation = useAgentStateChange();
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);

    const handleOnConfirm = () => {
        setShowConfirmPopup(false);
        onAgentArchive();
    };

    const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
        setShowConfirmPopup(true);
        event.stopPropagation();
    };

    const onAgentArchive = () => {
        const agentToArchive = {
            ...agent,
            active: false,
        };
        agentStateChangeMutation.mutate({
            agentId: agentToArchive.id,
            agent: agentToArchive,
        });
    };

    return (
        <>
            <Button leadingVisual={<IconArchive aria-label="Archive" />} onClick={handleOnClick}>
                {translate('agents.archiveButton.btnText')}
            </Button>
            <ArchiveAgentConfirmPopup
                isOpen={showConfirmPopup}
                onSubmit={handleOnConfirm}
                onDismiss={() => setShowConfirmPopup(false)}
                name={agent.name}
            />
        </>
    );
};
