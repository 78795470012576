import { formatDate } from './formatDate.helper';
import { notAvailable } from './notAvailable.helper';
import { translate } from './translate.helper';

export const createdBy = ({ order }) => {
    function createCreatedBy(order) {
        return order.createdByUser.firstname + ' ' + order.createdByUser.lastname;
    }

    let date = undefined;

    if (order.dateStarted) {
        date = order.dateStarted;
    } else if (order.dateCreated) {
        date = order.dateCreated;
    }

    if (date && order.createdByUser) {
        return translate('order.createdBy', {
            date: formatDate({ date }),
            name: createCreatedBy(order),
        });
    } else if (date) {
        return translate('order.createdBy', {
            date: formatDate({ date }),
            name: translate('global.system'),
        });
    } else if (order.createdByUser) {
        return translate('order.createdBy', {
            date: notAvailable({ value: undefined }),
            name: createCreatedBy(order),
        });
    }
    return notAvailable({ value: undefined });
};
