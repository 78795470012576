import { ProductContext } from '../../../../types/ProductContext';
import { OfferModel } from '../../../offer/model/OfferModel';
import { OfferState } from '../../../offer/model/OfferState';

export const sortOffers = (type: ProductContext) => {
    return (offerA: OfferModel, offerB: OfferModel) => {
        if (offerA.state === OfferState.ENQUIRY_DECLINED && offerB.state !== OfferState.ENQUIRY_DECLINED) {
            return 1;
        }
        if (offerA.state !== OfferState.ENQUIRY_DECLINED && offerB.state === OfferState.ENQUIRY_DECLINED) {
            return -1;
        }
        if (offerA.state === OfferState.ENQUIRY_DECLINED && offerB.state === OfferState.ENQUIRY_DECLINED) {
            return 0;
        }

        if (!offerA.hasValidProducts() && offerB.hasValidProducts()) {
            return 1;
        }
        if (offerA.hasValidProducts() && !offerB.hasValidProducts()) {
            return -1;
        }
        if (!offerA.hasValidProducts() && !offerB.hasValidProducts()) {
            return 0;
        }

        if (type === ProductContext.FUEL) {
            if (!offerA.wasQuoted() && offerB.wasQuoted()) {
                return 1;
            }
            if (offerA.wasQuoted() && !offerB.wasQuoted()) {
                return -1;
            }
            if (!offerA.wasQuoted() && !offerB.wasQuoted()) {
                return 0;
            }
            return offerA.total.value - offerB.total.value;
        }

        if (type === ProductContext.LUBES) {
            if (offerA.ppl && offerB.ppl) {
                return offerA.ppl.value - offerB.ppl.value;
            } else {
                return 0;
            }
        }

        return 0;
    };
};
