import { useQuery } from '@tanstack/react-query';

import { getManagementUserById } from '../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { useRole } from '../../../common/hooks/useRole';

export const useManagementUser = (userId: string) => {
    const role = useRole();
    return useQuery({
        queryKey: queryKeys.user(userId),
        queryFn: () => getManagementUserById(userId),
        enabled: !!userId && role.isAdmin(),
    });
};
