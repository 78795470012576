import { CoreDataInfo } from '../../common/CoreData/CoreDataInfo';
import { AgentInfoPopup } from '../../common/CoreDataPopup/AgentInfoPopup';
import { CoreDataPopup } from '../../common/CoreDataPopup/CoreDataPopup';
import { CustomerInfoPopup } from '../../common/CoreDataPopup/CustomerInfoPopup';
import { formatCompanyName } from '../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../common/helpers/formatDate.helper';
import { formatDateTime } from '../../common/helpers/formatDateTime.helper';
import { translate } from '../../common/helpers/translate.helper';
import { Port } from '../../port/Port';
import { Text } from '../../cdl/Text/Text.tsx';
import { DataSheet } from '../../common/DataSheet/DataSheet.tsx';
import { AnonymousQuotationVesselInfoPopup } from '../components/AnonymousQuotationVesselInfoPopup';

export const useAnonymousCoreDataFields = () => {
    return {
        vessel: {
            label: translate('global.coreData.vessel'),
            path: 'offer.vessel',
            render: (vessel) => <CoreDataInfo primary={vessel.name} secondary={[vessel.imo]} />,
            popup: (vessel) => {
                return {
                    buttonText: translate('global.coreData.viewVesselInformation'),
                    content: <AnonymousQuotationVesselInfoPopup vessel={vessel} />,
                };
            },
        },
        customer: {
            label: translate('global.coreData.customer'),
            path: 'offer.customer',
            render: (customer) => (
                <CoreDataInfo
                    primary={formatCompanyName({
                        company: customer,
                        includeType: false,
                    })}
                    secondary={[
                        customer.email ? `${translate('global.contact.email')}: ${customer.email}` : null,
                        customer.phone ? `${translate('global.contact.phone')}: ${customer.phone}` : null,
                    ]}
                />
            ),
            popup: (customer, getField, data) => {
                return {
                    buttonText: translate('global.coreData.viewCustomerInformation'),
                    content: <CustomerInfoPopup offer={data.offer} />,
                };
            },
        },
        user: {
            path: 'offer.createdByUser',
            label: translate('global.coreData.createdByUser'),
            render: (createdByUser) => (
                <CoreDataInfo
                    primary={[createdByUser.firstname, createdByUser.lastname].join(' ')}
                    secondary={[
                        createdByUser.email ? `${translate('global.contact.email')}: ${createdByUser.email}` : null,
                        createdByUser.phone ? `${translate('global.contact.phone')}: ${createdByUser.phone}` : null,
                    ]}
                />
            ),
            popup: (createdByUser) => ({
                buttonText: translate('global.coreData.viewCreatedByUserInformation'),
                content: (
                    <CoreDataPopup heading={translate('global.coreData.createdByUser')}>
                        <DataSheet>
                            <DataSheet.Label>{translate('global.contact.name')}</DataSheet.Label>
                            <Text>{[createdByUser.firstname, createdByUser.lastname].join(' ')}</Text>

                            <DataSheet.Label>{translate('global.contact.email')}</DataSheet.Label>
                            <Text>{createdByUser.email}</Text>

                            {createdByUser.phone ? (
                                <>
                                    <DataSheet.Label>{translate('global.contact.phone')}</DataSheet.Label>
                                    <Text>{createdByUser.email}</Text>
                                </>
                            ) : null}
                        </DataSheet>
                    </CoreDataPopup>
                ),
            }),
        },
        agent: {
            label: translate('global.coreData.agent'),
            path: 'offer.agentInformation',
            fallback: translate('global.coreData.noAgent'),
            render: (agent) => {
                if (!agent) {
                    return null;
                }

                return (
                    <CoreDataInfo
                        primary={agent.name}
                        secondary={[
                            agent.contact.emailAddress
                                ? `${translate('global.contact.email')}: ${agent.contact.emailAddress}`
                                : null,
                            agent.contact.phoneNumber
                                ? `${translate('global.contact.phone')}: ${agent.contact.phoneNumber}`
                                : null,
                        ]}
                    />
                );
            },
            popup: (agent) => {
                if (!agent) {
                    return {
                        buttonText: translate('global.coreData.viewAgentInformation'),
                    };
                }

                return {
                    buttonText: translate('global.coreData.viewAgentInformation'),
                    headline: translate('global.coreData.agent'),
                    content: <AgentInfoPopup agent={agent} />,
                };
            },
        },
        dateDelivery: {
            path: 'offer.dateDelivery',
            label: translate('global.coreData.deliveryDate'),
            render: (date) => {
                if (!date) {
                    return null;
                }

                return formatDate({ date, timeZone: 'UTC' });
            },
        },
        eta: {
            label: translate('global.coreData.estimatedTimeOfArrival'),
            render: (date, _, data) => {
                if (!data.offer.eta) {
                    return null;
                }
                return formatDateTime({
                    date: data.offer.eta,
                    timeZoneId: data.offer.port.timeZoneId,
                });
            },
        },
        etd: {
            label: translate('global.coreData.estimatedTimeOfDeparture'),
            render: (date, _, data) => {
                if (!data.offer.etd) {
                    return null;
                }
                return formatDateTime({
                    date: data.offer.etd,
                    timeZoneId: data.offer.port.timeZoneId,
                });
            },
        },
        port: {
            label: translate('global.coreData.port'),
            render: (port, _, data) => <Port port={data.offer.port} vesselId={data.offer.vesselId} showTooltip />,
        },
        buyerReference: {
            path: 'offer.buyerReference',
            label: translate('global.coreData.buyerReference'),
        },
        enquiryNote: {
            path: 'messages',
            label: translate('global.coreData.enquiryNote'),
            render: (messages) => {
                const latestNonSystemMessageText = messages
                    .filter((message) => message.systemMessage === false && message.receiverType === 'SUPPLIER')
                    .sort((a, b) => b.dateUpdated - a.dateUpdated)[0]?.message;

                return latestNonSystemMessageText || '-';
            },
        },
    };
};
