import { Text } from '../../../../cdl/Text/Text';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconInfoCircle } from '../../../../common/icons/cdl/InfoCircle';

export const CounterOfferRejectHint = () => {
    return (
        <Text
            variant="extraSmall"
            as="p"
            style={{
                display: 'flex',
                gap: '4px',
                marginBottom: 0,
            }}
        >
            <IconInfoCircle height={16} width={16} /> {translate('offer.quotationProcess.fuel.counterOfferRejectHint')}
        </Text>
    );
};
