import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { deleteOrder } from '../../../common/api/clients/order.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { translate } from '../../../common/helpers/translate.helper';

export const useOrderDelete = () => {
    const queryClient = useQueryClient();
    const { addToast, addErrorToast } = useToasts();

    return useMutation({
        mutationFn: deleteOrder,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.orders() });
            queryClient.invalidateQueries({ queryKey: queryKeys.ordersTotals() });

            addToast(translate(`order.deleteDraftToast.success`));
        },
        onError: () => {
            addErrorToast(translate(`order.deleteDraft.error`));
        },
    });
};
