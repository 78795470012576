import styled from 'styled-components';

import { ProgressBar } from '../../cdl/ProgressBar/ProgressBar';
import { Text } from '../../cdl/Text/Text';
import { formatNumber } from '../../common/helpers/formatNumber.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useFuelVolumeFormat } from '../../common/hooks/useFuelVolumeFormat';
import { Box } from '../../common/ui/Box';

const EllipsisText = styled(Text).attrs({ as: 'div' })`
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    white-space: nowrap;
`;

interface RemainingContractVolumeProps {
    contractVolume: number;
    usedContractVolume?: number;
}

export const RemainingContractVolume = ({ contractVolume, usedContractVolume = 0 }: RemainingContractVolumeProps) => {
    const formatFuelVolume = useFuelVolumeFormat();
    const remainingVolume = contractVolume - usedContractVolume;

    const formattedRemainingVolume =
        remainingVolume <= 0
            ? 0
            : formatNumber({
                  number: remainingVolume,
                  maximumFractionDigits: 1,
              });

    const formattedContractVolume = formatFuelVolume({
        volume: contractVolume,
    });

    return (
        <div>
            <Box display="grid" gridTemplateColumns="1fr auto" gridColumnGap={4} maxWidth="100%">
                <EllipsisText color="dark-blue.2" weight="semi" fontSize={12}>
                    {translate('fuelEnquiry.second.contractProducts.remainingVolume')}
                </EllipsisText>
                <Text color="dark-blue.2" weight="semi" fontSize={12}>
                    {formattedRemainingVolume}/{formattedContractVolume}
                </Text>
            </Box>
            <Box marginTop={4}>
                <ProgressBar value={remainingVolume} maxValue={contractVolume} />
            </Box>
        </div>
    );
};
