import PropTypes from 'prop-types';

import { useAngularDependencies } from '../hooks/useAngularDependencies';

export const Link = ({ to, children, className = '', onClick }) => {
    const { $state } = useAngularDependencies();

    return (
        <a className={className} href={$state.href(to)} onClick={onClick}>
            {children}
        </a>
    );
};

Link.propTypes = {
    to: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
};
