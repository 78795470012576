import { variant } from 'styled-system';

export const emphasisVariants = variant({
    variants: {
        default: {
            color: 'foreground.onEmphasis',
            backgroundColor: 'foreground.default',
            borderColor: '#0B1A22',
        },
        accent: {
            color: 'foreground.onEmphasis',
            backgroundColor: 'accent.emphasis',
            borderColor: 'accent.foreground',
        },
        positive: {
            color: 'foreground.onEmphasis',
            backgroundColor: 'positive.emphasis',
            borderColor: 'positive.foreground',
        },
        attention: {
            color: 'foreground.onEmphasis',
            backgroundColor: 'attention.emphasis',
            borderColor: 'attention.foreground',
        },
        negative: {
            color: 'foreground.onEmphasis',
            backgroundColor: 'negative.emphasis',
            borderColor: 'negative.foreground',
        },
    },
});
