import { Flex } from '../ui/Flex';

import { Spinner } from './Spinner';

interface ButtonSpinnerProps {
    color?: string;
}

/**
 * @deprecated use CDL Button with `isLoading` prop instead.
 **/

export const ButtonSpinner = ({ color }: ButtonSpinnerProps) => {
    return (
        <Flex justifyContent="center" alignItems="center" width={20} height={20}>
            <Spinner color={color} />
        </Flex>
    );
};
