import { useCallback, useEffect, useState } from 'react';

import { ProductContext } from '../../../types/ProductContext';

import { useAngularDependencies } from './useAngularDependencies';
import { useNavigation } from './useNavigation';
import { useRole } from './useRole';

const getContextBooleans = (context: ProductContext) => ({
    isLubes: context === ProductContext.LUBES,
    isFuel: context === ProductContext.FUEL,
});

interface ProductContextHookData {
    context: ProductContext;
    setContext: (context: ProductContext, dashboardRedirect?: boolean) => void;
    possibleContexts: ProductContext[];
    isFuel?: boolean;
    isLubes?: boolean;
}

export const useProductContext = (initiateContext?: () => ProductContext): ProductContextHookData => {
    const { $rootScope, RoleService } = useAngularDependencies();
    const { navigate } = useNavigation();
    const role = useRole();
    const [context, _setContext] = useState<ProductContext>(() => RoleService.get()?.context);

    useEffect(() => {
        const deregister = $rootScope.$on('contextUpdated', (event: unknown, context: ProductContext) => {
            _setContext(context);
        });

        return () => deregister();
    }, [$rootScope]);

    const initiatedContext = initiateContext?.();

    const setContext = useCallback(
        (context: ProductContext, dashboardRedirect = true) => {
            RoleService.updateContext(context);

            if (dashboardRedirect) {
                if (context === ProductContext.FUEL) {
                    navigate('base.fuel-dashboard');
                    return;
                }

                navigate('base.dashboard');
            }
        },
        [navigate, RoleService]
    );

    useEffect(() => {
        if (!initiatedContext) {
            return;
        }

        setContext(initiatedContext, false);
    }, [initiatedContext, setContext]);

    const possibleContexts = role?.getPossibleContexts();
    const contextBooleans = context ? getContextBooleans(context) : null;

    return {
        context,
        setContext,
        possibleContexts,
        ...contextBooleans,
    };
};
