ExceptionInterceptor.$inject = ['$q', '$log'];

function ExceptionInterceptor($q, $log) {
    return {
        responseError: function (rejection) {
            $log.error(rejection);
            return $q.reject(rejection);
        },
    };
}

export default {
    name: 'ExceptionInterceptor',
    fn: ExceptionInterceptor,
};
