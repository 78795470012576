import * as Dialog from '@radix-ui/react-dialog';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const ModalBackdrop = styled(Dialog.Overlay)`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background-color: ${get('colors.background.dimming')};
    backdrop-filter: blur(2px);
    z-index: 9999;
`;
