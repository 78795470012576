import { PageMessage } from '../../../../types/PageMessage';
import { SpotAvailabilityMessage } from '../../../../types/SpotAvailabilityMessage';
import {
    CreateSupplierGroupRequestMessage,
    EditSupplierGroupRequestMessage,
    SupplierGroupMessage,
} from '../../../../types/SupplierGroupMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const getSupplierGroups = async (
    searchQuery: string,
    page: number
): Promise<PageMessage<SupplierGroupMessage>> => {
    const params = new URLSearchParams({
        searchQuery,
        page: page.toString(),
    });

    const response = await authorizedAxiosInstance.get(`/v1/suppliergroup?${params.toString()}`);

    return response.data;
};

export const getSupplierGroup = async (groupId: string): Promise<SupplierGroupMessage> => {
    const response = await authorizedAxiosInstance.get(`/v1/suppliergroup/${groupId}`);

    return response.data;
};

export const createSupplierGroup = async (
    supplierGroup: CreateSupplierGroupRequestMessage
): Promise<SupplierGroupMessage> => {
    const response = await authorizedAxiosInstance.post(`/v1/suppliergroup`, supplierGroup);

    return response.data;
};

export const updateSupplierGroup = async (
    supplierGroup: EditSupplierGroupRequestMessage
): Promise<SupplierGroupMessage> => {
    const response = await authorizedAxiosInstance.put(`/v1/suppliergroup/${supplierGroup.id}`, supplierGroup);

    return response.data;
};

export const uploadSupplierGroupLogo = async (supplierGroupId: string, file: File): Promise<SupplierGroupMessage> => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await authorizedAxiosInstance.post(`/v1/suppliergroup/${supplierGroupId}/logo`, formData);

    return response.data;
};

export const deleteSupplierGroupLogo = async (supplierGroupId: string): Promise<SupplierGroupMessage> => {
    const response = await authorizedAxiosInstance.delete(`/v1/suppliergroup/${supplierGroupId}/logo`);

    return response.data;
};

export const getSpotAvailability = async (
    portCountryCode: string,
    testOrder: boolean
): Promise<SpotAvailabilityMessage> => {
    const params = new URLSearchParams({
        countryCode: portCountryCode,
        test: testOrder.toString(),
    });

    const response = await authorizedAxiosInstance.get(`/v1/suppliergroup/spot?${params.toString()}`);

    return response.data;
};
