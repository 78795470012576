import { MouseEventHandler } from 'react';

import { OfferSummaryContainer } from '../../../../common/OfferCard/OfferSummaryContainer';

import { OfferDetails } from './OfferDetails';
import { OfferSupplierAndTotals } from './OfferSupplierAndTotals';

interface OfferInformationProps {
    onClick?: MouseEventHandler<HTMLDivElement>;
    isBestLiterPrice?: boolean;
}

export const OfferSummary = ({ onClick, isBestLiterPrice }: OfferInformationProps) => {
    return (
        <OfferSummaryContainer onClick={onClick} positiveHover={isBestLiterPrice}>
            <OfferSupplierAndTotals isBestLiterPrice={isBestLiterPrice} />
            <OfferDetails />
        </OfferSummaryContainer>
    );
};
