import { Text } from '../../../cdl/Text/Text';
import { Error } from '../../../common/Error/Error';
import { HeaderWithLine } from '../../../common/HeaderWithLine/HeaderWithLine';
import { translate } from '../../../common/helpers/translate.helper';
import { useAngularRouterLocation } from '../../../common/hooks/useAngularRouterLocation';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Page } from '../../../common/Page/Page';
import { PageGrid } from '../../../common/PageGrid';
import { Section } from '../../../common/Section/Section';
import { Box } from '../../../common/ui/Box';
import { RequestSuppliersButton } from '../common/RequestSuppliersButton';

import { AvailableContractProducts } from './AvailableContractProducts';
import { OfferList } from './OfferList';
import { OrderDetailActionBar } from './OrderDetailActionBar';
import { OrderStatusSection } from './OrderStatusSection';
import { OrderSummary } from './OrderSummary';
import { useFuelOrderDetails } from './useFuelOrderDetails';

export const FuelOrderDetailPage = () => {
    const { id } = useAngularRouterLocation();

    const orderDetailQuery = useFuelOrderDetails(id);

    useDocumentTitle(
        orderDetailQuery.data
            ? translate('fuelOrder.documentTitle', {
                  vessel: orderDetailQuery.data.vessel.name,
                  port: orderDetailQuery.data.port.name,
              })
            : null
    );

    if (orderDetailQuery.isPending) {
        return (
            <Page>
                <LoadingIndicator variant="page" />
            </Page>
        );
    }

    if (orderDetailQuery.isError) {
        return (
            <Page>
                <Error />
            </Page>
        );
    }

    const order = orderDetailQuery.data;

    return (
        <Page>
            <PageGrid>
                <HeaderWithLine>
                    <Text variant="headline" as="h1">
                        {translate('fuelOrder.headline')}
                    </Text>

                    <OrderDetailActionBar order={order} />
                </HeaderWithLine>

                <OrderStatusSection order={order} />

                <Section>
                    <OrderSummary order={order} />
                </Section>

                <HeaderWithLine>
                    <Text variant="title" as="h2">
                        {translate('fuelOrder.offerList.offerList')}
                    </Text>

                    <RequestSuppliersButton order={order} />
                </HeaderWithLine>

                <OfferList order={order} />

                {order.state === 'OPEN' ? (
                    <>
                        <Box paddingTop={5}>
                            <Text variant="subtitle1" as="h2">
                                {translate('fuelOrder.offerList.contractProducts')}
                            </Text>
                        </Box>

                        <AvailableContractProducts order={order} />
                    </>
                ) : null}
            </PageGrid>
        </Page>
    );
};
