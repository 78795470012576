import { ReactNode } from 'react';

import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex';

interface QuickStatsWrapperProps {
    children: ReactNode;
}

export const QuickStatsWrapper = (props: QuickStatsWrapperProps) => {
    return (
        <Box paddingX={7} paddingY={6}>
            <Flex justifyContent="space-between" flexDirection="row">
                {props.children}
            </Flex>
        </Box>
    );
};
