import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';

import { ProductContext } from '../../../../types/ProductContext';
import { QuickStatsMessage } from '../../../../types/QuickStatsMessage';
import { getStats } from '../../../common/api/clients/stats.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { useProductContext } from '../../../common/hooks/useProductContext';

type UseStatsQueryResult = UseQueryResult<QuickStatsMessage> & {
    prefetch: (context: ProductContext) => Promise<void>;
};

export const useStats = (enabled = true): UseStatsQueryResult => {
    const queryClient = useQueryClient();
    const { context } = useProductContext();

    const prefetch = (context: ProductContext) => {
        return queryClient.prefetchQuery({ queryKey: queryKeys.stats(context), queryFn: () => getStats(context) });
    };

    const query = useQuery({ queryKey: queryKeys.stats(context), queryFn: () => getStats(context), enabled });

    return {
        ...query,
        prefetch,
    };
};
