import PropTypes from 'prop-types';

import { LogoHeader } from '../../common/headers/LogoHeader';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';
import { CustomerApprovalOfferCoreData } from '../OfferCoreData/CustomerApprovalOfferCoreData';

import { ApprovalRequestsDropdown } from './ApprovalRequestsDropdown';
import { ContentContainer } from './ContentContainer';
import { FuelApprovalContent } from './FuelApprovalContent';
import { SpacedProductTable } from './SpacedProductTable';

export const ApprovalRequestBasicContent = ({ offer, approvalRequests, children }) => {
    const isLubes = offer.type === 'LUBES';
    const subTitle = isLubes ? offer.offerNumber : offer.vessel.name;

    return (
        <Box>
            <LogoHeader
                title={translate('approvalRequest.header.title')}
                subTitle={subTitle}
                rightElement={<ApprovalRequestsDropdown approvalRequests={approvalRequests} />}
            />

            <ContentContainer>
                <Box marginBottom={5}>{children}</Box>

                {isLubes ? (
                    <Box>
                        <Box marginBottom={5}>
                            <CustomerApprovalOfferCoreData offer={offer} />
                        </Box>
                        <SpacedProductTable offer={offer} />
                    </Box>
                ) : (
                    <FuelApprovalContent offer={offer} />
                )}
            </ContentContainer>
        </Box>
    );
};

ApprovalRequestBasicContent.propTypes = {
    offer: PropTypes.object.isRequired,
    approvalRequests: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired,
};
