import { ReactNode } from 'react';

import { Tag } from '../../../../cdl/Tag/Tag';
import { Text } from '../../../../cdl/Text/Text';
import { translate } from '../../../../common/helpers/translate.helper';
import { LiteOverlay } from '../../../../common/LiteOverlay/LiteOverlay';
import { SelectBox } from '../../../../common/SelectBox/SelectBox';
import { Box } from '../../../../common/ui/Box';

const SupplierSelectBoxContent = ({ defaultTag, disabled, title, image, description }: SupplierSelectBoxProps) => {
    return (
        <>
            {defaultTag ? (
                <Box position="absolute" top="10px" right="42px">
                    <Tag variant="default" disabled={disabled}>
                        {translate('vessel.default')}
                    </Tag>
                </Box>
            ) : null}

            <Box display="flex" alignItems="end" height="100%" padding={5} marginTop={2}>
                <div>
                    {image}
                    <Box minHeight="48px">
                        <Text variant="body" fontWeight="semiBold">
                            {title}
                        </Text>
                    </Box>
                    <Box marginTop={2}>
                        <Text
                            variant="extraSmall"
                            color={disabled ? 'foreground.disabled' : 'foreground.subtle'}
                            fontWeight="semiBold"
                        >
                            {description}
                        </Text>
                    </Box>
                </div>
            </Box>
        </>
    );
};

interface SupplierSelectBoxProps {
    title: string;
    description: string;
    selected: boolean;
    disabled: boolean;
    onClick?: () => void;
    defaultTag?: boolean;
    image: ReactNode;
    liteOverlay?: boolean;
}

export const SupplierSelectBox = ({
    selected,
    disabled,
    onClick,
    defaultTag,
    image,
    title,
    description,
    liteOverlay,
}: SupplierSelectBoxProps) => {
    const selectBoxContent = (
        <SupplierSelectBoxContent
            title={title}
            description={description}
            selected={selected}
            disabled={disabled}
            image={image}
            defaultTag={defaultTag}
        />
    );

    return (
        <SelectBox
            height="180px"
            width="200px"
            onClick={!liteOverlay ? onClick : undefined}
            selected={selected}
            disabled={disabled}
            padding={0}
        >
            {liteOverlay ? (
                <LiteOverlay variant="small" title={translate('order.supplier')} borderRadius={1}>
                    {selectBoxContent}
                </LiteOverlay>
            ) : (
                selectBoxContent
            )}
        </SelectBox>
    );
};
