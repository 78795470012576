import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const updateContract = async (contract: { id: string }): Promise<any> => {
    const response = await authorizedAxiosInstance.put(`/v1/fuel-contracts/${contract.id}`, contract);

    return response.data;
};

export const createContract = async (contract: unknown): Promise<any> => {
    const response = await authorizedAxiosInstance.post('/v1/fuel-contracts', contract);

    return response.data;
};

export const deleteContract = async (contractId: string): Promise<any> => {
    const response = await authorizedAxiosInstance.delete(`/v1/fuel-contracts/${contractId}`);

    return response.data;
};

interface SendFuelContractRequestMessage {
    receiverEmailAddresses: string[];
    copyToSelf: boolean;
}

interface SendContractParams {
    contractId: string;
    request: SendFuelContractRequestMessage;
}

export const sendContract = async ({ contractId, request }: SendContractParams): Promise<void> => {
    await authorizedAxiosInstance.post(`/v1/fuel-contracts/${contractId}/send`, request);
};
