import { AxiosPromise } from 'axios';

import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const exportOffer = async (offerId: string): AxiosPromise<void> => {
    return authorizedAxiosInstance.post(`/v1/maersk/purchase-order/export?offerId=${offerId}`);
};

export const confirmOffer = async (offerId: string, customerId: String): AxiosPromise<void> => {
    return authorizedAxiosInstance.post(
        `/v1/maersk/purchase-order/confirm?offerId=${offerId}&customerId=${customerId}`
    );
};

export const importPurchaseRequest = async (code: string, changesFrom: Date): AxiosPromise<void> => {
    return authorizedAxiosInstance.post(
        `/v1/maersk/purchase-requests/import?glsmCodes=${code}&changesFrom=${changesFrom.toISOString()}`
    );
};
