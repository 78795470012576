import { AxiosProgressEvent, AxiosResponse } from 'axios';
import { useField } from 'formik';
import { useEffect } from 'react';

import { FileManagementResponse } from '../../../../../types/FileManagementResponse';
import * as offerFileManagementApi from '../../../../common/api/clients/offerFileManagement.api';
import { Attachments } from '../../../../common/Attachments/components/Attachments';
import { OfferModel } from '../../../model/OfferModel';

interface LubesQuoteAttachmentsProps {
    offer: OfferModel;
    invalidateCache: () => Promise<void>;
}

export const LubesQuoteAttachments = (props: LubesQuoteAttachmentsProps) => {
    const [fileIdsField, , fileIdsHelper] = useField('fileIds');
    const [filesField, , filesHelper] = useField('files');

    useEffect(() => {
        if (props.offer.fileIds !== fileIdsField.value) {
            fileIdsHelper.setValue(props.offer.fileIds);
            filesHelper.setValue(props.offer.files);
        }
    }, [props.offer.files, props.offer.fileIds, fileIdsField, fileIdsHelper, filesHelper]);

    const uploadFile = (
        file: File,
        onUploadProgress: (event: AxiosProgressEvent) => void
    ): Promise<FileManagementResponse> => {
        return offerFileManagementApi.uploadFile(file, props.offer.id, onUploadProgress);
    };

    const deleteFile = (fileId: string): Promise<void> => {
        return offerFileManagementApi.deleteFile([fileId], props.offer.id);
    };

    const downloadFile = (fileId: string): Promise<AxiosResponse> => {
        return offerFileManagementApi.downloadFile(fileId, props.offer.id);
    };

    const invalidateCache = async () => {
        return props.invalidateCache();
    };

    return (
        <Attachments
            files={filesField.value}
            companyId={props.offer.supplierId}
            counterCompanyName={props.offer.customer.name}
            config={{
                uploadFile,
                deleteFile,
                downloadFile,
                invalidateCache,
                hasWriteRights: true,
                enableDownloadUploadedFiles: true,
            }}
        />
    );
};
