import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { ContractOverviewPage as OriginalOverviewPage } from './ContractOverviewPage';

const ContractOverviewPage = (props) => {
    return (
        <AppProviders>
            <OriginalOverviewPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(ContractOverviewPage, 'reactContractOverview');
