import * as RadixTooltip from '@radix-ui/react-tooltip';
import { TooltipContentProps } from '@radix-ui/react-tooltip';
import { ReactNode } from 'react';

import { Arrow } from './components/Arrow';
import { Content } from './components/Content';

interface TooltipProps extends Pick<TooltipContentProps, 'side' | 'align'> {
    children: ReactNode;
    label: ReactNode;
    maxWidth?: string;
    showArrow?: boolean;
}

export const Tooltip = ({ children, label, maxWidth, showArrow = false, side, align }: TooltipProps) => {
    return (
        <RadixTooltip.Provider>
            <RadixTooltip.Root>
                <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
                <RadixTooltip.Portal>
                    <Content align={align} side={side} $maxWidth={maxWidth} sideOffset={showArrow ? 4 : 8}>
                        {label}
                        {showArrow ? <Arrow /> : null}
                    </Content>
                </RadixTooltip.Portal>
            </RadixTooltip.Root>
        </RadixTooltip.Provider>
    );
};
