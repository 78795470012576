import moment from 'moment';
import React from 'react';

import { Text } from '../../../cdl/Text/Text';
import { Tooltip } from '../../../cdl/Tooltip/Tooltip';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { translate } from '../../../common/helpers/translate.helper';

interface Props {
    dateMeasured: string;
}
export const DateMeasured: React.FC<Props> = ({ dateMeasured }: Props) => {
    const timeSince = (dateMeasured: string): string | null => {
        const utcMoment = moment.utc();
        const utcDateMeasuredMoment = moment.utc(dateMeasured, true);

        if (!utcDateMeasuredMoment.isValid()) {
            return null;
        }

        if (utcDateMeasuredMoment.isAfter(utcMoment)) {
            return formatDate({
                date: dateMeasured,
                timeZone: 'UTC',
            });
        }

        // @ts-ignore
        const duration = utcMoment - utcDateMeasuredMoment;

        let interval = Math.floor(moment.duration(duration).as('days'));
        if (interval === 1) {
            return translate('order.stockbox.day', { day: interval.toString() });
        }
        if (interval >= 1) {
            return translate('order.stockbox.days', { days: interval.toString() });
        }
        interval = Math.floor(moment.duration(duration).as('hours'));
        if (interval === 1) {
            return translate('order.stockbox.hour', { hour: interval.toString() });
        }
        if (interval >= 1) {
            return translate('order.stockbox.hours', { hours: interval.toString() });
        }
        interval = Math.floor(moment.duration(duration).as('minutes'));
        if (interval === 1) {
            return translate('order.stockbox.minute', { minute: interval.toString() });
        }
        if (interval >= 1) {
            return translate('order.stockbox.minutes', { minutes: interval.toString() });
        }

        return null;
    };

    const timeSinceDate = timeSince(dateMeasured);

    if (!timeSinceDate) {
        return null;
    }

    return (
        <Tooltip
            label={translate('order.stockbox.lastUpdatedTooltip', {
                date: formatDate({ date: dateMeasured, timeZone: 'UTC' }),
            })}
        >
            <Text variant="extraSmall" color="foreground.subtle" data-testid="info-label">
                {timeSinceDate}
            </Text>
        </Tooltip>
    );
};
