import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconPackage = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M11.51 2.128a1 1 0 0 1 .98 0l3.986 2.243.03.016 3.984 2.241A1 1 0 0 1 21 7.5v9a1 1 0 0 1-.51.872l-8 4.5a1 1 0 0 1-.98 0l-8-4.5A1 1 0 0 1 3 16.5v-9a1 1 0 0 1 .51-.872l8-4.5Zm2.45 3.122L12 4.147 6.04 7.5 8 8.603l5.96-3.353Zm-3.92 4.5L16 6.397 17.96 7.5 12 10.853 10.04 9.75Zm-2.546.863.03.017L11 12.585v6.705l-6-3.375V9.21l2.494 1.403ZM13 19.29l6-3.375V9.21l-6 3.375v6.705Z"
            clipRule="evenodd"
        />
    </svg>
);
