import { ProductCategoryFuel } from '../../../types/ProductCategoryFuel';
import { ProductCategoryLubes } from '../../../types/ProductCategoryLubes';

const sortByCategory = (
    categories: typeof ProductCategoryLubes | typeof ProductCategoryFuel
): ((a: string, b: string) => number) => {
    const orderedCategories = Object.values(categories).reduce((acc, cv, index) => {
        acc[cv] = index;
        return acc;
    }, {});

    return (a, b) => orderedCategories[a] - orderedCategories[b];
};

export const sortLubesCategory = sortByCategory(ProductCategoryLubes);

export const sortFuelCategory = sortByCategory(ProductCategoryFuel);
