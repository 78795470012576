import styled from 'styled-components';

export const OverlayContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 9997;
    overflow-y: scroll;
    max-height: 100%;
`;
