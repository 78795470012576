import { ItemModel } from '../../models/ItemModel';
import { Table } from '../../Table/Table';

import { LubesProductTableConfig, LubesProductTableContext } from './components/LubesProductTableContext';
import { TableContent } from './components/TableContent';
import { TableHeaderRow } from './components/TableHeaderRow';

interface Config extends LubesProductTableConfig {
    initialItems: ItemModel[];
}

interface FormikLubesProductTableProps {
    config: Config;
}

export const FormikLubesProductTable = ({ config }: FormikLubesProductTableProps) => {
    return (
        <LubesProductTableContext.Provider value={config}>
            <Table>
                <thead>
                    <TableHeaderRow />
                </thead>
                <tbody>
                    <TableContent />
                </tbody>
            </Table>
        </LubesProductTableContext.Provider>
    );
};
