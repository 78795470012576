import styled, { css } from 'styled-components';

import { get } from '../../../common/ui/get';

const activeStyles = css`
    box-shadow: inset 0 0 0 2px ${get('colors.accent.emphasis')};
    background-color: ${get('colors.accent.background')} !important;
`;

interface StyledDefaultTableBodyRowProps {
    active: boolean;
    selectable: boolean;
    alternateRowColors: boolean;
    disabled?: boolean;
}

export const StyledDefaultTableBodyRow = styled.tr<StyledDefaultTableBodyRowProps>`
    color: ${(props) => (props.disabled ? get('colors.foreground.disabled') : 'inherit')};

    &:nth-child(odd) {
        background-color: ${(props) => (props.alternateRowColors ? get('colors.background.subtle')(props) : 'none')};
    }

    &:hover {
        cursor: ${(props) => (props.selectable ? 'pointer' : 'default')};
    }

    ${(props) => (props.active ? activeStyles : null)}
`;
