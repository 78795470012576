import styled from 'styled-components';

import { DemandInformationVessel, VesselState } from '../../../types/DemandInformationMessage';
import { ProductContext } from '../../../types/ProductContext';
import { DiscoverableButton } from '../../cdl/Button/DiscoverableButton';
import { DropdownMenu } from '../../cdl/DropdownMenu/DropdownMenu';
import { Tag } from '../../cdl/Tag/Tag';
import { translate } from '../../common/helpers/translate.helper';
import { useNavigation } from '../../common/hooks/useNavigation';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { Box } from '../../common/ui/Box';

import { DemandAccordionToggleButton } from './DemandAccordionToggleButton';
import { DemandHeaderWrapper } from './DemandHeaderWrapper';
import { DemandLink } from './DemandLink';
import { DemandLubesAdditionalActions } from './DemandLubesAdditionalActions';
import { DemandVesselDaysLeftSailing } from './DemandVesselDaysLeftSailing';
import { VesselScheduleSummary } from './VesselScheduleSummary/VesselScheduleSummary';

const VesselStateBadge = ({ state }: { state: VesselState }) => {
    switch (state) {
        case VesselState.ORDERED:
            return <Tag variant="default">{translate('demand.ordered')}</Tag>;
        case VesselState.PLANNED:
            return <Tag variant="default">{translate('demand.planned')}</Tag>;
        case VesselState.OPEN:
            return null;
    }
};

const VesselNameContainer = styled.div`
    width: 100%;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface DemandAccordionHeaderProps {
    isOpen: boolean;
    disabled: boolean;
    vessel: DemandInformationVessel;
    onClick: () => void;
}

export const DemandAccordionHeader = ({ isOpen, disabled, onClick, vessel }: DemandAccordionHeaderProps) => {
    const { context: productContext } = useProductContext();
    const { navigate } = useNavigation();
    const role = useRole();

    const handleHeaderClick = () => {
        if (disabled) {
            return;
        }

        onClick();
    };

    const navigateToPrePlanning = () => {
        navigate('base.fuel-planning-bunker-case', {
            vesselId: vessel.id,
            caseNumber: vessel.bunkerMetricRecommendation?.caseNumber,
            solutionNumber: vessel.bunkerMetricRecommendation?.solutionNumber,
        });
    };

    const vesselDetailPageLink =
        productContext === ProductContext.FUEL ? 'base.fuel-vessels-vessel' : 'base.vessels-vessel';

    return (
        <DemandHeaderWrapper isOpen={isOpen} disabled={disabled} onClick={handleHeaderClick}>
            <DemandAccordionToggleButton isOpen={isOpen} disabled={disabled} />

            <VesselNameContainer>
                <DemandLink
                    variant="fieldLabel"
                    fontWeight="semiBold"
                    to={vesselDetailPageLink}
                    params={{
                        id: vessel.id,
                    }}
                >
                    {vessel.name}
                </DemandLink>
            </VesselNameContainer>
            <div>
                <VesselStateBadge state={vessel.state} />
            </div>

            <DemandVesselDaysLeftSailing categories={vessel.categories} />
            <VesselScheduleSummary vessel={vessel} />

            <Box marginLeft="auto" display="flex" alignItems="center" gap={4}>
                {vessel.bunkerMetricRecommendation?.caseNumber && vessel.bunkerMetricRecommendation?.solutionNumber ? (
                    vessel.state === VesselState.ORDERED || vessel.state === VesselState.PLANNED ? (
                        <>
                            <DiscoverableButton
                                emphasis="high"
                                onClick={() => navigate('base.fuel-planning-bunker-plan')}
                            >
                                {translate('demand.viewBunkerPlan')}
                            </DiscoverableButton>
                            <DropdownMenu
                                align="end"
                                options={[
                                    {
                                        label: translate('demand.continuePrePlanning'),
                                        onSelect: () => {
                                            navigateToPrePlanning();
                                        },
                                    },
                                ]}
                            />
                        </>
                    ) : (
                        <DiscoverableButton emphasis="high" onClick={() => navigateToPrePlanning()}>
                            {translate('demand.prePlanningPageButton')}
                        </DiscoverableButton>
                    )
                ) : (
                    <>
                        <DiscoverableButton
                            emphasis="high"
                            onClick={() => {
                                navigate(vesselDetailPageLink, {
                                    id: vessel.id,
                                });
                            }}
                        >
                            {translate('demand.vesselDetailsButton')}
                        </DiscoverableButton>
                        {productContext === ProductContext.LUBES &&
                        !role.isAdmin() &&
                        role.hasWriteRights(vessel.customerId) ? (
                            <DemandLubesAdditionalActions vessel={vessel} />
                        ) : null}
                    </>
                )}
            </Box>
        </DemandHeaderWrapper>
    );
};
