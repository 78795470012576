import { animated, config, useTransition } from '@react-spring/web';
import useComponentSize from '@rehooks/component-size';
import { useRef } from 'react';
import styled from 'styled-components';

import { ProductContext } from '../../../../../../types/ProductContext';
import { Tag } from '../../../../../cdl/Tag/Tag';
import { queries } from '../../../../../common/api/queryKeys/queries';
import { translate } from '../../../../../common/helpers/translate.helper';
import { useRole } from '../../../../../common/hooks/useRole';
import { Box } from '../../../../../common/ui/Box';
import { get } from '../../../../../common/ui/get';
import { OfferModel } from '../../../../../offer/model/OfferModel';
import { OfferChat } from '../../../../../offer/OfferChat/OfferChat';
import { OrderModel } from '../../../../model/OrderModel';
import { OfferCompareAttachments } from '../../../common/OfferCard/OfferCompareAttachments';
import { OfferListItemGrid } from '../../../common/OfferCard/OfferListItemGrid';
import { LubesReadOnlyProductTable } from '../LubesReadOnlyTable/LubesReadOnlyProductTable';

import { OfferActions } from './components/OfferActions';
import { OfferCardContainer } from './components/OfferCardContainer';
import { OfferCardContextProvider } from './components/OfferCardContextProvider';
import { OfferSummary } from './components/OfferSummary';

const OfferTagIndicators = styled.div`
    display: flex;
    background-color: ${get('colors.background.inset')};
    width: 100%;
    height: 35px;
    padding-left: ${get('space.3')}px;
    padding-top: 5px;
    border-radius: ${get('radii.2')}px ${get('radii.2')}px 0 0;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    column-gap: ${get('space.2')}px;
`;

const OfferChatWrapper = styled(Box).attrs({
    width: '20%',
    height: '100%',
    position: 'absolute',
    right: 0,
    borderLeft: 1,
})``;

const OfferAccordion = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`;

const OfferAccordionContentWrapper = styled.div`
    display: flex;
    width: calc(100% - 16px);
    margin: auto;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
    border: 1px solid;
    border-color: ${get('colors.border.muted')};
    border-top: none;
    z-index: 1;
    position: relative;
`;

interface LubesOfferCardProps {
    offer: OfferModel;
    order: OrderModel;
    isBestLiterPrice?: boolean;
    expanded: boolean;
    onClick: () => void;
}

export const LubesOfferCard = (props: LubesOfferCardProps) => {
    const accordionContentRef = useRef<HTMLDivElement>(null);
    const { height } = useComponentSize(accordionContentRef);
    const role = useRole();

    const hasChangedItems = props.offer.items.some((item) => Object.values(item.changes).some((change) => change));
    const hasChangedSampleKits = props.offer.samplekits
        ? props.offer.samplekits?.some((sampleKit) => Object.values(sampleKit.changes).some((change) => change))
        : false;

    const hasChanges = hasChangedItems || hasChangedSampleKits;

    const contentAnimation = useTransition(props.expanded, {
        from: {
            opacity: 0,
            height: 0,
        },
        enter: {
            opacity: 1,
            height: height,
        },
        leave: {
            opacity: 0,
            height: 0,
        },
        update: {
            height: height,
            opacity: 1,
        },
        config: {
            ...config.default,
            duration: 150,
        },
    });

    const showChangesTag = props.offer.displayQuoteDetails() && hasChanges;
    const showSupplierReadTag = role.isAdmin() && props.offer.supplierRead;

    const showTags = showChangesTag || showSupplierReadTag;

    return (
        <OfferCardContextProvider offer={props.offer} order={props.order}>
            <OfferAccordion paddingTop={showTags ? '30px' : 0}>
                <OfferTagIndicators>
                    {showChangesTag ? (
                        <Tag variant="attention">{translate('order.detail.offerCard.changed')}</Tag>
                    ) : null}
                    {showSupplierReadTag ? (
                        <Tag variant="default">{translate('order.detail.offerCard.supplierRead')}</Tag>
                    ) : null}
                </OfferTagIndicators>

                <OfferCardContainer expanded={props.expanded}>
                    <OfferListItemGrid>
                        <OfferSummary onClick={props.onClick} isBestLiterPrice={props.isBestLiterPrice} />
                        <OfferActions />
                    </OfferListItemGrid>
                </OfferCardContainer>

                {contentAnimation((style, item) =>
                    item ? (
                        <animated.div style={{ ...style, overflow: 'hidden' }}>
                            <OfferAccordionContentWrapper ref={accordionContentRef}>
                                <Box
                                    display="flex"
                                    width="80%"
                                    minHeight="400px"
                                    padding={7}
                                    rowGap={6}
                                    flexDirection="column"
                                >
                                    <LubesReadOnlyProductTable
                                        items={props.offer.items}
                                        sampleKits={props.offer.samplekits}
                                        surcharges={props.offer.surcharges}
                                        showPrices
                                    />

                                    <Box flex="auto">
                                        <OfferCompareAttachments
                                            offer={props.offer}
                                            invalidationQueryKey={
                                                queries.offers.list({
                                                    orderIds: [props.offer.orderId],
                                                    types: [ProductContext.LUBES],
                                                }).queryKey
                                            }
                                        />
                                    </Box>
                                </Box>

                                <OfferChatWrapper>
                                    <OfferChat offer={props.offer} />
                                </OfferChatWrapper>
                            </OfferAccordionContentWrapper>
                        </animated.div>
                    ) : null
                )}
            </OfferAccordion>
        </OfferCardContextProvider>
    );
};
