import { MoneyMessage } from './MoneyMessage';
import { PortMessage } from './PortMessage';
import { VesselMessage } from './VesselMessage';

export enum BunkerMode {
    BUNKERING = 'BUNKERING',
    CANAL_TRANSIT = 'CANAL_TRANSIT',
    CLEANING = 'CLEANING',
    CONTRACTUAL = 'CONTRACTUAL',
    DISCHARGE = 'DISCHARGE',
    DRYDOCK = 'DRYDOCK',
    IDLING = 'IDLING',
    LOADING = 'LOADING',
    MULTI_PURPOSE = 'MULTI_PURPOSE',
    LADEN = 'LADEN',
    BALLAST = 'BALLAST',
    BUFFER_NO_BUNKERING = 'BUFFER_NO_BUNKERING',
    REDELIVERY = 'REDELIVERY',
}

export enum CategoryWarningType {
    OVERFILL = 'OVERFILL',
    QUARANTINE_VIOLATION = 'QUARANTINE_VIOLATION',
    QUARANTINE_REQUIREMENT = 'QUARANTINE_REQUIREMENT',
    RESERVE_REQ_VIOLATION_START = 'RESERVE_REQ_VIOLATION_START',
    RESERVE_REQ_VIOLATION_END = 'RESERVE_REQ_VIOLATION_END',
    MARGIN_VIOLATION = 'MARGIN_VIOLATION',
    COMMINGLING = 'COMMINGLING',
    WARNING = 'WARNING',
    FUEL_SUBSTITUTION = 'FUEL_SUBSTITUTION',
}

export enum PortLocationType {
    ANCHOR = 'ANCHOR',
    BERTH = 'BERTH',
}

export interface CategoryWarning {
    type: CategoryWarningType;
    message: string;
}

export interface CategoryRecommendation {
    productCategory: string;
    sulphurContent: string;
    consumption: number;
    quantityStart: number;
    quantityEnd: number;
    consumptionWarnings: CategoryWarning[];
    quantityStartWarnings: CategoryWarning[];
    recommendedContractId?: string;
}

export interface CategoryPurchase {
    productCategory: string;
    sulphurContent: string;
    quantity: number;
    price: MoneyMessage;
    priceDate?: string;
    latestDeliveryDate?: string;
    total: MoneyMessage;
}

export interface BunkerScheduleCosts {
    fuelCost?: MoneyMessage;
    canalCost?: MoneyMessage;
    portCost?: MoneyMessage;
    bargeCost?: MoneyMessage;
    tcePenaltyCost?: MoneyMessage;
}

export interface BunkerSchedule {
    portId: string;
    port: PortMessage;
    eta: string;
    etd: string;
    mode: BunkerMode;
    inducedCall: boolean;
    portLocationType?: PortLocationType;
    categoryConsumption: CategoryRecommendation[];
    purchases: CategoryPurchase[];
    bunkerScheduleCosts: BunkerScheduleCosts;
    totalCosts: MoneyMessage;
}

export interface BunkerCaseSolutionResponse {
    caseNumber: string;
    solutionNumber: string;
    vesselId: string;
    vessel: VesselMessage;
    bunkerSchedules: BunkerSchedule[];
}
