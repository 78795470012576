import styled from 'styled-components';
import { compose, textAlign, TextAlignProps, width } from 'styled-system';

import { get } from '../../../common/ui/get';
import { TableBuilderColumnProps } from '../TableBuilderColumn';

export const StyledInlineHeaderCell = styled.th<TextAlignProps & Pick<TableBuilderColumnProps<unknown>, 'width'>>`
    padding: ${get('space.4')}px;

    ${compose(textAlign, width)}
`;
