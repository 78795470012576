import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { LoginPage as OriginalLoginPage } from './LoginPage';

const LoginPageWrapper = (props) => {
    return (
        <AppProviders>
            <OriginalLoginPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(LoginPageWrapper, 'reactLoginPage');
