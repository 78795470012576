import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { LubesOfferComparePage } from './LubesOfferComparePage';

const LubesOfferComparePageDirective = (props: any) => {
    return (
        <AppProviders>
            <LubesOfferComparePage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(LubesOfferComparePageDirective, 'reactLubesOfferComparePage');
