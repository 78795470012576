import { BaseStatisticsMessage } from '../../../types/StatisticsMessage';

import { PortsTabFilter } from './PortsTabFilter';
import { PortsTabStatistics } from './PortsTabStatistics';

interface PortsTabContentProps {
    portStatistics: BaseStatisticsMessage['port'];
}

export const PortsTabContent = ({ portStatistics }: PortsTabContentProps) => {
    return (
        <PortsTabFilter allEntries={portStatistics}>
            {(filteredEntries) => <PortsTabStatistics portStatistics={filteredEntries} />}
        </PortsTabFilter>
    );
};
