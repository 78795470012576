import { useQuery, useQueryClient } from '@tanstack/react-query';

import { queries } from '../api/queryKeys/queries';

import { useProductContext } from './useProductContext';
import { useRole } from './useRole';

export const useKeyPorts = () => {
    const { context } = useProductContext();
    const queryClient = useQueryClient();
    const role = useRole();

    const { data, isPending, isSuccess } = useQuery({
        ...queries.keyPorts.list(context),
        staleTime: Infinity,
        enabled: !!role?.isCustomer() && !!context,
    });

    const prefetch = () => {
        if (!!role?.isCustomer() && !!context) {
            queryClient.prefetchQuery({ ...queries.keyPorts.list(context), staleTime: Infinity });
        }
    };

    const keyPorts = isSuccess ? data.keyPorts : [];

    const isKeyPort = ({ portId, vesselId }) => {
        // No vesselId is specified. Just check for portId in key port.
        if (!vesselId) {
            return keyPorts.some((keyPort) => keyPort.portId === portId);
        }

        const keyPort = keyPorts.find((keyPort) => keyPort.portId === portId);

        // There is no key port for the specified portId
        if (!keyPort) {
            return false;
        }

        // There is a key port and it has no limitations, so we know it is a key port for all vessels
        if (!keyPort.vesselLimitations?.length) {
            return true;
        }

        // There is a key port. Now we need to check whether the vesselId matches.
        return keyPort.vesselLimitations.some((vesselLimitationId) => vesselLimitationId === vesselId);
    };

    return {
        keyPorts,
        isKeyPort,
        isPending,
        prefetch,
    };
};
