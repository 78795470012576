import { useQuery } from '@tanstack/react-query';

import { GetAgentListParams } from '../../../common/api/clients/agent.api';
import { queries } from '../../../common/api/queryKeys/queries';

interface UseAgentListParams extends GetAgentListParams {}

export const useAgentList = (params: UseAgentListParams) => {
    return useQuery(queries.agents.list({ customerIds: params.customerIds, portIds: params.portIds }));
};
