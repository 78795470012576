import { useLocationState } from '../../common/LocationState/useLocationState';
import { TabSwitch } from '../../common/TabSwitch/TabSwitch';

import { StatisticsStateConfig } from './StatisticsState';
import { StatisticTab } from './StatisticTab';

export const CustomerStatisticsTabSwitch = () => {
    const [locationStateUntyped, { setLocationFieldValue }] = useLocationState();
    const locationState = locationStateUntyped as StatisticsStateConfig;

    return (
        <TabSwitch
            selectedContext={locationState.tab}
            onSelect={(tab) => setLocationFieldValue('tab', tab)}
            totals={{
                [StatisticTab.GENERAL]: null,
                [StatisticTab.VESSELS]: null,
                [StatisticTab.SUPPLIERS]: null,
                [StatisticTab.PORTS]: null,
                [StatisticTab.PRODUCTS]: null,
                [StatisticTab.PRODUCT_GROUPS]: null,
            }}
        />
    );
};
