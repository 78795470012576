import { ApprovalSettings } from '../../../../../../types/CustomerSettingsMessage';

export function getAdditionalApproverCount(approvalSettings: ApprovalSettings) {
    if (!approvalSettings.additionalApprovers) {
        return 0;
    }

    return approvalSettings.additionalApprovers.reduce((acc, approvalLimit) => {
        if (!approvalLimit.approvers) {
            return acc;
        }

        return acc + approvalLimit.approvers.length;
    }, 0);
}
