import { ReactNode } from 'react';

import { Text } from '../../cdl/Text/Text';
import { Box } from '../ui/Box';

interface CoreDataPopupProps {
    heading: string;
    children: ReactNode;
}

export const CoreDataPopup = ({ heading, children }: CoreDataPopupProps) => {
    return (
        <Box display="grid" padding={6} gap={5}>
            <Text variant="title">{heading}</Text>
            {children}
        </Box>
    );
};
