import PropTypes from 'prop-types';

import { translate } from '../helpers/translate.helper';

/** @deprecated */

export const YesOrNo = ({ value }) => {
    if (value) {
        return <span>{translate('global.yes')}</span>;
    }

    return <span>{translate('global.no')}</span>;
};

YesOrNo.propTypes = {
    value: PropTypes.bool.isRequired,
};
