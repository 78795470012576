import { baseAxiosInstance } from '../instances/BaseAxiosInstance';

interface SetNewPasswordParams {
    password: string;
    token: string;
}

export const setNewPassword = async ({ password, token }: SetNewPasswordParams): Promise<any> => {
    const response = await baseAxiosInstance.put(`/v1/password-resets/${token}/password`, { password });

    return response.data;
};

interface CreatePasswordResetParams {
    emailAddress: string;
}

export const createPasswordReset = async ({ emailAddress }: CreatePasswordResetParams): Promise<any> => {
    const response = await baseAxiosInstance.post('/v1/password-resets', { emailAddress });

    return response.data;
};
