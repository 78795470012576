import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const getVesselBudget = async (vesselId: string): Promise<any> => {
    const response = await authorizedAxiosInstance.get(`/v1/vessel-budgets/vessel/${vesselId}`);

    return response.data;
};
export const createVesselBudget = async (budget: any): Promise<any> => {
    const response = await authorizedAxiosInstance.post('/v1/vessel-budgets', budget);

    return response.data;
};
export const editVesselBudget = async (budget: any): Promise<any> => {
    const response = await authorizedAxiosInstance.put(`/v1/vessel-budgets/${budget.id}`, budget);

    return response.data;
};
export const deleteVesselBudget = async (budgetId: string): Promise<any> => {
    const response = await authorizedAxiosInstance.delete(`/v1/vessel-budgets/${budgetId}`);

    return response.data;
};
