import { getContracts } from '../../common/api/clients/gateway.api';
import { queryKeys } from '../../common/api/queryKeys';
import { usePaginationQuery } from '../../common/hooks/usePaginationQuery';

export const useContractsPagination = ({ page, searchQuery, customerGroupIds, tender, active }) => {
    return usePaginationQuery({
        queryKey: queryKeys.contracts({ page, searchQuery, customerGroupIds, tender, active }),
        queryFn: () =>
            getContracts({
                page,
                searchQuery,
                customerGroupIds,
                tender,
                active,
            }),
        options: { keepPreviousData: true },
        prefetchQueryKey: queryKeys.contracts({
            page: page + 1,
            searchQuery,
            customerGroupIds,
            tender,
            active,
        }),
        prefetchQueryFn: () =>
            getContracts({
                page: page + 1,
                searchQuery,
                customerGroupIds,
                tender,
                active,
            }),
    });
};
