import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { OfferFeeMessage } from '../../../../../types/OfferFeeMessage';
import { getFeeForOfferTotal } from '../../../../common/api/clients/fee.api';
import { queryKeys } from '../../../../common/api/queryKeys';
import { useRole } from '../../../../common/hooks/useRole';

export function useFee(
    offerTotal: number,
    currencyCode: string = 'USD',
    enabled: boolean = true
): UseQueryResult<OfferFeeMessage> {
    const role = useRole();

    return useQuery({
        queryKey: queryKeys.feesByOfferTotal(offerTotal, currencyCode),
        queryFn: () => getFeeForOfferTotal(offerTotal, currencyCode),
        enabled: enabled && (role.isSupplier() || role.isAdmin()),
    });
}
