import { CustomerGroupMessage } from '../../../../types/CustomerGroupMessage';
import { getCustomerGroups } from '../../../common/api/clients/customerGroups.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { usePaginationQuery } from '../../../common/hooks/usePaginationQuery';

export const useCustomerGroupsPagination = (searchQuery: string, page: number) => {
    return usePaginationQuery<CustomerGroupMessage>({
        queryKey: queryKeys.customerGroups({ searchQuery, page }),
        queryFn: () => getCustomerGroups(searchQuery, page),
        prefetchQueryKey: queryKeys.customerGroups({ searchQuery, page: page + 1 }),
        prefetchQueryFn: () => getCustomerGroups(searchQuery, page + 1),
    });
};
