import { useReducer } from 'react';

import { FileMessage } from '../../../../types/FileMessage';
import { generateRandomId } from '../../../order/compare/generateRandomId';

export interface FileData {
    id: string;
    file: File;
    deleted: boolean;
    uploadResponse?: FileMessage;
}

type Action =
    | {
          type: 'ADD_UPLOAD_RESPONSE';
          fileId: string;
          uploadResponse: FileMessage;
      }
    | { type: 'DELETE_FILE'; fileId: string }
    | { type: 'ADD_FILES'; files: File[] };

const uploadAreaReducer = (files: FileData[], action: Action) => {
    switch (action.type) {
        case 'ADD_UPLOAD_RESPONSE': {
            const filesStateCopy = [...files];
            const index = filesStateCopy.findIndex((file) => file.id === action.fileId);

            filesStateCopy[index] = {
                ...filesStateCopy[index],
                uploadResponse: action.uploadResponse,
            };

            return filesStateCopy;
        }
        case 'DELETE_FILE': {
            const filesStateCopy = [...files];

            const index = filesStateCopy.findIndex((file) => file.id === action.fileId);

            filesStateCopy[index] = {
                ...filesStateCopy[index],
                deleted: true,
            };

            return filesStateCopy;
        }
        case 'ADD_FILES': {
            const filesStateCopy = [...files];
            action.files.forEach((file) => {
                filesStateCopy.push({
                    file: file,
                    deleted: false,
                    id: generateRandomId(),
                });
            });
            return filesStateCopy;
        }
    }
};

export const useUploadAreaReducer = () => {
    return useReducer(uploadAreaReducer, []);
};
