interface FormatDateConfig {
    date: number | string;
    timeZone?: string;
}

export const formatDate = ({ date, timeZone }: FormatDateConfig) => {
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone,
    }).format(new Date(date));
};
