import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconAnchor = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12 4a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM8 6a4 4 0 1 1 5 3.874v10.054a6.999 6.999 0 0 0 5.929-5.93A1 1 0 0 1 19 12h2a1 1 0 1 1 0 2h-.056a9 9 0 0 1-17.888 0H3a1 1 0 1 1 0-2h2a1 1 0 0 1 .071 1.998A7 7 0 0 0 11 19.928V9.874A4.002 4.002 0 0 1 8 6Z"
            clipRule="evenodd"
        />
    </svg>
);
