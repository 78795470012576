import { Tag } from '../../../../cdl/Tag/Tag';
import { useEnums } from '../../../../common/hooks/useEnums';
import { OrderState } from '../../../model/OrderModel';

const determineTagVariant = (state: OrderState) => {
    switch (state) {
        case OrderState.DRAFT:
        case OrderState.OPEN:
        case OrderState.CLOSED:
            return 'default';
        case OrderState.CANCELED:
            return 'negative';
    }
};

interface OrderStateTagProps {
    state: OrderState;
}

export const OrderStateTag = ({ state }: OrderStateTagProps) => {
    const { getHumanReadableValue } = useEnums();

    return <Tag variant={determineTagVariant(state)}>{getHumanReadableValue('orderState', state)}</Tag>;
};
