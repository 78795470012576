import { ReactNode } from 'react';

import { LocationStateProvider } from '../../../common/LocationState/LocationStateProvider';
import { sortValueHelpers } from '../../../common/LocationState/sortValueHelpers';

export const LubesOfferOverviewState = ({ children }: { children: ReactNode }) => {
    const stateConfig = {
        page: 0,
        searchQuery: '',
        customerIds: [],
        supplierIds: [],
        tab: 'enquired',
        sortValue: {
            defaultValue: {
                sortField: 'dateUpdated',
                sortDirection: 'DESC',
            },
            deserializer: sortValueHelpers.deserializer,
            serializer: sortValueHelpers.serializer,
        },
    };

    return <LocationStateProvider stateConfig={stateConfig}>{children}</LocationStateProvider>;
};
