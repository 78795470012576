import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconAlertTriangle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M10.466 2.384a3 3 0 0 1 4.144 1.122l7.095 12.243c.024.04.044.082.062.126A3 3 0 0 1 19.007 20H5l-.055-.001a1.032 1.032 0 0 1-.107 0 3 3 0 0 1-2.625-4.125.996.996 0 0 1 .062-.125L9.37 3.506a3 3 0 0 1 1.096-1.122ZM4.945 18.001 5 18h13.996a1 1 0 0 0 .937-1.32L12.875 4.5l-.01-.017a1 1 0 0 0-1.75 0l-.01.017-7.059 12.18A1 1 0 0 0 4.945 18ZM12 8a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1Zm0 6a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0V15a1 1 0 0 1 1-1Z"
            clipRule="evenodd"
        />
    </svg>
);
