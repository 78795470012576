import styled from 'styled-components';

export const ActionListItem = styled.li`
    cursor: pointer;
    display: inline-block;
    margin-right: 20px;

    &:last-child {
        margin-right: 0;
    }
`;
