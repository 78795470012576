import { Button } from '../../cdl/Button/Button';
import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { useNavigation } from '../../common/hooks/useNavigation';
import { Box } from '../../common/ui/Box';

export const SetNewPasswordCompleteContent = () => {
    const { navigate } = useNavigation();

    return (
        <div>
            <Text variant="title" as="h1">
                {translate('setNewPassword.successfullyChangedPassword')}
            </Text>
            <Box marginTop={6}>
                <Button emphasis="high" size="large" type="submit" width="100%" onClick={() => navigate('login')}>
                    {translate('setNewPassword.goToLogin')}
                </Button>
            </Box>
        </div>
    );
};
