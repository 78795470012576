import styled from 'styled-components';

import { get } from './get';

/**  @deprecated  **/

export const RoundedButton = styled.button.attrs((props) => ({
    type: 'button',
    ...props,
}))`
    display: inline-flex;
    align-items: center;
    padding: 8px;
    border-radius: ${get('radii.1')}px;
    border: ${get('borders.1')};
`;
