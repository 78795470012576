import { useFormikContext } from 'formik';

import { Text } from '../../cdl/Text/Text';
import { Button } from '../../common/buttons/Button';
import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { FormikPositiveNumberInput } from '../../common/form-elements/formik/FormikPositiveNumberInput';
import { FormikTextArea } from '../../common/form-elements/formik/FormikTextArea';
import { Label } from '../../common/form-elements/Label/Label';
import { translate } from '../../common/helpers/translate.helper';
import { IconChevronRight } from '../../common/icons/cdl/ChevronRight';
import { IconUsers } from '../../common/icons/cdl/Users';
import { ButtonSpinner } from '../../common/Spinner/ButtonSpinner';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';
import { Grid, GridItem } from '../../common/ui/Grid';

import { FuelEnquiryAttachments } from './FuelEnquiryAttachments';
import { FuelEnquiryReceivers } from './FuelEnquiryReceivers';
import { useEnquiryCreation } from './hooks/useEnquiryCreation';
import { PreviousButton } from './PreviousButton';
import { SaveAsDraftButton } from './SaveAsDraftButton';

export const FuelEnquiryReceiversInformation = () => {
    const { isValid, submitForm, isSubmitting } = useFormikContext();
    const { setPage } = useEnquiryCreation();

    return (
        <Box>
            <Flex flexDirection="row" alignItems="center" color="dark-blue.3">
                <IconUsers />
                <Text marginLeft={4} size={14} weight="semi">
                    {translate('fuelEnquiry.third.receiversAndValidityTitle')}
                </Text>
            </Flex>

            <Grid marginTop={6} flexDirection="column">
                <GridItem>
                    <FuelEnquiryReceivers />
                </GridItem>
            </Grid>
            <Grid marginTop={6}>
                <GridItem width={1 / 2}>
                    <FormikTextArea
                        label={translate('fuelEnquiry.third.textarea.title')}
                        placeholder={translate('fuelEnquiry.third.textarea.placeholder')}
                        name="comment"
                    />

                    <FormikPositiveNumberInput
                        min={1}
                        name="validityTime"
                        label={translate('fuelEnquiry.third.validity.label')}
                        placeholder={translate('fuelEnquiry.third.validity.placeholder')}
                    />
                </GridItem>

                <GridItem width={1 / 2}>
                    <Label label={translate('fuelEnquiry.third.attachments.label')}>
                        <FuelEnquiryAttachments />
                    </Label>
                </GridItem>
            </Grid>

            <Flex justifyContent="center" marginTop={9}>
                <ButtonGroup>
                    <PreviousButton onClick={() => setPage(2)} />
                    <SaveAsDraftButton />
                    <Button color="solid-clear" disabled={!isValid || isSubmitting} onClick={submitForm}>
                        {translate('fuelEnquiry.navigation.sendEnquiry')}
                        {isSubmitting ? <ButtonSpinner /> : <IconChevronRight />}
                    </Button>
                </ButtonGroup>
            </Flex>
        </Box>
    );
};
