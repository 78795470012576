import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createSurcharge } from '../../../../common/api/clients/surcharge.api';
import { queries } from '../../../../common/api/queryKeys/queries';

export const useSurchargeCreate = (groupId: string) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: createSurcharge,
        onSuccess: () => {
            return queryClient.invalidateQueries({
                queryKey: queries.supplierGroups.detail(groupId)._ctx.surcharges.queryKey,
            });
        },
    });
};
