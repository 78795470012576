import { createQueryKeys } from '@lukemorales/query-key-factory';

import { calculatePackagePrices, PackagePriceRequestMessage } from '../clients/packagePrice.api';

export const packagePrices = createQueryKeys('packagePrices', {
    prices: (customerId: string, packagePricesRequest: PackagePriceRequestMessage[]) => ({
        queryKey: [{ customerId, packagePricesRequest }],
        queryFn: () => calculatePackagePrices(customerId, packagePricesRequest),
    }),
});
