import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../../../cdl/Toast/useToasts';
import { createCounterOffer } from '../../../../../common/api/clients/counterOffer.api';
import { queryKeys } from '../../../../../common/api/queryKeys';
import { translate } from '../../../../../common/helpers/translate.helper';

export const useCounterOfferCreate = () => {
    const queryClient = useQueryClient();
    const { addErrorToast } = useToasts();

    return useMutation({
        mutationFn: createCounterOffer,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: queryKeys.fuelOrderOffers() });
        },
        onError: () => {
            addErrorToast(translate('counterOffer.toast.createError'));
        },
    });
};
