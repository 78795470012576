import styled from 'styled-components';

import { theme } from '../../common/ui/theme';

interface IndeterminateProps {
    disabled?: boolean;
}

export const IntederminateSwitch = styled.div<IndeterminateProps>`
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 24px;
    height: 16px;
    background: ${(props) => (props.disabled ? theme.colors.border.accent : theme.colors.accent.emphasis)};
    border-radius: ${theme.radii[2]}px;

    &:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 4px;
        background: ${theme.colors.foreground.onEmphasis};
        border-radius: ${theme.radii[2]}px;
    }
`;
