import PropTypes from 'prop-types';
import { Table as BootstrapTable } from 'react-bootstrap';

export const Table = ({ cursor = false, className = '', ...rest }) => {
    return <BootstrapTable className={cursor ? `table-cursor ${className}` : className} {...rest} />;
};

Table.propTypes = {
    cursor: PropTypes.bool,
    className: PropTypes.string,
};
