import { useQuery } from '@tanstack/react-query';

import { getOrder } from '../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { Order } from '../../model/order.model';

const formatDateWithTimeZone = (date, timeZoneId) => {
    return formatDateTime({
        date,
        timeZoneId,
        format: 'YYYY-MM-DDTHH:mm',
        //The date time picker requires this format
    });
};

export const useOrderView = (orderId) => {
    return useQuery({
        queryKey: queryKeys.order(orderId),
        queryFn: () => getOrder(orderId),
        staleTime: Infinity,
        select: (order) => {
            return new Order({
                ...order,
                eta: order.eta ? formatDateWithTimeZone(order.eta, order.port?.timeZoneId) : '',
                etd: order.etd ? formatDateWithTimeZone(order.etd, order.port?.timeZoneId) : '',
            });
        },
    });
};
