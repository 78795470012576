import { FormikApprovalSettingsState } from '../MultipleApproversSettingsForm';

export function hasMultipleUniqueApprovers(values: FormikApprovalSettingsState): Boolean {
    const emailAddresses = new Set<string>();

    if (values.defaultControllerEmailAddress && values.defaultControllerEmailAddress.length > 0) {
        emailAddresses.add(values.defaultControllerEmailAddress);
    }

    values.additionalApprovers.forEach((approverLimit) => {
        approverLimit.approvers.forEach((approver) => {
            if (approver.emailAddress.length > 0) {
                emailAddresses.add(approver.emailAddress);
            }
        });
    });

    return emailAddresses.size > 1;
}
