import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { SupplierSpecificStatisticsMessage } from '../../../types/StatisticsMessage';
import { useChartTitleTranslations } from '../hooks/useChartTitleTranslations';
import { barOptions, ChartType } from '../utils/barOptions';
import { chartColors } from '../utils/chartColors';

import { ChartGrid } from './ChartGrid';
import { ChartWrapper } from './ChartWrapper';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

interface CustomersTabStatisticsProps {
    customerStatistics: SupplierSpecificStatisticsMessage['customer'];
}

export const CustomersTabStatistics = ({ customerStatistics }: CustomersTabStatisticsProps) => {
    const chartTitles = useChartTitleTranslations();
    const customerNames = customerStatistics.map((customer) => customer.context.name);
    const backgroundColor = chartColors;

    const completedOrdersData = {
        labels: customerNames,
        datasets: [
            {
                label: ChartType.COMPLETED,
                data: customerStatistics.map((customer) => customer.completedCount),
                backgroundColor,
            },
        ],
    };

    const pplData = {
        labels: customerNames,
        datasets: [
            {
                label: ChartType.PPL,
                data: customerStatistics.map((customer) => customer.ppl.value),
                backgroundColor,
            },
        ],
    };

    const totalData = {
        labels: customerNames,
        datasets: [
            {
                label: ChartType.TOTAL,
                data: customerStatistics.map((customer) => customer.total.value),
                backgroundColor,
            },
        ],
    };

    const volumeData = {
        labels: customerNames,
        datasets: [
            {
                label: ChartType.VOLUME,
                data: customerStatistics.map((customer) => customer.volume),
                backgroundColor,
            },
        ],
    };

    return (
        <ChartGrid>
            <ChartWrapper title={chartTitles.completedOrders}>
                <Bar data={completedOrdersData} options={barOptions} />
            </ChartWrapper>

            <ChartWrapper title={chartTitles.pricePerUnit}>
                <Bar data={pplData} options={barOptions} />
            </ChartWrapper>

            <ChartWrapper title={chartTitles.total}>
                <Bar data={totalData} options={barOptions} />
            </ChartWrapper>

            <ChartWrapper title={chartTitles.volume}>
                <Bar data={volumeData} options={barOptions} />
            </ChartWrapper>
        </ChartGrid>
    );
};
