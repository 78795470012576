import { Button } from '../../../cdl/Button/Button';
import { translate } from '../../helpers/translate.helper';
import { useAngularDependencies } from '../../hooks/useAngularDependencies';
import { useNavigation } from '../../hooks/useNavigation';
import { useProductContext } from '../../hooks/useProductContext';
import { useRole } from '../../hooks/useRole';
import { IconPlus } from '../../icons/cdl/Plus';
import { Box } from '../../ui/Box';
import { SidebarNavigationItem } from '../components/SidebarNavigationItem';

export const FuelCustomerSidebarNavigation = () => {
    const role = useRole();
    const { context } = useProductContext();
    const { navigate } = useNavigation();
    const { $state } = useAngularDependencies();

    return (
        <Box display="grid" gap={5}>
            {role.hasAnyCompanyWriteRights() ? (
                <Box width="100%" paddingX={4}>
                    <Button
                        width="100%"
                        onClick={() =>
                            navigate('base.fuel-enquiry', {
                                origin: { stateName: $state.current.name },
                            })
                        }
                        emphasis="high"
                        leadingVisual={<IconPlus height={16} width={16} />}
                    >
                        {translate('navigation.createnew')}
                    </Button>
                </Box>
            ) : null}

            <Box display="flex" flexDirection="column">
                <SidebarNavigationItem to="base.fuel-dashboard">
                    {translate('navigation.dashboard')}
                </SidebarNavigationItem>

                <SidebarNavigationItem to="base.fuel-orderlist">{translate('navigation.orders')}</SidebarNavigationItem>

                <SidebarNavigationItem to="base.fuel-planning">
                    {translate('navigation.planning')}
                </SidebarNavigationItem>

                <SidebarNavigationItem to="base.fuel-vessels">{translate('navigation.vessels')}</SidebarNavigationItem>

                <SidebarNavigationItem to="base.fuel-key-ports">
                    {translate('navigation.keyPorts')}
                </SidebarNavigationItem>

                <SidebarNavigationItem to="base.fuel-contracts">
                    {translate('navigation.contracts')}
                </SidebarNavigationItem>

                {role.hasAnyAdminRights(context) ? (
                    <SidebarNavigationItem to="base.fuel-assignments">
                        {translate('navigation.suppliers')}
                    </SidebarNavigationItem>
                ) : null}

                {role.hasAnyAdminRights(context) ? (
                    <SidebarNavigationItem to="base.agents">{translate('navigation.agents')}</SidebarNavigationItem>
                ) : null}

                <SidebarNavigationItem to="base.fuel-reporting">
                    {translate('navigation.reporting')}
                </SidebarNavigationItem>

                <SidebarNavigationItem to={'base.fuel-global-prices'}>
                    {translate('navigation.globalPrices')}
                </SidebarNavigationItem>
            </Box>
        </Box>
    );
};
