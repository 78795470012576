import { useField } from 'formik';

import { FormikFormattedNumberInput } from '../../form-elements/formik/FormikFormattedNumberInput';
import { formatNumber } from '../../helpers/formatNumber.helper';
import { translate } from '../../helpers/translate.helper';

interface EnergyContentDisplayProps {
    editable: boolean;
    name: string;
}

export const EnergyContentDisplay = ({ editable, name }: EnergyContentDisplayProps) => {
    const [field] = useField(`${name}.value`);

    if (!editable) {
        if (field.value) {
            return formatNumber({
                number: field.value,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
        return '-';
    }

    return (
        <FormikFormattedNumberInput
            name={`${name}.value`}
            placeholder={translate('offer.quotationProcess.fuel.energyContentPlaceholder')}
            decimalsLimit={2}
            decimalScale={2}
        />
    );
};
