import { Form, Formik, FormikErrors } from 'formik';
import React from 'react';

import { Button } from '../../cdl/Button/Button';
import { FormikCheckbox } from '../../cdl/Checkbox/FormikCheckbox';
import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';

interface GroupAdminModalContentProps {
    onLogOut: () => void;
    onAccept: (newsletter: boolean) => void;
}

interface FormState {
    accepted: boolean;
    newsletter: boolean;
}

export const GroupAdminModalContent = (props: GroupAdminModalContentProps) => {
    const state: FormState = {
        accepted: false,
        newsletter: false,
    };

    const validate = (values: FormState): FormikErrors<FormState> => {
        const errors: FormikErrors<FormState> = {};

        if (!values.accepted) {
            errors.accepted = translate('termsAndConditions.groupAdmin.notAcceptedError');
        }

        return errors;
    };

    const onSubmit = (values: FormState) => {
        props.onAccept(values.newsletter);
    };

    return (
        <Box display="flex" flexDirection="column" rowGap={5} padding={6}>
            <Formik initialValues={state} onSubmit={onSubmit} validate={validate} validateOnMount>
                {(formikProps) => (
                    <Form>
                        <Text variant="title" as="h3">
                            {translate('termsAndConditions.groupAdmin.headline')}
                        </Text>
                        <Text
                            variant="small"
                            as="p"
                            dangerouslySetInnerHTML={{
                                __html: translate('termsAndConditions.groupAdmin.description'),
                            }}
                        />

                        <Box display="flex" flexDirection="column" rowGap={5}>
                            <FormikCheckbox
                                name="newsletter"
                                label={translate('termsAndConditions.groupAdmin.newsletterHeadline')}
                                description={translate('termsAndConditions.groupAdmin.newsletterDescription')}
                            />

                            <FormikCheckbox
                                name="accepted"
                                label={translate('termsAndConditions.groupAdmin.acceptHeadline')}
                                description={translate('termsAndConditions.groupAdmin.acceptDescription')}
                            />

                            <Text variant="extraSmall" as="p">
                                {translate('termsAndConditions.groupAdmin.legalCaption')}
                            </Text>
                        </Box>

                        <Box display="flex" columnGap={4} justifyContent="end">
                            <Button onClick={props.onLogOut} type="button">
                                {translate('termsAndConditions.logOutButton')}
                            </Button>
                            <Button emphasis="high" type="submit" disabled={!formikProps.isValid}>
                                {translate('termsAndConditions.groupAdmin.acceptButton')}
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
