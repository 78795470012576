import styled from 'styled-components';

import { Td, TdProps } from '../../../Table/Td';

const TopAlignedTd = styled(Td)`
    && {
        vertical-align: top;
    }
`;

const ContentWrapper = styled.div`
    display: grid;
    align-items: center;
    height: 40px;
`;

interface ProductTdProps extends TdProps {}

export const ProductTd = (props: ProductTdProps) => {
    return (
        <TopAlignedTd {...props}>
            <ContentWrapper>{props.children}</ContentWrapper>
        </TopAlignedTd>
    );
};
