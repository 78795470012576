import { getIn, useFormikContext } from 'formik';
import { useEffect } from 'react';

export const useFormikEffect = (fn, name) => {
    const { values } = useFormikContext();
    const fieldValue = getIn(values, name);

    useEffect(() => {
        if (fieldValue) {
            fn(fieldValue);
        }
    }, [fieldValue]); // eslint-disable-line react-hooks/exhaustive-deps
};
