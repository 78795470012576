import { ModalProps } from '../Modal';

export function getModalWidth(size: NonNullable<ModalProps['size']>): number {
    switch (size) {
        case 'small':
            return 420;
        case 'medium':
            return 560;
        case 'large':
            return 640;
    }
}
