import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

import { getAssignmentList } from '../../common/api/clients/gateway.api';
import { queryKeys } from '../../common/api/queryKeys';
import { useFormData } from '../../common/form-elements/Form/useFormData';
import { FormSelect } from '../../common/form-elements/Select/Select';
import { formatCompanyName } from '../../common/helpers/formatCompanyName.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';

export const DynamicDefaultSupplierSelect = () => {
    const { value: customerId } = useFormData('customerId');
    const oldCustomerIdRef = useRef(customerId);
    const { setValue: setDefaultSupplierId, value: defaultSupplierId } = useFormData('defaultSupplierId');
    const { data: supplierData } = useQuery({
        queryKey: queryKeys.assignments({ customerIds: [customerId] }),

        queryFn: () =>
            getAssignmentList({
                customerIds: [customerId],
            }),
    });
    const role = useRole();

    // reset the defaultSupplierId when the customerId changes
    useEffect(() => {
        if (customerId !== oldCustomerIdRef.current) {
            oldCustomerIdRef.current = customerId;
            setDefaultSupplierId(null);
        }
    }, [customerId, setDefaultSupplierId, defaultSupplierId, oldCustomerIdRef]);

    const supplierOptions = supplierData
        ? supplierData
              .filter((supplierData) => supplierData.supplier)
              .map(({ supplier }) => ({
                  label: formatCompanyName({
                      company: supplier,
                      includeType: role.isAdmin(),
                  }),
                  value: supplier.id,
              }))
        : [];

    return (
        <FormSelect
            isClearable
            key={`dynamicDefaultSupplierSelect.${customerId}.${defaultSupplierId}`}
            label={translate('vessel.defaultsupplier')}
            options={supplierOptions}
            dataPath="defaultSupplierId"
            isDisabled={!customerId}
        />
    );
};
