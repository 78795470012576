import { ProductGroupReport } from '../../../types/SpecificReportMessage';

export const formatFuelProductGroupReports = (reports: ProductGroupReport[]): ProductGroupReport[] => {
    return reports.map((report) => {
        const [sulphurContent, productGroup] = report.productGroup.split('-');
        const modifiedProductGroup = `${productGroup}-${sulphurContent}`;
        return {
            ...report,
            productGroup: modifiedProductGroup,
        };
    });
};
