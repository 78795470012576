import styled from 'styled-components';

import { get } from '../../common/ui/get';

export const Link = styled.a`
    color: ${get('colors.accent.foreground')};
    font-weight: 500;
    cursor: pointer;

    &:hover {
        // required, because *.less override hover
        // styles for all a tags
        color: ${get('colors.accent.foreground')};
    }
`;
