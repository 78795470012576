import { Button } from '../../../cdl/Button/Button';
import { translate } from '../../helpers/translate.helper';
import { useAngularDependencies } from '../../hooks/useAngularDependencies';
import { useNavigation } from '../../hooks/useNavigation';
import { useProductContext } from '../../hooks/useProductContext';
import { useRole } from '../../hooks/useRole';
import { IconPlus } from '../../icons/cdl/Plus';
import { Box } from '../../ui/Box';
import { SidebarNavigationItem } from '../components/SidebarNavigationItem';

import { ContextualLiteSidebarItem } from './ContextualLiteSidebarItem';

export const LubesCustomerSidebarNavigation = () => {
    const role = useRole();
    const { context } = useProductContext();
    const { navigate } = useNavigation();
    const { $state } = useAngularDependencies();

    return (
        <Box display="grid" gap={5}>
            {role.hasAnyCompanyWriteRights() ? (
                <Box width="100%" paddingX={4}>
                    <Button
                        width="100%"
                        onClick={() => {
                            void navigate('lubes-create-enquiry', {
                                origin: { stateName: $state.current.name, params: $state.params },
                            });
                        }}
                        emphasis="high"
                        leadingVisual={<IconPlus height={16} width={16} />}
                    >
                        {translate('navigation.createnew')}
                    </Button>
                </Box>
            ) : null}

            <Box display="flex" flexDirection="column">
                <SidebarNavigationItem to="base.dashboard">{translate('navigation.dashboard')}</SidebarNavigationItem>

                <SidebarNavigationItem to="base.orderlist">{translate('navigation.orders')}</SidebarNavigationItem>

                <SidebarNavigationItem to="base.lubes-demand-overview">
                    {translate('navigation.demandOverview')}
                </SidebarNavigationItem>

                <SidebarNavigationItem to="base.compare">{translate('navigation.compare')}</SidebarNavigationItem>

                <SidebarNavigationItem to="base.vessels">{translate('navigation.vessels')}</SidebarNavigationItem>

                <ContextualLiteSidebarItem to="base.key-ports">
                    {translate('navigation.keyPorts')}
                </ContextualLiteSidebarItem>

                {role.hasAnyAdminRights(context) ? (
                    <SidebarNavigationItem to="base.customer-assignments">
                        {translate('navigation.suppliers')}
                    </SidebarNavigationItem>
                ) : null}

                {role.hasAnyAdminRights(context) ? (
                    <ContextualLiteSidebarItem to="base.agents">
                        {translate('navigation.agents')}
                    </ContextualLiteSidebarItem>
                ) : null}

                <ContextualLiteSidebarItem to="base.reporting">
                    {translate('navigation.reporting')}
                </ContextualLiteSidebarItem>
            </Box>
        </Box>
    );
};
