import { CONTEXT_TYPE } from './user/model/ContextType';

RoutesProvider.$inject = ['$urlRouterProvider', '$stateProvider', '$locationProvider'];

function RoutesProvider($urlRouterProvider, $stateProvider, $locationProvider) {
    $locationProvider.html5Mode(true);
    $urlRouterProvider.otherwise('/dashboard');

    $stateProvider
        .state('login', {
            url: '/login',
            template: '<react-login-page></react-login-page>',
            params: {
                redirect: undefined,
                emailAddress: '',
            },
            noAuthRequired: true,
        })

        .state('onboard', {
            url: '/onboard/:userId/:token',
            template: '<react-onboard-page></react-onboard-page>',
            noAuthRequired: true,
        })

        .state('base', {
            abstract: true,
            resolve: {
                role: resolveRole(),
            },
            templateUrl: 'common/base.html',
        })
        .state('base.dashboard', {
            url: '/dashboard',
            template: '<react-lubes-dashboard/>',
            allowedContexts: [CONTEXT_TYPE.LUBES],
            params: {
                showWelcomePopup: false,
            },
        })
        .state('base.fuel-dashboard', {
            url: '/fuel/dashboard',
            template: '<react-fuel-dashboard/>',
            allowedContexts: [CONTEXT_TYPE.FUEL],
            params: {
                showWelcomePopup: false,
            },
        })
        .state('base.search-list', {
            url: '/search?query',
            template: '<react-search-result-page/>',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
            params: {
                query: undefined,
            },
        })
        .state('base.offerlist', {
            url: '/offers',
            template: '<react-lubes-offer-overview-page />',
            allowedContexts: [CONTEXT_TYPE.LUBES],
        })
        .state('base.offerlist-offer', {
            url: '/offer/:id',
            template: '<lubes-offer-detail-page/>',
            allowedContexts: [CONTEXT_TYPE.LUBES],
        })
        .state('base.offerlist-offer-edit', {
            url: '/offer/:id/edit',
            template: '<react-lubes-offer-edit-flow/>',
            allowedContexts: [CONTEXT_TYPE.LUBES],
        })
        .state('base.fuel-offerlist', {
            url: '/fuel/offers',
            template: '<react-fuel-offer-overview-page/>',
            allowedContexts: [CONTEXT_TYPE.FUEL],
        })
        .state('base.fuel-offerlist-offer', {
            url: '/fuel/offer/:id',
            template: '<fuel-offer-detail-page />',
            allowedContexts: [CONTEXT_TYPE.FUEL],
            params: {
                id: undefined,
            },
        })
        .state('base.fuel-orderlist', {
            url: '/fuel/orders',
            template: '<react-fuel-order-overview-page/>',
            allowedContexts: [CONTEXT_TYPE.FUEL],
        })
        .state('base.fuel-orderlist-order', {
            url: '/fuel/order/:id',
            template: '<react-fuel-order-detail-page/>',
            allowedContexts: [CONTEXT_TYPE.FUEL],
            params: {
                offerId: undefined,
                timestamp: undefined,
            },
        })
        .state('base.orderlist', {
            url: '/orders',
            template: '<react-lubes-order-overview-page />',
            allowedContexts: [CONTEXT_TYPE.LUBES],
            params: {
                tab: undefined,
            },
        })
        .state('lubes-create-enquiry-draft', {
            url: '/orders/draft/:id',
            template: '<react-lubes-create-enquiry-page />',
            allowedContexts: [CONTEXT_TYPE.LUBES],
            resolve: {
                role: resolveRole(),
            },
            params: {
                origin: undefined,
            },
        })
        .state('lubes-create-enquiry', {
            url: '/orders/new',
            template: '<react-lubes-create-enquiry-page />',
            allowedContexts: [CONTEXT_TYPE.LUBES],
            resolve: {
                role: resolveRole(),
            },
            params: {
                origin: undefined,
                vessel: undefined,
                order: undefined,
                items: undefined,
                portId: undefined,
            },
        })
        .state('base.orderlist-order-edit', {
            url: '/order/edit/:id',
            allowedContexts: [CONTEXT_TYPE.LUBES],
            template: '<react-order-edit-page />',
        })
        .state('base.orderlist-order', {
            url: '/order/:id',
            template: '<react-lubes-offer-compare-page />',
            allowedContexts: [CONTEXT_TYPE.LUBES],
        })
        .state('base.vessels', {
            url: '/vessels',
            template: '<react-vessel-overview />',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })
        .state('base.fuel-vessels', {
            url: '/fuel/vessels',
            template: '<react-vessel-overview />',
            allowedContexts: [CONTEXT_TYPE.FUEL],
        })
        .state('base.vessels-vessel', {
            url: '/vessel/:id',
            template: '<react-vessel-detail-page />',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })
        .state('base.fuel-vessels-vessel', {
            url: '/fuel/vessel/:id',
            template: '<react-vessel-detail-page />',
            allowedContexts: [CONTEXT_TYPE.FUEL],
        })

        .state('base.assignments', {
            url: '/assignments',
            template: '<react-assignments-page/>',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })

        .state('base.supplier-assignments', {
            url: '/customers',
            template: '<react-customers-overview-page />',
        })

        .state('base.customer-assignments', {
            url: '/suppliers',
            template: '<react-suppliers-overview-page />',
        })

        .state('base.fuel-assignments', {
            url: '/fuel/assignments',
            template: '<react-fuel-suppliers-overview />',
            allowedContexts: [CONTEXT_TYPE.FUEL],
        })
        .state('base.reporting', {
            url: '/statistics',
            template: '<react-statistics-page />',
            allowedContexts: [CONTEXT_TYPE.LUBES],
        })
        .state('base.fuel-reporting', {
            url: '/fuel/statistics',
            template: '<react-statistics-page />',
            allowedContexts: [CONTEXT_TYPE.FUEL],
        })
        .state('base.ports', {
            url: '/ports',
            template: '<react-ports-overview-page />',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })
        .state('base.products', {
            url: '/products',
            template: '<react-products-overview-page />',
            allowedContexts: [CONTEXT_TYPE.LUBES],
        })
        .state('base.integrations', {
            url: '/integrations',
            template: '<react-integrations-overview-page />',
            params: { offerId: undefined },
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })
        .state('base.ports-port', {
            url: '/port/detail/:id',
            template: '<react-port-detail-page />',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })
        .state('base.users', {
            url: '/users?page&search&customerIds&supplierIds&customerGroupIds&supplierGroupIds',
            template: '<react-user-overview-page/>',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })
        .state('base.users-user', {
            url: '/user/detail/:id',
            template: '<react-user-detail-page />',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })
        .state('base.supplier-groups', {
            url: '/supplier-groups',
            template: '<react-supplier-groups-overview-page/>',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })
        .state('base.supplier-groups-supplier-group', {
            url: '/supplier-group/:id',
            template: '<react-supplier-group-detail-page/>',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })
        .state('base.supplier-groups-supplier-group-supplier', {
            url: '/supplier/detail/:id',
            template: '<react-supplier-detail-page/>',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })
        .state('base.customer-groups', {
            url: '/customer-groups',
            template: '<react-customer-groups-overview-page/>',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })
        .state('base.customer-groups-customer-group', {
            url: '/customer-group/:id',
            template: '<react-customer-group-detail-page />',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })
        .state('base.customer-groups-customer-group-customer', {
            url: '/customer/detail/:id',
            template: '<react-customer-detail-page/>',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })

        .state('base.fuel-contracts', {
            url: '/fuel/contracts',
            template: '<react-contract-overview/>',
            allowedContexts: [CONTEXT_TYPE.FUEL],
        })

        .state('base.key-ports', {
            url: '/key-ports',
            template: '<react-key-port-overview/>',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })

        .state('base.fuel-key-ports', {
            url: '/fuel/key-ports',
            template: '<react-key-port-overview/>',
            allowedContexts: [CONTEXT_TYPE.FUEL],
        })

        .state('base.agents', {
            url: '/agents',
            template: '<react-agents-overview/>',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })

        .state('base.agents-agent', {
            url: '/agents/:id',
            template: '<react-agent-detail-page/>',
            allowedContexts: [CONTEXT_TYPE.LUBES, CONTEXT_TYPE.FUEL],
        })

        .state('base.compare', {
            url: '/compare',
            template: '<react-compare-overview />',
            allowedContexts: [CONTEXT_TYPE.LUBES],
        })
        .state('base.lubes-demand-overview', {
            url: '/demand',
            template: '<react-demand-overview />',
            allowedContexts: [CONTEXT_TYPE.LUBES],
        })
        .state('base.fuel-enquiry', {
            url: '/fuel/create?orderId',
            template: '<react-fuel-create-enquiry />',
            allowedContexts: [CONTEXT_TYPE.FUEL],
            params: { order: null, origin: null },
        })

        .state('base.fuel-quote', {
            url: '/fuel/offer/:id/quote',
            template: '<react-fuel-create-quote-flow />',
            allowedContexts: [CONTEXT_TYPE.FUEL],
        })
        .state('base.fuel-offer-edit', {
            url: '/fuel/offer/:id/edit',
            template: '<react-fuel-offer-edit-flow />',
            allowedContexts: [CONTEXT_TYPE.FUEL],
        })
        .state('base.fuel-order-edit', {
            url: '/fuel/order/:id/edit',
            template: '<react-fuel-order-edit-flow />',
            allowedContexts: [CONTEXT_TYPE.FUEL],
        })
        .state('base.fuel-planning', {
            url: '/fuel/planning/demand',
            template: '<react-planning/>',
            allowedContexts: [CONTEXT_TYPE.FUEL],
        })
        .state('base.fuel-planning-bunker-case', {
            url: '/fuel/planning/solutions/:vesselId/:caseNumber/:solutionNumber',
            template: '<react-pre-planning-page />',
            allowedContexts: [CONTEXT_TYPE.FUEL],
        })
        .state('base.fuel-planning-bunker-plan', {
            url: '/fuel/planning/bunker-plan',
            template: '<react-bunker-plan />',
            allowedContexts: [CONTEXT_TYPE.FUEL],
            params: {
                bunkerStopIds: undefined,
            },
        })
        .state('base.fuel-global-prices', {
            url: '/fuel/global-prices',
            template: '<react-global-prices-page/>',
            allowedContexts: [CONTEXT_TYPE.FUEL],
        })
        .state('base.offer-create-quote', {
            url: '/offer/:id/quote',
            template: '<react-lubes-create-quote-page/>',
        })
        .state('approval-request', {
            url: '/offer/:offerId/approval-request?token',
            template: '<react-offer-approval-request></react-offer-approval-request>',
            noAuthRequired: true,
        })
        .state('password-reset', {
            url: '/password-reset',
            template: '<react-password-reset></react-password-reset>',
            noAuthRequired: true,
            params: {
                emailAddress: '',
            },
        })
        .state('set-new-password', {
            url: '/set-new-password',
            template: '<react-set-new-password></react-set-new-password>',
            noAuthRequired: true,
        })
        .state('anonymous-quotation', {
            url: '/limited-quotation/:anonymousQuotationId?token',
            template: '<react-anonymous-quotation-page></react-anonymous-quotation-page>',
            noAuthRequired: true,
        });

    function resolveRole() {
        return [
            'RoleService',
            (RoleService) => {
                return RoleService.getPromise();
            },
        ];
    }
}

export default RoutesProvider;
