import { Form, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { Button } from '../../../../cdl/Button/Button';
import { FormikInput } from '../../../../cdl/Input/FormikInput';
import { FormikDebug } from '../../../../common/form-elements/formik/FormikDebug';
import { translate } from '../../../../common/helpers/translate.helper';
import { FormikPasswordInput } from '../../../../common/PasswordInput/FormikPasswordInput';
import { Box } from '../../../../common/ui/Box';

import { PlattsCredentialsServerError } from './PlattsCredentialsPopup';

export interface PlattsCredentialsFormState {
    customerId: string;
    username: string;
    password: string;
    appKey: string;
}

interface PlattsCredentialsFormProps {
    onCancel: () => void;
    credentialsExists: boolean;
    onRemove: () => void;
    error?: PlattsCredentialsServerError;
}

export const PlattsCredentialsForm = ({ onCancel, credentialsExists, onRemove, error }: PlattsCredentialsFormProps) => {
    const formikContext = useFormikContext<PlattsCredentialsFormState>();
    const [isRemoving, setIsRemoving] = useState<boolean>();

    useEffect(() => {
        formikContext.validateForm();
    }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form>
            <Box display="grid" gridRowGap={5}>
                <FormikInput label={translate('integrationSettings.platts.username')} name="username" />
                <FormikPasswordInput label={translate('integrationSettings.platts.password')} name="password" />
                <FormikInput label={translate('integrationSettings.platts.appKey')} name="appKey" />
            </Box>

            <Box display="flex" justifyContent="space-between" marginTop={6}>
                {credentialsExists ? (
                    <Button
                        type="button"
                        destructive={true}
                        emphasis="medium"
                        disabled={formikContext.isSubmitting}
                        isLoading={isRemoving}
                        onClick={async () => {
                            setIsRemoving(true);
                            await onRemove();
                        }}
                    >
                        {translate('integrationSettings.platts.removeBtn')}
                    </Button>
                ) : (
                    <Box></Box>
                )}
                <Box display="flex" justifyContent="end" gap={4}>
                    <Button
                        type="button"
                        emphasis="low"
                        disabled={formikContext.isSubmitting || isRemoving}
                        onClick={onCancel}
                    >
                        {translate('integrationSettings.platts.cancelBtn')}
                    </Button>
                    <Button
                        type="submit"
                        emphasis="high"
                        isLoading={formikContext.isSubmitting}
                        disabled={!formikContext.isValid || isRemoving}
                    >
                        {translate('integrationSettings.platts.submitBtn')}
                    </Button>
                </Box>
            </Box>

            <FormikDebug />
        </Form>
    );
};
