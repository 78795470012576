import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { updateSupplierDetails } from '../../../common/api/clients/company.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { translate } from '../../../common/helpers/translate.helper';

export const useSupplierDetailsEdit = () => {
    const queryClient = useQueryClient();
    const { addToast, addErrorToast } = useToasts();
    return useMutation({
        mutationFn: updateSupplierDetails,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: queryKeys.supplierDetails(data.id) });
            addToast(translate('company.toasts.informationUpdateSuccess'));
        },
        onError: () => {
            addErrorToast(translate('company.toasts.informationUpdateError'));
        },
    });
};
