import moment from 'moment';

import { AverageConsumption } from '../../../../../types/AverageConsumption';

interface AverageConsumptionGraphProps {
    consumptions: AverageConsumption[];
    daysArrayFromRange: Date[];
}

export const mapAverageConsumptionToRange = ({ consumptions, daysArrayFromRange }: AverageConsumptionGraphProps) => {
    return daysArrayFromRange.map((day) => {
        const consumptionsOfTheDay = consumptions.filter((consumption) =>
            moment(consumption.averageDate).isSame(day, 'day')
        );
        return consumptionsOfTheDay.length > 0 ? consumptionsOfTheDay.at(-1)! : undefined;
    });
};
