import PropTypes from 'prop-types';

import { useFormData } from '../Form/useFormData';

import { FuelMoneyInput } from './FuelMoneyInput';

export const FormFuelMoneyInput = ({ dataPath, transformValue = (v) => v, allowNegativeValue, ...props }) => {
    const { onChange, value } = useFormData(dataPath, { transformValue });

    const onChangeHandle = (value) => {
        onChange({
            target: { value },
        });
    };

    return (
        <FuelMoneyInput value={value} onChange={onChangeHandle} allowNegativeValue={allowNegativeValue} {...props} />
    );
};

FormFuelMoneyInput.propTypes = {
    label: PropTypes.node,
    transformValue: PropTypes.func,
    dataPath: PropTypes.string.isRequired,
    allowNegativeValue: PropTypes.bool,
};
