import { useQuery } from '@tanstack/react-query';

import { ProductContext } from '../../../types/ProductContext';
import { SupplierStatisticsMessage } from '../../../types/StatisticsMessage';
import { getCustomerReports, getPortReports, getProductReports } from '../../common/api/clients/gateway.api';
import { getGeneralReport } from '../../common/api/clients/report.api';
import { queries } from '../../common/api/queryKeys/queries';
import { useProductContext } from '../../common/hooks/useProductContext';
import { formatFuelProductGroupReports } from '../utils/formatFuelProductGroupReports';
import { getStatisticsProductGroupReport } from '../utils/getStatisticsProductGroupReport';
import { mapProductGroupsReports } from '../utils/mapProductGroupsReports';
import { sortReportsByName } from '../utils/sortReports';

interface FetchSupplierStatisticsParams {
    from: number;
    to: number;
    type: ProductContext;
    supplierIds?: string[];
}

export const useSupplierStatistics = (params: FetchSupplierStatisticsParams) => {
    const { isLubes } = useProductContext();

    const fetchSupplierStatistics = async (
        params: FetchSupplierStatisticsParams
    ): Promise<SupplierStatisticsMessage> => {
        const [general, customer, product, port, productGroup] = await Promise.all([
            getGeneralReport(params),
            getCustomerReports(params),
            getProductReports(params),
            getPortReports(params),
            getStatisticsProductGroupReport(params),
        ]);

        const mappedCustomerReports = Object.values(customer.reports);
        const mappedPortReports = Object.values(port.reports);
        const mappedProductReports = Object.values(product.reports);

        const mappedAndSortedProductGroupReports = isLubes
            ? mapProductGroupsReports(productGroup.reports).sort((a, b) => a.productGroup.localeCompare(b.productGroup))
            : formatFuelProductGroupReports(mapProductGroupsReports(productGroup.reports)).sort((a, b) =>
                  a.productGroup.localeCompare(b.productGroup)
              );

        return {
            period: general.period,
            general: general.item,
            customer: mappedCustomerReports.sort(sortReportsByName),
            port: mappedPortReports.sort(sortReportsByName),
            product: mappedProductReports.sort(sortReportsByName),
            productGroup: mappedAndSortedProductGroupReports,
        };
    };

    const fiveMinutesInMilliseconds = 5 * 60 * 1000;

    return useQuery({
        queryKey: queries.reports.all(params).queryKey,
        queryFn: () => fetchSupplierStatistics(params),
        staleTime: fiveMinutesInMilliseconds,
    });
};
