import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { DataTable } from '../../../common/DataTable/DataTable';
import { Error } from '../../../common/Error/Error';
import { formatCompanyName } from '../../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate as t } from '../../../common/helpers/translate.helper';
import { translateOrderOfferState } from '../../../common/helpers/translateOrderOfferState.helper';
import { useNavigation } from '../../../common/hooks/useNavigation';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { useUpdateEffect } from '../../../common/hooks/useUpdateEffect';
import { IconList } from '../../../common/icons/cdl/List';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { TabSwitch } from '../../../common/TabSwitch/TabSwitch';
import { TableValidity } from '../../../common/Validity/TableValidity';
import { Port } from '../../../port/Port';
import { useOrderPagination } from '../../useOrderPagination';
import { OrderActions } from '../actions/OrderActions';
import { orderTabOfferStateFilter, orderTabStateFilter } from '../orderTabsHelper';
import { useOrderPaginationTotals } from '../useOrderPaginationTotals';

import { selectSortOption } from './utils/selectSortOption';

export const FuelOrderOverviewTable = () => {
    const [locationState, { setLocationState, setLocationFieldValue }] = useLocationState();
    const role = useRole();
    const { context } = useProductContext();

    const { navigate } = useNavigation();

    const handleCustomerIds = (customerIds) => {
        if (customerIds.length) {
            return customerIds;
        }

        if (role.isAdmin()) {
            return null;
        }

        return role.getCompaniesWithType(context).map((it) => it.id);
    };

    const ordersTotalsQuery = useOrderPaginationTotals({
        searchQuery: locationState.searchQuery,
        customerIds: handleCustomerIds(locationState.customerIds),
        supplierIds: locationState.supplierIds,
        types: 'FUEL',
    });
    const ordersTotals = ordersTotalsQuery.data ?? {};

    const ordersPaginationQuery = useOrderPagination({
        page: locationState.page,
        searchQuery: locationState.searchQuery,
        customerIds: handleCustomerIds(locationState.customerIds),
        supplierIds: locationState.supplierIds,
        types: 'FUEL',
        offerStates: orderTabOfferStateFilter[locationState.tab],
        states: orderTabStateFilter[locationState.tab],
        sortField: locationState.sortValue?.sortField,
        sortDirection: locationState.sortValue?.sortDirection,
    });

    useUpdateEffect(() => {
        setLocationFieldValue('page', 0);
    }, [
        locationState.searchQuery,
        locationState.customerIds,
        locationState.supplierIds,
        locationState.tab,
        locationState.sortValue,
    ]);

    const onTabChange = (tab) => {
        setLocationState((state) => ({
            ...state,
            sortValue: selectSortOption(tab),
            tab,
        }));
    };

    if (ordersPaginationQuery.isPending) {
        return (
            <div>
                <TabSwitch selectedContext={locationState.tab} onSelect={onTabChange} totals={ordersTotals} />
                <LoadingIndicator />
            </div>
        );
    }

    if (ordersPaginationQuery.isError) {
        return (
            <div>
                <TabSwitch selectedContext={locationState.tab} onSelect={onTabChange} totals={ordersTotals} />

                <Error />
            </div>
        );
    }

    if (!ordersPaginationQuery.data.content.length) {
        return (
            <div>
                <TabSwitch selectedContext={locationState.tab} onSelect={onTabChange} totals={ordersTotals} />
                <TableEmptyState Icon={IconList} text={t('order.emptylist')} />
            </div>
        );
    }

    const columns = [
        {
            label: t('order.buyerreferenceshort'),
            width: '10%',
            value: (order) => order.buyerReference || '-',
        },
        {
            label: role.isCustomer() ? t('order.company') : t('order.customer'),
            width: '15%',
            condition: !role.isOneCompanyUser('FUEL'),
            value: (order) => formatCompanyName({ company: order.customer }) || '-',
        },
        {
            label: t('order.vessel'),
            width: '10%',
            value: (order) => formatVessel({ vessel: order.vessel, short: true }),
        },
        {
            label: t('order.port'),
            width: '15%',
            value: function renderPort(order) {
                if (!order.port) {
                    return '-';
                }

                return <Port port={order.port} vesselId={order.vesselId} locode={false} showTooltip />;
            },
        },
        {
            label: t('order.dateDeliveryShort'),
            width: '10%',
            condition: ['ordered', 'delivered', 'canceled'].includes(locationState.tab),
            value: function renderDeliveryDate(order) {
                if (!order.dateDelivery) {
                    return '-';
                }
                return formatDate({
                    date: order.dateDelivery,
                    timeZone: 'UTC',
                });
            },
        },
        {
            label: t('order.eta'),
            width: '10%',
            condition: ['drafted', 'enquired'].includes(locationState.tab),
            value: function renderETA(order) {
                if (!order.eta) {
                    return '-';
                }
                return formatDateTime({
                    date: order.eta,
                    timeZoneId: order.port?.timeZoneId,
                });
            },
        },
        {
            label: t('order.supplier'),
            width: '10%',
            condition: ['ordered', 'delivered', 'canceled'].includes(locationState.tab),
            value: (order) => formatCompanyName({ company: order.supplier }) || '-',
        },
        {
            label: t('order.status'),
            width: '10%',
            value: function renderOfferState(order) {
                const offerState = translateOrderOfferState(order, 'CUSTOMER');

                return offerState || '-';
            },
        },
        {
            label: t('order.offercount'),
            width: '5%',
            condition: ['enquired'].includes(locationState.tab),
            value: (order) => `${order.offerCount}/${order.receiverSupplierIds.length}` + (order.spot ? '+' : ''),
        },
        {
            label: t('order.enquiryValidityBy'),
            width: '10%',
            condition: ['enquired'].includes(locationState.tab),
            value: (order) => <TableValidity validUntil={order.validUntil} />,
        },
        {
            label: t('order.action'),
            width: '10%',
            condition:
                (role.hasAnyCompanyWriteRights() && role.isCustomer()) ||
                (role.isAdmin() && locationState.tab === 'drafted'),
            interactive: true,
            value: (order) => <OrderActions order={order} />,
        },
    ].filter(({ condition = true }) => condition);

    const onRowClick = (order) => {
        if (order.isClosed()) {
            navigate('base.fuel-offerlist-offer', {
                id: order.chosenOfferId,
            });
        } else if (order.isDraft() && !role.isAdmin() && role.hasWriteRights(order.customerId)) {
            navigate('base.fuel-enquiry', {
                orderId: order.id,
                origin: {
                    stateName: 'base.fuel-orderlist',
                },
            });
        } else {
            navigate('base.fuel-orderlist-order', {
                id: order.id,
            });
        }
    };

    return (
        <div>
            <TabSwitch selectedContext={locationState.tab} onSelect={onTabChange} totals={ordersTotals} />
            <DataTable
                columns={columns}
                rows={ordersPaginationQuery.data.content}
                onRowClick={({ row: order }) => {
                    onRowClick(order);
                }}
                isRowUnread={({ row: order }) =>
                    role.isCustomer() && role.hasWriteRights(order.customerId) && !order.read
                }
            />

            <CenteredPagination
                pageTotal={ordersPaginationQuery.pageTotal}
                currentPage={locationState.page}
                onPageChange={(page) => setLocationFieldValue('page', page)}
            />
        </div>
    );
};
