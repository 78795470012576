import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconLetterQOff = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M21.707 2.293a1 1 0 0 1 0 1.414l-3.116 3.116C18.86 7.51 19 8.248 19 9v6a6 6 0 0 1-1.11 3.476l.817.817a1 1 0 1 1-1.414 1.414l-.817-.817A6 6 0 0 1 13 21h-2a6 6 0 0 1-4.525-2.06l-2.768 2.767a1 1 0 0 1-1.414-1.414l18-18a1 1 0 0 1 1.414 0ZM7.894 17.52A4 4 0 0 0 11 19h2a4 4 0 0 0 2.031-.554l-2.738-2.739a1 1 0 0 1 1.414-1.414l2.739 2.738A4 4 0 0 0 17 15V9a4.01 4.01 0 0 0-.038-.548L7.894 17.52ZM6.757 4.757A6 6 0 0 1 11 3h2a6 6 0 0 1 3 .804 1 1 0 0 1-1 1.732A4 4 0 0 0 13 5h-2a4 4 0 0 0-4 4v5.5a1 1 0 1 1-2 0V9a6 6 0 0 1 1.757-4.243Z"
            clipRule="evenodd"
        />
    </svg>
);
