import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getCountryList } from '../clients/coreData.api';

export const countries = createQueryKeys('countries', {
    list: () => ({
        queryKey: ['list'],
        queryFn: () => getCountryList(),
    }),
});
