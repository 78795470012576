import { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';

import { ProductMessage } from '../../../../../../../../types/ProductMessage';
import { Modal } from '../../../../../../../cdl/Modal/Modal';
import { Text } from '../../../../../../../cdl/Text/Text';
import { DataSheet, DataSheetSkeleton } from '../../../../../../../common/DataSheet/DataSheet';
import { Error } from '../../../../../../../common/Error/Error';
import { translate } from '../../../../../../../common/helpers/translate.helper';
import { Box } from '../../../../../../../common/ui/Box';
import { useContractDetails } from '../../../../../../../contracts/ContractForm/useContractDetails';
import { useMakeContractPopupInformation } from '../hooks/useMakeContractPopupInformation';

export interface RecommendedContract {
    contractId: string;
    productCategory: string;
    sulphurContent: string;
}

const RecommendedContractPopupContent = (props: { recommendedContract: RecommendedContract }) => {
    const contractDetailsQuery = useContractDetails(props.recommendedContract.contractId);
    const makeContractPopupInformation = useMakeContractPopupInformation();

    if (contractDetailsQuery.isPending) {
        return (
            <Box padding={6} display="grid" rowGap={5}>
                <Skeleton height="22px" width="375px" />
                <Skeleton height="18px" width="375px" />
                <DataSheetSkeleton rows={9} />
            </Box>
        );
    }

    if (contractDetailsQuery.isError) {
        return (
            <Box padding={6} display="grid" rowGap={3}>
                <Error />
            </Box>
        );
    }

    const applicableContractProducts = contractDetailsQuery.data.productGroups.flatMap((contractProductGroup) => {
        return contractProductGroup.products.filter(
            (contractProduct) =>
                contractProduct.product.group === props.recommendedContract.productCategory &&
                contractProduct.product.sulphurContent === props.recommendedContract.sulphurContent
        );
    });

    const applicableContractProduct = applicableContractProducts.sort(
        (a, b) => a.priceDifference.value - b.priceDifference.value
    )[0];

    const applicableContractProductGroup = contractDetailsQuery.data.productGroups.find((productGroup) =>
        productGroup.products.includes(applicableContractProduct)
    )!;

    const contractData = makeContractPopupInformation({
        contract: contractDetailsQuery.data,
        contractProduct: applicableContractProduct,
        contractProductGroup: applicableContractProductGroup,
    });

    const createProductNameString = (product: ProductMessage) => {
        if (!product.sulphurContent) {
            return product.name;
        }

        return `${product.name} ${translate(`sulphurContent.${product.sulphurContent}`)}`;
    };

    return (
        <Box padding={6} display="grid" rowGap={3}>
            <Text variant="title">{contractDetailsQuery.data.supplier.name}</Text>
            <Text variant="subtitle1" color="foreground.muted">
                {contractDetailsQuery.data?.reference}&ensp;&middot;&ensp;
                {createProductNameString(applicableContractProduct.product)}
            </Text>
            <Box marginTop={5}>
                <DataSheet>
                    {contractData.map((row) => {
                        return (
                            <Fragment key={row.label}>
                                <Text style={{ whiteSpace: 'nowrap' }} color="foreground.subtle" variant="fieldLabel">
                                    {row.label}
                                </Text>
                                <Text variant="body">{row.value}</Text>
                            </Fragment>
                        );
                    })}
                </DataSheet>
            </Box>
        </Box>
    );
};

export const RecommendedContractPopup = (props: {
    onDismiss: () => void;
    isOpen: boolean;
    recommendedContract: RecommendedContract;
}) => {
    return (
        <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
            <RecommendedContractPopupContent recommendedContract={props.recommendedContract} />
        </Modal>
    );
};
