import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconPencil = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M13.793 4.793a3.828 3.828 0 1 1 5.414 5.414l-10.5 10.5A1 1 0 0 1 8 21H4a1 1 0 0 1-1-1v-4a1 1 0 0 1 .293-.707l9.493-9.494.007-.006.006-.007.994-.993ZM13.5 7.914l-8.5 8.5V19h2.586l8.5-8.5L13.5 7.914Zm4 1.172L14.914 6.5l.293-.293a1.828 1.828 0 0 1 2.586 2.586l-.293.293Z"
            clipRule="evenodd"
        />
    </svg>
);
