import { useContext } from 'react';

import { CompareSelectionStateContext } from '../context/CompareSelectionStateContext';

export const useCompareSelectionState = () => {
    const context = useContext(CompareSelectionStateContext);

    if (!context) {
        throw new Error('useCompareSelectionState must be used within a CompareSelectionStateContextProvider');
    }

    return context;
};
