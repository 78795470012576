import { ReactNode, useContext } from 'react';

import { OfferModel } from '../../../model/OfferModel';
import { OfferState } from '../../../model/OfferState';
import { StateSwitchContext } from '../components/StateSwitchContextProvider';

interface IStateSwitchContext {
    changeState: (params: { destinationState: OfferState; offer: OfferModel; component?: ReactNode }) => void;
}

export const useSwitchState = (): IStateSwitchContext => {
    return useContext(StateSwitchContext) as IStateSwitchContext;
};
