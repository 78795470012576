import PropTypes from 'prop-types';

import { TextButton } from '../../../common/buttons/TextButton.tsx';

export const ApprovalRequestTextButton = ({ children, onClick }) => {
    return (
        <TextButton fontWeight="semiBold" fontSize={1} onClick={onClick}>
            {children}
        </TextButton>
    );
};

ApprovalRequestTextButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
};
