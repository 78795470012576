import styled from 'styled-components';

import { BudgetMessage } from '../../../../types/BudgetMessage';
import { Text } from '../../../cdl/Text/Text';
import { formatMoney } from '../../../common/helpers/formatMoney.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';
import { Grid, GridItem } from '../../../common/ui/Grid';
import { theme } from '../../../common/ui/theme';

const CaptionColor = styled(Box)(
    {
        borderRadius: 1,
        width: '24px',
        height: '24px',
        marginRight: 5,
    },
    (props) => {
        if (props.color) {
            return {
                backgroundColor: props.color,
            };
        }
    }
);

interface BudgetCaptionProps {
    budgetData: BudgetMessage;
}

export const BudgetCaption = ({ budgetData }: BudgetCaptionProps) => {
    return (
        <Grid>
            <GridItem width={2 / 3} display="flex" flexDirection="row" alignItems="center">
                <CaptionColor color={theme.colors['clear-blue'][4]} />
                <Text size={14} weight="semi">
                    {translate('budgets.budgetAvailable')}
                </Text>
            </GridItem>

            <GridItem width={1 / 3} display="flex" alignItems="center">
                <Text
                    size={14}
                    weight="semi"
                    color={budgetData.remainingBudget.amount < 0 ? 'signal-red.1' : undefined}
                >
                    {formatMoney({
                        value: budgetData.remainingBudget.amount,
                        currency: budgetData.remainingBudget.currency,
                    })}
                </Text>
            </GridItem>

            <GridItem width={2 / 3} display="flex" flexDirection="row" alignItems="center">
                <CaptionColor color={theme.colors['clear-blue'][0]} />
                <Text size={14} weight="semi">
                    {translate('budgets.budgetSpent')}
                </Text>
            </GridItem>

            <GridItem width={1 / 3} display="flex" alignItems="center">
                <Text size={14} weight="semi">
                    {formatMoney({
                        value: budgetData.spentBudget.amount,
                        currency: budgetData.spentBudget.currency,
                    })}
                </Text>
            </GridItem>
        </Grid>
    );
};
