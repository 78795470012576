import { AxiosInstance, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import storage from 'local-storage-fallback';

import { ngDeps } from '../../../../ngDeps';

const AUTH_TOKEN_KEY = 'cl-session-token';

function injectAuthHeader(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
    const accessToken = storage.getItem(AUTH_TOKEN_KEY);

    if (!accessToken) {
        return config;
    }

    return {
        ...config,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            ...config.headers,
        } as AxiosRequestHeaders,
    };
}

const handleAuthorizationError = (error: any): Promise<unknown> => {
    const authErrorStatusCodes = [401, 403];

    if (!authErrorStatusCodes.includes(error.response.status)) {
        return Promise.reject(error);
    }

    const { SessionService, $state } = ngDeps;
    SessionService.logout();
    $state.go('login');

    return Promise.reject(error);
};

export function addAuthenticationInterceptors(axios: AxiosInstance) {
    axios.interceptors.request.use(injectAuthHeader);
    axios.interceptors.response.use(null, handleAuthorizationError);
}
