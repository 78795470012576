import { Dispatch, SetStateAction, useState } from 'react';

import { useUpdateEffect } from '../hooks/useUpdateEffect';

import { StateConfig } from './StateConfig';

/*
 * This hook is responsible for storing complex state in the URL.
 * It can be called like this:
 * useGroupedLocationState({
 *   searchQuery: '',
 *   ids: [],
 *   somethingMoreComplex: {
 *     defaultValue: {},
 *     serializer: value => value,
 *     deserializer: value => value
 *   }
 * })
 *
 * The key of the object is always the key of the query param.
 * Strings are not serialized or deserialized by default.
 * Lists are serialized and deserialized by default using commas.
 * Numbers are serialized and deserialized by default using parseInt.
 * You can add your own serializer and deserializer by providing a config like this:
 * {
 *   defaultValue: your default value,
 *   serializer: called with the deserialized value to convert it to a string that is stored in the URL
 *   deserializer: called with the serialized value from the URL to deserialize it
 * }
 */

export const useGroupedLocationState = (
    initialState: StateConfig
): [StateConfig, Dispatch<SetStateAction<StateConfig>>] => {
    const [state, setState] = useState<StateConfig>(readFromUrl(initialState));

    useUpdateEffect(() => {
        setUrl(state, initialState);
    }, [state]);

    return [state, setState];
};

const isObject = (value: unknown): boolean => {
    return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

const setUrl = (currentState: StateConfig, initialState: StateConfig) => {
    const serializeValue = (value: unknown, key: string) => {
        const initialStateForKey = initialState[key];

        if (isObject(initialStateForKey)) {
            if (initialStateForKey.serializer) {
                return initialStateForKey.serializer(value);
            }
        }

        if (Array.isArray(value)) {
            return value && value.length ? value.join(',') : null;
        }

        if (typeof value === 'number') {
            return value ? value + 1 : null;
        }

        return value;
    };

    const url = new URL(window.location.href);

    Object.keys(currentState).forEach((key) => {
        const value = currentState[key];
        const serializedValue = serializeValue(value, key);
        if (serializedValue) {
            url.searchParams.set(key, serializedValue);
        } else {
            url.searchParams.delete(key);
        }
    });

    window.history.replaceState(null, '', url.toString());
};

const readFromUrl = (initialState: StateConfig): StateConfig => {
    const deserializeValue = (value: string, key: string): any => {
        const initialStateForKey = initialState[key];

        if (isObject(initialStateForKey)) {
            if (initialStateForKey.deserializer) {
                return initialStateForKey.deserializer(value);
            }
        }

        if (Array.isArray(initialStateForKey)) {
            return value ? value.split(',') : [];
        }

        if (typeof initialStateForKey === 'number') {
            return value ? parseInt(value) - 1 : 0;
        }

        return value;
    };

    const params = new URLSearchParams(window.location.search);

    if (!Array.from(params).length) {
        const newState: StateConfig = {};

        Object.keys(initialState).forEach((key) => {
            const value = initialState[key];

            if (isObject(value)) {
                newState[key] = JSON.parse(JSON.stringify(value.defaultValue));
            } else {
                newState[key] = value;
            }
        });
        return newState;
    }

    const newState: StateConfig = {};

    Object.keys(initialState).forEach((key) => {
        const value = params.get(key);
        const initialStateForKey = initialState[key];

        if (!value) {
            if (isObject(initialStateForKey)) {
                newState[key] = initialStateForKey.defaultValue;
            } else {
                newState[key] = initialStateForKey;
            }
        } else {
            newState[key] = deserializeValue(value, key);
        }
    });

    return newState;
};
