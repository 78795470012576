import { Formik, FormikErrors } from 'formik';
import React from 'react';

import { Modal } from '../../cdl/Modal/Modal';
import { Text } from '../../cdl/Text/Text';
import { useToasts } from '../../cdl/Toast/useToasts';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';
import { useBunkerStopShareMutation } from '../hooks/useBunkerStopShareMutation';

import { ShareSupplierBunkerStopForm } from './ShareSupplierBunkerStopForm';

interface ShareSupplierBunkerPlanPopupProps {
    isOpen: boolean;
    onDismiss: () => void;
    bunkerStopId: string;
}

export interface ShareSupplierBunkerPlanFormState {
    receiverEmailAddresses: string[];
    copyToSelf: boolean;
}

export const ShareSupplierBunkerStopPopup = ({
    isOpen,
    onDismiss,
    bunkerStopId,
}: ShareSupplierBunkerPlanPopupProps) => {
    const { addToast, addErrorToast } = useToasts();
    const shareMutation = useBunkerStopShareMutation();
    const onSubmit = async (values: ShareSupplierBunkerPlanFormState) => {
        await shareMutation.mutateAsync(
            {
                bunkerStopId: bunkerStopId,
                receiverEmailAddresses: values.receiverEmailAddresses,
                copyToSelf: values.copyToSelf,
                receiverType: 'SUPPLIER',
            },
            {
                onSuccess: () => {
                    addToast(translate('bunkerPlan.table.sharingSupplierSuccessToast'));
                },
                onError: () => {
                    addErrorToast(translate('global.genericToastError'));
                },
            }
        );
        onDismiss();
    };

    const validate = (values: ShareSupplierBunkerPlanFormState): FormikErrors<ShareSupplierBunkerPlanFormState> => {
        const errors: FormikErrors<ShareSupplierBunkerPlanFormState> = {};

        if (values.receiverEmailAddresses.length <= 0) {
            errors.receiverEmailAddresses = 'Required';
        }

        return errors;
    };

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} dismissible={false}>
            <Box padding={6} display="grid" rowGap={5}>
                <Text variant="title">{translate('bunkerPlan.table.supplierPopup.headline')}</Text>

                <Text variant="small" color="foreground.muted">
                    {translate('bunkerPlan.table.vesselPopup.text')}
                </Text>

                <Formik
                    initialValues={{
                        copyToSelf: false,
                        receiverEmailAddresses: [],
                    }}
                    onSubmit={onSubmit}
                    validate={validate}
                    validateOnMount
                >
                    <ShareSupplierBunkerStopForm onCancel={onDismiss} />
                </Formik>
            </Box>
        </Modal>
    );
};
