import { Text } from '../../../../../../cdl/Text/Text';
import { translate } from '../../../../../../common/helpers/translate.helper';
import { Box } from '../../../../../../common/ui/Box';
import { OfferModel } from '../../../../../../offer/model/OfferModel';
import { useOfferCardContext } from '../hooks/useOfferCardContext';

const getAssignedLabel = (offer: OfferModel): string | null => {
    switch (offer.enquiryType) {
        case 'ASSIGNED':
        case 'FORWARDED':
            return translate('labelList.contracted');
        default:
            return null;
    }
};

const getForwardedLabel = (offer: OfferModel): string | null => {
    switch (offer.enquiryType) {
        case 'FORWARDED':
            return translate('labelList.forwarded');
        default: {
            return null;
        }
    }
};

const getTypeLabel = (offer: OfferModel): string | null => {
    if (offer.isForwarded()) {
        return null;
    }

    switch (offer.supplier.supplierGroup.type) {
        case 'MANUFACTURER': {
            return translate('labelList.manufacturer');
        }
        case 'DISTRIBUTOR': {
            return translate('labelList.distributor');
        }
        case 'TRADER': {
            return translate('labelList.trader');
        }
        default:
            return null;
    }
};

export const OfferLabelList = () => {
    const { offer } = useOfferCardContext();
    const isDefaultSupplier = offer.supplierId === offer.vessel?.defaultSupplierId;

    const labels = [getTypeLabel(offer), getAssignedLabel(offer), getForwardedLabel(offer)]
        .filter((label) => label !== null)
        .map((label) => (
            <Text key={label} variant="extraSmall" color="foreground.muted">
                {label}
            </Text>
        ));

    return (
        <Box display="flex" columnGap={4}>
            {isDefaultSupplier ? (
                <Text variant="extraSmall" fontWeight="semiBold" color="foreground.default">
                    {translate('labelList.default')}
                </Text>
            ) : null}
            {labels}
        </Box>
    );
};
