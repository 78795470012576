import { CounterOfferState } from '../../../../../types/CounterOfferState';
import { CircleValidity } from '../../../../common/Validity/CircleValidity';
import { OfferModel } from '../../../../offer/model/OfferModel';

interface OfferCardValidityProps {
    offer: OfferModel;
}

export const OfferCardValidity = ({ offer }: OfferCardValidityProps) => {
    if (
        offer.counterOffers.length &&
        offer.counterOffers[0].state === CounterOfferState.OPEN &&
        offer.counterOffers[0].validityTimeMinutes &&
        offer.counterOffers[0].validUntil
    ) {
        return (
            <CircleValidity
                validUntil={offer.counterOffers[0].validUntil}
                validityTime={offer.counterOffers[0].validityTimeMinutes}
                size="small"
            />
        );
    }

    if (offer.quoteValidUntil && offer.quoteValidityTime) {
        return (
            <CircleValidity validUntil={offer.quoteValidUntil} validityTime={offer.quoteValidityTime} size="small" />
        );
    }

    return null;
};
