import PropTypes from 'prop-types';

import { translate } from '../../../common/helpers/translate.helper';
import { DeprecatedSortSelect } from '../../../common/SortSelect/DeprecatedSortSelect';

interface Props {
    onChange: (value: string) => void;
    value: {
        sortDirection: string;
        sortField: string;
    };
}

export const LubesOfferOverviewSortSelect = ({ onChange, value }: Props) => {
    const orderSortOptions = [
        {
            label: translate('sortOptions.latest'),
            value: {
                sortField: 'dateUpdated',
                sortDirection: 'DESC',
            },
        },
        {
            label: translate('sortOptions.deliveryDate'),
            value: {
                sortField: 'dateDelivery',
                sortDirection: 'DESC',
            },
        },
        {
            label: translate('sortOptions.dateCreated'),
            value: {
                sortField: 'dateCreated',
                sortDirection: 'DESC',
            },
        },
    ];

    const selectedOption = orderSortOptions.find(
        (option) => option.value.sortField === value.sortField && option.value.sortDirection === value.sortDirection
    );

    return (
        <DeprecatedSortSelect
            value={selectedOption}
            options={orderSortOptions}
            onChange={(option) => {
                onChange(option.value);
            }}
        />
    );
};

LubesOfferOverviewSortSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
};
