import { useMutation } from '@tanstack/react-query';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { updateOrder } from '../../../common/api/clients/order.api';
import { translate } from '../../../common/helpers/translate.helper';
import { useNavigation } from '../../../common/hooks/useNavigation';
import { OrderModel } from '../../model/OrderModel';

import { useSelectedOfferForFuelOrder } from './useContractOrder';

export const useUpdateContractOrder = () => {
    const { addErrorToast } = useToasts();
    const { navigate } = useNavigation();

    const fetchSelectedOfferId = useSelectedOfferForFuelOrder();

    const updateOrderMutation = useMutation({ mutationFn: updateOrder });

    return (order: OrderModel, orderId: string) => {
        return updateOrderMutation.mutateAsync(
            {
                order,
                orderId,
            },
            {
                onSuccess: async (order: any) => {
                    try {
                        const selectedOfferId = await fetchSelectedOfferId(order.id);

                        navigate('base.fuel-offerlist-offer', {
                            id: selectedOfferId,
                        });
                    } catch {
                        navigate('base.fuel-orderlist-order', {
                            id: order.id,
                        });
                    }
                },
                onError: () => {
                    addErrorToast(translate('fuelOrder.toast.updateOrder.error'));
                },
            }
        );
    };
};
