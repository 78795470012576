import { useState } from 'react';

import { CounterOfferState } from '../../../../../types/CounterOfferState';
import { Button } from '../../../../cdl/Button/Button';
import { translate } from '../../../../common/helpers/translate.helper';
import { useCountdown } from '../../../../common/Validity/hooks/useCountdown';
import { OfferModel } from '../../../../offer/model/OfferModel';
import { OrderModel } from '../../../model/OrderModel';
import { CreateOrderPopup } from '../CreateOrderPopup';
import { useOfferOrder } from '../useOfferOrder';

interface OrderButtonProps {
    offer: OfferModel;
    order: OrderModel;
}

export const OrderButton = ({ offer, order }: OrderButtonProps) => {
    const { orderOffer, isPending } = useOfferOrder(order);
    const [isPopupOpen, setPopupOpen] = useState(false);

    const remainingTime = useCountdown(offer.quoteValidUntil);

    const isExpiredOffer = remainingTime <= 0;
    const isAcceptedCounterOffer = offer.counterOffers[0]?.state === CounterOfferState.ACCEPTED;

    const onConfirm = () => {
        void orderOffer(offer);
    };

    if (isExpiredOffer && !isAcceptedCounterOffer) {
        return (
            <Button emphasis="high" width="100%" disabled>
                {translate('fuelOrder.offerList.placeOrder')}
            </Button>
        );
    }

    return (
        <>
            <Button emphasis="high" width="100%" onClick={() => setPopupOpen(true)}>
                {translate('fuelOrder.offerList.placeOrder')}
            </Button>

            <CreateOrderPopup
                onDismiss={() => setPopupOpen(false)}
                onConfirm={onConfirm}
                offer={offer}
                isOpen={isPopupOpen}
                isSubmitting={isPending}
            />
        </>
    );
};
