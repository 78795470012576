import { translate, translateOrNull } from './translate.helper';

const generateState = (offer) => {
    if (offer.isSpot()) {
        if (
            offer.state === 'QUOTED' ||
            offer.state === 'ENQUIRY' ||
            offer.state === 'ENQUIRY_CANCELED' ||
            offer.state === 'QUOTE_CANCELED'
        )
            return offer.state + '_SPOT';
    }

    return offer.state;
};

const translateOrFallback = (key, fallbackKey) => {
    const translation = translateOrNull(key);

    if (translation) {
        return translation;
    }

    return translate(fallbackKey);
};

export const translateOfferState = ({ offer, userType }) => {
    if (!offer) {
        return undefined;
    }

    const state = generateState(offer);

    if (!userType) {
        return translate(`state.${offer.type}.${state}.title`);
    }

    return translateOrFallback(`state.${offer.type}.${userType}.${state}.title`, `state.${offer.type}.${state}.title`);
};
