import { ProductContext } from '../../../../types/ProductContext';
import { SupplierCompanySelect } from '../../../common/form-elements/CompanySelect/SupplierCompanySelect';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { Box } from '../../../common/ui/Box';

export const CustomerFilterActions = () => {
    const role = useRole();
    const [locationState, { setLocationFieldValue }] = useLocationState();

    if (role.isOneCompanyUser(ProductContext.LUBES)) {
        return null;
    }

    return (
        <Box minWidth="350px">
            <SupplierCompanySelect
                value={locationState.supplierIds}
                placeholder={translate('assignments.customers.companySelectPlaceholder')}
                onChange={(supplierIds) => {
                    setLocationFieldValue('supplierIds', supplierIds);
                }}
                isMulti
            />
        </Box>
    );
};
