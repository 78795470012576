import { translate } from '../../../../../../common/helpers/translate.helper';
import { useEnums } from '../../../../../../common/hooks/useEnums';
import { OfferModel } from '../../../../../../offer/model/OfferModel';

export const useLubesOfferPaymentTerms = (offer: OfferModel) => {
    const { getHumanReadableValue } = useEnums();

    if (offer.isSpot()) {
        const paymentTermReference = getHumanReadableValue('paymentTermReference', offer.paymentTermReference);

        if (offer.paymentTermReferenceDays) {
            return `${offer.paymentTermReferenceDays} ${paymentTermReference}`;
        }

        return paymentTermReference;
    }

    return translate('order.detail.contractual');
};
