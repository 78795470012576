import { ProductContext } from '../../../../types/ProductContext';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { DataTable } from '../../../common/DataTable/DataTable';
import { Error } from '../../../common/Error/Error.tsx';
import { formatCompanyName } from '../../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate as t } from '../../../common/helpers/translate.helper';
import { translateOfferState } from '../../../common/helpers/translateOfferState.helper';
import { useNavigation } from '../../../common/hooks/useNavigation';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { useUpdateEffect } from '../../../common/hooks/useUpdateEffect';
import { IconListDetails } from '../../../common/icons/cdl/ListDetails';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { TabSwitch } from '../../../common/TabSwitch/TabSwitch';
import { CircleValidity } from '../../../common/Validity/CircleValidity';
import { TableValidity } from '../../../common/Validity/TableValidity';
import { Port } from '../../../port/Port';
import { useOfferPagination } from '../../useOfferPagination';
import { OfferActions } from '../actions/OfferActions';

import { fuelTabToOfferStateMap } from './fuelTabToOfferStateMap';
import { useFuelOfferPaginationTotals } from './useFuelOfferPaginationTotals';
import { selectSortOption } from './utils/selectSortOption';

export const FuelOfferOverviewTable = () => {
    const { context } = useProductContext();
    const role = useRole();

    const handleSupplierIds = (supplierIds) => {
        if (supplierIds.length) {
            return supplierIds;
        }

        if (role.isAdmin()) {
            return null;
        }

        return role.getCompaniesWithType(context).map((it) => it.id);
    };

    const [locationState, { setLocationState, setLocationFieldValue }] = useLocationState();

    const { navigate } = useNavigation();

    const { data: offersTotals = {} } = useFuelOfferPaginationTotals({
        searchQuery: locationState.searchQuery,
        supplierIds: handleSupplierIds(locationState.supplierIds),
        customerIds: locationState.customerIds,
    });

    const { data, isPending, isError, pageTotal } = useOfferPagination({
        page: locationState.page,
        searchQuery: locationState.searchQuery,
        supplierIds: handleSupplierIds(locationState.supplierIds),
        customerIds: locationState.customerIds,
        types: [ProductContext.FUEL],
        states: fuelTabToOfferStateMap[locationState.tab],
        sortField: locationState.sortValue?.sortField,
        sortDirection: locationState.sortValue?.sortDirection,
    });

    useUpdateEffect(() => {
        setLocationFieldValue('page', 0);
    }, [
        locationState.searchQuery,
        locationState.supplierIds,
        locationState.customerIds,
        locationState.tab,
        locationState.sortValue,
    ]);

    const onTabSelect = (tab) => {
        setLocationState((state) => ({
            ...state,
            sortValue: selectSortOption(tab),
            tab,
        }));
    };

    if (isPending) {
        return (
            <div>
                <TabSwitch selectedContext={locationState.tab} onSelect={onTabSelect} totals={offersTotals} />
                <LoadingIndicator />
            </div>
        );
    }

    if (isError) {
        return (
            <div>
                <TabSwitch selectedContext={locationState.tab} onSelect={onTabSelect} totals={offersTotals} />

                <Error />
            </div>
        );
    }

    if (!data.content.length) {
        return (
            <div>
                <TabSwitch selectedContext={locationState.tab} onSelect={onTabSelect} totals={offersTotals} />
                <TableEmptyState Icon={IconListDetails} text={t('order.emptylist')} />
            </div>
        );
    }

    const columns = [
        {
            label: t('order.vendorreferenceshort'),
            width: '10%',
            value: (offer) => offer.vendorReference || '-',
            condition: !['enquired'].includes(locationState.tab),
        },
        {
            label: role.isSupplier() ? t('offer.company') : t('offer.supplier'),
            width: '10%',
            condition: !role.isOneCompanyUser('FUEL'),
            value: (offer) => formatCompanyName({ company: offer.supplier }),
        },
        {
            label: t('offer.customer'),
            width: '15%',
            value: (offer) => formatCompanyName({ company: offer.customer }),
        },
        {
            label: t('order.vessel'),
            width: '10%',
            value: (offer) => formatVessel({ vessel: offer.vessel, short: true }),
        },
        {
            label: t('order.port'),
            width: '15%',
            value: function renderPort(offer) {
                if (!offer.port) {
                    return '-';
                }

                return <Port port={offer.port} vesselId={offer.vesselId} locode={false} showTooltip />;
            },
        },
        {
            label: t('order.dateDeliveryShort'),
            width: '10%',
            condition: [
                'offered',
                'ordered',
                'acknowledged',
                'confirmed',
                'delivered',
                'invoiced',
                'canceled',
                'expired',
            ].includes(locationState.tab),
            value: function renderDeliveryDate(offer) {
                if (!offer.dateDelivery) {
                    return '-';
                }
                return formatDate({
                    date: offer.dateDelivery,
                    timeZone: 'UTC',
                });
            },
        },
        {
            label: t('order.eta'),
            width: '10%',
            condition: ['enquired'].includes(locationState.tab),
            value: function renderETA(offer) {
                if (!offer.eta) {
                    return '-';
                }

                return formatDateTime({
                    date: offer.eta,
                    timeZoneId: offer.port.timeZoneId,
                });
            },
        },
        {
            label: t('order.status'),
            width: '10%',
            value: function renderOfferState(offer) {
                const offerState = translateOfferState({
                    offer,
                    userType: role.user.userType,
                });

                return offerState || '-';
            },
        },
        {
            label: t('order.enquiryValidity'),
            width: '20%',
            condition: ['enquired'].includes(locationState.tab),
            value: (offer) => {
                if (!offer.validUntil) {
                    return null;
                }

                return (
                    <CircleValidity
                        validityTime={offer.validityTime}
                        validUntil={offer.validUntil}
                        background="transparent"
                    />
                );
            },
        },
        {
            label: t('order.quoteValidityBy'),
            width: '10%',
            condition: ['offered'].includes(locationState.tab),
            value: (offer) => <TableValidity validUntil={offer.quoteValidUntil} />,
        },
        {
            label: t('order.action'),
            width: '10%',
            condition: role.hasWriteRights() && role.isSupplier(),
            interactive: true,
            value: (offer) => <OfferActions offer={offer} />,
        },
    ].filter(({ condition = true }) => condition);

    return (
        <div>
            <TabSwitch selectedContext={locationState.tab} onSelect={onTabSelect} totals={offersTotals} />
            <DataTable
                columns={columns}
                rows={data.content}
                onRowClick={({ row: offer }) => {
                    navigate('base.fuel-offerlist-offer', {
                        id: offer.id,
                    });
                }}
                isRowUnread={({ row: offer }) => role.isSupplier() && !offer.supplierRead}
            />

            <CenteredPagination
                pageTotal={pageTotal}
                currentPage={locationState.page}
                onPageChange={(page) => setLocationFieldValue('page', page)}
            />
        </div>
    );
};
