import { PortMessage } from '../../../types/PortMessage';
import { Text } from '../../cdl/Text/Text';
import { DataSheet } from '../DataSheet/DataSheet';
import { formatAddress } from '../helpers/formatAddress.helper';
import { formatPort } from '../helpers/formatPort.helper';
import { translate } from '../helpers/translate.helper';
import { Box } from '../ui/Box';

import { CoreDataPopup } from './CoreDataPopup';

interface AgentInfoPopupProps {
    agent: any;
}

export const AgentInfoPopup = ({ agent }: AgentInfoPopupProps) => {
    return (
        <CoreDataPopup heading={translate('agentInfoPopup.heading')}>
            <Box display="grid" gap={4}>
                <Text variant="subtitle2">{translate('agentInfoPopup.generalData')}</Text>
                <DataSheet>
                    <DataSheet.Label>{translate('agentInfoPopup.agentName')}</DataSheet.Label>
                    <Text>{agent.name}</Text>
                    {agent.ports ? (
                        <>
                            <DataSheet.Label>{translate('agentInfoPopup.port')}</DataSheet.Label>
                            <Text>
                                {agent.ports
                                    .map((port: PortMessage) =>
                                        formatPort({
                                            port,
                                        })
                                    )
                                    .join(', ')}
                            </Text>
                        </>
                    ) : null}
                    {agent.address ? (
                        <>
                            <DataSheet.Label>{translate('agentInfoPopup.address')}</DataSheet.Label>
                            <Text>
                                {formatAddress({
                                    address: {
                                        street: agent.address.street,
                                        streetNumber: agent.address.streetNumber,
                                        zipcode: agent.address.zipCode,
                                        city: agent.address.city,
                                        country: agent.address.country,
                                    },
                                })}
                            </Text>
                        </>
                    ) : null}
                    {agent.note ? (
                        <>
                            <DataSheet.Label>{translate('agentInfoPopup.note')}</DataSheet.Label>
                            <span>
                                {agent.note.split('\n').map((line: string, key: string) => {
                                    return line ? <Text key={key}>{line}</Text> : <br />;
                                })}
                            </span>
                        </>
                    ) : null}
                </DataSheet>
            </Box>

            <Box display="grid" gap={4}>
                <Text variant="subtitle2">{translate('agentInfoPopup.mainContactData')}</Text>
                <DataSheet>
                    {agent.contact?.name ? (
                        <>
                            <DataSheet.Label>{translate('agentInfoPopup.contact.name')}</DataSheet.Label>
                            <Text>{agent.contact.name}</Text>
                        </>
                    ) : null}
                    {agent.contact?.emailAddress ? (
                        <>
                            <DataSheet.Label>{translate('agentInfoPopup.contact.emailAddress')}</DataSheet.Label>
                            <Text>{agent.contact.emailAddress}</Text>
                        </>
                    ) : null}
                    {agent.contact?.phoneNumber ? (
                        <>
                            <DataSheet.Label>{translate('agentInfoPopup.contact.phoneNumber')}</DataSheet.Label>
                            <Text>{agent.contact.phoneNumber}</Text>
                        </>
                    ) : null}
                    {agent.contact?.faxNumber ? (
                        <>
                            <DataSheet.Label>{translate('agentInfoPopup.contact.faxNumber')}</DataSheet.Label>
                            <Text>{agent.contact.faxNumber}</Text>
                        </>
                    ) : null}
                </DataSheet>
            </Box>
        </CoreDataPopup>
    );
};
