import { Formik, FormikErrors } from 'formik';
import React from 'react';

import { ContractMessage } from '../../../../types/ContractMessage';
import { Modal } from '../../../cdl/Modal/Modal';
import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';

import { SendContractForm, SendContractFormState } from './SendContractForm';
import { useContractSend } from './useContractSend';

interface SendContractPopupProps {
    contract: ContractMessage;
    isOpen: boolean;
    onDismiss: () => void;
}

export const SendContractPopup = ({ isOpen, onDismiss, contract }: SendContractPopupProps) => {
    const sendMutation = useContractSend();
    const onSubmit = async (values: SendContractFormState) => {
        await sendMutation.mutateAsync({
            contractId: contract.id,
            request: values,
        });
        onDismiss();
    };

    const validate = (values: SendContractFormState): FormikErrors<SendContractFormState> => {
        const errors: FormikErrors<SendContractFormState> = {};

        if (values.receiverEmailAddresses.length <= 0) {
            errors.receiverEmailAddresses = 'Required';
        }

        return errors;
    };

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} dismissible={false}>
            <Box padding={6} display="grid" rowGap={5}>
                <Text variant="title">{translate('contracts.sendContract.popupHeader')}</Text>

                <Text variant="small" color="foreground.muted">
                    {translate('contracts.sendContract.popupDescription')}
                </Text>

                <Formik
                    initialValues={{
                        copyToSelf: false,
                        receiverEmailAddresses: [],
                    }}
                    onSubmit={onSubmit}
                    validate={validate}
                    validateOnMount
                >
                    <SendContractForm onCancel={onDismiss} previouslySentTo={contract.tender.receiverEmailAddresses} />
                </Formik>
            </Box>
        </Modal>
    );
};
