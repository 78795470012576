import { ReactNode } from 'react';
import styled from 'styled-components';

import { BaseStatisticsMessage } from '../../../types/StatisticsMessage';
import { Text } from '../../cdl/Text/Text';
import { formatMoney } from '../../common/helpers/formatMoney.helper';
import { formatNumber } from '../../common/helpers/formatNumber.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useProductContext } from '../../common/hooks/useProductContext';
import { Box } from '../../common/ui/Box';
import { get } from '../../common/ui/get';

const StatsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: ${get('breakpoints.2')}) {
        grid-template-columns: 1fr;
        row-gap: ${get('space.6')}px;
    }
`;

interface StatsItemProps {
    label: string;
    children: ReactNode;
}

const StatsItem = ({ label, children }: StatsItemProps) => {
    return (
        <Box display="flex" flexDirection="column">
            <Text variant="headline">{children}</Text>
            <Text variant="fieldLabel">{label}</Text>
        </Box>
    );
};

interface GeneralStatsProps {
    statisticsData: BaseStatisticsMessage['general'];
}

export const GeneralStats = ({ statisticsData }: GeneralStatsProps) => {
    const { isLubes } = useProductContext();
    const unit = isLubes ? 'L' : 'mt';
    return (
        <StatsGrid>
            <StatsItem label={translate('reporting.general.deliveries')}>
                {formatNumber({
                    number: statisticsData.completedCount,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })}
            </StatsItem>

            <StatsItem label={translate('reporting.general.volumes')}>
                {formatNumber({
                    number: statisticsData.volume,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })}
                &nbsp;
                {unit}
            </StatsItem>

            <StatsItem label={isLubes ? translate('reporting.general.ppl') : translate('reporting.general.ppmt')}>
                {formatMoney({
                    value: statisticsData.ppl.value,
                    currency: statisticsData.ppl.currency,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })}
            </StatsItem>

            <StatsItem label={translate('reporting.general.costs')}>
                {formatMoney({
                    value: statisticsData.total.value,
                    currency: statisticsData.total.currency,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })}
            </StatsItem>
        </StatsGrid>
    );
};
