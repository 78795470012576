import { useCallback, useEffect, useState } from 'react';
import { MultiValue, OnChangeValue } from 'react-select';

import { ProductContext } from '../../../../types/ProductContext';
import { ComboBox, ComboBoxProps, DefaultOption } from '../../../cdl/ComboBox/ComboBox';
import { getCustomers, GetCustomersParams } from '../../api/clients/company.api';
import { formatCompanyName } from '../../helpers/formatCompanyName.helper';
import { useRole } from '../../hooks/useRole';

export interface CustomerCompanySelectProps<IsMulti extends boolean>
    extends Omit<ComboBoxProps<DefaultOption, IsMulti>, 'onChange' | 'value' | 'options' | 'isLoading'> {
    onChange: (option: IsMulti extends true ? string[] : string) => void;
    groupId?: string;
    context?: ProductContext;
    value: IsMulti extends true ? string[] : string;
}

export function CustomerCompanySelect<IsMulti extends boolean>({
    context,
    ...rest
}: CustomerCompanySelectProps<IsMulti>) {
    const [selectedValue, setSelectedValue] = useState<DefaultOption[]>([]);
    const role = useRole();

    const loadCustomers = useCallback(
        (query: GetCustomersParams): Promise<DefaultOption[]> => {
            return getCustomers(query).then((response) =>
                response.map((company) => ({
                    value: company.id,
                    label: formatCompanyName({
                        company,
                        includeType: role.isAdmin(),
                    }) as DefaultOption['label'],
                }))
            );
        },
        [role]
    );

    useEffect(() => {
        let isMounted = true;

        if (rest.value.length) {
            loadCustomers({
                ids: Array.isArray(rest.value) ? rest.value : [rest.value],
                type: context,
            }).then((options) => {
                if (isMounted) setSelectedValue(options);
            });
        } else {
            setSelectedValue([]);
        }

        return () => {
            isMounted = false;
        };
    }, [rest.value, context, loadCustomers]);

    const loadCompaniesByInput = (searchQuery?: string) =>
        loadCustomers({
            searchQuery,
            groupId: rest.groupId,
            type: context,
        });

    function updateSelectedValue(options: OnChangeValue<DefaultOption, IsMulti>) {
        if (rest.isMulti) {
            rest.onChange(
                (options as MultiValue<DefaultOption>).map((it) => it.value) as IsMulti extends true ? string[] : string
            );
        } else {
            rest.onChange((options as DefaultOption).value as IsMulti extends true ? string[] : string);
        }
    }

    return (
        <ComboBox
            {...rest}
            value={rest.isMulti ? selectedValue : selectedValue[0]}
            onChange={updateSelectedValue}
            loadOptions={loadCompaniesByInput}
            // Hack to trigger fetching of new options
            rerenderValue={JSON.stringify(rest.groupId)}
        />
    );
}
