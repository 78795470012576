import { useQuery } from '@tanstack/react-query';

import { ProductContext } from '../../../types/ProductContext';
import { getDemandInformation } from '../../common/api/clients/demand.api';
import { queryKeys } from '../../common/api/queryKeys';
import { useRole } from '../../common/hooks/useRole';
import { SortingKey } from '../components/SortingKey';

export const useFuelDemandInformation = (
    sortingKey: SortingKey,
    useBunkerMetricRecommendations: boolean,
    groupId?: string
) => {
    const role = useRole();
    return useQuery({
        queryKey: queryKeys.demandInformation(sortingKey, ProductContext.FUEL, useBunkerMetricRecommendations, groupId),
        queryFn: () => getDemandInformation(sortingKey, ProductContext.FUEL, useBunkerMetricRecommendations, groupId),
        enabled: !role.isAdmin() || groupId !== undefined,
    });
};
