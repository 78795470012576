import { Text } from '../cdl/Text/Text';
import { translate } from '../common/helpers/translate.helper';
import { useDocumentTitle } from '../common/hooks/useDocumentTitle';
import { LocationStateProvider } from '../common/LocationState/LocationStateProvider';
import { useLocationState } from '../common/LocationState/useLocationState';
import { Page } from '../common/Page/Page';
import { TabSwitch } from '../common/TabSwitch/TabSwitch';
import { Box } from '../common/ui/Box';

import { MaerskPageContent } from './MaerskPageContent';
import { OnePortPageContent } from './OnePortPageContent';

enum Integration {
    MAERSK = 'maersk',
    ONE_PORT = 'onePort',
}

interface IntegrationsOverviewStateConfig {
    integration: Integration;
}

export const IntegrationsOverviewPage = () => {
    useDocumentTitle(translate('page.integrationsOverview'));

    const stateConfig: IntegrationsOverviewStateConfig = {
        integration: Integration.MAERSK,
    };

    return (
        <Page>
            <LocationStateProvider stateConfig={stateConfig}>
                <IntegrationsPageContent />
            </LocationStateProvider>
        </Page>
    );
};

const IntegrationsPageContent = () => {
    const [locationStateUntyped, { setLocationFieldValue }] = useLocationState();
    const locationState = locationStateUntyped as IntegrationsOverviewStateConfig;

    return (
        <Box display="grid" rowGap={4}>
            <Text variant="headline" as="h1">
                {translate('integrationsOverview.headline')}
            </Text>

            <TabSwitch
                selectedContext={locationState.integration}
                onSelect={(integration) => setLocationFieldValue('integration', integration)}
                totals={{
                    [Integration.MAERSK]: null,
                    [Integration.ONE_PORT]: null,
                }}
            />

            {locationState.integration === Integration.MAERSK ? <MaerskPageContent /> : null}
            {locationState.integration === Integration.ONE_PORT ? <OnePortPageContent /> : null}
        </Box>
    );
};
