import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { Button } from '../../../../cdl/Button/Button';
import { Checkbox } from '../../../../cdl/Checkbox/Checkbox';
import { Modal } from '../../../../cdl/Modal/Modal';
import { Text } from '../../../../cdl/Text/Text';
import { calculateTotal } from '../../../../common/helpers/calculate.utilities';
import { formatMoney } from '../../../../common/helpers/formatMoney.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { LoadingIndicator } from '../../../../common/LoadingIndicator/LoadingIndicator';
import { Box } from '../../../../common/ui/Box';
import { OfferModel } from '../../../model/OfferModel';
import { useFee } from '../hooks/useFee';

interface SpotConfirmTermsAndConditionsModalProps {
    open: boolean;
    onDismiss: () => void;
}

export const FormikSpotConfirmTermsModal = ({ open, onDismiss }: SpotConfirmTermsAndConditionsModalProps) => {
    const { isSubmitting, submitForm, values } = useFormikContext<OfferModel>();
    const [hasAcceptedTermsAndConditions, setHasAcceptedTermsAndConditions] = useState(false);

    const calculatedOfferTotal = calculateTotal(values.items, values.surcharges, values.samplekits);
    const offerFeeQueryResult = useFee(calculatedOfferTotal.value, calculatedOfferTotal.currency, open);

    useEffect(() => {
        if (!open) {
            setHasAcceptedTermsAndConditions(false);
        }
    }, [open]);

    const handleOnSubmit = async () => {
        await submitForm();
    };

    return (
        <Modal isOpen={open} onDismiss={onDismiss} dismissible={false}>
            <Box display="flex" flexDirection="column" rowGap={5} padding={6}>
                <Text variant="title" as="h3">
                    {translate('offer.quotationProcess.navigationButtons.spot.send')}
                </Text>

                {offerFeeQueryResult.isPending || !offerFeeQueryResult.data ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        <Text
                            variant="body"
                            as="p"
                            color="foreground.muted"
                            dangerouslySetInnerHTML={{
                                __html: translate('offer.quotationProcess.confirm.gtcLabel', {
                                    value: formatMoney({ ...offerFeeQueryResult.data.fee, maximumFractionDigits: 2 }),
                                }),
                            }}
                        />

                        <Checkbox
                            label={translate('offer.quotationProcess.agreeToTermsAndConditions')}
                            checked={hasAcceptedTermsAndConditions}
                            onChange={(e) => setHasAcceptedTermsAndConditions(e.target.checked)}
                        />

                        <Box display="flex" columnGap={4} justifyContent="end" marginTop={5}>
                            <Button onClick={onDismiss}>
                                {translate('offer.quotationProcess.navigationButtons.cancel')}
                            </Button>
                            <Button
                                emphasis="high"
                                type="button"
                                isLoading={isSubmitting}
                                onClick={handleOnSubmit}
                                disabled={!hasAcceptedTermsAndConditions}
                            >
                                {translate('offer.quotationProcess.confirm.acceptTermsAndConditions')}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};
