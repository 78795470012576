import styled from 'styled-components';

import { Box } from '../../../../common/ui/Box';
import { get } from '../../../../common/ui/get';

export const StopDot = styled(Box)({
    borderWidth: '4px',
    borderStyle: 'solid',
    borderRadius: '50%',
    width: '12px',
    height: '12px',
    position: 'absolute',
    left: '0',
    top: '10px',
    backgroundColor: get('colors.background.default'),
});
