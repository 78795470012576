import { animated, config, useTransition } from '@react-spring/web';
import useComponentSize from '@rehooks/component-size';
import { ReactNode, useRef } from 'react';

interface BasicAccordionWithoutHeaderProps {
    isOpen: boolean;
    children?: ReactNode;
}

export const BasicAccordionWithoutHeader = (props: BasicAccordionWithoutHeaderProps) => {
    const accordionBodyRef = useRef<HTMLDivElement>(null);
    const { height } = useComponentSize(accordionBodyRef);

    const closedState = {
        opacity: 0,
        height: 0,
    };

    const openState = {
        opacity: 1,
        height: height,
    };

    const contentAnimation = useTransition(props.isOpen, {
        from: closedState,
        leave: closedState,
        update: openState,
        config: {
            ...config.default,
            tension: 300,
            clamp: true,
        },
    });

    return (
        <div>
            {contentAnimation((style, item) => {
                if (!item) {
                    return null;
                }

                return (
                    <animated.div
                        style={{
                            overflow: 'hidden',
                            ...style,
                        }}
                    >
                        <div ref={accordionBodyRef}>{props.children}</div>
                    </animated.div>
                );
            })}
        </div>
    );
};
