import { VesselMessage } from '../../../../../../types/VesselMessage';
import { Text } from '../../../../../cdl/Text/Text';
import { VesselInfoPopup } from '../../../../../common/CoreDataPopup/VesselInfoPopup';
import { translate } from '../../../../../common/helpers/translate.helper';

import { InvolvedParty } from './InvolvedParty';

interface VesselPartyProps {
    vessel: VesselMessage;
}

export const VesselParty = ({ vessel }: VesselPartyProps) => {
    return (
        <InvolvedParty
            label={translate('offer.detail.coreData.vessel')}
            modalContent={<VesselInfoPopup vessel={vessel} />}
        >
            <Text aria-label="Vessel name">{vessel.name}</Text>
            <Text color="foreground.subtle">{vessel.imo}</Text>
        </InvolvedParty>
    );
};
