import { useQuery } from '@tanstack/react-query';

import { getCustomers } from '../common/api/clients/company.api';
import { queryKeys } from '../common/api/queryKeys';

export const useCustomerIdList = ({ groupId, type }) => {
    return useQuery({
        queryKey: queryKeys.customers({ customerGroupId: groupId }),
        queryFn: () =>
            getCustomers({
                groupId,
                type,
            }),
        select: (data) => data.map((customer) => customer.id),
    });
};
