import FileDownload from 'js-file-download';

import { KeyValuePair } from '../../../types/BunkerStopsCsvRequestMessage';
import { getBunkerStopsCsv } from '../../common/api/clients/bunkerPlan.api';

export const downloadCsv = async ({ rows, filename }: { rows: Map<string, string>[]; filename: string }) => {
    // Use the first row to determine the headers
    const headers = [...rows[0].keys()];

    const keyValuePairs: KeyValuePair[] = rows.reduce((acc: KeyValuePair[], row) => {
        const keyValuePairs: KeyValuePair = {};
        row.forEach((value, key) => {
            keyValuePairs[key] = value;
        });
        acc.push(keyValuePairs);

        return acc;
    }, []);

    const response = await getBunkerStopsCsv({
        headers,
        rows: keyValuePairs,
    });

    FileDownload(response.data, filename);
};
