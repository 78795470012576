import { Text } from '../../../cdl/Text/Text';
import { Tooltip } from '../../../cdl/Tooltip/Tooltip';
import { translate } from '../../../common/helpers/translate.helper';
import { IconAlertTriangle } from '../../../common/icons/cdl/AlertTriangle';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex';
import { theme } from '../../../common/ui/theme';

interface PricingCodeWrapperProps {
    isInvalid: boolean;
    textColor: string;
    children: string;
}

export const PricingCodeWrapper = ({ isInvalid, textColor, children }: PricingCodeWrapperProps) => {
    return (
        <Flex alignItems="center">
            <Text color={textColor}>{children}</Text>

            <Box height={20} width={20} marginX={4}>
                {isInvalid ? (
                    <Tooltip label={translate('contracts.invalidPricingCodeError')}>
                        <div>
                            <IconAlertTriangle color={theme.colors.negative.foreground} />
                        </div>
                    </Tooltip>
                ) : null}
            </Box>
        </Flex>
    );
};
