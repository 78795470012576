import PropTypes from 'prop-types';

import { Button } from '../../common/buttons/Button';
import { LogoHeader } from '../../common/headers/LogoHeader';
import { IconX } from '../../common/icons/cdl/X';
import { ThreeStepsProgressBar } from '../../common/ThreeStepsProgressBar/ThreeStepsProgressBar';

import { useEnquiryCreation } from './hooks/useEnquiryCreation';

export const FuelEnquiryHeader = ({ title, onCancelClick }) => {
    const { page } = useEnquiryCreation();

    return (
        <LogoHeader
            title={title}
            centerElement={<ThreeStepsProgressBar circleNumber={page} />}
            rightElement={
                <Button color="outline-dark" onClick={onCancelClick}>
                    <IconX />
                </Button>
            }
        />
    );
};

FuelEnquiryHeader.propTypes = {
    title: PropTypes.string.isRequired,
    onCancelClick: PropTypes.func.isRequired,
};
