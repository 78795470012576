import Ably from 'ably';
import storage from 'local-storage-fallback';

import AppSettings from '../../constants';

import Message = Ably.Message;

let client: Ably.Realtime | null = null;

const subscriptions: Map<string, ((message: Message) => void) | null> = new Map();

export class AblyService {
    static connect() {
        const accessToken = storage.getItem('cl-session-token');

        client = new Ably.Realtime({
            authUrl: AppSettings.routes.api + '/v1/ably/token-request',
            authMethod: 'POST',
            authHeaders: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static disconnect() {
        if (this.isConnected()) {
            client?.close();
        }
    }

    static isConnected(): boolean {
        return client?.connection.state === 'connected';
    }

    static subscribe(topic: string, handler: (message: Message) => void) {
        if (subscriptions.get(topic)) {
            this.unsubscribe(topic);
        }

        if (!this.isConnected()) {
            return;
        }

        subscriptions.set(topic, handler);

        const channel = client?.channels.get(topic);
        channel?.subscribe(handler);
    }

    static unsubscribe(topic: string) {
        if (!this.isConnected()) {
            return;
        }

        if (client && subscriptions.get(topic)) {
            subscriptions.set(topic, null);

            const channel = client?.channels.get(topic);
            channel?.unsubscribe();
        }
    }

    static getClient(): Ably.Realtime | null {
        return client;
    }
}
