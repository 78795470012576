export enum AverageConsumptionQuality {
    GOOD = 'GOOD',
    FAIR = 'FAIR',
    POOR = 'POOR',
}

export interface AverageConsumption {
    vesselId: string;
    category: string;
    averageConsumption: number;
    averageConsumptionExclDaysAtPort: number;
    qualityCategory: AverageConsumptionQuality;
    qualityScore: number;
    averageDate: string;
    overallConsumption: number;
    numberOfConsumptions: number;
    baseDays: number;
}
