import { useField } from 'formik';
import PropTypes from 'prop-types';

import { FormikHumanReadableEnumValue } from '../../form-elements/formik/FormikHumanReadableEnumValue';
import { FormikTextInput } from '../../form-elements/formik/FormikTextInput';

export const SurchargeName = (props) => {
    const [field] = useField(props.name);
    const surcharge = field.value;

    const isWaiver = (waiver) => {
        return waiver.value?.value <= 0;
    };

    const isSurchargeNameEditable = (surcharge) => {
        return isWaiver(surcharge) || surcharge.surchargeType === 'CUSTOM';
    };

    if (!isSurchargeNameEditable(surcharge)) {
        return <FormikHumanReadableEnumValue name={`${props.name}.surchargeType`} enumType="surchargeType" />;
    }

    if (surcharge.deleted || !props.editable) {
        return surcharge.name;
    }

    return <FormikTextInput placeholder="Name" name={`${props.name}.name`} />;
};

SurchargeName.propTypes = {
    name: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired,
};
