import PropTypes from 'prop-types';
import { Children, useRef } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Button } from '../../common/buttons/Button';
import { IconChevronLeft } from '../../common/icons/cdl/ChevronLeft';
import { IconChevronRight } from '../../common/icons/cdl/ChevronRight';
import { CloakBox } from '../../common/Slider/CloakBox';
import { Box } from '../../common/ui/Box';

SwiperCore.use([Navigation]);

export const ContractProductSlider = ({ slidesPerView, children }) => {
    const prevSlideControl = useRef(null);
    const nextSlideControl = useRef(null);

    return (
        <Box position="relative" paddingX="64px">
            <CloakBox left="0">
                <Button color="outline-clear" ref={prevSlideControl}>
                    <IconChevronLeft />
                </Button>
            </CloakBox>

            <CloakBox right="0" reverseGradient>
                <Button color="outline-clear" ref={nextSlideControl}>
                    <IconChevronRight />
                </Button>
            </CloakBox>

            <Box>
                <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={32}
                    slidesPerGroup={1}
                    allowTouchMove={false}
                    loop={false}
                    navigation={{
                        prevEl: prevSlideControl.current,
                        nextEl: nextSlideControl.current,
                    }}
                >
                    {Children.map(children, (child, index) => {
                        return <SwiperSlide key={index}>{child}</SwiperSlide>;
                    })}
                </Swiper>
            </Box>
        </Box>
    );
};

ContractProductSlider.propTypes = {
    slidesPerView: PropTypes.number.isRequired,
    children: PropTypes.node,
};
