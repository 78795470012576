import { useField } from 'formik';

import { SurchargeMessage } from '../../../../../types/SurchargeMessage';
import { FormikInput } from '../../../../cdl/Input/FormikInput';
import { Text } from '../../../../cdl/Text/Text';
import { FormikHumanReadableEnumValue } from '../../../form-elements/formik/FormikHumanReadableEnumValue';

interface SurchargeNameProps {
    name: string;
    editable: boolean;
}

export const SurchargeName = (props: SurchargeNameProps) => {
    const [field] = useField(props.name);
    const surcharge = field.value;

    const isWaiver = (surcharge: SurchargeMessage) => {
        return surcharge.value?.value < 0;
    };

    const isSurchargeNameEditable = (surcharge: SurchargeMessage) => {
        return isWaiver(surcharge) || surcharge.surchargeType === 'CUSTOM';
    };

    if (!isSurchargeNameEditable(surcharge)) {
        return (
            <Text variant="body">
                <FormikHumanReadableEnumValue name={`${props.name}.surchargeType`} enumType="surchargeType" />
            </Text>
        );
    }

    if (surcharge.deleted || !props.editable) {
        return <Text variant="body">{surcharge.name}</Text>;
    }

    return <FormikInput name={`${props.name}.name`} />;
};
