import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { getPorts } from '../common/api/clients/port.api';
import { DoubleLineOption } from '../common/DoubleLineOption/DoubleLineOption';
import { AsyncSearchSelect } from '../common/form-elements/AsyncSearchSelect/AsyncSearchSelect';
import { Label } from '../common/form-elements/Label/Label';
import { selectStyles } from '../common/form-elements/Select/Select.styles';
import { translate } from '../common/helpers/translate.helper';

import { Port } from './Port';

const PortOption = ({ data: { port }, ...props }) => {
    return (
        <DoubleLineOption
            headline={<Port port={port} short={true} />}
            subline={`${port.country.name} (${port.locCode})`}
            {...props}
        />
    );
};

PortOption.propTypes = {
    data: PropTypes.object,
    innerProps: PropTypes.object,
};

/**
 * @deprecated use PortSelect instead
 */

export function DeprecatedPortSelect({ portId, onChange, label = translate('portSelect.label'), ...props }) {
    const [selectedValue, setSelectedValue] = useState(null);
    const [isDirty, setIsDirty] = useState(false);

    const loadPortsBySearchQuery = (searchQuery) => {
        return new Promise((resolve) => {
            getPorts({ searchQuery })
                .then((response) => {
                    return response.content.map((port) => ({
                        label: port.name,
                        value: port.id,
                        port,
                        schedule: null,
                        hideBorder: true,
                    }));
                })

                .then((ports) => resolve(ports));
        });
    };

    const loadPortById = (portId) => {
        return new Promise((resolve) => {
            getPorts({ ids: [portId] })
                .then((response) => {
                    const port = response.content[0];
                    return {
                        label: port.name,
                        value: port.id,
                        port,
                    };
                })

                .then((port) => resolve(port));
        });
    };

    useEffect(() => {
        let mounted = true;
        if (portId) {
            loadPortById(portId).then((result) => {
                if (mounted) {
                    setSelectedValue(result);
                }
            });
        }

        return () => (mounted = false);
    }, [portId]);

    const updateSelectedValue = (option) => {
        onChange(option.value, option);
    };

    const customStyles = {
        ...selectStyles,
        menu: (provided, state) => ({
            ...provided,
            ...selectStyles.menu(provided, state),
        }),
    };

    return (
        <Label label={label} required={props.required}>
            <AsyncSearchSelect
                placeholder={translate('portSelect.placeholder')}
                values={selectedValue}
                onChange={updateSelectedValue}
                loadOptions={loadPortsBySearchQuery}
                components={{
                    Option: PortOption,
                }}
                className={isDirty ? 'dirty' : ''}
                onBlur={() => setIsDirty(true)}
                styles={customStyles}
                {...props}
            />
        </Label>
    );
}

DeprecatedPortSelect.propTypes = {
    portId: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    label: PropTypes.string,
    schedules: PropTypes.array,
};
