import storage from 'local-storage-fallback';

function SessionStorageService() {
    const KEY = 'cl-session-token';
    return {
        saveToken: function (token) {
            storage.setItem(KEY, token);
        },
        getToken: function () {
            return storage.getItem(KEY);
        },
        resetToken: function () {
            storage.removeItem(KEY);
        },
    };
}

export default {
    name: 'SessionStorageService',
    fn: SessionStorageService,
};
