import PropTypes from 'prop-types';

import { useAngularRouterLocation } from '../../../common/hooks/useAngularRouterLocation';
import { LocationStateProvider } from '../../../common/LocationState/LocationStateProvider';
import { sortValueHelpers } from '../../../common/LocationState/sortValueHelpers';

export const LubesOrderOverviewState = ({ children }) => {
    const angularLocation = useAngularRouterLocation();

    const stateConfig = {
        searchQuery: '',
        customerIds: [],
        supplierIds: [],
        tab: angularLocation.tab || 'enquired',
        page: 0,
        sortValue: {
            defaultValue: {
                sortField: 'dateDelivery',
                sortDirection: 'DESC',
            },
            deserializer: sortValueHelpers.deserializer,
            serializer: sortValueHelpers.serializer,
        },
    };

    return <LocationStateProvider stateConfig={stateConfig}>{children}</LocationStateProvider>;
};

LubesOrderOverviewState.propTypes = {
    children: PropTypes.node,
};
