import css from '@styled-system/css';
import styled from 'styled-components';

import { NavigationLink } from '../../Link/NavigationLink';
import { theme } from '../../ui/theme';

export const NavigationItem = styled(NavigationLink)(
    {
        color: theme.colors['dark-blue'][2],
        '&.active': {
            color: theme.colors['clear-blue'][0],
            backgroundColor: theme.colors['clear-blue'][9],
        },
    },
    css({
        display: 'flex',
        borderRadius: 1,
        alignItems: 'center',
        padding: 4,
        ':hover, :focus': {
            color: 'clear-blue.0',
            textDecoration: 'none',
            backgroundColor: 'clear-blue.9',
        },
        svg: {
            marginRight: 3,
        },
    })
);
