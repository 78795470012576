import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { OrderEditPage as OriginalOrderEditPage } from './OrderEditPage';

const OrderEditPage = (props) => {
    return (
        <AppProviders>
            <OriginalOrderEditPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(OrderEditPage, 'reactOrderEditPage');
