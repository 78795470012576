import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { SupplierGroupDetailPage } from './SupplierGroupDetailPage';

const SupplierGroupDetailPageReactComponent = (props: any) => {
    return (
        <AppProviders>
            <SupplierGroupDetailPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(SupplierGroupDetailPageReactComponent, 'reactSupplierGroupDetailPage');
