CustomerService.$inject = ['CustomerResource'];

function CustomerService(CustomerResource) {
    return {
        list: list,
    };

    function list(filter) {
        return CustomerResource.list(filter);
    }
}

export default {
    name: 'CustomerService',
    fn: CustomerService,
};
