import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { FormikApprovalSettingsState } from '../MultipleApproversSettingsForm';

export const useManageNewApprovalLimitInputFields = () => {
    const { values, setFieldValue } = useFormikContext<FormikApprovalSettingsState>();

    useEffect(() => {
        if (values.additionalApprovers.length > 0) {
            const lastAdditionalApprover = values.additionalApprovers[values.additionalApprovers.length - 1];

            if (lastAdditionalApprover.approvalLimit.length > 0) {
                const newValues = [
                    ...values.additionalApprovers,
                    { approvalLimit: '', approvers: [{ emailAddress: '' }] },
                ];

                setFieldValue('additionalApprovers', newValues);
            }

            if (values.additionalApprovers.length > 1) {
                const secondLastAdditionalApprover = values.additionalApprovers[values.additionalApprovers.length - 2];

                if (secondLastAdditionalApprover.approvalLimit.length === 0) {
                    const newValues = values.additionalApprovers.slice(0, values.additionalApprovers.length - 1);

                    setFieldValue('additionalApprovers', newValues);
                }
            }
        } else {
            setFieldValue('additionalApprovers', [{ approvalLimit: '', approvers: [{ emailAddress: '' }] }]);
        }
    }, [values, setFieldValue]);
};
