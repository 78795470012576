import { useEffect, useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled, { keyframes } from 'styled-components';

import { Box } from '../../common/ui/Box.tsx';
import { get } from '../../common/ui/get';

import { calculateDrawerOffset } from './calculateDrawerOffset';
import { Layer } from './Layer';
import { useDrawer } from './useDrawer.hook';

const fadeInAnimation = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const slideOutAnimation = keyframes`
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(100%, 0, 0);
    }
`;

const Wrapper = styled(Box)`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    min-height: 100vh;
    z-index: 9998;
    animation: ${fadeInAnimation} 200ms ease-in-out;
    transition: opacity 200ms ease-in-out;

    &.backdrop-exit-active {
        opacity: 0;
    }

    .layer {
    }

    .layer-enter {
    }

    .layer-enter-active {
    }

    .layer-exit {
    }

    .layer-exit-active {
        animation: ${slideOutAnimation} 200ms ease-in-out forwards;
    }
`;

const Backdrop = styled(Box)`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    backdrop-filter: blur(2px);
    background-color: ${get('colors.background.dimming')};
    z-index: ${({ index }) => index};
`;

export const Drawer = () => {
    const { layers, pop } = useDrawer();
    const [showDrawer, setShowDrawer] = useState(false);
    const [offsets, setOffsets] = useState([]);

    useEffect(() => {
        setShowDrawer(layers.length > 0);
        setOffsets(calculateDrawerOffset(layers));
    }, [layers]);

    useEffect(() => {
        const handleKeyUp = (event) => {
            if (event.key === 'Escape') {
                pop();
            }
        };

        if (showDrawer) {
            window.addEventListener('keyup', handleKeyUp);
            return () => {
                window.removeEventListener('keyup', handleKeyUp);
            };
        }
    }, [showDrawer, pop]);

    return (
        <TransitionGroup className="backdrop">
            {showDrawer ? (
                <CSSTransition in={showDrawer} key="backdrop" classNames="backdrop" timeout={200}>
                    <RemoveScroll forwardProps>
                        <Wrapper>
                            <TransitionGroup className="layer-list">
                                {layers.map((layer, index) => {
                                    return (
                                        <CSSTransition timeout={200} key={index} classNames="layer">
                                            <Layer
                                                key={index}
                                                width={layer.width}
                                                offset={offsets[index]}
                                                index={index}
                                                total={layers.length}
                                            >
                                                {layer.content}
                                            </Layer>
                                        </CSSTransition>
                                    );
                                })}
                            </TransitionGroup>
                            <Backdrop index={layers.length - 1} />
                        </Wrapper>
                    </RemoveScroll>
                </CSSTransition>
            ) : null}
        </TransitionGroup>
    );
};

Drawer.proptypes = {};
