import * as Dialog from '@radix-ui/react-dialog';
import styled from 'styled-components';

import { theme } from '../../../common/ui/theme';

export const CloseIconWrapper = styled(Dialog.Close)`
    padding: 0;
    border: none;
    position: absolute;
    top: ${theme.space[6]}px;
    right: ${theme.space[6]}px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    height: 32px;
    width: 32px;
    background: transparent;
    transition: background-color 100ms ease-out;

    color: ${theme.colors.foreground.subtle};

    &:active {
        background-color: ${theme.colors.background.inset};
    }

    &:hover {
        background-color: ${theme.colors.background.subtle};
        color: ${theme.colors.foreground.default};
    }
`;
