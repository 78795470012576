import { useMutation } from '@tanstack/react-query';

import { BunkerStopMessage } from '../../../types/BunkerStopsMessage';
import { formatDateTime } from '../../common/helpers/formatDateTime.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useFuelVolumeFormat } from '../../common/hooks/useFuelVolumeFormat';
import { createCategoriesFromStops } from '../utils/createCategoriesFromStops';
import { downloadCsv } from '../utils/dowloadCsv';
import { generateFileDate } from '../utils/generateFileDate';

import { useBunkerStops } from './useBunkerStops';

const useRowGeneration = () => {
    const formatVolume = useFuelVolumeFormat(false);

    return (
        bunkerStops: BunkerStopMessage[],
        categories: Map<
            string,
            {
                productGroup: string;
                sulphurContent: string;
            }
        >
    ): Map<string, string>[] => {
        const rows: Map<string, string>[] = [];
        bunkerStops.forEach((bunkerStop) => {
            const row: Map<string, string> = new Map<string, string>();
            row.set(translate('bunkerPlan.csvHeaders.port'), bunkerStop.port.name);
            row.set(translate('bunkerPlan.csvHeaders.vessel'), bunkerStop.vessel.name);
            row.set(translate('bunkerPlan.csvHeaders.eta'), formatDateTime({ date: bunkerStop.eta }));
            row.set(translate('bunkerPlan.csvHeaders.etd'), formatDateTime({ date: bunkerStop.etd }));

            categories.forEach((category) => {
                const bunkerStopItemMessage = bunkerStop.items.find(
                    (item) =>
                        item.productGroup === category.productGroup && item.sulphurContent === category.sulphurContent
                );

                const volume = bunkerStopItemMessage?.volume;
                row.set(
                    translate(`bunkerPlan.csvHeaders.category`, {
                        category: `${category.productGroup} ${category.sulphurContent}`,
                    }),
                    volume ? formatVolume({ volume }) : '-'
                );
            });

            rows.push(row);
        });

        return rows;
    };
};

const generateFilename = (): string => {
    const date = generateFileDate();

    return `bunker-plan_${date}.csv`;
};

export const useBunkerStopsCsvDownload = () => {
    const bunkerStopsQuery = useBunkerStops();
    const generateRows = useRowGeneration();
    const bunkerStops = bunkerStopsQuery.data?.bunkerStops ?? [];

    const categories = createCategoriesFromStops(bunkerStops);
    const rows = generateRows(bunkerStops, categories);

    return useMutation({
        mutationFn: () =>
            downloadCsv({
                rows,
                filename: generateFilename(),
            }),
    });
};
