import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { UserGatewayMessage } from '../../../types/UserGatewayMessage';
import { UserRole } from '../../../types/UserRole';
import { UserType } from '../../../types/UserType';
import { UserUpdateMessage } from '../../../types/UserUpdateMessage';
import { identifyUserRole } from '../../common/helpers/identifyUserRole.helper';
import { translate } from '../../common/helpers/translate.helper';
import { companyAccessLevel, groupAccessLevel } from '../list/utils/UserAccessLevels';

import { EditUserBaseForm } from './EditUserBaseForm';

interface EditUserFormProps {
    onSubmit: (userMessage: UserUpdateMessage, meta: FormikHelpers<FormikEditUserState>) => void;
    user: UserGatewayMessage;
}

export interface FormikEditUserState {
    id: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    groupId: string;
    groupName?: string;
    phoneNumber?: string;
    userType: UserType;
    newsletter: boolean;
    transactional: boolean;
    role: UserRole;
    companyIds: string[];
}

export const EditUserForm = ({ onSubmit, user }: EditUserFormProps) => {
    const initialFormikState: FormikEditUserState = {
        id: user.id,
        emailAddress: user.email,
        firstName: user.firstname,
        lastName: user.lastname,
        groupId: user.groupId,
        groupName: user.group?.name,
        userType: user.userType,
        phoneNumber: user.phone ?? '',
        newsletter: user.mailSettings.newsletter,
        transactional: user.mailSettings.transactional,
        role: identifyUserRole(user),
        companyIds: user.companyAccesses ? user.companyAccesses.map((company) => company.companyId) : [],
    };

    const handleSubmit = (values: FormikEditUserState, meta: FormikHelpers<FormikEditUserState>) => {
        const userMessage: UserUpdateMessage = {
            ...user,
            // @ts-expect-error missing UserRole.CUSTOM on groupAccessLevel
            accessLevel: groupAccessLevel[values.role],
            email: values.emailAddress,
            userType: values.userType!,
            firstname: values.firstName,
            lastname: values.lastName,
            phone: values.phoneNumber || undefined,
            mailSettings: {
                newsletter: values.newsletter,
                transactional: values.transactional,
            },
            companyAccesses: values.companyIds.length
                ? values.companyIds.map((companyId) => {
                      return {
                          companyId: companyId,
                          // @ts-expect-error missing UserRole.CUSTOM on companyAccessLevel
                          accessLevel: companyAccessLevel[values.role.toString()],
                      };
                  })
                : undefined,
        };
        onSubmit(userMessage, meta);
    };

    const validate = (values: FormikEditUserState): FormikErrors<FormikEditUserState> => {
        const errors: FormikErrors<FormikEditUserState> = {};

        if (!values.emailAddress || values.emailAddress.length === 0) {
            errors.emailAddress = translate('user.error.requiredEmail');
        }

        if (!values.firstName || values.firstName.length === 0) {
            errors.firstName = translate('user.error.requiredFirstName');
        }

        if (!values.lastName || values.lastName.length === 0) {
            errors.lastName = translate('user.error.requiredLastName');
        }

        if (!values.role || values.role.length === 0) {
            errors.role = translate('user.error.requiredUserRole');
        }

        if (
            ![UserRole.GROUP_ADMIN, UserRole.CUSTOM].some((role) => values.role === role) &&
            !values.companyIds?.length
        ) {
            errors.companyIds = translate('user.error.requiredCompanyIds');
        }
        return errors;
    };

    return (
        <Formik initialValues={initialFormikState} onSubmit={handleSubmit} validate={validate}>
            <EditUserBaseForm />
        </Formik>
    );
};
