import { components, OptionProps } from 'react-select';
import styled from 'styled-components';

import { IconCheck } from '../../../common/icons/cdl/Check';
import { get } from '../../../common/ui/get';
import { theme } from '../../../common/ui/theme';
import { Text } from '../../Text/Text';

const StyledOption = styled(components.Option)<OptionProps>`
    && {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 400;
        height: 40px;
        vertical-align: center;
        padding: 0;
        color: ${get('colors.foreground.default')};
        border-radius: 4px;
        background-color: ${(props) => {
            if (props.isFocused) {
                return get('colors.accent.background');
            } else {
                return get('colors.background.default');
            }
        }};

        &:active {
            background-color: ${get('colors.accent.background')};
        }
    }
`;

const CheckmarkWrapper = styled.div`
    width: 24px;
    margin-left: ${get('space.4')}px;
    margin-right: ${get('space.2')}px;
`;

export const CustomOption = ({ children, ...rest }: OptionProps) => {
    return (
        <StyledOption {...rest}>
            <CheckmarkWrapper>
                {rest.isSelected ? <IconCheck style={{ color: theme.colors.accent.foreground }} /> : null}
            </CheckmarkWrapper>
            <Text variant="body" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {children}
            </Text>
        </StyledOption>
    );
};
