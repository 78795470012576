import { Form, Formik, FormikErrors } from 'formik';
import { useState } from 'react';

import { CustomerSettingsMessage } from '../../../../../types/CustomerSettingsMessage';
import { Button } from '../../../../cdl/Button/Button';
import { Text } from '../../../../cdl/Text/Text';
import { translate } from '../../../../common/helpers/translate.helper';
import { Box } from '../../../../common/ui/Box';

import { FormikMinimumApprovalThresholdAccordion } from './components/FormikMinimumApprovalThresholdAccordion';
import { FormikTopLevelApproverAndApproversAccordion } from './components/FormikTopLevelApproverAndApproversAccordion';
import { validateDuplicateApprovalLimits } from './util/validateDuplicateApprovalLimits';
import { validateDuplicateEmailAddresses } from './util/validateDuplicateEmailAddresses';
import { validateEmailAddresses } from './util/validateEmailAddresses';
import { validateMinimumThresholdMatchesLimits } from './util/validateMinimumThresholdMatchesLimits';
import { validateMissingApprovalLimits } from './util/validateMissingApprovalLimits';
import { validateMissingEmailAddress } from './util/validateMissingEmailAddress';

function validateMultipleApproverSettings(
    values: FormikApprovalSettingsState
): FormikErrors<FormikApprovalSettingsState> {
    let errors: FormikErrors<FormikApprovalSettingsState> = {};

    if (!values.defaultControllerEmailAddress || values.defaultControllerEmailAddress.length === 0) {
        errors.defaultControllerEmailAddress = translate(
            'multipleApproverSettings.errors.topLevelEmailAddressRequired'
        );
    }

    if (!values.enableAdditionalApprovers) {
        return errors;
    }

    errors = validateMinimumThresholdMatchesLimits(errors, values);
    errors = validateMissingApprovalLimits(errors, values);
    errors = validateMissingEmailAddress(errors, values);
    errors = validateDuplicateApprovalLimits(errors, values);
    errors = validateDuplicateEmailAddresses(errors, values);
    errors = validateEmailAddresses(errors, values);

    return errors;
}

export interface FormikApprovalSettingsState {
    activated: boolean;
    defaultControllerEmailAddress?: string;
    minimumThreshold?: {
        value: string;
        currency: string;
    };
    enableAdditionalApprovers: boolean;
    preventMultipleApprovers: boolean;
    additionalApprovers: {
        approvalLimit: string;
        approvers: {
            emailAddress: string;
        }[];
    }[];
}

enum ActiveAccordion {
    APPROVERS,
    THRESHOLD,
}

interface MultipleApproversSettingsFormProps {
    onSubmit: (customerSettings: CustomerSettingsMessage) => void;
    customerSettings: CustomerSettingsMessage;
}

export const MultipleApproversSettingsForm = ({ onSubmit, customerSettings }: MultipleApproversSettingsFormProps) => {
    const [activeAccordion, setActiveAccordion] = useState<ActiveAccordion | undefined>(ActiveAccordion.APPROVERS);

    const initialApprovalSettings: FormikApprovalSettingsState = {
        activated: customerSettings.approvalSettings.activated,
        defaultControllerEmailAddress: customerSettings.approvalSettings.defaultControllerEmailAddress,
        minimumThreshold: {
            value: customerSettings.approvalSettings.minimumThreshold?.value?.toString() || '',
            currency: 'USD',
        },
        enableAdditionalApprovers: !!customerSettings.approvalSettings.additionalApprovers?.length,
        additionalApprovers: customerSettings.approvalSettings.additionalApprovers
            ? customerSettings.approvalSettings.additionalApprovers.map((additionalApprovers) => ({
                  approvalLimit: additionalApprovers.approvalLimit.value.toString(),
                  approvers: additionalApprovers.approvers,
              }))
            : [],
        preventMultipleApprovers: customerSettings.approvalSettings.preventMultipleApprovers,
    };

    const handleSubmit = (values: FormikApprovalSettingsState) => {
        // enable the settings from dialog if default controller email address is added for the first time
        const shouldEnable =
            !customerSettings.approvalSettings.defaultControllerEmailAddress &&
            !!values.defaultControllerEmailAddress?.length;

        const newCompanySettings: CustomerSettingsMessage = {
            ...customerSettings,
            approvalSettings: {
                activated: shouldEnable || customerSettings.approvalSettings.activated,
                required: true,
                defaultControllerEmailAddress: values.defaultControllerEmailAddress,
                additionalApprovers: values.additionalApprovers
                    .filter((it) => it.approvalLimit.trim().length > 0)
                    .map((additionalApproverLimit) => ({
                        approvalLimit: {
                            value: Number(additionalApproverLimit.approvalLimit),
                            currency: 'USD',
                        },
                        approvers: additionalApproverLimit.approvers.filter((it) => it.emailAddress.trim().length > 0),
                    })),
                preventMultipleApprovers: values.preventMultipleApprovers,
            },
        };

        if (!Number.isNaN(values.minimumThreshold?.value) && Number(values.minimumThreshold!!.value) > 0) {
            newCompanySettings.approvalSettings.minimumThreshold = {
                value: Number(values.minimumThreshold!!.value),
                currency: 'USD',
            };
        }

        if (!values.enableAdditionalApprovers) {
            newCompanySettings.approvalSettings.additionalApprovers = [];
        }

        onSubmit(newCompanySettings);
    };

    return (
        <Box padding={7} height="100%">
            <Formik
                initialValues={initialApprovalSettings}
                onSubmit={handleSubmit}
                validate={validateMultipleApproverSettings}
            >
                <Form style={{ height: '100%' }}>
                    <Box display="grid" gridTemplateRows="auto 1fr auto" height="100%">
                        <Text variant="title" marginBottom={6}>
                            {translate('approvalSettings.headerEditLabel')}
                        </Text>

                        <Box>
                            <Box marginBottom={5}>
                                <FormikTopLevelApproverAndApproversAccordion
                                    isOpen={activeAccordion === ActiveAccordion.APPROVERS}
                                    onHeaderClick={() => {
                                        setActiveAccordion((current) =>
                                            current === ActiveAccordion.APPROVERS
                                                ? undefined
                                                : ActiveAccordion.APPROVERS
                                        );
                                    }}
                                />
                            </Box>
                            <FormikMinimumApprovalThresholdAccordion
                                isOpen={activeAccordion === ActiveAccordion.THRESHOLD}
                                onHeaderClick={() => {
                                    setActiveAccordion((current) =>
                                        current === ActiveAccordion.THRESHOLD ? undefined : ActiveAccordion.THRESHOLD
                                    );
                                }}
                                onOpenApproversAccordionClick={() => setActiveAccordion(ActiveAccordion.APPROVERS)}
                            />
                        </Box>

                        <Button emphasis="high" size="large" type="submit">
                            {translate('btn.save.default')}
                        </Button>
                    </Box>
                </Form>
            </Formik>
        </Box>
    );
};
