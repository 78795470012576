import { AppProviders } from '../common/AppProviders';
import { createDirectiveFromReactComponent } from '../common/helpers/createDirectiveFromReactComponent';

import { AgentsOverviewPage as OriginalOverviewPage } from './AgentsOverviewPage';

const AgentsOverviewPage = (props) => {
    return (
        <AppProviders>
            <OriginalOverviewPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(AgentsOverviewPage, 'reactAgentsOverview');
