import { useMemo } from 'react';
import styled from 'styled-components';

import { DateRangePicker } from '../../cdl/DateRangePicker/DateRangePicker';
import { CustomerCompanySelect } from '../../common/form-elements/CompanySelect/CustomerCompanySelect';
import { SupplierCompanySelect } from '../../common/form-elements/CompanySelect/SupplierCompanySelect';
import { generateStatisticsDateRangePresets } from '../../common/helpers/generateStatisticsDateRangePresets';
import { translate } from '../../common/helpers/translate.helper';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { useLocationState } from '../../common/LocationState/useLocationState';
import { Box } from '../../common/ui/Box';
import { get } from '../../common/ui/get';

const ActionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${get('space.5')}px;

    @media (max-width: ${get('breakpoints.2')}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const StatisticsActions = () => {
    const role = useRole();
    const { context } = useProductContext();

    const presets = useMemo(generateStatisticsDateRangePresets, []);
    const [locationState, { setLocationFieldValue }] = useLocationState();

    const onDateChange = (dates: Date[]) => {
        setLocationFieldValue('from', dates[0].getTime());
        setLocationFieldValue('to', dates[1].getTime());
    };

    return (
        <ActionWrapper>
            {role.isAdmin() || (role.isCustomer() && !role.isOneCompanyUser(context)) ? (
                <Box minWidth="400px">
                    <CustomerCompanySelect
                        onChange={(customerIds) => setLocationFieldValue('customerIds', customerIds)}
                        value={locationState.customerIds}
                        context={context}
                        placeholder={
                            role.isCustomer()
                                ? translate('reporting.filterByCompany')
                                : translate('reporting.filterByCustomer')
                        }
                        isMulti
                    />
                </Box>
            ) : null}

            {role.isAdmin() || (role.isSupplier() && !role.isOneCompanyUser(context)) ? (
                <Box minWidth="400px">
                    <SupplierCompanySelect
                        onChange={(supplierIds) => setLocationFieldValue('supplierIds', supplierIds)}
                        value={locationState.supplierIds}
                        context={context}
                        placeholder={
                            role.isSupplier()
                                ? translate('reporting.filterByCompany')
                                : translate('reporting.filterBySupplier')
                        }
                        isMulti
                    />
                </Box>
            ) : null}

            <Box minWidth="320px">
                <DateRangePicker
                    dates={[new Date(locationState.from), new Date(locationState.to)]}
                    onDatesChange={(dates) => onDateChange(dates)}
                    presets={presets}
                />
            </Box>
        </ActionWrapper>
    );
};
