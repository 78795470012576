import styled from 'styled-components';

import { Spinner } from '../Spinner/Spinner';
import { Box } from '../ui/Box';
import { theme } from '../ui/theme';

const Wrapper = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface LoadingIndicatorProps {
    variant?: 'small' | 'page' | 'full-height';
}

export const LoadingIndicator = ({ variant = 'small' }: LoadingIndicatorProps) => {
    let height: string;

    switch (variant) {
        case 'page':
            height = 'calc(100vh - 144px)';
            break;
        case 'full-height':
            height = '100vh';
            break;
        case 'small':
            height = '88px';
            break;
    }

    return (
        <Wrapper height={height}>
            <Spinner size={24} color={theme.colors.foreground.default} />
        </Wrapper>
    );
};
