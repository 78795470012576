import { ReactNode } from 'react';

interface TrProps {
    inactive?: boolean;
    focused?: boolean;
    unread?: boolean;
    children: ReactNode;
    onClick?: () => void;
}

export const Tr = ({ inactive = false, focused = false, unread = false, children, onClick }: TrProps) => {
    let className: string = '';
    if (inactive) {
        className = 'table-inactive';
    }
    if (focused) {
        className = 'table-focus';
    }
    if (unread) {
        className = 'unread';
    }

    return (
        <tr className={className} onClick={onClick}>
            {children}
        </tr>
    );
};
