import { useField } from 'formik';

import {
    FormikProductSelect,
    FormikProductSelectProps,
} from '../../../../common/form-elements/formik/FormikProductSelect';

interface InternalFormikProductSelectProps extends Omit<FormikProductSelectProps, 'onSelect'> {}

export const InternalFormikProductSelect = ({ name }: InternalFormikProductSelectProps) => {
    const [, , helper] = useField(name);

    return <FormikProductSelect name={`${name}.id`} onSelect={(product) => helper.setValue(product)} />;
};
