import { translate } from '../../../../common/helpers/translate.helper';
import { DefaultOption } from '../../../ComboBox/ComboBox';

export function getFilterValueLabel(options?: DefaultOption[]): string | undefined {
    if (!options || options.length === 0) {
        return undefined;
    }

    const labels = options.map((value) => value.label);

    if (labels.length < 2) {
        return labels.join(', ');
    }

    const translatedMoreItems = translate('global.filterMultiSelect.xMoreItems', {
        count: (labels.length - 1).toString(),
    });

    return `${labels[labels.length - 1]} ${translatedMoreItems}`;
}
