CustomerGroupService.$inject = ['CustomerGroupResource'];

function CustomerGroupService(CustomerGroupResource) {
    return {
        get: get,
    };

    function get(id) {
        return CustomerGroupResource.get({ id });
    }
}

export default {
    name: 'CustomerGroupService',
    fn: CustomerGroupService,
};
