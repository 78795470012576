import styled from 'styled-components';

import { LabelText } from '../../common/LabelText/LabelText';
import { Box } from '../../common/ui/Box';
import { get } from '../../common/ui/get';
import { Text } from '../Text/Text';

export const ValueFrame = styled.div`
    background: transparent;
    border-style: dashed;
    border-width: 1px;
    border-color: ${get('colors.border.muted')};
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 9px;
    padding-right: 9px;
    margin: 0;
    border-radius: ${get('radii.1')}px;
    height: 38px;
    overflow: hidden;
`;

interface ReadOnlyFieldProps {
    value: string;
    label?: string;
}

export const ReadOnlyField = ({ value, label }: ReadOnlyFieldProps) => {
    return (
        <Box>
            {label ? <LabelText>{label}</LabelText> : null}
            <ValueFrame>
                <Text
                    variant="body"
                    fontWeight="medium"
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'block',
                    }}
                >
                    {value}
                </Text>
            </ValueFrame>
        </Box>
    );
};
