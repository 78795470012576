import PropTypes from 'prop-types';

import { FuelShowOfferProductTable } from '../../../common/FuelShowOfferProductTable/FuelShowOfferProductTable';
import { ConfirmDeliveryPopup } from '../common/components/ConfirmDeliveryPopup';

export const FuelConfirmDeliveryPopup = ({ onDismiss, offer, onConfirm, isSubmitting, isOpen }) => {
    return (
        <ConfirmDeliveryPopup
            onDismiss={onDismiss}
            isOpen={isOpen}
            offer={offer}
            onConfirm={onConfirm}
            isSubmitting={isSubmitting}
        >
            <FuelShowOfferProductTable offer={offer} showPrices={false} />
        </ConfirmDeliveryPopup>
    );
};

FuelConfirmDeliveryPopup.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    offer: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
};
