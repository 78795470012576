import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { LubesOrderOverviewPage as OriginalLubesOrderOverviewPage } from './LubesOrderOverviewPage';

const LubesOrderOverviewPage = (props) => {
    return (
        <AppProviders>
            <OriginalLubesOrderOverviewPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(LubesOrderOverviewPage, 'reactLubesOrderOverviewPage');
