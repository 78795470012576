import { ProductCategoryLubes } from '../../../../types/ProductCategoryLubes';
import { VesselTankMessage } from '../../../../types/VesselTankMessage';
import { translate } from '../../../common/helpers/translate.helper';
import { LiteOverlay } from '../../../common/LiteOverlay/LiteOverlay';
import { Section } from '../../../common/Section/Section';
import { Box } from '../../../common/ui/Box';
import { VesselTankStocks } from '../../VesselTanks/VesselTankStocks';

export const LiteRemainingOnBoard = () => {
    const vesselTanks: VesselTankMessage[] = [
        {
            id: '1',
            name: 'Aux Eng Storage Tank',
            category: ProductCategoryLubes.AE_CIRC,
            threshold: 100,
            maxVolume: 10_000,
            latestStock: {
                quantity: 5_000,
                dateMeasured: '2023-01-01T00:00:00.000Z',
            },
        },
        {
            id: '2',
            name: 'ME Syst Oil Storage Tank',
            category: ProductCategoryLubes.ME_CIRC,
            threshold: 10_000,
            maxVolume: 50_000,
            latestStock: {
                quantity: 7_000,
                dateMeasured: '2023-01-01T00:00:00.000Z',
            },
        },
        {
            id: '3',
            name: 'Cyl Storage HS',
            category: ProductCategoryLubes.ME_CYL_HS,
            threshold: 3_000,
            maxVolume: 50_000,
            latestStock: {
                quantity: 20_000,
                dateMeasured: '2023-01-01T00:00:00.000Z',
            },
        },
        {
            id: '4',
            name: 'Cyl Storage LS 1&2',
            category: ProductCategoryLubes.ME_CYL_LS,
            threshold: 20_000,
            maxVolume: 50_000,
            latestStock: {
                quantity: 6_000,
                dateMeasured: '2023-01-01T00:00:00.000Z',
            },
        },
    ];

    return (
        <Section title={translate('vessel.remainingOnBoard.headline')} paddingX={0} paddingY={0}>
            <LiteOverlay title={translate('vessel.remainingOnBoard.headline')} variant="medium">
                <Box paddingX={7} paddingY={6}>
                    <VesselTankStocks vesselTanks={vesselTanks} />
                </Box>
            </LiteOverlay>
        </Section>
    );
};
