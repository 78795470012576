import { Select } from '../../cdl/Select/Select';
import { translate } from '../../common/helpers/translate.helper';

import { SortingKey } from './SortingKey';

interface DemandSortingSelectProps {
    value: SortingKey;
    onChange: (newValue: SortOrderOption | null) => void;
}

interface SortOrderOption {
    label: string;
    value: SortingKey;
}

export const DemandSortingSelect = (props: DemandSortingSelectProps) => {
    const options: SortOrderOption[] = [
        {
            value: SortingKey.DAYS_LEFT_SAILING,
            label: translate('demand.select.daysLeftSailing'),
        },
        {
            value: SortingKey.NEXT_KEY_PORT,
            label: translate('demand.select.nextKeyPort'),
        },
    ];

    const value = options.find((option) => option.value === props.value);

    return <Select options={options} value={value} onChange={props.onChange} />;
};
