import { useMutation, useQueryClient } from '@tanstack/react-query';

import { inviteSupplier } from '../../../common/api/clients/company.api';
import { queries } from '../../../common/api/queryKeys/queries';

export const useInviteSupplier = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: inviteSupplier,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queries.assignments.pagination._def });
        },
    });
};
