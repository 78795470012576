import { useMutation } from '@tanstack/react-query';

import { useToasts } from '../../../../cdl/Toast/useToasts';
import { createOrder, CreateOrderResponse } from '../../../../common/api/clients/order.api';
import { translate } from '../../../../common/helpers/translate.helper';
import { useNavigation } from '../../../../common/hooks/useNavigation';

export const useEnquiryCreate = () => {
    const { navigate } = useNavigation();
    const { addErrorToast } = useToasts();

    return useMutation({
        mutationFn: createOrder,
        onSuccess: (order: CreateOrderResponse) => {
            void navigate('base.orderlist-order', {
                id: order.id,
            });
        },
        onError: () => {
            addErrorToast(translate('alert.defaulterror.message'));
        },
    });
};
