import { Bar, Pie } from 'react-chartjs-2';

import { CustomerSpecificStatisticsMessage } from '../../../types/StatisticsMessage';
import { useChartTitleTranslations } from '../hooks/useChartTitleTranslations';
import { barOptions, ChartType } from '../utils/barOptions';
import { chartColors } from '../utils/chartColors';
import { pieOptions } from '../utils/pieOptions';

import { ChartGrid } from './ChartGrid';
import { ChartWrapper } from './ChartWrapper';

interface SuppliersTabStatisticsProps {
    supplierStatistics: CustomerSpecificStatisticsMessage['supplier'];
}

export const SuppliersTabStatistics = ({ supplierStatistics }: SuppliersTabStatisticsProps) => {
    const chartTitles = useChartTitleTranslations();
    const supplierNames = supplierStatistics.map((supplier) => supplier.context.name);
    const backgroundColor = chartColors;

    const completedOrdersData = {
        labels: supplierNames,
        datasets: [
            {
                label: ChartType.COMPLETED,
                data: supplierStatistics.map((supplier) => supplier.completedCount),
                backgroundColor,
            },
        ],
    };

    const pplData = {
        labels: supplierNames,
        datasets: [
            {
                label: ChartType.PPL,
                data: supplierStatistics.map((supplier) => supplier.ppl.value),
                backgroundColor,
            },
        ],
    };

    const totalData = {
        labels: supplierNames,
        datasets: [
            {
                label: ChartType.TOTAL,
                data: supplierStatistics.map((supplier) => supplier.total.value),
                backgroundColor,
            },
        ],
    };

    const volumeData = {
        labels: supplierNames,
        datasets: [
            {
                label: ChartType.VOLUME,
                data: supplierStatistics.map((supplier) => supplier.volume),
                backgroundColor,
            },
        ],
    };

    return (
        <ChartGrid>
            <ChartWrapper title={chartTitles.completedOrders}>
                <Pie data={completedOrdersData} options={pieOptions} />
            </ChartWrapper>

            <ChartWrapper title={chartTitles.pricePerUnit}>
                <Bar data={pplData} options={barOptions} />
            </ChartWrapper>

            <ChartWrapper title={chartTitles.total}>
                <Pie data={totalData} options={pieOptions} />
            </ChartWrapper>

            <ChartWrapper title={chartTitles.volume}>
                <Pie data={volumeData} options={pieOptions} />
            </ChartWrapper>
        </ChartGrid>
    );
};
