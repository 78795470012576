import { Fragment } from 'react';

import { CustomerGroupMessage } from '../../../../../types/CustomerGroupMessage';
import { Text } from '../../../../cdl/Text/Text';
import { DataSheet } from '../../../../common/DataSheet/DataSheet';
import { ExternalLink } from '../../../../common/ExternalLink/ExternalLink';
import { formatAddress } from '../../../../common/helpers/formatAddress.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useRole } from '../../../../common/hooks/useRole';
import { YesOrNo } from '../../../../common/YesOrNo/YesOrNo';

interface CustomerGroupDataSheetProps {
    customerGroup: CustomerGroupMessage;
}

export const CustomerGroupDataSheet = (props: CustomerGroupDataSheetProps) => {
    const role = useRole();

    const rows = [
        {
            label: translate('global.contact.email'),
            value: props.customerGroup.email,
            condition: !!props.customerGroup.email,
        },
        {
            label: translate('global.contact.phone'),
            value: props.customerGroup.phone,
            condition: !!props.customerGroup.phone,
        },
        {
            label: translate('global.contact.fax'),
            value: props.customerGroup.fax,
            condition: !!props.customerGroup.fax,
        },
        {
            label: translate('global.address.title'),
            value: formatAddress({ address: props.customerGroup.address }),
            condition: !!props.customerGroup.address,
        },
        {
            label: translate('company.gtcUrl'),
            value: (
                <ExternalLink href={props.customerGroup.gtcUrl} target="_blank">
                    {translate('company.gtcLink')}
                </ExternalLink>
            ),
            condition: !!props.customerGroup.gtcUrl,
        },
        {
            label: translate('company.gtcAccepted'),
            value: <YesOrNo value={props.customerGroup.gtcAccepted} />,
            condition: role.isAdmin(),
        },
    ].filter(({ condition = true }) => condition);

    return (
        <DataSheet>
            {rows.map((row, index) => (
                <Fragment key={index}>
                    <DataSheet.Label>{row.label}</DataSheet.Label>
                    <Text variant="body">{row.value}</Text>
                </Fragment>
            ))}
        </DataSheet>
    );
};
