import { CustomerSettingsMessage } from '../../../../types/CustomerSettingsMessage';
import { ProductContext } from '../../../../types/ProductContext';
import { Callout } from '../../../cdl/Callout/Callout';
import { Switch } from '../../../cdl/Switch/Switch';
import { updateCustomerSettings } from '../../../common/api/clients/companySettings.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { translate } from '../../../common/helpers/translate.helper';
import { SettingsSection } from '../../SettingsSection';
import { useOptimisticSettingsEdit } from '../../useOptimisticSettingsEdit';

interface CustomerNotificationSettingsProps {
    customerSettings: CustomerSettingsMessage;
    customerId: string;
    type: ProductContext;
}

export const CustomerNotificationSettings = ({
    customerSettings,
    customerId,
    type,
}: CustomerNotificationSettingsProps) => {
    const editCustomerSettingsMutation = useOptimisticSettingsEdit({
        updateFunction: updateCustomerSettings,
        queryKey: queryKeys.customerSettings(customerId),
    });

    const updateEmailSettings = (newSettings: Partial<CustomerSettingsMessage['emailCategorySettings']>) => {
        // @ts-ignore
        editCustomerSettingsMutation.mutate({
            id: customerId,
            settings: {
                ...customerSettings,
                emailCategorySettings: {
                    ...customerSettings.emailCategorySettings,
                    ...newSettings,
                },
            },
        });
    };

    const emailCategorySettings = customerSettings.emailCategorySettings;

    return (
        <SettingsSection label={translate('company.notificationSettings.emailNotification')}>
            {type === ProductContext.LUBES ? (
                <Callout description={translate('company.notificationSettings.emailNotificationSettingsHint')} />
            ) : null}

            <Switch
                checked={emailCategorySettings.offerStateChange}
                onChange={(checked) =>
                    updateEmailSettings({
                        offerStateChange: checked,
                    })
                }
                label={translate('company.notificationSettings.statusUpdate')}
                description={translate('company.notificationSettings.statusUpdateDescription')}
            />
            <Switch
                checked={emailCategorySettings.offerUpdate}
                onChange={(checked) =>
                    updateEmailSettings({
                        offerUpdate: checked,
                    })
                }
                label={translate('company.notificationSettings.newDetails')}
                description={translate('company.notificationSettings.newDetailsDescription')}
            />
            <Switch
                checked={emailCategorySettings.newChatMessage}
                onChange={(checked) =>
                    updateEmailSettings({
                        newChatMessage: checked,
                    })
                }
                label={translate('company.notificationSettings.newChatMessages')}
            />
            {type === ProductContext.FUEL ? (
                <Switch
                    checked={emailCategorySettings.systemReminder}
                    onChange={(checked) =>
                        updateEmailSettings({
                            systemReminder: checked,
                        })
                    }
                    label={translate('company.notificationSettings.systemReminders')}
                />
            ) : null}

            {type === ProductContext.LUBES ? (
                <>
                    <Switch
                        checked={emailCategorySettings.stockWarningLevelReminder}
                        onChange={(checked) =>
                            updateEmailSettings({
                                stockWarningLevelReminder: checked,
                            })
                        }
                        label={translate('company.notificationSettings.stockWarningLevelReminder')}
                        description={translate('company.notificationSettings.stockWarningLevelReminderDescription')}
                    />
                    <Switch
                        checked={emailCategorySettings.safetyReserveLevelReminder}
                        onChange={(checked) =>
                            updateEmailSettings({
                                safetyReserveLevelReminder: checked,
                            })
                        }
                        label={translate('company.notificationSettings.safetyReserveLevel')}
                        description={translate('company.notificationSettings.safetyReserveLevelDescription')}
                    />
                    <Switch
                        checked={emailCategorySettings.keyPortCallReminder}
                        onChange={(checked) =>
                            updateEmailSettings({
                                keyPortCallReminder: checked,
                            })
                        }
                        label={translate('company.notificationSettings.keyPortCallReminder')}
                        description={translate('company.notificationSettings.keyPortCallReminderDescription')}
                    />
                    <Switch
                        checked={emailCategorySettings.orderDeliveryReminder}
                        onChange={(checked) =>
                            updateEmailSettings({
                                orderDeliveryReminder: checked,
                            })
                        }
                        label={translate('company.notificationSettings.orderDeliveryReminder')}
                        description={translate('company.notificationSettings.orderDeliveryReminderDescription')}
                    />
                    <Switch
                        checked={emailCategorySettings.openEnquiryReminder}
                        onChange={(checked) =>
                            updateEmailSettings({
                                openEnquiryReminder: checked,
                            })
                        }
                        label={translate('company.notificationSettings.openEnquiryReminder')}
                        description={translate('company.notificationSettings.openEnquiryReminderDescription')}
                    />
                </>
            ) : null}
        </SettingsSection>
    );
};
