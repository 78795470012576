import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getSuppliers, GetSuppliersParams } from '../clients/company.api';

export const suppliers = createQueryKeys('suppliers', {
    list: (params: GetSuppliersParams) => ({
        queryKey: [{ params }],
        queryFn: () => getSuppliers(params),
    }),
});
