import { TextButton } from '../../common/buttons/TextButton';
import { translate } from '../../common/helpers/translate.helper';
import { IconChevronLeft } from '../../common/icons/cdl/ChevronLeft';
import { IconChevronRight } from '../../common/icons/cdl/ChevronRight';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';

import { Pages } from './components/Pages';

interface PaginationProps {
    currentPage: number;
    pageTotal: number;
    onPageChange: (page: number) => void;
}

export const Pagination = ({ currentPage, pageTotal, onPageChange }: PaginationProps) => {
    if (!pageTotal) {
        return null;
    }

    return (
        <Flex>
            <TextButton onClick={() => onPageChange(currentPage - 1)} disabled={currentPage <= 0}>
                <IconChevronLeft />
                {translate('global.pagination.previous')}
            </TextButton>

            <Box marginX={4}>
                <Pages currentPage={currentPage} pageTotal={pageTotal} onPageSelect={onPageChange} />
            </Box>

            <TextButton onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === pageTotal - 1}>
                {translate('global.pagination.next')}
                <IconChevronRight />
            </TextButton>
        </Flex>
    );
};
