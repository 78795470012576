import styled from 'styled-components';

import { get } from '../../common/ui/get';

interface DemandHeaderWrapperProps {
    isOpen: boolean;
    disabled: boolean;
}

export const DemandHeaderWrapper = styled.div<DemandHeaderWrapperProps>`
    background: ${(props) => (props.isOpen ? get('colors.background.subtle') : get('colors.background.default'))};
    border: 1px solid ${(props) => (props.isOpen ? get('colors.border.muted') : get('colors.border.subtle'))};
    padding: ${get('space.4')}px;
    height: 48px;
    display: grid;
    justify-items: start;
    grid-template-columns: 32px 15% 10% 20% 30% 1fr;
    grid-column-gap: ${get('space.4')}px;
    align-items: center;
    transition:
        background 75ms,
        border 75ms;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    user-select: none;

    &:hover:not([disabled]) {
        background: ${get('colors.background.subtle')};
        border-color: ${get('colors.border.muted')};
    }
`;
