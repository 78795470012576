import { useMutation } from '@tanstack/react-query';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { createOrder, CreateOrderRequest } from '../../../common/api/clients/order.api';
import { translate } from '../../../common/helpers/translate.helper';
import { useNavigation } from '../../../common/hooks/useNavigation';

export const useEnquiryCreate = () => {
    const createOrderMutation = useMutation({ mutationFn: createOrder });

    const { navigate } = useNavigation();
    const { addErrorToast } = useToasts();

    return (order: CreateOrderRequest) => {
        return createOrderMutation.mutateAsync(order, {
            onSuccess: (order: any) => {
                setTimeout(() => {
                    navigate('base.fuel-orderlist-order', {
                        id: order.id,
                    });
                });
            },
            onError: () => {
                addErrorToast(translate('fuelEnquiry.toast.createEnquiry.error'));
            },
        });
    };
};
