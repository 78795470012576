// returns an array of offsets for each layer
export const calculateDrawerOffset = (layers, amount = 100) => {
    return layers.reduceRight((offsets, layer, index) => {
        const nextLayer = layers[index + 1];
        const previousOffsetSum = offsets[0] || 0;

        if (!nextLayer) return [0];

        const offset =
            typeof nextLayer.offsetParent === 'undefined' || nextLayer.offsetParent
                ? previousOffsetSum + amount
                : previousOffsetSum;

        return [offset, ...offsets];
    }, []);
};
