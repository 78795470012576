import { Text } from '../../../cdl/Text/Text';
import { HeaderWithLine } from '../../../common/HeaderWithLine/HeaderWithLine';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { Page } from '../../../common/Page/Page';
import { DebouncedSearchTextInput } from '../../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../../common/ui/Box';

import { CreatePortButton } from './components/CreatePortButton';
import { PortsOverviewState } from './components/PortsOverviewState';
import { PortsOverviewTable } from './components/PortsOverviewTable';

export const PortsOverviewPage = () => {
    return (
        <PortsOverviewState>
            <PortsOverviewPageContent />
        </PortsOverviewState>
    );
};

const PortsOverviewPageContent = () => {
    const title = translate('ports.headline');
    useDocumentTitle(title);

    const [locationState, { setLocationFieldValue }] = useLocationState();

    return (
        <Page>
            <HeaderWithLine>
                <Text variant="headline" as="h1">
                    {translate('ports.headline')}
                </Text>

                <Box display="flex" columnGap={5} alignItems="center">
                    <DebouncedSearchTextInput
                        onChange={(searchQuery) => {
                            setLocationFieldValue('searchQuery', searchQuery);
                            setLocationFieldValue('page', 0);
                        }}
                        value={locationState.searchQuery}
                        placeholder={translate('placeholder.port')}
                    />
                    <CreatePortButton />
                </Box>
            </HeaderWithLine>

            <Box marginTop={5}>
                <PortsOverviewTable />
            </Box>
        </Page>
    );
};
