import { MouseEvent } from 'react';

import { Button } from '../../../cdl/Button/Button';
import { Modal } from '../../../cdl/Modal/Modal';
import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';

interface RestoreAgentConfirmPopupProps {
    isOpen: boolean;
    onSubmit: () => void;
    onDismiss: () => void;
    name: string;
}

export const RestoreAgentConfirmPopup = ({ isOpen, onSubmit, onDismiss, name }: RestoreAgentConfirmPopupProps) => {
    const onSubmitButton = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onSubmit();
    };

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} scrollable={false}>
            <Box padding={6}>
                <Text variant="title" as="h2" marginBottom={4}>
                    {translate('agents.restoreButton.headline')}
                </Text>

                <Text variant="body" as="p">
                    {translate('agents.restoreButton.text')}
                </Text>

                <Text variant="body" as="p" marginTop={6}>
                    {name}
                </Text>
            </Box>

            <Box display="flex" justifyContent="end" paddingX={6} paddingBottom={6}>
                <Button emphasis="high" onClick={onSubmitButton}>
                    {translate('restorePopupButton.submit')}
                </Button>
            </Box>
        </Modal>
    );
};
