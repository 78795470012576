import { OptionProps } from 'react-select';

import { CustomDoubleLineOption } from '../../../../cdl/Select/components/CustomDoubleLineOption';
import { Tag } from '../../../../cdl/Tag/Tag';
import { translate } from '../../../helpers/translate.helper';
import { useEnums } from '../../../hooks/useEnums';
import { ProductOption } from '../ProductSelect';

interface CustomProductOptionProps extends OptionProps {
    data: ProductOption;
}

export const CustomProductOption = (props: CustomProductOptionProps) => {
    const { getHumanReadableValue } = useEnums();

    const label = props.data.product.name;

    const description = `${getHumanReadableValue(
        'supplierProductGroup',
        props.data.product.supplierProductGroup
    )} (${getHumanReadableValue('productGroup', props.data.product.group)})`;

    return (
        <CustomDoubleLineOption
            {...props}
            label={label}
            description={description}
            trailingVisual={props.data.inTank ? <Tag variant="default">{translate('productSelect.inTank')}</Tag> : null}
        />
    );
};
