import { AxiosPromise } from 'axios';

import { PageMessage } from '../../../../types/PageMessage';
import { PriceListMessage } from '../../../../types/PriceListMessage';
import { ProductContext } from '../../../../types/ProductContext';
import { ProductMessage } from '../../../../types/ProductMessage';
import { ProductUnit } from '../../../../types/ProductUnit';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const getProductsByQuery = async (
    searchQuery: string,
    includeArchived: boolean = false,
    page: number = 0,
    supplierProductGroups: string[] = []
): Promise<PageMessage<ProductMessage>> => {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('searchQuery', searchQuery.trim());
    params.append('productTypes', 'LUBES');
    if (!includeArchived) {
        params.append('archived', 'false');
    }

    if (supplierProductGroups.length) {
        supplierProductGroups.forEach((supplierGroup) => {
            params.append('supplierProductGroups', supplierGroup);
        });
    }

    const response = await authorizedAxiosInstance.get(`/v1/product?${params.toString()}`);

    return response.data;
};
export const getProductsByIds = async (productIds: string[]): Promise<ProductMessage[]> => {
    const productsListRequestMessage = {
        productTypes: ['LUBES'],
        productIds: productIds,
    };

    const response = await authorizedAxiosInstance.post(`/v2/products/list`, productsListRequestMessage);

    return response.data;
};

export const getFuelProducts = async (): Promise<ProductMessage[]> => {
    const params = new URLSearchParams();
    params.append('productTypes', 'FUEL');
    params.append('archived', 'false');

    const response = await authorizedAxiosInstance.get(`/v1/product/list?${params.toString()}`);

    return response.data;
};

export interface ProductMutationMessage {
    supplierProductGroup: string;
    name: string;
    packTypeDefault: string;
    unitDefault: ProductUnit;
    group: string;
    type: ProductContext;
}

export const createProduct = async (product: ProductMutationMessage): Promise<ProductMessage> => {
    const response = await authorizedAxiosInstance.post(`/v1/product`, product);

    return response.data;
};

export const updateProduct = async ({
    product,
    id,
}: {
    product: ProductMutationMessage;
    id: string;
}): Promise<ProductMessage> => {
    const response = await authorizedAxiosInstance.put(`/v1/product/${id}`, product);

    return response.data;
};

interface UploadPriceListParams {
    file: File;
    supplierGroupId: string;
    supplierId: string;
    customerId: string;
}

export const uploadPriceList = async ({
    file,
    supplierGroupId,
    supplierId,
    customerId,
}: UploadPriceListParams): AxiosPromise<unknown> => {
    const params = new URLSearchParams();
    const formData = new FormData();
    formData.append('file', file);

    params.append('supplierGroupId', supplierGroupId);
    params.append('supplierId', supplierId);
    params.append('customerId', customerId);

    return await authorizedAxiosInstance.post(`/v1/import?${params.toString()}`, formData);
};

export interface GetPriceListsParams {
    supplierGroupId: string;
    customerId: string;
}

export const getPriceLists = async ({
    supplierGroupId,
    customerId,
}: GetPriceListsParams): Promise<PriceListMessage[]> => {
    const params = new URLSearchParams();
    params.append('supplierGroupId', supplierGroupId);
    params.append('customerId', customerId);

    const response = await authorizedAxiosInstance.get(`/v1/import`, { params });

    return response.data;
};
