import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../cdl/Toast/useToasts';
import { updateAgent } from '../common/api/clients/agent.api';
import { queries } from '../common/api/queryKeys/queries';
import { translate } from '../common/helpers/translate.helper';

export const useAgentStateChange = () => {
    const queryClient = useQueryClient();
    const { addToast, addErrorToast } = useToasts();

    return useMutation({
        mutationFn: updateAgent,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: queries.agents._def });

            if (data.active) {
                addToast(translate(`agents.restoreAgentToast.success`));
            } else {
                addToast(translate(`agents.archiveAgentToast.success`));
            }
        },
        onError: (data) => {
            if (data.active) {
                addErrorToast(translate(`agents.restoreAgentToast.error`));
            } else {
                addErrorToast(translate(`agents.archiveAgentToast.error`));
            }
        },
    });
};
