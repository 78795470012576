import { FormikErrors, setIn } from 'formik';

import { translate } from '../../../../../common/helpers/translate.helper';
import { FormikApprovalSettingsState } from '../MultipleApproversSettingsForm';

export function validateDuplicateEmailAddresses(
    errors: FormikErrors<FormikApprovalSettingsState>,
    values: FormikApprovalSettingsState
): FormikErrors<FormikApprovalSettingsState> {
    const topLevelApproverEmail = values.defaultControllerEmailAddress;
    const emailAddressToPathMap = new Map<string, string[]>();

    values.additionalApprovers.forEach((approvalLimit, approvalLimitIndex) => {
        approvalLimit.approvers.forEach((approver, index) => {
            if (approver.emailAddress === '') {
                return;
            }

            const currentPath = `additionalApprovers.${approvalLimitIndex}.approvers.${index}.emailAddress`;

            if (approver.emailAddress === topLevelApproverEmail) {
                errors = setIn(
                    errors,
                    currentPath,
                    translate('multipleApproverSettings.errors.emailAddressAlreadyUsedForTopLevelApprover')
                );
            }

            const existingPaths = emailAddressToPathMap.get(approver.emailAddress) ?? [];

            existingPaths.push(currentPath);
            emailAddressToPathMap.set(approver.emailAddress, existingPaths);
        });
    });

    emailAddressToPathMap.forEach((duplicateEmailAddressPaths) => {
        if (duplicateEmailAddressPaths.length > 1) {
            duplicateEmailAddressPaths.forEach((it) => {
                errors = setIn(errors, it, translate('multipleApproverSettings.errors.duplicateApproverEmailAddress'));
            });
        }
    });

    return errors;
}
