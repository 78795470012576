import { useField } from 'formik';
import PropTypes from 'prop-types';

import { TextInput } from '../TextInput/TextInput';

/*
 * An input that only accepts positive numbers.
 * Copy paste values will also not work.
 * Based on https://stackoverflow.com/a/46334535/3276759
 */

export const FormikPositiveNumberInput = (props) => {
    const min = props.min ?? 0;
    const [field, meta] = useField(props.name);

    const onInput = (event) => {
        return event.target.validity.valid || (event.target.value = '');
    };

    return (
        <TextInput
            {...field}
            type="number"
            min={min}
            onInput={onInput}
            highlight={!meta.touched && props.required && !field.value}
            invalid={!!meta.error}
            {...props}
        />
    );
};

FormikPositiveNumberInput.propTypes = {
    name: PropTypes.string.isRequired,
    min: PropTypes.number,
    required: PropTypes.bool,
};
