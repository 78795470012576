import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { Error } from '../../common/Error/Error';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { Flex } from '../../common/ui/Flex';
import { useAssignmentList } from '../common/hooks/useAssignmentList';

import { FuelEnquiryAssignedSupplier } from './FuelEnquiryAssignedSupplier';
import { FuelEnquiryCloselinkNetwork } from './FuelEnquiryCloselinkNetwork';

export const FuelEnquiryReceivers = () => {
    const { values, setValues } = useFormikContext();
    const {
        data: assignments,
        isPending,
        isError,
    } = useAssignmentList(values.customerId, values.portId, values.testOrder);

    const confirmedAssignments = assignments?.filter((assignment) => assignment.status === 'CONFIRMED');

    useEffect(() => {
        if (!assignments) {
            return;
        }

        if (!confirmedAssignments?.length) {
            setValues({
                ...values,
                assignedSupplierIds: [],
                receiverSupplierIds: [],
                spot: true,
            });
            return;
        }

        const assignmentsSupplierIds = confirmedAssignments?.map((assignment) => assignment.supplierId);

        if (values.assignedSupplierIds) {
            const cleanedReceiverSupplierIds = values.receiverSupplierIds.filter((supplierId) =>
                assignmentsSupplierIds.includes(supplierId)
            );
            setValues({
                ...values,
                assignedSupplierIds: assignmentsSupplierIds,
                receiverSupplierIds: cleanedReceiverSupplierIds,
            });
            return;
        }

        setValues({
            ...values,
            assignedSupplierIds: assignmentsSupplierIds,
            receiverSupplierIds: assignmentsSupplierIds,
            spot: false,
        });
    }, [assignments]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isPending) {
        return (
            <Flex width="50%" justifyContent="center">
                <LoadingIndicator />
            </Flex>
        );
    }

    if (isError) {
        return (
            <Flex width="50%" justifyContent="center">
                <Error />
            </Flex>
        );
    }

    return (
        <ButtonGroup>
            {values.assignedSupplierIds?.length ? (
                <FuelEnquiryAssignedSupplier assignments={confirmedAssignments} />
            ) : null}
            <FuelEnquiryCloselinkNetwork />
        </ButtonGroup>
    );
};
