import * as Sentry from '@sentry/browser';

ExceptionHandler.$inject = ['$log'];
function ExceptionHandler($log) {
    return function (exception) {
        Sentry.captureException(exception);
        $log.error(exception);
    };
}

export default {
    name: '$exceptionHandler',
    fn: ExceptionHandler,
};
