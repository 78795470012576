export const selectSortOption = (tab) => {
    switch (tab) {
        case 'enquired': {
            return {
                sortField: 'validUntil',
                sortDirection: 'ASC',
            };
        }
        case 'offered': {
            return {
                sortField: 'quoteValidUntil',
                sortDirection: 'ASC',
            };
        }
        default: {
            return {
                sortField: 'dateUpdated',
                sortDirection: 'DESC',
            };
        }
    }
};
