import { Text } from '../../cdl/Text/Text';
import { translate } from '../helpers/translate.helper';
import { IconMoodSad } from '../icons/cdl/MoodSad';
import { Box } from '../ui/Box';

export const Error = () => {
    return (
        <Box color="dark-blue.1">
            <IconMoodSad width={32} height={32} style={{ margin: '0 auto' }} />
            <Text marginTop={4} size={14} textAlign="center" as="h5" color="dark-blue.2">
                {translate('global.genericError')}
            </Text>
        </Box>
    );
};
