import moment from 'moment';

import { formatDateTime } from './formatDateTime.helper';

export const getUTCTimeFromDate = (item: { eta: string; timeZoneId: string }) =>
    moment
        .utc(formatDateTime({ date: item.eta, timeZoneId: item.timeZoneId }))
        .startOf('day')
        .toDate()
        .getTime();
