import { useState } from 'react';

import { UserStatus } from '../../../types/UserStatus';
import { Button } from '../../cdl/Button/Button';
import { Text } from '../../cdl/Text/Text';
import { useToasts } from '../../cdl/Toast/useToasts';
import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { translate } from '../../common/helpers/translate.helper';
import { useAngularRouterLocation } from '../../common/hooks/useAngularRouterLocation';
import { useRole } from '../../common/hooks/useRole';
import { Page } from '../../common/Page/Page';
import { PageGrid } from '../../common/PageGrid';
import { Box } from '../../common/ui/Box';

import { AdminUserDataSheet } from './components/AdminUserDataSheet';
import { EditUserButton } from './components/EditUserButton';
import { SetNewPasswordPopup } from './components/SetNewPasswordPopup';
import { UserDataSheet } from './components/UserDataSheet';
import { useUser } from './hooks/useUser';
import { useUserInvitationResend } from './hooks/useUserInvitationResend';

export const UserDetailPage = () => {
    const role = useRole();
    const { id } = useAngularRouterLocation<{ id: string }>();
    const { addErrorToast, addToast } = useToasts();
    const [isNewPasswordPopupOpen, setIsNewPasswordPopupOpen] = useState<boolean>(false);
    const resendInvitationMutation = useUserInvitationResend();

    const userUserQuery = useUser(id);

    const resendInvite = () => {
        return resendInvitationMutation.mutate(id, {
            onSuccess: () => addToast(translate('user.resendInvitationToasts.success')),
            onError: () => addErrorToast(translate('user.resendInvitationToasts.error')),
        });
    };

    return (
        <Page>
            <PageGrid>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Text as="h1" variant="headline">
                        {translate('user.userDetails')}
                    </Text>

                    <ButtonGroup>
                        {role.user.id === id ? (
                            <Button color={'outline-dark'} onClick={() => setIsNewPasswordPopupOpen(true)}>
                                {translate('user.resetpassword')}
                            </Button>
                        ) : null}

                        {role.isAdmin() && userUserQuery.data?.status === UserStatus.INVITED ? (
                            <Button color={'outline-dark'} onClick={resendInvite}>
                                {translate('user.resendInvitation')}
                            </Button>
                        ) : null}

                        {userUserQuery.data ? <EditUserButton user={userUserQuery.data} /> : null}
                    </ButtonGroup>
                </Box>

                <SetNewPasswordPopup
                    user={role.user}
                    isOpen={isNewPasswordPopupOpen}
                    onDismiss={() => setIsNewPasswordPopupOpen(false)}
                />

                {role.isAdmin() ? <AdminUserDataSheet /> : <UserDataSheet />}
            </PageGrid>
        </Page>
    );
};
