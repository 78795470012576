import { useQuery } from '@tanstack/react-query';

import { getPortDirectory } from '../api/clients/additionalData.api';
import { queryKeys } from '../api/queryKeys';

export const usePortDirectoryDetails = ({ portId, supplierGroupId }) => {
    const thirtyMinutes = 30 * 60 * 1000;

    return useQuery({
        queryKey: queryKeys.portDirectories({ portId, supplierGroupId }),
        queryFn: () => getPortDirectory({ portId, supplierGroupId }),
        staleTime: thirtyMinutes,
        retry: (_, error) => {
            return error.response.status !== 404;
        },
        refetchOnWindowFocus: false,
    });
};
