import { CSSProperties, Fragment, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { get } from '../ui/get';

const DataSheetGrid = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: min-content 1fr;
    grid-column-gap: ${get('space.6')}px;
    grid-row-gap: ${get('space.4')}px;
`;

interface DataSheetProps {
    children: ReactNode;
}

export const DataSheet = (props: DataSheetProps) => {
    return <DataSheetGrid {...props} />;
};

interface DataSheetLabelProps {
    children: string;
}

const DataSheetLabel = (props: DataSheetLabelProps) => {
    return <Text style={{ whiteSpace: 'nowrap' }} color="foreground.muted" variant="fieldLabel" {...props} />;
};

DataSheet.Label = DataSheetLabel;

interface DataSheetHeadingProps {
    children: string;
    marginTop?: CSSProperties['marginTop'];
}

const DataSheetHeading = (props: DataSheetHeadingProps) => {
    return (
        <Text variant="subtitle2" style={{ whiteSpace: 'nowrap', gridColumn: '1 / 3', marginTop: props.marginTop }}>
            {props.children}
        </Text>
    );
};

DataSheet.Heading = DataSheetHeading;

interface DataSheetSkeletonProps {
    rows: number;
}

export const DataSheetSkeleton = ({ rows }: DataSheetSkeletonProps) => {
    return (
        <DataSheet>
            {Array.from(Array(rows)).map((_, index) => {
                return (
                    <Fragment key={index}>
                        <Skeleton width="100px" />
                        <Skeleton width="250px" />
                    </Fragment>
                );
            })}
        </DataSheet>
    );
};

DataSheet.Skeleton = DataSheetSkeleton;

interface DataSheetRowProps {
    label: string;
    value: string | undefined | null;
}

const DataSheetRow = ({ label, value }: DataSheetRowProps) => {
    if (value === undefined) {
        return null;
    }
    return (
        <>
            <DataSheet.Label>{label}</DataSheet.Label>
            <Text>{value}</Text>
        </>
    );
};

DataSheet.Row = DataSheetRow;
