import { AxiosPromise, AxiosResponse } from 'axios';

import { BunkerStopsCsvRequestMessage } from '../../../../types/BunkerStopsCsvRequestMessage';
import { BunkerStopMessage, BunkerStopsMessage } from '../../../../types/BunkerStopsMessage';
import { BunkerStopsRequestMessage } from '../../../../types/BunkerStopsRequestMessage';
import { BunkerStop } from '../../../../types/DemandInformationMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';
import { authorizedGatewayAxiosInstance } from '../instances/AuthorizedGatewayAxiosInstance';

export const deleteBunkerStop = async ({ bunkerStopId }: { bunkerStopId: string }): Promise<void> => {
    return authorizedAxiosInstance.delete(`/v1/bunker-stop/${bunkerStopId}`);
};

interface ShareBunkerStopParams {
    bunkerStopId: string;
    receiverEmailAddresses: string[];
    copyToSelf?: boolean;
    receiverType: 'VESSEL' | 'SUPPLIER';
}

export const shareBunkerStop = async ({
    bunkerStopId,
    receiverEmailAddresses,
    copyToSelf,
    receiverType,
}: ShareBunkerStopParams): Promise<void> => {
    return authorizedAxiosInstance.post(`/v1/bunker-stop/${bunkerStopId}/share`, {
        receiverEmailAddresses,
        copyToSelf,
        receiverType,
    });
};

export const createBunkerStops = async ({
    bunkerStops,
}: {
    bunkerStops: BunkerStopsRequestMessage;
}): AxiosPromise<{ bunkerStops: BunkerStop[] }> => {
    return authorizedAxiosInstance.post(`/v1/bunker-stop/bulk`, bunkerStops);
};

export const getBunkerStops = async (): Promise<BunkerStopsMessage> => {
    const response = await authorizedGatewayAxiosInstance.get(`/v1/bunker-stop`);

    return response.data;
};

export const getBunkerStopsCsv = async (
    bunkerStopsCsvRequestMessage: BunkerStopsCsvRequestMessage
): Promise<AxiosResponse> => {
    return authorizedAxiosInstance.post('/v1/bunker-report/export/csv', bunkerStopsCsvRequestMessage);
};

export const createBunkerStopEnquiry = async ({
    bunkerStopId,
}: {
    bunkerStopId: string;
}): Promise<BunkerStopMessage> => {
    const response = await authorizedAxiosInstance.post(`/v1/bunker-stop/${bunkerStopId}/draft-enquiry`);
    return response.data;
};
