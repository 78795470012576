import PropTypes from 'prop-types';

import { Switch } from '../../../cdl/Switch/Switch';
import { useFormData } from '../Form/useFormData';

export const FormSwitch = ({ dataPath, label, ...props }) => {
    const { value, onChange } = useFormData(dataPath);

    const handleChange = (checked) => {
        onChange({ target: { value: checked } });
    };

    return <Switch label={label} checked={value} onChange={handleChange} {...props} />;
};

FormSwitch.propTypes = {
    label: PropTypes.node,
    dataPath: PropTypes.string,
    description: PropTypes.string,
};
