import { ProductGroupMessage } from '../../../../types/ContractMessage';
import { Tooltip } from '../../../cdl/Tooltip/Tooltip';
import { translate } from '../../../common/helpers/translate.helper';
import { IconAlertTriangle } from '../../../common/icons/cdl/AlertTriangle';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex';
import { theme } from '../../../common/ui/theme';
import { ProductListing } from '../../ContractOverview/ProductListing';
import { checkProductGroupValidity, checkTenderProductGroupValidity } from '../validateContract';

interface ProductDisplayProps {
    contract: any;
    productGroup: ProductGroupMessage;
}

export const ProductDisplay = ({ contract, productGroup }: ProductDisplayProps) => {
    const isInvalidProductGroup = contract.tender.enabled
        ? !checkTenderProductGroupValidity(contract, productGroup)
        : !checkProductGroupValidity(contract, productGroup);

    return (
        <Flex alignItems="center">
            <ProductListing productGroups={[productGroup]} />
            {isInvalidProductGroup ? (
                <Box height={20} width={20} marginX={4}>
                    <Tooltip label={translate('contracts.invalidProductGroup')}>
                        <div>
                            <IconAlertTriangle color={theme.colors.negative.foreground} />
                        </div>
                    </Tooltip>
                </Box>
            ) : null}
        </Flex>
    );
};
