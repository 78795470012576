import { ReactNode } from 'react';

export type Layer = {
    content: ReactNode;
    width?: `${number}${'vw' | 'px'}`;
    offsetParent?: boolean;
    onPop?: (data?: Layer) => void;
};

export type LayerAction = { type: 'push'; data: Layer } | { type: 'pop'; data?: Layer };

export type LayerStoreState = {
    layers: Layer[];
};

export const layerStoreReducer = (state: LayerStoreState, action: LayerAction) => {
    switch (action.type) {
        case 'push':
            return {
                ...state,
                layers: [
                    ...state.layers,
                    {
                        content: action.data?.content,
                        width: action.data?.width,
                        offsetParent: action.data?.offsetParent,
                        onPop: action.data?.onPop,
                    },
                ],
            };
        case 'pop': {
            const lastLayer = state.layers.slice(-1)[0];

            if (state.layers.length === 0) {
                // was invoked wihtout any layers left.
                return {
                    ...state,
                    layers: [],
                };
            }

            if (lastLayer.onPop) {
                lastLayer.onPop(action.data);
            }

            return {
                ...state,
                layers: state.layers.slice(0, -1),
            };
        }
    }
};
