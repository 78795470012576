import css from '@styled-system/css';
import styled from 'styled-components';

import { Box } from '../../../common/ui/Box';

export const ScheduleWrapper = styled(Box)(
    css({
        '.swiper-container': {
            overflow: 'visible',
        },
    })
);
