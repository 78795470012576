import { useField } from 'formik';
import { SingleValue } from 'react-select';

import { EnumOptionSelect, EnumOptionSelectOption, EnumOptionSelectProps } from '../../enums/EnumOptionSelect';

interface FormikEnumOptionSelectProps extends EnumOptionSelectProps {
    name: string;
    onOptionChange?: (option?: string) => void;
}

export const FormikEnumOptionSelect = (
    props: Omit<FormikEnumOptionSelectProps, 'value' | 'onChange' | 'negative' | 'onBlur'>
) => {
    const [field, meta, helpers] = useField(props.name);

    const onChange = (option: SingleValue<EnumOptionSelectOption>) => {
        if (field.value !== option?.value) {
            props.onOptionChange?.(option?.value);
        }

        if (!option) {
            helpers.setValue(null);
        } else {
            helpers.setValue(option?.value);
        }
    };

    return (
        <EnumOptionSelect
            {...props}
            {...field}
            value={field.value}
            onChange={onChange}
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : props.caption}
            onBlur={() => {
                helpers.setTouched(true);
            }}
        />
    );
};
