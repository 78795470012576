import { EmptyPagePlaceholder } from '../../../common/EmptyPagePlaceholder/EmptyPagePlaceholder';
import { translate } from '../../../common/helpers/translate.helper';
import { IconFilter } from '../../../common/icons/cdl/Filter';
import { Box } from '../../../common/ui/Box';
import { theme } from '../../../common/ui/theme';

export const NoFilterResults = () => {
    return (
        <Box marginTop="124px" display="flex" justifyContent="center">
            <EmptyPagePlaceholder
                title={translate('global.emptyFilterResult.noMatchingResults')}
                description={translate('global.emptyFilterResult.tryDifferentFilter')}
                icon={<IconFilter width={24} height={24} color={theme.colors.foreground.subtle} />}
            />
        </Box>
    );
};
