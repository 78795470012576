import { Toaster } from 'react-hot-toast';

export const ToastsProvider = () => {
    return (
        <Toaster
            position="bottom-center"
            toastOptions={{ duration: 3000 }}
            containerStyle={{
                top: 24,
                left: 24,
                bottom: 24,
                right: 24,
            }}
        />
    );
};
