import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { AssignmentsPage } from './AssignmentsPage';

const AssignmentsPageComponent = (props: any) => {
    return (
        <AppProviders>
            <AssignmentsPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(AssignmentsPageComponent, 'reactAssignmentsPage');
