import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AssignmentMessage } from '../../../../types/AssignmentMessage';
import { updateAssignment } from '../../../common/api/clients/company.api';
import { queries } from '../../../common/api/queryKeys/queries';

export const useAssignmentUpdate = (assignmentId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (assignment: AssignmentMessage) => updateAssignment(assignmentId, assignment),

        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queries.assignments._def });
        },
    });
};
