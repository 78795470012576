import PropTypes from 'prop-types';

import { Text } from '../../../../cdl/Text/Text';
import { Box } from '../../../../common/ui/Box';

export const LabelValueInformation = ({ label, value }) => {
    return (
        <Box>
            <Text weight="bold">{label}:</Text>
            <Text marginLeft={2}>{value}</Text>
        </Box>
    );
};

LabelValueInformation.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.node.isRequired,
};
