import { Error } from '../../common/Error/Error';
import { useProductContext } from '../../common/hooks/useProductContext';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../common/LocationState/useLocationState';
import { useCustomerStatistics } from '../hooks/useCustomerStatistics';

import { CustomerStatisticsContent } from './CustomerStatisticsContent';
import { CustomerStatisticsTabSwitch } from './CustomerStatisticsTabSwitch';
import { NoStatistics } from './NoStatistics';
import { StatisticsCenteredFrame } from './StatisticsCenteredFrame';
import { StatisticsStateConfig } from './StatisticsState';

export const CustomerStatistics = () => {
    const { context } = useProductContext();
    const [locationStateUntyped] = useLocationState();
    const locationState = locationStateUntyped as StatisticsStateConfig;

    const customerStatisticsQuery = useCustomerStatistics({
        from: locationState.from,
        to: locationState.to,
        customerIds: locationState.customerIds,
        type: context,
    });

    if (customerStatisticsQuery.isPending) {
        return (
            <div>
                <CustomerStatisticsTabSwitch />
                <StatisticsCenteredFrame>
                    <LoadingIndicator />
                </StatisticsCenteredFrame>
            </div>
        );
    }

    if (customerStatisticsQuery.isError) {
        return (
            <StatisticsCenteredFrame>
                <Error />
            </StatisticsCenteredFrame>
        );
    }

    if (customerStatisticsQuery.data?.general.completedCount === 0) {
        return (
            <div>
                <CustomerStatisticsTabSwitch />
                <StatisticsCenteredFrame>
                    <NoStatistics />
                </StatisticsCenteredFrame>
            </div>
        );
    }

    return (
        <div>
            <CustomerStatisticsTabSwitch />
            <CustomerStatisticsContent statistics={customerStatisticsQuery.data} />
        </div>
    );
};
