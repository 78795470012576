import { PortMessage } from './PortMessage';
import { VesselMessage } from './VesselMessage';

export interface BunkerStopsMessage {
    bunkerStops: BunkerStopMessage[];
    contractRecommendations: ContractRecommendationMessage[];
}

export enum BunkerStopState {
    OPEN = 'OPEN',
    DRAFT = 'DRAFT',
    ENQUIRY = 'ENQUIRY',
    OFFER_CHOSEN = 'OFFER_CHOSEN',
    DELIVERED = 'DELIVERED',
}

export interface BunkerStopMessage {
    id: string;
    customerId: string;
    portId: string;
    vesselId: string;
    eta: string;
    etd: string;
    items: BunkerStopItemMessage[];
    orderId?: string;
    offerId?: string;
    state: BunkerStopState;

    port: PortMessage;
    vessel: VesselMessage;
}

interface ContractRecommendationMessage {
    portId: string;

    port: PortMessage;
}

interface BunkerStopItemMessage {
    productGroup: string;
    sulphurContent: string;
    volume: number;
}
