import { useToasts } from '../../../../cdl/Toast/useToasts';
import { translate } from '../../../../common/helpers/translate.helper';
import { useProductContext } from '../../../../common/hooks/useProductContext';
import { useRole } from '../../../../common/hooks/useRole';
import { useSupplierSettingsEdit } from '../../../../company/supplier/detail/useSupplierSettingsEdit';
import { useSupplierCreate } from '../hooks/useSupplierCreate';

import { CreateSupplierCompanyForm, CreateSupplierCompanyFormProps } from './CreateSupplierCompanyForm';

interface CreateSupplierCompanyProps {
    groupId: string;
    onSuccess: () => void;
    isTestGroup: boolean;
}

export const CreateSupplierCompany = ({ groupId, onSuccess, isTestGroup }: CreateSupplierCompanyProps) => {
    const { possibleContexts } = useProductContext();
    const { addErrorToast } = useToasts();
    const role = useRole();

    const supplierCreateMutation = useSupplierCreate();
    const supplierSettingsMutation = useSupplierSettingsEdit();

    const handleSubmit: CreateSupplierCompanyFormProps['onSubmit'] = async (values) => {
        const supplierCompanyMessage = {
            supplierGroupId: groupId,
            type: values.type || possibleContexts[0],
            name: values.name,
            email: values.email,
            phone: values.phone || undefined,
            fax: values.fax || undefined,
            address: {
                city: values.address.city || undefined,
                companyName: values.address.companyName || undefined,
                country: values.address.country || undefined,
                street: values.address.street || undefined,
                streetNumber: values.address.streetNumber || undefined,
                zipcode: values.address.zipcode || undefined,
            },
            testCompany: role.isAdmin() ? values.testCompany : isTestGroup,
            showGtc: role.isAdmin() ? values.showGtc : false,
            trial: role.isAdmin() ? values.trial : false,
        };

        const supplierCompanySettingsMessage = {
            emailCategorySettings: {
                newChatMessage: false,
                offerStateChange: true,
                offerUpdate: true,
                systemReminder: true,
            },
            id: groupId,
            messageSettings: {
                oderConfirmationMessage: values.messageSettings.oderConfirmationMessage || undefined,
                quotationMessage: values.messageSettings.quotationMessage || undefined,
            },
        };
        try {
            await supplierCreateMutation.mutateAsync(supplierCompanyMessage);
            await supplierSettingsMutation.mutateAsync({ id: groupId, settings: supplierCompanySettingsMessage });
            onSuccess();
        } catch {
            addErrorToast(translate('supplier.createErrorToast'));
        }
    };
    return <CreateSupplierCompanyForm onSubmit={handleSubmit} />;
};
