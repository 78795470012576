import { useEffect, useRef } from 'react';

export const useScrollToBottom = ({ dependencies }) => {
    const ref = useRef(null);

    useEffect(() => {
        ref.current?.scroll({
            top: ref.current.scrollHeight,
            behavior: 'smooth',
        });
    }, [dependencies]);

    return ref;
};
