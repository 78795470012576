import { AxiosPromise } from 'axios';

import { AssignmentMessage } from '../../../../types/AssignmentMessage';
import { AssignmentRequestMessage } from '../../../../types/AssignmentRequestMessage';
import { CreateCustomerRequestMessage } from '../../../../types/CreateCustomerRequestMessage';
import { CreateSupplierRequestMessage } from '../../../../types/CreateSupplierRequestMessage';
import { CustomerCompanyMessage } from '../../../../types/CustomerCompanyMessage';
import { InviteSupplierRequestMessage } from '../../../../types/InviteSupplierRequestMessage';
import { SupplierCompanyMessage } from '../../../../types/SupplierCompanyMessage';
import { SupplierGroupMessage } from '../../../../types/SupplierGroupMessage';
import { SupplierMessage } from '../../../../types/SupplierMessage';
import { SupplierSettingsMessage } from '../../../../types/SupplierSettingsMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export interface GetCustomersParams {
    searchQuery?: string;
    ids?: string[] | string;
    groupId?: string;
    type?: string;
}

export const getCustomers = async ({
    searchQuery,
    ids,
    groupId,
    type,
}: GetCustomersParams): Promise<CustomerCompanyMessage[]> => {
    const params = new URLSearchParams();

    if (searchQuery) {
        params.append('searchQuery', searchQuery.trim());
    }

    if (ids) {
        if (Array.isArray(ids)) {
            ids.forEach((it) => params.append('ids', it));
        } else {
            params.append('ids', ids);
        }
    }

    if (groupId) {
        params.append('groupId', groupId);
    }

    if (type) {
        params.append('types', type);
    }

    const response = await authorizedAxiosInstance.get(`/v1/customer/list?${params.toString()}`);

    return response.data;
};

export interface GetCustomerGroupsParams {
    searchQuery?: string;
    ids?: string[] | string;
}
export const getCustomerGroups = async ({ searchQuery, ids }: GetCustomerGroupsParams): Promise<any> => {
    const params = new URLSearchParams();

    if (searchQuery) {
        params.append('searchQuery', searchQuery.trim());
    }

    if (ids) {
        if (Array.isArray(ids)) {
            ids.forEach((it) => params.append('ids', it));
        } else {
            params.append('ids', ids);
        }
    }

    const response = await authorizedAxiosInstance.get(`/v1/customergroup/list?${params.toString()}`);

    return response.data;
};

export interface GetSupplierGroupsParams {
    searchQuery?: string;
    ids?: string[] | string;
}
export const getSupplierGroups = async ({
    searchQuery,
    ids,
}: GetSupplierGroupsParams): Promise<SupplierGroupMessage[]> => {
    const params = new URLSearchParams();

    if (searchQuery) {
        params.append('searchQuery', searchQuery.trim());
    }

    if (ids) {
        if (Array.isArray(ids)) {
            ids.forEach((it) => params.append('ids', it));
        } else {
            params.append('ids', ids);
        }
    }

    const response = await authorizedAxiosInstance.get(`/v1/suppliergroup/list?${params.toString()}`);

    return response.data;
};

export interface GetSuppliersParams {
    searchQuery?: string;
    ids?: string[] | string;
    groupId?: string;
    type?: string;
}
export const getSuppliers = async ({
    searchQuery,
    ids,
    groupId,
    type,
}: GetSuppliersParams): Promise<SupplierMessage[]> => {
    const params = new URLSearchParams();

    if (searchQuery) {
        params.append('searchQuery', searchQuery.trim());
    }

    if (ids) {
        if (Array.isArray(ids)) {
            ids.forEach((it) => params.append('ids', it));
        } else {
            params.append('ids', ids);
        }
    }

    if (groupId) {
        params.append('groupId', groupId);
    }

    if (type) {
        params.append('types', type);
    }

    const response = await authorizedAxiosInstance.get(`/v1/supplier/list?${params.toString()}`);

    return response.data;
};

export const getCustomerDetails = async (id: string): Promise<any> => {
    const response = await authorizedAxiosInstance.get(`/v1/customer/${id}`);

    return response.data;
};

interface UpdateCustomerDetailsParams {
    id: string;
    customerData: unknown;
}
export const updateCustomerDetails = async ({ id, customerData }: UpdateCustomerDetailsParams): Promise<any> => {
    const response = await authorizedAxiosInstance.put(`/v1/customer/${id}`, customerData);

    return response.data;
};

export const getSupplierDetails = async (id: string): Promise<SupplierMessage> => {
    const response = await authorizedAxiosInstance.get(`/v1/supplier/${id}`);

    return response.data;
};

interface UpdateSupplierDetailsParams {
    id: string;
    supplierData: unknown;
}
export const updateSupplierDetails = async ({ id, supplierData }: UpdateSupplierDetailsParams): Promise<any> => {
    const response = await authorizedAxiosInstance.put(`/v1/supplier/${id}`, supplierData);

    return response.data;
};

export const getSupplierSettings = async (id: string): Promise<SupplierSettingsMessage> => {
    const response = await authorizedAxiosInstance.get(`/v1/suppliers/${id}/settings`);

    return response.data;
};

export const updateSupplierSettings = async ({
    id,
    settings,
}: {
    id: string;
    settings: SupplierSettingsMessage;
}): Promise<any> => {
    const response = await authorizedAxiosInstance.put(`/v1/suppliers/${id}/settings`, settings);

    return response.data;
};

export const createCustomer = async (customerData: CreateCustomerRequestMessage): Promise<CustomerCompanyMessage> => {
    const response = await authorizedAxiosInstance.post(`/v1/customer`, customerData);

    return response.data;
};

export const createSupplier = async (supplierData: CreateSupplierRequestMessage): Promise<SupplierCompanyMessage> => {
    const response = await authorizedAxiosInstance.post(`/v1/supplier`, supplierData);

    return response.data;
};

export const inviteSupplier = async (assignment: InviteSupplierRequestMessage): Promise<void> => {
    await authorizedAxiosInstance.post(`/v1/assignment`, assignment);
};

export const createAssignment = async (assignment: AssignmentRequestMessage): Promise<AssignmentMessage> => {
    const response = await authorizedAxiosInstance.post('/v1/assignment', assignment);

    return response.data;
};

export const updateAssignment = (
    assignmentId: string,
    assignment: AssignmentMessage
): AxiosPromise<AssignmentMessage> => {
    return authorizedAxiosInstance.put(`/v1/assignment/${assignmentId}`, assignment);
};

export const deleteAssignment = (assignmentId: string): AxiosPromise<void> => {
    return authorizedAxiosInstance.delete(`/v1/assignment/${assignmentId}`);
};
