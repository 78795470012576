import PropTypes from 'prop-types';

import { DrawerFormLayout } from '../cdl/Drawer/DrawerFormLayout';
import { Text } from '../cdl/Text/Text';
import { FormCheckbox } from '../common/form-elements/Checkbox/Checkbox';
import { Form } from '../common/form-elements/Form/Form';
import { translate } from '../common/helpers/translate.helper';

export const AdminSettingsForm = ({ onSubmit, companyDetails, companyType }) => {
    return (
        <Form height="100%" data={companyDetails} onSubmit={onSubmit}>
            <DrawerFormLayout title={translate('customers.details.editAdminSettings')}>
                <FormCheckbox label={translate('company.testCompany')} dataPath="testCompany" />

                {companyType === 'CUSTOMER' ? (
                    <FormCheckbox label={translate('company.spotallowed')} dataPath="spotAllowed" />
                ) : null}

                {companyType === 'SUPPLIER' ? (
                    <FormCheckbox label={translate('company.showGtc')} dataPath="showGtc" />
                ) : null}

                <FormCheckbox label={translate('company.trial')} dataPath="trial" />

                <Text as="h2" variant="subtitle1" paddingTop={6}>
                    {translate('global.mailsettings.title')}
                </Text>

                <FormCheckbox label={translate('global.mailsettings.newsletter')} dataPath="mailSettings.newsletter" />

                <FormCheckbox
                    label={translate('global.mailsettings.transactional')}
                    dataPath="mailSettings.transactional"
                />
            </DrawerFormLayout>
        </Form>
    );
};

AdminSettingsForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    companyDetails: PropTypes.object.isRequired,
    companyType: PropTypes.string.isRequired,
};
