import { ProductMessage } from '../../../../../types/ProductMessage';
import { Text } from '../../../../cdl/Text/Text';
import { formatFuelMoney } from '../../../../common/helpers/formatFuelMoney.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { translateSulphurContent } from '../../../../common/helpers/translateSulphurContent';
import { OfferModel } from '../../../../offer/model/OfferModel';

import { PricesMap } from './PricesMap';

interface OfferProductSummaryProps {
    offer: OfferModel;
}

export const OfferProductSummary = ({ offer }: OfferProductSummaryProps) => {
    const activeItems = offer.items.filter((item) => !item.deleted);
    const activeSurcharges = offer.surcharges
        .filter((surcharge) => surcharge.value?.value >= 0)
        .filter((surcharge) => !surcharge.deleted);

    const makeProductString = (product: ProductMessage): string => {
        const translatedSulphurContent = translateSulphurContent(product.sulphurContent);

        return `${product.group} ${translatedSulphurContent}`;
    };

    const priceInformationRow = activeItems.map((item) => {
        const showPrice = (offer.isOrder() || offer.isQuotedEnquiry()) && item.price;

        return {
            label: makeProductString(item.product!!),
            value: showPrice
                ? formatFuelMoney({
                      value: item.price!!.value,
                      currency: item.price!!.currency,
                  })
                : '-',
        };
    });

    if (activeSurcharges.length) {
        const surchargesSum = activeSurcharges.reduce((acc, surcharge) => acc + (surcharge.value?.value ?? 0), 0);

        priceInformationRow.push({
            label: translate('order.detail.surcharges'),
            value: formatFuelMoney({
                value: surchargesSum,
                currency: activeSurcharges[0].value?.currency,
            }),
        });
    }

    const maxItems = priceInformationRow.length > 3 ? priceInformationRow.slice(0, 2) : priceInformationRow;

    return (
        <div>
            <PricesMap items={maxItems} />
            {activeItems.length > maxItems.length ? (
                <Text as="p" variant="extraSmall" color="accent.foreground" marginTop="4px">
                    {translate('order.detail.showMore')}
                </Text>
            ) : null}
        </div>
    );
};
