import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createCustomer } from '../../../../common/api/clients/company.api';
import { queries } from '../../../../common/api/queryKeys/queries';
import { useAngularDependencies } from '../../../../common/hooks/useAngularDependencies';

export const useCustomerCreate = () => {
    const queryClient = useQueryClient();
    const { SessionService } = useAngularDependencies();

    return useMutation({
        mutationFn: createCustomer,
        onSuccess: async () => {
            await SessionService.renewSession();
            return queryClient.invalidateQueries({ queryKey: queries.customers._def });
        },
    });
};
