import { CenteredPagination } from '../cdl/Pagination/CenteredPagination';
import { Error } from '../common/Error/Error.tsx';
import { formatDate } from '../common/helpers/formatDate.helper';
import { translate } from '../common/helpers/translate.helper';
import { useRole } from '../common/hooks/useRole';
import { useUpdateEffect } from '../common/hooks/useUpdateEffect';
import { IconHeadset } from '../common/icons/cdl/Headset';
import { LoadingIndicator } from '../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../common/LocationState/useLocationState';
import { PortListing } from '../common/PortListing/PortListing';
import { TableEmptyState } from '../common/TableEmptyState/TableEmptyState';
import { TabSwitch } from '../common/TabSwitch/TabSwitch';

import { useAgentPagination } from './useAgentPagination';
import { useAgentPaginationTotals } from './useAgentPaginationTotals';
import { useNavigation } from '../common/hooks/useNavigation';
import { TableBuilderColumn } from '../cdl/TableBuilder/TableBuilderColumn';
import { TableBuilder } from '../cdl/TableBuilder/TableBuilder';
import { formatCompanyName } from '../common/helpers/formatCompanyName.helper';
import { useProductContext } from '../common/hooks/useProductContext';
import { AgentTableActions } from './components/AgentTableActions';
import { Box } from '../common/ui/Box';

export const AgentsOverviewTable = () => {
    const role = useRole();
    const { context } = useProductContext();
    const [locationState, { setLocationFieldValue }] = useLocationState();
    const { navigate } = useNavigation();

    const active = locationState.tab === 'active';

    const { data: agentTotals } = useAgentPaginationTotals({
        searchQuery: locationState.searchQuery,
        customerIds: locationState.customerIds,
        portIds: locationState.portIds,
    });

    const { data, pageTotal, isPending, isError } = useAgentPagination({
        page: locationState.page,
        searchQuery: locationState.searchQuery,
        customerIds: locationState.customerIds,
        portIds: locationState.portIds,
        active,
    });

    useUpdateEffect(() => {
        setLocationFieldValue('page', 0);
    }, [locationState.searchQuery, locationState.customerIds, locationState.portIds, locationState.tab]);

    const totals = {
        active: agentTotals?.active,
        archived: agentTotals?.archived,
    };

    if (isPending) {
        return (
            <div>
                <TabSwitch
                    selectedContext={locationState.tab}
                    onSelect={(tab) => setLocationFieldValue('tab', tab)}
                    totals={totals}
                />
                <LoadingIndicator />
            </div>
        );
    }

    if (isError) {
        return (
            <div>
                <TabSwitch
                    selectedContext={locationState.tab}
                    onSelect={(tab) => setLocationFieldValue('tab', tab)}
                    totals={totals}
                />
                <Error />
            </div>
        );
    }

    if (!data.content?.length) {
        return (
            <div>
                <TabSwitch
                    selectedContext={locationState.tab}
                    onSelect={(tab) => setLocationFieldValue('tab', tab)}
                    totals={totals}
                />
                <TableEmptyState Icon={IconHeadset} text={translate('agents.emptyList')} />
            </div>
        );
    }

    const handleRowClick = (agent) => {
        navigate('base.agents-agent', {
            id: agent.id,
        });
    };

    return (
        <div>
            <TabSwitch
                selectedContext={locationState.tab}
                onSelect={(tab) => setLocationFieldValue('tab', tab)}
                totals={totals}
            />
            <TableBuilder data={data.content} onRowSelect={handleRowClick}>
                <TableBuilderColumn header={translate('agents.name')} width="25%">
                    {(agent) => agent.name}
                </TableBuilderColumn>
                {role.isAdmin() || !role.isOneCompanyUser(context) ? (
                    <TableBuilderColumn header={translate('agents.company')} width="20%">
                        {(agent) =>
                            agent.customer
                                ? formatCompanyName({
                                      company: agent.customer,
                                      includeType: role.isAdmin(),
                                  })
                                : ''
                        }
                    </TableBuilderColumn>
                ) : null}
                <TableBuilderColumn header={translate('agents.ports')} width="20%">
                    {(agent) => (agent.ports.length ? <PortListing ports={agent.ports} /> : '')}
                </TableBuilderColumn>
                <TableBuilderColumn header={translate('agents.dateCreated')} width="10%">
                    {(agent) => formatDate({ date: agent.dateCreated })}
                </TableBuilderColumn>
                <TableBuilderColumn header={translate('agents.lastEdit')} width="10%">
                    {(agent) => formatDate({ date: agent.dateUpdated })}
                </TableBuilderColumn>
                {role.hasAnyCompanyWriteRights() ? (
                    <TableBuilderColumn header="" width="5%" interactive>
                        {(agent) =>
                            role.hasWriteRights(agent.customerId) ? (
                                <Box display="flex" flexWrap="nowrap" justifyContent="flex-end">
                                    <AgentTableActions agent={agent} />
                                </Box>
                            ) : (
                                ''
                            )
                        }
                    </TableBuilderColumn>
                ) : null}
            </TableBuilder>
            <CenteredPagination
                pageTotal={pageTotal}
                currentPage={locationState.page}
                onPageChange={(page) => setLocationFieldValue('page', page)}
            />
        </div>
    );
};
