import { CustomerCompanyMessage } from '../../../../../types/CustomerCompanyMessage';
import { TableBuilder } from '../../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../../cdl/TableBuilder/TableBuilderColumn';
import { Error } from '../../../../common/Error/Error';
import { formatCompanyName } from '../../../../common/helpers/formatCompanyName.helper';
import { formatDateTime } from '../../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useNavigation } from '../../../../common/hooks/useNavigation';
import { useRole } from '../../../../common/hooks/useRole';
import { IconCheck } from '../../../../common/icons/cdl/Check';
import { LoadingIndicator } from '../../../../common/LoadingIndicator/LoadingIndicator';
import { TableEmptyState } from '../../../../common/TableEmptyState/TableEmptyState';
import { useCustomerList } from '../hooks/useCustomerList';

interface CustomersTableProps {
    groupId: string;
}

export const CustomersTable = (props: CustomersTableProps) => {
    const customersQuery = useCustomerList({ groupId: props.groupId });
    const { navigate } = useNavigation();
    const role = useRole();

    if (customersQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (customersQuery.isError) {
        return <Error />;
    }

    if (!customersQuery.data.length) {
        return <TableEmptyState text={translate('customers.emptylist')} />;
    }

    const reversedCustomers = [...customersQuery.data].reverse();

    return (
        <TableBuilder
            data={reversedCustomers}
            onRowSelect={(row: CustomerCompanyMessage) => {
                void navigate('base.customer-groups-customer-group-customer', { id: row.id });
            }}
        >
            <TableBuilderColumn<CustomerCompanyMessage> header={translate('customer.name')} width="30%">
                {(row) => formatCompanyName({ company: row, includeType: role.isAdmin() })}
            </TableBuilderColumn>
            <TableBuilderColumn<CustomerCompanyMessage> header={translate('customer.mail')}>
                {(row) => row.email}
            </TableBuilderColumn>
            <TableBuilderColumn<CustomerCompanyMessage> header={translate('customer.datecreated')}>
                {(row) => formatDateTime({ date: row.dateCreated })}
            </TableBuilderColumn>
            {role.isAdmin() ? (
                <TableBuilderColumn<CustomerCompanyMessage> header={translate('customer.testcompany')}>
                    {(row) => (row.testCompany ? <IconCheck width={16} height={16} /> : null)}
                </TableBuilderColumn>
            ) : null}
        </TableBuilder>
    );
};
