import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { LocationStateProvider } from '../../common/LocationState/LocationStateProvider';
import { Page } from '../../common/Page/Page';
import { Box } from '../../common/ui/Box';

import { AssignmentsActions } from './components/AssignmentsActions';
import { AssignmentsTable } from './components/AssignmentsTable';

export interface AssignmentsPageStateConfig {
    page: number;
    supplierIds: string[];
    customerIds: string[];
}

export const AssignmentsPage = () => {
    useDocumentTitle(translate('page.assigmentoverview'));

    const stateConfig: AssignmentsPageStateConfig = {
        page: 0,
        supplierIds: [],
        customerIds: [],
    };
    return (
        <LocationStateProvider stateConfig={stateConfig}>
            <Page>
                <Box display="flex" flexDirection="row" justifyContent="space-between" gap={5}>
                    <Text variant="headline">{translate('assignments.title')}</Text>

                    <AssignmentsActions />
                </Box>
                <AssignmentsTable />
            </Page>
        </LocationStateProvider>
    );
};
