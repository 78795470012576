import PropTypes from 'prop-types';
import { useState } from 'react';
import styled from 'styled-components';

import { IconX } from '../../icons/cdl/X';
import { Box } from '../../ui/Box.tsx';
import { RoundedButton } from '../../ui/RoundedButton';
import { TextInput } from '../TextInput/TextInput';

const HorizontalStack = styled(Box)`
    > * {
        margin-right: 8px;
        margin-bottom: 8px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

/**
 * @deprecated use TagListInput instead
 **/

export const MultipleEntriesInput = (props) => {
    const [inputValue, setInputValue] = useState('');

    const onKeyPress = (event) => {
        if (event.key !== 'Enter') {
            return false;
        }

        if (!inputValue.match(props.pattern)) {
            event.preventDefault();
            return false;
        }

        const newEntries = [...props.entries, inputValue];
        props.onEntriesChanged(newEntries);

        setInputValue('');

        event.preventDefault();
        return false;
    };

    const removeEntry = (index) => () => {
        const copyOfEntries = [...props.entries];
        copyOfEntries.splice(index, 1);

        props.onEntriesChanged(copyOfEntries);
    };

    return (
        <Box>
            <TextInput
                label={props.inputLabel}
                placeholder={props.inputPlaceholder}
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
                onKeyPress={onKeyPress}
                pattern={props.pattern}
            />

            {props.entries?.length ? (
                <HorizontalStack marginTop={5}>
                    {props.entries.map((entry, index) => {
                        return (
                            <RoundedButton key={index} onClick={removeEntry(index)}>
                                {entry}
                                <IconX style={{ marginRight: '-4px' }} />
                            </RoundedButton>
                        );
                    })}
                </HorizontalStack>
            ) : null}
        </Box>
    );
};

MultipleEntriesInput.propTypes = {
    inputLabel: PropTypes.string.isRequired,
    inputPlaceholder: PropTypes.string.isRequired,
    pattern: PropTypes.string.isRequired,
    entries: PropTypes.array.isRequired,
    onEntriesChanged: PropTypes.func.isRequired,
};
