import PropTypes from 'prop-types';

import { StatusBar } from '../../../cdl/StatusBar/StatusBar';
import { translate } from '../../../common/helpers/translate.helper';
import { GridItem } from '../../../common/ui/Grid';
import { OrderModel } from '../../model/OrderModel';

interface OrderStatusSectionProps {
    order: OrderModel;
}

export const OrderStatusSection = ({ order }: OrderStatusSectionProps) => {
    if (order.state === 'CANCELED') {
        return (
            <GridItem>
                <StatusBar
                    variant="attention"
                    headline={translate('fuelOrder.states.enquiryCanceled.headline')}
                    subHeadline={translate('fuelOrder.states.enquiryCanceled.subHeadline')}
                />
            </GridItem>
        );
    }

    if (order.isClosed()) {
        return (
            <GridItem>
                <StatusBar
                    variant="positive"
                    headline={translate('fuelOrder.states.enquiryOrdered.headline')}
                    subHeadline={translate('fuelOrder.states.enquiryOrdered.subHeadline')}
                />
            </GridItem>
        );
    }

    return null;
};

OrderStatusSection.propTypes = {
    order: PropTypes.object.isRequired,
};
