import { MoneyMessage } from '../../../../types/MoneyMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export interface PackagePriceRequestMessage {
    portId: string;
    customerId: string;
    supplierGroupId: string;
    packType: string;
    productId: string;
}

interface PackagePriceResponseMessage {
    id?: string;
    price?: MoneyMessage;
    supplierGroupId: string;
    dateCreated: number;
    productId: string;
    customerId: string;
    packType: string;
    dateUpdated?: number;
    portId: string;
}

export const calculatePackagePrices = async (
    customerId: string,
    packagePricesRequest: PackagePriceRequestMessage[]
): Promise<PackagePriceResponseMessage[]> => {
    const response = await authorizedAxiosInstance.post(`/v1/price?customerId=${customerId}`, packagePricesRequest);

    return response.data;
};
