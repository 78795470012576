import { ApprovalRequestState } from '../../../../types/ApprovalRequestState';
import { StatusBar } from '../../../cdl/StatusBar/StatusBar';
import { translate } from '../../../common/helpers/translate.helper';
import { CircleValidity } from '../../../common/Validity/CircleValidity';
import { OfferModel } from '../../model/OfferModel';
import { OfferState } from '../../model/OfferState';

import { FuelAdminCounterOfferExpiredStatusBar } from './FuelAdminCounterOfferExpiredStatusBar';

interface FuelAdminOfferDetailStatusBarProps {
    offer: OfferModel;
}

export const FuelAdminOfferDetailStatusBar = ({ offer }: FuelAdminOfferDetailStatusBarProps) => {
    switch (offer.state) {
        case OfferState.ACKNOWLEDGED: {
            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.admin.fuel.acknowledged.headline')}
                    subHeadline={translate('statusbar.admin.fuel.acknowledged.subHeadline')}
                />
            );
        }
        case OfferState.CANCELED: {
            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="attention"
                        headline={translate('statusbar.admin.fuel.canceledContract.headline')}
                        subHeadline={translate('statusbar.admin.fuel.canceledContract.subHeadline')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.admin.fuel.canceled.headline')}
                    subHeadline={translate('statusbar.admin.fuel.canceled.subHeadline')}
                />
            );
        }
        case OfferState.CONFIRMED: {
            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.admin.fuel.confirmedContract.headline')}
                        subHeadline={translate('statusbar.admin.fuel.confirmedContract.subHeadline')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.admin.fuel.confirmed.headline')}
                    subHeadline={translate('statusbar.admin.fuel.confirmed.subHeadline')}
                />
            );
        }
        case OfferState.DELIVERED: {
            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="positive"
                        headline={translate('statusbar.admin.fuel.deliveredContract.headline')}
                        subHeadline={translate('statusbar.admin.fuel.deliveredContract.subHeadline')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="positive"
                    headline={translate('statusbar.admin.fuel.delivered.headline')}
                    subHeadline={translate('statusbar.admin.fuel.delivered.subHeadline')}
                />
            );
        }
        case OfferState.ENQUIRY: {
            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.admin.fuel.enquiry.headline')}
                    subHeadline={translate('statusbar.admin.fuel.enquiry.subHeadline')}
                    trailingVisual={
                        <>
                            {!!offer.validUntil && !!offer.validityTime ? (
                                <CircleValidity
                                    validUntil={offer.validUntil}
                                    validityTime={offer.validityTime}
                                    background="transparent"
                                />
                            ) : null}
                        </>
                    }
                />
            );
        }
        case OfferState.ENQUIRY_CANCELED: {
            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.admin.fuel.enquiryCanceled.headline')}
                    subHeadline={translate('statusbar.admin.fuel.enquiryCanceled.subHeadline')}
                />
            );
        }
        case OfferState.ENQUIRY_DECLINED: {
            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.admin.fuel.enquiryDeclined.headline')}
                    subHeadline={translate('statusbar.admin.fuel.enquiryDeclined.subHeadline')}
                />
            );
        }
        case OfferState.ENQUIRY_EXPIRED: {
            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.admin.fuel.enquiryExpired.headline')}
                    subHeadline={translate('statusbar.admin.fuel.enquiryExpired.subHeadline')}
                />
            );
        }
        case OfferState.INVOICED: {
            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="positive"
                        headline={translate('statusbar.admin.fuel.invoicedContract.headline')}
                        subHeadline={translate('statusbar.admin.fuel.invoicedContract.subHeadline')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="positive"
                    headline={translate('statusbar.admin.fuel.invoiced.headline')}
                    subHeadline={translate('statusbar.admin.fuel.invoiced.subHeadline')}
                />
            );
        }
        case OfferState.ORDER: {
            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.admin.fuel.orderContract.headline')}
                        subHeadline={translate('statusbar.admin.fuel.orderContract.subHeadline')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.admin.fuel.order.headline')}
                    subHeadline={translate('statusbar.admin.fuel.order.subHeadline')}
                />
            );
        }
        case OfferState.QUOTE_CANCELED: {
            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="attention"
                        headline={translate('statusbar.admin.fuel.quoteCanceledContract.headline')}
                        subHeadline={translate('statusbar.admin.fuel.quoteCanceledContract.subHeadline')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.admin.fuel.quoteCanceled.headline')}
                    subHeadline={translate('statusbar.admin.fuel.quoteCanceled.subHeadline')}
                />
            );
        }
        case OfferState.QUOTE_DECLINED: {
            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.admin.fuel.quoteDeclined.headline')}
                    subHeadline={translate('statusbar.admin.fuel.quoteDeclined.subHeadline')}
                />
            );
        }
        case OfferState.QUOTED: {
            if (offer.counterOffers.length) {
                const latestCounterOffer = offer.counterOffers[0];

                switch (latestCounterOffer.state) {
                    case 'ACCEPTED': {
                        return (
                            <StatusBar
                                variant="neutral"
                                headline={translate('statusbar.admin.fuel.counterOfferAccepted.headline')}
                                subHeadline={translate('statusbar.admin.fuel.counterOfferAccepted.subHeadline')}
                            />
                        );
                    }
                    case 'EXPIRED':
                    case 'OPEN': {
                        return <FuelAdminCounterOfferExpiredStatusBar latestCounterOffer={latestCounterOffer} />;
                    }
                }
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.admin.fuel.quoted.headline')}
                    subHeadline={translate('statusbar.admin.fuel.quoted.subHeadline')}
                    trailingVisual={
                        <>
                            {!!offer.quoteValidUntil && !!offer.quoteValidityTime ? (
                                <CircleValidity
                                    validUntil={offer.quoteValidUntil}
                                    validityTime={offer.quoteValidityTime}
                                    background="transparent"
                                />
                            ) : null}
                        </>
                    }
                />
            );
        }
        case OfferState.CUSTOMER_ADJUSTED: {
            const latestApprovalRequestState = offer.approvalRequests?.[0]?.state;

            if (latestApprovalRequestState === ApprovalRequestState.PENDING) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.admin.fuel.customerAdjustedApprovalPending.headline')}
                        subHeadline={translate('statusbar.admin.fuel.customerAdjustedApprovalPending.subHeadline')}
                    />
                );
            }

            if (latestApprovalRequestState === ApprovalRequestState.DECLINED) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.admin.fuel.customerAdjustedApprovalDeclined.headline')}
                        subHeadline={translate('statusbar.admin.fuel.customerAdjustedApprovalDeclined.subHeadline')}
                    />
                );
            }

            if (latestApprovalRequestState === ApprovalRequestState.EXPIRED) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.admin.fuel.customerAdjustedApprovalExpired.headline')}
                        subHeadline={translate('statusbar.admin.fuel.customerAdjustedApprovalExpired.subHeadline')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.admin.fuel.customerAdjusted.headline')}
                    subHeadline={translate('statusbar.admin.fuel.customerAdjusted.subHeadline')}
                />
            );
        }
        case OfferState.SUPPLIER_ADJUSTED: {
            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.admin.fuel.customerAdjustedApprovalPending.headline')}
                    subHeadline={translate('statusbar.admin.fuel.customerAdjustedApprovalPending.subHeadline')}
                />
            );
        }
    }

    return null;
};
