import { ReactNode } from 'react';
import styled from 'styled-components';

import { Text } from '../cdl/Text/Text';
import { get } from '../common/ui/get';
import { theme } from '../common/ui/theme';

const SectionWrapper = styled.div`
    margin-bottom: ${get('space.7')}px;
    &:last-child {
        margin-bottom: 0;
    }
`;

const ContentWrapper = styled.div`
    display: grid;
    margin-top: ${get('space.5')}px;
    grid-row-gap: ${get('space.6')}px;
`;

interface SettingsSectionProps {
    label: ReactNode;
    children: ReactNode;
}

export const SettingsSection = (props: SettingsSectionProps) => {
    return (
        <SectionWrapper>
            <Text weight="medium" size={14} color={theme.colors.foreground.muted}>
                {props.label}
            </Text>
            <ContentWrapper>{props.children}</ContentWrapper>
        </SectionWrapper>
    );
};
