import { LubesProductGroup } from '../../../../types/LubesProductGroup';
import { PriceCompareLubesMessage } from '../../../../types/PriceCompareLubesMessage';
import { ProductCategoryLubes } from '../../../../types/ProductCategoryLubes';
import { ProductContext } from '../../../../types/ProductContext';
import { ProductUnit } from '../../../../types/ProductUnit';
import { SchedulePriceInformation } from '../hooks/useEnhancedSchedule';

const createPrice = (category: ProductCategoryLubes, portId: string): PriceCompareLubesMessage => {
    return {
        bestAlternative: false,
        bestAlternativeDifference: {
            currency: 'USD',
            value: 0.0,
        },
        bestPriceCategory: false,
        bestPriceCategoryDefault: false,
        category,
        defaultSupplier: false,
        portId,
        price: {
            currency: 'USD',
            value: 2.979,
        },
        productId: 'productId-1',
        product: {
            id: 'productId-1',
            dateCreated: 123,
            dateUpdated: 123,
            supplierProductGroup: 'EXXONMOBIL',
            publicId: 'L-123',
            name: 'TBD',
            packTypeDefault: 'BULK',
            unitDefault: ProductUnit.L,
            group: LubesProductGroup.ANCILLARY_OIL,
            type: ProductContext.LUBES,
            archived: false,
        },
        supplierGroupId: 'supplierGroupId-1',
    };
};

const ports = ['portId-1', 'portId-2', 'portId-3', 'portId-4', 'portId-5'];
const categories = [
    ProductCategoryLubes.AE_CIRC,
    ProductCategoryLubes.ME_CIRC,
    ProductCategoryLubes.ME_CYL_HS,
    ProductCategoryLubes.ME_CYL_LS,
];

const prices = categories.flatMap((category) => {
    return ports.map((port) => {
        return createPrice(category, port);
    });
});

export const liteData: SchedulePriceInformation<PriceCompareLubesMessage> = {
    schedules: [
        {
            id: '1',
            imo: '123',
            locode: 'GYGEO',
            eta: '2023-03-07T15:00:00.000+0000',
            etd: '2023-03-08T23:00:00.000+0000',
            customerGroupId: '1',
            port: {
                id: 'portId-1',
                locCode: 'GYGEO',
                name: 'Georgetown (Guyana)',
                country: {
                    id: 'countryId-1',
                    name: 'TBD',
                    code: 'TBD',
                    aliases: [],
                    region: 'EUROPE',
                },
                latitude: 0,
                longitude: 0,
                aliases: [],
                locCodeAliases: [],
                timeZoneId: 'Europe/Berlin',
            },
        },
        {
            id: '2',
            imo: '123',
            locode: 'SRPBM',
            eta: '2023-03-13T01:00:00.000+0000',
            etd: '2023-03-13T23:00:00.000+0000',
            customerGroupId: '1',
            port: {
                id: 'portId-2',
                locCode: 'SRPBM',
                name: 'Paramaribo',
                country: {
                    id: 'countryId-2',
                    name: 'TBD',
                    code: 'TBD',
                    aliases: [],
                    region: 'EUROPE',
                },
                latitude: 0,
                longitude: 0,
                aliases: [],
                locCodeAliases: [],
                timeZoneId: 'Europe/Berlin',
            },
        },
        {
            id: '3',
            imo: '123',
            locode: 'BRVIX',
            eta: '2023-03-13T01:00:00.000+0000',
            etd: '2023-03-13T23:00:00.000+0000',
            customerGroupId: '1',
            port: {
                id: 'portId-3',
                locCode: 'BRVIX',
                name: 'Vitoria',
                country: {
                    id: 'countryId-2',
                    name: 'TBD',
                    code: 'TBD',
                    aliases: [],
                    region: 'EUROPE',
                },
                latitude: 0,
                longitude: 0,
                aliases: [],
                locCodeAliases: [],
                timeZoneId: 'Europe/Berlin',
            },
        },
        {
            id: '4',
            imo: '123',
            locode: 'GYGEO',
            eta: '2023-03-13T01:00:00.000+0000',
            etd: '2023-03-13T23:00:00.000+0000',
            customerGroupId: '1',
            port: {
                id: 'portId-1',
                locCode: 'GYGEO',
                name: 'Georgetown (Guyana)',
                country: {
                    id: 'countryId-1',
                    name: 'TBD',
                    code: 'TBD',
                    aliases: [],
                    region: 'EUROPE',
                },
                latitude: 0,
                longitude: 0,
                aliases: [],
                locCodeAliases: [],
                timeZoneId: 'Europe/Berlin',
            },
        },
        {
            id: '5',
            imo: '123',
            locode: 'USBAL',
            eta: '2023-03-13T01:00:00.000+0000',
            etd: '2023-03-13T23:00:00.000+0000',
            customerGroupId: '1',
            port: {
                id: 'portId-4',
                locCode: 'USBAL',
                name: 'Baltimore (MD)',
                country: {
                    id: 'countryId-2',
                    name: 'TBD',
                    code: 'TBD',
                    aliases: [],
                    region: 'EUROPE',
                },
                latitude: 0,
                longitude: 0,
                aliases: [],
                locCodeAliases: [],
                timeZoneId: 'Europe/Berlin',
            },
        },
    ],
    prices,
    reachInformation: [],
};
