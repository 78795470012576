import { VesselMessage } from '../../../../types/VesselMessage';
import { useRole } from '../../../common/hooks/useRole';

import { LiteLubesScheduleSection } from './LiteLubesScheduleSection';
import { RegularLubesScheduleSection } from './RegularLubesScheduleSection';

interface LubesScheduleSectionProps {
    vessel: VesselMessage;
}

export const LubesScheduleSection = (props: LubesScheduleSectionProps) => {
    const role = useRole();

    if (role.usesCloselinkLite()) {
        return <LiteLubesScheduleSection />;
    }

    return <RegularLubesScheduleSection vessel={props.vessel} />;
};
