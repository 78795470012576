import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { PriceMessage } from '../../../types/LubesPricesMessage';
import { GetLubesPricesParams } from '../../common/api/clients/gateway.api';
import { queries } from '../../common/api/queryKeys/queries';

interface PriceMap {
    [portId: string]: {
        [supplierId: string]: PriceMessage;
    };
}

const makePriceMap = (prices: PriceMessage[]) => {
    const map: PriceMap = {};
    prices.forEach((price) => {
        if (!map[price.portId]) {
            map[price.portId] = {};
        }
        if (!map[price.portId][price.supplierId]) {
            map[price.portId][price.supplierId] = price;
        }
    });

    return map;
};

interface UseMappedPriceCompareConfig extends GetLubesPricesParams {
    enabled: boolean;
}

export const useMappedPriceCompare = ({ enabled, ...params }: UseMappedPriceCompareConfig) => {
    const { data, isPending, isFetching } = useQuery({
        ...queries.priceCompare.lubes(params),
        enabled,
        placeholderData: keepPreviousData,
        staleTime: Infinity,
    });

    if (!data) {
        return {
            isPending,
            isFetching,
            priceMap: null,
        };
    }

    return {
        isPending,
        isFetching,
        priceMap: makePriceMap(data.prices),
    };
};
