import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { Offer } from '../../../model/offer.model';
import { PaymentTerms } from '../../../PaymentTerms';

export const FormikPaymentTerms = (props) => {
    const { values, setValues } = useFormikContext();

    const onChange = ({ days, reference }) => {
        setValues(
            new Offer({
                ...values,
                paymentTermReference: reference,
                paymentTermReferenceDays: days,
            })
        );
    };

    return (
        <PaymentTerms
            onChange={onChange}
            days={values.paymentTermReferenceDays}
            reference={values.paymentTermReference}
            label={props.label}
            required={props.required}
        />
    );
};

FormikPaymentTerms.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
};
