import { FieldArray, FieldArrayRenderProps, FormikErrors, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import * as uuid from 'uuid';

import { ConsumptionCalculationBase } from '../../../../types/ConumptionCalculationBase';
import { Accordion } from '../../../cdl/Accordion/Accordion';
import { Button } from '../../../cdl/Button/Button';
import { Divider } from '../../../common/Divider/Divider';
import { translate } from '../../../common/helpers/translate.helper';
import { useEnums } from '../../../common/hooks/useEnums';
import { IconPlus } from '../../../common/icons/cdl/Plus';
import { Box } from '../../../common/ui/Box';

import { FormikCategoryPresetState, FormikEditPresetsState } from './EditPresetsForm';
import { PresetSummary } from './PresetSummary';
import { SinglePresetForm } from './SinglePresetForm';

export const PresetsAccordion = () => {
    const { values, errors, isSubmitting } = useFormikContext<FormikEditPresetsState>();
    useEffect(() => {
        if (isSubmitting) {
            const typedErrors: FormikErrors<FormikCategoryPresetState[]> | undefined = errors.presets as FormikErrors<
                FormikCategoryPresetState[]
            >;
            const firstError = typedErrors?.findIndex((e) => (e ? Object.keys(e).length > 0 : false));
            if (firstError) {
                setExpandedItem(values.presets[firstError]?.id);
            }
        }
    }, [errors, values, isSubmitting]);

    const emptyPreset = (): FormikCategoryPresetState => ({
        id: uuid.v4(),
        vesselTankCategory: undefined,
        consumptionCalculationBase: ConsumptionCalculationBase.DYNAMIC,
        excludePortCalls: false,
        fixedAverageDailyConsumption: undefined,
    });

    const { getHumanReadableValue } = useEnums();
    const categoryTitle = (category: string | undefined) =>
        category
            ? getHumanReadableValue('vesselTankCategory', category)
            : translate('consumption.editPresets.categoryPlaceholder');

    const [expandedItem, setExpandedItem] = useState(values.presets[0]?.id);

    const addItem = (arrayHelpers: FieldArrayRenderProps) => {
        const newPreset = emptyPreset();
        arrayHelpers.push({ ...newPreset });
        setExpandedItem(newPreset.id);
    };

    return (
        <FieldArray
            name="presets"
            render={(arrayHelpers) => {
                return (
                    <>
                        <Accordion value={expandedItem} onValueChange={setExpandedItem}>
                            {values.presets.map((preset, index) => (
                                <Accordion.Item
                                    id={preset.id}
                                    key={index}
                                    title={categoryTitle(preset.vesselTankCategory)}
                                    summary={<PresetSummary preset={preset}></PresetSummary>}
                                    onRemove={() => arrayHelpers.remove(index)}
                                >
                                    <Box width="50%">
                                        <SinglePresetForm
                                            index={index}
                                            isDynamic={
                                                preset.consumptionCalculationBase === ConsumptionCalculationBase.DYNAMIC
                                            }
                                        />
                                    </Box>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                        <Divider marginBottom={5} />
                        <div>
                            <Button
                                type="button"
                                leadingVisual={<IconPlus height={16} width={16} />}
                                onClick={() => addItem(arrayHelpers)}
                            >
                                {translate('consumption.editPresets.addCategory')}
                            </Button>
                        </div>
                    </>
                );
            }}
        />
    );
};
