import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { createQuote, CreateQuoteRequestMessage } from '../common/api/clients/one-port.api';

export const useOnePortQuote = () => {
    return useMutation<void, AxiosError<{ reason: string }>, CreateQuoteRequestMessage>({
        mutationFn: createQuote,
    });
};
