import { useField } from 'formik';

import { Input, InputProps } from './Input';

export interface FormikInputProps extends InputProps {
    name: string;
}

export const FormikInput = (props: FormikInputProps) => {
    const [field, meta] = useField(props.name);

    return (
        <Input
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error && typeof meta.error === 'string' ? meta.error : props.caption}
            {...props}
            {...field}
        />
    );
};
