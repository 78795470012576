import { Formik, FormikErrors } from 'formik';

import {
    EditSupplierGroupRequestMessage,
    SupplierGroupMessage,
    SupplierGroupTypes,
} from '../../../../../types/SupplierGroupMessage';
import { SupplierGroupBaseForm } from '../../../../admin/suppliergroup/components/SupplierGroupBaseForm';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { translate } from '../../../../common/helpers/translate.helper';
import { useSupplierGroupUpdate } from '../hooks/useSupplierGroupUpdate';

export interface FormikEditSupplierGroupState {
    id: string;
    name: string;
    email: string;
    description: string;
    gtcUrl: string;
    headquarter: string;
    testCompany: boolean;
    emailQuote: boolean;
    anonymousQuote: boolean;
    spotAllowedLubes: boolean;
    spotSupplierIdLubes: string;
    availabilityCountryCodesLubes: string[];
    spotAllowedFuel: boolean;
    spotSupplierIdFuel: string;
    availabilityCountryCodesFuel: string[];
    supplierProductGroups?: string[];
    translationSupplierProductGroup?: string;
    type: SupplierGroupTypes;
    phone: string;
    fax: string;
    address: {
        companyName: string;
        street: string;
        streetNumber: string;
        zipcode: string;
        city: string;
        country: string;
    };
}

interface EditSupplierGroupFormProps {
    onSuccess: () => void;
    initialValues: SupplierGroupMessage;
}

export const EditSupplierGroupForm = ({ onSuccess, initialValues }: EditSupplierGroupFormProps) => {
    const { addErrorToast, addToast } = useToasts();
    const updateSupplierGroupMutation = useSupplierGroupUpdate();

    const initialFormikState: FormikEditSupplierGroupState = {
        id: initialValues.id,
        name: initialValues.name,
        email: initialValues.email || '',
        description: initialValues.description || '',
        gtcUrl: initialValues.gtcUrl || '',
        headquarter: initialValues.headquarter || '',
        testCompany: initialValues.testCompany,
        emailQuote: initialValues.emailQuote,
        anonymousQuote: initialValues.anonymousQuote,
        spotAllowedLubes: initialValues.lubesSettings.spotAllowed || false,
        spotSupplierIdLubes: initialValues.lubesSettings.spotSupplierId || '',
        availabilityCountryCodesLubes: initialValues.lubesSettings.availabilityCountryCodes || [],
        spotAllowedFuel: initialValues.fuelSettings.spotAllowed || false,
        spotSupplierIdFuel: initialValues.fuelSettings.spotSupplierId || '',
        availabilityCountryCodesFuel: initialValues.fuelSettings.availabilityCountryCodes || [],
        supplierProductGroups: initialValues.supplierProductGroups?.length ? initialValues.supplierProductGroups : [],
        translationSupplierProductGroup: initialValues.translationSupplierProductGroup,
        type: initialValues.type,
        phone: initialValues.phone || '',
        fax: initialValues.fax || '',
        address: {
            companyName: initialValues.address?.companyName || '',
            street: initialValues.address?.street || '',
            streetNumber: initialValues.address?.streetNumber || '',
            zipcode: initialValues.address?.zipcode || '',
            city: initialValues.address?.city || '',
            country: initialValues.address?.country || '',
        },
    };

    const validate = (values: FormikEditSupplierGroupState) => {
        const errors: FormikErrors<FormikEditSupplierGroupState> = {};

        if (!values.name || values.name.trim().length === 0) {
            errors.name = translate('company.error.name');
        }

        if (!values.type) {
            errors.type = translate('company.error.supplierType');
        }

        return errors;
    };

    const handleSubmit = (values: FormikEditSupplierGroupState) => {
        const supplierGroup: EditSupplierGroupRequestMessage = {
            ...initialValues,
            name: values.name,
            email: values.email || undefined,
            description: values.description || undefined,
            phone: values.phone || undefined,
            fax: values.fax || undefined,
            address: {
                companyName: values.address.companyName || undefined,
                street: values.address.street || undefined,
                streetNumber: values.address.streetNumber || undefined,
                zipcode: values.address.zipcode || undefined,
                city: values.address.city || undefined,
                country: values.address.country || undefined,
            },
            testCompany: values.testCompany,
            gtcUrl: values.gtcUrl || undefined,
            headquarter: values.headquarter || undefined,
            emailQuote: values.emailQuote,
            anonymousQuote: values.anonymousQuote,
            lubesSettings: {
                spotAllowed: values.spotAllowedLubes,
                availabilityCountryCodes: values.availabilityCountryCodesLubes,
                spotSupplierId: values.spotSupplierIdLubes || undefined,
            },
            fuelSettings: {
                spotAllowed: values.spotAllowedFuel,
                availabilityCountryCodes: values.availabilityCountryCodesFuel,
                spotSupplierId: values.spotSupplierIdFuel || undefined,
            },
            supplierProductGroups: values.supplierProductGroups || undefined,
            translationSupplierProductGroup: values.translationSupplierProductGroup || undefined,
            type: values.type,
        };

        updateSupplierGroupMutation.mutate(supplierGroup, {
            onSuccess: () => {
                onSuccess();
                addToast(translate('suppliergroup.toasts.updateSupplierGroupSuccess'));
            },
            onError: () => {
                addErrorToast(translate('suppliergroup.toasts.updateSupplierGroupError'));
            },
        });
    };

    return (
        <Formik initialValues={initialFormikState} onSubmit={handleSubmit} validate={validate} validateOnMount>
            <SupplierGroupBaseForm title={translate('suppliergroup.edit')} showSpotAllowedInputs />
        </Formik>
    );
};
