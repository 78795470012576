import styled from 'styled-components';
import { borderRadius, BorderRadiusProps } from 'styled-system';

export const BlurBackground = styled.div<BorderRadiusProps>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid transparent;
    background: rgba(255, 255, 255, 0.5);
    z-index: 1;
    backdrop-filter: blur(6px);
    user-select: none;
    transition:
        border-color 150ms ease-out,
        box-shadow 150ms ease-out;

    ${borderRadius};
`;
