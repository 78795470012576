import { BudgetMessage } from '../../../../types/BudgetMessage';
import { Text } from '../../../cdl/Text/Text';
import { BudgetDonutChart } from '../../../common/BudgetDonutChart/BudgetDonutChart';
import { translate } from '../../../common/helpers/translate.helper';
import { Grid, GridItem } from '../../../common/ui/Grid';

import { BudgetCaption } from './BudgetCaption';
import { BudgetDonutChartDisplay } from './BudgetDonutChartDisplay';

interface BudgetInformationProps {
    budgetData: BudgetMessage;
}

export const BudgetInformation = (props: BudgetInformationProps) => {
    return (
        <div>
            <Grid>
                <GridItem width="40%" display="flex" justifyContent="center" alignItems="center">
                    <BudgetDonutChart
                        spentBudget={props.budgetData.spentBudget.amount}
                        budget={props.budgetData.budget.amount}
                    >
                        <BudgetDonutChartDisplay budgetData={props.budgetData} />
                    </BudgetDonutChart>
                </GridItem>

                <GridItem width="60%" display="flex" alignItems="center">
                    <BudgetCaption budgetData={props.budgetData} />
                </GridItem>
            </Grid>

            <Text display="block" size={12} weight="semi" color="dark-blue.3" textAlign="right">
                {translate('budgets.budgetSpentInfo')}
            </Text>
        </div>
    );
};
