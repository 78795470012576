import { AppProviders } from '../AppProviders';
import { Sidebar } from '../Sidebar/Sidebar';
import { Topbar } from '../Topbar/Topbar';

export const AppFrame = () => {
    return (
        <AppProviders>
            <Sidebar />
            <Topbar />
        </AppProviders>
    );
};
