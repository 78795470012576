import { Text } from '../cdl/Text/Text.tsx';
import { translate } from '../common/helpers/translate.helper';
import { useDocumentTitle } from '../common/hooks/useDocumentTitle';
import { useRole } from '../common/hooks/useRole';
import { Page } from '../common/Page/Page';
import { PageGrid } from '../common/PageGrid';
import { Box } from '../common/ui/Box.tsx';

import { AgentOverviewState } from './AgentOverviewState';
import { AgentsOverviewTable } from './AgentsOverviewTable';
import { AgentsOverviewTableControls } from './components/AgentsOverviewTableControls';
import { CreateAgentButton } from './components/CreateAgentButton';

export const AgentsOverviewPage = () => {
    return (
        <AgentOverviewState>
            <AgentsOverviewPageContent />
        </AgentOverviewState>
    );
};

const AgentsOverviewPageContent = () => {
    useDocumentTitle(translate('agents.overviewPageTitle'));

    const role = useRole();

    return (
        <Page>
            <PageGrid>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Text variant="headline">{translate('agents.overviewPageTitle')}</Text>

                    {role.hasAnyCompanyWriteRights() ? <CreateAgentButton /> : null}
                </Box>

                <AgentsOverviewTableControls />

                <AgentsOverviewTable />
            </PageGrid>
        </Page>
    );
};
