import PropTypes from 'prop-types';

import { LocationStateProvider } from '../../common/LocationState/LocationStateProvider';

export const VesselOverviewState = ({ children }) => {
    const stateConfig = {
        searchQuery: '',
        customerIds: [],
        tab: 'active',
        page: 0,
    };

    return <LocationStateProvider stateConfig={stateConfig}>{children}</LocationStateProvider>;
};

VesselOverviewState.propTypes = {
    children: PropTypes.node,
};
