import { animated, to, useTransition } from '@react-spring/web';
import { ChangeEvent, ComponentPropsWithoutRef, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { get } from '../../common/ui/get';
import { theme } from '../../common/ui/theme';
import { Text } from '../Text/Text';

import { CheckboxLabel } from './components/CheckboxLabel';
import { CheckmarkBackground } from './components/CheckmarkBackground';
import { CheckmarkIcon } from './components/CheckmarkIcon';
import { HiddenInput } from './components/HiddenCheckboxInput';
import { LabelWrapper } from './components/LabelWrapper';

const ComponentWrapper = styled.div`
    display: grid;
    grid-template-columns: 18px auto;
    grid-column-gap: ${get('space.4')}px;
    align-items: center;
    row-gap: ${get('space.2')}px;
`;

export interface CheckboxProps extends ComponentPropsWithoutRef<'input'> {
    label?: ReactNode;
    description?: ReactNode;
}

export const Checkbox = (props: CheckboxProps) => {
    // Formik compatability: props.value can have a boolean value
    const propsValue = props.value !== undefined ? !!props.value : undefined;

    const [isChecked, setChecked] = useState<boolean | undefined>(props.checked ?? propsValue ?? props.defaultChecked);

    useEffect(() => {
        if (props.checked !== undefined || propsValue !== undefined) {
            setChecked(props.checked ?? propsValue);
        }
    }, [props.checked, propsValue]);

    const checkmarkTransition = useTransition(isChecked, {
        from: {
            strokeOffset: 34,
            scale: 1,
            translateX: '3px',
            opacity: 1,
        },
        enter: {
            strokeOffset: 19,
            scale: 1,
            translateX: '0px',
            opacity: 1,
        },
        leave: {
            strokeOffset: 19,
            scale: 0.5,
            translateX: '0px',
            opacity: 0,
        },
        config: {
            duration: 150,
        },
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.onChange?.(event);
        setChecked(event.target.checked);
    };

    return (
        <ComponentWrapper>
            <LabelWrapper disabled={props.disabled}>
                <HiddenInput
                    {...props}
                    type="checkbox"
                    onChange={handleChange}
                    checked={props.value as boolean | undefined}
                />
                <CheckmarkIcon>
                    <CheckmarkBackground checked={isChecked} disabled={props.disabled}>
                        {checkmarkTransition((style, isCheckmarkVisible) =>
                            isCheckmarkVisible ? (
                                <animated.svg
                                    style={{
                                        transform: to(
                                            [style.scale, style.translateX],
                                            (s, t) => `scale(${s}) translateX(${t})`
                                        ),
                                        opacity: style.opacity,
                                        position: 'absolute',
                                        top: '3px',
                                    }}
                                    width="16"
                                    height="14"
                                    viewBox="0 0 16 14"
                                    fill="none"
                                    stroke={
                                        props.disabled
                                            ? theme.colors.foreground.disabled
                                            : theme.colors.foreground.onEmphasis
                                    }
                                    strokeWidth={1.5}
                                    strokeDasharray={30}
                                    strokeDashoffset={style.strokeOffset}
                                    strokeLinejoin="round"
                                    strokeLinecap="round"
                                >
                                    <path d="M4 6L6.5 8.5L12 3" />
                                </animated.svg>
                            ) : null
                        )}
                    </CheckmarkBackground>
                </CheckmarkIcon>
                {props.label ? (
                    <>
                        <CheckboxLabel disabled={props.disabled}>{props.label}</CheckboxLabel>
                    </>
                ) : null}
            </LabelWrapper>

            {props.description ? (
                <Text variant="extraSmall" color="foreground.subtle" style={{ gridColumnStart: 2 }}>
                    {props.description}
                </Text>
            ) : null}
        </ComponentWrapper>
    );
};
