import PropTypes from 'prop-types';

import { translate } from '../../../common/helpers/translate.helper';
import { IconArrowBackUp } from '../../../common/icons/cdl/ArrowBackUp';

import { ApprovalRequestTextButton } from './ApprovalRequestTextButton';

export const SendRequestAgainButton = ({ onClick }) => {
    return (
        <ApprovalRequestTextButton onClick={onClick}>
            <IconArrowBackUp height={20} width={20} style={{ marginRight: '2px' }} />
            {translate('approvalRequest.buttons.sendRequestAgain')}
        </ApprovalRequestTextButton>
    );
};

SendRequestAgainButton.propTypes = {
    onClick: PropTypes.func,
};
