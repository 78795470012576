import { AssignmentGatewayMessage } from '../../../../types/AssignmentGatewayMessage';
import { queries } from '../../../common/api/queryKeys/queries';
import { usePaginationQuery } from '../../../common/hooks/usePaginationQuery';

export const useSuppliersPagination = (customerIds: string[], page: number) => {
    return usePaginationQuery<AssignmentGatewayMessage>({
        queryKey: queries.assignments.pagination({
            customerIds,
            page,
        }).queryKey,
        queryFn: queries.assignments.pagination({
            customerIds,
            page,
        }).queryFn,
        prefetchQueryKey: queries.assignments.pagination({
            customerIds,
            page: page + 1,
        }).queryKey,
        prefetchQueryFn: queries.assignments.pagination({
            customerIds,
            page: page + 1,
        }).queryFn,
    });
};
