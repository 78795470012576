import { Form, Formik, FormikErrors, useFormikContext } from 'formik';
import React from 'react';

import { Button } from '../../cdl/Button/Button';
import { FormikInput } from '../../cdl/Input/FormikInput';
import { Modal } from '../../cdl/Modal/Modal';
import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';

interface VesselEmailAddressPopupFormState {
    emailAddress: string;
}

interface VesselEmailAddressPopupFormProps {
    onCancel: () => void;
}

const VesselEmailAddressPopupForm = (props: VesselEmailAddressPopupFormProps) => {
    const formikContext = useFormikContext<VesselEmailAddressPopupFormState>();

    return (
        <Form>
            <FormikInput label={translate('bunkerPlan.table.vesselPopup.emailAddressInputLabel')} name="emailAddress" />

            <Box display="flex" justifyContent="end" marginTop={6} gap={4}>
                <Button type="button" emphasis="medium" disabled={formikContext.isSubmitting} onClick={props.onCancel}>
                    {translate('btn.cancel')}
                </Button>
                <Button
                    type="submit"
                    emphasis="high"
                    isLoading={formikContext.isSubmitting}
                    disabled={!formikContext.isValid}
                >
                    {translate('btn.send')}
                </Button>
            </Box>
        </Form>
    );
};

interface VesselEmailAddressPopupProps {
    isOpen: boolean;
    onDismiss: () => void;
    onSubmit: (emailAddress: string) => void;
}

export const VesselEmailAddressPopup = (props: VesselEmailAddressPopupProps) => {
    const validate = (values: VesselEmailAddressPopupFormState): FormikErrors<VesselEmailAddressPopupFormState> => {
        const errors: FormikErrors<VesselEmailAddressPopupFormState> = {};

        if (values.emailAddress.length <= 0) {
            errors.emailAddress = 'Required';
        }

        return errors;
    };

    return (
        <Modal isOpen={props.isOpen} onDismiss={props.onDismiss} dismissible={false}>
            <Box padding={6} display="grid" rowGap={5}>
                <Text variant="title">{translate('bunkerPlan.table.vesselPopup.headline')}</Text>

                <Text variant="small" color="foreground.muted">
                    {translate('bunkerPlan.table.vesselPopup.text')}
                </Text>

                <Formik
                    initialValues={{
                        emailAddress: '',
                    }}
                    onSubmit={(values) => props.onSubmit(values.emailAddress)}
                    validate={validate}
                    validateOnMount
                >
                    <VesselEmailAddressPopupForm onCancel={props.onDismiss} />
                </Formik>
            </Box>
        </Modal>
    );
};
