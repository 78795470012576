import { useQuery } from '@tanstack/react-query';

import { ReachInformationCategory } from '../../../../../types/ReachInformationMessage';
import { getReachInformation } from '../../../../common/api/clients/reachInformation.api';
import { queryKeys } from '../../../../common/api/queryKeys';

export const useReachInformationCategories = (vesselId: string) => {
    const query = useQuery({
        queryKey: queryKeys.reachInformation(vesselId),
        queryFn: () => getReachInformation(vesselId),
    });

    const categories = query.data?.categories.reduce<{ [accessor: string]: ReachInformationCategory }>(
        (acc, category) => {
            const { productGroup, sulphurContent } = category;
            const accessor = `${productGroup}_${sulphurContent}`;
            if (!acc[accessor]) {
                acc[accessor] = category;
            }
            return acc;
        },
        {}
    );

    return categories;
};
