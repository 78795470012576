import { useField } from 'formik';
import PropTypes from 'prop-types';

import { LegacyEnumOptionSelect } from '../../enums/LegacyEnumOptionSelect';

export const FormikLegacyEnumOptionSelect = (props) => {
    const [field, , helpers] = useField(props.name);

    const onSelect = (option) => {
        if (!option) {
            helpers.setValue(null);
        } else {
            helpers.setValue(option?.value);
        }
    };

    return (
        <LegacyEnumOptionSelect
            {...field}
            value={field.value}
            label={props.label}
            enumType={props.enumType}
            context={props.context}
            required={props.required}
            onSelect={onSelect}
            isClearable={props.isClearable}
            placeholder={props.placeholder}
            sort={props.sort}
        />
    );
};

FormikLegacyEnumOptionSelect.propTypes = {
    name: PropTypes.string.isRequired,
    enumType: PropTypes.string.isRequired,
    context: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    isClearable: PropTypes.bool,
    placeholder: PropTypes.string,
    sort: PropTypes.func,
};
