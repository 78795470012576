import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../../common/helpers/translate.helper';
import { useCustomerGroupCreate } from '../hooks/useCustomerGroupCreate';

import { CreateCustomerGroupForm, CreateCustomerGroupFormProps } from './CreateCustomerGroupForm';

interface CreateCustomerGroupProps {
    onSuccess: () => void;
}

export const CreateCustomerGroup = ({ onSuccess }: CreateCustomerGroupProps) => {
    const { addErrorToast } = useToasts();
    const createCustomerGroupMutation = useCustomerGroupCreate();

    const handleSubmit: CreateCustomerGroupFormProps['onSubmit'] = (values) => {
        const createCustomerGroupMessage = {
            name: values.name,
            email: values.email || undefined,
            phone: values.phone || undefined,
            fax: values.fax || undefined,
            address: {
                companyName: values.address.companyName || undefined,
                street: values.address.street || undefined,
                streetNumber: values.address.streetNumber || undefined,
                zipcode: values.address.zipcode || undefined,
                city: values.address.city || undefined,
                country: values.address.country || undefined,
            },
            testCompany: values.testCompany,
            closelinkLite: values.closelinkLite,
            gtcUrl: values.gtcUrl || undefined,
        };

        return createCustomerGroupMutation.mutateAsync(createCustomerGroupMessage, {
            onSuccess: () => onSuccess(),
            onError: () => {
                addErrorToast(translate('customergroup.createCustomerGroupErrorToast'));
            },
        });
    };

    return <CreateCustomerGroupForm onSubmit={handleSubmit} />;
};
