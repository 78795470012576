import { components } from 'react-select';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const CustomNoOptionsMessage = styled(components.NoOptionsMessage).attrs({ children: <>No matches found</> })`
    && {
        font-size: 16px;
        color: ${get('colors.foreground.subtle')};
        text-align: left;
        margin-left: 24px;
    }
`;
