import { Text } from '../../../../../cdl/Text/Text';
import { CustomerInfoPopup } from '../../../../../common/CoreDataPopup/CustomerInfoPopup';
import { formatCompanyName } from '../../../../../common/helpers/formatCompanyName.helper';
import { translate } from '../../../../../common/helpers/translate.helper';
import { useRole } from '../../../../../common/hooks/useRole';
import { OfferModel } from '../../../../model/OfferModel';

import { InvolvedParty } from './InvolvedParty';

interface CustomerPartyProps {
    offer: OfferModel;
}

export const CustomerParty = ({ offer }: CustomerPartyProps) => {
    const role = useRole();
    return (
        <InvolvedParty
            label={translate('offer.detail.coreData.customer')}
            modalContent={<CustomerInfoPopup offer={offer} />}
        >
            <Text>
                {formatCompanyName({
                    company: offer.customer,
                    includeType: role.isAdmin(),
                })}
            </Text>
            {offer.customer.email ? <Text color="foreground.subtle">{offer.customer.email}</Text> : null}
            {offer.customer.phone ? <Text color="foreground.subtle">{offer.customer.phone}</Text> : null}
        </InvolvedParty>
    );
};
