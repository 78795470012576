import { queries } from '../common/api/queryKeys/queries';
import { usePaginationQuery } from '../common/hooks/usePaginationQuery';

export const useVesselPagination = ({ page, searchQuery, customerIds, active }) => {
    return usePaginationQuery({
        queryKey: queries.vessels.pagination({ page, searchQuery, customerIds, active }).queryKey,
        queryFn: queries.vessels.pagination({ page, searchQuery, customerIds, active }).queryFn,
        prefetchQueryKey: queries.vessels.pagination({ page: page + 1, searchQuery, customerIds, active }).queryKey,
        prefetchQueryFn: queries.vessels.pagination({ page: page + 1, searchQuery, customerIds, active }).queryFn,
    });
};
