import { useQuery } from '@tanstack/react-query';

import { getCustomerSettings } from '../../../common/api/clients/companySettings.api';
import { queryKeys } from '../../../common/api/queryKeys';

export const useCustomerSettings = (customerId, enabled = true) => {
    return useQuery({
        queryKey: queryKeys.customerSettings(customerId),
        queryFn: () => getCustomerSettings(customerId),
        enabled,
    });
};
