import PropTypes from 'prop-types';

import { Box } from '../../common/ui/Box.tsx';

export const ProgressBar = ({ color, progress, ...props }) => {
    const height = 8;

    return (
        <Box borderRadius={height} backgroundColor="background.inset" height={height} width="100%" {...props}>
            <Box
                height={height}
                borderRadius={height}
                backgroundColor={color}
                width={`${Math.min(progress * 100, 100)}%`}
                style={{
                    transition: 'all 200ms ease-in-out',
                }}
            />
        </Box>
    );
};

ProgressBar.propTypes = {
    color: PropTypes.string,
    progress: PropTypes.number,
};
