import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconSend = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M21.707 2.293a1 1 0 0 1 .234 1.047l-6.5 18a1.55 1.55 0 0 1-2.84.096l-3.346-6.69-6.69-3.346a1.55 1.55 0 0 1 .095-2.84l18-6.5a1 1 0 0 1 1.047.233Zm-10.49 11.904 2.715 5.431 4.604-12.75-7.32 7.32Zm5.905-8.733-12.75 4.604 5.43 2.715 7.32-7.32Z"
            clipRule="evenodd"
        />
    </svg>
);
