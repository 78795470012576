import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PURE_WHITE } from '../colors';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background: ${PURE_WHITE};
`;

const EdgeWrapper = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

const LeftWrapper = styled(EdgeWrapper)`
    flex-direction: row;
`;

const RightWrapper = styled(EdgeWrapper)`
    flex-direction: row-reverse;
`;

export const Header = (props) => {
    return (
        <Wrapper className={props.className}>
            <LeftWrapper>{props.leftElement}</LeftWrapper>
            {props.centerElement}
            <RightWrapper>{props.rightElement}</RightWrapper>
        </Wrapper>
    );
};

Header.propTypes = {
    className: PropTypes.string,
    leftElement: PropTypes.node,
    centerElement: PropTypes.node,
    rightElement: PropTypes.node,
};
