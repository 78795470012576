import { Formik, FormikErrors } from 'formik';

import { SupplierGroupTypes } from '../../../../types/SupplierGroupMessage';
import { translate } from '../../../common/helpers/translate.helper';

import { SupplierGroupBaseForm } from './SupplierGroupBaseForm';

export interface FormikCreateSupplierGroupState {
    name: string;
    description: string;
    gtcUrl: string;
    headquarter: string;
    testCompany: boolean;
    emailQuote: boolean;
    anonymousQuote: boolean;
    supplierProductGroups: string[];
    translationSupplierProductGroup?: string;
    type?: SupplierGroupTypes;
    phone: string;
    fax: string;
    address: {
        companyName: string;
        street: string;
        streetNumber: string;
        zipcode: string;
        city: string;
        country: string;
    };
}

export interface CreateSupplierGroupFormProps {
    onSubmit: (values: FormikCreateSupplierGroupState) => void;
}

export const CreateSupplierGroupForm = ({ onSubmit }: CreateSupplierGroupFormProps) => {
    const initialFormikState: FormikCreateSupplierGroupState = {
        name: '',
        description: '',
        gtcUrl: '',
        headquarter: '',
        testCompany: false,
        emailQuote: false,
        anonymousQuote: false,
        supplierProductGroups: [],
        translationSupplierProductGroup: undefined,
        type: undefined,
        phone: '',
        fax: '',
        address: {
            companyName: '',
            street: '',
            streetNumber: '',
            zipcode: '',
            city: '',
            country: '',
        },
    };

    const validate = (values: FormikCreateSupplierGroupState) => {
        const errors: FormikErrors<FormikCreateSupplierGroupState> = {};

        if (!values.name || values.name.trim().length === 0) {
            errors.name = translate('company.error.name');
        }

        if (!values.type) {
            errors.type = translate('company.error.supplierType');
        }

        return errors;
    };

    return (
        <Formik initialValues={initialFormikState} onSubmit={onSubmit} validate={validate} validateOnMount>
            <SupplierGroupBaseForm title={translate('suppliergroups.create')} />
        </Formik>
    );
};
