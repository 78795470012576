import styled, { keyframes } from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { Box } from '../ui/Box';

const indicatorAnimation = keyframes`
  80%, 100% {
    transform: translateY(0);
  }
  90% {
    transform: translateY(-6px);
  }
`;

const BouncingBox = styled(Box)`
    animation-name: ${indicatorAnimation};
    animation-duration: 4s;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    animation-iteration-count: infinite;
`;

export const CheapIndicator = (props) => (
    <BouncingBox
        borderRadius="100%"
        width="20px"
        height="20px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor="clear-blue.0"
        color="white"
        position="absolute"
        top="calc(50% - 30px)"
        zIndex="100"
        {...props}
    >
        <Text size={12}>$</Text>
    </BouncingBox>
);
