import { useQuery } from '@tanstack/react-query';

import { getAgents } from '../common/api/clients/gateway.api';
import { queries } from '../common/api/queryKeys/queries';

export interface AgentPaginationTotalsParams {
    searchQuery?: string;
    portIds?: string[];
    customerIds?: string[];
}

export const useAgentPaginationTotals = ({ searchQuery, portIds, customerIds }: AgentPaginationTotalsParams) => {
    const fetchTotals = ({ searchQuery, customerIds, portIds }: AgentPaginationTotalsParams) => {
        return Promise.all([
            getAgents({
                page: 0,
                searchQuery,
                customerIds,
                portIds,
                active: true,
            }),
            getAgents({
                page: 0,
                searchQuery,
                customerIds,
                portIds,
                active: false,
            }),
        ])
            .then((values) => values.map((value) => value.total))
            .then((agentTotals) => {
                return {
                    active: agentTotals[0],
                    archived: agentTotals[1],
                };
            });
    };

    return useQuery({
        queryKey: queries.agents.totals({ searchQuery, customerIds, portIds }).queryKey,
        queryFn: () =>
            fetchTotals({
                searchQuery,
                customerIds,
                portIds,
            }),
        refetchOnWindowFocus: false,
    });
};
