import { useFormikContext } from 'formik';

import { FormikInput } from '../../../../cdl/Input/FormikInput';
import { ReadOnlyField } from '../../../../cdl/ReadOnlyField/ReadOnlyField';
import { Text } from '../../../../cdl/Text/Text';
import { translate } from '../../../../common/helpers/translate.helper';
import { useProductContext } from '../../../../common/hooks/useProductContext';
import { useRole } from '../../../../common/hooks/useRole';
import { FormikTagListInput } from '../../../../common/TagListInput/FormikTagListInput';
import { Box } from '../../../../common/ui/Box';
import { FormikVesselDetailsState } from '../EditVesselForm';

import { FormikDefaultSupplierComboBox } from './FormikDefaultSupplierComboBox';

interface FormikVesselDetailsSectionProps {
    customerId: string;
}

export const FormikVesselDetailsSection = ({ customerId }: FormikVesselDetailsSectionProps) => {
    const { values } = useFormikContext<FormikVesselDetailsState>();
    const { isFuel, isLubes } = useProductContext();
    const role = useRole();
    return (
        <>
            <Box display="grid" gap={5}>
                <FormikInput name="name" label={translate('vessel.name')} />
                {role.isAdmin() ? (
                    <FormikInput name="imo" label={translate('vessel.imo')} />
                ) : (
                    <ReadOnlyField value={values.imo} label={translate('vessel.imo')} />
                )}

                {isFuel ? (
                    <>
                        <FormikTagListInput name="types" label={translate('vessel.types')} />
                        <FormikTagListInput name="services" label={translate('vessel.services')} />
                    </>
                ) : null}

                <FormikInput name="email" label={translate('vessel.edit.email')} />

                {isLubes ? (
                    <FormikDefaultSupplierComboBox
                        name="defaultSupplierId"
                        customerId={customerId}
                        label={translate('vessel.edit.defaultSupplier')}
                    />
                ) : null}
            </Box>

            <Box display="flex" flexDirection="column" gap={5}>
                <Text variant="subtitle1">{translate('vessel.edit.invoiceInformation')}</Text>

                <FormikInput
                    name="invoiceAddress.companyName"
                    label={translate('global.address.company')}
                    autoComplete="organization"
                />
                <FormikInput name="vatNumber" label={translate('vessel.edit.vat')} />

                <Box display="flex" flexDirection="column" gap={2}>
                    <FormikInput
                        name={'invoiceAddress.street'}
                        placeholder={translate('vessel.edit.streetName')}
                        label={translate('vessel.edit.billingAddress')}
                        autoComplete="street-address"
                    />
                    <FormikInput
                        name={'invoiceAddress.streetNumber'}
                        placeholder={translate('vessel.edit.streetNumber')}
                    />
                    <FormikInput
                        name={'invoiceAddress.zipcode'}
                        placeholder={translate('vessel.edit.postalCode')}
                        autoComplete="postal-code"
                    />
                    <FormikInput
                        name={'invoiceAddress.city'}
                        placeholder={translate('vessel.edit.townCity')}
                        autoComplete="city"
                    />
                    <FormikInput
                        name={'invoiceAddress.country'}
                        placeholder={translate('global.address.country')}
                        autoComplete="country"
                    />
                </Box>
            </Box>
        </>
    );
};
