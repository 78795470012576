import css from '@styled-system/css';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { translate } from '../helpers/translate.helper';
import { useProductContext } from '../hooks/useProductContext';
import { Box } from '../ui/Box';

import { TopbarTab } from './TopbarTab';

const TabBar = styled.div(
    css({
        marginTop: '21px',
        [TopbarTab]: {
            marginRight: 6,
            ':last-of-type': {
                marginRight: 0,
            },
        },
    })
);

const ProductContextTab = ({ productContext }) => {
    const { context, setContext } = useProductContext();

    const onProductContextSelectClick = () => setContext(productContext);

    return (
        <TopbarTab onClick={onProductContextSelectClick} active={productContext === context}>
            {translate(`productContext.${productContext}`)}
        </TopbarTab>
    );
};

ProductContextTab.propTypes = {
    productContext: PropTypes.string,
};

export const ProductContextSwitch = () => {
    const { possibleContexts } = useProductContext();

    if (possibleContexts.length === 1) {
        return null;
    }

    return (
        <Box>
            <TabBar>
                {possibleContexts.map((context) => (
                    <ProductContextTab key={context} productContext={context} />
                ))}
            </TabBar>
        </Box>
    );
};
