import { VesselTankMessage } from '../../../types/VesselTankMessage';
import { Text } from '../../cdl/Text/Text';
import { sortLubesCategory } from '../../common/helpers/sortByCategory.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { MiddleDotSeparator } from '../../common/Separators/MiddleDotSeparator';
import { Box } from '../../common/ui/Box';

import { DateMeasured } from './components/DateMeasured';
import { FillLevelBar } from './components/FillLevelBar';
import { QuantityRatio } from './components/QuantityRatio';

interface VesselTankStocksProps {
    vesselTanks: VesselTankMessage[];
}

export const VesselTankStocks = ({ vesselTanks }: VesselTankStocksProps) => {
    const sortedTanks = vesselTanks.sort((tankA, tankB) => sortLubesCategory(tankA.category, tankB.category));

    return (
        <Box display="grid" gap={8} gridTemplateColumns={{ _: '1fr', xl: '1fr 1fr' }}>
            {sortedTanks.map((tank) => (
                <VesselTankStock key={tank.id} vesselTank={tank} />
            ))}
        </Box>
    );
};

interface VesselTankStockProps {
    vesselTank: VesselTankMessage;
}

const VesselTankStock = ({ vesselTank }: VesselTankStockProps) => {
    const { getHumanReadableValue } = useEnums();

    const { maxVolume, threshold: warningLimit, safetyReserve, name, category } = vesselTank;

    const oilType = getHumanReadableValue('vesselTankCategory', category);
    const quantity = vesselTank.latestStock ? vesselTank.latestStock.quantity : 0;
    const dateMeasured = vesselTank.latestStock ? vesselTank.latestStock.dateMeasured : null;

    return (
        <div>
            <Box display="flex" justifyContent="space-between">
                <Text variant="subtitle2" color="foreground.muted">
                    {name}
                </Text>

                {dateMeasured ? <DateMeasured dateMeasured={dateMeasured} /> : null}
            </Box>
            <Box display="flex" alignItems="center" marginTop={1}>
                <Text variant="body" fontWeight="semiBold">
                    {oilType}
                    <MiddleDotSeparator />
                </Text>
                <QuantityRatio
                    maxVolume={maxVolume}
                    quantity={quantity}
                    warningLimit={warningLimit}
                    safetyReserve={safetyReserve}
                />
            </Box>
            <Box marginTop={3}>
                <FillLevelBar
                    quantity={quantity}
                    warningLimit={warningLimit}
                    safetyReserve={safetyReserve}
                    maxVolume={maxVolume}
                />
            </Box>
        </div>
    );
};
