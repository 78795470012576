import PropTypes from 'prop-types';
import { useState } from 'react';
import { ControlLabel, FormControl as _FormControl, FormGroup } from 'react-bootstrap';
import styled from 'styled-components';

import { Button } from '../../cdl/Button/Button';
import { Modal } from '../../cdl/Modal/Modal';
import { Text } from '../../cdl/Text/Text';
import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { CharacterCount } from '../../common/CharacterCount/CharacterCount';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';

import { useCharacterLimit } from './useCharacterLimit';

const CHARACTER_LIMIT = 280;

const CharacterCountWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
`;

const FormControl = styled(_FormControl)`
    resize: vertical;
    min-height: 100px;
`;

export const CommentPopup = ({ open, onDismiss, onSubmit, requestInProgress, headline, description, inputLabel }) => {
    const [message, setMessage] = useState('');

    const { limitReached } = useCharacterLimit({
        characterLength: message.length,
        characterLimit: CHARACTER_LIMIT,
    });

    const onFormSubmit = (event) => {
        event.preventDefault();

        onSubmit({ message });
    };

    return (
        <Modal isOpen={open} onDismiss={onDismiss} scrollable={false}>
            <Box display="grid" rowGap={5} padding={6}>
                <Text variant="title">{headline}</Text>

                <Text color="foreground.subtle">{description}</Text>

                <form onSubmit={onFormSubmit}>
                    <Box display="grid" rowGap={5}>
                        <Box display="grid" gridTemplateColumns="auto max-content" alignItems="center" columnGap={4}>
                            <FormGroup controlId="formControlsTextarea">
                                <ControlLabel>{inputLabel}</ControlLabel>
                                <FormControl
                                    placeholder={translate('approvalRequest.commentInputPlaceholder')}
                                    componentClass="textarea"
                                    onChange={(event) => setMessage(event.target.value)}
                                    value={message}
                                />
                            </FormGroup>

                            <CharacterCountWrapper>
                                <CharacterCount count={message.length} limit={CHARACTER_LIMIT} />
                            </CharacterCountWrapper>
                        </Box>

                        <ButtonGroup justifyContent="end">
                            <Button
                                emphasis="high"
                                isLoading={requestInProgress}
                                disabled={requestInProgress || limitReached}
                            >
                                {translate('approvalRequest.declinePopup.submitButton')}
                            </Button>
                        </ButtonGroup>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
};

CommentPopup.propTypes = {
    open: PropTypes.bool,
    onDismiss: PropTypes.func,
    onSubmit: PropTypes.func,
    requestInProgress: PropTypes.bool,
    headline: PropTypes.string,
    description: PropTypes.string,
    inputLabel: PropTypes.string,
};
