import { AverageConsumption } from '../../../../types/AverageConsumption';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

type Iso8601DateTimeString = string;

interface AverageConsumptionMessage {
    averageConsumptions: AverageConsumption[];
}

export interface GetAverageConsumptionsParams {
    vesselId: string;
    dateFrom: Iso8601DateTimeString;
    dateTo: Iso8601DateTimeString;
    resolution: number;
}

export const getAverageConsumption = async ({
    vesselId,
    dateFrom,
    dateTo,
    resolution,
}: GetAverageConsumptionsParams): Promise<AverageConsumptionMessage> => {
    const params = new URLSearchParams();

    params.append('dateFrom', dateFrom);
    params.append('dateTo', dateTo);
    params.append('resolution', resolution.toString());

    const response = await authorizedAxiosInstance.get(`/v1/consumptions/${vesselId}?${params.toString()}`);
    return response.data;
};
