import { useQuery } from '@tanstack/react-query';

import { getFeeForOfferId } from '../../../common/api/clients/fee.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { useRole } from '../../../common/hooks/useRole';

export const useFee = (offerId) => {
    const role = useRole();

    return useQuery({
        queryKey: queryKeys.fees(offerId),
        queryFn: () => getFeeForOfferId(offerId),
        enabled: role.isSupplier() || role.isAdmin(),
    });
};
