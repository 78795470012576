import { get } from 'lodash';

import { useFormContext } from './useFormContext';

export const useFormData = (dataPath, options = {}) => {
    const transformValue = options.transformValue || ((v) => v);
    const { data, updateDataPath, onChange: onFormChange } = useFormContext();
    const value = get(data, dataPath, get(options, 'default', ''));
    const setValue = (newValue) => updateDataPath(dataPath, transformValue(newValue));

    const onChange = (event, getEventValue) => {
        const newData = setValue(getEventValue ? getEventValue(event) : event.target.value);

        // trigger the onChange defined in FormContext
        if (onFormChange) {
            onFormChange({ data: newData, event });
        }
    };

    return {
        value,
        onChange,
        setValue,
    };
};
