import { CategoryRecommendation, CategoryWarningType } from '../../../../../../types/BunkerCaseSolutionResponse';
import { IconFileInvoice } from '../../../../../common/icons/cdl/FileInvoice';
import { Box } from '../../../../../common/ui/Box';

import { WarningIcon } from './BunkerSolutionStopTable/components/WarningIcon';

interface SolutionDetailsWarningsSummaryProps {
    categoryConsumptions: CategoryRecommendation[];
}

export const BunkerStopWarningsSummary = ({ categoryConsumptions }: SolutionDetailsWarningsSummaryProps) => {
    const uniqueWarnings = categoryConsumptions.reduce((acc, categoryConsumption) => {
        categoryConsumption.quantityStartWarnings.forEach((warning) => {
            acc.add(warning.type);
        });

        categoryConsumption.consumptionWarnings.forEach((warning) => {
            acc.add(warning.type);
        });

        return acc;
    }, new Set<CategoryWarningType>());

    const hasRecommendedContracts = categoryConsumptions.some(
        (categoryConsumption) => categoryConsumption.recommendedContractId !== undefined
    );

    return (
        <Box display="flex" gap={2}>
            {Array.from(uniqueWarnings)
                .sort()
                .map((warningType) => (
                    <WarningIcon key={warningType} warningType={warningType} />
                ))}
            {hasRecommendedContracts ? <IconFileInvoice width={16} height={16} /> : null}
        </Box>
    );
};
