import { ReactNode } from 'react';

import { Text } from '../../../../cdl/Text/Text';

interface CheapestTagProps {
    children: ReactNode;
}

export const CheapestTag = ({ children }: CheapestTagProps) => {
    return (
        <Text color="positive.foreground" variant="extraSmall" fontWeight="600">
            {children}
        </Text>
    );
};
