import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { PlattsCredentialsRequestMessage } from '../../../../../../types/PlattsCredentialsRequestMessage';
import { PlattsCredentialsResponseMessage } from '../../../../../../types/PlattsCredentialsResponseMessage';
import {
    createPlattsCredentials,
    PlattsCredentialsServerErrorCode,
} from '../../../../../common/api/clients/plattsCredentials.api';
import { queryKeys } from '../../../../../common/api/queryKeys';

export const usePlattsCredentialsCreate = () => {
    const queryClient = useQueryClient();

    return useMutation<
        PlattsCredentialsResponseMessage,
        AxiosError<{ code: PlattsCredentialsServerErrorCode }>,
        PlattsCredentialsRequestMessage
    >({
        mutationFn: createPlattsCredentials,
        onSuccess: async (data) => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: queryKeys.plattsCredentials(data.customerId) }),
                queryClient.invalidateQueries({ queryKey: queryKeys.schedulesWithPrices() }),
                queryClient.invalidateQueries({ queryKey: queryKeys.fuelContractProducts() }),
            ]);
        },
    });
};
