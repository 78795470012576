import PropTypes from 'prop-types';

import { Tooltip } from '../cdl/Tooltip/Tooltip';
import { translate } from '../common/helpers/translate.helper';
import { useKeyPorts } from '../common/hooks/useKeyPorts';

import { KeyPortIndicator } from './KeyPortIndicator';

export const KeyPortHighlight = ({ portId, vesselId, color, showTooltip = false }) => {
    const { isKeyPort } = useKeyPorts();

    if (!isKeyPort({ portId, vesselId })) {
        return null;
    }

    const title = translate('keyPorts.highlightTooltip');

    if (showTooltip) {
        return (
            <Tooltip label={title}>
                <KeyPortIndicator color={color} />
            </Tooltip>
        );
    }

    return <KeyPortIndicator color={color} />;
};

KeyPortHighlight.propTypes = {
    portId: PropTypes.string.isRequired,
    vesselId: PropTypes.string,
    color: PropTypes.string,
    showTooltip: PropTypes.bool,
};
