import { FormikErrors } from 'formik';

import { PaymentTermReference } from '../../../../types/PaymentTermReference';
import { translate } from '../../../common/helpers/translate.helper';

import { FormikAssignmentState } from './AssignmentBaseForm';

export const validateAssignment = (values: FormikAssignmentState): FormikErrors<FormikAssignmentState> => {
    const errors: FormikErrors<FormikAssignmentState> = {};

    if (!values.customerId) {
        errors.customerId = translate('assignments.customerRequired');
    }

    if (!values.supplierId) {
        errors.supplierId = translate('assignments.supplierRequired');
    }

    if (
        values.defaultPaymentTermReference &&
        values.defaultPaymentTermReference !== PaymentTermReference.PREPAYMENT &&
        !values.defaultPaymentTermReferenceDays
    ) {
        errors.defaultPaymentTermReferenceDays = translate('assignments.defaultPaymentTermReferenceDaysRequired');
    }

    return errors;
};
