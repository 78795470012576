import { useFormikContext } from 'formik';

import { Required } from '../../form-elements/Required/Required';
import { translate } from '../../helpers/translate.helper';
import { Th } from '../../Table/Th';
import { Tr } from '../../Table/Tr';

import { useProductTableConfig } from './useProductTableConfig';

export const TableHeaderRow = () => {
    const { values: offer } = useFormikContext();
    const { showPrices, editable, showPhysicalSupplierColumn, showCounterOffers, showEnergyContentColumn } =
        useProductTableConfig();

    return (
        <Tr>
            <Th width="20%">{translate('offer.quotationProcess.fuel.product')}</Th>
            <Th width="5%">{translate('offer.quotationProcess.fuel.category')}</Th>
            <Th width="10%">{translate('order.isoSpec')}</Th>

            {showEnergyContentColumn ? (
                <Th width="10%">{translate('offer.quotationProcess.fuel.energyContent')}</Th>
            ) : null}

            {showPhysicalSupplierColumn ? (
                <Th width="10%">{translate('offer.quotationProcess.fuel.physicalSupplier')}</Th>
            ) : null}

            <Th width="10%">
                {translate('offer.quotationProcess.fuel.volume')}
                {editable && <Required />}
            </Th>
            <Th width="3%">{translate('offer.quotationProcess.fuel.unit')}</Th>

            {showPrices ? (
                <>
                    {showCounterOffers && offer?.hasOpenCounterOffers() ? (
                        <Th width="10%">{translate('offer.quotationProcess.fuel.counterPrice')}</Th>
                    ) : null}
                    <Th width="10%">
                        {translate('offer.quotationProcess.fuel.price')}
                        {editable && <Required />}
                    </Th>
                    <Th width="10%">{translate('offer.quotationProcess.fuel.total')}</Th>
                </>
            ) : null}

            {editable ? <Th width="3%" /> : null}
        </Tr>
    );
};
