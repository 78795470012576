import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { PortDetailPage as OriginalPortDetailPage } from './PortDetailPage';

const PortDetailPage = (props) => {
    return (
        <AppProviders>
            <OriginalPortDetailPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(PortDetailPage, 'reactPortDetailPage');
