import { Form, Formik, FormikErrors, setIn } from 'formik';

import { NotificationSettings, VesselMessage } from '../../../../types/VesselMessage';
import { VesselUpdateRequestMessage } from '../../../../types/VesselUpdateRequestMessage';
import { Button } from '../../../cdl/Button/Button';
import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../../common/helpers/translate.helper';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { Box } from '../../../common/ui/Box';

import { FormikVesselAdminSection } from './components/FormikVesselAdminSection';
import { FormikVesselDetailsSection } from './components/FormikVesselDetailsSection';
import { FormikVesselLubesNotificationSettingsSection } from './components/FormikVesselLubesNotificationSettingsSection';
import { FormikVesselLubricationChartSection } from './components/FormikVesselLubricationChartSection';
import { setRelatedNotificationSettings } from './utils/setRelatedNotificationSettings';

interface EditVesselFormProps {
    vessel: VesselMessage;
    onSubmit: (vessel: VesselUpdateRequestMessage) => void;
}

export interface FormikVesselDetailsState {
    name: string;
    imo: string;
    types: string[];
    services: string[];
    email: string;
    defaultSupplierId: string;
    invoiceAddress: {
        companyName: string;
        street: string;
        streetNumber: string;
        zipcode: string;
        city: string;
        country: string;
    };
    vatNumber: string;
    mailNotificationSettings: {
        orderUpdateSettings: NotificationSettings;
        stockWarningSettings: NotificationSettings;
        safetyReserveSettings: NotificationSettings;
        keyPortCallSettings: NotificationSettings;
    };
    lubricationChart: {
        file?: File;
        added: boolean;
        removed: boolean;
    };
    active: boolean;
    paying: boolean;
    directOrderAllowed: boolean;
}

export const EditVesselForm = ({ vessel, onSubmit }: EditVesselFormProps) => {
    const role = useRole();
    const { isLubes } = useProductContext();

    const initialValues: FormikVesselDetailsState = {
        name: vessel.name,
        imo: vessel.imo,
        types: vessel.types || [],
        services: vessel.services || [],
        email: vessel.email || '',
        defaultSupplierId: vessel.defaultSupplierId || '',
        invoiceAddress: {
            companyName: vessel.invoiceAddress.companyName || '',
            street: vessel.invoiceAddress.street || '',
            streetNumber: vessel.invoiceAddress.streetNumber || '',
            zipcode: vessel.invoiceAddress.zipcode || '',
            city: vessel.invoiceAddress.city || '',
            country: vessel.invoiceAddress.country || '',
        },
        vatNumber: vessel.invoiceVAT || '',
        mailNotificationSettings: { ...vessel.mailNotificationSettings },
        lubricationChart: {
            file: undefined,
            added: false,
            removed: false,
        },
        active: vessel.active,
        paying: vessel.paying,
        directOrderAllowed: vessel.directOrderAllowed,
    };

    const validate = (values: FormikVesselDetailsState) => {
        let errors: FormikErrors<FormikVesselDetailsState> = {};

        if (!values.name) {
            errors.name = translate('vessel.edit.nameRequired');
        }

        if (!values.imo) {
            errors.imo = translate('vessel.edit.imoRequired');
        }

        if (!values.invoiceAddress.companyName) {
            errors = setIn(errors, 'invoiceAddress.companyName', translate('vessel.edit.companyNameRequired'));
        }

        if (!values.invoiceAddress.country) {
            errors = setIn(errors, 'invoiceAddress.country', translate('vessel.edit.countryRequired'));
        }

        if (!values.invoiceAddress.street) {
            errors = setIn(errors, 'invoiceAddress.street', translate('vessel.edit.streetRequired'));
        }

        if (!values.invoiceAddress.streetNumber) {
            errors = setIn(errors, 'invoiceAddress.streetNumber', translate('vessel.edit.streetNumberRequired'));
        }

        if (!values.invoiceAddress.zipcode) {
            errors = setIn(errors, 'invoiceAddress.zipcode', translate('vessel.edit.zipCodeRequired'));
        }

        if (!values.invoiceAddress.city) {
            errors = setIn(errors, 'invoiceAddress.city', translate('vessel.edit.cityRequired'));
        }

        return errors;
    };

    const handleSubmit = (values: FormikVesselDetailsState) => {
        const updatedVessel: VesselUpdateRequestMessage = {
            id: vessel.id,
            customerId: vessel.customerId,
            ...values,
            types: values.types.length ? values.types : undefined,
            services: values.services.length ? values.services : undefined,
            email: values.email || undefined,
            defaultSupplierId: values.defaultSupplierId || undefined,
            invoiceVAT: values.vatNumber || undefined,
            mailNotificationSettings: {
                ...values.mailNotificationSettings,
                orderUpdateSettings: {
                    enabled:
                        values.mailNotificationSettings.orderUpdateSettings.enabled &&
                        values.mailNotificationSettings.orderUpdateSettings.recipients.length > 0,

                    recipients: values.mailNotificationSettings.orderUpdateSettings.recipients,
                    sendToOthers: vessel.mailNotificationSettings.orderUpdateSettings.sendToOthers,
                    sendToVessel: vessel.mailNotificationSettings.orderUpdateSettings.sendToVessel,
                },
                stockWarningSettings: setRelatedNotificationSettings(
                    values.mailNotificationSettings.stockWarningSettings
                ),
                safetyReserveSettings: setRelatedNotificationSettings(
                    values.mailNotificationSettings.safetyReserveSettings
                ),
                keyPortCallSettings: setRelatedNotificationSettings(
                    values.mailNotificationSettings.keyPortCallSettings
                ),
            },
        };
        onSubmit(updatedVessel);
    };

    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            <Form style={{ height: '100%' }}>
                <Box display="grid" gridTemplateRows="auto 1fr auto" height="100%" gridTemplateColumns="100%">
                    <Box padding={7} paddingBottom={6}>
                        <Text variant="title">{translate('vessel.edit.editDetails')}</Text>
                    </Box>

                    <Box display="flex" flexDirection="column" gap={7} paddingX={7} overflowY="auto">
                        <FormikVesselDetailsSection customerId={vessel.customerId} />
                        {isLubes ? (
                            <>
                                <FormikVesselLubesNotificationSettingsSection />
                                <FormikVesselLubricationChartSection lubricationChart={vessel.lubricationChart} />
                            </>
                        ) : null}
                        {role.isAdmin() ? <FormikVesselAdminSection /> : null}
                    </Box>

                    <Box padding={7}>
                        <Button width="100%" emphasis="high" type="submit" size="large">
                            {translate('btn.save.default')}
                        </Button>
                    </Box>
                </Box>
            </Form>
        </Formik>
    );
};
