import axios from 'axios';

import AppSettings from '../../constants';
import { Offer } from '../model/offer.model';

export const getOfferAndApprovalRequestsById = ({ offerId, token }) => {
    return axios
        .get(AppSettings.routes.gateway + `/v1/offer/approval-requests?offerIds=${offerId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            return {
                offers: response.data.offers.map((offer) => new Offer(offer)),
                approvalRequests: response.data.approvalRequests,
            };
        });
};

export const updateApprovalRequest = ({ approvalRequestId, approvalRequest, token }) => {
    return axios
        .put(`${AppSettings.routes.api}/v1/offer/approval-requests/${approvalRequestId}`, approvalRequest, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => response.data);
};
