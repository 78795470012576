import { useFormikContext } from 'formik';

import { FuelEnquiryFlow } from './FuelEnquiryFlow';
import { FuelOrderFlow } from './FuelOrderFlow';

export const ThirdStep = () => {
    const { values } = useFormikContext();

    if (values.contractId) {
        return <FuelOrderFlow />;
    }

    return <FuelEnquiryFlow />;
};
