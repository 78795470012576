import { ReactNode } from 'react';

export interface TdProps {
    danger?: boolean;
    width?: string;
    colSpan?: number;
    children: ReactNode;
}

export const Td = ({ danger = false, children, ...rest }: TdProps) => {
    let className: string = '';
    if (danger) {
        className = 'table-danger';
    }

    return (
        <td className={className} {...rest}>
            {children}
        </td>
    );
};
