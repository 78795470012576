import { memo, useState } from 'react';

import { CounterOffer } from '../../../types/CounterOffer';
import { CounterOfferState } from '../../../types/CounterOfferState';
import { Button } from '../../cdl/Button/Button';
import { translate } from '../../common/helpers/translate.helper';
import { CounterOfferReviewFormState } from '../../offer/detail/fuel/CounterOffer/components/CounterOfferReviewForm';
import { CounterOfferReviewPopup } from '../../offer/detail/fuel/CounterOffer/components/CounterOfferReviewPopup';
import { OfferModel } from '../../offer/model/OfferModel';
import { useAnonymousQuotationCounterOfferUpdate } from '../hooks/useAnonymousQuotationCounterOfferUpdate';
import { useAnonymousQuotationOfferUpdate } from '../hooks/useAnonymousQuotationOfferUpdate';
import { makeOfferUpdateRequest } from '../utils/makeOfferUpdateRequest';

interface AnonymousCounterOfferReviewButtonProps {
    anonymousQuotationId: string;
    latestCounterOffer: CounterOffer;
    offer: OfferModel;
    isCounterOfferExpired: boolean;
}

export const AnonymousCounterOfferReviewButton = memo(function AnonymousCounterOfferReviewButton({
    anonymousQuotationId,
    latestCounterOffer,
    offer,
    isCounterOfferExpired,
}: AnonymousCounterOfferReviewButtonProps) {
    const [showPopup, setShowPopup] = useState(false);
    const counterOfferUpdateMutation = useAnonymousQuotationCounterOfferUpdate(anonymousQuotationId);
    const offerUpdateMutation = useAnonymousQuotationOfferUpdate(anonymousQuotationId);

    const counterOfferReviewFormState: CounterOfferReviewFormState = {
        state: latestCounterOffer.state,
        responseMessage: '',
        items: latestCounterOffer.items.map((counterOfferItem, index) => {
            const offerItem = offer.items[index];

            return {
                productId: counterOfferItem.productId,
                productName: offerItem.product?.name,
                sulphurContent: offerItem.product?.sulphurContent,
                price: counterOfferItem.price,
                counterPrice: counterOfferItem.counterPrice,
                deleted: counterOfferItem.deleted || false,
            };
        }),
    };

    const onSubmit = async (values: CounterOfferReviewFormState) => {
        await counterOfferUpdateMutation.mutateAsync({
            anonymousQuotationId: anonymousQuotationId,
            counterOffer: {
                ...latestCounterOffer,
                state: values.state,
                responseMessage: values.responseMessage || undefined,
            },
        });
        if (values.state === CounterOfferState.ACCEPTED) {
            await offerUpdateMutation.mutateAsync({
                anonymousQuotationId: anonymousQuotationId,
                offer: {
                    ...makeOfferUpdateRequest(offer),
                    items: offer.items.map((item, index) => {
                        if (item.deleted || values.items[index].deleted) {
                            return item;
                        }
                        return { ...item, price: values.items[index].counterPrice };
                    }),
                },
            });
        }

        setShowPopup(false);
    };

    return (
        <>
            <Button
                emphasis={isCounterOfferExpired ? 'medium' : 'high'}
                onClick={() => {
                    setShowPopup(true);
                }}
            >
                {translate('statusbar.supplier.fuel.counterOfferReceived.button')}
            </Button>

            <CounterOfferReviewPopup
                open={showPopup}
                data={counterOfferReviewFormState}
                onDismiss={() => {
                    setShowPopup(false);
                }}
                onSubmit={onSubmit}
                latestCounterOffer={latestCounterOffer}
                isCounterOfferExpired={isCounterOfferExpired}
                onUpdate={() => {
                    setShowPopup(false);
                }}
            />
        </>
    );
});
