import styled from 'styled-components';

import { get } from '../../../../../../common/ui/get';

interface OfferCardContainerProps {
    expanded: boolean;
}

export const OfferCardContainer = styled.div<OfferCardContainerProps>`
    background-color: ${get('colors.white')};
    display: flex;
    flex-direction: column;
    min-height: 145px;
    position: relative;
    z-index: 2;
    border-radius: 8px;
    border: 1px solid ${get('colors.border.muted')};
    transition: box-shadow 75ms ease-in-out;

    box-shadow: ${(props) => (props.expanded ? get('shadows.medium') : 'none')};

    &:hover {
        box-shadow: ${get('shadows.medium')};
    }
`;
