import { QuickStatsMessage } from '../../../../types/QuickStatsMessage';
import { formatMoney } from '../../../common/helpers/formatMoney.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { LiteOverlay } from '../../../common/LiteOverlay/LiteOverlay';

import { PplStatsItem } from './PplStatsItem';
import { QuickStatsWrapper } from './QuickStatsWapper';
import { StatsItem } from './StatsItem';
import { VolumeStatsItem } from './VolumeStatsItem';

export const LiteQuickStats = () => {
    const stats: QuickStatsMessage = {
        count: 18,
        ppl: {
            value: 1.87,
            currency: 'USD',
        },
        total: {
            value: 50_000,
            currency: 'USD',
        },
        volume: 25_000,
    };

    return (
        <LiteOverlay variant="wide">
            <QuickStatsWrapper>
                <StatsItem item={stats.count} description={translate('dashboard.quickstats.deliveries')} />
                <VolumeStatsItem stats={stats} />

                <PplStatsItem stats={stats} />

                <StatsItem
                    item={formatMoney({
                        value: stats.total.value,
                        currency: stats.total.currency,
                        maximumFractionDigits: 0,
                    })}
                    description={translate('dashboard.quickstats.total')}
                />
            </QuickStatsWrapper>
        </LiteOverlay>
    );
};
