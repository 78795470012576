import { MoneyMessage } from '../../../../../types/MoneyMessage';
import { Text } from '../../../../cdl/Text/Text';
import { formatMoney } from '../../../../common/helpers/formatMoney.helper';
import { IconArrowNarrowDown } from '../../../../common/icons/cdl/ArrowNarrowDown';
import { IconArrowNarrowUp } from '../../../../common/icons/cdl/ArrowNarrowUp';
import { Box } from '../../../../common/ui/Box';

interface FormattedPriceProps {
    price?: MoneyMessage;
    positive?: boolean;
    attention?: boolean;
    trend?: 'up' | 'down';
}

interface TrendIndicatorProps extends Pick<FormattedPriceProps, 'trend'> {}

const TrendIndicator = ({ trend }: TrendIndicatorProps) => {
    if (!trend) {
        return null;
    }

    switch (trend) {
        case 'up':
            return <IconArrowNarrowUp width="16" height="16" />;
        case 'down':
            return <IconArrowNarrowDown width="16" height="16" />;
    }
};

export const FormattedPrice = ({ price, positive, attention, trend }: FormattedPriceProps) => {
    let textColor = 'foreground.default';

    if (positive) {
        textColor = 'positive.foreground';
    }
    if (attention) {
        textColor = 'attention.foreground';
    }

    return (
        <Box as="span" display="inline-flex" alignItems="center" color={textColor}>
            <TrendIndicator trend={trend} />
            <Text variant="small" fontWeight="600" color={textColor}>
                {price
                    ? formatMoney({
                          value: price.value,
                          currency: price.currency,
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 4,
                      })
                    : '-'}
            </Text>
        </Box>
    );
};
