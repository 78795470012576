import { components, OptionProps } from 'react-select';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const CustomPortOption = styled(components.Option)<OptionProps>`
    && {
        cursor: pointer;
        display: grid;

        grid-template-columns: 24px auto 24px;
        grid-template-rows: 24px 16px;
        column-gap: ${get('space.4')}px;
        align-items: center;
        height: 56px;
        padding: ${get('space.4')}px;
        padding-right: 16px;
        color: ${get('colors.foreground.default')};
        border-radius: 4px;
        background-color: ${(props) => {
            if (props.isFocused) {
                return get('colors.accent.background');
            } else {
                return get('colors.background.default');
            }
        }};

        &:active {
            background-color: ${get('colors.accent.background')};
        }
    }
`;
