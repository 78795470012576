import PropTypes from 'prop-types';

import { LogoHeader } from '../../common/headers/LogoHeader';
import { translate } from '../../common/helpers/translate.helper';
import { OfferState } from '../../offer/model/OfferState';

const getTitle = (offer) => {
    if (offer?.isOrder()) {
        return translate('anonymousQuotation.order');
    }

    if (offer?.state === OfferState.QUOTED) {
        return translate('anonymousQuotation.updateOffer');
    }

    if (offer && offer.state !== OfferState.QUOTED) {
        return translate('anonymousQuotation.createOffer');
    }

    return '';
};
export const AnonymousQuotationOfferHeader = ({ offer }) => <LogoHeader title={getTitle(offer)} />;

AnonymousQuotationOfferHeader.propTypes = {
    offer: PropTypes.object,
};
