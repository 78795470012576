import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { SupplierMessage } from '../../../../../types/SupplierMessage';
import { useRole } from '../../../../common/hooks/useRole';
import { FormikCreateEnquiryValues } from '../LubesCreateEnquiryPage';

export function useUpdateSupplierDefaults(
    suppliers: SupplierMessage[],
    defaultSupplierId?: string,
    customerId?: string,
    directOrderAllowed?: boolean,
    receiverSupplierIds?: string[]
) {
    const supplierIds = suppliers.map((supplier) => supplier.id);
    const [suppliersState, setSuppliersState] = useState<SupplierMessage[]>([]);

    const { setFieldValue, setFieldTouched, touched } = useFormikContext<FormikCreateEnquiryValues>();
    const role = useRole();

    const newDefaultSupplierId = defaultSupplierId
        ? supplierIds.includes(defaultSupplierId)
            ? defaultSupplierId
            : undefined
        : undefined;

    useEffect(() => {
        const differentSuppliers = JSON.stringify(suppliersState) !== JSON.stringify(suppliers);

        // List of suppliers has changed (initial or if vessel of different customer is selected)
        if (differentSuppliers && !role.usesCloselinkLite()) {
            setSuppliersState(suppliers);
            setFieldValue('assignedSupplierIds', supplierIds, false);
            setFieldValue('assignedSuppliers', suppliers, false);

            // Remove supplier from receiver list if not in new list
            if (receiverSupplierIds?.length && touched.receiverSupplierIds) {
                const newReceiverSupplierIds = receiverSupplierIds.filter((id) => supplierIds.includes(id));

                // Preselect remaining receiver suppliers
                if (newReceiverSupplierIds.length > 1 && newReceiverSupplierIds.length !== receiverSupplierIds.length) {
                    setFieldValue('receiverSupplierIds', newReceiverSupplierIds, false);
                    setFieldTouched('receiverSupplierIds', true, false);
                }

                // Set default supplier if no receiver supplier is left
                if (newReceiverSupplierIds.length === 0 && directOrderAllowed && newDefaultSupplierId) {
                    setFieldValue('receiverSupplierIds', [newDefaultSupplierId], false);
                }
            } else {
                // Set default supplier if no receiver supplier is set
                if (directOrderAllowed && newDefaultSupplierId && !touched.receiverSupplierIds) {
                    setFieldValue('receiverSupplierIds', [newDefaultSupplierId], false);
                    setFieldTouched('receiverSupplierIds', false, false);
                }
            }
        }
    }, [
        suppliersState,
        setSuppliersState,
        setFieldValue,
        setFieldTouched,
        suppliers,
        supplierIds,
        receiverSupplierIds,
        directOrderAllowed,
        newDefaultSupplierId,
        touched.receiverSupplierIds,
        role,
    ]);

    useEffect(() => {
        // set spot for lite customers
        if (role.usesCloselinkLite()) {
            setFieldValue('spot', role.isSpotAllowed(customerId));
        }
    }, [customerId, role, setFieldValue]);
}
