import styled, { css } from 'styled-components';

import { get } from '../../../common/ui/get';
import { TableBuilderProps } from '../TableBuilder';

export const StyledTable = styled.table<Pick<TableBuilderProps<unknown>, 'variant'>>`
    border: ${(props) => (props.variant === 'default' ? '1px' : 0)} solid ${get('colors.background.inset')};
    width: 100%;

    ${(props) =>
        props.variant === 'inline'
            ? css`
                  border-collapse: separate;
                  border-spacing: 0 ${get('space.2')}px;
              `
            : null}
`;
