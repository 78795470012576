import { ReactNode } from 'react';

import { IconButton } from '../../../../../cdl/IconButton/IconButton';
import { IconX } from '../../../../../common/icons/cdl/X';
import { Box } from '../../../../../common/ui/Box';

interface DeletableComponentProps {
    children: ReactNode;
    isDeletable: boolean;
    onDeleteClick: () => void;
}

export const DeletableComponent = ({ isDeletable, onDeleteClick, children }: DeletableComponentProps) => {
    return (
        <Box display="grid" gridTemplateColumns="1fr 40px" gridColumnGap={2}>
            {children}
            <Box marginTop="28px" marginLeft={4}>
                {isDeletable ? <IconButton icon={IconX} onClick={onDeleteClick} /> : null}
            </Box>
        </Box>
    );
};
