import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createKeyPort } from '../common/api/clients/keyPorts.api';
import { queries } from '../common/api/queryKeys/queries';

export const useKeyPortCreate = ({ onSuccess, onError }) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createKeyPort,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queries.keyPorts._def });
            onSuccess?.();
        },
        onError,
    });
};
