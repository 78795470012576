import { Formik, FormikErrors } from 'formik';

import { OfferApprovalSettingsMessage } from '../../../types/OfferApprovalSettingsMessage';
import { Modal } from '../../cdl/Modal/Modal';

import { FormikRequestApprovalMultipleApproversPopupContent } from './components/FormikRequestApprovalMultipleApproversPopupContent';
import { useApprovalRequestCreate } from './hooks/useMultipleApproversMutation';

interface RequestApprovalMultipleApproversPopupProps {
    offerApprovalSettings: OfferApprovalSettingsMessage;
    open: boolean;
    onDismiss: () => void;
    offerId: string;
    customerId: string;
}

export interface FormikRequestApprovalMultipleApproversState {
    requestMessage: string;
    approverEmailAddresses: string[];
}

export const RequestApprovalMultipleApproversPopup = (props: RequestApprovalMultipleApproversPopupProps) => {
    const approvalRequestCreateMutation = useApprovalRequestCreate(props.offerId);

    const hasOnlyOneApprover = props.offerApprovalSettings.suggestedApprovers.length === 1;
    const hasAtLeastOneApprover = props.offerApprovalSettings.suggestedApprovers.length >= 1;

    const formikInitialState: FormikRequestApprovalMultipleApproversState = {
        requestMessage: '',
        approverEmailAddresses:
            hasOnlyOneApprover || (hasAtLeastOneApprover && props.offerApprovalSettings.preventMultipleApprovers)
                ? [props.offerApprovalSettings.suggestedApprovers[0].emailAddress]
                : [],
    };

    const validate = (values: FormikRequestApprovalMultipleApproversState) => {
        const errors: FormikErrors<FormikRequestApprovalMultipleApproversState> = {};

        if (values.approverEmailAddresses.length < 1) {
            errors['approverEmailAddresses'] = 'At least one approver need to be defined';
        }

        return errors;
    };

    // Formik sets isSubmitting to false automatically after finishing an async function.
    // This is a bit annoying since we would quickly show the normal button state again right before closing the popup.
    const onSubmit = (values: FormikRequestApprovalMultipleApproversState) => {
        const onSubmitAsync = async () => {
            const approverEmailAddresses = Array.isArray(values.approverEmailAddresses)
                ? values.approverEmailAddresses
                : [values.approverEmailAddresses];

            await approvalRequestCreateMutation.mutateAsync({
                offerId: props.offerId,
                approvalRequestMessage: {
                    requestMessage: values.requestMessage ? values.requestMessage : undefined,
                    approvers: approverEmailAddresses.map((emailAddress) => ({
                        emailAddress,
                    })),
                    offerId: props.offerId,
                    customerId: props.customerId,
                },
            });

            props.onDismiss();
        };

        onSubmitAsync();
    };

    return (
        <Modal isOpen={props.open} onDismiss={props.onDismiss} dismissible={false}>
            <Formik initialValues={formikInitialState} validate={validate} onSubmit={onSubmit} validateOnMount>
                {(formikProps) => (
                    <FormikRequestApprovalMultipleApproversPopupContent
                        offerApprovalSettingsMessage={props.offerApprovalSettings}
                        onDismiss={props.onDismiss}
                        {...formikProps}
                    />
                )}
            </Formik>
        </Modal>
    );
};
