import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconOilBarrel = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M3 3a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2h-.504c.17.805.33 2.051.422 4H20a1 1 0 1 1 0 2h-.018a104.303 104.303 0 0 1 0 4H20a1 1 0 1 1 0 2h-.082c-.093 1.949-.251 3.195-.422 4H20a1 1 0 1 1 0 2H4a1 1 0 1 1 0-2h.504c-.17-.805-.33-2.051-.421-4H4a1 1 0 1 1 0-2h.018a104.458 104.458 0 0 1 0-4H4a1 1 0 0 1 0-2h.083c.092-1.949.25-3.195.42-4H4a1 1 0 0 1-1-1Zm3.563 1c-.026.09-.056.202-.087.342C6.251 5.357 6 7.518 6 12s.25 6.643.476 7.658c.031.14.06.253.087.342h10.874c.026-.09.056-.202.087-.342.225-1.015.476-3.176.476-7.658s-.25-6.643-.476-7.658A5.214 5.214 0 0 0 17.437 4H6.563Zm5.438 3a1 1 0 0 1 .822.432l2.596 3.765.03.046c1 1.632.595 3.698-.905 4.882a4.144 4.144 0 0 1-5.094 0c-1.499-1.185-1.904-3.25-.905-4.882l.03-.046 2.602-3.766A1 1 0 0 1 12.001 7Zm-1.311 7.555c-.724-.572-.89-1.509-.452-2.247l.452 2.247Zm-.452-2.247L12 9.76l1.757 2.548c.438.738.273 1.676-.45 2.247a2.145 2.145 0 0 1-2.616 0"
            clipRule="evenodd"
        />
    </svg>
);
