import { OptionProps } from 'react-select';

import { Text } from '../../../cdl/Text/Text';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { IconCheck } from '../../../common/icons/cdl/Check';
import { MiddleDotSeparator } from '../../../common/Separators/MiddleDotSeparator';
import { Box } from '../../../common/ui/Box';
import { theme } from '../../../common/ui/theme';
import { KeyPortHighlight } from '../../../keyport/KeyPortHighlight';
import { PortOptionWithScheduleType } from '../PortDetailSelectWithSchedule';

import { CustomPortOption } from './CustomPortOption';
import { ScheduleIndicator } from './ScheduleIndicator';

interface PortOptionWithScheduleProps extends Omit<OptionProps, 'children'> {
    data: PortOptionWithScheduleType;
}

export const PortOptionWithSchedule = (props: PortOptionWithScheduleProps) => {
    const portCountryWithLocode = `${props.data.port.country.name} (${props.data.port.locCode})`;

    const formattedArrivalDate = formatDateTime({
        date: props.data.scheduleEntry.eta,
        timeZoneId: props.data.scheduleEntry.timeZoneId,
    });

    return (
        <CustomPortOption {...props}>
            <Box height="100%">
                <ScheduleIndicator
                    hideScheduleLine={props.data.schedulesLength === 1}
                    isFirstScheduleStop={props.data.scheduleEntry.rank === 1}
                    isLastScheduleStop={props.data.scheduleEntry.rank === props.data.schedulesLength}
                />
            </Box>

            <Box gridColumn="2/3" gridRow="1/3">
                <Box display="flex" alignItems="center" overflow="hidden">
                    <Text
                        variant="small"
                        fontWeight="semiBold"
                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {props.label}
                    </Text>
                    &nbsp;
                    <KeyPortHighlight portId={props.data.port.id} vesselId={props.data.vesselId} />
                </Box>

                <Box display="flex" alignItems="center" overflow="hidden" gridColumn="1/2" gridRow="1/2">
                    <Text
                        variant="extraSmall"
                        color="foreground.muted"
                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {portCountryWithLocode}
                        <MiddleDotSeparator />
                        {formattedArrivalDate}
                    </Text>
                </Box>
            </Box>

            <Box>
                {props.isSelected ? (
                    <IconCheck height={24} width={24} style={{ color: theme.colors.accent.foreground }} />
                ) : null}
            </Box>
        </CustomPortOption>
    );
};
