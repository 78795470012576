import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { OnboardPage as OriginalOnboardPage } from './OnboardPage';

const OnboardPageWrapper = (props) => {
    return (
        <AppProviders>
            <OriginalOnboardPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(OnboardPageWrapper, 'reactOnboardPage');
