import { useRef, useState } from 'react';

import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { DataTable } from '../../../common/DataTable/DataTable';
import { Error } from '../../../common/Error/Error';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatPort } from '../../../common/helpers/formatPort.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { translateOfferState } from '../../../common/helpers/translateOfferState.helper';
import { useNavigation } from '../../../common/hooks/useNavigation';
import { useUpdateEffect } from '../../../common/hooks/useUpdateEffect';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Section } from '../../../common/Section/Section';
import { OfferModel } from '../../../offer/model/OfferModel';
import { RecentActivitiesEmptyState } from '../../common/components/RecentActivitiesEmptyState';
import { useFuelSupplierRecentActivities } from '../hooks/useFuelSupplierRecentActivities';

export const FuelSupplierRecentActivities = () => {
    const [page, setPage] = useState(0);
    const { navigate } = useNavigation();
    const wrapperRef = useRef<HTMLDivElement>(null);

    const recentActivitiesQuery = useFuelSupplierRecentActivities(page);

    useUpdateEffect(() => {
        if (wrapperRef.current) {
            window.scrollTo(0, wrapperRef.current.offsetTop);
        }
    }, [page]);

    if (recentActivitiesQuery.isPending) {
        return (
            <div ref={wrapperRef}>
                <Section title={translate('dashboard.recentactivities')}>
                    <LoadingIndicator />
                </Section>
            </div>
        );
    }

    if (recentActivitiesQuery.isError) {
        return (
            <div ref={wrapperRef}>
                <Section title={translate('dashboard.recentactivities')}>
                    <Error />
                </Section>
            </div>
        );
    }

    const recentActivitiesData = recentActivitiesQuery.data!!;

    if (!recentActivitiesData.content.length) {
        return (
            <div ref={wrapperRef}>
                <Section title={translate('dashboard.recentactivities')}>
                    <RecentActivitiesEmptyState />
                </Section>
            </div>
        );
    }

    const pageTotal = Math.ceil(recentActivitiesData.total / recentActivitiesData.pageable.size);

    const columns = [
        {
            width: '15%',
            label: translate('order.vendorreference'),
            value: (offer: any) => {
                if (!offer.vendorReference) {
                    return '-';
                }

                return offer.vendorReference;
            },
        },
        {
            width: '15%',
            label: translate('offer.customer'),
            value: (offer: any) => offer.customer.name,
        },
        {
            width: '15%',
            label: translate('vessel.name'),
            value: (offer: any) => formatVessel({ vessel: offer.vessel, short: true }),
        },
        {
            width: '25%',
            label: translate('order.port'),
            value: (offer: any) => formatPort({ port: offer.port, locode: false }),
        },
        {
            width: '15%',
            label: translate('order.dateDelivery'),
            value: function renderDateDelivery(offer: any) {
                if (!offer.dateDelivery) {
                    return '-';
                }

                return formatDate({
                    date: offer.dateDelivery,
                    timeZone: 'UTC',
                });
            },
        },
        {
            width: '15%',
            label: translate('order.status'),
            value: function renderOfferState(offer: any) {
                return translateOfferState({
                    offer,
                    userType: 'SUPPLIER',
                });
            },
        },
    ];

    const openOffer = (offer: OfferModel) => {
        navigate('base.fuel-offerlist-offer', {
            id: offer.id,
        });
    };

    return (
        <div ref={wrapperRef}>
            <Section title={translate('dashboard.recentactivities')}>
                <DataTable
                    columns={columns}
                    rows={recentActivitiesData.content}
                    onRowClick={({ row }) => openOffer(row)}
                    isRowUnread={({ row: offer }) => !offer.supplierRead}
                />

                <CenteredPagination pageTotal={pageTotal} currentPage={page} onPageChange={(page) => setPage(page)} />
            </Section>
        </div>
    );
};
