import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const CustomMultiValueContainer = styled.div`
    display: flex;
    height: 24px;
    background-color: ${get('colors.white')};
    padding: calc(${get('space.2')}px - 1px);
    border-radius: ${get('radii.1')}px;
    border: 1px solid ${get('colors.border.default')};
    margin-right: ${get('space.2')}px;
`;
