import { Form, Formik, useField } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Button } from '../../../cdl/Button/Button';
import { Callout } from '../../../cdl/Callout/Callout';
import { Modal } from '../../../cdl/Modal/Modal';
import { Text } from '../../../cdl/Text/Text.tsx';
import { FormikDatePicker } from '../../../common/form-elements/formik/FormikDatePicker';
import { FormikFormattedNumberInput } from '../../../common/FormattedNumberInput/FormikFormattedNumberInput';
import { formatNumber } from '../../../common/helpers/formatNumber.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { Characters } from '../../../common/helpers/unicode.helper';
import { useEnums } from '../../../common/hooks/useEnums';
import { Box } from '../../../common/ui/Box';

const FutureMeasurementDateCallout = () => {
    const [field] = useField('dateMeasured');

    if (field.value && moment(field.value).isAfter(moment().endOf('day'))) {
        return (
            <Box marginBottom={4}>
                <Callout
                    variant="attention"
                    description={translate('vessel.insertRemainingOnBoardPopup.dateMeasuredInFuture')}
                />
            </Box>
        );
    }

    return null;
};

export const InsertRemainingOnBoardPopup = ({ tanks, ...props }) => {
    const { getHumanReadableValue } = useEnums();

    const initialValues = {
        // Set dateMeasured to the users current date, but at the start of the day in UTC.
        // With the format we remove the timezone of the user.
        dateMeasured: moment.utc(moment().format('lll')).startOf('day').toDate().getTime(),
    };

    return (
        <Modal isOpen={props.open} onDismiss={props.onDismiss}>
            <Box display="flex" flexDirection="column" rowGap={5} padding={6}>
                <Text variant="title" as="h3">
                    {translate('vessel.insertRemainingOnBoardPopup.headline')}
                </Text>
                <Text variant="body" as="p" color="foreground.muted">
                    {translate('vessel.insertRemainingOnBoardPopup.description')}
                </Text>
                <Formik initialValues={initialValues} onSubmit={(data) => props.onSubmit({ data })}>
                    <Form>
                        <Box display="flex" flexDirection="column" gap={5}>
                            <FormikDatePicker
                                name="dateMeasured"
                                label={translate('vessel.insertRemainingOnBoardPopup.dateMeasured')}
                            />

                            <FutureMeasurementDateCallout />

                            {tanks.map((tank) => (
                                <FormikFormattedNumberInput
                                    key={tank.id}
                                    name={`stocks.${tank.id}.value`}
                                    label={
                                        tank.name +
                                        Characters.EN_SPACE +
                                        Characters.MIDDLE_DOT +
                                        Characters.EN_SPACE +
                                        getHumanReadableValue('vesselTankCategory', tank.category)
                                    }
                                    meta={
                                        tank.maxVolume
                                            ? translate('vessel.insertRemainingOnBoardPopup.stockAnnotation', {
                                                  maxVolume: formatNumber({ number: tank.maxVolume }),
                                              })
                                            : null
                                    }
                                    trailingVisual="L"
                                />
                            ))}

                            <Box display="flex" columnGap={4} marginTop={5} justifyContent="end">
                                <Button emphasis="high" type="submit">
                                    {translate('vessel.insertRemainingOnBoardPopup.updateButton')}
                                </Button>
                            </Box>
                        </Box>
                    </Form>
                </Formik>
            </Box>
        </Modal>
    );
};

InsertRemainingOnBoardPopup.propTypes = {
    open: PropTypes.bool,
    onDismiss: PropTypes.func,
    onSubmit: PropTypes.func,
    tanks: PropTypes.array,
};
