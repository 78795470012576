TokenInterceptor.$inject = ['SessionStorageService'];

function TokenInterceptor(SessionStorageService) {
    const AUTH_HEADER = 'Authorization';
    const BEARER = 'Bearer ';
    return {
        request: function (config) {
            const token = SessionStorageService.getToken();
            config.headers = config.headers || {};

            if (token) {
                config.headers[AUTH_HEADER] = BEARER + token;
            }
            return config;
        },
    };
}

export default {
    name: 'TokenInterceptor',
    fn: TokenInterceptor,
};
