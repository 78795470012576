import css from '@styled-system/css';
import { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { useAngularDependencies } from '../hooks/useAngularDependencies';
import { useOutsideClick } from '../hooks/useOutsideClick';
import { useRole } from '../hooks/useRole';
import { IconChevronDown } from '../icons/cdl/ChevronDown';
import { IconChevronUp } from '../icons/cdl/ChevronUp';
import { Box } from '../ui/Box';
import { Flex } from '../ui/Flex';

const UserAvatar = styled(Flex)(
    css({
        backgroundColor: 'striking-purple.0',
        borderRadius: '100%',
        height: '32px',
        width: '32px',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        ':hover': {
            opacity: 0.75,
        },
    })
);

const UserMenuLink = styled(Text).attrs({
    as: 'a',
})(
    css({
        display: 'block',
        paddingX: 6,
        paddingY: 5,
        borderBottom: 1,
        minWidth: '120px',
        cursor: 'pointer',
        ':hover': {
            color: 'clear-blue.0',
            textDecoration: 'none',
            backgroundColor: 'clear-blue.9',
        },
    })
);

const UserMenuPopout = styled(Box)(
    css({
        position: 'absolute',
        backgroundColor: 'white',
        top: '70px',
        right: 3,
        border: 1,
        borderRadius: 1,
        boxShadow: 'medium',
    })
);

const Arrow = styled(Box)(
    css({
        borderRadius: '100%',
        backgroundColor: 'white',
        width: '12px',
        height: '12px',
        color: 'black',
        position: 'absolute',
        right: 0,
        transform: 'translateX(50%)',
    })
);

export const UserMenu = () => {
    const role = useRole();
    const { SessionService, $state } = useAngularDependencies();
    const [showMenu, setShowMenu] = useState(false);
    const userMenuRef = useRef(null);
    const avatarRef = useRef(null);

    const hideMenu = useCallback(() => {
        setShowMenu(false);
    }, [setShowMenu]);

    const logout = () => {
        SessionService.logout();
        $state.go('login');
    };

    useOutsideClick([userMenuRef, avatarRef], hideMenu);

    return (
        <div data-test-id="UserMenu">
            <UserAvatar onClick={() => setShowMenu((prev) => !prev)} ref={avatarRef}>
                <Text color="white" weight="semi" size={10}>
                    {role.user.firstname[0]}
                    {role.user.lastname[0]}
                </Text>
                <Arrow>
                    {showMenu ? <IconChevronUp width={12} height={12} /> : <IconChevronDown width={12} height={12} />}
                </Arrow>
            </UserAvatar>

            {showMenu ? (
                <UserMenuPopout ref={userMenuRef}>
                    <UserMenuLink
                        onClick={hideMenu}
                        href={$state.href('base.users-user', {
                            id: role.user.id,
                        })}
                    >
                        Settings
                    </UserMenuLink>
                    <UserMenuLink onClick={logout}>Logout</UserMenuLink>
                </UserMenuPopout>
            ) : null}
        </div>
    );
};
