import { NotificationSettings } from '../../../../../types/VesselMessage';

export const setRelatedNotificationSettings = (settings: NotificationSettings) => {
    if (!settings.enabled) {
        return settings;
    }

    if (settings.sendToOthers && settings.recipients.length === 0) {
        settings.sendToOthers = false;
    }

    if (!settings.sendToVessel && !settings.sendToOthers) {
        settings.enabled = false;
    }
    return settings;
};
