import styled from 'styled-components';

import { get } from '../../../../../../common/ui/get';

export const OfferSupplierAndTotalsGrid = styled.div`
    display: grid;
    grid-template-columns: 40px 1fr auto;
    grid-template-rows: 40px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${get('colors.border.muted')};
    padding-bottom: ${get('space.4')}px;
    gap: ${get('space.5')}px;
`;
