import { useQuery } from '@tanstack/react-query';

import { CountryMessage } from '../../../../../types/CountryMessage';
import { queries } from '../../../../common/api/queryKeys/queries';
import { useEnums } from '../../../../common/hooks/useEnums';

export const useRegionToCountriesMap = (): Map<string, CountryMessage[]> => {
    const { getEnumsForTypeAndContext } = useEnums();
    const countriesQuery = useQuery(queries.countries.list());
    const regions = getEnumsForTypeAndContext('region');

    const countries = countriesQuery.data;
    if (!countries) {
        return new Map<string, CountryMessage[]>();
    }

    const sortedCountries = countries.sort((a, b) => a.name.localeCompare(b.name));

    return Object.keys(regions).reduce((acc, region) => {
        const countriesForRegion = sortedCountries.filter((country) => country.region === region);
        const regionEntry = acc.get(region);

        if (regionEntry) {
            regionEntry.push(...countriesForRegion);
        } else {
            acc.set(region, countriesForRegion);
        }

        return acc;
    }, new Map<string, CountryMessage[]>());
};
