import storage from 'local-storage-fallback';

const prefix = 'preferReact.';

export const flag = (key, value = true) => {
    storage.setItem(prefix + key, value);
    window.location.reload();
};

/**
 * check user preference whether to use new react components.
 * return true for shiny new react, false for old angular
 */
export const checkPreference = (key, def = false) => {
    return JSON.parse(storage.getItem(prefix + key, def));
};
