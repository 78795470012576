import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { SupplierDetailPage as OriginalSupplierDetailPage } from './SupplierDetailPage';

const SupplierDetailPage = (props) => (
    <AppProviders>
        <OriginalSupplierDetailPage {...props} />
    </AppProviders>
);

export default createDirectiveFromReactComponent(SupplierDetailPage, 'reactSupplierDetailPage');
