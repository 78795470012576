import PropTypes from 'prop-types';
import { components } from 'react-select';
import { default as ReactAsyncSelect } from 'react-select/async';
import styled from 'styled-components';

import { theme } from '../../ui/theme';
import { CustomOption } from '../Select/CustomOption';
import { selectStyles } from '../Select/Select.styles';

const { Control: OriginalControl, Placeholder: OriginalPlaceholder } = components;

const Placeholder = styled(OriginalPlaceholder)`
    && {
        padding-left: 2px;
        color: ${theme.colors['dark-blue'][3]};
    }
`;

const Control = styled(OriginalControl)`
    && {
        min-height: 39px; // to give selects the same height as the inputs
        border-color: ${theme.colors['dark-blue'][6]};
        :hover {
          border-color: ${theme.colors['clear-blue'][0]}
        }
`;

const IndicatorSeparator = () => null;

/**
 * @deprecated use CDL ComboBox instead
 */

export const AsyncSearchSelect = (props) => {
    const rerenderValue = props.rerenderValue ?? Math.random();
    const styles = props.styles ?? selectStyles;

    return (
        <ReactAsyncSelect
            key={rerenderValue}
            defaultOptions
            components={{
                Option: CustomOption,
                IndicatorSeparator,
                Control,
                Placeholder,
                ...props.components,
            }}
            styles={styles}
            onChange={props.onChange}
            onBlur={props.onBlur}
            loadOptions={props.loadOptions}
            placeholder={props.placeholder}
            value={props.values}
            isMulti={props.isMulti}
            isClearable={props.isClearable}
            isDisabled={props.isDisabled}
            className={props.className}
            required={props.required}
        />
    );
};

AsyncSearchSelect.propTypes = {
    loadOptions: PropTypes.func,
    values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    components: PropTypes.object,
    rerenderValue: PropTypes.any,
    styles: PropTypes.object,
    required: PropTypes.bool,
};
