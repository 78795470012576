import PropTypes from 'prop-types';
import { forwardRef, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'react-day-picker/moment';
import styled from 'styled-components';

import { translate } from '../../helpers/translate.helper';
import { IconCalendar } from '../../icons/cdl/Calendar';
import { Box } from '../../ui/Box.tsx';
import { Flex } from '../../ui/Flex.tsx';
import { useFormData } from '../Form/useFormData';
import { Label } from '../Label/Label';
import { StyledInput } from '../TextInput/Input';

const IconBox = styled(Flex)({
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 5,
    alignItems: 'center',
});

/**
 * @deprecated Use cdl DatePicker instead 

 */

export const DeprecatedDatePicker = ({
    label,
    value,
    placeholder = translate('datepicker.placeholder'),
    annotation,
    required,
    disabledBeforeDate,
    disabled,
    ...input
}) => {
    const [isDirty, setIsDirty] = useState(false);
    const formattedValue = value ? moment.formatDate(value, 'DD.MM.YYYY').toString() : null;

    return (
        <Label label={label} annotation={annotation} required={required}>
            <Box position="relative">
                <IconBox color="dark-blue.3">
                    <IconCalendar />
                </IconBox>
                <DayPickerInput
                    formatDate={moment.formatDate}
                    parseDate={moment.parseDate}
                    format="DD.MM.YYYY"
                    component={forwardRef(function StyledInputRef(props, ref) {
                        return (
                            <StyledInput
                                paddingRight={5}
                                paddingLeft={7}
                                paddingY={4}
                                border={1}
                                borderRadius={1}
                                required={required}
                                className={isDirty ? 'dirty' : ''}
                                onBlur={() => setIsDirty(true)}
                                ref={ref}
                                disabled={disabled}
                                {...props}
                                placeholder={placeholder}
                            />
                        );
                    })}
                    className={isDirty ? 'dirty' : ''}
                    style={{ display: 'block' }}
                    value={formattedValue}
                    onDayPickerHide={() => setIsDirty(true)}
                    dayPickerProps={{
                        disabledDays: { before: disabledBeforeDate },
                    }}
                    {...input}
                />
            </Box>
        </Label>
    );
};

DeprecatedDatePicker.propTypes = {
    label: PropTypes.node,
    focus: PropTypes.bool,
    annotation: PropTypes.string,
    required: PropTypes.bool,
    dataPath: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    disabledBeforeDate: PropTypes.instanceOf(Date),
    disabled: PropTypes.bool,
};

export const FormDatePicker = ({ dataPath, disabledBeforeDateDataPath, transformValue, disabled, ...props }) => {
    const { onChange, value } = useFormData(dataPath, { transformValue });
    const { value: disabledBeforeDateValue } = useFormData(disabledBeforeDateDataPath, {
        transformValue,
    });

    const disabledBeforeDate = disabledBeforeDateValue ? new Date(disabledBeforeDateValue) : null;

    const onDayChange = (day) => {
        onChange({
            target: {
                value: day?.toISOString(),
            },
        });
    };

    return (
        <DeprecatedDatePicker
            onDayChange={onDayChange}
            value={value}
            disabledBeforeDate={disabledBeforeDate}
            disabled={disabled}
            {...props}
        />
    );
};

FormDatePicker.propTypes = {
    label: PropTypes.node,
    transformValue: PropTypes.func,
    dataPath: PropTypes.string.isRequired,
    disabledBeforeDateDataPath: PropTypes.string,
    disabled: PropTypes.bool,
};
