import styled from 'styled-components';

import demandOverviewURL from '../../../images/demand-overview.png';
import { IconButton } from '../../cdl/IconButton/IconButton';
import { Tag } from '../../cdl/Tag/Tag';
import { Text } from '../../cdl/Text/Text';
import { TextButton } from '../../common/buttons/TextButton';
import { translate } from '../../common/helpers/translate.helper';
import { useSessionStorage } from '../../common/hooks/useSessionStorage';
import { useStartUserlikeChat } from '../../common/hooks/useStartUserlikeChat';
import { IconArrowRight } from '../../common/icons/cdl/ArrowRight';
import { IconX } from '../../common/icons/cdl/X';
import { Box } from '../../common/ui/Box';
import { get } from '../../common/ui/get';
import { theme } from '../../common/ui/theme';

const Banner = styled(Box)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: ${theme.colors.background.subtle};
    width: 100%;
    padding: ${get('space.7')}px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    gap: ${get('space.7')}px;

    @media (max-width: 1400px) {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
    }

    @media (max-width: 900px) {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
    }
`;

const ImageContainer = styled(Box)`
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 85%;

    @media (min-width: 1400px) {
        width: 80%;
    }

    @media (max-width: 900px) {
        display: none;
    }
`;

export const DemandLubesReachBanner = () => {
    const [dismissed, setDismissed] = useSessionStorage('reachBanner', false);
    const startUserLike = useStartUserlikeChat();

    if (dismissed) {
        return null;
    }

    return (
        <Banner>
            <Box display="flex" flexDirection="column" gap={5} alignItems="start">
                <Text variant="title">{translate('demand.reachBanner.title')}</Text>
                <Text variant="body" color="foreground.subtle">
                    {translate('demand.reachBanner.text')}
                </Text>
                <Tag variant="default">{translate('demand.reachBanner.setupRequired')}</Tag>
                <TextButton onClick={startUserLike}>
                    {translate('demand.reachBanner.contactSupport')}
                    <IconArrowRight />
                </TextButton>
            </Box>

            <ImageContainer>
                <img
                    alt=""
                    src={demandOverviewURL}
                    width="100%"
                    style={{
                        borderRadius: 2,
                        boxShadow: 'large',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                />
            </ImageContainer>

            <IconButton
                icon={IconX}
                onClick={() => {
                    setDismissed('true');
                }}
                style={{ position: 'absolute', top: 0, right: 0 }}
            />
        </Banner>
    );
};
