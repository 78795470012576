import { useState } from 'react';
import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { PlattsCredentialsPopup } from '../../company/customer/detail/integrations/PlattsCredentialsPopup';
import { TextButton } from '../buttons/TextButton';
import { translate } from '../helpers/translate.helper';
import { useRole } from '../hooks/useRole';
import { InfoPopover } from '../InfoPopover/InfoPopover';
import { get } from '../ui/get';

interface MissingPlattsPopoverProps {
    customerId: string;
}

const MissingPlattsPopover = ({ customerId }: MissingPlattsPopoverProps) => {
    const [showPopover, setShowPopover] = useState(false);

    const role = useRole();

    return (
        <>
            <InfoPopover width="260px" align="start">
                <Text as="p" variant="extraSmall" color="foreground.default">
                    {translate('platts.missingPlattsCredentialsPopover.text')}
                </Text>
                {role.hasAnyAdminRights() ? (
                    <div>
                        <TextButton
                            style={{ fontSize: '12px' }}
                            onClick={() => {
                                setShowPopover(true);
                            }}
                        >
                            {translate('platts.missingPlattsCredentialsPopover.btn')}
                        </TextButton>
                    </div>
                ) : null}
            </InfoPopover>
            <PlattsCredentialsPopup
                data={{
                    customerId: customerId,
                    username: '',
                    password: '',
                    appKey: '',
                }}
                isOpen={showPopover}
                onDismiss={() => {
                    setShowPopover(false);
                }}
                credentialsExists={false}
            />
        </>
    );
};

const Link = styled.a`
    color: ${get('colors.accent.foreground')};
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    &:hover,
    &:focus {
        // required, because *.less override hover
        // styles for all a tags
        color: ${get('colors.accent.foreground')};
        text-decoration: solid;
    }
`;

const RestrictedAccessPopover = () => {
    return (
        <InfoPopover width="260px" align="start">
            <Text as="p" variant="extraSmall" color="foreground.default">
                {translate('platts.restrictedAccessPopover.text')}
            </Text>
            <div>
                <Link href={translate('platts.restrictedAccessPopover.btnUrl')}>
                    {translate('platts.restrictedAccessPopover.btn')}
                </Link>
            </div>
        </InfoPopover>
    );
};

interface EmptyFuelPricePopoverProps {
    customerId: string;
    hasPlattsCredentials: boolean;
}

export const EmptyFuelPricePopover = ({ customerId, hasPlattsCredentials }: EmptyFuelPricePopoverProps) => {
    return hasPlattsCredentials ? <RestrictedAccessPopover /> : <MissingPlattsPopover customerId={customerId} />;
};
