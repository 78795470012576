import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { TextButton } from '../../common/buttons/TextButton';
import { DataSheet } from '../../common/DataSheet/DataSheet';
import { formatAddress } from '../../common/helpers/formatAddress.helper';
import { formatCompanyName } from '../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../common/helpers/formatDate.helper';
import { formatVessel } from '../../common/helpers/formatVessel.helper';
import { notAvailable } from '../../common/helpers/notAvailable.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';
import { IconDownload } from '../../common/icons/cdl/Download';
import { YesOrNo } from '../../common/YesOrNo/YesOrNo';

export const LubesVesselDetailDataSheet = ({ vessel }) => {
    const role = useRole();

    const makeInformation = () => {
        const data = {};

        if (!role.isOneCompanyUser()) {
            data[translate('vessel.company')] = formatCompanyName({
                company: vessel.customer,
                includeType: role.isAdmin(),
            });
        }

        data[translate('order.vessel')] = formatVessel({ vessel });
        data[translate('vessel.lastedit')] = notAvailable({
            value: formatDate({ date: vessel.dateUpdated }),
        });
        data[translate('company.invoiceVAT')] = notAvailable({
            value: vessel.invoiceVAT,
        });
        data[translate('vessel.defaultsupplier')] = notAvailable({
            value:
                vessel.defaultSupplier &&
                formatCompanyName({
                    company: vessel.defaultSupplier,
                    includeType: role.isAdmin(),
                }),
        });
        data[translate('global.status')] = vessel.active ? 'Active' : 'Inactive';
        data[translate('global.contact.email')] = notAvailable({
            value: vessel.email,
        });
        data[translate('global.address.invoiceaddress')] = notAvailable({
            value: formatAddress({ address: vessel.invoiceAddress }),
        });
        data[translate('vessel.lubricationChart')] = notAvailable({
            value: vessel.lubricationChart ? (
                <TextButton as="a" href={vessel.lubricationChart.downloadUrl} target="_blank">
                    <IconDownload />
                    {vessel.lubricationChart.name}
                </TextButton>
            ) : null,
        });

        if (role.isAdmin()) {
            data[translate('vessel.paying')] = <YesOrNo value={vessel.paying} />;
            data[translate('vessel.directOrderAllowed')] = <YesOrNo value={vessel.directOrderAllowed} />;
        }

        return data;
    };

    const vesselData = makeInformation();
    return (
        <DataSheet>
            {Object.keys(vesselData).map((key, index) => (
                <Fragment key={index}>
                    <DataSheet.Label>{key}</DataSheet.Label>
                    <span>{vesselData[key]}</span>
                </Fragment>
            ))}
        </DataSheet>
    );
};

LubesVesselDetailDataSheet.propTypes = {
    vessel: PropTypes.object,
};
