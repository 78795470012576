import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { updateSupplierSettings } from '../../../common/api/clients/company.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { translate } from '../../../common/helpers/translate.helper';

export const useSupplierSettingsEdit = () => {
    const queryClient = useQueryClient();
    const { addErrorToast } = useToasts();

    return useMutation({
        mutationFn: updateSupplierSettings,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: queryKeys.supplierSettings(data.supplierId) });
        },
        onError: () => {
            addErrorToast(translate('company.toasts.informationUpdateError'));
        },
    });
};
