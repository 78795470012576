import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { LubesDashboard as OriginalLubesDashboard } from './LubesDashboard.tsx';

const LubesDashboard = (props) => (
    <AppProviders>
        <OriginalLubesDashboard {...props} />
    </AppProviders>
);

export default createDirectiveFromReactComponent(LubesDashboard, 'reactLubesDashboard');
