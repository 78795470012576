import { ErrorCode, useDropzone } from 'react-dropzone';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../helpers/translate.helper';
import { attachmentFileFormats } from '../utils/attachmentFileFormats';

export const useUploadDropzone = (onFilesDrop: (droppedFiles: File[]) => void) => {
    const { addErrorToast } = useToasts();

    return useDropzone({
        onDrop: (files) => onFilesDrop(files),
        onDropRejected: (rejections) => {
            const firstError = rejections[0]?.errors[0]?.code as ErrorCode | undefined;

            let firstErrorTranslation = translate('attachments.toast.invalidFilesError.unknownError');

            if (firstError) {
                switch (firstError) {
                    case ErrorCode.FileInvalidType:
                        firstErrorTranslation = translate('attachments.toast.invalidFilesError.invalidFileFormat');
                        break;
                    case ErrorCode.FileTooLarge:
                        firstErrorTranslation = translate('attachments.toast.invalidFilesError.invalidFileSize');
                        break;
                    case ErrorCode.TooManyFiles:
                        firstErrorTranslation = translate(
                            'attachments.toast.invalidFilesError.multipleFilesNotAllowed'
                        );
                        break;
                }
            }

            addErrorToast(firstErrorTranslation);
        },
        multiple: false,
        noClick: true,
        maxSize: 1e7, // 10MB in bytes
        maxFiles: 20,
        accept: attachmentFileFormats,
    });
};
