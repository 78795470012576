import PropTypes from 'prop-types';

import { Button } from '../cdl/Button/Button';
import { useDrawer } from '../cdl/Drawer/useDrawer.hook';
import { DataSheet } from '../common/DataSheet/DataSheet';
import { translate } from '../common/helpers/translate.helper';
import { Section } from '../common/Section/Section';
import { YesOrNo } from '../common/YesOrNo/YesOrNo';

import { AdminSettingsForm } from './AdminSettingsForm';

export const AdminSettings = ({ companyDetails, onEditSubmit, companyType }) => {
    const { push, pop } = useDrawer();

    const onSubmit = ({ event, data }) => {
        event.preventDefault();
        onEditSubmit(data);
        pop();
    };

    const openAdminSettingsModal = () => {
        push({
            content: (
                <AdminSettingsForm companyDetails={companyDetails} onSubmit={onSubmit} companyType={companyType} />
            ),
        });
    };

    return (
        <Section
            title={translate('company.adminSettings')}
            actions={<Button onClick={openAdminSettingsModal}>{translate('company.editSettings')}</Button>}
        >
            <DataSheet>
                <DataSheet.Label>{translate('company.testCompany')}</DataSheet.Label>
                <YesOrNo value={companyDetails.testCompany} />

                {companyType === 'CUSTOMER' ? (
                    <>
                        <DataSheet.Label>{translate('company.spotallowed')}</DataSheet.Label>
                        <YesOrNo value={companyDetails.spotAllowed} />
                    </>
                ) : null}

                {companyType === 'SUPPLIER' ? (
                    <>
                        <DataSheet.Label>{translate('company.showGtc')}</DataSheet.Label>
                        <YesOrNo value={companyDetails.showGtc} />
                    </>
                ) : null}

                <DataSheet.Label>{translate('company.trial')}</DataSheet.Label>
                <YesOrNo value={companyDetails.trial} />

                <DataSheet.Label>{translate('global.mailsettings.receiveNews')}</DataSheet.Label>
                <YesOrNo value={companyDetails.mailSettings.newsletter} />

                <DataSheet.Label>{translate('global.mailsettings.receiveUpdates')}</DataSheet.Label>
                <YesOrNo value={companyDetails.mailSettings.transactional} />
            </DataSheet>
        </Section>
    );
};

AdminSettings.propTypes = {
    companyDetails: PropTypes.object.isRequired,
    onEditSubmit: PropTypes.func.isRequired,
    companyType: PropTypes.string.isRequired,
};
