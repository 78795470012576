import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { scrollOverlayToTop } from '../../../common/Overlay/Overlay';

import { EnquiryCreationContext } from './EnquiryCreationContext';

export const EnquiryCreationContextProvider = ({ children }) => {
    const [hasMounted, setHasMounted] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [search] = useSearchParams();

    const page = location.state?.page ?? 1;
    const orderId = search.get('orderId');

    const setPage = useCallback(
        (page) => {
            if (orderId) {
                navigate(`/fuel/create?orderId=${orderId}`, {
                    state: {
                        page,
                    },
                });
                return;
            }

            navigate('/fuel/create', {
                state: {
                    page,
                },
            });
        },
        [orderId, navigate]
    );

    // Redirect to first step on page refresh.
    useEffect(() => {
        if (!orderId) {
            setPage(1);
        }

        setHasMounted(true);
    }, [orderId, setPage]);

    useEffect(() => {
        scrollOverlayToTop();
    }, [page]);

    const contextValue = {
        page,
        setPage,
    };

    if (!hasMounted) {
        return null;
    }

    return <EnquiryCreationContext.Provider value={contextValue}>{children}</EnquiryCreationContext.Provider>;
};

EnquiryCreationContextProvider.propTypes = {
    children: PropTypes.node,
};
