import { ComponentType, Fragment, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { get } from '../../common/ui/get';

export interface CaptionProps {
    $negative?: boolean;
    icon?: ComponentType<{ width?: number; height?: number }>;
    children: ReactNode;
}

const negativeStyles = css`
    color: ${get('colors.negative.foreground')};
`;

interface CaptionWrapperProps {
    $negative: boolean;
}

const CaptionWrapper = styled.span<CaptionWrapperProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    color: ${get('colors.foreground.subtle')};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 4px;
    width: 100%;
    ${(p) => (p.$negative ? negativeStyles : null)};
`;

export const Caption = ({ children, $negative = false, icon }: CaptionProps) => {
    const IconComponent = icon || Fragment;
    return (
        <CaptionWrapper $negative={$negative}>
            {icon ? <IconComponent height={16} width={16} /> : null}
            {children}
        </CaptionWrapper>
    );
};
