import { useFormikContext } from 'formik';

import { UserType } from '../../../types/UserType';
import { FormikCustomerCompanySelect } from '../../common/form-elements/formik/FormikCustomerCompanySelect';
import { FormikSupplierCompanySelect } from '../../common/form-elements/formik/FormikSupplierCompanySelect';
import { translate } from '../../common/helpers/translate.helper';

import { FormikEditUserState } from './EditUserForm';

export const FormikEditUserFormCompanyMultiSelect = () => {
    const { values } = useFormikContext<FormikEditUserState>();
    const isCustomer = values.userType === UserType.CUSTOMER;

    if (isCustomer) {
        return (
            <FormikCustomerCompanySelect
                name="companyIds"
                groupId={values.groupId}
                label={translate('user.companies')}
                isMulti
            />
        );
    }

    return (
        <FormikSupplierCompanySelect
            name="companyIds"
            groupId={values.groupId}
            label={translate('user.companies')}
            isMulti
        />
    );
};
