import { AppProviders } from '../common/AppProviders';
import { createDirectiveFromReactComponent } from '../common/helpers/createDirectiveFromReactComponent';

import { StatisticsPage as OriginalStatisticsPage } from './StatisticsPage';

const StatisticsPage = (props) => {
    return (
        <AppProviders>
            <OriginalStatisticsPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(StatisticsPage, 'reactStatisticsPage');
