import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconGameplan = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M17.347 3.534a1 1 0 0 1 .607.466l2 3.464a1 1 0 1 1-1.732 1l-.793-1.373-.107.4c-.009.032-.018.07-.03.11-.242.934-1.065 4.093-4.641 7.158-1.87 1.602-3.865 2.531-5.385 3.06-.762.265-1.41.431-1.873.532a11.688 11.688 0 0 1-.703.132l-.044.006-.014.002h-.006s-.002 0-.126-.992l.124.993a1 1 0 0 1-.25-1.985h.002l.026-.004.111-.017c.102-.017.256-.045.454-.089.396-.086.966-.232 1.642-.467 1.355-.471 3.11-1.293 4.74-2.69 3.111-2.666 3.804-5.349 4.013-6.16m.028-.107.107-.4-1.373.793a1 1 0 0 1-1-1.732l3.464-2a1 1 0 0 1 .759-.1M7.518 6.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM4.018 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Zm10.293 6.793a1 1 0 0 1 1.414 0l1.293 1.29 1.294-1.29a1 1 0 0 1 1.414 1.414L18.434 17.5l1.292 1.293a1 1 0 0 1-1.414 1.415l-1.294-1.292-1.293 1.292a1 1 0 0 1-1.414-1.415l1.291-1.293-1.29-1.292-.001-.001a1 1 0 0 1 0-1.414Zm2.002 3.414h.001l-.002.003.704.704m.709-.71.004.003.703-.705m-.709-.709h-.001l.003-.003-.705-.704"
            clipRule="evenodd"
        />
    </svg>
);
