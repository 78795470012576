import { ComponentType } from 'react';
import { components, ControlProps } from 'react-select';
import styled, { css } from 'styled-components';

import { Characters } from '../../../common/helpers/unicode.helper';
import { get } from '../../../common/ui/get';
import { SelectProps } from '../Select';

const positiveStyles = css`
    background-color: ${get('colors.positive.background')};
    border-color: ${get('colors.positive.border')};
`;

const negativeStyles = css`
    background-color: ${get('colors.negative.background')};
    border-color: ${get('colors.negative.border')};
`;

interface CustomControlWrapperProps<A> extends ControlProps<A> {
    $positive: boolean;
    $negative: boolean;
}

const CustomControlWrapper = styled(components.Control)<CustomControlWrapperProps<any>>`
    && {
        background: ${(props) => {
            if (props.isDisabled) {
                return get('colors.background.subtle');
            }
            if (props.menuIsOpen) {
                return get('colors.accent.background');
            }
            return get('colors.background.inset');
        }};
        border: 2px solid transparent;
        outline: none;
        box-shadow: none;
        min-height: 40px;
        padding: ${get('space.3')}px 10px ${get('space.3')}px ${get('space.1')}px;
        ${(props) => (props.$positive && !props.menuIsOpen ? positiveStyles : null)}
        ${(props) => (props.$negative && !props.menuIsOpen ? negativeStyles : null)}
        &:hover {
            border-color: transparent;
            ${(props) => (props.$positive && !props.menuIsOpen ? positiveStyles : null)}
            ${(props) => (props.$negative && !props.menuIsOpen ? negativeStyles : null)}
        }
    }
` as ComponentType<CustomControlWrapperProps<any>>;

const LabelText = styled.div<Pick<ControlProps, 'menuIsOpen' | 'isDisabled'>>`
    color: ${(props) => {
        if (props.isDisabled) {
            return get('colors.foreground.disabled');
        }

        return props.menuIsOpen ? get('colors.accent.foreground') : get('colors.foreground.muted');
    }};
    font-size: ${get('fontSizes.2')}px;
    font-weight: 500;
    line-height: 24px;
    transition: color 75ms;
`;

interface CustomControlProps<Option, IsMulti extends boolean>
    extends ControlProps<Option, IsMulti>,
        Pick<SelectProps<Option>, 'label'> {
    $positive: boolean;
    $negative: boolean;
    markAsRequired: boolean;
}

export function CustomControl<Option, IsMulti extends boolean>({
    label,
    menuIsOpen,
    isDisabled,
    $positive,
    $negative,
    markAsRequired,
    ...rest
}: CustomControlProps<Option, IsMulti>) {
    return (
        <div>
            {label ? (
                <LabelText menuIsOpen={menuIsOpen} isDisabled={isDisabled}>
                    {label}
                    &nbsp;
                    {markAsRequired ? Characters.BULLET : null}
                </LabelText>
            ) : null}
            <CustomControlWrapper
                {...rest}
                menuIsOpen={menuIsOpen}
                isDisabled={isDisabled}
                $positive={$positive}
                $negative={$negative}
            />
        </div>
    );
}
