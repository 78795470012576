import { Form, FormikProps } from 'formik';
import { FormEventHandler } from 'react';

import { ApproverMessage } from '../../../../types/ApproverMessage';
import { OfferApprovalSettingsMessage } from '../../../../types/OfferApprovalSettingsMessage';
import { Button } from '../../../cdl/Button/Button';
import { FormikRadioGroup } from '../../../cdl/RadioGroup/FormikRadioGroup';
import { Text } from '../../../cdl/Text/Text';
import { FormikTextArea } from '../../../cdl/TextArea/FormikTextArea';
import { ButtonGroup } from '../../../common/buttons/ButtonGroup';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';
import { FormikRequestApprovalMultipleApproversState } from '../RequestApprovalMultipleApproversPopup';

import { AdditionalApprovers } from './AdditionalApprovers';
import { ApproverList } from './ApproverList';
import { TopLevelApprover } from './TopLevelApprover';

interface FormikRequestApprovalMultipleApproversPopupContentProps
    extends FormikProps<FormikRequestApprovalMultipleApproversState> {
    offerApprovalSettingsMessage: OfferApprovalSettingsMessage;
    onDismiss: () => void;
}

export const FormikRequestApprovalMultipleApproversPopupContent = (
    props: FormikRequestApprovalMultipleApproversPopupContentProps
) => {
    const preventMultipleApprovers: boolean = props.offerApprovalSettingsMessage.preventMultipleApprovers;

    const isApproverSelected = (approver: ApproverMessage): boolean => {
        return props.values.approverEmailAddresses.includes(approver.emailAddress);
    };

    const onApproverChange = (approver: ApproverMessage) => () => {
        if (preventMultipleApprovers) {
            props.setFieldValue('approverEmailAddresses', [approver.emailAddress]);
        } else {
            if (isApproverSelected(approver)) {
                props.setFieldValue(
                    'approverEmailAddresses',
                    props.values.approverEmailAddresses.filter(
                        (currentEmailAddress) => currentEmailAddress !== approver.emailAddress
                    )
                );
            } else {
                props.setFieldValue(
                    'approverEmailAddresses',
                    props.values.approverEmailAddresses.concat(approver.emailAddress)
                );
            }
        }
    };

    const hasOnlyOneApprover: boolean = props.offerApprovalSettingsMessage.suggestedApprovers.length === 1;

    const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();

        if (props.isSubmitting) {
            return;
        }

        props.submitForm();
    };

    const makeSpecificHintString = () => {
        if (hasOnlyOneApprover) {
            return translate('approvalRequest.multipleApproversPopup.hintTopLevelApprover');
        }

        if (preventMultipleApprovers) {
            return translate('approvalRequest.multipleApproversPopup.hintSingleApprover');
        }

        return translate('approvalRequest.multipleApproversPopup.hint');
    };

    return (
        <Box padding={6}>
            <Text variant="title" as="h3">
                {translate('approvalRequest.multipleApproversPopup.headline')}
            </Text>

            <Text variant="body" as="p" color="foreground.muted" marginTop={3}>
                {makeSpecificHintString()}
            </Text>

            {!hasOnlyOneApprover ? (
                <Text variant="fieldLabel" color="foreground.muted" marginTop={6} as="h4">
                    {translate('approvalRequest.multipleApproversPopup.qualifiedApproversHeadline')}
                </Text>
            ) : null}

            <Form onSubmit={onSubmit}>
                <FormikRadioGroup name="approverEmailAddresses">
                    {hasOnlyOneApprover ? (
                        <Box marginTop={5}>
                            <TopLevelApprover approver={props.offerApprovalSettingsMessage.suggestedApprovers[0]} />
                        </Box>
                    ) : (
                        <ApproverList
                            approvers={props.offerApprovalSettingsMessage.suggestedApprovers}
                            onApproverChange={onApproverChange}
                            isApproverSelected={isApproverSelected}
                            preventMultipleApprovers={preventMultipleApprovers}
                        />
                    )}

                    {props.offerApprovalSettingsMessage.additionalApprovers.length ? (
                        <Box marginTop={5}>
                            <AdditionalApprovers>
                                <ApproverList
                                    approvers={props.offerApprovalSettingsMessage.additionalApprovers}
                                    isApproverSelected={isApproverSelected}
                                    onApproverChange={onApproverChange}
                                    preventMultipleApprovers={preventMultipleApprovers}
                                />
                            </AdditionalApprovers>
                        </Box>
                    ) : null}
                </FormikRadioGroup>

                <Box marginTop={6}>
                    <FormikTextArea
                        name="requestMessage"
                        label={translate('approvalRequest.multipleApproversPopup.remark')}
                        rows={4}
                    />
                </Box>

                <Box marginTop={6}>
                    <Box display="flex" justifyContent="flex-end">
                        <ButtonGroup>
                            <Button type="button" onClick={props.onDismiss}>
                                {translate('approvalRequest.multipleApproversPopup.cancel')}
                            </Button>

                            <Button
                                emphasis="high"
                                size="medium"
                                disabled={!props.isValid}
                                type="submit"
                                isLoading={props.isSubmitting}
                            >
                                {translate('approvalRequest.multipleApproversPopup.submit')}
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Box>
            </Form>
        </Box>
    );
};
