import { useMagicBellEvent, useNotifications } from '@magicbell/magicbell-react';
import css from '@styled-system/css';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { IconBell } from '../../icons/Bell.tsx';
import { IconBellAlert } from '../../icons/BellAlert.tsx';
import { Box } from '../../ui/Box';
import 'animate.css';

const NotificationItem = styled(Box)(
    css({
        display: 'flex',
        borderRadius: '100%',
        alignItems: 'center',
        padding: '8px !important',
        color: 'dark-blue.2',
        ':hover, :focus': {
            color: 'clear-blue.0',
            backgroundColor: 'clear-blue.9',
        },
    })
);

export const BellIcon = () => {
    const store = useNotifications();
    const bellRef = useRef(null);

    useEffect(() => {
        const bellRefCurrent = bellRef.current;
        let eventHandler;
        if (bellRefCurrent) {
            eventHandler = bellRefCurrent.addEventListener('animationend', () => {
                bellRefCurrent.classList.remove('animate__swing');
            });
        }
        return () => {
            if (eventHandler && bellRefCurrent) {
                bellRefCurrent.removeEventListener('animationend', eventHandler);
            }
        };
    }, [bellRef]);

    useMagicBellEvent('notifications.new', () => {
        if (bellRef.current) {
            bellRef.current.classList.add('animate__swing');
        }
    });

    const icon = store?.unseenCount ? (
        <div ref={bellRef} className="animate__animated animate__swing">
            <IconBellAlert width={24} height={24} />
        </div>
    ) : (
        <IconBell width={24} height={24} />
    );

    return <NotificationItem>{icon}</NotificationItem>;
};
