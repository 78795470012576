import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { PasswordResetPage as OriginalPasswordResetPage } from './PasswordResetPage';

const PasswordResetPage = (props) => (
    <AppProviders>
        <OriginalPasswordResetPage {...props} />
    </AppProviders>
);

export default createDirectiveFromReactComponent(PasswordResetPage, 'reactPasswordReset');
