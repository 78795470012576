import { ProductContext } from '../../../../../types/ProductContext';
import { SpotSettings } from '../../../../../types/SupplierGroupMessage';
import { Box } from '../../../../common/ui/Box';
import { useRegionToCountriesMap } from '../hooks/useRegionToCountriesMap';
import { useSupplierGroupSpotAvailabilityUpdate } from '../hooks/useSupplierGroupSpotAvailabilityUpdate';

import { Region } from './Region';
import { RegionState } from './RegionState';

interface SpotSectionProps {
    groupId: string;
    spotSettings: SpotSettings;
    type: ProductContext;
}

export const SpotPortAvailability = (props: SpotSectionProps) => {
    const regionToCountriesMap = useRegionToCountriesMap();
    const supplierGroupSpotAvailabilityUpdate = useSupplierGroupSpotAvailabilityUpdate(props.groupId, props.type);

    const isCountryActive = (countryCode: string): boolean => {
        return props.spotSettings.availabilityCountryCodes.includes(countryCode);
    };

    const getRegionState = (region: string): RegionState => {
        const countriesForRegion = regionToCountriesMap.get(region) ?? [];
        const countryCodesForRegion = countriesForRegion.map((it) => it.code);

        const activeCountryCodes = countryCodesForRegion.filter((countryCode) =>
            props.spotSettings.availabilityCountryCodes.includes(countryCode)
        );

        if (activeCountryCodes.length === countryCodesForRegion.length) {
            return RegionState.ACTIVE;
        }

        if (activeCountryCodes.length === 0) {
            return RegionState.INACTIVE;
        }

        return RegionState.INDETERMINATE;
    };

    const onRegionSwitchChange = (checked: boolean, region: string) => {
        const countryCodes = regionToCountriesMap.get(region)?.map((it) => it.code) ?? [];

        const countryCodesToUpdate = checked
            ? props.spotSettings.availabilityCountryCodes.concat(countryCodes)
            : props.spotSettings.availabilityCountryCodes.filter((countryCode) => !countryCodes.includes(countryCode));

        supplierGroupSpotAvailabilityUpdate.mutate(countryCodesToUpdate);
    };

    const onCountrySwitchChange = (checked: boolean, countryCode: string) => {
        const countryCodesToUpdate = checked
            ? props.spotSettings.availabilityCountryCodes.concat(countryCode)
            : props.spotSettings.availabilityCountryCodes.filter((countryCodes) => countryCodes !== countryCode);

        supplierGroupSpotAvailabilityUpdate.mutate(countryCodesToUpdate);
    };

    return (
        <Box>
            {Array.from(regionToCountriesMap).map(([region, countries]) => {
                return (
                    <Region
                        state={getRegionState(region)}
                        key={region}
                        region={region}
                        countries={countries}
                        onRegionSwitchChange={onRegionSwitchChange}
                        isCountryActive={isCountryActive}
                        onCountrySwitchChange={onCountrySwitchChange}
                    />
                );
            })}
        </Box>
    );
};
