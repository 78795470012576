import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconSettings = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9.41 4.297c.867-2.437 4.313-2.437 5.18 0a.748.748 0 0 0 1.026.425c2.335-1.11 4.772 1.327 3.662 3.662-.19.4.007.878.425 1.027 2.437.866 2.437 4.312 0 5.178a.748.748 0 0 0-.425 1.027c1.11 2.335-1.326 4.772-3.662 3.662a.748.748 0 0 0-1.026.425c-.867 2.437-4.313 2.437-5.18 0a.748.748 0 0 0-1.026-.425c-2.335 1.11-4.772-1.327-3.662-3.662a.748.748 0 0 0-.425-1.027c-2.436-.866-2.436-4.312 0-5.178a.748.748 0 0 0 .425-1.027C3.612 6.05 6.05 3.612 8.384 4.722c.4.19.878-.007 1.027-.425Zm3.295.67c-.236-.663-1.174-.663-1.41 0a2.748 2.748 0 0 1-3.77 1.562c-.635-.303-1.299.36-.996.997a2.748 2.748 0 0 1-1.562 3.769c-.663.236-.663 1.174 0 1.41a2.748 2.748 0 0 1 1.562 3.77c-.303.635.36 1.299.997.996 1.47-.699 3.223.027 3.77 1.562.235.663 1.173.663 1.41 0a2.748 2.748 0 0 1 3.768-1.562c.636.303 1.3-.36.998-.997a2.748 2.748 0 0 1 1.56-3.769c.664-.236.664-1.174 0-1.41a2.748 2.748 0 0 1-1.56-3.77c.302-.635-.362-1.299-.998-.997a2.748 2.748 0 0 1-3.769-1.56ZM12 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
            clipRule="evenodd"
        />
    </svg>
);
