import { useField } from 'formik';

import { TimeZoneSelect, TimeZoneSelectProps } from './TimeZoneSelect';

interface FormikTimeZoneSelectProps extends Pick<TimeZoneSelectProps, 'caption' | 'label'> {
    name: string;
}

export const FormikTimeZoneSelect = (props: FormikTimeZoneSelectProps) => {
    const [field, meta, helpers] = useField(props.name);

    const onChange = (state: string) => {
        helpers.setValue(state);
    };

    return (
        <TimeZoneSelect
            {...props}
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : props.caption}
            timeZoneId={field.value}
            onChange={onChange}
            onBlur={() => helpers.setTouched(true)}
        />
    );
};
