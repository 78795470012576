import { Formik, FormikErrors } from 'formik';
import { useState } from 'react';

import { Button } from '../../../cdl/Button/Button';
import { Modal } from '../../../cdl/Modal/Modal';
import { Text } from '../../../cdl/Text/Text';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';
import { useInviteSupplier } from '../hooks/useInviteSupplier';

import { InviteSupplierForm, InviteSupplierFormState } from './InviteSupplierForm';

export const InviteSupplierButton = () => {
    const [showPopup, setShowPopup] = useState(false);
    const { addToast } = useToasts();
    const inviteSupplierMutation = useInviteSupplier();

    const validate = (values: InviteSupplierFormState): FormikErrors<InviteSupplierFormState> => {
        const errors: FormikErrors<InviteSupplierFormState> = {};

        if (!values.customerId) {
            errors.customerId = translate('assignments.suppliers.companyRequired');
        }

        if (!values.inviteCompany) {
            errors.inviteCompany = translate('assignments.suppliers.supplierRequired');
        }

        if (!values.inviteMail) {
            errors.inviteMail = translate('assignments.suppliers.inviteMailRequired');
        }

        return errors;
    };

    const onSubmit = (values: InviteSupplierFormState) => {
        inviteSupplierMutation.mutate(
            {
                customerId: values.customerId,
                inviteCompany: values.inviteCompany,
                inviteMail: values.inviteMail,
            },
            {
                onSuccess: () => {
                    setShowPopup(false);
                    addToast(translate('assignments.suppliers.invitedSuccessfullyToast'));
                },
            }
        );
    };

    return (
        <>
            <Button emphasis="high" onClick={() => setShowPopup(true)}>
                {translate('assignments.suppliers.invite')}
            </Button>
            <Modal isOpen={showPopup} onDismiss={() => setShowPopup(false)} dismissible={false}>
                <Box padding={6} display="grid" rowGap={5}>
                    <Text variant="title">{translate('assignments.suppliers.invite')}</Text>

                    <Formik
                        initialValues={{
                            customerId: '',
                            inviteCompany: '',
                            inviteMail: '',
                        }}
                        onSubmit={onSubmit}
                        validate={validate}
                        validateOnMount
                    >
                        <InviteSupplierForm onCancel={() => setShowPopup(false)} />
                    </Formik>
                </Box>
            </Modal>
        </>
    );
};
