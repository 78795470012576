SupplierGroupService.$inject = ['SupplierGroupResource'];

function SupplierGroupService(SupplierGroupResource) {
    return {
        get: get,
    };

    function get(id) {
        return SupplierGroupResource.get({ id });
    }
}

export default {
    name: 'SupplierGroupService',
    fn: SupplierGroupService,
};
