import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { getOfferList } from '../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../common/api/queryKeys';

const getOfferListWithLogic = async (orderId: string): Promise<string> => {
    const offers = await getOfferList({ orderIds: [orderId], types: ['FUEL'] });

    if (!offers.length) {
        return Promise.reject();
    }

    return Promise.resolve(offers[0].id);
};

export const useSelectedOfferForFuelOrder = () => {
    const [orderId, setOrderId] = useState<string | null>(null);
    const refetchIntervalInMilliseconds = 300;

    const query = useQuery({
        queryKey: queryKeys.fuelOrderOffers(orderId),
        queryFn: () => getOfferListWithLogic(orderId!!),
        enabled: !!orderId,
        refetchInterval: (query) => (query.state.status === 'success' ? false : refetchIntervalInMilliseconds),
    });

    return async (orderId: string): Promise<string> => {
        setOrderId(orderId);
        const queryResult = await query.refetch();

        return queryResult.data!!;
    };
};
