import { MultiValueRemoveProps } from 'react-select';
import styled from 'styled-components';

import { IconX } from '../../../common/icons/cdl/X';

const CustomMultiValueRemoveWrapper = styled.div`
    cursor: pointer;
`;

export const CustomMultiValueRemove = (props: MultiValueRemoveProps) => {
    // remove className from react-select
    const innerProps = { ...props.innerProps, className: undefined };

    return (
        <div {...innerProps}>
            <CustomMultiValueRemoveWrapper>
                <IconX width={16} height={16} />
            </CustomMultiValueRemoveWrapper>
        </div>
    );
};
