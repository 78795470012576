import { useField } from 'formik';

import { HumanReadableEnumValue } from '../../common/enums/HumanReadableEnumValue';
import { formatNumber } from '../../common/helpers/formatNumber.helper';
import { translate } from '../../common/helpers/translate.helper';
import { Table } from '../../common/Table/Table';
import { Td } from '../../common/Table/Td';
import { Tr } from '../../common/Table/Tr';

import { HeaderRow } from './ProductTable';

export const ProductSummary = () => {
    const [field] = useField('items');
    const items = field.value;

    return (
        <Table>
            <thead>
                <HeaderRow />
            </thead>
            <tbody>
                {items.map((item, index) => (
                    <Tr key={index}>
                        <Td width="30%">
                            {item.productName} {translate('sulphurContent.' + item.sulphurContent)}
                        </Td>
                        <Td width="15%">
                            {item.isoSpec ? (
                                <HumanReadableEnumValue enumType="fuelIsoSpec" value={item.isoSpec} />
                            ) : (
                                '-'
                            )}
                        </Td>
                        <Td width="15%">
                            <HumanReadableEnumValue enumType="productGroup" value={item.group} />{' '}
                            <HumanReadableEnumValue enumType="sulphurContent" value={item.sulphurContent} />
                        </Td>
                        <Td width="15%">
                            {formatNumber({
                                number: item.units,
                                maximumFractionDigits: 1,
                            })}
                        </Td>
                        <Td width="10%">
                            <HumanReadableEnumValue enumType="productUnit" value={item.unit} />
                        </Td>
                    </Tr>
                ))}
            </tbody>
        </Table>
    );
};
