import React, { useState } from 'react';

import { DropdownMenu } from '../../../cdl/DropdownMenu/DropdownMenu';
import { DeleteConfirmPopup } from '../../../common/DeleteConfirmPopup/DeleteConfirmPopup';
import { translate } from '../../../common/helpers/translate.helper';
import { useAngularDependencies } from '../../../common/hooks/useAngularDependencies';
import { useNavigation } from '../../../common/hooks/useNavigation';
import { OrderModel } from '../../model/OrderModel';

interface DraftActionsDropdwonMenuProps {
    order: OrderModel;
    onMarkRead: (order: OrderModel) => void;
    onDeleteConfirm: (orderId: string) => void;
}

export const DraftActionsDropdownMenu = ({ order, onMarkRead, onDeleteConfirm }: DraftActionsDropdwonMenuProps) => {
    const { navigate } = useNavigation();
    const { $state } = useAngularDependencies();

    const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);

    const handleOnDeleteConfirm = (order: OrderModel) => {
        setShowDeleteConfirmPopup(false);
        onDeleteConfirm(order.id);
    };

    const handleDeleteClick = (event: Event) => {
        setShowDeleteConfirmPopup(true);
        event.stopPropagation();
    };

    const onDuplicateOrderClick = (e: Event) => {
        e.stopPropagation();

        if (order.type === 'FUEL') {
            navigate('base.fuel-enquiry', {
                order,
                origin: {
                    stateName: 'base.fuel-orderlist',
                },
            });
        } else {
            void navigate('lubes-create-enquiry', { order, origin: { stateName: $state.current.name } });
        }
    };

    const options = [
        {
            label: order.read ? translate('actionListLabel.markAsUnread') : translate('actionListLabel.markAsRead'),
            onSelect: () => onMarkRead(order),
        },
        {
            label: translate('actionListLabel.delete'),
            onSelect: handleDeleteClick,
        },
        {
            label: translate('actionListLabel.copyDraftTo'),
            onSelect: onDuplicateOrderClick,
        },
    ];

    return (
        <>
            <DropdownMenu options={options} align="end" />
            <DeleteConfirmPopup
                onSubmit={() => handleOnDeleteConfirm(order)}
                headline={translate('alert.deleteDraft.headline', {
                    reference: order.buyerReference || '',
                })}
                text={translate('alert.deleteDraft.text')}
                isOpen={showDeleteConfirmPopup}
                onDismiss={() => setShowDeleteConfirmPopup(false)}
            />
        </>
    );
};
