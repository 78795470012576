import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { FuelStockGroup } from '../../common/FuelStockGroup/FuelStockGroup';
import { formatVessel } from '../../common/helpers/formatVessel.helper';
import { sortFuelCategory } from '../../common/helpers/sortByCategory.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useVesselDetails } from '../../common/hooks/useVesselDetails';
import { Box } from '../../common/ui/Box';
import { CardVariation } from '../../common/ui/CardVariation';
import { Flex } from '../../common/ui/Flex';
import { get } from '../../common/ui/get';
import { Grid, GridItem } from '../../common/ui/Grid';
import { FuelStockInfoIcon } from '../../vessel/VesselDetailPage/FuelStocks/components/FuelStockInfoIcon';
import { useFuelStocks } from '../../vessel/VesselDetailPage/FuelStocks/hooks/useFuelStocks';
import { useReachInformationCategories } from '../../vessel/VesselDetailPage/FuelStocks/hooks/useReachInformationCategories';

const FuelStockGroupWrapper = styled(Box)`
    border: 1px solid ${get('colors.border.muted')};
    border-radius: ${get('radii.1')}px;
    padding: ${get('space.5')}px;
`;

interface FuelStocksCardProps {
    vesselId: string;
}

export const FuelStocksCard = ({ vesselId }: FuelStocksCardProps) => {
    const { groupedFuelStocks, leadingFuelStock, hasFuelStocks } = useFuelStocks(vesselId);
    const reachInformationCategories = useReachInformationCategories(vesselId);

    const { data: vesselData } = useVesselDetails(vesselId);

    if (!hasFuelStocks || !vesselData) {
        return null;
    }

    const formattedVesselName = formatVessel({
        vessel: vesselData,
    });

    const cardTitle = translate('fuelEnquiry.second.fuelStocks.cardHeadline', {
        vessel: formattedVesselName,
    });

    return (
        <CardVariation
            title={
                <Flex alignItems="center">
                    <Text variant="subtitle1" marginRight={2}>
                        {cardTitle}
                    </Text>
                    <FuelStockInfoIcon dateLastUpdated={leadingFuelStock.dateMeasured} />
                </Flex>
            }
        >
            <Grid>
                {Object.keys(groupedFuelStocks)
                    .sort(sortFuelCategory)
                    .map((key) => (
                        <GridItem width={1 / 2} key={key}>
                            <FuelStockGroupWrapper>
                                <FuelStockGroup
                                    fuelStocks={groupedFuelStocks[key]}
                                    reachInformation={reachInformationCategories?.[key]}
                                />
                            </FuelStockGroupWrapper>
                        </GridItem>
                    ))}
            </Grid>
        </CardVariation>
    );
};
