import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../cdl/Toast/useToasts';
import { translate } from '../common/helpers/translate.helper';

export const useOptimisticSettingsEdit = ({ updateFunction, queryKey }) => {
    const queryClient = useQueryClient();
    const { addErrorToast } = useToasts();

    return useMutation({
        mutationFn: updateFunction,
        onMutate: (newSettingsData) => {
            queryClient.cancelQueries({ queryKey });
            const previousSettings = queryClient.getQueryData(queryKey);
            queryClient.setQueryData(queryKey, (oldSettingsData) => {
                return {
                    ...oldSettingsData,
                    ...newSettingsData.settings,
                };
            });

            return previousSettings;
        },
        onError: (err, newSettingsData, previousSettings) => {
            queryClient.setQueryData(queryKey, previousSettings);
            addErrorToast(translate('company.toasts.informationUpdateError'));
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey });
        },
    });
};
