import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteAssignment } from '../../../common/api/clients/company.api';
import { queries } from '../../../common/api/queryKeys/queries';

export const useAssignmentDelete = (assignmentId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () => deleteAssignment(assignmentId),

        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queries.assignments._def });
        },
    });
};
