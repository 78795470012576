import { OnChangeValue } from 'react-select';

import { FormikCustomerCompanySelect } from '../../../common/form-elements/formik/FormikCustomerCompanySelect';
import { FormikCustomerGroupSelect } from '../../../common/form-elements/formik/FormikCustomerGroupSelect';
import { FormikRoleSelect } from '../../../common/form-elements/formik/FormikRoleSelect';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';

import { FormikInviteUserState } from './InviteUserForm';

interface InviteUserCustomerFormProps {
    values: FormikInviteUserState;
    onGroupChange?: (value: OnChangeValue<string, false>) => void;
    onRoleChange?: (role?: string) => void;
}

export const InviteUserCustomerForm = ({ values, onGroupChange, onRoleChange }: InviteUserCustomerFormProps) => {
    const role = useRole();
    return (
        <>
            {role.isAdmin() ? (
                <FormikCustomerGroupSelect
                    name="groupId"
                    label={translate('user.group')}
                    onGroupChange={onGroupChange}
                />
            ) : null}
            <FormikRoleSelect name="role" label={translate('user.role')} onRoleChange={onRoleChange} />

            {values.groupId?.length && values.role && values.role !== 'GROUP_ADMIN' ? (
                <FormikCustomerCompanySelect
                    name="companyIds"
                    groupId={values.groupId}
                    label={translate('user.companies')}
                    isMulti
                />
            ) : null}
        </>
    );
};
