import { components } from 'react-select';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const CustomLoadingIndicator = styled(components.LoadingIndicator)`
    && {
        color: ${get('colors.foreground.muted')};
        margin-left: 3px;
    }
`;
