import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { markOrderRead, MarkOrderReadParams } from '../../../common/api/clients/order.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { translate } from '../../../common/helpers/translate.helper';
import { OrderModel } from '../../model/OrderModel';

interface MarkReadVariables extends MarkOrderReadParams {
    order: OrderModel;
}

export const useOrderMarkRead = () => {
    const queryClient = useQueryClient();
    const { addToast, addErrorToast } = useToasts();

    return useMutation<void, unknown, MarkReadVariables>({
        mutationFn: async ({ orderId, read }) => {
            await markOrderRead({ orderId, read });
        },
        onMutate: (variables) => {
            // Normally we would change the actual query data, but because of the complex queryKey we are not able to find them here.
            variables.order.read = variables.read;
        },
        onSuccess: (_, variables) => {
            if (variables.read) {
                addToast(translate('order.markReadToast.success'));
            } else {
                addToast(translate('order.markUnreadToast.success'));
            }
        },
        onError: (_, variables) => {
            if (variables.read) {
                addErrorToast(translate('order.markReadToast.error'));
            } else {
                addErrorToast(translate('order.markUnreadToast.error'));
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.orders() });
        },
    });
};
