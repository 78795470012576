AuthErrorInterceptor.$inject = ['$q', '$rootScope', '$injector'];

function AuthErrorInterceptor($q, $rootScope, $injector) {
    const LOGIN_ROUTE = 'api/user/login';
    const AUTH_ERRORS = [401, 403];
    return {
        responseError: function (rejection) {
            if (AUTH_ERRORS.indexOf(rejection.status) > -1 && rejection.config.url !== LOGIN_ROUTE) {
                const SessionService = $injector.get('SessionService');
                const state = $injector.get('$state');
                SessionService.logout();
                state.go('login');
            }
            return $q.reject(rejection);
        },
    };
}

export default {
    name: 'AuthErrorInterceptor',
    fn: AuthErrorInterceptor,
};
