import { useState } from 'react';

import { Button } from '../../cdl/Button/Button';
import { TextButton } from '../../common/buttons/TextButton';
import { translate } from '../../common/helpers/translate.helper';

import { EditPresetsOverlay } from './EditPresetsOverlay';

interface EditPresetsButtonProps {
    vesselId: string;
    customerId: string;
    variant?: 'default' | 'text';
}

export const EditPresetsButton = ({ vesselId, customerId, variant = 'default' }: EditPresetsButtonProps) => {
    const [open, setOpen] = useState(false);
    const buttonText = translate('consumption.editPresets.title');

    const handleClick = () => {
        setOpen(true);
    };

    return (
        <>
            {variant === 'text' ? (
                <TextButton style={{ fontSize: '12px' }} onClick={handleClick}>
                    {buttonText}
                </TextButton>
            ) : (
                <Button onClick={handleClick}>{buttonText}</Button>
            )}

            {open ? (
                <EditPresetsOverlay vesselId={vesselId} customerId={customerId} onClose={() => setOpen(false)} />
            ) : null}
        </>
    );
};
