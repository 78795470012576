import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createBunkerStops } from '../../common/api/clients/bunkerPlan.api';
import { queryKeys } from '../../common/api/queryKeys';

export const useBunkerStopsCreation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createBunkerStops,
        onSuccess: async () => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.bunkerStops() });
        },
    });
};
