import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { UserDetailPage as OriginalUserDetailPage } from './UserDetailPage';

const UserDetailPage = (props: any) => (
    <AppProviders>
        <OriginalUserDetailPage {...props} />
    </AppProviders>
);

export default createDirectiveFromReactComponent(UserDetailPage, 'reactUserDetailPage');
