import PropTypes from 'prop-types';

import { formatDateTime } from '../../common/helpers/formatDateTime.helper';
import { translate } from '../../common/helpers/translate.helper';
import { IconCircleCheck } from '../../common/icons/cdl/CircleCheck';
import { StatusBar } from '../../common/StatusBar/StatusBar';
import { StatusBarType } from '../../common/StatusBar/StatusBarType';

import { ApprovalRequestBasicContent } from './ApprovalRequestBasicContent';

const ApprovedStatusBar = ({ approvalRequest }) => {
    const makeSubHeadline = () => {
        if (!approvalRequest.responseMessage) {
            return translate('approvalRequest.statusBar.approvedNoComment.subHeadline', {
                date: formatDateTime({
                    date: approvalRequest.dateApproved,
                }),
                author: approvalRequest.controllerEmailAddress,
            });
        }

        return translate('approvalRequest.statusBar.approved.subHeadline', {
            date: formatDateTime({ date: approvalRequest.dateApproved }),
            author: approvalRequest.controllerEmailAddress,
            responseMessage: approvalRequest.responseMessage,
        });
    };

    const headline = translate('approvalRequest.statusBar.approved.headline');
    const subHeadline = makeSubHeadline();

    return (
        <StatusBar
            headline={headline}
            subHeadline={subHeadline}
            type={StatusBarType.SUCCESS}
            icon={<IconCircleCheck />}
        />
    );
};

ApprovedStatusBar.propTypes = {
    className: PropTypes.string,
    approvalRequest: PropTypes.object,
};

export const ApprovedState = ({ offer, approvalRequests }) => {
    return (
        <ApprovalRequestBasicContent approvalRequests={approvalRequests} offer={offer}>
            <ApprovedStatusBar approvalRequest={approvalRequests[0]} />
        </ApprovalRequestBasicContent>
    );
};

ApprovedState.propTypes = {
    offer: PropTypes.object.isRequired,
    approvalRequests: PropTypes.array.isRequired,
};
