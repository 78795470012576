import PropTypes from 'prop-types';

import { Button } from '../../common/buttons/Button';
import { translate } from '../../common/helpers/translate.helper';
import { IconChevronRight } from '../../common/icons/cdl/ChevronRight';

export const NextButton = ({ onClick, disabled }) => {
    return (
        <Button type="button" color="solid-dark" disabled={disabled} onClick={onClick}>
            {translate('fuelEnquiry.navigation.next')} <IconChevronRight />
        </Button>
    );
};

NextButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};
