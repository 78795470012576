import { useEnums } from '../../../../common/hooks/useEnums';

export const useFormatFuelCategory = () => {
    const { getHumanReadableValue } = useEnums();

    return (productGroup: string, sulphurContent: string) => {
        return `${getHumanReadableValue('productGroup', productGroup)} - ${getHumanReadableValue(
            'sulphurContent',
            sulphurContent
        )}`;
    };
};
