import { colors } from './colors';

const breakpoints = ['868px', '1024px', '1200px', '1634px'];

// aliases
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const theme = {
    colors: {
        ...colors,
        'black-alpha': 'rgba(0,0,0,0.25)',
    },

    space: [0, 2, 4, 6, 8, 16, 24, 32, 40, 48, 56, 64],
    fontSizes: [10, 12, 14, 20, 24, 32, 48],
    lineHeights: [0, '14px', '18px', '26px', '32px', '40px', '64px'],
    fontWeights: {
        normal: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
    },
    sizes: [0, 2, 4, 8, 16, 24, 32],
    radii: [0, 4, 8, 16],
    borders: ['none', '1px solid #edeff0', '2px solid #0068ff', '4px solid #0001fe', '2px dashed #d1d7d9'],
    shadows: {
        small: '0px 1px 2px 1px rgba(25, 54, 67, 0.10)',
        medium: '0px 4px 8px -1px rgba(25, 54, 67, 0.15)',
        large: '0px 8px 24px -2px rgba(25, 54, 67, 0.20)',
        extraLarge: '0px 12px 24px -4px rgba(25, 54, 67, 0.30)',
    },
    opacity: [1, 0.68],
    letterSpacings: [1, 1.5],
    breakpoints,
};
