import { useQuery, useQueryClient } from '@tanstack/react-query';

import { queries } from '../api/queryKeys/queries';

export const useVesselDetails = (id?: string) => {
    const queryClient = useQueryClient();

    const query = useQuery({ ...queries.vessels.detail(id ?? ''), enabled: !!id });

    const prefetch = (id: string) => {
        queryClient.prefetchQuery(queries.vessels.detail(id));
    };

    return {
        ...query,
        prefetch,
    };
};
