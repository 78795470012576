import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { OfferModel } from '../../model/OfferModel';

export const useCreateQuoteDocumentTitle = (offer?: OfferModel) => {
    const documentTitle =
        offer !== undefined
            ? translate('page.quotationProcess', {
                  vesselName: offer.vessel.name,
                  portName: offer.port?.name || '',
              })
            : undefined;

    useDocumentTitle(documentTitle);
};
