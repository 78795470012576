import { ReactNode } from 'react';
import styled from 'styled-components';

import { Box } from '../ui/Box';

const Content = styled(Box).attrs({
    backgroundColor: 'background.default',
    position: 'absolute',
    left: '220px',
    top: '64px',
    minHeight: 'calc(100vh - 64px)', // Viewport without Topbar
    right: 0,
})``;

interface PageProps {
    children: ReactNode;
    padding?: number;
}

export const Page = ({ children, padding = 8 }: PageProps) => {
    return (
        <Content className="reset" padding={padding}>
            {children}
        </Content>
    );
};
