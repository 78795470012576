import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { AnonymousQuotationPage as OriginalAnonymousQuotationPage } from './AnonymousQuotationPage';

const AnonymousQuotationPageWrapper = (props) => {
    return (
        <AppProviders>
            <OriginalAnonymousQuotationPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(AnonymousQuotationPageWrapper, 'reactAnonymousQuotationPage');
