CustomerGroupResource.$inject = ['$resource', 'AppSettings'];

function CustomerGroupResource($resource, AppSettings) {
    return $resource(
        AppSettings.routes.api + '/v1/customergroup/:id',
        {},
        {
            update: {
                method: 'PUT',
                params: {
                    id: '@id',
                },
            },
            list: {
                method: 'GET',
                isArray: true,
                url: AppSettings.routes.api + '/v1/customergroup/list',
            },
        }
    );
}

export default {
    name: 'CustomerGroupResource',
    fn: CustomerGroupResource,
};
