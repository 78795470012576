import { createQueryKeys } from '@lukemorales/query-key-factory';

import { AgentPaginationTotalsParams } from '../../../agent/useAgentPaginationTotals';
import { getAgentList, GetAgentListParams } from '../clients/agent.api';
import { getAgents, GetAgentsParams, getAgent } from '../clients/gateway.api';

export const agents = createQueryKeys('agents', {
    detail: (id: string) => ({
        queryKey: [id],
        queryFn: () => getAgent(id),
    }),
    pagination: (params: GetAgentsParams) => ({
        queryKey: [params],
        queryFn: () => getAgents(params),
    }),
    totals: (params: AgentPaginationTotalsParams) => ({
        queryKey: [params],
    }),
    list: (params: GetAgentListParams) => ({
        queryKey: [params],
        queryFn: () => getAgentList(params),
    }),
});
