import { useEffect, useState } from 'react';

import { CountryMessage } from '../../../../types/CountryMessage';
import { getCountries } from '../../../common/api/clients/coreData.api';
import { AsyncSearchSelect } from '../../../common/form-elements/AsyncSearchSelect/AsyncSearchSelect';
import { translate } from '../../../common/helpers/translate.helper';
import { Option } from '../../../order/compare/overview/ProductSelect';

export interface CountrySelectState {
    label: string;
    value: string;
    country: CountryMessage;
}

interface CountrySelectProps {
    countryId?: string;
    onChange?: (value: CountrySelectState) => void;
}

export function CountrySelect({ countryId, onChange, ...props }: CountrySelectProps) {
    const [selectedValue, setSelectedValue] = useState<CountrySelectState | null>(null);

    const loadCountriesBySearchQuery = (searchQuery: string): Promise<CountrySelectState[]> => {
        return getCountries(searchQuery).then((response) =>
            response.content.map((country) => ({
                label: country.name,
                value: country.id,
                country,
            }))
        );
    };

    const loadCountryById = (countryId: string): Promise<CountrySelectState> => {
        return getCountries(countryId).then((response) => {
            const country = response.content[0];
            return {
                label: country.name,
                value: country.id,
                country,
            };
        });
    };

    useEffect(() => {
        let mounted = true;
        if (countryId) {
            loadCountryById(countryId).then((result) => {
                if (mounted) {
                    setSelectedValue(result);
                }
            });
        }

        return () => {
            mounted = false;
        };
    }, [countryId]);

    const updateSelectedValue = (option: CountrySelectState) => {
        onChange?.(option);
    };

    return (
        <AsyncSearchSelect
            placeholder={translate('countrySelect.placeholder')}
            values={selectedValue}
            onChange={updateSelectedValue}
            loadOptions={loadCountriesBySearchQuery}
            components={{
                Option,
            }}
            {...props}
        />
    );
}
