import { Fragment } from 'react';

import { SupplierGroupMessage } from '../../../../../types/SupplierGroupMessage';
import { Text } from '../../../../cdl/Text/Text';
import { DataSheet } from '../../../../common/DataSheet/DataSheet';
import { ExternalLink } from '../../../../common/ExternalLink/ExternalLink';
import { formatAddress } from '../../../../common/helpers/formatAddress.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useEnums } from '../../../../common/hooks/useEnums';
import { useRole } from '../../../../common/hooks/useRole';
import { Box } from '../../../../common/ui/Box';
import { YesOrNo } from '../../../../common/YesOrNo/YesOrNo';

interface SupplierGroupDataSheetProps {
    supplierGroup: SupplierGroupMessage;
}

export const SupplierGroupDataSheet = ({ supplierGroup }: SupplierGroupDataSheetProps) => {
    const { getHumanReadableValue } = useEnums();
    const role = useRole();

    const baseRows = [
        {
            label: translate('global.contact.email'),
            value: supplierGroup.email,
            condition: !!supplierGroup.email,
        },
        {
            label: translate('global.contact.phone'),
            value: supplierGroup.phone,
            condition: !!supplierGroup.phone,
        },
        {
            label: translate('global.contact.fax'),
            value: supplierGroup.fax,
            condition: !!supplierGroup.fax,
        },
        {
            label: translate('global.address.title'),
            value: formatAddress({ address: supplierGroup.address }),
            condition: !!supplierGroup.address,
        },
        {
            label: translate('company.headquarter'),
            value: supplierGroup.headquarter,
            condition: !!supplierGroup.headquarter,
        },
        {
            label: translate('company.gtcUrl'),
            value: (
                <ExternalLink href={supplierGroup.gtcUrl} target="_blank">
                    {translate('company.gtcLink')}
                </ExternalLink>
            ),
            condition: !!supplierGroup.gtcUrl,
        },
        {
            label: translate('company.description'),
            value: supplierGroup.description,
            condition: !!supplierGroup.description,
        },
        {
            label: translate('suppliergroup.type'),
            value: getHumanReadableValue('supplierGroupType', supplierGroup.type),
        },
        {
            label: translate('suppliergroup.testcompany'),
            value: <YesOrNo value={supplierGroup.testCompany} />,
        },
        {
            label: translate('company.emailquote'),
            value: <YesOrNo value={supplierGroup.emailQuote} />,
        },
        {
            label: translate('suppliergroup.anonymousQuote'),
            value: <YesOrNo value={supplierGroup.anonymousQuote} />,
        },
        {
            label: translate('company.gtcAccepted'),
            value: <YesOrNo value={supplierGroup.gtcAccepted} />,
            condition: role.isAdmin(),
        },
    ].filter(({ condition = true }) => condition);

    const lubesSettings = [
        {
            label: translate('company.spotallowed'),
            value: <YesOrNo value={supplierGroup.lubesSettings.spotAllowed} />,
        },
        {
            label: translate('company.supplierproductgroups'),
            value: supplierGroup.supplierProductGroups
                ?.map((productGroup) => getHumanReadableValue('supplierProductGroup', productGroup))
                .join(', '),
            condition: !!supplierGroup.supplierProductGroups?.length,
        },
        {
            label: translate('company.translateproductgroup'),
            value: supplierGroup.translationSupplierProductGroup
                ? getHumanReadableValue('supplierProductGroup', supplierGroup.translationSupplierProductGroup)
                : undefined,
            condition: !!supplierGroup.translationSupplierProductGroup,
        },
    ].filter(({ condition = true }) => condition);

    const fuelSettings = [
        {
            label: translate('company.spotallowed'),
            value: <YesOrNo value={supplierGroup.fuelSettings.spotAllowed} />,
        },
    ];

    return (
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={8}>
            <DataSheet>
                {baseRows.map((row, index) => (
                    <Fragment key={index}>
                        <DataSheet.Label>{row.label}</DataSheet.Label>
                        <Text variant="body">{row.value}</Text>
                    </Fragment>
                ))}
            </DataSheet>

            {role.hasAdminRights() ? (
                <Box>
                    <DataSheet>
                        <DataSheet.Heading>{translate('company.lubesSettings')}</DataSheet.Heading>

                        {lubesSettings.map((row, index) => (
                            <Fragment key={index}>
                                <DataSheet.Label>{row.label}</DataSheet.Label>
                                <Text variant="body">{row.value}</Text>
                            </Fragment>
                        ))}

                        <DataSheet.Heading marginTop={12}>{translate('company.fuelSettings')}</DataSheet.Heading>

                        {fuelSettings.map((row, index) => (
                            <Fragment key={index}>
                                <DataSheet.Label>{row.label}</DataSheet.Label>
                                <Text variant="body">{row.value}</Text>
                            </Fragment>
                        ))}
                    </DataSheet>
                </Box>
            ) : null}
        </Box>
    );
};
