import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { getCustomerGroups } from '../../api/clients/company.api';
import { translate } from '../../helpers/translate.helper';
import { AsyncSearchSelect } from '../AsyncSearchSelect/AsyncSearchSelect';
import { useFormContext } from '../Form/useFormContext';
import { useFormData } from '../Form/useFormData';
import { Label } from '../Label/Label';

const customerGroupSelectPropTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    required: PropTypes.bool,
    label: PropTypes.string,
    isMulti: PropTypes.bool,
};

const loadCustomerGroups = (query) => {
    return new Promise((resolve) => {
        getCustomerGroups(query)
            .then((response) =>
                response.map((customerGroup) => ({
                    value: customerGroup.id,
                    label: customerGroup.name,
                }))
            )
            .then((customerGroups) => resolve(customerGroups));
    });
};

const loadCustomerGroupsByIds = (ids) => loadCustomerGroups({ ids });
const loadCustomerGroupsBySearchQuery = (searchQuery) => loadCustomerGroups({ searchQuery });

function LegacyCustomerGroupSelect({ value, onChange, required = false, label = null, isMulti = false }) {
    const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null);

    useEffect(() => {
        if (value?.length) {
            loadCustomerGroupsByIds(value).then((result) => {
                setSelectedValue(isMulti ? result : result[0]);
            });
        } else {
            setSelectedValue(isMulti ? [] : null);
        }
    }, [value, isMulti]);

    function updateSelectedValue(option) {
        setSelectedValue(option);
        if (onChange) {
            onChange(option);
        }
    }

    return (
        <Label label={label} required={required}>
            <AsyncSearchSelect
                values={selectedValue}
                onChange={updateSelectedValue}
                loadOptions={loadCustomerGroupsBySearchQuery}
                placeholder={translate('customerGroupSelect.placeholder')}
                required={required}
                isMulti={isMulti}
            />
        </Label>
    );
}

LegacyCustomerGroupSelect.propTypes = customerGroupSelectPropTypes;

const formCustomerGroupSelectPropTypes = {
    dataPath: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
};

export const FormLegacyCustomerGroupSelect = ({ dataPath, onChange: inOnChange, ...props }) => {
    const { value, onChange } = useFormData(dataPath);
    const { updateDataPath, onFormChange } = useFormContext();

    const onChangeHandle = (option) => {
        inOnChange?.({
            updateDataPath: (dataPath, value) => {
                const newData = updateDataPath(dataPath, value);

                if (onFormChange) {
                    onFormChange({ data: newData });
                }
            },
        });

        onChange({ target: { value: option.value } });
    };

    return <LegacyCustomerGroupSelect onChange={onChangeHandle} value={value} {...props} />;
};

FormLegacyCustomerGroupSelect.propTypes = formCustomerGroupSelectPropTypes;
