import { AssignmentStatus } from '../../../../types/AssignmentMessage';
import { Tag } from '../../../cdl/Tag/Tag';
import { useEnums } from '../../../common/hooks/useEnums';

interface AssignmentStatusBadgeProps {
    status: AssignmentStatus;
}

export const AssignmentStatusBadge = (props: AssignmentStatusBadgeProps) => {
    const { getHumanReadableValue } = useEnums();
    const humanReadableValue = getHumanReadableValue('assignmentStatus', props.status);

    switch (props.status) {
        case AssignmentStatus.REQUESTED: {
            return <Tag variant="default">{humanReadableValue}</Tag>;
        }
        case AssignmentStatus.CONFIRMED: {
            return <Tag variant="positive">{humanReadableValue}</Tag>;
        }
    }
};
