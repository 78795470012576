import { Formik, FormikErrors } from 'formik';

import { useToasts } from '../../../../cdl/Toast/useToasts';
import { SurchargeRequestMessage } from '../../../../common/api/clients/surcharge.api';
import { translate } from '../../../../common/helpers/translate.helper';
import { useSurchargeCreate } from '../hooks/useSurchargeCreate';

import { FormikCreateSurchargeForm } from './FormikCreateSurchargeForm';

interface FormikCreateSurchargeState {
    packType: string;
    productGroup: string;
    customerId: string;
    value: string;
}

interface CreateSurchargeFormProps {
    groupId: string;
    onSuccess: () => void;
}

export const CreateSurchargeForm = ({ groupId, onSuccess }: CreateSurchargeFormProps) => {
    const surchargeCreateMutation = useSurchargeCreate(groupId);
    const { addErrorToast } = useToasts();

    const formikCreateSurchargeState: FormikCreateSurchargeState = {
        packType: '',
        productGroup: '',
        customerId: '',
        value: '',
    };

    const validate = (values: FormikCreateSurchargeState) => {
        const errors: FormikErrors<FormikCreateSurchargeState> = {};

        if (!values.packType) {
            errors.packType = translate('surcharges.packTypeRequired');
        }

        if (!values.value) {
            errors.value = translate('surcharges.valueRequired');
        }

        return errors;
    };

    const handleSurchargeSubmit = (formData: FormikCreateSurchargeState) => {
        const surchargeRequestMessage: SurchargeRequestMessage = {
            supplierGroupId: groupId,
            packType: formData.packType,
            group: formData.productGroup || undefined,
            customerId: formData.customerId || undefined,
            value: { value: Number(formData.value), currency: 'USD' },
        };
        surchargeCreateMutation.mutate(surchargeRequestMessage, {
            onSuccess: () => {
                onSuccess();
            },
            onError: () => {
                addErrorToast(translate('surcharges.toasts.createSurchargeError'));
            },
        });
    };

    return (
        <Formik
            initialValues={formikCreateSurchargeState}
            onSubmit={handleSurchargeSubmit}
            validate={validate}
            component={FormikCreateSurchargeForm}
        />
    );
};
