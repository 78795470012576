import { useMemo } from 'react';

export const useCharacterLimit = ({ characterLength, characterLimit }) => {
    const remainingCharacters = useMemo(() => characterLimit - characterLength, [characterLength, characterLimit]);

    const limitReached = useMemo(() => remainingCharacters <= 0, [remainingCharacters]);

    return {
        remainingCharacters,
        limitReached,
    };
};
