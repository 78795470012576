import styled from 'styled-components';

import { Box } from './Box';

/**
 * @deprecated use Box as display grid or flex instead
 **/

// * qnd grid system, to be refined in later issue, but API will be similar
export const Grid = styled(Box)`
    display: flex;
    flex-wrap: wrap;
`;

/**
 * @deprecated use Box to layout components, if needed
 **/

export const GridItem = styled(Box).attrs((props) => ({
    width: '100%',
    padding: '8px',
    ...props,
}))``;
