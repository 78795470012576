import { PriceCompareLubesMessage } from '../../../../types/PriceCompareLubesMessage';

export const createLubesPricesMap = (prices: PriceCompareLubesMessage[]) => {
    const pricesMap: { [key: string]: { [key: string]: PriceCompareLubesMessage[] } } = {};

    prices?.forEach((priceData) => {
        if (!pricesMap[priceData.portId]) {
            pricesMap[priceData.portId] = {};
        }
        if (!pricesMap[priceData.portId][priceData.category.toString()]) {
            pricesMap[priceData.portId][priceData.category.toString()] = [];
        }
        pricesMap[priceData.portId][priceData.category].push(priceData);
    });
    return pricesMap;
};
