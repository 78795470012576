import { PortWithPrices } from '../../../types/GlobalPricesMessage';

export const getProductCategories = (ports: PortWithPrices[]) => {
    const productCategories: Set<string> = new Set();

    ports.forEach((port) => {
        port.prices.forEach((price) => productCategories.add(`${price.productGroup} ${price.sulphurContent}`));
    });

    return Array.from(productCategories);
};
