export const selectSortOption = (tab) => {
    if (tab === 'enquired') {
        return {
            sortField: 'validUntil',
            sortDirection: 'ASC',
        };
    }

    return {
        sortField: 'dateUpdated',
        sortDirection: 'DESC',
    };
};
