import { Fragment } from 'react';

import { LogoHeader } from '../../common/headers/LogoHeader';
import { translate } from '../../common/helpers/translate.helper';
import { IconClock } from '../../common/icons/cdl/Clock';
import { StatusBar } from '../../common/StatusBar/StatusBar';
import { StatusBarType } from '../../common/StatusBar/StatusBarType';

import { ContentContainer } from './ContentContainer';

const UnauthorizedStatusBar = () => {
    const headline = translate('approvalRequest.statusBar.unauthorizedError.headline');
    const subHeadline = translate('approvalRequest.statusBar.unauthorizedError.subHeadline');

    return <StatusBar headline={headline} subHeadline={subHeadline} type={StatusBarType.DANGER} icon={<IconClock />} />;
};

export const UnauthorizedError = () => {
    return (
        <Fragment>
            <LogoHeader />

            <ContentContainer>
                <UnauthorizedStatusBar />
            </ContentContainer>
        </Fragment>
    );
};
