import styled from 'styled-components';

import { Box } from '../../../common/ui/Box';

export const ScheduleStopContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '38px',
    minHeight: 'calc(100% - 38px)',
});
