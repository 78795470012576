import PropTypes from 'prop-types';

import { CounterOfferState } from '../../../types/CounterOfferState';
import { Button } from '../../cdl/Button/Button';
import { Text } from '../../cdl/Text/Text';
import { getProducts } from '../../common/api/clients/anonymousQuotation.api';
import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { FormikDatePicker } from '../../common/form-elements/formik/FormikDatePicker';
import { FormikDebug } from '../../common/form-elements/formik/FormikDebug';
import { FormikPositiveNumberInput } from '../../common/form-elements/formik/FormikPositiveNumberInput';
import { FormikTextArea } from '../../common/form-elements/formik/FormikTextArea';
import { FormikTextInput } from '../../common/form-elements/formik/FormikTextInput';
import { useFormikDirtyWarning } from '../../common/form-elements/formik/useFormikDirtyWarning';
import { Label } from '../../common/form-elements/Label/Label';
import { FormikFuelProductTable } from '../../common/FormikProductTable/fuel/FormikFuelProductTable';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';
import { CardVariation } from '../../common/ui/CardVariation';
import { Flex } from '../../common/ui/Flex';
import { Grid, GridItem } from '../../common/ui/Grid';
import { CounterOfferRejectHint } from '../../offer/quote/fuel/components/CounterOfferRejectHint';
import { FormikFuelDeliveryCostsSelect } from '../../offer/quote/fuel/components/FormikFuelDeliveryCostsSelect';
import { FormikFuelSupplyTypeSelect } from '../../offer/quote/fuel/components/FormikFuelSupplyTypeSelect';
import { FormikPaymentTerms } from '../../offer/quote/fuel/components/FormikPaymentTerms';

import { AnonymousQuotationAttachments } from './AnonymousQuotationAttachments';

export const AnonymousOfferEditForm = (props) => {
    useFormikDirtyWarning();

    const isCounterOfferExpiredOrOpen = () => {
        if (!props.values.counterOffers.length) {
            return false;
        }

        const latestCounterOffer = props.values.counterOffers[0];
        return [CounterOfferState.OPEN, CounterOfferState.EXPIRED].includes(latestCounterOffer.state);
    };

    return (
        <>
            <CardVariation>
                <FormikFuelProductTable
                    config={{
                        fetchProducts: () =>
                            getProducts({
                                anonymousQuotationId: props.anonymousQuotationId,
                            }).then((response) => response.products),
                    }}
                />
            </CardVariation>
            <CardVariation>
                <Grid>
                    <GridItem width={1 / 2}>
                        <Label label={translate('offer.edit.fuel.estimatedDeliveryDate')} required>
                            <FormikDatePicker required name="dateDelivery" />
                        </Label>
                    </GridItem>
                    <GridItem width={1 / 2}>
                        <FormikFuelSupplyTypeSelect name="supplyMode" />
                    </GridItem>

                    <GridItem width={1 / 2}>
                        <FormikFuelDeliveryCostsSelect
                            name="supplyModeIncludesDeliveryCosts"
                            label={translate('offer.quotationProcess.fuel.deliveryCosts')}
                            required
                        />
                    </GridItem>

                    <GridItem width={1 / 2}>
                        <FormikPositiveNumberInput
                            name="quoteValidityTime"
                            type="number"
                            label={translate('offer.quotationProcess.fuel.validityTime')}
                            placeholder={translate('offer.quotationProcess.fuel.validityTimePlaceholder')}
                            required
                        />
                    </GridItem>

                    <GridItem width={1 / 2}>
                        <FormikTextInput
                            name="vendorReference"
                            label={translate('offer.quotationProcess.fuel.vendorReference')}
                            placeholder={translate('offer.quotationProcess.fuel.vendorReferencePlaceholder')}
                        />
                    </GridItem>

                    <GridItem width={1 / 2}>
                        <Label label={translate('offer.quotationProcess.fuel.paymentTerms')} required>
                            <FormikPaymentTerms name="paymentTermReferenceDays" />
                        </Label>
                    </GridItem>
                </Grid>

                <Grid marginTop={8}>
                    <GridItem width={1 / 2}>
                        <FormikTextArea
                            placeholder={translate('offer.quotationProcess.fuel.messagePlaceholder')}
                            name="message"
                        />
                    </GridItem>
                    <GridItem width={1 / 2}>
                        <AnonymousQuotationAttachments
                            anonymousQuotationId={props.anonymousQuotationId}
                            offer={props.offer}
                        />
                    </GridItem>
                </Grid>

                {isCounterOfferExpiredOrOpen() ? (
                    <Flex justifyContent="center" marginTop={9} marginBottom={5}>
                        <CounterOfferRejectHint />
                    </Flex>
                ) : null}

                <Box display="flex" justifyContent="center" marginTop={isCounterOfferExpiredOrOpen() ? 0 : 9}>
                    <ButtonGroup>
                        {props.values.state === 'QUOTED' ? (
                            <Button
                                type="button"
                                emphasis="high"
                                destructive
                                onClick={props.onCancelOffer}
                                isLoading={props.isCancelLoading}
                            >
                                {translate('anonymousQuotation.cancelOffer')}
                            </Button>
                        ) : null}
                        <Button
                            emphasis="high"
                            onClick={props.submitForm}
                            disabled={!props.isValid}
                            isLoading={props.isSubmitting}
                        >
                            {props.values.state === 'QUOTED'
                                ? translate('offer.quotationProcess.fuel.update')
                                : translate('offer.quotationProcess.fuel.send')}
                        </Button>
                    </ButtonGroup>
                </Box>
                <Box marginTop={5}>
                    <Text
                        variant="extraSmall"
                        color="foreground.subtle"
                        as="p"
                        textAlign="center"
                        dangerouslySetInnerHTML={{
                            __html: translate('anonymousQuotation.createOrUpdateAgreesWithTerms'),
                        }}
                    />
                </Box>
            </CardVariation>
            <FormikDebug />
        </>
    );
};

AnonymousOfferEditForm.propTypes = {
    submitForm: PropTypes.func.isRequired,
    onCancelOffer: PropTypes.func.isRequired,
    anonymousQuotationId: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isValid: PropTypes.bool,
    values: PropTypes.object,
    offer: PropTypes.object,
    isCancelLoading: PropTypes.bool.isRequired,
};
