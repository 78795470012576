import { ProductGroupReport, SpecificReportMessage } from '../../../types/SpecificReportMessage';

export const mapProductGroupsReports = (productGroupReports: SpecificReportMessage['reports']) => {
    const mappedProductGroupReports: ProductGroupReport[] = Object.keys(productGroupReports).reduce(
        (acc: ProductGroupReport[], productGroupName) => {
            acc.push({ productGroup: productGroupName, ...productGroupReports[productGroupName] });
            return acc;
        },
        []
    );
    return mappedProductGroupReports;
};
