import { useQuery } from '@tanstack/react-query';

import { queries } from '../../../common/api/queryKeys/queries';

interface UsePriceListsParams {
    supplierGroupId: string;
    customerId: string;
}

export const usePriceLists = ({ supplierGroupId, customerId }: UsePriceListsParams) => {
    return useQuery(queries.assignments.priceLists({ supplierGroupId, customerId }));
};
