import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateVessel } from '../../../../common/api/clients/vessel.api';
import { queries } from '../../../../common/api/queryKeys/queries';

export const useVesselEdit = (vesselId) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateVessel,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queries.vessels.detail(vesselId).queryKey });
        },
    });
};
