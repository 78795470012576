import { useDrawer } from '../cdl/Drawer/useDrawer.hook';
import { IconButton } from '../cdl/IconButton/IconButton';
import { CenteredPagination } from '../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../cdl/TableBuilder/TableBuilderColumn';
import { DeleteIconButton } from '../common/buttons/DeleteIconButton';
import { Error } from '../common/Error/Error';
import { formatPort } from '../common/helpers/formatPort.helper';
import { formatVessel } from '../common/helpers/formatVessel.helper';
import { translate } from '../common/helpers/translate.helper';
import { useEnums } from '../common/hooks/useEnums';
import { useProductContext } from '../common/hooks/useProductContext';
import { useRole } from '../common/hooks/useRole';
import { useUpdateEffect } from '../common/hooks/useUpdateEffect';
import { IconPencil } from '../common/icons/cdl/Pencil';
import { IconStar } from '../common/icons/cdl/Star';
import { ListingWithMore } from '../common/ListingWithMore/ListingWithMore';
import { LoadingIndicator } from '../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../common/LocationState/useLocationState';
import { TableEmptyState } from '../common/TableEmptyState/TableEmptyState';
import { Box } from '../common/ui/Box';
import { Port } from '../port/Port';

import { EditKeyPortForm } from './EditKeyPortForm';
import { useKeyPortDelete } from './useKeyPortDelete';
import { useKeyPortPagination } from './useKeyPortPagination';

export const KeyPortOverviewTable = () => {
    const role = useRole();
    const { getHumanReadableValue } = useEnums();
    const { push, pop } = useDrawer();
    const { isFuel } = useProductContext();

    const { mutate: deleteKeyPort } = useKeyPortDelete();

    const [locationState, { setLocationFieldValue }] = useLocationState();

    const { data, isPending, isError, pageTotal } = useKeyPortPagination({
        page: locationState.page,
        searchQuery: locationState.searchQuery,
        customerGroupIds: locationState.customerGroupIds,
    });

    useUpdateEffect(() => {
        setLocationFieldValue('page', 0);
    }, [locationState.searchQuery, locationState.customerGroupIds]);

    if (isPending) {
        return <LoadingIndicator />;
    }

    if (isError) {
        return <Error />;
    }

    if (!data.content.length) {
        return <TableEmptyState Icon={IconStar} text={translate('keyPorts.emptyList')} />;
    }

    const openEditKeyPortModal = (keyPortId) => {
        push({
            content: <EditKeyPortForm keyPortId={keyPortId} onSuccess={pop} />,
        });
    };

    return (
        <div>
            <TableBuilder data={data.content}>
                <TableBuilderColumn header={translate('keyPorts.port')} width="20%">
                    {(keyPort) => <Port port={keyPort.port} country={false} showTooltip />}
                </TableBuilderColumn>
                <TableBuilderColumn header={translate('keyPorts.country')}>
                    {(keyPort) => keyPort.port.country.name}
                </TableBuilderColumn>
                <TableBuilderColumn header={translate('keyPorts.region')}>
                    {(keyPort) => getHumanReadableValue('region', keyPort.port.country.region)}
                </TableBuilderColumn>
                {isFuel ? (
                    <TableBuilderColumn header={translate('keyPorts.productCategories')} width="20%">
                        {(keyPort) => {
                            if (keyPort.productCategoryLimitations?.length) {
                                const combinedLimitations = keyPort.productCategoryLimitations?.map((limitation) => {
                                    return `${getHumanReadableValue(
                                        'productGroup',
                                        limitation.productGroup
                                    )} - ${getHumanReadableValue('sulphurContent', limitation.sulphurContent)}`;
                                });
                                return <ListingWithMore>{combinedLimitations}</ListingWithMore>;
                            }
                        }}
                    </TableBuilderColumn>
                ) : null}
                <TableBuilderColumn header={translate('keyPorts.vesselLimitations')} width="20%">
                    {(keyPort) => {
                        if (keyPort.vesselLimitationsVessels?.length) {
                            const combinedLimitations = keyPort.vesselLimitationsVessels?.map((vessel) =>
                                formatVessel({ vessel })
                            );
                            return <ListingWithMore>{combinedLimitations}</ListingWithMore>;
                        }
                    }}
                </TableBuilderColumn>
                {role.isAdmin() ? (
                    <TableBuilderColumn header={translate('keyPorts.group')}>
                        {(keyPort) => keyPort.customerGroup.name}
                    </TableBuilderColumn>
                ) : null}

                {role.hasWriteRights() ? (
                    <TableBuilderColumn header="" width="110px">
                        {(keyPort) => (
                            <Box>
                                <IconButton
                                    icon={IconPencil}
                                    onClick={() => openEditKeyPortModal(keyPort.id)}
                                    tooltipLabel={translate('keyPorts.editTitle')}
                                />
                                <DeleteIconButton
                                    onDeleteConfirm={() => deleteKeyPort(keyPort.id)}
                                    popupHeadline={translate('keyPorts.deleteButton.headline')}
                                    popupText={translate('keyPorts.deleteButton.text')}
                                    tooltip={translate('keyPorts.deleteButton.headline')}
                                    itemToDelete={formatPort({
                                        port: keyPort.port,
                                        country: false,
                                    })}
                                />
                            </Box>
                        )}
                    </TableBuilderColumn>
                ) : null}
            </TableBuilder>

            <CenteredPagination
                pageTotal={pageTotal}
                currentPage={locationState.page}
                onPageChange={(page) => setLocationFieldValue('page', page)}
            />
        </div>
    );
};
