import styled from 'styled-components';

import { get } from '../../../../common/ui/get';

export const ContentWrapper = styled.div`
    width: 334px;
    background-color: ${get('colors.background.default')};
    box-shadow: ${get('shadows.large')};
    border-radius: ${get('radii.1')}px;
    outline: none;
`;
