import { Fragment, MouseEventHandler, useState } from 'react';

import { Button } from '../../../cdl/Button/Button';
import { DeleteConfirmPopup } from '../../../common/DeleteConfirmPopup/DeleteConfirmPopup';
import { translate } from '../../../common/helpers/translate.helper';

export const DeleteBudgetPopupButton = ({ onConfirm }: { onConfirm: () => void }) => {
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);

    const handleOnConfirm = () => {
        setShowConfirmPopup(false);
        onConfirm();
    };

    const handleOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        setShowConfirmPopup(true);
        e.stopPropagation();
    };

    return (
        <Fragment>
            <Button onClick={handleOnClick}>{translate('budgets.remove')}</Button>
            <DeleteConfirmPopup
                isOpen={showConfirmPopup}
                onSubmit={handleOnConfirm}
                onDismiss={() => setShowConfirmPopup(false)}
                headline={translate('budgets.popup.headline')}
                text={translate('budgets.popup.text')}
                deleteButtonText={translate('budgets.popup.delete')}
            />
        </Fragment>
    );
};
