import { AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';

import { IconExternalLink } from '../../common/icons/cdl/ExternalLink';
import { get } from '../../common/ui/get';

interface StyledLinkProps {
    fontSize: string;
}

const StyledLink = styled.a<StyledLinkProps>`
    display: inline-flex;
    align-items: center;
    gap: 4px;
    font-size: ${(props) => props.fontSize};
    color: ${get('colors.accent.foreground')};
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;

    &:hover,
    &:active {
        color: ${get('colors.accent.foreground')};
        text-decoration: underline;
    }
`;

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    external?: boolean;
    size?: 'small' | 'default';
}

export const Link = ({ external, size = 'default', ...props }: LinkProps) => {
    const iconSize = size === 'small' ? 16 : 20;
    return (
        <StyledLink target={external ? '_blank' : undefined} {...props} fontSize={size === 'small' ? '14px' : '16px'}>
            {props.children}
            {external ? <IconExternalLink width={iconSize} height={iconSize} style={{ flexShrink: 0 }} /> : null}
        </StyledLink>
    );
};
