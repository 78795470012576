import { MouseEvent, useState } from 'react';

import { AgentMessage } from '../../../../types/AgentMessage';
import { Button } from '../../../cdl/Button/Button';
import { translate } from '../../../common/helpers/translate.helper';
import { IconArrowBackUp } from '../../../common/icons/cdl/ArrowBackUp';
import { useAgentStateChange } from '../../useAgentStateChange';

import { RestoreAgentConfirmPopup } from './RestoreAgentConfirmPopup';

interface RestoreAgentButtonProps {
    agent: AgentMessage;
}

export const RestoreAgentButton = ({ agent }: RestoreAgentButtonProps) => {
    const agentStateChangeMutation = useAgentStateChange();
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);

    const handleOnConfirm = () => {
        setShowConfirmPopup(false);
        onAgentRestore();
    };

    const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
        setShowConfirmPopup(true);
        event.stopPropagation();
    };

    const onAgentRestore = () => {
        const agentToRestore = {
            ...agent,
            active: true,
        };
        agentStateChangeMutation.mutate({
            agentId: agentToRestore.id,
            agent: agentToRestore,
        });
    };

    return (
        <>
            <Button leadingVisual={<IconArrowBackUp aria-label="Restore" />} onClick={handleOnClick}>
                {translate('agents.restoreButton.btnText')}
            </Button>
            <RestoreAgentConfirmPopup
                isOpen={showConfirmPopup}
                onSubmit={handleOnConfirm}
                onDismiss={() => setShowConfirmPopup(false)}
                name={agent.name}
            />
        </>
    );
};
