import { SupplierSpecificStatisticsMessage } from '../../../types/StatisticsMessage';

import { CustomersTabFilter } from './CustomersTabFilter';
import { CustomersTabStatistics } from './CustomersTabStatistics';

interface CustomersTabContentProps {
    customerStatistics: SupplierSpecificStatisticsMessage['customer'];
}

export const CustomersTabContent = ({ customerStatistics }: CustomersTabContentProps) => {
    return (
        <CustomersTabFilter allEntries={customerStatistics}>
            {(filteredEntries) => <CustomersTabStatistics customerStatistics={filteredEntries} />}
        </CustomersTabFilter>
    );
};
