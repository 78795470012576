import { useField } from 'formik';

import { StrengthMeter } from './StrengthMeter';

interface FormikStrengthMeterProps {
    name: string;
}

export const FormikStrengthMeter = (props: FormikStrengthMeterProps) => {
    const [field] = useField(props.name);

    return <StrengthMeter text={field.value} />;
};
