import isEmpty from 'lodash/isEmpty';

import { CompanyMessage } from '../../../types/CompanyMessage';
import { ProductContext } from '../../../types/ProductContext';

const createCompanyTypePrefix = (type: ProductContext) => {
    switch (type) {
        case ProductContext.LUBES:
            return '(L)';
        case ProductContext.FUEL:
            return '(F)';
    }
};

interface FormatCompanyNameConfig {
    company?: CompanyMessage;
    includeType?: boolean;
}

export const formatCompanyName = ({ company, includeType }: FormatCompanyNameConfig) => {
    if (!company) {
        return null;
    }

    if (typeof company !== 'object' || isEmpty(company)) {
        return null;
    }

    const companyString = company.name;

    if (includeType && company.type) {
        const companyTypePrefix = createCompanyTypePrefix(company.type);
        return `${companyTypePrefix} ${companyString}`;
    }

    return companyString;
};
