import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateSupplierGroup } from '../../../../common/api/clients/supplierGroups.api';
import { queries } from '../../../../common/api/queryKeys/queries';

export const useSupplierGroupUpdate = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateSupplierGroup,
        onSuccess: (supplierGroup) => {
            return queryClient.invalidateQueries({
                queryKey: queries.supplierGroups.detail(supplierGroup.id).queryKey,
            });
        },
    });
};
