import { ReactNode } from 'react';
import { WidthProps } from 'styled-system';

export interface TableBuilderColumnProps<T> extends WidthProps {
    header: ReactNode;
    numeric?: boolean;
    children: (row: T, index: number) => ReactNode;
    interactive?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function TableBuilderColumn<T>(props: TableBuilderColumnProps<T>) {
    // Renderless component. See TableBuilder.
    return null;
}
