import { createQueryKeys } from '@lukemorales/query-key-factory';

import { UserType } from '../../../../types/UserType';
import { getApiKeys } from '../clients/apiKey.api';

export const apiKeys = createQueryKeys('apiKeys', {
    list: (groupId: string, userType: UserType) => ({
        queryKey: [groupId],
        queryFn: () => getApiKeys(groupId, userType),
    }),
});
