import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { PrePlanningPage as OriginalPrePlanningPage } from './PrePlanningPage';

const PrePlanning = (props) => {
    return (
        <AppProviders>
            <OriginalPrePlanningPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(PrePlanning, 'reactPrePlanningPage');
