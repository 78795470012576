import styled from 'styled-components';

import { get } from '../../../common/ui/get';

interface PaginationButtonProps {
    active: boolean;
    children: number;
}

export const PaginationButton = styled.button.attrs({ type: 'button' })<PaginationButtonProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: ${get('sizes.6')}px;
    width: ${(props) => {
        const page = props.children;
        if (page < 100) {
            return '32px';
        }
        return '44px';
    }};
    color: ${(props) => (props.active ? get('colors.foreground.onEmphasis') : get('colors.foreground.default'))};
    background-color: ${(props) => (props.active ? get('colors.accent.emphasis') : 'transparent')};
    border-radius: ${get('radii.1')}px;
    margin-left: ${get('space.1')}px;
    margin-right: ${get('space.1')}px;
    border: none;
    font-size: 16px;

    &:hover {
        background-color: ${(props) => !props.active && get('colors.background.subtle')};
        border-width: ${(props) => !props.active && '1px'};
        border-color: ${(props) => !props.active && get('colors.border.muted')};
        border-style: ${(props) => !props.active && 'solid'};
    }

    &:disabled {
        cursor: not-allowed;
    }
`;
