import React, { ComponentPropsWithRef, forwardRef, ReactNode } from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { get } from '../ui/get';

import { createColorVariants } from './createColorVariants';

interface ButtonProps extends ComponentPropsWithRef<'button'> {
    sticky?: boolean;
    fullWidth?: boolean;
    color:
        | 'solid-clear'
        | 'outline-clear'
        | 'light-clear'
        | 'solid-dark'
        | 'outline-dark'
        | 'light-dark'
        | 'solid-red'
        | 'outline-red'
        | 'light-red'
        | 'solid-green'
        | 'outline-green'
        | 'light-green';
    children: ReactNode;
    tooltip?: ReactNode;
    size?: 'small' | 'medium' | 'large' | 'submit';
}

const ButtonContentWrapper = styled.span({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
});

const StyledTextWrapper = styled.span({});

const StyledElementWrapper = styled.span({
    ':first-child': {
        marginLeft: '-4px',
        marginRight: '4px',
    },
    ':last-child': {
        marginRight: '-4px',
        marginLeft: '4px',
    },
    ':only-child': {
        marginLeft: '-8px',
        marginRight: '-8px',
    },
});

const StyledButton = styled('button')<ButtonProps>(
    (props) => ({
        border: '1px solid transparent',
        borderRadius: `${get('radii.1')(props)}px`,
        display: 'inline-block',
        outline: 'none',
        cursor: 'pointer',
        verticalAlign: 'middle',
        position: 'relative',
        boxSizing: 'border-box',
        fontWeight: 500,
    }),
    (props) => {
        if (props.sticky) {
            return {
                position: 'sticky',
                bottom: 0, // we don't need the entire position system, but it's helping in this case
            };
        }
    },
    (props) => {
        if (props.fullWidth) {
            return {
                width: '100%',
            };
        }
    },
    variant({
        prop: 'size',
        variants: {
            small: {
                fontSize: '10px',
                lineHeight: '14px',
                padding: '8px 12px',
                height: '32px',
                svg: {
                    height: '16px',
                },
                'svg:first-child': {
                    marginLeft: '-4px',
                    marginRight: '4px',
                },
                'svg:last-child': {
                    marginRight: '-4px',
                    marginLeft: '4px',
                },
                'svg:only-child': {
                    marginLeft: '-4px',
                    marginRight: '-4px',
                },
            },
            medium: {
                padding: '8px 16px',
            },
            large: {
                padding: '22px 64px',
            },
            submit: {
                padding: '22px 64px',
                borderRadius: 0,
                display: 'block',
                width: '100%',
            },
        },
    }),
    variant({
        prop: 'color',
        variants: {
            ...createColorVariants({
                suffix: 'clear',
                themeKey: 'clear-blue',
            }),
            ...createColorVariants({ suffix: 'dark', themeKey: 'dark-blue' }),
            ...createColorVariants({ suffix: 'red', themeKey: 'signal-red' }),
            ...createColorVariants({
                suffix: 'green',
                themeKey: 'confirming-green',
            }),
        },
    })
);

/**
 * @deprecated Use `Button` from CDL components instead.
 */
// eslint-disable-next-line react/display-name
export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    // eslint-disable-next-line react/prop-types
    const { tooltip, children, size = 'medium', sticky = false, fullWidth = false, ...rest } = props;

    const content = (
        <React.Fragment>
            {React.Children.map(children, (child) => {
                return typeof child === 'string' ? (
                    <StyledTextWrapper>{child}</StyledTextWrapper>
                ) : (
                    <StyledElementWrapper>{child}</StyledElementWrapper>
                );
            })}
        </React.Fragment>
    );

    if (!tooltip) {
        return (
            <StyledButton sticky={sticky} fullWidth={fullWidth} {...rest} size={size} ref={ref}>
                <ButtonContentWrapper>{content}</ButtonContentWrapper>
            </StyledButton>
        );
    }

    return (
        <Tooltip label={tooltip}>
            <StyledButton sticky={sticky} fullWidth={fullWidth} {...rest} size={size} ref={ref}>
                <ButtonContentWrapper>{content}</ButtonContentWrapper>
            </StyledButton>
        </Tooltip>
    );
});
