import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getOrder } from '../clients/gateway.api';

export const orders = createQueryKeys('orders', {
    detail: (id: string) => ({
        queryKey: [id],
        queryFn: () => getOrder(id),
    }),
});
