import { createContext, Dispatch, ReactNode, useReducer } from 'react';

import { LayerAction, layerStoreReducer, LayerStoreState } from './layerStoreReducer';

type DrawerContextType = {
    store: LayerStoreState;
    dispatch: Dispatch<LayerAction>;
};

export const DrawerContext = createContext<DrawerContextType>({
    store: { layers: [] },
    dispatch: () => {
        throw new Error("Must be called within a DrawerContextProvider's child");
    },
});

type DrawerContextProviderProps = {
    children: ReactNode;
};

export const DrawerContextProvider = ({ children }: DrawerContextProviderProps) => {
    const [store, dispatch] = useReducer(layerStoreReducer, {
        layers: [],
    });

    return <DrawerContext.Provider value={{ store, dispatch }}>{children}</DrawerContext.Provider>;
};
