import { animated } from '@react-spring/web';
import styled from 'styled-components';
import { variant } from 'styled-system';

import { get } from '../../../common/ui/get';
import { ProgressBarProps } from '../ProgressBar';

interface BarProps {
    variant: ProgressBarProps['variant'];
}

export const Bar = styled(animated.div)<BarProps>`
    background: ${(p) => get(`colors.${p.color}`)};
    height: 100%;
    border-radius: 2px;
    min-width: 4px;

    ${variant({
        prop: 'variant',
        variants: {
            neutral: {
                backgroundColor: get('colors.foreground.default'),
            },
            accent: {
                backgroundColor: get('colors.accent.emphasis'),
            },
            attention: {
                backgroundColor: get('colors.attention.emphasis'),
            },
            negative: {
                backgroundColor: get('colors.negative.emphasis'),
            },
        },
    })}
`;
