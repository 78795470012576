export const getAddress = (geoCodeResponse) => {
    const getByType = (type) =>
        geoCodeResponse.address_components.find((c) => c.types.includes(type)) || { long_name: null };

    return {
        street: getByType('route').long_name,
        streetNumber: getByType('street_number').long_name,
        zipcode: getByType('postal_code').long_name,
        city: getByType('locality').long_name,
        country: getByType('country').long_name,
    };
};
