import { ChangeEvent, useRef, useState } from 'react';

import { AssignmentGatewayMessage } from '../../../../types/AssignmentGatewayMessage';
import { ProductContext } from '../../../../types/ProductContext';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { DropdownMenu } from '../../../cdl/DropdownMenu/DropdownMenu';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { DeleteConfirmPopup } from '../../../common/DeleteConfirmPopup/DeleteConfirmPopup';
import { translate } from '../../../common/helpers/translate.helper';
import { useAssignmentDelete } from '../hooks/useAssignmentDelete';
import { usePriceListUpload } from '../hooks/usePriceListUpload';

import { EditAssignmentDrawer } from './EditAssignmentDrawer';
import { PriceListPopup } from './PriceListPopup';

interface AssignmentsDropdownProps {
    assignment: AssignmentGatewayMessage;
}

export const AssignmentsDropdown = ({ assignment }: AssignmentsDropdownProps) => {
    const [priceListPopupVisible, setPriceListPopupVisible] = useState(false);
    const { addLoadingToast, addToast } = useToasts();
    const { push, pop } = useDrawer();
    const uploadMutation = usePriceListUpload(
        assignment.supplier.supplierGroupId,
        assignment.supplier.id,
        assignment.customerId
    );
    const fileInputRef = useRef<HTMLInputElement>(null);
    const deleteAssignmentMutation = useAssignmentDelete(assignment.id);
    const [deleteAssignmentConfirmationVisible, setDeleteAssignmentConfirmationVisible] = useState(false);

    const handleEditClick = () => {
        push({
            content: <EditAssignmentDrawer assignment={assignment} onSuccess={pop} />,
        });
    };

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };

    const uploadFile = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            addLoadingToast(
                () => uploadMutation.mutateAsync(file),
                translate('assignments.toasts.uploadLoading'),
                translate('assignments.toasts.uploadSuccess'),
                translate('assignments.toasts.uploadError')
            );
        }
        event.target.value = '';
    };

    const options = [
        {
            label: translate('assignments.edit'),
            onSelect: () => {
                handleEditClick();
            },
        },
    ];

    if (assignment.customer.type === ProductContext.LUBES && assignment.supplier.type === ProductContext.LUBES) {
        options.push({
            label: translate('assignments.btnupload'),
            onSelect: () => {
                handleUploadClick();
            },
        });
    }

    if (assignment.dateLastUploaded) {
        options.push({
            label: translate('assignments.viewPriceLists'),
            onSelect: () => setPriceListPopupVisible(true),
        });
    }

    async function handleDeleteAssignment() {
        await deleteAssignmentMutation.mutateAsync();
        setDeleteAssignmentConfirmationVisible(false);
        addToast(translate('assignments.deleteAssignment.deleteSuccess'));
    }

    options.push({
        label: translate('assignments.deleteAssignment.delete'),
        onSelect: () => {
            setDeleteAssignmentConfirmationVisible(true);
        },
    });

    return (
        <div>
            <DropdownMenu align="end" options={options} />
            <input ref={fileInputRef} type="file" onInput={uploadFile} style={{ display: 'none' }} />
            {priceListPopupVisible ? (
                <PriceListPopup
                    supplierGroupId={assignment.supplier.supplierGroupId}
                    customerId={assignment.customerId}
                    isOpen={priceListPopupVisible}
                    onDismiss={() => setPriceListPopupVisible(false)}
                />
            ) : null}

            <DeleteConfirmPopup
                isOpen={deleteAssignmentConfirmationVisible}
                onSubmit={handleDeleteAssignment}
                isSubmitting={deleteAssignmentMutation.isPending}
                onDismiss={() => setDeleteAssignmentConfirmationVisible(false)}
                headline={translate('assignments.deleteAssignment.headline')}
                text={translate('assignments.deleteAssignment.description', {
                    customer: assignment.customer.name,
                    supplier: assignment.supplier.name,
                })}
                deleteButtonText={translate('assignments.deleteAssignment.delete')}
            />
        </div>
    );
};
