import { AxiosResponse } from 'axios';

import { MoneyMessage } from '../../../../types/MoneyMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const deleteSurcharge = async (surchargeId: string) => {
    const response = await authorizedAxiosInstance.delete(`/v1/surcharge/${surchargeId}`);
    return response.data;
};

export interface SurchargeRequestMessage {
    group?: string;
    packType: string;
    supplierGroupId: string;
    customerId?: string;
    value: MoneyMessage;
}

export const createSurcharge = async (surchargeData: SurchargeRequestMessage): Promise<AxiosResponse> => {
    const response = await authorizedAxiosInstance.post(`/v1/surcharge`, surchargeData);
    return response.data;
};
