import { useQuery } from '@tanstack/react-query';

import { getManagementUserById } from '../../../common/api/clients/gateway.api';
import { getUser } from '../../../common/api/clients/user.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { useRole } from '../../../common/hooks/useRole';

export const useUser = (userId: string) => {
    const role = useRole();

    return useQuery({
        queryKey: queryKeys.user(userId),
        queryFn: () => (role.hasAnyAdminRights() ? getManagementUserById(userId) : getUser()),
    });
};
