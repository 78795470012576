import { PageMessage } from '../../../../types/PageMessage';
import { PortMessage, PortRequestMessage } from '../../../../types/PortMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export interface GetPortsParams {
    searchQuery?: string;
    ids?: string[];
    page?: number;
}

export const getPorts = async ({ searchQuery, ids, page = 0 }: GetPortsParams): Promise<PageMessage<PortMessage>> => {
    const params = new URLSearchParams();

    if (searchQuery) {
        params.append('searchQuery', searchQuery.trim());
    }

    if (ids && ids.length) {
        params.append('ids', ids.join(','));
    }

    if (page !== undefined) {
        params.append('page', page.toString());
    }

    const response = await authorizedAxiosInstance.get(`/v1/port?${params.toString()}`);

    return response.data;
};

export const createPort = async (port: PortRequestMessage): Promise<PortMessage> => {
    const response = await authorizedAxiosInstance.post('/v1/port', port);

    return response.data;
};

interface UpdatePortParams {
    id: string;
    port: unknown;
}

export const updatePort = async ({ id, port }: UpdatePortParams): Promise<PortMessage> => {
    const response = await authorizedAxiosInstance.put(`/v1/port/${id}`, port);

    return response.data;
};
