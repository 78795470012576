import { Text } from '../../cdl/Text/Text';
import { formatDateTime } from '../../common/helpers/formatDateTime.helper';

export const ScheduleStopTime = ({ title, stop, date }: { title: string; stop: any; date: string }) => {
    return (
        <>
            <Text variant="extraSmall" color="foreground.subtle" as="p">
                {title}
            </Text>
            <Text as="p">
                {formatDateTime({
                    date: date,
                    timeZoneId: stop.port?.timeZoneId || 'UTC',
                    format: 'MMM D, YYYY',
                })}
            </Text>
            <Text as="p">
                {formatDateTime({
                    date: date,
                    timeZoneId: stop.port?.timeZoneId || 'UTC',
                    format: 'h:mm A',
                }) + (stop.port?.timeZoneId ? '' : ' (UTC)')}
            </Text>
        </>
    );
};
