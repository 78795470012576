import { useQueryClient } from '@tanstack/react-query';
import { AxiosProgressEvent, AxiosResponse } from 'axios';

import { FileManagementResponse } from '../../../../../types/FileManagementResponse';
import * as offerFileManagementApi from '../../../../common/api/clients/offerFileManagement.api';
import { queryKeys } from '../../../../common/api/queryKeys';
import { Attachments } from '../../../../common/Attachments/components/Attachments';
import { useRole } from '../../../../common/hooks/useRole';
import { OfferModel } from '../../../model/OfferModel';

interface OfferDetailAttachmentsProps {
    offer: OfferModel;
}

export const OfferDetailAttachments = ({ offer }: OfferDetailAttachmentsProps) => {
    const queryClient = useQueryClient();
    const role = useRole();

    const uploadFile = (
        file: File,
        onUploadProgress: (event: AxiosProgressEvent) => void
    ): Promise<FileManagementResponse> => {
        return offerFileManagementApi.uploadFile(file, offer.id, onUploadProgress);
    };

    const deleteFile = (fileId: string): Promise<void> => {
        return offerFileManagementApi.deleteFile([fileId], offer.id);
    };

    const downloadFile = (fileId: string): Promise<AxiosResponse> => {
        return offerFileManagementApi.downloadFile(fileId, offer.id);
    };

    const invalidateCache = async () => {
        await queryClient.cancelQueries({ queryKey: queryKeys.offer(offer.id) });
        return queryClient.invalidateQueries({ queryKey: queryKeys.offer(offer.id) });
    };

    if (role.isAdmin() || offer.isCanceled()) {
        return (
            <Attachments
                files={offer.files}
                config={{
                    uploadFile,
                    deleteFile,
                    downloadFile,
                    invalidateCache,
                    hasWriteRights: false,
                    enableDownloadUploadedFiles: true,
                }}
            />
        );
    }

    const companyId = role.isCustomer() ? offer.customerId : offer.supplierId;
    const counterCompanyName = role.isCustomer() ? offer.supplier.name : offer.customer.name;

    return (
        <Attachments
            files={offer.files}
            companyId={companyId}
            counterCompanyName={counterCompanyName}
            config={{
                uploadFile,
                deleteFile,
                downloadFile,
                invalidateCache,
                hasWriteRights: role.hasWriteRights(companyId) && !offer.isCanceled(),
                enableDownloadUploadedFiles: true,
            }}
        />
    );
};
