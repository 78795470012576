import { FormikErrors, setIn } from 'formik';

import { translate } from '../../../../../common/helpers/translate.helper';
import { FormikApprovalSettingsState } from '../MultipleApproversSettingsForm';

export function validateMinimumThresholdMatchesLimits(
    errors: FormikErrors<FormikApprovalSettingsState>,
    values: FormikApprovalSettingsState
): FormikErrors<FormikApprovalSettingsState> {
    let hasAtLeastOneLowerLimit = false;
    const minimumThresholdValue = Number(values.minimumThreshold?.value ?? 0);

    if (minimumThresholdValue > 0) {
        values.additionalApprovers.forEach((additionalLimit, index) => {
            if (
                additionalLimit.approvalLimit !== '' &&
                minimumThresholdValue >= Number(additionalLimit.approvalLimit)
            ) {
                hasAtLeastOneLowerLimit = true;
                errors = setIn(
                    errors,
                    `additionalApprovers.${index}.approvalLimit`,
                    translate('multipleApproverSettings.errors.limitLowerThanThreshold')
                );
            }
        });
    }

    if (hasAtLeastOneLowerLimit) {
        errors = setIn(
            errors,
            'minimumThreshold.value',
            translate('multipleApproverSettings.errors.minimumThresholdHigherThanLimit')
        );
    }

    return errors;
}
