import { QuickStatsMessage } from '../../../../types/QuickStatsMessage';
import { formatMoney } from '../../../common/helpers/formatMoney.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useProductContext } from '../../../common/hooks/useProductContext';

import { StatsItem } from './StatsItem';

interface PplStatsItemProps {
    stats: QuickStatsMessage;
}

export const PplStatsItem = ({ stats }: PplStatsItemProps) => {
    const { isFuel } = useProductContext();
    const description = isFuel
        ? translate('dashboard.quickstats.priceMetricTons')
        : translate('dashboard.quickstats.ppl');

    return (
        <StatsItem
            item={formatMoney({
                value: stats.ppl.value,
                currency: stats.ppl.currency,
                maximumFractionDigits: 3,
            })}
            description={description}
        />
    );
};
