import styled from 'styled-components';

import { get } from '../../../common/ui/get';
import { Text } from '../../Text/Text';
import { CheckboxProps } from '../Checkbox';

type CheckboxLabelProps = Pick<CheckboxProps, 'disabled'>;

export const CheckboxLabel = styled(Text).attrs({ variant: 'body' })<CheckboxLabelProps>`
    color: ${(props) => (props.disabled ? get('colors.foreground.disabled') : get('colors.foreground.default'))};
`;
