import { useField } from 'formik';
import PropTypes from 'prop-types';

import { FormattedNumberInput } from '../NumberInputs/FormattedNumberInput';

export const FormikFormattedNumberInput = (props) => {
    const [field, , helpers] = useField(props.name);

    const onChange = (value) => {
        helpers.setValue(value);
    };

    return <FormattedNumberInput {...props} value={field.value} onChange={onChange} />;
};

FormikFormattedNumberInput.propTypes = {
    name: PropTypes.string.isRequired,
    decimalsLimit: PropTypes.number,
    allowNegativeValue: PropTypes.bool,
    decimalScale: PropTypes.number,
    placeholder: PropTypes.string,
};
