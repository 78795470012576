import { components } from 'react-select';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const CustomSingleValue = styled(components.SingleValue)`
    && {
        font-size: 16px;
        line-height: 24px;
        color: ${(props) => (props.isDisabled ? get('colors.foreground.disabled') : get('colors.foreground.default'))};
        font-weight: ${get('fontWeights.medium')};
        margin-left: 0;
    }
`;
