import css from '@styled-system/css';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { IconCircle } from '../../common/icons/cdl/Circle';
import { IconCircleCheck } from '../../common/icons/cdl/CircleCheck';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';
import { get } from '../../common/ui/get';

import { ButtonSelectBox } from './ButtonSelectBox';

const Hint = styled(Flex)(
    css({
        borderRadius: `0 0 ${get('radii.1')}px ${get('radii.1')}px`,
        position: 'absolute',
        bottom: '-16px',
        right: '5px',
        backgroundColor: 'clear-blue.4',
        paddingX: 3,
        paddingBottom: 1,
        userSelect: 'none',
    })
);

export const FuelEnquiryCloselinkNetwork = () => {
    const { values, setValues } = useFormikContext();

    const onSpotClick = () => {
        if (values.spot) {
            if (!values.receiverSupplierIds?.length) {
                return;
            }
            setValues({
                ...values,
                spot: false,
            });
        } else {
            setValues({
                ...values,
                spot: true,
            });
        }
    };

    return (
        <Box position="relative">
            <ButtonSelectBox isSelected={values.spot} onClick={onSpotClick}>
                {values.spot ? <IconCircleCheck /> : <IconCircle />}
                {translate('fuelEnquiry.third.suppliers.closelinkNetwork')}
            </ButtonSelectBox>
            <Hint>
                <Text color="clear-blue.0" size={10}>
                    {translate('fuelEnquiry.third.suppliers.nonAssignedSuppliers')}
                </Text>
            </Hint>
        </Box>
    );
};
