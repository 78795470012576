import { useField } from 'formik';

import { EnumComboBox, EnumComboBoxProps } from '../../enums/EnumComboBox';

interface FormikEnumComboBoxProps<IsMulti extends boolean>
    extends Omit<EnumComboBoxProps<IsMulti>, 'value' | 'onChange'> {
    name: string;
}

export function FormikEnumComboBox<IsMulti extends boolean>(props: FormikEnumComboBoxProps<IsMulti>) {
    const [field, meta, helpers] = useField(props.name);

    const onChange = (options: string[] | string) => {
        helpers.setValue(options);
    };

    return (
        <EnumComboBox
            {...props}
            {...field}
            onChange={onChange}
            enumType={props.enumType}
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : props.caption}
            onBlur={() => {
                helpers.setTouched(true);
            }}
        />
    );
}
