import { Text } from '../../cdl/Text/Text';
import { OfferModel } from '../../offer/model/OfferModel';
import { DataSheet } from '../DataSheet/DataSheet';
import { formatAddress } from '../helpers/formatAddress.helper';
import { translate } from '../helpers/translate.helper';

import { CoreDataPopup } from './CoreDataPopup';

interface CustomerInfoPopupProps {
    offer: OfferModel;
}

export const CustomerInfoPopup = ({ offer }: CustomerInfoPopupProps) => {
    const createWording = (content: string | null) => {
        if (!offer.isEnquiry() || !offer.isSpot()) {
            return content;
        }

        return offer.isSpot()
            ? translate('offer.detail.coreData.customerPopup.emailAddressNotAvailableSpot')
            : translate('offer.detail.coreData.customerPopup.emailAddressNotAvailableContracted');
    };

    return (
        <CoreDataPopup heading={translate('offer.detail.coreData.customerPopup.headline')}>
            <DataSheet>
                <DataSheet.Label>{translate('offer.detail.coreData.customerPopup.name')}</DataSheet.Label>
                <Text>{offer.customer.name}</Text>

                {offer.customer.email ? (
                    <>
                        <DataSheet.Label>
                            {translate('offer.detail.coreData.customerPopup.emailAddress')}
                        </DataSheet.Label>
                        <Text>{createWording(offer.customer.email)}</Text>
                    </>
                ) : null}

                {offer.customer.phone ? (
                    <>
                        <DataSheet.Label>
                            {translate('offer.detail.coreData.customerPopup.phoneNumber')}
                        </DataSheet.Label>
                        <Text>{offer.customer.phone}</Text>
                    </>
                ) : null}

                {offer.customer.address ? (
                    <>
                        <DataSheet.Label>{translate('offer.detail.coreData.customerPopup.address')}</DataSheet.Label>
                        <Text>{formatAddress({ address: offer.customer.address })}</Text>
                    </>
                ) : null}
            </DataSheet>
        </CoreDataPopup>
    );
};
