import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { VesselDetailPage as OriginalVesselDetailPage } from './VesselDetailPage';

const VesselDetailPage = (props) => (
    <AppProviders>
        <OriginalVesselDetailPage {...props} />
    </AppProviders>
);

export default createDirectiveFromReactComponent(VesselDetailPage, 'reactVesselDetailPage');
