import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconAB = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12 5a1 1 0 0 1 1 1v12a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1ZM5.5 9A1.5 1.5 0 0 0 4 10.5v.5h3v-.5A1.5 1.5 0 0 0 5.5 9ZM9 10.5a3.5 3.5 0 1 0-7 0V16a1 1 0 1 0 2 0v-3h3v3a1 1 0 1 0 2 0v-5.5ZM15 8a1 1 0 0 1 1-1h3a3 3 0 0 1 2.236 5A3 3 0 0 1 19 17h-3a1 1 0 0 1-1-1V8Zm4 3a1 1 0 0 0 0-2h-2v2h2Zm-2 2h2a1 1 0 0 1 0 2h-2v-2Z"
            clipRule="evenodd"
        />
    </svg>
);
