import moment from 'moment';
import styled from 'styled-components';

import { DemandInformationStop } from '../../../../../types/DemandInformationMessage';
import { CategoryState } from '../../../../../types/ReachInformationMessage';
import { Text } from '../../../../cdl/Text/Text';
import { Tooltip } from '../../../../cdl/Tooltip/Tooltip';
import { formatDate } from '../../../../common/helpers/formatDate.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconAlertTriangle } from '../../../../common/icons/cdl/AlertTriangle';
import { Box } from '../../../../common/ui/Box';
import { theme } from '../../../../common/ui/theme';
import { KeyPortIndicator } from '../../../../keyport/KeyPortIndicator';

const SmallBoldText = styled(Text).attrs({ variant: 'small' })`
    font-weight: 500;
    white-space: nowrap;
`;

interface PortNameProps {
    stop: DemandInformationStop;
    isFirstStop?: boolean;
    isLastStop?: boolean;
}

function getTooltipText(stop: DemandInformationStop, isFirstStop?: boolean, isLastStop?: boolean): string {
    if (isFirstStop) {
        if (stop.currentPort) {
            return translate('demand.vesselSchedule.currentPort');
        } else {
            return translate('demand.vesselSchedule.lastPort');
        }
    }

    const stopOutOfReach = stop.categories.some((it) => it.categoryState === CategoryState.OUT_OF_REACH);
    const daysUntilStop = moment(stop.eta).diff(moment(), 'days');
    const formattedEta = formatDate({ date: stop.eta });

    if (stop.keyPort) {
        if (stopOutOfReach) {
            return translate('demand.vesselSchedule.nextKeyPortNotInReach');
        }

        if (daysUntilStop === 1) {
            return translate('demand.vesselSchedule.nextKeyPortDay', { date: formattedEta });
        }

        return translate('demand.vesselSchedule.nextKeyPortDays', {
            days: daysUntilStop.toString(),
            date: formattedEta,
        });
    }

    if (stopOutOfReach) {
        return translate('demand.vesselSchedule.nextPortNotInReach');
    }

    if (isLastStop) {
        if (daysUntilStop === 1) {
            return translate('demand.vesselSchedule.nextPortNotInReachDay', { date: formattedEta });
        }

        return translate('demand.vesselSchedule.nextPortNotInReachDays', {
            days: daysUntilStop.toString(),
            date: formattedEta,
        });
    } else {
        return translate('demand.vesselSchedule.nextPort');
    }
}

export const PortName = ({ stop, isFirstStop, isLastStop }: PortNameProps) => {
    const stopNotInReach = stop.categories.some((it) => it.categoryState === CategoryState.OUT_OF_REACH);
    const tooltipText = getTooltipText(stop, isFirstStop, isLastStop);

    return (
        <Tooltip label={tooltipText}>
            <Box display="inline-flex" alignItems="center" columnGap={2}>
                {stopNotInReach ? (
                    <IconAlertTriangle width={16} height={16} color={theme.colors.negative.foreground} />
                ) : null}

                <SmallBoldText color={isFirstStop && !stop.currentPort ? 'foreground.subtle' : 'foreground.default'}>
                    {stop.portName}
                </SmallBoldText>

                {stop.keyPort ? <KeyPortIndicator /> : null}
            </Box>
        </Tooltip>
    );
};
