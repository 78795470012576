import useComponentSize from '@rehooks/component-size';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { SliderWrapper } from '../../../common/Slider/SliderWrapper';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex';
import { SupplierPriceSlider } from '../SupplierPriceSlider';
import { useCheapestScope } from '../useCheapestScope';

const getSlidesPerViewByWidth = (width) => {
    if (width < 700) {
        return 1;
    }

    if (width < 950) {
        return 2;
    }

    if (width < 1350) {
        return 3;
    }

    if (width < 1600) {
        return 4;
    }

    return 5;
};

export const ComparePriceWrapper = ({ children: comparePriceButtons, isFetching, supplierData }) => {
    const [sliderWrapperRef, setSliderWrapperRef] = useState(null);
    const { width } = useComponentSize({ current: sliderWrapperRef });
    const slidesPerView = getSlidesPerViewByWidth(width);

    const [activeSlide, setActiveSlide] = useState(0);
    const cheapestDefaultScope = useCheapestScope({
        supplierData,
        isFetching,
        activeSlide,
        slidesPerView,
    });

    if (!comparePriceButtons.length && !isFetching) {
        return null;
    }

    return (
        <SliderWrapper ref={setSliderWrapperRef}>
            <Box paddingX={5}>
                <SupplierPriceSlider
                    slidesPerView={slidesPerView}
                    isFetching={isFetching}
                    onSlideChange={setActiveSlide}
                    cheapestDefaultScope={cheapestDefaultScope}
                >
                    {comparePriceButtons.map((button, index) => (
                        <Flex key={index} width="100%" justifyContent="center">
                            {button}
                        </Flex>
                    ))}
                </SupplierPriceSlider>
            </Box>
        </SliderWrapper>
    );
};

ComparePriceWrapper.propTypes = {
    children: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    supplierData: PropTypes.object,
};
