import { AxiosProgressEvent, AxiosResponse } from 'axios';

import { FileManagementResponse } from '../../../../types/FileManagementResponse';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const uploadFile = async (
    file: File,
    orderId: string,
    onUploadProgress: (event: AxiosProgressEvent) => void
): Promise<FileManagementResponse> => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await authorizedAxiosInstance.post(`/v1/order/${orderId}/files`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
    });

    return response.data;
};

export const downloadFile = (fileId: string, orderId: string): Promise<AxiosResponse<Blob>> => {
    return authorizedAxiosInstance.get(`/v1/order/${orderId}/files/${fileId}/download`, {
        responseType: 'blob',
    });
};

export const deleteFile = (fileIds: string[], orderId: string): Promise<AxiosResponse> => {
    const params = new URLSearchParams();

    if (fileIds.length) {
        fileIds.forEach((id) => params.append('ids', id));
    }

    return authorizedAxiosInstance.delete(`/v1/order/${orderId}/files?${params.toString()}`);
};
