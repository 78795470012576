import { BaseStatisticsMessage } from '../../../types/StatisticsMessage';

import { ProductsTabFilter } from './ProductsTabFilter';
import { ProductsTabStatistics } from './ProductsTabStatistics';

interface ProductsTabContentProps {
    productStatistics: BaseStatisticsMessage['product'];
}

export const ProductsTabContent = ({ productStatistics }: ProductsTabContentProps) => {
    return (
        <ProductsTabFilter allEntries={productStatistics}>
            {(filteredEntries) => <ProductsTabStatistics productStatistics={filteredEntries} />}
        </ProductsTabFilter>
    );
};
