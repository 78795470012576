import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const getCustomerSettings = async (id: string): Promise<any> => {
    const response = await authorizedAxiosInstance.get(`/v1/customers/${id}/settings`);

    return response.data;
};

interface UpdateCustomerSettingsParams {
    id: string;
    settings: unknown;
}

export const updateCustomerSettings = async ({ id, settings }: UpdateCustomerSettingsParams): Promise<any> => {
    const response = await authorizedAxiosInstance.put(`/v1/customers/${id}/settings`, settings);

    return response.data;
};
