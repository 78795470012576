import PropTypes from 'prop-types';

import { Button } from '../../../common/buttons/Button';
import { translate } from '../../../common/helpers/translate.helper';
import { IconPlus } from '../../../common/icons/cdl/Plus';
import { Table } from '../../../common/Table/Table';
import { Td } from '../../../common/Table/Td';
import { Th } from '../../../common/Table/Th';
import { Tr } from '../../../common/Table/Tr';

import { ItemsTableRow } from './ItemsTableRow';

export const ItemsTable = ({ items, onRemoveItemClick, onAddItemClick }) => {
    const _onAddItemClick = (event) => {
        event.preventDefault();

        onAddItemClick();
    };

    return (
        <Table>
            <thead>
                <Tr>
                    <Th width="20%">{translate('order.product')}</Th>
                    <Th width="15%">{translate('order.productgroup')}</Th>
                    <Th width="15%">{translate('order.packtype')}</Th>
                    <Th>{translate('order.unitsize')}</Th>
                    <Th width="10%">{translate('order.units')}</Th>
                    <Th>{translate('order.volume')}</Th>
                    <Th>{translate('order.productunit')}</Th>
                    <Th>{translate('order.tableActions')}</Th>
                </Tr>
            </thead>

            <tbody>
                {items.map((item, index) => {
                    return (
                        <ItemsTableRow
                            key={item.id}
                            index={index}
                            onRemoveItemClick={onRemoveItemClick}
                            showRemoveIcon={items.length > 1}
                        />
                    );
                })}
                <Tr>
                    <Td colSpan={8}>
                        <Button color="light-dark" onClick={_onAddItemClick}>
                            <IconPlus />
                            {translate('order.additem')}
                        </Button>
                    </Td>
                </Tr>
            </tbody>
        </Table>
    );
};

ItemsTable.propTypes = {
    items: PropTypes.array,
    onRemoveItemClick: PropTypes.func,
    onAddItemClick: PropTypes.func,
};
