import { AxiosPromise } from 'axios';

import { AccessLevel } from '../../../../types/AccessLevel';
import { ApiKeyMessage } from '../../../../types/ApiKeyMessage';
import { CompanyAccessMessage } from '../../../../types/CompanyAccessMessage';
import { UserType } from '../../../../types/UserType';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';
import { authorizedGatewayAxiosInstance } from '../instances/AuthorizedGatewayAxiosInstance';

export const getApiKeys = async (groupId: string, userType: UserType): Promise<ApiKeyMessage[]> => {
    const params = new URLSearchParams();
    params.append('groupId', groupId);
    params.append('userType', userType.toString());

    const response = await authorizedGatewayAxiosInstance.get(`/v1/apikey`, { params });

    return response.data;
};

interface UpdateApiKey {
    id: string;
    apiKeyMessage: ApiKeyMessage;
}

export const updateApiKey = async ({ id, apiKeyMessage }: UpdateApiKey) => {
    return authorizedAxiosInstance.put(`/v1/apikey/${id}`, apiKeyMessage);
};

interface CreateApiKeyMessage {
    groupId: string;
    userType: UserType;
    accessLevel: AccessLevel;
    companyAccesses: CompanyAccessMessage[];
}

export const createApiKey = async (apiKeyMessage: CreateApiKeyMessage): AxiosPromise<ApiKeyMessage> => {
    return authorizedAxiosInstance.post(`/v1/apikey`, apiKeyMessage);
};
