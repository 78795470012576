import { FormikErrors, setIn } from 'formik';

import { translate } from '../../../../../common/helpers/translate.helper';
import { FormikApprovalSettingsState } from '../MultipleApproversSettingsForm';

export function validateMissingEmailAddress(
    errors: FormikErrors<FormikApprovalSettingsState>,
    values: FormikApprovalSettingsState
): FormikErrors<FormikApprovalSettingsState> {
    values.additionalApprovers.forEach((additionalApprover, additionalApproverIndex) => {
        if (additionalApprover.approvalLimit === '' || additionalApprover.approvers.length > 1) {
            return;
        }

        const hasNoApprovers =
            additionalApprover.approvers.length === 1 && additionalApprover.approvers[0].emailAddress === '';

        if (hasNoApprovers) {
            errors = setIn(
                errors,
                `additionalApprovers[${additionalApproverIndex}].approvers[0].emailAddress`,
                translate('multipleApproverSettings.errors.missingEmailAddress')
            );
        }
    });
    return errors;
}
