import { useMutation, useQueryClient } from '@tanstack/react-query';

import { redispatchOrder } from '../../../common/api/clients/order.api';
import { queryKeys } from '../../../common/api/queryKeys';

export const useOrderRedispatch = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: redispatchOrder,
        onSuccess: (redispatchedOrder) => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.order(redispatchedOrder.id) });
        },
    });
};
