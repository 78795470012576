import { useState, useRef } from 'react';
import styled from 'styled-components';

import { translate } from '../helpers/translate.helper';
import { useAngularDependencies } from '../hooks/useAngularDependencies';
import { Box } from '../ui/Box.tsx';
import { Flex } from '../ui/Flex.tsx';

import { GlobalSearchButton } from './GlobalSearchButton';

const SearchInput = styled.input`
    border: 0;
    flex-grow: 1;
    :hover {
        border: none;
    }
`;

const getQueryFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('query') || '';
};

export const GlobalSearch = () => {
    const { $state } = useAngularDependencies();
    const [query, setQuery] = useState(() => getQueryFromURL());

    const searchInputRef = useRef(null);

    const onSearchSubmit = (event) => {
        event.preventDefault();

        if (searchInputRef.current) {
            // focus the input as a convenience for the user
            searchInputRef.current.focus();
        }

        if (query) {
            $state.go('base.search-list', { query });
        }
    };

    return (
        <Flex as="form" onSubmit={onSearchSubmit} width="100%" height="100%">
            <Box marginRight={3} marginLeft={-4}>
                <GlobalSearchButton />
            </Box>
            <SearchInput
                ref={searchInputRef}
                onChange={(e) => setQuery(e.target.value)}
                value={query}
                placeholder={translate('globalsearch.placeholder')}
            />
        </Flex>
    );
};
