import { getAssignments } from '../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { usePaginationQuery } from '../../../common/hooks/usePaginationQuery';

export const useSupplierPagination = ({ customerIds, page }) => {
    return usePaginationQuery({
        queryKey: queryKeys.suppliers({
            page,
            customerIds,
        }),
        queryFn: () =>
            getAssignments({
                page,
                customerIds,
            }),
        prefetchQueryKey: queryKeys.suppliers({
            page: page + 1,
            customerIds,
        }),
        prefetchQueryFn: () =>
            getAssignments({
                page: page + 1,
                customerIds,
            }),
    });
};
