import { ReactNode } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { theme } from './ui/theme';

interface ThemeProviderProps {
    children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
    return <StyledComponentsThemeProvider theme={theme}>{children}</StyledComponentsThemeProvider>;
};
