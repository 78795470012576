import { Avatar } from '../../../../cdl/Avatar/Avatar';
import { Tag } from '../../../../cdl/Tag/Tag';
import { Text } from '../../../../cdl/Text/Text';
import { formatFuelMoney } from '../../../../common/helpers/formatFuelMoney.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useFuelVolumeFormat } from '../../../../common/hooks/useFuelVolumeFormat';
import { Box } from '../../../../common/ui/Box';
import { OfferModel } from '../../../../offer/model/OfferModel';

import { OfferLabelList } from './OfferLabelList';

interface OfferSupplierAndTotalsProps {
    offer: OfferModel;
    isBestPrice?: boolean;
}

export const OfferSupplierAndTotals = ({ offer, isBestPrice }: OfferSupplierAndTotalsProps) => {
    const formatFuelVolume = useFuelVolumeFormat();

    return (
        <Box
            display="grid"
            gridTemplateColumns="40px 1fr auto"
            paddingBottom="4"
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderBottomColor="border.subtle"
            gap={4}
        >
            <Avatar name={offer.supplier.name} imageUrl={offer.supplier.supplierGroup.logo?.url} />
            <Box>
                <Text variant="subtitle1">{offer.supplier.name}</Text>
                <OfferLabelList offer={offer} />
            </Box>

            <Box style={{ textAlign: 'right' }}>
                <Box display="flex" justifyContent="end" style={{ columnGap: '8px' }}>
                    {isBestPrice ? <Tag variant="positive">{translate('fuelOrder.offerList.bestPrice')}</Tag> : null}
                    <Text variant="subtitle1">
                        {offer.hasValidProducts()
                            ? formatFuelMoney({
                                  value: offer.total.value,
                                  currency: offer.total.currency,
                              })
                            : '-'}
                    </Text>
                </Box>
                <Text variant="extraSmall" color="foreground.muted">
                    {offer.volume ? formatFuelVolume({ volume: offer.volume }) : '-'}
                </Text>
            </Box>
        </Box>
    );
};
