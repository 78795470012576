import { QueryObserverResult, useQuery } from '@tanstack/react-query';

import { OfferApprovalSettingsMessage } from '../../../../types/OfferApprovalSettingsMessage';
import { getOfferApprovalSettings } from '../../../common/api/clients/offerApproval.api';
import { queryKeys } from '../../../common/api/queryKeys';

export const useOfferApprovalSettings: (
    offerId: string,
    enabled: boolean
) => QueryObserverResult<OfferApprovalSettingsMessage> = (offerId: string, enabled: boolean) => {
    return useQuery({
        queryKey: queryKeys.offerApprovalSettings(offerId),
        queryFn: () => {
            return getOfferApprovalSettings(offerId);
        },
        enabled,
    });
};
