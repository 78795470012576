import { ReactNode, SyntheticEvent } from 'react';
import ReactSwitch, { ReactSwitchProps } from 'react-switch';
import styled from 'styled-components';

import { Box } from '../../common/ui/Box';
import { get } from '../../common/ui/get';
import { theme } from '../../common/ui/theme';
import { Text } from '../Text/Text';

import { IntederminateSwitch } from './IndeterminateSwitch';

const StyledSwitch = styled(ReactSwitch)`
    opacity: 1 !important;

    .react-switch-handle {
        height: 8px !important;
        width: 8px !important;
        top: 4px !important;
        left: 3px !important;
        right: 3px !important;
        box-shadow: ${(props) => (props.disabled ? null : 'small')};
    }
`;

const SwitchLabelGrid = styled.label`
    display: grid;
    grid-template-rows: min-content min-content;
    grid-template-columns: 24px 1fr;
    column-gap: ${get('space.4')}px;
    row-gap: ${get('space.2')}px;
    user-select: none;
    cursor: pointer;
    width: fit-content;
`;

const SwitchLabelFlex = styled.label`
    display: flex;
    align-items: center;
    flex-direction: row;
    user-select: none;
    width: max-content;
`;

interface ConfiguredSwitchProps extends Pick<ReactSwitchProps, 'checked' | 'disabled'> {
    indeterminate?: boolean;
    onChange: (checked: boolean, event?: SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent) => void;
}

const ConfiguredSwitch = (props: ConfiguredSwitchProps) => {
    if (props.indeterminate) {
        return <IntederminateSwitch onClick={() => props.onChange(true)} disabled={props.disabled} />;
    }

    return (
        <StyledSwitch
            checked={props.checked}
            onChange={props.onChange}
            offColor={props.disabled ? theme.colors.background.disabled : theme.colors.background.muted}
            onColor={props.disabled ? theme.colors.border.accent : theme.colors.accent.emphasis}
            width={24}
            height={16}
            uncheckedIcon={false}
            checkedIcon={false}
            activeBoxShadow="none"
            disabled={props.disabled}
        />
    );
};

export interface SwitchProps extends Pick<ReactSwitchProps, 'checked' | 'disabled'> {
    label?: string;
    /**
      Can only be used together with label
     */
    description?: ReactNode;
    indeterminate?: boolean;
    onChange: (checked: boolean, event?: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent) => void;
}

export const Switch = (props: SwitchProps) => {
    if (props.label && props.description) {
        return (
            <SwitchLabelGrid>
                <Box gridColumn="1/2" gridRow="1/3" paddingTop="2px">
                    <ConfiguredSwitch
                        checked={props.checked}
                        onChange={props.onChange}
                        disabled={props.disabled}
                        indeterminate={props.indeterminate}
                    />
                </Box>
                <Text size={14}>{props.label}</Text>
                <Text size={12} color={theme.colors.foreground.subtle}>
                    {props.description}
                </Text>
            </SwitchLabelGrid>
        );
    }

    if (props.label && !props.description) {
        return (
            <SwitchLabelFlex>
                <ConfiguredSwitch
                    checked={props.checked}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    indeterminate={props.indeterminate}
                />
                <Text style={{ cursor: 'pointer' }} marginLeft={4} size={14}>
                    {props.label}
                </Text>
            </SwitchLabelFlex>
        );
    }

    return (
        <ConfiguredSwitch
            checked={props.checked}
            onChange={props.onChange}
            disabled={props.disabled}
            indeterminate={props.indeterminate}
        />
    );
};
