import { Fragment } from 'react';

import { Text } from '../../../cdl/Text/Text';
import { formatNumber } from '../../../common/helpers/formatNumber.helper';

const LitersTag = () => (
    <Text variant="body" fontWeight="semiBold" color="foreground.subtle">
        L
    </Text>
);

interface QuantityRatioProps {
    quantity: number;
    maxVolume?: number;
    warningLimit?: number;
    safetyReserve?: number;
}

export const QuantityRatio = ({ quantity, maxVolume, warningLimit, safetyReserve }: QuantityRatioProps) => {
    const formattedQuantity = formatNumber({ number: quantity, maximumFractionDigits: 0 });

    if (!maxVolume) {
        return (
            <Text variant="body" fontWeight="semiBold">
                {formattedQuantity} <LitersTag />
            </Text>
        );
    }

    const maxVolumeLevel = maxVolume ? formatNumber({ number: maxVolume, maximumFractionDigits: 0 }) : '-';

    let quantityColor = 'foreground.default';

    if ((safetyReserve && quantity >= safetyReserve) || (warningLimit && quantity >= warningLimit)) {
        quantityColor = 'accent.foreground';
    }

    if (warningLimit && quantity < warningLimit) {
        quantityColor = 'attention.foreground';
    }

    if (safetyReserve && quantity < safetyReserve) {
        quantityColor = 'negative.foreground';
    }

    return (
        <Fragment>
            <Text variant="body" fontWeight="semiBold" color={quantityColor}>
                {formattedQuantity}
            </Text>
            <Text variant="body" fontWeight="semiBold" color="foreground.subtle">
                /{maxVolumeLevel}&nbsp;
                <LitersTag />
            </Text>
        </Fragment>
    );
};
