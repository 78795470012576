import { DPDay } from '@rehookify/datepicker';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';
import { getWeekNumberForDate } from '../util/getWeekNumberForDate';

interface WeekNumbersProps {
    days: DPDay[];
}

const WeekNumber = styled.div`
    width: 40px;
    height: 40px;
    padding: ${get('space.4')}px;
    text-align: center;
    color: ${get('colors.foreground.subtle')};
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
`;

export const WeekNumbers = ({ days }: WeekNumbersProps) => {
    const weekNumbersRequired = Math.ceil(days.length / 7);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <WeekNumber />
            {new Array(weekNumbersRequired).fill(1).map((_, index) => {
                const firstDayOfWeek = days[index * 7];
                const weekNumber = getWeekNumberForDate(firstDayOfWeek.$date);

                return <WeekNumber key={firstDayOfWeek.$date.toISOString()}>{weekNumber}</WeekNumber>;
            })}
        </div>
    );
};
