import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { getAssignmentList } from '../../common/api/clients/gateway.api';
import { queryKeys } from '../../common/api/queryKeys';
import { FormSelect } from '../../common/form-elements/Select/Select';
import { formatCompanyName } from '../../common/helpers/formatCompanyName.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';

export const FormFuelSupplierSelect = (props) => {
    const role = useRole();
    const customerIds = role.getCompaniesWithType('FUEL').map((it) => it.id);

    const { data: supplierData } = useQuery({
        queryKey: queryKeys.assignments(customerIds),
        queryFn: () => getAssignmentList({ customerIds }),
    });

    const supplierOptions = supplierData
        ? supplierData
              .filter((supplierData) => supplierData.supplier)
              .map((assignment) => ({
                  label: formatCompanyName({
                      company: assignment.supplier,
                      includeType: role.isAdmin(),
                  }),
                  value: assignment.supplier.id,
                  assignment,
              }))
        : [];

    return (
        <FormSelect
            placeholder={translate('contracts.placeholder.supplier')}
            label={translate('contracts.supplier')}
            options={supplierOptions}
            dataPath={props.dataPath}
            onChange={props.onChange}
            required={props.required}
        />
    );
};

FormFuelSupplierSelect.propTypes = {
    dataPath: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    required: PropTypes.bool,
};
