export enum OfferState {
    DRAFT = 'DRAFT',
    ENQUIRY = 'ENQUIRY',
    QUOTED = 'QUOTED',
    ORDER = 'ORDER',
    CUSTOMER_ADJUSTED = 'CUSTOMER_ADJUSTED',
    SUPPLIER_ADJUSTED = 'SUPPLIER_ADJUSTED',
    CONFIRMED = 'CONFIRMED',
    ACKNOWLEDGED = 'ACKNOWLEDGED',
    CANCELED = 'CANCELED',
    DELIVERED = 'DELIVERED',
    INVOICED = 'INVOICED',
    QUOTE_DECLINED = 'QUOTE_DECLINED',
    QUOTE_CANCELED = 'QUOTE_CANCELED',
    ENQUIRY_DECLINED = 'ENQUIRY_DECLINED',
    ENQUIRY_CANCELED = 'ENQUIRY_CANCELED',
    ENQUIRY_EXPIRED = 'ENQUIRY_EXPIRED',
    DELIVERY_CONFIRMED = 'DELIVERY_CONFIRMED', // DEPRECATED
}
