import { useField } from 'formik';

import { PortMessage } from '../../../types/PortMessage';

import {
    PortDetailSelectWithSchedule,
    PortOptionSelectWithSchedule,
    PortSelectWithScheduleProps,
} from './PortDetailSelectWithSchedule';

interface FormikPortSelectWithScheduleProps extends Omit<PortSelectWithScheduleProps, 'onChange' | 'value'> {
    name: string;
    onSelect?: (port: PortMessage, scheduleRank?: number) => void;
}

export const FormikPortSelectWithSchedules = (props: FormikPortSelectWithScheduleProps) => {
    const [field, meta, helpers] = useField(props.name);

    const onChange = (option: PortOptionSelectWithSchedule, scheduleRank?: number) => {
        helpers.setValue(option.value);
        props.onSelect?.(option.port, scheduleRank);
    };

    return (
        <PortDetailSelectWithSchedule
            {...props}
            value={field.value}
            onChange={onChange}
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : ''}
            onBlur={() => helpers.setTouched(true)}
        />
    );
};
