import css from '@styled-system/css';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text } from '../../../cdl/Text/Text.tsx';
import { Box } from '../../ui/Box.tsx';
import { useFormData } from '../Form/useFormData';
import { Label } from '../TextInput/Input';
import { StyledInput as Input } from '../TextInput/Input';

const RadioWrapper = styled(Label)(
    css({
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '39px',
        cursor: 'pointer',
        paddingLeft: '4px',
        marginLeft: '-4px',
        '.radio-input': {
            flexGrow: 0,
            width: 'auto',
        },
        ':hover': {
            backgroundColor: 'dark-blue.8',
            borderRadius: 1,
        },
    })
);

export const RadioButton = ({ label, ...props }) => (
    <RadioWrapper>
        <Input className="radio-input" marginTop="0 !important" border={1} borderRadius={1} type="radio" {...props} />
        {typeof label === 'string' ? (
            <Text marginLeft={3}>{label}</Text>
        ) : (
            <Box flexGrow={1} paddingLeft={3}>
                {label}
            </Box>
        )}
    </RadioWrapper>
);

RadioButton.propTypes = {
    label: PropTypes.node,
    checked: PropTypes.bool,
};

export const FormRadioButton = ({ dataPath, value, ...props }) => {
    const { value: formValue, onChange } = useFormData(dataPath);

    const handleChange = (event) => {
        onChange(event, () => value);
    };

    return <RadioButton onChange={handleChange} checked={value === formValue} name={dataPath} {...props} />;
};

FormRadioButton.propTypes = {
    label: PropTypes.node,
    onChange: PropTypes.func,
    value: PropTypes.string,
    dataPath: PropTypes.string,
};
