import { translate } from '../../helpers/translate.helper';
import { useRole } from '../../hooks/useRole';
import { SidebarNavigationItem } from '../components/SidebarNavigationItem';

export const LubesSupplierSidebarNavigation = () => {
    const role = useRole();

    return (
        <div>
            <SidebarNavigationItem to="base.dashboard">{translate('navigation.dashboard')}</SidebarNavigationItem>

            <SidebarNavigationItem to="base.offerlist">{translate('navigation.orders')}</SidebarNavigationItem>

            {role.hasAnyAdminRights() ? (
                <SidebarNavigationItem to="base.supplier-assignments">
                    {translate('navigation.customers')}
                </SidebarNavigationItem>
            ) : null}

            <SidebarNavigationItem to="base.reporting">{translate('navigation.reporting')}</SidebarNavigationItem>
        </div>
    );
};
