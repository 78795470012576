import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconLetterQ = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M6.757 4.757A6 6 0 0 1 11 3h2a6 6 0 0 1 6 6v6a6 6 0 0 1-1.11 3.476l.817.817a1 1 0 0 1-1.414 1.414l-.817-.817A6 6 0 0 1 13 21h-2a6 6 0 0 1-6-6V9a6 6 0 0 1 1.757-4.243Zm9.689 12.275-2.739-2.74a1 1 0 0 0-1.414 1.415l2.739 2.739c-.61.36-1.311.554-2.032.554h-2a4 4 0 0 1-4-4V9a4 4 0 0 1 4-4h2a4 4 0 0 1 4 4v6c0 .72-.194 1.421-.554 2.032Z"
            clipRule="evenodd"
        />
    </svg>
);
