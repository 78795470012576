import { SingleValueProps } from 'react-select';

import { CustomSingleValue } from '../../../cdl/Select/components/CustomSingleValue';
import { Box } from '../../../common/ui/Box';
import { KeyPortHighlight } from '../../../keyport/KeyPortHighlight';
import { PortOptionSelectWithSchedule } from '../PortDetailSelectWithSchedule';

interface PortSingleValueProps extends SingleValueProps {
    data: PortOptionSelectWithSchedule;
}

export const PortSingleValue = (props: PortSingleValueProps) => {
    return (
        <CustomSingleValue {...props}>
            <Box display="flex" gap={4} alignItems="center">
                {props.children}
                <KeyPortHighlight portId={props.data.port.id} vesselId={props.data.vesselId} />
            </Box>
        </CustomSingleValue>
    );
};
