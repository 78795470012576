import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getAssignments, GetAssignmentsParams } from '../clients/gateway.api';
import { getPriceLists, GetPriceListsParams } from '../clients/product.api';

export const assignments = createQueryKeys('assignments', {
    pagination: (params: GetAssignmentsParams) => ({
        queryKey: [params],
        queryFn: () => getAssignments(params),
    }),
    priceLists: (params: GetPriceListsParams) => ({
        queryKey: [params],
        queryFn: () => getPriceLists(params),
    }),
});
