import styled from 'styled-components';

import { IconButton } from '../../../../cdl/IconButton/IconButton';
import { Text } from '../../../../cdl/Text/Text';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconChevronDown } from '../../../../common/icons/cdl/ChevronDown';
import { IconChevronRight } from '../../../../common/icons/cdl/ChevronRight';
import { get } from '../../../../common/ui/get';

interface HeaderProps {
    isOpen: boolean;
}

const Header = styled.div<HeaderProps>`
    background-color: ${(props) => (props.isOpen ? get('colors.background.subtle') : 'transparent')};
    border-style: solid;
    border-width: 1px;
    border-color: ${get('colors.border.muted')};
    padding: ${get('space.4')}px;
    height: 48px;
    display: grid;
    justify-items: start;
    grid-template-columns: 32px 1fr;
    grid-column-gap: ${get('space.4')}px;
    align-items: center;
    user-select: none;
    cursor: pointer;

    &:hover {
        background-color: ${get('colors.background.subtle')};
    }
`;

interface OrderedProductsProps {
    isOpen: boolean;
    onClick: () => void;
}

export const EnquiredProductsAccordionHeader = ({ isOpen, onClick }: OrderedProductsProps) => {
    return (
        <Header isOpen={isOpen} onClick={onClick}>
            <IconButton icon={isOpen ? IconChevronDown : IconChevronRight} />

            <Text variant="small" color="accent.foreground">
                {isOpen ? translate('order.hideEnquiredProducts') : translate('order.showEnquiredProducts')}
            </Text>
        </Header>
    );
};
