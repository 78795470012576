import { FormikInput } from '../../cdl/Input/FormikInput';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';

import { EditUserFormContentWrapper } from './EditUserFormContent';

export const EditUserFormPersonalInformationSection = () => {
    return (
        <EditUserFormContentWrapper>
            <FormikInput name="emailAddress" autoComplete="off" label={translate('user.mail')} type="email" />

            <Box display="flex" flexDirection="row" style={{ gap: '16px' }}>
                <FormikInput name="firstName" autoComplete="off" label={translate('user.firstname')} />
                <FormikInput name="lastName" autoComplete="off" label={translate('user.lastname')} />
            </Box>

            <FormikInput name="phoneNumber" autoComplete="off" label={translate('user.phoneInput')} />
        </EditUserFormContentWrapper>
    );
};
