import { CustomerCompanySelect } from '../../common/form-elements/CompanySelect/CustomerCompanySelect';
import { PortSelect } from '../../common/form-elements/PortSelect/PortSelect';
import { translate } from '../../common/helpers/translate.helper';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { useLocationState } from '../../common/LocationState/useLocationState';
import { DebouncedSearchTextInput } from '../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../common/ui/Box';

export const AgentsOverviewTableControls = () => {
    const [locationState, { setLocationFieldValue }] = useLocationState();
    const role = useRole();
    const { context } = useProductContext();

    return (
        <Box display="flex" gap={4}>
            <Box minWidth="250px">
                <DebouncedSearchTextInput
                    onChange={(searchQuery) => setLocationFieldValue('searchQuery', searchQuery)}
                    value={locationState.searchQuery}
                    placeholder={translate('agents.searchInputPlaceholder')}
                />
            </Box>

            {!role.isOneCompanyUser(context) ? (
                <Box minWidth="250px" maxWidth="80%">
                    <CustomerCompanySelect
                        onChange={(customerIds) => {
                            setLocationFieldValue('customerIds', customerIds);
                            setLocationFieldValue('page', 0);
                        }}
                        placeholder={translate('agents.filterCompaniesPlaceholder')}
                        value={locationState.customerIds}
                        isMulti
                    />
                </Box>
            ) : null}

            <Box minWidth="250px" maxWidth="80%">
                <PortSelect
                    placeholder={translate('agents.portsPlaceholder')}
                    value={locationState.portIds}
                    isMulti
                    onChange={(portIds: string[]) => {
                        setLocationFieldValue('portIds', portIds);
                    }}
                />
            </Box>
        </Box>
    );
};
