import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createProduct } from '../../../common/api/clients/product.api';
import { queries } from '../../../common/api/queryKeys/queries';

export const useProductCreate = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createProduct,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queries.products.pagination._def });
        },
    });
};
