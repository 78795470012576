import FileDownload from 'js-file-download';
import { useEffect, useState } from 'react';

import { FileManagementResponse } from '../../../../types/FileManagementResponse';
import { IconButton } from '../../../cdl/IconButton/IconButton';
import { ProgressBar } from '../../../cdl/ProgressBar/ProgressBar';
import { Text } from '../../../cdl/Text/Text';
import { TextButton } from '../../buttons/TextButton';
import { extractFileName } from '../../helpers/extractFileName.helper';
import { formatNumber } from '../../helpers/formatNumber.helper';
import { translate } from '../../helpers/translate.helper';
import { IconAlertTriangle } from '../../icons/cdl/AlertTriangle';
import { IconCircleCheck } from '../../icons/cdl/CircleCheck';
import { IconRotateClockwise } from '../../icons/cdl/RotateClockwise';
import { IconX } from '../../icons/cdl/X';
import { ButtonSpinner } from '../../Spinner/ButtonSpinner';
import { Box } from '../../ui/Box';
import { theme } from '../../ui/theme';
import { useAttachmentsConfig } from '../hooks/useAttachmentsConfig';
import { useFileDelete } from '../hooks/useFileDelete';
import { useFileDownload } from '../hooks/useFileDownload';
import { useFileUpload } from '../hooks/useFileUpload';
import { FileData } from '../hooks/useUploadAreaReducer';

import { AttachmentGrid } from './AttachmentGrid';

interface UploadRowProps {
    fileData: FileData;
    onUploadSuccess: (file: FileManagementResponse) => void;
    onDeleteSuccess: () => void;
}

export const UploadRow = ({ fileData, onDeleteSuccess, onUploadSuccess }: UploadRowProps) => {
    const attachmentsConfig = useAttachmentsConfig();
    const fileUploadMutation = useFileUpload();
    const fileDeleteMutation = useFileDelete(onDeleteSuccess);
    const fileDownloadMutation = useFileDownload();

    const [isLoading, setIsLoading] = useState(false);

    const downloadUploadedFile = (fileId: string) => {
        fileDownloadMutation.mutate(fileId, {
            onSuccess: (response) => {
                FileDownload(
                    response.data,
                    extractFileName(
                        response.headers['content-disposition'] || '',
                        translate('attachments.fileNameFallback')
                    )
                );
            },
        });
    };

    const deleteFile = async (fileId: string) => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        return fileDeleteMutation.mutateAsync(fileId);
    };

    const uploadResponse = fileData.uploadResponse;

    useEffect(() => {
        if (uploadResponse) {
            return;
        }

        fileUploadMutation.mutate(fileData.file, {
            onSuccess: onUploadSuccess,
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (uploadResponse) {
        return (
            <AttachmentGrid>
                <Box>
                    {isLoading ? (
                        <ButtonSpinner />
                    ) : (
                        <IconCircleCheck
                            color={
                                attachmentsConfig.enableDownloadUploadedFiles
                                    ? theme.colors.accent.emphasis
                                    : theme.colors.foreground.default
                            }
                        />
                    )}
                </Box>

                <Box alignSelf="center">
                    {attachmentsConfig.enableDownloadUploadedFiles ? (
                        <TextButton
                            type="button"
                            onClick={() => downloadUploadedFile(uploadResponse.id)}
                            style={{
                                wordBreak: 'break-all',
                            }}
                        >
                            {uploadResponse.fileName}
                        </TextButton>
                    ) : (
                        <Text
                            style={{
                                wordBreak: 'break-all',
                            }}
                            color={theme.colors.foreground.default}
                        >
                            {uploadResponse.fileName}
                        </Text>
                    )}
                </Box>

                <Box position="relative" width="32px">
                    <IconButton
                        type="button"
                        icon={IconX}
                        onClick={() => {
                            deleteFile(uploadResponse.id);
                        }}
                        color="light-dark"
                        style={{ position: 'absolute', top: '-6px' }}
                    />
                </Box>
            </AttachmentGrid>
        );
    }

    if (fileUploadMutation.isError) {
        return (
            <AttachmentGrid>
                <Box>
                    <IconAlertTriangle color={theme.colors.negative.emphasis} />
                </Box>

                <Box alignSelf="center">
                    <Text
                        size={14}
                        weight="medium"
                        style={{
                            wordBreak: 'break-all',
                        }}
                    >
                        {fileData.file.name}
                    </Text>
                </Box>

                <Box gridRow="1/2" gridColumn="3/4" width="68px" position="relative">
                    <Box
                        display="flex"
                        gap={2}
                        style={{
                            position: 'absolute',
                            top: '-6px',
                        }}
                    >
                        <IconButton
                            type="button"
                            icon={IconRotateClockwise}
                            onClick={() => {
                                fileUploadMutation.mutate(fileData.file, {
                                    onSuccess: onUploadSuccess,
                                });
                            }}
                            color="light-dark"
                        />
                        <IconButton type="button" icon={IconX} onClick={onDeleteSuccess} />
                    </Box>
                </Box>

                <Box gridColumn="2/3" gridRow="2/3">
                    <Text size={12} weight="normal" color={theme.colors.negative.foreground}>
                        {translate('attachments.failedUpload')}
                    </Text>
                </Box>
            </AttachmentGrid>
        );
    }

    return (
        <AttachmentGrid>
            <Box gridRow="1/2" gridColumn="2/3" alignSelf="center">
                <Text
                    size={14}
                    weight="medium"
                    style={{
                        wordBreak: 'break-all',
                    }}
                >
                    {fileData.file.name}
                </Text>
            </Box>

            <Box gridColumn="2/3" gridRow="2/3">
                <Text size={12} weight="normal" color={theme.colors.foreground.muted}>
                    {formatNumber({
                        number: fileUploadMutation.progress,
                        maximumFractionDigits: 0,
                    })}
                    %
                </Text>
            </Box>

            <Box gridRow="3/4" gridColumn="2/4" marginTop={5}>
                <ProgressBar value={fileUploadMutation.progress} />
            </Box>
        </AttachmentGrid>
    );
};
