import { ReactNode } from 'react';

import { Text } from '../../../cdl/Text/Text';
import { Box } from '../../../common/ui/Box';
import { Heading } from '../../../common/ui/Heading';

interface StatsItemProps {
    item: ReactNode;
    description: string;
}

export const StatsItem = ({ item, description }: StatsItemProps) => {
    return (
        <Box>
            <Text fontSize={4} fontWeight="700" color="dark-blue.0" as="h4">
                {item}
            </Text>
            <Heading color="dark-blue.3" marginTop={3} as="h5">
                {description}
            </Heading>
        </Box>
    );
};
