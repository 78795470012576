import { Form, useFormikContext } from 'formik';
import { useEffect } from 'react';

import { UserRole } from '../../../../types/UserRole';
import { UserType } from '../../../../types/UserType';
import { FormikInput } from '../../../cdl/Input/FormikInput';
import { Text } from '../../../cdl/Text/Text';
import { Button } from '../../../common/buttons/Button';
import { FormikDebug } from '../../../common/form-elements/formik/FormikDebug';
import { FormikEnumOptionSelect } from '../../../common/form-elements/formik/FormikEnumOptionSelect';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { ButtonSpinner } from '../../../common/Spinner/ButtonSpinner';
import { Box } from '../../../common/ui/Box';
import { theme } from '../../../common/ui/theme';
import { UserServerError } from '../../detail/hooks/useUserInvite';

import { InviteUserCustomerForm } from './InviteUserCustomerForm';
import { FormikInviteUserState } from './InviteUserForm';
import { InviteUserSupplierForm } from './InviteUserSupplierForm';

interface InviteUserBaseFormProps {
    error?: UserServerError;
}

export const InviteUserBaseForm = ({ error }: InviteUserBaseFormProps) => {
    const { values, setValues, isSubmitting, isValid, validateForm, setSubmitting } =
        useFormikContext<FormikInviteUserState>();

    const role = useRole();

    useEffect(() => {
        validateForm();
        setSubmitting(false);
    }, [error, setSubmitting, validateForm]);

    const resetCompanyIdsOnGroupChange = () => {
        setValues({
            ...values,
            companyIds: [],
        });
    };

    const resetOnUserTypeChange = () => {
        setValues({
            ...values,
            role: undefined,
            groupId: '',
            companyIds: [],
        });
    };

    const resetCompanyIdsOnRoleChange = (role?: string) => {
        if (role === UserRole.GROUP_ADMIN) {
            setValues({
                ...values,
                companyIds: [],
            });
        }
    };

    return (
        <Form style={{ height: '100%' }}>
            <Box display="grid" gridTemplateRows="auto 1fr auto" height="100%" gridTemplateColumns="100%">
                <Box padding={7} paddingBottom={6}>
                    <Text variant="title" marginBottom={6}>
                        {translate('user.inviteTitle')}
                    </Text>
                </Box>

                <Box paddingX={7} display="flex" flexDirection="column" style={{ gap: '16px' }}>
                    <FormikInput name="email" autoComplete="off" label={translate('user.mail')} type="email" />

                    {role.isAdmin() ? (
                        <>
                            <Box display="flex" flexDirection="row" style={{ gap: '16px' }}>
                                <FormikInput name="firstname" autoComplete="off" label={translate('user.firstname')} />
                                <FormikInput name="lastname" autoComplete="off" label={translate('user.lastname')} />
                            </Box>
                            <FormikInput name="phone" autoComplete="off" label={translate('user.phoneInput')} />
                            <FormikEnumOptionSelect
                                enumType="userType"
                                name="userType"
                                label={translate('user.userType')}
                                filter={(option) => option.value !== 'ADMIN'}
                                onOptionChange={resetOnUserTypeChange}
                            />
                        </>
                    ) : null}

                    {values.userType === UserType.CUSTOMER ? (
                        <InviteUserCustomerForm
                            values={values}
                            onGroupChange={resetCompanyIdsOnGroupChange}
                            onRoleChange={resetCompanyIdsOnRoleChange}
                        />
                    ) : null}
                    {values.userType === UserType.SUPPLIER ? (
                        <InviteUserSupplierForm
                            values={values}
                            onGroupChange={resetCompanyIdsOnGroupChange}
                            onRoleChange={resetCompanyIdsOnRoleChange}
                        />
                    ) : null}

                    <FormikDebug />
                </Box>

                <Box padding={7}>
                    <Button fullWidth color="solid-clear" type="submit" disabled={!isValid}>
                        {isSubmitting ? (
                            <ButtonSpinner color={theme.colors.foreground.onEmphasis} />
                        ) : (
                            translate('user.sendInvite')
                        )}
                    </Button>
                </Box>
            </Box>
        </Form>
    );
};
