import { ContractMessage } from '../../../../types/ContractMessage';
import { DeleteIconButton } from '../../../common/buttons/DeleteIconButton';
import { translate } from '../../../common/helpers/translate.helper';

interface DeleteContractButtonProps {
    contract: ContractMessage;
    handleDelete: (contract: ContractMessage) => void;
}
export const DeleteContractButton = ({ contract, handleDelete }: DeleteContractButtonProps) => {
    const popupHeadlineKey = contract.tender.enabled
        ? 'contracts.deleteButtonTender.headline'
        : 'contracts.deleteButton.headline';
    const popupTextKey = contract.tender.enabled ? 'contracts.deleteButtonTender.text' : 'contracts.deleteButton.text';
    const tooltipKey = contract.tender.enabled
        ? 'contracts.deleteButtonTender.tooltip'
        : 'contracts.deleteButton.tooltip';

    return (
        <DeleteIconButton
            onDeleteConfirm={() => handleDelete(contract)}
            popupHeadline={translate(popupHeadlineKey, {
                contract: contract.reference,
            })}
            popupText={translate(popupTextKey)}
            tooltip={translate(tooltipKey)}
            itemToDelete={contract.reference}
        />
    );
};
