import styled, { css } from 'styled-components';

import { get } from '../../../common/ui/get';

import { CoreInputProps } from './CoreInput';
import { LeadingVisualWrapper } from './LeadingVisualWrapper';
import { TrailingVisualWrapper } from './TrailingVisualWrapper';

export interface InputContainerProps extends Pick<CoreInputProps, 'disabled'> {
    $isFocused: boolean;
    $positive?: boolean;
    $negative?: boolean;
}

const positiveStyles = css`
    background-color: ${get('colors.positive.background')};
    border-color: ${get('colors.positive.border')};
`;

const negativeStyles = css`
    background-color: ${get('colors.negative.background')};
    border-color: ${get('colors.negative.border')};
`;

const focusedStyles = css`
    border-color: ${get('colors.accent.emphasis')};
    background-color: ${get('colors.accent.background')};
`;

export const InputContainer = styled.div<InputContainerProps>`
    display: flex;
    align-items: center;
    position: relative;
    color: ${get('colors.foreground.default')};
    background-color: ${(props) => (props.disabled ? get('colors.background.subtle') : get('colors.background.inset'))};
    outline: none;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    margin: 0;
    width: 100%;

    border: 2px solid transparent;
    border-radius: 4px;

    transition:
        border 75ms,
        background 75ms;

    input:placeholder-shown ~ ${TrailingVisualWrapper} {
        color: ${get('colors.foreground.subtle')};
    }

    ${LeadingVisualWrapper}:has(~ input:placeholder-shown) {
        color: ${get('colors.foreground.subtle')};
    }

    ${(props) => (props.$positive ? positiveStyles : null)}
    ${(props) => (props.$negative ? negativeStyles : null)}
    ${(props) => (props.$isFocused ? focusedStyles : null)}
`;
