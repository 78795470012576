import { useState } from 'react';

import { CounterOffer } from '../../../../../../types/CounterOffer';
import { Button } from '../../../../../cdl/Button/Button';
import { translate } from '../../../../../common/helpers/translate.helper';
import { OfferModel } from '../../../../../offer/model/OfferModel';

import { CounterOfferReviewPopup } from './CounterOfferReviewPopup';

interface CounterOfferReviewButtonProps {
    offer: OfferModel;
    counterOffer: CounterOffer;
}

export const CounterOfferReviewButton = ({ offer, counterOffer }: CounterOfferReviewButtonProps) => {
    const [showPopup, setShowPopup] = useState(false);

    return (
        <>
            <Button
                onClick={() => {
                    setShowPopup(true);
                }}
            >
                {translate('counterOffer.reviewButton')}
            </Button>

            <CounterOfferReviewPopup
                offer={offer}
                counterOffer={counterOffer}
                open={showPopup}
                onDismiss={() => setShowPopup(false)}
            />
        </>
    );
};
