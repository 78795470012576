import { Error } from '../../../common/Error/Error';
import { formatMoney } from '../../../common/helpers/formatMoney.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { useStats } from '../hooks/useStats';

import { PplStatsItem } from './PplStatsItem';
import { QuickStatsWrapper } from './QuickStatsWapper';
import { StatsItem } from './StatsItem';
import { VolumeStatsItem } from './VolumeStatsItem';

export const QuickStats = () => {
    const role = useRole();
    const statsQuery = useStats();

    if (statsQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (statsQuery.isError) {
        return <Error />;
    }

    const stats = statsQuery.data!!;

    return (
        <QuickStatsWrapper>
            <StatsItem item={stats.count} description={translate('dashboard.quickstats.deliveries')} />
            <VolumeStatsItem stats={stats} />

            {!role.isSupplier() ? <PplStatsItem stats={stats} /> : null}

            <StatsItem
                item={formatMoney({
                    value: stats.total.value,
                    currency: stats.total.currency,
                    maximumFractionDigits: 0,
                })}
                description={translate('dashboard.quickstats.total')}
            />
        </QuickStatsWrapper>
    );
};
