import styled from 'styled-components';

import { get } from '../ui/get';

export const ExternalLink = styled.a`
    color: ${get('colors.accent.foreground')};

    &:hover,
    &:visited {
        color: ${get('colors.accent.foreground')};
        text-decoration: none;
    }
`;
