import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { useContractProducts } from './useContractProducts';

export const useContractProductsPrefetching = () => {
    const { values } = useFormikContext();
    const { prefetch } = useContractProducts();

    useEffect(() => {
        if (values.portId && values.eta && values.etd) {
            prefetch({
                portId: values.portId,
                eta: values.eta,
                etd: values.etd,
            });
        }
    }, [values.portId, values.eta, values.etd, prefetch]);
};
