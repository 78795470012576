import { useField } from 'formik';
import * as React from 'react';

import { PortSelect, PortSelectProps } from '../PortSelect/PortSelect';

interface FormikPortSelectProps<IsMulti extends boolean> extends Omit<PortSelectProps<IsMulti>, 'onChange' | 'value'> {
    name: string;
}

export function FormikPortSelect<IsMulti extends boolean>(props: FormikPortSelectProps<IsMulti>) {
    const [field, meta, helpers] = useField(props.name);

    const onChange = (value: IsMulti extends true ? string[] : string) => {
        helpers.setValue(value);
    };

    return (
        <PortSelect
            {...props}
            onChange={onChange}
            value={field.value}
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : props.caption}
            onBlur={() => {
                helpers.setTouched(true);
            }}
        />
    );
}
