import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateOrder } from '../../../../common/api/clients/order.api';
import { queries } from '../../../../common/api/queryKeys/queries';
import { Order } from '../../../model/order.model';

export const useEnquiryUpdate = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateOrder,
        onSuccess: (order) => {
            queryClient.setQueryData(queries.orders.detail(order.id).queryKey, new Order(order));
        },
    });
};
