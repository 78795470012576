import { formatDateTime } from '../../common/helpers/formatDateTime.helper';

const formatDateWithTimeZone = (date, timeZoneId) => {
    return formatDateTime({
        date,
        timeZoneId,
        format: 'YYYY-MM-DDTHH:mm',
        //The date time picker requires this format
    });
};

export function getFormStateFromOrder(order) {
    return {
        state: order.state ?? null,
        orderId: order.id ?? null,
        customerId: order.customerId ?? null,
        vesselId: order.vesselId ?? null,
        portId: order.portId ?? null,
        contractId: order.contractId ?? null,
        contract: order.contract ?? null,
        eta: order.eta ? formatDateWithTimeZone(order.eta, order.port?.timeZoneId) : '',
        etd: order.etd ? formatDateWithTimeZone(order.etd, order.port?.timeZoneId) : '',
        agentId: order.agentId ?? null,
        timeZoneId: order.port?.timeZoneId ?? null,
        buyerReference: order.buyerReference ?? '',
        comment: order.comment ?? '',
        validityTime: order.validityTime ?? '',
        testOrder: order.testOrder ?? false,
        assignedSupplierIds: order.assignedSupplierIds ?? null,
        receiverSupplierIds: order.receiverSupplierIds ?? null,
        spot: order.spot ?? false,
        items: order.items.map((item) => {
            return {
                productId: item.productId ?? null,
                units: item.units ?? '',
                unit: item.unit ?? null,
                packType: item.packType ?? null,
                unitSize: item.unitSize ?? null,
                sulphurContent: item.product?.sulphurContent ?? null,
                group: item.product?.group ?? null,
                productName: item.product?.name ?? null,
                isoSpec: item.isoSpec ?? null,
            };
        }),
        files: order.files ?? [],
        scheduleId: null,
    };
}
