import { Text } from '../../../cdl/Text/Text';
import { LegacyCustomerCompanyMultiSelect } from '../../../common/form-elements/CompanySelect/LegacyCustomerCompanyMultiSelect';
import { LegacySupplierCompanyMultiSelect } from '../../../common/form-elements/CompanySelect/LegacySupplierCompanyMultiSelect';
import { DeprecatedDebouncedSearchTextInput } from '../../../common/form-elements/SearchTextInput/DeprecatedDebouncedSearchTextInput';
import { translate as t } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { Page } from '../../../common/Page/Page';
import { PageGrid } from '../../../common/PageGrid';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex';

import { FuelOfferOverviewSortSelect } from './FuelOfferOverviewSortSelect';
import { FuelOfferOverviewState } from './FuelOfferOverviewState';
import { FuelOfferOverviewTable } from './FuelOfferOverviewTable';

export const FuelOfferOverviewPage = () => {
    return (
        <FuelOfferOverviewState>
            <FuelOfferOverviewPageContent />
        </FuelOfferOverviewState>
    );
};

const FuelOfferOverviewPageContent = () => {
    const role = useRole();
    const { context } = useProductContext();

    const title = t(role.isAdmin() ? 'offer.overviewPageTitle' : 'order.overviewPageTitle');
    useDocumentTitle(title);

    const [locationState, { setLocationFieldValue }] = useLocationState();

    return (
        <Page>
            <PageGrid>
                <Text variant="headline" as="h1">
                    {title}
                </Text>

                <Flex justifyContent="space-between">
                    <DeprecatedDebouncedSearchTextInput
                        placeholder={
                            role.isAdmin() ? t('offer.searchInputPlaceholder') : t('order.searchInputPlaceholder')
                        }
                        value={locationState.searchQuery}
                        onChange={(searchQuery) => setLocationFieldValue('searchQuery', searchQuery)}
                    />

                    <Flex marginLeft={5}>
                        {role.isAdmin() ? (
                            <Box minWidth="300px" maxWidth="80%" marginRight={5}>
                                <LegacyCustomerCompanyMultiSelect
                                    companyIds={locationState.customerIds}
                                    onChange={(customerIds) => {
                                        setLocationFieldValue('customerIds', customerIds);
                                    }}
                                    placeholder={t('order.filterCustomerPlaceholder')}
                                />
                            </Box>
                        ) : null}

                        {!role.isOneCompanyUser(context) || role.isAdmin() ? (
                            <Box minWidth="300px" maxWidth="80%" marginRight={5}>
                                <LegacySupplierCompanyMultiSelect
                                    companyIds={locationState.supplierIds}
                                    onChange={(supplierIds) => {
                                        setLocationFieldValue('supplierIds', supplierIds);
                                    }}
                                    placeholder={
                                        role.isAdmin()
                                            ? t('order.filterSupplierPlaceholder')
                                            : t('order.filterSupplierPlaceholder')
                                    }
                                />
                            </Box>
                        ) : null}

                        <FuelOfferOverviewSortSelect
                            onChange={(sortOption) => {
                                setLocationFieldValue('sortValue', sortOption);
                            }}
                            value={locationState.sortValue}
                        />
                    </Flex>
                </Flex>

                <FuelOfferOverviewTable />
            </PageGrid>
        </Page>
    );
};
