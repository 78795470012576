import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const getFeeForOfferId = async (offerId: string): Promise<any> => {
    const response = await authorizedAxiosInstance.get(`/v1/fee/offer/${offerId}`);

    return response.data;
};

export const getFeeForOfferTotal = async (offerTotal: number, currencyCode: string): Promise<any> => {
    const parameters = new URLSearchParams({
        total: offerTotal.toString(),
        currencyCode: currencyCode,
    });

    const response = await authorizedAxiosInstance.get(`/v1/fee/offer?${parameters.toString()}`);

    return response.data;
};
