import { components } from 'react-select';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const CustomValueContainer = styled(components.ValueContainer)`
    && {
        padding: 0 0 0 ${get('space.4')}px;
        row-gap: ${get('space.2')}px;
    }
`;
