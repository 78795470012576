import { EnergyContentUnit } from '../../../types/EnergyContentMessage';

export const makeOfferUpdateRequest = (offer) => {
    return {
        ...offer,
        vendorReference: offer.vendorReference ? offer.vendorReference : null,
        quoteValidityTime: offer.quoteValidityTime ? offer.quoteValidityTime : null,
        items: offer.items.map((item) => {
            return {
                ...item,
                energyContent: item.energyContent?.value
                    ? { value: Number(item.energyContent.value), unit: EnergyContentUnit.MJKG }
                    : null,
            };
        }),
    };
};
