import useComponentSize from '@rehooks/component-size';
import { ReactNode, useRef } from 'react';

import { IconInfoCircle } from '../../common/icons/cdl/InfoCircle';
import { IconX } from '../../common/icons/cdl/X';
import { Box } from '../../common/ui/Box';
import { IconButton } from '../IconButton/IconButton';

import { CalloutContent } from './CalloutContent';
import { CalloutWrapper } from './CalloutWrapper';
import { getVariantColors } from './getVariantColors';

export type CalloutVariant = 'default' | 'accent' | 'attention' | 'negative';

interface SharedCalloutProps {
    leadingVisual?: (componentProps: { width: string; height: string; color: string }) => ReactNode | null;
    variant?: CalloutVariant;
    /**
     * Can only be used when no children are provided
     */
    onDismiss?: () => void;
    children?: ReactNode;
}

type EnsureHeadingOrDescription =
    | {
          heading: ReactNode;
          description?: never;
      }
    | {
          heading?: never;
          description: ReactNode;
      }
    | {
          heading: ReactNode;
          description: ReactNode;
      };

export type CalloutProps = SharedCalloutProps & EnsureHeadingOrDescription;

export const Callout = ({
    variant = 'default',
    leadingVisual = (props) => <IconInfoCircle {...props} />,
    ...props
}: CalloutProps) => {
    const calloutRef = useRef<HTMLDivElement>(null);
    const { width } = useComponentSize(calloutRef);

    const variantColors = getVariantColors(variant);

    // Design defined breakpoint for the layout of the callout
    const isWide = width > 600;

    if (!props.heading && !props.children && props.description) {
        return (
            <CalloutWrapper
                ref={calloutRef}
                backgroundColor={variantColors.background}
                borderColor={variantColors.border}
                borderWidth="0px"
                borderRadius={1}
                isWide={isWide}
            >
                <CalloutContent
                    description={props.description}
                    leadingVisual={leadingVisual}
                    color={variantColors.color}
                />

                {props.onDismiss && !props.children ? (
                    <Box position="absolute" top={4} right={4}>
                        <IconButton icon={IconX} onClick={props.onDismiss} />
                    </Box>
                ) : null}
            </CalloutWrapper>
        );
    }

    return (
        <CalloutWrapper
            ref={calloutRef}
            backgroundColor={variantColors.background}
            borderColor={variantColors.border}
            borderWidth="1px"
            borderRadius={2}
            isWide={isWide}
        >
            <CalloutContent
                heading={props.heading}
                description={props.description}
                leadingVisual={leadingVisual}
                color={variantColors.color}
            />

            {props.children}

            {props.onDismiss && !props.children ? (
                <Box position="absolute" top={4} right={4}>
                    <IconButton icon={IconX} onClick={props.onDismiss} />
                </Box>
            ) : null}
        </CalloutWrapper>
    );
};
