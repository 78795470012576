import { DPCalendar, useContextCalendars, useContextDaysPropGetters } from '@rehookify/datepicker';
import styled from 'styled-components';

import { DayButton } from './DayButton';
import { DayOfWeekName } from './DayOfWeekName';
import { MonthHeader } from './MonthHeader';
import { WeekNumbers } from './WeekNumbers';

interface MonthViewProps {
    calendar: DPCalendar;
    hasPrevious?: boolean;
    hasNext?: boolean;
}

const DaysGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 40px);
    gap: 0;
`;

export const MonthView = ({ calendar, hasPrevious, hasNext }: MonthViewProps) => {
    const { weekDays } = useContextCalendars();
    const { dayButton } = useContextDaysPropGetters();
    const { days, month, year } = calendar;

    return (
        <div style={{ width: '360px', padding: '20px 24px 24px 24px' }}>
            <MonthHeader isFirst={hasPrevious} isLast={hasNext}>
                {month} {year}
            </MonthHeader>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <WeekNumbers days={days} />
                <DaysGrid>
                    {weekDays.map((dayOfWeek) => (
                        <DayOfWeekName key={dayOfWeek}>{dayOfWeek}</DayOfWeekName>
                    ))}
                    {days.map((dpDay) => (
                        <DayButton key={dpDay.$date.toString()} {...dayButton(dpDay)} day={dpDay}>
                            {dpDay.day}
                        </DayButton>
                    ))}
                </DaysGrid>
            </div>
        </div>
    );
};
