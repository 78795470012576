import { DemandInformationCategory } from '../../../types/DemandInformationMessage';
import { ProductContext } from '../../../types/ProductContext';
import { sortFuelCategory, sortLubesCategory } from '../../common/helpers/sortByCategory.helper';

export const sortCategory =
    (context: ProductContext) =>
    (a: DemandInformationCategory, b: DemandInformationCategory): number => {
        if (context === ProductContext.FUEL) {
            const aCategory = `${a.productGroup}_${a.sulphurContent}`;
            const bCategory = `${b.productGroup}_${b.sulphurContent}`;

            return sortFuelCategory(aCategory, bCategory);
        }

        if (a.tankCategory && b.tankCategory) {
            return sortLubesCategory(a.tankCategory, b.tankCategory);
        }

        return 0;
    };
