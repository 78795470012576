import { SingleValueProps } from 'react-select';

import { CustomSingleValue } from '../../../../cdl/Select/components/CustomSingleValue';
import { Tag } from '../../../../cdl/Tag/Tag';
import { Text } from '../../../../cdl/Text/Text';
import { translate } from '../../../helpers/translate.helper';
import { useEnums } from '../../../hooks/useEnums';
import { Box } from '../../../ui/Box';
import { ProductOption } from '../ProductSelect';

interface ProductSingleValueProps extends SingleValueProps {
    data: ProductOption;
}

export const ProductSingleValue = (props: ProductSingleValueProps) => {
    const { getHumanReadableValue } = useEnums();

    const supplierProductGroupString = `(${getHumanReadableValue(
        'supplierProductGroup',
        props.data.product.supplierProductGroup
    )})`;

    return (
        <CustomSingleValue {...props}>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                overflow="hidden"
            >
                <Text style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {props.data.product.name}
                    &nbsp;
                    {supplierProductGroupString}
                </Text>

                {props.data.inTank ? <Tag variant="default">{translate('productSelect.inTank')}</Tag> : null}
            </Box>
        </CustomSingleValue>
    );
};
