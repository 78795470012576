import { BunkerSchedule } from '../../../../../../../types/BunkerCaseSolutionResponse';
import { TableBuilder } from '../../../../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../../../../cdl/TableBuilder/TableBuilderColumn';
import { Tooltip } from '../../../../../../cdl/Tooltip/Tooltip';
import { translate } from '../../../../../../common/helpers/translate.helper';
import { Box } from '../../../../../../common/ui/Box';

import { MoneyLabel } from './components/MoneyLabel';
import { NumberWithWarningsLabel } from './components/NumberWithWarningsLabel';
import { RecommendedContractButton } from './components/RecommendedContractButton';
import { buildEntriesForBunkerSchedule, BunkerStopData } from './util/buildEntriesForBunkerSchedule';
import { findIncludedProductCategories } from './util/findIncludedProductCategories';

interface BunkerSolutionStopTableProps {
    bunkerSchedule: BunkerSchedule;
}

export const BunkerSolutionStopTable = (props: BunkerSolutionStopTableProps) => {
    const includedProductCategories = findIncludedProductCategories(props.bunkerSchedule);
    const entries = buildEntriesForBunkerSchedule(props.bunkerSchedule, includedProductCategories);

    return (
        <TableBuilder variant="inline" data={entries}>
            <TableBuilderColumn<BunkerStopData>
                header={translate('prePlanning.solutionDetails.stopTable.product')}
                width="25%"
            >
                {(row) => (
                    <Box display="flex" gap={2}>
                        {row.name}
                        {row.recommendedContract ? (
                            <RecommendedContractButton recommendedContract={row.recommendedContract} />
                        ) : null}
                    </Box>
                )}
            </TableBuilderColumn>
            <TableBuilderColumn<BunkerStopData>
                header={translate('prePlanning.solutionDetails.stopTable.quantityStart')}
                width="15%"
                numeric
            >
                {(row) =>
                    !row.isAdditional ? (
                        <NumberWithWarningsLabel
                            value={row.quantityStart?.value}
                            warnings={row.quantityStart?.warnings}
                        />
                    ) : null
                }
            </TableBuilderColumn>
            <TableBuilderColumn<BunkerStopData>
                header={translate('prePlanning.solutionDetails.stopTable.consumption')}
                width="15%"
                numeric
            >
                {(row) =>
                    !row.isAdditional ? (
                        <NumberWithWarningsLabel value={row.consumption?.value} warnings={row.consumption?.warnings} />
                    ) : null
                }
            </TableBuilderColumn>
            <TableBuilderColumn<BunkerStopData>
                header={translate('prePlanning.solutionDetails.stopTable.purchaseQuantity')}
                width="15%"
                numeric
            >
                {(row) => {
                    if (row.isAdditional) {
                        return null;
                    }

                    if (row.purchaseQuantity?.latestDeliveryDate === undefined) {
                        return <NumberWithWarningsLabel value={row.purchaseQuantity?.value} />;
                    }

                    const deliveredByLabel = translate('prePlanning.solutionDetails.stopTable.deliveredBy', {
                        date: row.purchaseQuantity.latestDeliveryDate,
                    });

                    return (
                        <Tooltip label={deliveredByLabel}>
                            <span>
                                <NumberWithWarningsLabel value={row.purchaseQuantity?.value} />
                            </span>
                        </Tooltip>
                    );
                }}
            </TableBuilderColumn>
            <TableBuilderColumn<BunkerStopData>
                header={translate('prePlanning.solutionDetails.stopTable.price')}
                width="15%"
                numeric
            >
                {(row) => {
                    if (row.isAdditional) {
                        return null;
                    }

                    if (row.price?.priceDate === undefined) {
                        return <MoneyLabel price={row.price?.value} />;
                    }

                    const indicationDateLabel = translate('prePlanning.solutionDetails.stopTable.indicationDate', {
                        date: row.price.priceDate,
                    });

                    return (
                        <Tooltip label={indicationDateLabel}>
                            <span>
                                <MoneyLabel price={row.price?.value} />
                            </span>
                        </Tooltip>
                    );
                }}
            </TableBuilderColumn>
            <TableBuilderColumn<BunkerStopData>
                header={translate('prePlanning.solutionDetails.stopTable.total')}
                width="15%"
                numeric
            >
                {(row) => <MoneyLabel price={row.total} />}
            </TableBuilderColumn>
        </TableBuilder>
    );
};
