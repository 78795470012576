import Skeleton from 'react-loading-skeleton';

import { Box } from '../../../common/ui/Box';
import { OfferListItemGrid } from '../common/OfferCard/OfferListItemGrid';
import { OfferSummaryContainer } from '../common/OfferCard/OfferSummaryContainer';

import { OfferCardContainer } from './OfferCard/OfferCardContainer';

export const OfferListItemSkeleton = () => {
    return (
        <OfferCardContainer style={{ marginBottom: '16px' }}>
            <OfferListItemGrid>
                <OfferSummaryContainer>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" flexDirection="column">
                            <Skeleton height={18} width="120px" />
                            <Skeleton height={12} width="240px" />
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="end" style={{ textAlign: 'right' }}>
                            <Skeleton height={18} width="120px" />
                            <Skeleton height={12} width="80px" />
                        </Box>
                    </Box>

                    <Box display="grid" gridTemplateColumns="1fr 1fr" gridColumnGap="16px" marginTop="4">
                        <Box display="flex" flexDirection="column" justifyContent="space-between">
                            <Skeleton height={12} width="200px" />
                            <Skeleton height={12} width="200px" />
                            <Skeleton height={12} width="200px" />
                        </Box>
                        <Box display="flex" justifyContent="space-between" style={{ columnGap: '16px' }}>
                            <Box display="flex" flexDirection="column" justifyContent="space-between">
                                <Skeleton height={12} width="140px" />
                                <Skeleton height={12} width="140px" />
                                <Skeleton height={12} width="140px" />
                            </Box>
                            <Box display="flex" flexDirection="column" justifyContent="space-between">
                                <Skeleton height={12} width="100px" />
                                <Skeleton height={12} width="100px" />
                                <Skeleton height={12} width="100px" />
                            </Box>
                        </Box>
                    </Box>
                </OfferSummaryContainer>

                <Box padding="5" display="flex" flexDirection="column" justifyContent="space-between">
                    <Skeleton height={24} width="100%" />
                    <Box display="grid" gridTemplateColumns="1fr 1fr" gridColumnGap="8px">
                        <Skeleton height={32} />
                        <Skeleton height={32} />
                    </Box>
                </Box>
            </OfferListItemGrid>
        </OfferCardContainer>
    );
};
