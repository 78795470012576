import { PaymentTermReference } from '../../../types/PaymentTermReference';

import { useEnums } from './useEnums';

export const usePaymentTermsFormat = () => {
    const { getHumanReadableValue } = useEnums();

    return (paymentTermReference: PaymentTermReference, paymentTermReferenceDays: number | undefined): string => {
        if (paymentTermReference === PaymentTermReference.PREPAYMENT) {
            return getHumanReadableValue('paymentTermReference', paymentTermReference);
        }

        return `${paymentTermReferenceDays} ${getHumanReadableValue('paymentTermReference', paymentTermReference)}`;
    };
};
