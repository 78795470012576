import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { FuelSuppliersOverviewPage as OriginalOverviewPage } from './FuelSuppliersOverviewPage';

const FuelSuppliersOverviewPage = (props) => {
    return (
        <AppProviders>
            <OriginalOverviewPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(FuelSuppliersOverviewPage, 'reactFuelSuppliersOverview');
