import { IconButtonProps } from '../IconButton';

export function getButtonSize(size: NonNullable<IconButtonProps['size']>) {
    switch (size) {
        case 'small':
            return 24;
        case 'medium':
            return 32;
        case 'large':
            return 40;
    }
}
