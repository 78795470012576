import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { UserOverviewPage as OriginalUserOverviewPage } from './UserOverviewPage';

const UserOverviewPage = (props) => (
    <AppProviders>
        <OriginalUserOverviewPage {...props} />
    </AppProviders>
);

export default createDirectiveFromReactComponent(UserOverviewPage, 'reactUserOverviewPage');
