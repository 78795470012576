import { useFormikContext } from 'formik';

import { UserRole } from '../../../types/UserRole';
import { FormikCheckbox } from '../../cdl/Checkbox/FormikCheckbox';
import { ReadOnlyField } from '../../cdl/ReadOnlyField/ReadOnlyField';
import { FormikRoleSelect } from '../../common/form-elements/formik/FormikRoleSelect';
import { translate } from '../../common/helpers/translate.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { useRole } from '../../common/hooks/useRole';
import { LabelText } from '../../common/LabelText/LabelText';
import { Box } from '../../common/ui/Box';

import { FormikEditUserState } from './EditUserForm';
import { EditUserFormContentWrapper } from './EditUserFormContent';
import { EditUserFormPersonalInformationSection } from './EditUserFormPersonalInformationSection';
import { FormikEditUserFormCompanyMultiSelect } from './FormikEditUserCompanyMultiSelect';

interface EditUserAdminFormContentProps {
    onRoleChange: (role?: string) => void;
}

export const EditUserAdminFormContent = ({ onRoleChange }: EditUserAdminFormContentProps) => {
    const { values } = useFormikContext<FormikEditUserState>();
    const role = useRole();
    const { getHumanReadableValue } = useEnums();

    const isEditingOwnUser = values.id === role.user.id;

    if (isEditingOwnUser) {
        return (
            <EditUserFormContentWrapper>
                <EditUserFormPersonalInformationSection />
                <Box display="grid" gridTemplateRows="1fr 1fr 1fr" gridGap="8px">
                    <LabelText>{translate('user.mailSettings')}</LabelText>
                    <FormikCheckbox name="newsletter" label={translate('user.news')} />
                    <FormikCheckbox name="transactional" label={translate('user.orderUpdates')} />
                </Box>
            </EditUserFormContentWrapper>
        );
    }

    if (values.role === UserRole.CUSTOM) {
        return (
            <EditUserFormContentWrapper>
                <EditUserFormPersonalInformationSection />
                <ReadOnlyField
                    value={getHumanReadableValue('userType', values.userType)}
                    label={translate('user.userType')}
                />
            </EditUserFormContentWrapper>
        );
    }

    return (
        <EditUserFormContentWrapper>
            <EditUserFormPersonalInformationSection />
            <ReadOnlyField
                value={getHumanReadableValue('userType', values.userType)}
                label={translate('user.userType')}
            />
            {values.groupName ? <ReadOnlyField value={values.groupName} label={translate('user.group')} /> : null}

            <FormikRoleSelect name="role" label={translate('user.role')} onRoleChange={onRoleChange} />
            {values.role && values.role !== 'GROUP_ADMIN' ? <FormikEditUserFormCompanyMultiSelect /> : null}
        </EditUserFormContentWrapper>
    );
};
