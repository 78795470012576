export const useCheapestScope = ({ supplierData, isFetching, activeSlide, slidesPerView }) => {
    if (!supplierData || isFetching) {
        return {
            prev: false,
            next: false,
        };
    }

    const supplierPrices = Object.values(supplierData);
    const leftSupplierInformation = supplierPrices.slice(0, activeSlide);
    const rightSupplierInformation = supplierPrices.slice(activeSlide + slidesPerView);

    const hasBestPrice = (supplierPrices) => {
        return supplierPrices.some((price) => price.bestPrice);
    };

    return {
        prev: hasBestPrice(leftSupplierInformation),
        next: hasBestPrice(rightSupplierInformation),
    };
};
