import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getLubesPrices, GetLubesPricesParams } from '../clients/gateway.api';

export const priceCompare = createQueryKeys('priceCompare', {
    lubes: (params: GetLubesPricesParams) => ({
        queryKey: [{ params }],
        queryFn: () => getLubesPrices(params),
    }),
});
