import axios from 'axios';

import AppSettings from '../../../constants';

import { addAuthenticationInterceptors } from './interceptors/addAuthenticationInterceptors';

const authorizedGatewayAxiosInstance = axios.create({
    baseURL: AppSettings.routes.gateway,
});

addAuthenticationInterceptors(authorizedGatewayAxiosInstance);

export { authorizedGatewayAxiosInstance };
