import { Button } from '../../../cdl/Button/Button';
import { translate } from '../../../common/helpers/translate.helper';
import { IconDownload } from '../../../common/icons/cdl/Download';

export const DownloadPriceListButton = () => {
    return (
        <Button
            as="a"
            target="_blank"
            href="download/price-list-template.xls"
            download="price-list-template.xls"
            leadingVisual={<IconDownload height={16} width={16} />}
        >
            {translate('assignments.dlpricelistbtn')}
        </Button>
    );
};
