import { Text } from '../../../cdl/Text/Text';
import { translate, TranslationKeyEn } from '../../../common/helpers/translate.helper';
import { useLocalStorageState } from '../../../common/hooks/useLocalStorageState';
import { useRole } from '../../../common/hooks/useRole';
import { IconBulb } from '../../../common/icons/cdl/Bulb';
import { IconX } from '../../../common/icons/cdl/X';
import { Section } from '../../../common/Section/Section';
import { Flex } from '../../../common/ui/Flex';
import { Paragraph } from '../../../common/ui/Paragraph';

// Usually, we should not use dangerouslySetInnerHTML
// In this case, it makes sense to have an easy way to integrate links inside translations.
const MultilineParagraph = ({ children }: { children: string }) => {
    if (!children) {
        return null;
    }

    return children.split('\n').map((line, key) => {
        return line ? <Paragraph dangerouslySetInnerHTML={{ __html: line }} key={key} /> : <br />;
    });
};

export const UpdateSection = () => {
    const featureName = '';
    const [viewedTimestamp, setViewedTimestamp] = useLocalStorageState(featureName, null);
    const role = useRole();

    const onCancelIconClick = () => setViewedTimestamp(new Date());

    const t = (key: string) => {
        const translationKey = `communicationBanner.${featureName}.${key}` as TranslationKeyEn;
        const result = translate(translationKey);

        // Our translation provider does not return null
        // but the requested key if there is no translation for the given key
        if (translationKey === result) {
            return null;
        }

        return result;
    };
    const headline = t('headline');
    const description = t('description');

    if (!role.isCustomer()) {
        return null;
    }

    if (viewedTimestamp) {
        return null;
    }

    if (!headline || !description) {
        return null;
    }

    return (
        <Section
            title={
                <Flex alignItems="center">
                    <IconBulb />
                    <Text marginLeft={2}>{headline}</Text>
                </Flex>
            }
            actions={
                <button onClick={onCancelIconClick}>
                    <IconX />
                </button>
            }
        >
            {/* @ts-ignore */}
            <MultilineParagraph>{description}</MultilineParagraph>
        </Section>
    );
};
