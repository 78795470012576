import { FuelPricingCodeFrequency } from '../../../../types/FuelPricingCodeFrequency';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const getPricingCodes = async (frequencies?: FuelPricingCodeFrequency[], searchQuery?: string): Promise<any> => {
    const params = new URLSearchParams();

    if (frequencies?.length) {
        frequencies.forEach((frequency) => params.append('frequencies', frequency));
    }

    if (searchQuery) {
        params.append('searchQuery', searchQuery.trim());
    }

    const response = await authorizedAxiosInstance.get(`/v1/fuel-pricing-codes?${params.toString()}`);

    return response.data;
};

export const getPricingCode = async (id: string): Promise<any> => {
    const response = await authorizedAxiosInstance.get(`/v1/fuel-pricing-codes/${id}`);

    return response.data;
};
