import moment from 'moment-timezone';

interface FormatDateTimeConfig {
    date: number | string;
    timeZoneId?: string;
    format?: string;
}

// lll is the shortcut for Sep 4, 1986 8:30 PM
// https://momentjs.com/docs/#/displaying/format/
export const formatDateTime = ({ date, timeZoneId, format = 'lll' }: FormatDateTimeConfig) => {
    if (timeZoneId && moment.tz.zone(timeZoneId)) {
        return moment(date).tz(timeZoneId).format(format);
    }

    timeZoneId = moment.tz.guess();

    return moment(date).tz(timeZoneId).format(format);
};
