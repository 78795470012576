import { ReactNode } from 'react';

import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../helpers/translate.helper';
import { IconCircleX } from '../../icons/cdl/CircleX';
import { IconProgress10 } from '../../icons/cdl/Progress10';
import { IconProgress25 } from '../../icons/cdl/Progress25';
import { IconProgress50 } from '../../icons/cdl/Progress50';
import { IconProgress75 } from '../../icons/cdl/Progress75';
import { IconProgress90 } from '../../icons/cdl/Progress90';
import { Flex, FlexProps } from '../../ui/Flex';
import { useCountdown } from '../hooks/useCountdown';
import { makeRemainingTimeString } from '../utils/makeRemainingTimeString';

interface WrapperProps extends Pick<FlexProps, 'color' | 'backgroundColor'> {
    children: ReactNode;
    isSmallVariant: boolean;
}

const Wrapper = (props: WrapperProps) => {
    return (
        <Flex
            paddingX={props.isSmallVariant ? 4 : '12px'}
            paddingY={props.isSmallVariant ? 2 : 4}
            borderRadius={1}
            alignItems="center"
            color={props.color}
            backgroundColor={props.backgroundColor}
        >
            {props.children}
        </Flex>
    );
};

interface ValidityTextProps {
    remainingTime: number;
    isSmallVariant: boolean;
}

const ValidityText = ({ remainingTime, isSmallVariant }: ValidityTextProps) => {
    return (
        <Text
            marginLeft={isSmallVariant ? 2 : 4}
            variant={isSmallVariant ? 'extraSmall' : 'small'}
            fontWeight={600}
            style={{ fontVariantNumeric: 'tabular-nums' }}
        >
            {makeRemainingTimeString(remainingTime)}
        </Text>
    );
};

interface ValidityContentProps {
    validityTime: number;
    validUntil: string;
    background?: string;
    size: 'small' | 'default';
}

export const CircleValidityContent = ({ validityTime, validUntil, background, size }: ValidityContentProps) => {
    const remainingTime = useCountdown(validUntil);

    const initialRemainingTime = validityTime * 60;
    const leftPercentage = (remainingTime / initialRemainingTime) * 100;

    const isExpired = leftPercentage <= 0;
    const isSmall = size === 'small';

    const backgroundColor = background ?? 'background.inset';
    const iconSize = isSmall ? 16 : 24;

    if (isExpired) {
        return (
            <Wrapper color="foreground.default" backgroundColor={backgroundColor} isSmallVariant={isSmall}>
                <IconCircleX width={iconSize} height={iconSize} />
                <Text marginLeft={isSmall ? 2 : 4} variant={isSmall ? 'extraSmall' : 'small'} fontWeight={600}>
                    {translate('order.validityExpired')}
                </Text>
            </Wrapper>
        );
    }

    if (leftPercentage >= 75) {
        return (
            <Wrapper color="accent.foreground" backgroundColor={backgroundColor} isSmallVariant={isSmall}>
                <IconProgress90 width={iconSize} height={iconSize} />
                <ValidityText remainingTime={remainingTime} isSmallVariant={isSmall} />
            </Wrapper>
        );
    }

    if (leftPercentage >= 50) {
        return (
            <Wrapper color="accent.foreground" backgroundColor={backgroundColor} isSmallVariant={isSmall}>
                <IconProgress75 width={iconSize} height={iconSize} />
                <ValidityText remainingTime={remainingTime} isSmallVariant={isSmall} />
            </Wrapper>
        );
    }

    if (leftPercentage >= 25) {
        return (
            <Wrapper color="accent.foreground" backgroundColor={backgroundColor} isSmallVariant={isSmall}>
                <IconProgress50 width={iconSize} height={iconSize} />
                <ValidityText remainingTime={remainingTime} isSmallVariant={isSmall} />
            </Wrapper>
        );
    }

    if (leftPercentage >= 10) {
        return (
            <Wrapper
                color="attention.foreground"
                backgroundColor={background ?? 'attention.background'}
                isSmallVariant={isSmall}
            >
                <IconProgress25 width={iconSize} height={iconSize} />
                <ValidityText remainingTime={remainingTime} isSmallVariant={isSmall} />
            </Wrapper>
        );
    }

    return (
        <Wrapper
            color="negative.foreground"
            backgroundColor={background ?? 'negative.background'}
            isSmallVariant={isSmall}
        >
            <IconProgress10 width={iconSize} height={iconSize} />
            <ValidityText remainingTime={remainingTime} isSmallVariant={isSmall} />
        </Wrapper>
    );
};
