import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconMail = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M4.112 6.54 12 11.798l7.888-5.258A1 1 0 0 0 19 6H5a1 1 0 0 0-.888.54ZM20 8.869l-7.445 4.963a1 1 0 0 1-1.11 0L4 8.87V17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V8.869ZM2 7a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V7Z"
            clipRule="evenodd"
        />
    </svg>
);
