import styled, { css } from 'styled-components';

import { theme } from '../../../common/ui/theme';

interface ScheduleLineStylesProps {
    hideScheduleLine: boolean;
    isFirstScheduleStop: boolean;
    isLastScheduleStop: boolean;
}

const scheduleLineStyles = (props: ScheduleLineStylesProps) => {
    if (props.hideScheduleLine) {
        return null;
    }

    const individualLineStyles = (props: ScheduleLineStylesProps) => {
        if (props.isFirstScheduleStop) {
            return css`
                height: 32px;
                top: 16px;
            `;
        }

        if (props.isLastScheduleStop) {
            return css`
                height: 16px;
                top: -8px;
            `;
        }

        return css`
            top: -8px;
        `;
    };

    return css`
        &:before {
            content: '';
            position: absolute;
            height: 56px;
            ${individualLineStyles};
            left: 10px;
            border-left: 2px solid ${theme.colors.border.default};
        }
    `;
};

const scheduleStopStyles = () => {
    return css`
        &:after {
            content: '';
            position: absolute;
            left: 7px;
            top: 8px;
            height: 8px;
            width: 8px;
            background-color: ${theme.colors.foreground.subtle};
            border-radius: 100%;
        }
    `;
};

export const ScheduleIndicator = styled.div`
    position: relative;
    height: 100%;

    ${scheduleLineStyles};

    ${scheduleStopStyles};
`;
