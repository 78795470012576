import * as Dialog from '@radix-ui/react-dialog';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const ModalContent = styled(Dialog.Content).attrs<{
    width: number;
    scrollable?: boolean;
}>((props) => ({
    style: {
        maxWidth: `${props.width}px`,
        width: `${props.width}px`,
    },
}))<{
    width: number;
    scrollable?: boolean;
}>`
    text-align: left;
    vertical-align: middle;
    position: relative;
    padding: 0;
    box-shadow: ${get('shadows.extraLarge')};
    border-radius: 8px;
    max-height: 80vh;
    background-color: white;
    overflow: ${(props) => (props.scrollable ? 'auto' : 'visible')};
`;
