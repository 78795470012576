import styled from 'styled-components';

import { get } from '../../../common/ui/get';
import { Text } from '../../Text/Text';

const DayOfWeekNameContainer = styled.div`
    width: 40px;
    height: 40px;
    padding: ${get('space.4')}px;
    text-align: center;
`;

interface DayOfWeekNameProps {
    children: string;
}

export const DayOfWeekName = ({ children }: DayOfWeekNameProps) => {
    return (
        <DayOfWeekNameContainer>
            <Text variant="small" fontWeight={600}>
                {children.slice(0, 2)}
            </Text>
        </DayOfWeekNameContainer>
    );
};
