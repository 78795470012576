import { PortMessage } from '../../../../types/PortMessage';
import { Button } from '../../../cdl/Button/Button';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../../common/helpers/translate.helper';

import { EditPortForm } from './EditPortForm';

interface EditPortButtonProps {
    port: PortMessage;
}

export const EditPortButton = ({ port }: EditPortButtonProps) => {
    const { pop, push } = useDrawer();
    const { addToast } = useToasts();

    const onModalClose = () => {
        pop();
        addToast(translate('port.updatePortSuccessToast'));
    };

    const openPortEditModal = () =>
        push({
            content: <EditPortForm onSuccess={onModalClose} port={port} />,
        });

    return <Button onClick={openPortEditModal}>{translate('port.edit')}</Button>;
};
