import { QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { useAngularDependencies } from '../hooks/useAngularDependencies';

export const ReactQueryClientProvider = ({ children, queryClient }) => {
    const { $rootScope } = useAngularDependencies();

    $rootScope.$on('logoutSuccess', () => queryClient.clear());

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

ReactQueryClientProvider.propTypes = {
    children: PropTypes.node,
    queryClient: PropTypes.object,
};
