import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import { ReachInformationStop } from '../../../../types/ReachInformationMessage';
import { ScheduleMessage } from '../../../../types/ScheduleMessage';
import { VesselMessage } from '../../../../types/VesselMessage';
import { getScheduleList } from '../../../common/api/clients/gateway.api';
import { getReachInformation } from '../../../common/api/clients/reachInformation.api';
import { queryKeys } from '../../../common/api/queryKeys';

export interface SchedulePriceInformation<T> {
    schedules: ScheduleMessage[];
    prices: T[];
    reachInformation: ReachInformationStop[];
}

async function fetchSchedulesWithPrices<T>(
    imo: string,
    etdFrom: string,
    etdTo: string,
    vesselId: string,
    customerGroupId: string,
    loadPrices: (vesselId: string, portIds: string[]) => Promise<T[]>
): Promise<SchedulePriceInformation<T>> {
    const [schedules, reachInformation] = await Promise.all([
        getScheduleList({
            imo,
            etdFrom,
            etdTo,
            customerGroupId,
        }),
        getReachInformation(vesselId),
    ]);

    if (!schedules?.length) {
        return { schedules: [], prices: [], reachInformation: [] };
    }

    const schedulesWithPort = schedules.filter((schedule) => schedule.port);
    const portIds = schedulesWithPort.map((stop) => stop.port!.id);

    const prices = await loadPrices(vesselId, portIds);

    return {
        schedules,
        reachInformation: reachInformation.stops,
        prices,
    };
}

export function useEnhancedSchedule<T>(
    vessel: VesselMessage,
    loadPrices: (vesselId: string, portIds: string[]) => Promise<T[]>
) {
    const etdFrom = moment.utc(moment()).startOf('day').toISOString();

    const etdTo = moment.utc(moment().add(120, 'days')).endOf('day').toISOString();

    const imo = vessel.imo;
    const vesselId = vessel.id;
    const customerGroupId = vessel.customer.customerGroupId;

    return useQuery({
        queryKey: queryKeys.schedulesWithPrices(vesselId),
        queryFn: () => fetchSchedulesWithPrices(imo, etdFrom, etdTo, vesselId, customerGroupId, loadPrices),
    });
}
