import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconBellAlert = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12.334 5.2a6.934 6.934 0 0 0-.641-.095 2 2 0 0 0-3.669.983c-2.327 1.674-3.906 5.13-3.211 7.724l.17 2.654a2 2 0 0 0 2.514 1.803L18.24 15.39a2 2 0 0 0 1.275-2.819l-.493-.997a4.984 4.984 0 0 1-2.272.419l.172.644a1 1 0 0 1-.707 1.225l-7.727 2.07a1 1 0 0 1-1.225-.707l-.259-.966c-.258-.966-.328-5.088 3.536-6.124a5.094 5.094 0 0 1 1.552-.18 5.026 5.026 0 0 1 .243-2.756Zm1.902.63A2.99 2.99 0 0 0 14 7c0 .76.283 1.454.749 1.983.183.146.353.301.51.46.49.35 1.092.557 1.741.557a2.99 2.99 0 0 0 1.195-.248c-.618-1.642-2.175-3.086-3.959-3.921Zm-2.523 14.415a2 2 0 0 0 1.414-2.45l-3.864 1.036a2 2 0 0 0 2.45 1.414Z"
            clipRule="evenodd"
        />
        <path fill="#FE355A" d="M20 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    </svg>
);
