import { useFormikContext } from 'formik';

import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { IconCircleCheck } from '../../common/icons/cdl/CircleCheck';
import { SelectBox } from '../../common/SelectBox/SelectBox';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';

import { ContractProducts } from './ContractProducts';
import { FuelEnquiryBoxContent } from './FuelEnquiryBoxContent';

const mapContractProductToItemState = (contractProduct, defaultUnitSize) => {
    return {
        productId: contractProduct.product.id,
        units: '',
        unit: contractProduct.product.unitDefault,
        productName: contractProduct.product.name,
        sulphurContent: contractProduct.product.sulphurContent,
        group: contractProduct.product.group,
        packType: contractProduct.product.packTypeDefault,
        unitSize: defaultUnitSize,
    };
};

export const FuelContractProducts = () => {
    const { values, setValues } = useFormikContext();
    const { getEnumField } = useEnums();

    const itemsStateShape = values.items.map((item) => {
        return { ...item, contractId: values.contractId || null };
    });

    const onEnquiryButtonClick = () => {
        if (!values.contractId) {
            return;
        }

        setValues({
            ...values,
            contractId: null,
            contract: null,
            items: [
                {
                    productName: null,
                    productId: null,
                    units: '',
                    unit: null,
                    sulphurContent: null,
                    group: null,
                    packType: null,
                    unitSize: null,
                },
            ],
        });
    };

    const makeContractProductKey = (product) => {
        return `${product.contractId}-${product.productId}`;
    };

    const onContractButtonClick = (contractProduct) => {
        const defaultUnitSize = getEnumField('packType', contractProduct.product.packTypeDefault, 'defaultUnitSize');
        const contractProductStateShape = mapContractProductToItemState(contractProduct, defaultUnitSize);

        if (values.contractId !== contractProduct.contractId) {
            values.contractId = contractProduct.contractId;
            values.contract = contractProduct.contract;
            values.items = [contractProductStateShape];

            setValues({
                ...values,
                contractId: values.contractId,
                contract: values.contract,
                items: values.items,
                validityTime: '',
                assignedSupplierIds: null,
                receiverSupplierIds: null,
                spot: false,
            });
            return;
        }

        const hasProduct = itemsStateShape.some((item) => {
            return makeContractProductKey(item) === makeContractProductKey(contractProduct);
        });

        if (!hasProduct) {
            values.items.push(contractProductStateShape);
            setValues({
                ...values,
                contractId: values.contractId,
                contract: values.contract,
                items: values.items,
            });
            return;
        }

        const index = itemsStateShape.findIndex(
            (item) => makeContractProductKey(item) === makeContractProductKey(contractProduct)
        );

        const isLastProduct = values.items.length === 1;

        if (isLastProduct) {
            values.items.splice(index, 1, {
                productName: null,
                productId: null,
                units: '',
                unit: null,
                sulphurContent: null,
                group: null,
                packType: null,
                unitSize: null,
            });
            values.contractId = null;
            values.contract = null;
        } else {
            values.items.splice(index, 1);
        }

        setValues({
            ...values,
            contractId: values.contractId,
            contract: values.contract,
            items: values.items,
        });
    };

    const isSelected = (contractProduct) => {
        const includesProduct = itemsStateShape.some((item) => {
            return makeContractProductKey(item) === makeContractProductKey(contractProduct);
        });

        return values.contractId === contractProduct.contractId && includesProduct;
    };

    return (
        <Flex flexDirection="row">
            <Box borderRight={1} paddingRight={7}>
                <Flex flexDirection="row" alignItems="center" color="dark-blue.3">
                    <IconCircleCheck />
                    <Text marginLeft={4} size={14} weight="semi">
                        {translate('fuelEnquiry.second.contractProducts.createEnquiry')}
                    </Text>
                </Flex>

                <Flex paddingY={5} width="100%" justifyContent="center">
                    <SelectBox
                        onClick={onEnquiryButtonClick}
                        selected={!values.contractId}
                        height="250px"
                        width="258px"
                    >
                        <FuelEnquiryBoxContent />
                    </SelectBox>
                </Flex>
            </Box>

            <Box width="calc(100% - 300px)">
                <Flex flexDirection="row" alignItems="center" color="dark-blue.3" marginLeft={7}>
                    <IconCircleCheck />
                    <Text marginLeft={4} size={14} weight="semi">
                        {translate('fuelEnquiry.second.contractProducts.createDirectOrder')}
                    </Text>
                </Flex>

                <Flex justifyContent="center" alignItems="center" minHeight="250px">
                    <ContractProducts
                        isSelected={isSelected}
                        onContractButtonClick={onContractButtonClick}
                        customerId={values.customerId}
                    />
                </Flex>
            </Box>
        </Flex>
    );
};
