import { Formik, FormikErrors } from 'formik';

import { AddressMessage } from '../../../../../types/AddressMessage';
import { ProductContext } from '../../../../../types/ProductContext';
import { translate } from '../../../../common/helpers/translate.helper';
import { useProductContext } from '../../../../common/hooks/useProductContext';
import { useRole } from '../../../../common/hooks/useRole';

import { FormikCreateSupplierCompanyForm } from './FormikCreateSupplierCompanyForm';

export interface FormikSupplierCreateState {
    type: ProductContext | '';
    name: string;
    email: string;
    phone?: string;
    fax?: string;
    invoiceMail: string;
    address: AddressMessage;
    testCompany: boolean;
    showGtc: boolean;
    trial: boolean;
    messageSettings: {
        oderConfirmationMessage: string;
        quotationMessage: string;
    };
}

export interface CreateSupplierCompanyFormProps {
    onSubmit: (values: FormikSupplierCreateState) => void;
}

export const CreateSupplierCompanyForm = ({ onSubmit }: CreateSupplierCompanyFormProps) => {
    const role = useRole();
    const { possibleContexts } = useProductContext();

    const initialFormikState: FormikSupplierCreateState = {
        type: '',
        name: '',
        email: '',
        phone: '',
        fax: '',
        invoiceMail: '',
        address: {
            city: '',
            companyName: '',
            country: '',
            street: '',
            streetNumber: '',
            zipcode: '',
        },
        testCompany: true,
        showGtc: false,
        trial: false,
        messageSettings: {
            oderConfirmationMessage: '',
            quotationMessage: '',
        },
    };

    const validate = (values: FormikSupplierCreateState) => {
        const errors: FormikErrors<FormikSupplierCreateState> = {};

        if (!values.type && (role.isAdmin() || possibleContexts.length > 1)) {
            errors.type = translate('supplier.noCompanyTypeError');
        }

        if (!values.name) {
            errors.name = translate('supplier.noCompanyNameError');
        }

        if (!values.email) {
            errors.email = translate('supplier.noEmailAddressError');
        }

        return errors;
    };

    return (
        <Formik
            initialValues={initialFormikState}
            onSubmit={onSubmit}
            validate={validate}
            component={FormikCreateSupplierCompanyForm}
        />
    );
};
