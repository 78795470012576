import { debounce } from 'lodash';
import { BaseSyntheticEvent, useRef, useState } from 'react';

import { Input } from '../../../cdl/Input/Input';
import { IconSearch } from '../../icons/cdl/Search';

interface DebouncedSearchTextInputProps {
    onChange: (value: string) => void;
    value: string;
    placeholder: string;
}

export const DebouncedSearchTextInput = ({ onChange, value, placeholder }: DebouncedSearchTextInputProps) => {
    const [searchInput, setSearchInput] = useState(value);

    const debouncedOnChangeRef = useRef(debounce((value) => onChange(value), 300));

    const handleChange = (event: BaseSyntheticEvent) => {
        setSearchInput(event.target.value);
        debouncedOnChangeRef.current(event.target.value);
    };

    return (
        <Input value={searchInput} onChange={handleChange} placeholder={placeholder} leadingVisual={<IconSearch />} />
    );
};
