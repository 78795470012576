import { ProductContext } from '../../../../types/ProductContext';
import { CustomerCompanySelect } from '../../../common/form-elements/CompanySelect/CustomerCompanySelect';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { Box } from '../../../common/ui/Box';

export const SupplierFilterActions = () => {
    const role = useRole();
    const [locationState, { setLocationFieldValue }] = useLocationState();

    if (role.isOneCompanyUser(ProductContext.LUBES)) {
        return null;
    }

    return (
        <Box minWidth="350px">
            <CustomerCompanySelect
                value={locationState.customerIds}
                placeholder={translate('assignments.suppliers.companySelectPlaceholder')}
                onChange={(customerIds) => {
                    setLocationFieldValue('customerIds', customerIds);
                }}
                isMulti
            />
        </Box>
    );
};
