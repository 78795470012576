import PropTypes from 'prop-types';

import { Select } from '../form-elements/Select/Select';
import { useEnums } from '../hooks/useEnums';

export const LegacyEnumOptionSelect = (props) => {
    const { getEnumsForTypeAndContext } = useEnums();

    const getOptions = () => {
        const enums = getEnumsForTypeAndContext(props.enumType, props.context);

        if (!enums) {
            return [];
        }

        const options = Object.keys(enums).map((key) => {
            const value = enums[key];

            return {
                value: key,
                label: value.humanReadableValue,
            };
        });

        if (props.sort) {
            props.sort(options);
        }

        return options;
    };

    const options = getOptions();

    const selectedValue = options.find((option) => option.value === props.value);

    return (
        <Select
            {...props}
            label={props.label}
            required={props.required}
            value={selectedValue}
            options={options}
            onChange={props.onSelect}
        />
    );
};

LegacyEnumOptionSelect.propTypes = {
    enumType: PropTypes.string.isRequired,
    context: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    sort: PropTypes.func,
};
