import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { updatePort } from '../../../common/api/clients/port.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { translate } from '../../../common/helpers/translate.helper';

export const usePortEdit = () => {
    const { addErrorToast } = useToasts();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updatePort,
        onSuccess: (port) => queryClient.invalidateQueries({ queryKey: queryKeys.port(port.id) }),
        onError: () => {
            addErrorToast(translate('port.updatePortErrorToast'));
        },
    });
};
