import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { DrawerFormLayout } from '../../cdl/Drawer/DrawerFormLayout';
import { Text } from '../../cdl/Text/Text';
import { FormContextCompanySelect } from '../../common/form-elements/CompanySelect/ContextCustomerCompanySelect';
import { Form } from '../../common/form-elements/Form/Form';
import { FormInputPlaces } from '../../common/form-elements/InputPlaces/FormInputPlaces';
import { Label } from '../../common/form-elements/Label/Label';
import { FormTextarea } from '../../common/form-elements/Textarea/Textarea';
import { FormTextInput } from '../../common/form-elements/TextInput/TextInput';
import { translate } from '../../common/helpers/translate.helper';
import { FormMultiPortSelect } from '../../common/MultiPortSelect/MultiPortSelect';
import { Box } from '../../common/ui/Box';
import { OrderCreationComment } from '../../order/OrderCreationComment';

export const AgentForm = ({ title, agent, comment, onSubmit: onSubmitCallback, showCompanySelect = false }) => {
    const [valid, setValid] = useState(false);

    const checkFormValidity = ({ data }) => {
        if (data.name && data.customerId && data.portIds?.length > 0) {
            setValid(true);
        } else {
            setValid(false);
        }
    };

    useEffect(() => {
        if (agent) {
            checkFormValidity({ data: agent });
        }
    }, [agent]);

    const onSubmit = ({ data, event }) => {
        onSubmitCallback({ data, event });
    };

    return (
        <Form height="100%" data={agent} onSubmit={onSubmit} onChange={checkFormValidity}>
            <DrawerFormLayout title={title} isSubmitButtonDisabled={!valid}>
                {comment ? (
                    <Box marginBottom={6} paddingX={7}>
                        <OrderCreationComment text={comment} />
                    </Box>
                ) : null}

                {showCompanySelect ? (
                    <div>
                        <Box marginBottom={3}>
                            <Text>{translate('agentForm.companyHint')}</Text>
                        </Box>
                        <Label label={translate('agents.company')} required>
                            <FormContextCompanySelect dataPath="customerId" />
                        </Label>
                    </div>
                ) : null}

                <FormTextInput
                    label={translate('agentForm.agentName')}
                    dataPath="name"
                    placeholder={translate('agentForm.placeholder.agentName')}
                    required
                    focus
                />

                <FormTextInput
                    label={translate('global.address.country')}
                    dataPath="address.country"
                    placeholder={translate('global.address.placeholder.country')}
                />

                <FormMultiPortSelect
                    dataPath="portIds"
                    label={translate('agentForm.ports')}
                    placeholder={translate('agentForm.placeholder.ports')}
                    required
                />

                <FormInputPlaces
                    label={translate('global.address.street')}
                    dataPaths={{
                        street: 'address.street',
                        streetNumber: 'address.streetNumber',
                        zipcode: 'address.zipCode',
                        city: 'address.city',
                        country: 'address.country',
                    }}
                    input={{
                        placeholder: translate('global.address.placeholder.street'),
                    }}
                />

                <FormTextInput
                    label={translate('global.address.streetnumber')}
                    dataPath="address.streetNumber"
                    placeholder={translate('global.address.placeholder.streetnumber')}
                />

                <FormTextInput
                    label={translate('global.address.zipcode')}
                    dataPath="address.zipCode"
                    placeholder={translate('global.address.placeholder.zipcode')}
                />

                <FormTextInput
                    label={translate('global.address.city')}
                    dataPath="address.city"
                    placeholder={translate('global.address.placeholder.city')}
                />

                <Text marginTop={6} as="h2" variant="subtitle1">
                    {translate('agentForm.contact.heading')}
                </Text>

                <FormTextInput
                    label={translate('agentForm.contact.name')}
                    dataPath="contact.name"
                    placeholder={translate('agentForm.placeholder.contact.name')}
                />

                <FormTextInput
                    label={translate('agentForm.contact.emailAddress')}
                    dataPath="contact.emailAddress"
                    placeholder={translate('agentForm.placeholder.contact.emailAddress')}
                />

                <FormTextInput
                    label={translate('agentForm.contact.phoneNumber')}
                    dataPath="contact.phoneNumber"
                    placeholder={translate('agentForm.placeholder.contact.phoneNumber')}
                />

                <FormTextInput
                    label={translate('agentForm.contact.faxNumber')}
                    dataPath="contact.faxNumber"
                    placeholder={translate('agentForm.placeholder.contact.faxNumber')}
                />

                <FormTextarea
                    label={translate('agentForm.note')}
                    dataPath="note"
                    placeholder={translate('agentForm.placeholder.note')}
                />
            </DrawerFormLayout>
        </Form>
    );
};

AgentForm.propTypes = {
    title: PropTypes.string.isRequired,
    agent: PropTypes.object.isRequired,
    comment: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    showCompanySelect: PropTypes.bool,
};
