import PropTypes from 'prop-types';
import { createContext } from 'react';

export const FormContext = createContext({});
export const FormContextProvider = ({ children, value }) => (
    <FormContext.Provider value={value}>{children}</FormContext.Provider>
);

FormContextProvider.propTypes = {
    children: PropTypes.node,
    value: PropTypes.object,
};
