import PropTypes from 'prop-types';

import { IconButton } from '../../../cdl/IconButton/IconButton';
import { translate } from '../../../common/helpers/translate.helper';
import { useAngularDependencies } from '../../../common/hooks/useAngularDependencies';
import { useNavigation } from '../../../common/hooks/useNavigation';
import { IconCopy } from '../../../common/icons/cdl/Copy';

const getDuplicateTooltipForOrderState = (order) => {
    if (order.isDraft()) {
        return translate('actionListLabel.copyDraftTo');
    }

    if (order.isClosed()) {
        return translate('actionListLabel.copyTo');
    }

    return translate('actionListLabel.copyEnquiryTo');
};

export const DuplicateOrderButton = ({ order }) => {
    const { navigate } = useNavigation();
    const { $state } = useAngularDependencies();

    const onDuplicateOrderClick = (e) => {
        e.stopPropagation();

        if (order.type === 'FUEL') {
            navigate('base.fuel-enquiry', {
                order,
                origin: {
                    stateName: 'base.fuel-orderlist',
                },
            });
        } else {
            void navigate('lubes-create-enquiry', { order, origin: { stateName: $state.current.name } });
        }
    };

    return (
        <IconButton icon={IconCopy} tooltip={getDuplicateTooltipForOrderState(order)} onClick={onDuplicateOrderClick} />
    );
};

DuplicateOrderButton.propTypes = {
    order: PropTypes.object.isRequired,
};
