import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';

import { Modal } from '../../cdl/Modal/Modal';
import { TextButton } from '../buttons/TextButton.tsx';

export const PopupButton = ({ data, ...props }) => {
    const [show, setShow] = useState(false);

    if (!data.content) {
        return (
            <TextButton {...props} disabled>
                {data.buttonText}
            </TextButton>
        );
    }

    return (
        <Fragment>
            <TextButton onClick={() => setShow(true)} {...props}>
                {data.buttonText}
            </TextButton>

            <Modal isOpen={show} scrollable={false} onDismiss={() => setShow(false)}>
                {data.content}
            </Modal>
        </Fragment>
    );
};

PopupButton.propTypes = {
    buttonText: PropTypes.string,
    data: PropTypes.object,
};
