import { useQuery } from '@tanstack/react-query';

import { getOrders } from '../../common/api/clients/gateway.api';
import { DataTable } from '../../common/DataTable/DataTable';
import { Error } from '../../common/Error/Error';
import { formatDate } from '../../common/helpers/formatDate.helper';
import { translate } from '../../common/helpers/translate.helper';
import { translateOrderOfferState } from '../../common/helpers/translateOrderOfferState.helper';
import { useAngularRouterLocation } from '../../common/hooks/useAngularRouterLocation';
import { useNavigation } from '../../common/hooks/useNavigation';
import { useRole } from '../../common/hooks/useRole';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { Section } from '../../common/Section/Section';
import { TableEmptyState } from '../../common/TableEmptyState/TableEmptyState';
import { Port } from '../../port/Port';

export const VesselOrderTable = () => {
    const { id } = useAngularRouterLocation();
    const role = useRole();

    const { isPending, isError, data } = useQuery({
        queryKey: ['vessel', 'orders', 1, id],
        queryFn: () => getOrders({ page: 0, vesselId: id }),
    });

    const { navigate } = useNavigation();

    const openOrder = (order) => {
        if (order.isClosed()) {
            navigate('base.offerlist-offer', {
                id: order.chosenOfferId,
            });
        } else if (order.isDraft() && !role.isAdmin() && role.hasWriteRights(order.customerId)) {
            navigate('lubes-create-enquiry-draft', {
                id: order.id,
            });
        } else {
            navigate('base.orderlist-order', {
                id: order.id,
            });
        }
    };

    if (isPending) {
        return (
            <Section title={translate('order.headline')}>
                <LoadingIndicator />
            </Section>
        );
    }

    if (isError) {
        return (
            <Section title={translate('order.headline')}>
                <Error />
            </Section>
        );
    }

    if (!data.content.length) {
        return (
            <Section title={translate('order.headline')}>
                <TableEmptyState text={translate('order.emptylist')} />
            </Section>
        );
    }

    const lastOrders = data.content.slice(0, 2);

    const columns = [
        {
            width: '10%',
            label: translate('order.buyerreferenceshort'),
            value: (order) => order.buyerReference || '-',
        },
        {
            width: role.isAdmin() ? '30%' : '40%',
            label: translate('order.port'),
            value: function renderPort(order) {
                if (!order.port) {
                    return '-';
                }
                return <Port port={order.port} vesselId={order.vesselId} showTooltip />;
            },
        },
        {
            width: '20%',
            label: translate('order.dateDeliveryShort'),
            value: function renderDateDelivery(order) {
                if (!order.dateDelivery) {
                    return '-';
                }

                return formatDate({
                    date: order.dateDelivery,
                    timeZone: 'UTC',
                });
            },
        },
        {
            width: '15%',
            label: translate('order.status'),
            value: function renderOfferState(order) {
                return translateOrderOfferState(order, 'CUSTOMER');
            },
        },
    ].filter(({ condition = true }) => condition);

    return (
        <Section title={translate('order.headline')}>
            <DataTable columns={columns} rows={lastOrders} onRowClick={({ row }) => openOrder(row)} />
        </Section>
    );
};
