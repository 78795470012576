import { Formik, FormikErrors } from 'formik';

import { translate } from '../../../common/helpers/translate.helper';

import { ProductBaseForm, ProductFormState } from './ProductBaseForm';

interface ProductFormProps {
    title: string;
    initialState: ProductFormState;
    onSubmit: (values: ProductFormState) => void;
}

export const ProductForm = (props: ProductFormProps) => {
    const validate = (values: ProductFormState) => {
        const errors: FormikErrors<ProductFormState> = {};

        if (!values.name) {
            errors.name = translate('productsOverview.formErrors.name');
        }

        if (!values.supplierProductGroup) {
            errors.supplierProductGroup = translate('productsOverview.formErrors.supplier');
        }

        if (!values.group) {
            errors.group = translate('productsOverview.formErrors.group');
        }

        if (!values.packTypeDefault) {
            errors.packTypeDefault = translate('productsOverview.formErrors.packType');
        }

        if (!values.unitDefault) {
            errors.unitDefault = translate('productsOverview.formErrors.unitDefault');
        }

        return errors;
    };

    return (
        <Formik initialValues={props.initialState} onSubmit={props.onSubmit} validate={validate} validateOnMount>
            <ProductBaseForm title={props.title} />
        </Formik>
    );
};
