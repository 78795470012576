import { components, InputProps } from 'react-select';
import styled from 'styled-components';

import { get } from '../../../../common/ui/get';
import { ComboBox, ComboBoxProps, DefaultOption } from '../../../ComboBox/ComboBox';

const CustomInput = styled(components.Input)<InputProps & { placeholder?: string }>`
    && {
        width: 100%;
        font-size: 16px;
        height: 24px;
        margin: 0;
        padding: 0;
        grid-template-columns: 0 auto;

        input::placeholder {
            font-size: 16px;
            color: ${get('colors.foreground.subtle')};
        }
    }
`;

const CustomInputWithPlaceholder = (props: InputProps) => {
    const placeholder = props.selectProps.placeholder;

    return <CustomInput {...props} placeholder={placeholder as string} />;
};

export const CustomComboBox = ({ placeholder, ...props }: ComboBoxProps<DefaultOption, true>) => {
    return (
        <ComboBox<DefaultOption, true>
            {...props}
            placeholder={placeholder}
            overrides={{
                MultiValueContainer: () => null,
                Placeholder: () => null,
                Input: CustomInputWithPlaceholder as any,
            }}
            backspaceRemovesValue={false}
        />
    );
};
