import { CategoryPurchase } from '../../../../../../types/BunkerCaseSolutionResponse';
import { Text } from '../../../../../cdl/Text/Text';
import { useFuelVolumeFormat } from '../../../../../common/hooks/useFuelVolumeFormat';
import { Box } from '../../../../../common/ui/Box';

interface PurchaseSummaryProps {
    purchases: CategoryPurchase[];
    purchaseCategories: Map<string, { productCategory: string; sulphurContent: string }>;
}

export const PurchaseSummary = ({ purchases, purchaseCategories }: PurchaseSummaryProps) => {
    const formatVolume = useFuelVolumeFormat();
    const templateAreas = Array.from(purchaseCategories.keys()).sort().join(' ');

    return (
        <Box
            display="grid"
            gridTemplateColumns={`repeat(${purchaseCategories.size}, 1fr)`}
            gridTemplateAreas={`"${templateAreas}"`}
        >
            {purchases
                .filter((it) => it.quantity > 0)
                .map((purchase) => {
                    const gridAreaKey = `${purchase.productCategory}-${purchase.sulphurContent}`;

                    return (
                        <Box key={gridAreaKey} gridArea={gridAreaKey}>
                            <Box display="flex" gap={3}>
                                <Text variant="small" color="foreground.muted">
                                    {purchase.productCategory} {purchase.sulphurContent}
                                </Text>
                                <Text variant="small" fontWeight="semiBold">
                                    {formatVolume({ volume: purchase.quantity })}
                                </Text>
                            </Box>
                        </Box>
                    );
                })}
        </Box>
    );
};
