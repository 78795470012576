import { Button } from '../../cdl/Button/Button';
import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { useNavigation } from '../../common/hooks/useNavigation';
import { Box } from '../../common/ui/Box';

export const SetNewPasswordResetAgainContent = () => {
    const { navigate } = useNavigation();

    return (
        <div>
            <Text variant="title" as="h1">
                {translate('setNewPassword.passwordAlreadyReset')}
            </Text>
            <Box marginTop={4} marginBottom={6}>
                <Text as="p" color="foreground.muted">
                    {translate('setNewPassword.notPossibleToReuse')}
                </Text>
            </Box>
            <Button emphasis="high" width="100%" size="large" onClick={() => navigate('login')}>
                {translate('setNewPassword.goToLogin')}
            </Button>
        </div>
    );
};
