import { ReactNode } from 'react';

import { Text } from '../../cdl/Text/Text';

import { Box } from './Box';

interface CardVariationProps {
    children: ReactNode;
    paddingX?: number;
    paddingY?: number;
    title?: ReactNode;
}

/** @deprecated Use Box or Section instead */
export const CardVariation = ({ children, paddingX = 7, paddingY = 6, title }: CardVariationProps) => {
    return (
        <Box variant="shadow" display="flex" flexDirection="column" backgroundColor="white">
            {title ? (
                <Box
                    display="flex"
                    paddingX={6}
                    paddingY={5}
                    borderBottom={1}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {title && (
                        <Text variant="subtitle1" as="h2" margin={0}>
                            {title}
                        </Text>
                    )}
                </Box>
            ) : null}

            <Box paddingX={paddingX} paddingY={paddingY} height="100%" width="100%">
                {children}
            </Box>
        </Box>
    );
};
