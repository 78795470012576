import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { translate } from '../../common/helpers/translate.helper';
import { useEnquiryNavigateBack } from '../common/hooks/useEnquiryNavigateBack';

import { FuelEnquiryHeader } from './FuelEnquiryHeader';

export const FormikFuelEnquiryHeader = ({ title }) => {
    const { navigateBack } = useEnquiryNavigateBack();

    const formik = useFormikContext();

    const onClick = () => {
        if (!formik.dirty) {
            navigateBack();
            return;
        }

        const confirmed = confirm(translate('confirmation.warning'));
        if (confirmed) {
            navigateBack();
        }
    };

    return <FuelEnquiryHeader title={title} onCancelClick={onClick} />;
};

FormikFuelEnquiryHeader.propTypes = {
    title: PropTypes.string.isRequired,
};
