import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import styled from 'styled-components';

import { Button } from '../../cdl/Button/Button';
import { APPROVAL_STATE } from '../../common/approvalState.constant';
import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { LogoHeader } from '../../common/headers/LogoHeader';
import { formatDateTime } from '../../common/helpers/formatDateTime.helper';
import { translate } from '../../common/helpers/translate.helper';
import { IconClock } from '../../common/icons/cdl/Clock';
import { StatusBar } from '../../common/StatusBar/StatusBar';
import { StatusBarType } from '../../common/StatusBar/StatusBarType';
import { Box } from '../../common/ui/Box';
import { CustomerApprovalOfferCoreData } from '../OfferCoreData/CustomerApprovalOfferCoreData';

import { ApprovalRequestsDropdown } from './ApprovalRequestsDropdown';
import { ApprovePopup } from './ApprovePopup';
import { ContentContainer } from './ContentContainer';
import { DeclinePopup } from './DeclinePopup';
import { FuelApprovalContent } from './FuelApprovalContent';
import { SpacedProductTable } from './SpacedProductTable';

const Buttons = ({ onApproveButtonClick, onDeclineButtonClick, updatingApprovalRequestState }) => {
    return (
        <ButtonGroup>
            <Button
                emphasis="high"
                onClick={onApproveButtonClick}
                isLoading={updatingApprovalRequestState === APPROVAL_STATE.APPROVED}
            >
                {translate('approvalRequest.buttons.approve')}
            </Button>

            <Button emphasis="high" destructive onClick={onDeclineButtonClick}>
                {translate('approvalRequest.buttons.decline')}
            </Button>
        </ButtonGroup>
    );
};

Buttons.propTypes = {
    onApproveButtonClick: PropTypes.func,
    onDeclineButtonClick: PropTypes.func,
    updatingApprovalRequestState: PropTypes.string,
};

const RightElementWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const PendingStatusBar = ({ approvalRequest }) => {
    const makeSubHeadline = () => {
        if (!approvalRequest.requestMessage) {
            return translate('approvalRequest.statusBar.pendingNoComment.subHeadline', {
                date: formatDateTime({
                    date: approvalRequest.dateCreated,
                }),
                author: `${approvalRequest.createdByUser.firstname} ${approvalRequest.createdByUser.lastname}`,
            });
        }

        return translate('approvalRequest.statusBar.pending.subHeadline', {
            date: formatDateTime({ date: approvalRequest.dateCreated }),
            author: `${approvalRequest.createdByUser.firstname} ${approvalRequest.createdByUser.lastname}`,
            requestMessage: approvalRequest.requestMessage,
        });
    };

    const headline = translate('approvalRequest.statusBar.pending.headline');
    const subHeadline = makeSubHeadline();

    return <StatusBar headline={headline} subHeadline={subHeadline} type={StatusBarType.INFO} icon={<IconClock />} />;
};

PendingStatusBar.propTypes = {
    className: PropTypes.string,
    approvalRequest: PropTypes.object,
};

export const PendingState = ({
    offer,
    approvalRequests,
    onApprovalRequestDeclined,
    onApprovalRequestApproved,
    updatingApprovalRequestState,
}) => {
    const [declinePopupOpen, setDeclinePopupOpen] = useState(false);
    const [approvePopupOpen, setApprovePopupOpen] = useState(false);

    const isLubes = offer.type === 'LUBES';
    const subTitle = isLubes ? offer.offerNumber : offer.vessel.name;

    return (
        <Fragment>
            <LogoHeader
                title={translate('approvalRequest.header.title')}
                subTitle={subTitle}
                rightElement={
                    <RightElementWrapper>
                        <ApprovalRequestsDropdown approvalRequests={approvalRequests} />
                        <Buttons
                            onDeclineButtonClick={() => setDeclinePopupOpen(true)}
                            onApproveButtonClick={() => setApprovePopupOpen(true)}
                            updatingApprovalRequestState={updatingApprovalRequestState}
                        />
                    </RightElementWrapper>
                }
            />

            <ContentContainer>
                <Box marginBottom={5}>
                    <PendingStatusBar approvalRequest={approvalRequests[0]} />
                </Box>

                {isLubes ? (
                    <>
                        <Box marginBottom={5}>
                            <CustomerApprovalOfferCoreData offer={offer} />
                        </Box>
                        <SpacedProductTable offer={offer} />
                    </>
                ) : (
                    <FuelApprovalContent offer={offer} />
                )}
            </ContentContainer>

            <DeclinePopup
                open={declinePopupOpen}
                onDismiss={() => setDeclinePopupOpen((prevOpen) => !prevOpen)}
                onSubmit={({ message }) => onApprovalRequestDeclined({ message })}
                requestInProgress={updatingApprovalRequestState === APPROVAL_STATE.DECLINED}
            />

            <ApprovePopup
                open={approvePopupOpen}
                onDismiss={() => setApprovePopupOpen((prevOpen) => !prevOpen)}
                onSubmit={({ message }) => onApprovalRequestApproved({ message })}
                requestInProgress={updatingApprovalRequestState === APPROVAL_STATE.APPROVED}
            />
        </Fragment>
    );
};

PendingState.propTypes = {
    offer: PropTypes.object,
    approvalRequests: PropTypes.array,
    onApprovalRequestApproved: PropTypes.func,
    onApprovalRequestDeclined: PropTypes.func,
    updatingApprovalRequestState: PropTypes.string,
};
