import { useField, useFormikContext } from 'formik';
import React from 'react';

import { Text } from '../../../../cdl/Text/Text';
import { OfferModel } from '../../../../offer/model/OfferModel';
import { OfferState } from '../../../../offer/model/OfferState';
import { FormikFormattedNumberInput } from '../../../FormattedNumberInput/FormikFormattedNumberInput';
import { formatNumber } from '../../../helpers/formatNumber.helper';
import { useRole } from '../../../hooks/useRole';

interface UnitSizeDisplayProps {
    name: string;
    editable: boolean;
}

export const UnitSizeDisplay = (props: UnitSizeDisplayProps) => {
    const { values: offer } = useFormikContext<OfferModel>();
    const role = useRole();
    const [field] = useField(props.name);
    const item = field.value;

    if (!props.editable || (role.isCustomer() && offer.state !== OfferState.DELIVERED)) {
        const unitSize = item.unitSize ? formatNumber({ number: item.unitSize, maximumFractionDigits: 0 }) : '-';

        return <Text variant="body">{unitSize}</Text>;
    }

    return (
        <FormikFormattedNumberInput
            name={`${props.name}.unitSize`}
            disabled={item.deleted}
            decimalsLimit={3}
            allowNegativeValue={false}
        />
    );
};
