import PropTypes from 'prop-types';

import { DataSheet } from '../../common/DataSheet/DataSheet';
import { formatAddress } from '../../common/helpers/formatAddress.helper';
import { formatCompanyName } from '../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../common/helpers/formatDate.helper';
import { formatVessel } from '../../common/helpers/formatVessel.helper';
import { notAvailable } from '../../common/helpers/notAvailable.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';
import { YesOrNo } from '../../common/YesOrNo/YesOrNo';

export const FuelVesselDetailDataSheet = ({ vessel }) => {
    const role = useRole();

    return (
        <DataSheet>
            {!role.isOneCompanyUser() ? (
                <>
                    <DataSheet.Label>{translate('order.company')}</DataSheet.Label>
                    <span>
                        {formatCompanyName({
                            company: vessel.customer,
                            includeType: role.isAdmin(),
                        })}
                    </span>
                </>
            ) : null}
            <DataSheet.Label>{translate('order.vessel')}</DataSheet.Label>
            <span>{formatVessel({ vessel })}</span>

            <DataSheet.Label>{translate('vessel.types')}</DataSheet.Label>
            <span>
                {notAvailable({
                    value: vessel.types?.join(', '),
                })}
            </span>

            <DataSheet.Label>{translate('vessel.services')}</DataSheet.Label>
            <span>
                {notAvailable({
                    value: vessel.services?.join(', '),
                })}
            </span>

            <DataSheet.Label>{translate('vessel.lastedit')}</DataSheet.Label>
            <span>
                {notAvailable({
                    value: formatDate({ date: vessel.dateUpdated }),
                })}
            </span>
            <DataSheet.Label>{translate('company.invoiceVAT')}</DataSheet.Label>
            <span>
                {notAvailable({
                    value: vessel.invoiceVAT,
                })}
            </span>
            <DataSheet.Label>{translate('global.contact.email')}</DataSheet.Label>
            <span>
                {notAvailable({
                    value: vessel.email,
                })}
            </span>
            <DataSheet.Label>{translate('global.address.invoiceaddress')}</DataSheet.Label>
            <span>
                {notAvailable({
                    value: formatAddress({ address: vessel.invoiceAddress }),
                })}
            </span>
            {role.isAdmin() ? (
                <>
                    <DataSheet.Label>{translate('vessel.paying')}</DataSheet.Label>
                    <YesOrNo value={vessel.paying} />
                    <DataSheet.Label>{translate('vessel.directOrderAllowed')}</DataSheet.Label>
                    <YesOrNo value={vessel.directOrderAllowed} />
                </>
            ) : null}
        </DataSheet>
    );
};

FuelVesselDetailDataSheet.propTypes = {
    vessel: PropTypes.object,
};
