import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { FuelEnquiryFormStep } from './FuelEnquiryFormStep';
import { useEnquiryCreation } from './hooks/useEnquiryCreation';

export const FuelEnquiryForm = ({ data, onSubmit }) => {
    const { page } = useEnquiryCreation();

    /*
     * For now it is just important that the value of errors is truthy.
     * e.g. 'Required' does not do anything, but it is helpful for debugging.
     */
    const validate = (values) => {
        const errors = {};

        if (!values.vesselId) {
            errors.vesselId = 'Required';
        }
        if (!values.portId) {
            errors.portId = 'Required';
        }
        if (!values.eta) {
            errors.eta = 'Required';
        }
        if (!values.etd) {
            errors.etd = 'Required';
        }

        if (!!values.eta && !!values.etd) {
            if (new Date(values.eta) >= new Date(values.etd)) {
                errors.etd = 'ETD invalid';
            }
        }

        if (page === 1) {
            return errors;
        }

        values.items?.forEach((item, index) => {
            if (!item.productId) {
                errors[`items.${index}.productId`] = 'Required';
            }

            if (!item.units) {
                errors[`items.${index}.units`] = 'Required';
            } else if (item.units === '0' || String(item.units).endsWith('.')) {
                errors[`items.${index}.units`] = 'Units invalid';
            }
        });

        if (page === 2) {
            return errors;
        }

        return errors;
    };

    return (
        <Formik
            initialValues={data}
            validateOnMount
            validate={validate}
            onSubmit={onSubmit}
            component={FuelEnquiryFormStep}
        />
    );
};

FuelEnquiryForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.object,
};
