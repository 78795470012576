import { translate } from '../../../common/helpers/translate.helper';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { DebouncedSearchTextInput } from '../../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../../common/ui/Box';
import { SupplierGroupsOverviewStateConfig } from '../SupplierGroupsOverviewPage';

export const SupplierGroupSearch = () => {
    const [locationStateUntyped, { setLocationFieldValue }] = useLocationState();
    const locationState = locationStateUntyped as SupplierGroupsOverviewStateConfig;

    const handleChange = (newValue: string) => {
        setLocationFieldValue('searchQuery', newValue ?? '');
        setLocationFieldValue('page', 0);
    };

    return (
        <Box width="300px">
            <DebouncedSearchTextInput
                onChange={handleChange}
                value={locationState.searchQuery}
                placeholder={translate('suppliergroups.searchForSupplierGroup')}
            />
        </Box>
    );
};
