import styled from 'styled-components';
import { variant, width } from 'styled-system';

import { get } from '../../../common/ui/get';
import { ButtonProps } from '../Button';
import { getDefaultEmphasisVariant } from '../util/getDefaultEmphasisVariant';
import { getDestructiveEmphasisVariant } from '../util/getDestructiveEmphasisVariant';

import { ButtonContentWrapper } from './ButtonContentWrapper';

export const StyledButton = styled.button<ButtonProps>`
    appearance: none;
    position: relative;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;

    cursor: pointer;

    color: ${get('colors.foreground.default')};
    font-weight: 500;
    font-size: 14px;

    border-width: 1px;
    border-radius: ${get('radii.1')}px;

    white-space: nowrap;

    ${width};

    ${variant({
        prop: 'size',
        variants: {
            small: {
                fontSize: '12px',
                lineHeight: '16px',
            },
            medium: {
                fontSize: '14px',
                lineHeight: '24px',
            },
            large: {
                fontSize: '16px',
                lineHeight: '32px',
                [`& ${ButtonContentWrapper}`]: {
                    paddingLeft: '4px',
                    paddingRight: '4px',
                },
            },
        },
    })}

    ${(props) => (props.destructive ? getDestructiveEmphasisVariant() : getDefaultEmphasisVariant())}

    &:disabled {
        cursor: not-allowed;

        ${variant({
            prop: 'emphasis',
            variants: {
                high: {
                    background: get('colors.background.disabled'),
                    color: get('colors.foreground.disabled'),
                },
                medium: {
                    borderColor: get('colors.border.subtle'),
                    background: get('colors.white'),
                    color: get('colors.foreground.disabled'),
                },
                low: {
                    color: get('colors.foreground.disabled'),
                    background: get('colors.white'),
                },
                none: {
                    color: get('colors.foreground.disabled'),
                    background: get('colors.white'),
                },
            },
        })}
    }

    // Reset styles in case button is rendered as a link
    &:hover,
    &:focus {
        text-decoration: none;
    }
`;
