import moment from 'moment';
import { useState } from 'react';

import { AverageConsumption } from '../../../../../types/AverageConsumption';
import { LubesConsumptionPreset } from '../../../../../types/LubesConsumptionPresetsRequestMessage';
import { Text } from '../../../../cdl/Text/Text';
import { Tooltip } from '../../../../cdl/Tooltip/Tooltip';
import { formatDateTime } from '../../../../common/helpers/formatDateTime.helper';
import { formatNumber } from '../../../../common/helpers/formatNumber.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useEnums } from '../../../../common/hooks/useEnums';
import { Box } from '../../../../common/ui/Box';

import { AverageConsumptionLineChart } from './AverageConsumptionLineChart';
import { Difference } from './DIfference';
import { QualityIndicator } from './QualityIndicator';

interface AverageConsumptionCategoryProps {
    consumptions: AverageConsumption[];
    preset: LubesConsumptionPreset;
    dateRange: Date[];
}

export const AverageConsumptionCategory = ({ consumptions, preset, dateRange }: AverageConsumptionCategoryProps) => {
    const { getHumanReadableValue } = useEnums();
    const lastConsumptionOfRange = consumptions.at(-1);
    const [activeConsumption, setActiveConsumption] = useState<AverageConsumption | null>(
        lastConsumptionOfRange || null
    );

    const isToday = moment(dateRange[1]).isSame(moment(), 'day');
    const endDate = isToday ? translate('vessel.averageConsumption.today') : moment(dateRange[1]).format('MMM D');

    const handleActiveElementChange = (averageConsumption?: AverageConsumption) => {
        if (averageConsumption) {
            setActiveConsumption(averageConsumption);
        } else {
            setActiveConsumption(lastConsumptionOfRange || null);
        }
    };

    const averageConsumption = preset.excludePortCalls
        ? activeConsumption?.averageConsumptionExclDaysAtPort
        : activeConsumption?.averageConsumption;

    return (
        <Box display="grid" gap={4} gridTemplateColumns="minmax(0, 1fr)">
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" gap={4} alignItems="center">
                    <Text fontWeight="semiBold" variant="body">
                        {getHumanReadableValue('vesselTankCategory', preset.vesselTankCategory)}
                    </Text>
                    {activeConsumption ? (
                        <QualityIndicator qualityCategory={activeConsumption.qualityCategory} />
                    ) : null}
                </Box>
                {preset.fixedAverageDailyConsumption && averageConsumption ? (
                    <Tooltip
                        maxWidth="200px"
                        showArrow
                        label={translate('vessel.averageConsumption.differenceTooltip')}
                    >
                        <div>
                            <Difference
                                fixedAverageConsumption={preset.fixedAverageDailyConsumption}
                                dynamicAverageConsumption={averageConsumption}
                            />
                        </div>
                    </Tooltip>
                ) : null}
            </Box>

            <Box display="flex" justifyContent="space-between">
                <div>
                    {preset.fixedAverageDailyConsumption ? (
                        <Text variant="body" color="foreground.muted">
                            {preset.fixedAverageDailyConsumption}L
                        </Text>
                    ) : null}
                </div>

                <div>
                    {activeConsumption ? (
                        <Text variant="body" color="accent.foreground" fontWeight="semiBold">
                            {formatNumber({
                                number: averageConsumption,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                            L
                        </Text>
                    ) : null}
                </div>
            </Box>

            <AverageConsumptionLineChart
                consumptions={consumptions}
                preset={preset}
                dateRange={dateRange}
                onActiveElementChange={handleActiveElementChange}
            />

            <Box display="flex" justifyContent="space-between">
                <Text variant="extraSmall" color="foreground.subtle">
                    {formatDateTime({ date: dateRange[0].toISOString(), format: 'MMM D' })}
                </Text>
                <Text variant="extraSmall" color="foreground.subtle">
                    {endDate}
                </Text>
            </Box>
        </Box>
    );
};
