import styled from 'styled-components';

import { NavigationLink } from '../../Link/NavigationLink';
import { get } from '../../ui/get';

export const SidebarNavigationItem = styled(NavigationLink)`
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: ${get('space.4')}px;
    padding-right: ${get('space.4')}px;
    color: ${get('colors.foreground.muted')};
    font-size: ${get('fontSizes.2')}px;
    font-weight: ${get('fontWeights.medium')};
    line-height: 24px;
    border-radius: ${get('radii.2')}px;

    &:before {
        content: '';
        opacity: 0;
        position: absolute;
        left: -16px;
        top: 8px;
        width: 2px;
        height: 16px;
        background-color: ${get('colors.accent.foreground')};
        border-radius: ${get('radii.2')}px;
        transform: scale(0.2);
        transition:
            opacity 75ms ease-in,
            transform 75ms ease-in;
    }

    &:hover,
    &:focus {
        color: ${get('colors.foreground.default')};
        text-decoration: none;
        background-color: ${get('colors.background.inset')};
    }

    &.active {
        color: ${get('colors.accent.foreground')};

        &:before {
            opacity: 1;
            transform: scale(1);
        }

        &:hover {
            background-color: ${get('colors.accent.background')};
        }
    }
`;
