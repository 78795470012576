import styled from 'styled-components';
import { variant } from 'styled-system';

import { get } from '../../../../common/ui/get';

interface MetaInformationProps {
    variant: 'accent' | 'positive' | 'negative' | 'neutral' | 'attention';
}

export const MetaInformation = styled.div<MetaInformationProps>`
    display: flex;
    align-items: center;
    column-gap: ${get('space.2')}px;
    border-radius: ${get('radii.1')}px;
    padding: ${get('space.2')}px ${get('space.4')}px;

    ${variant({
        prop: 'variant',
        variants: {
            accent: {
                color: get('colors.accent.tint'),
                background: get('colors.accent.background'),
            },
            positive: {
                color: get('colors.positive.tint'),
                background: get('colors.positive.background'),
            },
            negative: {
                color: get('colors.negative.tint'),
                background: get('colors.negative.background'),
            },
            neutral: {
                color: get('colors.foreground.default'),
                background: get('colors.background.inset'),
            },
            attention: {
                color: get('colors.attention.tint'),
                background: get('colors.attention.background'),
            },
        },
    })}
`;
