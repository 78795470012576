import { Error } from '../../common/Error/Error';
import { useProductContext } from '../../common/hooks/useProductContext';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../common/LocationState/useLocationState';
import { useAdminStatistics } from '../hooks/useAdminStatistics';

import { AdminStatisticsContent } from './AdminStatisticsContent';
import { AdminStatisticsTabSwitch } from './AdminStatisticsTabSwitch';
import { NoStatistics } from './NoStatistics';
import { StatisticsCenteredFrame } from './StatisticsCenteredFrame';
import { StatisticsStateConfig } from './StatisticsState';

export const AdminStatistics = () => {
    const { context } = useProductContext();
    const [locationStateUntyped] = useLocationState();
    const locationState = locationStateUntyped as StatisticsStateConfig;

    const adminStatisticsQuery = useAdminStatistics({
        from: locationState.from,
        to: locationState.to,
        customerIds: locationState.customerIds,
        supplierIds: locationState.supplierIds,
        type: context,
    });

    if (adminStatisticsQuery.isPending) {
        return (
            <div>
                <AdminStatisticsTabSwitch />
                <StatisticsCenteredFrame>
                    <LoadingIndicator />
                </StatisticsCenteredFrame>
            </div>
        );
    }

    if (adminStatisticsQuery.isError) {
        return (
            <StatisticsCenteredFrame>
                <Error />
            </StatisticsCenteredFrame>
        );
    }

    if (adminStatisticsQuery.data?.general.completedCount === 0) {
        return (
            <div>
                <AdminStatisticsTabSwitch />
                <StatisticsCenteredFrame>
                    <NoStatistics />
                </StatisticsCenteredFrame>
            </div>
        );
    }

    return (
        <div>
            <AdminStatisticsTabSwitch />
            <AdminStatisticsContent statistics={adminStatisticsQuery.data} />
        </div>
    );
};
