import PropTypes from 'prop-types';

import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Flex } from '../../../common/ui/Flex';
import { usePlattsCredentials } from '../../../company/customer/detail/integrations/hooks/usePlattsCredentials';
import { ContractProductOrderCard } from '../../fuel/ContractProductOrderCard';
import { useContractProducts } from '../../fuel/hooks/useContractProducts';

import { EmptyState } from './EmptyState';

export const AvailableContractProducts = ({ order }) => {
    const role = useRole();

    const categoriesInOrder = new Set(order.items.map((item) => item.product.group));
    const sulphurContentsInOrder = new Set(order.items.map((item) => item.product.sulphurContent));

    const contractProductsQuery = useContractProducts({
        eta: order.eta,
        etd: order.etd,
        portId: order.portId,
        categories: [...categoriesInOrder],
        sulphurContents: [...sulphurContentsInOrder],
        groupId: role.isAdmin() ? order.customer.customerGroupId : null,
    });
    const plattsCredentialsQuery = usePlattsCredentials(order.customerId);

    if (contractProductsQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (contractProductsQuery.data?.contracts.length <= 0) {
        return <EmptyState>{translate('fuelEnquiry.second.contractProducts.emptyState')}</EmptyState>;
    }

    return (
        <Flex flexWrap="wrap">
            {contractProductsQuery.data?.contracts.map((product, index) => (
                <ContractProductOrderCard
                    key={index}
                    order={order}
                    product={product}
                    showCreateOrderButton={!role.isAdmin()}
                    hasPlattsCredentials={!!plattsCredentialsQuery.data}
                />
            ))}
        </Flex>
    );
};

AvailableContractProducts.propTypes = {
    order: PropTypes.object,
};
