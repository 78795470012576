import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createBunkerStopEnquiry } from '../../common/api/clients/bunkerPlan.api';
import { queryKeys } from '../../common/api/queryKeys';

export const useBunkerStopEnquiryCreation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createBunkerStopEnquiry,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.bunkerStops() });
        },
    });
};
