import PropTypes from 'prop-types';

import { Text } from '../../../cdl/Text/Text.tsx';
import { AgentImportedLabel } from '../../../common/AgentImportedLabel/AgentImportedLabel';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex.tsx';

export const AgentSelectSelectedItem = ({ agent }) => {
    return (
        <Flex alignItems="center">
            <Text size={14} color="dark-blue.0">
                {agent.name}
            </Text>
            {agent.source ? (
                <Box marginLeft={4}>
                    <AgentImportedLabel />
                </Box>
            ) : null}
        </Flex>
    );
};

AgentSelectSelectedItem.propTypes = {
    agent: PropTypes.object.isRequired,
};
