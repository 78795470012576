import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { DemandOverviewPage } from './DemandOverviewPage';

const DemandOverviewPageComponent = (props: any) => {
    return (
        <AppProviders>
            <DemandOverviewPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(DemandOverviewPageComponent, 'reactDemandOverview');
