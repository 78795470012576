import Skeleton from 'react-loading-skeleton';

import { Box } from '../../../common/ui/Box';

const SkeletonRow = () => (
    <Box
        borderColor="border.subtle"
        borderStyle="solid"
        borderWidth="1px"
        height="48px"
        display="grid"
        gridTemplateColumns="14px 2fr 2fr 2fr 2fr 1fr 0.5fr 1fr 0.5fr 80px 2fr"
        gap={5}
        paddingX={5}
        alignItems="center"
        justifyContent="space-between"
    >
        <Skeleton width="14px" />
        <Skeleton width="90%" />
        <Skeleton width="90%" />
        <Skeleton width="90%" />
        <Skeleton width="90%" />
        <Skeleton width="90%" />
        <Skeleton width="90%" />
        <Skeleton width="90%" />
        <Skeleton width="90%" />
        <Box display="flex" gap={5} justifySelf="center">
            <Skeleton width="14px" />
            <Skeleton width="14px" />
        </Box>
        <Skeleton width="90%" />
    </Box>
);

export const SkeletonSolutionDetails = () => {
    return (
        <Box display="flex" flexDirection="column" gap={5}>
            <SkeletonRow />
            <SkeletonRow />
            <SkeletonRow />
            <SkeletonRow />
            <SkeletonRow />
            <SkeletonRow />
        </Box>
    );
};
