import { ReactNode, useState } from 'react';

import { CompareSelectionStateContext } from './CompareSelectionStateContext';

export interface CompareSelectionState {
    portId: string | null;
    supplierIds: string[];
}

interface CompareSelectionStateContextProviderProps {
    children: ReactNode;
}

export const CompareSelectionStateContextProvider = ({ children }: CompareSelectionStateContextProviderProps) => {
    const [selection, setSelection] = useState<CompareSelectionState>({
        portId: null,
        supplierIds: [],
    });

    const contextValue = {
        selection,
        setSelection,
    };

    return (
        <CompareSelectionStateContext.Provider value={contextValue}>{children}</CompareSelectionStateContext.Provider>
    );
};
