import { Loader } from '@googlemaps/js-api-loader';
import { Combobox, ComboboxInput, ComboboxList, ComboboxOption, ComboboxPopover } from '@reach/combobox';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { border, space } from 'styled-system';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';

import AppSettings from '../../../constants';
import { getAddress } from '../../helpers/placesAutocomplete.utils';
import { get as themeGet } from '../../ui/get';
import { useFormContext } from '../Form/useFormContext';
import { Label } from '../Label/Label';
import { StyledInput as Input } from '../TextInput/Input';

const SuggestionBox = styled.div`
    position: relative;
`;

const SuggestionList = styled.ul`
    ${border};

    background-color: white;
    list-style-type: none;
    position: absolute;
    top: 100%;
    z-index: 1;
`;

const SuggestionListItem = styled.li`
    ${space}

    &[aria-selected='true'] {
        background: ${themeGet('colors.clear-blue.5')};
    }
`;

const loader = new Loader({
    apiKey: AppSettings.googleApiURLKey,
    id: 'closelink',
    libraries: ['places'],
});

/**
 * @deprecated
 **/

export const FormInputPlaces = ({ dataPaths, input, label, required }) => {
    const { data, updateDataPath, onChange } = useFormContext();
    const value = get(data, dataPaths.street);
    const [isDirty, setIsDirty] = useState(false);

    // usePlacesAutocomplete:
    // https://www.npmjs.com/package/use-places-autocomplete#api
    const { suggestions, setValue, clearSuggestions, init } = usePlacesAutocomplete({
        debounce: 300,
        initOnMount: false,
    });

    useEffect(() => {
        loader.load().then(() => {
            init();
        });
    }, [init]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        const newData = updateDataPath(dataPaths.street, newValue);

        onChange({ data: newData });

        updateDataPath(dataPaths.street, newValue);
        setValue(newValue);
    };

    const handleSelect = async (description) => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);

        // in order to autocomplete the other fields too, we need to make an
        // additional API call and parse its response
        const result = await getGeocode({
            address: description,
        });

        const address = getAddress(result[0]);

        ['street', 'streetNumber', 'city', 'zipcode', 'country'].forEach((field) => {
            if (dataPaths[field]) {
                updateDataPath(dataPaths[field], address[field] || '');
            }
        });

        clearSuggestions();
    };

    return (
        <Label required={required} label={label}>
            <Combobox onSelect={handleSelect} as={SuggestionBox}>
                <ComboboxInput
                    as={Input}
                    value={value}
                    paddingX={5}
                    paddingY={4}
                    border={1}
                    borderRadius={1}
                    {...input}
                    onChange={handleChange}
                    onBlur={() => setIsDirty(true)}
                    className={isDirty ? 'dirty' : ''}
                />

                <ComboboxPopover portal={false}>
                    {suggestions.data ? (
                        <SuggestionList border={1} as={ComboboxList}>
                            {suggestions.data.slice(0, 10).map((suggestion) => (
                                <SuggestionListItem
                                    padding={4}
                                    as={ComboboxOption}
                                    key={suggestion.place_id}
                                    value={suggestion.description}
                                />
                            ))}
                        </SuggestionList>
                    ) : null}
                </ComboboxPopover>
            </Combobox>
        </Label>
    );
};

FormInputPlaces.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    required: PropTypes.bool,
    dataPaths: PropTypes.shape({
        street: PropTypes.string,
        streetNumber: PropTypes.string,
        zipcode: PropTypes.string,
        city: PropTypes.string,
    }),
};
