import { ReactNode } from 'react';

import { ReportsMessage } from '../../../types/SpecificReportMessage';
import { BaseStatisticsMessage } from '../../../types/StatisticsMessage';
import { DynamicFilterContainer, Filter } from '../../cdl/Filter/DynamicFilterContainer/DynamicFilterContainer';
import { FilterMultiSelectFull } from '../../cdl/Filter/FilterMultiSelectFull/FilterMultiSelectFull';
import { translate } from '../../common/helpers/translate.helper';

interface ProductsTabFilterProps {
    allEntries: BaseStatisticsMessage['product'];
    children: (filteredEntries: BaseStatisticsMessage['product']) => ReactNode;
}

export const ProductsTabFilter = ({ children, allEntries }: ProductsTabFilterProps) => {
    const productFilter: Filter<string, ReportsMessage> = {
        filterFn: (values, entry) => {
            return values.includes(entry.context.id);
        },
        component: (values, setValues) => {
            const availableOptions = Array.from(new Set(allEntries.map((entry) => entry.context.id))).map(
                (productId) => ({
                    label: allEntries.find((entry) => entry.context.id === productId)?.context.name ?? '',
                    value: productId,
                })
            );

            return (
                <FilterMultiSelectFull
                    options={availableOptions}
                    values={values}
                    onChange={setValues}
                    label={translate('reporting.filter.product.title')}
                    filterByLabel={translate('reporting.filter.product.filterByProduct')}
                    filterPlaceholder={translate('reporting.filter.product.searchForProduct')}
                />
            );
        },
    };

    return (
        <DynamicFilterContainer allEntries={allEntries} filters={{ product: productFilter }}>
            {children}
        </DynamicFilterContainer>
    );
};
