import styled from 'styled-components';

import { get } from '../../../common/ui/get';

import { CoreInputProps } from './CoreInput';

interface TrailingVisualWrapperProps extends Pick<CoreInputProps, 'disabled'> {}

export const TrailingVisualWrapper = styled.div<TrailingVisualWrapperProps>`
    padding-right: 10px;
    color: ${(props) => (props.disabled ? get('colors.foreground.disabled') : get('colors.foreground.default'))};
`;
