import { ReactNode } from 'react';

import { LocationStateProvider } from '../common/LocationState/LocationStateProvider';

interface KeyPortOverviewStateProps {
    children?: ReactNode;
}

export const KeyPortOverviewState = ({ children }: KeyPortOverviewStateProps) => {
    const stateConfig = {
        searchQuery: '',
        customerGroupIds: [],
        page: 0,
    };

    return <LocationStateProvider stateConfig={stateConfig}>{children}</LocationStateProvider>;
};
