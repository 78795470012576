import { useRole } from './useRole';

export function useStartUserlikeChat(): () => void {
    const role = useRole();

    return () => {
        try {
            if (role !== undefined) {
                const userData = {
                    user: {
                        name: role.user.firstname + ' ' + role.user.lastname,
                        email: role.user.email,
                        additional02: role.group.name,
                    },
                };

                userlike.setData(userData);
            }

            userlike.userlikeStartChat();
        } catch {
            window.open('mailto:hello@closelink.com');
        }
    };
}
