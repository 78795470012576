import { useEffect } from 'react';

import { translate } from '../helpers/translate.helper';

export const useDocumentTitle = (title?: string, deps: any[] = []) => {
    useEffect(() => {
        if (title) {
            document.title = translate('global.documentTitle', { title });
        }
    }, [title, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps
};
