import { useMutation } from '@tanstack/react-query';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { createOrder, CreateOrderRequest } from '../../../common/api/clients/order.api';
import { translate } from '../../../common/helpers/translate.helper';
import { useNavigation } from '../../../common/hooks/useNavigation';

import { useSelectedOfferForFuelOrder } from './useContractOrder';

export const useCreateContractOrder = () => {
    const { addErrorToast } = useToasts();
    const { navigate } = useNavigation();

    const fetchSelectedOfferId = useSelectedOfferForFuelOrder();

    const createOrderMutation = useMutation({ mutationFn: createOrder });

    return (order: CreateOrderRequest) => {
        return createOrderMutation.mutateAsync(order, {
            onSuccess: async (order: any) => {
                try {
                    const selectedOfferId = await fetchSelectedOfferId(order.id);

                    navigate('base.fuel-offerlist-offer', {
                        id: selectedOfferId,
                    });
                } catch {
                    navigate('base.fuel-orderlist-order', {
                        id: order.id,
                    });
                }
            },
            onError: () => {
                addErrorToast(translate('fuelEnquiry.toast.createOrder.error'));
            },
        });
    };
};
