import styled from 'styled-components';

export const LinkWrapper = styled.a`
    &:hover,
    &:active,
    &:focus,
    &:visited {
        text-decoration: none;
    }
`;
