UserService.$inject = ['UserResource'];

function UserService(UserResource) {
    return {
        get: function () {
            return UserResource.get();
        },
        update: function (user) {
            return user.$update();
        },
    };
}

export default {
    name: 'UserService',
    fn: UserService,
};
