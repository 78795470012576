import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { FuelDashboard as OriginalFuelDashboard } from './FuelDashboard';

const FuelDashboard = (props) => (
    <AppProviders>
        <OriginalFuelDashboard {...props} />
    </AppProviders>
);

export default createDirectiveFromReactComponent(FuelDashboard, 'reactFuelDashboard');
