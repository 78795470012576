import axios, { AxiosProgressEvent, AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import storage from 'local-storage-fallback';

import { CounterOffer } from '../../../../types/CounterOffer';
import { FileManagementResponse } from '../../../../types/FileManagementResponse';
import AppSettings from '../../../constants';
import { Offer } from '../../../offer/model/offer.model';
import { baseAxiosInstance } from '../instances/BaseAxiosInstance';

export const ANONYMOUS_QUOTATION_AUTH_TOKEN_KEY = 'cl-anonymous-quotation-token';

const authorizedAnonymousQuotationAxiosInstance = axios.create({
    baseURL: AppSettings.routes.api,
});

function injectAnonymousQuotationAuthHeader(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
    const accessToken = storage.getItem(ANONYMOUS_QUOTATION_AUTH_TOKEN_KEY);

    if (!accessToken) {
        return config;
    }

    return {
        ...config,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            ...config.headers,
        } as AxiosRequestHeaders,
    };
}

authorizedAnonymousQuotationAxiosInstance.interceptors.request.use(injectAnonymousQuotationAuthHeader);

interface UpdateOfferParams {
    anonymousQuotationId: string;
    offer: unknown;
}

export const updateOffer = async ({ anonymousQuotationId, offer }: UpdateOfferParams): Promise<any> => {
    const response = await authorizedAnonymousQuotationAxiosInstance.put(
        `/v1/anonymous-quotations/${anonymousQuotationId}/offer`,
        offer
    );

    return response.data;
};

interface CreateMessageParams {
    anonymousQuotationId: string;
    message: string;
}

export const createMessage = async ({ anonymousQuotationId, message }: CreateMessageParams): Promise<any> => {
    const response = await authorizedAnonymousQuotationAxiosInstance.post(
        `/v1/anonymous-quotations/${anonymousQuotationId}/message`,
        { message }
    );

    return response.data;
};

interface GetProductsParams {
    anonymousQuotationId: string;
}

export const getProducts = async ({ anonymousQuotationId }: GetProductsParams): Promise<any> => {
    const response = await authorizedAnonymousQuotationAxiosInstance.get(
        `/v1/anonymous-quotations/${anonymousQuotationId}/fuel-products`
    );

    return response.data;
};

export const uploadFile = async (
    anonymousQuotationId: string,
    file: File,
    onUploadProgress: (event: AxiosProgressEvent) => void
): Promise<FileManagementResponse> => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await authorizedAnonymousQuotationAxiosInstance.post(
        `/v1/anonymous-quotations/${anonymousQuotationId}/offer/files`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
        }
    );

    return response.data;
};

export const downloadFile = (anonymousQuotationId: string, fileId: string): Promise<AxiosResponse<Blob>> => {
    return authorizedAnonymousQuotationAxiosInstance.get(
        `/v1/anonymous-quotations/${anonymousQuotationId}/offer/files/${fileId}/download`,
        {
            responseType: 'blob',
        }
    );
};

export const deleteFile = async (anonymousQuotationId: string, fileIds: string[]): Promise<void> => {
    const params = new URLSearchParams();

    if (fileIds.length) {
        fileIds.forEach((id) => params.append('ids', id));
    }

    await authorizedAnonymousQuotationAxiosInstance.delete(
        `/v1/anonymous-quotations/${anonymousQuotationId}/offer/files?${params.toString()}`
    );
};

export const getAnonymousQuotation = (anonymousQuotationId: string, authorizationToken: string): Promise<any> => {
    return baseAxiosInstance
        .get(`/gateway/v1/anonymous-quotations/${anonymousQuotationId}`, {
            headers: {
                Authorization: `Bearer ${authorizationToken}`,
            },
        })
        .then((response) => {
            return {
                ...response.data,
                offer: new Offer(response.data.offer),
            };
        });
};

interface UpdateCounterOfferParams {
    anonymousQuotationId: string;
    counterOffer: CounterOffer;
}

export const updateCounterOffer = async ({
    anonymousQuotationId,
    counterOffer,
}: UpdateCounterOfferParams): Promise<void> => {
    await authorizedAnonymousQuotationAxiosInstance.put(
        `/v1/anonymous-quotations/${anonymousQuotationId}/counter-offer/${counterOffer.id}`,
        counterOffer
    );
};
