import css from '@styled-system/css';
import styled from 'styled-components';

import { IconSearch } from '../icons/cdl/Search';

const Button = styled.button(
    css({
        borderRadius: '100%',
        border: 'none',
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        cursor: 'pointer',
        background: 'transparent',
        color: 'dark-blue.2',
        '&:hover': {
            color: 'clear-blue.0',
            textDecoration: 'none',
            backgroundColor: 'dark-blue.6',
        },
    })
);

export const GlobalSearchButton = () => {
    return (
        <Button>
            <IconSearch />
        </Button>
    );
};
