import PropTypes from 'prop-types';
import { useState } from 'react';

import { DrawerFormLayout } from '../../cdl/Drawer/DrawerFormLayout';
import { Form } from '../../common/form-elements/Form/Form';
import { FormFuelMoneyInput } from '../../common/form-elements/NumberInputs/FormFuelMoneyInput';
import { FormTextInput } from '../../common/form-elements/TextInput/TextInput';
import { translate } from '../../common/helpers/translate.helper';

const isNotEmpty = (value) => {
    if (!value) {
        return false;
    }

    if (typeof value === 'string' && value === '') {
        return false;
    }

    return true;
};

export const WaiverForm = ({ onSubmit, data }) => {
    const [formState, setFormState] = useState(data);
    const [isValid, setIsValid] = useState(false);

    const validateForm = (data) => {
        const areRequiredFieldsValid = ['description', 'amount'].every((name) => isNotEmpty(data[name]));
        setIsValid(areRequiredFieldsValid && Number(data.amount) !== 0);
    };

    return (
        <Form
            onSubmit={({ event, data }) => {
                event.preventDefault();
                onSubmit(data);
            }}
            data={formState}
            onChange={({ data: newData }) => {
                setFormState(newData);
                validateForm(newData);
            }}
            height="100%"
        >
            <DrawerFormLayout title={translate('waiverForm.title')} isSubmitButtonDisabled={!isValid}>
                <FormTextInput
                    dataPath="description"
                    placeholder={translate('waiverForm.placeholders.description')}
                    label={translate('waiverForm.labels.description')}
                    required
                />

                <FormFuelMoneyInput
                    dataPath="amount"
                    required
                    placeholder={translate('waiverForm.placeholders.amount')}
                    label={translate('waiverForm.labels.amount')}
                    allowNegativeValue
                />
            </DrawerFormLayout>
        </Form>
    );
};

WaiverForm.propTypes = {
    onSubmit: PropTypes.func,
    data: PropTypes.object,
};
