import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
    CL_CLEAR_BLUE_100,
    CL_CLEAR_BLUE_20,
    CL_CLEAR_BLUE_40,
    CL_CLEAR_BLUE_60,
    CL_DARK_BLUE_100,
    CONFIRMING_GREEN_10,
    CONFIRMING_GREEN_100,
    SIGNAL_RED_100,
    SIGNAL_RED_20,
} from '../colors';
import { ConditionalRenderer } from '../ConditionalRenderer/ConditionalRenderer';
import { IconInfoCircle } from '../icons/cdl/InfoCircle';

import { StatusBarType } from './StatusBarType';

const typeStyles = (props) => {
    switch (props.type) {
        case StatusBarType.DANGER: {
            return `
                border: 1px solid ${SIGNAL_RED_100};
                background-color: ${SIGNAL_RED_20};
            `;
        }
        case StatusBarType.SUCCESS: {
            return `
                border: 1px solid ${CONFIRMING_GREEN_100};
                background-color: ${CONFIRMING_GREEN_10};
            `;
        }
        case StatusBarType.INFO: {
            return `
                border: 1px solid ${CL_CLEAR_BLUE_100};
                background-color: ${CL_CLEAR_BLUE_20};
            `;
        }
        case StatusBarType.WHITE: {
            return `
                background-color: white;
            `;
        }
        default:
            return null;
    }
};

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 18px 24px 18px 0;
    border-radius: 4px;

    ${typeStyles};
`;

const IconWrapper = styled.div`
    position: absolute;
    height: 24px;
    color: ${CL_DARK_BLUE_100};

    margin: auto;
    top: 0;
    left: 24px;
    bottom: 0;
`;

const RightElementWrapper = styled.div`
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    div {
        float: right;
    }

    button[disabled] {
        cursor: not-allowed;
        color: ${CL_CLEAR_BLUE_60};
        background-color: ${CL_CLEAR_BLUE_40} !important;
        border: none;
        box-shadow: none;
    }
`;

const HeadlinesWrapper = styled.div`
    margin-left: 40px;
    padding-left: 24px;
`;

const Headline = styled.h3`
    font-size: 14px;
    font-weight: bold;

    line-height: 1.5;
    margin: 0;
`;

const SubHeadline = styled.h4`
    font-size: 14px;
    font-weight: 400;

    line-height: 1.5;
    margin: 0;
`;

/**
 * @deprecated use CDL StatusBar instead
 */

export const StatusBar = (props) => {
    const getClassNameForLeftArea = (rightElement) => {
        let className = 'p-a-0';
        if (rightElement) {
            className += ' col-xs-8';
        }

        return className;
    };

    const icon = props.icon ?? <IconInfoCircle />;
    const className = props.className ?? '';

    return (
        <Wrapper className={className} type={props.type}>
            <div className="col-xs-12 p-a-0">
                <div className={getClassNameForLeftArea(props.rightElement)}>
                    <IconWrapper>{icon}</IconWrapper>

                    <HeadlinesWrapper>
                        <Headline>{props.headline}</Headline>

                        <SubHeadline>{props.subHeadline}</SubHeadline>
                    </HeadlinesWrapper>
                </div>

                <ConditionalRenderer condition={!!props.rightElement}>
                    <RightElementWrapper className="col-xs-4 p-a-0">
                        <div>{props.rightElement}</div>
                    </RightElementWrapper>
                </ConditionalRenderer>
            </div>
        </Wrapper>
    );
};

StatusBar.propTypes = {
    type: PropTypes.oneOf(Object.values(StatusBarType)),
    headline: PropTypes.string,
    subHeadline: PropTypes.string,
    rightElement: PropTypes.node,
    icon: PropTypes.node,
    className: PropTypes.string,
};
