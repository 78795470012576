import { useOfferPaginationTotals } from '../useOfferPaginationTotals';

import { lubesTabToOfferStateMap } from './lubesTabToOfferStateMap';

export const useLubesOfferPaginationTotals = ({ searchQuery, supplierIds, customerIds }) => {
    return useOfferPaginationTotals({
        searchQuery,
        supplierIds,
        customerIds,
        type: 'LUBES',
        offerTabs: ['enquired', 'ordered', 'acknowledged', 'confirmed', 'delivered', 'canceled', 'expired'],
        tapToOfferStateMap: lubesTabToOfferStateMap,
    });
};
