import { MoneyMessage } from '../../../../../../../../types/MoneyMessage';
import { formatFuelMoney } from '../../../../../../../common/helpers/formatFuelMoney.helper';

interface MoneyLabelProps {
    price?: MoneyMessage;
}

export const MoneyLabel = ({ price }: MoneyLabelProps) => {
    if (price === undefined || price.value <= 0) {
        return <>-</>;
    }

    return <>{formatFuelMoney(price, false)}</>;
};
