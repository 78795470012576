import styled from 'styled-components';
import { variant } from 'styled-system';

import { get } from '../../../common/ui/get';
import { ProgressBarProps } from '../ProgressBar';

interface BarContainerProps {
    variant: ProgressBarProps['variant'];
}

export const BarContainer = styled.div<BarContainerProps>`
    position: relative;
    height: 4px;
    border-radius: 2px;

    ${variant({
        prop: 'variant',
        variants: {
            neutral: {
                backgroundColor: get('colors.background.inset'),
            },
            accent: {
                backgroundColor: get('colors.accent.background'),
            },
            attention: {
                backgroundColor: get('colors.attention.background'),
            },
            negative: {
                backgroundColor: get('colors.negative.background'),
            },
        },
    })}
`;
