import PropTypes from 'prop-types';

import { Text } from '../../cdl/Text/Text';
import { Box } from '../ui/Box';

export const DoubleLineOption = ({ headline, subline, innerProps, getStyles, className, ...props }) => {
    return (
        <Box {...innerProps} style={getStyles('option', props)} className={className}>
            <Text weight="semi">{headline}</Text>

            <Box>
                <Text color="dark-blue.2" size={12}>
                    {subline}
                </Text>
            </Box>
        </Box>
    );
};

DoubleLineOption.propTypes = {
    headline: PropTypes.node,
    subline: PropTypes.node,
    className: PropTypes.string,
    innerProps: PropTypes.object,
    getStyles: PropTypes.func,
};
