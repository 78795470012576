import { Formik, FormikErrors, FormikProps } from 'formik';

import { Modal } from '../../../../cdl/Modal/Modal';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { ReceiverType, ShareOfferRequestMessage } from '../../../../common/api/clients/export.api';
import { translate } from '../../../../common/helpers/translate.helper';
import { OfferModel } from '../../../model/OfferModel';
import { useShareOffer } from '../hooks/useShareOffer';

import { FormikShareOrderFormContent } from './FormikShareOrderFormContent';

export interface FormikShareOrderReceiversState {
    sendToVessel: boolean;
    vesselEmailAddress: string;
    sendToAgent: boolean;
    sendToSurveyor: boolean;
    sendToOthers: boolean;
    othersEmailAddresses: string[];
    copyToSelf: boolean;
}

interface ShareOrderPopupProps {
    isOpen: boolean;
    onSuccess: (vesselEmailAddress?: string) => void;
    onDismiss: () => void;
    offer: OfferModel;
}

export const ShareOrderPopup = ({ isOpen, onDismiss, onSuccess, offer }: ShareOrderPopupProps) => {
    const shareOfferMutation = useShareOffer();
    const { addErrorToast, addToast } = useToasts();

    const formikInitialValues: FormikShareOrderReceiversState = {
        sendToVessel: true,
        vesselEmailAddress: '',
        sendToAgent: false,
        sendToSurveyor: false,
        sendToOthers: false,
        othersEmailAddresses: [],
        copyToSelf: true,
    };

    const validate = (values: FormikShareOrderReceiversState) => {
        const errors: FormikErrors<FormikShareOrderReceiversState> = {};

        if (values.sendToOthers && !values.othersEmailAddresses.length) {
            errors.othersEmailAddresses = translate('shareOrderPopup.othersEmailAddressError');
        }

        if (values.sendToVessel && !offer.vessel.email && !values.vesselEmailAddress) {
            errors.vesselEmailAddress = translate('shareOrderPopup.emailAddressError');
        }

        return errors;
    };

    const handleSubmit = async (values: FormikShareOrderReceiversState) => {
        const request: ShareOfferRequestMessage = {
            receiverEmailAddresses: [],
            receiverTypes: [],
            copyToSelf: values.copyToSelf,
        };

        if (values.sendToVessel) {
            if (offer.vessel.email) {
                request.receiverEmailAddresses.push(offer.vessel.email);
            } else {
                request.receiverEmailAddresses.push(values.vesselEmailAddress);
            }

            request.receiverTypes.push(ReceiverType.VESSEL);
        }

        if (values.sendToAgent && offer.agentInformation?.contact?.emailAddress) {
            request.receiverEmailAddresses.push(offer.agentInformation.contact.emailAddress);

            request.receiverTypes.push(ReceiverType.AGENT);
        }

        if (values.sendToSurveyor && offer.surveyor?.emailAddress) {
            request.receiverEmailAddresses.push(offer.surveyor?.emailAddress);

            request.receiverTypes.push(ReceiverType.SURVEYOR);
        }

        if (values.sendToOthers) {
            request.receiverEmailAddresses.push(...values.othersEmailAddresses);

            request.receiverTypes.push(ReceiverType.OTHERS);
        }

        await shareOfferMutation.mutateAsync(
            { offerId: offer.id, shareOfferRequestMessage: request },
            {
                onSuccess: () => {
                    addToast(translate('shareOrderPopup.successToast'));
                    if (!offer.vessel.email && values.vesselEmailAddress) {
                        onSuccess(values.vesselEmailAddress);
                    } else {
                        onSuccess();
                    }
                },
                onError: () => {
                    addErrorToast(translate('global.genericToastError'));
                },
            }
        );
    };

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} scrollable={false}>
            <Formik
                initialValues={formikInitialValues}
                validate={validate}
                onSubmit={handleSubmit}
                component={(formikProps: FormikProps<FormikShareOrderReceiversState>) => (
                    <FormikShareOrderFormContent offer={offer} onDismiss={onDismiss} {...formikProps} />
                )}
            />
        </Modal>
    );
};
