import { useField } from 'formik';

import { Text } from '../../../../cdl/Text/Text';
import { FormikFormattedNumberInput } from '../../../FormattedNumberInput/FormikFormattedNumberInput';
import { formatNumber } from '../../../helpers/formatNumber.helper';

interface UnitsDisplayProps {
    name: string;
    editable: boolean;
    itemDeleted: boolean;
    index: number;
}

export const UnitsDisplay = (props: UnitsDisplayProps) => {
    const [field, meta] = useField(`${props.name}.units`);

    if (props.editable === false) {
        const units = field.value
            ? formatNumber({
                  number: field.value,
              })
            : '-';

        return <Text variant="body">{units}</Text>;
    }

    return (
        <FormikFormattedNumberInput
            name={`${props.name}.units`}
            negative={meta.touched && !!meta.error}
            disabled={props.itemDeleted}
            allowDecimals={false}
            allowNegativeValue={false}
        />
    );
};
