import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../cdl/Toast/useToasts';
import { createAgent } from '../common/api/clients/agent.api';
import { queries } from '../common/api/queryKeys/queries';
import { translate } from '../common/helpers/translate.helper';

export const useAgentCreate = (onSuccess) => {
    const queryClient = useQueryClient();
    const { addToast, addErrorToast } = useToasts();

    return useMutation({
        mutationFn: createAgent,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: queries.agents._def });
            onSuccess?.(data);

            addToast(translate('createAgentForm.toast.success'));
        },
        onError: () => {
            addErrorToast(translate('createAgentForm.toast.error'));
        },
    });
};
