import { ConsumptionCalculationBase } from './ConumptionCalculationBase';
import { ProductGroup } from './ProductGroup';
import { SulphurContent } from './SulphurContent';

export enum CategoryState {
    IN_REACH = 'IN_REACH',
    SAFETY_RESERVE = 'SAFETY_RESERVE',
    OUT_OF_REACH = 'OUT_OF_REACH',
}

export interface ReachInformationCategory {
    daysLeft: number;
    averageDailyConsumption: number;
    sulphurContent: SulphurContent;
    productGroup: ProductGroup;
    categoryState: CategoryState;
    tankCategory: string;
    safetyReserve?: number;
    consumptionCalculationBase?: ConsumptionCalculationBase;
}

export interface ReachInformationStop {
    portLocode: string;
    scheduleId: string;
    categories: ReachInformationCategory[];
}

export interface ReachInformationMessage {
    categories: ReachInformationCategory[];
    stops: ReachInformationStop[];
}
