import PropTypes from 'prop-types';

import { useRole } from '../../common/hooks/useRole';
import { Box } from '../../common/ui/Box';
import { VesselOrderTable } from '../VesselOrderTable/VesselOrderTable';

import { LiteVesselBudget } from './Budget/LiteVesselBudget';
import { VesselBudget } from './Budget/VesselBudget';

export const LubesOrderSection = ({ vessel }) => {
    const role = useRole();

    return (
        <Box display="flex" gap={9}>
            <Box width={['100%', 1 / 2]}>
                {role.usesCloselinkLite() ? <LiteVesselBudget /> : <VesselBudget vessel={vessel} />}
            </Box>
            <Box width={['100%', 1 / 2]}>
                <VesselOrderTable />
            </Box>
        </Box>
    );
};

LubesOrderSection.propTypes = {
    vessel: PropTypes.object,
};
