import PropTypes from 'prop-types';

import { DataSheet } from '../common/DataSheet/DataSheet';
import { formatAddress } from '../common/helpers/formatAddress.helper';
import { notAvailable } from '../common/helpers/notAvailable.helper';
import { translate } from '../common/helpers/translate.helper';
import { useRole } from '../common/hooks/useRole';
import { YesOrNo } from '../common/YesOrNo/YesOrNo';

export const GeneralInformationDatasheet = ({ companyData }) => {
    const role = useRole();

    return (
        <DataSheet>
            {role.isAdmin() ? (
                <>
                    <DataSheet.Label>{translate('customer.type')}</DataSheet.Label>
                    <span>
                        {notAvailable({
                            value: translate(`productContext.${companyData.type}`),
                        })}
                    </span>
                </>
            ) : null}
            <DataSheet.Label>{translate('global.contact.email')}</DataSheet.Label>
            <span>
                {notAvailable({
                    value: companyData.email,
                })}
            </span>

            <DataSheet.Label>{translate('global.contact.phone')}</DataSheet.Label>
            <span>
                {notAvailable({
                    value: companyData.phone,
                })}
            </span>
            <DataSheet.Label>{translate('global.contact.fax')}</DataSheet.Label>
            <span>
                {notAvailable({
                    value: companyData.fax,
                })}
            </span>
            <DataSheet.Label>{translate('global.contact.title')}</DataSheet.Label>
            <span>
                {notAvailable({
                    value: formatAddress({
                        address: companyData.address,
                    }),
                })}
            </span>
            <DataSheet.Label>{translate('global.contact.invoicemail')}</DataSheet.Label>
            <span>
                {notAvailable({
                    value: companyData.invoiceMail,
                })}
            </span>
            {role.isAdmin() ? (
                <>
                    <DataSheet.Label>{translate('global.mailsettings.receiveNews')}</DataSheet.Label>
                    <YesOrNo value={companyData.mailSettings.newsletter} />
                </>
            ) : null}
        </DataSheet>
    );
};

GeneralInformationDatasheet.propTypes = {
    companyData: PropTypes.object.isRequired,
};
