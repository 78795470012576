import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { IconAlertTriangle } from '../../common/icons/cdl/AlertTriangle';
import { theme } from '../../common/ui/theme';

interface WarningProps {
    tooltip: string;
}

export const Warning = ({ tooltip }: WarningProps) => {
    return (
        <Tooltip label={tooltip}>
            <span>
                <IconAlertTriangle color={theme.colors.attention.foreground} />
            </span>
        </Tooltip>
    );
};
