import { OptionProps } from 'react-select';

import { PortOptionSelectWithSchedule } from '../PortDetailSelectWithSchedule';

import { PortOptionWithoutSchedule } from './PortOptionWithoutSchedule';
import { PortOptionWithSchedule } from './PortOptionWithSchedule';

interface PortSelectWithScheduleOptionProps extends OptionProps {
    data: PortOptionSelectWithSchedule;
}

export const PortSelectWithScheduleOption = ({ data, ...rest }: PortSelectWithScheduleOptionProps) => {
    if (data.type === 'schedule') {
        return <PortOptionWithSchedule {...rest} data={data} />;
    }

    return <PortOptionWithoutSchedule {...rest} data={data} />;
};
