import { useCallback, useEffect, useState } from 'react';
import { OnChangeValue } from 'react-select';

import { PortMessage } from '../../../types/PortMessage';
import { ComboBox, ComboBoxProps, DefaultOption } from '../../cdl/ComboBox/ComboBox';
import { getPorts } from '../../common/api/clients/port.api';
import { formatPort } from '../../common/helpers/formatPort.helper';
import { translate } from '../../common/helpers/translate.helper';
import { Schedule } from '../../common/hooks/useSchedules';

import { PortSelectWithScheduleOption } from './components/PortSelectWithScheduleOption';
import { PortSingleValue } from './components/PortSingleValue';
import { getPortOptionsWithLatestPorts } from './utils/getPortOptionsWithLatestPorts';
import { getPortOptionsWithSearchQuery } from './utils/getPortOptionsWithSearchQuery';

export interface PortOptionWithoutScheduleType {
    type: 'noSchedule';
    label: string;
    value: string;
    port: PortMessage;
    vesselId?: string;
}

export interface PortOptionWithScheduleType {
    type: 'schedule';
    label: string;
    value: string;
    port: PortMessage;
    vesselId?: string;
    scheduleEntry: Schedule;
    schedulesLength: number;
}

export type PortOptionSelectWithSchedule = PortOptionWithoutScheduleType | PortOptionWithScheduleType;

export interface PortSelectWithScheduleProps extends Omit<ComboBoxProps<DefaultOption, false>, 'onChange' | 'value'> {
    value: string;
    onChange: (option: PortOptionSelectWithSchedule, scheduleRank?: number) => void;
    schedule: Schedule[];
    vesselId?: string;
}

export function PortDetailSelectWithSchedule({
    value,
    onChange,
    schedule,
    label = translate('portSelect.label'),
    vesselId,
    ...rest
}: PortSelectWithScheduleProps) {
    const [selectedValue, setSelectedValue] = useState<PortOptionSelectWithSchedule | null>(null);

    const loadPortsBySearchQuery = (searchQuery: string) => {
        if (!searchQuery) {
            return getPortOptionsWithLatestPorts(schedule, vesselId);
        }

        return getPortOptionsWithSearchQuery(searchQuery, schedule, vesselId);
    };

    const loadPortById = useCallback(async (portId: string): Promise<PortOptionSelectWithSchedule> => {
        const response = await getPorts({ ids: [portId] });
        const port = response.content[0];
        return {
            type: 'noSchedule',
            label: formatPort({ port }),
            value: port.id,
            port,
        };
    }, []);

    useEffect(() => {
        let isMounted = true;

        if (value) {
            loadPortById(value).then((option: PortOptionSelectWithSchedule) => {
                if (isMounted) {
                    setSelectedValue(option);
                }
            });
        }

        return () => {
            isMounted = false;
        };
    }, [loadPortById, value]);

    const updateSelectedValue = (option: OnChangeValue<PortOptionSelectWithSchedule, false>) => {
        if (option && 'scheduleEntry' in option) {
            onChange(option, option.scheduleEntry.rank);
        } else {
            onChange(option as PortOptionSelectWithSchedule);
        }
    };

    return (
        <ComboBox<PortOptionSelectWithSchedule, false>
            {...rest}
            label={label}
            onChange={updateSelectedValue}
            value={selectedValue}
            loadOptions={loadPortsBySearchQuery}
            rerenderValue={JSON.stringify(schedule)}
            overrides={{
                Option: PortSelectWithScheduleOption as any,
                SingleValue: PortSingleValue as any,
            }}
        />
    );
}
