import { useQuery } from '@tanstack/react-query';

import { getBunkerMetricCredentials } from '../../common/api/clients/bunkerMetric.api';
import { queryKeys } from '../../common/api/queryKeys';

export const useBunkerMetricCredentials = () => {
    return useQuery({
        queryKey: queryKeys.bunkerMetricCredentials(),
        queryFn: () => getBunkerMetricCredentials(),
    });
};
