import { SolutionWarning, SolutionWarningType } from '../../../../../../types/BunkerCaseResponse';
import { Tooltip } from '../../../../../cdl/Tooltip/Tooltip';
import { translate } from '../../../../../common/helpers/translate.helper';
import { IconAB } from '../../../../../common/icons/cdl/AB';
import { IconAlertTriangle } from '../../../../../common/icons/cdl/AlertTriangle';
import { IconArrowsDiff } from '../../../../../common/icons/cdl/ArrowsDiff';
import { IconCake } from '../../../../../common/icons/cdl/Cake';
import { IconInfoCircle } from '../../../../../common/icons/cdl/InfoCircle';
import { IconLetterQ } from '../../../../../common/icons/cdl/LetterQ';
import { IconLetterQOff } from '../../../../../common/icons/cdl/LetterQOff';
import { Box } from '../../../../../common/ui/Box';

function getIconComponent(warning: SolutionWarning) {
    switch (warning.type) {
        case SolutionWarningType.QUARANTINE_REQUIREMENT:
            return IconLetterQ;
        case SolutionWarningType.REDELIVERY_DISCREPANCY:
            return IconAB;
        case SolutionWarningType.FUEL_SUBSTITUTION:
            return IconArrowsDiff;
        case SolutionWarningType.WARNING:
            return IconAlertTriangle;
        case SolutionWarningType.PERFORMANCE:
            return IconCake;
        case SolutionWarningType.OVERFILL:
            return IconAlertTriangle;
        case SolutionWarningType.QUARANTINE_VIOLATION:
            return IconLetterQOff;
        case SolutionWarningType.RESERVE_VIOLATION:
            return IconInfoCircle;
    }
}

function getTooltipTitle(warning: SolutionWarning): string {
    switch (warning.type) {
        case SolutionWarningType.QUARANTINE_REQUIREMENT:
            return translate('prePlanning.warnings.quarantineRequirement');
        case SolutionWarningType.REDELIVERY_DISCREPANCY:
            return translate('prePlanning.warnings.redeliveryDiscrepancy');
        case SolutionWarningType.FUEL_SUBSTITUTION:
            return translate('prePlanning.warnings.fuelSubstitution');
        case SolutionWarningType.WARNING:
            return translate('prePlanning.warnings.warning');
        case SolutionWarningType.PERFORMANCE:
            return translate('prePlanning.warnings.performance');
        case SolutionWarningType.OVERFILL:
            return translate('prePlanning.warnings.overfill');
        case SolutionWarningType.QUARANTINE_VIOLATION:
            return translate('prePlanning.warnings.quarantineViolation');
        case SolutionWarningType.RESERVE_VIOLATION:
            return translate('prePlanning.warnings.reserveViolation');
    }
}

const WarningIcon = ({ warning }: { warning: SolutionWarning }) => {
    const IconComponent = getIconComponent(warning);

    return (
        <Tooltip
            label={
                <span>
                    <b>{getTooltipTitle(warning)}</b>
                    <br />
                    {warning.message}
                </span>
            }
        >
            <span>
                <IconComponent width={16} height={16} />
            </span>
        </Tooltip>
    );
};

interface BunkerSolutionWarningsProps {
    warnings: SolutionWarning[];
}

export const BunkerSolutionWarnings = ({ warnings }: BunkerSolutionWarningsProps) => {
    if (warnings.length === 0) {
        return <>-</>;
    }

    return (
        <Box display="flex" gap={4}>
            {warnings.map((it) => (
                <WarningIcon warning={it} key={it.type} />
            ))}
        </Box>
    );
};
