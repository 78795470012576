import { FormikConfig, FormikValues } from 'formik';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

// Inspired by: https://gist.github.com/AlonMiz/e583946d3978de691ed53cece972e1a1

export function useDebouncedValidate<T extends FormikValues>({
    values,
    validate,
    debounceTime = 200,
    page,
}: {
    values: T;
    validate: FormikConfig<T>['validate'];
    debounceTime?: number;
    page: number;
}) {
    const debouncedFunction = useRef(
        debounce((validateFunc: FormikConfig<T>['validate'], data: T) => {
            return validateFunc?.(data);
        }, debounceTime)
    );

    const debounceValidate = useCallback(
        (data) => {
            return debouncedFunction.current(validate, data);
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Debounced validate on values change
    useEffect(() => {
        debounceValidate(values);
    }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

    // Immediately validate on page change
    useEffect(() => {
        validate?.(values);
    }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const debouncedFunctionCurrent = debouncedFunction.current;
        return () => {
            debouncedFunctionCurrent.cancel();
        };
    }, []);
}
