import { useContextCalendars } from '@rehookify/datepicker';

import { DividerVertical } from './DividerVertical';
import { MonthView } from './MonthView';

export const CalendarMonths = () => {
    const { calendars } = useContextCalendars();

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <MonthView calendar={calendars[1]} hasPrevious />
            <DividerVertical />
            <MonthView calendar={calendars[2]} hasNext />
        </div>
    );
};
