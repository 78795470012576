import PropTypes from 'prop-types';

import { useFormData } from '../../../common/form-elements/Form/useFormData';
import { FormTextInput } from '../../../common/form-elements/TextInput/TextInput';
import { notAvailable } from '../../../common/helpers/notAvailable.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useEnums } from '../../../common/hooks/useEnums';
import { IconX } from '../../../common/icons/cdl/X';
import { Item } from '../../../common/models/item.model';
import { Td } from '../../../common/Table/Td';
import { Tr } from '../../../common/Table/Tr';

import { PackTypeFormSelect } from './PackTypeFormSelect';
import { FormProductSelect } from './ProductSelect';

export const ItemsTableRow = ({ index, onRemoveItemClick, showRemoveIcon }) => {
    const { getHumanReadableValue } = useEnums();

    const { value: item, setValue } = useFormData(`items[${index}]`);

    const onProductSelect = ({ product }) => {
        const newItem = new Item({
            ...item,
            productId: product.id,
            product,
        });

        newItem.initWithProduct(product);

        setValue(newItem);
    };

    const _onRemoveItemClick = (index) => (event) => {
        event.preventDefault();

        onRemoveItemClick(index);
    };

    const productGroup = item.group ? getHumanReadableValue('productGroup', item.group) : null;

    return (
        <Tr>
            <Td>
                <FormProductSelect dataPath={`items[${index}].productId`} onSelect={onProductSelect} />
            </Td>
            <Td>
                {notAvailable({
                    value: productGroup,
                })}
            </Td>
            <Td>
                <PackTypeFormSelect dataPath={`items[${index}].pack`} productGroup={item.group} />
            </Td>
            <Td>{item.unitSize}</Td>
            <Td>
                <FormTextInput
                    dataPath={`items[${index}].units`}
                    placeholder={translate('placeholder.units')}
                    type="number"
                    transformValue={
                        (value) => {
                            return value && !Number.isNaN(value) ? Number(value) : value;
                        }
                        //Create Enquiry expects units as number
                    }
                    pattern="\d*"
                    min="0"
                    step="1"
                />
            </Td>
            <Td>{notAvailable({ value: item.volume })}</Td>
            <Td>{item.unit}</Td>
            <Td>
                {showRemoveIcon ? (
                    <button onClick={_onRemoveItemClick(index)}>
                        <IconX />
                    </button>
                ) : null}
            </Td>
        </Tr>
    );
};

ItemsTableRow.propTypes = {
    index: PropTypes.number,
    onRemoveItemClick: PropTypes.func,
    showRemoveIcon: PropTypes.bool,
};
