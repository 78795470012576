import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconFilter = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M5.17 4.056A1 1 0 0 1 5.5 4h13a1 1 0 0 1 .331.056 2 2 0 0 1 1 3c-.027.041-.058.08-.091.117L15 12.387V19a1 1 0 0 1-1.6.8l-4-3A1 1 0 0 1 9 16v-3.613L4.26 7.173a1 1 0 0 1-.09-.117 2 2 0 0 1 1-3ZM5.896 6l4.843 5.327A1 1 0 0 1 11 12v3.5l2 1.5v-5a1 1 0 0 1 .26-.673L18.103 6H5.897Z"
            clipRule="evenodd"
        />
    </svg>
);
