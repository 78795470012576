import { ProductContext } from '../../../../types/ProductContext';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { Error } from '../../../common/Error/Error';
import { formatCompanyName } from '../../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate as t } from '../../../common/helpers/translate.helper';
import { useNavigation } from '../../../common/hooks/useNavigation';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { useUpdateEffect } from '../../../common/hooks/useUpdateEffect';
import { IconCheck } from '../../../common/icons/cdl/Check';
import { IconList } from '../../../common/icons/cdl/List';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { TabSwitch } from '../../../common/TabSwitch/TabSwitch';
import { Box } from '../../../common/ui/Box';
import { OfferState } from '../../../offer/model/OfferState';
import { Port } from '../../../port/Port';
import { UnreadIndicator } from '../../common/UnreadIndicator';
import { OrderModel } from '../../model/OrderModel';
import { useOrderPagination } from '../../useOrderPagination';
import { OrderActions } from '../actions/OrderActions';
import { orderTabOfferStateFilter, orderTabStateFilter } from '../orderTabsHelper';
import { useOrderPaginationTotals } from '../useOrderPaginationTotals';

import { OfferCount } from './OfferCount';
import { OrderStatusTag } from './OrderStatusTag';

export const LubesOrderOverviewTable = () => {
    const [locationState, { setLocationFieldValue }] = useLocationState();
    const role = useRole();
    const { context } = useProductContext();
    const { navigate } = useNavigation();

    const handleCustomerIds = (customerIds: string[]) => {
        if (customerIds.length) {
            return customerIds;
        }

        if (role.isAdmin()) {
            return undefined;
        }

        return role.getCompaniesWithType(context).map((it) => it.id);
    };

    const ordersTotalsQuery = useOrderPaginationTotals({
        searchQuery: locationState.searchQuery,
        customerIds: handleCustomerIds(locationState.customerIds),
        supplierIds: locationState.supplierIds,
        types: 'LUBES',
    });
    const ordersTotals = ordersTotalsQuery.data || {};

    const paginationQuery = useOrderPagination({
        page: locationState.page,
        searchQuery: locationState.searchQuery,
        customerIds: handleCustomerIds(locationState.customerIds),
        supplierIds: locationState.supplierIds,
        types: [ProductContext.LUBES],
        offerStates: orderTabOfferStateFilter[locationState.tab as keyof typeof orderTabOfferStateFilter],
        states: orderTabStateFilter[locationState.tab as keyof typeof orderTabStateFilter],
        sortField: locationState.sortValue?.sortField,
        sortDirection: locationState.sortValue?.sortDirection,
    });

    useUpdateEffect(() => {
        setLocationFieldValue('page', 0);
    }, [
        locationState.searchQuery,
        locationState.customerIds,
        locationState.supplierIds,
        locationState.tab,
        locationState.sortValue,
    ]);

    const onTabChange = (tab: keyof typeof orderTabOfferStateFilter) => {
        setLocationFieldValue('tab', tab);
    };

    if (paginationQuery.isPending) {
        return (
            <div>
                <TabSwitch selectedContext={locationState.tab} onSelect={onTabChange} totals={ordersTotals} />
                <LoadingIndicator />
            </div>
        );
    }

    if (paginationQuery.isError) {
        return (
            <div>
                <TabSwitch selectedContext={locationState.tab} onSelect={onTabChange} totals={ordersTotals} />
                <Error />
            </div>
        );
    }

    if (!paginationQuery.data.content.length) {
        return (
            <div>
                <TabSwitch selectedContext={locationState.tab} onSelect={onTabChange} totals={ordersTotals} />
                <TableEmptyState Icon={IconList} text={t('order.emptylist')} />
            </div>
        );
    }

    const onRowClick = (order: OrderModel) => {
        if (order.isClosed()) {
            navigate('base.offerlist-offer', {
                id: order.chosenOfferId,
            });
        } else if (order.isDraft() && !role.isAdmin() && role.hasWriteRights(order.customerId)) {
            navigate('lubes-create-enquiry-draft', {
                id: order.id,
                origin: {
                    stateName: 'base.orderlist',
                    params: {
                        tab: 'drafted',
                    },
                },
            });
        } else {
            navigate('base.orderlist-order', {
                id: order.id,
            });
        }
    };

    return (
        <div>
            <TabSwitch selectedContext={locationState.tab} onSelect={onTabChange} totals={ordersTotals} />

            <TableBuilder data={paginationQuery.data.content} onRowSelect={(order) => onRowClick(order)}>
                <TableBuilderColumn<OrderModel> header="" width="1%">
                    {(order) => <Box width="8px">{!order.read ? <UnreadIndicator /> : null}</Box>}
                </TableBuilderColumn>

                <TableBuilderColumn<OrderModel> header={t('order.buyerreferenceshort')} width="10%">
                    {(order) => order.buyerReference || '-'}
                </TableBuilderColumn>

                {!role.isOneCompanyUser('LUBES') && (
                    <TableBuilderColumn<OrderModel>
                        header={role.isCustomer() ? t('order.company') : t('order.customer')}
                        width="10%"
                    >
                        {(order) => formatCompanyName({ company: order.customer }) || null}
                    </TableBuilderColumn>
                )}

                <TableBuilderColumn<OrderModel> header={t('order.vessel')} width="15%">
                    {(order) => formatVessel({ vessel: order.vessel, short: true })}
                </TableBuilderColumn>

                <TableBuilderColumn<OrderModel> header={t('order.port')} width="15%">
                    {(order) => {
                        if (!order.port) {
                            return null;
                        }
                        return <Port port={order.port} vesselId={order.vesselId} locode={false} showTooltip />;
                    }}
                </TableBuilderColumn>

                <TableBuilderColumn<OrderModel> header={t('order.dateDeliveryShort')} width="10%">
                    {(order) => {
                        if (!order.dateDelivery) {
                            return null;
                        }
                        return formatDate({ date: order.dateDelivery, timeZone: 'UTC' });
                    }}
                </TableBuilderColumn>

                {['ordered', 'delivered', 'canceled'].includes(locationState.tab) ? (
                    <TableBuilderColumn<OrderModel> header={t('order.supplier')} width="10%">
                        {(order) => formatCompanyName({ company: order.supplier }) || null}
                    </TableBuilderColumn>
                ) : null}

                {['enquired', 'ordered', 'canceled'].includes(locationState.tab) ? (
                    <TableBuilderColumn<OrderModel> header={t('order.status')} width="5%">
                        {(order) => {
                            if (
                                !order.offerState ||
                                [OfferState.DRAFT, OfferState.DELIVERED].includes(order.offerState)
                            ) {
                                return null;
                            }
                            return <OrderStatusTag state={order.offerState} />;
                        }}
                    </TableBuilderColumn>
                ) : null}

                {['enquired'].includes(locationState.tab) ? (
                    <TableBuilderColumn<OrderModel> header={t('order.offercount')} width="5%">
                        {(order) => {
                            return (
                                <OfferCount
                                    offerCount={order.offerCount}
                                    totalOffers={order.receiverSupplierIds.length}
                                    includesSpot={order.spot}
                                />
                            );
                        }}
                    </TableBuilderColumn>
                ) : null}

                {['drafted'].includes(locationState.tab) ? (
                    <TableBuilderColumn<OrderModel> header={t('order.created')} width="10%">
                        {(order) => formatDate({ date: order.dateCreated })}
                    </TableBuilderColumn>
                ) : null}

                {role.isAdmin() && locationState.tab !== 'drafted' ? (
                    <TableBuilderColumn<OrderModel> header={t('order.detail.spot')} width="5%">
                        {(order) => {
                            if (order.spot) {
                                return <IconCheck height={16} width={16} />;
                            }
                            return null;
                        }}
                    </TableBuilderColumn>
                ) : null}

                {(role.hasAnyCompanyWriteRights() && role.isCustomer()) ||
                (role.isAdmin() && locationState.tab === 'drafted') ? (
                    <TableBuilderColumn<OrderModel> header={t('order.action')} width="5%">
                        {(order) => <OrderActions order={order} />}
                    </TableBuilderColumn>
                ) : null}
            </TableBuilder>

            <CenteredPagination
                pageTotal={paginationQuery.pageTotal}
                currentPage={locationState.page}
                onPageChange={(page) => setLocationFieldValue('page', page)}
            />
        </div>
    );
};
