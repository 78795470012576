import { useEffect, useState } from 'react';

export const useCheapestDefaultScope = ({ hasCheapestDefaultFn, activeSlide, slidesPerView, pricesMap, schedules }) => {
    const [cheapestDefaultScope, setCheapestDefaultScope] = useState({
        prev: false,
        next: false,
    });

    useEffect(() => {
        if (Object.keys(pricesMap).length && schedules.length) {
            const leftStops = schedules.slice(0, activeSlide);
            const rightStops = schedules.slice(activeSlide + slidesPerView);

            setCheapestDefaultScope({
                prev: leftStops.find((stop) => hasCheapestDefaultFn(stop, pricesMap)),
                next: rightStops.find((stop) => hasCheapestDefaultFn(stop, pricesMap)),
            });
        }
    }, [pricesMap, schedules, activeSlide, slidesPerView, hasCheapestDefaultFn]);

    return cheapestDefaultScope;
};
