import { AxiosProgressEvent } from 'axios';

import { FileManagementResponse } from '../../../../types/FileManagementResponse';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const uploadFile = async (
    file: File,
    companyId: string,
    onUploadProgress: (event: AxiosProgressEvent) => void
): Promise<FileManagementResponse> => {
    const formData = new FormData();
    formData.append('file', file);

    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('companyId', companyId);

    const response = await authorizedAxiosInstance.post(`/v1/files?${urlSearchParams.toString()}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
    });

    return response.data;
};
