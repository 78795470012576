import { useQueryClient } from '@tanstack/react-query';
import { AxiosProgressEvent, AxiosResponse } from 'axios';
import { useFormikContext } from 'formik';

import { FileManagementResponse } from '../../../types/FileManagementResponse';
import * as anonymousQuotationApi from '../../common/api/clients/anonymousQuotation.api';
import { queryKeys } from '../../common/api/queryKeys';
import { Attachments } from '../../common/Attachments/components/Attachments';
import { OfferModel } from '../../offer/model/OfferModel';

interface AnonymousQuotationAttachmentsProps {
    anonymousQuotationId: string;
    offer: OfferModel;
}

export const AnonymousQuotationAttachments = ({ anonymousQuotationId, offer }: AnonymousQuotationAttachmentsProps) => {
    const queryClient = useQueryClient();
    const formikContext = useFormikContext<OfferModel>();

    const uploadFile = async (
        file: File,
        onUploadProgress: (event: AxiosProgressEvent) => void
    ): Promise<FileManagementResponse> => {
        const promise = anonymousQuotationApi.uploadFile(anonymousQuotationId, file, onUploadProgress);
        const fileManagementResponse = await promise;
        formikContext.setFieldValue(
            'files',
            formikContext.values.files && formikContext.values.files.length
                ? [...formikContext.values.files, fileManagementResponse]
                : [fileManagementResponse]
        );
        formikContext.setFieldValue(
            'fileIds',
            formikContext.values.fileIds && formikContext.values.fileIds.length
                ? [...formikContext.values.fileIds, fileManagementResponse.id]
                : [fileManagementResponse.id]
        );
        return promise;
    };

    const deleteFile = (fileId: string): Promise<void> => {
        return anonymousQuotationApi.deleteFile(anonymousQuotationId, [fileId]);
    };

    const downloadFile = (fileId: string): Promise<AxiosResponse> => {
        return anonymousQuotationApi.downloadFile(anonymousQuotationId, fileId);
    };

    const invalidateCache = async () => {
        await queryClient.cancelQueries({ queryKey: queryKeys.anonymousQuotation(anonymousQuotationId) });
    };

    return (
        <Attachments
            files={offer.files}
            companyId={offer.supplierId}
            counterCompanyName={offer.customer.name}
            config={{
                uploadFile,
                deleteFile,
                downloadFile,
                invalidateCache,
                hasWriteRights: true,
                enableDownloadUploadedFiles: true,
            }}
        />
    );
};
