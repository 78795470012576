import styled from 'styled-components';

import { Text } from '../../../../cdl/Text/Text';
import { translate } from '../../../../common/helpers/translate.helper';
import { get } from '../../../../common/ui/get';

const StyledOrderedList = styled.ol`
    padding-left: 1.5em;
    list-style: decimal;
    color: ${get('colors.foreground.muted')};
    a {
        color: ${get('colors.accent.foreground')};
        text-decoration: solid;
    }
`;

export const PlattsCredentialsManual = () => {
    return (
        <StyledOrderedList>
            <li>
                <Text
                    variant="small"
                    color="foreground.muted"
                    dangerouslySetInnerHTML={{
                        __html: translate('integrationSettings.platts.manualFirst'),
                    }}
                />
            </li>
            <li>
                <Text
                    variant="small"
                    color="foreground.muted"
                    dangerouslySetInnerHTML={{
                        __html: translate('integrationSettings.platts.manualSecond'),
                    }}
                />
            </li>
        </StyledOrderedList>
    );
};
