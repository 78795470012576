export const queryKeys = {
    contract: (contractId) => ['contracts', contractId],
    contracts: (params) => {
        return params
            ? [
                  'contracts',
                  {
                      page: params.page,
                      searchQuery: params.searchQuery,
                      customerGroupIds: params.customerGroupIds,
                      tender: params.tender,
                      active: params.active,
                  },
              ]
            : ['contracts'];
    },
    contractsTotals: (params) => {
        return params
            ? [
                  'contractsTotals',
                  {
                      searchQuery: params.searchQuery,
                      customerGroupIds: params.customerGroupIds,
                  },
              ]
            : ['contractsTotals'];
    },
    customers: ({ customerGroupId }) => ['customers', customerGroupId],
    customerGroups: (params) => {
        return params ? ['customerGroups', params] : ['customerGroups'];
    },
    customerDetails: (customerId) => ['customerDetails', customerId],
    supplierGroups: (params) => (params ? ['supplierGroups', params] : ['supplierGroups']),
    orders: (params) => {
        return params
            ? [
                  'orders',
                  {
                      page: params.page,
                      searchQuery: params.searchQuery,
                      customerIds: params.customerIds,
                      supplierIds: params.supplierIds,
                      offerStates: params.offerStates,
                      types: params.types,
                      states: params.states,
                      sortField: params.sortField,
                      sortDirection: params.sortDirection,
                  },
              ]
            : ['orders'];
    },
    offer: (id) => {
        return id ? ['offers', id] : ['offers'];
    },
    offerApprovalSettings: (offerId) => {
        return ['offerApprovalSettings', offerId];
    },
    fuelOrderOffers: (id) => {
        return id ? ['orderOffers', id] : ['orderOffers'];
    },
    ordersTotals: (params) => {
        return params
            ? [
                  'ordersTotals',
                  {
                      searchQuery: params.searchQuery,
                      customerIds: params.customerIds,
                      supplierIds: params.supplierIds,
                      types: params.types,
                  },
              ]
            : ['ordersTotals'];
    },
    offers: (params) => {
        return params
            ? [
                  'offers',
                  {
                      page: params.page,
                      searchQuery: params.searchQuery,
                      supplierIds: params.supplierIds,
                      customerIds: params.customerIds,
                      offerStates: params.offerStates,
                      type: params.type,
                      states: params.states,
                      sortField: params.sortField,
                      sortDirection: params.sortDirection,
                  },
              ]
            : ['offers'];
    },
    offersTotals: (params) => {
        return params
            ? [
                  'offersTotals',
                  {
                      searchQuery: params.searchQuery,
                      supplierIds: params.supplierIds,
                      customerIds: params.customerIds,
                      type: params.type,
                  },
              ]
            : ['offersTotals'];
    },
    supplierRecentActivities: ({ page, types }) => ['supplierRecentActivities', { page, types }],
    port: (id) => ['ports', id],
    portDirectories: ({ portId, supplierGroupId }) => ['portDirectories', portId, supplierGroupId],

    fuelStocks: (vesselId) => ['fuelStocks', vesselId],
    schedulesWithPrices: (vesselId) => (vesselId ? ['schedulesWithPrices', vesselId] : ['schedulesWithPrices']),
    schedules: (imo) => ['schedules', imo],
    enums: () => ['enums'],
    fuelProducts: () => ['fuel-products'],
    order: (orderId) => ['orders', orderId],
    fuelContractProducts: (params) => {
        return params
            ? [
                  'fuelContractProducts',
                  {
                      portId: params.portId,
                      validFrom: params.validFrom,
                      validTo: params.validTo,
                      categories: params.categories,
                      sulphurContents: params.sulphurContents,
                      groupId: params.groupId,
                  },
              ]
            : ['fuelContractProducts'];
    },
    suppliers: ({ customerIds, page }) => {
        return ['suppliers', { customerIds, page }];
    },
    assignments: ({ customerIds }) => {
        return ['assignments', { customerIds }];
    },
    offerPositions: (offerId) => ['offerPositions', offerId],
    offerMessages: (offerId) => ['offerMessages', offerId],
    messages: (offerId) => ['messages', { offerId }],
    stats: (type) => ['stats', { type }],
    initialEnquiry: (offerId) => ['initialEnquiry', offerId],
    anonymousQuotation: (anonymousQuotationId) => ['anonymousQuotations', anonymousQuotationId],
    magicBellUserConfig: () => ['magicBellUserConfig'],
    supplierDetails: (supplierId) => ['supplierDetails', supplierId],
    supplierSettings: (supplierId) => ['supplierSettings', supplierId],
    customerSettings: (customerId) => ['customerSettings', customerId],
    plattsCredentials: (customerId) => ['plattsCredentials', customerId],
    fees: (offerId) => ['fees', offerId],
    feesByOfferTotal: (offerTotal, currencyCode) => ['feesByOfferTotal', { offerTotal, currencyCode }],
    userOnboarding: (userId) => ['userOnboarding', userId],
    user: (userId) => {
        return userId ? ['users', userId] : ['users'];
    },
    users: (params) => {
        return params ? ['users', params] : ['users'];
    },
    ports: (params) => (params ? ['ports', { page: params.page, searchQuery: params.searchQuery }] : ['ports']),
    reachInformation: (vesselId) => ['reachInformation', vesselId],
    scheduleReachInformation: (params) => [
        'scheduleReachInformation',
        { vesselId: params.vesselId, scheduleId: params.scheduleId },
    ],
    demandInformation: (sortingKey, productContext, bunkerMetricInformation, groupId) => [
        'demandInformation',
        { sortingKey, productContext, bunkerMetricInformation, groupId },
    ],
    bunkerMetricCredentials: () => ['bunkerMetricCredentials'],
    globalPrices: () => ['globalPrices'],
    bunkerMetricBunkerCase: (params) => [
        'bunkerMetricBunkerCase',
        { vesselId: params.vesselId, caseNumber: params.caseNumber },
    ],
    bunkerMetricBunkerCaseSolution: (params) => [
        'bunkerMetricBunkerCaseSolution',
        { vesselId: params.vesselId, caseNumber: params.caseNumber, solutionNumber: params.solutionNumber },
    ],
    bunkerMetricBunkerCaseGeometry: (params) => [
        'bunkerMetricBunkerCaseGeometry',
        { vesselId: params.vesselId, caseNumber: params.caseNumber, solutionNumber: params.solutionNumber },
    ],
    bunkerStops: () => ['bunkerStops'],
};
