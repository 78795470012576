import { AgentGatewayMessage } from '../../../types/AgentMessage';
import { ArchiveAgentButton } from '../detail/components/ArchiveAgentButton';
import { RestoreAgentButton } from '../detail/components/RestoreAgentButton';

interface ArchiveRestoreButtonProps {
    agent: AgentGatewayMessage;
}

export const ArchiveRestoreButton = ({ agent }: ArchiveRestoreButtonProps) => {
    if (agent.active) {
        return <ArchiveAgentButton agent={agent} />;
    } else {
        return <RestoreAgentButton agent={agent} />;
    }
};
