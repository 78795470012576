import { useField } from 'formik';
import { ReactNode, useCallback, useEffect, useState } from 'react';

import { ProductContext } from '../../../../../types/ProductContext';
import { VesselMessage } from '../../../../../types/VesselMessage';
import { ComboBox } from '../../../../cdl/ComboBox/ComboBox';
import { Text } from '../../../../cdl/Text/Text';
import { getVessel } from '../../../../common/api/clients/gateway.api';
import { getVesselList } from '../../../../common/api/clients/vessel.api';
import { formatVessel } from '../../../../common/helpers/formatVessel.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useRole } from '../../../../common/hooks/useRole';
import { useVesselCreateModal } from '../../../../common/hooks/useVesselCreateModal';
import { IconPlus } from '../../../../common/icons/cdl/Plus';
import { Box } from '../../../../common/ui/Box';

interface FormikVesselSelectProps {
    name: string;
    label: string;
    onVesselChange?: (vessel?: VesselMessage) => void;
}

type VesselOption = {
    label: ReactNode;
    value: string;
    vessel?: VesselMessage;
};

const makeVesselOption = (vessel: VesselMessage): VesselOption => ({
    label: formatVessel({ vessel }),
    value: vessel.id,
    vessel,
});

export const FormikVesselSelect = (props: FormikVesselSelectProps) => {
    const [field, meta, helper] = useField(props.name);
    const [selectedValue, setSelectedValue] = useState<VesselOption>();
    const role = useRole();
    const { open: openVesselCreateModal, isOpen: modalIsOpen } = useVesselCreateModal({
        onSuccess: (vessel) => {
            helper.setValue(vessel.id);
            setSelectedValue(makeVesselOption(vessel));
        },
        onCancel: () => {
            if (!field.value) {
                helper.setValue(undefined);
            }
        },
    });

    const loadVesselBySearch = useCallback(
        async (searchQuery: string): Promise<VesselOption[]> => {
            const customerIds = role.getCompaniesWithType(ProductContext.LUBES).map((company) => company.id);

            const vessels = await getVesselList({
                searchQuery: searchQuery || undefined,
                customerIds,
                active: true,
            });

            const options = vessels.map(makeVesselOption);
            if (role.usesCloselinkLite()) {
                options?.push({
                    value: 'create-new',
                    label: (
                        <Text as="div" fontWeight="medium" color="accent.foreground">
                            <Box display="flex" alignItems="center" gap={2}>
                                <IconPlus height={16} width={16} /> {translate('vessel.create')}
                            </Box>
                        </Text>
                    ),
                });
            }

            return options;
        },
        [role]
    );

    useEffect(() => {
        if (field.value && selectedValue === undefined) {
            getVessel(field.value).then((vessel) => {
                setSelectedValue(makeVesselOption(vessel));
                props?.onVesselChange?.(vessel);
            });
        }
    }, [field.value, props, selectedValue]);

    return (
        <ComboBox<VesselOption>
            label={props.label}
            value={selectedValue}
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : ''}
            onChange={(newValue) => {
                if (newValue?.value === 'create-new') {
                    openVesselCreateModal();
                    // setting the value back to the previous value to mimick
                    // behaviour in other places in the app
                    helper.setValue(selectedValue?.value);
                    return;
                }
                setSelectedValue(newValue ? newValue : undefined);
                props?.onVesselChange?.(newValue?.vessel);
                helper.setValue(newValue?.value);
            }}
            rerenderValue={field.value}
            onBlur={() => helper.setTouched(!modalIsOpen)}
            cacheOptions={modalIsOpen}
            loadOptions={loadVesselBySearch}
        />
    );
};
