import moment from 'moment';

import { formatDateTime } from '../../helpers/formatDateTime.helper';

export const formatValidUntil = (validUntil: string) => {
    const validUntilDate = moment(validUntil);

    if (validUntilDate.isBefore(moment()) || validUntilDate.isAfter(moment().add(1, 'days'))) {
        return formatDateTime({
            date: validUntil,
            format: 'MMM D, h:mm a',
        });
    }

    return formatDateTime({
        date: validUntil,
        format: 'h:mm a',
    });
};
