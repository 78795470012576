import { FormikErrors } from 'formik';

import { translate } from '../../../../common/helpers/translate.helper';
import { FormikVesselTanksState } from '../EditTanksModal';

export const handleFormikValidate = (values: FormikVesselTanksState): FormikErrors<FormikVesselTanksState> => {
    let hasAtLeastOneError = false;

    const errors = {
        vesselTanks: values.vesselTanks.map((vesselTank) => {
            if (vesselTank.category === '') {
                hasAtLeastOneError = true;

                return {
                    category: translate('errormessage.empty'),
                };
            } else {
                return {};
            }
        }),
    };

    return hasAtLeastOneError ? errors : {};
};
