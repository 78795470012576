import { ScriptableContext, ScriptableLineSegmentContext } from 'chart.js';

import { theme } from '../../../../common/ui/theme';

interface UseChartUtilsParams {
    isFirstValueMissing: boolean;
    indexOfFirstValidValue: number;
    isLastValueMissing: boolean;
    indexOfLastValidValue: number;
}

const EMPTY_VALUE_BORDER_DASH = 5;
const RADIUS = 6;

export const useChartUtils = ({
    isFirstValueMissing,
    isLastValueMissing,
    indexOfLastValidValue,
    indexOfFirstValidValue,
}: UseChartUtilsParams) => {
    const getSegmentBorderColor = (ctx: ScriptableLineSegmentContext) => {
        if (
            (isFirstValueMissing && ctx.p1DataIndex <= indexOfFirstValidValue) ||
            (isLastValueMissing && ctx.p1DataIndex > indexOfLastValidValue)
        ) {
            return theme.colors.foreground.disabled;
        }

        return theme.colors.accent.emphasis;
    };

    const getSegmentBorderDash = (ctx: ScriptableLineSegmentContext) => {
        if (
            (isFirstValueMissing && ctx.p1DataIndex <= indexOfFirstValidValue) ||
            (isLastValueMissing && ctx.p1DataIndex > indexOfLastValidValue)
        ) {
            return [EMPTY_VALUE_BORDER_DASH, EMPTY_VALUE_BORDER_DASH];
        }

        return [];
    };

    const getRadius = (ctx: ScriptableContext<'line'>) => {
        const index = ctx.dataIndex;
        const amountPoints = ctx.chart.data.labels?.length || 0;

        if ((isFirstValueMissing && index === 0) || (isLastValueMissing && index === amountPoints - 1)) {
            return 0;
        }

        return RADIUS;
    };
    return {
        getSegmentBorderColor,
        getSegmentBorderDash,
        getRadius,
    };
};
