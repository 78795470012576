import { useField } from 'formik';
import PropTypes from 'prop-types';

import { FormikTextInput } from '../../form-elements/formik/FormikTextInput';
import { translate } from '../../helpers/translate.helper';

export const PhysicalSupplierDisplay = (props) => {
    const [field] = useField(props.name);
    const item = field.value;

    if (item.deleted || !props.editable) {
        return item.physicalSupplierName || '-';
    }

    return (
        <FormikTextInput
            name={`${props.name}.physicalSupplierName`}
            placeholder={translate('offer.quotationProcess.fuel.physicalSupplierPlaceholder')}
        />
    );
};

PhysicalSupplierDisplay.propTypes = {
    name: PropTypes.string.isRequired,
    editable: PropTypes.bool,
};
