import moment from 'moment';

import { DemandInformationLatestStocks } from '../../../../types/DemandInformationMessage';
import { Tooltip } from '../../../cdl/Tooltip/Tooltip';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { LabelText } from '../../../common/LabelText/LabelText';

interface StockTableHeadingProps {
    latestStocks: DemandInformationLatestStocks[];
}

export const StockTableHeading = (props: StockTableHeadingProps) => {
    const getFormattedLastUpdate = (): string | null => {
        if (!props.latestStocks.length) {
            return null;
        }

        const latestDate = moment.max(props.latestStocks.map((stock) => moment(stock.dateCreated)));
        const today = moment().startOf('day');

        if (latestDate.isSame(today, 'day')) {
            return translate('demand.table.today');
        }

        const yesterday = moment().subtract(1, 'days').startOf('day');

        if (latestDate.isSame(yesterday, 'day')) {
            return translate('demand.table.yesterday');
        }

        return formatDate({
            date: latestDate.toISOString(),
        });
    };

    const formattedLastUpdate = getFormattedLastUpdate();

    if (!formattedLastUpdate) {
        return <span>{translate('demand.table.currentRob')}</span>;
    }

    return (
        <Tooltip
            label={translate('demand.table.currentRobTooltip', {
                lastUpdate: formattedLastUpdate,
            })}
        >
            <LabelText>{translate('demand.table.currentRob')}</LabelText>
        </Tooltip>
    );
};
