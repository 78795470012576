import { variant } from 'styled-system';

export const defaultVariants = variant({
    variants: {
        default: {
            color: 'foreground.default',
            backgroundColor: 'background.subtle',
            borderColor: 'border.muted',
        },
        accent: {
            color: 'accent.tint',
            backgroundColor: 'accent.background',
            borderColor: 'accent.border',
        },
        positive: {
            color: 'positive.tint',
            backgroundColor: 'positive.background',
            borderColor: 'positive.border',
        },
        attention: {
            color: 'attention.tint',
            backgroundColor: 'attention.background',
            borderColor: 'attention.border',
        },
        negative: {
            color: 'negative.tint',
            backgroundColor: 'negative.background',
            borderColor: 'negative.border',
        },
    },
});
