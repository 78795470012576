import css from '@styled-system/css';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Error } from '../../common/Error/Error';
import { formatDate } from '../../common/helpers/formatDate.helper';
import { translate } from '../../common/helpers/translate.helper';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';

import { OfferChatMessage } from './OfferChatMessage';
import { StyledMessage } from './StyledMessage';
import { useMessages } from './useMessages';
import { useScrollToBottom } from './useScrollToBottom';

const Day = styled(Box)(
    css({
        display: 'inline-block',
        width: '30%',
        margin: '20px 35%',
        textAlign: 'center',
        backgroundColor: 'dark-blue.8',
        borderRadius: 1,
        padding: 2,
        fontSize: 1,
        fontWeight: 'semiBold',
    })
);

const EmptyMessage = styled(StyledMessage)(
    css({
        textAlign: 'center',
        margin: '20px auto',
        boxShadow: 'none',
        color: 'dark-blue.0',
        height: '100%',
    })
);

export const OfferChatMessages = ({ offer }) => {
    const { messages, isPending, isError } = useMessages(offer);
    const messageBoxRef = useScrollToBottom({
        dependencies: messages,
    });

    if (isPending) {
        return (
            <Flex height="100%" flexDirection="column" margin={6}>
                <LoadingIndicator />
            </Flex>
        );
    }

    if (isError) {
        return (
            <Flex height="100%" flexDirection="column" margin={6}>
                <Error />
            </Flex>
        );
    }

    if (!messages.length) {
        return <EmptyMessage>{translate('order.emptymessages')}</EmptyMessage>;
    }

    const messageGroupedByDay = messages.reduce((acc, message) => {
        const dateCreated = moment(message.dateCreated).startOf('day').toISOString();
        acc[dateCreated] = acc[dateCreated] || [];
        acc[dateCreated].push(message);
        return acc;
    }, []);

    return (
        <Box ref={messageBoxRef} overflow="auto" height="100%">
            {Object.entries(messageGroupedByDay).map(([dateCreated, messagesOfTheDay]) => (
                <Flex key={dateCreated} flexDirection="column">
                    <Day>{formatDate({ date: dateCreated })}</Day>
                    {messagesOfTheDay.map((message) => (
                        <OfferChatMessage key={message.id} message={message} />
                    ))}
                </Flex>
            ))}
        </Box>
    );
};

OfferChatMessages.propTypes = {
    offer: PropTypes.object.isRequired,
};
