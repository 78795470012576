import styled from 'styled-components';
import { compose, variant } from 'styled-system';

import { theme } from '../../../common/ui/theme';
import { IconButtonProps } from '../IconButton';

const iconColorVariant = variant({
    prop: 'destructive',
    variants: {
        false: {
            color: theme.colors.foreground.subtle,

            ':hover, :active': {
                color: theme.colors.foreground.default,
            },
        },
        true: {
            color: theme.colors.negative.foreground,
        },
    },
});

const buttonEmphasisVariant = variant({
    prop: 'emphasis',
    variants: {
        medium: {
            background: `linear-gradient(0deg, ${theme.colors.background.subtle}, ${theme.colors.background.subtle}, ${theme.colors.white})`,
            backgroundSize: '200% 200%',
            backgroundPosition: '0% 0%',
            borderColor: theme.colors.border.muted,
            transition: 'background-position 100ms ease-out',

            ':hover': {
                backgroundPosition: '100% 100%',
            },
        },
        none: {
            background: 'transparent',
            borderColor: 'transparent',
            transition: 'background-color 100ms ease-out',

            ':hover': {
                backgroundColor: theme.colors.background.subtle,
            },
        },
        transparent: {
            background: 'transparent',
            borderColor: 'transparent',

            ':hover': {
                backgroundColor: 'transparent',
            },

            ':active': {
                backgroundColor: 'transparent',
            },
        },
    },
});

export const ButtonWrapper = styled.button<{
    size: number;
    destructive: IconButtonProps['destructive'];
    emphasis: IconButtonProps['emphasis'];
}>`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    border-color: ${(props) => (props.emphasis === 'medium' ? theme.colors.border.muted : 'transparent')};
    cursor: pointer;
    outline: none;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;

    &:active {
        background: ${theme.colors.background.inset};
    }

    ${compose(iconColorVariant, buttonEmphasisVariant)};

    &:disabled {
        cursor: not-allowed;
        color: ${theme.colors.foreground.disabled};
        background: transparent;
    }
`;
