import PropTypes from 'prop-types';
import { useState } from 'react';

import { Callout } from '../cdl/Callout/Callout';
import { DrawerFormLayout } from '../cdl/Drawer/DrawerFormLayout';
import { FormLegacyCustomerGroupSelect } from '../common/form-elements/CustomerGroupSelect/LegacyCustomerGroupSelect';
import { Form } from '../common/form-elements/Form/Form';
import { translate } from '../common/helpers/translate.helper';
import { useProductContext } from '../common/hooks/useProductContext';
import { useRole } from '../common/hooks/useRole';
import { useUpdateEffect } from '../common/hooks/useUpdateEffect';
import { FormPortSelectWithSchedules } from '../port/DeprecatedPortSelectWithSchedules';

import { FormProductCategoryLimitationsSection } from './FormProductCategoryLimitationsSection';
import { FormVesselLimitationsSection } from './FormVesselLimitationsSection';

export const KeyPortForm = ({ title, onSubmit: onSubmitCallback, data: keyPort = {}, errors = [], update = false }) => {
    const role = useRole();
    const [displayError, setDisplayError] = useState(false);
    const [isValid, setIsValid] = useState(!!keyPort.id || false);
    const [formState, setFormState] = useState(keyPort);
    const { isFuel, context } = useProductContext();

    const [vesselLimitations, setVesselLimitations] = useState(keyPort?.vesselLimitationsVessels || []);

    const [productCategoryLimitations, setProductCategoryLimitations] = useState(
        keyPort?.productCategoryLimitations || []
    );

    useUpdateEffect(() => errors.length && filterErrors(errors), [errors]);

    const filterErrors = () => {
        const duplicateError = errors.find((error) => error.code === 'DUPLICATE' && error.path === 'portId');

        if (duplicateError) {
            setDisplayError(true);
            setIsValid(false);
        }
    };

    const handleChange = ({ data }) => {
        setFormState(data);

        if (displayError) {
            setDisplayError(false);
        }

        setIsValid(data.portId && data.customerGroupId && context);
    };

    const onSubmit = ({ data, event }) => {
        event.preventDefault();
        onSubmitCallback({
            data: {
                ...data,
                productCategoryLimitations: productCategoryLimitations,
                vesselLimitations: vesselLimitations.map((vessel) => vessel.id),
            },
        });
    };

    return (
        <Form height="100%" data={formState} onChange={handleChange} onSubmit={onSubmit}>
            <DrawerFormLayout title={title} isSubmitButtonDisabled={!isValid}>
                {!update && role.isAdmin() ? (
                    <FormLegacyCustomerGroupSelect
                        dataPath="customerGroupId"
                        label={translate('customerGroupSelect.label')}
                        required
                    />
                ) : null}

                <FormPortSelectWithSchedules dataPath="portId" isDirty required />

                {displayError ? (
                    <Callout variant="negative" description={translate('keyPorts.duplicateError')} />
                ) : null}

                <FormVesselLimitationsSection
                    vessels={vesselLimitations}
                    onSubmit={setVesselLimitations}
                    groupId={formState.customerGroupId}
                    context={formState.context}
                />

                {isFuel ? (
                    <FormProductCategoryLimitationsSection
                        productCategories={productCategoryLimitations}
                        onSubmit={setProductCategoryLimitations}
                    />
                ) : null}
            </DrawerFormLayout>
        </Form>
    );
};

KeyPortForm.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    errors: PropTypes.array,
    update: PropTypes.bool,
};
