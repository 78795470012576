import { FuelVesselConsumptionPreset } from '../../../../types/FuelVesselConsumptionPreset';
import { FuelVesselConsumptionPresetsMessage } from '../../../../types/FuelVesselConsumptionPresetsMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const getFuelVesselConsumptionPresets = async (
    vesselId: string
): Promise<FuelVesselConsumptionPresetsMessage> => {
    const response = await authorizedAxiosInstance.get(`/v1/vessel-consumption-presets/${vesselId}`);

    return response.data;
};

interface UpdateFuelVesselConsumptionPresetsConfig {
    vesselId: string;
    data: {
        vesselId: string;
        customerId: string;
        presets: FuelVesselConsumptionPreset[];
    };
}

export const updateFuelVesselConsumptionPresets = async ({
    vesselId,
    data,
}: UpdateFuelVesselConsumptionPresetsConfig): Promise<FuelVesselConsumptionPresetsMessage> => {
    const response = await authorizedAxiosInstance.put(`/v1/vessel-consumption-presets/${vesselId}`, data);

    return response.data;
};
