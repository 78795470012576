import PropTypes from 'prop-types';

import { IconButton } from '../../cdl/IconButton/IconButton';
import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { DataTable } from '../../common/DataTable/DataTable';
import { translate } from '../../common/helpers/translate.helper';
import { useFuelVolumeFormat } from '../../common/hooks/useFuelVolumeFormat';
import { IconPencil } from '../../common/icons/cdl/Pencil';
import { IconTrash } from '../../common/icons/cdl/Trash';
import { Table } from '../../common/Table/Table';
import { Box } from '../../common/ui/Box.tsx';

import { PricingCodeDisplay } from './components/PricingCodeDisplay';
import { ProductDisplay } from './components/ProductDisplay';
import { getPricingCodeFrequencies } from './utils/getPricingCodeFrequencies';

const t = (key) => translate(`contracts.product.${key}`);

export const ProductList = ({ contract, productGroups, onEditClick, onDeleteClick }) => {
    const formatFuelVolume = useFuelVolumeFormat();
    const pricingCodeFrequencies = getPricingCodeFrequencies(contract.pricingDate);

    const columns = [
        {
            label: t('products'),
            width: '30%',
            value: function renderProductListing(productGroup) {
                return <ProductDisplay contract={contract} productGroup={productGroup} />;
            },
        },
        {
            label: t('volume'),
            width: '20%',
            value: (productGroup) => formatFuelVolume({ volume: productGroup.contractVolume }),
        },
        {
            label: t('minStemSize'),
            width: '20%',
            value: (productGroup) =>
                productGroup.minStemSize ? formatFuelVolume({ volume: productGroup.minStemSize }) : '-',
        },
        {
            label: t('pricingCode'),
            width: '20%',
            value: function renderPricingCodeDisplay(productGroup) {
                return (
                    <PricingCodeDisplay productGroup={productGroup} pricingCodeFrequencies={pricingCodeFrequencies} />
                );
            },
        },
        {
            label: t('actions'),
            value: function renderActionButtons(productGroup) {
                return (
                    <ButtonGroup>
                        <IconButton icon={IconPencil} type="button" onClick={() => onEditClick(productGroup)} />

                        <IconButton
                            icon={IconTrash}
                            destructive
                            type="button"
                            onClick={() => onDeleteClick(productGroup)}
                        />
                    </ButtonGroup>
                );
            },
        },
    ];
    return (
        <Box>
            <DataTable columns={columns} rows={productGroups} Table={Table} />
        </Box>
    );
};

ProductList.propTypes = {
    contract: PropTypes.object,
    productGroups: PropTypes.array,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
};
