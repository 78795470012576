import { CategoryWarningType } from '../../../../../../../../types/BunkerCaseSolutionResponse';
import { translate } from '../../../../../../../common/helpers/translate.helper';

export function getWarningTitle(type: CategoryWarningType): string {
    switch (type) {
        case CategoryWarningType.OVERFILL:
            return translate('prePlanning.warnings.overfill');
        case CategoryWarningType.QUARANTINE_VIOLATION:
            return translate('prePlanning.warnings.quarantineViolation');
        case CategoryWarningType.QUARANTINE_REQUIREMENT:
            return translate('prePlanning.warnings.quarantineRequirement');
        case CategoryWarningType.RESERVE_REQ_VIOLATION_START:
            return translate('prePlanning.warnings.reserveReqViolationStart');
        case CategoryWarningType.RESERVE_REQ_VIOLATION_END:
            return translate('prePlanning.warnings.reserveReqViolationEnd');
        case CategoryWarningType.MARGIN_VIOLATION:
            return translate('prePlanning.warnings.marginViolation');
        case CategoryWarningType.COMMINGLING:
            return translate('prePlanning.warnings.commingling');
        case CategoryWarningType.WARNING:
            return translate('prePlanning.warnings.warning');
        case CategoryWarningType.FUEL_SUBSTITUTION:
            return translate('prePlanning.warnings.fuelSubstitution');
    }
}
