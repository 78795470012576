import PropTypes from 'prop-types';

import { Box } from '../../common/ui/Box';
import { VesselTankStocks } from '../../vessel/VesselTanks/VesselTankStocks';

import { useVesselTanks } from './hooks/useVesselTanks';

export const OrderVesselTanks = ({ vesselId }) => {
    const { data: vesselTankResponse, isPending } = useVesselTanks({ vesselId, enabled: !!vesselId });

    if (isPending || !vesselTankResponse.vesselTanks.length) {
        return null;
    }

    return (
        <Box marginY={8}>
            <VesselTankStocks vesselTanks={vesselTankResponse.vesselTanks} />
        </Box>
    );
};

OrderVesselTanks.propTypes = {
    vesselId: PropTypes.string.isRequired,
};
