import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { SolutionSummary } from '../../../../types/BunkerCaseResponse';
import {
    getBunkerMetricBunkerCase,
    getBunkerMetricBunkerCaseSolution,
} from '../../../common/api/clients/bunkerMetric.api';
import { queryKeys } from '../../../common/api/queryKeys';

interface UseBunkerMetricBunkerCaseParams {
    vesselId: string;
    caseNumber: string;
}

export const useBunkerMetricBunkerCase = ({ vesselId, caseNumber }: UseBunkerMetricBunkerCaseParams) => {
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: queryKeys.bunkerMetricBunkerCase({ vesselId, caseNumber }),

        queryFn: () => getBunkerMetricBunkerCase({ vesselId, caseNumber }),
    });

    useEffect(() => {
        const prefetchSolution = async (solutionNumber: string) => {
            return queryClient.prefetchQuery({
                queryKey: queryKeys.bunkerMetricBunkerCaseSolution({
                    vesselId,
                    caseNumber,
                    solutionNumber,
                }),
                queryFn: () =>
                    getBunkerMetricBunkerCaseSolution({
                        vesselId,
                        caseNumber,
                        solutionNumber,
                    }),

                staleTime: 10 * 60 * 1000, // 10 min
            });
        };

        const prefetch = async (solutions: SolutionSummary[]) => {
            for (const solution of solutions) {
                await prefetchSolution(solution.solutionNumber);
            }
        };

        if (!query.data) {
            return;
        }

        prefetch(query.data.solutions);
    }, [query.data, queryClient, vesselId, caseNumber]);

    return query;
};
