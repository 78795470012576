import PropTypes from 'prop-types';

import { useFormData } from '../Form/useFormData';

import { MultipleEntriesInput } from './MultipleEntriesInput';

/**
 * @deprecated use TagListInput instead
 **/

export const FormMultipleEntriesInput = (props) => {
    const { value: entries, setValue: setEntries } = useFormData(props.dataPath, { default: [] });

    const onEntriesChanged = (entries) => {
        setEntries(entries);
    };

    return (
        <MultipleEntriesInput
            inputLabel={props.inputLabel}
            inputPlaceholder={props.inputPlaceholder}
            pattern={props.pattern}
            entries={entries}
            onEntriesChanged={onEntriesChanged}
        />
    );
};

FormMultipleEntriesInput.propTypes = {
    dataPath: PropTypes.string.isRequired,
    inputLabel: PropTypes.string.isRequired,
    inputPlaceholder: PropTypes.string.isRequired,
    pattern: PropTypes.string.isRequired,
};
