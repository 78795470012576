import { Formik, FormikErrors, setIn } from 'formik';

import { Callout } from '../../../../../cdl/Callout/Callout';
import { Modal } from '../../../../../cdl/Modal/Modal';
import { Text } from '../../../../../cdl/Text/Text';
import { translate } from '../../../../../common/helpers/translate.helper';
import { Box } from '../../../../../common/ui/Box';

import { FormikCounterOfferCreateState } from './CounterOfferCreateState';
import { CounterOfferForm } from './CounterOfferForm';

interface CounterOfferCreatePopupProps {
    onDismiss: () => void;
    onSubmit: (values: FormikCounterOfferCreateState) => void;
    counterOfferCreateState: FormikCounterOfferCreateState;
    open: boolean;
}

export const CounterOfferCreatePopup = ({
    onDismiss,
    onSubmit,
    counterOfferCreateState,
    open,
}: CounterOfferCreatePopupProps) => {
    const validate = (values: FormikCounterOfferCreateState): FormikErrors<FormikCounterOfferCreateState> => {
        let errors: FormikErrors<FormikCounterOfferCreateState> = {};

        values.items.forEach((item, index) => {
            if (item.counterPrice) {
                if (Number(item.counterPrice) < 0.01) {
                    errors = setIn(
                        errors,
                        `items.${index}.counterPrice`,
                        translate('counterOffer.form.counterPriceInvalid')
                    );
                }
            } else {
                errors = setIn(
                    errors,
                    `items.${index}.counterPrice`,
                    translate('counterOffer.form.counterPriceRequired')
                );
            }
        });

        if (!values.validityTimeMinutes) {
            errors = setIn(errors, 'validityTimeMinutes', translate('counterOffer.form.validityTimeRequired'));
        }
        return errors;
    };

    return (
        <Modal isOpen={open} onDismiss={onDismiss} dismissible={false}>
            <Box padding={6} display="grid" rowGap={5}>
                <Text variant="title">{translate('counterOffer.createPopup.headline')}</Text>

                <Text variant="small" color="foreground.muted">
                    {translate('counterOffer.createPopup.text')}
                </Text>

                <Callout variant="accent" description={translate('counterOffer.createPopup.hint')} />

                <Formik
                    initialValues={counterOfferCreateState}
                    validateOnMount
                    validate={validate}
                    onSubmit={onSubmit}
                    component={(props) => <CounterOfferForm onCancel={onDismiss} {...props} />}
                />
            </Box>
        </Modal>
    );
};
