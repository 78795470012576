import { ContractMessage, PricingCodeMessage, PricingDateType } from '../../../types/ContractMessage';
import { MoneyMessage } from '../../../types/MoneyMessage';
import { Text } from '../../cdl/Text/Text';
import { FormattedPrice } from '../../vessel/VesselDetailPage/PriceCompare/components/FormattedPrice';
import { PriceDifference } from '../../vessel/VesselDetailPage/PriceCompare/components/PriceDifference';
import { formatDate } from '../helpers/formatDate.helper';
import { formatFuelMoney } from '../helpers/formatFuelMoney.helper';
import { translate } from '../helpers/translate.helper';
import { useEnums } from '../hooks/useEnums';
import { Box } from '../ui/Box';
import { Flex } from '../ui/Flex';

import { FuelPriceTitle } from './FuelPriceTitle';

interface FormattedFuelContractPriceProps {
    price?: MoneyMessage;
    marketPriceDifference?: MoneyMessage;
    positive: boolean;
}
const FormattedFuelContractPrice = ({ price, marketPriceDifference, positive }: FormattedFuelContractPriceProps) => {
    return (
        <Flex alignItems="center" flexWrap="nowrap">
            <FormattedPrice price={price} positive={positive} />
            {marketPriceDifference ? (
                <Box marginLeft={4}>
                    <PriceDifference priceDifference={marketPriceDifference} />
                </Box>
            ) : null}
        </Flex>
    );
};
interface FuelContractPriceDisplayProps {
    hasPlattsCredentials: boolean;
    customerId: string;
    pricingCode?: PricingCodeMessage;
    priceDate?: string;
    price?: MoneyMessage;
    contractBasePriceDifference?: MoneyMessage;
    contractBasePrice?: MoneyMessage;
    supplierName?: string;
    contract?: ContractMessage;
    marketPriceDifference?: MoneyMessage;
    positive: boolean;
    label: string;
}

export const FuelContractPriceDisplay = ({
    hasPlattsCredentials,
    customerId,
    pricingCode,
    priceDate,
    price,
    contractBasePriceDifference,
    contractBasePrice,
    supplierName,
    contract,
    marketPriceDifference,
    positive,
    label,
}: FuelContractPriceDisplayProps) => {
    const { getHumanReadableValue } = useEnums();

    if (
        pricingCode?.code === undefined ||
        priceDate === undefined ||
        contractBasePriceDifference === undefined ||
        contractBasePrice === undefined ||
        supplierName === undefined ||
        contract === undefined
    ) {
        return (
            <>
                <FuelPriceTitle
                    price={price}
                    hasPlattsCredentials={hasPlattsCredentials}
                    label={label}
                    customerId={customerId}
                />
                <FormattedFuelContractPrice
                    price={price}
                    positive={positive}
                    marketPriceDifference={marketPriceDifference}
                />
            </>
        );
    }

    const tooltipLabel = (
        <Box display="flex" flexDirection="column" rowGap={4}>
            <Box>
                <Text
                    dangerouslySetInnerHTML={{
                        __html: translate('platts.contractPriceTooltip', {
                            code: pricingCode?.code,
                            priceDate: formatDate({ date: priceDate }),
                            contractBasePrice: formatFuelMoney({
                                value: contractBasePrice.value,
                                currency: contractBasePrice.currency,
                            }),
                            operator:
                                contractBasePriceDifference.value > 0
                                    ? translate('platts.plus')
                                    : translate('platts.minus'),
                            contractBasePriceDifference: formatFuelMoney({
                                value: Math.abs(contractBasePriceDifference.value),
                                currency: contractBasePriceDifference.currency,
                            }),
                        }),
                    }}
                />
            </Box>
            <Box>
                <Text
                    dangerouslySetInnerHTML={{
                        __html: translate(
                            contract.pricingDate === PricingDateType.MONTHLY_AVERAGE
                                ? 'platts.contractPriceContractMonthlyTooltip'
                                : 'platts.contractPriceContractTooltip',
                            {
                                reference: contract.reference,
                                supplier: supplierName,
                                pricingDate: getHumanReadableValue('pricingDate', contract.pricingDate),
                                daysOperator: contract.pricingDateDays > 0 ? '+' : '-',
                                pricingDateDays: Math.abs(contract.pricingDateDays).toString(),
                                pricingDateMonthType: getHumanReadableValue(
                                    'pricingDateMonthType',
                                    contract.pricingDateMonthType
                                ),
                            }
                        ),
                    }}
                />
            </Box>
        </Box>
    );

    return (
        <>
            <FuelPriceTitle
                price={price}
                hasPlattsCredentials={hasPlattsCredentials}
                label={label}
                customerId={customerId}
                tooltipLabel={tooltipLabel}
            />
            <FormattedFuelContractPrice
                price={price}
                positive={positive}
                marketPriceDifference={marketPriceDifference}
            />
        </>
    );
};
