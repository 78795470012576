SupplierService.$inject = ['SupplierResource'];

function SupplierService(SupplierResource) {
    return {
        list: list,
    };

    function list(filter) {
        return SupplierResource.list(filter);
    }
}

export default {
    name: 'SupplierService',
    fn: SupplierService,
};
