import MagicBell, {
    ClickableNotification,
    FloatingNotificationInbox,
    INotification,
    IRemoteNotification,
} from '@magicbell/magicbell-react';

import demandIllustrationUrl from '../../../../images/illustrations/demand.png';
import AppSettings from '../../../constants';
import { useNavigation } from '../../hooks/useNavigation';
import { useRole } from '../../hooks/useRole';
import { IconBell as BellIconSvg } from '../../icons/Bell';
import { Box } from '../../ui/Box';

import { BellIcon } from './BellIcon';
import { magicBellTheme } from './magicBellTheme';
import { useMagicBellUserConfig } from './useMagicBellUserConfig';

interface CustomClickableNotificationProps {
    notification: IRemoteNotification;
}

const CustomClickableNotification = (props: CustomClickableNotificationProps) => {
    /**
     * we currently do not want to re-build the ClickableNotification, so we
     * "convert" the \r\n to <br/>, as it's directly set as innerHTML in the
     * ClickableNotification component
     */
    const notification = {
        ...props.notification,
        content: props.notification.content ? props.notification.content.replaceAll('\r\n', '<br/>') : null,
    };

    return <ClickableNotification {...props} notification={notification} />;
};

interface CustomNotificationAttributes {
    offerId: string;
    orderId: string;
    offerState: string;
}

export const Notifications = () => {
    const { navigate } = useNavigation();
    const role = useRole();

    const magicBellUserConfigQuery = useMagicBellUserConfig();

    const isOrdered = (state: string) => {
        const orderedStates = [
            'ORDER',
            'CUSTOMER_ADJUSTED',
            'SUPPLIER_ADJUSTED',
            'ADJUSTED',
            'CONFIRMED',
            'ACKNOWLEDGED',
            'CANCELED',
            'DELIVERED',
            'DELIVERY_CONFIRMED',
            'INVOICED',
        ];

        return orderedStates.includes(state);
    };

    const navigateToActionUrl = (notification: INotification) => {
        const customAttributes = notification.customAttributes as any as CustomNotificationAttributes | undefined;

        if (!customAttributes) {
            return;
        }

        if (role.isSupplier()) {
            setTimeout(() => {
                navigate('base.fuel-offerlist-offer', {
                    id: customAttributes.offerId,
                });
            });
            return false;
        }

        if (role.isCustomer()) {
            if (isOrdered(customAttributes.offerState)) {
                setTimeout(() => {
                    navigate('base.fuel-offerlist-offer', {
                        id: customAttributes.offerId,
                    });
                });
                return false;
            }

            setTimeout(() => {
                navigate('base.fuel-orderlist-order', {
                    id: customAttributes.orderId,
                    offerId: customAttributes.offerId,
                    timestamp: new Date().getTime(),
                });
            });
            return false;
        }
    };

    if (magicBellUserConfigQuery.isPending) {
        return (
            <Box color="dark-blue.4">
                <BellIconSvg />
            </Box>
        );
    }

    return (
        <MagicBell
            apiKey={AppSettings.magicBellApiKey}
            userKey={magicBellUserConfigQuery.data?.hmac}
            userExternalId={role.user.id}
            theme={magicBellTheme}
            locale="en"
            images={{
                emptyInboxUrl: demandIllustrationUrl,
            }}
            BellIcon={<BellIcon />}
            Badge={() => null as any as JSX.Element}
        >
            {(props) => (
                <FloatingNotificationInbox
                    {...props}
                    width={400}
                    height={500}
                    notificationPreferencesEnabled={true}
                    onNotificationClick={navigateToActionUrl}
                    NotificationItem={CustomClickableNotification}
                />
            )}
        </MagicBell>
    );
};
