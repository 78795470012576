import { CSSProperties, ReactNode } from 'react';

import { Box } from '../ui/Box';
import { theme } from '../ui/theme';

interface CircleProps extends Pick<CSSProperties, 'strokeDasharray' | 'strokeDashoffset'> {
    color: string;
    radius: number;
    size: number;
}

const Circle = ({ color, radius, strokeDasharray, strokeDashoffset, size }: CircleProps) => {
    const strokeWidth = Math.round(size * 0.125);

    return (
        <circle
            stroke={color}
            strokeWidth={strokeWidth}
            fill="transparent"
            r={radius}
            cx={size / 2}
            cy={size / 2}
            style={{
                strokeDasharray: strokeDasharray,
                strokeDashoffset: strokeDashoffset,
                transition: '0.35s stroke-dashoffset',
                transform: 'rotate(-90deg)',
                transformOrigin: '50% 50%',
            }}
        />
    );
};

interface BudgetDonutChartProps {
    budget: number;
    spentBudget: number;
    children?: ReactNode;
    color?: string;
    backgroundColor?: string;
    size?: number;
}

export const BudgetDonutChart = ({
    budget,
    spentBudget,
    color = theme.colors['clear-blue'][0],
    backgroundColor = theme.colors['clear-blue'][4],
    size = 200,
    children,
}: BudgetDonutChartProps) => {
    const spentBudgetPercent = (spentBudget / budget) * 100;

    //numbers to convert the defined size in relation
    const radiusRatio = 0.35;
    const proportionRatio = 1.5;
    const positionRatio = 0.235;

    const radius = size * radiusRatio;
    const circumference = Math.round(radius * 2 * Math.PI);
    const offset = Math.round(circumference - (spentBudgetPercent / 100) * circumference);
    const formattedOffset = offset > 0 ? offset : 0; //To just display full ring if budget free is negative

    const strokeDasharray = `${circumference}px, ${circumference}px`;
    const strokeDashoffset = `${formattedOffset}px`;

    return (
        <Box position="relative">
            <svg width={size} height={size} display="block">
                <Circle color={backgroundColor} radius={radius} size={size} />
                <Circle
                    color={color}
                    size={size}
                    radius={radius}
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={strokeDashoffset}
                />
            </svg>
            <Box
                position="absolute"
                top={size * positionRatio}
                left={size * positionRatio}
                height={radius * proportionRatio}
                width={radius * proportionRatio}
            >
                {children}
            </Box>
        </Box>
    );
};
