import { ReactNode } from 'react';

import { Text } from '../../cdl/Text/Text';
import { Box } from '../../common/ui/Box';

interface ChartWrapperProps {
    children: ReactNode;
    title: string;
}

export const ChartWrapper = ({ title, children }: ChartWrapperProps) => {
    return (
        <Box display="grid" gridTemplateRows="24px minmax(0, 1fr)" gridTemplateColumns="minmax(0, 1fr)">
            <Text variant="subtitle1">{title}</Text>
            <div>{children}</div>
        </Box>
    );
};
