import { Form, useFormikContext } from 'formik';

import { Button } from '../../cdl/Button/Button';
import { FormikCheckbox } from '../../cdl/Checkbox/FormikCheckbox';
import { FormikInput } from '../../cdl/Input/FormikInput';
import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { FormikPasswordInput } from '../../common/PasswordInput/FormikPasswordInput';
import { Box } from '../../common/ui/Box';
import { FormikStrengthMeter } from '../../user/StrengthMeter/FormikStrengthMeter';
import { FormikRegistrationState } from '../pages/OnboardPage';

import { Link } from './Link';

export const FormikOnboardForm = () => {
    const { isSubmitting } = useFormikContext<FormikRegistrationState>();

    return (
        <Form>
            <Box display="grid" gridRowGap={5} marginBottom={7} marginTop={6}>
                <Box display="grid" gridTemplateColumns="1fr 1fr" gridColumnGap={5}>
                    <FormikInput name="firstName" label={translate('onboard.firstName')} />
                    <FormikInput name="lastName" label={translate('onboard.lastName')} />
                </Box>
                <FormikInput name="phone" label={translate('onboard.enterPhoneNumber')} />
                {/* This hidden input is used for password managers to store the email address */}
                <Box style={{ display: 'none' }}>
                    <FormikInput name="emailAddress" />
                </Box>
                <FormikPasswordInput name="password" minLength={6} label={translate('onboard.enterPassword')} />
                <FormikStrengthMeter name="password" />
            </Box>
            <Box marginBottom={5}>
                <FormikCheckbox
                    label={
                        <Text variant="extraSmall" as="p">
                            {translate('onboard.allowMarketingCommunication')}{' '}
                            <Link target="_blank" href="//www.closelink.com/privacy-policy">
                                {translate('onboard.privacyPolicy')}
                            </Link>
                        </Text>
                    }
                    name="newsletterEnabled"
                />
            </Box>
            <Button width="100%" emphasis="high" size="large" isLoading={isSubmitting} type="submit">
                {translate('onboard.completeRegistration')}
            </Button>
        </Form>
    );
};
