import { AxiosResponse } from 'axios';

import { ApprovalRequestRequestMessage } from '../../../../types/ApprovalRequestRequestMessage';
import { OfferApprovalSettingsMessage } from '../../../../types/OfferApprovalSettingsMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const getOfferApprovalSettings = async (offerId: string): Promise<OfferApprovalSettingsMessage> => {
    const response = await authorizedAxiosInstance.get(`/v1/offers/${offerId}/approval-settings`);

    return response.data;
};

export const createApprovalRequest = async ({
    offerId,
    approvalRequestMessage,
}: {
    offerId: string;
    approvalRequestMessage: ApprovalRequestRequestMessage;
}): Promise<AxiosResponse> => {
    return authorizedAxiosInstance.post(`/v1/offers/${offerId}/approval-requests`, approvalRequestMessage);
};
