import { Flex } from '../../common/ui/Flex';

import { Pagination } from './Pagination';

interface CenteredPaginationProps {
    pageTotal: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}

export const CenteredPagination = ({ pageTotal, currentPage, onPageChange }: CenteredPaginationProps) => {
    if (pageTotal <= 1) {
        return null;
    }

    return (
        <Flex textAlign="center" justifyContent="center" marginTop={6}>
            <Pagination currentPage={currentPage} pageTotal={pageTotal} onPageChange={onPageChange} />
        </Flex>
    );
};
