import { components, MenuProps } from 'react-select';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const CustomMenu = styled(components.Menu)<MenuProps>`
    && {
        box-shadow: none;
        margin-top: ${get('space.2')}px;
        margin-bottom: 0;
        z-index: 9999;
    }
`;
