import styled from 'styled-components';

import { get } from '../../../common/ui/get';

import { CoreInputProps } from './CoreInput';

interface LeadingVisualWrapperProps extends Pick<CoreInputProps, 'disabled'> {}

export const LeadingVisualWrapper = styled.div<LeadingVisualWrapperProps>`
    padding-left: 10px;
    color: ${(props) => (props.disabled ? get('colors.foreground.disabled') : get('colors.foreground.default'))};
`;
