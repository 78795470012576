import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { getMessages } from '../../common/api/clients/gateway.api';
import { queryKeys } from '../../common/api/queryKeys';
import { useAbly } from '../../common/hooks/useAbly';
import { useRole } from '../../common/hooks/useRole';

export const useMessages = (offer) => {
    const role = useRole();
    const query = useQuery({
        queryKey: queryKeys.messages(offer.id),
        queryFn: () => getMessages(offer.id),
    });
    const { subscribe, unsubscribe } = useAbly();

    useEffect(() => {
        if (!role) {
            return;
        }

        const topic = role?.isCustomer()
            ? `customers:${offer.customerId}:offers:${offer.id}:messages`
            : `suppliers:${offer.supplierId}:offers:${offer.id}:messages`;

        subscribe(topic, () => {
            query.refetch();
        });

        return () => unsubscribe(topic);
    });

    return {
        ...query,
        messages: query.data ?? [],
    };
};
