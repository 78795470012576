import { useCallback, useEffect, useState } from 'react';
import { MultiValue, OnChangeValue } from 'react-select';

import { ComboBox, ComboBoxProps, DefaultOption } from '../../../cdl/ComboBox/ComboBox';
import { getPorts, GetPortsParams } from '../../api/clients/port.api';
import { formatPort } from '../../helpers/formatPort.helper';

export interface PortSelectProps<IsMulti extends boolean>
    extends Omit<ComboBoxProps<DefaultOption, IsMulti>, 'onChange' | 'value'> {
    onChange: (option: IsMulti extends true ? string[] : string) => void;
    value: IsMulti extends true ? string[] : string;
}

export function PortSelect<IsMulti extends boolean>({ ...rest }: PortSelectProps<IsMulti>) {
    const [selectedValue, setSelectedValue] = useState<DefaultOption[]>([]);

    const loadPorts = useCallback((query: GetPortsParams): Promise<DefaultOption[]> => {
        return getPorts(query).then((response) => {
            return response.content.map((port) => ({
                value: port.id,
                label: formatPort({
                    port,
                }) as DefaultOption['label'],
            }));
        });
    }, []);

    useEffect(() => {
        let isMounted = true;

        if (rest.value?.length) {
            loadPorts({
                ids: Array.isArray(rest.value) ? rest.value : [rest.value],
            }).then((options) => {
                if (isMounted) setSelectedValue(options);
            });
        } else {
            setSelectedValue([]);
        }

        return () => {
            isMounted = false;
        };
    }, [rest.value, loadPorts]);

    const loadPortsByInput = (searchQuery?: string) => loadPorts({ searchQuery });

    function updateSelectedValue(options: OnChangeValue<DefaultOption, IsMulti>) {
        if (rest.isMulti) {
            rest.onChange(
                (options as MultiValue<DefaultOption>).map((it) => it.value) as IsMulti extends true ? string[] : string
            );
        } else {
            rest.onChange((options as DefaultOption).value as IsMulti extends true ? string[] : string);
        }
    }

    return (
        <ComboBox
            {...rest}
            value={rest.isMulti ? selectedValue : selectedValue[0]}
            onChange={updateSelectedValue}
            loadOptions={loadPortsByInput}
        />
    );
}
