import { Form, Formik } from 'formik';

import { Button } from '../../../../cdl/Button/Button';
import { Modal } from '../../../../cdl/Modal/Modal';
import { Text } from '../../../../cdl/Text/Text';
import { FormikTextArea } from '../../../../cdl/TextArea/FormikTextArea';
import { translate } from '../../../../common/helpers/translate.helper';
import { Box } from '../../../../common/ui/Box';

interface FormikCancelOrderValues {
    cancelReason: string;
}

interface OrderCancelModalProps {
    isOpen: boolean;
    onSubmit: (cancelReason: string) => void;
    onDismiss: () => void;
    isSubmitting: boolean;
}

export const OrderCancelModal = ({ isOpen, onSubmit, onDismiss, isSubmitting }: OrderCancelModalProps) => {
    const validate = (values: FormikCancelOrderValues) => {
        if (!values.cancelReason) {
            return { cancelReason: translate('alert.cancelorder.error') };
        }

        return {};
    };

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} dismissible={false} scrollable={false}>
            <Formik
                validateOnBlur={false}
                validate={validate}
                initialValues={{ cancelReason: '' }}
                onSubmit={(data) => onSubmit(data.cancelReason)}
            >
                <Form>
                    <Box display="flex" flexDirection="column" gap={5} padding={6}>
                        <Text variant="title">{translate('alert.cancelorder.title')}</Text>
                        <Text color="foreground.muted" variant="body">
                            {translate('alert.cancelorder.message')}
                        </Text>

                        <FormikTextArea name="cancelReason" label={translate('alert.cancelorder.label')} />

                        <Box display="flex" justifyContent="flex-end" gap={4}>
                            <Button type="button" onClick={onDismiss} emphasis="medium">
                                {translate('btn.goBack')}
                            </Button>
                            <Button type="submit" emphasis="high" isLoading={isSubmitting} destructive>
                                {translate('alert.cancelorder.btnok')}
                            </Button>
                        </Box>
                    </Box>
                </Form>
            </Formik>
        </Modal>
    );
};
