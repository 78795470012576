import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getSurcharges } from '../clients/gateway.api';
import { getSpotAvailability, getSupplierGroup } from '../clients/supplierGroups.api';

export const supplierGroups = createQueryKeys('supplierGroups', {
    detail: (groupId: string) => ({
        queryKey: [groupId],
        queryFn: () => getSupplierGroup(groupId),
        contextQueries: {
            surcharges: {
                queryKey: null,
                queryFn: () => getSurcharges(groupId),
            },
        },
    }),
    spotAvailability: (portCountryCode: string, testOrder: boolean) => ({
        queryKey: [{ portCountryCode, testOrder }],
        queryFn: () => getSpotAvailability(portCountryCode, testOrder),
    }),
});
