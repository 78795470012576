
            import AppSettings from './app/js/constants';

            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag('js', new Date());

            gtag('config', AppSettings.googleAnalyticsId);
        