import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UserUpdateMessage } from '../../../../types/UserUpdateMessage';
import { updateUser } from '../../../common/api/clients/user.api';
import { queryKeys } from '../../../common/api/queryKeys';

export const useUserUpdate = (userId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (user: UserUpdateMessage) => {
            return updateUser(userId, user);
        },

        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.users() });
        },
    });
};
