import { Button } from '../../cdl/Button/Button';
import { Modal } from '../../cdl/Modal/Modal';
import { Text } from '../../cdl/Text/Text';
import { translate } from '../helpers/translate.helper';
import { Box } from '../ui/Box';

interface DeleteConfirmPopupProps {
    isOpen: boolean;
    onSubmit: () => void;
    onDismiss: () => void;
    headline: string;
    text: string;
    itemToDelete?: string;
    deleteButtonText?: string;
    isSubmitting?: boolean;
}

export const DeleteConfirmPopup = ({
    isOpen,
    onSubmit,
    onDismiss,
    headline,
    text,
    itemToDelete,
    deleteButtonText = translate('btn.delete'),
    isSubmitting = false,
}: DeleteConfirmPopupProps) => {
    return (
        <Modal isOpen={isOpen} size="small" scrollable={false} onDismiss={onDismiss} dismissible={false}>
            <Box display="grid" gap={5} padding={6}>
                <Text variant="title">{headline}</Text>

                <Text variant="small" color="foreground.muted">
                    {text}
                </Text>

                {itemToDelete ? (
                    <Text
                        style={{
                            wordBreak: 'break-all',
                        }}
                        variant="small"
                    >
                        &bull; {itemToDelete}
                    </Text>
                ) : null}

                <Box display="flex" justifyContent="end" gap={4}>
                    <Button emphasis="medium" type="button" onClick={onDismiss}>
                        {translate('btn.goBack')}
                    </Button>
                    <Button emphasis="high" type="button" destructive onClick={onSubmit} isLoading={isSubmitting}>
                        {deleteButtonText}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
