import { useAngularRouterLocation } from '../../../common/hooks/useAngularRouterLocation';
import { useNavigation } from '../../../common/hooks/useNavigation';
import { useProductContext } from '../../../common/hooks/useProductContext';

export const useEnquiryNavigateBack = () => {
    const { origin } = useAngularRouterLocation<{ origin: { stateName: string; params: { tab: string } } }>();
    const { navigate } = useNavigation();
    const { isFuel } = useProductContext();

    const navigateBack = () => {
        if (!origin) {
            if (isFuel) {
                void navigate('base.fuel-dashboard');
                return;
            }
            void navigate('base.dashboard');
            return;
        }

        void navigate(origin.stateName, origin.params);
    };

    return {
        navigateBack,
    };
};
