import { ReactNode } from 'react';

import { Popover } from '../../cdl/Popover/Popover';
import { PositionMarker } from '../../common/PositionMarker/PositionMarker';

interface GlobalPricesPortMarkerProps {
    color: string;
    lat: number;
    lng: number;
    style?: object;
    children: ReactNode;
}

export const GlobalPricesPortMarker = ({ style, color, children }: GlobalPricesPortMarkerProps) => {
    return <Popover trigger={<PositionMarker color={color} style={style} />}>{children}</Popover>;
};
