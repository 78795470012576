import { FileMessage } from '../../../../types/FileMessage';
import { AttachmentsConfigContext } from '../hooks/AttachmentsConfigContext';
import { useUploadAreaReducer } from '../hooks/useUploadAreaReducer';
import { AttachmentsConfig } from '../types/AttachmentsConfig';

import { AttachmentList } from './AttachmentList';
import { UploadArea } from './UploadArea';

interface AttachmentsProps {
    files: FileMessage[];
    config: AttachmentsConfig;
    companyId?: string;
    counterCompanyName?: string;
}

export const Attachments = ({ files, companyId, counterCompanyName, config }: AttachmentsProps) => {
    const [uploadedFiles, dispatch] = useUploadAreaReducer();

    const addFilesToUpload = (files: File[]) => {
        dispatch({
            type: 'ADD_FILES',
            files,
        });
    };

    const updateFilesInUpload = (uploadResponse: FileMessage, fileId: string) => {
        dispatch({
            type: 'ADD_UPLOAD_RESPONSE',
            uploadResponse,
            fileId,
        });
    };

    const deleteFile = async (fileId: string) => {
        dispatch({
            type: 'DELETE_FILE',
            fileId,
        });
    };

    if (!config.hasWriteRights) {
        return (
            <AttachmentsConfigContext.Provider value={config}>
                <AttachmentList
                    hasWriteRights={false}
                    files={files}
                    companyId={companyId}
                    counterCompanyName={counterCompanyName}
                />
            </AttachmentsConfigContext.Provider>
        );
    }

    const uploadedFileIds = uploadedFiles
        .map((uploadedFile) => uploadedFile.uploadResponse?.id)
        .filter(Boolean) as string[];

    const filesToShowAsUploaded = files.filter((file) => !uploadedFileIds.includes(file.id));

    return (
        <AttachmentsConfigContext.Provider value={config}>
            <UploadArea
                onFilesUpload={addFilesToUpload}
                uploadedFiles={uploadedFiles.filter((file) => !file.deleted)}
                onDeleteSuccess={deleteFile}
                onUploadSuccess={updateFilesInUpload}
            />

            <AttachmentList
                hasWriteRights={config.hasWriteRights}
                files={filesToShowAsUploaded}
                companyId={companyId}
                counterCompanyName={counterCompanyName}
            />
        </AttachmentsConfigContext.Provider>
    );
};
