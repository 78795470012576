import PropType from 'prop-types';

import { Drawer } from './Drawer';
import { DrawerContextProvider } from './DrawerContextProvider';

export const DrawerAppWrapper = ({ children }) => (
    <DrawerContextProvider>
        <Drawer />
        {children}
    </DrawerContextProvider>
);

DrawerAppWrapper.propTypes = {
    children: PropType.node,
};
