import { useQueryClient } from '@tanstack/react-query';
import { AxiosProgressEvent, AxiosResponse } from 'axios';
import { useFormikContext } from 'formik';

import { FileManagementResponse } from '../../../../../types/FileManagementResponse';
import * as offerFileManagementApi from '../../../../common/api/clients/offerFileManagement.api';
import { queryKeys } from '../../../../common/api/queryKeys';
import { Attachments } from '../../../../common/Attachments/components/Attachments';
import { OfferModel } from '../../../model/OfferModel';

interface FuelQuoteAttachmentsProps {
    offer: OfferModel;
}

export const FuelQuoteAttachments = ({ offer }: FuelQuoteAttachmentsProps) => {
    const formikContext = useFormikContext();
    const queryClient = useQueryClient();

    const uploadFile = (
        file: File,
        onUploadProgress: (event: AxiosProgressEvent) => void
    ): Promise<FileManagementResponse> => {
        return offerFileManagementApi.uploadFile(file, offer.id, onUploadProgress);
    };

    const deleteFile = (fileId: string): Promise<void> => {
        return offerFileManagementApi.deleteFile([fileId], offer.id);
    };

    const downloadFile = (fileId: string): Promise<AxiosResponse> => {
        return offerFileManagementApi.downloadFile(fileId, offer.id);
    };

    const invalidateCache = async () => {
        await queryClient.cancelQueries({ queryKey: queryKeys.offer(offer.id) });
        await queryClient.invalidateQueries({ queryKey: queryKeys.offer(offer.id) });

        const updatedOffer = queryClient.getQueryData<OfferModel>(queryKeys.offer(offer.id))!!;

        formikContext.setFieldValue('files', updatedOffer.files);
        formikContext.setFieldValue('fileIds', updatedOffer.fileIds);
    };

    return (
        <Attachments
            files={offer.files}
            companyId={offer.supplierId}
            counterCompanyName={offer.customer.name}
            config={{
                uploadFile,
                deleteFile,
                downloadFile,
                invalidateCache,
                hasWriteRights: true,
                enableDownloadUploadedFiles: true,
            }}
        />
    );
};
