import { Form, Formik, FormikErrors } from 'formik';
import * as uuid from 'uuid';

import { ConsumptionCalculationBase } from '../../../../types/ConumptionCalculationBase';
import { LubesConsumptionPreset } from '../../../../types/LubesConsumptionPresetsRequestMessage';
import { Button } from '../../../cdl/Button/Button';
import { Text } from '../../../cdl/Text/Text';
import { sortLubesCategory } from '../../../common/helpers/sortByCategory.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { OverlayHeader } from '../../../common/Overlay/OverlayHeader';
import { Box } from '../../../common/ui/Box';

import { PresetsAccordion } from './PresetsAccordion';

export interface FormikEditPresetsState {
    presets: FormikCategoryPresetState[];
}

export interface FormikCategoryPresetState {
    id: string;
    vesselTankCategory?: string;
    consumptionCalculationBase: ConsumptionCalculationBase;
    excludePortCalls: boolean;
    fixedAverageDailyConsumption?: string;
}

interface EditPresetsFormProps {
    vesselId: string;
    presets: LubesConsumptionPreset[];
    onClose: () => void;
    onSubmit: (presets: LubesConsumptionPreset[]) => void;
}

export const EditPresetsForm = ({ presets, onClose, onSubmit }: EditPresetsFormProps) => {
    const initialValues: FormikEditPresetsState = {
        presets: presets.length
            ? presets.map((preset) => ({
                  id: uuid.v4(),
                  vesselTankCategory: preset.vesselTankCategory,
                  consumptionCalculationBase: preset.consumptionCalculationBase,
                  excludePortCalls: preset.excludePortCalls,
                  fixedAverageDailyConsumption: preset.fixedAverageDailyConsumption?.toString(),
              }))
            : [
                  {
                      id: uuid.v4(),
                      vesselTankCategory: undefined,
                      consumptionCalculationBase: ConsumptionCalculationBase.DYNAMIC,
                      excludePortCalls: false,
                      fixedAverageDailyConsumption: undefined,
                  },
              ],
    };

    const validate = (values: FormikEditPresetsState) => {
        const errors: FormikErrors<FormikEditPresetsState> = {};
        errors.presets = values.presets.map((preset, index) => {
            const presetErrors = (errors.presets?.[index] || {}) as FormikErrors<FormikCategoryPresetState>;
            if (!preset.vesselTankCategory) {
                presetErrors.vesselTankCategory = translate('consumption.editPresets.validation.category.required');
            } else {
                const isDuplicate = values.presets.some(
                    (p, i) => p.vesselTankCategory === preset.vesselTankCategory && i !== index
                );
                if (isDuplicate) {
                    presetErrors.vesselTankCategory = translate(
                        'consumption.editPresets.validation.category.duplicate'
                    );
                }
            }
            if (
                !preset.fixedAverageDailyConsumption &&
                preset.consumptionCalculationBase === ConsumptionCalculationBase.STATIC
            ) {
                presetErrors.fixedAverageDailyConsumption = translate(
                    'consumption.editPresets.validation.fixed.required'
                );
            }
            return presetErrors;
        });

        if (errors.presets.filter((preset) => Object.keys(preset).length > 0).length === 0) {
            return {};
        }

        return errors;
    };

    const handleSubmit = (values: FormikEditPresetsState) => {
        const updatedPresets: LubesConsumptionPreset[] = values.presets
            .map((preset) => ({
                vesselTankCategory: preset.vesselTankCategory || '',
                consumptionCalculationBase: preset.consumptionCalculationBase || ConsumptionCalculationBase.DYNAMIC,
                excludePortCalls: preset.excludePortCalls,
                fixedAverageDailyConsumption: preset.fixedAverageDailyConsumption
                    ? parseFloat(preset.fixedAverageDailyConsumption)
                    : undefined,
            }))
            .sort((a, b) => sortLubesCategory(a.vesselTankCategory, b.vesselTankCategory));
        onSubmit(updatedPresets);
    };

    return (
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
            {(formikProps) => (
                <Form style={{ height: '100%' }}>
                    <OverlayHeader
                        title={translate('consumption.editPresets.title')}
                        onClose={onClose}
                        trailingVisual={
                            <Button emphasis="high" type="submit" isLoading={formikProps.isSubmitting}>
                                {translate('btn.save.default')}
                            </Button>
                        }
                    >
                        <Box display="grid" height="100%" paddingY={6}>
                            <Box display="grid" height="100%" rowGap={3} paddingBottom={7}>
                                <Text variant="title">{translate('consumption.editPresets.title')}</Text>
                                <Text variant="body" color="foreground.muted">
                                    {translate('consumption.editPresets.description')}
                                </Text>
                            </Box>
                            <PresetsAccordion></PresetsAccordion>
                        </Box>
                    </OverlayHeader>
                </Form>
            )}
        </Formik>
    );
};
