import styled from 'styled-components';

import { translate } from '../../../common/helpers/translate.helper';
import { IconX } from '../../../common/icons/cdl/X';
import { get } from '../../../common/ui/get';
import { Text } from '../../Text/Text';

interface ClearFiltersProps {
    onClearClick: () => void;
}

const PillWithoutBorder = styled.div`
    height: 24px;
    display: inline-flex;
    align-items: center;
    color: ${get('colors.foreground.subtle')};
    gap: ${get('space.2')}px;
    transition: color 125ms;
    cursor: pointer;

    &:hover {
        color: ${get('colors.foreground.default')};
    }
`;

export const ClearFilters = (props: ClearFiltersProps) => {
    return (
        <PillWithoutBorder onClick={props.onClearClick}>
            <IconX width={16} height={16} />
            <Text variant="extraSmall" fontWeight="semiBold">
                {translate('global.clearFilter.clearFilters')}
            </Text>
        </PillWithoutBorder>
    );
};
