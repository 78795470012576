import { SulphurContent } from '../../../types/SulphurContent';

import { translate } from './translate.helper';

export function translateSulphurContent(sulphurContent?: SulphurContent): string {
    if (sulphurContent === undefined) {
        return '';
    }

    return translate(`sulphurContent.${sulphurContent}`);
}
