import { variant } from 'styled-system';

import { get } from '../../../common/ui/get';

export function getDefaultEmphasisVariant() {
    return variant({
        prop: 'emphasis',
        variants: {
            high: {
                color: get('colors.foreground.onEmphasis'),
                background: `linear-gradient(0deg, ${get('colors.accent.emphasis')({})}, ${get(
                    'colors.accent.emphasis'
                )({})}, #3386FF)`,
                borderStyle: 'none',
                backgroundSize: '200% 200%',
                backgroundPosition: '0% 0%',
                transition: 'background-position 100ms ease-out',
                padding: '4px 8px',

                ':hover': {
                    backgroundPosition: '100% 100%',
                },
                ':active': {
                    background: get('colors.accent.foreground'),
                },
            },
            medium: {
                color: get('colors.foreground.default'),
                background: `linear-gradient(0deg, ${get('colors.background.subtle')({})}, ${get(
                    'colors.background.subtle'
                )({})}, ${get('colors.white')({})})`,
                borderColor: get('colors.border.muted'),
                borderStyle: 'solid',
                padding: '3px 7px',
                backgroundSize: '200% 200%',
                backgroundPosition: '0% 0%',
                transition: 'background-position 100ms ease-out',

                ':hover': {
                    backgroundPosition: '100% 100%',
                },
                ':active': {
                    background: get('colors.background.inset'),
                },
            },
            low: {
                color: get('colors.accent.foreground'),
                background: 'transparent',
                borderStyle: 'none',
                padding: '4px 8px',

                ':hover': {
                    background: get('colors.background.subtle'),
                },
                ':active': {
                    background: get('colors.background.inset'),
                },
            },
            none: {
                color: get('colors.foreground.default'),
                background: 'transparent',
                borderStyle: 'none',
                padding: '4px 8px',

                ':hover': {
                    background: get('colors.background.subtle'),
                },
                ':active': {
                    background: get('colors.background.inset'),
                },
            },
        },
    });
}
