import React from 'react';

import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { ProductsOverviewPage } from './ProductsOverviewPage';

const ProductsOverviewPageReactComponent = (props: any) => {
    return (
        <AppProviders>
            <ProductsOverviewPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(ProductsOverviewPageReactComponent, 'reactProductsOverviewPage');
