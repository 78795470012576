import { queries } from '../common/api/queryKeys/queries';
import { usePaginationQuery } from '../common/hooks/usePaginationQuery';
import { useProductContext } from '../common/hooks/useProductContext';

export const useKeyPortPagination = ({ page, searchQuery, customerGroupIds }) => {
    const { context } = useProductContext();

    return usePaginationQuery({
        queryKey: queries.keyPorts.pagination({ page, searchQuery, customerGroupIds, context }).queryKey,
        queryFn: queries.keyPorts.pagination({ page, searchQuery, customerGroupIds, context }).queryFn,
        options: { keepPreviousData: true },
        prefetchQueryKey: queries.keyPorts.pagination({ page: page + 1, searchQuery, customerGroupIds, context })
            .queryKey,
        prefetchQueryFn: queries.keyPorts.pagination({ page: page + 1, searchQuery, customerGroupIds, context })
            .queryFn,
    });
};
