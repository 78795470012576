import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { useAttachmentsConfig } from './useAttachmentsConfig';

export const useFileDelete = (onDeleteSuccess?: () => void): UseMutationResult<void, unknown, string> => {
    const { deleteFile, invalidateCache } = useAttachmentsConfig();
    const mutation = useMutation({
        mutationFn: (fileId: string) => deleteFile(fileId),
    });

    const mutateAsync = (fileId: string): Promise<void> => {
        return mutation.mutateAsync(fileId, {
            onSuccess: async () => {
                await invalidateCache();
                onDeleteSuccess?.();
            },
        });
    };

    return {
        ...mutation,
        mutateAsync,
    };
};
