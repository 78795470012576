import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateCustomerGroup } from '../../../../common/api/clients/customerGroups.api';
import { queries } from '../../../../common/api/queryKeys/queries';

export const useCustomerGroupUpdate = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateCustomerGroup,
        onSuccess: (response) => {
            return queryClient.invalidateQueries({ queryKey: queries.customerGroups.detail(response.id).queryKey });
        },
    });
};
