import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getContract } from '../../common/api/clients/gateway.api';
import { queryKeys } from '../../common/api/queryKeys';

export const useContractDetails = (id) => {
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: queryKeys.contract(id),
        queryFn: () => getContract(id),
        enabled: !!id,
    });

    const prefetch = (id) => {
        queryClient.prefetchQuery({ queryKey: queryKeys.contract(id), queryFn: () => getContract(id) });
    };

    return {
        ...query,
        prefetch,
    };
};
