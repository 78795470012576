import { useEffect } from 'react';

import { useAngularDependencies } from '../../common/hooks/useAngularDependencies';

export const useOnboardingSessionReset = () => {
    const { SessionStorageService } = useAngularDependencies();

    // Make sure that there is no old token.
    useEffect(() => {
        SessionStorageService.resetToken();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
