import { ReachInformationCategory } from '../../../../types/ReachInformationMessage';
import { sortLubesCategory } from '../../../common/helpers/sortByCategory.helper';
import { Box } from '../../../common/ui/Box';
import { Frame } from '../ScheduleStop';

import { LubesPriceCompareCategory } from './LubesPriceCompareCategory';

interface LubesSchedulePriceCompareProps {
    prices: { [category: string]: unknown[] };
    reachInformation?: { [category: string]: ReachInformationCategory };
}

export const LubesSchedulePriceCompare = ({ prices, reachInformation }: LubesSchedulePriceCompareProps) => {
    return (
        <Box>
            <Box marginX={4} backgroundColor="background.inset" height="8px" />
            <Frame>
                {Object.keys(prices)
                    .sort(sortLubesCategory)
                    .map((category, index) => {
                        return (
                            <LubesPriceCompareCategory
                                priceData={prices[category]}
                                category={category}
                                reachInformation={reachInformation?.[category]}
                                key={index}
                            />
                        );
                    })}
            </Frame>
        </Box>
    );
};
