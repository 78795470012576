import { useState } from 'react';

import { Button } from '../../../../../../../cdl/Button/Button';
import { translate } from '../../../../../../../common/helpers/translate.helper';

import { RecommendedContract, RecommendedContractPopup } from './RecommendedContractPopup';

export const RecommendedContractButton = (props: { recommendedContract: RecommendedContract }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Button emphasis="low" size="small" onClick={() => setIsOpen(true)}>
                {translate('prePlanning.solutionDetails.stopTable.recommendedContractBtn')}
            </Button>
            <RecommendedContractPopup
                onDismiss={() => setIsOpen(false)}
                isOpen={isOpen}
                recommendedContract={props.recommendedContract}
            />
        </>
    );
};
