import PropTypes from 'prop-types';

import { useToasts } from '../cdl/Toast/useToasts';
import { translate } from '../common/helpers/translate.helper';
import { useProductContext } from '../common/hooks/useProductContext';
import { useRole } from '../common/hooks/useRole';

import { KeyPortForm } from './KeyPortForm';
import { useKeyPortCreate } from './useKeyPortCreate';

export const CreateKeyPortForm = ({ onSuccess }) => {
    const role = useRole();
    const { addToast, addErrorToast } = useToasts();

    const { mutate: createKeyPort, error } = useKeyPortCreate({
        onSuccess: () => {
            addToast(translate('keyPorts.toast.createKeyPort.success'));

            onSuccess();
        },
        onError: (error) => {
            if (error.response.status !== 400) {
                addErrorToast(translate('keyPorts.toast.createKeyPort.error'));
            }
        },
    });
    const { context } = useProductContext();

    const errors = error?.response.data.errors;

    const handleSubmit = ({ data: keyPort }) => {
        createKeyPort(keyPort);
    };

    return (
        <KeyPortForm
            title={translate('keyPorts.createTitle')}
            onSubmit={handleSubmit}
            errors={errors}
            data={{
                context,
                customerGroupId: !role.isAdmin() ? role.user.groupId : null,
            }}
        />
    );
};

CreateKeyPortForm.propTypes = {
    onSuccess: PropTypes.func,
};
