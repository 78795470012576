import { AccessLevel } from '../../../../types/AccessLevel';
import { UserRole } from '../../../../types/UserRole';

export const groupAccessLevel = {
    [UserRole.GROUP_ADMIN]: AccessLevel.ADMIN,
    [UserRole.COMPANY_EMPLOYEE]: AccessLevel.READ,
    [UserRole.COMPANY_INSPECTOR]: AccessLevel.READ,
    [UserRole.COMPANY_ADMIN]: AccessLevel.WRITE,
};

export const companyAccessLevel = {
    [UserRole.COMPANY_EMPLOYEE]: AccessLevel.WRITE,
    [UserRole.COMPANY_INSPECTOR]: AccessLevel.READ,
    [UserRole.COMPANY_ADMIN]: AccessLevel.ADMIN,
};
