import { ApprovalRequestMessage } from '../../../../../../../types/ApprovalRequestMessage';
import { ApprovalRequestState } from '../../../../../../../types/ApprovalRequestState';
import { OfferApprovalSettingsMessage } from '../../../../../../../types/OfferApprovalSettingsMessage';
import { Button } from '../../../../../../cdl/Button/Button';
import { translate } from '../../../../../../common/helpers/translate.helper';

import { OrderButton } from './OrderButton';

interface RequestApprovalButtonProps {
    onClick: () => void;
}

const RequestApprovalButton = ({ onClick }: RequestApprovalButtonProps) => {
    return (
        <Button emphasis="high" width="100%" onClick={onClick}>
            {translate('approvalRequest.buttons.requestApproval')}
        </Button>
    );
};

interface RequestButtonProps {
    approvalRequests: ApprovalRequestMessage[];
    offerApprovalSettings: OfferApprovalSettingsMessage;
    onRequestApprovalButtonClicked: () => void;
}

export const RequestButton = (props: RequestButtonProps) => {
    if (!props.approvalRequests.length && props.offerApprovalSettings.requiresApproval) {
        return <RequestApprovalButton onClick={props.onRequestApprovalButtonClicked} />;
    }

    if (!props.offerApprovalSettings.requiresApproval) {
        return <OrderButton />;
    }

    const latestApprovalRequest = props.approvalRequests[0];

    switch (latestApprovalRequest.state) {
        case ApprovalRequestState.PENDING:
            return (
                <Button emphasis="high" width="100%" onClick={props.onRequestApprovalButtonClicked}>
                    {translate('approvalRequest.buttons.sendRequestAgain')}
                </Button>
            );
        case ApprovalRequestState.DECLINED:
        case ApprovalRequestState.WITHDRAWN:
        case ApprovalRequestState.EXPIRED:
            return <RequestApprovalButton onClick={props.onRequestApprovalButtonClicked} />;
        case ApprovalRequestState.APPROVED:
            return <OrderButton />;
    }
};
