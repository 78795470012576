import { EmptyPagePlaceholder } from '../../common/EmptyPagePlaceholder/EmptyPagePlaceholder';
import { translate } from '../../common/helpers/translate.helper';
import { IconChartPie } from '../../common/icons/cdl/ChartPie';
import { theme } from '../../common/ui/theme';

export const NoStatistics = () => {
    return (
        <EmptyPagePlaceholder
            title={translate('reporting.noStatistics.title')}
            description={translate('reporting.noStatistics.description')}
            icon={<IconChartPie width={24} height={24} color={theme.colors.foreground.subtle} />}
        />
    );
};
