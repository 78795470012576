import styled from 'styled-components';

import { Flex } from '../../ui/Flex';
import { get } from '../../ui/get';

const getBorderColor = (props: DropZoneProps) => {
    if (props.isDragReject) {
        return get('colors.negative.emphasis');
    }

    if (props.isDragAccept || props.isDragActive) {
        return get('colors.accent.emphasis');
    }

    return get('colors.border.muted');
};

const getBackgroundColor = (props: DropZoneProps) => {
    if (props.isDragReject) {
        return get('colors.negative.background');
    }

    if (props.isDragAccept || props.isDragActive) {
        return get('colors.accent.background');
    }

    return get('colors.background.default');
};

interface DropZoneProps {
    isDragAccept: boolean;
    isDragReject: boolean;
    isDragActive: boolean;
}

export const DropZone = styled(Flex)<DropZoneProps>`
    border-style: dashed;
    border-color: ${(props) => getBorderColor(props)};
    border-width: 1px;
    border-radius: ${get('radii.1')}px;
    padding: ${get('sizes.6')}px;
    background-color: ${(props) => getBackgroundColor(props)};
    flex-direction: column;
    align-items: stretch;
`;
