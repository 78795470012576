import { ReactNode } from 'react';
import styled from 'styled-components';

import { BunkerStopMessage, BunkerStopState } from '../../../types/BunkerStopsMessage';
import { DiscoverableButton } from '../../cdl/Button/DiscoverableButton';
import { StyledDefaultTableBodyRow } from '../../cdl/TableBuilder/components/StyledDefaultTableBodyRow';
import { TableBuilder } from '../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../cdl/TableBuilder/TableBuilderColumn';
import { Tag } from '../../cdl/Tag/Tag';
import { Text } from '../../cdl/Text/Text';
import { CenteredPageError } from '../../common/Error/CenteredPageError';
import { formatDateTime } from '../../common/helpers/formatDateTime.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useAngularRouterLocation } from '../../common/hooks/useAngularRouterLocation';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useFuelVolumeFormat } from '../../common/hooks/useFuelVolumeFormat';
import { useRole } from '../../common/hooks/useRole';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { Page } from '../../common/Page/Page';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';
import { BunkerPlanDot } from '../components/BunkerPlanDot';
import { BunkerPlanDropdownMenu } from '../components/BunkerPlanDropdownMenu';
import { BunkerPlanFilter } from '../components/BunkerPlanFilter';
import { BunkerPlanStateButton } from '../components/BunkerPlanStateButton';
import { BunkerStopsDownloadCsvButton } from '../components/BunkerStopsDownloadCsvButton';
import { ContractRecommendation } from '../components/ContractRecommendation/ContractRecommendation';
import { DemandTab } from '../components/DemandTab';
import { NoBunkerPlans } from '../components/NoBunkerPlans';
import { PrePlanningTabSwitch } from '../components/PrePlanningTabSwitch';
import { useBunkerStops } from '../hooks/useBunkerStops';
import { createCategoriesFromStops } from '../utils/createCategoriesFromStops';

const Wrapper = styled.div`
    & ${StyledDefaultTableBodyRow}:hover ${DiscoverableButton} {
        opacity: 1;
    }
`;

const BunkerPlanContent = () => {
    const { bunkerStopIds: bunkerStopIdsFromRoute } = useAngularRouterLocation<{
        bunkerStopIds?: string[];
    }>();
    const role = useRole();

    const formatVolume = useFuelVolumeFormat();

    const bunkerStopsQuery = useBunkerStops();

    if (bunkerStopsQuery.isPending) {
        return <LoadingIndicator variant="page" />;
    }

    if (bunkerStopsQuery.isError) {
        return <CenteredPageError />;
    }

    const bunkerStopsMessage = bunkerStopsQuery.data;

    if (!bunkerStopsMessage.bunkerStops.length) {
        return (
            <Box display="flex" marginTop="200px" justifyContent="center">
                <NoBunkerPlans />
            </Box>
        );
    }

    const categories = createCategoriesFromStops(bunkerStopsMessage.bunkerStops);

    const categoryColumns: ReactNode[] = [];

    categories.forEach((category, key) => {
        const header = translate(`bunkerPlan.table.headers.category`, {
            category: `${category.productGroup} ${category.sulphurContent}`,
        });

        categoryColumns.push(
            <TableBuilderColumn<BunkerStopMessage> header={header} key={key} numeric>
                {(row) => {
                    const volume = row.items.find(
                        (item) =>
                            item.productGroup === category.productGroup &&
                            item.sulphurContent === category.sulphurContent
                    )?.volume;

                    return volume ? formatVolume({ volume }) : '-';
                }}
            </TableBuilderColumn>
        );
    });

    return (
        <Wrapper>
            {bunkerStopsMessage.contractRecommendations.length &&
            role.hasWriteRights(bunkerStopsMessage.bunkerStops.at(0)?.customerId) ? (
                <Box marginBottom={6}>
                    <ContractRecommendation portName={bunkerStopsMessage.contractRecommendations[0].port.name} />
                </Box>
            ) : null}

            <BunkerPlanFilter allEntries={bunkerStopsQuery.data.bunkerStops}>
                {(filteredBunkerStops) => (
                    <TableBuilder data={filteredBunkerStops}>
                        <TableBuilderColumn<BunkerStopMessage> header={translate('bunkerPlan.table.headers.port')}>
                            {(row) => {
                                return (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        columnGap={4}
                                        style={{ whiteSpace: 'nowrap' }}
                                    >
                                        {row.port.name}{' '}
                                        {bunkerStopIdsFromRoute?.includes(row.id) ? <BunkerPlanDot /> : null}
                                    </Box>
                                );
                            }}
                        </TableBuilderColumn>
                        <TableBuilderColumn<BunkerStopMessage> header={translate('bunkerPlan.table.headers.vessel')}>
                            {(row) => row.vessel.name}
                        </TableBuilderColumn>
                        <TableBuilderColumn<BunkerStopMessage> header={translate('bunkerPlan.table.headers.eta')}>
                            {(row) => formatDateTime({ date: row.eta })}
                        </TableBuilderColumn>
                        <TableBuilderColumn<BunkerStopMessage> header={translate('bunkerPlan.table.headers.etd')}>
                            {(row) => formatDateTime({ date: row.etd })}
                        </TableBuilderColumn>
                        {categoryColumns}
                        <TableBuilderColumn<BunkerStopMessage> header={translate('bunkerPlan.table.headers.state')}>
                            {(row) =>
                                row.state !== BunkerStopState.OPEN ? (
                                    <Tag variant="default">{translate(`bunkerPlan.table.state.${row.state}`)}</Tag>
                                ) : null
                            }
                        </TableBuilderColumn>
                        {role.hasWriteRights(bunkerStopsMessage.bunkerStops.at(0)?.customerId) ? (
                            <TableBuilderColumn<BunkerStopMessage> header="">
                                {(row) => (
                                    <Box display="flex" justifyContent="end" gap={5}>
                                        <BunkerPlanStateButton bunkerStop={row}></BunkerPlanStateButton>
                                        <BunkerPlanDropdownMenu bunkerStop={row} />
                                    </Box>
                                )}
                            </TableBuilderColumn>
                        ) : null}
                    </TableBuilder>
                )}
            </BunkerPlanFilter>
        </Wrapper>
    );
};

export const BunkerPlanPage = () => {
    useDocumentTitle(translate('bunkerPlan.pageTitle'));

    return (
        <Page>
            <Flex justifyContent="space-between" marginBottom={5}>
                <Box>
                    <Text as="h1" variant="headline">
                        {translate('bunkerPlan.pageTitle')}
                    </Text>
                </Box>

                <Box>
                    <BunkerStopsDownloadCsvButton />
                </Box>
            </Flex>
            <PrePlanningTabSwitch currentTab={DemandTab.BUNKER_PLAN} />

            <BunkerPlanContent />
        </Page>
    );
};
