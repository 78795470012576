import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../cdl/Toast/useToasts';
import { updateCounterOffer } from '../../common/api/clients/anonymousQuotation.api';
import { queryKeys } from '../../common/api/queryKeys';
import { translate } from '../../common/helpers/translate.helper';

export const useAnonymousQuotationCounterOfferUpdate = (anonymousQuotationId) => {
    const queryClient = useQueryClient();
    const { addErrorToast } = useToasts();

    return useMutation({
        mutationFn: updateCounterOffer,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.anonymousQuotation(anonymousQuotationId) });
        },
        onError: () => {
            addErrorToast(translate('anonymousQuotation.toast.error'));
        },
    });
};
