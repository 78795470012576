import { AxiosResponse } from 'axios';

import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const downloadVesselRequisition = (vesselId: string): Promise<AxiosResponse<Blob>> => {
    return authorizedAxiosInstance.get(`/v1/vessel-requisitions?vesselIds=${vesselId}`, {
        responseType: 'blob',
    });
};

export const sendVesselRequisition = (vesselId: string): Promise<AxiosResponse> => {
    return authorizedAxiosInstance.post(`/v1/vessel-requisitions/email?vesselIds=${vesselId}`);
};
