import styled from 'styled-components';

import { Box } from '../ui/Box';

interface CloakBoxProps {
    hasBackground?: boolean;
    reverseGradient?: boolean;
}

export const CloakBox = styled(Box)<CloakBoxProps>`
    position: absolute;
    top: 0;
    bottom: 0;
    justify-content: center;
    display: flex;
    align-items: center;
    z-index: 100;
    background-color: white;
    background: ${({ hasBackground = false, reverseGradient = false }) => {
        if (!hasBackground) {
            return 'transparent';
        }

        return reverseGradient
            ? 'linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255,255,255,1) 30%)'
            : 'linear-gradient(90deg, rgba(255, 255, 255, 1) 70%, rgba(255,255,255,0))';
    }};

    & *:disabled {
        opacity: 0.25;
    }
`;
