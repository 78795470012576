import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createAssignment } from '../../../common/api/clients/company.api';
import { queries } from '../../../common/api/queryKeys/queries';

export const useAssignmentCreate = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: createAssignment,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queries.assignments._def });
        },
    });
};
