import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { Text } from '../../cdl/Text/Text';
import { setNewPassword } from '../../common/api/clients/passwordReset.api';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { FormikSetNewPasswordForm } from '../components/FormikSetNewPasswordForm';
import { LoginPageContentBox } from '../components/LoginPageContentBox';
import { SetNewPasswordCompleteContent } from '../components/SetNewPasswordCompleteContent';
import { SetNewPasswordResetAgainContent } from '../components/SetNewPasswordResetAgainContent';

interface FormikSetNewPasswordState {
    password: string;
    passwordRepeat: string;
}

const validate = (values: FormikSetNewPasswordState): FormikErrors<FormikSetNewPasswordState> => {
    const errors: FormikErrors<FormikSetNewPasswordState> = {};

    if (!values.password) {
        errors.password = translate('setNewPassword.error.newPasswordRequired');
    } else if (values.password.length < 6) {
        errors.password = translate('setNewPassword.error.newPasswordTooShort');
    }

    if (!values.passwordRepeat || values.password !== values.passwordRepeat) {
        errors.passwordRepeat = translate('setNewPassword.error.passwordRepeatInvalid');
    }

    return errors;
};

type SetNewPasswordError = AxiosError<{
    code: 'VALIDATION_FAILED';
}>;

export const SetNewPasswordPage = () => {
    const token = new URLSearchParams(location.search).get('token');
    const setNewPasswordMutation = useMutation({ mutationFn: setNewPassword });

    useDocumentTitle(translate('setNewPassword.documentTitle'));

    const handleSubmit = async (values: FormikSetNewPasswordState, meta: FormikHelpers<FormikSetNewPasswordState>) => {
        try {
            await setNewPasswordMutation.mutateAsync({
                password: values.password,
                token: token ?? '',
            });
        } catch (error) {
            const axiosError = error as SetNewPasswordError;

            if (axiosError.response?.data.code === 'VALIDATION_FAILED') {
                meta.setErrors({
                    password: translate('setNewPassword.error.insecurePassword'),
                });
            }
        }
    };

    if (setNewPasswordMutation.isSuccess) {
        return (
            <LoginPageContentBox>
                <SetNewPasswordCompleteContent />
            </LoginPageContentBox>
        );
    }

    const isOtherError = setNewPasswordMutation.error
        ? (setNewPasswordMutation.error as SetNewPasswordError).response?.data.code !== 'VALIDATION_FAILED'
        : false;

    if (isOtherError) {
        return (
            <LoginPageContentBox>
                <SetNewPasswordResetAgainContent />
            </LoginPageContentBox>
        );
    }

    return (
        <LoginPageContentBox>
            <Text variant="title" as="h1">
                {translate('setNewPassword.resetPassword')}
            </Text>
            <Formik
                initialValues={{ password: '', passwordRepeat: '' }}
                onSubmit={handleSubmit}
                validate={validate}
                component={FormikSetNewPasswordForm}
            />
        </LoginPageContentBox>
    );
};
