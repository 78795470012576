import PropTypes from 'prop-types';

import { ProductTable } from '../../../product/ProductTable/ProductTable';
import { ConfirmDeliveryPopup } from '../common/components/ConfirmDeliveryPopup';

export const LubesConfirmDeliveryPopup = ({ onDismiss, offer, onConfirm, isSubmitting, isOpen }) => {
    return (
        <ConfirmDeliveryPopup
            onDismiss={onDismiss}
            isOpen={isOpen}
            offer={offer}
            onConfirm={onConfirm}
            isSubmitting={isSubmitting}
        >
            <ProductTable offer={offer} showPrices={false} />
        </ConfirmDeliveryPopup>
    );
};

LubesConfirmDeliveryPopup.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    offer: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
};
