import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconPaperclip = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M11.293 3.293a5.243 5.243 0 0 1 7.414 7.414l-6.5 6.5a3.122 3.122 0 0 1-4.414-4.414l6.5-6.5a1 1 0 0 1 1.414 1.414l-6.5 6.5a1.121 1.121 0 0 0 1.586 1.586l6.5-6.5a3.242 3.242 0 1 0-4.586-4.586l-6.5 6.5a5.364 5.364 0 0 0 7.586 7.586l6.5-6.5a1 1 0 1 1 1.414 1.414l-6.5 6.5A7.364 7.364 0 0 1 4.793 9.793l6.5-6.5Z"
            clipRule="evenodd"
        />
    </svg>
);
