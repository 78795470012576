/*
 * With the standard browser behaviour,
 * the user can change the number in number inputs
 * when scrolling while hovering on the input
 *
 * Sometimes, this leads to user experience issues.
 * The user wants to order 10.000 liters, starts scrolling
 * and accidentally orders 9.997 liters.
 *
 * We now disable this behaviour if the active element is a number input.
 *
 * The downside to this approach is that the input loses focus on page scroll.
 *
 * Reported here: https://linear.app/closelink/issue/DEV-939/do-not-change-value-in-number-inputs-on-mouse-wheel
 * Solution inspired by: https://stackoverflow.com/a/38589039/3276759
 */

export const registerScrollPreventionEventListener = () => {
    document.addEventListener('wheel', () => {
        if (document.activeElement.type === 'number') {
            document.activeElement.blur();
        }
    });
};
