import * as Sentry from '@sentry/browser';
import storage from 'local-storage-fallback';

RoleService.$inject = ['Role', '$q', '$rootScope', '$timeout'];

function RoleService(Role, $q, $rootScope, $timeout) {
    let rolePromise;
    let role;

    return {
        create,
        addUser,
        addCompanies,
        addGroup,
        reset,
        authenticated,
        get,
        getPromise,
        updateContext,
    };

    function create(token) {
        role = new Role(token.authorities, token.accessLevel, token.companyAccesses);
        return role;
    }

    function addUser(user) {
        role.user = user;
        return role;
    }

    function addGroup(group) {
        role.group = group;
        return role;
    }

    function addCompanies(companies) {
        role.addCompanies(companies);

        return role;
    }

    function updateContext(newContext) {
        role.context = newContext;
        storage.setItem('productContext', newContext);
        $rootScope.$broadcast('contextUpdated', newContext);
        $timeout(() => {
            $rootScope.$apply();
        });
    }

    function reset() {
        role = undefined;
        rolePromise = $q.defer();
        Sentry.setUser(null);
    }

    function authenticated() {
        if (rolePromise) {
            rolePromise.resolve(role);
            Sentry.setUser({ id: role.user.id, email: role.user.email, segment: role.user.userType });
        }
    }

    function get() {
        return role;
    }

    function getPromise() {
        if (!rolePromise) rolePromise = $q.defer();
        return rolePromise.promise;
    }
}

export default {
    name: 'RoleService',
    fn: RoleService,
};
