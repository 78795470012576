import styled from 'styled-components';
import { variant } from 'styled-system';

import { Text, TextProps } from '../../cdl/Text/Text';

interface HeadingProps extends TextProps {
    level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'formHeading' | 'uppercase';
}

/**
 * @deprecated use CDL Text instead
 **/

export const Heading = styled(Text)<HeadingProps>(
    {
        display: 'block',
    },

    variant({
        prop: 'level',
        variants: {
            h1: { fontSize: 6, lineHeight: 6 },
            h2: { fontSize: 5, lineHeight: 5 },
            h3: { fontSize: 4, lineHeight: 4 },
            h4: { fontSize: 3, lineHeight: 3 },
            h5: { fontSize: 2, lineHeight: 2, fontWeight: 'semiBold' },
            formHeading: {
                fontSize: 2,
                lineHeight: 2,
                textTransform: 'uppercase',
                color: 'dark-blue.3',
                letterSpacing: 1,
            },
            uppercase: {
                fontSize: 2,
                lineHeight: 2,
                fontWeight: 'semiBold',
                textTransform: 'uppercase',
            },
        },
    })
);
