import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconLetterB = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M6 4a1 1 0 0 1 1-1h6a5 5 0 0 1 3 9 5 5 0 0 1-3 9H7a1 1 0 0 1-1-1V4Zm2 9v6h5a3 3 0 0 0 0-6H8Zm5-2a3 3 0 0 0 0-6H8v6h5Z"
            clipRule="evenodd"
        />
    </svg>
);
