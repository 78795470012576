import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { SuppliersOverviewPage as OriginalSuppliersOverviewPage } from './SuppliersOverviewPage';

const SuppliersOverviewPage = (props) => {
    return (
        <AppProviders>
            <OriginalSuppliersOverviewPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(SuppliersOverviewPage, 'reactSuppliersOverviewPage');
