import React from 'react';

import { AppProviders } from '../common/AppProviders';
import { createDirectiveFromReactComponent } from '../common/helpers/createDirectiveFromReactComponent';

import { IntegrationsOverviewPage } from './IntegrationsOverviewPage';

const IntegrationsOverviewPageReactComponent = (props: any) => {
    return (
        <AppProviders>
            <IntegrationsOverviewPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(
    IntegrationsOverviewPageReactComponent,
    'reactIntegrationsOverviewPage'
);
