import { FormikErrors } from 'formik';

import { translate } from './translate.helper';

export const validateRequiredFields = <T extends Record<string, unknown>>(
    values: T,
    fields: (keyof typeof values)[]
) => {
    const errors: Partial<Record<keyof T, string>> = {};

    for (const field of fields) {
        if (!values[field]) {
            errors[field] = translate('errormessage.empty');
        }
    }

    return errors as FormikErrors<T>;
};
