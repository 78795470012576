import { CustomerCompanySelect } from '../../../common/form-elements/CompanySelect/CustomerCompanySelect';
import { SupplierCompanySelect } from '../../../common/form-elements/CompanySelect/SupplierCompanySelect';
import { translate } from '../../../common/helpers/translate.helper';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { Box } from '../../../common/ui/Box';

import { CreateAssignmentButton } from './CreateAssignmentButton';

export const AssignmentsActions = () => {
    const [locationState, { setLocationFieldValue }] = useLocationState();

    return (
        <Box
            display="grid"
            alignItems="center"
            gridTemplateColumns="minmax(350px, auto) minmax(350px, auto) auto"
            gap={5}
        >
            <CustomerCompanySelect
                onChange={(customerIds) => {
                    setLocationFieldValue('customerIds', customerIds);
                    setLocationFieldValue('page', 0);
                }}
                placeholder={translate('placeholder.filter.customer')}
                value={locationState.customerIds}
                isMulti
            />
            <SupplierCompanySelect
                value={locationState.supplierIds}
                placeholder={translate('placeholder.filter.supplier')}
                onChange={(supplierIds) => {
                    setLocationFieldValue('supplierIds', supplierIds);
                    setLocationFieldValue('page', 0);
                }}
                isMulti
            />
            <CreateAssignmentButton />
        </Box>
    );
};
