import PropTypes from 'prop-types';

import { formatDateTime } from '../../common/helpers/formatDateTime.helper';
import { translate } from '../../common/helpers/translate.helper';
import { IconCircleX } from '../../common/icons/cdl/CircleX';
import { StatusBar } from '../../common/StatusBar/StatusBar';
import { StatusBarType } from '../../common/StatusBar/StatusBarType';

import { ApprovalRequestBasicContent } from './ApprovalRequestBasicContent';

const DeclinedStatusBar = ({ approvalRequest }) => {
    const makeSubHeadline = () => {
        if (!approvalRequest.responseMessage) {
            return translate('approvalRequest.statusBar.declinedNoReason.subHeadline', {
                date: formatDateTime({ date: approvalRequest.dateDeclined }),
            });
        }

        return translate('approvalRequest.statusBar.declined.subHeadline', {
            date: formatDateTime({ date: approvalRequest.dateDeclined }),
            responseMessage: approvalRequest.responseMessage,
        });
    };

    const headline = translate('approvalRequest.statusBar.declined.headline');
    const subHeadline = makeSubHeadline();

    return (
        <StatusBar headline={headline} subHeadline={subHeadline} type={StatusBarType.DANGER} icon={<IconCircleX />} />
    );
};

DeclinedStatusBar.propTypes = {
    approvalRequest: PropTypes.object,
};

export const DeclinedState = ({ offer, approvalRequests }) => {
    return (
        <ApprovalRequestBasicContent approvalRequests={approvalRequests} offer={offer}>
            <DeclinedStatusBar approvalRequest={approvalRequests[0]} />
        </ApprovalRequestBasicContent>
    );
};

DeclinedState.propTypes = {
    offer: PropTypes.object.isRequired,
    approvalRequests: PropTypes.array.isRequired,
};
