import { useField } from 'formik';

import { TagListInput, TagListInputProps } from './TagListInput';

interface FormikTagListInputProps extends Omit<TagListInputProps, 'onChange' | 'values'> {
    name: string;
}

export const FormikTagListInput = (props: FormikTagListInputProps) => {
    const [field, meta, helpers] = useField(props.name);

    const handleChange = (newValue: string[]) => {
        helpers.setValue(newValue);
    };

    return (
        <TagListInput
            {...field}
            {...props}
            values={field.value}
            onChange={handleChange}
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : props.caption}
        />
    );
};
