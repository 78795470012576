import { useState } from 'react';

import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Section } from '../../../common/Section/Section';
import { OfferModel } from '../../model/OfferModel';

import { OfferPositionsMap } from './components/OfferPositionsMap';
import { PositionList } from './components/PositionList';
import { useOfferPositions } from './hooks/useOfferPositions';
import { PositionData } from './types/PositionData';

interface OfferPositionsProps {
    offer: OfferModel;
}

export const OfferPositions = ({ offer }: OfferPositionsProps) => {
    const [activePosition, setActivePosition] = useState<PositionData | undefined>(undefined);
    const offerPositionsQuery = useOfferPositions(offer);

    if (offerPositionsQuery.isPending) {
        return (
            <Section fullHeight>
                <LoadingIndicator />
            </Section>
        );
    }

    const offerPositions = offerPositionsQuery.data as PositionData[];

    return (
        <Section paddingY={0}>
            <OfferPositionsMap positionData={offerPositions} mapPosition={activePosition?.position} />
            <PositionList positionData={offerPositions} onActivePositionChange={setActivePosition} />
        </Section>
    );
};
