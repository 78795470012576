import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Box } from '../../ui/Box';
import { Flex } from '../../ui/Flex';
import { get as themeGet } from '../../ui/get';
import { useFormContext } from '../Form/useFormContext';
import { useFormData } from '../Form/useFormData';
import { Label } from '../Label/Label';

import { StyledInput } from './Input';

const IconBox = styled(Flex)({
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    alignItems: 'center',
    color: themeGet('colors.dark-blue.3'),
});

/**
 * @deprecated use CDL Input instead
 **/

export const TextInput = ({
    label = false,
    focus = false,
    icon = null,
    annotation = null,
    required = false,
    invalid = false,
    highlight = false,
    ...input
}) => {
    const inputRef = useRef(null);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        if (focus) {
            inputRef.current.focus();
        }
    }, [focus]);

    return (
        <Label label={label} annotation={annotation} required={required}>
            <Box position="relative">
                {icon ? <IconBox>{icon}</IconBox> : null}
                <StyledInput
                    paddingRight={5}
                    paddingLeft={icon ? 7 : 5}
                    paddingY={4}
                    border={1}
                    borderRadius={1}
                    ref={inputRef}
                    required={required}
                    className={isDirty ? 'dirty' : ''}
                    onBlur={() => setIsDirty(true)}
                    invalid={invalid}
                    $highlight={highlight}
                    error={invalid}
                    {...input}
                />
            </Box>
        </Label>
    );
};

TextInput.propTypes = {
    label: PropTypes.node,
    focus: PropTypes.bool,
    icon: PropTypes.node,
    annotation: PropTypes.string,
    required: PropTypes.bool,
    invalid: PropTypes.bool,
    highlight: PropTypes.bool,
};

export const FormTextInput = ({ dataPath, transformValue = (v) => v, disabled = false, ...props }) => {
    const bindFormData = useFormData(dataPath, { transformValue });
    const { data } = useFormContext();
    const disabledValue = typeof disabled === 'function' ? disabled(data) : disabled;

    return <TextInput {...bindFormData} {...props} disabled={disabledValue} />;
};

FormTextInput.propTypes = {
    label: PropTypes.node,
    transformValue: PropTypes.func,
    disabled: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    dataPath: PropTypes.string.isRequired,
    invalid: PropTypes.bool,
};
