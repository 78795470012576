import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getEnums } from '../api/clients/coreData.api';
import { queryKeys } from '../api/queryKeys';
import { findApplicableTypes } from '../helpers/productcontext.helper';

export const useEnums = () => {
    const queryClient = useQueryClient();
    const { data, isPending, isSuccess } = useQuery({
        queryKey: queryKeys.enums(),
        queryFn: getEnums,
        staleTime: Infinity,
    });

    const getHumanReadableValue = (enumType, enumKey) => {
        if (!data) {
            return null;
        }

        const value = data[enumType][enumKey];

        return value ? value.humanReadableValue : null;
    };

    const getEnumsForTypeAndContext = (enumType, context) => {
        if (!data) {
            return null;
        }

        if (!context) {
            return data[enumType];
        }

        return findApplicableTypes({
            enumValues: data[enumType],
            context,
        });
    };

    const getEnumField = (enumType, enumKey, field) => {
        return data[enumType][enumKey]?.[field];
    };

    const prefetch = () => {
        queryClient.prefetchQuery({ queryKey: queryKeys.enums(), queryFn: getEnums, staleTime: Infinity });
    };

    return {
        enums: isSuccess ? data : {},
        isPending,
        getHumanReadableValue,
        prefetch,
        getEnumsForTypeAndContext,
        getEnumField,
    };
};
