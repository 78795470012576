import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ProductContext } from '../../../../../types/ProductContext';
import { SupplierGroupMessage } from '../../../../../types/SupplierGroupMessage';
import { updateSupplierGroup } from '../../../../common/api/clients/supplierGroups.api';
import { queries } from '../../../../common/api/queryKeys/queries';

export const useSupplierGroupSpotAvailabilityUpdate = (groupId: string, type: ProductContext) => {
    const queryClient = useQueryClient();

    const updateSupplierGroupSpotAvailability = (availabilityCountryCodes: string[]) => {
        const queryKey = queries.supplierGroups.detail(groupId).queryKey;
        const previousSupplierGroup = queryClient.getQueryData<SupplierGroupMessage>(queryKey);

        if (!previousSupplierGroup) {
            return Promise.reject();
        }

        if (type === ProductContext.FUEL) {
            return updateSupplierGroup({
                ...previousSupplierGroup,
                fuelSettings: {
                    ...previousSupplierGroup.fuelSettings,
                    availabilityCountryCodes,
                },
            });
        }

        return updateSupplierGroup({
            ...previousSupplierGroup,
            lubesSettings: {
                ...previousSupplierGroup.lubesSettings,
                availabilityCountryCodes,
            },
        });
    };

    return useMutation({
        mutationFn: updateSupplierGroupSpotAvailability,

        onMutate: async (availabilityCountryCodes) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: queries.supplierGroups.detail(groupId).queryKey });

            const queryKey = queries.supplierGroups.detail(groupId).queryKey;
            const previousSupplierGroup = queryClient.getQueryData<SupplierGroupMessage>(queryKey);

            if (!previousSupplierGroup) {
                return { previousSupplierGroup: undefined };
            }

            const supplierGroup =
                type === ProductContext.FUEL
                    ? {
                          ...previousSupplierGroup,
                          fuelSettings: {
                              ...previousSupplierGroup.fuelSettings,
                              availabilityCountryCodes,
                          },
                      }
                    : {
                          ...previousSupplierGroup,
                          lubesSettings: {
                              ...previousSupplierGroup.lubesSettings,
                              availabilityCountryCodes,
                          },
                      };

            queryClient.setQueryData(queryKey, supplierGroup);

            return { previousSupplierGroup };
        },

        onError: (err, variables, context) => {
            const queryKey = queries.supplierGroups.detail(groupId).queryKey;

            queryClient.setQueryData(queryKey, context!.previousSupplierGroup);
        },

        onSettled: () => {
            const queryKey = queries.supplierGroups.detail(groupId).queryKey;

            queryClient.invalidateQueries({ queryKey });
        },
    });
};
