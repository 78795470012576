import { theme } from '../../../../common/ui/theme';
import { PositionType } from '../types/PositionType';

export function getMarkerColor(positionType: PositionType): string {
    let dotColor = theme.colors.positive.emphasis;

    switch (positionType) {
        case PositionType.VESSEL:
            dotColor = theme.colors.positive.emphasis;
            break;

        case PositionType.NEXT_PORT:
            dotColor = theme.colors.foreground.default;
            break;

        case PositionType.LAST_PORT:
            dotColor = theme.colors.foreground.disabled;
            break;

        case PositionType.ORDER_PORT:
            dotColor = theme.colors.accent.emphasis;
            break;
    }

    return dotColor;
}
