import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';

import { updateOffer } from '../../../../common/api/clients/order.api';
import { queryKeys } from '../../../../common/api/queryKeys';

export const StateSwitchContext = createContext({ changeState: () => {} });

const ActionType = {
    SHOW: 'SHOW',
    HIDE: 'HIDE',
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionType.SHOW: {
            return {
                showPopup: true,
                component: action.component,
                offer: action.offer,
                destinationState: action.destinationState,
            };
        }
        case ActionType.HIDE: {
            return {
                showPopup: false,
                component: state.component,
                offer: state.offer,
                destinationState: state.destinationState,
            };
        }
        default:
            throw Error(`Unknown action type ${action.type}`);
    }
};

const useUpdateOffer = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateOffer,
        onSuccess: (offer) => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.offer(offer.id) });
        },
    });
};

export const StateSwitchContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, {
        showPopup: false,
        component: null,
        offer: null,
        destinationState: null,
    });

    const updateOfferMutation = useUpdateOffer();

    const changeState = ({ destinationState, offer, component }) => {
        // If a component is provided, we show the popup. Otherwise, we change the state directly.
        if (component) {
            dispatch({
                type: ActionType.SHOW,
                destinationState,
                offer,
                component,
            });
        } else {
            _changeState(offer, destinationState);
        }
    };

    const _changeState = async (offer, state) => {
        const response = await updateOfferMutation.mutateAsync({
            offerId: offer.id,
            offer: {
                ...offer,
                state,
            },
        });

        dispatch({
            type: ActionType.HIDE,
        });

        return response;
    };

    const submit = () => {
        return _changeState(state.offer, state.destinationState);
    };

    const Component = state.component;

    return (
        <StateSwitchContext.Provider
            value={{
                changeState,
            }}
        >
            {children}
            {Component ? (
                <Component
                    onConfirm={submit}
                    onDismiss={() => dispatch({ type: ActionType.HIDE })}
                    isOpen={state.showPopup}
                    offer={state.offer}
                    isSubmitting={updateOfferMutation.isPending}
                />
            ) : null}
        </StateSwitchContext.Provider>
    );
};

StateSwitchContextProvider.propTypes = {
    children: PropTypes.node,
};
