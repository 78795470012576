import { mergeQueryKeys } from '@lukemorales/query-key-factory';

import { agents } from './agents';
import { apiKeys } from './apiKeys';
import { assignments } from './assignments';
import { countries } from './countries';
import { customerGroups } from './customerGroups';
import { customers } from './customers';
import { keyPorts } from './keyPorts';
import { offers } from './offers';
import { orders } from './orders';
import { packagePrices } from './packagePrices';
import { priceCompare } from './priceCompare';
import { products } from './products';
import { reports } from './reports';
import { supplierGroups } from './supplierGroups';
import { suppliers } from './suppliers';
import { vessels } from './vessels';

export const queries = mergeQueryKeys(
    assignments,
    vessels,
    keyPorts,
    agents,
    customerGroups,
    customers,
    apiKeys,
    supplierGroups,
    suppliers,
    products,
    countries,
    priceCompare,
    reports,
    packagePrices,
    orders,
    offers
);
