import { ReactNode } from 'react';
import { components, OptionProps } from 'react-select';
import styled from 'styled-components';

import { IconCheck } from '../../../common/icons/cdl/Check';
import { Box } from '../../../common/ui/Box';
import { get } from '../../../common/ui/get';
import { theme } from '../../../common/ui/theme';
import { Text } from '../../Text/Text';

const StyledOption = styled(components.Option)<OptionProps>`
    && {
        cursor: pointer;
        display: grid;
        grid-template-columns: 24px auto;
        grid-template-rows: 24px 16px;
        column-gap: ${get('space.4')}px;
        align-items: center;
        height: 56px;
        padding: ${get('space.4')}px;
        color: ${get('colors.foreground.default')};
        border-radius: 4px;
        background-color: ${(props) => {
            if (props.isFocused) {
                return get('colors.accent.background');
            } else {
                return get('colors.background.default');
            }
        }};

        &:active {
            background-color: ${get('colors.accent.background')};
        }
    }
`;

export interface CustomDoubleLineOptionProps extends Omit<OptionProps, 'children' | 'label'> {
    label: string;
    description: string;
    trailingVisual?: ReactNode;
}

export const CustomDoubleLineOption = ({
    trailingVisual,
    label,
    description,
    ...rest
}: CustomDoubleLineOptionProps) => {
    return (
        <StyledOption label={label} {...rest}>
            <Box>
                {rest.isSelected ? (
                    <IconCheck height={24} width={24} style={{ color: theme.colors.accent.foreground }} />
                ) : null}
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" overflow="hidden">
                <Text
                    variant="small"
                    fontWeight="semiBold"
                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                    {label}
                </Text>
                {trailingVisual ? <Box>{trailingVisual}</Box> : null}
            </Box>
            <Text
                variant="extraSmall"
                color="foreground.muted"
                style={{ gridColumnStart: 2, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
                {description}
            </Text>
        </StyledOption>
    );
};
