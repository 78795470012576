import { createContext, Dispatch, SetStateAction } from 'react';

import { CompareSelectionState } from './CompareSelectionStateContextProvider';

interface CompareSelectionStateContextType {
    selection: CompareSelectionState;
    setSelection: Dispatch<SetStateAction<CompareSelectionState>>;
}

export const CompareSelectionStateContext = createContext<CompareSelectionStateContextType | null>(null);
