import { CategoryWarningType } from '../../../../../../../../types/BunkerCaseSolutionResponse';
import { IconAlertOctagon } from '../../../../../../../common/icons/cdl/AlertOctagon';
import { IconAlertTriangle } from '../../../../../../../common/icons/cdl/AlertTriangle';
import { IconArrowsDiff } from '../../../../../../../common/icons/cdl/ArrowsDiff';
import { IconFlask } from '../../../../../../../common/icons/cdl/Flask';
import { IconInfoCircle } from '../../../../../../../common/icons/cdl/InfoCircle';
import { IconLetterQ } from '../../../../../../../common/icons/cdl/LetterQ';
import { IconLetterQOff } from '../../../../../../../common/icons/cdl/LetterQOff';

interface WarningIconProps {
    warningType: CategoryWarningType;
}

export const WarningIcon = ({ warningType }: WarningIconProps) => {
    let IconComponent: ((props: any) => JSX.Element) | null = null;

    switch (warningType) {
        case CategoryWarningType.OVERFILL:
            IconComponent = IconAlertTriangle;
            break;
        case CategoryWarningType.QUARANTINE_VIOLATION:
            IconComponent = IconLetterQOff;
            break;
        case CategoryWarningType.QUARANTINE_REQUIREMENT:
            IconComponent = IconLetterQ;
            break;
        case CategoryWarningType.RESERVE_REQ_VIOLATION_START:
        case CategoryWarningType.RESERVE_REQ_VIOLATION_END:
            IconComponent = IconInfoCircle;
            break;
        case CategoryWarningType.MARGIN_VIOLATION:
            IconComponent = IconAlertOctagon;
            break;
        case CategoryWarningType.COMMINGLING:
            IconComponent = IconFlask;
            break;
        case CategoryWarningType.WARNING:
            IconComponent = IconAlertTriangle;
            break;
        case CategoryWarningType.FUEL_SUBSTITUTION:
            IconComponent = IconArrowsDiff;
            break;
    }

    if (IconComponent === null) {
        return null;
    }

    return <IconComponent width={16} height={16} />;
};
