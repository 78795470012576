import { ReactNode } from 'react';

import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';
import { Button } from '../../Button/Button';

interface CalendarActionsProps {
    children?: ReactNode;
    onTodayClick?: () => void;
}

export const CalendarActions = ({ onTodayClick, children }: CalendarActionsProps) => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            paddingY={5}
            paddingX={6}
            borderTopStyle="solid"
            borderTopWidth="1px"
            borderTopColor="border.subtle"
        >
            <Box>
                <Button emphasis="low" size="large" onClick={onTodayClick}>
                    {translate('global.datePicker.today')}
                </Button>
            </Box>
            {children ? (
                <Box display="flex" gap={4}>
                    {children}
                </Box>
            ) : null}
        </Box>
    );
};
