import styled from 'styled-components';

import { Text, TextProps } from '../../cdl/Text/Text';
import { useAngularDependencies } from '../../common/hooks/useAngularDependencies';
import { get } from '../../common/ui/get';

const StyledAnchor = styled.a`
    color: ${get('colors.accent.foreground')};
    white-space: nowrap;

    &:hover,
    &:active,
    &:visited,
    &:focus {
        text-decoration: none;
        color: ${get('colors.accent.foreground')};
    }
`;

interface DemandLinkProps extends Pick<TextProps, 'variant' | 'fontWeight'> {
    to: string;
    params: object;
    children: string;
}

export const DemandLink = ({ to, params, children, variant, fontWeight }: DemandLinkProps) => {
    const { $state } = useAngularDependencies();

    const href = params ? $state.href(to, params) : $state.href(to);

    return (
        <StyledAnchor href={href}>
            <Text variant={variant} fontWeight={fontWeight}>
                {children}
            </Text>
        </StyledAnchor>
    );
};
