import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { FuelOfferDetailPage as OriginalFuelOfferDetailPage } from './FuelOfferDetailPage';

const FuelOfferDetailPage = (props) => {
    return (
        <AppProviders>
            <OriginalFuelOfferDetailPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(FuelOfferDetailPage, 'fuelOfferDetailPage');
