import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { FuelOfferOverviewPage as OriginalOverviewPage } from './FuelOfferOverviewPage';

const FuelOfferOverviewPage = (props) => {
    return (
        <AppProviders>
            <OriginalOverviewPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(FuelOfferOverviewPage, 'reactFuelOfferOverviewPage');
