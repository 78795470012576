import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

interface BaseInputProps extends ComponentPropsWithoutRef<'input'> {
    $hasLeadingVisual: boolean;
    $hasTrailingVisual: boolean;
}

export const BaseInput = styled.input<BaseInputProps>`
    font-size: 16px;
    color: ${(props) =>
        props.disabled ? get('colors.foreground.disabled') : get('colors.foreground.default')} !important;
    background: transparent;
    border: none;
    width: 100%;
    line-height: 24px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: ${(p) => (p.$hasLeadingVisual ? 8 : 10)}px;
    padding-right: ${(p) => (p.$hasTrailingVisual ? 8 : 10)}px;
    margin: 0;
    transition: padding 75ms;

    &:focus {
        outline: none;
    }

    &:hover {
        // required because inputs.less sets a hover
        // style for all input components
        border: none;
    }

    &::placeholder {
        color: ${get('colors.foreground.subtle')};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 60px transparent inset !important;
        background-color: transparent !important;
        background-clip: content-box !important;
    }

    // required for 1Password
    &[data-com-onepassword-filled='light'],
    &[data-com-onepassword-filled='dark'] {
        background-color: transparent !important;
    }
`;
