import styled from 'styled-components';

import { theme } from '../../../common/ui/theme';

import { CoreInputProps } from './CoreInput';

const getLabelColor = (disabled?: CoreInputProps['disabled'], focused?: boolean) => {
    if (disabled) {
        return theme.colors.foreground.disabled;
    }

    return focused ? theme.colors.accent.foreground : theme.colors.foreground.muted;
};

interface InputLabelTextProps extends Pick<CoreInputProps, 'disabled'> {
    $isFocused?: boolean;
}

export const InputLabelText = styled.div<InputLabelTextProps>`
    color: ${(props) => getLabelColor(props.disabled, props.$isFocused)};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;

    transition: color 75ms;
`;
