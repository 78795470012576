import { useField } from 'formik';

import { FormattedNumberInput, FormattedNumberInputProps } from './FormattedNumberInput';

interface FormikFormattedNumberInputProps
    extends Omit<
        FormattedNumberInputProps,
        | 'prefix'
        | 'step'
        | 'maxLength'
        | 'groupSeparator'
        | 'decimalSeparator'
        | 'onValueChange'
        | 'onChange'
        | 'onBlur'
        | 'value'
    > {
    name: string;
}

export const FormikFormattedNumberInput = (props: FormikFormattedNumberInputProps) => {
    const [field, meta, helpers] = useField(props.name);

    const handleOnValueChange = (value?: string) => {
        helpers.setValue(value ?? '');
    };

    return (
        <FormattedNumberInput
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error && typeof meta.error === 'string' ? meta.error : props.caption}
            onValueChange={handleOnValueChange}
            prefix={undefined}
            step={1}
            maxLength={10}
            groupSeparator=","
            decimalSeparator="."
            {...props}
            {...field}
            // remove the onChange handler from Formik
            onChange={undefined}
        />
    );
};
