import { PriceCompareFuelMessage } from '../../../../types/PriceCompareFuelMessage';
import { VesselMessage } from '../../../../types/VesselMessage';
import { getFuelPriceCompare } from '../../../common/api/clients/gateway.api';

import { useEnhancedSchedule } from './useEnhancedSchedule';

export const useFuelSchedulesWithPrices = (vessel: VesselMessage) => {
    return useEnhancedSchedule<PriceCompareFuelMessage>(vessel, (vesselId: string, portIds: string[]) =>
        getFuelPriceCompare(vesselId, portIds).then((compareMessage) => compareMessage.prices)
    );
};
