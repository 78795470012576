const AppSettings = {
    VERSION: '6.0.0',
    ENV: 'production',
    SENTRY_DSN: 'https://f2addee7c0a849c28f0049d6f1fc04c7@sentry.io/278221',
    googleAnalyticsId: 'G-HVD6P0GK8F',
    googleApiURLKey: 'AIzaSyCItcgw6J6eZxVzHcke7SUJ_gUewOdw_z4',
    magicBellApiKey: '0236db337b6ac330c1e1cbb1abfcca58e67200e4',
    routes: {
        api: '/api',
        gateway: '/api/gateway',
    },
    launchDarklyClientSideId: '65d46b4443c600100518f0f3',
    closelinkChartsUrl: 'https://charts.closelink.com',
};

export default AppSettings;
