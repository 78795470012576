import { AxiosProgressEvent, AxiosResponse } from 'axios';
import { useFormikContext } from 'formik';

import { FileManagementResponse } from '../../../types/FileManagementResponse';
import { FileMessage } from '../../../types/FileMessage';
import * as fileManagement from '../../common/api/clients/fileManagement.api';
import * as orderFileManagement from '../../common/api/clients/orderFileManagement.api';
import { Attachments } from '../../common/Attachments/components/Attachments';

interface FuelEnquiryFormikState {
    state: string;
    orderId?: string;
    customerId: string;
    files?: FileMessage[];
}

export const FuelEnquiryAttachments = () => {
    const formikContext = useFormikContext<FuelEnquiryFormikState>();
    const isDraft = formikContext.values.state === 'DRAFT';

    const addFile = (file: FileManagementResponse) => {
        const files = formikContext.values.files ?? [];

        formikContext.setValues({
            ...formikContext.values,
            files: files.concat(file),
        });
    };

    const removeFile = (fileId: string) => {
        const files = formikContext.values.files ?? [];

        formikContext.setValues({
            ...formikContext.values,
            files: files.filter((file) => file.id !== fileId),
        });
    };

    const uploadFile = async (
        file: File,
        onUploadProgress: (event: AxiosProgressEvent) => void
    ): Promise<FileManagementResponse> => {
        let fileManagementResponse;
        if (isDraft) {
            fileManagementResponse = await orderFileManagement.uploadFile(
                file,
                formikContext.values.orderId!!,
                onUploadProgress
            );
        } else {
            fileManagementResponse = await fileManagement.uploadFile(
                file,
                formikContext.values.customerId,
                onUploadProgress
            );
        }

        addFile(fileManagementResponse);

        return fileManagementResponse;
    };

    const deleteFile = async (fileId: string): Promise<void> => {
        if (isDraft) {
            await orderFileManagement.deleteFile([fileId], formikContext.values.orderId!!);

            removeFile(fileId);
            return Promise.resolve();
        }

        removeFile(fileId);
        return Promise.resolve();
    };

    const downloadFile = (fileId: string): Promise<AxiosResponse> => {
        return orderFileManagement.downloadFile(fileId, formikContext.values.orderId!!);
    };

    const invalidateCache = async () => {
        // Work around React unmounting warning.
        return new Promise<void>((resolve) => {
            setTimeout(resolve, 0);
        });
    };

    return (
        <Attachments
            files={formikContext.values.files ?? []}
            companyId={formikContext.values.customerId}
            config={{
                uploadFile,
                deleteFile,
                invalidateCache,
                downloadFile,
                hasWriteRights: true,
                enableDownloadUploadedFiles: isDraft,
            }}
        />
    );
};
