import { FormEvent } from 'react';

import { SupplierSettingsMessage } from '../../../../types/SupplierSettingsMessage';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { Text } from '../../../cdl/Text/Text';
import { Tooltip } from '../../../cdl/Tooltip/Tooltip';
import { TextButton } from '../../../common/buttons/TextButton';
import { translate } from '../../../common/helpers/translate.helper';
import { IconInfoCircle } from '../../../common/icons/cdl/InfoCircle';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex';
import { SettingsSection } from '../../SettingsSection';

import { MessageSettingsForm } from './MessageSettingsForm';
import { useSupplierSettingsEdit } from './useSupplierSettingsEdit';

interface SupplierMessageSettingsProps {
    supplierSettings: SupplierSettingsMessage;
    supplierId: string;
}

export const SupplierMessageSettings = ({ supplierSettings, supplierId }: SupplierMessageSettingsProps) => {
    const { push, pop } = useDrawer();
    const updateSupplierSettingsMutation = useSupplierSettingsEdit();

    const onSubmit = ({ event, data }: { event: FormEvent; data: SupplierSettingsMessage }) => {
        event.preventDefault();
        updateSupplierSettingsMutation.mutate({ id: supplierId, settings: data });
        pop();
    };

    const openMessageSettings = () => {
        push({
            content: <MessageSettingsForm supplierSettingsData={supplierSettings} onSubmit={onSubmit} />,
        });
    };

    const messageSettings = [
        {
            label: translate('supplier.messageSettings.quotationMessageLabel'),
            value: <Text>{supplierSettings.messageSettings.quotationMessage || '-'}</Text>,
        },
        {
            label: translate('supplier.messageSettings.orderConfirmationMessageLabel'),
            value: <Text>{supplierSettings.messageSettings.orderConfirmationMessage || '-'}</Text>,
        },
    ];

    return (
        <SettingsSection
            label={
                <Flex alignItems="center" gap={3}>
                    {translate('supplier.messageSettings.headline')}
                    <Tooltip label={translate('supplier.messageSettings.explanation')}>
                        <span>
                            <IconInfoCircle width={16} height={16} />
                        </span>
                    </Tooltip>
                </Flex>
            }
        >
            <Box>
                <Box>
                    {messageSettings.map((setting, index) => {
                        return (
                            <Box key={index} marginBottom={3}>
                                <Text color="foreground.muted">{setting.label}: </Text>
                                <Text>{setting.value}</Text>
                            </Box>
                        );
                    })}
                </Box>

                <Box marginTop={4}>
                    <TextButton onClick={openMessageSettings}>{translate('company.editMessages')}</TextButton>
                </Box>
            </Box>
        </SettingsSection>
    );
};
