import { FormikCheckbox } from '../../../../cdl/Checkbox/FormikCheckbox';
import { Text } from '../../../../cdl/Text/Text';
import { translate } from '../../../../common/helpers/translate.helper';
import { Box } from '../../../../common/ui/Box';

export const FormikVesselAdminSection = () => {
    return (
        <div>
            <Text variant="subtitle1">{translate('vessel.edit.adminSettings')}</Text>

            <Box display="grid" gap={4} marginTop={5}>
                <FormikCheckbox name="active" label={translate('vessel.edit.activeVessel')} />
                <FormikCheckbox name="paying" label={translate('vessel.edit.payingVessel')} />
                <FormikCheckbox name="directOrderAllowed" label={translate('vessel.edit.directOrder')} />
            </Box>
        </div>
    );
};
