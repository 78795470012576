import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteBunkerStop } from '../../common/api/clients/bunkerPlan.api';
import { queryKeys } from '../../common/api/queryKeys';

export const useBunkerStopDeletion = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: deleteBunkerStop,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.bunkerStops() });
        },
    });
};
