import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { SetNewPasswordPage as OriginalSetNewPasswordPage } from './SetNewPasswordPage';

const SetNewPasswordPage = (props) => (
    <AppProviders>
        <OriginalSetNewPasswordPage {...props} />
    </AppProviders>
);

export default createDirectiveFromReactComponent(SetNewPasswordPage, 'reactSetNewPassword');
