import PropTypes from 'prop-types';

import { Button } from '../cdl/Button/Button';
import { useDrawer } from '../cdl/Drawer/useDrawer.hook';
import { IconButton } from '../cdl/IconButton/IconButton';
import { Text } from '../cdl/Text/Text';
import { ButtonGroup } from '../common/buttons/ButtonGroup';
import { HeaderWithLine } from '../common/HeaderWithLine/HeaderWithLine';
import { translate } from '../common/helpers/translate.helper';
import { useEnums } from '../common/hooks/useEnums';
import { IconPencil } from '../common/icons/cdl/Pencil';
import { IconPlus } from '../common/icons/cdl/Plus';
import { IconTrash } from '../common/icons/cdl/Trash';
import { Box } from '../common/ui/Box';

import { ProductCategoryForm } from './ProductCategoryForm';

export const FormProductCategoryLimitationsSection = ({ productCategories, onSubmit }) => {
    const { push, pop } = useDrawer();
    const { getHumanReadableValue } = useEnums();

    const alreadyExists = (newCategory) => {
        return productCategories.some(
            (category) =>
                category.sulphurContent === newCategory.sulphurContent &&
                category.productGroup === newCategory.productGroup
        );
    };

    const onProductCategoryAddSubmit = ({ data: newCategory }) => {
        if (!alreadyExists(newCategory)) {
            onSubmit(productCategories.concat(newCategory));
        }
        pop();
    };

    const onProductCategoryEditSubmit = ({ data: newCategory, oldCategory }) => {
        const index = productCategories.indexOf(oldCategory);
        const updatedProductCategories = [...productCategories];

        if (!alreadyExists(newCategory)) {
            updatedProductCategories.splice(index, 1, newCategory);
            onSubmit(updatedProductCategories);
        } else {
            updatedProductCategories.splice(index, 1);
            onSubmit(updatedProductCategories);
        }

        pop();
    };

    const openProductCategoryModal = (productCategory) => {
        push({
            width: '15vw',
            offsetParent: false,
            content: productCategory ? (
                <ProductCategoryForm
                    title={translate('keyPorts.editProductCategory')}
                    onSubmit={onProductCategoryEditSubmit}
                    data={productCategory}
                />
            ) : (
                <ProductCategoryForm
                    title={translate('keyPorts.addProductCategory')}
                    onSubmit={onProductCategoryAddSubmit}
                />
            ),
        });
    };
    const deleteProductCategoryLimitation = (productCategoryToDelete) => {
        onSubmit(productCategories.filter((productCategory) => productCategory !== productCategoryToDelete));
    };
    return (
        <Box marginTop={5}>
            <HeaderWithLine>
                <Text variant="subtitle1" as="h2">
                    {translate('keyPorts.productCategoryLimitation.headline')}
                </Text>
            </HeaderWithLine>

            <Box display="flex" flexDirection="column" rowGap={5} marginTop={5} alignItems="start">
                <Text>{translate('keyPorts.productCategoryLimitation.text')}</Text>

                {productCategories?.length ? (
                    <>
                        {productCategories.map((productCategory, index) => (
                            <Box
                                key={index}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                            >
                                <Text size={14}>
                                    {`${getHumanReadableValue(
                                        'productGroup',
                                        productCategory.productGroup
                                    )} - ${getHumanReadableValue('sulphurContent', productCategory.sulphurContent)}`}
                                </Text>

                                <ButtonGroup>
                                    <IconButton
                                        type="button"
                                        icon={IconPencil}
                                        onClick={() => openProductCategoryModal(productCategory)}
                                    />

                                    <IconButton
                                        type="button"
                                        destructive
                                        icon={IconTrash}
                                        onClick={() => deleteProductCategoryLimitation(productCategory)}
                                    />
                                </ButtonGroup>
                            </Box>
                        ))}
                    </>
                ) : null}

                <Button leadingVisual={<IconPlus />} type="button" onClick={() => openProductCategoryModal()}>
                    {translate('keyPorts.addProductCategory')}
                </Button>
            </Box>
        </Box>
    );
};

FormProductCategoryLimitationsSection.propTypes = {
    productCategories: PropTypes.array,
    onSubmit: PropTypes.func,
};
