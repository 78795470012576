import React from 'react';
import { OptionProps } from 'react-select';
import styled from 'styled-components';

import { translate } from '../../../common/helpers/translate.helper';
import { IconPlus } from '../../../common/icons/cdl/Plus';
import { Box } from '../../../common/ui/Box';
import { get } from '../../../common/ui/get';

import { StyledAgentOption } from './CustomSimpleAgentOption';

const InteractiveText = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: row;
    color: ${get('colors.accent.foreground')};
    font-weight: ${get('fontWeights.medium')};
    column-gap: ${get('space.2')}px;
    height: 100%;
`;

const AgentCreateOptionLabel = () => (
    <InteractiveText>
        <IconPlus height={16} width={16} /> {translate('selectAgent.addAgent')}
    </InteractiveText>
);

interface AgentCreateOptionProps extends Omit<OptionProps, 'children'> {}

export const AgentCreateOption = (props: AgentCreateOptionProps) => {
    return (
        <StyledAgentOption {...props}>
            <Box
                style={{
                    gridColumnStart: 2,
                }}
            >
                <AgentCreateOptionLabel />
            </Box>
        </StyledAgentOption>
    );
};
