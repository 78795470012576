import { AgentMessage } from '../../../../../../types/AgentMessage';
import { Text } from '../../../../../cdl/Text/Text';
import { AgentInfoPopup } from '../../../../../common/CoreDataPopup/AgentInfoPopup';
import { translate } from '../../../../../common/helpers/translate.helper';

import { InvolvedParty } from './InvolvedParty';

interface AgentPartyProps {
    agent?: AgentMessage;
}

export const AgentParty = ({ agent }: AgentPartyProps) => {
    return (
        <InvolvedParty
            disabled={!agent}
            label={translate('offer.detail.coreData.agent')}
            modalContent={<AgentInfoPopup agent={agent} />}
        >
            {agent ? (
                <>
                    <Text>{agent.name}</Text>
                    {agent.contact?.emailAddress ? (
                        <Text color="foreground.subtle">{agent.contact.emailAddress}</Text>
                    ) : null}
                    {agent.contact?.phoneNumber ? (
                        <Text color="foreground.subtle">{agent.contact.phoneNumber}</Text>
                    ) : null}
                </>
            ) : (
                translate('offer.detail.coreData.noAgent')
            )}
        </InvolvedParty>
    );
};
