import { CustomerGroupMessage } from '../../../../types/CustomerGroupMessage';
import { getSupplierGroups } from '../../../common/api/clients/supplierGroups.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { usePaginationQuery } from '../../../common/hooks/usePaginationQuery';

export const useSupplierGroupsPagination = (searchQuery: string, page: number) => {
    return usePaginationQuery<CustomerGroupMessage>({
        queryKey: queryKeys.supplierGroups({ searchQuery, page }),
        queryFn: () => getSupplierGroups(searchQuery, page),
        prefetchQueryKey: queryKeys.supplierGroups({ searchQuery, page: page + 1 }),
        prefetchQueryFn: () => getSupplierGroups(searchQuery, page + 1),
    });
};
