import { useField } from 'formik';
import PropTypes from 'prop-types';

import { VesselSelect } from '../../../vessel/VesselSelect';

export const FormikVesselSelect = (props) => {
    const [field, , helpers] = useField(props.name);

    const onChange = (vessel) => {
        helpers.setValue(vessel.id);
        props.onVesselSelect?.(vessel);
    };

    return <VesselSelect {...props} vesselId={field.value} onChange={onChange} context="FUEL" />;
};

FormikVesselSelect.propTypes = {
    name: PropTypes.string.isRequired,
    onVesselSelect: PropTypes.func,
};
