import { Text } from '../cdl/Text/Text';
import { translate } from '../common/helpers/translate.helper';
import { useDocumentTitle } from '../common/hooks/useDocumentTitle';
import { useRole } from '../common/hooks/useRole';
import { LocationStateProvider } from '../common/LocationState/LocationStateProvider';
import { Page } from '../common/Page/Page';
import { Flex } from '../common/ui/Flex';

import { AdminSearchResultOfferTable } from './components/AdminSearchResultOfferTable';
import { AdminSearchResultOrderTable } from './components/AdminSearchResultOrderTable';
import { CustomerSearchResult } from './components/CustomerSearchResult';
import { SupplierSearchResult } from './components/SupplierSearchResult';

export const SearchResultPage = () => {
    const role = useRole();

    useDocumentTitle(translate('searchResult.title'));

    if (role.isCustomer()) {
        const stateConfig = {
            query: '',
            ordersPage: 0,
        };

        return (
            <LocationStateProvider stateConfig={stateConfig}>
                <Page>
                    <CustomerSearchResult />
                </Page>
            </LocationStateProvider>
        );
    }

    if (role.isSupplier()) {
        const stateConfig = {
            query: '',
            offersPage: 0,
        };

        return (
            <LocationStateProvider stateConfig={stateConfig}>
                <Page>
                    <SupplierSearchResult />
                </Page>
            </LocationStateProvider>
        );
    }

    if (role.isAdmin()) {
        const stateConfig = {
            query: '',
            ordersPage: 0,
            offersPage: 0,
        };

        return (
            <LocationStateProvider stateConfig={stateConfig}>
                <Page>
                    <Flex flexDirection="column" rowGap={8} marginBottom={6}>
                        <Text as="h1" variant="headline" marginBottom={6}>
                            {translate('searchResult.title')}
                        </Text>
                        <AdminSearchResultOrderTable />
                        <AdminSearchResultOfferTable />
                    </Flex>
                </Page>
            </LocationStateProvider>
        );
    }
};
