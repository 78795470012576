import styled from 'styled-components';
import { space, border } from 'styled-system';

import { get as themeGet } from '../../ui/get';

export const StyledInput = styled.input`
    ${space}
    ${border}

    width: 100%;
    font-weight: normal;

    border-color: ${(props) => {
        if (props.$highlight) {
            return themeGet('colors.clear-blue.0');
        }

        if (props.invalid) {
            return themeGet('colors.signal-red.0');
        }

        return null;
    }};

    &:hover,
    &:focus {
        border-color: ${themeGet('colors.clear-blue.0')};
    }

    &:disabled {
        cursor: not-allowed;
        background-color: ${themeGet('colors.dark-blue.6')};
        ${border}
    }

    &.dirty:invalid {
        border-color: ${themeGet('colors.signal-red.0')};
    }

    &[type='checkbox'] {
        width: 16px;
        height: 16px;
    }
    ::placeholder {
        color: ${themeGet('colors.dark-blue.3')};
        opacity: 1;
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }
`;

export const Label = styled.label`
    ${space};
    width: 100%;
    position: relative;
`;
