import { ReactNode } from 'react';

import { MoneyMessage } from '../../../types/MoneyMessage';
import { Text } from '../../cdl/Text/Text';
import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { IconInfoSquare } from '../icons/cdl/InfoSquare';
import { Box } from '../ui/Box';

import { EmptyFuelPricePopover } from './EmptyFuelPricePopover';

interface PriceTitleProps {
    price?: MoneyMessage;
    hasPlattsCredentials: boolean;
    customerId: string;
    label: string;
    tooltipLabel?: ReactNode;
}

export const FuelPriceTitle = ({ price, hasPlattsCredentials, customerId, label, tooltipLabel }: PriceTitleProps) => {
    return (
        <Box display="flex" columnGap={2} color="foreground.subtle">
            <Text variant="extraSmall">{label}</Text>
            {price ? (
                <Box width="16px" height="16px">
                    {tooltipLabel ? (
                        <Tooltip label={tooltipLabel} align="start">
                            <span>
                                <IconInfoSquare width={16} height={16} />
                            </span>
                        </Tooltip>
                    ) : (
                        <IconInfoSquare width={16} height={16} />
                    )}
                </Box>
            ) : (
                <EmptyFuelPricePopover customerId={customerId} hasPlattsCredentials={hasPlattsCredentials} />
            )}
        </Box>
    );
};
