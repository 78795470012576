import { useQuery } from '@tanstack/react-query';

import { queries } from '../../../../common/api/queryKeys/queries';

export const useVesselTanks = (vesselId: string) => {
    const {
        isPending,
        isError,
        data: vesselTankResponse,
    } = useQuery({
        ...queries.vessels.detail(vesselId)._ctx.tanks,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        gcTime: 0,
    });

    return {
        vesselTankResponse,
        isPending: isPending || isError,
    };
};
