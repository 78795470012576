import { MultiValue, OnChangeValue, PropsValue } from 'react-select';

import { ComboBox, ComboBoxProps, DefaultOption } from '../../cdl/ComboBox/ComboBox';
import { useEnums } from '../hooks/useEnums';

export interface EnumComboBoxProps<IsMulti extends boolean>
    extends Omit<ComboBoxProps<DefaultOption, IsMulti>, 'value' | 'onChange'> {
    onChange: (options: string[] | string) => void;
    enumType: string;
    context?: string;
    value: string[] | string;
}

export function EnumComboBox<IsMulti extends boolean>({
    enumType,
    context,
    onChange,
    ...rest
}: EnumComboBoxProps<IsMulti>) {
    const { getEnumsForTypeAndContext, getHumanReadableValue } = useEnums();

    const options = Object.keys(getEnumsForTypeAndContext(enumType, context)).map((key: string) => ({
        label: getHumanReadableValue(enumType, key),
        value: key,
    }));

    function updateSelectedValue(options: OnChangeValue<DefaultOption, IsMulti>) {
        if (rest.isMulti) {
            onChange((options as MultiValue<DefaultOption>).map((it) => it.value));
        } else {
            onChange((options as DefaultOption).value);
        }
    }

    const getValue = (): PropsValue<DefaultOption> => {
        if (!rest.value) {
            return [];
        }

        if (rest.isMulti) {
            const castedValue = rest.value as string[];

            return castedValue.map((it: string) => ({ label: getHumanReadableValue(enumType, it), value: it }));
        }

        return { label: getHumanReadableValue(enumType, rest.value), value: rest.value as string };
    };

    return (
        <ComboBox
            {...rest}
            value={getValue()}
            onChange={updateSelectedValue}
            loadOptions={(search) =>
                Promise.resolve(
                    options.filter((it: DefaultOption) => it.label.toLowerCase().includes(search.toLowerCase()))
                )
            }
        />
    );
}
