export enum ContractTab {
    TENDERED = 'tendered',
    CONTRACTED = 'contracted',
    CONCLUDED = 'concluded',
}

export const contractTabFilter = {
    tendered: {
        tender: true,
    },
    contracted: {
        tender: false,
        active: true,
    },
    concluded: {
        tender: false,
        active: false,
    },
};
