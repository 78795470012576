import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconAlertOctagon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8.714 4a.645.645 0 0 0-.007.007l-4.7 4.7A.645.645 0 0 0 4 8.714v6.572l.007.007 4.7 4.7.007.007h6.572l.007-.007 4.7-4.7.007-.007V8.714l-.007-.007-4.7-4.7A3.192 3.192 0 0 1 15.286 4H8.714Zm-.808-1.839A1.93 1.93 0 0 1 8.7 2h6.6c.267 0 .534.046.794.161.255.114.454.273.613.432l4.7 4.7c.16.159.318.358.432.613.115.26.161.527.161.794v6.6c0 .267-.046.534-.161.794a2.034 2.034 0 0 1-.432.613l-4.7 4.7a2.034 2.034 0 0 1-.613.432c-.26.115-.527.161-.794.161H8.7c-.267 0-.534-.046-.794-.161a2.035 2.035 0 0 1-.613-.432l-4.7-4.7a2.034 2.034 0 0 1-.432-.613A1.929 1.929 0 0 1 2 15.3V8.7c0-.267.046-.534.161-.794.114-.255.273-.454.432-.613l4.7-4.7c.159-.16.358-.318.613-.432ZM11 12V8a1 1 0 1 1 2 0v4a1 1 0 1 1-2 0Zm0 4a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1Z"
            clipRule="evenodd"
        />
    </svg>
);
