import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../../common/helpers/translate.helper';

import { SimpleConfirmPopup } from './components/SimpleConfirmPopup';

interface ConfirmChangesPopupProps {
    onConfirm: () => Promise<void>;
    onDismiss: () => void;
    isOpen: boolean;
    isSubmitting: boolean;
}

export const ConfirmChangesPopup = (props: ConfirmChangesPopupProps) => {
    const { addErrorToast } = useToasts();

    const onSubmit = async () => {
        try {
            await props.onConfirm();
        } catch {
            addErrorToast(translate('global.genericToastError'));
        }
    };

    return (
        <SimpleConfirmPopup
            isOpen={props.isOpen}
            onSubmit={onSubmit}
            onDismiss={props.onDismiss}
            headline={translate('alert.confirmChanges.title')}
            description={translate('alert.confirmChanges.message')}
            buttonText={translate('alert.confirmChanges.button')}
            isSubmitting={props.isSubmitting}
        />
    );
};
