export enum LubesProductGroup {
    ANCILLARY_OIL = 'ANCILLARY_OIL',
    BASE_OIL = 'BASE_OIL',
    BLEND_ON_BOARD_ADDITIVES = 'BLEND_ON_BOARD_ADDITIVES',
    CYLINDER_OIL = 'CYLINDER_OIL',
    ENGINE_OIL_4_STROKE = 'ENGINE_OIL_4_STROKE',
    GREASE = 'GREASE',
    SAMPLE_ANALYSIS = 'SAMPLE_ANALYSIS',
    TEST_KIT = 'TEST_KIT',
    SYSTEM_OIL_2_STROKE = 'SYSTEM_OIL_2_STROKE',
}
