import { AxiosPromise } from 'axios';
import FileDownload from 'js-file-download';

import { extractFileName } from '../../../../common/helpers/extractFileName.helper';
import { translate } from '../../../../common/helpers/translate.helper';

import { useExportOfferMutation } from './useExportOfferMutation';

export const useOfferExport = (): { downloadPdf: (offerId: string) => AxiosPromise<Blob> } => {
    const exportPdfMutation = useExportOfferMutation();

    const downloadPdf = async (offerId: string) => {
        return exportPdfMutation.mutateAsync(
            {
                id: offerId,
            },
            {
                onSuccess: (response) => {
                    FileDownload(
                        response.data,
                        extractFileName(
                            response.headers['content-disposition'] || '',
                            translate('attachments.fileNameFallback')
                        ),
                        'application/pdf'
                    );
                },
            }
        );
    };

    return {
        downloadPdf,
    };
};
