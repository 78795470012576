import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Text } from '../../../../../../cdl/Text/Text';
import { Tooltip } from '../../../../../../cdl/Tooltip/Tooltip';
import { Characters } from '../../../../../../common/helpers/unicode.helper';
import { get } from '../../../../../../common/ui/get';

const MessageWrapper = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1;
    color: ${get('colors.foreground.muted')};
`;

interface MessageQuoteProps {
    children: string;
}

const MessageQuote = ({ children }: MessageQuoteProps) => {
    return (
        <Text variant="action" fontStyle="italic">
            {Characters.LEFT_DOUBLE_QUOTATION_MARK}
            {children}
            {Characters.RIGHT_DOUBLE_QUOTATION_MARK}
        </Text>
    );
};

interface ApprovalRequestMessageDisplayProps {
    message?: string;
}

export const ApprovalRequestMessageDisplay = ({ message }: ApprovalRequestMessageDisplayProps) => {
    const requestMessageRef = useRef<HTMLDivElement>(null);
    const [isRequestMessageTruncated, setIsRequestMessageTruncated] = useState(false);

    useEffect(() => {
        if (requestMessageRef.current) {
            setIsRequestMessageTruncated(requestMessageRef.current.scrollWidth > requestMessageRef.current.clientWidth);
        }
    }, []);

    if (!message) {
        return null;
    }

    if (isRequestMessageTruncated) {
        return (
            <Tooltip label={<MessageQuote>{message}</MessageQuote>}>
                <MessageWrapper ref={requestMessageRef}>
                    <MessageQuote>{message}</MessageQuote>
                </MessageWrapper>
            </Tooltip>
        );
    }

    return (
        <MessageWrapper ref={requestMessageRef}>
            <MessageQuote>{message}</MessageQuote>
        </MessageWrapper>
    );
};
