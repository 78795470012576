import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { GetLubesPricesParams } from '../../../../common/api/clients/gateway.api';
import { queries } from '../../../../common/api/queryKeys/queries';

interface UseSchedulePriceCompareProps extends GetLubesPricesParams {}

export const usePriceCompare = (props: UseSchedulePriceCompareProps) => {
    return useQuery({
        ...queries.priceCompare.lubes(props),
        placeholderData: keepPreviousData,
        staleTime: Infinity,
    });
};
