import PropTypes from 'prop-types';
import { useState } from 'react';

import { DrawerFormLayout } from '../../cdl/Drawer/DrawerFormLayout';
import { Form } from '../../common/form-elements/Form/Form';
import { FormFuelMoneyInput } from '../../common/form-elements/NumberInputs/FormFuelMoneyInput';
import { FormRadioButton } from '../../common/form-elements/RadioButton/RadioButton';
import { FormTextInput } from '../../common/form-elements/TextInput/TextInput';
import { translate } from '../../common/helpers/translate.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { Box } from '../../common/ui/Box';

const isNotEmpty = (value) => {
    if (!value) {
        return false;
    }

    if (typeof value === 'string' && value === '') {
        return false;
    }

    return true;
};

export const SurchargeForm = ({ onSubmit, data }) => {
    const [formState, setFormState] = useState(data);
    const [isValid, setIsValid] = useState(false);
    const { enums, isPending: enumsLoading, getHumanReadableValue } = useEnums();

    const validateForm = (data) => {
        const areRequiredFieldsValid = ['surchargeType', 'amount'].every((name) => isNotEmpty(data[name]));
        setIsValid(areRequiredFieldsValid && Number(data.amount) !== 0);
    };

    if (enumsLoading) {
        return null;
    }

    const surchargeOptions = Object.keys(enums.surchargeType).filter((option) => option !== 'CUSTOM');

    return (
        <Box height="100%">
            <Form
                onSubmit={({ event, data }) => {
                    event.preventDefault();
                    onSubmit(data);
                }}
                data={formState}
                onChange={({ data: newData }) => {
                    setFormState(newData);
                    validateForm(newData);
                }}
                height="100%"
            >
                <DrawerFormLayout title={translate('surchargeForm.title')} isSubmitButtonDisabled={!isValid}>
                    <Box display="grid" rowGap={1}>
                        {surchargeOptions.map((option) => (
                            <FormRadioButton
                                key={option}
                                label={getHumanReadableValue('surchargeType', option)}
                                value={option}
                                dataPath="surchargeType"
                            />
                        ))}

                        <FormRadioButton
                            label={
                                <FormTextInput
                                    dataPath="customOption"
                                    onFocus={() => {
                                        setFormState({
                                            ...formState,
                                            surchargeType: 'CUSTOM',
                                        });
                                    }}
                                    placeholder={translate('surchargeForm.placeholders.customOption')}
                                />
                            }
                            dataPath="surchargeType"
                            value="CUSTOM"
                        />
                    </Box>

                    <Box marginTop={5}>
                        <FormFuelMoneyInput
                            dataPath="amount"
                            required
                            label={translate('surchargeForm.labels.amount')}
                            placeholder={translate('surchargeForm.placeholders.amount')}
                        />
                    </Box>
                </DrawerFormLayout>
            </Form>
        </Box>
    );
};

SurchargeForm.propTypes = {
    onSubmit: PropTypes.func,
    data: PropTypes.object,
};
