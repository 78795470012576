import styled from 'styled-components';
import { flex, FlexboxProps } from 'styled-system';

import { Box, BoxProps } from './Box';

export interface FlexProps extends BoxProps, FlexboxProps {}

/** @deprecated Use Box instead */

export const Flex = styled(Box as any)<FlexProps>`
    display: flex;
    ${flex};
`;
