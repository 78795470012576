import { CounterOffer } from '../../../../types/CounterOffer';
import { StatusBar } from '../../../cdl/StatusBar/StatusBar';
import { translate } from '../../../common/helpers/translate.helper';
import { CircleValidity } from '../../../common/Validity/CircleValidity';
import { useCountdown } from '../../../common/Validity/hooks/useCountdown';
import { OfferModel } from '../../model/OfferModel';

import { CounterOfferReviewButton } from './CounterOffer/CounterOfferReviewButton';

interface FuelSupplierCounterOfferExpiredStatusBarProps {
    onButtonClick: () => void;
    latestCounterOffer: CounterOffer;
    offer: OfferModel;
}

export const FuelSupplierCounterOfferExpiredStatusBar = (props: FuelSupplierCounterOfferExpiredStatusBarProps) => {
    const latestCounterOffer = props.latestCounterOffer;
    const remainingTime = useCountdown(latestCounterOffer.validUntil);
    const isCounterOfferExpired = remainingTime <= 0;

    return (
        <StatusBar
            variant="neutral"
            headline={translate('statusbar.supplier.fuel.counterOfferReceived.headline')}
            subHeadline={translate('statusbar.supplier.fuel.counterOfferReceived.subHeadline')}
            trailingVisual={
                <>
                    {!!latestCounterOffer.validUntil && !!latestCounterOffer.validityTimeMinutes ? (
                        <CircleValidity
                            validUntil={latestCounterOffer.validUntil}
                            validityTime={latestCounterOffer.validityTimeMinutes}
                            background="transparent"
                        />
                    ) : null}

                    <CounterOfferReviewButton
                        isCounterOfferExpired={isCounterOfferExpired}
                        latestCounterOffer={latestCounterOffer}
                        offer={props.offer}
                    />
                </>
            }
        />
    );
};
