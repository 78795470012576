import PropTypes from 'prop-types';

import { Button } from '../../common/buttons/Button';
import { translate } from '../../common/helpers/translate.helper';
import { IconChevronLeft } from '../../common/icons/cdl/ChevronLeft';

export const PreviousButton = ({ onClick }) => {
    return (
        <Button type="button" color="outline-dark" onClick={onClick}>
            <IconChevronLeft /> {translate('fuelEnquiry.navigation.previous')}
        </Button>
    );
};

PreviousButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};
