import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '../../common/buttons/Button';
import { translate } from '../../common/helpers/translate.helper';

import { useDraftCreate } from './hooks/useDraftCreate';
import { useDraftUpdate } from './hooks/useDraftUpdate';
import { mapToFuelOrderRequestObject } from './mapToFuelOrderRequestObject';

export const SaveAsDraftButton = ({ disabled }) => {
    const { values } = useFormikContext();

    const draftCreate = useDraftCreate();
    const draftUpdate = useDraftUpdate();

    const navigate = useNavigate();
    const location = useLocation();

    const onClick = () => {
        const order = {
            ...mapToFuelOrderRequestObject(values),
            state: 'DRAFT',
        };

        if (values.orderId) {
            draftUpdate.mutate({
                orderId: values.orderId,
                order,
            });
        } else {
            draftCreate.mutate(order, {
                onSuccess: (data) => {
                    const orderId = data.id;

                    navigate(`/fuel/create?orderId=${orderId}`, {
                        state: location.state,
                    });
                },
            });
        }
    };

    return (
        <Button type="button" color="outline-dark" disabled={disabled} onClick={onClick}>
            {translate('fuelEnquiry.navigation.saveAsDraft')}
        </Button>
    );
};

SaveAsDraftButton.propTypes = {
    disabled: PropTypes.bool,
};
