import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { FuelOfferEditFlow as OriginalFuelOfferEditFlow } from './FuelOfferEditFlow';

const FuelOfferEditFlow = (props) => {
    return (
        <AppProviders>
            <OriginalFuelOfferEditFlow {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(FuelOfferEditFlow, 'reactFuelOfferEditFlow');
