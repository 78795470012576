import { AxiosError } from 'axios';
import { Formik, FormikErrors } from 'formik';
import { useState } from 'react';

import { PortErrorResponseMessage } from '../../../../types/PortErrorResponseMessage';
import { PortMessage, PortRequestMessage } from '../../../../types/PortMessage';
import { translate } from '../../../common/helpers/translate.helper';
import { FormikPortState } from '../FormikPortState';

import { FormikPortForm } from './FormikPortForm';

interface PortFormProps {
    onSuccess: () => void;
    onSubmit: (portMessage: PortRequestMessage) => Promise<PortMessage>;
    initialValues: FormikPortState;
}

export const PortForm = (props: PortFormProps) => {
    const [error, setError] = useState<string | null>(null);

    const validate = (values: FormikPortState): FormikErrors<FormikPortState> => {
        // Reset error
        if (error) {
            setError(null);
        }

        const errors: FormikErrors<FormikPortState> = {};

        if (!values.name) {
            errors.name = translate('port.requiredError');
        }

        if (!values.locCode) {
            errors.locCode = translate('port.requiredError');
        }

        if (!values.timeZoneId) {
            errors.timeZoneId = translate('port.requiredError');
        }

        if (!values.countryId) {
            errors.countryId = translate('port.requiredError');
        }

        return errors;
    };

    const handleSubmit = async (values: FormikPortState) => {
        const portMessage: PortRequestMessage = {
            locCode: values.locCode,
            name: values.name,
            country: {
                id: values.countryId,
            },
            latitude: values.latitude ? parseFloat(values.latitude.replaceAll(',', '.')) : null,
            longitude: values.longitude ? parseFloat(values.longitude.replaceAll(',', '.')) : null,
            aliases: values.aliases,
            locCodeAliases: values.locCodeAliases,
            timeZoneId: values.timeZoneId,
        };

        try {
            await props.onSubmit(portMessage);

            props.onSuccess();
        } catch (error) {
            const axiosError = error as AxiosError<PortErrorResponseMessage>;
            if (!axiosError.response) {
                setError(translate('port.generalError'));
                return;
            }

            switch (axiosError.response.data.errorCode) {
                case 'ALREADY_EXISTS':
                    setError(translate('port.locodeAlreadyExists'));
            }
        }
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validate}
            onSubmit={handleSubmit}
            component={(props) => <FormikPortForm {...props} error={error} />}
        />
    );
};
