import { Button } from '../../../../cdl/Button/Button';
import { useDrawer } from '../../../../cdl/Drawer/useDrawer.hook';
import { Text } from '../../../../cdl/Text/Text';
import { HeaderWithLine } from '../../../../common/HeaderWithLine/HeaderWithLine';
import { translate } from '../../../../common/helpers/translate.helper';
import { Box } from '../../../../common/ui/Box';

import { CreateSurchargeForm } from './CreateSurchargeForm';
import { SurchargesTable } from './SurchargesTable';

interface LubesSurchargesProps {
    groupId: string;
}

export const LubesSurcharges = ({ groupId }: LubesSurchargesProps) => {
    const { push, pop } = useDrawer();

    const openSurchargeCreateModal = () => {
        push({ content: <CreateSurchargeForm groupId={groupId} onSuccess={pop} />, width: '15vw' });
    };

    return (
        <Box display="flex" flexDirection="column" gap={5}>
            <HeaderWithLine>
                <Text variant="title" as="h2">
                    {translate('surcharges.headline')}
                </Text>

                <Button onClick={openSurchargeCreateModal}>{translate('surcharges.create')}</Button>
            </HeaderWithLine>
            <SurchargesTable groupId={groupId} />
        </Box>
    );
};
