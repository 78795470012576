import { SupplierMessage } from '../../../types/SupplierMessage';

export function sortSuppliersByName(suppliers: SupplierMessage[], defaultSupplierId?: string): SupplierMessage[] {
    return suppliers.sort((a, b) => {
        if (a.id === defaultSupplierId && b.id !== defaultSupplierId) {
            return -1;
        } else if (a.id !== defaultSupplierId && b.id === defaultSupplierId) {
            return 1;
        }

        return a.name.localeCompare(b.name);
    });
}
