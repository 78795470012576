import { translate, translateOrNull } from './translate.helper';

export const translateOrderOfferState = (order, userType) => {
    if (!order) {
        return null;
    }

    if (!userType) {
        return translate(`state.${order.type}.${order.offerState}.title`);
    }

    const translation = translateOrNull(`state.${order.type}.${userType}.${order.offerState}.title`);

    if (translation) {
        return translation;
    }

    return translate(`state.${order.type}.${order.offerState}.title`);
};
