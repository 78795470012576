import { ProductContext } from '../../../../types/ProductContext';
import { Text } from '../../../cdl/Text/Text';
import { CustomerCompanySelect } from '../../../common/form-elements/CompanySelect/CustomerCompanySelect';
import { SupplierCompanySelect } from '../../../common/form-elements/CompanySelect/SupplierCompanySelect';
import { translate as t } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { Page } from '../../../common/Page/Page';
import { PageGrid } from '../../../common/PageGrid';
import { DebouncedSearchTextInput } from '../../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../../common/ui/Box';

import { LubesOrderOverviewSortSelect } from './LubesOrderOverviewSortSelect';
import { LubesOrderOverviewState } from './LubesOrderOverviewState';
import { LubesOrderOverviewTable } from './LubesOrderOverviewTable';

export const LubesOrderOverviewPage = () => {
    return (
        <LubesOrderOverviewState>
            <LubesOrderOverviewPageContent />
        </LubesOrderOverviewState>
    );
};

const LubesOrderOverviewPageContent = () => {
    const role = useRole();
    const { context } = useProductContext();

    useDocumentTitle(t('order.overviewPageTitle'));

    const [locationState, { setLocationFieldValue }] = useLocationState();

    return (
        <Page>
            <PageGrid>
                <Text variant="headline" as="h1">
                    {t('order.overviewPageTitle')}
                </Text>

                <Box display="flex" justifyContent="space-between" columnGap={4}>
                    <Box display="flex" columnGap={4}>
                        <Box minWidth="250px">
                            <DebouncedSearchTextInput
                                placeholder={t('order.searchInputPlaceholder')}
                                value={locationState.searchQuery}
                                onChange={(searchQuery) => setLocationFieldValue('searchQuery', searchQuery)}
                            />
                        </Box>

                        {!role.isOneCompanyUser(context) || role.isAdmin() ? (
                            <Box minWidth="250px" maxWidth="80%">
                                <CustomerCompanySelect
                                    value={locationState.customerIds}
                                    context={ProductContext.LUBES}
                                    isMulti
                                    onChange={(customerIds) => {
                                        setLocationFieldValue('customerIds', customerIds);
                                    }}
                                    placeholder={
                                        role.isAdmin()
                                            ? t('order.filterCustomerPlaceholder')
                                            : t('order.filterCompanyPlaceholder')
                                    }
                                />
                            </Box>
                        ) : null}

                        {role.isAdmin() ? (
                            <Box minWidth="250px" maxWidth="80%">
                                <SupplierCompanySelect
                                    context={ProductContext.LUBES}
                                    isMulti
                                    value={locationState.supplierIds}
                                    onChange={(supplierIds) => {
                                        setLocationFieldValue('supplierIds', supplierIds);
                                    }}
                                    placeholder={t('order.filterSupplierPlaceholder')}
                                />
                            </Box>
                        ) : null}
                    </Box>

                    <Box minWidth="250px">
                        <LubesOrderOverviewSortSelect
                            onChange={(sortValue) => {
                                setLocationFieldValue('sortValue', sortValue);
                            }}
                            value={locationState.sortValue}
                        />
                    </Box>
                </Box>

                <LubesOrderOverviewTable />
            </PageGrid>
        </Page>
    );
};
