import { useState } from 'react';

import { BunkerStopMessage } from '../../../types/BunkerStopsMessage';
import { DropdownMenu } from '../../cdl/DropdownMenu/DropdownMenu';
import { useToasts } from '../../cdl/Toast/useToasts';
import { translate } from '../../common/helpers/translate.helper';
import { useBunkerPlanVesselMutation } from '../hooks/useBunkerPlanVesselMutation';
import { useBunkerStopShareMutation } from '../hooks/useBunkerStopShareMutation';

import { DeleteBunkerStopPopup } from './DeleteBunkerStopPopup';
import { ShareSupplierBunkerStopPopup } from './ShareSupplierBunkerStopPopup';
import { VesselEmailAddressPopup } from './VesselEmailAddressPopup';

interface BunkerPlanDropdownMenuProps {
    bunkerStop: BunkerStopMessage;
}

export const BunkerPlanDropdownMenu = (props: BunkerPlanDropdownMenuProps) => {
    const { addErrorToast, addToast } = useToasts();

    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
    const [isVesselEmailAddressPopupOpen, setIsVesselEmailAddressPopupOpen] = useState(false);

    const bunkerStopShareMutation = useBunkerStopShareMutation();
    const vesselEditMutation = useBunkerPlanVesselMutation(props.bunkerStop.vesselId);

    const shareBunkerStopWithVessel = (vesselEmailAddress: string) => {
        return bunkerStopShareMutation.mutateAsync(
            {
                receiverEmailAddresses: [vesselEmailAddress],
                receiverType: 'VESSEL',
                bunkerStopId: props.bunkerStop.id,
            },
            {
                onError: () => {
                    addErrorToast(translate('global.genericToastError'));
                },
                onSuccess: () => {
                    addToast(translate('bunkerPlan.table.sharingVesselSuccessToast'));
                },
            }
        );
    };

    const onVesselEmailAddressSubmit = async (vesselEmailAddress: string) => {
        try {
            const vesselDetails = props.bunkerStop.vessel;

            await vesselEditMutation.mutateAsync({
                vesselId: vesselDetails.id,
                vessel: {
                    ...vesselDetails,
                    email: vesselEmailAddress,
                },
            });
        } catch {
            setIsVesselEmailAddressPopupOpen(false);
            addErrorToast(translate('global.genericToastError'));
        }

        try {
            await shareBunkerStopWithVessel(vesselEmailAddress);
            setIsVesselEmailAddressPopupOpen(false);
        } catch {
            setIsVesselEmailAddressPopupOpen(false);
        }
    };

    const onVesselOptionSelect = () => {
        const vesselEmailAddress = props.bunkerStop.vessel.email;

        if (!vesselEmailAddress) {
            setIsVesselEmailAddressPopupOpen(true);
            return;
        }

        shareBunkerStopWithVessel(vesselEmailAddress);
    };

    const options = [
        {
            label: translate('bunkerPlan.table.dropdown.vessel'),
            onSelect: onVesselOptionSelect,
        },
        {
            label: translate('bunkerPlan.table.dropdown.supplier'),
            onSelect: () => setIsSharePopupOpen(true),
        },
        {
            label: translate('bunkerPlan.table.dropdown.delete'),
            onSelect: () => setIsDeletePopupOpen(true),
        },
    ];

    return (
        <>
            <DropdownMenu options={options} align="end" />
            <DeleteBunkerStopPopup
                bunkerStopId={props.bunkerStop.id}
                isOpen={isDeletePopupOpen}
                onDismiss={() => setIsDeletePopupOpen(false)}
            />

            <ShareSupplierBunkerStopPopup
                bunkerStopId={props.bunkerStop.id}
                isOpen={isSharePopupOpen}
                onDismiss={() => setIsSharePopupOpen(false)}
            />

            {isVesselEmailAddressPopupOpen ? (
                <VesselEmailAddressPopup
                    isOpen={isVesselEmailAddressPopupOpen}
                    onDismiss={() => setIsVesselEmailAddressPopupOpen(false)}
                    onSubmit={onVesselEmailAddressSubmit}
                />
            ) : null}
        </>
    );
};
