import { AxiosError } from 'axios';
import { useState } from 'react';

import { Button } from '../../../cdl/Button/Button';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { translate } from '../../../common/helpers/translate.helper';
import { UserServerError, useUserInvite } from '../../detail/hooks/useUserInvite';

import { InviteUserForm, InviteUserFormProps } from './InviteUserForm';

interface InviteUserProps {
    onSuccess: () => void;
}

const InviteUser = ({ onSuccess }: InviteUserProps) => {
    const [error, setError] = useState<UserServerError>();
    const inviteUserMutation = useUserInvite();

    const handleSubmit: InviteUserFormProps['onSubmit'] = (userInviteMessage) => {
        inviteUserMutation.mutate(userInviteMessage, {
            onSuccess: onSuccess,
            onError: (error: AxiosError<any>) => {
                if (error.response) {
                    setError({
                        email: userInviteMessage.email,
                        errorCode: error.response?.data.errorCode,
                    });
                }
            },
        });
    };

    return <InviteUserForm onSubmit={handleSubmit} error={error} />;
};

export const InviteUserButton = () => {
    const { push, pop } = useDrawer();

    return (
        <Button
            emphasis="high"
            onClick={() =>
                push({
                    content: <InviteUser onSuccess={pop} />,
                })
            }
        >
            {translate('users.invite')}
        </Button>
    );
};
