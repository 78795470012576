import { useState } from 'react';

import { Button } from '../../../../../../cdl/Button/Button';
import { useToasts } from '../../../../../../cdl/Toast/useToasts';
import { translate } from '../../../../../../common/helpers/translate.helper';
import { useNavigation } from '../../../../../../common/hooks/useNavigation';
import { OrderState } from '../../../../../model/OrderModel';
import { useOrderUpdate } from '../../../hooks/useOrderUpdate';
import { useOfferCardContext } from '../hooks/useOfferCardContext';

import { ConfirmOrderModal } from './ConfirmOrderModal';

export const OrderButton = () => {
    const { offer, order } = useOfferCardContext();
    const [isOpen, setIsOpen] = useState(false);
    const { addErrorToast } = useToasts();
    const { navigate } = useNavigation();

    const orderUpdateMutation = useOrderUpdate();

    const createOrder = () => {
        const closedOrder = { ...order, state: OrderState.CLOSED, chosenOfferId: offer.id };

        orderUpdateMutation.mutate(
            {
                order: closedOrder,
                orderId: order.id,
            },
            {
                onSuccess: () => {
                    navigate('base.offerlist-offer', {
                        id: offer.id,
                    });
                },
                onError: () => {
                    setIsOpen(false);
                    addErrorToast(translate('global.genericToastError'));
                },
            }
        );
    };

    return (
        <div>
            <Button emphasis="high" width="100%" onClick={() => setIsOpen(true)}>
                {translate('approvalRequest.buttons.orderNow')}
            </Button>

            <ConfirmOrderModal
                isOpen={isOpen}
                onDismiss={() => setIsOpen(false)}
                onConfirm={createOrder}
                isLoading={orderUpdateMutation.isPending}
            />
        </div>
    );
};
