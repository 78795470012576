import { useFormikContext } from 'formik';
import moment from 'moment';

import { PortMessage } from '../../../../../types/PortMessage';
import { ReadOnlyField } from '../../../../cdl/ReadOnlyField/ReadOnlyField';
import { formatDateTime } from '../../../../common/helpers/formatDateTime.helper';
import { formatPort } from '../../../../common/helpers/formatPort.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useRole } from '../../../../common/hooks/useRole';
import { Schedule, useSchedules } from '../../../../common/hooks/useSchedules';
import { FormikPortDetailSelect } from '../../../../port/portSelect/FormikPortDetailSelect';
import { FormikPortSelectWithSchedules } from '../../../../port/portSelect/FormikPortDetailSelectWithSchedule';
import { OfferModel } from '../../../model/OfferModel';

interface PortDisplayProps {
    offer: OfferModel;
}

export const PortDisplay = (props: PortDisplayProps) => {
    const { schedules: schedule } = useSchedules(props.offer.vessel.imo);
    const role = useRole();
    const { setFieldValue } = useFormikContext();

    const onPortSelect = (port: PortMessage, scheduleRank?: number) => {
        setFieldValue('agentId', undefined, false);
        setFieldValue('agentInformation', undefined, false);

        if (!schedule || !schedule.length) {
            return;
        }

        const matchingSchedule = schedule.find(
            (scheduleEntry: Schedule) => scheduleEntry.port?.id === port.id && scheduleEntry.rank === scheduleRank
        );

        if (!matchingSchedule) {
            return;
        }

        setFieldValue(
            'dateDelivery',
            // Set dateDelivery to the schedules current date, but at the start of the day in UTC.
            // With the format we remove the timezone of the schedule.
            moment
                .utc(
                    formatDateTime({
                        date: matchingSchedule.eta,
                        timeZoneId: matchingSchedule.timeZoneId,
                    })
                )
                .startOf('day')
                .toDate()
                .getTime(),
            false
        );
    };

    if (role.isCustomer()) {
        return (
            <FormikPortSelectWithSchedules
                name="portId"
                schedule={schedule}
                vesselId={props.offer.vesselId}
                onSelect={onPortSelect}
            />
        );
    }

    if (role.isSupplier() && props.offer.isOrder()) {
        return <FormikPortDetailSelect name="portId" />;
    }

    return <ReadOnlyField value={formatPort({ port: props.offer.port })} label={translate('offer.edit.lubes.port')} />;
};
