import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconHeadset = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12 5a7 7 0 0 0-7 7v.17A3 3 0 0 1 9 15v2a3 3 0 1 1-6 0v-5a9 9 0 1 1 18 0v5c0 1.363-.91 2.514-2.155 2.88-.338.932-1.19 1.653-2.155 2.136-1.296.648-2.984.984-4.69.984a1 1 0 1 1 0-2c1.476 0 2.84-.296 3.795-.773.383-.191.65-.383.832-.559A3 3 0 0 1 15 17v-2a3 3 0 0 1 4-2.83V12a7 7 0 0 0-7-7Zm7 10a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0v-2ZM5 15v2a1 1 0 1 0 2 0v-2a1 1 0 1 0-2 0Z"
            clipRule="evenodd"
        />
    </svg>
);
