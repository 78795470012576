import styled from 'styled-components';

import { IconCirclePlus } from '../../../../common/icons/cdl/CirclePlus';

interface RotatingIconCirclePlusProps {
    $isRotated: boolean;
}

export const RotatingIconCirclePlus = styled(IconCirclePlus).attrs({
    width: 16,
    height: 16,
})<RotatingIconCirclePlusProps>`
    transform: ${(props) => (props.$isRotated ? 'rotate(45deg)' : 'rotate(0deg)')};
    transition: transform 125ms;
`;
