export enum GlobalPricesPortColor {
    LEVEL_1 = '#DCF2FF',
    LEVEL_2 = '#6FB0FF',
    LEVEL_3 = '#0068FF',
    LEVEL_4 = '#0045AA',
    LEVEL_5 = '#002255',
}

export enum GlobalPricesPortColorLevel {
    LEVEL_1 = 'LEVEL_1',
    LEVEL_2 = 'LEVEL_2',
    LEVEL_3 = 'LEVEL_3',
    LEVEL_4 = 'LEVEL_4',
    LEVEL_5 = 'LEVEL_5',
}
