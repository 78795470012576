import { ProductContext } from '../../../types/ProductContext';
import { getFuelProductGroupReport, getProductGroupReport, GetReportParams } from '../../common/api/clients/report.api';

export const getStatisticsProductGroupReport = async (params: GetReportParams) => {
    switch (params.type) {
        case ProductContext.LUBES:
            return await getProductGroupReport(params);
        case ProductContext.FUEL:
            return await getFuelProductGroupReport(params);
    }
};
