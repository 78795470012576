import { useContextDatePickerOffsetPropGetters, useContextDays } from '@rehookify/datepicker';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';

import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';
import { CalendarRangePreset } from '../types/CalendarRangePreset';
import { RangeDirection } from '../types/RangeDirection';

import { CalendarPresetEntry } from './CalendarPresetEntry';

function getRelativeOffset(direction: RangeDirection, endDateOfRange: Date): Date {
    switch (direction) {
        case RangeDirection.Forward:
            return endDateOfRange;
        case RangeDirection.Backward:
            return moment(endDateOfRange).subtract('30', 'days').toDate();
    }
}

const todayFirstHour = moment().startOf('day').toDate();

interface CalendarPresetsProps {
    presets: CalendarRangePreset[];
    setInternalSelectedDates: Dispatch<SetStateAction<Date[]>>;
}

export const CalendarPresets = ({ presets, setInternalSelectedDates }: CalendarPresetsProps) => {
    const { selectedDates } = useContextDays();
    const { setOffset } = useContextDatePickerOffsetPropGetters();

    let isCustomRange = selectedDates.length >= 1;

    return (
        <Box marginTop={5}>
            {presets.map((preset) => {
                const isSelectedRange =
                    selectedDates[0]?.getTime() === preset.range[0].getTime() &&
                    selectedDates[1]?.getTime() === preset.range[1].getTime();

                if (isSelectedRange) {
                    isCustomRange = false;
                }

                return (
                    <CalendarPresetEntry
                        key={preset.label}
                        active={isSelectedRange}
                        onClick={(event) => {
                            setInternalSelectedDates(preset.range);
                            setOffset(getRelativeOffset(preset.direction, preset.range[1])).onClick?.(event);
                        }}
                    >
                        {preset.label}
                    </CalendarPresetEntry>
                );
            })}
            <CalendarPresetEntry
                active={isCustomRange}
                onClick={(event) => {
                    setInternalSelectedDates([]);
                    setOffset(todayFirstHour).onClick?.(event);
                }}
            >
                {translate('global.datePicker.presets.custom')}
            </CalendarPresetEntry>
        </Box>
    );
};
