import { useVesselCreateModal } from '../../../js/common/hooks/useVesselCreateModal.tsx';
import { Button } from '../../cdl/Button/Button';
import { translate } from '../../common/helpers/translate.helper';

export const CreateVesselButton = () => {
    const { open } = useVesselCreateModal();

    return (
        <Button emphasis="high" onClick={open}>
            {translate('vessel.create')}
        </Button>
    );
};
