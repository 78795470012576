import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export interface MagicBellUserConfigResponse {
    hmac: string;
}

export const getMagicBellUserConfig = async (): Promise<MagicBellUserConfigResponse> => {
    const response = await authorizedAxiosInstance.get<MagicBellUserConfigResponse>('/v1/magic-bell-user-config');

    return response.data;
};
