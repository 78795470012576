import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../../cdl/Toast/useToasts';
import { updateVesselTanks as updateVesselTanksApi } from '../../../../common/api/clients/vesselTank.api';
import { queryKeys } from '../../../../common/api/queryKeys';
import { queries } from '../../../../common/api/queryKeys/queries';
import { translate } from '../../../../common/helpers/translate.helper';

export const useVesselTanksMutation = (vesselId: string) => {
    const queryClient = useQueryClient();
    const { addToast, addErrorToast } = useToasts();

    return useMutation({
        mutationFn: updateVesselTanksApi,
        onSuccess: () => {
            void queryClient.invalidateQueries({ queryKey: queries.vessels.detail(vesselId)._ctx.tanks.queryKey });
            void queryClient.invalidateQueries({ queryKey: queryKeys.schedulesWithPrices(vesselId) });

            addToast(translate('vessel.editTanksPopup.successToast'));
        },
        onError: () => {
            addErrorToast(translate('vessel.editTanksPopup.errorToast'));
        },
    });
};
