import { Text } from '../../cdl/Text/Text';
import { OfferModel } from '../../offer/model/OfferModel';
import { DataSheet } from '../DataSheet/DataSheet';
import { formatAddress } from '../helpers/formatAddress.helper';
import { translate } from '../helpers/translate.helper';

import { CoreDataPopup } from './CoreDataPopup';

interface SupplierInfoPopupProps {
    offer: OfferModel;
}

export const SupplierInfoPopup = ({ offer }: SupplierInfoPopupProps) => {
    const createWording = (content: string | null) => {
        if (!offer.isEnquiry()) {
            return content;
        }

        return offer.isSpot()
            ? translate('offer.detail.coreData.supplierPopup.emailAddressNotAvailableSpot')
            : translate('offer.detail.coreData.supplierPopup.emailAddressNotAvailableContracted');
    };

    return (
        <CoreDataPopup heading={translate('offer.detail.coreData.supplierPopup.headline')}>
            <DataSheet>
                <DataSheet.Label>{translate('offer.detail.coreData.supplierPopup.name')}</DataSheet.Label>
                <Text>{offer.supplier.name}</Text>

                {offer.supplier.email ? (
                    <>
                        <DataSheet.Label>
                            {translate('offer.detail.coreData.supplierPopup.emailAddress')}
                        </DataSheet.Label>
                        <Text>{createWording(offer.supplier.email)}</Text>
                    </>
                ) : null}

                {offer.supplier.phone ? (
                    <>
                        <DataSheet.Label>
                            {translate('offer.detail.coreData.supplierPopup.phoneNumber')}
                        </DataSheet.Label>
                        <Text>{createWording(offer.supplier.phone)}</Text>
                    </>
                ) : null}

                {offer.supplier.address ? (
                    <>
                        <DataSheet.Label>{translate('offer.detail.coreData.supplierPopup.address')}</DataSheet.Label>
                        <Text>{createWording(formatAddress({ address: offer.supplier.address }))}</Text>
                    </>
                ) : null}
            </DataSheet>
        </CoreDataPopup>
    );
};
