import { useQuery } from '@tanstack/react-query';

import { getBunkerMetricBunkerCaseGeometry } from '../../../common/api/clients/bunkerMetric.api';
import { queryKeys } from '../../../common/api/queryKeys';

interface UseBunkerMetricBunkerCaseGeometryParams {
    vesselId: string;
    caseNumber: string;
    solutionNumber: string;
}

export const useBunkerMetricBunkerCaseGeometry = (params: UseBunkerMetricBunkerCaseGeometryParams) => {
    return useQuery({
        queryKey: queryKeys.bunkerMetricBunkerCaseGeometry(params),
        queryFn: () => getBunkerMetricBunkerCaseGeometry(params),
    });
};
