import { Text } from '../../../../cdl/Text/Text';
import { translate } from '../../../../common/helpers/translate.helper';
import { Box } from '../../../../common/ui/Box';
import { OfferModel } from '../../../../offer/model/OfferModel';

const getAssignedLabel = (offer: OfferModel): string | null => {
    switch (offer.enquiryType) {
        case 'ASSIGNED':
        case 'FORWARDED':
        case 'ANONYMOUS':
            return translate('fuelOrder.offerList.labels.listed');
        default:
            return null;
    }
};

const getForwardedLabel = (offer: OfferModel): string | null => {
    switch (offer.enquiryType) {
        case 'ANONYMOUS':
        case 'FORWARDED':
            return translate('fuelOrder.offerList.labels.forwarded');
        default: {
            return null;
        }
    }
};

const getDefaultSupplierLabel = (offer: OfferModel): string | null => {
    if (offer.supplierId !== offer.vessel?.defaultSupplierId) {
        return null;
    }

    return translate('fuelOrder.offerList.labels.default');
};

const getTypeLabel = (offer: OfferModel): string | null => {
    if (offer.isForwarded()) {
        return null;
    }

    switch (offer.supplier.supplierGroup.type) {
        case 'MANUFACTURER': {
            return translate('fuelOrder.offerList.labels.manufacturer');
        }
        case 'DISTRIBUTOR': {
            return translate('fuelOrder.offerList.labels.distributor');
        }
        case 'TRADER': {
            return translate('fuelOrder.offerList.labels.trader');
        }
        case 'BROKER': {
            return translate('fuelOrder.offerList.labels.broker');
        }
        default:
            return null;
    }
};

interface OfferLabelListProps {
    offer: OfferModel;
}

export const OfferLabelList = ({ offer }: OfferLabelListProps) => {
    const labels = [
        getTypeLabel(offer),
        getAssignedLabel(offer),
        getForwardedLabel(offer),
        getDefaultSupplierLabel(offer),
    ]
        .filter((label) => label !== null)
        .map((label) => (
            <Text key={label} variant="extraSmall" color="foreground.muted">
                {label}
            </Text>
        ));

    return (
        <Box display="flex" style={{ columnGap: '8px' }}>
            {labels}
        </Box>
    );
};
