import { Button } from '../../../../cdl/Button/Button';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconExternalLink } from '../../../../common/icons/cdl/ExternalLink';
import AppSettings from '../../../../constants';

interface CustomerHealthButtonProps {
    customerGroupId: string;
    customerGroupName: string;
}

export const CustomerHealthButton = (props: CustomerHealthButtonProps) => {
    const url = new URL(`${AppSettings.closelinkChartsUrl}/customer-health`);
    url.searchParams.append('customerGroupId', props.customerGroupId);
    url.searchParams.append('customerGroupName', props.customerGroupName);

    return (
        <>
            <Button as="a" href={url.toString()} leadingVisual={<IconExternalLink width={16} height={16} />}>
                {translate('customergroup.customerHealthButton')}
            </Button>
        </>
    );
};
