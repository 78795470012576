import { useMutation, useQueryClient } from '@tanstack/react-query';

import { uploadPriceList } from '../../../common/api/clients/product.api';
import { queries } from '../../../common/api/queryKeys/queries';

export const usePriceListUpload = (supplierGroupId: string, supplierId: string, customerId: string) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (file: File) => uploadPriceList({ file, supplierGroupId, supplierId, customerId }),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: queries.assignments._def }),
    });
};
