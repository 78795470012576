import { components, InputProps } from 'react-select';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const CustomInput = styled(components.Input)<InputProps>`
    && {
        font-size: 16px;
        height: 24px;
        font-weight: ${get('fontWeights.medium')};
        margin: 0;
        padding: 0;
    }
`;
