import moment from 'moment';
import PropTypes from 'prop-types';

import { formatDateTime } from '../helpers/formatDateTime.helper';
import { translate } from '../helpers/translate.helper';

export const TableValidity = ({ validUntil }) => {
    if (!validUntil) {
        return '-';
    }

    const validUntilDate = moment(validUntil);

    if (validUntilDate.isBefore(moment())) {
        return translate('order.validityExpired');
    }

    if (validUntilDate.isAfter(moment().add(1, 'days'))) {
        return formatDateTime({
            date: validUntilDate,
            format: 'MMM D, h:mm a',
        });
    }

    return formatDateTime({
        date: validUntilDate,
        format: 'h:mm a',
    });
};

TableValidity.propTypes = {
    validUntil: PropTypes.string,
};
