import styled from 'styled-components';

import { get } from '../../ui/get';

export const AttachmentGrid = styled.div`
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    grid-template-columns: 24px 1fr min-content;
    width: 100%;
    column-gap: ${get('space.4')}px;
    margin-bottom: ${get('space.5')}px;

    &:last-child {
        margin: 0;
    }
`;
