import { Formik } from 'formik';

import { AssignmentMessage } from '../../../../types/AssignmentMessage';
import { PaymentTermReference } from '../../../../types/PaymentTermReference';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../../common/helpers/translate.helper';
import { useAssignmentUpdate } from '../hooks/useAssignmentUpdate';

import { AssignmentBaseForm, FormikAssignmentState } from './AssignmentBaseForm';
import { validateAssignment } from './validateAssignment';

interface EditAssignmentDrawerProps {
    assignment: AssignmentMessage;
    onSuccess: () => void;
}

export const EditAssignmentDrawer = ({ assignment, onSuccess }: EditAssignmentDrawerProps) => {
    const { addErrorToast } = useToasts();
    const updateAssignmentMutation = useAssignmentUpdate(assignment.id);

    const initialValues: FormikAssignmentState = {
        customerId: assignment.customerId,
        supplierId: assignment.supplierId,
        portLimitations: assignment.portLimitations?.length ? assignment.portLimitations : [],
        defaultPaymentTermReference: assignment.defaultPaymentTermReference || undefined,
        defaultPaymentTermReferenceDays: assignment.defaultPaymentTermReferenceDays?.toString() || '',
    };

    const handleSubmit = (values: FormikAssignmentState) => {
        const requestMessage: AssignmentMessage = {
            ...assignment,
            customerId: values.customerId,
            supplierId: values.supplierId,
            portLimitations: values.portLimitations,
            defaultPaymentTermReference: values.defaultPaymentTermReference || undefined,
            defaultPaymentTermReferenceDays:
                values.defaultPaymentTermReference &&
                values.defaultPaymentTermReference !== PaymentTermReference.PREPAYMENT
                    ? Number(values.defaultPaymentTermReferenceDays)
                    : undefined,
        };

        updateAssignmentMutation.mutate(requestMessage, {
            onSuccess: () => {
                onSuccess();
            },
            onError: () => {
                addErrorToast(translate('assignments.toasts.updateError'));
            },
        });
    };

    return (
        <Formik validate={validateAssignment} initialValues={initialValues} onSubmit={handleSubmit}>
            <AssignmentBaseForm title={translate('assignments.editTitle')} />
        </Formik>
    );
};
