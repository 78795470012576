import { AxiosError } from 'axios';
import { FormikHelpers } from 'formik';

import { UserGatewayMessage } from '../../../types/UserGatewayMessage';
import { UserMessage } from '../../../types/UserMessage';
import { UserUpdateMessage } from '../../../types/UserUpdateMessage';
import { useToasts } from '../../cdl/Toast/useToasts';
import { UpdateManagementUserRequestErrorResponse } from '../../common/api/clients/user.api';
import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';
import { useUserUpdate } from '../detail/hooks/useUserUpdate';
import { useUserManagementUpdate } from '../list/hooks/useUserManagementUpdate';

import { EditUserForm, FormikEditUserState } from './EditUserForm';

interface EditUserProps {
    user: UserMessage | UserGatewayMessage;
    onSuccess: () => void;
}

export const EditUser = ({ user, onSuccess }: EditUserProps) => {
    const userManagementUpdateMutation = useUserManagementUpdate();
    const userUpdateMutation = useUserUpdate(user.id);
    const role = useRole();

    const { addToast, addErrorToast } = useToasts();

    const onEditUserSubmit = async (userMessage: UserUpdateMessage, meta: FormikHelpers<FormikEditUserState>) => {
        try {
            if (role.hasAnyAdminRights() && role.user.id !== user.id) {
                await userManagementUpdateMutation.mutateAsync(
                    { userId: user.id, userMessage },
                    {
                        onSuccess: () => {
                            onSuccess();
                            addToast(translate('user.editUserToasts.success'));
                        },
                    }
                );
            } else {
                await userUpdateMutation.mutateAsync(userMessage, {
                    onSuccess: () => {
                        onSuccess();
                        addToast(translate('user.editUserToasts.success'));
                    },
                });
            }
        } catch (error) {
            const axiosError = error as AxiosError<UpdateManagementUserRequestErrorResponse>;

            if (axiosError.response?.status !== 400) {
                addErrorToast(translate('user.editUserToasts.error'));
                meta.setSubmitting(false);
            } else {
                switch (axiosError.response.data.errorCode) {
                    case 'MAIL_NOT_UNIQUE':
                        meta.setErrors({
                            emailAddress: translate('user.error.emailNotUnique'),
                        });
                        meta.setSubmitting(false);
                        break;
                    case 'INVALID_EMAIL':
                        meta.setErrors({
                            emailAddress: translate('user.error.emailInvalid'),
                        });
                        meta.setSubmitting(false);
                        break;
                }
            }
        }
    };

    return <EditUserForm onSubmit={onEditUserSubmit} user={user} />;
};
