import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const createKeyPort = async (keyPort: unknown) => {
    const response = await authorizedAxiosInstance.post('/v1/key-ports', keyPort);

    return response.data;
};

interface UpdateKeyPortParams {
    keyPortId: string;
    keyPort: unknown;
}
export const updateKeyPort = async ({ keyPort, keyPortId }: UpdateKeyPortParams) => {
    const response = await authorizedAxiosInstance.put(`/v1/key-ports/${keyPortId}`, keyPort);

    return response.data;
};
export const deleteKeyPort = async (keyPortId: string) => {
    const response = await authorizedAxiosInstance.delete(`/v1/key-ports/${keyPortId}`);

    return response.data;
};
export const getKeyPortList = async (context: string) => {
    const response = await authorizedAxiosInstance.get(`/v1/key-ports/list?context=${context}`);

    return response.data;
};
