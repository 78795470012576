import Skeleton from 'react-loading-skeleton';

import { SupplierMessage } from '../../../../types/SupplierMessage';
import { Error } from '../../../common/Error/Error';
import { translate } from '../../../common/helpers/translate.helper';
import { Section } from '../../../common/Section/Section';
import { SettingsSection } from '../../SettingsSection';

import { SupplierMessageSettings } from './SupplierMessageSettings';
import { SupplierNotificationSettings } from './SupplierNotificationSettings';
import { useSupplierSettings } from './useSupplierSettings';

interface SupplierSettingsProps {
    supplier: SupplierMessage;
}

export const SupplierSettings = (props: SupplierSettingsProps) => {
    const supplierSettingsQuery = useSupplierSettings(props.supplier.id);

    if (supplierSettingsQuery.isPending) {
        return (
            <Section title={translate('customers.details.settings')}>
                <SettingsSection label={<Skeleton width="120px" height="16px" />}>
                    <Skeleton width="80%" height="16px" />
                    <Skeleton width="80%" height="16px" />
                    <Skeleton width="80%" height="16px" />
                    <Skeleton width="80%" height="16px" />
                </SettingsSection>
                <SettingsSection label={<Skeleton width="120px" height="16px" />}>
                    <Skeleton width="80%" height="16px" />
                    <Skeleton width="80%" height="16px" />
                </SettingsSection>
            </Section>
        );
    }

    if (supplierSettingsQuery.isError) {
        return (
            <Section title={translate('customers.details.settings')}>
                <Error />
            </Section>
        );
    }

    const supplierSettings = supplierSettingsQuery.data;

    return (
        <Section title={translate('customers.details.settings')}>
            <SupplierMessageSettings supplierSettings={supplierSettings} supplierId={props.supplier.id} />
            <SupplierNotificationSettings supplierSettings={supplierSettings} supplierId={props.supplier.id} />
        </Section>
    );
};
