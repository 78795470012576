import PropTypes from 'prop-types';

import { FormattedNumberInput } from './FormattedNumberInput';

export const FuelMoneyInput = ({ highlight, error, required, onChange, allowNegativeValue = false, ...input }) => {
    return (
        <FormattedNumberInput
            onValueChange={onChange}
            required={required}
            prefix="$"
            step={1}
            maxLength={10}
            decimalScale={2}
            decimalsLimit={2}
            allowNegativeValue={allowNegativeValue}
            highlight={highlight}
            error={error}
            {...input}
        />
    );
};

FuelMoneyInput.propTypes = {
    label: PropTypes.node,
    annotation: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    allowNegativeValue: PropTypes.bool,
    highlight: PropTypes.bool,
    error: PropTypes.bool,
};
