import { FormikErrors, setIn } from 'formik';

import { translate } from '../../../../../common/helpers/translate.helper';
import { FormikApprovalSettingsState } from '../MultipleApproversSettingsForm';

export function validateEmailAddresses(
    errors: FormikErrors<FormikApprovalSettingsState>,
    values: FormikApprovalSettingsState
): FormikErrors<FormikApprovalSettingsState> {
    values.additionalApprovers.forEach((approvalLimit, approvalLimitIndex) => {
        approvalLimit.approvers.forEach((approver, index) => {
            if (approver.emailAddress === '') {
                return;
            }

            // https://stackoverflow.com/a/48800/3276759
            const emailAddressRegex = new RegExp('^\\S+@\\S+$');

            if (!emailAddressRegex.test(approver.emailAddress)) {
                const currentPath = `additionalApprovers.${approvalLimitIndex}.approvers.${index}.emailAddress`;
                errors = setIn(errors, currentPath, translate('multipleApproverSettings.errors.invalidEmailAddress'));
            }
        });
    });

    return errors;
}
