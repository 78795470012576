import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { AcceptTerms as OriginalAcceptTerms } from './AcceptTerms';

export const AcceptTerms = (props: any) => {
    return (
        <AppProviders>
            <OriginalAcceptTerms {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(AcceptTerms, 'reactAcceptTerms');
