import { AxiosPromise } from 'axios';
import FileDownload from 'js-file-download';

import { extractFileName } from '../../../../common/helpers/extractFileName.helper';
import { translate } from '../../../../common/helpers/translate.helper';

import { useExportOrderMutation } from './useExportOrderMutation';

export const useOrderExport = (): { downloadPdf: (orderId: string) => AxiosPromise<Blob> } => {
    const exportPdfMutation = useExportOrderMutation();

    const downloadPdf = async (orderId: string) => {
        return exportPdfMutation.mutateAsync(
            {
                id: orderId,
            },
            {
                onSuccess: (response) => {
                    FileDownload(
                        response.data,
                        extractFileName(
                            response.headers['content-disposition'] || '',
                            translate('attachments.fileNameFallback')
                        ),
                        'application/pdf'
                    );
                },
            }
        );
    };

    return {
        downloadPdf,
    };
};
