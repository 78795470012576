import { useFormikContext } from 'formik';

import { NotificationSettings } from '../../../../../types/VesselMessage';
import { FormikCheckbox } from '../../../../cdl/Checkbox/FormikCheckbox';
import { Text } from '../../../../cdl/Text/Text';
import { isValidEmailAddress } from '../../../../common/helpers/isValidEmailAddress.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { FormikTagListInput } from '../../../../common/TagListInput/FormikTagListInput';
import { Box } from '../../../../common/ui/Box';
import { FormikVesselDetailsState } from '../EditVesselForm';

export const FormikVesselLubesNotificationSettingsSection = () => {
    const { values, setFieldValue } = useFormikContext<FormikVesselDetailsState>();

    const customPadding = '26px'; // To align with the description of the checkbox
    const gap = 3;

    const handleSettingsToggle = (
        settings: keyof FormikVesselDetailsState['mailNotificationSettings'],
        checked: boolean
    ) => {
        if (checked) {
            if (!values.mailNotificationSettings[settings].sendToVessel) {
                setFieldValue(`mailNotificationSettings.${settings}.sendToVessel`, true, false);
            }

            if (!values.mailNotificationSettings[settings].sendToOthers) {
                setFieldValue(`mailNotificationSettings.${settings}.sendToOthers`, true, false);
            }
        }
    };

    const handleSubSettingsToggle = (
        settings: keyof FormikVesselDetailsState['mailNotificationSettings'],
        subSettings: keyof NotificationSettings,
        checked: boolean
    ) => {
        if (!checked) {
            if (subSettings === 'sendToVessel' && !values.mailNotificationSettings[settings].sendToOthers) {
                setFieldValue(`mailNotificationSettings.${settings}.enabled`, false, false);
            }
            if (subSettings === 'sendToOthers' && !values.mailNotificationSettings[settings].sendToVessel) {
                setFieldValue(`mailNotificationSettings.${settings}.enabled`, false, false);
            }
        }
    };

    const validateEmailAddressInput = (emailAddress: string) => {
        if (isValidEmailAddress(emailAddress)) {
            return null;
        }
        return translate('vessel.edit.validEmailAddress');
    };

    return (
        <Box display="grid" gap={6}>
            <Box display="grid" gap={gap}>
                <Text variant="subtitle1">{translate('vessel.edit.emailNotifications')}</Text>
                <Text variant="small" color="foreground.muted">
                    {translate('vessel.edit.emailNotificationsText')}
                </Text>
            </Box>

            <Box display="grid" gap={gap}>
                <FormikCheckbox
                    name="mailNotificationSettings.orderUpdateSettings.enabled"
                    label={translate('vessel.edit.orderUpdates')}
                    description={translate('vessel.edit.orderUpdatesText')}
                />
                {values.mailNotificationSettings.orderUpdateSettings.enabled ? (
                    <Box paddingLeft={customPadding}>
                        <FormikTagListInput
                            name="mailNotificationSettings.orderUpdateSettings.recipients"
                            label={translate('vessel.edit.recipients')}
                            validate={validateEmailAddressInput}
                        />
                    </Box>
                ) : null}
            </Box>

            <Box display="grid" gap={gap}>
                <FormikCheckbox
                    name="mailNotificationSettings.stockWarningSettings.enabled"
                    label={translate('vessel.edit.stockWarningLevel')}
                    description={translate('vessel.edit.stockWarningLevelText')}
                    onChange={(checked) => handleSettingsToggle('stockWarningSettings', checked)}
                />
                {values.mailNotificationSettings.stockWarningSettings.enabled ? (
                    <Box paddingLeft={customPadding} display="grid" gap={gap}>
                        <FormikCheckbox
                            name="mailNotificationSettings.stockWarningSettings.sendToVessel"
                            label={translate('vessel.edit.sendToVessel')}
                            onChange={(checked) =>
                                handleSubSettingsToggle('stockWarningSettings', 'sendToVessel', checked)
                            }
                        />
                        <FormikCheckbox
                            name="mailNotificationSettings.stockWarningSettings.sendToOthers"
                            label={translate('vessel.edit.sendToOthers')}
                            onChange={(checked) =>
                                handleSubSettingsToggle('stockWarningSettings', 'sendToOthers', checked)
                            }
                        />
                        {values.mailNotificationSettings.stockWarningSettings.sendToOthers ? (
                            <FormikTagListInput
                                name="mailNotificationSettings.stockWarningSettings.recipients"
                                label={translate('vessel.edit.recipients')}
                                validate={validateEmailAddressInput}
                            />
                        ) : null}
                    </Box>
                ) : null}
            </Box>

            <Box display="grid" gap={gap}>
                <FormikCheckbox
                    name="mailNotificationSettings.safetyReserveSettings.enabled"
                    label={translate('vessel.edit.safetyReserve')}
                    description={translate('vessel.edit.safetyReserveText')}
                    onChange={(checked) => handleSettingsToggle('safetyReserveSettings', checked)}
                />
                {values.mailNotificationSettings.safetyReserveSettings.enabled ? (
                    <Box paddingLeft={customPadding} display="grid" gap={gap}>
                        <FormikCheckbox
                            name="mailNotificationSettings.safetyReserveSettings.sendToVessel"
                            label={translate('vessel.edit.sendToVessel')}
                            onChange={(checked) =>
                                handleSubSettingsToggle('safetyReserveSettings', 'sendToVessel', checked)
                            }
                        />
                        <FormikCheckbox
                            name="mailNotificationSettings.safetyReserveSettings.sendToOthers"
                            label={translate('vessel.edit.sendToOthers')}
                            onChange={(checked) =>
                                handleSubSettingsToggle('safetyReserveSettings', 'sendToOthers', checked)
                            }
                        />
                        {values.mailNotificationSettings.safetyReserveSettings.sendToOthers ? (
                            <FormikTagListInput
                                name="mailNotificationSettings.safetyReserveSettings.recipients"
                                label={translate('vessel.edit.recipients')}
                                validate={validateEmailAddressInput}
                            />
                        ) : null}
                    </Box>
                ) : null}
            </Box>

            <Box display="grid" gap={gap}>
                <FormikCheckbox
                    name="mailNotificationSettings.keyPortCallSettings.enabled"
                    label={translate('vessel.edit.keyPortCall')}
                    description={translate('vessel.edit.keyPortCallText')}
                    onChange={(checked) => handleSettingsToggle('keyPortCallSettings', checked)}
                />
                {values.mailNotificationSettings.keyPortCallSettings.enabled ? (
                    <Box paddingLeft={customPadding} display="grid" gap={gap}>
                        <FormikCheckbox
                            name="mailNotificationSettings.keyPortCallSettings.sendToVessel"
                            label={translate('vessel.edit.sendToVessel')}
                            onChange={(checked) =>
                                handleSubSettingsToggle('keyPortCallSettings', 'sendToVessel', checked)
                            }
                        />
                        <FormikCheckbox
                            name="mailNotificationSettings.keyPortCallSettings.sendToOthers"
                            label={translate('vessel.edit.sendToOthers')}
                            onChange={(checked) =>
                                handleSubSettingsToggle('keyPortCallSettings', 'sendToOthers', checked)
                            }
                        />
                        {values.mailNotificationSettings.keyPortCallSettings.sendToOthers ? (
                            <FormikTagListInput
                                name="mailNotificationSettings.keyPortCallSettings.recipients"
                                label={translate('vessel.edit.recipients')}
                                validate={validateEmailAddressInput}
                            />
                        ) : null}
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
};
