import PropTypes from 'prop-types';

import { Button } from '../../cdl/Button/Button';
import { useDrawer } from '../../cdl/Drawer/useDrawer.hook';
import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';
import { Section } from '../../common/Section/Section.tsx';
import { Box } from '../../common/ui/Box';

import { EditVesselForm } from './EditVessel/EditVesselForm';
import { LubesVesselDetailDataSheet } from './LubesVesselDetailDataSheet';
import { LiteRemainingOnBoard } from './RemainingOnBoard/LiteRemainingOnBoard';
import { RemainingOnBoard } from './RemainingOnBoard/RemainingOnBoard';
import { FileMode, useVesselMutation } from './useVesselMutation';

export const LubesInfoSection = ({ vessel }) => {
    const { pop, push } = useDrawer();
    const role = useRole();

    const { mutate: mutateVessel } = useVesselMutation(vessel.id);

    const parseEditClose = (editedVessel) => {
        if (editedVessel.lubricationChart?.added) {
            mutateVessel({
                vesselId: vessel.id,
                vessel: editedVessel,
                lubricationChart: editedVessel.lubricationChart.file,
                fileMode: FileMode.ADDED,
            });
        } else if (editedVessel.lubricationChart?.removed) {
            mutateVessel({
                vesselId: vessel.id,
                vessel: editedVessel,
                fileMode: FileMode.REMOVED,
            });
        } else {
            mutateVessel({
                vesselId: vessel.id,
                vessel: editedVessel,
            });
        }

        pop();
    };

    const onEditClick = () => {
        push({
            content: <EditVesselForm onSubmit={parseEditClose} vessel={vessel} />,
        });
    };

    return (
        <Box display="flex" gap={9}>
            <Box width={['100%', 1 / 2]}>
                <Section
                    title={translate('vessel.info')}
                    actions={
                        role.hasWriteRights(vessel.customerId) ? (
                            <Button onClick={onEditClick}>{translate('vessel.editInfo')}</Button>
                        ) : null
                    }
                >
                    <LubesVesselDetailDataSheet vessel={vessel} />
                </Section>
            </Box>

            <Box width={['100%', 1 / 2]}>
                {role.usesCloselinkLite() ? (
                    <LiteRemainingOnBoard vessel={vessel} />
                ) : (
                    <RemainingOnBoard vessel={vessel} />
                )}
            </Box>
        </Box>
    );
};

LubesInfoSection.propTypes = {
    vessel: PropTypes.object,
};
