import { useQuery } from '@tanstack/react-query';

import { getOffersTotal } from '../../common/api/clients/gateway.api';
import { queryKeys } from '../../common/api/queryKeys';

export const useOfferPaginationTotals = ({
    searchQuery,
    supplierIds,
    customerIds,
    type,
    offerTabs,
    tapToOfferStateMap,
}) => {
    const fetchTotals = ({ searchQuery, supplierIds, customerIds, type }) => {
        return Promise.all(
            offerTabs.map((tab) =>
                getOffersTotal({
                    searchQuery,
                    supplierIds,
                    customerIds,
                    types: [type],
                    states: tapToOfferStateMap[tab],
                })
            )
        )
            .then((values) => values.map((value) => value.count))
            .then((offersTotals) => {
                return offerTabs.reduce((acc, value, index) => {
                    acc[value] = offersTotals[index];
                    return acc;
                }, {});
            });
    };

    return useQuery({
        queryKey: queryKeys.offersTotals({
            searchQuery,
            supplierIds,
            customerIds,
            type,
        }),
        queryFn: () =>
            fetchTotals({
                searchQuery,
                supplierIds,
                customerIds,
                type,
            }),
        refetchOnWindowFocus: false,
    });
};
