import { ReachInformationMessage } from '../../../../types/ReachInformationMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export async function getReachInformation(vesselId: string): Promise<ReachInformationMessage> {
    const params = new URLSearchParams();
    params.append('vesselId', vesselId);

    const response = await authorizedAxiosInstance.get<ReachInformationMessage>(
        `/v1/reach-information?${params.toString()}`
    );

    return response.data;
}

export async function getReachInformationForScheduleId(
    vesselId: string,
    scheduleId: string
): Promise<ReachInformationMessage> {
    const params = new URLSearchParams();
    params.append('vesselId', vesselId);
    params.append('scheduleId', scheduleId);

    const response = await authorizedAxiosInstance.get<ReachInformationMessage>(
        `/v1/reach-information?${params.toString()}`
    );

    return response.data;
}
