import { EmptyPagePlaceholder } from '../../EmptyPagePlaceholder/EmptyPagePlaceholder';
import { translate } from '../../helpers/translate.helper';
import { IconSearch } from '../../icons/cdl/Search';
import { Flex } from '../../ui/Flex';
import { theme } from '../../ui/theme';

interface NoSearchResultsProps {
    variant?: 'default' | 'page';
}

export const NoSearchResults = ({ variant = 'default' }: NoSearchResultsProps) => {
    return (
        <Flex justifyContent="center" paddingTop={variant === 'page' ? '124px' : undefined}>
            <EmptyPagePlaceholder
                title={translate('global.emptySearchResult.noSearchResults')}
                description={translate('global.emptySearchResult.tryDifferentQueryr')}
                icon={<IconSearch height={24} width={24} color={theme.colors.foreground.subtle} />}
            />
        </Flex>
    );
};
