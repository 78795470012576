import { useState } from 'react';

import { Collapsable } from '../../../../cdl/Collapsable/Collapsable';
import { ItemModel } from '../../../../common/models/ItemModel';
import { SamplekitModel } from '../../../../common/models/SamplekitModel';

import { EnquiredProductsAccordionHeader } from './EnquiredProductsAccordionHeader';
import { EnquiredProductsTable } from './EnquiredProductsTable';

interface OrderedProductsProps {
    items: ItemModel[];
    sampleKits: SamplekitModel[];
}

export const EnquiredProductsAccordion = ({ items, sampleKits }: OrderedProductsProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Collapsable
            isOpen={isOpen}
            header={<EnquiredProductsAccordionHeader isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />}
        >
            <EnquiredProductsTable items={items} sampleKits={sampleKits} />
        </Collapsable>
    );
};
