import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateOffer } from '../../../../common/api/clients/order.api';
import { queryKeys } from '../../../../common/api/queryKeys';

export const useOfferUpdate = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateOffer,
        onSuccess: (data) => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.offer(data.id) });
        },
    });
};
