import { ProductGroupMessage } from '../../../../types/ContractMessage';
import { FuelPricingCodeFrequency } from '../../../../types/FuelPricingCodeFrequency';
import { formatFuelMoney } from '../../../common/helpers/formatFuelMoney.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { theme } from '../../../common/ui/theme';
import { hasValidPricingCode } from '../utils/hasValidPricingCode';

import { PricingCodeWrapper } from './PricingCodeWrapper';

interface PricingCodeDisplayProps {
    productGroup: ProductGroupMessage;
    pricingCodeFrequencies: FuelPricingCodeFrequency[];
}

export const PricingCodeDisplay = ({ productGroup, pricingCodeFrequencies }: PricingCodeDisplayProps) => {
    const isInvalidProductGroup = productGroup.products
        .map((product) => hasValidPricingCode(pricingCodeFrequencies, product.pricingCode))
        .includes(false);

    const textColor = isInvalidProductGroup ? theme.colors.negative.foreground : 'inherit';
    const productsWithPricingCodes = productGroup.products.filter((product) => product.pricingCode);

    if (productsWithPricingCodes.length <= 0) {
        return '-';
    }

    if (productsWithPricingCodes.length > 1) {
        return (
            <PricingCodeWrapper isInvalid={isInvalidProductGroup} textColor={textColor}>
                {translate('contracts.product.multiple')}
            </PricingCodeWrapper>
        );
    }
    const code = productGroup.products[0].pricingCode?.code;
    const difference = productGroup.products[0].priceDifference?.value;

    if (!code) {
        return (
            <PricingCodeWrapper isInvalid={isInvalidProductGroup} textColor={textColor}>
                -
            </PricingCodeWrapper>
        );
    }

    if (!difference) {
        return (
            <PricingCodeWrapper isInvalid={isInvalidProductGroup} textColor={textColor}>
                {code}
            </PricingCodeWrapper>
        );
    }

    const differenceNumber = Number(difference);
    const sign = differenceNumber < 0 ? '' : '+';

    return (
        <PricingCodeWrapper isInvalid={isInvalidProductGroup} textColor={textColor}>{`${code} ${sign}${formatFuelMoney({
            value: differenceNumber,
            currency: 'USD',
        })}`}</PricingCodeWrapper>
    );
};
