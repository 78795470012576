import { Button } from '../../cdl/Button/Button';
import { useDrawer } from '../../cdl/Drawer/useDrawer.hook';
import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { LocationStateProvider } from '../../common/LocationState/LocationStateProvider';
import { Page } from '../../common/Page/Page';
import { Box } from '../../common/ui/Box';
import { DownloadLubesReportButton } from '../../group/customer/detail/components/DownloadLubesReportButton';

import { CreateCustomerGroup } from './components/CreateCustomerGroup';
import { CustomerGroupSearch } from './components/CustomerGroupSearch';
import { CustomerGroupsTable } from './components/CustomerGroupsTable';

export interface CustomerGroupsOverviewStateConfig {
    page: number;
    searchQuery: string;
}

export const CustomerGroupsOverviewPage = () => {
    useDocumentTitle(translate('page.customergroupoverview'));
    const { push, pop } = useDrawer();

    const stateConfig: CustomerGroupsOverviewStateConfig = {
        page: 0,
        searchQuery: '',
    };

    return (
        <LocationStateProvider stateConfig={stateConfig}>
            <Page>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Text variant="headline" as="h1">
                        {translate('customergroups.headline')}
                    </Text>
                    <Box display="flex" columnGap={5} alignItems="center">
                        <CustomerGroupSearch />
                        <DownloadLubesReportButton />
                        <Button
                            emphasis="high"
                            onClick={() => push({ content: <CreateCustomerGroup onSuccess={pop} /> })}
                        >
                            {translate('customergroups.create')}
                        </Button>
                    </Box>
                </Box>

                <CustomerGroupsTable />
            </Page>
        </LocationStateProvider>
    );
};
