import styled from 'styled-components';

import { FileManagementResponse } from '../../../../types/FileManagementResponse';
import { Button } from '../../../cdl/Button/Button';
import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../helpers/translate.helper';
import { IconFileUpload } from '../../icons/cdl/FileUpload';
import { Box } from '../../ui/Box';
import { Flex } from '../../ui/Flex';
import { FileData } from '../hooks/useUploadAreaReducer';
import { useUploadDropzone } from '../hooks/useUploadDropzone';

import { DropZone } from './DropZone';
import { UploadRow } from './UploadRow';

const CenteredRowFlex = styled(Flex)`
    flex-direction: row;
    align-items: center;
`;

interface UploadAreaProps {
    onFilesUpload: (files: File[]) => void;
    uploadedFiles: FileData[];
    onUploadSuccess: (uploadResponse: FileManagementResponse, fileId: string) => void;
    onDeleteSuccess: (fileId: string) => void;
}

export const UploadArea = ({ onFilesUpload, uploadedFiles, onUploadSuccess, onDeleteSuccess }: UploadAreaProps) => {
    const uploadDropzoneProps = useUploadDropzone(onFilesUpload);

    return (
        <DropZone
            {...uploadDropzoneProps.getRootProps({
                isDragAccept: uploadDropzoneProps.isDragAccept,
                isDragReject: uploadDropzoneProps.isDragReject,
                isDragActive: uploadDropzoneProps.isDragActive,
            })}
        >
            <input {...uploadDropzoneProps.getInputProps()} />

            <CenteredRowFlex>
                <Button type="button" onClick={uploadDropzoneProps.open}>
                    {translate('attachments.uploadButton')}
                </Button>
                <CenteredRowFlex marginLeft={5}>
                    <IconFileUpload />
                    <Text weight="medium" size={12} marginLeft={3}>
                        {translate('attachments.dropFiles')}
                    </Text>
                </CenteredRowFlex>
            </CenteredRowFlex>

            {uploadedFiles.length ? (
                <Box marginTop={6}>
                    {uploadedFiles.map((fileData) => {
                        return (
                            <UploadRow
                                key={fileData.id}
                                fileData={fileData}
                                onUploadSuccess={(uploadResponse) => {
                                    onUploadSuccess(uploadResponse, fileData.id);
                                }}
                                onDeleteSuccess={() => onDeleteSuccess(fileData.id)}
                            />
                        );
                    })}
                </Box>
            ) : null}
        </DropZone>
    );
};
