import { translate } from '../../../../common/helpers/translate.helper';
import { FilterMultiSelectShortProps } from '../FilterMultiSelectShort';

export function getFilterValueLabel<T extends string>(
    options: FilterMultiSelectShortProps<T>['options'],
    values: FilterMultiSelectShortProps<T>['values']
): string | undefined {
    if (!values || values.length === 0) {
        return undefined;
    }

    const labels = values.map((value) => options.find((option) => option.value === value)?.label);

    if (labels.length < 4) {
        return labels.join(', ');
    }

    const translatedMoreItems = translate('global.filterMultiSelect.xMoreItems', {
        count: (labels.length - 1).toString(),
    });

    return `${labels[labels.length - 1]} ${translatedMoreItems}`;
}
