import { useEffect } from 'react';

/**
 * ref: Can be an array of refs, so that multiple containers can be excluded
 */
export const useOutsideClick = (ref, callback) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            const aRef = Array.isArray(ref) ? ref : [ref];
            if (aRef.every((r) => r.current && !r.current.contains(event.target))) {
                callback(event);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
};
