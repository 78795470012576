import useComponentSize from '@rehooks/component-size';
import { Form, Formik, FormikProps } from 'formik';
import { useRef } from 'react';

import { FuelVesselConsumptionPreset } from '../../../../../types/FuelVesselConsumptionPreset';
import { Text } from '../../../../cdl/Text/Text';
import { Button } from '../../../../common/buttons/Button';
import { FormikDebug } from '../../../../common/form-elements/formik/FormikDebug';
import { translate } from '../../../../common/helpers/translate.helper';
import { ButtonSpinner } from '../../../../common/Spinner/ButtonSpinner';
import { Box } from '../../../../common/ui/Box';
import { theme } from '../../../../common/ui/theme';

import { FormikVesselConsumptionPresetCategories } from './FormikVesselConsumptionPresetCategories';

export interface FormikVesselConsumptionPresetsState {
    presets: {
        sulphurContent: string;
        productGroup: string;
        averageDailyConsumption: string;
        safetyReserve: string;
        maximumIntake: string;
    }[];
}

interface EditVesselConsumptionPresetsFormProps {
    onSubmit: (data: { presets: FuelVesselConsumptionPreset[] }) => void;
    data: { presets: FuelVesselConsumptionPreset[] };
}

export const EditVesselConsumptionPresetsForm = ({ onSubmit, data }: EditVesselConsumptionPresetsFormProps) => {
    const formContentRef = useRef<HTMLDivElement>(null);
    const formBodyRef = useRef<HTMLDivElement>(null);
    const contentSize = useComponentSize(formContentRef);
    const bodySize = useComponentSize(formBodyRef);

    const isContentBigger = contentSize.height > 0.8 * bodySize.height;

    const initialFormikState = {
        presets: data.presets.length
            ? data.presets.map((preset) => {
                  return {
                      sulphurContent: preset.sulphurContent,
                      productGroup: preset.productGroup,
                      averageDailyConsumption: preset.averageDailyConsumption?.toString() || '',
                      safetyReserve: preset.safetyReserve?.toString() || '',
                      maximumIntake: preset.maximumIntake?.toString() || '',
                  };
              })
            : [
                  {
                      sulphurContent: '',
                      productGroup: '',
                      averageDailyConsumption: '',
                      safetyReserve: '',
                      maximumIntake: '',
                  },
              ],
    };

    const handleSubmit = (values: FormikVesselConsumptionPresetsState) => {
        const newPresets: FuelVesselConsumptionPreset[] = values.presets
            .filter((preset) => !!preset.productGroup)
            .map((preset) => {
                return {
                    sulphurContent: preset.sulphurContent,
                    productGroup: preset.productGroup,
                    averageDailyConsumption: Number(preset.averageDailyConsumption) || undefined,
                    safetyReserve: Number(preset.safetyReserve) || undefined,
                    maximumIntake: Number(preset.maximumIntake) || undefined,
                };
            });
        onSubmit({ presets: newPresets });
    };

    return (
        <Formik initialValues={initialFormikState} onSubmit={handleSubmit}>
            {(props: FormikProps<FormikVesselConsumptionPresetsState>) => (
                <Form style={{ height: '100%' }}>
                    <Box display="grid" gridTemplateRows="auto 1fr auto" height="100%">
                        <Box padding={7} paddingBottom={6}>
                            <Text as="h1" margin="0" variant="title">
                                {translate('vessel.fuelStocks.editPresetsForm.title')}
                            </Text>
                        </Box>

                        <Box
                            ref={formBodyRef}
                            overflow="auto"
                            paddingX={7}
                            paddingBottom={isContentBigger ? '220px' : undefined}
                        >
                            <Box ref={formContentRef}>
                                <Text variant="body">{translate('vessel.fuelStocks.editPresetsForm.description')}</Text>
                                <FormikVesselConsumptionPresetCategories />
                                <FormikDebug />
                            </Box>
                        </Box>

                        <Box padding={7}>
                            <Button fullWidth color="solid-clear" type="submit">
                                {props.isSubmitting ? (
                                    <ButtonSpinner color={theme.colors.foreground.onEmphasis} />
                                ) : (
                                    translate('btn.save.default')
                                )}
                            </Button>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};
