import { ApprovalRequestState } from '../../../../types/ApprovalRequestState';
import { StatusBar } from '../../../cdl/StatusBar/StatusBar';
import { translate } from '../../../common/helpers/translate.helper';
import { OfferModel } from '../../model/OfferModel';
import { OfferState } from '../../model/OfferState';

interface LubesAdminOfferDetailStatusBarProps {
    offer: OfferModel;
}
export const LubesAdminOfferDetailStatusBar = ({ offer }: LubesAdminOfferDetailStatusBarProps) => {
    switch (offer.state) {
        case OfferState.ENQUIRY: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.admin.spotenquiry.label')}
                        subHeadline={translate('statusbar.admin.spotenquiry.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.admin.enquiry.label')}
                    subHeadline={translate('statusbar.admin.enquiry.subtitle')}
                />
            );
        }
        case OfferState.QUOTED: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.admin.spotquoted.label')}
                        subHeadline={translate('statusbar.admin.spotquoted.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.admin.quoted.label')}
                    subHeadline={translate('statusbar.admin.quoted.subtitle')}
                />
            );
        }
        case OfferState.ORDER: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.admin.spotorder.label')}
                        subHeadline={translate('statusbar.admin.spotorder.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.admin.order.label')}
                    subHeadline={translate('statusbar.admin.order.subtitle')}
                />
            );
        }
        case OfferState.CUSTOMER_ADJUSTED: {
            const latestApprovalRequestState = offer.approvalRequests?.[0]?.state;

            if (latestApprovalRequestState === ApprovalRequestState.PENDING) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.admin.customerAdjustedApprovalPending.label')}
                        subHeadline={translate('statusbar.admin.customerAdjustedApprovalPending.subtitle')}
                    />
                );
            }

            if (latestApprovalRequestState === ApprovalRequestState.DECLINED) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.admin.customerAdjustedApprovalDeclined.label')}
                        subHeadline={translate('statusbar.admin.customerAdjustedApprovalDeclined.subtitle')}
                    />
                );
            }

            if (latestApprovalRequestState === ApprovalRequestState.EXPIRED) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.admin.customerAdjustedApprovalExpired.label')}
                        subHeadline={translate('statusbar.admin.customerAdjustedApprovalExpired.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.admin.customerAdjustedApproval.label')}
                    subHeadline={translate('statusbar.admin.customerAdjustedApproval.subtitle')}
                />
            );
        }
        case OfferState.SUPPLIER_ADJUSTED: {
            return (
                <StatusBar
                    headline={translate('statusbar.admin.supplierAdjusted.label')}
                    subHeadline={translate('statusbar.admin.supplierAdjusted.subtitle')}
                />
            );
        }
        case OfferState.ACKNOWLEDGED: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.admin.spotorderacknowledged.label')}
                        subHeadline={translate('statusbar.admin.spotorderacknowledged.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.admin.orderacknowledged.label')}
                    subHeadline={translate('statusbar.admin.orderacknowledged.subtitle')}
                />
            );
        }
        case OfferState.CONFIRMED: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="positive"
                        headline={translate('statusbar.admin.spotorderconfirmed.label')}
                        subHeadline={translate('statusbar.admin.spotorderconfirmed.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="positive"
                    headline={translate('statusbar.admin.orderconfirmed.label')}
                    subHeadline={translate('statusbar.admin.orderconfirmed.subtitle')}
                />
            );
        }
        case OfferState.DELIVERED: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="positive"
                        headline={translate('statusbar.admin.spotdelivered.label')}
                        subHeadline={translate('statusbar.admin.spotdelivered.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="positive"
                    headline={translate('statusbar.admin.delivered.label')}
                    subHeadline={translate('statusbar.admin.delivered.subtitle')}
                />
            );
        }
        case OfferState.DELIVERY_CONFIRMED: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="positive"
                        headline={translate('statusbar.admin.spotcompleted.label')}
                        subHeadline={translate('statusbar.admin.spotcompleted.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="positive"
                    headline={translate('statusbar.admin.completed.label')}
                    subHeadline={translate('statusbar.admin.completed.subtitle')}
                />
            );
        }
        case OfferState.INVOICED: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="positive"
                        headline={translate('statusbar.admin.spotinvoiced.label')}
                        subHeadline={translate('statusbar.admin.spotinvoiced.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="positive"
                    headline={translate('statusbar.admin.invoiced.label')}
                    subHeadline={translate('statusbar.admin.invoiced.subtitle')}
                />
            );
        }
        case OfferState.CANCELED: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="attention"
                        headline={translate('statusbar.admin.spotcanceled.label')}
                        subHeadline={translate('statusbar.admin.spotcanceled.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.admin.canceled.label')}
                    subHeadline={translate('statusbar.admin.canceled.subtitle')}
                />
            );
        }
        case OfferState.QUOTE_CANCELED: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="attention"
                        headline={translate('statusbar.admin.spotquotecanceled.label')}
                        subHeadline={translate('statusbar.admin.spotquotecanceled.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.admin.quotecanceled.label')}
                    subHeadline={translate('statusbar.admin.quotecanceled.subtitle')}
                />
            );
        }
        case OfferState.ENQUIRY_CANCELED: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="attention"
                        headline={translate('statusbar.admin.spotquotecanceled.label')}
                        subHeadline={translate('statusbar.admin.spotquotecanceled.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.admin.enquirycanceled.label')}
                    subHeadline={translate('statusbar.admin.enquirycanceled.subtitle')}
                />
            );
        }
        case OfferState.ENQUIRY_DECLINED: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="attention"
                        headline={translate('statusbar.admin.spotenquirycanceled.label')}
                        subHeadline={translate('statusbar.admin.spotenquirycanceled.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.admin.enquirydeclined.label')}
                    subHeadline={translate('statusbar.admin.enquirydeclined.subtitle')}
                />
            );
        }
        case OfferState.ENQUIRY_EXPIRED: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="attention"
                        headline={translate('statusbar.admin.spotenquiryexpired.label')}
                        subHeadline={translate('statusbar.admin.spotenquiryexpired.subtitle')}
                    />
                );
            }

            return null;
        }
        case OfferState.QUOTE_DECLINED: {
            if (offer.isSpot()) {
                return (
                    <StatusBar
                        variant="attention"
                        headline={translate('statusbar.admin.spotquotedeclined.label')}
                        subHeadline={translate('statusbar.admin.spotquotedeclined.subtitle')}
                    />
                );
            }

            return null;
        }
    }

    return null;
};
