import { useField } from 'formik';
import PropTypes from 'prop-types';

import { FuelProductSelect } from '../FuelProductSelect/FuelProductSelect';

export const FormikFuelProductSelect = (props) => {
    const [field, , helpers] = useField(props.name);

    const onChange = (option) => {
        helpers.setValue(option.value);
        props.onSelect?.(option.product);
    };

    return (
        <FuelProductSelect
            key={field.value}
            value={field.value}
            onChange={onChange}
            fetchProducts={props.fetchProducts}
        />
    );
};

FormikFuelProductSelect.propTypes = {
    name: PropTypes.string.isRequired,
    onSelect: PropTypes.func,
    fetchProducts: PropTypes.func,
};
