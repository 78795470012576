import moment from 'moment';
import { useEffect, useState } from 'react';

// Updates every second and returns the remaining time till the provided date.
export const useCountdown = (targetDate) => {
    const countDownDate = moment(targetDate);

    const [remainingTime, setRemainingTime] = useState(countDownDate.diff(moment(), 'seconds'));

    useEffect(() => {
        const interval = setInterval(() => {
            setRemainingTime(countDownDate.diff(moment(), 'seconds'));
        }, 1000);

        return () => clearInterval(interval);
    }, [targetDate, countDownDate]);

    return remainingTime;
};
