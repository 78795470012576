import { SingleValueProps } from 'react-select';

import { AgentMessage } from '../../../../types/AgentMessage';
import { CustomSingleValue } from '../../../cdl/Select/components/CustomSingleValue';
import { AgentImportedLabel } from '../../../common/AgentImportedLabel/AgentImportedLabel';
import { Box } from '../../../common/ui/Box';

interface AgentSingleValueProps extends SingleValueProps {
    data: { agent: AgentMessage };
}

export const AgentSingleValue = (props: AgentSingleValueProps) => {
    return (
        <CustomSingleValue {...props}>
            <Box display="flex" gap={4} alignItems="center">
                {props.children}
                {props.data.agent.source ? (
                    <Box style={{ lineHeight: '14px' }}>
                        <AgentImportedLabel />
                    </Box>
                ) : null}
            </Box>
        </CustomSingleValue>
    );
};
