import { useQuery } from '@tanstack/react-query';
import { useFormikContext } from 'formik';

import { ReachInformationCategory } from '../../../../types/ReachInformationMessage';
import { getReachInformationForScheduleId } from '../../../common/api/clients/reachInformation.api';
import { queryKeys } from '../../../common/api/queryKeys';

interface FormikState {
    vesselId: string | null;
    scheduleId: string | null;
}

interface UseFormikReachInformationResult {
    getReachInformationCategory: (sulphurContent: string, productGroup: string) => ReachInformationCategory | null;
}

export const useFormikReachInformation = (): UseFormikReachInformationResult => {
    const formik = useFormikContext<FormikState>();
    const vesselId = formik.values.vesselId;
    const scheduleId = formik.values.scheduleId;

    const query = useQuery({
        queryKey: queryKeys.scheduleReachInformation({ vesselId, scheduleId }),
        queryFn: () => getReachInformationForScheduleId(vesselId!!, scheduleId!!),
        enabled: !!vesselId && !!scheduleId,
    });

    const getReachInformationCategory = (sulphurContent: string, productGroup: string) => {
        if (!query.data?.stops[0]) {
            return null;
        }

        const relevantStop = query.data.stops[0];
        const category = relevantStop.categories.find(
            (category) => category.productGroup === productGroup && category.sulphurContent === sulphurContent
        );

        if (!category) {
            return null;
        }

        return category;
    };

    return {
        getReachInformationCategory,
    };
};
