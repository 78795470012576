import isEmpty from 'lodash/isEmpty';

export const formatAddress = ({ address }) => {
    if (isEmpty(address)) {
        return null;
    }

    const modifiedAddress = {
        companyName: address.companyName,
        street: address.street && address.streetNumber ? address.street + ' ' + address.streetNumber : address.street,
        city: address.zipcode && address.city ? address.zipcode + ' ' + address.city : address.city,
        country: address.country,
    };

    const isNotEmpty = (value) => {
        return value?.length > 0;
    };

    return Object.values(modifiedAddress).filter(isNotEmpty).join(', ');
};
