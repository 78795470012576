import * as RadixAccordion from '@radix-ui/react-accordion';
import { AccordionSingleProps } from '@radix-ui/react-accordion';
import { ReactElement } from 'react';

import { AccordionItem, AccordionItemProps } from './components/AccordionItem';

interface AccordionProps extends Pick<AccordionSingleProps, 'defaultValue' | 'value' | 'onValueChange'> {
    children: ReactElement<AccordionItemProps, typeof AccordionItem>[];
}

export const Accordion = ({ children, ...props }: AccordionProps) => {
    return (
        <RadixAccordion.Root {...props} type="single" collapsible>
            {children}
        </RadixAccordion.Root>
    );
};

Accordion.Item = AccordionItem;
