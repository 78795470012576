import { MouseEvent } from 'react';

import { IconButton } from '../../../cdl/IconButton/IconButton';
import { translate } from '../../helpers/translate.helper';
import { IconEye } from '../../icons/cdl/Eye';
import { IconEyeOff } from '../../icons/cdl/EyeOff';

interface OverviewReadButtonProps {
    isRead: boolean;
    onClick: () => void;
}

export const OverviewReadButton = ({ isRead, onClick }: OverviewReadButtonProps) => {
    const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
        onClick();
        event.stopPropagation();
    };

    const translation = isRead ? translate('actionListLabel.markAsUnread') : translate('actionListLabel.markAsRead');

    return (
        <IconButton
            icon={isRead ? IconEyeOff : IconEye}
            onClick={handleOnClick}
            tooltipLabel={translation}
            aria-label={translation}
        />
    );
};
