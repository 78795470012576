import PropTypes from 'prop-types';

import { Flex } from '../../common/ui/Flex';

export const PortPriceRow = ({ children }) => {
    return (
        <Flex
            backgroundColor="dark-blue.8"
            padding={5}
            alignItems="center"
            marginBottom={6}
            borderRadius="4px"
            position="relative"
            height="240px"
        >
            {children}
        </Flex>
    );
};

PortPriceRow.propTypes = {
    children: PropTypes.node,
};
