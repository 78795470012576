import PropTypes from 'prop-types';

import { translate } from '../../common/helpers/translate.helper';

import { ContractForm } from './ContractForm';
import { useContractCreate } from './useContractCreate';

export const CreateContractForm = ({ onSuccess }) => {
    const { mutate: createContract, error } = useContractCreate(onSuccess);
    const errors = error?.response.data.errors;

    const handleSubmit = ({ data }) => {
        createContract(data);
    };

    return <ContractForm title={translate('contracts.createTitle')} onSubmit={handleSubmit} errors={errors} />;
};

CreateContractForm.propTypes = {
    data: PropTypes.object,
    onSuccess: PropTypes.func.isRequired,
};
