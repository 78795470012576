import { useProductContext } from '../../hooks/useProductContext';

import { FuelSupplierSidebar } from './FuelSupplierSidebar';
import { LubesSupplierSidebarNavigation } from './LubesSupplierSidebarNavigation';

export const SupplierSidebarNavigation = () => {
    const { isFuel } = useProductContext();

    if (isFuel) {
        return <FuelSupplierSidebar />;
    }

    return <LubesSupplierSidebarNavigation />;
};
