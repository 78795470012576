import styled from 'styled-components';

import { get } from '../../common/ui/get';

export const ChartGrid = styled.div`
    height: 70vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: ${get('space.6')}px;
    padding-top: ${get('space.4')}px;
`;
