import moment from 'moment/moment';
import styled from 'styled-components';

import { Text } from '../../../../cdl/Text/Text';
import { formatDateTime } from '../../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useEnums } from '../../../../common/hooks/useEnums';
import { Box } from '../../../../common/ui/Box';
import { get } from '../../../../common/ui/get';

interface EmptyAverageConsumptionCategoryProps {
    category: string;
    dateRange: Date[];
}

const NoDataArea = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;

    // Styles for a custom dashed border with 6px dash and 6px gap
    background-image: repeating-linear-gradient(
            0deg,
            ${get('colors.border.muted')},
            ${get('colors.border.muted')} 6px,
            transparent 6px,
            transparent 12px,
            ${get('colors.border.muted')} 12px
        ),
        repeating-linear-gradient(
            90deg,
            ${get('colors.border.muted')},
            ${get('colors.border.muted')} 6px,
            transparent 6px,
            transparent 12px,
            ${get('colors.border.muted')} 12px
        ),
        repeating-linear-gradient(
            180deg,
            ${get('colors.border.muted')},
            ${get('colors.border.muted')} 6px,
            transparent 6px,
            transparent 12px,
            ${get('colors.border.muted')} 12px
        ),
        repeating-linear-gradient(
            270deg,
            ${get('colors.border.muted')},
            ${get('colors.border.muted')} 6px,
            transparent 6px,
            transparent 12px,
            ${get('colors.border.muted')} 12px
        );
    background-size:
        1px 100%,
        100% 1px,
        1px 100%,
        100% 1px;
    background-position:
        0 0,
        0 0,
        100% 0,
        0 100%;
    background-repeat: no-repeat;
`;

export const EmptyAverageConsumptionCategory = (props: EmptyAverageConsumptionCategoryProps) => {
    const { getHumanReadableValue } = useEnums();

    const isToday = moment(props.dateRange[1]).isSame(moment(), 'day');
    const endDate = isToday ? translate('vessel.averageConsumption.today') : moment(props.dateRange[1]).format('MMM D');

    return (
        <Box display="grid" gap={4} gridTemplateRows="min-content 1fr min-content">
            <Text fontWeight="semiBold" variant="body">
                {getHumanReadableValue('vesselTankCategory', props.category)}
            </Text>

            <NoDataArea>
                <Text color="foreground.subtle" variant="body" as="p">
                    {translate('vessel.averageConsumption.noConsumptionPlaceholder')}
                </Text>
            </NoDataArea>

            <Box display="flex" justifyContent="space-between">
                <Text variant="extraSmall" color="foreground.subtle">
                    {formatDateTime({ date: props.dateRange[0].toISOString(), format: 'MMM D' })}
                </Text>
                <Text variant="extraSmall" color="foreground.subtle">
                    {endDate}
                </Text>
            </Box>
        </Box>
    );
};
