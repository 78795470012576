import PropTypes from 'prop-types';

import { RestoreAgentPopupButton } from './RestoreAgentPopupButton';
import { useAgentStateChange } from '../useAgentStateChange';

export const ArchivedActions = ({ agent }) => {
    const { mutate: restoreAgent } = useAgentStateChange();

    const onAgentActivate = (agent) => {
        const agentToRestore = {
            ...agent,
            active: true,
        };
        restoreAgent({ agentId: agentToRestore.id, agent: agentToRestore });
    };

    return <RestoreAgentPopupButton onConfirm={() => onAgentActivate(agent)} name={agent.name} />;
};

ArchivedActions.propTypes = {
    agent: PropTypes.object.isRequired,
};
