import { useField } from 'formik';

import { DatePicker, DatePickerProps } from '../../../cdl/DatePicker/DatePicker';
import { formatLocalDateToUtcTimestamp } from '../../../order/lubes/create-enquiry/util/formatLocalDateToUtcTimestamp';

interface FormikDatePickerProps extends Pick<DatePickerProps, 'label' | 'markAsRequired' | 'caption'> {
    name: string;
}

export const FormikDatePicker = ({ name, ...rest }: FormikDatePickerProps) => {
    const [field, meta, helpers] = useField(name);
    const millisecondsInMinute = 60_000;

    const onDateChange = (date: Date | undefined) => {
        helpers.setTouched(true);
        if (date) {
            helpers.setValue(formatLocalDateToUtcTimestamp(date));
        } else {
            helpers.setValue(null);
        }
    };

    const formatUtcTimestampToLocalDate = (timestamp: number) => {
        const date = new Date(timestamp);
        const timezoneOffset = date.getTimezoneOffset() * millisecondsInMinute * -1;

        return new Date(date.getTime() - timezoneOffset);
    };

    return (
        <DatePicker
            {...rest}
            date={field.value ? formatUtcTimestampToLocalDate(field.value) : undefined}
            onDateChange={onDateChange}
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : rest.caption}
            onBlur={() => helpers.setTouched(true)}
        />
    );
};
