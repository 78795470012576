import css from '@styled-system/css';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';

const InfoBox = styled(Box)(
    css({
        padding: '10px',
        textAlign: 'center',
        fontWeight: 300,
        fontSize: '1',
        color: 'black',
        lineHeight: 2,
        backgroundColor: 'dark-blue.9',
    })
);

export const OfferChatInfo = ({ showForwardedOfferInfoText, showInfoText }) => {
    if (showForwardedOfferInfoText) {
        return <InfoBox>{translate('order.forwardedOfferMessageInfo')}</InfoBox>;
    }

    if (showInfoText) {
        return <InfoBox>{translate('order.suppliermessageinfo')}</InfoBox>;
    }

    return null;
};

OfferChatInfo.propTypes = {
    showForwardedOfferInfoText: PropTypes.bool.isRequired,
    showInfoText: PropTypes.bool.isRequired,
};
