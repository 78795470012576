import { MoneyMessage } from './MoneyMessage';
import { VesselMessage } from './VesselMessage';

export enum SolutionWarningType {
    OVERFILL = 'OVERFILL',
    QUARANTINE_REQUIREMENT = 'QUARANTINE_REQUIREMENT',
    QUARANTINE_VIOLATION = 'QUARANTINE_VIOLATION',
    RESERVE_VIOLATION = 'RESERVE_VIOLATION',
    REDELIVERY_DISCREPANCY = 'REDELIVERY_DISCREPANCY',
    FUEL_SUBSTITUTION = 'FUEL_SUBSTITUTION',
    WARNING = 'WARNING',
    PERFORMANCE = 'PERFORMANCE',
}

export interface SolutionWarning {
    type: SolutionWarningType;
    message: string;
}

interface Voyage {
    averageSpeed: number;
    totalDistance: number;
    totalDurationHours: number;
    totalDelayHours: number;
}

export interface CategoryPrice {
    productCategory: string;
    sulphurContent: string;
    price: MoneyMessage;
}

interface SolutionCosts {
    fuelCost: MoneyMessage;
    canalCost: MoneyMessage;
    portCost: MoneyMessage;
    bargeCost: MoneyMessage;
    tcePenaltyCost: MoneyMessage;
}

export interface SolutionSummary {
    solutionNumber: string;
    rank: number;
    nextFuelPortId: string;
    nextFuelDate: string;
    voyage: Voyage;
    warnings: SolutionWarning[];
    averageCategoryPrices: CategoryPrice[];
    solutionCosts: SolutionCosts;
    solutionTotal: MoneyMessage;
    bestSolutionDifference?: MoneyMessage;
}

export interface BunkerCaseResponse {
    caseNumber: string;
    vesselId: string;
    vessel: VesselMessage;
    establishedDate: string;
    solutions: SolutionSummary[];
}
