import styled from 'styled-components';

import { CL_DARK_BLUE_80 } from '../../common/colors';

/** @deprecated use CDL Text instead */

export const Paragraph = styled.p`
    margin: 8px 0 0 0;
    font-weight: 400;
    color: ${CL_DARK_BLUE_80};
    line-height: 1.3;
    font-size: 14px;

    &:first-of-type {
        margin-top: 0;
    }
`;
