import PropTypes from 'prop-types';

import { Tooltip } from '../../cdl/Tooltip/Tooltip';

import { ActionListItem } from './ActionListItem';

export const ActionIconListItem = ({ tooltip, children, onClick, ariaLabel }) => {
    if (!tooltip) {
        return (
            <ActionListItem>
                <button aria-label={ariaLabel} style={{ display: 'block' }} onClick={onClick}>
                    {children}
                </button>
            </ActionListItem>
        );
    }

    return (
        <ActionListItem>
            <Tooltip label={tooltip}>
                <span>
                    <button aria-label={ariaLabel} style={{ display: 'block' }} onClick={onClick}>
                        {children}
                    </button>
                </span>
            </Tooltip>
        </ActionListItem>
    );
};

ActionIconListItem.propTypes = {
    tooltip: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    ariaLabel: PropTypes.string,
};
