import { translate } from '../../helpers/translate.helper';
import { SidebarNavigationItem } from '../components/SidebarNavigationItem';

export const FuelSupplierSidebar = () => {
    return (
        <div>
            <SidebarNavigationItem to="base.fuel-dashboard">{translate('navigation.dashboard')}</SidebarNavigationItem>

            <SidebarNavigationItem to="base.fuel-offerlist">{translate('navigation.orders')}</SidebarNavigationItem>

            <SidebarNavigationItem to="base.fuel-reporting">{translate('navigation.reporting')}</SidebarNavigationItem>
        </div>
    );
};
