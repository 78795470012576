import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

interface GetPortDirectoryParams {
    portId: string;
    supplierGroupId: string;
}

export const getPortDirectory = async ({ portId, supplierGroupId }: GetPortDirectoryParams): Promise<any> => {
    const response = await authorizedAxiosInstance.get(
        `/v1/portdirectory/data/port/${portId}/suppliergroup/${supplierGroupId}`
    );

    return response.data;
};
