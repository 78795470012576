import PropTypes from 'prop-types';

import { CardVariation } from '../../common/ui/CardVariation';
import { ProductTable } from '../../product/ProductTable/ProductTable';

export const SpacedProductTable = ({ offer }) => {
    return (
        <CardVariation>
            <ProductTable offer={offer} />
        </CardVariation>
    );
};

SpacedProductTable.propTypes = {
    offer: PropTypes.object,
};
