import styled from 'styled-components';

import { get } from '../../../common/ui/get';

interface DotsWrapperProps {
    pageTotal?: number;
}

const DotsWrapper = styled.div<DotsWrapperProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: ${get('sizes.5')}px;
    width: ${(props) => {
        if (!props.pageTotal || props.pageTotal < 100) {
            return '32px';
        }
        return '44px';
    }};
    color: ${get('colors.foreground.default')};
    background-color: transparent;
    border-radius: ${get('radii.1]')}px;
    margin-left: ${get('space.1')}px;
    margin-right: ${get('space.1')}px;
`;

interface PaginationDotsProps {
    pageTotal?: number;
}

export const PaginationDots = ({ pageTotal }: PaginationDotsProps) => {
    return <DotsWrapper pageTotal={pageTotal}>&hellip;</DotsWrapper>;
};
