import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { sendContract } from '../../../common/api/clients/fuelContracts.api';
import { queryKeys } from '../../../common/api/queryKeys.js';
import { translate } from '../../../common/helpers/translate.helper';

export const useContractSend = () => {
    const queryClient = useQueryClient();
    const { addToast, addErrorToast } = useToasts();

    return useMutation({
        mutationFn: sendContract,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: queryKeys.contracts() });
            addToast(translate('contracts.toast.sendContract.success'));
        },
        onError: () => {
            addErrorToast(translate('contracts.toast.sendContract.error'));
        },
    });
};
