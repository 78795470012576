import { useField } from 'formik';
import PropTypes from 'prop-types';

import { Box } from '../../ui/Box';
import { DeprecatedDatePicker } from '../DatePicker/DeprecatedDatePicker';

/**
 * @deprecated Use FormikDatePicker instead
 */

export const DeprecatedFormikDatePicker = (props) => {
    const [field, , helpers] = useField(props.name);

    const onDayChange = (day) => {
        if (day) {
            helpers.setValue(day.toISOString());
        } else {
            helpers.setValue('');
        }
    };

    return (
        <Box>
            <DeprecatedDatePicker {...props} value={field.value} onDayChange={onDayChange} />
        </Box>
    );
};

DeprecatedFormikDatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    disabledBeforeDate: PropTypes.string,
    disabledAfterDate: PropTypes.string,
};
