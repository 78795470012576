import PropTypes from 'prop-types';

import { Button } from '../../cdl/Button/Button';
import { useDrawer } from '../../cdl/Drawer/useDrawer.hook';
import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';
import { Section } from '../../common/Section/Section.tsx';
import { Box } from '../../common/ui/Box';

import { EditVesselForm } from './EditVessel/EditVesselForm';
import { FuelStocks } from './FuelStocks/FuelStocks';
import { FuelVesselDetailDataSheet } from './FuelVesselDetailDataSheet';
import { useVesselMutation } from './useVesselMutation';

export const FuelInfoSection = ({ vessel }) => {
    const { pop, push } = useDrawer();
    const role = useRole();

    const { mutate: mutateVessel } = useVesselMutation(vessel.id);

    const parseEditClose = (updatedVessel) => {
        mutateVessel({
            vesselId: vessel.id,
            vessel: updatedVessel,
        });

        pop();
    };

    const onEditClick = () => {
        push({
            content: <EditVesselForm onSubmit={parseEditClose} vessel={vessel} />,
        });
    };

    return (
        <Box display="flex" gap={9}>
            <Box width={1 / 2}>
                <Section
                    title={translate('vessel.info')}
                    actions={
                        role.hasWriteRights(vessel.customerId) ? (
                            <Button onClick={onEditClick}>{translate('vessel.editInfo')}</Button>
                        ) : null
                    }
                >
                    <FuelVesselDetailDataSheet vessel={vessel} />
                </Section>
            </Box>

            <Box width={1 / 2}>
                <FuelStocks vesselId={vessel.id} customerId={vessel.customerId} />
            </Box>
        </Box>
    );
};

FuelInfoSection.propTypes = {
    vessel: PropTypes.object,
};
