import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteSupplierGroupLogo } from '../../../../common/api/clients/supplierGroups.api';
import { queries } from '../../../../common/api/queryKeys/queries';

export const useSupplierGroupLogoDelete = (groupId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () => deleteSupplierGroupLogo(groupId),

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queries.supplierGroups.detail(groupId).queryKey });
        },
    });
};
