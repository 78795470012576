import { useQuery, useQueryClient } from '@tanstack/react-query';

import { FuelStockMessage } from '../../../../../types/FuelStockMessage';
import { getFuelStocks } from '../../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../../common/api/queryKeys';

type BaseQueryResult = {
    isPending: boolean;
    isError: boolean;
    prefetch: (vesselId: string) => Promise<void>;
};

type QueryResultPending = BaseQueryResult & {
    groupedFuelStocks: { [key: string]: FuelStockMessage[] } | undefined;
    leadingFuelStock: FuelStockMessage | undefined;
    hasFuelStocks: false;
};

type QueryResultEmpty = BaseQueryResult & {
    groupedFuelStocks: { [key: string]: FuelStockMessage[] };
    leadingFuelStock: FuelStockMessage;
    hasFuelStocks: false;
};

type QueryResultFilled = BaseQueryResult & {
    groupedFuelStocks: { [key: string]: FuelStockMessage[] };
    leadingFuelStock: FuelStockMessage;
    hasFuelStocks: true;
};

type QueryResult = QueryResultPending | QueryResultEmpty | QueryResultFilled;

export const useFuelStocks = (vesselId: string): QueryResult => {
    const queryClient = useQueryClient();
    const { data, isSuccess, isPending, isError } = useQuery({
        queryKey: queryKeys.fuelStocks(vesselId),
        queryFn: () => getFuelStocks(vesselId),
        enabled: !!vesselId,
    });

    const prefetch = (vesselId: string) => {
        return queryClient.prefetchQuery({
            queryKey: queryKeys.fuelStocks(vesselId),
            queryFn: () => getFuelStocks(vesselId),
        });
    };

    if (!isSuccess) {
        return {
            groupedFuelStocks: undefined,
            leadingFuelStock: undefined,
            isPending,
            isError,
            prefetch,
            hasFuelStocks: false,
        };
    }

    const groupedFuelStocks = data.fuelStocks.reduce<{ [accessor: string]: FuelStockMessage[] }>((acc, fuelStock) => {
        const { productGroup, productSulphurContent } = fuelStock;
        const accessor = `${productGroup}_${productSulphurContent}`;
        if (!acc[accessor]) {
            acc[accessor] = [fuelStock];
        } else {
            acc[accessor].push(fuelStock);
        }
        return acc;
    }, {});

    const leadingFuelStock = data.fuelStocks[0];

    return {
        groupedFuelStocks,
        leadingFuelStock,
        isPending,
        isError,
        prefetch,
        hasFuelStocks: data.fuelStocks.length > 0,
    };
};
