import { ActionMeta, SingleValue, SingleValueProps } from 'react-select';

import { CustomSingleValue } from '../../cdl/Select/components/CustomSingleValue';
import { Select } from '../../cdl/Select/Select';
import { Text } from '../../cdl/Text/Text';
import { translate } from '../helpers/translate.helper';

const SortValue = ({ children, ...props }: SingleValueProps) => (
    <CustomSingleValue {...props}>
        <Text color="foreground.subtle" marginRight={3}>
            {translate('order.sort')}
        </Text>
        {children}
    </CustomSingleValue>
);

export interface SortOption {
    label: string;
    value: {
        sortField: string;
        sortDirection: string;
    };
}

interface SortSelectProps {
    onChange: (newValue: SingleValue<SortOption>, actionMeta: ActionMeta<SortOption>) => void;
    options: SortOption[];
    value: SortOption;
}

export const SortSelect = (props: SortSelectProps) => {
    return (
        <Select
            value={props.value}
            options={props.options}
            onChange={props.onChange}
            overrides={{ SingleValue: SortValue as any }}
        />
    );
};
