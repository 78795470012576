import { ReactNode } from 'react';

import { useRole } from '../../hooks/useRole';
import { SidebarNavigationItem } from '../components/SidebarNavigationItem';

import { LockedSidebarItem } from './LockedSidebarItem';

interface ContextualLiteNavigationItemProps {
    to: string;
    children: ReactNode;
}

export const ContextualLiteSidebarItem = ({ to, children }: ContextualLiteNavigationItemProps) => {
    const role = useRole();

    if (role.usesCloselinkLite()) {
        return <LockedSidebarItem>{children}</LockedSidebarItem>;
    } else {
        return <SidebarNavigationItem to={to}>{children}</SidebarNavigationItem>;
    }
};
