import { Text } from '../../../../cdl/Text/Text';
import { ButtonGroup } from '../../../../common/buttons/ButtonGroup';
import { Error } from '../../../../common/Error/Error';
import { translate } from '../../../../common/helpers/translate.helper';
import { Box } from '../../../../common/ui/Box';
import { useBunkerMetricBunkerCaseSolution } from '../../hooks/useBunkerMetricBunkerCaseSolution';
import { SolutionRankIcon } from '../BunkerSolutionsTable/components/SolutionRankIcon';
import { SkeletonSolutionDetails } from '../SkeletonSolutionDetails';

import { BunkerSolutionStopAccordion } from './components/BunkerSolutionStopAccordion';
import { CreateBunkerStopsButton } from './components/CreateBunkerStopsButton';
import { SolutionCsvDownloadButton } from './components/SolutionCsvDownloadButton';

interface BunkerSolutionDetailsProps {
    vesselId: string;
    caseNumber: string;
    solutionNumber: string;
    rank: number;
}

export const BunkerSolutionDetails = ({ vesselId, caseNumber, solutionNumber, rank }: BunkerSolutionDetailsProps) => {
    const bunkerMetricBunkerCaseSolutionQuery = useBunkerMetricBunkerCaseSolution({
        vesselId: vesselId,
        caseNumber: caseNumber,
        solutionNumber: solutionNumber,
    });

    if (bunkerMetricBunkerCaseSolutionQuery.isPending) {
        return (
            <>
                <Box marginBottom={5}>
                    <Box>
                        <Box display="flex" alignItems="center" gap={2}>
                            <Text as="h2" variant="title">
                                {translate('prePlanning.detailsTitle')}
                            </Text>
                            <SolutionRankIcon rank={rank} size={24} />
                        </Box>
                        <Text variant="small" color="foreground.muted">
                            {translate('prePlanning.detailsSubtitle')}
                        </Text>
                    </Box>
                </Box>
                <SkeletonSolutionDetails />
            </>
        );
    }

    if (bunkerMetricBunkerCaseSolutionQuery.isError) {
        return <Error />;
    }

    const purchaseCategories = bunkerMetricBunkerCaseSolutionQuery.data.bunkerSchedules.reduce(
        (acc, bunkerSchedule) => {
            if (bunkerSchedule.purchases.length <= 0) {
                return acc;
            }

            bunkerSchedule.purchases.forEach((purchase) => {
                if (purchase.quantity > 0) {
                    acc.set(`${purchase.productCategory}-${purchase.sulphurContent}`, {
                        productCategory: purchase.productCategory,
                        sulphurContent: purchase.sulphurContent,
                    });
                }
            });

            return acc;
        },
        new Map<string, { productCategory: string; sulphurContent: string }>()
    );

    return (
        <>
            <Box marginBottom={5}>
                <Box display="flex" justifyContent="space-between">
                    <Box>
                        <Box display="flex" alignItems="center" gap={2}>
                            <Text as="h2" variant="title">
                                {translate('prePlanning.detailsTitle')}
                            </Text>
                            <SolutionRankIcon rank={rank} size={24} />
                        </Box>
                        <Text variant="small" color="foreground.muted">
                            {translate('prePlanning.detailsSubtitle')}
                        </Text>
                    </Box>
                    <Box>
                        <ButtonGroup>
                            <SolutionCsvDownloadButton solution={bunkerMetricBunkerCaseSolutionQuery.data} />
                            <CreateBunkerStopsButton solution={bunkerMetricBunkerCaseSolutionQuery.data} />
                        </ButtonGroup>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={4}>
                {bunkerMetricBunkerCaseSolutionQuery.data.bunkerSchedules.map((bunkerSchedule, index) => (
                    <BunkerSolutionStopAccordion
                        key={`${solutionNumber}-${index}`}
                        bunkerSchedule={bunkerSchedule}
                        purchaseCategories={purchaseCategories}
                    />
                ))}
            </Box>
        </>
    );
};
