import { useField } from 'formik';
import PropTypes from 'prop-types';

import { translate } from '../../common/helpers/translate.helper';
import { useFuelVolumeFormat } from '../../common/hooks/useFuelVolumeFormat';

import { Warning } from './Warning';

export const FormikContractVolumeWarning = (props) => {
    const formatFuelVolume = useFuelVolumeFormat();

    const [{ value: contract }] = useField('contract');
    const [{ value: productId }] = useField(`${props.name}.productId`);
    const [{ value: units = 0 }] = useField(`${props.name}.units`);

    //In the create enquiry flow are no initial units, why we default it here to 0

    const initialUnits = props.initialItems?.length ? props.initialItems[props.index].units : 0;

    const contractProductGroup = contract.productGroups.find((productGroup) =>
        productGroup.products.some((product) => product.productId === productId)
    );

    const remainingVolume =
        contractProductGroup.contractVolume - contractProductGroup.usedContractVolume + initialUnits;

    if (contractProductGroup.minStemSize > units && remainingVolume < units) {
        return <Warning tooltip={translate('contracts.tooltips.belowAndExceeded')} />;
    }

    if (units > 0) {
        if (contractProductGroup.minStemSize > units) {
            const formattedMinStemSize = formatFuelVolume({
                volume: contractProductGroup.minStemSize,
            });
            return (
                <Warning
                    tooltip={translate('contracts.tooltips.belowMinStemSize', {
                        volume: formattedMinStemSize,
                    })}
                />
            );
        }

        if (remainingVolume < units) {
            const overrun = formatFuelVolume({
                volume: units - remainingVolume,
            });

            return <Warning tooltip={translate('contracts.tooltips.contractVolumeExceeded', { volume: overrun })} />;
        }
    }

    return null;
};

FormikContractVolumeWarning.propTypes = {
    name: PropTypes.string.isRequired,
    initialItems: PropTypes.array,
    index: PropTypes.number,
};
