import { QuickStatsMessage } from '../../../../types/QuickStatsMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const getStats = async (type: string): Promise<QuickStatsMessage> => {
    const params = new URLSearchParams();
    params.append('types', type);

    const response = await authorizedAxiosInstance.get<QuickStatsMessage>(`/v1/quickstats?${params.toString()}`);

    return response.data;
};
