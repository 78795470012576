import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { FuelOrderEditFlow as OriginalFuelOrderEditFlow } from './FuelOrderEditFlow';

const FuelOrderEditFlow = (props) => {
    return (
        <AppProviders>
            <OriginalFuelOrderEditFlow {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(FuelOrderEditFlow, 'reactFuelOrderEditFlow');
