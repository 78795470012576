export const CL_DARK_BLUE_100 = '#193643';
export const CL_DARK_BLUE_80 = '#475E68';
export const CL_DARK_BLUE_60 = '#75878e';
export const CL_DARK_BLUE_40 = '#A3AFB4';
export const CL_DARK_BLUE_20 = '#D1D7D9';
export const CL_DARK_BLUE_10 = '#DCDFE1';
export const CL_DARK_BLUE_4 = '#F6F7F7';
export const CL_DARK_BLUE_2 = '#fafbfb';

export const CL_CLEAR_BLUE_100 = '#0068FF';
export const CL_CLEAR_BLUE_60 = '#66A4FF';
export const CL_CLEAR_BLUE_40 = '#99c3ff';
export const CL_CLEAR_BLUE_20 = '#cce1ff';
export const CL_CLEAR_BLUE_10 = '#E1EBFB';

export const BOLD_BLUEISH_100 = '#0001FE';
export const BOLD_BLUEISH_40 = '#9999FF';
export const BOLD_BLUEISH_20 = '#CCCCFF';

export const SIGNAL_RED_100 = '#fe355a';
export const SIGNAL_RED_20 = '#ffd7de';

export const MELLOW_TEAL_100 = '#1de9b6';

export const PURE_WHITE = '#ffffff';

export const CONFIRMING_GREEN_100 = '#00CC69';
export const CONFIRMING_GREEN_10 = '#E1F5EB';
