import { SurveyorMessage } from '../../../../../../types/SurveyorMessage';
import { Text } from '../../../../../cdl/Text/Text';
import { translate } from '../../../../../common/helpers/translate.helper';

import { InvolvedParty } from './InvolvedParty';

interface SurveyorPartyProps {
    surveyor?: SurveyorMessage;
}

export const SurveyorParty = ({ surveyor }: SurveyorPartyProps) => {
    return (
        <InvolvedParty disabled={!surveyor} label={translate('offer.detail.coreData.surveyor')}>
            {surveyor ? (
                <>
                    <Text>{surveyor.name}</Text>
                    {surveyor.emailAddress ? <Text color="foreground.subtle">{surveyor.emailAddress}</Text> : null}
                </>
            ) : (
                translate('offer.detail.coreData.noSurveyor')
            )}
        </InvolvedParty>
    );
};
