import { ReactNode } from 'react';

import { DemandInformationVessel, VesselState } from '../../../types/DemandInformationMessage';
import { DefaultOption } from '../../cdl/ComboBox/ComboBox';
import { DynamicFilterContainer, Filter } from '../../cdl/Filter/DynamicFilterContainer/DynamicFilterContainer';
import { FilterMultiSelectFull } from '../../cdl/Filter/FilterMultiSelectFull/FilterMultiSelectFull';
import {
    FilterMultiSelectOption,
    FilterMultiSelectShort,
} from '../../cdl/Filter/FilterMultiSelectShort/FilterMultiSelectShort';
import { translate } from '../../common/helpers/translate.helper';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { useCustomerList } from '../../group/customer/detail/hooks/useCustomerList';

interface FilterContainerProps {
    allEntries: DemandInformationVessel[];
    children: (filteredItems: DemandInformationVessel[]) => ReactNode;
    groupId?: string;
}

export const VesselDemandFilter = ({ allEntries, children, groupId }: FilterContainerProps) => {
    const role = useRole();
    const { context: productContext } = useProductContext();
    const customerQuery = useCustomerList({
        groupId: role.isAdmin() ? groupId : role.group.id,
        type: productContext,
    });

    const stateFilter: Filter<VesselState, DemandInformationVessel> = {
        filterFn: (value, entry) => value.includes(entry.state),
        component: (values, setValues) => {
            const availableVesselStates = Array.from(new Set(allEntries.map((vessel) => vessel.state)));

            const optionsWithLabel: FilterMultiSelectOption<VesselState>[] = availableVesselStates.map((value) => {
                return {
                    label: translate(`demand.filter.vesselState.${value as VesselState}`),
                    value: value as VesselState,
                };
            });

            return (
                <FilterMultiSelectShort<VesselState>
                    label={translate('demand.filter.vesselState.state')}
                    filterByLabel={translate('demand.filter.vesselState.filterByState')}
                    values={values}
                    options={optionsWithLabel}
                    onChange={setValues}
                />
            );
        },
    };

    const vesselFilter: Filter<string, DemandInformationVessel> = {
        filterFn: (value, entry) => value.includes(entry.id),
        component: (values, setValues) => {
            const optionsWithLabel: DefaultOption[] = allEntries.map((value) => {
                return {
                    label: value.name,
                    value: value.id,
                };
            });

            return (
                <FilterMultiSelectFull
                    label={translate('demand.filter.vessel.vessel')}
                    filterByLabel={translate('demand.filter.vessel.filterByVessel')}
                    filterPlaceholder={translate('demand.filter.vessel.searchForVessel')}
                    options={optionsWithLabel}
                    values={values}
                    onChange={setValues}
                />
            );
        },
    };

    const companyFilter: Filter<string, DemandInformationVessel> = {
        filterFn: (value, entry) => value.includes(entry.customerId),
        component: (values, setValues) => {
            const availableCompanies = customerQuery.data ?? [];

            const optionsWithLabel: FilterMultiSelectOption<string>[] = availableCompanies.map((value) => {
                return {
                    label: value.name,
                    value: value.id,
                };
            });

            return (
                <FilterMultiSelectShort
                    label={translate('demand.filter.company.company')}
                    filterByLabel={translate('demand.filter.company.filterByCompany')}
                    values={values}
                    options={optionsWithLabel}
                    onChange={setValues}
                />
            );
        },
    };

    const filters: Record<string, Filter<any, DemandInformationVessel>> = role.isOneCompanyUser(role.context) &&
    !role.isAdmin()
        ? {
              vessel: vesselFilter,
              state: stateFilter,
          }
        : {
              company: companyFilter,
              vessel: vesselFilter,
              state: stateFilter,
          };

    return (
        <DynamicFilterContainer allEntries={allEntries} filters={filters}>
            {children}
        </DynamicFilterContainer>
    );
};
