import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { createOrder } from '../../../common/api/clients/order.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { translate } from '../../../common/helpers/translate.helper';

export const useDraftCreate = () => {
    const queryClient = useQueryClient();

    const { addErrorToast } = useToasts();

    return useMutation({
        mutationFn: createOrder,
        onSuccess: () => {
            queryClient.removeQueries({ queryKey: queryKeys.orders() });
            queryClient.removeQueries({ queryKey: queryKeys.ordersTotals() });
        },
        onError: () => {
            addErrorToast(translate('fuelEnquiry.toast.createDraft.error'));
        },
    });
};
