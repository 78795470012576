const AppSettings = {
    VERSION: '6.0.0',
    ENV: 'storybook',
    SENTRY_DSN: 'https://f2addee7c0a849c28f0049d6f1fc04c7@sentry.io/278221',
    googleAnalyticsId: '',
    googleApiURLKey: '',
    magicBellApiKey: '',
    routes: {
        api: '/api-base',
        gateway: '/api-gateway',
    },
    launchDarklyClientSideId: '',
    closelinkChartsUrl: 'https://charts.closelink.com',
};

export default AppSettings;
