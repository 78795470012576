import { Form, useFormikContext } from 'formik';
import React from 'react';

import { AccessLevel } from '../../../../../types/AccessLevel';
import { UserType } from '../../../../../types/UserType';
import { Button } from '../../../../cdl/Button/Button';
import { Text } from '../../../../cdl/Text/Text';
import { FormikCustomerCompanySelect } from '../../../../common/form-elements/formik/FormikCustomerCompanySelect';
import { FormikDebug } from '../../../../common/form-elements/formik/FormikDebug';
import { FormikEnumComboBox } from '../../../../common/form-elements/formik/FormikEnumComboBox';
import { FormikSupplierCompanySelect } from '../../../../common/form-elements/formik/FormikSupplierCompanySelect';
import { translate } from '../../../../common/helpers/translate.helper';
import { Box } from '../../../../common/ui/Box';

interface ApiKeyBaseFormProps {
    title: string;
    groupId: string;
    userType: UserType.CUSTOMER | UserType.SUPPLIER;
}

export interface ApiKeyFormState {
    companyIds: string[];
    companyAccessLevel: AccessLevel;
}

export const ApiKeyBaseForm = (props: ApiKeyBaseFormProps) => {
    const formikContext = useFormikContext();

    return (
        <Form style={{ height: '100%' }}>
            <Box
                display="grid"
                rowGap={6}
                padding={7}
                gridTemplateRows="auto 1fr auto"
                height="100%"
                gridTemplateColumns="100%"
            >
                <Box>
                    <Text variant="title">{props.title}</Text>
                </Box>

                <Box display="flex" flexDirection="column" rowGap={4}>
                    {props.userType === UserType.CUSTOMER ? (
                        <FormikCustomerCompanySelect
                            name="companyIds"
                            label={translate('apikey.companies')}
                            groupId={props.groupId}
                            isMulti
                        />
                    ) : (
                        <FormikSupplierCompanySelect
                            name="companyIds"
                            label={translate('apikey.companies')}
                            groupId={props.groupId}
                            isMulti
                        />
                    )}

                    <FormikEnumComboBox
                        name="companyAccessLevel"
                        enumType="accessLevel"
                        label={translate('apikey.companyaccesslevel')}
                    />
                </Box>

                <Button
                    emphasis="high"
                    type="submit"
                    size="large"
                    disabled={!formikContext.isValid}
                    isLoading={formikContext.isSubmitting}
                    width="100%"
                >
                    {translate('btn.save.default')}
                </Button>
                <FormikDebug />
            </Box>
        </Form>
    );
};
