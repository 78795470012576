import { ProductMessage } from '../../../../../types/ProductMessage';
import { SupplierMessage } from '../../../../../types/SupplierMessage';

interface ProductTranslationResult {
    baseProductId: string;
    productId: string;
    isTranslated: boolean;
    isSuccessful: boolean;
}

export function translateProduct(product: ProductMessage, supplier: SupplierMessage): ProductTranslationResult {
    if (!product.references) {
        return {
            baseProductId: product.id,
            productId: product.id,
            isTranslated: false,
            isSuccessful: false,
        };
    }

    const translationSupplierProductGroup = supplier.supplierGroup.translationSupplierProductGroup;
    if (!translationSupplierProductGroup || product.supplierProductGroup === translationSupplierProductGroup) {
        return {
            baseProductId: product.id,
            productId: product.id,
            isTranslated: false,
            isSuccessful: true,
        };
    }

    const reference = product.references[translationSupplierProductGroup];

    if (reference === undefined) {
        return {
            baseProductId: product.id,
            productId: product.id,
            isTranslated: false,
            isSuccessful: false,
        };
    }

    return {
        baseProductId: product.id,
        productId: reference,
        isTranslated: true,
        isSuccessful: true,
    };
}
