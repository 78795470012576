import { useCallback, useEffect, useState } from 'react';
import { OnChangeValue } from 'react-select';

import { PortMessage } from '../../../types/PortMessage';
import { ComboBox, ComboBoxProps, DefaultOption } from '../../cdl/ComboBox/ComboBox';
import { getPorts } from '../../common/api/clients/port.api';
import { formatPort } from '../../common/helpers/formatPort.helper';
import { translate } from '../../common/helpers/translate.helper';

import { PortOptionWithoutKeyPortsAndSchedule } from './components/PortOptionWithoutKeyPortsAndSchedule';

export interface PortOption {
    label: string;
    value: string;
    port: PortMessage;
}

export interface PortSelectProps extends Omit<ComboBoxProps<DefaultOption, false>, 'onChange' | 'value'> {
    value: string;
    onChange: (option: PortOption) => void;
}

export function PortDetailSelect({ value, onChange, label = translate('portSelect.label'), ...rest }: PortSelectProps) {
    const [selectedValue, setSelectedValue] = useState<PortOption | null>(null);

    const loadPortsBySearchQuery = async (searchQuery: string) => {
        const response = await getPorts({ searchQuery });
        return response.content.map((port) => {
            return { label: formatPort({ port, short: true }), value: port.id, port };
        });
    };

    const loadPortById = useCallback(async (portId: string): Promise<PortOption> => {
        const response = await getPorts({ ids: [portId] });
        const port = response.content[0];
        return {
            label: formatPort({ port }),
            value: port.id,
            port,
        };
    }, []);

    useEffect(() => {
        let isMounted = true;

        if (value) {
            loadPortById(value).then((option: PortOption) => {
                if (isMounted) {
                    setSelectedValue(option);
                }
            });
        }

        return () => {
            isMounted = false;
        };
    }, [value, loadPortById]);

    const updateSelectedValue = (option: OnChangeValue<PortOption, false>) => {
        onChange(option as PortOption);
    };

    return (
        <ComboBox<PortOption, false>
            {...rest}
            label={label}
            onChange={updateSelectedValue}
            value={selectedValue}
            loadOptions={loadPortsBySearchQuery}
            overrides={{
                Option: PortOptionWithoutKeyPortsAndSchedule as any,
            }}
        />
    );
}
