import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';

export const Greeting = () => {
    const role = useRole();

    const getTranslationKey = () => {
        const currentHour = new Date().getHours();

        if (currentHour >= 2 && currentHour <= 12) {
            return 'dashboard.greeting.morning';
        }

        if (currentHour >= 12 && currentHour <= 18) {
            return 'dashboard.greeting.afternoon';
        }

        return 'dashboard.greeting.evening';
    };

    const translationKey = getTranslationKey();
    const userName = `${role.user.firstname} ${role.user.lastname}`;

    return (
        <Text variant="headline" color="foreground.subtle">
            {translate(translationKey, { userName })}
        </Text>
    );
};
