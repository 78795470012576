import { ChangeEvent, useRef } from 'react';

import { SupplierGroupMessage } from '../../../../../types/SupplierGroupMessage';
import { Button } from '../../../../cdl/Button/Button';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconUpload } from '../../../../common/icons/cdl/Upload';
import { useSupplierGroupLogoDelete } from '../hooks/useSupplierGroupLogoDelete';
import { useSupplierGroupLogoUpload } from '../hooks/useSupplierGroupLogoUpload';

interface SupplierGroupLogoButtonProps extends Pick<SupplierGroupMessage, 'logo' | 'id'> {}

export const SupplierGroupLogoButton = ({ logo, id }: SupplierGroupLogoButtonProps) => {
    const { addLoadingToast } = useToasts();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const uploadLogoMutation = useSupplierGroupLogoUpload(id);
    const deleteLogoMutation = useSupplierGroupLogoDelete(id);

    const removeFile = () => {
        addLoadingToast(
            () => deleteLogoMutation.mutateAsync(),
            translate('suppliergroup.toasts.deleteLogoLoading'),
            translate('suppliergroup.toasts.deleteLogoSuccess'),
            translate('suppliergroup.toasts.deleteLogoError')
        );
    };

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const uploadFile = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            addLoadingToast(
                () => uploadLogoMutation.mutateAsync(file),
                translate('suppliergroup.toasts.logoUploadLoading'),
                translate('suppliergroup.toasts.logoUploadSuccess'),
                translate('suppliergroup.toasts.logoUploadError')
            );
        }
    };

    if (logo) {
        return (
            <Button destructive onClick={removeFile}>
                {translate('suppliergroup.btnRemoveLogo')}
            </Button>
        );
    }

    return (
        <div>
            <Button leadingVisual={<IconUpload height={16} width={16} />} onClick={handleUploadClick}>
                {translate('suppliergroup.btnuploadlogo')}
            </Button>
            <input ref={fileInputRef} type="file" accept="image/*" onInput={uploadFile} style={{ display: 'none' }} />
        </div>
    );
};
