import { useField } from 'formik';

import { FuelDeliveryCostSelect } from './FuelDeliveryCostSelect';

interface FormikFuelDeliveryCostsSelectProps {
    name: string;
    label: string;
    required: boolean;
}

export const FormikFuelDeliveryCostsSelect = (props: FormikFuelDeliveryCostsSelectProps) => {
    const [field, , helpers] = useField(props.name);

    return (
        <FuelDeliveryCostSelect
            {...props}
            {...field}
            value={field.value}
            onChange={(isIncluded: boolean) => {
                helpers.setValue(isIncluded);
            }}
            isHighlight={props.required && field.value === undefined}
        />
    );
};
