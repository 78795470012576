import { ComponentType } from 'react';

import { AverageConsumptionQuality } from '../../../../../types/AverageConsumption';
import { Popover } from '../../../../cdl/Popover/Popover';
import { Tag } from '../../../../cdl/Tag/Tag';
import { Text } from '../../../../cdl/Text/Text';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconLetterA } from '../../../../common/icons/cdl/LetterA';
import { IconLetterB } from '../../../../common/icons/cdl/LetterB';
import { IconLetterC } from '../../../../common/icons/cdl/LetterC';

interface QualityIndicatorProps {
    qualityCategory: AverageConsumptionQuality;
}

interface QualityDefinition {
    headline: string;
    description: string;
    icon: ComponentType<{ width?: number; height?: number }>;
    variant: 'positive' | 'attention' | 'negative';
}

const selectQualityDefinition = (qualityCategory: AverageConsumptionQuality): QualityDefinition => {
    switch (qualityCategory) {
        case AverageConsumptionQuality.GOOD:
            return {
                headline: translate('vessel.averageConsumption.qualityIndicator.GOOD.headline'),
                description: translate('vessel.averageConsumption.qualityIndicator.GOOD.description'),
                icon: IconLetterA,
                variant: 'positive',
            };
        case AverageConsumptionQuality.FAIR:
            return {
                headline: translate('vessel.averageConsumption.qualityIndicator.FAIR.headline'),
                description: translate('vessel.averageConsumption.qualityIndicator.FAIR.description'),
                icon: IconLetterB,
                variant: 'attention',
            };
        case AverageConsumptionQuality.POOR:
            return {
                headline: translate('vessel.averageConsumption.qualityIndicator.POOR.headline'),
                description: translate('vessel.averageConsumption.qualityIndicator.POOR.description'),
                icon: IconLetterC,
                variant: 'negative',
            };
    }
};

export const QualityIndicator = ({ qualityCategory }: QualityIndicatorProps) => {
    const { headline, description, icon, variant } = selectQualityDefinition(qualityCategory);

    return (
        <Popover
            trigger={
                <div style={{ cursor: 'pointer' }}>
                    <Tag variant={variant} Icon={icon} />
                </div>
            }
            width="250px"
            align="start"
            side="bottom"
        >
            <div>
                <Text as="p" variant="extraSmall" fontWeight="bold">
                    {headline}
                </Text>
                <Text as="span" display="block" variant="extraSmall">
                    {description}
                </Text>
            </div>
        </Popover>
    );
};
