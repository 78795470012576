import { AxiosError } from 'axios';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { Text } from '../../cdl/Text/Text';
import { useToasts } from '../../cdl/Toast/useToasts';
import { convertToSha1Hash } from '../../common/helpers/convertToSh1Hash.helper';
import { isValidEmailAddress } from '../../common/helpers/isValidEmailAddress.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useAngularDependencies } from '../../common/hooks/useAngularDependencies';
import { useAngularRouterLocation } from '../../common/hooks/useAngularRouterLocation';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { FormikLoginForm } from '../components/FormikLoginForm';
import { Link } from '../components/Link';
import { LoginPageContentBox } from '../components/LoginPageContentBox';
import { useRedirectAfterLogin } from '../hooks/useRedirectAfterLogin';

export interface FormikLoginState {
    emailAddress: string;
    password: string;
}

const validate = (values: FormikLoginState): FormikErrors<FormikLoginState> => {
    const errors: FormikErrors<FormikLoginState> = {};

    if (!values.emailAddress || !isValidEmailAddress(values.emailAddress)) {
        errors.emailAddress = translate('login.error.invalidEmailAddress');
    }

    if (!values.password) {
        errors.password = translate('login.error.passwordRequired');
    }

    return errors;
};

export const LoginPage = () => {
    const { addErrorToast } = useToasts();
    const { SessionService } = useAngularDependencies();
    const redirectAfterLogin = useRedirectAfterLogin();
    const { emailAddress: emailAddressFromRoute } = useAngularRouterLocation<{
        emailAddress: string;
    }>();

    useDocumentTitle(translate('login.pageTitle'));

    if (SessionService.isAuthenticated()) {
        void redirectAfterLogin();

        return null;
    }

    const handleSubmit = async (values: FormikLoginState, meta: FormikHelpers<FormikLoginState>) => {
        const passwordHash = await convertToSha1Hash(values.password);
        const credentials = {
            username: values.emailAddress,
            password: passwordHash,
        };

        return SessionService.authenticate(credentials)
            .then(() => redirectAfterLogin())
            .catch((error: AxiosError) => {
                if (error.status === 403) {
                    meta.setErrors({
                        password: translate('login.error.emailAddressOrPasswordIncorrect'),
                    });
                } else {
                    addErrorToast(translate('login.error.weCouldNotConnectToCloselink'));
                }
            });
    };

    return (
        <LoginPageContentBox
            belowBoxContent={
                <Text variant="extraSmall">
                    {translate('login.dontHaveAnAccountYet')}{' '}
                    <Link href="//closelink.com/contact-us">{translate('login.getInTouchToday')}</Link>
                </Text>
            }
        >
            <Text variant="title" as="h1">
                {translate('login.loginToYourAccount')}
            </Text>
            <Formik
                initialValues={{
                    emailAddress: emailAddressFromRoute,
                    password: '',
                }}
                validate={validate}
                onSubmit={handleSubmit}
                component={FormikLoginForm}
            />
        </LoginPageContentBox>
    );
};
