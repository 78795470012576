import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../cdl/Toast/useToasts';
import { deleteContract as deleteContractQuery } from '../../common/api/clients/fuelContracts.api';
import { queryKeys } from '../../common/api/queryKeys';
import { translate } from '../../common/helpers/translate.helper';

export const useContractDelete = () => {
    const queryClient = useQueryClient();
    const { addErrorToast } = useToasts();

    return useMutation({
        mutationFn: deleteContractQuery,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.contracts() });
            queryClient.invalidateQueries({ queryKey: queryKeys.contractsTotals() });
        },
        onError: () => {
            addErrorToast(translate('contracts.toast.deleteContract.error'));
        },
    });
};
