import { useMutation } from '@tanstack/react-query';

import { useToasts } from '../../cdl/Toast/useToasts';
import { createMessage } from '../../common/api/clients/order.api';
import { translate } from '../../common/helpers/translate.helper';

export const useMessageCreate = () => {
    const { addErrorToast } = useToasts();
    return useMutation({
        mutationFn: createMessage,
        onError: () => {
            addErrorToast(translate('order.sendMessageFailedToast'));
        },
    });
};
