import { Form, useFormikContext } from 'formik';
import React from 'react';

import { Button } from '../../../cdl/Button/Button';
import { FormikInput } from '../../../cdl/Input/FormikInput';
import { Text } from '../../../cdl/Text/Text';
import { FormikDebug } from '../../../common/form-elements/formik/FormikDebug';
import { FormikEnumComboBox } from '../../../common/form-elements/formik/FormikEnumComboBox';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';

interface ProductBaseFormProps {
    title: string;
}

export interface ProductFormState {
    supplierProductGroup: string;
    name: string;
    group: string;
    packTypeDefault: string;
    unitDefault: string;
}

export const ProductBaseForm = (props: ProductBaseFormProps) => {
    const formikContext = useFormikContext();

    return (
        <Form style={{ height: '100%' }}>
            <Box
                display="grid"
                rowGap={6}
                padding={7}
                gridTemplateRows="auto 1fr auto"
                height="100%"
                gridTemplateColumns="100%"
            >
                <Box>
                    <Text variant="title">{props.title}</Text>
                </Box>

                <Box display="flex" flexDirection="column" rowGap={4}>
                    <FormikInput name="name" label={translate('productsOverview.labels.name')} />
                    <FormikEnumComboBox
                        name="supplierProductGroup"
                        enumType="supplierProductGroup"
                        label={translate('productsOverview.labels.supplier')}
                    />
                    <FormikEnumComboBox
                        name="group"
                        enumType="productGroup"
                        context="LUBES"
                        label={translate('productsOverview.labels.group')}
                    />
                    <FormikEnumComboBox
                        name="packTypeDefault"
                        enumType="packType"
                        label={translate('productsOverview.labels.packType')}
                    />
                    <FormikEnumComboBox
                        name="unitDefault"
                        enumType="productUnit"
                        context="LUBES"
                        label={translate('productsOverview.labels.unitDefault')}
                    />
                </Box>

                <Button
                    emphasis="high"
                    type="submit"
                    size="large"
                    disabled={!formikContext.isValid}
                    isLoading={formikContext.isSubmitting}
                    width="100%"
                >
                    {translate('btn.save.default')}
                </Button>
                <FormikDebug />
            </Box>
        </Form>
    );
};
