import PropTypes from 'prop-types';

import { useFormContext } from '../../common/form-elements/Form/useFormContext';

export const FormCondition = ({ condition, children }) => {
    const { data } = useFormContext();
    return condition(data) ? children : null;
};

FormCondition.propTypes = {
    condition: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};
