import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Text } from '../../../../cdl/Text/Text';
import { TextButton } from '../../../../common/buttons/TextButton';
import { translate } from '../../../../common/helpers/translate.helper';
import { SettingsSection } from '../../../SettingsSection';

import { usePlattsCredentials } from './hooks/usePlattsCredentials';
import { PlattsCredentialsFormState } from './PlattsCredentialsForm';
import { PlattsCredentialsPopup } from './PlattsCredentialsPopup';

interface FuelCustomerIntegrationSettingsProps {
    customerId: string;
}

export const FuelCustomerIntegrationSettings = ({ customerId }: FuelCustomerIntegrationSettingsProps) => {
    const [showPopup, setShowPopup] = useState(false);
    const plattsCredentialsQuery = usePlattsCredentials(customerId);

    if (plattsCredentialsQuery.isPending) {
        return (
            <SettingsSection label={translate('integrationSettings.title')}>
                <Skeleton width="80%" height="16px" />
            </SettingsSection>
        );
    }

    if (plattsCredentialsQuery.isError) {
        return (
            <SettingsSection label={translate('integrationSettings.title')}>
                <Text variant="extraSmall" color="foreground.subtle">
                    {translate('global.genericError')}
                </Text>
            </SettingsSection>
        );
    }

    const plattsCredentialsFormState: PlattsCredentialsFormState = {
        customerId: customerId,
        username: plattsCredentialsQuery.data?.username || '',
        password: '',
        appKey: plattsCredentialsQuery.data?.appKey || '',
    };

    return (
        <>
            <SettingsSection label={translate('integrationSettings.title')}>
                <TextButton
                    onClick={() => {
                        setShowPopup(true);
                    }}
                >
                    {translate(
                        plattsCredentialsQuery.data
                            ? 'integrationSettings.platts.editBtn'
                            : 'integrationSettings.platts.enableBtn'
                    )}
                </TextButton>
            </SettingsSection>
            <PlattsCredentialsPopup
                data={plattsCredentialsFormState}
                isOpen={showPopup}
                credentialsExists={!!plattsCredentialsQuery.data}
                onDismiss={() => {
                    setShowPopup(false);
                }}
            />
        </>
    );
};
