import styled from 'styled-components';

import { useRole } from '../hooks/useRole';
import { get } from '../ui/get';

import { AdminSidebarNavigation } from './admin/AdminSidebarNavigation';
import { ContactUs } from './components/ContactUs';
import { CustomerSidebarNavigation } from './customer/CustomerSidebarNavigation';
import { SupplierSidebarNavigation } from './supplier/SupplierSidebarNavigation';

const Wrapper = styled.div`
    position: fixed;
    top: 64px;
    left: 0;
    bottom: 0;
    width: 220px;
    background: linear-gradient(90deg, #fff 0%, #f9f9fa 100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${get('space.6')}px ${get('space.5')}px;
`;

export const Sidebar = () => {
    const role = useRole();

    return (
        <Wrapper borderRight={1}>
            {role.isAdmin() ? <AdminSidebarNavigation /> : null}
            {role.isCustomer() ? <CustomerSidebarNavigation /> : null}
            {role.isSupplier() ? <SupplierSidebarNavigation /> : null}

            {role.isAdmin() ? null : <ContactUs />}
        </Wrapper>
    );
};
