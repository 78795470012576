import { Button } from '../../../cdl/Button/Button';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { translate } from '../../../common/helpers/translate.helper';

import { CreateAssignmentDrawer } from './CreateAssignmentDrawer';

export const CreateAssignmentButton = () => {
    const { push, pop } = useDrawer();

    const openDrawer = () => {
        push({
            content: <CreateAssignmentDrawer onSuccess={pop} />,
        });
    };

    return (
        <Button emphasis="high" onClick={openDrawer}>
            {translate('assignments.admin.invite')}
        </Button>
    );
};
