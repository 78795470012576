import { theme } from '../../ui/theme';

const fontFamily = 'Inter, sans-serif';
const borderRadius = '4px';

export const magicBellTheme = {
    unseenBadge: { backgroundColor: theme.colors.negative.emphasis },
    icon: {
        width: 'auto',
    },
    banner: {
        backgroundColor: theme.colors.accent.background,
        backgroundOpacity: 1,
    },
    header: {
        textColor: theme.colors.accent.foreground,
        borderRadius,
        fontFamily,
    },
    footer: {
        textColor: theme.colors.accent.foreground,
        borderRadius,
        fontFamily,
    },
    notification: {
        default: {
            backgroundColor: theme.colors.background.default,
            hover: {
                backgroundColor: theme.colors.background.subtle,
            },
            textColor: theme.colors.foreground.subtle,
            borderRadius,
            fontFamily,
        },
        unseen: {
            backgroundColor: theme.colors.background.inset,
            hover: {
                backgroundColor: theme.colors.background.disabled,
            },
            state: {
                color: theme.colors.negative.emphasis,
            },
            textColor: theme.colors.foreground.default,
            borderRadius,
            fontFamily,
        },
        unread: {
            backgroundColor: theme.colors.background.subtle,
            hover: {
                backgroundColor: theme.colors.background.inset,
            },
            textColor: theme.colors.foreground.default,
            state: {
                color: theme.colors.negative.emphasis,
            },
            borderRadius,
            fontFamily,
        },
    },
};
