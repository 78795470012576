import { useQuery } from '@tanstack/react-query';

import { queries } from '../../../common/api/queryKeys/queries';

interface UseLubesConsumptionPresetsConfig {
    vesselId: string;
}

export const useLubesConsumptionPresets = ({ vesselId }: UseLubesConsumptionPresetsConfig) => {
    return useQuery({
        ...queries.vessels.detail(vesselId)._ctx.lubesConsumptionPresets,
        retry: (_, error: any) => {
            return error.response.status !== 404;
        },
        refetchOnWindowFocus: false,
    });
};
