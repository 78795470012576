import axios from 'axios';

import AppSettings from '../../../constants';

import { addAuthenticationInterceptors } from './interceptors/addAuthenticationInterceptors';

const authorizedAxiosInstance = axios.create({
    baseURL: AppSettings.routes.api,
});

addAuthenticationInterceptors(authorizedAxiosInstance);

export { authorizedAxiosInstance };
