import { BunkerCaseSolutionResponse } from '../../../../../../types/BunkerCaseSolutionResponse';
import { Button } from '../../../../../cdl/Button/Button';
import { translate } from '../../../../../common/helpers/translate.helper';
import { useSolutionCsvDownload } from '../../../hooks/useSolutionCsvDownload';

interface SolutionCsvDownloadButtonProps {
    solution: BunkerCaseSolutionResponse;
}

export const SolutionCsvDownloadButton = (props: SolutionCsvDownloadButtonProps) => {
    const solutionCsvDownloadMutation = useSolutionCsvDownload(props.solution);

    const onClick = () => {
        solutionCsvDownloadMutation.mutate();
    };

    return (
        <Button emphasis="medium" onClick={onClick}>
            {translate('prePlanning.solutionDetails.downloadCsvButton')}
        </Button>
    );
};
