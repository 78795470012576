import { AxiosError } from 'axios';

import { PlattsCredentialsRequestMessage } from '../../../../types/PlattsCredentialsRequestMessage';
import { PlattsCredentialsResponseMessage } from '../../../../types/PlattsCredentialsResponseMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export enum PlattsCredentialsServerErrorCode {
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    INVALID_APP_KEY = 'INVALID_APP_KEY',
}

export const getPlattsCredentials = async (customerId?: string): Promise<PlattsCredentialsResponseMessage | null> => {
    try {
        const response = await authorizedAxiosInstance.get(`/v1/platts/credentials/${customerId}`);
        return response.data;
    } catch (error) {
        const err = error as AxiosError;
        if (err?.response?.status === 404) {
            return null;
        }
        throw error;
    }
};

export const createPlattsCredentials = async (
    credentials: PlattsCredentialsRequestMessage
): Promise<PlattsCredentialsResponseMessage> => {
    const response = await authorizedAxiosInstance.post(`/v1/platts/credentials`, credentials);

    return response.data;
};
export const updatePlattsCredentials = async (
    credentials: PlattsCredentialsRequestMessage
): Promise<PlattsCredentialsResponseMessage> => {
    const response = await authorizedAxiosInstance.put(`/v1/platts/credentials/${credentials.customerId}`, credentials);

    return response.data;
};

export interface RemovePlattsCredentialsParams {
    customerId: string;
}

export const removePlattsCredentials = async ({ customerId }: RemovePlattsCredentialsParams): Promise<void> => {
    await authorizedAxiosInstance.delete(`/v1/platts/credentials/${customerId}`);
};
