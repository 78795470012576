export const fuelTabToOfferStateMap = {
    enquired: ['ENQUIRY'],
    offered: ['QUOTED'],
    ordered: ['ORDER', 'CUSTOMER_ADJUSTED', 'SUPPLIER_ADJUSTED'],
    acknowledged: ['ACKNOWLEDGED'],
    confirmed: ['CONFIRMED'],
    delivered: ['DELIVERED', 'DELIVERY_CONFIRMED', 'INVOICED'],
    canceled: ['CANCELED', 'ENQUIRY_CANCELED', 'QUOTE_CANCELED', 'ENQUIRY_DECLINED'],
    closed: ['QUOTE_DECLINED', 'ENQUIRY_EXPIRED'],
};
