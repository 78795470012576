import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import { IconSearch } from '../../icons/cdl/Search';
import { TextInput } from '../TextInput/TextInput';

/**
 * @deprecated
 * Use the new CDL DebouncedSearchTextInput instead.
 */

export const DeprecatedDebouncedSearchTextInput = ({ onChange, value, placeholder }) => {
    const [searchInput, setSearchInput] = useState(value);

    const debouncedOnChange = useCallback(debounce(onChange, 300), []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateSearchInput = (event) => {
        setSearchInput(event.target.value);
        debouncedOnChange(event.target.value);
    };
    return (
        <TextInput
            style={{
                width: '250px',
            }}
            placeholder={placeholder}
            value={searchInput}
            onChange={updateSearchInput}
            icon={<IconSearch />}
        />
    );
};

DeprecatedDebouncedSearchTextInput.propTypes = {
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
