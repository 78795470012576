import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

import { get } from '../../common/ui/get';

export const DropdownMenuContent = styled(RadixDropdownMenu.Content)`
    padding: 8px 0;
    background: ${get('colors.background.emphasis')};
    min-width: 200px;
    border-radius: 4px;
    z-index: 9999;
`;
