import styled from 'styled-components';

import { Text } from '../cdl/Text/Text';
import { translate } from '../common/helpers/translate.helper';
import { useDocumentTitle } from '../common/hooks/useDocumentTitle';
import { useRole } from '../common/hooks/useRole';
import { Page } from '../common/Page/Page';
import { Box } from '../common/ui/Box';
import { get } from '../common/ui/get';

import { AdminStatistics } from './components/AdminStatistics';
import { CustomerStatistics } from './components/CustomerStatistics';
import { StatisticsActions } from './components/StatisticsActions';
import { StatisticsState } from './components/StatisticsState';
import { SupplierStatistics } from './components/SupplierStatistics';

const Header = styled.div`
    display: flex;
    gap: ${get('space.5')}px;
    justify-content: space-between;

    @media (max-width: ${get('breakpoints.3')}) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export const StatisticsPage = () => {
    const role = useRole();
    useDocumentTitle(translate('reporting.pagetitle'));

    return (
        <StatisticsState>
            <Page>
                <Box display="grid" rowGap={5}>
                    <Header>
                        <Text variant="headline">{translate('reporting.subtitle')}</Text>
                        <StatisticsActions />
                    </Header>

                    {role.isAdmin() ? <AdminStatistics /> : null}
                    {role.isCustomer() ? <CustomerStatistics /> : null}
                    {role.isSupplier() ? <SupplierStatistics /> : null}
                </Box>
            </Page>
        </StatisticsState>
    );
};
