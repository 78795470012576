import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconDiscount2 = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12.01 4.011a1.2 1.2 0 0 0-.85.354l-.003.002-.7.7-.002.002A3.2 3.2 0 0 1 8.201 6H7.2A1.2 1.2 0 0 0 6 7.2v1c0 .845-.335 1.656-.93 2.255l-.003.002-.7.7-.002.002a1.2 1.2 0 0 0 0 1.702l.002.002.702.702c.596.599.93 1.41.931 2.254v1.001a1.2 1.2 0 0 0 1.2 1.2h1c.845 0 1.656.335 2.255.93l.002.003.702.702a1.2 1.2 0 0 0 1.702 0l.002-.002.7-.7.002-.002a3.2 3.2 0 0 1 2.254-.931h1.001a1.2 1.2 0 0 0 1.2-1.2v-1c0-.845.335-1.656.93-2.255l.003-.002.7-.7.002-.002a1.2 1.2 0 0 0 0-1.702l-.002-.002-.7-.7-.002-.002a3.2 3.2 0 0 1-.931-2.254V7.2a1.2 1.2 0 0 0-1.2-1.2h-1a3.201 3.201 0 0 1-2.255-.93l-.002-.003-.7-.7-.002-.002a1.2 1.2 0 0 0-.851-.354Zm-1.229-1.754a3.2 3.2 0 0 1 3.497.697h.001l.696.697h.001A1.2 1.2 0 0 0 15.82 4h1a3.2 3.2 0 0 1 3.2 3.2v1c0 .316.125.62.348.844l.697.697.001.001a3.2 3.2 0 0 1 0 4.536v.001l-.697.696v.001a1.2 1.2 0 0 0-.349.844v1a3.2 3.2 0 0 1-3.2 3.2h-1a1.2 1.2 0 0 0-.844.348v.001l-.697.696-.001.001a3.2 3.2 0 0 1-4.536 0h-.001l-.696-.697a1.202 1.202 0 0 0-.846-.349H7.2a3.2 3.2 0 0 1-3.2-3.2v-1a1.2 1.2 0 0 0-.348-.844H3.65l-.696-.697-.001-.001a3.2 3.2 0 0 1 0-4.536v-.001l.697-.696v-.001A1.2 1.2 0 0 0 4 8.2v-1A3.2 3.2 0 0 1 7.2 4h1a1.2 1.2 0 0 0 .844-.348V3.65l.697-.696.001-.001a3.2 3.2 0 0 1 1.04-.697ZM9.5 10a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM8 9.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm6.293-1.207a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414-1.414l6-6ZM14.5 15a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-1.5-.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
            clipRule="evenodd"
        />
    </svg>
);
