import { PortMessage } from '../../../../types/PortMessage';
import { PortForm } from '../components/PortForm';

import { usePortEdit } from './usePortEdit';

interface EditPortFormProps {
    onSuccess: () => void;
    port: PortMessage;
}

export const EditPortForm = ({ onSuccess, port }: EditPortFormProps) => {
    const portEditMutation = usePortEdit();

    const initialValues = {
        id: port.id,
        name: port.name,
        locCode: port.locCode,
        latitude: port.latitude ? String(port.latitude) : '',
        longitude: port.longitude ? String(port.longitude) : '',
        timeZoneId: port.timeZoneId,
        countryId: port.country.id,
        aliases: port.aliases || [],
        locCodeAliases: port.locCodeAliases || [],
    };

    return (
        <PortForm
            onSubmit={(portMessage) => portEditMutation.mutateAsync({ id: port.id, port: portMessage })}
            onSuccess={onSuccess}
            initialValues={initialValues}
        />
    );
};
