import { ReactNode } from 'react';

import { FuelStockMessage } from '../../../../types/FuelStockMessage';
import { PortMessage } from '../../../../types/PortMessage';
import { Tooltip } from '../../../cdl/Tooltip/Tooltip';
import { Port } from '../../../port/Port';
import { formatDate } from '../../helpers/formatDate.helper';

interface FormattedLabelProps {
    supplyDate: string;
    supplyPort: PortMessage;
    vesselId: string;
}

const FormattedLabel = ({ supplyDate, supplyPort, vesselId }: FormattedLabelProps) => {
    const formattedDate = formatDate({ date: supplyDate!! });

    return (
        <span>
            <Port port={supplyPort!!} vesselId={vesselId} highlightColor="white" />
            <span> - </span>
            <span>{formattedDate}</span>
        </span>
    );
};

interface FuelStockTooltipProps {
    children: ReactNode;
    fuelStock: FuelStockMessage;
}

export const FuelStockTooltip = ({ children, fuelStock }: FuelStockTooltipProps): JSX.Element => {
    if (!fuelStock.supplyPort || !fuelStock.supplyDate) {
        return <>{children}</>;
    }

    return (
        <Tooltip
            label={
                <FormattedLabel
                    vesselId={fuelStock.vesselId}
                    supplyDate={fuelStock.supplyDate}
                    supplyPort={fuelStock.supplyPort}
                />
            }
            maxWidth="450px"
        >
            {children}
        </Tooltip>
    );
};
