import React, { ReactNode } from 'react';

import { Text } from '../../cdl/Text/Text';

interface GlobalPricesPortInformationLabelProps {
    children: ReactNode;
}

export const GlobalPricesPortInformationLabel = ({ children }: GlobalPricesPortInformationLabelProps) => {
    return <Text variant="small" fontWeight="semiBold" style={{ whiteSpace: 'nowrap' }}>{`${children}: `}</Text>;
};
