import { ContractMessage } from '../../../../types/ContractMessage';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { IconButton } from '../../../cdl/IconButton/IconButton';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../../common/helpers/translate.helper';
import { IconPencil } from '../../../common/icons/cdl/Pencil';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { EditContractForm } from '../../ContractForm/EditContractForm';
import { ContractTab } from '../contractTabsHelper';
import { SendContractButton } from '../SendContractButton/SendContractButton';
import { useContractDelete } from '../useContractDelete';

import { DeleteContractButton } from './DeleteContractButton';

interface ContractTableActionsProps {
    contract: ContractMessage;
}

export const ContractTableActions = ({ contract }: ContractTableActionsProps) => {
    const { push, pop } = useDrawer();
    const { addToast } = useToasts();
    const [, { setLocationFieldValue }] = useLocationState();

    const deleteContractMutation = useContractDelete();

    const openEditModal = (contract: ContractMessage) => {
        push({
            width: '60vw',
            content: (
                <EditContractForm
                    id={contract.id}
                    onSuccess={(updatedContract) => {
                        if (contract.tender.enabled && !updatedContract.tender.enabled) {
                            setLocationFieldValue('tab', ContractTab.CONTRACTED);
                        }
                        pop();
                    }}
                />
            ),
        });
    };

    const handleDelete = (contract: ContractMessage) => {
        deleteContractMutation.mutate(contract.id, {
            onSuccess: () => {
                if (contract.tender.enabled) {
                    addToast(translate('contracts.toast.deleteContract.successTender'));
                } else {
                    addToast(translate('contracts.toast.deleteContract.success'));
                }
            },
        });
    };

    return (
        <>
            {contract.tender.enabled ? <SendContractButton contract={contract} /> : null}
            <IconButton
                icon={IconPencil}
                onClick={() => openEditModal(contract)}
                tooltipLabel={translate(contract.tender.enabled ? 'contracts.editTitleTender' : 'contracts.editTitle')}
            />
            <DeleteContractButton contract={contract} handleDelete={handleDelete} />
        </>
    );
};
