import Skeleton from 'react-loading-skeleton';

import { Box } from '../../../../../../common/ui/Box';
import { OfferListItemGrid } from '../../../../common/OfferCard/OfferListItemGrid';
import { OfferSummaryContainer } from '../../../../common/OfferCard/OfferSummaryContainer';

import { OfferCardContainer } from './OfferCardContainer';
import { OfferSupplierAndTotalsGrid } from './OfferSupplierAndTotalsGrid';

export const OfferCardSkeleton = () => {
    return (
        <OfferCardContainer>
            <OfferListItemGrid>
                <OfferSummaryContainer>
                    <OfferSupplierAndTotalsGrid>
                        <Skeleton height={40} width={40} circle style={{ display: 'block', lineHeight: 1 }} />

                        <Box display="grid" gridTemplateRows="24px 16px" alignItems="center">
                            <Skeleton height={18} width="150px" style={{ display: 'block', lineHeight: 1 }} />
                            <Skeleton height={12} width="80px" style={{ display: 'block', lineHeight: 1 }} />
                        </Box>

                        <Box display="grid" gridTemplateRows="24px 16px" alignItems="center" justifyItems="end">
                            <Skeleton height={18} width="100px" style={{ display: 'block', lineHeight: 1 }} />
                            <Skeleton height={12} width="150px" style={{ display: 'block', lineHeight: 1 }} />
                        </Box>
                    </OfferSupplierAndTotalsGrid>

                    <Box
                        display="grid"
                        gridTemplateRows="repeat(3, 16px)"
                        alignItems="center"
                        rowGap={2}
                        paddingTop={4}
                    >
                        <Skeleton height={12} width="200px" style={{ display: 'block', lineHeight: 1 }} />
                        <Skeleton height={12} width="150px" style={{ display: 'block', lineHeight: 1 }} />
                        <Skeleton height={12} width="150px" style={{ display: 'block', lineHeight: 1 }} />
                    </Box>
                </OfferSummaryContainer>

                <Box
                    padding={5}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    borderLeftStyle="solid"
                    borderLeftWidth="1px"
                    borderLeftColor="border.muted"
                >
                    <Skeleton height={24} style={{ display: 'block', lineHeight: 1 }} />
                    <Skeleton height={32} style={{ display: 'block', lineHeight: 1 }} />
                </Box>
            </OfferListItemGrid>
        </OfferCardContainer>
    );
};
