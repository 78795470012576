import { useField, useFormikContext } from 'formik';

import { DeprecatedAgentSelectFlow } from '../../../agent/AgentSelectFlow/deprecated/DeprecatedAgentSelectFlow';
import { translate } from '../../helpers/translate.helper';
import { Box } from '../../ui/Box';
import { Label } from '../Label/Label';

/**
 * @deprecated - use FormikAgentSelect instead
 **/

export const DeprecatedFormikAgentSelect = () => {
    const [, , { setValue }] = useField('agentId');
    const { values } = useFormikContext();

    const onAgentSelect = (agentId) => {
        setValue(agentId);
    };

    return (
        <Box>
            <Label label={translate('fuelEnquiry.first.agentLabel')} />
            <Box marginTop={-2}>
                <DeprecatedAgentSelectFlow
                    agentId={values.agentId}
                    portId={values.portId}
                    onAgentSelect={onAgentSelect}
                    vesselId={values.vesselId}
                    customerId={values.customerId}
                />
            </Box>
        </Box>
    );
};
