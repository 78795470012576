import { Button } from '../../../../cdl/Button/Button';
import { useDrawer } from '../../../../cdl/Drawer/useDrawer.hook';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { translate } from '../../../../common/helpers/translate.helper';

import { CreatePortForm } from './CreatePortForm';

export const CreatePortButton = () => {
    const { push, pop } = useDrawer();
    const { addToast } = useToasts();

    const onModalClose = () => {
        pop();
        addToast(translate('port.createPortSuccessToast'));
    };

    const openPortCreateModal = () =>
        push({
            content: <CreatePortForm onSuccess={onModalClose} />,
        });

    return (
        <Button emphasis="high" onClick={openPortCreateModal}>
            {translate('port.createTitle')}
        </Button>
    );
};
