import PropTypes from 'prop-types';
import { components } from 'react-select';
import styled from 'styled-components';

import { CL_DARK_BLUE_40 } from '../colors';
import { Select } from '../form-elements/Select/Select';
import { translate } from '../helpers/translate.helper';

const StyledSubSingleValue = styled.span`
    font-size: 12px;
    margin: 0 5px 0 0;
    color: ${CL_DARK_BLUE_40};
`;

const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
        <StyledSubSingleValue>{translate('order.sortedBy')}</StyledSubSingleValue>
        {children}
    </components.SingleValue>
);

SingleValue.propTypes = {
    children: PropTypes.node,
};

const StyledSelect = styled(Select)`
    width: 230px;
    font-size: 12px;
`;

/**
 * @deprecated use new SortSelect.tsx instead
 **/

export const DeprecatedSortSelect = (props) => {
    return (
        <StyledSelect
            value={props.value}
            defaultValue={props.defaultValue}
            options={props.options}
            onChange={props.onChange}
            components={{ SingleValue }}
        />
    );
};

DeprecatedSortSelect.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.object,
    defaultValue: PropTypes.object,
};
