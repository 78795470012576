import { LubesProductGroup } from '../../../types/LubesProductGroup';
import { findDefaultUnitSize } from '../helpers/calculate.utilities';

function Item(item) {
    this.deleted = false;
    if (item) {
        if (!item.unit) this.unit = 'L';

        Object.assign(this, item);
    } else {
        this.pack = 'BULK';
        this.unit = 'L';
    }
}

Item.prototype.isComplete = function () {
    return this.units && this.unitSize && this.packType && this.productId;
};

Item.prototype.isCompleteWithPrices = function () {
    return this.isComplete() && this.price;
};

function calculateItemVolume(item) {
    return Math.round((item.unitSize * item.units + Number.EPSILON) * 1000) / 1000;
}

function calculateItemTotal(item) {
    if (!item?.price?.value) {
        return undefined;
    }
    return {
        value: Math.round((item.price.value * calculateItemVolume(item) + Number.EPSILON) * 100) / 100,
        currency: item.price.currency,
    };
}

Item.prototype.compute = function () {
    if (this.units && this.unitSize && this.packType) {
        this.volume = calculateItemVolume(this);
        this.total = calculateItemTotal(this);
    } else {
        this.volume = undefined;
        this.total = undefined;
    }
};

Item.prototype.initWithProduct = function (product) {
    const specialProductGroups = [LubesProductGroup.SAMPLE_ANALYSIS, LubesProductGroup.TEST_KIT];

    if (product) {
        if (product.unitDefault) {
            this.unit = product.unitDefault;
        }

        const oldProductGroupIsSpecial = specialProductGroups.includes(this.group);
        const newProductGroupIsSpecial = specialProductGroups.includes(product.group);
        const isChangeFromOrToSpecialProductGroup =
            (oldProductGroupIsSpecial && !newProductGroupIsSpecial) ||
            (!oldProductGroupIsSpecial && newProductGroupIsSpecial);

        // Only apply default pack type if we change from or to a special product group or if there are no units
        if ((!this.units || isChangeFromOrToSpecialProductGroup) && product.packTypeDefault) {
            this.pack = product.packTypeDefault;
        }

        if (product.group) {
            this.group = product.group;
        }
    }
};

Item.prototype.updateWithItem = function (item) {
    this.pack = item.packType;
    this.unit = item.unit;
    this.group = item.group;
    this.price = item.price;
    this.units = item.units;
    this.unitSize = item.unitSize;
    this.productId = item.productId;
    this.product = item.product;
};

Item.prototype.isTranslationMissing = function () {
    return this.translationFailed && !this.productId;
};

Object.defineProperty(Item.prototype, 'pack', {
    get: function () {
        return this.packType;
    },
    set: function (val) {
        this.packType = val;
        const oldUnitSize = this.unitSize;
        this.unitSize = findDefaultUnitSize(this.packType);
        if (this.units) this.units = Math.ceil((this.units * oldUnitSize) / this.unitSize);
    },
});

function ItemFactory() {
    return Item;
}

export default {
    name: 'Item',
    fn: ItemFactory,
};

export { Item };
