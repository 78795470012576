import PropTypes from 'prop-types';

import { IconButton } from '../../../cdl/IconButton/IconButton';
import { translate } from '../../../common/helpers/translate.helper';
import { IconEye } from '../../../common/icons/cdl/Eye';
import { IconEyeOff } from '../../../common/icons/cdl/EyeOff';
import { Box } from '../../../common/ui/Box';

import { useOfferMarkRead } from './useOfferMarkRead';

export const OfferActions = ({ offer }) => {
    const { mutate: markRead } = useOfferMarkRead();

    const onMarkRead = (offer) => {
        markRead({ offer, offerId: offer.id, read: !offer.supplierRead });
    };

    const label = offer.supplierRead
        ? translate('actionListLabel.markAsUnread')
        : translate('actionListLabel.markAsRead');

    return (
        <Box>
            <IconButton
                icon={offer.supplierRead ? IconEyeOff : IconEye}
                onClick={() => onMarkRead(offer)}
                tooltipLabel={label}
                aria-label={label}
            />
        </Box>
    );
};

OfferActions.propTypes = {
    offer: PropTypes.object.isRequired,
};
