import { useField } from 'formik';

import { TextArea, TextAreaProps } from './TextArea';

interface FormikTextAreaProps extends TextAreaProps {
    name: string;
}

export const FormikTextArea = (props: FormikTextAreaProps) => {
    const [field, meta] = useField(props.name);

    return (
        <TextArea
            caption={meta.touched && meta.error && typeof meta.error === 'string' ? meta.error : props.caption}
            negative={meta.touched && !!meta.error}
            {...props}
            {...field}
        />
    );
};
