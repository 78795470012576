import { AgentGatewayMessage } from '../../../../types/AgentMessage';
import { DataSheet } from '../../../common/DataSheet/DataSheet';
import { formatAddress } from '../../../common/helpers/formatAddress.helper';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { Port } from '../../../port/Port';

interface AgentDetailsDataSheetProps {
    agent: AgentGatewayMessage;
}

export const AgentDetailsDataSheet = ({ agent }: AgentDetailsDataSheetProps) => {
    const role = useRole();
    const { context } = useProductContext();
    const addressAvailable =
        agent.address?.companyName || agent.address?.street || agent.address?.city || agent.address?.country;

    return (
        <DataSheet>
            {!role.isOneCompanyUser(context) ? (
                <DataSheet.Row label={translate('agents.company')} value={agent.customer.name} />
            ) : null}

            {agent.ports.length ? (
                <>
                    <DataSheet.Label>{translate('agents.ports')}</DataSheet.Label>
                    <div>
                        {agent.ports.map((port) => (
                            <Port key={port.id} port={port} />
                        ))}
                    </div>
                </>
            ) : null}
            {addressAvailable ? (
                <DataSheet.Row
                    label={translate('agents.detail.address')}
                    value={formatAddress({ address: agent.address })}
                />
            ) : null}
            <DataSheet.Row label={translate('agents.detail.note')} value={agent.note} />
            <DataSheet.Row
                label={translate('agents.detail.lastEdit')}
                value={formatDateTime({ date: agent.dateUpdated })}
            />
            <DataSheet.Row
                label={translate('agents.detail.dateCreated')}
                value={formatDateTime({ date: agent.dateCreated })}
            />
        </DataSheet>
    );
};
