import styled from 'styled-components';

import { get } from '../../../../common/ui/get';

export const Line = styled('div')({
    position: 'absolute',
    borderTop: '4px solid',
    borderColor: get('colors.border.subtle'),
    top: '14px',
    right: 0,
    left: 0,
});
