import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { CustomerGroupsOverviewPage } from './CustomerGroupsOverviewPage';

const CustomerGroupsOverviewPageReactComponent = (props: any) => {
    return (
        <AppProviders>
            <CustomerGroupsOverviewPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(
    CustomerGroupsOverviewPageReactComponent,
    'reactCustomerGroupsOverviewPage'
);
