import { Form, useFormikContext } from 'formik';

import { Button } from '../../../../cdl/Button/Button';
import { Text } from '../../../../cdl/Text/Text';
import { FormikCustomerCompanySelect } from '../../../../common/form-elements/formik/FormikCustomerCompanySelect';
import { FormikDebug } from '../../../../common/form-elements/formik/FormikDebug';
import { FormikEnumOptionSelect } from '../../../../common/form-elements/formik/FormikEnumOptionSelect';
import { translate } from '../../../../common/helpers/translate.helper';
import { FormikMoneyInput } from '../../../../common/MoneyInput/FormikMoneyInput';
import { Box } from '../../../../common/ui/Box';

export const FormikCreateSurchargeForm = () => {
    const { isSubmitting } = useFormikContext();
    return (
        <Form style={{ height: '100%' }}>
            <Box
                display="grid"
                gridTemplateRows="auto 1fr auto"
                height="100%"
                gridTemplateColumns="100%"
                rowGap={6}
                padding={7}
            >
                <Text variant="title">{translate('surcharges.create')}</Text>
                <Box display="flex" flexDirection="column" gap={5}>
                    <FormikEnumOptionSelect
                        name="packType"
                        enumType="packType"
                        label={translate('surcharges.formLabels.packtype')}
                    />
                    <FormikEnumOptionSelect
                        name="productGroup"
                        enumType="productGroup"
                        label={translate('surcharges.formLabels.productGroup')}
                    />
                    <FormikCustomerCompanySelect
                        name="customerId"
                        label={translate('surcharges.formLabels.customer')}
                    />
                    <FormikMoneyInput name="value" decimals={4} label={translate('surcharges.formLabels.value')} />

                    <FormikDebug />
                </Box>
                <Button type="submit" emphasis="high" size="large" isLoading={isSubmitting}>
                    {translate('surcharges.createbtn')}
                </Button>
            </Box>
        </Form>
    );
};
