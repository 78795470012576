import { AblyService } from './common/services/AblyService';
import { injectNgDeps } from './ngDeps';
import { registerScrollPreventionEventListener } from './registerScrollPreventionEventListener';

Run.$inject = [
    '$stateParams',
    '$rootScope',
    '$location',
    '$state',
    '$window',
    '$translate',
    '$timeout',
    'SessionService',
    'RoleService',
    'PopupService',
    'SessionStorageService',
];

function Run(
    $stateParams,
    $rootScope,
    $location,
    $state,
    $window,
    $translate,
    $timeout,
    SessionService,
    RoleService,
    PopupService,
    SessionStorageService
) {
    injectNgDeps({
        $translate,
        $stateParams,
        RoleService,
        $state,
        $rootScope,
        SessionService,
        SessionStorageService,
    });

    $rootScope.$on('$stateChangeStart', function (event, toState, stateParams) {
        if (!SessionService.isAuthenticated() && !toState.noAuthRequired) {
            event.preventDefault();
            $state.go('login', {
                redirect: {
                    state: toState.name,
                    params: angular.copy(stateParams),
                },
            });
        }

        if (
            toState.allowedContexts &&
            RoleService.get() &&
            !RoleService.get().isAdmin() &&
            !toState.allowedContexts.includes(RoleService.get().context)
        ) {
            RoleService.updateContext(toState.allowedContexts[0]);
        }
    });

    $rootScope.$on('$stateChangeSuccess', () => {
        if (!$window.ga) return;
        $window.ga('send', 'pageview', {
            page: $location.path(),
        });
    });

    $rootScope.$on('loginSuccess', function () {
        if (!RoleService.get().hasTermsAndConditionsAccepted()) {
            PopupService.createAcceptTermsModal();
        }
    });

    $rootScope.$on('logoutSuccess', function () {
        AblyService.disconnect();
    });

    const wrap = function (method) {
        const orig = $window.window.history[method];
        $window.window.history[method] = function () {
            const retval = orig.apply(this, Array.prototype.slice.call(arguments));
            $timeout(function () {
                $window.scrollTo(0, 0);
            }, 0);

            return retval;
        };
    };
    wrap('pushState');
    wrap('replaceState');

    SessionService.loadRoleInformation();

    registerScrollPreventionEventListener();
}

export default Run;
