import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateLubesConsumptionPresets } from '../../../common/api/clients/vessel.api';
import { queries } from '../../../common/api/queryKeys/queries';

export const useVesselConsumptionPresetsMutation = (vesselId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateLubesConsumptionPresets,
        onSuccess: () => {
            queryClient.invalidateQueries(queries.vessels.detail(vesselId)._ctx.lubesConsumptionPresets);
        },
    });
};
