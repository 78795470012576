import { useField } from 'formik';

import { PortDetailSelect, PortOption, PortSelectProps } from './PortDetailSelect';

interface FormikPortSelectProps extends Omit<PortSelectProps, 'onChange' | 'value'> {
    name: string;
}

export const FormikPortDetailSelect = (props: FormikPortSelectProps) => {
    const [field, meta, helpers] = useField(props.name);

    const onChange = (option: PortOption) => {
        helpers.setValue(option.value);
    };

    return (
        <PortDetailSelect {...props} value={field.value} onChange={onChange} negative={meta.touched && !!meta.error} />
    );
};
