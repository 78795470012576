import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { getSuppliers } from '../../api/clients/company.api';
import { formatCompanyName } from '../../helpers/formatCompanyName.helper';
import { useProductContext } from '../../hooks/useProductContext';
import { useRole } from '../../hooks/useRole';
import { AsyncSearchSelect } from '../AsyncSearchSelect/AsyncSearchSelect';

export const LegacySupplierCompanyMultiSelect = ({
    onChange,
    placeholder,
    companyIds,
    contextAware = true,
    groupId,
}) => {
    const role = useRole();
    const { context } = useProductContext();
    const [selectedValue, setSelectedValue] = useState([]);

    useEffect(() => {
        let isMounted = true;

        if (companyIds?.length) {
            getSuppliers({
                ids: companyIds,
                type: contextAware ? context : undefined,
            })
                .then((response) => {
                    return response.map((company) => ({
                        value: company.id,
                        label: formatCompanyName({
                            company,
                            includeType: role.isAdmin(),
                        }),
                    }));
                })
                .then((options) => {
                    if (isMounted) setSelectedValue(options);
                });
        } else {
            setSelectedValue([]);
        }

        return () => {
            isMounted = false;
        };
    }, [companyIds, context, contextAware, role]);

    const loadCompaniesByInput = (searchQuery) => {
        return new Promise((resolve) => {
            getSuppliers({
                searchQuery,
                groupId: groupId,
                type: contextAware ? context : undefined,
            })
                .then((response) =>
                    response.map((company) => ({
                        value: company.id,
                        label: formatCompanyName({
                            company,
                            includeType: role.isAdmin(),
                        }),
                    }))
                )
                .then((companies) => resolve(companies));
        });
    };

    function updateSelectedValue(options) {
        onChange(options.map((it) => it.value));

        if (options.length <= 0) {
            setSelectedValue([]);
        }
    }

    return (
        <AsyncSearchSelect
            values={selectedValue}
            onChange={updateSelectedValue}
            loadOptions={loadCompaniesByInput}
            placeholder={placeholder}
            isMulti
            // Hack to trigger fetching of new options
            rerenderValue={JSON.stringify(groupId)}
        />
    );
};

LegacySupplierCompanyMultiSelect.propTypes = {
    onChange: PropTypes.func,
    companyIds: PropTypes.array,
    placeholder: PropTypes.string,
    contextAware: PropTypes.bool,
    groupId: PropTypes.string,
};
