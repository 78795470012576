import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '../../common/buttons/Button';
import { theme } from '../../common/ui/theme';

const hoverStyles = (props) => {
    if (props.color === 'solid-clear') {
        return {
            ':hover': {
                backgroundColor: theme.colors['clear-blue'][0],
            },
        };
    }

    if (props.color === 'outline-dark') {
        return {
            ':hover': {
                color: theme.colors['dark-blue'][0],
                borderColor: theme.colors['dark-blue'][3],
                backgroundColor: 'transparent',
            },
            ':active': {
                borderColor: theme.colors['dark-blue'][3],
                backgroundColor: 'transparent',
            },
        };
    }
};

const SelectOptimizedButton = styled(Button).attrs({
    forwardedAs: 'span',
})`
    user-select: none;
    ${hoverStyles}
`;

const ButtonSelectBox = (props) => {
    const { isSelected, children, ...rest } = props;

    return (
        <SelectOptimizedButton {...rest} color={isSelected ? 'solid-clear' : 'outline-dark'}>
            {children}
        </SelectOptimizedButton>
    );
};

ButtonSelectBox.propTypes = {
    children: PropTypes.node,
    isSelected: PropTypes.bool,
};

export { ButtonSelectBox };
