import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../helpers/translate.helper';
import { LiteOverlayProps } from '../LiteOverlay';

export const LiteInformationText = ({ variant }: { variant: LiteOverlayProps['variant'] }) => {
    switch (variant) {
        case 'small':
            return (
                <Text variant="extraSmall" textAlign="center">
                    {translate('closelinkLite.overlay.descriptionShort')}
                </Text>
            );
        case 'medium':
            return (
                <Text variant="body" textAlign="center" style={{ whiteSpace: 'pre-wrap' }}>
                    {translate('closelinkLite.overlay.descriptionLong')}
                </Text>
            );
        case 'wide':
            return (
                <Text variant="small" textAlign="left" style={{ whiteSpace: 'pre-wrap' }}>
                    {translate('closelinkLite.overlay.descriptionLong')}
                </Text>
            );
    }
};
