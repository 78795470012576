import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';

import { IconButton } from '../../cdl/IconButton/IconButton';
import { translate } from '../../common/helpers/translate.helper';
import { IconArchive } from '../../common/icons/cdl/Archive';
import { ArchiveAgentConfirmPopup } from '../detail/components/ArchiveAgentConfirmPopup';

export const ArchiveAgentPopupButton = ({ onConfirm, name }) => {
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);

    const handleOnConfirm = () => {
        setShowConfirmPopup(false);
        onConfirm();
    };

    const handleOnClick = (e) => {
        setShowConfirmPopup(true);
        e.stopPropagation();
    };

    return (
        <Fragment>
            <IconButton
                icon={IconArchive}
                onClick={handleOnClick}
                tooltipLabel={translate('agents.archiveButton.headline')}
                aria-label="Archive"
            />
            <ArchiveAgentConfirmPopup
                isOpen={showConfirmPopup}
                onSubmit={handleOnConfirm}
                onDismiss={() => setShowConfirmPopup(false)}
                name={name}
            />
        </Fragment>
    );
};

ArchiveAgentPopupButton.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
};
