import { EmptyPagePlaceholder } from '../../../common/EmptyPagePlaceholder/EmptyPagePlaceholder';
import { translate } from '../../../common/helpers/translate.helper';
import { IconFilter } from '../../../common/icons/cdl/Filter';
import { Flex } from '../../../common/ui/Flex';
import { theme } from '../../../common/ui/theme';

export const AssignmentsEmptyState = () => {
    return (
        <Flex justifyContent="center" paddingTop="124px">
            <EmptyPagePlaceholder
                title={translate('assignments.noAssignmnents')}
                description={translate('assignments.tryDifferentFilter')}
                icon={<IconFilter height={24} width={24} color={theme.colors.foreground.subtle} />}
            />
        </Flex>
    );
};
