import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { DeprecatedFormikAgentSelect } from '../../common/form-elements/formik/DeprecatedFormikAgentSelect';
import { DeprecatedFormikPortSelectWithSchedules } from '../../common/form-elements/formik/DeprecatedFormikPortSelectWithSchedules';
import { FormikDateTimePicker } from '../../common/form-elements/formik/FormikDateTimePicker';
import { FormikDebug } from '../../common/form-elements/formik/FormikDebug';
import { FormikVesselSelect } from '../../common/form-elements/formik/FormikVesselSelect';
import { formatDateTime } from '../../common/helpers/formatDateTime.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useSchedules } from '../../common/hooks/useSchedules';
import { useVesselDetails } from '../../common/hooks/useVesselDetails';
import { Box } from '../../common/ui/Box';
import { CardVariation } from '../../common/ui/CardVariation';
import { Flex } from '../../common/ui/Flex';
import { Grid, GridItem } from '../../common/ui/Grid';

import { FormikBuyerReferenceInput } from './FormikBuyerReferenceInput';
import { useAgentPrefetching } from './hooks/useAgentPrefetching';
import { useContractProductsPrefetching } from './hooks/useContractProductsPrefetching';
import { useEnquiryCreation } from './hooks/useEnquiryCreation';
import { useFuelStocksPrefetching } from './hooks/useFuelStocksPrefetching';
import { usePlattsCredentialsPrefetching } from './hooks/usePlattsCredentialsPrefetching';
import { usePortPrefetching } from './hooks/usePortPrefetching';
import { useVesselDetailsPrefetching } from './hooks/useVesselDetailsPrefetching';
import { NextButton } from './NextButton';
import { SaveAsDraftButton } from './SaveAsDraftButton';

const formatDateWithTimeZone = (date, timeZoneId) => {
    return formatDateTime({
        date,
        timeZoneId,
        format: 'YYYY-MM-DDTHH:mm',
    });
};

export const FirstStepForm = ({ dirty, isValid, errors, handleSubmit, values, setFieldValue, setValues }) => {
    const { setPage } = useEnquiryCreation();
    useFuelStocksPrefetching('vesselId');
    useVesselDetailsPrefetching('vesselId');
    useContractProductsPrefetching();
    useAgentPrefetching('agentId');
    usePortPrefetching('portId');
    usePlattsCredentialsPrefetching('customerId');

    const { schedules } = useSchedules(values.imo);

    const vesselQuery = useVesselDetails(values.vesselId);
    const vessel = vesselQuery.data;

    useEffect(() => {
        if (!vessel) {
            return;
        }

        setFieldValue('imo', vessel.imo, false);
        setFieldValue('testOrder', vessel.customer.testCompany, false);
    }, [vessel, setFieldValue]);

    const setPortIdAndDependantValues = (port) => {
        const newValues = {
            ...values,
            portId: port.id,
            timeZoneId: port.timeZoneId,
            agentId: null,
            scheduleId: null,
        };

        if (values.contractId) {
            newValues.contractId = null;
            newValues.items = [
                {
                    productName: null,
                    productId: null,
                    units: '',
                    unit: null,
                    sulphurContent: null,
                    group: null,
                    packType: null,
                    unitSize: null,
                },
            ];
        }

        if (!schedules || !schedules.length) {
            setValues(newValues);
            return;
        }

        const matchingSchedule = schedules.find((schedule) => schedule.port?.id === port.id);

        if (!matchingSchedule) {
            setValues(newValues);
            return;
        }

        const etaFromSchedule = formatDateWithTimeZone(matchingSchedule.eta, matchingSchedule.timeZoneId);

        const etdFromSchedule = formatDateWithTimeZone(matchingSchedule.etd, matchingSchedule.timeZoneId);

        newValues.eta = etaFromSchedule;
        newValues.etd = etdFromSchedule;
        newValues.scheduleId = matchingSchedule.id;

        if (matchingSchedule.agentIds?.length === 1) {
            newValues.agentId = matchingSchedule.agentIds[0];
        }

        setValues(newValues);
    };

    const resetScheduleId = () => {
        setFieldValue('scheduleId', null);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box display="grid" gap={6}>
                <CardVariation>
                    <Grid>
                        <GridItem width={1 / 2}>
                            <FormikVesselSelect
                                required
                                name="vesselId"
                                onVesselSelect={(vessel) => {
                                    setFieldValue('imo', vessel.imo, false);
                                    setFieldValue('customerId', vessel.customerId, false);
                                    setFieldValue('scheduleId', null, false);
                                }}
                            />
                        </GridItem>

                        <GridItem width={1 / 2}>
                            <DeprecatedFormikPortSelectWithSchedules
                                required
                                name="portId"
                                schedules={schedules}
                                onPortSelect={(port) => setPortIdAndDependantValues(port)}
                            />
                        </GridItem>
                    </Grid>
                </CardVariation>

                <CardVariation>
                    <Grid>
                        <GridItem width={1 / 2}>
                            <FormikDateTimePicker
                                label={translate('fuelEnquiry.first.etaLabel')}
                                required
                                name="eta"
                                onChange={resetScheduleId}
                            />
                        </GridItem>

                        <GridItem width={1 / 2}>
                            <FormikDateTimePicker
                                label={translate('fuelEnquiry.first.etdLabel')}
                                required
                                name="etd"
                                onChange={resetScheduleId}
                            />
                        </GridItem>

                        <GridItem width={1 / 2}>
                            <DeprecatedFormikAgentSelect />
                        </GridItem>

                        <GridItem width={1 / 2}>
                            <FormikBuyerReferenceInput
                                name="buyerReference"
                                customerId={values.customerId}
                                orderId={values.orderId}
                                type="FUEL"
                            />
                        </GridItem>
                    </Grid>

                    <Flex justifyContent="center" marginTop={8}>
                        <ButtonGroup>
                            <SaveAsDraftButton disabled={!values.customerId || !!errors.buyerReference} />
                            <NextButton onClick={() => setPage(2)} disabled={!dirty || !isValid} />
                        </ButtonGroup>
                    </Flex>
                </CardVariation>

                <FormikDebug />
            </Box>
        </form>
    );
};

FirstStepForm.propTypes = {
    dirty: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
};
