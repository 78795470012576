import * as Popover from '@radix-ui/react-popover';
import { config, useTransition } from '@react-spring/web';
import { DatePickerStateProvider } from '@rehookify/datepicker';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';

import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';
import { Button } from '../Button/Button';

import { CalendarActions } from './components/CalendarActions';
import { CalendarContainer } from './components/CalendarContainer';
import { CalendarMonths } from './components/CalendarMonths';
import { CalendarPresets } from './components/CalendarPresets';
import { CustomDateRangeInput } from './components/CustomDateRangeInput';
import { DividerVertical } from './components/DividerVertical';
import { CalendarRangePreset } from './types/CalendarRangePreset';

interface DateRangePickerProps {
    label?: string;
    presets?: CalendarRangePreset[];
    dates?: Date[];
    onDatesChange?: (dates: Date[]) => void;
}

const todayStartOfDay: Date = moment().startOf('day').toDate();

export const DateRangePicker = (props: DateRangePickerProps) => {
    const [internalSelectedDates, setInternalSelectedDates] = useState<Date[]>(props.dates ?? []);
    const [internalOffsetDate, setInternalOffsetDate] = useState<Date>(new Date());

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const delayedDateReset = setTimeout(() => {
            if (!open) {
                setInternalSelectedDates(props.dates ?? []);
            }
        }, 75);

        return () => {
            clearTimeout(delayedDateReset);
        };
    }, [open, props.dates]);

    const handleConfirmButtonClick = () => {
        props.onDatesChange?.(internalSelectedDates);
    };

    const transitions = useTransition(open, {
        from: { opacity: 0, scale: 0.98 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 0.98 },
        config: {
            ...config.default,
            tension: 600,
            clamp: true,
        },
    });

    return (
        <DatePickerStateProvider
            config={{
                selectedDates: internalSelectedDates,
                onDatesChange: setInternalSelectedDates,
                offsetDate: internalOffsetDate,
                onOffsetChange: setInternalOffsetDate,
                dates: {
                    mode: 'range',
                    toggle: false,
                    selectSameDate: true,
                },
                calendar: {
                    offsets: [0, 1],
                    startDay: 1,
                    mode: 'static',
                },
                locale: {
                    day: 'numeric',
                },
            }}
        >
            <Popover.Root open={open} onOpenChange={setOpen}>
                <Popover.Trigger asChild>
                    <div>
                        <CustomDateRangeInput $isFocused={open} label={props.label} />
                    </div>
                </Popover.Trigger>
                <Popover.Portal forceMount>
                    {transitions((styles, item) =>
                        item ? (
                            <Popover.Content
                                asChild
                                align="start"
                                side="bottom"
                                sideOffset={12}
                                collisionPadding={12}
                                forceMount
                            >
                                <CalendarContainer style={styles}>
                                    <Box display="flex" flexDirection="row">
                                        {props.presets ? (
                                            <>
                                                <CalendarPresets
                                                    presets={props.presets}
                                                    setInternalSelectedDates={setInternalSelectedDates}
                                                />
                                                <DividerVertical />
                                            </>
                                        ) : null}
                                        <div>
                                            <CalendarMonths />
                                            <CalendarActions
                                                onTodayClick={() => {
                                                    setInternalSelectedDates([todayStartOfDay]);
                                                    setInternalOffsetDate(todayStartOfDay);
                                                }}
                                            >
                                                <Popover.Close asChild>
                                                    <Button size="large">
                                                        {translate('global.datePicker.cancel')}
                                                    </Button>
                                                </Popover.Close>
                                                <Popover.Close asChild>
                                                    <Button
                                                        emphasis="high"
                                                        size="large"
                                                        onClick={handleConfirmButtonClick}
                                                    >
                                                        {translate('global.datePicker.setDateRange')}
                                                    </Button>
                                                </Popover.Close>
                                            </CalendarActions>
                                        </div>
                                    </Box>
                                </CalendarContainer>
                            </Popover.Content>
                        ) : null
                    )}
                </Popover.Portal>
            </Popover.Root>
        </DatePickerStateProvider>
    );
};
