import { useField } from 'formik';
import PropTypes from 'prop-types';

import { FormikFuelMoneyInput } from '../../form-elements/formik/FormikFuelMoneyInput';
import { formatFuelMoney } from '../../helpers/formatFuelMoney.helper';
import { translate } from '../../helpers/translate.helper';

export const PriceDisplay = (props) => {
    const [field] = useField(props.name);
    const item = field.value;
    const editable = props.editable ?? true;

    if (!item.price && (!editable || item.deleted)) {
        return '-';
    }

    if (item.deleted || !editable) {
        return formatFuelMoney({
            value: item.price.value,
            currency: item.price.currency,
        });
    }

    return (
        <FormikFuelMoneyInput
            placeholder={translate('offer.quotationProcess.fuel.pricePlaceholder')}
            name={`${props.name}.price`}
            required
        />
    );
};

PriceDisplay.propTypes = {
    name: PropTypes.string.isRequired,
    editable: PropTypes.bool,
};
