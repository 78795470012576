import { ReachInformationCategory } from '../../../../types/ReachInformationMessage';
import { Text } from '../../../cdl/Text/Text';
import { Tooltip } from '../../../cdl/Tooltip/Tooltip';
import { formatNumber } from '../../helpers/formatNumber.helper';
import { translate } from '../../helpers/translate.helper';

interface DaysLeftSailingProps {
    reachInformation: ReachInformationCategory;
}

export const DaysLeftSailing = ({ reachInformation }: DaysLeftSailingProps) => {
    const averageConsumption = formatNumber({
        number: reachInformation.averageDailyConsumption,
        maximumFractionDigits: 4,
    });

    return (
        <Tooltip label={translate('schedule.priceCompare.basedOnAverageConsumptionFuel', { averageConsumption })}>
            <Text variant="small">
                &ensp;&middot;&ensp;
                {reachInformation.daysLeft === 1
                    ? translate('schedule.priceCompare.oneDayLeftSailing')
                    : translate('schedule.priceCompare.daysLeftSailing', {
                          daysLeft: formatNumber({
                              number: reachInformation.daysLeft,
                              maximumFractionDigits: 0,
                          }),
                      })}
            </Text>
        </Tooltip>
    );
};
