import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { Error } from '../../../common/Error/Error';
import { translate } from '../../../common/helpers/translate.helper';
import { useAngularRouterLocation } from '../../../common/hooks/useAngularRouterLocation';
import { Box } from '../../../common/ui/Box';
import { useStateSet } from '../common/useStateSet';

import { EmptyState } from './EmptyState';
import { OfferListItem } from './OfferListItem';
import { OfferListItemSkeleton } from './OfferListItemSkeleton';
import { useFuelOrderOffers } from './useFuelOrderOffers';

export const OfferList = ({ order }) => {
    const offerListItemRef = useRef(null);
    const offerIdSetState = useStateSet();
    const expandedOfferIds = offerIdSetState.value;
    const [openedThroughNotification, setOpenedThroughNotification] = useState(false);
    const { offerId: paramOfferId, timestamp } = useAngularRouterLocation();

    const orderOffersQuery = useFuelOrderOffers(order);

    useEffect(() => {
        if (paramOfferId) {
            offerIdSetState.replace([paramOfferId]);
            setOpenedThroughNotification(true);
        }
    }, [timestamp, offerIdSetState, paramOfferId]);

    useEffect(() => {
        if (!openedThroughNotification || !offerListItemRef.current) {
            return;
        }

        const scrollOffset = -75;
        const scrollHeight = offerListItemRef.current.getBoundingClientRect().top + window.scrollY + scrollOffset;

        window.scrollTo({ top: scrollHeight, behavior: 'smooth' });
    }, [expandedOfferIds.size, openedThroughNotification]);

    if (orderOffersQuery.isPending) {
        return (
            <Box>
                {order.receiverSupplierIds.map((id) => {
                    return <OfferListItemSkeleton key={id} />;
                })}

                {order.spot ? <EmptyState>{translate('fuelOrder.offerList.spot')}</EmptyState> : null}
            </Box>
        );
    }

    if (orderOffersQuery.isError) {
        return (
            <EmptyState>
                <Error />
            </EmptyState>
        );
    }

    if (!orderOffersQuery.data.length) {
        return <EmptyState>{translate('fuelOrder.offerList.waitingForOffers')}</EmptyState>;
    }

    const isBestPrice = (offer) => {
        const cheapestOffer = orderOffersQuery.data[0];

        if (offer.state === 'ENQUIRY') {
            return false;
        }

        if (!cheapestOffer.total?.value) {
            return false;
        }

        return offer.total?.value === cheapestOffer.total.value;
    };

    const onOfferListItemClick = (offerId) => {
        setOpenedThroughNotification(false);

        if (expandedOfferIds.has(offerId)) {
            offerIdSetState.remove(offerId);
        } else {
            offerIdSetState.add(offerId);
        }
    };

    return (
        <Box>
            {orderOffersQuery.data.map((offer) => {
                const ref = paramOfferId === offer.id ? offerListItemRef : null;

                return (
                    <OfferListItem
                        isBestPrice={isBestPrice(offer)}
                        key={offer.id}
                        offer={offer}
                        order={order}
                        expanded={expandedOfferIds.has(offer.id)}
                        onClick={() => onOfferListItemClick(offer.id)}
                        ref={ref}
                    />
                );
            })}

            {order.spot && order.isOpen() ? <EmptyState>{translate('fuelOrder.offerList.spot')}</EmptyState> : null}
        </Box>
    );
};

OfferList.propTypes = {
    order: PropTypes.object.isRequired,
};
