import { useFormikContext } from 'formik';
import { useBeforeunload } from 'react-beforeunload';

// Warn user if they have already entered data and close the tab.
export const useFormikDirtyWarning = () => {
    const formik = useFormikContext();

    useBeforeunload((event) => {
        if (formik.dirty) {
            event.preventDefault();
        }
    });
};
