import { useQuery } from '@tanstack/react-query';

import { getAnonymousQuotation } from '../../common/api/clients/anonymousQuotation.api';
import { queryKeys } from '../../common/api/queryKeys';
import { Offer } from '../../offer/model/offer.model';

export const useAnonymousQuotationDetails = (anonymousQuotationId, token) => {
    return useQuery({
        queryKey: queryKeys.anonymousQuotation(anonymousQuotationId),
        queryFn: () => getAnonymousQuotation(anonymousQuotationId, token),
        enabled: !!anonymousQuotationId && !!token,
        staleTime: Infinity,
        select: (data) => {
            const offer = new Offer({
                ...data.offer,
                quoteValidityTime: data.offer.quoteValidityTime ?? '',
                vendorReference: data.offer.vendorReference ?? '',
            });

            offer.compute();

            return {
                ...data,
                offer,
            };
        },
        retry: (count, error) => {
            if (error?.response?.status && (error.response.status !== 404 || error.response.status !== 403)) {
                return false;
            }

            return count < 3;
        },
    });
};
