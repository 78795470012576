UserResource.$inject = ['$resource', 'AppSettings', 'User'];

function UserResource($resource, AppSettings, User) {
    const UserResource = $resource(
        AppSettings.routes.api + '/v1/user',
        {},
        {
            update: {
                method: 'PUT',
            },
        }
    );
    angular.extend(UserResource.prototype, User.prototype);
    return UserResource;
}

export default {
    name: 'UserResource',
    fn: UserResource,
};
