import { ReactNode } from 'react';
import styled from 'styled-components';

import { Tooltip } from '../../../cdl/Tooltip/Tooltip';
import { translate } from '../../helpers/translate.helper';
import { IconLock } from '../../icons/cdl/Lock';
import { Box } from '../../ui/Box';
import { get } from '../../ui/get';

const SidebarItemImitation = styled.div`
    height: 32px;
    font-size: ${get('fontSizes.2')}px;
    font-weight: ${get('fontWeights.medium')};
    line-height: 24px;
    color: ${get('colors.foreground.muted')};
    padding-left: ${get('space.4')}px;
    padding-right: ${get('space.4')}px;
    user-select: none;
`;

interface LockedNavigationItemProps {
    children: ReactNode;
}

export const LockedSidebarItem = ({ children }: LockedNavigationItemProps) => {
    return (
        <SidebarItemImitation>
            <Tooltip showArrow side="right" align="start" label={translate('closelinkLite.lockedFeatureTooltip')}>
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" height="100%">
                    {children}
                    <Box padding={4}>
                        <IconLock width={16} height={16} />
                    </Box>
                </Box>
            </Tooltip>
        </SidebarItemImitation>
    );
};
