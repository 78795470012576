AcceptTermsController.$inject = ['$scope', '$uibModalInstance'];

function AcceptTermsController($scope, $uibModalInstance) {
    $scope.onClose = () => {
        $uibModalInstance.close();
    };
}

export default {
    name: 'AcceptTermsController',
    fn: AcceptTermsController,
};
