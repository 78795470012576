import PropTypes from 'prop-types';

import { LocationStateProvider } from '../common/LocationState/LocationStateProvider';

export const AgentOverviewState = ({ children }) => {
    const stateConfig = {
        searchQuery: '',
        customerIds: [],
        portIds: [],
        tab: 'active',
        page: 0,
    };

    return <LocationStateProvider stateConfig={stateConfig}>{children}</LocationStateProvider>;
};

AgentOverviewState.propTypes = {
    children: PropTypes.node,
};
