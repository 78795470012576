import { Box } from '../../common/ui/Box';
import { Text } from '../Text/Text';

import { CalloutProps } from './Callout';

interface CalloutContentProps extends Pick<CalloutProps, 'heading' | 'description' | 'leadingVisual'> {
    color: string;
}

export const CalloutContent = ({ heading, description, leadingVisual, color }: CalloutContentProps) => {
    const renderedLeadingVisual = leadingVisual ? leadingVisual({ height: '16px', width: '16px', color: color }) : null;
    return (
        <Box
            display="grid"
            gridTemplateColumns={renderedLeadingVisual ? '16px auto' : 'auto'}
            columnGap={5}
            rowGap={2}
            paddingRight={6}
        >
            {renderedLeadingVisual}

            {heading ? <Text variant="subtitle2">{heading}</Text> : null}

            {description ? (
                <Text
                    variant="extraSmall"
                    color={heading ? 'foreground.muted' : color}
                    style={{ gridColumnStart: renderedLeadingVisual ? 2 : 0 }}
                >
                    {description}
                </Text>
            ) : null}
        </Box>
    );
};
