import { FuelPricingDate } from '../../../types/FuelPricingDate';

import { getPricingCodeFrequencies } from './utils/getPricingCodeFrequencies';
import { hasValidPricingCode } from './utils/hasValidPricingCode';

export const checkValidity = (contract, productGroups) => {
    const hasProductGroups = productGroups.length > 0;
    const areRequiredFieldsValid = [
        'reference',
        'supplierId',
        'startDate',
        'endDate',
        'contractBasis',
        'pricingDate',
        'portIds',
    ].every((name) => {
        const field = contract[name];

        if (!field) {
            return false;
        }

        if (typeof field === 'string' && field === '') {
            return false;
        }

        if (Array.isArray(field) && field.length === 0) {
            return false;
        }

        if (contract.startDate > contract.endDate) {
            return false;
        }

        return true;
    });

    const hasValidPricingDate =
        contract.pricingDate === FuelPricingDate.MONTHLY_AVERAGE
            ? !!contract.pricingDateMonthType
            : !!Number(contract.pricingDateDays) || Number(contract.pricingDateDays) === 0;

    const hasValidPaymentTerms =
        contract.paymentTermReference &&
        (contract.paymentTermReferenceDays || contract.paymentTermReference === 'PREPAYMENT');

    const hasInvalidProductGroup = productGroups
        ? productGroups.map((productGroup) => !checkProductGroupValidity(contract, productGroup)).includes(true)
        : false;

    return (
        hasProductGroups &&
        areRequiredFieldsValid &&
        hasValidPricingDate &&
        hasValidPaymentTerms &&
        !hasInvalidProductGroup
    );
};

export const checkTenderValidity = (contract, productGroups) => {
    const hasProductGroups = productGroups.length > 0;
    const areRequiredFieldsValid =
        ['reference', 'startDate', 'endDate', 'portIds'].every((name) => {
            const field = contract[name];

            if (!field) {
                return false;
            }

            if (typeof field === 'string' && field === '') {
                return false;
            }

            if (Array.isArray(field) && field.length === 0) {
                return false;
            }

            if (contract.startDate > contract.endDate) {
                return false;
            }

            return true;
        }) &&
        contract.tender.dueDate &&
        contract.tender.dueDate !== '';

    const hasInvalidProductGroup = productGroups
        ? productGroups.map((productGroup) => !checkTenderProductGroupValidity(contract, productGroup)).includes(true)
        : false;

    return hasProductGroups && areRequiredFieldsValid && !hasInvalidProductGroup;
};

export const checkProductValidity = (contract, productGroup) => {
    return productGroup?.products?.every(
        (product) =>
            ['product', 'pricingCode', 'conversionRate'].every((property) => !!product[property]) &&
            !!product.priceDifference?.value &&
            hasValidPricingCode(getPricingCodeFrequencies(contract.pricingDate), product.pricingCode)
    );
};

const checkVolumesValidity = (productGroup) => {
    return ['contractVolume', 'minStemSize'].every(
        (name) => !!productGroup[name] && productGroup[name] !== '0' && !String(productGroup[name]).endsWith('.')
    );
};

export const checkProductGroupValidity = (contract, productGroup) => {
    return checkProductValidity(contract, productGroup) && checkVolumesValidity(productGroup);
};

export const checkTenderProductValidity = (contract, productGroup) => {
    return productGroup?.products?.every(
        (product) =>
            ['product'].every((property) => !!product[property]) &&
            hasValidPricingCode(getPricingCodeFrequencies(contract.pricingDate), product.pricingCode)
    );
};

const checkTenderVolumesValidity = (productGroup) => {
    return ['contractVolume'].every(
        (name) => !!productGroup[name] && productGroup[name] !== '0' && !String(productGroup[name]).endsWith('.')
    );
};

export const checkTenderProductGroupValidity = (contract, productGroup) => {
    return checkTenderProductValidity(contract, productGroup) && checkTenderVolumesValidity(productGroup);
};
