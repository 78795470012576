import React from 'react';
import styled from 'styled-components';

import { PortWithPrices } from '../../../types/GlobalPricesMessage';
import { Text } from '../../cdl/Text/Text';
import { formatDate } from '../../common/helpers/formatDate.helper';
import { formatFuelMoney } from '../../common/helpers/formatFuelMoney.helper';
import { Box } from '../../common/ui/Box';
import { get } from '../../common/ui/get';

import { GlobalPricesPortAdditionalCosts } from './GlobalPricesPortAdditionalCosts';
import { GlobalPricesPortInformationLabel } from './GlobalPricesPortInformationLabel';

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${get('colors.border.muted')};
    margin: ${get('space.3')}px 0;
`;

interface GlobalPricesPortInformationProps {
    port: PortWithPrices;
}

export const GlobalPricesPortInformation = ({ port }: GlobalPricesPortInformationProps) => {
    return (
        <Box display="flex" flexDirection="column" paddingX={5} paddingY={4}>
            <Text variant="body" fontWeight="semiBold" marginBottom={2}>
                {port.name}
            </Text>

            <Box display="flex" flexDirection="column" rowGap={2}>
                <Box display="grid" gridTemplateColumns="min-content 1fr" columnGap={3}>
                    {port.prices.map((priceData) => {
                        return (
                            <React.Fragment key={`${priceData.productGroup} ${priceData.sulphurContent}`}>
                                <GlobalPricesPortInformationLabel>{`${priceData.productGroup} ${priceData.sulphurContent}`}</GlobalPricesPortInformationLabel>

                                <Text variant="small">{`${formatFuelMoney({
                                    value: priceData.price.value,
                                    currency: priceData.price.currency,
                                })} ${priceData.terms} (${priceData.priceSource}, ${formatDate({
                                    date: priceData.priceEstimationDate,
                                })})`}</Text>
                            </React.Fragment>
                        );
                    })}
                </Box>

                {Object.keys(port.additionalCosts).length ? (
                    <>
                        <Divider />
                        <GlobalPricesPortAdditionalCosts additionalCosts={port.additionalCosts} />
                    </>
                ) : null}
            </Box>
        </Box>
    );
};
