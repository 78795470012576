import { useFormikContext } from 'formik';
import { ReactNode } from 'react';

import { Collapsable } from '../../../../../cdl/Collapsable/Collapsable';
import { translate } from '../../../../../common/helpers/translate.helper';
import { FormikApprovalSettingsState } from '../MultipleApproversSettingsForm';

export const FormikTopLevelAndApproverAccordionDescription = () => {
    const { values } = useFormikContext<FormikApprovalSettingsState>();

    const isTopLevelApproverSpecified = !!values.defaultControllerEmailAddress;

    const configuredApprovalLimits = values.enableAdditionalApprovers
        ? values.additionalApprovers.filter((approvalLimit) => approvalLimit.approvalLimit.length > 0).length
        : 0;

    const additionalApproversCount = values.enableAdditionalApprovers
        ? values.additionalApprovers.reduce((acc, approvalLimit) => {
              if (approvalLimit.approvalLimit.length > 0) {
                  let validApproverCount = 0;

                  approvalLimit.approvers.forEach((approver) => {
                      if (approver.emailAddress.length > 0) {
                          validApproverCount++;
                      }
                  });

                  return acc + validApproverCount;
              }

              return acc;
          }, 0)
        : 0;

    const isAdditionalApproversSpecified = configuredApprovalLimits > 0;

    let topLevelApproverComponent: ReactNode;
    let approvalLimitComponent: ReactNode;

    if (isTopLevelApproverSpecified) {
        if (additionalApproversCount === 0) {
            topLevelApproverComponent = (
                <Collapsable.Description active>
                    {translate('multipleApproverSettings.topLevelApproverSpecified')}
                </Collapsable.Description>
            );
        } else if (additionalApproversCount === 1) {
            topLevelApproverComponent = (
                <Collapsable.Description active>
                    {translate('multipleApproverSettings.topLevelApproverAndSingleAdditionalApproverSpecified', {
                        additionalApproversCount: additionalApproversCount.toString(),
                    })}
                </Collapsable.Description>
            );
        } else {
            topLevelApproverComponent = (
                <Collapsable.Description active>
                    {translate('multipleApproverSettings.topLevelApproverAndMultipleAdditionalApproversSpecified', {
                        additionalApproversCount: additionalApproversCount.toString(),
                    })}
                </Collapsable.Description>
            );
        }
    } else {
        topLevelApproverComponent = (
            <Collapsable.Description>
                {translate('multipleApproverSettings.noApproverSpecified')}
            </Collapsable.Description>
        );
    }

    if (isAdditionalApproversSpecified) {
        if (configuredApprovalLimits === 1) {
            approvalLimitComponent = (
                <Collapsable.Description active>
                    {translate('multipleApproverSettings.singleApprovalLimitSpecified', {
                        approvalLimitCount: configuredApprovalLimits.toString(),
                    })}
                </Collapsable.Description>
            );
        } else {
            approvalLimitComponent = (
                <Collapsable.Description active>
                    {translate('multipleApproverSettings.multipleApprovalLimitsSpecified', {
                        approvalLimitCount: configuredApprovalLimits.toString(),
                    })}
                </Collapsable.Description>
            );
        }
    } else {
        approvalLimitComponent = (
            <Collapsable.Description>
                {translate('multipleApproverSettings.noApprovalLimitSpecified')}
            </Collapsable.Description>
        );
    }

    return (
        <div
            style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}
        >
            {topLevelApproverComponent}
            <Collapsable.Description active={isTopLevelApproverSpecified && isAdditionalApproversSpecified}>
                &nbsp;•&nbsp;
            </Collapsable.Description>
            {approvalLimitComponent}
        </div>
    );
};
