import { useEffect, useState } from 'react';

import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';
import { Button } from '../../Button/Button';
import { DefaultOption } from '../../ComboBox/ComboBox';
import { Text } from '../../Text/Text';
import { FilterPill, FilterPillProps } from '../FilterPill/FilterPill';

import { CustomComboBox } from './components/CustomComboBox';
import { getFilterValueLabel } from './util/getFilterValueLabel';

interface FilterMultiSelectFullProps {
    label: FilterPillProps['label'];
    filterByLabel: string;
    filterPlaceholder?: string;
    options: DefaultOption[];
    values?: string[];
    onChange?: (values: string[]) => void;
}

export function FilterMultiSelectFull({
    label,
    filterByLabel,
    filterPlaceholder,
    values,
    options,
    onChange,
}: FilterMultiSelectFullProps) {
    const [visibleOptions, setVisibleOptions] = useState<string[]>(values || []);

    useEffect(() => {
        setVisibleOptions(values || []);
    }, [values]);

    const sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label));

    return (
        <FilterPill
            label={label}
            value={getFilterValueLabel(visibleOptions.map((value) => options.find((it) => it.value === value)!!))}
            onClear={() => onChange?.([])}
            onPopoverClose={() => {
                setVisibleOptions(values || []);
            }}
        >
            {(closePopover) => {
                const selectedOptions = visibleOptions.map((value) => ({
                    value,
                    label: options.find((it) => it.value === value)!!.label,
                }));

                return (
                    <Box padding="12px" display="flex" flexDirection="column" gap={5}>
                        <Text variant="body" fontWeight="semiBold">
                            {filterByLabel}
                        </Text>
                        <Box display="flex" flexDirection="column" gap={4}>
                            <CustomComboBox
                                isMulti
                                placeholder={filterPlaceholder}
                                onChange={(newValue) => setVisibleOptions(newValue.map((it) => it.value))}
                                value={selectedOptions}
                                loadOptions={(search) =>
                                    Promise.resolve(
                                        sortedOptions.filter((it) =>
                                            it.label.toLowerCase().includes(search.toLowerCase())
                                        )
                                    )
                                }
                            />
                        </Box>
                        <Button
                            type="submit"
                            width="100%"
                            emphasis="high"
                            onClick={() => {
                                onChange?.(visibleOptions);
                                closePopover();
                            }}
                        >
                            {translate('global.filterMultiSelect.apply')}
                        </Button>
                    </Box>
                );
            }}
        </FilterPill>
    );
}
