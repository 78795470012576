import { Form, useFormikContext } from 'formik';
import styled from 'styled-components';

import { Button } from '../../../cdl/Button/Button';
import { FormikCheckbox } from '../../../cdl/Checkbox/FormikCheckbox';
import { Tag } from '../../../cdl/Tag/Tag';
import { Text } from '../../../cdl/Text/Text';
import { FormikDebug } from '../../../common/form-elements/formik/FormikDebug';
import { FormikMultipleEntriesInput } from '../../../common/form-elements/FormMultipleEntriesInput/FormikMultipleEntriesInput';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';

export interface SendContractFormState {
    receiverEmailAddresses: string[];
    copyToSelf: boolean;
}

interface SendContractFormProps {
    onCancel: () => void;
    previouslySentTo: string[];
}

const Divider = styled(Box).attrs({
    backgroundColor: 'border.muted',
    height: '1px',
    marginTop: 5,
})``;

export const SendContractForm = ({ onCancel, previouslySentTo }: SendContractFormProps) => {
    const formikContext = useFormikContext<SendContractFormState>();

    return (
        <Form>
            <FormikMultipleEntriesInput
                inputLabel={translate('contracts.sendContract.recipients.inputLabel')}
                inputPlaceholder={translate('contracts.sendContract.recipients.inputPlaceholder')}
                name="receiverEmailAddresses"
                pattern="^\S+@\S+$"
            />

            {previouslySentTo.length > 0 ? (
                <>
                    <Divider />
                    <Box display="grid" marginTop={5}>
                        <Text variant="fieldLabel" color="foreground.muted">
                            {translate('contracts.sendContract.previouslySent')}
                        </Text>
                        <Box display="flex" gap={4} marginTop={4} flexWrap={'wrap'}>
                            {previouslySentTo.map((email) => (
                                <Tag key={email} variant="default" emphasis={true}>
                                    {email}
                                </Tag>
                            ))}
                        </Box>
                    </Box>
                </>
            ) : null}

            <Box display="flex" justifyContent="space-between" marginTop={6}>
                <FormikCheckbox name="copyToSelf" label={translate('contracts.sendContract.copyToSelf')} />
                <Box display="flex" gap={4}>
                    <Button type="button" emphasis="low" disabled={formikContext.isSubmitting} onClick={onCancel}>
                        {translate('contracts.sendContract.cancelBtn')}
                    </Button>
                    <Button
                        type="submit"
                        emphasis="high"
                        isLoading={formikContext.isSubmitting}
                        disabled={!formikContext.isValid}
                    >
                        {translate('contracts.sendContract.submitBtn')}
                    </Button>
                </Box>
            </Box>

            <FormikDebug />
        </Form>
    );
};
