import { Fragment } from 'react';

import { UserGatewayMessage } from '../../../../types/UserGatewayMessage';
import { UserMessage } from '../../../../types/UserMessage';
import { Text } from '../../../cdl/Text/Text';
import { DataSheet } from '../../../common/DataSheet/DataSheet';
import { identifyUserRole } from '../../../common/helpers/identifyUserRole.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useEnums } from '../../../common/hooks/useEnums';

interface BasicUserDataSheetContentProps {
    user: UserMessage | UserGatewayMessage;
}

export const BasicUserDataSheetContent = ({ user }: BasicUserDataSheetContentProps) => {
    const { getHumanReadableValue } = useEnums();

    const rows = [
        { label: translate('user.firstname'), value: user.firstname, condition: !!user.firstname },
        { label: translate('user.lastname'), value: user.lastname, condition: !!user.lastname },
        {
            label: translate('user.role'),
            value: getHumanReadableValue('role', identifyUserRole(user)),
        },
        { label: translate('user.mail'), value: user.email },
        {
            label: translate('user.phone'),
            value: user.phone,
            condition: !!user.phone,
        },
        {
            label: translate('user.mailSettings'),
            value: translate('user.mailSettingsValue', {
                news: user.mailSettings.newsletter ? translate('global.yes') : translate('global.no'),
                transactional: user.mailSettings.transactional ? translate('global.yes') : translate('global.no'),
            }),
        },
    ].filter(({ condition = true }) => condition);

    return (
        <>
            {rows.map((row) => {
                return (
                    <Fragment key={row.label}>
                        <DataSheet.Label>{row.label}</DataSheet.Label>
                        <Text variant="body">{row.value}</Text>
                    </Fragment>
                );
            })}
        </>
    );
};
