import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../../../cdl/Toast/useToasts';
import { updateCounterOffer } from '../../../../../common/api/clients/counterOffer.api';
import { queryKeys } from '../../../../../common/api/queryKeys';
import { translate } from '../../../../../common/helpers/translate.helper';

export const useCounterOfferEdit = () => {
    const queryClient = useQueryClient();
    const { addErrorToast } = useToasts();

    return useMutation({
        mutationFn: updateCounterOffer,
        onSuccess: async (data) => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: queryKeys.fuelOrderOffers() }),
                queryClient.invalidateQueries({ queryKey: queryKeys.offer(data.offerId) }),
            ]);
        },
        onError: () => {
            addErrorToast(translate('counterOffer.toast.updateError'));
        },
    });
};
