import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';

import { PageMessage } from '../../../../types/PageMessage';
import { UserGatewayMessage } from '../../../../types/UserGatewayMessage';
import { updateManagementUser } from '../../../common/api/clients/user.api';
import { queryKeys } from '../../../common/api/queryKeys';

export const useOptimisticUserMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateManagementUser,
        onMutate: (variables) => {
            const queryKey = queryKeys.users();
            const queries = queryClient.getQueriesData<PageMessage<UserGatewayMessage>>({
                queryKey,
            });

            const changedUserPages: {
                queryKey: QueryKey;
                data: PageMessage<UserGatewayMessage>;
            }[] = [];

            queries.forEach((query) => {
                const userPage = query[1];
                if (!userPage?.content) {
                    return;
                }
                const indexForUser = userPage.content.findIndex((user) => variables.userId === user.id);
                if (indexForUser === -1) {
                    return;
                }

                userPage.content[indexForUser] = {
                    ...userPage.content[indexForUser],
                    ...variables.userMessage,
                };

                queryClient.setQueryData(queryKey, userPage);

                changedUserPages.push({
                    queryKey,
                    data: userPage,
                });
            });

            return { changedUserPages };
        },
        onError: (err, newUserData, context) => {
            if (!context?.changedUserPages) {
                return;
            }
            context.changedUserPages.forEach((userPage) => {
                queryClient.setQueryData(userPage.queryKey, userPage.data);
            });
        },
        onSettled: () => {
            const queryKey = queryKeys.users();

            queryClient.invalidateQueries({ queryKey });
        },
    });
};
