import { useProductContext } from '../../hooks/useProductContext';

import { FuelCustomerSidebarNavigation } from './FuelCustomerSidebarNavigation';
import { LubesCustomerSidebarNavigation } from './LubesCustomerSidebarNavigation';

export const CustomerSidebarNavigation = () => {
    const { isFuel } = useProductContext();

    if (isFuel) {
        return <FuelCustomerSidebarNavigation />;
    }

    return <LubesCustomerSidebarNavigation />;
};
