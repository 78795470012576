import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';

const Wrapper = styled.div`
    position: absolute;
    bottom: 24px;
    right: 72px;
    z-index: 1;
    border-radius: 2px;
    background-color: white;
    padding: 8px 8px 4px 8px;
    box-shadow: 0px 1px 4px -1px #0000004d;
`;

interface MarkerColorsProps {
    colors: string[];
}
export const MarkerColors = ({ colors }: MarkerColorsProps) => {
    return (
        <Wrapper>
            <Flex columnGap={4} width="218px">
                {colors.map((color) => {
                    return <Box key={color} flexGrow={1} height="4px" backgroundColor={color} borderRadius="100px" />;
                })}
            </Flex>

            <Flex justifyContent="space-between" marginTop={4}>
                <Text variant="action" color={'foreground.muted'}>
                    {translate('globalPrices.highPrice')}
                </Text>
                <Text variant="action" color={'foreground.muted'}>
                    {translate('globalPrices.lowPrice')}
                </Text>
            </Flex>
        </Wrapper>
    );
};
