import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getPorts } from '../../../common/api/clients/port.api';
import { queryKeys } from '../../../common/api/queryKeys';

export const usePortDetails = (id) => {
    const queryClient = useQueryClient();

    const getPort = async (id) => {
        const response = await getPorts({
            ids: [id],
        });

        return response.content[0];
    };

    const query = useQuery({
        queryKey: queryKeys.port(id),
        queryFn: () => getPort(id),
        enabled: !!id,
    });

    const prefetch = (id) => {
        queryClient.prefetchQuery({ queryKey: queryKeys.port(id), queryFn: () => getPort(id) });
    };

    return {
        ...query,
        prefetch,
    };
};
