import { PortForm } from '../../components/PortForm';
import { usePortCreate } from '../hooks/usePortCreate';

interface CreatePortFormProps {
    onSuccess: () => void;
}

export const CreatePortForm = ({ onSuccess }: CreatePortFormProps) => {
    const portCreateMutation = usePortCreate();

    const initialValues = {
        id: undefined,
        name: '',
        locCode: '',
        latitude: '',
        longitude: '',
        timeZoneId: '',
        countryId: '',
        aliases: [] as string[],
        locCodeAliases: [] as string[],
    };

    return (
        <PortForm
            onSubmit={(portMessage) => portCreateMutation.mutateAsync(portMessage)}
            onSuccess={onSuccess}
            initialValues={initialValues}
        />
    );
};
