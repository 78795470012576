import { CategoryWarning, CategoryWarningType } from '../../../../../../../../types/BunkerCaseSolutionResponse';
import { Tooltip } from '../../../../../../../cdl/Tooltip/Tooltip';
import { useFuelVolumeFormat } from '../../../../../../../common/hooks/useFuelVolumeFormat';
import { Box } from '../../../../../../../common/ui/Box';
import { getWarningTitle } from '../util/getWarningTitle';

import { WarningIcon } from './WarningIcon';

interface NumberWithWarningsLabelProps {
    value?: number;
    warnings?: CategoryWarning[];
}

export const NumberWithWarningsLabel = ({ value, warnings }: NumberWithWarningsLabelProps) => {
    const formatVolume = useFuelVolumeFormat(false);

    if (value === undefined || value <= 0) {
        return <>-</>;
    }

    const formattedValue = formatVolume({ volume: value });

    const groupedWarnings = (warnings ?? []).reduce((acc, warning) => {
        const currentValue = acc.get(warning.type);

        if (currentValue !== undefined) {
            acc.set(warning.type, [...currentValue, warning]);
        } else {
            acc.set(warning.type, [warning]);
        }

        return acc;
    }, new Map<CategoryWarningType, CategoryWarning[]>());

    return (
        <Box display="inline-flex" gap={2} alignItems="center" justifyContent="end">
            {Array.from(groupedWarnings).map(([type, warnings]) => {
                const tooltipLabel = (
                    <div>
                        <b>{getWarningTitle(type)}</b>
                        <br />
                        <span style={{ whiteSpace: 'pre-line' }}>{warnings.map((it) => it.message).join('\n')}</span>
                    </div>
                );

                return (
                    <Tooltip label={tooltipLabel} key={type} maxWidth="420px">
                        <span>
                            <WarningIcon warningType={type} />
                        </span>
                    </Tooltip>
                );
            })}
            {formattedValue}
        </Box>
    );
};
