import { FieldMetaProps, useField, useFormikContext } from 'formik';
import { ReactNode } from 'react';

import { Button } from '../../../../../cdl/Button/Button';
import { Collapsable } from '../../../../../cdl/Collapsable/Collapsable';
import { Text } from '../../../../../cdl/Text/Text';
import { formatMoney } from '../../../../../common/helpers/formatMoney.helper';
import { translate } from '../../../../../common/helpers/translate.helper';
import { FormikMoneyInput } from '../../../../../common/MoneyInput/FormikMoneyInput';
import { Box } from '../../../../../common/ui/Box';
import { FormikApprovalSettingsState } from '../MultipleApproversSettingsForm';

function getDescription(approvalSettings: FormikApprovalSettingsState): ReactNode {
    if (
        approvalSettings.defaultControllerEmailAddress &&
        approvalSettings.minimumThreshold &&
        !Number.isNaN(approvalSettings.minimumThreshold.value) &&
        Number(approvalSettings.minimumThreshold.value) > 0
    ) {
        return (
            <Collapsable.Description active>
                {translate('multipleApproverSettings.ordersUnderXDoNotRequireApproval', {
                    minimumThreshold: formatMoney({
                        value: Number(approvalSettings.minimumThreshold.value),
                        currency: approvalSettings.minimumThreshold.currency,
                    }),
                })}
            </Collapsable.Description>
        );
    }

    return (
        <Collapsable.Description>
            {translate('multipleApproverSettings.allOrdersRequireApproval')}
        </Collapsable.Description>
    );
}

function getMinimumThresholdCaption(
    minimumThresholdMeta: FieldMetaProps<string>,
    hasTopLevelApproverSpecified: boolean
): string | undefined {
    if (!hasTopLevelApproverSpecified) {
        return translate('multipleApproverSettings.toConfigureThresholdTheTopLevelApproverMustBeSet');
    }

    if (!!minimumThresholdMeta.error && minimumThresholdMeta.touched) {
        return minimumThresholdMeta.error;
    }

    return undefined;
}

interface FormikMinimumApprovalThresholdAccordionProps {
    isOpen: boolean;
    onHeaderClick: () => void;
    onOpenApproversAccordionClick: () => void;
}

export const FormikMinimumApprovalThresholdAccordion = ({
    isOpen,
    onHeaderClick,
    onOpenApproversAccordionClick,
}: FormikMinimumApprovalThresholdAccordionProps) => {
    const { values } = useFormikContext<FormikApprovalSettingsState>();
    const formikMinimumThresholdField = useField('minimumThreshold.value');

    const hasTopLevelApproverSpecified = !!values.defaultControllerEmailAddress;

    return (
        <Collapsable
            isOpen={isOpen}
            header={
                <Collapsable.Header
                    title={
                        <Collapsable.Title>
                            {translate('multipleApproverSettings.thresholdForOrderingWithoutApproval')}
                        </Collapsable.Title>
                    }
                    description={getDescription(values)}
                    isOpen={isOpen}
                    expandActionText={(isOpen) =>
                        isOpen ? translate('multipleApproverSettings.hide') : translate('multipleApproverSettings.show')
                    }
                    onClick={onHeaderClick}
                />
            }
        >
            {() => (
                <Box display="flex" flexDirection="column" style={{ gap: '16px' }} padding={5}>
                    <Text variant="body">
                        {translate('multipleApproverSettings.minimumApprovalThresholdExplanation')}
                    </Text>
                    <FormikMoneyInput
                        name="minimumThreshold.value"
                        label={translate('multipleApproverSettings.minimumThresholdLabel')}
                        disabled={!hasTopLevelApproverSpecified}
                        caption={getMinimumThresholdCaption(
                            formikMinimumThresholdField[1],
                            hasTopLevelApproverSpecified
                        )}
                        negative={
                            formikMinimumThresholdField[1].touched &&
                            !!formikMinimumThresholdField[1].error &&
                            hasTopLevelApproverSpecified
                        }
                        placeholder={translate('multipleApproverSettings.approvalThresholdPlaceholder')}
                    />
                    {!hasTopLevelApproverSpecified ? (
                        <Box marginTop={6}>
                            <Button type="button" emphasis="high" size="large" onClick={onOpenApproversAccordionClick}>
                                {translate('multipleApproverSettings.setTopLevelApprover')}
                            </Button>
                        </Box>
                    ) : null}
                </Box>
            )}
        </Collapsable>
    );
};
