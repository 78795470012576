import styled from 'styled-components';

import { LabelText } from '../../common/LabelText/LabelText';
import { Box } from '../../common/ui/Box';
import { ValueFrame as ReadOnlyFieldValueFrame } from '../ReadOnlyField/ReadOnlyField';

const ValueFrame = styled(ReadOnlyFieldValueFrame)`
    min-height: 90px;
    white-space: pre-wrap;
    overflow: auto;
`;

interface ReadOnlyFieldProps {
    value?: string;
    label?: string;
}

export const ReadOnlyTextarea = ({ value, label }: ReadOnlyFieldProps) => {
    return (
        <Box>
            {label ? <LabelText>{label}</LabelText> : null}
            <ValueFrame>{value}</ValueFrame>
        </Box>
    );
};
