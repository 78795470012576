import { useProductContext } from '../../hooks/useProductContext';
import { Flex } from '../../ui/Flex';
import { SidebarSeparator } from '../components/SidebarSeparator';

import { FuelAdminSidebarItems } from './FuelAdminSidebarItems';
import { GeneralAdminSidebarItems } from './GeneralAdminSidebarItems';
import { LubesAdminSidebarItems } from './LubesAdminSidebarItems';

export const AdminSidebarNavigation = () => {
    const { isFuel, isLubes } = useProductContext();

    return (
        <Flex flexDirection="column" justifyContent="space-between">
            {isFuel ? <FuelAdminSidebarItems /> : null}
            {isLubes ? <LubesAdminSidebarItems /> : null}
            <SidebarSeparator />
            <GeneralAdminSidebarItems />
        </Flex>
    );
};
