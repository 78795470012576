import { FormikErrors, setIn } from 'formik';

import { translate } from '../../../../../common/helpers/translate.helper';
import { FormikApprovalSettingsState } from '../MultipleApproversSettingsForm';

export function validateDuplicateApprovalLimits(
    errors: FormikErrors<FormikApprovalSettingsState>,
    values: FormikApprovalSettingsState
): FormikErrors<FormikApprovalSettingsState> {
    const existingApprovalLimits = new Map<number, number[]>();

    values.additionalApprovers.forEach((additionalLimit, index) => {
        if (additionalLimit.approvalLimit === '') {
            return;
        }

        const limit = Number(additionalLimit.approvalLimit);
        const existingIndices = existingApprovalLimits.get(limit) ?? [];
        existingIndices.push(index);
        existingApprovalLimits.set(limit, existingIndices);
    });

    existingApprovalLimits.forEach((indices) => {
        if (indices.length > 1) {
            indices.forEach((it) => {
                errors = setIn(
                    errors,
                    `additionalApprovers.${it}.approvalLimit`,
                    translate('multipleApproverSettings.errors.approvalLimitAlreadyExists')
                );
            });
        }
    });

    return errors;
}
