import { baseAxiosInstance } from '../instances/BaseAxiosInstance';

export interface UserOnboardResponse {
    id: string;
    email: string;
    phone?: string;
    firstname?: string;
    lastname?: string;
    loginTimes?: number;
    mailSettings: {
        newsletter: boolean;
        transactional: boolean;
    };
}

export interface UserOnboardErrorResponse {
    errorCode: 'NOT_ALLOWED';
}

export interface UserOnboardRequest extends UserOnboardResponse {
    password: string;
}

export interface UserOnboardRequestErrorResponse {
    errorCode: 'MAIL_NOT_UNIQUE' | 'PASSWORD_SAFETY';
}

export const getOnboardingUser = async (userId: string, token: string): Promise<UserOnboardResponse> => {
    const params = new URLSearchParams();
    params.append('userId', userId);
    params.append('token', token);

    const response = await baseAxiosInstance.get(`/v1/user/onboarding?${params.toString()}`);

    return response.data;
};

export const updateOnboardingUser = async (
    userId: string,
    token: string,
    user: UserOnboardRequest
): Promise<UserOnboardResponse> => {
    const params = new URLSearchParams();
    params.append('userId', userId);
    params.append('token', token);

    const response = await baseAxiosInstance.put<UserOnboardResponse>(`/v1/user/onboarding?${params.toString()}`, user);

    return response.data;
};
