import { CountryMessage } from '../../../../types/CountryMessage';
import { PageMessage } from '../../../../types/PageMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const getEnums = async (): Promise<any> => {
    const response = await authorizedAxiosInstance.get('/v1/enums');

    return response.data;
};

export const getCountries = async (searchQuery: string, page?: number): Promise<PageMessage<CountryMessage>> => {
    const params = new URLSearchParams();
    params.append('searchQuery', searchQuery.trim());

    if (page !== undefined) {
        params.append('page', page.toString());
    }

    const response = await authorizedAxiosInstance.get(`/v1/country?${params.toString()}`);

    return response.data;
};

export const getCountryList = async (): Promise<CountryMessage[]> => {
    const response = await authorizedAxiosInstance.get(`v1/country/list`);

    return response.data;
};
