import * as RadixPopover from '@radix-ui/react-popover';
import { PopperContentProps } from '@radix-ui/react-popper';
import { config, useTransition } from '@react-spring/web';
import { ReactNode, useState } from 'react';

import { AnimatedContent } from './components/AnimatedContent';

export interface PopoverProps extends Pick<PopperContentProps, 'side' | 'align'> {
    children: ReactNode;
    trigger: ReactNode;
    width?: string;
}

export const Popover = ({ children, trigger, width, side = 'top', align = 'center' }: PopoverProps) => {
    const [open, setOpen] = useState(false);

    const transitions = useTransition(open, {
        from: { opacity: 0, scale: 0.975 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 0.975 },
        config: {
            ...config.default,
            tension: 600,
            clamp: true,
        },
    });

    return (
        <RadixPopover.Root open={open} onOpenChange={setOpen}>
            <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
            <RadixPopover.Portal forceMount>
                {transitions((styles, item) =>
                    item ? (
                        <RadixPopover.Content
                            align={align}
                            side={side}
                            sideOffset={4}
                            collisionPadding={8}
                            style={{ outline: 'none' }}
                            forceMount
                        >
                            <AnimatedContent width={width} style={styles}>
                                {children}
                            </AnimatedContent>
                        </RadixPopover.Content>
                    ) : null
                )}
            </RadixPopover.Portal>
        </RadixPopover.Root>
    );
};
