import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { updateOrder } from '../../../common/api/clients/order.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { translate } from '../../../common/helpers/translate.helper';

export const useOrderCancel = (order) => {
    const queryClient = useQueryClient();
    const { addErrorToast } = useToasts();

    const updateOrderMutation = useMutation({
        mutationFn: updateOrder,
        onSuccess: (updatedOrder) => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.order(updatedOrder.id) });
        },
        onError: () => {
            addErrorToast(translate('fuelOrder.toast.cancelOrder.error'));
        },
    });

    const cancelOrder = async (cancelReason) => {
        await updateOrderMutation.mutateAsync({
            order: {
                ...order,
                state: 'CANCELED',
                cancelReason,
            },
            orderId: order.id,
        });
    };

    return { cancelOrder, isPending: updateOrderMutation.isPending };
};
