import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { queries } from '../../../../common/api/queryKeys/queries';

export const useFuelVesselConsumptionPresets = (vesselId: string) => {
    return useQuery({
        ...queries.vessels.detail(vesselId)._ctx.fuelConsumptionPresets,
        retry: (_, error: AxiosError) => {
            return error.response?.status !== 404;
        },
    });
};
