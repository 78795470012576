import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { updateOrder } from '../../../common/api/clients/order.api';
import { queries } from '../../../common/api/queryKeys/queries';
import { translate } from '../../../common/helpers/translate.helper';

export const useOrderEdit = () => {
    const queryClient = useQueryClient();
    const { addErrorToast } = useToasts();

    return useMutation({
        mutationFn: updateOrder,
        onSuccess: (order) => {
            return queryClient.invalidateQueries({ queryKey: queries.orders.detail(order.id).queryKey });
        },
        onError: () => {
            addErrorToast(translate('order.edit.toast.update.error'));
        },
    });
};
