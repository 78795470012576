import { BunkerSchedule } from '../../../../../../../../types/BunkerCaseSolutionResponse';

export function findIncludedProductCategories(bunkerSchedule: BunkerSchedule): Map<
    string,
    {
        productCategory: string;
        sulphurContent: string;
    }
> {
    const includedProductCategories: Map<string, { productCategory: string; sulphurContent: string }> = new Map();

    bunkerSchedule.purchases.forEach((purchase) => {
        includedProductCategories.set(purchase.productCategory + '-' + purchase.sulphurContent, {
            productCategory: purchase.productCategory,
            sulphurContent: purchase.sulphurContent,
        });
    });

    bunkerSchedule.categoryConsumption.forEach((categoryConsumption) => {
        includedProductCategories.set(categoryConsumption.productCategory + '-' + categoryConsumption.sulphurContent, {
            productCategory: categoryConsumption.productCategory,
            sulphurContent: categoryConsumption.sulphurContent,
        });
    });

    return includedProductCategories;
}
