import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { PortsOverviewPage as OriginalPortsOverviewPage } from './PortsOverviewPage';

const PortsOverviewPage = (props) => {
    return (
        <AppProviders>
            <OriginalPortsOverviewPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(PortsOverviewPage, 'reactPortsOverviewPage');
