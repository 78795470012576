import PropTypes from 'prop-types';

import { Button } from '../../../../cdl/Button/Button';
import { Modal } from '../../../../cdl/Modal/Modal';
import { Text } from '../../../../cdl/Text/Text';
import { ButtonGroup } from '../../../../common/buttons/ButtonGroup';
import { Box } from '../../../../common/ui/Box';

export const SimpleConfirmPopup = ({
    isOpen,
    onDismiss,
    onSubmit,
    headline,
    description,
    buttonText,
    isSubmitting,
}) => {
    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} scrollable={false}>
            <Box padding={6} display="grid" rowGap={5}>
                <Text variant="title">{headline}</Text>

                <Text color="dark-blue.2">{description}</Text>

                <ButtonGroup justifyContent="end">
                    <Button emphasis="high" isLoading={isSubmitting} onClick={onSubmit}>
                        {buttonText}
                    </Button>
                </ButtonGroup>
            </Box>
        </Modal>
    );
};

SimpleConfirmPopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onDismiss: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    headline: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
};
