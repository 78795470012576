import { ProductMessage } from '../../../../types/ProductMessage';
import { queries } from '../../../common/api/queryKeys/queries';
import { usePaginationQuery } from '../../../common/hooks/usePaginationQuery';

export const useProductsPagination = (searchQuery: string, page: number) => {
    return usePaginationQuery<ProductMessage>({
        queryKey: queries.products.pagination(searchQuery, true, page).queryKey,
        queryFn: queries.products.pagination(searchQuery, true, page).queryFn,
        prefetchQueryKey: queries.products.pagination(searchQuery, true, page + 1).queryKey,
        prefetchQueryFn: queries.products.pagination(searchQuery, true, page + 1).queryFn,
    });
};
