import { AssignmentGatewayMessage } from '../../../../types/AssignmentGatewayMessage';
import { AssignmentStatus } from '../../../../types/AssignmentMessage';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { CenteredPageError } from '../../../common/Error/CenteredPageError';
import { formatCompanyName } from '../../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatPort } from '../../../common/helpers/formatPort.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { usePaymentTermsFormat } from '../../../common/hooks/usePaymentTermsFormat';
import { ListingWithMore } from '../../../common/ListingWithMore/ListingWithMore';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { Box } from '../../../common/ui/Box';
import { AssignmentsPageStateConfig } from '../AssignmentsPage';
import { useAssignmentsPagination } from '../hooks/useAssignmentsPagination';

import { AssignmentsDropdown } from './AssignmentsDropdown';
import { AssignmentsEmptyState } from './AssignmentsEmptyState';
import { AssignmentStatusBadge } from './AssignmentStatusBadge';

export const AssignmentsTable = () => {
    const [locationStateUntyped, { setLocationFieldValue }] = useLocationState();
    const locationState = locationStateUntyped as AssignmentsPageStateConfig;

    const assignmentsPaginationQuery = useAssignmentsPagination({
        page: locationState.page,
        supplierIds: locationState.supplierIds,
        customerIds: locationState.customerIds,
    });

    const formatPaymentTerms = usePaymentTermsFormat();

    if (assignmentsPaginationQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (assignmentsPaginationQuery.isError) {
        return <CenteredPageError />;
    }

    return (
        <Box marginTop={5}>
            {assignmentsPaginationQuery.data.content.length === 0 ? (
                <AssignmentsEmptyState />
            ) : (
                <TableBuilder data={assignmentsPaginationQuery.data.content}>
                    <TableBuilderColumn<AssignmentGatewayMessage>
                        width="15%"
                        header={translate('assignments.customer')}
                    >
                        {(row) => formatCompanyName({ company: row.customer, includeType: true })}
                    </TableBuilderColumn>

                    <TableBuilderColumn<AssignmentGatewayMessage>
                        width="15%"
                        header={translate('assignments.supplier')}
                    >
                        {(row) => formatCompanyName({ company: row.supplier, includeType: true })}
                    </TableBuilderColumn>

                    <TableBuilderColumn<AssignmentGatewayMessage>
                        width="25%"
                        header={translate('assignments.portLimitations')}
                    >
                        {(row) => {
                            if (!row.portLimitationsPorts?.length) {
                                return '-';
                            }

                            return (
                                <ListingWithMore>
                                    {row.portLimitationsPorts.map((port) => formatPort({ port: port }))}
                                </ListingWithMore>
                            );
                        }}
                    </TableBuilderColumn>

                    <TableBuilderColumn<AssignmentGatewayMessage>
                        width="15%"
                        header={translate('assignments.defaultPaymentTermDays')}
                    >
                        {(row) => {
                            if (!row.defaultPaymentTermReference) {
                                return '-';
                            }

                            return formatPaymentTerms(
                                row.defaultPaymentTermReference,
                                row.defaultPaymentTermReferenceDays
                            );
                        }}
                    </TableBuilderColumn>

                    <TableBuilderColumn<AssignmentGatewayMessage> width="5%" header={translate('assignments.status')}>
                        {(row) => <AssignmentStatusBadge status={row.status} />}
                    </TableBuilderColumn>

                    <TableBuilderColumn<AssignmentGatewayMessage>
                        width="10%"
                        header={translate('assignments.lastUpload')}
                    >
                        {(row) => (row.dateLastUploaded ? formatDate({ date: row.dateLastUploaded }) : '-')}
                    </TableBuilderColumn>

                    <TableBuilderColumn<AssignmentGatewayMessage>
                        width="10%"
                        header={translate('assignments.lastUploadAdmin')}
                    >
                        {(row) => (row.dateAdminLastUploaded ? formatDate({ date: row.dateAdminLastUploaded }) : '-')}
                    </TableBuilderColumn>

                    <TableBuilderColumn<AssignmentGatewayMessage> width="5%" header="">
                        {(row) => {
                            if (row.status === AssignmentStatus.REQUESTED) {
                                return null;
                            }

                            return <AssignmentsDropdown assignment={row} />;
                        }}
                    </TableBuilderColumn>
                </TableBuilder>
            )}

            <CenteredPagination
                currentPage={locationState.page}
                pageTotal={Math.ceil(
                    assignmentsPaginationQuery.data.total / assignmentsPaginationQuery.data.pageable.size
                )}
                onPageChange={(page) => {
                    setLocationFieldValue('page', page);
                }}
            />
        </Box>
    );
};
