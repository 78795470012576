import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconMailOpened = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M11.445 2.168a1 1 0 0 1 1.11 0l9 6A1 1 0 0 1 22 9v10a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V9a1 1 0 0 1 .445-.832l9-6ZM4 10.868v5.718l3.43-3.43L4 10.867Zm5.57 1.31a.95.95 0 0 0-.032-.022L4.803 9 12 4.202 19.197 9l-4.734 3.156a.878.878 0 0 0-.033.022L12 13.798l-2.43-1.62Zm-.443 2.109L4.064 19.35A1 1 0 0 0 5 20h14a1 1 0 0 0 .936-.65l-5.063-5.063-2.318 1.545a1 1 0 0 1-1.11 0l-2.318-1.545Zm7.443-1.132 3.43 3.43v-5.717l-3.43 2.287Z"
            clipRule="evenodd"
        />
    </svg>
);
