import { translate } from '../../../../common/helpers/translate.helper';
import { InfoPopover } from '../../../../common/InfoPopover/InfoPopover';
import { Box } from '../../../../common/ui/Box';

export const AverageConsumptionTitle = () => {
    return (
        <Box display="flex" alignItems="center" gap={4}>
            {translate('vessel.averageConsumption.title')}
            <InfoPopover>
                <Box width="250px">{translate('vessel.averageConsumption.infoText')}</Box>
            </InfoPopover>
        </Box>
    );
};
