import { AssignmentGatewayMessage } from '../../../../types/AssignmentGatewayMessage';
import { AssignmentStatus } from '../../../../types/AssignmentMessage';
import { CompanyMessage } from '../../../../types/CompanyMessage';
import { ProductContext } from '../../../../types/ProductContext';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { CenteredPageError } from '../../../common/Error/CenteredPageError';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { NoSearchResults } from '../../../common/Search/NoSearchResults/NoSearchResults';
import { Box } from '../../../common/ui/Box';
import { AssignmentStatusBadge } from '../../common/components/AssignmentStatusBadge';
import { useSuppliersPagination } from '../hooks/useSuppliersPagination';
import { SuppliersOverviewStateConfig } from '../SuppliersOverviewPage';

import { AssignmentSupplier } from './AssignmentSupplier';
import { UploadPriceListButton } from './UploadPriceListButton';

export const SuppliersOverviewTable = () => {
    const role = useRole();
    const [locationStateUntyped, { setLocationFieldValue }] = useLocationState();
    const locationState = locationStateUntyped as SuppliersOverviewStateConfig;

    const customerIds =
        locationState.customerIds?.length > 0
            ? locationState.customerIds
            : role.getCompaniesWithType(ProductContext.LUBES).map((company: CompanyMessage) => company.id);

    const suppliersPaginationQuery = useSuppliersPagination(customerIds, locationState.page);

    if (suppliersPaginationQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (suppliersPaginationQuery.isError) {
        return <CenteredPageError />;
    }

    return (
        <Box marginTop={5}>
            {suppliersPaginationQuery.data.content.length === 0 ? (
                <NoSearchResults variant="page" />
            ) : (
                <TableBuilder data={suppliersPaginationQuery.data.content}>
                    <TableBuilderColumn<AssignmentGatewayMessage> header={translate('assignments.name')}>
                        {(row) => <AssignmentSupplier assignment={row} />}
                    </TableBuilderColumn>
                    {!role.isOneCompanyUser(ProductContext.LUBES) ? (
                        <TableBuilderColumn<AssignmentGatewayMessage> header={translate('assignments.company')}>
                            {(row) => row.customer.name}
                        </TableBuilderColumn>
                    ) : null}
                    <TableBuilderColumn<AssignmentGatewayMessage> header={translate('assignments.status')}>
                        {(row) => <AssignmentStatusBadge status={row.status} />}
                    </TableBuilderColumn>

                    <TableBuilderColumn<AssignmentGatewayMessage>
                        header={translate('assignments.lastUploaded')}
                        width="150px"
                    >
                        {(row) =>
                            row.dateLastUploaded
                                ? formatDate({
                                      date: row.dateLastUploaded,
                                  })
                                : null
                        }
                    </TableBuilderColumn>

                    <TableBuilderColumn<AssignmentGatewayMessage> header="" width="200px">
                        {(row) =>
                            row.status !== AssignmentStatus.REQUESTED ? (
                                <UploadPriceListButton assignment={row} />
                            ) : null
                        }
                    </TableBuilderColumn>
                </TableBuilder>
            )}

            <CenteredPagination
                currentPage={locationState.page}
                pageTotal={Math.ceil(suppliersPaginationQuery.data.total / suppliersPaginationQuery.data.pageable.size)}
                onPageChange={(page) => {
                    setLocationFieldValue('page', page);
                }}
            />
        </Box>
    );
};
