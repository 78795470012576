import React, { CSSProperties, forwardRef } from 'react';
import styled from 'styled-components';

const MarkerWrapper = styled.div`
    position: absolute;
    user-select: none;
    transform: translate(-50%, -50%);
    cursor: default;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
`;

interface MarkerProps {
    color: string;
}

const Marker = styled.div<MarkerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    border: 1px solid white;
    padding: 0;
`;

const MarkerCenter = styled.div`
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: white;
`;

interface PositionMarkerProps {
    color: string;
    style?: CSSProperties;
    /**
     * required for google-map-react
     */
    lat?: number;
    /**
     * required for google-map-react
     */
    lng?: number;
}

export const PositionMarker = forwardRef(
    ({ style, color, ...rest }: PositionMarkerProps, ref: React.Ref<HTMLButtonElement>) => (
        <MarkerWrapper ref={ref} as="button" {...rest}>
            <Marker as="button" style={style} color={color}>
                <MarkerCenter />
            </Marker>
        </MarkerWrapper>
    )
);

PositionMarker.displayName = 'PositionMarker';
