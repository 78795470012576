import { AppProviders } from '../common/AppProviders';
import { createDirectiveFromReactComponent } from '../common/helpers/createDirectiveFromReactComponent';

import { SearchResultPage as OriginalSearchResultPage } from './SearchResultPage';

const SearchResultPage = (props) => {
    return (
        <AppProviders>
            <OriginalSearchResultPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(SearchResultPage, 'reactSearchResultPage');
