import { Text } from '../../../../cdl/Text/Text';
import { formatDateTime } from '../../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useEnums } from '../../../../common/hooks/useEnums';
import { usePaymentTermsFormat } from '../../../../common/hooks/usePaymentTermsFormat';
import { Box } from '../../../../common/ui/Box';
import { OfferModel } from '../../../../offer/model/OfferModel';

interface OfferCoreDataProps {
    offer: OfferModel;
}

export const OfferCoreData = ({ offer }: OfferCoreDataProps) => {
    const { getHumanReadableValue } = useEnums();
    const formatPaymentTerms = usePaymentTermsFormat();

    const displaySupplyModeWithDeliveryCosts = () => {
        if (!offer.supplyMode) {
            return '-';
        }

        const supplyMode = getHumanReadableValue('supplyMode', offer.supplyMode);

        if (offer.supplyModeIncludesDeliveryCosts === undefined) {
            return supplyMode;
        }

        const deliveryCostsIncluded = offer.supplyModeIncludesDeliveryCosts
            ? translate('fuelOrder.offerList.supplyModeIncludesDeliveryCosts')
            : translate('fuelOrder.offerList.supplyModeExcludesDeliveryCosts');

        return `${supplyMode} ${deliveryCostsIncluded}`;
    };

    if (!offer.isOrder() && !offer.isQuotedEnquiry()) {
        return (
            <Box display="flex" flexDirection="column" style={{ rowGap: '4px' }}>
                <Text as="p" variant="extraSmall">
                    -
                </Text>
                <Text as="p" variant="extraSmall">
                    -
                </Text>
                <Text as="p" variant="extraSmall">
                    -
                </Text>
            </Box>
        );
    }

    return (
        <Box display="flex" flexDirection="column" style={{ rowGap: '4px' }}>
            <Text as="p" variant="extraSmall">
                {formatDateTime({
                    date: offer.dateDelivery,
                    format: 'll',
                })}
                &nbsp;
                {translate('fuelOrder.offerList.deliveryDateShort')}
            </Text>
            <Text as="p" variant="extraSmall">
                {displaySupplyModeWithDeliveryCosts()}
            </Text>
            <Text as="p" variant="extraSmall">
                {offer.paymentTermReference
                    ? formatPaymentTerms(offer.paymentTermReference, offer.paymentTermReferenceDays)
                    : '-'}
            </Text>
        </Box>
    );
};
