import PropTypes from 'prop-types';

import { LegacyEnumOptionSelect } from '../common/enums/LegacyEnumOptionSelect';
import { Label } from '../common/form-elements/Label/Label';
import { FormattedNumberInput } from '../common/form-elements/NumberInputs/FormattedNumberInput';
import { translate } from '../common/helpers/translate.helper';
import { Box } from '../common/ui/Box';
import { Flex } from '../common/ui/Flex';

/**
 * @deprecated no alternative available yet
 **/

export const PaymentTerms = (props) => {
    const onDaysInputChange = (days) => {
        onChange({
            days: days ? parseInt(days) : null,
            reference: props.reference,
        });
    };

    const onReferenceSelect = (option) => {
        const reference = option.value;

        onChange({
            days: reference === 'PREPAYMENT' ? null : props.days,
            reference: reference,
        });
    };

    const onChange = ({ days, reference }) => {
        props.onChange({
            days,
            reference,
        });
    };

    const inputValue = props.days || '';

    return (
        <Label label={props.label} required={props.required} style={{ width: '100%' }}>
            <Flex flexDirection="row">
                <Box flexGrow={1}>
                    <LegacyEnumOptionSelect
                        value={props.reference}
                        enumType={'paymentTermReference'}
                        onSelect={onReferenceSelect}
                        isDisabled={props.disabled}
                    />
                </Box>
                <Box marginLeft={4}>
                    <FormattedNumberInput
                        style={{ width: '100px' }}
                        disabled={props.disabled || props.reference === 'PREPAYMENT'}
                        placeholder={translate('paymentTermDays.editBox.daysInput.placeholder')}
                        step={1}
                        decimalsLimit={0}
                        allowNegativeValue={false}
                        onChange={onDaysInputChange}
                        value={inputValue}
                        min={1}
                    />
                </Box>
            </Flex>
        </Label>
    );
};

PaymentTerms.propTypes = {
    onChange: PropTypes.func.isRequired,
    reference: PropTypes.string,
    label: PropTypes.string,
    days: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
};
