import PropTypes from 'prop-types';
import { useState } from 'react';
import styled from 'styled-components';

import { TextButton } from '../common/buttons/TextButton.tsx';
import { CL_CLEAR_BLUE_10 } from '../common/colors';
import { translate } from '../common/helpers/translate.helper';
import { IconChevronDown } from '../common/icons/cdl/ChevronDown';
import { IconChevronUp } from '../common/icons/cdl/ChevronUp';
import { MultilineParagraph } from '../common/MultilineParagraph/MultilineParagraph';

const Headline = styled.h3`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 16px;
`;

const Box = styled.div`
    padding: 16px;
    border-radius: 4px;
    background: ${CL_CLEAR_BLUE_10};
`;

const ToggleExpandedButton = ({ icon, onClick, children }) => {
    return (
        <TextButton onClick={onClick}>
            {icon}
            {children}
        </TextButton>
    );
};

ToggleExpandedButton.propTypes = {
    icon: PropTypes.node,
    onClick: PropTypes.func,
    children: PropTypes.node,
};

// Our SVGs have a protected area so they are technically larger than they look like.
// This is why we add some negative margin to compensate this space.
const ButtonWrapper = styled.div`
    margin-top: 16px;
    margin-left: -7px;
`;

const MAX_LENGTH_DEFAULT = 400;

/**
 * @deprecated use CDL CreationComment instead
 **/

export const OrderCreationComment = ({ text, maxLength = MAX_LENGTH_DEFAULT }) => {
    const [expanded, setExpanded] = useState(false);

    const shouldTruncate = text.length > maxLength;

    const truncatedText = shouldTruncate ? `${text.substring(0, maxLength)}[...]` : text;

    return (
        <Box>
            <Headline>{translate('order.creationComment.headline')}</Headline>
            <MultilineParagraph>{expanded ? text : truncatedText}</MultilineParagraph>

            {shouldTruncate && (
                <ButtonWrapper>
                    <ToggleExpandedButton
                        onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
                        icon={expanded ? <IconChevronUp /> : <IconChevronDown />}
                    >
                        {expanded
                            ? translate('order.creationComment.collapseButtonLabel')
                            : translate('order.creationComment.expandButtonLabel')}
                    </ToggleExpandedButton>
                </ButtonWrapper>
            )}
        </Box>
    );
};

OrderCreationComment.propTypes = {
    text: PropTypes.string,
    maxLength: PropTypes.number,
};
