import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useCharacterLimit } from '../../offer/ApprovalRequest/useCharacterLimit';
import { CL_CLEAR_BLUE_100, CL_DARK_BLUE_10, CL_DARK_BLUE_80, SIGNAL_RED_100 } from '../colors';

interface CircleWrapProps {
    size: number;
}

const CircleWrap = styled.div<CircleWrapProps>`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    background: ${CL_DARK_BLUE_10};
    border-radius: 50%;
`;

const MagicCircle = styled.div``;

interface MaskProps {
    size: number;
}

const Mask = styled.div<MaskProps>`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    position: absolute;
    border-radius: 50%;
    clip: ${(props) => `rect(0px, ${props.size}px, ${props.size}px, ${props.size / 2}px)`};
`;

interface FullMaskProps {
    degrees: number;
}

const FullMask = styled(Mask)<FullMaskProps>`
    transform: ${(props) => `rotate(${props.degrees}deg)`};
    transition: transform 0.3s;
`;

enum FillType {
    WARNING = 'WARNING',
    DANGER = 'DANGER',
    NEUTRAL = 'NEUTRAL',
}

const fillStyles = (props: { type: FillType }) => {
    switch (props.type) {
        case FillType.WARNING:
            return `background-color: #FFE101`;
        case FillType.DANGER:
            return `background-color: ${SIGNAL_RED_100};`;
    }

    return `background-color: ${CL_CLEAR_BLUE_100}`;
};

interface FillProps {
    size: number;
    degrees: number;
    type: FillType;
}

const Fill = styled.div<FillProps>`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    position: absolute;
    border-radius: 50%;
    clip: ${(props) => `rect(0px, ${props.size / 2}px, ${props.size}px, 0px)`};
    transform: ${(props) => `rotate(${props.degrees}deg)`};
    transition: transform 0.3s;

    ${fillStyles};
`;

interface InsideCircleProps {
    circleSize: number;
    size: number;
    color: string;
    fontSize: number;
}

const InsideCircle = styled.div<InsideCircleProps>`
    width: ${(props) => props.circleSize}px;
    height: ${(props) => props.circleSize}px;
    border-radius: 50%;
    background: #fff;
    line-height: ${(props) => props.circleSize}px;
    text-align: center;
    margin-top: ${(props) => (props.size - props.circleSize) / 2}px;
    margin-left: ${(props) => (props.size - props.circleSize) / 2}px;
    position: absolute;
    z-index: 100;
    color: ${(props) => props.color};
    font-size: ${(props) => props.fontSize}px;
    font-weight: 400;
`;

const selectFillType = (limitReachedPercent: number) => {
    if (limitReachedPercent >= 1.0) {
        return FillType.DANGER;
    }

    if (limitReachedPercent >= 0.9) {
        return FillType.WARNING;
    }

    return FillType.NEUTRAL;
};

interface CharacterCountProps {
    count: number;
    limit: number;
    size?: number;
    fontSize: number;
}

export const CharacterCount = ({ limit, count, size = 36, fontSize = 12 }: CharacterCountProps) => {
    const { remainingCharacters, limitReached } = useCharacterLimit({
        characterLength: count,
        characterLimit: limit,
    });

    const characterLimitReachedPercent = count / limit;

    let degrees = 360 * (characterLimitReachedPercent / 2);
    if (degrees >= 180) {
        degrees = 180;
    }

    const fillType = selectFillType(characterLimitReachedPercent);

    const circleSize = size * 0.85;

    return (
        <CircleWrap size={size}>
            <MagicCircle>
                <FullMask size={size} degrees={degrees}>
                    <Fill size={size} degrees={degrees} type={fillType} />
                </FullMask>

                <Mask size={size}>
                    <Fill size={size} degrees={degrees} type={fillType} />
                </Mask>

                <InsideCircle
                    color={limitReached ? SIGNAL_RED_100 : CL_DARK_BLUE_80}
                    size={size}
                    circleSize={circleSize}
                    fontSize={fontSize}
                >
                    {remainingCharacters}
                </InsideCircle>
            </MagicCircle>
        </CircleWrap>
    );
};

CharacterCount.propTypes = {
    count: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    size: PropTypes.number,
    fontSize: PropTypes.number,
};
