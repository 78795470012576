import moment from 'moment';

import { ComboBox, ComboBoxProps, DefaultOption } from '../../../cdl/ComboBox/ComboBox';

export interface TimeZoneSelectProps extends Omit<ComboBoxProps<DefaultOption, boolean>, 'onChange' | 'value'> {
    timeZoneId: string;
    onChange: (option: string) => void;
}

export function TimeZoneSelect({ timeZoneId, onChange, ...props }: TimeZoneSelectProps) {
    return (
        <ComboBox
            {...props}
            value={{ label: timeZoneId, value: timeZoneId }}
            loadOptions={(search) =>
                Promise.resolve(
                    moment.tz
                        .names()
                        .filter((name) => name.toLowerCase().includes(search.toLowerCase()))
                        .map((name) => ({ value: name, label: name }))
                )
            }
            onChange={(option) => onChange((option as DefaultOption).value)}
        />
    );
}
