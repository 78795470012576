import { useState } from 'react';

import { CounterOfferItem } from '../../../../../../types/CounterOfferItem';
import { MoneyMessage } from '../../../../../../types/MoneyMessage';
import { SulphurContent } from '../../../../../../types/SulphurContent';
import { Button } from '../../../../../cdl/Button/Button';
import { translate } from '../../../../../common/helpers/translate.helper';
import { OfferModel } from '../../../../../offer/model/OfferModel';
import { useCounterOfferCreate } from '../hooks/useCounterOfferCreate';

import { CounterOfferCreatePopup } from './CounterOfferCreatePopup';

export interface FormikCounterOfferCreateStateItem {
    productId: string;
    productName?: string;
    sulphurContent?: SulphurContent;
    price: MoneyMessage;
    counterPrice: string;
}

export interface FormikCounterOfferCreateState {
    offerId: string;
    customerId: string;
    supplierId: string;
    validityTimeMinutes: string;
    message?: string;
    items: FormikCounterOfferCreateStateItem[];
}

interface CounterOfferCreateStateProps {
    offer: OfferModel;
}

export const CounterOfferCreateState = ({ offer }: CounterOfferCreateStateProps) => {
    const [showPopup, setShowPopup] = useState(false);
    const counterOfferCreateMutation = useCounterOfferCreate();

    const deletedItems = offer.items.map((item, index) => {
        if (item.deleted) {
            return { productId: item.productId, deleted: true, index };
        }
    });

    const counterOfferCreateState: FormikCounterOfferCreateState = {
        offerId: offer.id,
        customerId: offer.customerId,
        supplierId: offer.supplierId,
        validityTimeMinutes: '',
        message: '',
        items: offer.items
            .filter((item) => !item.deleted)
            .map((item) => {
                return {
                    productId: item.productId,
                    productName: item.product?.name,
                    sulphurContent: item.product?.sulphurContent,
                    price: item.price!,
                    counterPrice: '',
                };
            }),
    };

    const onSubmit = async (values: FormikCounterOfferCreateState) => {
        const counterOfferItems: CounterOfferItem[] = values.items.map((item) => {
            return {
                productId: item.productId,
                price: item.price,
                counterPrice: { value: Number(item.counterPrice), currency: item.price.currency },
            };
        });

        if (deletedItems.length) {
            deletedItems.forEach((deletedItem) => {
                if (deletedItem) {
                    counterOfferItems.splice(deletedItem.index, 0, { productId: deletedItem.productId, deleted: true });
                }
            });
        }

        const counterOffer = {
            ...values,
            validityTimeMinutes: Number(values.validityTimeMinutes),
            items: counterOfferItems,
            message: values.message || undefined,
        };

        await counterOfferCreateMutation.mutateAsync(counterOffer);
        setShowPopup(false);
    };

    return (
        <>
            <Button
                onClick={() => {
                    setShowPopup(true);
                }}
                width="100%"
            >
                {translate('counterOffer.createButton')}
            </Button>

            <CounterOfferCreatePopup
                open={showPopup}
                counterOfferCreateState={counterOfferCreateState}
                onDismiss={() => {
                    setShowPopup(false);
                }}
                onSubmit={onSubmit}
            />
        </>
    );
};
