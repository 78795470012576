export const orderTabs = ['drafted', 'enquired', 'ordered', 'delivered', 'canceled'];

export const orderTabStateFilter = {
    drafted: ['DRAFT'],
    enquired: ['OPEN'],
    ordered: ['CLOSED'],
    delivered: ['CLOSED'],
    canceled: ['CANCELED', 'CLOSED'],
};

export const orderTabOfferStateFilter = {
    ordered: ['ORDER', 'CUSTOMER_ADJUSTED', 'SUPPLIER_ADJUSTED', 'CONFIRMED', 'ACKNOWLEDGED'],
    delivered: ['DELIVERED', 'DELIVERY_CONFIRMED', 'INVOICED'],
    canceled: ['CANCELED', 'QUOTE_CANCELED', 'ENQUIRY_CANCELED'],
};
