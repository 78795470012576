import { ApproverMessage } from '../../../../types/ApproverMessage';
import { Text } from '../../../cdl/Text/Text';
import { formatMoney } from '../../../common/helpers/formatMoney.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';

const ApproverEmailAddress = ({ approver }: { approver: ApproverMessage }) => {
    return (
        <Text
            variant="body"
            color="foreground.default"
            style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
            title={approver.emailAddress}
        >
            {approver.emailAddress}
        </Text>
    );
};

interface ApproverCheckboxLabelProps {
    approver: ApproverMessage;
}

export const ApproverLabel = ({ approver }: ApproverCheckboxLabelProps) => {
    if (!approver.approvalLimit) {
        return (
            <Box display="inline-grid" gridTemplateColumns="1fr auto">
                <ApproverEmailAddress approver={approver} />

                <Text
                    variant="body"
                    color="foreground.subtle"
                    style={{
                        flexShrink: 0,
                    }}
                >
                    &nbsp;
                    {translate('approvalRequest.multipleApproversPopup.topLevelApprover')}
                </Text>
            </Box>
        );
    }

    return (
        <Box display="inline-grid" gridTemplateColumns="1fr auto">
            <ApproverEmailAddress approver={approver} />

            <Text
                variant="body"
                color="foreground.subtle"
                style={{
                    flexShrink: 0,
                }}
            >
                &nbsp;
                {translate('approvalRequest.multipleApproversPopup.approverLimit', {
                    amount: formatMoney({
                        value: approver.approvalLimit.value,
                        currency: approver.approvalLimit.currency,
                    }),
                })}
            </Text>
        </Box>
    );
};
