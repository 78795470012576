import { get } from '../../ui/get';
import { theme } from '../../ui/theme';

export const selectStyles = {
    control: (provided, state) => ({
        ...provided,
        boxShadow: 'none',
        borderColor: state.isFocused ? theme.colors['bold-blueish'][7] : theme.colors['dark-blue'][6],
        '&:hover': {
            borderColor: theme.colors['bold-blueish'][7],
        },
        '.highlight &': {
            border: `1px solid ${theme.colors['clear-blue'][0]}`,
        },
        '.invalid.dirty &': {
            border: `1px solid ${theme.colors['signal-red'][0]}`,
        },
    }),

    placeholder: (provided) => ({
        ...provided,
        fontWeight: 'normal',
        margin: '0px 8px',
        color: theme.colors['dark-blue'][3],
    }),

    singleValue: (provided) => ({
        ...provided,
        fontWeight: 'normal',
        paddingLeft: '8px',
    }),

    input: (provided) => ({
        ...provided,
        paddingLeft: '8px',
    }),

    option: (provided, state) => ({
        ...provided,
        padding: '10px 20px',
        borderBottom: '1px solid #DCDFE1',
        color: 'black',
        cursor: 'pointer',
        backgroundColor: state.isFocused ? '#f6f7f7' : 'white',
        '&:hover': {
            backgroundColor: '#f6f7f7',
        },
    }),

    multiValueLabel: (provided) => ({
        ...provided,
        fontWeight: 'normal',
        backgroundColor: theme.colors['dark-blue'][0],
        color: 'white',
        borderRadius: `${get('radii.1')}px 0 0 ${get('radii.1')}px`,
        paddingLeft: '8px',
    }),

    multiValueRemove: (provided) => ({
        ...provided,
        fontWeight: 'normal',
        backgroundColor: theme.colors['dark-blue'][0],
        color: 'white',
        borderRadius: `0 ${get('radii.1')}px ${get('radii.1')}px 0`,
        paddingLeft: '4px',
        paddingRight: '6px',
    }),

    valueContainer: (provided) => ({
        ...provided,
        flexWrap: 'nowrap',
        padding: '2px 4px',
    }),

    menu: (provided) => ({
        ...provided,
        zIndex: 999,
    }),
};
