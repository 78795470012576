import { AssignmentGatewayMessage } from '../../../../types/AssignmentGatewayMessage';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { Error } from '../../../common/Error/Error';
import { translate } from '../../../common/helpers/translate.helper';
import { usePaymentTermsFormat } from '../../../common/hooks/usePaymentTermsFormat';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { useUpdateEffect } from '../../../common/hooks/useUpdateEffect';
import { IconPackage } from '../../../common/icons/cdl/Package';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { PortListing } from '../../../common/PortListing/PortListing';
import { Section } from '../../../common/Section/Section';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { useSupplierPagination } from '../hooks/useSupplierPagination';

export const FuelSuppliersOverviewTable = () => {
    const role = useRole();
    const { context } = useProductContext();

    const [locationState, { setLocationFieldValue }] = useLocationState();

    const customerIdsToQuery = locationState.customerIds.length
        ? locationState.customerIds
        : role.getCompaniesWithType(context).map((it) => it.id);

    const { data, pageTotal, isPending, isError } = useSupplierPagination({
        page: locationState.page,
        customerIds: customerIdsToQuery,
    });

    useUpdateEffect(() => {
        setLocationFieldValue('page', 0);
    }, [locationState.supplierIds]);

    const formatPaymentTerms = usePaymentTermsFormat();

    if (isPending) {
        return (
            <Section>
                <LoadingIndicator />
            </Section>
        );
    }

    if (isError) {
        return (
            <Section>
                <Error />
            </Section>
        );
    }

    if (!data.content?.length) {
        return (
            <Section>
                <TableEmptyState Icon={IconPackage} text={translate('assignments.suppliers.emptylist')} />
            </Section>
        );
    }

    return (
        <div>
            <TableBuilder data={data.content}>
                <TableBuilderColumn<AssignmentGatewayMessage> header={translate('assignments.supplier')}>
                    {(row) => row.supplier.name}
                </TableBuilderColumn>
                <TableBuilderColumn<AssignmentGatewayMessage> header={translate('assignments.portLimitations')}>
                    {(row) => {
                        if (!row.portLimitationsPorts?.length) {
                            return null;
                        }
                        return <PortListing ports={row.portLimitationsPorts} />;
                    }}
                </TableBuilderColumn>
                {!role.isOneCompanyUser() && (
                    <TableBuilderColumn<AssignmentGatewayMessage> header={translate('assignments.company')}>
                        {(row) => row.customer.name}
                    </TableBuilderColumn>
                )}
                <TableBuilderColumn<AssignmentGatewayMessage> header={translate('assignments.defaultPaymentTermDays')}>
                    {(row) => {
                        if (row.defaultPaymentTermReference === undefined) {
                            return null;
                        }
                        return formatPaymentTerms(row.defaultPaymentTermReference, row.defaultPaymentTermReferenceDays);
                    }}
                </TableBuilderColumn>
            </TableBuilder>

            <CenteredPagination
                pageTotal={pageTotal}
                currentPage={locationState.page}
                onPageChange={(page) => setLocationFieldValue('page', page)}
            />
        </div>
    );
};
