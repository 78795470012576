import { DemandInformationVessel } from '../../../../types/DemandInformationMessage';
import { CategoryState } from '../../../../types/ReachInformationMessage';
import { IconArrowRight } from '../../../common/icons/cdl/ArrowRight';
import { Box } from '../../../common/ui/Box';
import { theme } from '../../../common/ui/theme';

import { MultipleStops } from './components/MultipleStops';
import { PortName } from './components/PortName';

interface VesselScheduleProps {
    vessel: DemandInformationVessel;
}

export const VesselScheduleSummary = ({ vessel }: VesselScheduleProps) => {
    const stops = [...vessel.stops];

    if (stops.length === 0) {
        return <div />;
    }

    const firstStop = stops.shift();
    const secondStop = stops.shift();
    const secondStopIsKeyPort = secondStop?.keyPort;

    // find the next key port for the final stop
    let finalStopIndex = stops.findIndex((it) => it.keyPort);

    if (finalStopIndex === -1 || secondStopIsKeyPort) {
        // find the port just before the vessel will be out of reach
        finalStopIndex =
            stops.findIndex((it) =>
                it.categories.some((category) => category.categoryState === CategoryState.OUT_OF_REACH)
            ) - 1;
    }

    if (finalStopIndex === -1) {
        // use the last stop available in the schedule
        finalStopIndex = stops.length - 1;

        // remove last stop if the last reachable stop is the second stop
        if (stops[0] && stops[0].categories.some((it) => it.categoryState === CategoryState.OUT_OF_REACH)) {
            finalStopIndex = -1;
        }
    }

    const finalStop = stops[finalStopIndex];
    const stopsUntilFinalStop = finalStopIndex > 0 ? stops.slice(0, finalStopIndex) : [];

    return (
        <Box display="flex" columnGap={4} alignItems="center">
            {firstStop ? <PortName isFirstStop stop={firstStop} /> : null}
            {secondStop ? (
                <>
                    <IconArrowRight width={16} height={16} color={theme.colors.foreground.subtle} />
                    <PortName isLastStop={finalStopIndex === -1} stop={secondStop} />
                </>
            ) : null}

            {stopsUntilFinalStop.length > 0 && !secondStopIsKeyPort ? (
                <>
                    <IconArrowRight width={16} height={16} color={theme.colors.foreground.subtle} />
                    <MultipleStops stops={stopsUntilFinalStop} />
                </>
            ) : null}

            {finalStop && !secondStopIsKeyPort ? (
                <>
                    <IconArrowRight width={16} height={16} color={theme.colors.foreground.subtle} />
                    <PortName isLastStop stop={finalStop} />
                </>
            ) : null}
        </Box>
    );
};
