import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { CustomerGroupDetailPage } from './CustomerGroupDetailPage';

const CustomerGroupDetailPageComponent = (props: any) => {
    return (
        <AppProviders>
            <CustomerGroupDetailPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(CustomerGroupDetailPageComponent, 'reactCustomerGroupDetailPage');
