import PropTypes from 'prop-types';

import { LocationStateProvider } from '../../common/LocationState/LocationStateProvider';

import { ContractTab } from './contractTabsHelper';

export const ContractOverviewState = ({ children }) => {
    const stateConfig = {
        searchQuery: '',
        customerGroupIds: [],
        tab: ContractTab.CONTRACTED,
        page: 0,
    };

    return <LocationStateProvider stateConfig={stateConfig}>{children}</LocationStateProvider>;
};

ContractOverviewState.propTypes = {
    children: PropTypes.node,
};
