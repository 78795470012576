import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { Item } from '../../../common/models/item.model';
import { generateRandomId } from '../generateRandomId';
import { usePriceCompareState } from '../usePriceCompareState';

export const usePriceCompareOverview = ({ enabled }) => {
    const role = useRole();
    const { context } = useProductContext();

    const companyIdsForType = role.getCompaniesWithType(context).map((it) => it.id);

    return usePriceCompareState({
        initializer: () => {
            const item = new Item();
            item.id = generateRandomId();

            const port = {
                portId: null,
                id: generateRandomId(),
            };

            return {
                items: [item],
                portInformation: [port],
                customerId: role.isOneCompanyUser(context) ? companyIdsForType[0] : null,
            };
        },
        enabled,
    });
};
