import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconSpotMarket = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 61 60"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <rect width={9.6} height={12} x={13.696} y={12} fill="currentColor" opacity={0.6} rx={2} />
        <rect width={9.6} height={12} x={1.696} y={12} fill="currentColor" opacity={0.2} rx={2} />
        <rect width={9.6} height={12} x={49.696} y={12} fill="currentColor" opacity={0.2} rx={2} />
        <rect width={9.6} height={12} x={25.696} y={12} fill="currentColor" rx={2} />
        <rect width={9.6} height={12} x={37.696} y={12} fill="currentColor" opacity={0.6} rx={2} />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M26.256 32.049a1.2 1.2 0 0 1 0-1.697l3.387-3.387.008-.007a1.196 1.196 0 0 1 1.697 0l.007.007 3.387 3.387a1.2 1.2 0 1 1-1.697 1.697l-2.546-2.546-2.545 2.546a1.2 1.2 0 0 1-1.698 0ZM14.256 32.049a1.2 1.2 0 0 1 0-1.697l3.387-3.387.007-.007a1.196 1.196 0 0 1 1.697 0l.008.007 3.386 3.387a1.2 1.2 0 0 1-1.697 1.697L18.5 29.503l-2.546 2.546a1.2 1.2 0 0 1-1.697 0ZM38.256 32.049a1.2 1.2 0 0 1 0-1.697l3.387-3.387.007-.007a1.196 1.196 0 0 1 1.697 0l.008.007 3.387 3.387a1.2 1.2 0 0 1-1.698 1.697L42.5 29.503l-2.546 2.546a1.2 1.2 0 0 1-1.697 0Z"
            clipRule="evenodd"
        />
        <rect width={2.4} height={21.6} x={29.296} y={28.8} fill="currentColor" rx={1.2} />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={2}
            d="M42.496 30v2.4c0 6.627-5.373 12-12 12v0c-6.627 0-12-5.373-12-12V30"
        />
    </svg>
);
