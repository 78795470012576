import { MoneyMessage } from '../../../types/MoneyMessage';

import { formatMoney } from './formatMoney.helper';

export const formatLubesPrice = (price: MoneyMessage): string => {
    return formatMoney({
        value: price.value,
        currency: price.currency,
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
    });
};
