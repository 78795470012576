import { AssignmentGatewayMessage } from '../../../../types/AssignmentGatewayMessage';
import { GetAssignmentsParams } from '../../../common/api/clients/gateway.api';
import { queries } from '../../../common/api/queryKeys/queries';
import { usePaginationQuery } from '../../../common/hooks/usePaginationQuery';

export const useAssignmentsPagination = ({ page, supplierIds, customerIds }: GetAssignmentsParams) => {
    return usePaginationQuery<AssignmentGatewayMessage>({
        queryKey: queries.assignments.pagination({
            supplierIds,
            customerIds,
            page,
        }).queryKey,
        queryFn: queries.assignments.pagination({
            supplierIds,
            customerIds,
            page,
        }).queryFn,
        prefetchQueryKey: queries.assignments.pagination({
            supplierIds,
            customerIds,
            page: page + 1,
        }).queryKey,
        prefetchQueryFn: queries.assignments.pagination({
            supplierIds,
            customerIds,
            page: page + 1,
        }).queryFn,
    });
};
