import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { UserRole } from '../../../types/UserRole';
import { FormikCheckbox } from '../../cdl/Checkbox/FormikCheckbox';
import { FormikInput } from '../../cdl/Input/FormikInput';
import { ReadOnlyField } from '../../cdl/ReadOnlyField/ReadOnlyField';
import { FormikRoleSelect } from '../../common/form-elements/formik/FormikRoleSelect';
import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';
import { LabelText } from '../../common/LabelText/LabelText';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';

import { EditUserAdminFormContent } from './EditUserAdminFormContent';
import { FormikEditUserState } from './EditUserForm';
import { FormikEditUserFormCompanyMultiSelect } from './FormikEditUserCompanyMultiSelect';

export const EditUserFormContentWrapper = styled(Flex)`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const EditUserFormContent = () => {
    const { values, setValues } = useFormikContext<FormikEditUserState>();
    const role = useRole();

    const resetCompanyIdsOnRoleChange = (role?: string) => {
        if (role === UserRole.GROUP_ADMIN) {
            setValues({
                ...values,
                companyIds: [],
            });
        }
    };

    const isEditingOwnUser = values.id === role.user.id;

    if (role.isAdmin()) {
        return <EditUserAdminFormContent onRoleChange={resetCompanyIdsOnRoleChange} />;
    }

    if (isEditingOwnUser) {
        return (
            <EditUserFormContentWrapper>
                <ReadOnlyField value={values.emailAddress} label={translate('user.mail')} />

                <Box display="flex" flexDirection="row" style={{ gap: '16px' }}>
                    <FormikInput name="firstName" autoComplete="off" label={translate('user.firstname')} />
                    <FormikInput name="lastName" autoComplete="off" label={translate('user.lastname')} />
                </Box>
                <FormikInput name="phoneNumber" autoComplete="off" label={translate('user.phoneInput')} />
                <Box display="grid" gridTemplateRows="1fr 1fr" gridGap="8px">
                    <LabelText>{translate('user.mailSettings')}</LabelText>
                    <FormikCheckbox name="newsletter" label={translate('user.news')} />
                </Box>
            </EditUserFormContentWrapper>
        );
    }

    return (
        <EditUserFormContentWrapper>
            <ReadOnlyField value={values.emailAddress} label={translate('user.mail')} />

            <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap="16px">
                <ReadOnlyField value={values.firstName} label={translate('user.firstname')} />
                <ReadOnlyField value={values.lastName} label={translate('user.lastname')} />
            </Box>

            <FormikRoleSelect name="role" label={translate('user.role')} onRoleChange={resetCompanyIdsOnRoleChange} />
            {values.role && values.role !== 'GROUP_ADMIN' ? <FormikEditUserFormCompanyMultiSelect /> : null}
        </EditUserFormContentWrapper>
    );
};
