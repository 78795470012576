import PropTypes from 'prop-types';

import { formatCompanyName } from '../helpers/formatCompanyName.helper';
import { ListingWithMore } from '../ListingWithMore/ListingWithMore';

export const CompanyListing = ({ companies, showCount, includeType }) => {
    if (!companies) {
        return null;
    }
    const formattedCompanies = companies.map((company) => formatCompanyName({ company, includeType }));

    return <ListingWithMore showCount={showCount}>{formattedCompanies}</ListingWithMore>;
};

CompanyListing.propTypes = {
    companies: PropTypes.array,
    showCount: PropTypes.number,
    includeType: PropTypes.bool,
};
