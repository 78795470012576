import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';

import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { getOrders } from '../../../common/api/clients/gateway.api';
import { DataTable } from '../../../common/DataTable/DataTable';
import { Error } from '../../../common/Error/Error';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { translateOrderOfferState } from '../../../common/helpers/translateOrderOfferState.helper';
import { useNavigation } from '../../../common/hooks/useNavigation';
import { useRole } from '../../../common/hooks/useRole';
import { useUpdateEffect } from '../../../common/hooks/useUpdateEffect';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Section } from '../../../common/Section/Section';
import { Port } from '../../../port/Port';
import { RecentActivitiesEmptyState } from '../../common/components/RecentActivitiesEmptyState';

export const LubesCustomerOrderTable = () => {
    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const tableRef = useRef<HTMLDivElement>(null);
    const role = useRole();
    const { navigate } = useNavigation();

    const customerOrdersQuery = useQuery({
        queryKey: ['customer', 'orders', page],
        queryFn: () => getOrders({ page, read: false }),
        placeholderData: keepPreviousData,
    });

    const hasMoreData = customerOrdersQuery.data
        ? customerOrdersQuery.data.total >
          (customerOrdersQuery.data.pageable.page + 1) * customerOrdersQuery.data.pageable.size
        : false;

    useEffect(() => {
        if (hasMoreData) {
            queryClient.prefetchQuery({
                queryKey: ['customer', 'orders', page + 1],
                queryFn: () => getOrders({ page: page + 1, read: false }),
            });
        }
    }, [customerOrdersQuery.isPending, page, queryClient, hasMoreData]);

    // Prevent scrolling down on initial load
    useUpdateEffect(() => {
        if (tableRef.current) {
            window.scrollTo(0, tableRef.current.offsetTop);
        }
    }, [page]);

    if (customerOrdersQuery.isPending) {
        return (
            <Section title={translate('dashboard.recentactivities')}>
                <LoadingIndicator />
            </Section>
        );
    }

    if (customerOrdersQuery.isError) {
        return (
            <Section title={translate('dashboard.recentactivities')}>
                <Error />
            </Section>
        );
    }

    if (!customerOrdersQuery.data.content.length) {
        return (
            <Section title={translate('dashboard.recentactivities')}>
                <RecentActivitiesEmptyState />
            </Section>
        );
    }

    const openOrder = (order: any) => {
        if (order.isClosed()) {
            navigate('base.offerlist-offer', {
                id: order.chosenOfferId,
            });
        } else if (order.isDraft() && !role.isAdmin() && role.hasWriteRights(order.customerId)) {
            navigate('lubes-create-enquiry-draft', {
                id: order.id,
            });
        } else {
            navigate('base.orderlist-order', {
                id: order.id,
            });
        }
    };

    const pageTotal = Math.ceil(customerOrdersQuery.data.total / customerOrdersQuery.data.pageable.size);

    const columns = [
        {
            label: translate('order.ordernumber'),
            width: '10%',
            value: (order: any) => order.orderNumber,
        },
        {
            label: translate('order.company'),
            width: '10%',
            condition: !role.isOneCompanyUser(),
            value: (order: any) => order.customer.name,
        },
        {
            label: translate('order.vessel'),
            width: '20%',
            value: (order: any) => formatVessel({ vessel: order.vessel, short: true }),
        },
        {
            label: translate('order.port'),
            width: '20%',
            value: function renderPort(order: any) {
                if (!order.port) {
                    return '-';
                }

                return <Port port={order.port} vesselId={order.vesselId} locode={false} showTooltip />;
            },
        },
        {
            label: translate('order.dateDeliveryShort'),
            width: '15%',
            value: function renderDeliveryDate(order: any) {
                if (!order.dateDelivery) {
                    return '-';
                }
                return formatDate({
                    date: order.dateDelivery,
                    timeZone: 'UTC',
                });
            },
        },
        {
            label: translate('order.status'),
            width: '15%',
            value: function renderOfferState(order: any) {
                const offerState = translateOrderOfferState(order, 'CUSTOMER');

                return offerState || '-';
            },
        },
        {
            label: translate('order.offercount'),
            width: '5%',
            value: (order: any) => order.offerCount,
        },
    ].filter(({ condition = true }) => condition);

    return (
        <div ref={tableRef}>
            <Section title={translate('dashboard.recentactivities')}>
                <DataTable
                    rows={customerOrdersQuery.data.content}
                    columns={columns}
                    onRowClick={({ row }) => openOrder(row)}
                />

                <CenteredPagination pageTotal={pageTotal} currentPage={page} onPageChange={(page) => setPage(page)} />
            </Section>
        </div>
    );
};
