import PropTypes from 'prop-types';
import { createContext } from 'react';
import { ReactNode } from 'react';

import { StateConfig } from './StateConfig';
import { useGroupedLocationState } from './useGroupedLocationState';

interface LocationStateHelper {
    setLocationState: (stateConfig: StateConfig) => void;
    setLocationFieldValue: (field: string, value: any) => void;
}

type LocationState = [StateConfig, LocationStateHelper];

export const LocationStateContext = createContext<LocationState>([
    {},
    {
        setLocationState: () => {
            throw Error('LocationStateContext not initialized');
        },
        setLocationFieldValue: () => {
            throw Error('LocationStateContext not initialized');
        },
    },
]);

export const LocationStateProvider = ({ stateConfig, children }: { stateConfig: StateConfig; children: ReactNode }) => {
    const [locationState, setLocationState] = useGroupedLocationState(stateConfig);

    const setLocationFieldValue = (field: string, value: any) => {
        setLocationState((state) => ({
            ...state,
            [field]: value,
        }));
    };

    return (
        <LocationStateContext.Provider value={[locationState, { setLocationState, setLocationFieldValue }]}>
            {children}
        </LocationStateContext.Provider>
    );
};

LocationStateProvider.propTypes = {
    stateConfig: PropTypes.object,
    children: PropTypes.node,
};
