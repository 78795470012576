import PropTypes from 'prop-types';
import { createContext } from 'react';

import { ngDeps } from '../ngDeps';

export const AngularDependenciesContext = createContext({});
export const AngularDependenciesContextProvider = ({ children, dependencies = ngDeps }) => {
    return <AngularDependenciesContext.Provider value={dependencies}>{children}</AngularDependenciesContext.Provider>;
};

AngularDependenciesContextProvider.propTypes = {
    children: PropTypes.node,
    dependencies: PropTypes.object,
};
