import { SupplierMessage } from '../../../../../types/SupplierMessage';
import { translate } from '../../../../common/helpers/translate.helper';
import { useRole } from '../../../../common/hooks/useRole';
import { IconOilBarrel } from '../../../../common/icons/cdl/OilBarrel';
import { IconSpotMarket } from '../../../../common/icons/SpotMarket';
import { Box } from '../../../../common/ui/Box';
import { theme } from '../../../../common/ui/theme';

import { SupplierGroupLogo } from './SupplierGroupLogo';
import { SupplierSelectBox } from './SupplierSelectBox';

const UnknownSupplierIcon = () => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="48px"
            height="48px"
            borderRadius={1}
            backgroundColor="background.subtle"
        >
            <IconOilBarrel width={24} height={24} color={theme.colors.foreground.subtle} />
        </Box>
    );
};

interface SuppliersSelectProps {
    availableSuppliers: SupplierMessage[];
    defaultSupplierId?: string;
    receiverSupplierIds: string[];
    setReceiverSupplierIds: (ids: string[]) => void;
    directOrderAvailable?: boolean;
    spot?: boolean;
    setSpot: (spot: boolean) => void;
    spotAvailable?: boolean;
    customerId?: string;
}

export const SuppliersSelect = ({
    availableSuppliers,
    defaultSupplierId,
    receiverSupplierIds,
    setReceiverSupplierIds,
    spot,
    setSpot,
    spotAvailable,
    directOrderAvailable,
    customerId,
}: SuppliersSelectProps) => {
    const role = useRole();

    return (
        <Box marginTop={5} display="flex" gap={6} flexWrap="wrap">
            {role.isSpotAllowed(customerId) ? (
                <SupplierSelectBox
                    title={translate('order.spotmarket')}
                    description={translate('order.noncontractedsuppliers')}
                    selected={!!spot}
                    disabled={!spotAvailable}
                    onClick={() => setSpot(!spot)}
                    image={<IconSpotMarket width={48} height={48} />}
                />
            ) : null}
            {availableSuppliers.map((supplier) => {
                const isDefaultSupplier = supplier.id === defaultSupplierId;
                const isSelected = receiverSupplierIds.includes(supplier.id);

                const handleSupplierClick = () => {
                    if (isSelected) {
                        setReceiverSupplierIds(receiverSupplierIds.filter((id) => id !== supplier.id));
                    } else {
                        setReceiverSupplierIds([...receiverSupplierIds, supplier.id]);
                    }
                };

                return (
                    <SupplierSelectBox
                        key={supplier.id}
                        title={supplier.name}
                        description={translate('order.contractedsupplier')}
                        selected={isSelected}
                        disabled={!directOrderAvailable}
                        defaultTag={isDefaultSupplier}
                        onClick={handleSupplierClick}
                        liteOverlay={role.usesCloselinkLite()}
                        image={
                            supplier.supplierGroup.logo ? (
                                <SupplierGroupLogo
                                    imageUrl={supplier.supplierGroup.logo.thumbUrl}
                                    supplierName={supplier.name}
                                />
                            ) : (
                                <UnknownSupplierIcon />
                            )
                        }
                    />
                );
            })}
        </Box>
    );
};
