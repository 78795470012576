import { useRole } from '../../common/hooks/useRole';
import { useCustomerGroupUpdate } from '../customer/detail/hooks/useCustomerGroupUpdate';
import { useSupplierGroupUpdate } from '../supplier/detail/hooks/useSupplierGroupUpdate';

export const useAcceptGtc = () => {
    const role = useRole();
    const customerGroupUpdate = useCustomerGroupUpdate();
    const supplierGroupUpdate = useSupplierGroupUpdate();

    return async (newsletter: boolean): Promise<void> => {
        if (role.isCustomer()) {
            role.group.mailSettings.newsletter = newsletter;
            role.group.gtcAccepted = true;

            // @ts-ignore
            return customerGroupUpdate.mutateAsync(role.group);
        } else if (role.isSupplier()) {
            role.group.mailSettings.newsletter = newsletter;
            role.group.gtcAccepted = true;

            // @ts-ignore
            return supplierGroupUpdate.mutateAsync(role.group);
        }
    };
};
