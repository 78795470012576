PopupService.$inject = ['$uibModal'];

function PopupService($uibModal) {
    return {
        createAcceptTermsModal: function (onSuccess) {
            const modal = $uibModal.open({
                animation: true,
                templateUrl: 'group/modal/accept-terms.html',
                size: 'md',
                controller: 'AcceptTermsController',
                keyboard: false,
                backdrop: 'static',
            });
            modal.result.then(onSuccess, handleOnError);
        },
    };

    function handleOnError() {}
}

export default {
    name: 'PopupService',
    fn: PopupService,
};
