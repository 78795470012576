import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { FuelCreateQuoteFlow as OriginalFuelCreateQuoteFlow } from './FuelCreateQuoteFlow';

const FuelCreateQuoteFlow = (props) => {
    return (
        <AppProviders>
            <OriginalFuelCreateQuoteFlow {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(FuelCreateQuoteFlow, 'reactFuelCreateQuoteFlow');
