import styled from 'styled-components';

import { Flex } from '../ui/Flex';

import { Error } from './Error';

const Wrapper = styled(Flex)`
    justify-content: center;
    align-items: center;
    height: calc(100vh - 144px);
`;
// Height is viewport without Topbar and Page padding

export const CenteredPageError = () => {
    return (
        <Wrapper>
            <Error />
        </Wrapper>
    );
};
