import { ApiKeyMessage } from '../../../../../types/ApiKeyMessage';
import { UserType } from '../../../../../types/UserType';
import { Switch } from '../../../../cdl/Switch/Switch';
import { useApiKeyOptimisticUpdate } from '../hooks/useApiKeyOptimisticUpdate';

interface ApiKeySwitchProps {
    apiKey: ApiKeyMessage;
    groupId: string;
    userType: UserType.CUSTOMER | UserType.SUPPLIER;
}

export const ApiKeySwitch = (props: ApiKeySwitchProps) => {
    const apiKeyOptimisticUpdateMutation = useApiKeyOptimisticUpdate(props.groupId, props.userType);

    const onSwitchChange = (selected: boolean) => {
        apiKeyOptimisticUpdateMutation.mutate({
            id: props.apiKey.id,
            apiKeyMessage: {
                ...props.apiKey,
                disabled: !selected,
            },
        });
    };

    return <Switch checked={!props.apiKey.disabled} onChange={onSwitchChange} />;
};
