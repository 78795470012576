import { useState } from 'react';

import { ContractMessage } from '../../../../types/ContractMessage';
import { IconButton } from '../../../cdl/IconButton/IconButton';
import { translate } from '../../../common/helpers/translate.helper';
import { IconSend } from '../../../common/icons/cdl/Send';

import { SendContractPopup } from './SendContractPopup';

interface SendContractButtonProps {
    contract: ContractMessage;
}

export const SendContractButton = ({ contract }: SendContractButtonProps) => {
    const [showPopup, setShowPopup] = useState(false);

    return (
        <>
            <IconButton
                icon={IconSend}
                onClick={() => setShowPopup(true)}
                tooltipLabel={translate('contracts.sendContract.sendButton')}
            />
            <SendContractPopup
                contract={contract}
                isOpen={showPopup}
                onDismiss={() => {
                    setShowPopup(false);
                }}
            />
        </>
    );
};
