import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { queryKeys } from '../../../../../common/api/queryKeys';
import { useAbly } from '../../../../../common/hooks/useAbly';
import { useRole } from '../../../../../common/hooks/useRole';
import { OfferModel } from '../../../../../offer/model/OfferModel';

export const useCounterOfferUpdates = (offer: OfferModel) => {
    const queryClient = useQueryClient();
    const role = useRole();
    const { subscribe, unsubscribe } = useAbly();

    useEffect(() => {
        if (!role) {
            return;
        }

        const topic = role.isSupplier()
            ? `suppliers:${offer.supplierId}:offers:${offer.id}:counter-offers`
            : `customers:${offer.customerId}:offers:${offer.id}:counter-offers`;

        subscribe(topic, () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.fuelOrderOffers() });
        });

        return () => unsubscribe(topic);
    });
};
