import { MoneyMessage } from '../../../types/MoneyMessage';

export const formatLubesTotalPrice = (total: MoneyMessage): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: total.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(total.value);
};
