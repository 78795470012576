import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useState } from 'react';

import { FileManagementResponse } from '../../../../types/FileManagementResponse';

import { useAttachmentsConfig } from './useAttachmentsConfig';

type UseFileUploadResult = UseMutationResult<FileManagementResponse, unknown, File> & {
    progress: number;
};

export const useFileUpload = (): UseFileUploadResult => {
    const [progress, setProgress] = useState(0);
    const { uploadFile, invalidateCache } = useAttachmentsConfig();

    const mutation = useMutation({
        mutationFn: (file: File) =>
            uploadFile(file, (event) => {
                if (event.progress) {
                    setProgress(event.progress * 100);
                }
            }),

        onSuccess: () => {
            return invalidateCache();
        },
    });

    return {
        ...mutation,
        progress,
    };
};
