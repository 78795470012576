import { getPorts } from '../../../common/api/clients/port.api';
import { formatPort } from '../../../common/helpers/formatPort.helper';
import { Schedule } from '../../../common/hooks/useSchedules';
import { PortOptionSelectWithSchedule } from '../PortDetailSelectWithSchedule';

export const getPortOptionsWithSearchQuery = async (
    searchQuery: string,
    schedule: Schedule[],
    vesselId?: string
): Promise<PortOptionSelectWithSchedule[]> => {
    const response = await getPorts({ searchQuery });

    const portsWithSchedule: PortOptionSelectWithSchedule[] = [];
    const portsWithoutSchedule: PortOptionSelectWithSchedule[] = [];

    response.content.forEach((port) => {
        const scheduleEntries = schedule.filter((scheduleEntry) => scheduleEntry.port?.id === port.id);

        const formattedPortName = formatPort({ port, short: true });

        if (schedule && scheduleEntries?.length) {
            scheduleEntries.forEach((scheduleEntry) => {
                portsWithSchedule.push({
                    type: 'schedule',
                    label: formattedPortName,
                    value: port.id,
                    port,
                    vesselId,
                    scheduleEntry,
                    schedulesLength: schedule.length,
                });
            });
        } else {
            portsWithoutSchedule.push({
                type: 'noSchedule',
                label: formattedPortName,
                value: port.id,
                port,
                vesselId,
            });
        }
    });

    return [...portsWithSchedule, ...portsWithoutSchedule];
};
