import { Formik } from 'formik';

import { PaymentTermReference } from '../../../../types/PaymentTermReference';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../../common/helpers/translate.helper';
import { useAssignmentCreate } from '../hooks/useAssignmentCreate';

import { AssignmentBaseForm, FormikAssignmentState } from './AssignmentBaseForm';
import { validateAssignment } from './validateAssignment';

interface CreateAssignmentDrawerProps {
    onSuccess: () => void;
}

export const CreateAssignmentDrawer = (props: CreateAssignmentDrawerProps) => {
    const { addErrorToast } = useToasts();

    const createAssignmentMutation = useAssignmentCreate();

    const initialValues: FormikAssignmentState = {
        customerId: '',
        supplierId: '',
        portLimitations: [],
        defaultPaymentTermReference: undefined,
        defaultPaymentTermReferenceDays: '',
    };

    const handleSubmit = (values: FormikAssignmentState) => {
        const requestMessage = {
            customerId: values.customerId,
            supplierId: values.supplierId,
            portLimitations: values.portLimitations.length ? values.portLimitations : undefined,
            defaultPaymentTermReference: values.defaultPaymentTermReference || undefined,
            defaultPaymentTermReferenceDays:
                values.defaultPaymentTermReference &&
                values.defaultPaymentTermReference !== PaymentTermReference.PREPAYMENT
                    ? values.defaultPaymentTermReferenceDays
                    : undefined,
        };

        createAssignmentMutation.mutate(requestMessage, {
            onSuccess: () => {
                props.onSuccess();
            },
            onError: () => {
                addErrorToast(translate('assignments.toasts.createError'));
            },
        });
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validateAssignment}>
            <AssignmentBaseForm title={translate('assignments.admin.invite')} />
        </Formik>
    );
};
