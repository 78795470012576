import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { AgentDetailPage } from './AgentDetailPage';

const AgentDetailPageDirective = (props: any) => {
    return (
        <AppProviders>
            <AgentDetailPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(AgentDetailPageDirective, 'reactAgentDetailPage');
