import { Tag } from '../../../../cdl/Tag/Tag';

interface DifferenceProps {
    fixedAverageConsumption: number;
    dynamicAverageConsumption: number;
}

export const Difference = ({ fixedAverageConsumption, dynamicAverageConsumption }: DifferenceProps) => {
    const change = dynamicAverageConsumption - fixedAverageConsumption;
    const differenceInPercentage = (change / fixedAverageConsumption) * 100;

    const formattedDifferenceInPercentage = `${differenceInPercentage.toFixed(1)}%`;

    if (differenceInPercentage > 0) {
        return <Tag variant="negative">+{formattedDifferenceInPercentage}</Tag>;
    }
    if (differenceInPercentage < 0) {
        return <Tag variant="positive">{formattedDifferenceInPercentage}</Tag>;
    }

    return <Tag variant="default">{formattedDifferenceInPercentage}</Tag>;
};
