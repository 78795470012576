import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteSurcharge } from '../../../../common/api/clients/surcharge.api';
import { queries } from '../../../../common/api/queryKeys/queries';

export const useSurchargeDelete = (groupId: string) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (surchargeId: string) => deleteSurcharge(surchargeId),

        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: queries.supplierGroups.detail(groupId)._ctx.surcharges.queryKey,
            });
        },
    });
};
