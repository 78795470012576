import PropTypes from 'prop-types';

import { translate } from '../helpers/translate.helper';
import { Table } from '../Table/Table';
import { Tr } from '../Table/Tr';

import { Products } from './Products';
import { Surcharges } from './Surcharges';
import { Total } from './Total';

const HeaderRow = ({ showPrices, showOnlyInitialValues }) => {
    return (
        <Tr>
            <th>{translate('order.pos')}</th>
            <th>{translate('order.product')}</th>
            <th>{translate('order.category')}</th>
            <th>{translate('order.isoSpec')}</th>
            {!showOnlyInitialValues ? <th>{translate('order.energyContent')}</th> : null}
            {!showOnlyInitialValues ? <th>{translate('order.physicalSupplier')}</th> : null}
            <th>{translate('order.volume')}</th>
            {showPrices ? <th>{translate('order.price')}</th> : null}
            {showPrices ? <th>{translate('order.total')}</th> : null}
        </Tr>
    );
};

HeaderRow.propTypes = {
    showPrices: PropTypes.bool,
    showOnlyInitialValues: PropTypes.bool,
};

export const FuelShowOfferProductTable = ({ offer, showPrices = true, showOnlyInitialValues = false }) => {
    return (
        <Table>
            <thead>
                <HeaderRow showPrices={showPrices} showOnlyInitialValues={showOnlyInitialValues} />
            </thead>
            <tbody>
                <Products items={offer.items} showPrices={showPrices} showOnlyInitialValues={showOnlyInitialValues} />

                <Surcharges
                    surcharges={offer.surcharges}
                    offset={offer.items.length + offer.samplekits.length}
                    showPrices={showPrices}
                />
                <Total offer={offer} showPrices={showPrices} showOnlyInitialValues={showOnlyInitialValues} />
            </tbody>
        </Table>
    );
};

FuelShowOfferProductTable.propTypes = {
    offer: PropTypes.object.isRequired,
    showPrices: PropTypes.bool,
    showOnlyInitialValues: PropTypes.bool,
};
