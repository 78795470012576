import { ProductGroupReport, ReportsMessage } from '../../../types/SpecificReportMessage';
import { Text } from '../../cdl/Text/Text';
import { useEnums } from '../../common/hooks/useEnums';
import { useProductContext } from '../../common/hooks/useProductContext';
import { Box } from '../../common/ui/Box';

import { VolumeBar } from './VolumeBar';

interface VolumesBarDiagramProps {
    title: string;
    generalVolume: number;
    statistics: ReportsMessage[] | ProductGroupReport[];
}

export const VolumesBarDiagram = ({ title, generalVolume, statistics }: VolumesBarDiagramProps) => {
    const { getHumanReadableValue } = useEnums();
    const { isLubes } = useProductContext();
    return (
        <div>
            <Text variant="subtitle1">{title}</Text>

            <Box display="grid" gridTemplateColumns="1fr" rowGap={4} marginTop={4}>
                {statistics.map((report) => {
                    const percentage = (report.volume / generalVolume) * 100;

                    return (
                        <VolumeBar
                            key={'context' in report ? report.context.id : report.productGroup}
                            volume={report.volume}
                            label={
                                'context' in report
                                    ? report.context.name
                                    : isLubes
                                      ? getHumanReadableValue('productGroup', report.productGroup)
                                      : report.productGroup
                            }
                            percentage={percentage}
                        />
                    );
                })}
            </Box>
        </div>
    );
};
