import { createContext } from 'react';

import { AttachmentsConfig } from '../types/AttachmentsConfig';

const defaultContextValue = {
    uploadFile: () => Promise.reject(),
    deleteFile: () => Promise.reject(),
    downloadFile: () => Promise.reject(),
    invalidateCache: () => Promise.reject(),
    isAdmin: false,
    hasWriteRights: false,
    enableDownloadUploadedFiles: true,
};

export const AttachmentsConfigContext = createContext<AttachmentsConfig>(defaultContextValue);
