import { PricingCodeMessage } from '../../../types/ContractMessage';
import { MoneyMessage } from '../../../types/MoneyMessage';
import { Text } from '../../cdl/Text/Text';
import { FormattedPrice } from '../../vessel/VesselDetailPage/PriceCompare/components/FormattedPrice';
import { formatDate } from '../helpers/formatDate.helper';
import { translate } from '../helpers/translate.helper';

import { FuelPriceTitle } from './FuelPriceTitle';

interface FuelMarketPriceDisplayProps {
    pricingCode?: PricingCodeMessage;
    priceDate?: string;
    price?: MoneyMessage;
    hasPlattsCredentials: boolean;
    customerId: string;
    positive: boolean;
    label: string;
}

export const FuelMarketPriceDisplay = ({
    pricingCode,
    priceDate,
    price,
    hasPlattsCredentials,
    customerId,
    positive,
    label,
}: FuelMarketPriceDisplayProps) => {
    if (pricingCode?.code === undefined || priceDate === undefined) {
        return (
            <>
                <FuelPriceTitle
                    price={price}
                    hasPlattsCredentials={hasPlattsCredentials}
                    label={label}
                    customerId={customerId}
                />

                <FormattedPrice price={price} positive={positive} />
            </>
        );
    }
    const tooltipLabel = (
        <Text
            dangerouslySetInnerHTML={{
                __html: translate('platts.marketPriceTooltip', {
                    code: pricingCode.code,
                    priceDate: formatDate({ date: priceDate }),
                }),
            }}
        />
    );
    return (
        <>
            <FuelPriceTitle
                price={price}
                hasPlattsCredentials={hasPlattsCredentials}
                label={label}
                customerId={customerId}
                tooltipLabel={tooltipLabel}
            />

            <FormattedPrice price={price} positive={positive} />
        </>
    );
};
