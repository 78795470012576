import PropTypes from 'prop-types';

import { translate } from '../../common/helpers/translate.helper';

import { CommentPopup } from './CommentPopup';

export const ApprovePopup = ({ open, onDismiss, onSubmit, requestInProgress }) => {
    return (
        <CommentPopup
            open={open}
            onDismiss={onDismiss}
            onSubmit={onSubmit}
            requestInProgress={requestInProgress}
            headline={translate('approvalRequest.approvePopup.headline')}
            description={translate('approvalRequest.approvePopup.description')}
            inputLabel={translate('approvalRequest.approvePopup.commentInput.label')}
        />
    );
};

ApprovePopup.propTypes = {
    open: PropTypes.bool,
    onDismiss: PropTypes.func,
    onSubmit: PropTypes.func,
    requestInProgress: PropTypes.bool,
};
