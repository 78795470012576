import { MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';

import { IconCircle } from '../icons/cdl/Circle';
import { IconCircleCheck } from '../icons/cdl/CircleCheck';
import { Box, BoxProps } from '../ui/Box';
import { get } from '../ui/get';
import { theme } from '../ui/theme';

interface SelectBoxProps extends Pick<BoxProps, 'height' | 'width' | 'padding'> {
    children: ReactNode;
    selected?: boolean;
    disabled?: boolean;
    onClick?: MouseEventHandler;
}

const getBorderBoxShadow = (props: SelectBoxProps) => {
    let boxShadowColor = theme.colors.border.default;

    if (props.selected) {
        boxShadowColor = theme.colors.accent.foreground;
    }

    if (props.disabled) {
        boxShadowColor = theme.colors.border.subtle;
    }

    return props.selected ? `0 0 0 2px ${boxShadowColor}` : `0 0 0 1px ${boxShadowColor}`;
};

const IconWrapper = styled.div<Pick<SelectBoxProps, 'selected' | 'disabled'>>`
    position: absolute;
    top: 8px;
    right: 8px;
    transition: color 100ms;
    color: ${(props) => (props.selected ? get('colors.accent.foreground') : get('colors.foreground.subtle'))};
    color: ${(props) => (props.disabled ? get('colors.foreground.disabled') : null)};
`;

const ClickableBox = styled(Box as any)<SelectBoxProps>`
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : '100%')};
    text-align: left;
    white-space: normal;
    background-color: ${get('colors.background.default')};
    color: ${(props) => (props.disabled ? get('colors.foreground.disabled') : get('colors.foreground.default'))};
    position: relative;
    border-radius: 4px;
    padding: ${(props) => (props.padding !== undefined ? props.padding : '16px')};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    box-shadow: ${getBorderBoxShadow};
    transition: box-shadow 100ms ease-out;

    &:hover {
        box-shadow: ${getBorderBoxShadow},
            ${(props) => (!props.disabled ? '0 8px 24px -2px rgba(25, 54, 67, 0.2)' : null)};

        ${IconWrapper} {
            color: ${(props) => (!props.disabled && !props.selected ? get('colors.foreground.default') : null)};
        }
    }
`;
export const SelectBox = ({ selected, disabled, children, onClick, ...rest }: SelectBoxProps) => {
    return (
        <ClickableBox selected={selected} disabled={disabled} onClick={disabled ? undefined : onClick} {...rest}>
            <IconWrapper selected={selected} disabled={disabled}>
                {selected ? <IconCircleCheck width={28} height={28} /> : <IconCircle width={28} height={28} />}
            </IconWrapper>

            {children}
        </ClickableBox>
    );
};
