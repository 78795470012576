import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiKeyMessage } from '../../../../../types/ApiKeyMessage';
import { UserType } from '../../../../../types/UserType';
import { updateApiKey } from '../../../../common/api/clients/apiKey.api';
import { queries as queryFactory } from '../../../../common/api/queryKeys/queries';

export const useApiKeyOptimisticUpdate = (groupId: string, userType: UserType) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateApiKey,
        onMutate: async (variables) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: queryFactory.apiKeys.list(groupId, userType).queryKey });

            const queryKey = queryFactory.apiKeys.list(groupId, userType).queryKey;
            const previousApiKeys = queryClient.getQueryData<ApiKeyMessage[]>(queryKey) ?? [];
            const apiKeysToUpdate = [...previousApiKeys];

            const index = previousApiKeys.findIndex((apiKey) => variables.apiKeyMessage.id === apiKey.id);
            if (index === -1) {
                return { previousApiKeys };
            }

            apiKeysToUpdate[index] = {
                ...previousApiKeys[index],
                ...variables.apiKeyMessage,
            };

            queryClient.setQueryData(queryKey, apiKeysToUpdate);

            return { previousApiKeys };
        },
        onError: (err, variables, context) => {
            const queryKey = queryFactory.apiKeys.list(groupId, userType).queryKey;

            queryClient.setQueryData(queryKey, context!.previousApiKeys);
        },
        onSettled: () => {
            const queryKey = queryFactory.apiKeys.list(groupId, userType).queryKey;

            queryClient.invalidateQueries({ queryKey });
        },
    });
};
