import styled from 'styled-components';

import { Text } from '../../Text/Text';

export const CollapsableTitle = styled(Text)`
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
