import { animated, config, useTransition } from '@react-spring/web';
import useComponentSize from '@rehooks/component-size';
import { ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';

import { TextButton } from '../../../common/buttons/TextButton';
import { translate } from '../../../common/helpers/translate.helper';
import { IconChevronDown } from '../../../common/icons/cdl/ChevronDown';
import { IconChevronUp } from '../../../common/icons/cdl/ChevronUp';
import { Box } from '../../../common/ui/Box';
import { colors } from '../../../common/ui/colors';
import { get } from '../../../common/ui/get';

const ScrollingApproverList = styled.div`
    max-height: 200px;
    overflow: auto;
    padding: ${get('space.5')}px;
    padding-bottom: calc(${get('space.5')}px + 2px); // To visually center
`;

interface AdditionalApproversProps {
    children: ReactNode;
}

export const AdditionalApprovers = ({ children }: AdditionalApproversProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const moreApproversBodyRef = useRef<HTMLDivElement>(null);
    const { height } = useComponentSize(moreApproversBodyRef);

    const closedState = {
        opacity: 0,
        height: 0,
    };

    const openState = {
        opacity: 1,
        height: height,
    };

    const contentAnimation = useTransition(isOpen, {
        from: closedState,
        leave: closedState,
        update: openState,
        config: {
            ...config.default,
            tension: 300,
            clamp: true,
        },
    });

    // The negative margin-Left aligns the button with the other elements to compensate icon white space
    return (
        <Box>
            <Box marginBottom={5} marginLeft="-7px">
                <TextButton type="button" onClick={() => setIsOpen((currentIsOpen) => !currentIsOpen)}>
                    {isOpen ? <IconChevronUp /> : <IconChevronDown />}
                    {isOpen
                        ? translate('approvalRequest.multipleApproversPopup.hideOtherApprovers')
                        : translate('approvalRequest.multipleApproversPopup.showOtherApprovers')}
                </TextButton>
            </Box>
            {contentAnimation((style, item) => {
                if (!item) {
                    return null;
                }

                return (
                    <animated.div
                        style={{
                            overflow: 'hidden',
                            border: `1px solid ${colors.border.muted}`,
                            ...style,
                        }}
                    >
                        <ScrollingApproverList ref={moreApproversBodyRef}>{children}</ScrollingApproverList>
                    </animated.div>
                );
            })}
        </Box>
    );
};
