import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../cdl/Toast/useToasts';
import { deleteKeyPort } from '../common/api/clients/keyPorts.api';
import { queries } from '../common/api/queryKeys/queries';
import { translate } from '../common/helpers/translate.helper';

export const useKeyPortDelete = () => {
    const queryClient = useQueryClient();
    const { addToast, addErrorToast } = useToasts();

    return useMutation({
        mutationFn: deleteKeyPort,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queries.keyPorts._def });

            addToast(translate('keyPorts.toast.deleteKeyPort.success'));
        },
        onError: () => {
            addErrorToast(translate('keyPorts.toast.deleteKeyPort.error'));
        },
    });
};
