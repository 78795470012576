import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { FuelOrderDetailPage as OriginalFuelOrderDetailPage } from './FuelOrderDetailPage';

const FuelOrderDetailPage = (props) => {
    return (
        <AppProviders>
            <OriginalFuelOrderDetailPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(FuelOrderDetailPage, 'reactFuelOrderDetailPage');
