import { useField } from 'formik';
import PropTypes from 'prop-types';

import { TextInput } from '../TextInput/TextInput';

/**
 * @deprecated use CDL FormikInput instead
 **/

export const FormikTextInput = (props) => {
    const [field, meta] = useField(props.name);

    return (
        <TextInput
            invalid={meta.touched && !!meta.error}
            highlight={!meta.touched && props.required && !field.value}
            {...field}
            {...props}
        />
    );
};

FormikTextInput.propTypes = {
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
};
