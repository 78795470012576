import moment from 'moment';

import { translate } from '../../helpers/translate.helper';

export const makeRemainingTimeString = (remainingSeconds: number): string => {
    const remainingTimeDuration = moment.duration(remainingSeconds, 'seconds');

    if (remainingTimeDuration.asSeconds() < 60) {
        return translate('order.validitySecondsRemaining', { seconds: remainingTimeDuration.seconds().toString() });
    }

    if (remainingTimeDuration.asMinutes() < 10) {
        return translate('order.validityMinutesAndSecondsRemaining', {
            minutes: Math.trunc(remainingTimeDuration.asMinutes()).toString(),
            seconds: remainingTimeDuration.seconds().toString(),
        });
    }

    if (remainingTimeDuration.asMinutes() < 120) {
        return translate('order.validityMinutesRemaining', {
            minutes: Math.trunc(remainingTimeDuration.asMinutes()).toString(),
        });
    }

    return translate('order.validityHoursRemaining', {
        hours: Math.trunc(remainingTimeDuration.asHours()).toString(),
    });
};
