import styled from 'styled-components';

import { IconChevronDown } from '../../common/icons/cdl/ChevronDown';
import { IconChevronRight } from '../../common/icons/cdl/ChevronRight';
import { get } from '../../common/ui/get';

interface IconButtonProps {
    disabled: boolean;
}

const IconButton = styled.button<IconButtonProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: transparent;
    color: ${get('colors.foreground.subtle')};
    transition: color 75ms;

    &:hover {
        background: transparent;
        color: ${get('colors.foreground.default')};
    }

    &:disabled {
        color: ${get('colors.foreground.disabled')};
    }
`;

interface AccordionToggleButtonProps {
    isOpen: boolean;
    disabled: boolean;
}

export const DemandAccordionToggleButton = ({ isOpen, disabled }: AccordionToggleButtonProps) => {
    return (
        <IconButton disabled={disabled}>
            {isOpen ? <IconChevronDown height="16px" width="16px" /> : <IconChevronRight height="16px" width="16px" />}
        </IconButton>
    );
};
