import { useField } from 'formik';
import { SingleValue } from 'react-select';

import { DefaultOption } from '../../../../cdl/ComboBox/ComboBox';

import { DefaultSupplierComboBox, DefaultSupplierComboBoxProps } from './DefaultSupplierComboBox';

interface FormikDefaultSupplierComboBoxProps
    extends Omit<DefaultSupplierComboBoxProps, 'onChange' | 'value' | 'options'> {
    name: string;
}

export const FormikDefaultSupplierComboBox = (props: FormikDefaultSupplierComboBoxProps) => {
    const [field, meta, helpers] = useField(props.name);

    const handleChange = (option: SingleValue<DefaultOption>) => {
        helpers.setValue(option?.value);
    };

    return (
        <DefaultSupplierComboBox
            {...props}
            value={field.value}
            onChange={handleChange}
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : props.caption}
        />
    );
};
