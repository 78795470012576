import styled from 'styled-components';

import { Text, TextProps } from '../../cdl/Text/Text';
import { get } from '../ui/get';

interface LabelTextProps extends TextProps {
    disabled?: boolean;
}

export const LabelText = styled(Text).attrs({ variant: 'fieldLabel' })<LabelTextProps>`
    color: ${(props) => (props.disabled ? get('colors.foreground.disabled') : get('colors.foreground.muted'))};
`;
