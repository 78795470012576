import * as RadixTooltip from '@radix-ui/react-tooltip';
import { Fragment, ReactNode } from 'react';

import { Text } from '../../cdl/Text/Text';
import { Box } from '../ui/Box';

import { Arrow } from './Arrow';
import { Content } from './Content';

interface TooltipLargeProps {
    children: ReactNode;
    text: ReactNode;
    title: string;
}

/**
 * @deprecated Use the regular CDL Tooltip component instead.
 */
export const TooltipLarge = ({ children, text, title }: TooltipLargeProps) => {
    const hasText = Array.isArray(text) ? !!text.length : !!text;

    return (
        <RadixTooltip.Provider>
            <RadixTooltip.Root>
                <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
                <RadixTooltip.Portal>
                    <Content align="center" side="top" sideOffset={4}>
                        <Box>
                            {title ? (
                                <Box
                                    borderBottom={hasText ? 1 : 0}
                                    marginBottom={hasText ? 3 : 0}
                                    marginX={-2}
                                    paddingX={4}
                                    paddingY={3}
                                >
                                    <Text size={14} weight="semi">
                                        {title}
                                    </Text>
                                </Box>
                            ) : null}
                            {hasText ? (
                                <Text size={14} padding={2} display="block">
                                    {Array.isArray(text) ? text.map((t, i) => <Fragment key={i}>{t}</Fragment>) : text}
                                </Text>
                            ) : null}
                        </Box>
                        <Arrow />
                    </Content>
                </RadixTooltip.Portal>
            </RadixTooltip.Root>
        </RadixTooltip.Provider>
    );
};
