import css from '@styled-system/css';
import styled from 'styled-components';

const activeStyles = (props) => {
    if (!props.active) {
        return null;
    }

    return css({
        color: 'dark-blue.0',
        borderBottom: 2,
    });
};

export const TopbarTab = styled.button(
    css({
        border: 0,
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: '18px',
        color: 'dark-blue.2',
        ':hover': {
            color: 'dark-blue.0',
        },
    }),
    activeStyles
);
