import { ReactNode } from 'react';

import { ProductGroupReport } from '../../../types/SpecificReportMessage';
import { BaseStatisticsMessage } from '../../../types/StatisticsMessage';
import { DynamicFilterContainer, Filter } from '../../cdl/Filter/DynamicFilterContainer/DynamicFilterContainer';
import { FilterMultiSelectFull } from '../../cdl/Filter/FilterMultiSelectFull/FilterMultiSelectFull';
import { translate } from '../../common/helpers/translate.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { useProductContext } from '../../common/hooks/useProductContext';

interface ProductGroupsTabFilterProps {
    allEntries: BaseStatisticsMessage['productGroup'];
    children: (filteredEntries: BaseStatisticsMessage['productGroup']) => ReactNode;
}

export const ProductGroupsTabFilter = ({ children, allEntries }: ProductGroupsTabFilterProps) => {
    const { getHumanReadableValue } = useEnums();
    const { isLubes } = useProductContext();

    const productGroupFilter: Filter<string, ProductGroupReport> = {
        filterFn: (values, entry) => {
            return values.includes(entry.productGroup);
        },
        component: (values, setValues) => {
            const availableOptions = Array.from(new Set(allEntries.map((entry) => entry.productGroup))).map(
                (productGroup) => ({
                    label: isLubes
                        ? getHumanReadableValue(
                              'productGroup',
                              allEntries.find((entry) => entry.productGroup === productGroup)?.productGroup ?? ''
                          )
                        : allEntries.find((entry) => entry.productGroup === productGroup)?.productGroup ?? '',
                    value: productGroup,
                })
            );

            return (
                <FilterMultiSelectFull
                    options={availableOptions}
                    values={values}
                    onChange={setValues}
                    label={translate('reporting.filter.productGroup.title')}
                    filterByLabel={translate('reporting.filter.productGroup.filterByProductGroup')}
                    filterPlaceholder={translate('reporting.filter.productGroup.searchForProductGroup')}
                />
            );
        },
    };

    return (
        <DynamicFilterContainer allEntries={allEntries} filters={{ productGroup: productGroupFilter }}>
            {children}
        </DynamicFilterContainer>
    );
};
