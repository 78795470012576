import { FieldArray, Form, useFormikContext } from 'formik';
import React from 'react';

import { Button } from '../../../../../cdl/Button/Button';
import { FormikTextArea } from '../../../../../cdl/TextArea/FormikTextArea';
import { FormikDebug } from '../../../../../common/form-elements/formik/FormikDebug';
import { translate } from '../../../../../common/helpers/translate.helper';
import { LabelText } from '../../../../../common/LabelText/LabelText';
import { Table } from '../../../../../common/Table/Table';
import { Tr } from '../../../../../common/Table/Tr';
import { FormikTimeMinutesInput } from '../../../../../common/TimeMinutesInput/FormikTimeMinutesInput';
import { Box } from '../../../../../common/ui/Box';

import { CounterOfferItems } from './CounterOfferItems';

interface CounterOfferFormProps {
    onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const CounterOfferForm = ({ onCancel }: CounterOfferFormProps) => {
    const { isValid, isSubmitting } = useFormikContext();

    return (
        <Form>
            <Box display="grid" gridRowGap={5}>
                <Table style={{ margin: 0 }}>
                    <thead>
                        <Tr>
                            <th>
                                <LabelText>{translate('counterOffer.form.productHeader')}</LabelText>
                            </th>
                            <th>
                                <LabelText>{translate('counterOffer.form.priceHeader')}</LabelText>
                            </th>
                            <th>
                                <LabelText>{translate('counterOffer.form.counterPriceHeader')}</LabelText>
                            </th>
                        </Tr>
                    </thead>

                    <tbody>
                        <FieldArray name="items" component={CounterOfferItems} />
                    </tbody>
                </Table>

                <FormikTimeMinutesInput
                    name="validityTimeMinutes"
                    label={translate('counterOffer.form.validityTimeLabel')}
                    required
                />

                <FormikTextArea label={translate('counterOffer.form.messageLabel')} name="message" />
            </Box>

            <Box display="flex" justifyContent="end" style={{ gap: '8px' }} marginTop={6}>
                <Button onClick={onCancel}>{translate('btn.cancel')}</Button>

                <Button type="submit" emphasis="high" isLoading={isSubmitting} disabled={!isValid}>
                    {translate('btn.send')}
                </Button>
            </Box>

            <FormikDebug />
        </Form>
    );
};
