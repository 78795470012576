import { Chart, ChartEvent } from 'chart.js';

import { theme } from '../../../../common/ui/theme';

interface AfterEventArguments {
    event: ChartEvent;
    cancelable: boolean;
    changed?: boolean;
    inChartArea: boolean;
    replay: boolean;
}

interface UseChartPluginsParams {
    onActiveElementChange: () => void;
    isFirstValueMissing: boolean;
    isLastValueMissing: boolean;
}

export const useChartPlugins = ({
    onActiveElementChange,
    isFirstValueMissing,
    isLastValueMissing,
}: UseChartPluginsParams) => {
    const mouseLeavePlugin = {
        id: 'mouseLeavePlugin',
        afterEvent: (_: Chart, args: AfterEventArguments) => {
            const { type } = args.event;
            if (type === 'mouseout') {
                onActiveElementChange();
            }
        },
    };

    const verticalHoverLinePlugin = {
        id: 'verticalHoverLine',
        beforeDatasetsDraw(chart: Chart) {
            const {
                ctx,
                chartArea: { top, bottom, left, right },
            } = chart;

            ctx.clearRect(left, top, right - left, bottom - top);

            ctx.save();

            const points = chart.getDatasetMeta(0).data;

            points.forEach((point, index) => {
                if (point.active) {
                    if (isFirstValueMissing && index === 0) {
                        return;
                    }

                    if (isLastValueMissing && index === points.length - 1) {
                        return;
                    }

                    ctx.beginPath();
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = theme.colors.border.muted;
                    ctx.moveTo(point.x, top);
                    ctx.lineTo(point.x, bottom);
                    ctx.stroke();
                }
            });

            ctx.restore();
        },
    };

    return [verticalHoverLinePlugin, mouseLeavePlugin];
};
