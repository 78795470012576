import { OnChangeValue } from 'react-select';

import { FormikRoleSelect } from '../../../common/form-elements/formik/FormikRoleSelect';
import { FormikSupplierCompanySelect } from '../../../common/form-elements/formik/FormikSupplierCompanySelect';
import { FormikSupplierGroupSelect } from '../../../common/form-elements/formik/FormikSupplierGroupSelect';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';

import { FormikInviteUserState } from './InviteUserForm';

interface InviteUserSupplierFormProps {
    values: FormikInviteUserState;
    onGroupChange?: (value: OnChangeValue<string, false>) => void;
    onRoleChange?: (value?: string) => void;
}

export const InviteUserSupplierForm = ({ values, onGroupChange, onRoleChange }: InviteUserSupplierFormProps) => {
    const role = useRole();
    return (
        <>
            {role.isAdmin() ? (
                <FormikSupplierGroupSelect
                    name="groupId"
                    label={translate('user.group')}
                    onGroupChange={onGroupChange}
                />
            ) : null}
            <FormikRoleSelect name="role" label={translate('user.role')} onRoleChange={onRoleChange} />

            {values.groupId?.length && values.role && values.role !== 'GROUP_ADMIN' ? (
                <FormikSupplierCompanySelect
                    name="companyIds"
                    groupId={values.groupId}
                    label={translate('user.companies')}
                    isMulti
                />
            ) : null}
        </>
    );
};
