import { ReactNode } from 'react';
import styled from 'styled-components';
import { BorderRadiusProps } from 'styled-system';

import { Button } from '../../cdl/Button/Button';
import { Text } from '../../cdl/Text/Text';
import { translate } from '../helpers/translate.helper';
import { useStartUserlikeChat } from '../hooks/useStartUserlikeChat';
import { IconArrowRight } from '../icons/cdl/ArrowRight';
import { Box } from '../ui/Box';

import { BlurBackground } from './components/BlurBackground';
import { LinkWrapper } from './components/LinkWrapper';
import { LiteInformationContent } from './components/LiteInformationContent';
import { LiteInformationText } from './components/LiteInformationText';

interface LiteOverlayMediumProps {
    variant: 'medium';
    children: ReactNode;
    title: string;
    learnMoreLink?: string;
    borderRadius?: BorderRadiusProps['borderRadius'];
}

interface LiteOverlayWideProps {
    variant: 'wide';
    children: ReactNode;
    title?: never;
    learnMoreLink?: string;
    borderRadius?: BorderRadiusProps['borderRadius'];
}

interface LiteOverlaySmallProps {
    variant: 'small';
    children: ReactNode;
    title: string;
    learnMoreLink?: never;
    borderRadius?: BorderRadiusProps['borderRadius'];
}

export type LiteOverlayProps = LiteOverlaySmallProps | LiteOverlayMediumProps | LiteOverlayWideProps;

const LiteComponentWrapper = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;

    &:hover ${BlurBackground} {
        border-color: #b5ddff;
        box-shadow: inset 0 0 180px rgba(19, 116, 255, 0.1);
    }

    &:hover ${LiteInformationContent} {
        transform: translateY(0);
        opacity: 1;
    }
`;

export const LiteOverlay = ({ variant, children, title, learnMoreLink, borderRadius }: LiteOverlayProps) => {
    const startUserlikeChat = useStartUserlikeChat();
    const smallButtonStyles = {
        fontSize: '12px',
    };

    return (
        <LiteComponentWrapper>
            {children}
            <BlurBackground borderRadius={borderRadius} />
            <LiteInformationContent variant={variant}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Text variant={variant === 'small' ? 'subtitle1' : 'title'}>{title}</Text>
                    <LiteInformationText variant={variant} />
                </Box>

                <Box display="flex" gap={4}>
                    <Button
                        type="button"
                        emphasis="high"
                        style={variant === 'small' ? smallButtonStyles : undefined}
                        onClick={startUserlikeChat}
                    >
                        {translate('closelinkLite.overlay.buttonGetStarted')}
                    </Button>
                    {learnMoreLink ? (
                        <LinkWrapper href={learnMoreLink} target="_blank" rel="noreferrer noopener">
                            <Button emphasis="low" trailingVisual={<IconArrowRight />}>
                                {translate('closelinkLite.overlay.buttonLearnMore')}
                            </Button>
                        </LinkWrapper>
                    ) : null}
                </Box>
            </LiteInformationContent>
        </LiteComponentWrapper>
    );
};
