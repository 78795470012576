import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { OrderOverviewPage as OriginalOverviewPage } from './FuelOrderOverviewPage';

const FuelOrderOverviewPage = (props) => {
    return (
        <AppProviders>
            <OriginalOverviewPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(FuelOrderOverviewPage, 'reactFuelOrderOverviewPage');
