import { useField } from 'formik';

import { MultipleEntriesInput } from './MultipleEntriesInput';

interface FormikMultipleEntriesInputProps {
    name: string;
    inputLabel: string;
    inputPlaceholder: string;
    pattern: string;
}

export const FormikMultipleEntriesInput = (props: FormikMultipleEntriesInputProps) => {
    const [field, , helpers] = useField(props.name);

    return (
        <MultipleEntriesInput
            inputLabel={props.inputLabel}
            inputPlaceholder={props.inputPlaceholder}
            pattern={props.pattern}
            entries={field.value}
            onEntriesChanged={helpers.setValue}
        />
    );
};
