import { PaginationButton } from './PaginationButton';
import { PaginationDots } from './PaginationDots';

interface PagesProps {
    currentPage: number;
    pageTotal: number;
    onPageSelect: (page: number) => void;
}

export const Pages = ({ currentPage, pageTotal, onPageSelect }: PagesProps) => {
    const maxPages = 9;
    const maxPagesWithDots = 7;

    const pages = Array.from(Array(pageTotal).keys());

    if (pageTotal <= maxPages) {
        return (
            <>
                {pages.map((page) => {
                    return (
                        <PaginationButton key={page} onClick={() => onPageSelect(page)} active={page === currentPage}>
                            {page + 1}
                        </PaginationButton>
                    );
                })}
            </>
        );
    }

    if (currentPage < 5) {
        const pagesToShow = pages.slice(0, maxPagesWithDots);
        return (
            <>
                {pagesToShow.map((page) => {
                    return (
                        <PaginationButton key={page} onClick={() => onPageSelect(page)} active={page === currentPage}>
                            {page + 1}
                        </PaginationButton>
                    );
                })}
                <PaginationDots pageTotal={pageTotal} />
                <PaginationButton onClick={() => onPageSelect(pageTotal - 1)} active={pageTotal - 1 === currentPage}>
                    {pageTotal}
                </PaginationButton>
            </>
        );
    }

    if (currentPage >= pageTotal - 5) {
        const pagesToShow = pages.slice(pageTotal - maxPagesWithDots, pageTotal);
        return (
            <>
                <PaginationButton onClick={() => onPageSelect(0)} active={0 === currentPage}>
                    {1}
                </PaginationButton>
                <PaginationDots />
                {pagesToShow.map((page) => {
                    return (
                        <PaginationButton key={page} onClick={() => onPageSelect(page)} active={page === currentPage}>
                            {page + 1}
                        </PaginationButton>
                    );
                })}
            </>
        );
    }

    const pagesToShow = pages.slice(currentPage - 2, currentPage + 3);
    return (
        <>
            <PaginationButton onClick={() => onPageSelect(0)} active={0 === currentPage}>
                {1}
            </PaginationButton>
            <PaginationDots />

            {pagesToShow.map((page) => {
                return (
                    <PaginationButton key={page} onClick={() => onPageSelect(page)} active={page === currentPage}>
                        {page + 1}
                    </PaginationButton>
                );
            })}

            <PaginationDots pageTotal={pageTotal} />
            <PaginationButton onClick={() => onPageSelect(pageTotal - 1)} active={pageTotal - 1 === currentPage}>
                {pageTotal}
            </PaginationButton>
        </>
    );
};
