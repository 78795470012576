import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateApiKey } from '../../../../common/api/clients/apiKey.api';
import { queries } from '../../../../common/api/queryKeys/queries';

export const useApiKeyUpdate = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateApiKey,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queries.apiKeys.list._def });
        },
    });
};
