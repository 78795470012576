import { ReactNode } from 'react';
import styled from 'styled-components';

import { get } from './ui/get';

interface PageGridProps {
    children: ReactNode;
}

export const PageGrid = styled.div<PageGridProps>`
    display: flex;
    flex-direction: column;
    row-gap: ${get('space.5')}px;
`;
