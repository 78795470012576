import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
import styled from 'styled-components';
import { fontSize, fontWeight } from 'styled-system';

import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { get } from '../ui/get';

const StyledTextButtonContent = styled.span({
    overflow: 'visible',
    position: 'relative', // this is required in Safari for overflow visible to work
    display: 'flex',
    alignItems: 'center',
});

const StyledTextWrapper = styled.span({
    textAlign: 'left',
});

const StyledElementWrapper = styled.span({
    ':first-child': {
        marginRight: '4px',
    },
    ':last-child': {
        marginLeft: '4px',
    },
    ':only-child': {
        marginLeft: 0,
        marginRight: 0,
    },
});

const StyledTextButton = styled.button(
    {
        fontWeight: 'normal',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        color: get('colors.accent.foreground'),
        padding: 0,
        position: 'relative',
        boxSizing: 'border-box',
        ':active': {
            color: get('colors.accent.foreground'),
        },
        ':disabled': {
            color: get('colors.foreground.disabled'),
            textDecoration: 'none',
        },
    },
    fontWeight,
    fontSize
);

interface TextButtonProps extends ComponentPropsWithoutRef<'button'> {
    tooltip?: ReactNode;
    children: ReactNode;
}

export const TextButton = ({ tooltip, children, ...props }: TextButtonProps) => {
    const content = (
        <React.Fragment>
            {React.Children.map(children, (child) => {
                return typeof child === 'string' ? (
                    <StyledTextWrapper>{child}</StyledTextWrapper>
                ) : (
                    <StyledElementWrapper>{child}</StyledElementWrapper>
                );
            })}
        </React.Fragment>
    );

    if (!tooltip) {
        return (
            <StyledTextButton {...props}>
                <StyledTextButtonContent>{content}</StyledTextButtonContent>
            </StyledTextButton>
        );
    }

    return (
        <Tooltip label={tooltip}>
            <StyledTextButton {...props}>
                <StyledTextButtonContent>{content}</StyledTextButtonContent>
            </StyledTextButton>
        </Tooltip>
    );
};
