import { Form, useFormikContext } from 'formik';

import { Button } from '../../cdl/Button/Button';
import { translate } from '../../common/helpers/translate.helper';
import { FormikPasswordInput } from '../../common/PasswordInput/FormikPasswordInput';
import { Box } from '../../common/ui/Box';
import { FormikStrengthMeter } from '../../user/StrengthMeter/FormikStrengthMeter';

export const FormikSetNewPasswordForm = () => {
    const { isSubmitting } = useFormikContext();
    return (
        <Form>
            <Box display="grid" gridRowGap={5} marginBottom={7} marginTop={6}>
                <FormikPasswordInput label={translate('setNewPassword.newPassword')} name="password" minLength={6} />
                <FormikStrengthMeter name="password" />

                <FormikPasswordInput
                    label={translate('setNewPassword.passwordRepeat')}
                    name="passwordRepeat"
                    minLength={6}
                />
            </Box>
            <Button emphasis="high" type="submit" width="100%" isLoading={isSubmitting} size="large">
                {translate('setNewPassword.next')}
            </Button>
        </Form>
    );
};
