import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { UserMessage } from '../../../../types/UserMessage';
import { updateManagementUser, UpdateManagementUserConfig } from '../../../common/api/clients/user.api';
import { queryKeys } from '../../../common/api/queryKeys';

export const useUserManagementUpdate = () => {
    const queryClient = useQueryClient();

    return useMutation<UserMessage, AxiosError, UpdateManagementUserConfig>({
        mutationFn: updateManagementUser,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.users() });
        },
    });
};
