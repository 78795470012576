import styled from 'styled-components';

import { Text } from '../../../../cdl/Text/Text';
import { Box } from '../../../../common/ui/Box';
import { get } from '../../../../common/ui/get';

const DashedSeparator = styled.div`
    flex-grow: 1;
    min-width: 12px;
    margin: 0 4px 4px 4px;
    border-bottom-style: dashed;
    border-bottom-width: 1px;
    border-bottom-color: ${get('colors.border.muted')};
`;

interface PricesMapProps {
    items: { label: string; value: string }[];
}

export const PricesMap = ({ items }: PricesMapProps) => {
    return (
        <Box display="flex" flexDirection="column" style={{ rowGap: '4px' }}>
            {items.map((item, index) => (
                <Box display="flex" width="100%" key={index}>
                    <Text as="p" variant="extraSmall" color="foreground.muted">
                        {item.label}
                    </Text>
                    <DashedSeparator />
                    <Text as="p" variant="extraSmall" fontWeight="600">
                        {item.value}
                    </Text>
                </Box>
            ))}
        </Box>
    );
};
