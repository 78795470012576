export const extractFileName = (contentDispositionHeader: string, fallback: string) => {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDispositionHeader);

    if (matches === null || !matches[1]) {
        return fallback;
    }

    return matches[1].replace(/['"]/g, '');
};
