import { useField } from 'formik';
import styled from 'styled-components';

import { Text } from '../../../../../cdl/Text/Text';
import { formatFuelMoney } from '../../../../../common/helpers/formatFuelMoney.helper';
import { translateSulphurContent } from '../../../../../common/helpers/translateSulphurContent';
import { FormikMoneyInput } from '../../../../../common/MoneyInput/FormikMoneyInput';
import { Td } from '../../../../../common/Table/Td';
import { Tr } from '../../../../../common/Table/Tr';
import { Flex } from '../../../../../common/ui/Flex';

import { FormikCounterOfferCreateStateItem } from './CounterOfferCreateState';

const TopAlignedTd = styled(Td)`
    && {
        vertical-align: top;
    }
`;

interface ItemRowProps {
    name: string;
}

const ItemRow = (props: ItemRowProps) => {
    const [field] = useField<FormikCounterOfferCreateStateItem>(props.name);

    const translatedSulphurContent = translateSulphurContent(field.value.sulphurContent);

    return (
        <Tr key={props.name}>
            <TopAlignedTd width="40%">
                <Flex height="40px" alignItems="center">
                    <Text variant="body">{`${field.value.productName} ${translatedSulphurContent}`}</Text>
                </Flex>
            </TopAlignedTd>
            <TopAlignedTd width="20%">
                <Flex height="40px" alignItems="center">
                    <Text variant="body">
                        {formatFuelMoney({
                            value: field.value.price.value,
                            currency: field.value.price.currency,
                        })}
                    </Text>
                </Flex>
            </TopAlignedTd>
            <TopAlignedTd width="40%">
                <FormikMoneyInput name={`${props.name}.counterPrice`} required />
            </TopAlignedTd>
        </Tr>
    );
};

export const CounterOfferItems = () => {
    const [field] = useField('items');
    const items = field.value;

    return items.map((item: FormikCounterOfferCreateStateItem, index: number) => {
        return <ItemRow key={index} name={`items.${index}`} />;
    });
};
