import { Error } from '../../common/Error/Error';
import { useProductContext } from '../../common/hooks/useProductContext';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../common/LocationState/useLocationState';
import { useSupplierStatistics } from '../hooks/useSupplierStatistics';

import { NoStatistics } from './NoStatistics';
import { StatisticsCenteredFrame } from './StatisticsCenteredFrame';
import { StatisticsStateConfig } from './StatisticsState';
import { SupplierStatisticsContent } from './SupplierStatisticsContent';
import { SupplierStatisticsTabSwitch } from './SupplierStatisticsTabSwitch';

export const SupplierStatistics = () => {
    const { context } = useProductContext();
    const [locationStateUntyped] = useLocationState();
    const locationState = locationStateUntyped as StatisticsStateConfig;

    const supplierStatisticsQuery = useSupplierStatistics({
        from: locationState.from,
        to: locationState.to,
        supplierIds: locationState.supplierIds,
        type: context,
    });

    if (supplierStatisticsQuery.isPending) {
        return (
            <div>
                <SupplierStatisticsTabSwitch />
                <StatisticsCenteredFrame>
                    <LoadingIndicator />
                </StatisticsCenteredFrame>
            </div>
        );
    }

    if (supplierStatisticsQuery.isError) {
        return (
            <StatisticsCenteredFrame>
                <Error />
            </StatisticsCenteredFrame>
        );
    }

    if (supplierStatisticsQuery.data?.general.completedCount === 0) {
        return (
            <div>
                <SupplierStatisticsTabSwitch />
                <StatisticsCenteredFrame>
                    <NoStatistics />
                </StatisticsCenteredFrame>
            </div>
        );
    }

    return (
        <div>
            <SupplierStatisticsTabSwitch />
            <SupplierStatisticsContent statistics={supplierStatisticsQuery.data} />
        </div>
    );
};
