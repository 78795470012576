import { Form, useFormikContext } from 'formik';

import { Button } from '../../cdl/Button/Button';
import { Text } from '../../cdl/Text/Text';
import { FormikDebug } from '../../common/form-elements/formik/FormikDebug';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';

import { FormikEditUserState } from './EditUserForm';
import { EditUserFormContent } from './EditUserFormContent';

export const EditUserBaseForm = () => {
    const { isSubmitting, isValid } = useFormikContext<FormikEditUserState>();

    return (
        <Form style={{ height: '100%' }}>
            <Box display="grid" gridTemplateRows="auto 1fr auto" gridTemplateColumns="100%" height="100%">
                <Box padding={7} paddingBottom={6}>
                    <Text variant="title" marginBottom={6}>
                        {translate('user.updatetitle')}
                    </Text>
                </Box>

                <Box paddingX={7}>
                    <EditUserFormContent />

                    <FormikDebug />
                </Box>

                <Box padding={7}>
                    <Button emphasis="high" isLoading={isSubmitting} type="submit" disabled={!isValid} width="100%">
                        {translate('btn.save.default')}
                    </Button>
                </Box>
            </Box>
        </Form>
    );
};
