import { CreateSupplierGroupRequestMessage } from '../../../../types/SupplierGroupMessage';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../../common/helpers/translate.helper';
import { useSupplierGroupCreate } from '../hooks/useSupplierGroupCreate';

import { CreateSupplierGroupForm, CreateSupplierGroupFormProps } from './CreateSupplierGroupForm';

interface CreateSupplierGroupProps {
    onSuccess: () => void;
}

export const CreateSupplierGroup = ({ onSuccess }: CreateSupplierGroupProps) => {
    const { addErrorToast } = useToasts();
    const createSupplierGroupMutation = useSupplierGroupCreate();

    const handleSubmit: CreateSupplierGroupFormProps['onSubmit'] = (values) => {
        const createSupplierGroupMessage: CreateSupplierGroupRequestMessage = {
            name: values.name,
            description: values.description || undefined,
            phone: values.phone || undefined,
            fax: values.fax || undefined,
            address: {
                companyName: values.address.companyName || undefined,
                street: values.address.street || undefined,
                streetNumber: values.address.streetNumber || undefined,
                zipcode: values.address.zipcode || undefined,
                city: values.address.city || undefined,
                country: values.address.country || undefined,
            },
            testCompany: values.testCompany,
            gtcUrl: values.gtcUrl || undefined,
            headquarter: values.headquarter || undefined,
            emailQuote: values.emailQuote,
            anonymousQuote: values.anonymousQuote,
            supplierProductGroups: values.supplierProductGroups.length ? values.supplierProductGroups : undefined,
            translationSupplierProductGroup: values.translationSupplierProductGroup || undefined,
            type: values.type!,
        };

        return createSupplierGroupMutation.mutateAsync(createSupplierGroupMessage, {
            onSuccess: () => onSuccess(),
            onError: () => {
                addErrorToast(translate('suppliergroup.createSupplierGroupErrorToast'));
            },
        });
    };

    return <CreateSupplierGroupForm onSubmit={handleSubmit} />;
};
