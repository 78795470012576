import { CounterOffer } from '../../../../types/CounterOffer';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const createCounterOffer = async (counterOffer: Partial<CounterOffer>): Promise<CounterOffer> => {
    const response = await authorizedAxiosInstance.post('/v1/counter-offers', counterOffer);

    return response.data;
};

interface UpdateCounterOfferParams {
    counterOfferId: string;
    counterOffer: CounterOffer;
}

export const updateCounterOffer = async ({ counterOfferId, counterOffer }: UpdateCounterOfferParams): Promise<any> => {
    const response = await authorizedAxiosInstance.put(`/v1/counter-offers/${counterOfferId}`, counterOffer);

    return response.data;
};
