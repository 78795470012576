SupplierResource.$inject = ['$resource', 'AppSettings'];

function SupplierResource($resource, AppSettings) {
    return $resource(
        AppSettings.routes.api + '/v1/supplier/:id',
        {},
        {
            update: {
                method: 'PUT',
            },
            query: {
                method: 'GET',
                isArray: false,
            },
            list: {
                method: 'GET',
                isArray: true,
                url: AppSettings.routes.api + '/v1/supplier/list',
            },
            updateSettings: {
                method: 'PUT',
                url: AppSettings.routes.api + '/v1/suppliers/:id/settings',
                params: {
                    id: '@id',
                },
            },
        }
    );
}

export default {
    name: 'SupplierResource',
    fn: SupplierResource,
};
