import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconCircle2 = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm10-3a1 1 0 0 0-1 1 1 1 0 1 1-2 0 3 3 0 1 1 6 0c0 .51-.173.996-.356 1.379-.19.397-.431.77-.656 1.073l-.011.015-1.947 2.534L14 15a1 1 0 0 1 0 2l-4 .001a1 1 0 0 1-.793-1.61l3.178-4.135c.173-.234.337-.492.454-.738.126-.264.161-.437.161-.518a1 1 0 0 0-1-1Z"
            clipRule="evenodd"
        />
    </svg>
);
