import { FieldArray, useFormikContext } from 'formik';

import { TextButton } from '../../../../common/buttons/TextButton';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconPlus } from '../../../../common/icons/cdl/Plus';
import { Box } from '../../../../common/ui/Box';

import { FormikVesselConsumptionPresetsState } from './EditVesselConsumptionPresetsForm';
import { FormikVesselConsumptionPreset } from './FormikVesselConsumptionPreset';

export const FormikVesselConsumptionPresetCategories = () => {
    const { values, setFieldValue } = useFormikContext<FormikVesselConsumptionPresetsState>();

    const possibleCategories = 9; // Amount of all possible productGroup - sulphurContent combinations

    const emptyPreset = {
        sulphurContent: '',
        productGroup: '',
        averageDailyConsumption: '',
        safetyReserve: '',
        maximumIntake: '',
    };

    const onAddCategoryClick = () => {
        const newValues = [...values.presets, emptyPreset];
        setFieldValue('presets', newValues);
    };

    return (
        <FieldArray
            name="presets"
            render={(arrayHelpers) => {
                return (
                    <Box marginTop={5}>
                        {values.presets.map((preset, index) => {
                            return (
                                <Box key={index}>
                                    <FormikVesselConsumptionPreset
                                        index={index}
                                        onDeleteClick={() => {
                                            if (values.presets.length > 1) {
                                                arrayHelpers.remove(index);
                                            } else {
                                                arrayHelpers.replace(index, emptyPreset);
                                            }
                                        }}
                                    />
                                </Box>
                            );
                        })}
                        {values.presets.length < possibleCategories ? (
                            <Box marginTop={6}>
                                <TextButton onClick={onAddCategoryClick} type="button">
                                    <IconPlus />
                                    {translate('vessel.fuelStocks.editPresetsForm.addCategory')}
                                </TextButton>
                            </Box>
                        ) : null}
                    </Box>
                );
            }}
        />
    );
};
