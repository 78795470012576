import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';

import { OverlayContainer } from './OverlayContainer';

const overlayId = 'closelink-overlay';

export const createOverlay = (element) => {
    return ReactDOM.createPortal(
        <RemoveScroll>
            <OverlayContainer id={overlayId}>{element}</OverlayContainer>
        </RemoveScroll>,
        document.getElementById('closelink-portal-view')
    );
};

export const scrollOverlayToTop = () => {
    document.getElementById(overlayId).scrollTop = 0;
};
