import PropTypes from 'prop-types';

import { APPROVAL_STATE } from '../../common/approvalState.constant';

import { ApprovedState } from './ApprovedState';
import { DeclinedState } from './DeclinedState';
import { ERRORS } from './errors.constants';
import { GeneralError } from './GeneralError';
import { NotAvailable } from './NotAvailable';
import { PendingState } from './PendingState';
import { UnauthorizedError } from './UnauthorizedError';

export const OfferApprovalRequestContent = ({
    approvalRequests,
    offer,
    onApprovalRequestDeclined,
    onApprovalRequestApproved,
    updatingApprovalRequestState,
    error,
}) => {
    if (error) {
        switch (error) {
            case ERRORS.GONE: {
                return <NotAvailable />;
            }
            case ERRORS.UNAUTHORIZED: {
                return <UnauthorizedError />;
            }
            case ERRORS.DEFAULT:
            default: {
                return <GeneralError />;
            }
        }
    }

    switch (approvalRequests[0].state) {
        case APPROVAL_STATE.DECLINED: {
            return <DeclinedState offer={offer} approvalRequests={approvalRequests} />;
        }
        case APPROVAL_STATE.APPROVED: {
            return <ApprovedState offer={offer} approvalRequests={approvalRequests} />;
        }
        case APPROVAL_STATE.PENDING: {
            return (
                <PendingState
                    offer={offer}
                    approvalRequests={approvalRequests}
                    onApprovalRequestDeclined={onApprovalRequestDeclined}
                    onApprovalRequestApproved={onApprovalRequestApproved}
                    updatingApprovalRequestState={updatingApprovalRequestState}
                />
            );
        }
    }
};

OfferApprovalRequestContent.propTypes = {
    approvalRequests: PropTypes.array,
    offer: PropTypes.object,
    onApprovalRequestDeclined: PropTypes.func,
    onApprovalRequestApproved: PropTypes.func,
    updatingApprovalRequestState: PropTypes.string,
    error: PropTypes.string,
};
