import { Position } from 'google-map-react';
import { useState } from 'react';
import styled from 'styled-components';

import { CloselinkMap } from '../../../../common/Map/CloselinkMap';
import { PositionMarker } from '../../../../common/PositionMarker/PositionMarker';
import { Box } from '../../../../common/ui/Box';
import { PositionData } from '../types/PositionData';
import { getMarkerColor } from '../util/getMarkerColor';

const MapWrapper = styled(Box)`
    width: 100%;
    height: 200px;
    padding: 2px;
`;

interface OfferPositionsMapProps {
    mapPosition?: Position;
    positionData: PositionData[];
}

// We need to avoid flickering of the markers when dragged by hiding them on map drag.
// This is a workaround since google-map-react has this bug with react v18 and is not maintained anymore.

export const OfferPositionsMap = ({ positionData, mapPosition }: OfferPositionsMapProps) => {
    const [showMarker, setShowMarker] = useState(true);

    const places = positionData.map((data) => data.position);

    return (
        <MapWrapper>
            <CloselinkMap
                places={places}
                onDrag={() => setShowMarker(false)}
                onDragEnd={() => setShowMarker(true)}
                center={mapPosition ? mapPosition : { lat: 0, lng: 0 }}
                zoom={mapPosition ? 5 : 0}
                fitToBounds={!mapPosition}
            >
                {positionData.map((data) => {
                    const markerColor = getMarkerColor(data.type);

                    return (
                        <PositionMarker
                            key={data.id}
                            lat={data.position.lat}
                            lng={data.position.lng}
                            color={markerColor}
                            style={{
                                transform: `scale(${showMarker ? 1 : 0})`,
                                opacity: showMarker ? 1 : 0,
                                transition: 'transform 0.1s, opacity 0.3s',
                            }}
                        />
                    );
                })}
            </CloselinkMap>
        </MapWrapper>
    );
};
