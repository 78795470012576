import { translate } from '../../common/helpers/translate.helper';
import { IconCircle1 } from '../../common/icons/cdl/Circle1';
import { StatusBar } from '../../common/StatusBar/StatusBar';
import { StatusBarType } from '../../common/StatusBar/StatusBarType';
import { Box } from '../../common/ui/Box';
import { CardVariation } from '../../common/ui/CardVariation';

import { FirstStepForm } from './FirstStepForm';
import { FormikFuelEnquiryHeader } from './FormikFuelEnquiryHeader';

export const FirstStep = (props) => {
    return (
        <Box>
            <FormikFuelEnquiryHeader title={translate('fuelEnquiry.createNewTitle')} />
            <Box padding={6}>
                <CardVariation paddingX={0} paddingY={0}>
                    <StatusBar
                        headline={translate('fuelEnquiry.first.statusBar.headline')}
                        subHeadline={translate('fuelEnquiry.first.statusBar.subHeadline')}
                        type={StatusBarType.WHITE}
                        icon={<IconCircle1 />}
                    />
                </CardVariation>

                <Box marginTop={6}>
                    <FirstStepForm {...props} />
                </Box>
            </Box>
        </Box>
    );
};
