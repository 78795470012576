import { ComponentPropsWithoutRef, MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

interface DefaultCellProps extends ComponentPropsWithoutRef<'td'> {
    numeric?: boolean;
    emphasis?: 'default' | 'attention';
    interactive?: boolean;
    children?: ReactNode;
}

const StyledDefaultCell = styled.td.attrs((props) => ({
    emphasis: 'default',
    ...props,
}))<DefaultCellProps>`
    padding: 8px 16px;
    border-top: 1px solid ${get('colors.background.inset')};
    color: ${(props) => (props.emphasis === 'attention' ? get('colors.attention.foreground') : 'inherit')};

    text-align: ${(props) => (props.numeric ? 'right' : 'left')};
`;

export const DefaultCell = (props: DefaultCellProps) => {
    const onClick = (event: MouseEvent) => {
        if (props.interactive) {
            event.stopPropagation();
        }
    };

    return (
        <StyledDefaultCell {...props} onClick={onClick}>
            {props.children}
        </StyledDefaultCell>
    );
};
