import { initialize as initializeLaunchDarkly, LDClient } from 'launchdarkly-js-client-sdk';

import { Role } from '../../../types/Role';
import AppSettings from '../../constants';

export let client: LDClient | null = null;

export const initialize = async (role: Role) => {
    if (role.isAdmin()) {
        client = initializeLaunchDarkly(AppSettings.launchDarklyClientSideId, {
            kind: 'user',
            key: role.user.id,
            email: role.user.email,
            userType: role.user.userType,
        });
    } else {
        client = initializeLaunchDarkly(AppSettings.launchDarklyClientSideId, {
            kind: 'multi',
            user: {
                key: role.user.id,
                email: role.user.email,
                userType: role.user.userType,
                companyIds: role.companies?.map((company) => company.id),
            },
            organization: {
                key: role.user.groupId,
                groupId: role.user.groupId,
            },
        });
    }

    await client.waitForInitialization();
};
