import { useReducer } from 'react';

import { Item } from '../../common/models/item.model';

import { COMPARE_STATE_ACTION } from './CompareStateAction';
import { generateRandomId } from './generateRandomId';

const reducer = (state, action) => {
    switch (action.type) {
        case COMPARE_STATE_ACTION.ADD_ITEM: {
            const item = new Item();
            item.id = generateRandomId();
            const updatedProducts = state.items.concat(item);
            return {
                items: updatedProducts,
                portInformation: state.portInformation,
                customerId: state.customerId,
            };
        }

        case COMPARE_STATE_ACTION.REMOVE_ITEM: {
            const items = state.items;
            items.splice(action.index, 1);

            return {
                items,
                portInformation: state.portInformation,
                customerId: state.customerId,
            };
        }

        case COMPARE_STATE_ACTION.ADD_PORT: {
            const port = {
                portId: null,
                id: generateRandomId(),
            };
            const updatedPortInformation = state.portInformation.concat(port);

            return {
                items: state.items,
                portInformation: updatedPortInformation,
                customerId: state.customerId,
            };
        }

        case COMPARE_STATE_ACTION.REMOVE_PORT: {
            const portInformation = state.portInformation;

            portInformation.splice(action.index, 1);

            return {
                items: state.items,
                portInformation,
                customerId: state.customerId,
            };
        }

        case COMPARE_STATE_ACTION.SYNC_DATA: {
            action.items.forEach((item) => item.compute());
            return {
                items: action.items,
                portInformation: action.portInformation,
                customerId: action.customerId,
            };
        }
        default: {
            throw new Error(`Action with type ${action.type} is unknown`);
        }
    }
};

export const useCompareState = (initializer) => {
    const [state, dispatch] = useReducer(reducer, null, initializer);

    const actions = {
        addItem: () =>
            dispatch({
                type: COMPARE_STATE_ACTION.ADD_ITEM,
            }),
        removeItem: (index) =>
            dispatch({
                type: COMPARE_STATE_ACTION.REMOVE_ITEM,
                index,
            }),
        addPort: () =>
            dispatch({
                type: COMPARE_STATE_ACTION.ADD_PORT,
            }),
        removePort: (index) =>
            dispatch({
                type: COMPARE_STATE_ACTION.REMOVE_PORT,
                index,
            }),
        syncData: (data) =>
            dispatch({
                type: COMPARE_STATE_ACTION.SYNC_DATA,
                items: data.items,
                portInformation: data.portInformation,
                customerId: data.customerId,
            }),
    };

    return {
        state,
        actions,
    };
};
