import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getKeyPort, getKeyPorts, GetKeyPortsParams } from '../clients/gateway.api';
import { getKeyPortList } from '../clients/keyPorts.api';

export const keyPorts = createQueryKeys('keyPorts', {
    detail: (id: string) => ({
        queryKey: [id],
        queryFn: () => getKeyPort(id),
    }),
    list: (context: string) => ({
        queryKey: [context],
        queryFn: () => getKeyPortList(context),
    }),
    pagination: (params: GetKeyPortsParams) => ({
        queryKey: [params],
        queryFn: () => getKeyPorts(params),
    }),
});
