import { PortMessage } from '../../../../../types/PortMessage';
import { getPorts } from '../../../../common/api/clients/port.api';
import { queryKeys } from '../../../../common/api/queryKeys';
import { usePaginationQuery } from '../../../../common/hooks/usePaginationQuery';

export const usePortPagination = (page: number, searchQuery: string) => {
    return usePaginationQuery<PortMessage>({
        queryKey: queryKeys.ports({ page, searchQuery }),
        queryFn: () =>
            getPorts({
                searchQuery,
                page,
            }),
        prefetchQueryKey: queryKeys.ports({ page: page + 1, searchQuery }),
        prefetchQueryFn: () =>
            getPorts({
                page: page + 1,
                searchQuery,
            }),
    });
};
