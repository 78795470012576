import { ComponentType, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { IconX } from '../../common/icons/cdl/X';
import { Box } from '../../common/ui/Box';
import { get } from '../../common/ui/get';
import { Text } from '../Text/Text';
import { Tooltip } from '../Tooltip/Tooltip';

import { defaultVariants } from './util/defaultVariants';
import { disabledVariants } from './util/disabledVariants';
import { emphasisVariants } from './util/emphasisVariants';

const TextWrapper = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1;
`;

const RemoveButton = styled.button`
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    height: 12px;
    width: 12px;
`;

interface TagComponentProps extends Pick<TagProps, 'emphasis' | 'disabled'> {}

const TagComponent = styled.div<TagComponentProps>`
    box-sizing: border-box;
    display: inline-flex;
    max-width: 100%;
    height: 20px;
    padding: calc(${get('space.1')}px - 1px) calc(${get('space.2')}px - 1px);
    border: 1px solid transparent;
    border-radius: ${get('radii.1')}px;

    ${(props) => (props.emphasis ? emphasisVariants : defaultVariants)}
    ${(props) => (props.disabled ? disabledVariants : null)}
`;

type EnsureIconOrChildren =
    | {
          Icon: ComponentType<{ width?: number; height?: number }>;
          children?: never;
      }
    | {
          Icon?: never;
          children: ReactNode;
      }
    | {
          Icon: ComponentType<{ width?: number; height?: number }>;
          children: ReactNode;
      };

type SharedTagProps = {
    variant: 'default' | 'accent' | 'positive' | 'attention' | 'negative';
    emphasis?: boolean;
    disabled?: boolean;
    onRemoveClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

type TagProps = SharedTagProps & EnsureIconOrChildren;

export const Tag = ({ children, onRemoveClick, Icon, ...rest }: TagProps) => {
    const textWrapperRef = useRef<HTMLDivElement>(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        if (textWrapperRef.current) {
            setIsTruncated(textWrapperRef.current.scrollWidth > textWrapperRef.current.clientWidth);
        }
    }, []);

    if (isTruncated) {
        return (
            <Tooltip label={children} showArrow>
                <TagComponent {...rest}>
                    <Box display="flex" alignItems="center" maxWidth="100%">
                        {Icon ? (
                            <div>
                                <Icon height={12} width={12} />
                            </div>
                        ) : null}

                        {children ? (
                            <TextWrapper ref={textWrapperRef}>
                                <Text variant="extraSmall" fontWeight="medium" paddingX={1}>
                                    {children}
                                </Text>
                            </TextWrapper>
                        ) : null}

                        {onRemoveClick ? (
                            <RemoveButton type="button" onClick={(e) => onRemoveClick(e)}>
                                <IconX height={12} width={12} />
                            </RemoveButton>
                        ) : null}
                    </Box>
                </TagComponent>
            </Tooltip>
        );
    }
    return (
        <TagComponent {...rest}>
            <Box display="flex" alignItems="center" maxWidth="100%">
                {Icon ? (
                    <div>
                        <Icon height={12} width={12} />
                    </div>
                ) : null}

                {children ? (
                    <TextWrapper ref={textWrapperRef}>
                        <Text variant="extraSmall" fontWeight="medium" paddingX={1} display="inline-block">
                            {children}
                        </Text>
                    </TextWrapper>
                ) : null}

                {onRemoveClick ? (
                    <RemoveButton type="button" onClick={(e) => onRemoveClick(e)}>
                        <IconX height={12} width={12} />
                    </RemoveButton>
                ) : null}
            </Box>
        </TagComponent>
    );
};
