import { AgentContactMessage } from '../../../../types/AgentMessage';
import { Link } from '../../../cdl/Link/Link';
import { Text } from '../../../cdl/Text/Text';
import { DataSheet } from '../../../common/DataSheet/DataSheet';
import { translate } from '../../../common/helpers/translate.helper';

interface AgentContactDataSheetProps {
    contact: AgentContactMessage;
}

export const AgentContactDataSheet = ({ contact }: AgentContactDataSheetProps) => {
    return (
        <DataSheet>
            <DataSheet.Row label={translate('agents.detail.contact.name')} value={contact.name} />
            <DataSheet.Row label={translate('agents.detail.contact.phone')} value={contact.phoneNumber} />
            {contact.emailAddress ? (
                <>
                    <DataSheet.Label>{translate('agents.detail.contact.email')}</DataSheet.Label>
                    <Text>
                        <Link size="small" href={`mailto:${contact.emailAddress}`}>
                            {contact.emailAddress}
                        </Link>
                    </Text>
                </>
            ) : null}

            <DataSheet.Row label={translate('agents.detail.contact.fax')} value={contact.faxNumber} />
        </DataSheet>
    );
};
