import { FileMessage } from '../../../../types/FileMessage';
import { translate } from '../../helpers/translate.helper';
import { IconFilesOff } from '../../icons/cdl/FilesOff';
import { TableEmptyState } from '../../TableEmptyState/TableEmptyState';
import { Flex } from '../../ui/Flex';

import { AttachmentRow } from './AttachmentRow';

interface AttachmentListProps {
    files: FileMessage[];
    companyId?: string;
    counterCompanyName?: string;
    hasWriteRights: boolean;
}

export const AttachmentList = ({ files, companyId, counterCompanyName, hasWriteRights }: AttachmentListProps) => {
    const sortedFiles = Array.from(files).sort((a, b) => {
        if (new Date(a.dateCreated) > new Date(b.dateCreated)) {
            return -1;
        }
        if (new Date(a.dateCreated) < new Date(b.dateCreated)) {
            return 1;
        }
        return 0;
    });

    if (!sortedFiles.length && hasWriteRights) {
        return null;
    }

    if (!sortedFiles.length && !hasWriteRights) {
        return (
            <Flex justifyContent="center" alignItems="center" height="100%">
                <TableEmptyState text={translate('attachments.noFiles')} Icon={IconFilesOff} />
            </Flex>
        );
    }

    return (
        <Flex flexDirection="column" padding={7} alignItems="stretch">
            {sortedFiles.map((file) => {
                return (
                    <AttachmentRow
                        key={file.id}
                        file={file}
                        companyId={companyId}
                        counterCompanyName={counterCompanyName}
                    />
                );
            })}
        </Flex>
    );
};
