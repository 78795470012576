import { ConsumptionCalculationBase } from '../../../../../types/ConumptionCalculationBase';
import { CategoryState, ReachInformationCategory } from '../../../../../types/ReachInformationMessage';
import { Tag } from '../../../../cdl/Tag/Tag';
import { Text } from '../../../../cdl/Text/Text';
import { Tooltip } from '../../../../cdl/Tooltip/Tooltip';
import { formatNumber } from '../../../../common/helpers/formatNumber.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useProductContext } from '../../../../common/hooks/useProductContext';
import { Box } from '../../../../common/ui/Box';
import { Flex } from '../../../../common/ui/Flex';

interface DaysLeftSailingProps {
    reachInformation: ReachInformationCategory;
}

export const DaysLeftSailing = ({ reachInformation }: DaysLeftSailingProps) => {
    const { isLubes } = useProductContext();

    const averageConsumption = formatNumber({
        number: reachInformation.averageDailyConsumption,
        maximumFractionDigits: 4,
    });

    const lubesAverageConsumptionLabel =
        reachInformation.consumptionCalculationBase === ConsumptionCalculationBase.DYNAMIC
            ? translate('schedule.priceCompare.basedOnCalculatedAverageLubes', { averageConsumption })
            : translate('schedule.priceCompare.basedOnFixedAverageLubes', { averageConsumption });

    const contextLabel = isLubes
        ? lubesAverageConsumptionLabel
        : translate('schedule.priceCompare.basedOnAverageConsumptionFuel', { averageConsumption });

    if (reachInformation.categoryState === CategoryState.OUT_OF_REACH) {
        return (
            <Tooltip label={contextLabel}>
                <span>
                    <Tag variant="negative" emphasis>
                        {translate('schedule.priceCompare.outOfReach')}
                    </Tag>
                </span>
            </Tooltip>
        );
    }

    if (reachInformation.categoryState === CategoryState.SAFETY_RESERVE) {
        return (
            <Tooltip
                label={
                    reachInformation.safetyReserve ? (
                        <Flex flexDirection="column">
                            <Box style={{ whiteSpace: 'nowrap' }}>{contextLabel}</Box>
                            <Box>
                                {isLubes
                                    ? translate('schedule.priceCompare.safetyReserveTooltipLubes', {
                                          safetyReserve: formatNumber({
                                              number: reachInformation.safetyReserve,
                                          }),
                                      })
                                    : translate('schedule.priceCompare.safetyReserveTooltipFuel', {
                                          safetyReserve: formatNumber({
                                              number: reachInformation.safetyReserve,
                                          }),
                                      })}
                            </Box>
                        </Flex>
                    ) : (
                        contextLabel
                    )
                }
            >
                <span>
                    <Tag variant="attention">{translate('schedule.priceCompare.onSafetyReserve')}</Tag>
                </span>
            </Tooltip>
        );
    }

    return (
        <Tooltip label={contextLabel}>
            <Text variant="extraSmall">
                {reachInformation.daysLeft === 1
                    ? translate('schedule.priceCompare.oneDayLeftSailing')
                    : translate('schedule.priceCompare.daysLeftSailing', {
                          daysLeft: formatNumber({
                              number: reachInformation.daysLeft,
                              maximumFractionDigits: 0,
                          }),
                      })}
            </Text>
        </Tooltip>
    );
};
