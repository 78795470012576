import { ReactNode } from 'react';

import { Popover, PopoverProps } from '../../cdl/Popover/Popover';
import { IconInfoSquare } from '../icons/cdl/InfoSquare';

import { IconButton } from './components/IconButton';

interface InfoPopoverProps extends Omit<PopoverProps, 'trigger'> {
    children: ReactNode;
    width?: string;
}

export const InfoPopover = ({ align = 'center', side = 'top', children, ...rest }: InfoPopoverProps) => {
    return (
        <Popover
            align={align}
            side={side}
            {...rest}
            trigger={
                <IconButton
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <IconInfoSquare height={16} width={16} />
                </IconButton>
            }
        >
            {children}
        </Popover>
    );
};
