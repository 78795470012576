import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { FuelCreateEnquiryFlow as OriginalFuelCreateEnquiryFlow } from './FuelCreateEnquiry';
import { useIsMounted } from './hooks/useIsMounted';

export const FuelCreateEnquiry = (props) => {
    // this "is mounted" hack is required because of the angular/react router combination
    const mounted = useIsMounted();

    if (!mounted) {
        return null;
    }

    return (
        <BrowserRouter basename={import.meta.env.BASE_URL}>
            <Routes>
                <Route
                    path="/fuel/create"
                    element={
                        <AppProviders>
                            <OriginalFuelCreateEnquiryFlow {...props} />
                        </AppProviders>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

export default createDirectiveFromReactComponent(FuelCreateEnquiry, 'reactFuelCreateEnquiry');
