import { useQueryClient } from '@tanstack/react-query';

import { VesselMessage } from '../../../types/VesselMessage';
import { useDrawer } from '../../cdl/Drawer/useDrawer.hook';
import { CreateVesselForm } from '../../vessel/CreateVessel/CreateVesselForm';
import { queries } from '../api/queryKeys/queries';

import { useRole } from './useRole';

type VesselCreateModalProps = {
    onSuccess?: (vessel: VesselMessage) => void;
    onError?: (vessel: VesselMessage) => void;
    onCancel?: () => void;
};

export const useVesselCreateModal = ({ onSuccess, onError, onCancel }: VesselCreateModalProps = {}) => {
    const { push, pop, hasLayers } = useDrawer();
    const queryClient = useQueryClient();
    const role = useRole();
    const customerIds = role.getCompaniesWithType('LUBES').map((company) => company.id);

    return {
        isOpen: hasLayers,
        open: () =>
            push({
                onPop: (layer) => !layer && onCancel?.(),
                content: (
                    <CreateVesselForm
                        onSave={({ data: response, status }) => {
                            if (status === 'success') {
                                queryClient.invalidateQueries(queries.vessels.list({ customerIds, active: true }));
                                queryClient.invalidateQueries(queries.vessels.totals({ customerIds }));
                                onSuccess?.(response.data);
                                pop({ content: response.data });
                            } else {
                                onError?.(response.data);
                            }
                        }}
                    />
                ),
            }),
    };
};
