import { Characters } from '../helpers/unicode.helper';

export const MiddleDotSeparator = () => {
    return (
        <span style={{ whiteSpace: 'nowrap' }}>
            {Characters.EN_SPACE}
            {Characters.MIDDLE_DOT}
            {Characters.EN_SPACE}
        </span>
    );
};
