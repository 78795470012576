import { IconCircle } from '../../../../../common/icons/cdl/Circle';
import { IconCircle1 } from '../../../../../common/icons/cdl/Circle1';
import { IconCircle2 } from '../../../../../common/icons/cdl/Circle2';
import { IconCircle3 } from '../../../../../common/icons/cdl/Circle3';
import { IconCircle4 } from '../../../../../common/icons/cdl/Circle4';
import { IconCircle5 } from '../../../../../common/icons/cdl/Circle5';
import { IconCircle6 } from '../../../../../common/icons/cdl/Circle6';
import { IconCircle7 } from '../../../../../common/icons/cdl/Circle7';
import { IconCircle8 } from '../../../../../common/icons/cdl/Circle8';
import { IconCircle9 } from '../../../../../common/icons/cdl/Circle9';

interface SolutionRankIconProps {
    rank: number;
    size?: number;
}

export const SolutionRankIcon = ({ rank, size = 16 }: SolutionRankIconProps) => {
    switch (rank) {
        case 1:
            return <IconCircle1 width={size} height={size} />;
        case 2:
            return <IconCircle2 width={size} height={size} />;
        case 3:
            return <IconCircle3 width={size} height={size} />;
        case 4:
            return <IconCircle4 width={size} height={size} />;
        case 5:
            return <IconCircle5 width={size} height={size} />;
        case 6:
            return <IconCircle6 width={size} height={size} />;
        case 7:
            return <IconCircle7 width={size} height={size} />;
        case 8:
            return <IconCircle8 width={size} height={size} />;
        case 9:
            return <IconCircle9 width={size} height={size} />;
        default:
            return <IconCircle width={size} height={size} />;
    }
};
