import PropTypes from 'prop-types';

import { useEnums } from '../hooks/useEnums';

export const HumanReadableEnumValue = (props) => {
    const { getHumanReadableValue } = useEnums();

    return getHumanReadableValue(props.enumType, props.value);
};

HumanReadableEnumValue.propTypes = {
    enumType: PropTypes.string.isRequired,
    value: PropTypes.string,
};
