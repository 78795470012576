import { useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment-timezone';

import { getFuelContractProducts } from '../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../common/api/queryKeys';

export const useContractProducts = ({ portId, eta, etd, categories, sulphurContents, groupId } = {}) => {
    const queryClient = useQueryClient();

    const localDateOnBeginningOfDay = (date) => {
        return moment.utc(date).startOf('day').toISOString();
    };

    const localDateOnEndOfDay = (date) => {
        return moment.utc(date).endOf('day').toISOString();
    };

    const validFrom = eta ? localDateOnBeginningOfDay(eta) : null;
    const validTo = etd ? localDateOnEndOfDay(etd) : null;

    const query = useQuery({
        queryKey: queryKeys.fuelContractProducts({
            portId,
            validFrom,
            validTo,
            categories,
            sulphurContents,
            groupId,
        }),

        queryFn: () =>
            getFuelContractProducts({
                portId,
                validFrom,
                validTo,
                categories,
                sulphurContents,
                groupId,
            }),
        enabled: !!portId && !!validFrom && !!validTo,
    });

    const prefetch = ({ portId, eta, etd }) => {
        const validFrom = eta ? localDateOnBeginningOfDay(eta) : null;
        const validTo = etd ? localDateOnEndOfDay(etd) : null;

        queryClient.prefetchQuery({
            queryKey: queryKeys.fuelContractProducts({
                portId,
                validFrom,
                validTo,
                categories,
                sulphurContents,
                groupId,
            }),
            queryFn: () =>
                getFuelContractProducts({
                    portId,
                    validFrom,
                    validTo,
                    categories,
                    sulphurContents,
                    groupId,
                }),
            enabled: !!portId && !!validFrom && !!validTo,
        });
    };

    return {
        prefetch,
        ...query,
    };
};
