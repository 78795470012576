import { Content, Root, Trigger } from '@radix-ui/react-popover';
import { Portal } from '@reach/portal';
import { animated, config, to, useTransition } from '@react-spring/web';
import { MouseEventHandler, ReactNode, useState } from 'react';

import { IconChevronDown } from '../../../common/icons/cdl/ChevronDown';
import { Box } from '../../../common/ui/Box';
import { Text } from '../../Text/Text';

import { ContentWrapper } from './components/ContentWrapper';
import { Pill } from './components/Pill';
import { RotatingIconCirclePlus } from './components/RotatingIconCirclePlus';

export interface FilterPillProps {
    label: string;
    value?: string;
    onClear: () => void;
    children?: (closePopover: () => void) => ReactNode;
    onPopoverClose?: () => void;
}

export const FilterPill = ({ label, value, onClear, onPopoverClose, children }: FilterPillProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const hasValue = value !== undefined && value.length > 0;

    const transitions = useTransition(isOpen, {
        from: { opacity: 0, y: -2, scale: 0.975 },
        enter: { opacity: 1, y: 0, scale: 1 },
        leave: { opacity: 0, y: -2, scale: 0.975 },
        config: {
            ...config.default,
            tension: 900,
            clamp: true,
        },
    });

    const handleRotatingCirclePlusClick: MouseEventHandler = (event) => {
        if (hasValue) {
            event.stopPropagation();
            onClear();
            setIsOpen(false);
        }
    };

    const handleClosePopover = () => {
        setIsOpen(false);
    };

    return (
        <Root
            open={isOpen}
            onOpenChange={(popoverOpen) => {
                setIsOpen(popoverOpen);

                if (!popoverOpen) {
                    onPopoverClose?.();
                }
            }}
        >
            <Trigger asChild>
                <Pill hasValue={hasValue} isOpen={isOpen}>
                    <Box color="foreground.subtle" marginRight={2}>
                        <RotatingIconCirclePlus $isRotated={hasValue} onClick={handleRotatingCirclePlusClick} />
                    </Box>
                    <Text variant="extraSmall" fontWeight="semiBold" color="foreground.subtle">
                        {label}
                    </Text>
                    {hasValue ? (
                        <>
                            <Box
                                height="16px"
                                width="1px"
                                backgroundColor="border.default"
                                marginLeft={3}
                                marginRight={3}
                            />
                            <Text variant="extraSmall" fontWeight="semiBold" color="accent.foreground">
                                {value}
                            </Text>
                            <Box color="foreground.subtle" marginLeft={2}>
                                <IconChevronDown width={16} height={16} />
                            </Box>
                        </>
                    ) : null}
                </Pill>
            </Trigger>
            <Portal>
                {transitions((styles, item) =>
                    item ? (
                        <Content
                            forceMount
                            asChild
                            side="bottom"
                            align="start"
                            sideOffset={8}
                            style={{ outline: 'none' }}
                        >
                            <animated.div
                                style={{
                                    transformOrigin: 'var(--radix-popover-content-transform-origin)',
                                    opacity: styles.opacity,
                                    transform: to(
                                        [styles.y, styles.scale],
                                        (y, scale) => `scale(${scale}) translateY(${y}px)`
                                    ),
                                }}
                            >
                                <ContentWrapper>{children?.(handleClosePopover)}</ContentWrapper>
                            </animated.div>
                        </Content>
                    ) : null
                )}
            </Portal>
        </Root>
    );
};
