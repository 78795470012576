import {
    CustomDoubleLineOption,
    CustomDoubleLineOptionProps,
} from '../../../cdl/Select/components/CustomDoubleLineOption';
import { PortOption } from '../PortDetailSelect';

interface PortOptionWithoutKeyPortsAndScheduleProps extends CustomDoubleLineOptionProps {
    data: PortOption;
}

export const PortOptionWithoutKeyPortsAndSchedule = (props: PortOptionWithoutKeyPortsAndScheduleProps) => {
    const portCountryWithLocode = `${props.data.port.country.name} (${props.data.port.locCode})`;

    return <CustomDoubleLineOption {...props} description={portCountryWithLocode} />;
};
