import { AgentGatewayMessage } from '../../../types/AgentMessage';

import { ActiveActions } from './ActiveActions';
import { ArchivedActions } from './ArchivedActions';

interface AgentTableActionsProps {
    agent: AgentGatewayMessage;
}

export const AgentTableActions = ({ agent }: AgentTableActionsProps) => {
    return agent.active ? <ActiveActions agent={agent} /> : <ArchivedActions agent={agent} />;
};
