import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../../cdl/Toast/useToasts';
import { createOrder } from '../../../../common/api/clients/order.api';
import { queryKeys } from '../../../../common/api/queryKeys';
import { translate } from '../../../../common/helpers/translate.helper';
import { useAngularRouterLocation } from '../../../../common/hooks/useAngularRouterLocation';
import { useNavigation } from '../../../../common/hooks/useNavigation';

export const useDraftCreate = () => {
    const queryClient = useQueryClient();
    const { navigate } = useNavigation();
    const { origin } = useAngularRouterLocation<{ origin: { stateName: string; params: { tab: string } } }>();

    const { addErrorToast } = useToasts();

    return useMutation({
        mutationFn: createOrder,
        onSuccess: (response) => {
            queryClient.removeQueries({ queryKey: queryKeys.orders() });
            queryClient.removeQueries({ queryKey: queryKeys.ordersTotals() });

            void navigate('lubes-create-enquiry-draft', {
                id: response.id,
                origin: origin,
            });
        },
        onError: () => {
            addErrorToast(translate('alert.defaulterror.message'));
        },
    });
};
