import { ScheduleMessage } from '../../../../types/ScheduleMessage';
import { OrderModel } from '../../model/OrderModel';

import { CompareWithScheduleCallout } from './CompareWithScheduleCallout';
import { DiscoverBestPricesCallout } from './DiscoverBestPricesCallout';

export interface CompareCalloutProps {
    schedules: ScheduleMessage[];
    order: OrderModel;
    onCompareClick: () => void;
    onApply: (portId: string, receiverSupplierIds: string[]) => void;
    trackingId: string;
}

export const CompareCallout = ({ schedules, order, onCompareClick, onApply, trackingId }: CompareCalloutProps) => {
    const isSpotOnly = order.spot && !order.receiverSupplierIds.length;

    if (schedules.length && !isSpotOnly) {
        return (
            <CompareWithScheduleCallout
                order={order}
                onCompareClick={onCompareClick}
                schedules={schedules}
                onApply={onApply}
                trackingId={trackingId}
            />
        );
    }

    return <DiscoverBestPricesCallout onCompareClick={onCompareClick} />;
};
