import { PriceCompareLubesMessage } from '../../../../types/PriceCompareLubesMessage';
import { VesselMessage } from '../../../../types/VesselMessage';
import { getLubesPriceCompare } from '../../../common/api/clients/gateway.api';

import { useEnhancedSchedule } from './useEnhancedSchedule';

export const useLubesSchedulesWithPrices = (vessel: VesselMessage) => {
    return useEnhancedSchedule<PriceCompareLubesMessage>(vessel, async (vesselId: string, portIds: string[]) =>
        portIds.length ? getLubesPriceCompare(vesselId, portIds).then((compareMessage) => compareMessage.prices) : []
    );
};
