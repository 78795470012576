import { BaseStatisticsMessage } from '../../../types/StatisticsMessage';

import { ProductGroupsTabFilter } from './ProductGroupsTabFilter';
import { ProductGroupsTabStatistics } from './ProductGroupsTabStatistics';

interface ProductGroupsTabContentProps {
    productGroupsStatistics: BaseStatisticsMessage['productGroup'];
}

export const ProductGroupsTabContent = ({ productGroupsStatistics }: ProductGroupsTabContentProps) => {
    return (
        <ProductGroupsTabFilter allEntries={productGroupsStatistics}>
            {(filteredEntries) => <ProductGroupsTabStatistics productGroupsStatistics={filteredEntries} />}
        </ProductGroupsTabFilter>
    );
};
