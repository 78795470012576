import PropTypes from 'prop-types';

export const ConditionalRenderer = ({ condition, children }) => {
    if (condition) {
        return children;
    }

    return null;
};

ConditionalRenderer.propTypes = {
    condition: PropTypes.bool,
    children: PropTypes.node,
};
