import { useField } from 'formik';

import { FormikInputProps } from '../../cdl/Input/FormikInput';

import { PasswordInput } from './PasswordInput';

export const FormikPasswordInput = (props: FormikInputProps) => {
    const [field, meta] = useField(props.name);

    return (
        <PasswordInput
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : props.caption}
            {...field}
            {...props}
        />
    );
};
