import { useAngularDependencies } from './useAngularDependencies';

interface Options {
    location?: boolean | string;
    inherit?: boolean;
    relative?: string;
    notify?: boolean;
    reload?: boolean | string | object;
}

interface NavigationType {
    navigate: (stateOrName: string, params?: object, options?: Options) => Promise<object>;
}

export const useNavigation = (): NavigationType => {
    const { $state } = useAngularDependencies();

    return {
        navigate: $state.go,
    };
};
