import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createPort } from '../../../../common/api/clients/port.api';
import { queryKeys } from '../../../../common/api/queryKeys';

export const usePortCreate = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createPort,
        onSuccess: () => queryClient.refetchQueries({ queryKey: queryKeys.ports() }),
    });
};
