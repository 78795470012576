import { useProductContext } from '../../../../common/hooks/useProductContext';
import { useRole } from '../../../../common/hooks/useRole';
import { Box } from '../../../../common/ui/Box';
import { OfferModel } from '../../../model/OfferModel';

import { AgentParty } from './InvolvedParties/AgentParty';
import { CustomerParty } from './InvolvedParties/CustomerParty';
import { SupplierParty } from './InvolvedParties/SupplierParty';
import { SurveyorParty } from './InvolvedParties/SurveyorParty';
import { VesselParty } from './InvolvedParties/VesselParty';

interface InvolvedPartiesProps {
    offer: OfferModel;
}

export const InvolvedParties = ({ offer }: InvolvedPartiesProps) => {
    const { isFuel } = useProductContext();
    const role = useRole();

    return (
        <Box display="flex">
            <VesselParty vessel={offer.vessel} />

            {role.isCustomer() || role.isAdmin() ? <SupplierParty offer={offer} /> : null}
            {role.isSupplier() || role.isAdmin() ? <CustomerParty offer={offer} /> : null}

            <AgentParty agent={offer.agentInformation} />

            {isFuel ? <SurveyorParty surveyor={offer.surveyor} /> : null}
        </Box>
    );
};
