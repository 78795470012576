import { ApprovalRequestState } from '../../../../types/ApprovalRequestState';
import { CustomerSettingsMessage } from '../../../../types/CustomerSettingsMessage';
import { OfferApprovalSettingsMessage } from '../../../../types/OfferApprovalSettingsMessage';
import { Button } from '../../../cdl/Button/Button';
import { StatusBar } from '../../../cdl/StatusBar/StatusBar';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { OfferModel } from '../../model/OfferModel';
import { OfferState } from '../../model/OfferState';
import { ConfirmChangesPopup } from '../common/ConfirmChangesPopup';
import { useSwitchState } from '../common/hooks/useSwitchState';
import { RequestApprovalButton } from '../common/RequestApprovalButton';

import { LubesConfirmDeliveryPopup } from './LubesConfirmDeliveryPopup';

interface LubesCustomerOfferDetailStatusBarProps {
    offer: OfferModel;
    customerSettings: CustomerSettingsMessage;
    offerApprovalSettings?: OfferApprovalSettingsMessage;
}

export const LubesCustomerOfferDetailStatusBar = ({
    offer,
    customerSettings,
}: LubesCustomerOfferDetailStatusBarProps) => {
    const { changeState } = useSwitchState();
    const role = useRole();

    const hasWriteRights = role.hasWriteRights(offer.customerId);

    switch (offer.state) {
        case OfferState.ORDER: {
            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.customer.order.label')}
                    subHeadline={translate('statusbar.customer.order.subtitle')}
                />
            );
        }
        case OfferState.CUSTOMER_ADJUSTED: {
            if (!customerSettings.approvalSettings.activated) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.customer.customerAdjusted.label')}
                        subHeadline={translate('statusbar.customer.customerAdjusted.subtitle')}
                        trailingVisual={
                            hasWriteRights ? (
                                <Button
                                    emphasis="high"
                                    onClick={() => {
                                        changeState({
                                            offer,
                                            destinationState: OfferState.ORDER,
                                        });
                                    }}
                                >
                                    {translate('statusbar.customer.customerAdjusted.button')}
                                </Button>
                            ) : null
                        }
                    />
                );
            }

            const latestApprovalRequestState = offer.approvalRequests?.[0]?.state;

            if (latestApprovalRequestState === ApprovalRequestState.PENDING) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.customer.customerAdjustedApprovalPending.label')}
                        subHeadline={translate('statusbar.customer.customerAdjustedApprovalPending.subtitle')}
                        trailingVisual={
                            hasWriteRights ? (
                                <RequestApprovalButton offer={offer}>
                                    {translate('statusbar.customer.customerAdjustedApprovalPending.button')}
                                </RequestApprovalButton>
                            ) : null
                        }
                    />
                );
            }

            if (latestApprovalRequestState === ApprovalRequestState.DECLINED) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.customer.customerAdjustedApprovalDeclined.label')}
                        subHeadline={translate('statusbar.customer.customerAdjustedApprovalDeclined.subtitle')}
                        trailingVisual={
                            hasWriteRights ? (
                                <RequestApprovalButton offer={offer}>
                                    {translate('statusbar.customer.customerAdjustedApprovalDeclined.button')}
                                </RequestApprovalButton>
                            ) : null
                        }
                    />
                );
            }

            if (latestApprovalRequestState === ApprovalRequestState.EXPIRED) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.customer.customerAdjustedApprovalExpired.label')}
                        subHeadline={translate('statusbar.customer.customerAdjustedApprovalExpired.subtitle')}
                        trailingVisual={
                            hasWriteRights ? (
                                <RequestApprovalButton offer={offer}>
                                    {translate('statusbar.customer.customerAdjustedApprovalExpired.button')}
                                </RequestApprovalButton>
                            ) : null
                        }
                    />
                );
            }

            if (latestApprovalRequestState === ApprovalRequestState.WITHDRAWN) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.customer.customerAdjustedApprovalWithdrawn.label')}
                        subHeadline={translate('statusbar.customer.customerAdjustedApprovalWithdrawn.subtitle')}
                        trailingVisual={
                            hasWriteRights ? (
                                <RequestApprovalButton offer={offer}>
                                    {translate('statusbar.customer.customerAdjustedApprovalWithdrawn.button')}
                                </RequestApprovalButton>
                            ) : null
                        }
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.customer.customerAdjustedApproval.label')}
                    subHeadline={translate('statusbar.customer.customerAdjustedApproval.subtitle')}
                    trailingVisual={
                        hasWriteRights ? (
                            <RequestApprovalButton offer={offer}>
                                {translate('statusbar.customer.customerAdjustedApproval.button')}
                            </RequestApprovalButton>
                        ) : null
                    }
                />
            );
        }
        case OfferState.SUPPLIER_ADJUSTED: {
            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.customer.supplierAdjusted.label')}
                    subHeadline={translate('statusbar.customer.supplierAdjusted.subtitle')}
                    trailingVisual={
                        hasWriteRights ? (
                            <Button
                                emphasis="high"
                                onClick={() => {
                                    changeState({
                                        offer,
                                        destinationState: OfferState.ORDER,
                                        component: ConfirmChangesPopup,
                                    });
                                }}
                            >
                                {translate('statusbar.customer.supplierAdjusted.button')}
                            </Button>
                        ) : null
                    }
                />
            );
        }
        case OfferState.CONFIRMED: {
            if (!offer.deliveryPast() || customerSettings.automaticallyMarkAsDelivered) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.customer.confirmed.label')}
                        subHeadline={translate('statusbar.customer.confirmed.subtitle')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.customer.delivery.label')}
                    subHeadline={translate('statusbar.customer.delivery.subtitle')}
                    trailingVisual={
                        hasWriteRights ? (
                            <Button
                                emphasis="high"
                                onClick={() => {
                                    changeState({
                                        offer,
                                        destinationState: OfferState.DELIVERED,
                                        component: LubesConfirmDeliveryPopup,
                                    });
                                }}
                            >
                                {translate('statusbar.customer.delivery.button')}
                            </Button>
                        ) : null
                    }
                />
            );
        }
        case OfferState.ACKNOWLEDGED: {
            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.customer.acknowledged.label')}
                    subHeadline={translate('statusbar.customer.acknowledged.subtitle')}
                />
            );
        }
        case OfferState.DELIVERED: {
            return (
                <StatusBar
                    variant="positive"
                    headline={translate('statusbar.customer.delivered.label')}
                    subHeadline={translate('statusbar.customer.delivered.subtitle')}
                />
            );
        }
        case OfferState.DELIVERY_CONFIRMED: {
            return (
                <StatusBar
                    variant="positive"
                    headline={translate('statusbar.customer.deliveryconfirmed.label')}
                    subHeadline={translate('statusbar.customer.deliveryconfirmed.subtitle')}
                />
            );
        }
        case OfferState.INVOICED: {
            return (
                <StatusBar
                    variant="positive"
                    headline={translate('statusbar.customer.invoiced.label')}
                    subHeadline={translate('statusbar.customer.invoiced.subtitle')}
                />
            );
        }
        case OfferState.CANCELED: {
            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.customer.canceled.label')}
                    subHeadline={translate('statusbar.customer.canceled.subtitle')}
                />
            );
        }
    }
    return null;
};
