import { ReactNode } from 'react';

import { ReportsMessage } from '../../../types/SpecificReportMessage';
import { CustomerSpecificStatisticsMessage } from '../../../types/StatisticsMessage';
import { DynamicFilterContainer, Filter } from '../../cdl/Filter/DynamicFilterContainer/DynamicFilterContainer';
import { FilterMultiSelectFull } from '../../cdl/Filter/FilterMultiSelectFull/FilterMultiSelectFull';
import { translate } from '../../common/helpers/translate.helper';

interface SuppliersTabFilterProps {
    allEntries: CustomerSpecificStatisticsMessage['supplier'];
    children: (filteredEntries: CustomerSpecificStatisticsMessage['supplier']) => ReactNode;
}

export const SuppliersTabFilter = ({ children, allEntries }: SuppliersTabFilterProps) => {
    const supplierFilter: Filter<string, ReportsMessage> = {
        filterFn: (values, entry) => {
            return values.includes(entry.context.id);
        },
        component: (values, setValues) => {
            const availableOptions = Array.from(new Set(allEntries.map((entry) => entry.context.id))).map(
                (supplierId) => ({
                    label: allEntries.find((entry) => entry.context.id === supplierId)?.context.name ?? '',
                    value: supplierId,
                })
            );

            return (
                <FilterMultiSelectFull
                    options={availableOptions}
                    values={values}
                    onChange={setValues}
                    label={translate('reporting.filter.supplier.title')}
                    filterByLabel={translate('reporting.filter.supplier.filterBySupplier')}
                    filterPlaceholder={translate('reporting.filter.supplier.searchForSupplier')}
                />
            );
        },
    };

    return (
        <DynamicFilterContainer allEntries={allEntries} filters={{ supplier: supplierFilter }}>
            {children}
        </DynamicFilterContainer>
    );
};
