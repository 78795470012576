import { ReactNode, useState, MouseEvent } from 'react';
import styled from 'styled-components';

import { ContractProductMessage } from '../../../types/ContractProductMessage';
import { Text } from '../../cdl/Text/Text';
import { TextButton } from '../../common/buttons/TextButton';
import { FuelContractPriceDisplay } from '../../common/FuelPriceDisplay/FuelContractPriceDisplay';
import { FuelMarketPriceDisplay } from '../../common/FuelPriceDisplay/FuelMarketPriceDisplay';
import { translate } from '../../common/helpers/translate.helper';
import { translateSulphurContent } from '../../common/helpers/translateSulphurContent';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';
import { Grid, GridItem } from '../../common/ui/Grid';

import { ContractPopup } from './ContractPopup';
import { RemainingContractVolume } from './RemainingContractVolume';

const SulphurContentTag = ({ children }: { children: ReactNode }) => {
    return (
        <Box borderRadius={1} backgroundColor="dark-blue.8" paddingX={3} paddingY={1}>
            <Text size={12} weight="semi" caps style={{ lineHeight: '20px' }}>
                {children}
            </Text>
        </Box>
    );
};

const WrapperGrid = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
`;

interface ContractProductBoxContentProps {
    contractProduct: ContractProductMessage;
    additionalFooterContent?: ReactNode;
    hasPlattsCredentials: boolean;
    customerId: string;
}

export const ContractProductBoxContent = ({
    contractProduct,
    additionalFooterContent,
    hasPlattsCredentials,
    customerId,
}: ContractProductBoxContentProps) => {
    const [popupOpen, setPopupOpen] = useState(false);

    const onPopupButtonClick = (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        setPopupOpen(true);
    };

    const contractProductGroup = contractProduct.contract.productGroups.find((productGroup) =>
        productGroup.products.some((product) => product.productId === contractProduct.productId)
    )!!;

    return (
        <WrapperGrid>
            <Box paddingX={5} paddingY={4}>
                <Box display="grid" maxWidth="calc(100% - 32px)">
                    <Text
                        fontSize={12}
                        weight="semi"
                        caps
                        as="div"
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            minWidth: 0,
                        }}
                    >
                        {contractProduct.contract.supplier.name}
                    </Text>
                    <Text
                        fontSize={12}
                        as="div"
                        weight="semi"
                        color="dark-blue.2"
                        caps
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            minWidth: 0,
                        }}
                    >
                        {contractProduct.contract.reference}
                    </Text>
                </Box>
                <Flex marginTop={5} alignItems="center" justifyContent="space-between">
                    <Text size={24} weight="semi" marginRight={4}>
                        {contractProduct.product.name}
                    </Text>
                    <SulphurContentTag>
                        {translateSulphurContent(contractProduct.product.sulphurContent)}
                    </SulphurContentTag>
                </Flex>

                <Box marginTop={5}>
                    <RemainingContractVolume
                        contractVolume={contractProductGroup.contractVolume}
                        usedContractVolume={contractProductGroup.usedContractVolume}
                    />
                </Box>
                <Box marginTop={5}>
                    {/*@ts-ignore*/}
                    <TextButton onClick={onPopupButtonClick}>
                        {translate('fuelEnquiry.second.contractProducts.moreInfo')}
                    </TextButton>
                </Box>

                <ContractPopup
                    contractProduct={contractProduct}
                    contractProductGroup={contractProductGroup}
                    onDismiss={() => setPopupOpen(false)}
                    open={popupOpen}
                />
            </Box>
            <div>
                <Box width="100%" borderTop={1} borderColor="border.default" />
                <Grid width="100%" paddingX={4}>
                    <GridItem width={1 / 2}>
                        <FuelMarketPriceDisplay
                            pricingCode={contractProduct?.marketPricePricingCode}
                            priceDate={contractProduct?.marketPriceDate}
                            price={contractProduct?.marketPrice}
                            hasPlattsCredentials={hasPlattsCredentials}
                            customerId={customerId}
                            positive={false}
                            label={translate('fuelEnquiry.second.contractProducts.market')}
                        />
                    </GridItem>

                    <GridItem width={1 / 2}>
                        <FuelContractPriceDisplay
                            pricingCode={contractProduct?.contractPricePricingCode}
                            priceDate={contractProduct?.contractPriceDate}
                            price={contractProduct?.contractPrice}
                            contractBasePriceDifference={contractProduct?.contractBasePriceDifference}
                            contractBasePrice={contractProduct?.contractBasePrice}
                            supplierName={contractProduct?.contract?.supplier?.name}
                            contract={contractProduct?.contract}
                            positive={false}
                            hasPlattsCredentials={hasPlattsCredentials}
                            customerId={customerId}
                            label={translate('fuelEnquiry.second.contractProducts.contract')}
                        />
                    </GridItem>
                </Grid>
                {additionalFooterContent}
            </div>
        </WrapperGrid>
    );
};
