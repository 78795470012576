import { Select, SelectProps } from '../../cdl/Select/Select';
import { useEnums } from '../hooks/useEnums';

export interface EnumOptionSelectOption {
    value: string;
    label: string;
}

export interface EnumOptionSelectProps extends Omit<SelectProps<EnumOptionSelectOption>, 'value' | 'options'> {
    enumType: string;
    context?: string;
    sort?: (options: EnumOptionSelectOption[]) => void;
    filter?: (option: EnumOptionSelectOption) => void;
    value?: string;
    markAsRequired?: boolean;
}

export const EnumOptionSelect = ({ enumType, context, sort, filter, value, ...rest }: EnumOptionSelectProps) => {
    const { getEnumsForTypeAndContext } = useEnums();

    const getOptions = () => {
        const enums = getEnumsForTypeAndContext(enumType, context);

        if (!enums) {
            return [];
        }

        const options = Object.keys(enums)
            .map((key) => {
                const value = enums[key];

                return {
                    value: key,
                    label: value.humanReadableValue as string,
                } as EnumOptionSelectOption;
            })
            .filter((value) => (filter ? filter(value) : true));

        if (sort) {
            sort(options);
        }

        return options;
    };

    const options = getOptions();

    const selectedValue = options.find((option) => option.value === value);

    return <Select {...rest} value={selectedValue} options={options} />;
};
