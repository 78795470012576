import PropTypes from 'prop-types';

import { Button } from '../../../common/buttons/Button';
import { translate } from '../../../common/helpers/translate.helper';
import { IconPlus } from '../../../common/icons/cdl/Plus';
import { Box } from '../../../common/ui/Box';

import { PortPriceInformation } from './PortPriceInformation';

export const PriceInfoSection = ({
    isFetching,
    portInformation,
    priceMap,
    onEnquiryButtonClick,
    onRemovePortButtonClick,
    onPortAddedClick,
    customerId,
}) => {
    const _onPortAddedClick = (event) => {
        event.preventDefault();

        onPortAddedClick();
    };

    return (
        <Box>
            {portInformation.map((port, index) => {
                const supplierData = priceMap?.[port.portId];
                return (
                    <PortPriceInformation
                        key={port.id}
                        index={index}
                        onEnquiryButtonClick={onEnquiryButtonClick}
                        onRemovePortButtonClick={onRemovePortButtonClick}
                        isFetching={isFetching}
                        supplierData={supplierData}
                        customerId={customerId}
                        portId={port.portId}
                        showRemoveIcon={Object.keys(portInformation).length > 1}
                    />
                );
            })}

            <Box width="200px" style={{ flexShrink: 0 }}>
                <Button color="light-dark" onClick={_onPortAddedClick}>
                    <IconPlus />
                    {translate('compare.addPort')}
                </Button>
            </Box>
        </Box>
    );
};

PriceInfoSection.propTypes = {
    isFetching: PropTypes.bool,
    portInformation: PropTypes.array,
    priceMap: PropTypes.object,
    onEnquiryButtonClick: PropTypes.func,
    onRemovePortButtonClick: PropTypes.func,
    onPortAddedClick: PropTypes.func,
    customerId: PropTypes.string,
};
