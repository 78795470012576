import { Button } from '../../../cdl/Button/Button';
import { StatusBar } from '../../../cdl/StatusBar/StatusBar';
import { translate } from '../../../common/helpers/translate.helper';
import { useNavigation } from '../../../common/hooks/useNavigation';
import { IconCircleCheck } from '../../../common/icons/cdl/CircleCheck';
import { IconCircleX } from '../../../common/icons/cdl/CircleX';
import { CircleValidity } from '../../../common/Validity/CircleValidity';
import { OfferModel } from '../../model/OfferModel';
import { OfferState } from '../../model/OfferState';
import { AcknowledgePopup } from '../common/components/AcknowledgePopup';
import { ConfirmOrderPopup } from '../common/ConfirmOrderPopup';
import { useSwitchState } from '../common/hooks/useSwitchState';

import { FuelConfirmDeliveryPopup } from './FuelConfirmDeliveryPopup';
import { FuelSupplierCounterOfferExpiredStatusBar } from './FuelSupplierCounterOfferExpiredStatusBar';

interface FuelSupplierOfferDetailStatusBarProps {
    offer: OfferModel;
}

export const FuelSupplierOfferDetailStatusBar = ({ offer }: FuelSupplierOfferDetailStatusBarProps) => {
    const { navigate } = useNavigation();
    const { changeState } = useSwitchState() as any;

    switch (offer.state) {
        case OfferState.ENQUIRY: {
            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.supplier.fuel.enquiry.headline')}
                    subHeadline={translate('statusbar.supplier.fuel.enquiry.subHeadline')}
                    trailingVisual={
                        <>
                            {!!offer.validUntil && !!offer.validityTime ? (
                                <CircleValidity
                                    validUntil={offer.validUntil}
                                    validityTime={offer.validityTime}
                                    background="transparent"
                                />
                            ) : null}

                            <Button
                                emphasis="high"
                                onClick={() => {
                                    navigate('base.fuel-quote', {
                                        id: offer.id,
                                    });
                                }}
                            >
                                {translate('statusbar.supplier.fuel.enquiry.button')}
                            </Button>
                        </>
                    }
                />
            );
        }
        case OfferState.QUOTED: {
            const navigateToQuote = () => {
                navigate('base.fuel-quote', {
                    id: offer.id,
                });
            };

            if (offer.counterOffers.length) {
                const latestCounterOffer = offer.counterOffers[0];

                switch (latestCounterOffer.state) {
                    case 'ACCEPTED': {
                        return (
                            <StatusBar
                                variant="neutral"
                                headline={translate('statusbar.supplier.fuel.counterOfferAccepted.headline')}
                                subHeadline={translate('statusbar.supplier.fuel.counterOfferAccepted.subHeadline')}
                            />
                        );
                    }
                    case 'EXPIRED':
                    case 'OPEN': {
                        return (
                            <FuelSupplierCounterOfferExpiredStatusBar
                                onButtonClick={navigateToQuote}
                                latestCounterOffer={latestCounterOffer}
                                offer={offer}
                            />
                        );
                    }
                }
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.supplier.fuel.quoted.headline')}
                    subHeadline={translate('statusbar.supplier.fuel.quoted.subHeadline')}
                    trailingVisual={
                        <>
                            {!!offer.quoteValidUntil && !!offer.quoteValidityTime ? (
                                <CircleValidity
                                    validUntil={offer.quoteValidUntil}
                                    validityTime={offer.quoteValidityTime}
                                    background="transparent"
                                />
                            ) : null}
                            <Button emphasis="medium" onClick={navigateToQuote}>
                                {translate('statusbar.supplier.fuel.quoted.button')}
                            </Button>
                        </>
                    }
                />
            );
        }
        case OfferState.CONFIRMED: {
            if (!offer.deliveryPast()) {
                if (offer.contractId) {
                    return (
                        <StatusBar
                            variant="neutral"
                            headline={translate('statusbar.supplier.fuel.confirmedContract.headline')}
                            subHeadline={translate('statusbar.supplier.fuel.confirmedContract.subHeadline')}
                        />
                    );
                }

                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.supplier.fuel.confirmed.headline')}
                        subHeadline={translate('statusbar.supplier.fuel.confirmed.subHeadline')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.supplier.fuel.confirmedPastDelivery.headline')}
                    subHeadline={translate('statusbar.supplier.fuel.confirmedPastDelivery.subHeadline')}
                    trailingVisual={
                        <Button
                            emphasis="high"
                            onClick={() => {
                                changeState({
                                    offer,
                                    destinationState: OfferState.DELIVERED,
                                    component: FuelConfirmDeliveryPopup,
                                });
                            }}
                        >
                            {translate('statusbar.supplier.fuel.confirmedPastDelivery.button')}
                        </Button>
                    }
                />
            );
        }
        case OfferState.ORDER: {
            const headlineTranslationKey = offer.contractId
                ? 'statusbar.supplier.fuel.orderContract.headline'
                : 'statusbar.supplier.fuel.order.headline';

            const subHeadlineTranslationKey = offer.contractId
                ? 'statusbar.supplier.fuel.orderContract.subHeadline'
                : 'statusbar.supplier.fuel.order.subHeadline';

            const acknowledgeButtonTranslationKey = offer.contractId
                ? 'statusbar.supplier.fuel.orderContract.acknowledgeButton'
                : 'statusbar.supplier.fuel.order.acknowledgeButton';

            const confirmButtonTranslationKey = offer.contractId
                ? 'statusbar.supplier.fuel.orderContract.confirmButton'
                : 'statusbar.supplier.fuel.order.confirmButton';

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate(headlineTranslationKey)}
                    subHeadline={translate(subHeadlineTranslationKey)}
                    trailingVisual={
                        <>
                            <Button
                                emphasis="medium"
                                color="solid-clear"
                                onClick={() => {
                                    changeState({
                                        offer,
                                        destinationState: OfferState.ACKNOWLEDGED,
                                        component: AcknowledgePopup,
                                    });
                                }}
                            >
                                {translate(acknowledgeButtonTranslationKey)}
                            </Button>

                            <Button
                                emphasis="high"
                                onClick={() => {
                                    changeState({
                                        offer,
                                        destinationState: OfferState.CONFIRMED,
                                        component: ConfirmOrderPopup,
                                    });
                                }}
                            >
                                {translate(confirmButtonTranslationKey)}
                            </Button>
                        </>
                    }
                />
            );
        }
        case OfferState.CUSTOMER_ADJUSTED: {
            const headlineTranslationKey = offer.contractId
                ? 'statusbar.supplier.fuel.customerAdjustedContract.headline'
                : 'statusbar.supplier.fuel.customerAdjusted.headline';
            const subHeadlineTranslationKey = offer.contractId
                ? 'statusbar.supplier.fuel.customerAdjustedContract.subHeadline'
                : 'statusbar.supplier.fuel.customerAdjusted.subHeadline';

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate(headlineTranslationKey)}
                    subHeadline={translate(subHeadlineTranslationKey)}
                />
            );
        }
        case OfferState.SUPPLIER_ADJUSTED: {
            const headlineTranslationKey = offer.contractId
                ? 'statusbar.supplier.fuel.supplierAdjustedContract.headline'
                : 'statusbar.supplier.fuel.supplierAdjusted.headline';
            const subHeadlineTranslationKey = offer.contractId
                ? 'statusbar.supplier.fuel.supplierAdjustedContract.subHeadline'
                : 'statusbar.supplier.fuel.supplierAdjusted.subHeadline';

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate(headlineTranslationKey)}
                    subHeadline={translate(subHeadlineTranslationKey)}
                />
            );
        }
        case OfferState.ACKNOWLEDGED: {
            const headlineTranslationKey = offer.contractId
                ? 'statusbar.supplier.fuel.acknowledgedContract.headline'
                : 'statusbar.supplier.fuel.acknowledged.headline';

            const subHeadlineTranslationKey = offer.contractId
                ? 'statusbar.supplier.fuel.acknowledgedContract.subHeadline'
                : 'statusbar.supplier.fuel.acknowledged.subHeadline';

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate(headlineTranslationKey)}
                    subHeadline={translate(subHeadlineTranslationKey)}
                    trailingVisual={
                        <Button
                            emphasis="high"
                            onClick={() => {
                                changeState({
                                    offer,
                                    destinationState: OfferState.CONFIRMED,
                                    component: ConfirmOrderPopup,
                                });
                            }}
                        >
                            {translate('statusbar.supplier.fuel.acknowledged.button')}
                        </Button>
                    }
                />
            );
        }
        case OfferState.DELIVERED: {
            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="positive"
                        headline={translate('statusbar.supplier.fuel.deliveredContract.headline')}
                        subHeadline={translate('statusbar.supplier.fuel.deliveredContract.subHeadline')}
                        leadingVisual={<IconCircleCheck />}
                    />
                );
            }

            return (
                <StatusBar
                    variant="positive"
                    headline={translate('statusbar.supplier.fuel.delivered.headline')}
                    subHeadline={translate('statusbar.supplier.fuel.delivered.subHeadline')}
                    leadingVisual={<IconCircleCheck />}
                />
            );
        }
        case OfferState.INVOICED: {
            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="positive"
                        headline={translate('statusbar.supplier.fuel.invoicedContract.headline')}
                        subHeadline={translate('statusbar.supplier.fuel.invoicedContract.subHeadline')}
                        leadingVisual={<IconCircleCheck />}
                    />
                );
            }

            return (
                <StatusBar
                    variant="positive"
                    headline={translate('statusbar.supplier.fuel.invoiced.headline')}
                    subHeadline={translate('statusbar.supplier.fuel.invoiced.subHeadline')}
                    leadingVisual={<IconCircleCheck />}
                />
            );
        }
        case OfferState.CANCELED: {
            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="attention"
                        headline={translate('statusbar.supplier.fuel.canceledContract.headline')}
                        subHeadline={translate('statusbar.supplier.fuel.canceledContract.subHeadline')}
                        leadingVisual={<IconCircleX />}
                    />
                );
            }

            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.supplier.fuel.canceled.headline')}
                    subHeadline={translate('statusbar.supplier.fuel.canceled.subHeadline')}
                    leadingVisual={<IconCircleX />}
                />
            );
        }
        case OfferState.QUOTE_CANCELED:
        case OfferState.ENQUIRY_CANCELED: {
            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.supplier.fuel.enquiryCanceled.headline')}
                    subHeadline={translate('statusbar.supplier.fuel.enquiryCanceled.subHeadline')}
                    leadingVisual={<IconCircleX />}
                />
            );
        }
        case OfferState.ENQUIRY_DECLINED: {
            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.supplier.fuel.enquiryDeclined.headline')}
                    subHeadline={translate('statusbar.supplier.fuel.enquiryDeclined.subHeadline')}
                    leadingVisual={<IconCircleX />}
                />
            );
        }
        case OfferState.ENQUIRY_EXPIRED: {
            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.supplier.fuel.enquiryExpired.headline')}
                    subHeadline={translate('statusbar.supplier.fuel.enquiryExpired.subHeadline')}
                    leadingVisual={<IconCircleX />}
                />
            );
        }
        case OfferState.QUOTE_DECLINED: {
            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.supplier.fuel.quoteDeclined.headline')}
                    subHeadline={translate('statusbar.supplier.fuel.quoteDeclined.subHeadline')}
                    leadingVisual={<IconCircleX />}
                />
            );
        }
    }
    return null;
};
