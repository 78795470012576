import { BunkerCaseGeometryResponse } from '../../../../types/BunkerCaseGeometryResponse';
import { BunkerCaseResponse } from '../../../../types/BunkerCaseResponse';
import { BunkerCaseSolutionResponse } from '../../../../types/BunkerCaseSolutionResponse';
import { GlobalPricesMessage } from '../../../../types/GlobalPricesMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';
import { authorizedGatewayAxiosInstance } from '../instances/AuthorizedGatewayAxiosInstance';

interface BunkerMetricCredentialsMessage {
    credentials: { customerId: string }[];
}

export const getBunkerMetricCredentials = async (): Promise<BunkerMetricCredentialsMessage> => {
    const response = await authorizedAxiosInstance.get(`/v1/bunker-metric/credentials`);

    return response.data;
};

export const getBunkerMetricGlobalPrices = async (): Promise<GlobalPricesMessage> => {
    const response = await authorizedAxiosInstance.get('/v1/bunker-metric/global-prices');
    return response.data;
};

interface GetBunkerCaseParams {
    vesselId: string;
    caseNumber: string;
}

export const getBunkerMetricBunkerCase = async ({
    vesselId,
    caseNumber,
}: GetBunkerCaseParams): Promise<BunkerCaseResponse> => {
    const response = await authorizedGatewayAxiosInstance.get(
        `/v1/bunker-metric/vessels/${vesselId}/bunker-cases/${caseNumber}`
    );

    return response.data;
};

interface GetBunkerCaseSolutionParams {
    vesselId: string;
    caseNumber: string;
    solutionNumber: string;
}

export const getBunkerMetricBunkerCaseSolution = async ({
    vesselId,
    caseNumber,
    solutionNumber,
}: GetBunkerCaseSolutionParams): Promise<BunkerCaseSolutionResponse> => {
    const response = await authorizedGatewayAxiosInstance.get(
        `/v1/bunker-metric/vessels/${vesselId}/bunker-cases/${caseNumber}/solutions/${solutionNumber}`
    );

    return response.data;
};

interface GetBunkerMetricBunkerCaseGeometryParams {
    vesselId: string;
    caseNumber: string;
    solutionNumber: string;
}

export const getBunkerMetricBunkerCaseGeometry = async ({
    vesselId,
    caseNumber,
    solutionNumber,
}: GetBunkerMetricBunkerCaseGeometryParams): Promise<BunkerCaseGeometryResponse> => {
    const response = await authorizedGatewayAxiosInstance.get(
        `/v1/bunker-metric/vessels/${vesselId}/bunker-cases/${caseNumber}/solutions/${solutionNumber}/geometry`
    );

    return response.data;
};
