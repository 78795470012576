import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { ProductContext } from '../../../../../types/ProductContext';
import { queries } from '../../../../common/api/queryKeys/queries';
import { useAbly } from '../../../../common/hooks/useAbly';
import { useRole } from '../../../../common/hooks/useRole';
import { OfferModel } from '../../../../offer/model/OfferModel';
import { OrderModel } from '../../../model/OrderModel';
import { sortOffers } from '../../common/sortOffers';

export const useLubesOrderOffers = (order: OrderModel) => {
    const { subscribe, unsubscribe } = useAbly();
    const role = useRole();

    const types = ['LUBES'];

    const filterOffer = (offer: OfferModel) => {
        if (role.isAdmin()) {
            return offer.wasQuoted() || !offer.isSpot();
        }

        return true;
    };

    const offerListQuery = useQuery({
        ...queries.offers.list({ orderIds: [order.id], types }),
        enabled: !!order.id,
        select: (data) => data.filter(filterOffer).sort(sortOffers(ProductContext.LUBES)),
    });

    useEffect(() => {
        const offerTopic = `customers:${order.customerId}:orders:${order.id}:offers`;

        subscribe(offerTopic, () => {
            offerListQuery.refetch();
        });

        return () => {
            unsubscribe(offerTopic);
        };
    }, [offerListQuery, order, subscribe, unsubscribe]);

    return offerListQuery;
};
