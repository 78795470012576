import { useField } from 'formik';

import { RoleSelect, RoleSelectProps } from '../../../user/components/RoleSelect';

interface FormikRoleSelectProps extends Pick<RoleSelectProps, 'caption'> {
    name: string;
    label?: string;
    onRoleChange?: (value?: string) => void;
}

export const FormikRoleSelect = (props: FormikRoleSelectProps) => {
    const [field, meta, helpers] = useField(props.name);

    const onChange = (option?: string) => {
        if (field.value !== option) {
            props.onRoleChange?.(option);
        }
        helpers.setValue(option);
    };

    return (
        <RoleSelect
            {...props}
            onChange={onChange}
            value={field.value}
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : props.caption}
            onBlur={() => {
                helpers.setTouched(true);
            }}
        />
    );
};
