import { Text } from '../../../cdl/Text/Text';
import { LegacyCustomerCompanyMultiSelect } from '../../../common/form-elements/CompanySelect/LegacyCustomerCompanyMultiSelect';
import { LegacySupplierCompanyMultiSelect } from '../../../common/form-elements/CompanySelect/LegacySupplierCompanyMultiSelect';
import { DeprecatedDebouncedSearchTextInput } from '../../../common/form-elements/SearchTextInput/DeprecatedDebouncedSearchTextInput';
import { translate as t } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { Page } from '../../../common/Page/Page';
import { PageGrid } from '../../../common/PageGrid';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex';

import { FuelOrderOverviewSortSelect } from './FuelOrderOverviewSortSelect';
import { FuelOrderOverviewState } from './FuelOrderOverviewState';
import { FuelOrderOverviewTable } from './FuelOrderOverviewTable';

export const OrderOverviewPage = () => {
    return (
        <FuelOrderOverviewState>
            <FuelOrderOverviewPageContent />
        </FuelOrderOverviewState>
    );
};

const FuelOrderOverviewPageContent = () => {
    const role = useRole();
    const { context } = useProductContext();

    useDocumentTitle(t('order.overviewPageTitle'));

    const [locationState, { setLocationFieldValue }] = useLocationState();

    return (
        <Page>
            <PageGrid>
                <Text variant="headline" as="h1">
                    {t('order.overviewPageTitle')}
                </Text>

                <Flex justifyContent="space-between">
                    <DeprecatedDebouncedSearchTextInput
                        placeholder={t('order.searchInputPlaceholder')}
                        value={locationState.searchQuery}
                        onChange={(searchQuery) => setLocationFieldValue('searchQuery', searchQuery)}
                    />

                    <Flex marginLeft={5}>
                        {!role.isOneCompanyUser(context) || role.isAdmin() ? (
                            <Box minWidth="300px" maxWidth="80%" marginRight={5}>
                                <LegacyCustomerCompanyMultiSelect
                                    companyIds={locationState.customerIds}
                                    onChange={(customerIds) => {
                                        setLocationFieldValue('customerIds', customerIds);
                                    }}
                                    placeholder={
                                        role.isAdmin()
                                            ? t('order.filterCustomerPlaceholder')
                                            : t('order.filterCompanyPlaceholder')
                                    }
                                />
                            </Box>
                        ) : null}

                        {role.isAdmin() ? (
                            <Box minWidth="300px" maxWidth="80%" marginRight={5}>
                                <LegacySupplierCompanyMultiSelect
                                    companyIds={locationState.supplierIds}
                                    onChange={(supplierIds) => {
                                        setLocationFieldValue('supplierIds', supplierIds);
                                    }}
                                    placeholder={t('order.filterSupplierPlaceholder')}
                                />
                            </Box>
                        ) : null}

                        <FuelOrderOverviewSortSelect
                            onChange={(sortValue) => {
                                setLocationFieldValue('sortValue', sortValue);
                            }}
                            value={locationState.sortValue}
                        />
                    </Flex>
                </Flex>

                <FuelOrderOverviewTable />
            </PageGrid>
        </Page>
    );
};
