import { ReactNode } from 'react';

import { BunkerStopMessage, BunkerStopState } from '../../../types/BunkerStopsMessage';
import { DynamicFilterContainer, Filter } from '../../cdl/Filter/DynamicFilterContainer/DynamicFilterContainer';
import { FilterMultiSelectFull } from '../../cdl/Filter/FilterMultiSelectFull/FilterMultiSelectFull';
import { FilterMultiSelectShort } from '../../cdl/Filter/FilterMultiSelectShort/FilterMultiSelectShort';
import { translate } from '../../common/helpers/translate.helper';

interface BunkerPlanFilterContainerProps {
    allEntries: BunkerStopMessage[];
    children: (filteredEntries: BunkerStopMessage[]) => ReactNode;
}

export const BunkerPlanFilter = ({ children, allEntries }: BunkerPlanFilterContainerProps) => {
    const stateFilter: Filter<BunkerStopState, BunkerStopMessage> = {
        filterFn: (values, entry) => {
            return values.includes(entry.state);
        },
        component: (values, setValues) => {
            const availableStates = Array.from(new Set(allEntries.map((entry) => entry.state))).map((state) => ({
                label: translate(`bunkerPlan.filter.state.${state}`),
                value: state,
            }));

            return (
                <FilterMultiSelectShort
                    options={availableStates}
                    values={values}
                    onChange={setValues}
                    label={translate('bunkerPlan.filter.state.state')}
                    filterByLabel={translate('bunkerPlan.filter.state.filterByState')}
                />
            );
        },
    };

    const vesselFilter: Filter<string, BunkerStopMessage> = {
        filterFn: (values, entry) => {
            return values.includes(entry.vesselId);
        },
        component: (values, setValues) => {
            const availableOptions = Array.from(new Set(allEntries.map((entry) => entry.vesselId))).map((vesselId) => ({
                label: allEntries.find((entry) => entry.vesselId === vesselId)?.vessel.name ?? '',
                value: vesselId,
            }));

            return (
                <FilterMultiSelectFull
                    options={availableOptions}
                    values={values}
                    onChange={setValues}
                    label={translate('bunkerPlan.filter.vessel.vessel')}
                    filterByLabel={translate('bunkerPlan.filter.vessel.filterByVessel')}
                    filterPlaceholder={translate('bunkerPlan.filter.vessel.searchForVessel')}
                />
            );
        },
    };

    const portFilter: Filter<string, BunkerStopMessage> = {
        filterFn: (values, entry) => {
            return values.includes(entry.portId);
        },
        component: (values, setValues) => {
            const availableOptions = Array.from(new Set(allEntries.map((entry) => entry.portId))).map((portId) => ({
                label: allEntries.find((entry) => entry.portId === portId)?.port.name ?? '',
                value: portId,
            }));

            return (
                <FilterMultiSelectFull
                    options={availableOptions}
                    values={values}
                    onChange={setValues}
                    label={translate('bunkerPlan.filter.port.port')}
                    filterByLabel={translate('bunkerPlan.filter.port.filterByPort')}
                    filterPlaceholder={translate('bunkerPlan.filter.port.searchForPort')}
                />
            );
        },
    };

    return (
        <DynamicFilterContainer
            allEntries={allEntries}
            filters={{ vessel: vesselFilter, port: portFilter, state: stateFilter }}
        >
            {children}
        </DynamicFilterContainer>
    );
};
