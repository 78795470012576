import { ProductUnit } from '../../../../../../../types/ProductUnit';
import { Avatar } from '../../../../../../cdl/Avatar/Avatar';
import { Tag } from '../../../../../../cdl/Tag/Tag';
import { Text } from '../../../../../../cdl/Text/Text';
import { formatLubesPrice } from '../../../../../../common/helpers/formatLubesPrice.helper';
import { formatLubesTotalPrice } from '../../../../../../common/helpers/formatLubesTotal.helper';
import { formatNumber } from '../../../../../../common/helpers/formatNumber.helper';
import { translate } from '../../../../../../common/helpers/translate.helper';
import { Characters } from '../../../../../../common/helpers/unicode.helper';
import { useEnums } from '../../../../../../common/hooks/useEnums';
import { Box } from '../../../../../../common/ui/Box';
import { useOfferCardContext } from '../hooks/useOfferCardContext';

import { OfferLabelList } from './OfferLabelList';
import { OfferSupplierAndTotalsGrid } from './OfferSupplierAndTotalsGrid';

interface OfferSupplierAndTotalsProps {
    isBestLiterPrice?: boolean;
}

export const OfferSupplierAndTotals = ({ isBestLiterPrice }: OfferSupplierAndTotalsProps) => {
    const { getHumanReadableValue } = useEnums();

    const { offer } = useOfferCardContext();

    return (
        <OfferSupplierAndTotalsGrid>
            <Avatar name={offer.supplier.name} imageUrl={offer.supplier.supplierGroup.logo?.url} />
            <Box display="flex" flexDirection="column">
                <Text variant="subtitle1" style={{ whiteSpace: 'nowrap' }}>
                    {offer.supplier.name}
                </Text>
                <OfferLabelList />
            </Box>

            <Box display="flex" flexDirection="column" alignItems="end">
                <Box display="flex" justifyContent="end" alignItems={isBestLiterPrice ? 'center' : 'end'} columnGap={4}>
                    {offer.hasValidProducts() && isBestLiterPrice ? (
                        <Tag variant="positive">{translate('order.detail.offerCard.bestLiterPrice')}</Tag>
                    ) : null}

                    <Text variant="subtitle1">
                        {offer.hasValidProducts() ? formatLubesTotalPrice(offer.total) : '-'}
                    </Text>
                </Box>

                <Box display="flex" flexWrap="nowrap">
                    {offer.hasValidProducts() && offer.ppl ? (
                        <Text variant="extraSmall" color="foreground.muted" style={{ whiteSpace: 'nowrap' }}>
                            {translate('order.detail.perLiter', {
                                price: formatLubesPrice(offer.ppl),
                            })}
                            {Characters.NO_BREAK_SPACE}
                            {Characters.SOLIDUS}
                            {Characters.NO_BREAK_SPACE}
                        </Text>
                    ) : null}

                    <Text variant="extraSmall" color="foreground.muted">
                        {formatNumber({
                            number: offer.volume,
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}
                        {Characters.NO_BREAK_SPACE}
                        {getHumanReadableValue('productUnit', ProductUnit.L)}
                    </Text>
                </Box>
            </Box>
        </OfferSupplierAndTotalsGrid>
    );
};
