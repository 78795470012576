import PropTypes from 'prop-types';

import { Text } from '../../../cdl/Text/Text';
import { Tooltip } from '../../../cdl/Tooltip/Tooltip';
import { translate } from '../../../common/helpers/translate.helper';
import { useEnums } from '../../../common/hooks/useEnums';
import { Box } from '../../../common/ui/Box.tsx';
import { Flex } from '../../../common/ui/Flex.tsx';
import { Heading } from '../../../common/ui/Heading';
import { useLubesProductStringFormat } from '../../../order/detail/lubes/hooks/useLubesProductStringFormat';

import { Category } from './components/Category';
import { CheapestTag } from './components/CheapestTag';
import { DaysLeftSailing } from './components/DaysLeftSailing';
import { FormattedPrice } from './components/FormattedPrice';
import { PriceDifference } from './components/PriceDifference';

export const LubesPriceCompareCategory = ({ priceData, category, reachInformation }) => {
    const { getHumanReadableValue } = useEnums();
    const formatProductString = useLubesProductStringFormat();

    const findSpecificPriceData = (key) => {
        return priceData.find((priceData) => priceData[key] === true);
    };

    const defaultSupplier = findSpecificPriceData('defaultSupplier');
    const bestAlternative = findSpecificPriceData('bestAlternative');
    const isCheapestDefault = !!defaultSupplier?.bestPriceCategoryDefault;

    return (
        <Category padding={6} isCheapest={isCheapestDefault}>
            <Box marginBottom={5} display="flex" justifyContent="space-between" alignItems="baseline">
                <Heading level="h5">{getHumanReadableValue('vesselTankCategory', category)}</Heading>
                {isCheapestDefault ? (
                    <CheapestTag>{translate('schedule.priceCompare.cheapestDefault')}</CheapestTag>
                ) : null}
            </Box>

            <Box marginBottom={5}>
                {reachInformation !== undefined ? <DaysLeftSailing reachInformation={reachInformation} /> : <>&nbsp;</>}
            </Box>

            <Flex flexDirection="row">
                <Box width="60%">
                    <Text variant="extraSmall" color="foreground.subtle" as="p">
                        {translate('schedule.priceCompare.defaultSupplier')}
                    </Text>
                    <Flex alignItems="center" flexWrap="nowrap">
                        {defaultSupplier ? (
                            <Tooltip label={formatProductString(defaultSupplier.product)}>
                                <span>
                                    <FormattedPrice
                                        price={defaultSupplier?.price}
                                        positive={defaultSupplier?.bestPriceCategory}
                                    />
                                </span>
                            </Tooltip>
                        ) : (
                            <FormattedPrice
                                price={defaultSupplier?.price}
                                positive={defaultSupplier?.bestPriceCategory}
                            />
                        )}
                        {defaultSupplier?.bestAlternativeDifference ? (
                            <Box marginLeft={4}>
                                <PriceDifference priceDifference={defaultSupplier.bestAlternativeDifference} />
                            </Box>
                        ) : null}
                    </Flex>
                </Box>
                <Box>
                    <Text variant="extraSmall" color="foreground.subtle" as="p">
                        {translate('schedule.priceCompare.bestAlternative')}
                    </Text>
                    {bestAlternative ? (
                        <Tooltip label={formatProductString(bestAlternative.product)}>
                            <span>
                                <FormattedPrice
                                    price={bestAlternative?.price}
                                    positive={bestAlternative?.bestPriceCategory}
                                />
                            </span>
                        </Tooltip>
                    ) : (
                        <FormattedPrice price={bestAlternative?.price} positive={bestAlternative?.bestPriceCategory} />
                    )}
                </Box>
            </Flex>
        </Category>
    );
};

LubesPriceCompareCategory.propTypes = {
    priceData: PropTypes.array.isRequired,
    category: PropTypes.string.isRequired,
    reachInformation: PropTypes.object,
};
