import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export interface CreateQuoteRequestMessage {
    offerId: string;
    supplierId: string;
    customerId: string;
    vesselId: string;
}

export interface CreateOrderRequestMessage {
    offerId: string;
    supplierId: string;
    customerId: string;
    vesselId: string;
}

export const createQuote = async (requestBody: CreateQuoteRequestMessage): Promise<void> => {
    return await authorizedAxiosInstance.post(`/one-port/triggers/create-quote`, requestBody);
};

export const createOrder = async (requestBody: CreateOrderRequestMessage): Promise<void> => {
    return await authorizedAxiosInstance.post(`/one-port/triggers/create-order`, requestBody);
};

export const rebuildMapping = async (offerId: string, confirmedOrderNumber: string): Promise<void> => {
    const params = new URLSearchParams();
    params.append('offerId', offerId);
    params.append('confirmedOrderNumber', confirmedOrderNumber);

    return await authorizedAxiosInstance.post(`/one-port/triggers/rebuild-transaction`, params);
};
