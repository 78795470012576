import { useField } from 'formik';
import PropTypes from 'prop-types';

import { HumanReadableEnumValue } from '../../enums/HumanReadableEnumValue';

export const FormikHumanReadableEnumValue = (props) => {
    const [field] = useField(props.name);

    if (!field.value) {
        return props.fallback ?? '-';
    }

    return <HumanReadableEnumValue enumType={props.enumType} value={field.value} />;
};

FormikHumanReadableEnumValue.propTypes = {
    name: PropTypes.string.isRequired,
    enumType: PropTypes.string.isRequired,
    fallback: PropTypes.string,
};
