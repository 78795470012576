import { FormikCheckbox } from '../../../../../cdl/Checkbox/FormikCheckbox';
import { Collapsable } from '../../../../../cdl/Collapsable/Collapsable';
import { FormikInput } from '../../../../../cdl/Input/FormikInput';
import { Text } from '../../../../../cdl/Text/Text';
import { translate } from '../../../../../common/helpers/translate.helper';
import { Box } from '../../../../../common/ui/Box';

import { FormikAdditionalApproversForm } from './FormikAdditionalApproversForm';
import { FormikTopLevelAndApproverAccordionDescription } from './FormikTopLevelAndApproverAccordionDescription';

interface FormikTopLevelApproverAndApproversAccordionProps {
    isOpen: boolean;
    onHeaderClick: () => void;
}

export const FormikTopLevelApproverAndApproversAccordion = ({
    isOpen,
    onHeaderClick,
}: FormikTopLevelApproverAndApproversAccordionProps) => {
    return (
        <Collapsable
            isOpen={isOpen}
            header={
                <Collapsable.Header
                    isOpen={isOpen}
                    onClick={onHeaderClick}
                    expandActionText={(isOpen: boolean) =>
                        isOpen ? translate('multipleApproverSettings.hide') : translate('multipleApproverSettings.show')
                    }
                    title={
                        <Collapsable.Title>
                            {translate('multipleApproverSettings.approversAndApprovalLimits')}
                        </Collapsable.Title>
                    }
                    description={<FormikTopLevelAndApproverAccordionDescription />}
                />
            }
        >
            {() => (
                <Box
                    display="flex"
                    flexDirection="column"
                    style={{ gap: '16px' }}
                    overflow="auto"
                    maxHeight="calc(100vh - 360px)"
                    padding={5}
                >
                    <Text variant="body">
                        {translate('multipleApproverSettings.multipleApproverSettingsExplanation')}
                    </Text>

                    <FormikInput
                        name="defaultControllerEmailAddress"
                        autoComplete="off"
                        label={translate('multipleApproverSettings.topLevelApproverEmailAddressLabel')}
                    />

                    <Box marginTop={5}>
                        <FormikCheckbox
                            label={translate('multipleApproverSettings.setLimitsAndApprovers')}
                            name="enableAdditionalApprovers"
                        />
                    </Box>

                    <FormikAdditionalApproversForm />
                </Box>
            )}
        </Collapsable>
    );
};
