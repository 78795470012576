import { useQuery } from '@tanstack/react-query';

import { getOrder } from '../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { getFormStateFromOrder } from '../getFormStateFromOrder';

export const useOrderState = (orderId) => {
    return useQuery({
        queryKey: queryKeys.order(orderId),
        queryFn: () => getOrder(orderId),
        enabled: !!orderId,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        select: getFormStateFromOrder,
    });
};
