import { ChangeEvent, useRef } from 'react';

import { AssignmentGatewayMessage } from '../../../../types/AssignmentGatewayMessage';
import { Button } from '../../../cdl/Button/Button';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../../common/helpers/translate.helper';
import { usePriceListUpload } from '../../common/hooks/usePriceListUpload';

interface UploadPriceListButtonProps {
    assignment: AssignmentGatewayMessage;
}

export const UploadPriceListButton = (props: UploadPriceListButtonProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { addLoadingToast } = useToasts();
    const priceListUploadMutation = usePriceListUpload(
        props.assignment.supplier.supplierGroupId,
        props.assignment.supplier.id,
        props.assignment.customerId
    );

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };

    const uploadFile = (event: ChangeEvent<HTMLInputElement>) => {
        try {
            const file = event.target.files?.[0];
            if (file) {
                addLoadingToast(
                    () => priceListUploadMutation.mutateAsync(file),
                    translate('assignments.toasts.uploadLoading'),
                    translate('assignments.toasts.uploadSuccess'),
                    translate('assignments.toasts.uploadError')
                );
            }
        } finally {
            event.target.value = '';
        }
    };

    return (
        <>
            <Button size="medium" emphasis="low" onClick={handleUploadClick}>
                {translate('assignments.btnupload')}
            </Button>
            <input ref={fileInputRef} type="file" onInput={uploadFile} style={{ display: 'none' }} />
        </>
    );
};
