import styled from 'styled-components';

import { get } from '../../../../common/ui/get';

export const OfferDetailLayoutGrid = styled.div`
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: minmax(0, 75fr) minmax(0, 25fr);
    grid-gap: ${get('space.9')}px;
`;
