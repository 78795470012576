import { CounterOffer } from '../../../../types/CounterOffer';
import { StatusBar } from '../../../cdl/StatusBar/StatusBar';
import { translate } from '../../../common/helpers/translate.helper';
import { CircleValidity } from '../../../common/Validity/CircleValidity';

interface FuelAdminCounterOfferExpiredStatusBarProps {
    latestCounterOffer: CounterOffer;
}

export const FuelAdminCounterOfferExpiredStatusBar = (props: FuelAdminCounterOfferExpiredStatusBarProps) => {
    const latestCounterOffer = props.latestCounterOffer;

    return (
        <StatusBar
            variant="neutral"
            headline={translate('statusbar.admin.fuel.counterOfferReceived.headline')}
            subHeadline={translate('statusbar.admin.fuel.counterOfferReceived.subHeadline')}
            trailingVisual={
                <>
                    {!!latestCounterOffer.validUntil && !!latestCounterOffer.validityTimeMinutes ? (
                        <CircleValidity
                            validUntil={latestCounterOffer.validUntil}
                            validityTime={latestCounterOffer.validityTimeMinutes}
                            background="transparent"
                        />
                    ) : null}
                </>
            }
        />
    );
};
