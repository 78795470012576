import { MoneyMessage } from '../../../types/MoneyMessage';

export const formatFuelMoney = (money: MoneyMessage, includeCurrencySymbol: boolean = true): string => {
    return new Intl.NumberFormat('en-US', {
        style: includeCurrencySymbol ? 'currency' : 'decimal',
        currency: money.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(money.value);
};
