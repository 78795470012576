import { useField } from 'formik';
import { ChangeEvent } from 'react';

import { Checkbox, CheckboxProps } from './Checkbox';

interface FormikCheckboxProps extends Omit<CheckboxProps, 'onChange'> {
    name: string;
    onChange?: (checked: boolean) => void;
}

export const FormikCheckbox = (props: FormikCheckboxProps) => {
    const [field] = useField(props.name);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        field.onChange(event);
        props.onChange?.(event.target.checked);
    };

    return <Checkbox {...field} {...props} onChange={handleChange} />;
};
