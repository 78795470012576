import { formatNumber } from '../helpers/formatNumber.helper';

import { useEnums } from './useEnums';

interface FormatVolumeProps {
    volume: number;
    unit?: string;
}

export const useFuelVolumeFormat = (showUnit = true) => {
    const { getHumanReadableValue } = useEnums();

    return ({ volume, unit = 'MT' }: FormatVolumeProps) => {
        const formattedVolume = formatNumber({
            number: volume,
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        });

        if (!showUnit) {
            return formattedVolume;
        }

        const translatedUnit = getHumanReadableValue('productUnit', unit);

        return `${formattedVolume} ${translatedUnit}`;
    };
};
