import { ReactNode } from 'react';

import { InfoPopover } from '../../common/InfoPopover/InfoPopover';
import { Box } from '../../common/ui/Box';

interface DemandTableInfoPopoverProps {
    children: ReactNode;
    label: string;
}

export const DemandTableInfoPopover = ({ children, label }: DemandTableInfoPopoverProps) => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="end"
            alignItems="center"
            color="foreground.muted"
            style={{ textAlign: 'left' }}
            gap={2}
        >
            <InfoPopover width="250px">{children}</InfoPopover>
            {label}
        </Box>
    );
};
