import { Form, useFormikContext } from 'formik';

import { Button } from '../../../cdl/Button/Button';
import { FormikInput } from '../../../cdl/Input/FormikInput';
import { ButtonGroup } from '../../../common/buttons/ButtonGroup';
import { TextButton } from '../../../common/buttons/TextButton';
import { FormikCustomerCompanySelect } from '../../../common/form-elements/formik/FormikCustomerCompanySelect';
import { FormikDebug } from '../../../common/form-elements/formik/FormikDebug';
import { FormikEnumOptionSelect } from '../../../common/form-elements/formik/FormikEnumOptionSelect';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex';

interface InviteSupplierFormProps {
    onCancel: () => void;
}

export interface InviteSupplierFormState {
    customerId: string;
    inviteCompany: string;
    inviteMail: string;
}

export const InviteSupplierForm = (props: InviteSupplierFormProps) => {
    const formikContext = useFormikContext<InviteSupplierFormState>();

    return (
        <Form>
            <Flex gap={5} flexDirection="column">
                <FormikCustomerCompanySelect
                    name="customerId"
                    label={translate('assignments.suppliers.customerLabel')}
                />

                <FormikEnumOptionSelect
                    name="inviteCompany"
                    enumType="assignmentInviteCompany"
                    label={translate('assignments.suppliers.supplierLabel')}
                />

                <FormikInput name="inviteMail" label={translate('assignments.suppliers.emailAddressLabel')} />

                <Box display="flex" justifyContent="end" marginTop={6}>
                    <ButtonGroup>
                        <TextButton type="button" disabled={formikContext.isSubmitting} onClick={props.onCancel}>
                            {translate('btn.cancel')}
                        </TextButton>
                        <Button type="submit" emphasis="high" isLoading={formikContext.isSubmitting}>
                            {translate('btn.send')}
                        </Button>
                    </ButtonGroup>
                </Box>
            </Flex>

            <FormikDebug />
        </Form>
    );
};
