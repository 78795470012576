import { CategoryState, ReachInformationCategory } from '../../../types/ReachInformationMessage';
import { formatNumber } from '../../common/helpers/formatNumber.helper';
import { translate } from '../../common/helpers/translate.helper';

import { Warning } from './Warning';

interface ReachInformationWarningProps {
    reachInformationCategory: ReachInformationCategory;
}

export const ReachInformationWarning = (props: ReachInformationWarningProps) => {
    const formattedAverageDailyConsumption = formatNumber({
        number: props.reachInformationCategory.averageDailyConsumption,
        maximumFractionDigits: 0,
    });

    if (props.reachInformationCategory.categoryState === CategoryState.SAFETY_RESERVE) {
        return (
            <Warning
                tooltip={translate('fuelEnquiry.second.productTable.onSafetyReserveHint', {
                    averageConsumption: formattedAverageDailyConsumption,
                })}
            />
        );
    }

    return (
        <Warning
            tooltip={translate('fuelEnquiry.second.productTable.outOfReachHint', {
                averageConsumption: formattedAverageDailyConsumption,
            })}
        />
    );
};
