import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { DrawerFormLayout } from '../../cdl/Drawer/DrawerFormLayout';
import { Text } from '../../cdl/Text/Text';
import { useToasts } from '../../cdl/Toast/useToasts';
import { createVessel as createVesselQuery } from '../../common/api/clients/vessel.api';
import { FormCheckbox } from '../../common/form-elements/Checkbox/Checkbox';
import { FormContextCompanySelect } from '../../common/form-elements/CompanySelect/ContextCustomerCompanySelect';
import { Form } from '../../common/form-elements/Form/Form';
import { FormMultipleEntriesInput } from '../../common/form-elements/FormMultipleEntriesInput/FormMultipleEntriesInput';
import { FormInputPlaces } from '../../common/form-elements/InputPlaces/FormInputPlaces';
import { Label } from '../../common/form-elements/Label/Label';
import { FormTextInput } from '../../common/form-elements/TextInput/TextInput';
import { translate } from '../../common/helpers/translate.helper';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { Box } from '../../common/ui/Box';

import { DynamicDefaultSupplierSelect } from './DynamicDefaultSupplierSelect';

const t = (key) => translate(`createVesselForm.${key}`);

const isNotEmpty = (value) => {
    if (!value) {
        return false;
    }

    if (typeof value === 'string' && value === '') {
        return false;
    }

    return true;
};

const propTypes = {
    onSave: PropTypes.func,
};

/**
 * @typedef {{
 *  (response: {status: "success"|"error", data: VesselMessage}) => void
 * }} CreateVesselOnSaveHandler
 * @type {React.FC<{
 *  onSave: CreateVesselOnSaveHandler
 * *}>}
 */
export const CreateVesselForm = ({ onSave }) => {
    const role = useRole();
    const { context, isLubes, isFuel } = useProductContext();
    const companyIdsForType = role.getCompaniesWithType(context).map((it) => it.id);

    const [valid, setValid] = useState(false);
    const [formData] = useState(
        // if only one company is possible we don't show the company selection
        // and set the customerId (=company) automatically.
        role.isOneCompanyUser(context)
            ? {
                  customerId: companyIdsForType[0],
              }
            : {}
    );
    const { addToast, addErrorToast } = useToasts();

    const { mutate: createVessel } = useMutation({
        mutationFn: createVesselQuery,
        onSuccess: (data) => {
            addToast(t('toast.success'));

            onSave({ status: 'success', data });
        },
        onError: (data) => {
            addErrorToast(t('toast.error'));

            onSave({ status: 'error', data });
        },
    });

    const onSubmit = ({ data, event }) => {
        event.preventDefault();
        createVessel({
            vessel: {
                ...data,
                directOrderAllowed: true, // should really be set in the backend at some point
                active: typeof data.active !== 'undefined' ? data.active : isFuel || role.usesCloselinkLite(),
            },
        });
    };

    const validate = (data) => {
        const areRequiredFieldsValid = ['name', 'imo'].every((name) => isNotEmpty(data[name]));

        const areAddressFieldsValid = ['street', 'streetNumber', 'zipcode', 'city', 'country'].every((name) =>
            isNotEmpty(data.invoiceAddress?.[name])
        );

        if (!role.isOneCompanyUser(context)) {
            setValid(areRequiredFieldsValid && areAddressFieldsValid && !!data.customerId);
        } else {
            setValid(areRequiredFieldsValid && areAddressFieldsValid);
        }
    };

    return (
        <Form height="100%" data={formData} onSubmit={onSubmit} onChange={(args) => validate(args.data)}>
            <DrawerFormLayout title={t('title')} isSubmitButtonDisabled={!valid}>
                {!role.isOneCompanyUser(context) ? (
                    <Label label={translate('vessel.company')} required>
                        <FormContextCompanySelect
                            dataPath="customerId"
                            placeholder={translate('vessel.searchCompanyPlaceholder')}
                        />
                    </Label>
                ) : null}

                <FormTextInput
                    label={translate('order.detail.coreData.vessel')}
                    dataPath="name"
                    required
                    placeholder={translate('placeholder.vessel.name')}
                />
                <FormTextInput
                    label={translate('order.detail.coreData.vesselPopup.imo')}
                    dataPath="imo"
                    required
                    placeholder={translate('placeholder.vessel.imo')}
                />

                {isFuel ? (
                    <>
                        <FormMultipleEntriesInput
                            dataPath="types"
                            inputLabel={translate('vessel.typesInput.label')}
                            inputPlaceholder={translate('vessel.typesInput.placeholder')}
                            pattern=".*"
                        />

                        <FormMultipleEntriesInput
                            dataPath="services"
                            inputLabel={translate('vessel.servicesInput.label')}
                            inputPlaceholder={translate('vessel.servicesInput.placeholder')}
                            pattern=".*"
                        />
                    </>
                ) : null}

                <FormTextInput
                    label={translate('global.contact.email')}
                    dataPath="email"
                    placeholder={translate('placeholder.email')}
                />

                {isLubes ? <DynamicDefaultSupplierSelect /> : null}

                <Text variant="subtitle1" as="h2">
                    {translate('order.detail.coreData.vesselPopup.invoiceAddress')}
                </Text>
                <FormTextInput
                    label={translate('global.address.company')}
                    dataPath="invoiceAddress.companyName"
                    required
                    placeholder={translate('placeholder.companyName')}
                />
                <FormInputPlaces
                    label={translate('global.address.street')}
                    dataPaths={{
                        street: 'invoiceAddress.street',
                        streetNumber: 'invoiceAddress.streetNumber',
                        zipcode: 'invoiceAddress.zipcode',
                        city: 'invoiceAddress.city',
                        country: 'invoiceAddress.country',
                    }}
                    required
                    input={{
                        required: true,
                        placeholder: translate('global.address.placeholder.street'),
                    }}
                />
                <FormTextInput
                    label={translate('global.address.streetnumber')}
                    dataPath="invoiceAddress.streetNumber"
                    required
                    placeholder={translate('global.address.placeholder.streetnumber')}
                />
                <FormTextInput
                    label={translate('global.address.zipcode')}
                    dataPath="invoiceAddress.zipcode"
                    required
                    placeholder={translate('global.address.placeholder.zipcode')}
                />
                <FormTextInput
                    label={translate('global.address.city')}
                    dataPath="invoiceAddress.city"
                    required
                    placeholder={translate('global.address.placeholder.city')}
                />
                <FormTextInput
                    label={translate('global.address.country')}
                    dataPath="invoiceAddress.country"
                    required
                    placeholder={translate('global.address.placeholder.country')}
                />
                <FormTextInput
                    label={translate('company.invoiceVAT')}
                    dataPath="invoiceVAT"
                    placeholder={translate('global.contact.placeholder.vatnumber')}
                />

                {role.isAdmin() ? (
                    <Box display="grid" rowGap={4} marginTop={4}>
                        <Text variant="subtitle1" as="h2">
                            {translate('customers.details.adminSettings')}
                        </Text>
                        <FormCheckbox label={translate('global.active')} dataPath="active" />
                        <FormCheckbox label={translate('vessel.paying')} dataPath="paying" />
                        <FormCheckbox label={translate('vessel.directOrderAllowed')} dataPath="directOrderAllowed" />
                    </Box>
                ) : null}
            </DrawerFormLayout>
        </Form>
    );
};

CreateVesselForm.propTypes = propTypes;
