import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { BunkerCaseGeometryResponse } from '../../../../types/BunkerCaseGeometryResponse';
import { PortMessage } from '../../../../types/PortMessage';
import { formatPort } from '../../../common/helpers/formatPort.helper';
import { CloselinkMap, Polyline, Position } from '../../../common/Map/CloselinkMap';
import { Box } from '../../../common/ui/Box';
import { theme } from '../../../common/ui/theme';
import { GlobalPricesPortMarker } from '../../../globalPrices/components/GlobalPricesPortMarker';
import { useBunkerMetricBunkerCaseGeometry } from '../hooks/useBunkerMetricBunkerCaseGeometry';

const createPolylines = (bunkerCaseGeometryResponse: BunkerCaseGeometryResponse): Polyline[] => {
    return bunkerCaseGeometryResponse.segmentGeometries.map((segment) => {
        return {
            strokeColor: theme.colors.foreground.default,
            strokeWeight: 4,
            path: segment.polylineCoordinates.map((coordinates) => {
                return {
                    lat: coordinates.latitude,
                    lng: coordinates.longitude,
                };
            }),
        };
    });
};

const createPlaces = (ports: PortMessage[]): Position[] => {
    return ports.map((port) => {
        return {
            lat: port.latitude,
            lng: port.longitude,
        };
    });
};

const createUniquePorts = (bunkerCaseGeometryResponse: BunkerCaseGeometryResponse): PortMessage[] => {
    const ports = [
        bunkerCaseGeometryResponse.segmentGeometries[0].fromPort,
        ...bunkerCaseGeometryResponse.segmentGeometries.map((segmentGeometry) => segmentGeometry.toPort),
    ];

    return [...new Map(ports.map((port) => [port.locCode, port])).values()];
};

interface BunkerSolutionMapProps {
    vesselId: string;
    caseNumber: string;
    solutionNumber: string;
}

export const BunkerSolutionMap = ({ vesselId, caseNumber, solutionNumber }: BunkerSolutionMapProps) => {
    const [showMarker, setShowMarker] = useState(true);
    const bunkerMetricBunkerCaseGeometryQuery = useBunkerMetricBunkerCaseGeometry({
        vesselId: vesselId,
        caseNumber: caseNumber,
        solutionNumber: solutionNumber,
    });

    if (bunkerMetricBunkerCaseGeometryQuery.isPending) {
        return (
            <>
                <Skeleton width="calc(100vw - 290px)" height="600px" />
            </>
        );
    }

    if (bunkerMetricBunkerCaseGeometryQuery.isError) {
        return null;
    }

    const bunkerCaseGeometryResponse = bunkerMetricBunkerCaseGeometryQuery.data;

    const ports = createUniquePorts(bunkerCaseGeometryResponse);

    return (
        <Box width="calc(100vw - 290px)" height="600px">
            <CloselinkMap
                zoomControl={true}
                places={createPlaces(ports)}
                polylines={createPolylines(bunkerCaseGeometryResponse)}
                onDrag={() => setShowMarker(false)}
                onDragEnd={() => setShowMarker(true)}
                fitToBounds
            >
                {ports.map((port) => {
                    return (
                        <GlobalPricesPortMarker
                            style={{
                                transform: `scale(${showMarker ? 1 : 0})`,
                                opacity: showMarker ? 1 : 0,
                                transition: 'transform 0.1s, opacity 0.3s',
                            }}
                            key={port.locCode}
                            color={theme.colors.foreground.default}
                            lat={port.latitude}
                            lng={port.longitude}
                        >
                            {formatPort({ port: port })}
                        </GlobalPricesPortMarker>
                    );
                })}
            </CloselinkMap>
        </Box>
    );
};
