import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { UserInviteMessage } from '../../../../types/UserInviteMessage';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { createInvite } from '../../../common/api/clients/user.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { translate } from '../../../common/helpers/translate.helper';

export interface UserServerError {
    errorCode: string;
    email: string;
}

export const useUserInvite = () => {
    const queryClient = useQueryClient();
    const { addErrorToast } = useToasts();

    return useMutation({
        mutationFn: (userInviteMessage: UserInviteMessage) => {
            return createInvite(userInviteMessage);
        },

        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.users() });
        },

        onError: (error: AxiosError) => {
            if (error.response?.status !== 400) {
                addErrorToast(translate('user.invitationToasts.error'));
            }
        },
    });
};
