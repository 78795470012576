CustomerResource.$inject = ['$resource', 'AppSettings'];

function CustomerResource($resource, AppSettings) {
    return $resource(
        AppSettings.routes.api + '/v1/customer/:id',
        {},
        {
            update: {
                method: 'PUT',
            },
            list: {
                method: 'GET',
                isArray: true,
                url: AppSettings.routes.api + '/v1/customer/list',
            },
        }
    );
}

export default {
    name: 'CustomerResource',
    fn: CustomerResource,
};
