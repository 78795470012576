import { UserGatewayMessage } from '../../../../types/UserGatewayMessage';
import { getManagementUsers } from '../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { usePaginationQuery } from '../../../common/hooks/usePaginationQuery';

interface ManagementUsersParams {
    page: number;
    searchQuery?: string;
    companyIds?: string[];
    groupIds?: string[];
}

export const useManagementUsersPagination = (params: ManagementUsersParams) => {
    return usePaginationQuery<UserGatewayMessage>({
        queryKey: queryKeys.users(params),
        queryFn: () => getManagementUsers(params),
        options: { keepPreviousData: true },
        prefetchQueryKey: queryKeys.users({
            ...params,
            page: params.page + 1,
        }),
        prefetchQueryFn: () =>
            getManagementUsers({
                ...params,
                page: params.page + 1,
            }),
    });
};
