import styled from 'styled-components';

import { Text } from '../../../../cdl/Text/Text';
import { Box } from '../../../../common/ui/Box';

const EllipsisText = styled(Text)`
    white-space: nowrap;
    flex: 0 1 auto;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
    margin-right: 4px;
`;

interface PortAndLocodeProps {
    name: string;
    locode?: string;
}

export const PortAndLocode = ({ name, locode }: PortAndLocodeProps) => {
    return (
        <Box display="flex" minWidth={0}>
            <EllipsisText variant="title" title={name}>
                {name}
            </EllipsisText>
            {locode ? (
                <Text variant="title" color="foreground.subtle">
                    ({locode})
                </Text>
            ) : null}
        </Box>
    );
};
