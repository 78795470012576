import {
    ContractMessage,
    ContractProductMessage,
    ProductGroupMessage,
} from '../../../../../../../../types/ContractMessage';
import { formatDate } from '../../../../../../../common/helpers/formatDate.helper';
import { formatFuelMoney } from '../../../../../../../common/helpers/formatFuelMoney.helper';
import { translate } from '../../../../../../../common/helpers/translate.helper';
import { useEnums } from '../../../../../../../common/hooks/useEnums';
import { useFuelVolumeFormat } from '../../../../../../../common/hooks/useFuelVolumeFormat';
import { usePaymentTermsFormat } from '../../../../../../../common/hooks/usePaymentTermsFormat';

const makePricingCodeDifference = (contractProduct: ContractProductMessage) => {
    if (contractProduct.priceDifference.value >= 0) {
        return `+${formatFuelMoney({
            value: contractProduct.priceDifference.value,
            currency: contractProduct.priceDifference.currency,
        })}`;
    }

    return formatFuelMoney({
        value: contractProduct.priceDifference.value,
        currency: contractProduct.priceDifference.currency,
    });
};

const makeContractPeriod = (contract: ContractMessage) => {
    const dates = [
        formatDate({
            date: contract.startDate,
        }),
        formatDate({ date: contract.endDate }),
    ];
    return dates.join(' - ');
};

export const useMakeContractPopupInformation = () => {
    const formatFuelVolume = useFuelVolumeFormat();
    const formatPaymentTerms = usePaymentTermsFormat();
    const { getHumanReadableValue } = useEnums();

    return ({
        contract,
        contractProductGroup,
        contractProduct,
    }: {
        contract: ContractMessage;
        contractProductGroup: ProductGroupMessage;
        contractProduct: ContractProductMessage;
    }) => {
        return [
            {
                label: translate('contractPopup.contractVolume'),
                value: formatFuelVolume({
                    volume: contractProductGroup.contractVolume,
                }),
            },
            {
                label: translate('contractPopup.minVolume'),
                value: contractProductGroup.minContractVolume
                    ? formatFuelVolume({
                          volume: contractProductGroup.minContractVolume,
                      })
                    : '-',
            },
            {
                label: translate('contractPopup.maxVolume'),
                value: contractProductGroup.maxContractVolume
                    ? formatFuelVolume({
                          volume: contractProductGroup.maxContractVolume,
                      })
                    : '-',
            },
            {
                label: translate('contractPopup.minStemSize'),
                value: formatFuelVolume({
                    volume: contractProductGroup.minStemSize,
                }),
            },
            {
                label: translate('contractPopup.pricing'),
                value: `${contractProduct.pricingCode.code} ${makePricingCodeDifference(contractProduct)}`,
            },
            {
                label: translate('contractPopup.contractPeriod'),
                value: makeContractPeriod(contract),
            },
            {
                label: translate('contractPopup.contractBasis'),
                value: getHumanReadableValue('contractBasis', contract.contractBasis),
            },
            {
                label: translate('contractPopup.pricingDate'),
                value: getHumanReadableValue('pricingDate', contract.pricingDate),
            },
            {
                label: translate('contractPopup.paymentTerms'),
                value: contract.paymentTermReference
                    ? formatPaymentTerms(contract.paymentTermReference, contract.paymentTermReferenceDays)
                    : '-',
            },
        ];
    };
};
