import styled from 'styled-components';

import { Box } from '../ui/Box';

export const SliderWrapper = styled(Box).attrs({})`
    position: relative;
    overflow: scroll;
    flex-grow: 1;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
`;
