import React from 'react';

import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { SupplierGroupsOverviewPage } from './SupplierGroupsOverviewPage';

const SupplierGroupsOverviewPageReactComponent = (props: any) => {
    return (
        <AppProviders>
            <SupplierGroupsOverviewPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(
    SupplierGroupsOverviewPageReactComponent,
    'reactSupplierGroupsOverviewPage'
);
