import { CustomerSpecificStatisticsMessage } from '../../../types/StatisticsMessage';

import { VesselsTabFilter } from './VesselsTabFilter';
import { VesselsTabStatistics } from './VesselsTabStatistics';

interface VesselsTabContentProps {
    vesselStatistics: CustomerSpecificStatisticsMessage['vessel'];
}

export const VesselsTabContent = ({ vesselStatistics }: VesselsTabContentProps) => {
    return (
        <VesselsTabFilter allEntries={vesselStatistics}>
            {(filteredEntries) => <VesselsTabStatistics vesselStatistics={filteredEntries} />}
        </VesselsTabFilter>
    );
};
