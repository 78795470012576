import { useToasts } from '../../../../cdl/Toast/useToasts';
import { translate } from '../../../../common/helpers/translate.helper';
import { useProductContext } from '../../../../common/hooks/useProductContext';
import { useRole } from '../../../../common/hooks/useRole';
import { useCustomerCreate } from '../hooks/useCustomerCreate';

import { CreateCustomerCompanyForm, CreateCustomerCompanyFormProps } from './CreateCustomerCompanyForm';

interface CreateCustomerCompanyProps {
    groupId: string;
    onSuccess: () => void;
    isTestGroup: boolean;
}

export const CreateCustomerCompany = ({ groupId, onSuccess, isTestGroup }: CreateCustomerCompanyProps) => {
    const { possibleContexts } = useProductContext();
    const { addErrorToast } = useToasts();
    const role = useRole();

    const customerCreateMutation = useCustomerCreate();

    const handleSubmit: CreateCustomerCompanyFormProps['onSubmit'] = (values) => {
        const customerCompanyMessage = {
            customerGroupId: groupId,
            type: values.type || possibleContexts[0],
            name: values.name,
            email: values.email,
            phone: values.phone || undefined,
            fax: values.fax || undefined,
            invoiceMail: values.invoiceMail || undefined,
            address: {
                city: values.address.city || undefined,
                companyName: values.address.companyName || undefined,
                country: values.address.country || undefined,
                street: values.address.street || undefined,
                streetNumber: values.address.streetNumber || undefined,
                zipcode: values.address.zipcode || undefined,
            },
            spotAllowed: role.isAdmin() ? values.spotAllowed : undefined,
            testCompany: role.isAdmin() ? values.testCompany : isTestGroup,
            trial: role.isAdmin() ? values.trial : undefined,
        };

        customerCreateMutation.mutate(customerCompanyMessage, {
            onSuccess: () => {
                onSuccess();
            },
            onError: () => {
                addErrorToast(translate('customer.createCustomerCompanyErrorToast'));
            },
        });
    };
    return <CreateCustomerCompanyForm onSubmit={handleSubmit} />;
};
