import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';

import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { getOffers } from '../../../common/api/clients/gateway.api';
import { DataTable } from '../../../common/DataTable/DataTable';
import { Error } from '../../../common/Error/Error';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatPort } from '../../../common/helpers/formatPort.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { translateOfferState } from '../../../common/helpers/translateOfferState.helper';
import { useNavigation } from '../../../common/hooks/useNavigation';
import { useRole } from '../../../common/hooks/useRole';
import { useUpdateEffect } from '../../../common/hooks/useUpdateEffect';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Section } from '../../../common/Section/Section';
import { RecentActivitiesEmptyState } from '../../common/components/RecentActivitiesEmptyState';

export const LubesSupplierOfferTable = () => {
    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const tableRef = useRef<HTMLDivElement>(null);
    const role = useRole();
    const { navigate } = useNavigation();

    const suppliersOfferQuery = useQuery({
        queryKey: ['supplier', 'offers', page],
        queryFn: () =>
            getOffers({
                page,
                supplierRead: false,
                types: ['LUBES'],
            }),
        placeholderData: keepPreviousData,
    });

    // Prevent scrolling down on initial load
    useUpdateEffect(() => {
        if (tableRef.current) {
            window.scrollTo(0, tableRef.current.offsetTop);
        }
    }, [page]);

    const hasMoreData = suppliersOfferQuery.data
        ? suppliersOfferQuery.data.total >
          (suppliersOfferQuery.data.pageable.page + 1) * suppliersOfferQuery.data.pageable.size
        : false;

    useEffect(() => {
        if (hasMoreData) {
            queryClient.prefetchQuery({
                queryKey: ['supplier', 'offers', page + 1],
                queryFn: () =>
                    getOffers({
                        page: page + 1,
                        supplierRead: false,
                        types: ['LUBES'],
                    }),
            });
        }
    }, [suppliersOfferQuery.isPending, page, queryClient, hasMoreData]);

    if (suppliersOfferQuery.isPending) {
        return (
            <Section title={translate('dashboard.recentactivities')}>
                <LoadingIndicator />
            </Section>
        );
    }

    if (suppliersOfferQuery.isError) {
        return (
            <Section title={translate('dashboard.recentactivities')}>
                <Error />
            </Section>
        );
    }

    if (!suppliersOfferQuery.data?.content.length) {
        return (
            <Section title={translate('dashboard.recentactivities')}>
                <RecentActivitiesEmptyState />
            </Section>
        );
    }

    const openOffer = (offer: any) => {
        navigate('base.offerlist-offer', {
            id: offer.id,
        });
    };

    const pageTotal = Math.ceil(suppliersOfferQuery.data.total / suppliersOfferQuery.data.pageable.size);

    const columns = [
        {
            width: '10%',
            label: translate('order.enquiryorder'),
            value: (offer: any) => offer.offerNumber,
        },
        {
            width: '10%',
            label: translate('offer.company'),
            condition: !role.isOneCompanyUser(),
            value: (offer: any) => offer.supplier.name,
        },
        {
            width: '15%',
            label: translate('offer.customer'),
            value: (offer: any) => offer.customer.name,
        },
        {
            width: '15%',
            label: translate('vessel.name'),
            value: (offer: any) => formatVessel({ vessel: offer.vessel, short: true }),
        },
        {
            width: '20%',
            label: translate('order.port'),
            value: (offer: any) => formatPort({ port: offer.port, locode: false }),
        },
        {
            width: '15%',
            label: translate('order.dateDelivery'),
            value: function renderDateDelivery(offer: any) {
                if (!offer.dateDelivery) {
                    return '-';
                }

                return formatDate({
                    date: offer.dateDelivery,
                    timeZone: 'UTC',
                });
            },
        },
        {
            width: '15%',
            label: translate('order.status'),
            value: function renderOfferState(offer: any) {
                return translateOfferState({
                    offer,
                    userType: 'SUPPLIER',
                });
            },
        },
    ].filter(({ condition = true }) => condition);

    return (
        <div ref={tableRef}>
            <Section title={translate('dashboard.recentactivities')}>
                <DataTable
                    columns={columns}
                    rows={suppliersOfferQuery.data.content}
                    onRowClick={({ row }) => openOffer(row)}
                />

                <CenteredPagination pageTotal={pageTotal} currentPage={page} onPageChange={(page) => setPage(page)} />
            </Section>
        </div>
    );
};
