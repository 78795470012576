import styled from 'styled-components';
import {
    border,
    BorderProps,
    boxShadow,
    BoxShadowProps,
    color,
    ColorProps,
    compose,
    flexbox,
    FlexboxProps,
    grid,
    GridProps,
    layout,
    LayoutProps,
    position,
    PositionProps,
    RequiredTheme,
    ResponsiveValue,
    space,
    SpaceProps,
    system,
    Theme,
    ThemeValue,
    variant,
} from 'styled-system';

interface GapProps<ThemeType extends Theme = RequiredTheme, TVal = ThemeValue<'space', ThemeType>> {
    gap?: ResponsiveValue<TVal, ThemeType>;
    rowGap?: ResponsiveValue<TVal, ThemeType>;
    columnGap?: ResponsiveValue<TVal, ThemeType>;
}

export interface BoxProps
    extends SpaceProps,
        BorderProps,
        ColorProps,
        LayoutProps,
        FlexboxProps,
        GridProps,
        PositionProps,
        BoxShadowProps,
        GapProps {
    variant?: 'card' | 'shadow' | 'terminal';
}

export const Box = styled.div<BoxProps>(
    {
        boxSizing: 'border-box',
    },
    variant({
        variants: {
            card: {
                boxShadow: 'medium',
                borderRadius: 1,
                backgroundColor: 'white',
                padding: 6,
            },
            shadow: {
                boxShadow: 'medium',
                borderRadius: 1,
            },
            terminal: {
                backgroundColor: 'black',
                color: 'white',
                padding: 4,
            },
        },
    }),
    compose(space, color, layout, flexbox, border, position, boxShadow, grid),
    system({
        gap: { property: 'gap', scale: 'space' },
        rowGap: { property: 'rowGap', scale: 'space' },
        columnGap: { property: 'columnGap', scale: 'space' },
    })
);
