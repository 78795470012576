import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getOfferList, GetOfferListParams } from '../clients/gateway.api';

export const offers = createQueryKeys('offers', {
    list: (params: GetOfferListParams) => ({
        queryKey: [{ params }],
        queryFn: () => getOfferList(params),
    }),
});
