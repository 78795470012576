import { AppProviders } from '../common/AppProviders';
import { createDirectiveFromReactComponent } from '../common/helpers/createDirectiveFromReactComponent';

import { GlobalPricesPage as OriginalGlobalPricesPage } from './GlobalPricesPage';

const GlobalPricesPage = (props) => {
    return (
        <AppProviders>
            <OriginalGlobalPricesPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(GlobalPricesPage, 'reactGlobalPricesPage');
