import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { LubesCreateEnquiryPage } from './LubesCreateEnquiryPage';

const LubesCreateEnquiryPageDirective = (props: any) => {
    return (
        <AppProviders>
            <LubesCreateEnquiryPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(LubesCreateEnquiryPageDirective, 'reactLubesCreateEnquiryPage');
