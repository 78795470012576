import { Formik, FormikErrors, setIn } from 'formik';
import { useEffect, useState } from 'react';

import { SamplekitMessage } from '../../../../types/SamplekitMessage';
import { SupplierMessage } from '../../../../types/SupplierMessage';
import { VesselMessage } from '../../../../types/VesselMessage';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { CreateOrderRequest, validateOrder } from '../../../common/api/clients/order.api';
import { Error } from '../../../common/Error/Error';
import { translate } from '../../../common/helpers/translate.helper';
import { useAngularRouterLocation } from '../../../common/hooks/useAngularRouterLocation';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useNavigation } from '../../../common/hooks/useNavigation';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Item } from '../../../common/models/item.model';
import { ItemModel } from '../../../common/models/ItemModel';
import { useEnquiryNavigateBack } from '../../common/hooks/useEnquiryNavigateBack';
import { OrderModel, OrderState } from '../../model/OrderModel';
import { useOrder } from '../hooks/useOrder';

import { FormikLubesCreateEnquiryForm } from './components/FormikLubesCreateEnquiryForm';
import { FormikSpotModal } from './components/FormikSpotModal';
import { useDraftCreate } from './hooks/useDraftCreate';
import { useEnquiryCreate } from './hooks/useEnquiryCreate';
import { useEnquiryUpdate } from './hooks/useEnquiryUpdate';
import { useMarkOrderRead } from './hooks/useMarkOrderRead';
import { isDateDeliveryInPast } from './util/isDateDeliveryInPast';

export interface LubesCreateEnquiryRouterParams {
    origin?: { stateName: string; params: { tab: string } };
    order?: OrderModel;
    vessel?: VesselMessage;
    portId?: string;
    items?: ItemModel[];
}

export interface FormikCreateEnquiryValues {
    customerId: string;
    vesselId: string;
    portId: string;
    dateDelivery: number | null;
    buyerReference: string;
    agentId: string;
    items: ItemModel[];
    samplekits: SamplekitMessage[];
    testOrder: boolean;
    assignedSupplierIds: string[];
    assignedSuppliers: SupplierMessage[];
    receiverSupplierIds: string[];
    spot: boolean;
    comment?: string;
    creationComment?: string;
    state?: OrderState;
}

function buildOrderRequest(values: FormikCreateEnquiryValues, state: OrderState): CreateOrderRequest {
    return {
        state: state,
        vesselId: values.vesselId,
        customerId: values.customerId,
        agentId: values.agentId || undefined,
        assignedSupplierIds: values.assignedSupplierIds?.length ? values.assignedSupplierIds : undefined,
        buyerReference: values.buyerReference ? values.buyerReference : undefined,
        comment: values.comment || undefined,
        dateDelivery: values.dateDelivery ? values.dateDelivery : undefined,
        items: values.items
            .filter((item) => item.productId || item.units)
            .map((item) => ({
                productId: item.productId || undefined,
                packType: item.packType,
                unitSize: item.unitSize,
                units: item.units ? Number(item.units) : undefined,
                unit: item.unit,
                volume: item.volume || undefined,
                deleted: item.deleted,
            })),
        portId: values.portId || undefined,
        receiverSupplierIds: values.receiverSupplierIds?.length ? values.receiverSupplierIds : undefined,
        samplekits: values.samplekits?.length
            ? values.samplekits.filter((sampleKit) => sampleKit.name || sampleKit.quantity)
            : undefined,
        spot: values.spot,
        testOrder: values.testOrder,
    };
}

export const LubesCreateEnquiryPage = () => {
    const [spotModalOpen, setSpotModalOpen] = useState(false);
    const role = useRole();
    const { mutateAsync: createDraftAsync } = useDraftCreate();
    const { mutateAsync: createEnquiryAsync } = useEnquiryCreate();
    const { mutateAsync: updateEnquiryAsync } = useEnquiryUpdate();
    const { mutate: markOrderRead } = useMarkOrderRead();
    const { navigate } = useNavigation();
    const { id } = useAngularRouterLocation<{ id: string }>();
    const orderDetailsQuery = useOrder(id);
    const { addToast, addErrorToast } = useToasts();
    const { navigateBack } = useEnquiryNavigateBack();

    const {
        order: orderFromState,
        vessel: vesselFromState,
        portId: portIdFromState,
        items: itemsFromState,
    } = useAngularRouterLocation<LubesCreateEnquiryRouterParams>();

    useEffect(() => {
        if (orderDetailsQuery.data && !role.isAdmin() && !orderDetailsQuery.data.read) {
            markOrderRead({ orderId: id, read: true });
        }
    }, [id, markOrderRead, orderDetailsQuery.data, orderDetailsQuery.data?.read, role]);

    const isDraftEnquiry = !!id;
    const isCopiedFromOrder = !!orderFromState;
    const order = orderDetailsQuery.data;

    useDocumentTitle(isDraftEnquiry ? translate('order.createNewDraft') : translate('order.createnew'));

    if (isDraftEnquiry && orderDetailsQuery.isPending) {
        return <LoadingIndicator variant="page" />;
    }

    if (id && order?.state !== OrderState.DRAFT) {
        navigate('base.orderlist-order', {
            id: order.id,
        });
    }

    if (isDraftEnquiry && orderDetailsQuery.isError) {
        return <Error />;
    }

    const handleSendEnquiry = async (values: FormikCreateEnquiryValues) => {
        if (isDraftEnquiry) {
            await updateEnquiryAsync({ order: buildOrderRequest(values, OrderState.OPEN), orderId: id });
        } else {
            await createEnquiryAsync(buildOrderRequest(values, OrderState.OPEN));
        }
    };

    const handleSaveAsDraft = async (values: FormikCreateEnquiryValues) => {
        await createDraftAsync(buildOrderRequest(values, OrderState.DRAFT));
    };

    const handleDraftUpdate = async (values: FormikCreateEnquiryValues) => {
        await updateEnquiryAsync(
            { order: buildOrderRequest(values, OrderState.DRAFT), orderId: id },
            {
                onSuccess: () => {
                    addToast(translate('order.successDraftToast'));
                },
                onError: () => {
                    addErrorToast(translate('order.errorDraftToast'));
                },
            }
        );
    };

    const formikValidate = async (
        values: FormikCreateEnquiryValues
    ): Promise<FormikErrors<FormikCreateEnquiryValues>> => {
        let errors: FormikErrors<FormikCreateEnquiryValues> = {};

        if (!values.vesselId) {
            errors.vesselId = translate('errormessage.empty');
        }

        if (!values.portId) {
            errors.portId = translate('errormessage.empty');
        }

        if (!values.dateDelivery) {
            errors.dateDelivery = translate('errormessage.empty');
        }

        if (isDateDeliveryInPast(values.dateDelivery)) {
            errors.dateDelivery = translate('errormessage.isInPast');
        }

        if (!values.items || values.items.length === 0) {
            errors.items = translate('errormessage.empty');
        } else {
            values.items.forEach((item, index) => {
                if (!item.productId) {
                    errors = setIn(errors, `items.${index}.productId`, true);
                }

                if (!item.units || Number(item.units) === 0) {
                    errors = setIn(errors, `items.${index}.units`, true);
                }

                if (!item.unitSize || Number(item.unitSize) === 0) {
                    errors = setIn(errors, `items.${index}.unitSize`, true);
                }
            });
        }

        if (values.samplekits) {
            values.samplekits.forEach((samplekit, index) => {
                if (!samplekit.name) {
                    errors = setIn(errors, `samplekits.${index}.name`, true);
                }

                if (!samplekit.quantity || Number(samplekit.quantity) === 0) {
                    errors = setIn(errors, `samplekits.${index}.quantity`, true);
                }
            });
        }

        if ((!values.receiverSupplierIds || values.receiverSupplierIds.length === 0) && values.spot === false) {
            errors.receiverSupplierIds = translate('order.supplierRequired');
        }

        try {
            if (values.customerId && values.buyerReference) {
                const response = await validateOrder({
                    type: 'LUBES',
                    customerId: values.customerId,
                    buyerReference: values.buyerReference ? values.buyerReference : null,
                    id: isDraftEnquiry ? id : null,
                });

                const buyerReferenceError = response.validationErrors.find((error) => error.path === 'buyerReference');

                if (buyerReferenceError) {
                    errors.buyerReference = translate('order.buyerReferenceAlreadyExists');
                }
            }
        } catch {
            return errors;
        }

        return errors;
    };

    const handleInitialItems = (items: ItemModel[]): ItemModel[] => {
        if (items?.length) {
            return items.map((item): ItemModel => {
                // @ts-ignore
                return new Item({
                    productId: item.productId || '',
                    units: item.units || '',
                    packType: item.packType || 'BULK',
                    unitSize: item.unitSize || 1,
                    unit: item.unit || 'L',
                    new: true,
                    deleted: false,
                    total: item.total || undefined,
                    product: item.product || undefined,
                });
            });
        }

        return [
            // @ts-ignore
            new Item({
                productId: '',
                new: true,
                unit: 'L',
                units: '',
                packType: 'BULK',
                unitSize: 1,
                deleted: false,
            }),
        ];
    };

    const initialItemsFromOrder = order?.items.length ? order.items : undefined;
    const initialItemsFromState = itemsFromState || orderFromState?.items || undefined;

    const initialValues: FormikCreateEnquiryValues = {
        vesselId: orderFromState?.vesselId || vesselFromState?.id || order?.vesselId || '',
        customerId: orderFromState?.customerId || vesselFromState?.customerId || order?.customerId || '',
        portId: orderFromState?.portId || portIdFromState || order?.portId || '',
        dateDelivery: orderFromState?.dateDelivery || order?.dateDelivery || null,
        testOrder: orderFromState?.testOrder || order?.testOrder || role.group.testCompany,
        buyerReference: orderFromState?.buyerReference || order?.buyerReference || '',
        comment: orderFromState?.comment || order?.comment || '',
        items: handleInitialItems(initialItemsFromState || initialItemsFromOrder),
        samplekits: orderFromState?.samplekits || order?.samplekits || [],
        assignedSupplierIds: [],
        assignedSuppliers: [],
        receiverSupplierIds: orderFromState?.receiverSupplierIds || order?.receiverSupplierIds || [],
        creationComment: order?.creationComment || '',
        spot: orderFromState?.spot || order?.spot || false,
        agentId: orderFromState?.agentId || order?.agentId || '',
        state: order?.state,
    };

    const onCloseClick = (dirty: boolean) => {
        if (!dirty) {
            navigateBack();
            return;
        }

        const confirmed = confirm(translate('confirmation.warning'));
        if (confirmed) {
            navigateBack();
        }
    };

    return (
        <Formik<FormikCreateEnquiryValues>
            initialValues={initialValues}
            initialTouched={{
                receiverSupplierIds:
                    (isDraftEnquiry && initialValues.receiverSupplierIds?.length) || isCopiedFromOrder ? true : false,
                buyerReference: !!(isCopiedFromOrder && initialValues.buyerReference),
            }}
            validate={formikValidate}
            onSubmit={(values, formikHelpers) => {
                if (values.spot) {
                    formikHelpers.setSubmitting(false);
                    setSpotModalOpen(true);
                } else {
                    return handleSendEnquiry(values);
                }
            }}
        >
            {(formikProps) => {
                return (
                    <div>
                        <FormikLubesCreateEnquiryForm
                            {...formikProps}
                            isDraftEnquiry={isDraftEnquiry}
                            onCloseClick={onCloseClick}
                            onSaveAsDraft={handleSaveAsDraft}
                            onDraftUpdate={handleDraftUpdate}
                        />

                        <FormikSpotModal
                            onSubmit={() => {
                                formikProps.setSubmitting(true);
                                void handleSendEnquiry(formikProps.values);
                            }}
                            isOpen={spotModalOpen}
                            onDismiss={() => setSpotModalOpen(false)}
                        />
                    </div>
                );
            }}
        </Formik>
    );
};
