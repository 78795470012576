import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { rebuildMapping } from '../common/api/clients/one-port.api';

interface Params {
    offerId: string;
    confirmedOrderNumber: string;
}

export const useOnePortRebuildMapping = () => {
    return useMutation<void, AxiosError<string>, Params>({
        mutationFn: (params) => rebuildMapping(params.offerId, params.confirmedOrderNumber),
    });
};
