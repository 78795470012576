const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

export function getWeekNumberForDate(date: Date): number {
    const internalDate = new Date(date.getTime());
    internalDate.setHours(0, 0, 0, 0);

    // Set date to Thursday of the week of the date to determine the year.
    internalDate.setDate(internalDate.getDate() + 3 - ((internalDate.getDay() + 6) % 7));

    // January 4 is always in week 1, because calendar week 1 needs to have at least 4 days in the new year.
    const calendarWeekOne = new Date(internalDate.getFullYear(), 0, 4);

    const daysSinceWeekOne = (internalDate.getTime() - calendarWeekOne.getTime()) / DAY_IN_MILLISECONDS;

    // Adjust to Thursday in week 1 and count number of weeks from date to week 1.
    return 1 + Math.round((daysSinceWeekOne - 3 + ((calendarWeekOne.getDay() + 6) % 7)) / 7);
}
