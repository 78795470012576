import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconFlask = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2v4.824l3.925 10.793a1.7 1.7 0 0 1-1.244 2.366.992.992 0 0 1-.181.017h-11a.992.992 0 0 1-.181-.017 1.699 1.699 0 0 1-1.244-2.366L9 8.824V4a1 1 0 0 1-1-1Zm3 1v4h2V4h-2Zm2.3 6h-2.6L7.064 20h9.872L13.3 10Z"
            clipRule="evenodd"
        />
    </svg>
);
