import { CategoryState } from '../../../types/ReachInformationMessage';
import { Text } from '../../cdl/Text/Text';
import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { translate } from '../../common/helpers/translate.helper';
import { useFuelVolumeFormat } from '../../common/hooks/useFuelVolumeFormat';

interface DemandRemainingOnBoardProps {
    volume: number;
    categoryState?: CategoryState;
}

export const DemandRemainingOnBoard = ({ volume, categoryState }: DemandRemainingOnBoardProps) => {
    const formatVolume = useFuelVolumeFormat(false);

    const getTextColor = () => {
        switch (categoryState) {
            case CategoryState.SAFETY_RESERVE:
                return 'attention.foreground';

            case CategoryState.OUT_OF_REACH:
                return 'negative.foreground';

            case CategoryState.IN_REACH:
            case undefined:
                return 'foreground.default';
        }
    };
    const getTooltipLabel = () => {
        switch (categoryState) {
            case CategoryState.SAFETY_RESERVE:
                return translate('demand.onSafetyReserveTooltip');

            case CategoryState.OUT_OF_REACH:
                return translate('demand.outOfReachTooltip');

            case CategoryState.IN_REACH:
            case undefined:
                return undefined;
        }
    };
    const getFontWeight = () => {
        switch (categoryState) {
            case CategoryState.SAFETY_RESERVE:
                return 'semiBold';

            case CategoryState.OUT_OF_REACH:
                return 'semiBold';

            case CategoryState.IN_REACH:
            case undefined:
                return 'normal';
        }
    };

    const tooltipLabel = getTooltipLabel();

    if (!tooltipLabel) {
        return (
            <Text variant="small" fontWeight={getFontWeight()} color={getTextColor()}>
                {formatVolume({ volume })}
            </Text>
        );
    }

    return (
        <Tooltip label={tooltipLabel}>
            <Text variant="small" fontWeight={getFontWeight()} color={getTextColor()}>
                {formatVolume({ volume })}
            </Text>
        </Tooltip>
    );
};
