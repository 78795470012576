import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { useField } from 'formik';

import { Box } from '../../common/ui/Box';

import { RadioGroup } from './RadioGroup';

export const FormikRadioGroup = ({
    ...props
}: RadixRadioGroup.RadioGroupProps & {
    name: string;
}) => {
    const [field, meta, helpers] = useField({
        name: props.name,
        multiple: false,
    });

    return (
        <RadioGroup {...props} defaultValue={field.value} onValueChange={helpers.setValue}>
            {meta.touched && meta.error ? (
                <Box p={4} color="negative.foreground" border={1} borderColor="negative.border">
                    {meta.error}
                </Box>
            ) : null}
            {props.children}
        </RadioGroup>
    );
};
