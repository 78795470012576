import { FormikErrors, setIn } from 'formik';

import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';
import { OfferModel } from '../model/OfferModel';

interface LubesOfferFormikErrors extends FormikErrors<OfferModel> {
    invalidProducts?: boolean;
}

export function useValidateLubesOfferForm(): (formikValues: OfferModel) => LubesOfferFormikErrors {
    const role = useRole();

    return (formikValues: OfferModel) => {
        let errors: LubesOfferFormikErrors = {};

        if (!formikValues.dateDelivery) {
            errors.dateDelivery = translate('offer.edit.lubes.deliveryDateRequired');
        }

        if (role.isSupplier() && !formikValues.supplyMode) {
            errors.supplyMode = translate('offer.edit.lubes.supplyModeRequired');
        }

        if (formikValues.isSpot()) {
            if (!formikValues.paymentTermReference) {
                errors.paymentTermReference = translate('offer.edit.lubes.paymentTermReferenceRequired');
            }

            if (formikValues.paymentTermReference !== 'PREPAYMENT' && !formikValues.paymentTermReferenceDays) {
                errors.paymentTermReferenceDays = translate('offer.edit.lubes.paymentTermReferenceDaysRequired');
            }
        }

        formikValues.items.forEach((item, index) => {
            if (item.deleted) {
                return;
            }

            if (!item.productId) {
                errors = setIn(errors, `items.${index}.productId`, true);
            }

            if (!item.units || Number(item.units) === 0) {
                errors = setIn(errors, `items.${index}.units`, true);
            }

            if (!item.unitSize || Number(item.unitSize) === 0) {
                errors = setIn(errors, `items.${index}.unitSize`, true);
            }

            if (
                role.isSupplier() &&
                (item.price?.value === undefined ||
                    item.price?.value?.toString() === '' ||
                    Number(item.price?.value) < 0)
            ) {
                errors = setIn(errors, `items.${index}.price.value`, true);
            }
        });

        if (formikValues.items.every((item) => item.deleted)) {
            errors.invalidProducts = true;
        }

        formikValues.samplekits?.forEach((samplekit, index) => {
            if (samplekit.deleted) {
                return;
            }

            if (!samplekit.name) {
                errors = setIn(errors, `samplekits.${index}.name`, true);
            }

            if (!samplekit.quantity || Number(samplekit.quantity) === 0) {
                errors = setIn(errors, `samplekits.${index}.quantity`, true);
            }

            if (
                role.isSupplier() &&
                (samplekit.value?.value === undefined ||
                    samplekit.value?.value?.toString() === '' ||
                    Number(samplekit.value?.value) < 0)
            ) {
                errors = setIn(errors, `samplekits.${index}.value.value`, true);
            }
        });

        formikValues.surcharges?.forEach((surcharge, index) => {
            if (surcharge.deleted) {
                return;
            }

            if ((surcharge.surchargeType === 'CUSTOM' || surcharge.value?.value < 0) && !surcharge.name) {
                errors = setIn(errors, `surcharges.${index}.name`, true);
            }

            if (!surcharge.value?.value || Number(surcharge.value?.value) === 0) {
                errors = setIn(errors, `surcharges.${index}.value.value`, true);
            }
        });

        return errors;
    };
}
