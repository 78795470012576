import { FuelStockMessage } from '../../../types/FuelStockMessage';
import { CategoryState, ReachInformationCategory } from '../../../types/ReachInformationMessage';
import { Text } from '../../cdl/Text/Text';
import { checkPreference } from '../../flags';
import { translate } from '../helpers/translate.helper';
import { useFuelVolumeFormat } from '../hooks/useFuelVolumeFormat';
import { Box } from '../ui/Box';
import { Flex } from '../ui/Flex';

import { DaysLeftSailing } from './components/DaysLeftSailing';
import { FuelStockTooltip } from './components/FuelStockTooltip';

interface FuelStockGroupProps {
    fuelStocks: FuelStockMessage[];
    reachInformation?: ReachInformationCategory;
}

export const FuelStockGroup = ({ fuelStocks, reachInformation }: FuelStockGroupProps) => {
    const formatFuelVolume = useFuelVolumeFormat();

    const leadingFuelStock = fuelStocks[0];

    const totalVolume = fuelStocks.reduce((acc, stock) => acc + stock.volume, 0);

    const getColorForReachInformation = (reachInformation?: ReachInformationCategory) => {
        if (!reachInformation) {
            return 'inherit';
        }

        switch (reachInformation.categoryState) {
            case CategoryState.IN_REACH:
                return 'inherit';
            case CategoryState.SAFETY_RESERVE:
                return 'attention.foreground';
            case CategoryState.OUT_OF_REACH:
                return 'negative.foreground';
        }
    };

    return (
        <Box>
            <Flex justifyContent="space-between" marginBottom={2}>
                <Flex alignItems="center">
                    <Text size={14} weight="semi">
                        {leadingFuelStock.productGroup} - {leadingFuelStock.productSulphurContent}
                    </Text>
                    {reachInformation && !checkPreference('DaysLeftSailing') ? (
                        <DaysLeftSailing reachInformation={reachInformation} />
                    ) : null}
                </Flex>
                <Text size={14} weight="semi" color={getColorForReachInformation(reachInformation)}>
                    {formatFuelVolume({
                        volume: totalVolume,
                        unit: leadingFuelStock.unitOfMeasurement,
                    })}
                </Text>
            </Flex>

            {fuelStocks.map((fuelStock) => (
                <Flex key={fuelStock.id} marginTop={3} justifyContent="space-between">
                    <Box>
                        {fuelStock.product ? (
                            <FuelStockTooltip fuelStock={fuelStock}>
                                <Text variant="small">{fuelStock.product.name}</Text>
                            </FuelStockTooltip>
                        ) : null}

                        {fuelStock.bunkerDeliveryNote ? (
                            <FuelStockTooltip fuelStock={fuelStock}>
                                <Text color="dark-blue.3" marginLeft={3}>
                                    {translate('vessel.fuelStocks.bunkerDeliveryNote', {
                                        bunkerDeliveryNote: fuelStock.bunkerDeliveryNote,
                                    })}
                                </Text>
                            </FuelStockTooltip>
                        ) : null}

                        {fuelStock.tankName ? (
                            <FuelStockTooltip fuelStock={fuelStock}>
                                <Text color="dark-blue.3" marginLeft={3}>
                                    {translate('vessel.fuelStocks.tankName', {
                                        tankName: fuelStock.tankName,
                                    })}
                                </Text>
                            </FuelStockTooltip>
                        ) : null}
                    </Box>

                    <Box>
                        <FuelStockTooltip fuelStock={fuelStock}>
                            <Text color="dark-blue.3">
                                {formatFuelVolume({
                                    volume: fuelStock.volume,
                                    unit: leadingFuelStock.unitOfMeasurement,
                                })}
                            </Text>
                        </FuelStockTooltip>
                    </Box>
                </Flex>
            ))}
        </Box>
    );
};
