import { useEffect, useRef, useState } from 'react';

type DefaultValue = boolean;

function useSessionStorage(
    key: string,
    defaultValue: DefaultValue | (() => DefaultValue),
    { serialize = JSON.stringify, deserialize = JSON.parse } = {}
) {
    const [state, setState] = useState(() => {
        const valueInSessionStorage = sessionStorage.getItem(key);
        if (valueInSessionStorage) {
            return deserialize(valueInSessionStorage);
        }
        return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
    });

    const prevKeyRef = useRef(key);

    useEffect(() => {
        const prevKey = prevKeyRef.current;
        if (prevKey !== key) {
            sessionStorage.removeItem(prevKey);
        }
        prevKeyRef.current = key;
        sessionStorage.setItem(key, serialize(state));
    }, [key, state, serialize]);

    return [state, setState];
}

export { useSessionStorage };
