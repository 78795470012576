/* eslint-disable import/order */
import 'react-app-polyfill/ie11';
import 'core-js';
import 'url-search-params-polyfill';
import './ngReact';
import 'sweetalert';

// angular modules
import angular from 'angular';
import 'angular-animate';
import 'angular-cookies';
import 'angular-elastic';
import 'angular-jwt';
import 'angular-locale';
import 'angular-messages';
import 'angular-resource';
import 'angular-sanitize';
import 'angular-translate';
import 'angular-translate-loader-static-files';
import 'angular-ui-bootstrap';
import 'angular-ui-router';
import 'checklist-model';
import 'ui-select';

import translationEn from '../translations/en/translation.json';

import { loadDevTools } from './dev-tools/loadDevTools';
import constants from './constants';
import config from './config';
import run from './run';
import routes from './routes';

import 'react-loading-skeleton/dist/skeleton.css';
import '../styles/style.less';
/* eslint-enable import/order */

// -- loading all the filters, directives etc ----------------------------------
const getModules = (req) =>
    Object.keys(req).map((key) => {
        return { path: key, module: req[key].default };
    });

const directives = import.meta.glob('./**/*.(directive|converter).(js|jsx|tsx)', { eager: true });
const controllers = import.meta.glob('./**/*.controller.(js|jsx)', { eager: true });
const templates = import.meta.glob('./**/*.html', { eager: true, as: 'raw' });
const services = import.meta.glob('./**/*.(resource|service|factory|interceptor|model).(js|jsx)', { eager: true });

const inject = (key, modules) => (init) =>
    modules.reduce((acc, cv) => {
        const group = cv.path.split('/')[1];

        if (!acc[group]) {
            acc[group] = {};
        }

        if (!acc[group][key]) {
            acc[group][key] = [];
        }

        acc[group][key].push(cv.module);

        return acc;
    }, init);

const moduleMap = [
    inject('controllers', getModules(controllers)),
    inject('directives', getModules(directives)),
    inject('services', getModules(services)),
].reduce((p, f) => f(p), {});

const registeredModules = Object.entries(moduleMap).map(([key, item]) => {
    const name = 'closelink.' + key;
    const module = angular.module(name, []);

    item.directives?.forEach((m) => module.directive(m.name, m.fn));
    item.controllers?.forEach((m) => module.controller(m.name, m.fn));
    item.services?.forEach((m) => module.service(m.name, m.fn));

    return name;
});

// -- angular view template cache ---------------------------------------------

angular.module('templates', []).run([
    '$templateCache',
    function ($templateCache) {
        $templateCache.put('en/translation.json', JSON.stringify(translationEn));

        Object.keys(templates).forEach((path) => {
            $templateCache.put(path.slice(2), templates[path]);
        });
    },
]);

// -- adding generic angular dependencies -------------------------------------

const dependencies = [
    'angular-jwt',
    'checklist-model',
    'templates',
    'monospaced.elastic',
    'ngAnimate',
    'ngCookies',
    'ngMessages',
    'ngResource',
    'ngSanitize',
    'pascalprecht.translate',
    'react',
    'ui.bootstrap',
    'ui.router',
    'ui.select',
].concat(registeredModules);

angular.module('closelink', dependencies).constant('AppSettings', constants).config(config).config(routes).run(run);

loadDevTools(async () => {
    // We do not have access to $document in here.
    angular.bootstrap(document.body, ['closelink'], {
        strictDi: true,
    });
});
