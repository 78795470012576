import { createContext } from 'react';

const defaultContextValue = {
    showPrices: true,
    editable: true,
    showPhysicalSupplierColumn: true,
    showCounterOffers: true,
    editSurcharges: true,
    editPrices: true,
    fetchProducts: null,
};

export const ProductTableContext = createContext(defaultContextValue);
