import { ReactNode } from 'react';

import { LocationStateProvider } from '../../../../common/LocationState/LocationStateProvider';

export const PortsOverviewState = ({ children }: { children: ReactNode }) => {
    const stateConfig = {
        page: 0,
        searchQuery: '',
    };

    return <LocationStateProvider stateConfig={stateConfig}>{children}</LocationStateProvider>;
};
