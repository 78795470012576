import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
    getOnboardingUser,
    UserOnboardErrorResponse,
    UserOnboardResponse,
} from '../../common/api/clients/userOnboard.api';
import { queryKeys } from '../../common/api/queryKeys';

export const useUserOnboarding = (userId: string, token: string) => {
    return useQuery<UserOnboardResponse, AxiosError<UserOnboardErrorResponse>>({
        queryKey: queryKeys.userOnboarding(userId),
        queryFn: () => {
            return getOnboardingUser(userId, token);
        },
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: (failureCount, error) => {
            if (error.response?.status === 403) {
                return false;
            }

            return failureCount <= 3;
        },
    });
};
