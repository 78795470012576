import { FuelPricingCodeFrequency } from '../../../../types/FuelPricingCodeFrequency';
import { FuelPricingDate } from '../../../../types/FuelPricingDate';

export const getPricingCodeFrequencies = (pricingDate?: FuelPricingDate): FuelPricingCodeFrequency[] => {
    if (!pricingDate) {
        return [];
    }

    if (pricingDate === FuelPricingDate.MONTHLY_AVERAGE) {
        return [FuelPricingCodeFrequency.MONTHLY];
    }

    return [FuelPricingCodeFrequency.DAILY, FuelPricingCodeFrequency.INTRADAY];
};
