import { getOffers } from '../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { usePaginationQuery } from '../../../common/hooks/usePaginationQuery';

export const useFuelSupplierRecentActivities = (page: number) => {
    return usePaginationQuery({
        queryKey: queryKeys.supplierRecentActivities({
            page,
            types: ['FUEL'],
        }),
        queryFn: () =>
            getOffers({
                page,
                supplierRead: false,
                types: ['FUEL'],
                sortField: 'dateUpdated',
                sortDirection: 'DESC',
            }),
        prefetchQueryKey: queryKeys.supplierRecentActivities({
            page: page + 1,
            types: ['FUEL'],
        }),
        prefetchQueryFn: () =>
            getOffers({
                page: page + 1,
                supplierRead: false,
                types: ['FUEL'],
                sortField: 'dateUpdated',
                sortDirection: 'DESC',
            }),
    });
};
