import { CustomerStatisticsMessage } from '../../../types/StatisticsMessage';
import { useLocationState } from '../../common/LocationState/useLocationState';

import { GeneralTabContent } from './GeneralTabContent';
import { PortsTabContent } from './PortsTabContent';
import { ProductGroupsTabContent } from './ProductGroupsTabContent';
import { ProductsTabContent } from './ProductsTabContent';
import { StatisticsStateConfig } from './StatisticsState';
import { StatisticTab } from './StatisticTab';
import { SuppliersTabContent } from './SuppliersTabContent';
import { VesselsTabContent } from './VesselsTabContent';

interface CustomerStatisticsContentProps {
    statistics: CustomerStatisticsMessage;
}

export const CustomerStatisticsContent = ({ statistics }: CustomerStatisticsContentProps) => {
    const [locationStateUntyped] = useLocationState();
    const locationState = locationStateUntyped as StatisticsStateConfig;

    switch (locationState.tab) {
        case StatisticTab.GENERAL:
            return <GeneralTabContent statistics={statistics} />;
        case StatisticTab.VESSELS:
            return <VesselsTabContent vesselStatistics={statistics.vessel} />;
        case StatisticTab.SUPPLIERS:
            return <SuppliersTabContent supplierStatistics={statistics.supplier} />;
        case StatisticTab.PORTS:
            return <PortsTabContent portStatistics={statistics.port} />;
        case StatisticTab.PRODUCTS:
            return <ProductsTabContent productStatistics={statistics.product} />;
        case StatisticTab.PRODUCT_GROUPS:
            return <ProductGroupsTabContent productGroupsStatistics={statistics.productGroup} />;
        case StatisticTab.CUSTOMERS:
            return null;
    }
};
