import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { updateCustomerSettings } from '../../../common/api/clients/companySettings.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { translate } from '../../../common/helpers/translate.helper';

export const useCustomerSettingsEdit = () => {
    const queryClient = useQueryClient();
    const { addErrorToast } = useToasts();

    return useMutation({
        mutationFn: updateCustomerSettings,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: queryKeys.customerSettings(data.customerId) });
        },
        onError: () => {
            addErrorToast(translate('company.toasts.informationUpdateError'));
        },
    });
};
