import { variant } from 'styled-system';

export const disabledVariants = variant({
    variants: {
        default: {
            color: 'foreground.disabled',
            backgroundColor: 'background.subtle',
            borderColor: 'transparent',
        },
        accent: {
            color: 'accent.border',
            backgroundColor: 'accent.background',
            borderColor: 'transparent',
        },
        positive: {
            color: 'positive.border',
            backgroundColor: 'positive.background',
            borderColor: 'transparent',
        },
        attention: {
            color: 'attention.border',
            backgroundColor: 'attention.background',
            borderColor: 'transparent',
        },
        negative: {
            color: 'negative.border',
            backgroundColor: 'negative.background',
            borderColor: 'transparent',
        },
    },
});
