import { ApprovalRequestState } from '../../../../types/ApprovalRequestState';
import { CustomerSettingsMessage } from '../../../../types/CustomerSettingsMessage';
import { Button } from '../../../cdl/Button/Button';
import { StatusBar } from '../../../cdl/StatusBar/StatusBar';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { OfferModel } from '../../model/OfferModel';
import { OfferState } from '../../model/OfferState';
import { ConfirmChangesPopup } from '../common/ConfirmChangesPopup';
import { useSwitchState } from '../common/hooks/useSwitchState';
import { RequestApprovalButton } from '../common/RequestApprovalButton';

import { FuelConfirmDeliveryPopup } from './FuelConfirmDeliveryPopup';

interface AnonymousOrderStatusBarProps {
    offer: OfferModel;
}

const AnonymousOrderStatusBar = ({ offer }: AnonymousOrderStatusBarProps) => {
    const { changeState } = useSwitchState();
    const role = useRole();

    const headlineTranslation = offer.contractId
        ? translate('statusbar.customer.fuel.limitedSupplierOrderContract.headline')
        : translate('statusbar.customer.fuel.limitedSupplierOrder.headline');

    const subHeadlineTranslation = offer.contractId
        ? translate('statusbar.customer.fuel.limitedSupplierOrderContract.subHeadline')
        : translate('statusbar.customer.fuel.limitedSupplierOrder.subHeadline');

    return (
        <StatusBar
            variant="neutral"
            headline={headlineTranslation}
            subHeadline={subHeadlineTranslation}
            trailingVisual={
                role.hasWriteRights(offer.customerId) ? (
                    <Button
                        emphasis="high"
                        onClick={() => {
                            changeState({
                                offer,
                                destinationState: OfferState.DELIVERED,
                                component: FuelConfirmDeliveryPopup,
                            });
                        }}
                    >
                        {translate('statusbar.supplier.fuel.confirmedPastDelivery.button')}
                    </Button>
                ) : null
            }
        />
    );
};

interface FuelCustomerOfferDetailStatusBarProps {
    offer: OfferModel;
    customerSettings: CustomerSettingsMessage;
}

export const FuelCustomerOfferDetailStatusBar = ({
    offer,
    customerSettings,
}: FuelCustomerOfferDetailStatusBarProps) => {
    const { changeState } = useSwitchState();
    const role = useRole();

    if (
        offer.isAnonymous() &&
        [
            OfferState.ORDER,
            OfferState.CUSTOMER_ADJUSTED,
            OfferState.SUPPLIER_ADJUSTED,
            OfferState.CONFIRMED,
            OfferState.ACKNOWLEDGED,
        ].includes(offer.state)
    ) {
        return <AnonymousOrderStatusBar offer={offer} />;
    }

    const hasWriteRights = role.hasWriteRights(offer.customerId);

    switch (offer.state) {
        case OfferState.ACKNOWLEDGED: {
            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.customer.fuel.acknowledgedContract.headline')}
                        subHeadline={translate('statusbar.customer.fuel.acknowledgedContract.subHeadline')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.customer.fuel.acknowledged.headline')}
                    subHeadline={translate('statusbar.customer.fuel.acknowledged.subHeadline')}
                />
            );
        }
        case OfferState.CANCELED: {
            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="attention"
                        headline={translate('statusbar.customer.fuel.canceledContract.headline')}
                        subHeadline={translate('statusbar.customer.fuel.canceledContract.subHeadline')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="attention"
                    headline={translate('statusbar.customer.fuel.canceled.headline')}
                    subHeadline={translate('statusbar.customer.fuel.canceled.subHeadline')}
                />
            );
        }
        case OfferState.CONFIRMED: {
            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.customer.fuel.confirmedContract.headline')}
                        subHeadline={translate('statusbar.customer.fuel.confirmedContract.subHeadline')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.customer.fuel.confirmed.headline')}
                    subHeadline={translate('statusbar.customer.fuel.confirmed.subHeadline')}
                />
            );
        }
        case OfferState.DELIVERED: {
            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="positive"
                        headline={translate('statusbar.customer.fuel.deliveredContract.headline')}
                        subHeadline={translate('statusbar.customer.fuel.deliveredContract.subHeadline')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="positive"
                    headline={translate('statusbar.customer.fuel.delivered.headline')}
                    subHeadline={translate('statusbar.customer.fuel.delivered.subHeadline')}
                />
            );
        }
        case OfferState.INVOICED: {
            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="positive"
                        headline={translate('statusbar.customer.fuel.invoicedContract.headline')}
                        subHeadline={translate('statusbar.customer.fuel.invoicedContract.subHeadline')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="positive"
                    headline={translate('statusbar.customer.fuel.invoiced.headline')}
                    subHeadline={translate('statusbar.customer.fuel.invoiced.subHeadline')}
                />
            );
        }
        case OfferState.ORDER: {
            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.customer.fuel.orderContract.headline')}
                        subHeadline={translate('statusbar.customer.fuel.orderContract.subHeadline')}
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.customer.fuel.order.headline')}
                    subHeadline={translate('statusbar.customer.fuel.order.subHeadline')}
                />
            );
        }
        case OfferState.CUSTOMER_ADJUSTED: {
            if (!customerSettings.approvalSettings?.activated) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.customer.fuel.customerAdjusted.headline')}
                        subHeadline={translate('statusbar.customer.fuel.customerAdjusted.subHeadline')}
                        trailingVisual={
                            hasWriteRights ? (
                                <Button
                                    emphasis="high"
                                    onClick={() => {
                                        changeState({
                                            offer,
                                            destinationState: OfferState.ORDER,
                                        });
                                    }}
                                >
                                    {translate('statusbar.customer.fuel.customerAdjusted.actionButton')}
                                </Button>
                            ) : null
                        }
                    />
                );
            }

            const latestApprovalRequestState = offer.approvalRequests?.[0]?.state;

            if (latestApprovalRequestState === ApprovalRequestState.PENDING) {
                if (offer.contractId) {
                    return (
                        <StatusBar
                            variant="neutral"
                            headline={translate(
                                'statusbar.customer.fuel.customerAdjustedApprovalPendingContract.headline'
                            )}
                            subHeadline={translate(
                                'statusbar.customer.fuel.customerAdjustedApprovalPendingContract.subHeadline'
                            )}
                            trailingVisual={
                                hasWriteRights ? (
                                    <RequestApprovalButton offer={offer}>
                                        {translate(
                                            'statusbar.customer.fuel.customerAdjustedApprovalPendingContract.actionButton'
                                        )}
                                    </RequestApprovalButton>
                                ) : null
                            }
                        />
                    );
                }

                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.customer.fuel.customerAdjustedApprovalPending.headline')}
                        subHeadline={translate('statusbar.customer.fuel.customerAdjustedApprovalPending.subHeadline')}
                        trailingVisual={
                            hasWriteRights ? (
                                <RequestApprovalButton offer={offer}>
                                    {translate('statusbar.customer.fuel.customerAdjustedApprovalPending.actionButton')}
                                </RequestApprovalButton>
                            ) : null
                        }
                    />
                );
            }

            if (latestApprovalRequestState === ApprovalRequestState.DECLINED) {
                if (offer.contractId) {
                    return (
                        <StatusBar
                            variant="neutral"
                            headline={translate(
                                'statusbar.customer.fuel.customerAdjustedApprovalDeclinedContract.headline'
                            )}
                            subHeadline={translate(
                                'statusbar.customer.fuel.customerAdjustedApprovalDeclinedContract.subHeadline'
                            )}
                            trailingVisual={
                                hasWriteRights ? (
                                    <RequestApprovalButton offer={offer}>
                                        {translate(
                                            'statusbar.customer.fuel.customerAdjustedApprovalDeclinedContract.actionButton'
                                        )}
                                    </RequestApprovalButton>
                                ) : null
                            }
                        />
                    );
                }

                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.customer.fuel.customerAdjustedApprovalDeclined.headline')}
                        subHeadline={translate('statusbar.customer.fuel.customerAdjustedApprovalDeclined.subHeadline')}
                        trailingVisual={
                            hasWriteRights ? (
                                <RequestApprovalButton offer={offer}>
                                    {translate('statusbar.customer.fuel.customerAdjustedApprovalDeclined.actionButton')}
                                </RequestApprovalButton>
                            ) : null
                        }
                    />
                );
            }

            if (latestApprovalRequestState === ApprovalRequestState.EXPIRED) {
                if (offer.contractId) {
                    return (
                        <StatusBar
                            variant="neutral"
                            headline={translate(
                                'statusbar.customer.fuel.customerAdjustedApprovalExpiredContract.headline'
                            )}
                            subHeadline={translate(
                                'statusbar.customer.fuel.customerAdjustedApprovalExpiredContract.subHeadline'
                            )}
                            trailingVisual={
                                hasWriteRights ? (
                                    <RequestApprovalButton offer={offer}>
                                        {translate(
                                            'statusbar.customer.fuel.customerAdjustedApprovalExpiredContract.actionButton'
                                        )}
                                    </RequestApprovalButton>
                                ) : null
                            }
                        />
                    );
                }

                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.customer.fuel.customerAdjustedApprovalExpired.headline')}
                        subHeadline={translate('statusbar.customer.fuel.customerAdjustedApprovalExpired.subHeadline')}
                        trailingVisual={
                            hasWriteRights ? (
                                <RequestApprovalButton offer={offer}>
                                    {translate('statusbar.customer.fuel.customerAdjustedApprovalExpired.actionButton')}
                                </RequestApprovalButton>
                            ) : null
                        }
                    />
                );
            }

            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.customer.fuel.customerAdjustedApprovalContract.headline')}
                        subHeadline={translate('statusbar.customer.fuel.customerAdjustedApprovalContract.subHeadline')}
                        trailingVisual={
                            hasWriteRights ? (
                                <RequestApprovalButton offer={offer}>
                                    {translate('statusbar.customer.fuel.customerAdjustedApprovalContract.actionButton')}
                                </RequestApprovalButton>
                            ) : null
                        }
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.customer.fuel.customerAdjustedApproval.headline')}
                    subHeadline={translate('statusbar.customer.fuel.customerAdjustedApproval.subHeadline')}
                    trailingVisual={
                        hasWriteRights ? (
                            <RequestApprovalButton offer={offer}>
                                {translate('statusbar.customer.fuel.customerAdjustedApproval.actionButton')}
                            </RequestApprovalButton>
                        ) : null
                    }
                />
            );
        }
        case OfferState.SUPPLIER_ADJUSTED: {
            if (offer.contractId) {
                return (
                    <StatusBar
                        variant="neutral"
                        headline={translate('statusbar.customer.fuel.supplierAdjustedContract.headline')}
                        subHeadline={translate('statusbar.customer.fuel.supplierAdjustedContract.subHeadline')}
                        trailingVisual={
                            hasWriteRights ? (
                                <Button
                                    emphasis="high"
                                    onClick={() => {
                                        changeState({
                                            offer,
                                            destinationState: OfferState.ORDER,
                                            component: ConfirmChangesPopup,
                                        });
                                    }}
                                >
                                    {translate('statusbar.customer.supplierAdjusted.button')}
                                </Button>
                            ) : null
                        }
                    />
                );
            }

            return (
                <StatusBar
                    variant="neutral"
                    headline={translate('statusbar.customer.fuel.supplierAdjusted.headline')}
                    subHeadline={translate('statusbar.customer.fuel.supplierAdjusted.subHeadline')}
                    trailingVisual={
                        hasWriteRights ? (
                            <Button
                                emphasis="high"
                                onClick={() => {
                                    changeState({
                                        offer,
                                        destinationState: OfferState.ORDER,
                                        component: ConfirmChangesPopup,
                                    });
                                }}
                            >
                                {translate('statusbar.customer.supplierAdjusted.button')}
                            </Button>
                        ) : null
                    }
                />
            );
        }
    }
    return null;
};
