import styled, { css } from 'styled-components';

import { get } from '../../../common/ui/get';

const activeStyles = css`
    background-color: ${get('colors.accent.background')};
    color: ${get('colors.accent.foreground')};
`;

interface CalendarPresetEntryProps {
    active?: boolean;
}

export const CalendarPresetEntry = styled.div<CalendarPresetEntryProps>`
    padding: ${get('space.4')}px ${get('space.6')}px;
    cursor: pointer;
    font-size: ${get('fontSizes.2')};
    line-height: 24px;
    font-weight: 500;
    border: none;

    &:hover {
        background-color: ${(props) =>
            props.active ? get('colors.accent.background') : get('colors.background.subtle')};
    }

    ${(props) => (props.active ? activeStyles : '')}
`;
