import { ReactNode } from 'react';

import { OfferModel } from '../../../../../../offer/model/OfferModel';
import { OrderModel } from '../../../../../model/OrderModel';

import { OfferCardContext } from './OfferCardContext';

interface OfferCardContextProviderProps {
    offer: OfferModel;
    order: OrderModel;
    children: ReactNode;
}

export const OfferCardContextProvider = ({ children, offer, order }: OfferCardContextProviderProps) => {
    return <OfferCardContext.Provider value={{ offer, order }}>{children}</OfferCardContext.Provider>;
};
