import { Button } from '../../cdl/Button/Button';
import { useDrawer } from '../../cdl/Drawer/useDrawer.hook';
import { Text } from '../../cdl/Text/Text.tsx';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useRole } from '../../common/hooks/useRole';
import { useLocationState } from '../../common/LocationState/useLocationState';
import { Page } from '../../common/Page/Page';
import { PageGrid } from '../../common/PageGrid';
import { Box } from '../../common/ui/Box.tsx';
import { CreateContractForm } from '../ContractForm/CreateContractForm';

import { ContractOverviewState } from './ContractOverviewState';
import { ContractOverviewTable } from './ContractOverviewTable';
import { ContractTab } from './contractTabsHelper';
import { ContractOverviewTableControls } from './components/ContractOverviewTableControls';

export const ContractOverviewPage = () => {
    return (
        <ContractOverviewState>
            <ContractOverviewPageContent />
        </ContractOverviewState>
    );
};

const ContractOverviewPageContent = () => {
    const role = useRole();
    const { push, pop } = useDrawer();
    const [, { setLocationFieldValue }] = useLocationState();

    useDocumentTitle(translate('contracts.overviewPageTitle'));

    const openCreateModal = () => {
        push({
            width: '60vw',
            content: (
                <CreateContractForm
                    onSuccess={(contract) => {
                        if (contract.tender.enabled) {
                            setLocationFieldValue('tab', ContractTab.TENDERED);
                        } else {
                            setLocationFieldValue('tab', ContractTab.CONTRACTED);
                        }
                        pop();
                    }}
                />
            ),
        });
    };

    return (
        <Page>
            <PageGrid>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Text variant="headline" as="h1">
                        {translate('contracts.overviewPageTitle')}
                    </Text>

                    {!role.isAdmin() && role.hasWriteRights() ? (
                        <Button emphasis="high" onClick={openCreateModal}>
                            {translate('contracts.createTitle')}
                        </Button>
                    ) : null}
                </Box>

                <ContractOverviewTableControls />

                <ContractOverviewTable />
            </PageGrid>
        </Page>
    );
};
