import { useOfferPaginationTotals } from '../useOfferPaginationTotals';

import { fuelTabToOfferStateMap } from './fuelTabToOfferStateMap';

export const useFuelOfferPaginationTotals = ({ searchQuery, supplierIds, customerIds }) => {
    return useOfferPaginationTotals({
        searchQuery,
        supplierIds,
        customerIds,
        type: 'FUEL',
        offerTabs: ['enquired', 'offered', 'ordered', 'acknowledged', 'confirmed', 'delivered', 'canceled', 'closed'],
        tapToOfferStateMap: fuelTabToOfferStateMap,
    });
};
