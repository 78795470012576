import { formatDate } from './formatDate.helper';
import { translate } from './translate.helper';

const convertDateToUtc = (date) => {
    return new Date(
        Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        )
    );
};

const daysUntil = ({ date }) => {
    const dateUtc = convertDateToUtc(new Date(date));
    const nowUtc = convertDateToUtc(new Date());

    const amountMs = dateUtc.getTime() - nowUtc.getTime();

    const oneDayInMillis = 86400000;
    const amountDays = Math.ceil(amountMs / oneDayInMillis);

    if (amountDays === 1) {
        return translate('order.daysUntilTomorrow');
    }

    if (amountDays > 1) {
        return translate('order.daysuntil', {
            days: amountDays,
        });
    }

    if (amountDays === 0) {
        return translate('order.daystoday');
    }

    return '';
};

export const formatDateDelivery = ({ date }) => {
    if (!date) {
        return null;
    }

    return `
    ${formatDate({ date, timeZone: 'UTC' })} ${daysUntil({
        date,
        timeZone: 'UTC',
    })} 
    `;
};
