import { Text } from '../../../../cdl/Text/Text';
import { useEnums } from '../../../../common/hooks/useEnums';

const isNotEmpty = (value: string) => {
    return value?.length > 0;
};

interface FuelCategoryHeadingProps {
    productCategory: string;
    sulphurContent: string;
    viscosity?: number;
}

export const FuelCategoryHeading = ({ productCategory, sulphurContent, viscosity }: FuelCategoryHeadingProps) => {
    const { getHumanReadableValue } = useEnums();

    const modifiedCategory = [
        getHumanReadableValue('productGroup', productCategory),
        getHumanReadableValue('sulphurContent', sulphurContent),
        viscosity?.toString(),
    ];

    const formattedCategory = modifiedCategory.filter(isNotEmpty).join(' - ');

    return (
        <Text variant="body" fontWeight="600">
            {formattedCategory}
        </Text>
    );
};
