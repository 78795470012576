import PropTypes from 'prop-types';

import { FuelShowOfferProductTable } from '../../../common/FuelShowOfferProductTable/FuelShowOfferProductTable';
import { InitialEnquiryPopup } from '../common/components/InitialEnquiryPopup';
import { useInitialEnquiry } from '../common/hooks/useInitialEnquiry';

export const FuelInitialEnquiryPopup = ({ isOpen, onDismiss, offerId }) => {
    const initialEnquiryQuery = useInitialEnquiry(offerId);
    const offer = initialEnquiryQuery.data;

    return (
        <InitialEnquiryPopup
            isLoading={initialEnquiryQuery.isPending}
            offer={offer}
            isOpen={isOpen}
            onDismiss={onDismiss}
        >
            <FuelShowOfferProductTable offer={offer} showPrices={false} showOnlyInitialValues />
        </InitialEnquiryPopup>
    );
};

FuelInitialEnquiryPopup.propTypes = {
    offerId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    onDismiss: PropTypes.func,
};
