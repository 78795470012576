/**
 * Raise an error with the given message where only expressions are allowed.
 * Will also narrow down the type accordingly.
 *
 * example:
 *
 *    const value = someFunction() || raise("Expected a value");
 *
 */
export const raise = (message: string): never => {
    throw new Error(message);
};
