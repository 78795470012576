import { FormikErrors, setIn } from 'formik';

import { translate } from '../../../../../common/helpers/translate.helper';
import { FormikApprovalSettingsState } from '../MultipleApproversSettingsForm';

export function validateMissingApprovalLimits(
    errors: FormikErrors<FormikApprovalSettingsState>,
    values: FormikApprovalSettingsState
): FormikErrors<FormikApprovalSettingsState> {
    values.additionalApprovers.forEach((approvalLimit, approvalLimitIndex) => {
        if (approvalLimit.approvalLimit !== '') {
            return;
        }

        const hasApprovers = approvalLimit.approvers.some((it) => it.emailAddress !== '');

        if (hasApprovers) {
            errors = setIn(
                errors,
                `additionalApprovers[${approvalLimitIndex}].approvalLimit`,
                translate('multipleApproverSettings.errors.missingApprovalLimit')
            );
        }
    });

    return errors;
}
