import { AblyService } from '../services/AblyService';

export const useAbly = () => {
    const subscribe = (topic, handler) => {
        AblyService.subscribe(topic, handler);
    };
    const unsubscribe = (topic) => {
        AblyService.unsubscribe(topic);
    };

    return { subscribe, unsubscribe };
};
