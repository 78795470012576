import styled from 'styled-components';

import { Box, BoxProps } from '../../common/ui/Box';
import { get } from '../../common/ui/get';

interface CalloutWrapperProps
    extends Pick<BoxProps, 'backgroundColor' | 'borderColor' | 'borderWidth' | 'borderRadius'> {
    isWide: boolean;
}

export const CalloutWrapper = styled(Box)<CalloutWrapperProps>`
    width: 100%;
    min-width: 391px;
    position: relative;
    display: flex;
    flex-direction: ${({ isWide }) => (isWide ? 'row' : 'column')};
    align-items: ${({ isWide }) => (isWide ? 'center' : 'flex-start')};
    justify-content: ${({ isWide }) => (isWide ? 'space-between' : 'flex-start')};
    row-gap: ${get('space.4')}px;
    padding: ${get('space.5')}px;
    border-style: solid;
`;
