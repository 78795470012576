import { ReactNode } from 'react';
import { OptionProps } from 'react-select';

import { Text } from '../../../cdl/Text/Text';
import { IconCheck } from '../../../common/icons/cdl/Check';
import { Box } from '../../../common/ui/Box';
import { theme } from '../../../common/ui/theme';
import { KeyPortHighlight } from '../../../keyport/KeyPortHighlight';
import { PortOptionWithoutScheduleType } from '../PortDetailSelectWithSchedule';

import { CustomPortOption } from './CustomPortOption';

interface PortOptionWithoutScheduleProps extends Omit<OptionProps, 'children'> {
    trailingVisual?: ReactNode;
    data: PortOptionWithoutScheduleType;
}

export const PortOptionWithoutSchedule = (props: PortOptionWithoutScheduleProps) => {
    const portCountryWithLocode = `${props.data.port.country.name} (${props.data.port.locCode})`;

    return (
        <CustomPortOption {...props}>
            <Box gridColumn="2/3" gridRow="1/3">
                <Box display="flex" alignItems="center" overflow="hidden">
                    <Text
                        variant="small"
                        fontWeight="semiBold"
                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {props.label}
                    </Text>
                    &nbsp;
                    <KeyPortHighlight portId={props.data.port.id} vesselId={props.data.vesselId} />
                </Box>

                <Box display="flex" alignItems="center" overflow="hidden" gridColumn="1/2" gridRow="1/2">
                    <Text
                        variant="extraSmall"
                        color="foreground.muted"
                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {portCountryWithLocode}
                    </Text>
                </Box>
            </Box>

            <Box gridColumn="3/4">
                {props.isSelected ? (
                    <IconCheck height={24} width={24} style={{ color: theme.colors.accent.foreground }} />
                ) : null}
            </Box>
        </CustomPortOption>
    );
};
