import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { InfoPopover } from '../../common/InfoPopover/InfoPopover';
import { LocationStateProvider } from '../../common/LocationState/LocationStateProvider';
import { Page } from '../../common/Page/Page';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';

import { CustomerFilterActions } from './components/CustomerFilterActions';
import { CustomersOverviewTable } from './components/CustomersOverviewTable';

export interface CustomersOverviewStateConfig {
    page: number;
    supplierIds: string[];
}

export const CustomersOverviewPage = () => {
    useDocumentTitle(translate('page.customers'));

    const stateConfig: CustomersOverviewStateConfig = {
        page: 0,
        supplierIds: [],
    };

    return (
        <LocationStateProvider stateConfig={stateConfig}>
            <Page>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center" gap={4}>
                        <Text variant="headline" as="h1">
                            {translate('assignments.customers.subheadline')}
                        </Text>

                        <InfoPopover width="250px" side="right">
                            <Text
                                variant="extraSmall"
                                dangerouslySetInnerHTML={{
                                    __html: translate('assignments.customers.infoPopover'),
                                }}
                            ></Text>
                        </InfoPopover>
                    </Flex>
                    <CustomerFilterActions />
                </Box>

                <CustomersOverviewTable />
            </Page>
        </LocationStateProvider>
    );
};
