import { Text } from '../../../cdl/Text/Text';
import { Error } from '../../../common/Error/Error';
import { FuelStockGroup } from '../../../common/FuelStockGroup/FuelStockGroup';
import { sortFuelCategory } from '../../../common/helpers/sortByCategory.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Section } from '../../../common/Section/Section';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { Flex } from '../../../common/ui/Flex';

import { EditVesselConsumptionPresets } from './components/EditVesselConsumptionPresets';
import { FuelStockGroupStack } from './components/FuelStockGroupStack';
import { FuelStockInfoIcon } from './components/FuelStockInfoIcon';
import { useFuelStocks } from './hooks/useFuelStocks';
import { useReachInformationCategories } from './hooks/useReachInformationCategories';

export const FuelStocks = ({ vesselId, customerId }: { vesselId: string; customerId: string }) => {
    const role = useRole();

    const { groupedFuelStocks, leadingFuelStock, hasFuelStocks, isPending, isError } = useFuelStocks(vesselId);
    const reachInformationCategories = useReachInformationCategories(vesselId);

    const cardTitle = translate('vessel.fuelStocks.headline');

    if (isPending) {
        return (
            <Section title={cardTitle}>
                <LoadingIndicator />
            </Section>
        );
    }

    if (isError) {
        return (
            <Section title={cardTitle}>
                <Error />
            </Section>
        );
    }

    if (!hasFuelStocks) {
        return (
            <Section title={cardTitle}>
                <Flex alignItems="center" justifyContent="center" height="100%">
                    <TableEmptyState text={translate('vessel.fuelStocks.emptyList')} />
                </Flex>
            </Section>
        );
    }

    return (
        <Section
            title={
                <Flex alignItems="center">
                    <Text variant="subtitle1" marginRight={2}>
                        {cardTitle}
                    </Text>
                    <FuelStockInfoIcon dateLastUpdated={leadingFuelStock.dateMeasured} />
                </Flex>
            }
            actions={
                role.hasAnyAdminRights() ? (
                    <EditVesselConsumptionPresets vesselId={vesselId} customerId={customerId} />
                ) : null
            }
        >
            {Object.keys(groupedFuelStocks)
                .sort(sortFuelCategory)
                .map((key) => (
                    <FuelStockGroupStack key={key}>
                        <FuelStockGroup
                            fuelStocks={groupedFuelStocks[key]}
                            reachInformation={reachInformationCategories ? reachInformationCategories[key] : undefined}
                        />
                    </FuelStockGroupStack>
                ))}
        </Section>
    );
};
