import PropTypes from 'prop-types';

import { SelectBox } from '../../common/SelectBox/SelectBox';

import { ContractProductBoxContent } from './ContractProductBoxContent';

export const ContractProductBox = ({ selected, onClick, contractProduct, hasPlattsCredentials, customerId }) => {
    return (
        <SelectBox selected={selected} onClick={onClick} height="250px" padding="0">
            <ContractProductBoxContent
                contractProduct={contractProduct}
                hasPlattsCredentials={hasPlattsCredentials}
                customerId={customerId}
            />
        </SelectBox>
    );
};

ContractProductBox.propTypes = {
    selected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    contractProduct: PropTypes.object.isRequired,
    hasPlattsCredentials: PropTypes.bool.isRequired,
    customerId: PropTypes.string.isRequired,
};
