import { GlobalPricesPortColor, GlobalPricesPortColorLevel } from '../enums/GlobalPricesPortColor';

export const getMarkerColor = (level: GlobalPricesPortColorLevel) => {
    switch (level) {
        case 'LEVEL_1':
            return GlobalPricesPortColor.LEVEL_1;
        case 'LEVEL_2':
            return GlobalPricesPortColor.LEVEL_2;
        case 'LEVEL_3':
            return GlobalPricesPortColor.LEVEL_3;
        case 'LEVEL_4':
            return GlobalPricesPortColor.LEVEL_4;
        case 'LEVEL_5':
            return GlobalPricesPortColor.LEVEL_5;

        default:
            return GlobalPricesPortColor.LEVEL_1;
    }
};
