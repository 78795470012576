import { Box } from '../../../../common/ui/Box';

interface SupplierGroupLogoProps {
    supplierName: string;
    imageUrl: string;
}

export const SupplierGroupLogo = ({ supplierName, imageUrl }: SupplierGroupLogoProps) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="center" width="48px" height="48px">
            <img alt={supplierName} style={{ maxWidth: '48px', maxHeight: '48px' }} src={imageUrl} />
        </Box>
    );
};
