import React, { ComponentPropsWithoutRef, forwardRef } from 'react';

import { Tooltip } from '../Tooltip/Tooltip';

import { ButtonWrapper } from './components/ButtonWrapper';
import { getButtonSize } from './util/getButtonSize';
import { getIconSize } from './util/getIconSize';

export interface IconButtonProps extends ComponentPropsWithoutRef<'button'> {
    icon: React.ComponentType<{ width?: number; height?: number }>;
    emphasis?: 'medium' | 'none' | 'transparent';
    size?: 'small' | 'medium' | 'large';
    destructive?: boolean;
    tooltipLabel?: string;
}

export const IconButton = forwardRef(
    (
        {
            icon,
            emphasis = 'none',
            size = 'medium',
            destructive = false,
            tooltipLabel = undefined,
            ...rest
        }: IconButtonProps,
        ref: React.Ref<HTMLButtonElement>
    ) => {
        const IconComponent = icon;
        const iconSize = getIconSize(size);

        const IconButtonContent = (
            <ButtonWrapper ref={ref} size={getButtonSize(size)} destructive={destructive} emphasis={emphasis} {...rest}>
                <IconComponent width={iconSize} height={iconSize} />
            </ButtonWrapper>
        );

        if (tooltipLabel !== undefined) {
            return <Tooltip label={tooltipLabel}>{IconButtonContent}</Tooltip>;
        }

        return IconButtonContent;
    }
);

IconButton.displayName = 'IconButton';
