import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { getVessel } from '../common/api/clients/gateway.api';
import { getVesselList } from '../common/api/clients/vessel.api';
import { AsyncSearchSelect } from '../common/form-elements/AsyncSearchSelect/AsyncSearchSelect';
import { useFormData } from '../common/form-elements/Form/useFormData';
import { Label } from '../common/form-elements/Label/Label';
import { formatVessel } from '../common/helpers/formatVessel.helper';
import { translate } from '../common/helpers/translate.helper';
import { useProductContext } from '../common/hooks/useProductContext';
import { useCustomerIdList } from '../keyport/useCustomerIdList';

export function VesselSelect({ vesselId, onChange, groupId, context: selectedContext, ...props }) {
    const [selectedValue, setSelectedValue] = useState(null);
    const [isDirty, setIsDirty] = useState(false);

    const { context } = useProductContext();

    const { data: customerIds = [] } = useCustomerIdList({
        groupId,
        type: selectedContext || context,
    });

    const loadVesselsBySearchQuery = (searchQuery) => {
        return new Promise((resolve) => {
            getVesselList({
                searchQuery,
                customerIds,
                active: true,
            })
                .then((response) => {
                    return response.map((vessel) => ({
                        label: formatVessel({ vessel }),
                        value: {
                            id: vessel.id,
                            name: vessel.name,
                            imo: vessel.imo,
                            customerId: vessel.customerId,
                        },
                    }));
                })

                .then((vessels) => resolve(vessels));
        });
    };

    const loadVesselById = (vesselId) => {
        return new Promise((resolve) => {
            getVessel(vesselId)
                .then((vessel) => {
                    return {
                        label: formatVessel({ vessel }),
                        value: {
                            id: vessel.id,
                            name: vessel.name,
                            imo: vessel.imo,
                            customerId: vessel.customerId,
                        },
                    };
                })

                .then((vessel) => resolve(vessel));
        });
    };

    useEffect(() => {
        let mounted = true;
        if (vesselId) {
            loadVesselById(vesselId).then((result) => {
                if (mounted) {
                    setSelectedValue(result);
                }
            });
        }

        return () => (mounted = false);
    }, [vesselId]);

    const updateSelectedValue = (option) => {
        setSelectedValue(option);
        if (onChange) {
            onChange(option.value);
        }
    };

    return (
        <Label label={translate('keyPorts.vessel')} required={props.required}>
            <AsyncSearchSelect
                placeholder={translate('keyPorts.placeholder.searchVessels')}
                values={selectedValue}
                onChange={updateSelectedValue}
                loadOptions={loadVesselsBySearchQuery}
                className={isDirty ? 'dirty' : ''}
                onBlur={() => setIsDirty(true)}
                key={customerIds}
                {...props}
            />
        </Label>
    );
}

VesselSelect.propTypes = {
    vesselId: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    groupId: PropTypes.string,
    context: PropTypes.string,
};

export const FormVesselSelect = ({ dataPath, ...props }) => {
    const { value: vessel, onChange } = useFormData(dataPath, {
        default: null,
    });

    const onChangeHandle = (vessel) => {
        onChange({
            target: { value: vessel },
        });
    };

    return <VesselSelect vesselId={vessel?.id} onChange={onChangeHandle} {...props} />;
};

FormVesselSelect.propTypes = {
    dataPath: PropTypes.string,
    contextData: PropTypes.object,
};
