import PropTypes from 'prop-types';
import { useState } from 'react';

import { DrawerFormLayout } from '../cdl/Drawer/DrawerFormLayout';
import { Form } from '../common/form-elements/Form/Form';
import { FormVesselSelect } from '../vessel/VesselSelect';

export const VesselLimitationForm = ({ title, data: oldLimitation, onSubmit: onSubmitCallback, groupId, context }) => {
    const [isValid, setValid] = useState(!!oldLimitation || false);

    const onSubmit = ({ data, event }) => {
        event.preventDefault();
        onSubmitCallback({
            data: data.vessel,
            oldVessel: oldLimitation?.vessel,
        });
    };

    const handleChange = ({ data }) => {
        if (data) {
            setValid(true);
        }
    };

    return (
        <Form height="100%" data={oldLimitation} onChange={handleChange} onSubmit={onSubmit}>
            <DrawerFormLayout title={title} isSubmitButtonDisabled={!isValid}>
                <FormVesselSelect dataPath="vessel" groupId={groupId} context={context} isDirty required />
            </DrawerFormLayout>
        </Form>
    );
};

VesselLimitationForm.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    groupId: PropTypes.string,
    context: PropTypes.string,
};
