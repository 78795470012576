import styled from 'styled-components';

import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { translate } from '../../common/helpers/translate.helper';
import { get } from '../../common/ui/get';

const Indicator = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${get('colors.accent.emphasis')};
`;

export const UnreadIndicator = () => {
    return (
        <Tooltip label={translate('global.unread')} showArrow align="center" side="bottom">
            <Indicator />
        </Tooltip>
    );
};
