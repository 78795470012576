import { FuelPricingCodeFrequency } from './FuelPricingCodeFrequency';
import { PaymentTermReference } from './PaymentTermReference';
import { ProductMessage } from './ProductMessage';
import { SupplierMessage } from './SupplierMessage';

export enum PricingDateType {
    MONTHLY_AVERAGE = 'MONTHLY_AVERAGE',
    ORDER_DATE = 'ORDER_DATE',
    DELIVERY_DATE = 'DELIVERY_DATE',
    ETA = 'ETA',
}

enum PricingDateMonthType {
    DELIVERY_DATE = 'DELIVERY_DATE',
    ETA = 'ETA',
    ORDER_DATE = 'ORDER_DATE',
}

enum ContractBasis {
    DELIVERED = 'DELIVERED',
    EX_WHARF = 'EX_WHARF',
}

export interface PricingCodeMessage {
    frequency: FuelPricingCodeFrequency;
    code: string;
}

export interface ContractProductMessage {
    productId: string;
    pricingCode: PricingCodeMessage;
    priceDifference: { value: number; currency: 'USD' };
    product: ProductMessage;
}

export interface ProductGroupMessage {
    products: ContractProductMessage[];
    minStemSize: number;
    contractVolume: number;
    usedContractVolume: number;
    minContractVolume?: number;
    maxContractVolume?: number;
}

interface ContractTenderMessage {
    enabled: boolean;
    receiverEmailAddresses: string[];
}

export interface ContractMessage {
    id: string;
    productGroups: ProductGroupMessage[];
    supplier: SupplierMessage;
    reference: string;
    startDate: string;
    endDate: string;
    pricingDate: PricingDateType;
    contractBasis: ContractBasis;
    paymentTermReference: PaymentTermReference;
    paymentTermReferenceDays?: number;
    pricingDateDays: number;
    pricingDateMonthType: PricingDateMonthType;
    tender: ContractTenderMessage;
}
