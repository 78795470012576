import { Fragment } from 'react';

import { ConsumptionCalculationBase } from '../../../../types/ConumptionCalculationBase';
import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../../common/helpers/translate.helper';
import { MiddleDotSeparator } from '../../../common/Separators/MiddleDotSeparator';
import { Box } from '../../../common/ui/Box';

import { FormikCategoryPresetState } from './EditPresetsForm';

export const PresetSummary = ({ preset }: { preset: FormikCategoryPresetState }) => {
    const { consumptionCalculationBase, excludePortCalls, fixedAverageDailyConsumption } = preset;
    const readableCalculationBase = consumptionCalculationBase
        ? translatedCalculationBase(consumptionCalculationBase)
        : null;

    const entries = [
        fixedAverageDailyConsumption ? `${fixedAverageDailyConsumption} L` : null,
        excludePortCalls ? translate('consumption.editPresets.excluded') : null,
        consumptionCalculationBase ? readableCalculationBase : null,
    ].filter(Boolean);

    return (
        <Box display="flex" gap={1}>
            {entries.map((entry, index) => (
                <Fragment key={index}>
                    <Text variant="body">{entry}</Text>
                    {index < entries.length - 1 ? <MiddleDotSeparator /> : null}
                </Fragment>
            ))}
        </Box>
    );
};

const translatedCalculationBase = (consumptionCalculationBase: ConsumptionCalculationBase) => {
    switch (consumptionCalculationBase) {
        case ConsumptionCalculationBase.STATIC:
            return translate(`consumption.editPresets.calculationBase.STATIC`);
        case ConsumptionCalculationBase.DYNAMIC:
            return translate(`consumption.editPresets.calculationBase.DYNAMIC`);
    }
};
