import { MoneyMessage } from '../../../../../types/MoneyMessage';

import { FormattedPrice } from './FormattedPrice';

interface PriceDifferenceProps {
    priceDifference: MoneyMessage;
}

export const PriceDifference = ({ priceDifference }: PriceDifferenceProps) => {
    const priceMoneyMessage = { value: Math.abs(priceDifference.value), currency: priceDifference.currency };

    if (priceDifference.value > 0) {
        return <FormattedPrice price={priceMoneyMessage} attention trend="up" />;
    }

    if (priceDifference.value < 0) {
        return <FormattedPrice price={priceMoneyMessage} positive trend="down" />;
    }

    return <FormattedPrice price={priceMoneyMessage} />;
};
