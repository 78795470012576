import { ReactNode } from 'react';

import { CategoryPrice, SolutionSummary } from '../../../../../types/BunkerCaseResponse';
import { TableBuilder } from '../../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../../cdl/TableBuilder/TableBuilderColumn';
import { formatNumber } from '../../../../common/helpers/formatNumber.helper';
import { translate } from '../../../../common/helpers/translate.helper';

import { BunkerSolutionWarnings } from './components/BunkerSolutionWarnings';
import { SolutionRankIcon } from './components/SolutionRankIcon';

function formatFullNumber(number: number) {
    return formatNumber({
        number,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
}

interface BunkerSolutionsTableProps {
    vesselId: string;
    caseNumber: string;
    solutionNumber: string;
    solutions: SolutionSummary[];
    onSolutionNumberSelect: (solutionNumber: string) => void;
}

export const BunkerSolutionsTable = (props: BunkerSolutionsTableProps) => {
    const activeSolutionIndex = props.solutions.findIndex((it) => it.solutionNumber === props.solutionNumber);

    const availableProductCategories = props.solutions.reduce((acc, solution) => {
        solution.averageCategoryPrices.forEach((categoryPrice: CategoryPrice) => {
            acc.set(`${categoryPrice.productCategory}-${categoryPrice.sulphurContent}`, {
                productCategory: categoryPrice.productCategory,
                sulphurContent: categoryPrice.sulphurContent,
            });
        });

        return acc;
    }, new Map<String, { productCategory: string; sulphurContent: string }>());

    const actualColumns: ReactNode[] = [];

    availableProductCategories.forEach((value, key) => {
        const columnHeader = `${value.productCategory} ${value.sulphurContent}`;

        actualColumns.push(
            <TableBuilderColumn<SolutionSummary> key={key.toString()} header={`${columnHeader} ($)`} numeric>
                {(row) => {
                    const averageCategoryPrice = row.averageCategoryPrices.find(
                        (it) =>
                            it.productCategory === value.productCategory && it.sulphurContent === value.sulphurContent
                    );

                    return averageCategoryPrice ? formatFullNumber(averageCategoryPrice.price.value) : '-';
                }}
            </TableBuilderColumn>
        );
    });

    return (
        <TableBuilder
            data={props.solutions}
            onRowSelect={(solutionSummary) => {
                props.onSolutionNumberSelect(solutionSummary.solutionNumber);
            }}
            activeIndex={activeSolutionIndex}
        >
            <TableBuilderColumn<SolutionSummary> header={translate('prePlanning.solutionsSummary.rank')} width="40px">
                {(row) => <SolutionRankIcon rank={row.rank} />}
            </TableBuilderColumn>

            <TableBuilderColumn<SolutionSummary> header={translate('prePlanning.solutionsSummary.fuelPrice')} numeric>
                {(row) =>
                    row.solutionCosts.fuelCost.value > 0 ? formatFullNumber(row.solutionCosts.fuelCost.value) : '-'
                }
            </TableBuilderColumn>
            <TableBuilderColumn<SolutionSummary> header={translate('prePlanning.solutionsSummary.canalCost')} numeric>
                {(row) =>
                    row.solutionCosts.canalCost.value > 0 ? formatFullNumber(row.solutionCosts.canalCost.value) : '-'
                }
            </TableBuilderColumn>
            <TableBuilderColumn<SolutionSummary> header={translate('prePlanning.solutionsSummary.portCost')} numeric>
                {(row) =>
                    row.solutionCosts.portCost.value > 0 ? formatFullNumber(row.solutionCosts.portCost.value) : '-'
                }
            </TableBuilderColumn>
            <TableBuilderColumn<SolutionSummary> header={translate('prePlanning.solutionsSummary.bargeCost')} numeric>
                {(row) =>
                    row.solutionCosts.bargeCost.value > 0 ? formatFullNumber(row.solutionCosts.bargeCost.value) : '-'
                }
            </TableBuilderColumn>
            <TableBuilderColumn<SolutionSummary>
                header={translate('prePlanning.solutionsSummary.tcePenaltyCost')}
                numeric
            >
                {(row) =>
                    row.solutionCosts.tcePenaltyCost.value
                        ? formatFullNumber(row.solutionCosts.tcePenaltyCost.value)
                        : '-'
                }
            </TableBuilderColumn>
            <TableBuilderColumn<SolutionSummary> header={translate('prePlanning.solutionsSummary.totalCost')} numeric>
                {(row) => (row.solutionTotal.value > 0 ? formatFullNumber(row.solutionTotal.value) : '-')}
            </TableBuilderColumn>
            <TableBuilderColumn<SolutionSummary>
                header={translate('prePlanning.solutionsSummary.differenceToBest')}
                numeric
            >
                {(row) => (row.bestSolutionDifference ? formatFullNumber(row.bestSolutionDifference.value) : '-')}
            </TableBuilderColumn>
            {actualColumns}
            <TableBuilderColumn<SolutionSummary> header={translate('prePlanning.solutionsSummary.warnings')}>
                {(row) => <BunkerSolutionWarnings warnings={row.warnings} />}
            </TableBuilderColumn>
        </TableBuilder>
    );
};
