import moment from 'moment';

import { CalendarRangePreset } from '../../../../cdl/DateRangePicker/types/CalendarRangePreset';
import { RangeDirection } from '../../../../cdl/DateRangePicker/types/RangeDirection';
import { translate } from '../../../../common/helpers/translate.helper';

export function generateCustomerReportDatePresets(): CalendarRangePreset[] {
    const presets: CalendarRangePreset[] = [];

    // Get the current year and quarter
    const currentYear = moment().year();
    const currentQuarter = Math.ceil(moment().month() / 3);
    const currentMonth = moment().month();

    // Generate the last year preset
    const lastYearStartDate = moment()
        .year(currentYear - 1)
        .startOf('year')
        .toDate();
    const lastYearEndDate = moment()
        .year(currentYear - 1)
        .endOf('year')
        .toDate();

    presets.push({
        label: translate('customergroup.lubesCustomerReport.presets.lastYear', {
            year: (currentYear - 1).toString(),
        }),
        range: [lastYearStartDate, lastYearEndDate],
        direction: RangeDirection.Backward,
    });

    // Generate the current year preset
    const currentYearStartDate = moment().year(currentYear).startOf('year').toDate();
    const currentDate = moment().endOf('day').toDate();

    presets.push({
        label: translate('customergroup.lubesCustomerReport.presets.currentYear', {
            year: currentYear.toString(),
        }),
        range: [currentYearStartDate, currentDate],
        direction: RangeDirection.Backward,
    });

    // Generate quarters for the current year
    for (let quarter = 1; quarter <= currentQuarter; quarter++) {
        const startDate = moment().quarter(quarter).startOf('quarter').toDate();
        const endDate = moment().quarter(quarter).endOf('quarter').toDate();
        presets.push({
            label: `Q${quarter} ${currentYear}`,
            range: [startDate, endDate],
            direction: RangeDirection.Backward,
        });
    }

    // Generate the last month preset
    const lastMonthStartDate = moment()
        .month(currentMonth - 1)
        .startOf('month')
        .toDate();
    const lastMonthEndDate = moment()
        .month(currentMonth - 1)
        .endOf('month')
        .toDate();
    presets.push({
        label: translate('customergroup.lubesCustomerReport.presets.lastMonth'),
        range: [lastMonthStartDate, lastMonthEndDate],
        direction: RangeDirection.Backward,
    });

    return presets;
}
