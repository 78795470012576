import PropTypes from 'prop-types';

import { LocationStateProvider } from '../../../common/LocationState/LocationStateProvider';
import { sortValueHelpers } from '../../../common/LocationState/sortValueHelpers';

import { selectSortOption } from './utils/selectSortOption';

export const FuelOrderOverviewState = ({ children }) => {
    const stateConfig = {
        searchQuery: '',
        customerIds: [],
        supplierIds: [],
        tab: 'enquired',
        page: 0,
        sortValue: {
            defaultValue: selectSortOption('enquired'),
            deserializer: sortValueHelpers.deserializer,
            serializer: sortValueHelpers.serializer,
        },
    };

    return <LocationStateProvider stateConfig={stateConfig}>{children}</LocationStateProvider>;
};

FuelOrderOverviewState.propTypes = {
    children: PropTypes.node,
};
