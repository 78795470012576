import css from '@styled-system/css';
import styled from 'styled-components';

import { Box } from '../../../../common/ui/Box';

export const FuelStockGroupStack = styled(Box)(
    css({
        marginBottom: 7,

        '&:last-child': {
            marginBottom: 0,
        },
    })
);
