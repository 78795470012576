import { CustomerSettingsMessage } from '../../../../types/CustomerSettingsMessage';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { Switch } from '../../../cdl/Switch/Switch';
import { TextButton } from '../../../common/buttons/TextButton';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';
import { SettingsSection } from '../../SettingsSection';

import { MultipleApproverSettingsSummary } from './MultipleApproversSettingsForm/components/MultipleApproverSettingsSummary';
import { MultipleApproversSettingsForm } from './MultipleApproversSettingsForm/MultipleApproversSettingsForm';
import { useCustomerSettingsEdit } from './useCustomerSettingsEdit';

interface FuelCustomerApprovalSettingsProps {
    customerSettings: CustomerSettingsMessage;
    customerId: string;
}

export const FuelCustomerApprovalSettings = ({ customerSettings, customerId }: FuelCustomerApprovalSettingsProps) => {
    const { push, pop } = useDrawer();

    const updateCustomerSettingsMutation = useCustomerSettingsEdit();

    const onSubmit = (customerSettings: CustomerSettingsMessage) => {
        updateCustomerSettingsMutation.mutate(
            { id: customerId, settings: customerSettings },
            { onSuccess: () => pop() }
        );
    };

    const openApprovalSettingsModal = () => {
        push({
            content: <MultipleApproversSettingsForm customerSettings={customerSettings} onSubmit={onSubmit} />,
        });
    };

    const handleApprovalSettingsToggle = (checked: boolean) => {
        if (!customerSettings.approvalSettings.defaultControllerEmailAddress) {
            openApprovalSettingsModal();
        } else {
            const newCustomerSettings = customerSettings;
            newCustomerSettings.approvalSettings.activated = checked;
            updateCustomerSettingsMutation.mutate({
                id: customerId,
                settings: newCustomerSettings,
            });
        }
    };

    return (
        <SettingsSection label={translate('approvalSettings.order')}>
            <Switch
                checked={customerSettings.approvalSettings.activated}
                onChange={handleApprovalSettingsToggle}
                label={translate('fuelApprovalSettings.activatedLabel')}
                description={
                    <div>
                        {customerSettings.approvalSettings.activated ? (
                            <MultipleApproverSettingsSummary approvalSettings={customerSettings.approvalSettings} />
                        ) : null}
                        {customerSettings.approvalSettings.defaultControllerEmailAddress ? (
                            <Box marginTop={customerSettings.approvalSettings.activated ? 4 : 0}>
                                <TextButton onClick={openApprovalSettingsModal}>
                                    {translate('multipleApproverSettings.editSettings')}
                                </TextButton>
                            </Box>
                        ) : null}
                    </div>
                }
            />
        </SettingsSection>
    );
};
