import { Formik, FormikErrors } from 'formik';

import { CustomerGroupMessage } from '../../../../../types/CustomerGroupMessage';
import { CustomerGroupBaseForm } from '../../../../admin/customergroup/components/CustomerGroupBaseForm';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { translate } from '../../../../common/helpers/translate.helper';
import { useCustomerGroupUpdate } from '../hooks/useCustomerGroupUpdate';

interface FormikEditCustomerGroupState {
    name: string;
    email: string;
    phone: string;
    fax: string;
    address: {
        companyName: string;
        street: string;
        streetNumber: string;
        zipcode: string;
        city: string;
        country: string;
    };
    testCompany: boolean;
    closelinkLite: boolean;
    gtcUrl: string;
}

interface EditCustomerGroupFormProps {
    onDismiss: () => void;
    initialValues: CustomerGroupMessage;
}

export const EditCustomerGroupForm = ({ onDismiss, initialValues }: EditCustomerGroupFormProps) => {
    const customerGroupUpdateMutation = useCustomerGroupUpdate();
    const { addErrorToast } = useToasts();

    const onSubmit = async (values: FormikEditCustomerGroupState) => {
        try {
            await customerGroupUpdateMutation.mutateAsync({
                ...initialValues,
                name: values.name,
                email: values.email || undefined,
                phone: values.phone || undefined,
                fax: values.fax || undefined,
                address: {
                    companyName: values.address.companyName || undefined,
                    street: values.address.street || undefined,
                    streetNumber: values.address.streetNumber || undefined,
                    zipcode: values.address.zipcode || undefined,
                    city: values.address.city || undefined,
                    country: values.address.country || undefined,
                },
                testCompany: values.testCompany,
                closelinkLite: values.closelinkLite,
                gtcUrl: values.gtcUrl || undefined,
            });

            onDismiss();
        } catch {
            addErrorToast(translate('global.genericError'));
        }
    };

    const initialFormikValues: FormikEditCustomerGroupState = {
        name: initialValues.name,
        email: initialValues.email ?? '',
        phone: initialValues.phone ?? '',
        fax: initialValues.fax ?? '',
        address: {
            companyName: initialValues.address?.companyName ?? '',
            street: initialValues.address?.street ?? '',
            streetNumber: initialValues.address?.streetNumber ?? '',
            zipcode: initialValues.address?.zipcode ?? '',
            city: initialValues.address?.city ?? '',
            country: initialValues.address?.country ?? '',
        },
        testCompany: initialValues.testCompany,
        closelinkLite: initialValues.closelinkLite,
        gtcUrl: initialValues.gtcUrl ?? '',
    };

    const validate = (values: FormikEditCustomerGroupState) => {
        const errors: FormikErrors<FormikEditCustomerGroupState> = {};

        if (!values.name || values.name.trim().length === 0) {
            errors.name = translate('company.error.name');
        }

        return errors;
    };

    return (
        <Formik initialValues={initialFormikValues} onSubmit={onSubmit} validate={validate} validateOnMount>
            <CustomerGroupBaseForm title={translate('customergroup.edit')} />
        </Formik>
    );
};
