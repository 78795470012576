import { ForwardedRef, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { translate } from '../common/helpers/translate.helper';
import { Characters } from '../common/helpers/unicode.helper';

const specialBackground = css`
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
        45deg,
        hsl(19deg 100% 50%) 0%,
        hsl(27deg 100% 50%) 29%,
        hsl(34deg 100% 50%) 43%,
        hsl(41deg 100% 50%) 57%,
        hsl(47deg 100% 50%) 71%,
        hsl(53deg 100% 50%) 100%
    );
`;

const Wrapper = styled.div`
    display: inline-block;
    font-size: inherit;
    background-clip: text;
    color: ${({ color }) => (color ? color : '#ff9900')};
    ${({ color }) => (color ? null : specialBackground)}
`;

interface KeyPortIndicatorProps {
    color?: string;
}

export const KeyPortIndicator = forwardRef(function KeyPortIndicatorWithRef(
    { color }: KeyPortIndicatorProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const title = translate('keyPorts.highlightTooltip');

    return (
        <Wrapper ref={ref} color={color} title={title}>
            {Characters.BLACK_FOUR_POINTED_STAR}
        </Wrapper>
    );
});
