import { ReactNode } from 'react';

import { Text } from '../../cdl/Text/Text';
import { Box } from '../ui/Box';
import { Flex } from '../ui/Flex';

interface SectionProps {
    // The Contents of section
    children: ReactNode;
    // The title of a section
    title?: ReactNode;
    // Actions of a section that are shown in the upper right corner
    actions?: ReactNode;
    // The background color of a section
    backgroundColor?: string;
    // Wether the section should take up the full height of its parent
    fullHeight?: boolean;
    // The horizontal padding inside the section's content
    paddingX?: number;
    // The vertical padding inside the section's content
    paddingY?: number;
    // The vertical top margin outside of the section
    marginTop?: number;
    // The vertical bottom margin outside of the section
    marginBottom?: number;
    position?: string;
    overflow?: string;
}

export const Section = (props: SectionProps) => {
    const {
        title,
        children,
        actions,
        backgroundColor = 'white',
        fullHeight = true,
        paddingX = 0,
        paddingY = 5,
        marginTop = 0,
        marginBottom = 0,
        overflow,
        position,
    } = props;

    return (
        <Flex
            backgroundColor={backgroundColor}
            height={fullHeight ? '100%' : 'auto'}
            flexDirection="column"
            marginTop={marginTop}
            marginBottom={marginBottom}
            position={position ? position : 'relative'}
            overflow={overflow}
        >
            {(title || actions) && (
                <Flex
                    paddingBottom="12px"
                    borderBottomStyle="solid"
                    borderBottomWidth="1px"
                    borderColor="border.muted"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {title && (
                        <Text variant="title" as="h2" margin={0}>
                            {title}
                        </Text>
                    )}
                    {actions && <Box>{actions}</Box>}
                </Flex>
            )}

            <Box paddingX={paddingX} paddingY={paddingY} height="100%" width="100%">
                {children}
            </Box>
        </Flex>
    );
};
