import { useField } from 'formik';

import { Text } from '../../../../cdl/Text/Text';
import { formatLubesPrice } from '../../../helpers/formatLubesPrice.helper';
import { FormikMoneyInput } from '../../../MoneyInput/FormikMoneyInput';

interface PriceDisplayProps {
    name: string;
    editable?: boolean;
}

export const PriceDisplay = ({ name, editable = true }: PriceDisplayProps) => {
    const [field] = useField(name);
    const item = field.value;

    if (!item.price && (!editable || item.deleted)) {
        return <Text variant="body">-</Text>;
    }

    if (!editable) {
        return <Text variant="body">{formatLubesPrice(item.price)}</Text>;
    }

    return (
        <FormikMoneyInput
            name={`${name}.price.value`}
            decimals={4}
            disabled={item.deleted}
            allowNegativeValue={false}
        />
    );
};
