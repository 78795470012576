import { ReactNode } from 'react';

import { TextButton } from '../../../common/buttons/TextButton';

import { Header } from './Header';

interface CollapsableHeaderProps {
    isOpen: boolean;
    onClick: () => void;
    title: ReactNode;
    description: ReactNode;
    expandActionText: (isOpen: boolean) => string;
}

export const CollapsableHeader = (props: CollapsableHeaderProps) => {
    return (
        <Header isOpen={props.isOpen} onClick={props.onClick}>
            {props.title}
            {props.description}
            <div style={{ gridArea: '1 / 2 / 3 / 3' }}>
                <TextButton type="button">{props.expandActionText(props.isOpen)}</TextButton>
            </div>
        </Header>
    );
};
