import { QueryClient } from '@tanstack/react-query';
import { AblyProvider } from 'ably/react';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { DrawerAppWrapper } from '../cdl/Drawer/DrawerAppWrapper';
import { ToastsProvider } from '../cdl/Toast/ToastsProvider';
import AppSettings from '../constants';

import { AngularDependenciesContextProvider } from './AngularDependenciesContext';
import { ReactQueryClientProvider } from './api/ReactQueryClientProvider';
import { useEnums } from './hooks/useEnums';
import { useKeyPorts } from './hooks/useKeyPorts';
import { client as launchDarklyClient } from './launchDarkly/launchDarklyClient';
import { AblyService } from './services/AblyService';
import { ThemeProvider } from './ThemeProvider';
import { useMagicBellUserConfig } from './Topbar/Notifications/useMagicBellUserConfig';

const queryClient = new QueryClient();

// Prefetch queries using hooks on app start.
const PrefetchQueries = () => {
    const { prefetch: prefetchKeyPorts } = useKeyPorts();
    const { prefetch: prefetchEnums } = useEnums();
    const { prefetch: prefetchMagicBellUserConfig } = useMagicBellUserConfig();

    useEffect(() => {
        prefetchKeyPorts();
        prefetchEnums();
        prefetchMagicBellUserConfig();
    }, [prefetchEnums, prefetchKeyPorts, prefetchMagicBellUserConfig]);

    return null;
};

export const AppProviders = ({ children, angularDependencies }) => {
    const ablyClient = AblyService.getClient();

    return (
        <AngularDependenciesContextProvider dependencies={angularDependencies}>
            <ReactQueryClientProvider queryClient={queryClient}>
                <PrefetchQueries />
                <ThemeProvider>
                    <DrawerAppWrapper>
                        {launchDarklyClient ? (
                            <LDProvider
                                clientSideID={AppSettings.launchDarklyClientSideId}
                                ldClient={launchDarklyClient}
                            >
                                {ablyClient ? <AblyProvider client={ablyClient}>{children}</AblyProvider> : children}
                            </LDProvider>
                        ) : (
                            children
                        )}
                    </DrawerAppWrapper>
                </ThemeProvider>
            </ReactQueryClientProvider>
            <ToastsProvider />
        </AngularDependenciesContextProvider>
    );
};

AppProviders.propTypes = {
    children: PropTypes.node,
    angularDependencies: PropTypes.object,
    connectToNetwork: PropTypes.bool,
};
