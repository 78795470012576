import { useQuery } from '@tanstack/react-query';

import { ProductContext } from '../../../types/ProductContext';
import { getDemandInformation } from '../../common/api/clients/demand.api';
import { queryKeys } from '../../common/api/queryKeys';
import { useRole } from '../../common/hooks/useRole';
import { SortingKey } from '../components/SortingKey';

export const useLubesDemandInformation = (sortingKey: SortingKey, groupId?: string) => {
    const role = useRole();

    return useQuery({
        queryKey: queryKeys.demandInformation(sortingKey, ProductContext.LUBES, false, groupId),
        queryFn: () => getDemandInformation(sortingKey, ProductContext.LUBES, false, groupId),
        enabled: !role.isAdmin() || groupId !== undefined,
    });
};
