import styled from 'styled-components';

export const IconButton = styled.button`
    width: 16px;
    height: 16px;
    background: transparent;
    color: inherit;
    border: none;
    padding: 0;

    &:hover {
        background: transparent;
    }
`;
