import { Button } from '../../../cdl/Button/Button';
import { Callout } from '../../../cdl/Callout/Callout';
import { translate } from '../../../common/helpers/translate.helper';
import { IconDiscount2 } from '../../../common/icons/cdl/Discount2';

interface DiscoverBestPricesCalloutProps {
    onCompareClick: () => void;
}

export const DiscoverBestPricesCallout = ({ onCompareClick }: DiscoverBestPricesCalloutProps) => {
    return (
        <Callout
            heading={translate('priceCompare.discoverBestPrices.heading')}
            description={translate('priceCompare.discoverBestPrices.description')}
            leadingVisual={(props) => <IconDiscount2 {...props} />}
        >
            <Button type="button" onClick={onCompareClick}>
                {translate('priceCompare.compareBtn')}
            </Button>
        </Callout>
    );
};
