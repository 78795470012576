import { BudgetMessage } from '../../../../types/BudgetMessage';
import { Text } from '../../../cdl/Text/Text';
import { Tooltip } from '../../../cdl/Tooltip/Tooltip';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatMoney } from '../../../common/helpers/formatMoney.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { IconClock } from '../../../common/icons/cdl/Clock';
import { Box } from '../../../common/ui/Box';
import { theme } from '../../../common/ui/theme';

export const BudgetDonutChartDisplay = ({ budgetData }: { budgetData: BudgetMessage }) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="100%"
            height="100%"
        >
            <Text size={20} weight="semi">
                {formatMoney({
                    value: budgetData.budget.amount,
                    currency: budgetData.budget.currency,
                })}
            </Text>
            <Tooltip label={translate('budgets.startDate')}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <IconClock color={theme.colors['dark-blue'][3]} width={18} height={18} />
                    <Text size={12} weight="semi" color={theme.colors['dark-blue'][3]}>
                        {formatDate({
                            date: budgetData.startDate,
                        })}
                    </Text>
                </Box>
            </Tooltip>
        </Box>
    );
};
