import { ProductContext } from '../../../../types/ProductContext';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { DataTable } from '../../../common/DataTable/DataTable';
import { Error } from '../../../common/Error/Error';
import { formatCompanyName } from '../../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatLubesPrice } from '../../../common/helpers/formatLubesPrice.helper';
import { formatMoney } from '../../../common/helpers/formatMoney.helper';
import { formatNumber } from '../../../common/helpers/formatNumber.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate as t } from '../../../common/helpers/translate.helper';
import { translateOfferState } from '../../../common/helpers/translateOfferState.helper';
import { useNavigation } from '../../../common/hooks/useNavigation';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { useUpdateEffect } from '../../../common/hooks/useUpdateEffect';
import { IconListDetails } from '../../../common/icons/cdl/ListDetails';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { TabSwitch } from '../../../common/TabSwitch/TabSwitch';
import { Port } from '../../../port/Port';
import { useOfferPagination } from '../../useOfferPagination';
import { OfferActions } from '../actions/OfferActions';

import { lubesTabToOfferStateMap } from './lubesTabToOfferStateMap';
import { useLubesOfferPaginationTotals } from './useLubesOfferPaginationTotals';

export const LubesOfferOverviewTable = () => {
    const { context } = useProductContext();
    const role = useRole();

    const handleSupplierIds = (supplierIds: string[]): string[] | undefined => {
        if (supplierIds.length) {
            return supplierIds;
        }

        if (role.isAdmin()) {
            return undefined;
        }

        return role.getCompaniesWithType(context).map((it) => it.id);
    };

    const [locationState, { setLocationFieldValue }] = useLocationState();

    const { navigate } = useNavigation();

    const offerPaginationTotals = useLubesOfferPaginationTotals({
        searchQuery: locationState.searchQuery,
        supplierIds: handleSupplierIds(locationState.supplierIds),
        customerIds: locationState.customerIds,
    });
    const offersTotals = offerPaginationTotals.data ?? {};

    const offerPaginationQuery = useOfferPagination({
        page: locationState.page,
        searchQuery: locationState.searchQuery,
        supplierIds: handleSupplierIds(locationState.supplierIds),
        customerIds: locationState.customerIds,
        types: [ProductContext.LUBES],
        // @ts-expect-error the useLocationState hook needs to be able to take a generic, so the .tab property can be typed correctly
        states: lubesTabToOfferStateMap[locationState.tab],
        sortField: locationState.sortValue?.sortField,
        sortDirection: locationState.sortValue?.sortDirection,
    });

    useUpdateEffect(() => {
        setLocationFieldValue('page', 0);
    }, [
        locationState.searchQuery,
        locationState.supplierIds,
        locationState.customerIds,
        locationState.tab,
        locationState.sortValue,
    ]);

    const onTabSelect = (tab: unknown) => {
        setLocationFieldValue('tab', tab);
    };

    if (offerPaginationQuery.isPending) {
        return (
            <div>
                <TabSwitch selectedContext={locationState.tab} onSelect={onTabSelect} totals={offersTotals} />
                <LoadingIndicator />
            </div>
        );
    }

    if (offerPaginationQuery.isError) {
        return (
            <div>
                <TabSwitch selectedContext={locationState.tab} onSelect={onTabSelect} totals={offersTotals} />

                <Error />
            </div>
        );
    }

    if (!offerPaginationQuery.data?.content.length) {
        return (
            <div>
                <TabSwitch selectedContext={locationState.tab} onSelect={onTabSelect} totals={offersTotals} />
                <TableEmptyState Icon={IconListDetails} text={t('order.emptylist')} />
            </div>
        );
    }

    const columns = [
        {
            label: t('order.enquiryorder'),
            width: '5%',
            value: (offer: any) => offer.offerNumber || '-',
        },
        {
            label: t('order.vendorreferenceshort'),
            width: '10%',
            value: (offer: any) => offer.vendorReference || '-',
        },
        {
            label: role.isSupplier() ? t('offer.company') : t('offer.supplier'),
            width: '10%',
            condition: role.isAdmin() || !role.isOneCompanyUser('LUBES'),
            value: (offer: any) => formatCompanyName({ company: offer.supplier }),
        },
        {
            label: t('offer.customer'),
            width: '10%',
            value: (offer: any) => formatCompanyName({ company: offer.customer }),
        },
        {
            label: t('order.vessel'),
            width: '15%',
            value: (offer: any) => formatVessel({ vessel: offer.vessel, short: true }),
        },
        {
            label: t('order.port'),
            width: '10%',
            value: function renderPort(offer: any) {
                if (!offer.port) {
                    return '-';
                }

                return <Port port={offer.port} vesselId={offer.vesselId} locode={false} showTooltip />;
            },
        },
        {
            label: t('order.dateDeliveryShort'),
            width: '10%',
            value: function renderDeliveryDate(offer: any) {
                if (!offer.dateDelivery) {
                    return '-';
                }
                return formatDate({
                    date: offer.dateDelivery,
                    timeZone: 'UTC',
                });
            },
        },
        {
            label: t('order.volume'),
            width: '5%',
            condition: locationState.tab === 'enquired',
            value: (offer: any) => `${formatNumber({ number: offer.volume })}L`,
        },
        {
            label: t('order.ppl'),
            width: '10%',
            condition: role.isAdmin(),
            value: function renderPpl(offer: any) {
                if (offer.ppl.value > 0) {
                    return formatLubesPrice(offer.ppl);
                }

                return '-';
            },
        },
        {
            label: t('order.total'),
            condition: role.isAdmin(),
            value: function renderTotal(offer: any) {
                if (offer.total.value > 0) {
                    return formatMoney({
                        value: offer.total.value,
                        currency: offer.total.currency,
                        minimumFractionDigits: 2,
                    });
                }

                return '-';
            },
        },
        {
            label: t('order.status'),
            width: '10%',
            value: function renderOfferState(offer: any) {
                const offerState = translateOfferState({
                    offer,
                    userType: role.user.userType,
                });

                return offerState || '-';
            },
        },
        {
            label: t('order.action'),
            width: '5%',
            condition: role.hasWriteRights() && role.isSupplier(),
            interactive: true,
            value: (offer: any) => <OfferActions offer={offer} />,
        },
    ].filter(({ condition = true }) => condition);

    return (
        <div>
            <TabSwitch selectedContext={locationState.tab} onSelect={onTabSelect} totals={offersTotals} />
            <DataTable
                columns={columns}
                rows={offerPaginationQuery.data.content}
                onRowClick={({ row: offer }) => {
                    navigate('base.offerlist-offer', {
                        id: offer.id,
                    });
                }}
                isRowUnread={({ row: offer }) => role.isSupplier() && !offer.supplierRead}
            />

            <CenteredPagination
                pageTotal={offerPaginationQuery.pageTotal}
                currentPage={locationState.page}
                onPageChange={(page) => setLocationFieldValue('page', page)}
            />
        </div>
    );
};
