import { ComponentPropsWithoutRef, MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

interface InlineCellProps extends ComponentPropsWithoutRef<'td'> {
    numeric?: boolean;
    emphasis?: 'default' | 'attention';
    interactive?: boolean;
    children?: ReactNode;
}

const StyledInlineCell = styled.td.attrs((props) => ({
    emphasis: 'default',
    ...props,
}))<InlineCellProps>`
    padding: 0 ${get('space.4')}px;

    color: ${(props) => (props.emphasis === 'attention' ? get('colors.attention.foreground') : 'inherit')};

    text-align: ${(props) => (props.numeric ? 'right' : 'left')};
`;

export const InlineCell = (props: InlineCellProps) => {
    const onClick = (event: MouseEvent) => {
        if (props.interactive) {
            event.stopPropagation();
        }
    };

    return (
        <StyledInlineCell {...props} onClick={onClick}>
            {props.children}
        </StyledInlineCell>
    );
};
