import { FormattedNumberInput, FormattedNumberInputProps } from '../FormattedNumberInput/FormattedNumberInput';
import { translate } from '../helpers/translate.helper';

export interface TimeMinutesInputProps
    extends Omit<FormattedNumberInputProps, 'trailingVisual' | 'step' | 'maxLength' | 'allowDecimals'> {}

export const TimeMinutesInput = (props: TimeMinutesInputProps) => {
    return (
        <FormattedNumberInput
            {...props}
            trailingVisual={translate('global.min')}
            step={1}
            maxLength={10}
            allowDecimals={false}
        />
    );
};
