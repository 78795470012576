SupplierGroupResource.$inject = ['$resource', 'AppSettings'];

function SupplierGroupResource($resource, AppSettings) {
    return $resource(
        AppSettings.routes.api + '/v1/suppliergroup/:id',
        {},
        {
            getSpotAvailability: {
                method: 'GET',
                url: AppSettings.routes.api + '/v1/suppliergroup/spot',
            },
            update: {
                method: 'PUT',
                params: {
                    id: '@id',
                },
            },
            list: {
                method: 'GET',
                isArray: true,
                url: AppSettings.routes.api + '/v1/suppliergroup/list',
            },
        }
    );
}

export default {
    name: 'SupplierGroupResource',
    fn: SupplierGroupResource,
};
