import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getProductsByIds, getProductsByQuery } from '../clients/product.api';

export const products = createQueryKeys('products', {
    pagination: (searchQuery: string, includeArchived: boolean = false, page: number = 0) => ({
        queryKey: [{ searchQuery, includeArchived, page }],
        queryFn: () => getProductsByQuery(searchQuery, includeArchived, page),
    }),
    byId: (productIds: string[]) => ({
        queryKey: [{ productIds }],
        queryFn: () => getProductsByIds(productIds),
    }),
});
