import { GeneralReportMessage } from '../../../../types/GeneralReportMessage';
import { ProductContext } from '../../../../types/ProductContext';
import { SpecificReportMessage } from '../../../../types/SpecificReportMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export interface GetReportParams {
    from: number;
    to: number;
    type: ProductContext;
    customerIds?: string[];
    supplierIds?: string[];
}

export const getGeneralReport = async (params: GetReportParams): Promise<GeneralReportMessage> => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('from', params.from.toString());
    urlSearchParams.append('to', params.to.toString());

    if (params.type) {
        urlSearchParams.append('types', params.type);
    }

    if (params.customerIds) {
        params.customerIds.forEach((id) => {
            urlSearchParams.append('customerIds', id);
        });
    }

    if (params.supplierIds) {
        params.supplierIds.forEach((id) => {
            urlSearchParams.append('supplierIds', id);
        });
    }

    const response = await authorizedAxiosInstance.get<GeneralReportMessage>(`/v1/report?${urlSearchParams}`);

    return response.data;
};

export const getProductGroupReport = async (params: GetReportParams): Promise<SpecificReportMessage> => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('from', params.from.toString());
    urlSearchParams.append('to', params.to.toString());

    if (params.type) {
        urlSearchParams.append('types', params.type);
    }

    if (params.customerIds) {
        params.customerIds.forEach((id) => {
            urlSearchParams.append('customerIds', id);
        });
    }

    if (params.supplierIds) {
        params.supplierIds.forEach((id) => {
            urlSearchParams.append('supplierIds', id);
        });
    }

    const response = await authorizedAxiosInstance.get<SpecificReportMessage>(
        `/v1/report/productgroup?${urlSearchParams}`
    );

    return response.data;
};

export const getFuelProductGroupReport = async (params: GetReportParams): Promise<SpecificReportMessage> => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('from', params.from.toString());
    urlSearchParams.append('to', params.to.toString());

    if (params.type) {
        urlSearchParams.append('types', params.type);
    }

    if (params.customerIds) {
        params.customerIds.forEach((id) => {
            urlSearchParams.append('customerIds', id);
        });
    }

    if (params.supplierIds) {
        params.supplierIds.forEach((id) => {
            urlSearchParams.append('supplierIds', id);
        });
    }

    const response = await authorizedAxiosInstance.get<SpecificReportMessage>(
        `/v1/report/fuel-product-group?${urlSearchParams}`
    );

    return response.data;
};
