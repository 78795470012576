import { Formik, FormikErrors } from 'formik';

import { translate } from '../../../common/helpers/translate.helper';

import { CustomerGroupBaseForm } from './CustomerGroupBaseForm';

export interface FormikCreateCustomerGroupState {
    name: string;
    email: string;
    phone: string;
    fax: string;
    address: {
        companyName: string;
        street: string;
        streetNumber: string;
        zipcode: string;
        city: string;
        country: string;
    };
    testCompany: boolean;
    closelinkLite: boolean;
    gtcUrl: string;
}

export interface CreateCustomerGroupFormProps {
    onSubmit: (values: FormikCreateCustomerGroupState) => void;
}

export const CreateCustomerGroupForm = ({ onSubmit }: CreateCustomerGroupFormProps) => {
    const initialFormikState: FormikCreateCustomerGroupState = {
        name: '',
        email: '',
        phone: '',
        fax: '',
        address: {
            companyName: '',
            street: '',
            streetNumber: '',
            zipcode: '',
            city: '',
            country: '',
        },
        testCompany: false,
        closelinkLite: false,
        gtcUrl: '',
    };

    const validate = (values: FormikCreateCustomerGroupState) => {
        const errors: FormikErrors<FormikCreateCustomerGroupState> = {};

        if (!values.name || values.name.trim().length === 0) {
            errors.name = translate('company.error.name');
        }

        return errors;
    };

    return (
        <Formik initialValues={initialFormikState} onSubmit={onSubmit} validate={validate} validateOnMount>
            <CustomerGroupBaseForm title={translate('customergroup.createtitle')} />
        </Formik>
    );
};
