import { useFormikContext } from 'formik';

import { CoreData } from '../../common/CoreData/CoreData';
import { formatDateTime } from '../../common/helpers/formatDateTime.helper';
import { formatPort } from '../../common/helpers/formatPort.helper';
import { formatVessel } from '../../common/helpers/formatVessel.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useVesselDetails } from '../../common/hooks/useVesselDetails';
import { useContractDetails } from '../../contracts/ContractForm/useContractDetails';

import { useAgentDetails } from '../../agent/detail/hooks/useAgentDetails.js';
import { usePortDetails } from './hooks/usePortDetails';

const useDataSummaryFields = () => {
    const { values } = useFormikContext();
    const { data: vessel } = useVesselDetails(values.vesselId);
    const { data: agent } = useAgentDetails(values.agentId);
    const { data: contract } = useContractDetails(values.contractId);
    const { data: port } = usePortDetails(values.portId);

    const fieldDefinition = {
        vessel: {
            label: translate('fuelEnquiry.third.dataSummary.vessel'),
            path: 'vessel',
            render: function render(vessel) {
                if (!vessel) {
                    return null;
                }
                return formatVessel({
                    vessel,
                });
            },
        },
        agent: {
            label: translate('fuelEnquiry.third.dataSummary.agent'),
            path: 'agent',
            render: function render(agent) {
                if (!agent) {
                    return null;
                }
                return agent.name;
            },
        },
        contract: {
            label: translate('fuelEnquiry.third.dataSummary.contractRef'),
            path: 'contract',
            render: function render(contract) {
                if (!contract) {
                    return null;
                }
                return contract.reference;
            },
        },
        port: {
            label: translate('fuelEnquiry.third.dataSummary.port'),
            path: 'port',
            render: function render(port) {
                if (!port) {
                    return null;
                }
                return formatPort({ port });
            },
        },
        eta: {
            label: translate('fuelEnquiry.third.dataSummary.eta'),
            path: 'form',
            render: function render(form) {
                const { eta } = form;

                if (!eta) {
                    return null;
                }
                return formatDateTime({
                    date: new Date(eta),
                });
            },
        },
        etd: {
            label: translate('fuelEnquiry.third.dataSummary.etd'),
            path: 'form',
            render: function render(form) {
                const { etd } = form;

                if (!etd) {
                    return null;
                }
                return formatDateTime({
                    date: new Date(etd),
                });
            },
        },
        buyerReference: {
            label: translate('fuelEnquiry.third.dataSummary.buyerRef'),
            path: 'form',
            render: function render(form) {
                return form.buyerReference;
            },
        },
        supplier: {
            label: translate('fuelEnquiry.third.dataSummary.supplier'),
            path: 'contract',
            render: function render(contract) {
                if (!contract) {
                    return null;
                }
                return contract.supplier.name;
            },
        },
    };

    const data = {
        form: values,
        vessel,
        agent,
        contract,
        port,
    };

    const fields = [
        fieldDefinition.vessel,
        fieldDefinition.port,
        fieldDefinition.eta,
        fieldDefinition.etd,
        fieldDefinition.agent,
        fieldDefinition.buyerReference,
        values.contractId ? fieldDefinition.supplier : null,
        values.contractId ? fieldDefinition.contract : null,
    ].filter(Boolean);

    return {
        fields,
        data,
    };
};

export const DataSummary = () => {
    const { data, fields } = useDataSummaryFields();

    return <CoreData columnWidth={1 / 4} data={data} baseFields={fields} />;
};
