import isEmpty from 'lodash/isEmpty';

export const formatVessel = ({ vessel, short = false }) => {
    if (!vessel) {
        return undefined;
    }

    if (typeof vessel !== 'object' || isEmpty(vessel)) {
        return undefined;
    }

    let vesselString = vessel.name;
    if (!short) {
        vesselString += ` (${vessel.imo})`;
    }
    return vesselString;
};
