import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconFilesOff = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2.293 2.293a1 1 0 0 1 1.414 0l18 18a1 1 0 0 1-1.414 1.414L17 18.414V19a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V9a3 3 0 0 1 1.192-2.394l-2.9-2.899a1 1 0 0 1 0-1.414Zm4.356 5.77A1 1 0 0 0 6 9v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-1h-4a3 3 0 0 1-3-3V9.414l-1.35-1.35ZM10 11.415V15a1 1 0 0 0 1 1h3.586L10 11.414ZM11 4a1 1 0 0 0-1 1 1 1 0 0 1-2 0 3 3 0 0 1 3-3h4a1 1 0 0 1 .707.293l5 5A1 1 0 0 1 21 8v7c0 .439-.094.857-.267 1.236a1 1 0 1 1-1.82-.827A.983.983 0 0 0 19 15V9h-3a2 2 0 0 1-2-2V4h-3Zm5 1.414L17.586 7H16V5.414Z"
            clipRule="evenodd"
        />
    </svg>
);
