import PropTypes from 'prop-types';

import { DrawerFormLayout } from '../../../cdl/Drawer/DrawerFormLayout';
import { Text } from '../../../cdl/Text/Text';
import { FormDatePicker } from '../../../common/form-elements/DatePicker/DeprecatedDatePicker';
import { Form } from '../../../common/form-elements/Form/Form';
import { FormFormattedNumberInput } from '../../../common/form-elements/NumberInputs/FormattedNumberInput';
import { translate } from '../../../common/helpers/translate.helper';

export const BudgetForm = ({ budgetData, onSubmit, title }) => {
    const handleSubmit = ({ data, event }) => {
        event.preventDefault();
        onSubmit(data);
    };

    const isEdit = !!budgetData;

    return (
        <Form height="100%" data={budgetData} onSubmit={handleSubmit}>
            <DrawerFormLayout title={title}>
                {isEdit ? <Text>{translate('budgets.updateInfo')} </Text> : null}

                <FormFormattedNumberInput
                    dataPath="budget.amount"
                    label={translate('budgets.budget')}
                    placeholder={translate('budgets.placeholder.budget')}
                    annotation={translate('budgets.budgetAnnotation')}
                    focus={true}
                    maxLength={7}
                    step={1000}
                    decimalsLimit={0}
                    required
                />

                <FormDatePicker
                    placeholder={translate('budgets.placeholder.startDate')}
                    label={translate('budgets.startDate')}
                    dataPath="startDate"
                    required
                />
            </DrawerFormLayout>
        </Form>
    );
};

BudgetForm.propTypes = {
    title: PropTypes.string.isRequired,
    budgetData: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
};
