import { toast } from 'react-hot-toast';

import { Toast } from './Toast';

interface UseToastsHook {
    addToast: (text: string) => void;
    addLoadingToast: <T>(
        promiseFunction: () => Promise<T>,
        loadingMessage: string,
        successMessage: string,
        errorMessage: string
    ) => Promise<T>;
    addErrorToast: (text: string) => void;
}

export const useToasts = (): UseToastsHook => {
    const addToast = (text: string) => {
        toast.custom((toast) => <Toast isVisible={toast.visible}>{text}</Toast>);
    };

    const addErrorToast = (text: string) => {
        toast.custom((toast) => (
            <Toast isVisible={toast.visible} isError>
                {text}
            </Toast>
        ));
    };

    const addLoadingToast = async function <T>(
        promiseFunction: () => Promise<T>,
        loadingMessage: string,
        successMessage: string,
        errorMessage: string
    ): Promise<T> {
        const toastId = toast.custom(
            (toast) => (
                <Toast isVisible={toast.visible} isLoading>
                    {loadingMessage}
                </Toast>
            ),
            {
                duration: Infinity,
            }
        );

        return promiseFunction()
            .then((value) => {
                toast.custom((toast) => (
                    <Toast isVisible={toast.visible} isSuccess>
                        {successMessage}
                    </Toast>
                ));

                return value;
            })
            .catch((error) => {
                toast.custom((toast) => (
                    <Toast isVisible={toast.visible} isError>
                        {errorMessage}
                    </Toast>
                ));

                throw error;
            })
            .finally(() => {
                toast.dismiss(toastId);
            });
    };

    return {
        addToast,
        addErrorToast,
        addLoadingToast,
    };
};
