import { ReactNode } from 'react';

import { generateStatisticsDateRangePresets } from '../../common/helpers/generateStatisticsDateRangePresets';
import { LocationStateProvider } from '../../common/LocationState/LocationStateProvider';

import { StatisticTab } from './StatisticTab';

export interface StatisticsStateConfig {
    tab: StatisticTab;
    customerIds: string[];
    supplierIds: string[];
    from: number;
    to: number;
}

export const StatisticsState = ({ children }: { children: ReactNode }) => {
    const presets = generateStatisticsDateRangePresets();

    const stateConfig: StatisticsStateConfig = {
        tab: StatisticTab.GENERAL,
        customerIds: [],
        supplierIds: [],
        from: presets[0].range[0].getTime(),
        to: presets[0].range[1].getTime(),
    };

    return <LocationStateProvider stateConfig={stateConfig}>{children}</LocationStateProvider>;
};
