import { Select } from '../../../../common/form-elements/Select/Select';
import { translate } from '../../../../common/helpers/translate.helper';

enum DeliveryCosts {
    INCLUDED = 'INCLUDED',
    EXCLUDED = 'EXCLUDED',
}

interface SelectOption {
    label: string;
    value: string;
}

interface FuelDeliveryCostsSelectProps {
    value: boolean | undefined;
    onChange: (isIncluded: boolean) => void;
    label: string;
    isHighlight: boolean;
    required: boolean;
}

export const FuelDeliveryCostSelect = (props: FuelDeliveryCostsSelectProps) => {
    const options: SelectOption[] = [
        { label: translate('offer.quotationProcess.fuel.deliveryCostsIncluded'), value: DeliveryCosts.INCLUDED },
        { label: translate('offer.quotationProcess.fuel.deliveryCostsExcluded'), value: DeliveryCosts.EXCLUDED },
    ];

    const createSelectValueOption = (value: boolean | undefined): SelectOption | undefined => {
        if (value === undefined) {
            return undefined;
        }

        if (value) {
            return {
                label: translate('offer.quotationProcess.fuel.deliveryCostsIncluded'),
                value: DeliveryCosts.INCLUDED,
            };
        }

        return {
            label: translate('offer.quotationProcess.fuel.deliveryCostsExcluded'),
            value: DeliveryCosts.EXCLUDED,
        };
    };

    const handleChange = (selectedOption: SelectOption) => {
        props.onChange(selectedOption.value === DeliveryCosts.INCLUDED);
    };

    return (
        <Select
            {...props}
            isSearchable={false}
            options={options}
            value={createSelectValueOption(props.value)}
            onChange={handleChange}
        />
    );
};
