import { ReactNode } from 'react';

import { CloselinkLogo } from '../../common/icons/CloselinkLogo';
import { Box } from '../../common/ui/Box';

import { LoginFooter } from './LoginFooter';

interface LoginBoxWrapper {
    children: ReactNode;
    belowBoxContent?: ReactNode;
}

export const LoginPageContentBox = (props: LoginBoxWrapper) => {
    return (
        <Box height="100%" display="grid" gridTemplateRows="1fr auto">
            <Box paddingTop={9} marginX="auto" width="440px" marginBottom={6}>
                <CloselinkLogo width={144} />
                <Box
                    paddingX={9}
                    paddingY={8}
                    variant="shadow"
                    marginTop={7}
                    backgroundColor="white"
                    boxShadow="large"
                    borderRadius={2}
                >
                    {props.children}
                </Box>
                {props.belowBoxContent ? <Box marginTop={7}>{props.belowBoxContent}</Box> : null}
            </Box>
            <LoginFooter />
        </Box>
    );
};
