import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { updateOrder } from '../../../common/api/clients/order.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { translate } from '../../../common/helpers/translate.helper';

export const useDraftUpdate = () => {
    const { addToast, addErrorToast } = useToasts();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateOrder,
        onSuccess: (data) => {
            queryClient.removeQueries({ queryKey: queryKeys.order(data.id) });
            addToast(translate('fuelEnquiry.toast.updateDraft.success'));
        },
        onError: () => {
            addErrorToast(translate('fuelEnquiry.toast.updateDraft.error'));
        },
    });
};
