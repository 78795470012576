import styled from 'styled-components';

import { theme } from '../ui/theme';

interface AnimatedSvgProps {
    $size: number;
    $color: string;
}

// From @uiball/loaders. The library only exposes ES modules, which currently cannot be used by jest
const AnimatedSvg = styled.svg<AnimatedSvgProps>`
    --uib-size: ${(props) => props.$size}px;
    --uib-speed: 2s;
    --uib-color: ${(props) => props.$color};

    height: var(--uib-size);
    width: var(--uib-size);
    vertical-align: middle;

    & circle {
        fill: none;
        stroke: var(--uib-color);
        stroke-dasharray: 0, 200;
        stroke-dashoffset: 0;
        stroke-linecap: round;
        transform-origin: 50px 50px;
        animation:
            rotate var(--uib-speed) linear infinite,
            stretch calc(var(--uib-speed) * 0.75) ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes stretch {
        0% {
            stroke-dasharray: 0, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 200, 200;
            stroke-dashoffset: -124px;
        }
    }
`;

interface SpinnerProps {
    size?: number;
    strokeWidth?: number;
    color?: string;
}

export const Spinner = (props: SpinnerProps) => (
    <AnimatedSvg
        className="ring"
        viewBox="25 25 50 50"
        strokeWidth={props.strokeWidth ?? 6}
        $size={props.size ?? 16}
        $color={props.color ?? theme.colors['dark-blue'][2]}
    >
        <circle cx="50" cy="50" r="20" />
    </AnimatedSvg>
);
