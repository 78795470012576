import { SupplierStatisticsMessage } from '../../../types/StatisticsMessage';
import { useLocationState } from '../../common/LocationState/useLocationState';

import { CustomersTabContent } from './CustomersTabContent';
import { GeneralTabContent } from './GeneralTabContent';
import { PortsTabContent } from './PortsTabContent';
import { ProductGroupsTabContent } from './ProductGroupsTabContent';
import { ProductsTabContent } from './ProductsTabContent';
import { StatisticsStateConfig } from './StatisticsState';
import { StatisticTab } from './StatisticTab';

interface SupplierStatisticsContentProps {
    statistics: SupplierStatisticsMessage;
}

export const SupplierStatisticsContent = ({ statistics }: SupplierStatisticsContentProps) => {
    const [locationStateUntyped] = useLocationState();
    const locationState = locationStateUntyped as StatisticsStateConfig;

    switch (locationState.tab) {
        case StatisticTab.GENERAL:
            return <GeneralTabContent statistics={statistics} />;
        case StatisticTab.CUSTOMERS:
            return <CustomersTabContent customerStatistics={statistics.customer} />;
        case StatisticTab.PORTS:
            return <PortsTabContent portStatistics={statistics.port} />;
        case StatisticTab.PRODUCTS:
            return <ProductsTabContent productStatistics={statistics.product} />;
        case StatisticTab.PRODUCT_GROUPS:
            return <ProductGroupsTabContent productGroupsStatistics={statistics.productGroup} />;
        case StatisticTab.VESSELS:
            return null;
        case StatisticTab.SUPPLIERS:
            return null;
    }
};
