import PropTypes from 'prop-types';

import { Text } from '../../../cdl/Text/Text';
import { Box } from '../../ui/Box.tsx';
import { Flex } from '../../ui/Flex.tsx';
import { Required } from '../Required/Required';
import { Label as StyledLabel } from '../TextInput/Input';

/**
 * @deprecated use LabelText from the common folder instead
 **/

export const Label = ({ label, annotation, children, required }) => {
    if (!label) {
        return <Box position="relative">{children}</Box>;
    }

    return (
        <StyledLabel>
            <Flex paddingX={4} paddingY={4} justifyContent="space-between">
                <Text size={12}>
                    {label}
                    {required ? <Required /> : null}
                </Text>
                {annotation ? <Text size={12}>{annotation}</Text> : null}
            </Flex>

            {children}
        </StyledLabel>
    );
};

Label.propTypes = {
    label: PropTypes.node,
    annotation: PropTypes.string,
    required: PropTypes.bool,
    children: PropTypes.node,
};
