import Skeleton from 'react-loading-skeleton';

import { Text } from '../../cdl/Text/Text';
import { Box } from '../../common/ui/Box';

export const LoadingPriceBox = () => {
    return (
        <Box
            position="relative"
            paddingTop={5}
            paddingLeft={5}
            paddingRight={5}
            backgroundColor="white"
            width="240px"
            height="220px"
            borderRadius="4px"
        >
            <Text size={16} weight="semi">
                <Skeleton />
            </Text>

            <Text size={24} marginTop={3}>
                <Skeleton />
            </Text>

            <Box marginTop={4}>
                <Text size={14} color="dark-blue.3">
                    <Skeleton />
                </Text>
            </Box>

            <Box position="absolute" bottom={5} left={5} right={5}>
                <Skeleton />
            </Box>
        </Box>
    );
};
