import PropTypes from 'prop-types';

export const Th = ({ children, ...rest }) => {
    return <th {...rest}>{children}</th>;
};

Th.propTypes = {
    children: PropTypes.node,
    colSpan: PropTypes.number,
};
