import { CustomerGroupMessage, CustomerGroupRequestMessage } from '../../../../types/CustomerGroupMessage';
import { PageMessage } from '../../../../types/PageMessage';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const getCustomerGroups = async (
    searchQuery: string,
    page: number
): Promise<PageMessage<CustomerGroupMessage>> => {
    const params = new URLSearchParams({
        searchQuery,
        page: page.toString(),
    });

    const response = await authorizedAxiosInstance.get(`/v1/customergroup?${params.toString()}`);

    return response.data;
};

export const getCustomerGroup = async (groupId: string): Promise<CustomerGroupMessage> => {
    const response = await authorizedAxiosInstance.get(`/v1/customergroup/${groupId}`);

    return response.data;
};

export const createCustomerGroup = async (
    customerGroup: CustomerGroupRequestMessage
): Promise<CustomerGroupMessage> => {
    const response = await authorizedAxiosInstance.post(`/v1/customergroup`, customerGroup);

    return response.data;
};

export const updateCustomerGroup = async (
    customerGroup: CustomerGroupRequestMessage
): Promise<CustomerGroupMessage> => {
    const response = await authorizedAxiosInstance.put(`/v1/customergroup/${customerGroup.id}`, customerGroup);

    return response.data;
};
