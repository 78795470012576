import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconMessages = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9.586 2.586A2 2 0 0 1 11 2h9a2 2 0 0 1 2 2v10a1 1 0 0 1-1.707.707L17.586 12H11a2 2 0 0 1-2-2V4a2 2 0 0 1 .586-1.414ZM20 4h-9v6h7a1 1 0 0 1 .707.293L20 11.586V4ZM2.586 9.586A2 2 0 0 1 4 9h2a1 1 0 0 1 0 2H4v7.586l1.293-1.293A1 1 0 0 1 6 17h7v-2a1 1 0 1 1 2 0v2a2 2 0 0 1-2 2H6.414l-2.707 2.707A1 1 0 0 1 2 21V11a2 2 0 0 1 .586-1.414Z"
            clipRule="evenodd"
        />
    </svg>
);
