import { Button } from '../../cdl/Button/Button';
import { translate } from '../../common/helpers/translate.helper';
import { useBunkerStopsCsvDownload } from '../hooks/useBunkerStopsCsvDownload';

export const BunkerStopsDownloadCsvButton = () => {
    const bunkerStopsDownloadMutation = useBunkerStopsCsvDownload();
    const onClick = () => {
        bunkerStopsDownloadMutation.mutate();
    };

    return (
        <Button emphasis="medium" onClick={onClick}>
            {translate('bunkerPlan.downloadCsvButton')}
        </Button>
    );
};
