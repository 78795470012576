import { useQuery } from '@tanstack/react-query';

import { queries } from '../../../../common/api/queryKeys/queries';

export function useSpotAvailableAtPort(portCountryCode?: string, testOrder: boolean = false) {
    return useQuery({
        ...queries.supplierGroups.spotAvailability(portCountryCode!!, testOrder),
        enabled: !!portCountryCode,
    });
}
