import { translate } from '../../../common/helpers/translate.helper';
import { LiteOverlay } from '../../../common/LiteOverlay/LiteOverlay';
import { Section } from '../../../common/Section/Section';
import { Box } from '../../../common/ui/Box';
import { LubesSchedulePriceCompare } from '../PriceCompare/LubesSchedulePriceCompare';
import { ScheduleStop } from '../ScheduleStop';
import { ScheduleSlider } from '../Slider/ScheduleSlider';
import { ScheduleStopContainer } from '../Slider/ScheduleStopContainer';
import { ScheduleWrapper } from '../Slider/ScheduleWrapper';

import { createLubesPricesMap } from './createLubesPriceMap';
import { liteData } from './liteData';

export const LiteLubesScheduleSection = () => {
    const pricesMap: any = createLubesPricesMap(liteData.prices);

    const filledScheduleList = liteData.schedules
        .filter((schedule) => schedule.port)
        .map((stop, index) => {
            return (
                <ScheduleStopContainer key={stop.id}>
                    <ScheduleStop stop={stop} vesselId="vesselId-1" index={index} />
                    {pricesMap[stop.port!.id] ? <LubesSchedulePriceCompare prices={pricesMap[stop.port!.id]} /> : null}
                </ScheduleStopContainer>
            );
        });

    return (
        <ScheduleWrapper>
            <Section
                title={translate('vessel.schedule')}
                position="relative"
                paddingX={0}
                paddingY={0}
                overflow="hidden"
            >
                <LiteOverlay variant="medium" title={translate('vessel.schedule')}>
                    <Box paddingY={6}>
                        <ScheduleSlider
                            activeSlide={0}
                            onSlideChange={() => {}}
                            scheduleList={filledScheduleList}
                            cheapestDefaultScope={{ prev: false, next: false }}
                            negativeStopIndices={[]}
                            onSlidesPerViewChange={() => {}}
                            showControls={false}
                        />
                    </Box>
                </LiteOverlay>
            </Section>
        </ScheduleWrapper>
    );
};
