import muscleManUrl from '../../../../images/illustrations/Muscle-Man@2x.png';
import { Button } from '../../../cdl/Button/Button';
import { Modal } from '../../../cdl/Modal/Modal';
import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';

interface WelcomePopupProps {
    isOpen: boolean;
    onDismiss: () => void;
}

export const WelcomePopup = (props: WelcomePopupProps) => {
    return (
        <Modal isOpen={props.isOpen} scrollable={false} dismissible={false} size="small">
            <Box padding={9} style={{ textAlign: 'center' }}>
                <img src={muscleManUrl} width={150} height={150} alt="" />
                <Text variant="display" as="h1" marginTop={6} marginBottom={4}>
                    {translate('welcomePopup.youHaveMadeIt')}
                </Text>
                <Text variant="body" color="foreground.muted" as="p" marginBottom={5}>
                    {translate('welcomePopup.welcomeOnBoard')}
                </Text>

                <Button width="100%" emphasis="high" size="large" onClick={props.onDismiss}>
                    {translate('welcomePopup.letsGetStarted')}
                </Button>
            </Box>
        </Modal>
    );
};
