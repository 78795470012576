import { ReactNode } from 'react';
import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { Box } from '../ui/Box';
import { get } from '../ui/get';

const IconWrapper = styled.div`
    background-color: ${get('colors.background.subtle')};
    padding: ${get('space.4')}px;
    border-radius: 8px;
`;

interface EmptyPagePlaceholderProps {
    title: string;
    description: string;
    icon: ReactNode;
    action?: ReactNode;
}

export const EmptyPagePlaceholder = ({ title, description, icon, action }: EmptyPagePlaceholderProps) => {
    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start" maxWidth="344px">
            <IconWrapper>{icon}</IconWrapper>

            <Text variant="title" marginTop={5}>
                {title}
            </Text>

            <Text variant="body" marginTop={2} marginBottom={6} color="foreground.subtle">
                {description}
            </Text>

            {action ? action : null}
        </Box>
    );
};
