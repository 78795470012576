import { BunkerStopMessage } from '../../../types/BunkerStopsMessage';

export const createCategoriesFromStops = (
    bunkerStops: BunkerStopMessage[]
): Map<
    string,
    {
        productGroup: string;
        sulphurContent: string;
    }
> => {
    return bunkerStops.reduce((acc, stop) => {
        stop.items.forEach((item) => {
            acc.set(`${item.productGroup}-${item.sulphurContent}`, {
                productGroup: item.productGroup,
                sulphurContent: item.sulphurContent,
            });
        });

        return acc;
    }, new Map<string, { productGroup: string; sulphurContent: string }>());
};
