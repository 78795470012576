import PropTypes from 'prop-types';

import { Link } from '../../cdl/Link/Link';
import { Text } from '../../cdl/Text/Text';
import { BudgetDonutChart } from '../../common/BudgetDonutChart/BudgetDonutChart';
import { formatMoney } from '../../common/helpers/formatMoney.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useVesselBudgetDetails } from '../../common/hooks/useVesselBudgetDetails';
import { useVesselDetails } from '../../common/hooks/useVesselDetails';
import { Box } from '../../common/ui/Box';

import { OrderVesselTanks } from './OrderVesselTanks';

const LubricationChart = ({ downloadUrl }) => {
    return (
        <Link href={downloadUrl} external>
            {translate('order.lubricationChart')}
        </Link>
    );
};

LubricationChart.propTypes = {
    downloadUrl: PropTypes.string.isRequired,
};

const BudgetDetails = ({ vesselId }) => {
    const { budgetData, isPending, notFound } = useVesselBudgetDetails(vesselId);

    if (isPending || notFound) {
        return null;
    }

    return (
        <Box display="flex" alignItems="center">
            <BudgetDonutChart budget={budgetData.budget.amount} spentBudget={budgetData.spentBudget.amount} size={22} />

            <Text variant="body" marginLeft={2} marginRight={4}>
                {translate('budgets.budgetAvailable')}
            </Text>

            <Text
                variant="body"
                fontWeight="semiBold"
                color={budgetData.remainingBudget.amount < 0 ? 'negative.foreground' : null}
            >
                {formatMoney({
                    value: budgetData.remainingBudget.amount,
                    currency: budgetData.remainingBudget.currency,
                })}
            </Text>
        </Box>
    );
};

BudgetDetails.propTypes = {
    vesselId: PropTypes.string.isRequired,
};

export const OrderVesselInformation = ({ vesselId }) => {
    const vesselQuery = useVesselDetails(vesselId);

    if (!vesselId || vesselQuery.isPending) {
        return null;
    }

    return (
        <Box>
            <Box display="flex" gap={6}>
                {vesselQuery.data.lubricationChart ? (
                    <LubricationChart downloadUrl={vesselQuery.data.lubricationChart.downloadUrl} />
                ) : null}
                <BudgetDetails vesselId={vesselQuery.data.id} />
            </Box>

            <OrderVesselTanks vesselId={vesselQuery.data.id} />
        </Box>
    );
};

OrderVesselInformation.propTypes = {
    vesselId: PropTypes.string,
};
