import { useRole } from '../../../common/hooks/useRole';

import { LiteQuickStats } from './LiteQuickStats';
import { QuickStats } from './QuickStats';

export const ContextualQuickStats = () => {
    const role = useRole();

    if (role.usesCloselinkLite()) {
        return <LiteQuickStats />;
    }

    return <QuickStats />;
};
