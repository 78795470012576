import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { OnChangeValue } from 'react-select';

import { ProductMessage } from '../../../../types/ProductMessage';
import { Button } from '../../../cdl/Button/Button';
import { ComboBox, ComboBoxProps, DefaultOption } from '../../../cdl/ComboBox/ComboBox';
import { Tooltip } from '../../../cdl/Tooltip/Tooltip';
import { getLatestProducts, getLubesProducts } from '../../api/clients/gateway.api';
import { getProductsByQuery } from '../../api/clients/product.api';
import { queries } from '../../api/queryKeys/queries';
import { translate } from '../../helpers/translate.helper';
import { useRole } from '../../hooks/useRole';
import { IconArrowRight } from '../../icons/cdl/ArrowRight';
import { Box } from '../../ui/Box';

import { CustomProductOption } from './components/CustomProductOption';
import { ProductSingleValue } from './components/ProductSingleValue';

export interface ProductOption extends DefaultOption {
    product: ProductMessage;
    inTank?: boolean;
}

export interface ProductSelectProps extends Omit<ComboBoxProps<DefaultOption, false>, 'onChange' | 'value'> {
    onChange: (option: ProductOption) => void;
    value: string;
    defaultProducts?: ProductMessage[];
}

export const ProductSelect = ({ defaultProducts, ...rest }: ProductSelectProps) => {
    const role = useRole();

    const productsQuery = useQuery({
        queryKey: queries.products.pagination(rest.value).queryKey,
        queryFn: () => {
            return getLubesProducts({
                searchQuery: rest.value,
                supplierProductGroups: role.group?.supplierProductGroups,
            });
        },

        enabled: false,
        staleTime: Infinity,
    });

    const [selectedValue, setSelectedValue] = useState<ProductOption | null>(null);

    const loadLatestProducts = async () => {
        const response = await getLatestProducts();
        const productOptions = response.map((product) => ({
            value: product.id,
            label: product.name,
            product,
        }));

        if (defaultProducts?.length) {
            const defaultProductOptions = defaultProducts.map((product) => ({
                value: product.id,
                label: product.name,
                product,
                inTank: !!defaultProducts.some((defaultProduct: ProductMessage) => defaultProduct.id === product.id),
            }));

            const filteredProductOptions = productOptions.filter(
                (product) => !defaultProductOptions.some((defaultOption) => defaultOption.value === product.value)
            );
            return [...defaultProductOptions, ...filteredProductOptions];
        }

        return productOptions;
    };

    const loadProductsByQuery = useCallback(
        async (query?: string): Promise<ProductOption[]> => {
            const response = await getProductsByQuery(query || '', false, 0, role.group?.supplierProductGroups);
            return response.content.map((product: ProductMessage) => ({
                value: product.id,
                label: product.name,
                product,
                inTank: !!defaultProducts?.some((defaultProduct: ProductMessage) => defaultProduct.id === product.id),
            }));
        },
        [role.group?.supplierProductGroups, defaultProducts]
    );

    useEffect(() => {
        let isMounted = true;

        if (rest.value?.length) {
            productsQuery.refetch().then((response) => {
                if (response.data && isMounted) {
                    const product = response.data.content.at(0);

                    if (!product) {
                        return setSelectedValue(null);
                    }

                    setSelectedValue({
                        value: product.id,
                        label: product.name,
                        product: product,
                        inTank: !!defaultProducts?.some(
                            (defaultProduct: ProductMessage) => defaultProduct.id === product.id
                        ),
                    });
                }
            });
        } else {
            setSelectedValue(null);
        }

        return () => {
            isMounted = false;
        };
    }, [rest.value, defaultProducts]); // eslint-disable-line react-hooks/exhaustive-deps

    const loadProducts = async (searchQuery?: string) => {
        if (!searchQuery && !role.isSupplier()) {
            const latestProductsOptions = await loadLatestProducts();

            if (latestProductsOptions?.length) {
                return latestProductsOptions;
            } else {
                return loadProductsByQuery();
            }
        }
        return loadProductsByQuery(searchQuery);
    };

    function updateSelectedValue(option: OnChangeValue<ProductOption, false>) {
        rest.onChange(option as ProductOption);
        setSelectedValue(option as ProductOption);
    }

    return (
        <div>
            <ComboBox<ProductOption, false>
                {...rest}
                rerenderValue={JSON.stringify(defaultProducts)}
                value={selectedValue}
                overrides={{
                    Option: CustomProductOption as any,
                    SingleValue: ProductSingleValue as any,
                }}
                onChange={updateSelectedValue}
                loadOptions={loadProducts}
            />

            {selectedValue?.product.latestSuccessorProduct && !rest.disabled ? (
                <Box marginTop={4}>
                    <Tooltip side="right" label={translate('order.productSuccessorHintTooltip')}>
                        <Button
                            emphasis="low"
                            type="button"
                            onClick={() =>
                                updateSelectedValue({
                                    value: selectedValue.product.latestSuccessorProductId!,
                                    label: selectedValue.product.latestSuccessorProduct?.name!,
                                    product: selectedValue.product.latestSuccessorProduct!,
                                })
                            }
                            leadingVisual={<IconArrowRight height={16} width={16} />}
                        >
                            {selectedValue?.product.latestSuccessorProduct.name}
                        </Button>
                    </Tooltip>
                </Box>
            ) : null}
        </div>
    );
};
