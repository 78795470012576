import { generateRandomId } from '../generateRandomId';
import { usePriceCompareState } from '../usePriceCompareState';

export const usePriceCompareOverlay = ({ items, portIds, customerId }) => {
    return usePriceCompareState({
        initializer: () => {
            const portInformation = portIds.map((portId) => {
                return {
                    portId,
                    id: generateRandomId(),
                };
            });

            return {
                items,
                portInformation,
                customerId,
            };
        },
    });
};
