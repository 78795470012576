import { Formik, FormikErrors } from 'formik';

import { AddressMessage } from '../../../../../types/AddressMessage';
import { ProductContext } from '../../../../../types/ProductContext';
import { translate } from '../../../../common/helpers/translate.helper';
import { useProductContext } from '../../../../common/hooks/useProductContext';
import { useRole } from '../../../../common/hooks/useRole';

import { FormikCustomerCreateForm } from './FormikCustomerCreateForm';

export interface FormikCustomerCreateState {
    type: ProductContext | '';
    name: string;
    email: string;
    phone?: string;
    fax?: string;
    invoiceMail: string;
    address: AddressMessage;
    spotAllowed: boolean;
    testCompany: boolean;
    trial: boolean;
}

export interface CreateCustomerCompanyFormProps {
    onSubmit: (values: FormikCustomerCreateState) => void;
}

export const CreateCustomerCompanyForm = ({ onSubmit }: CreateCustomerCompanyFormProps) => {
    const role = useRole();
    const { possibleContexts } = useProductContext();

    const initialFormikState: FormikCustomerCreateState = {
        type: '',
        name: '',
        email: '',
        phone: '',
        fax: '',
        invoiceMail: '',
        address: {
            city: '',
            companyName: '',
            country: '',
            street: '',
            streetNumber: '',
            zipcode: '',
        },
        testCompany: true,
        spotAllowed: false,
        trial: false,
    };

    const validate = (values: FormikCustomerCreateState) => {
        const errors: FormikErrors<FormikCustomerCreateState> = {};

        if (!values.type && (role.isAdmin() || possibleContexts.length > 1)) {
            errors.type = translate('customer.noTypeSelectedError');
        }

        if (!values.name) {
            errors.name = translate('customer.noCompanyNameError');
        }

        if (!values.email) {
            errors.email = translate('customer.noEmailError');
        }

        return errors;
    };

    return (
        <Formik
            initialValues={initialFormikState}
            onSubmit={onSubmit}
            validate={validate}
            component={FormikCustomerCreateForm}
        />
    );
};
