import { useField } from 'formik';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { ProductContext } from '../../../types/ProductContext';
import { Callout } from '../../cdl/Callout/Callout';
import { validateOrder } from '../../common/api/clients/order.api';
import { FormikTextInput } from '../../common/form-elements/formik/FormikTextInput';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';

export const FormikBuyerReferenceInput = (props) => {
    const validate = useCallback(
        async (buyerReference) => {
            try {
                const response = await validateOrder({
                    id: props.orderId,
                    type: props.type ?? ProductContext.FUEL,
                    customerId: props.customerId,
                    buyerReference: buyerReference ? buyerReference : null,
                });

                const buyerReferenceError = response.validationErrors.find((error) => error.path === 'buyerReference');

                if (!buyerReferenceError) {
                    return undefined;
                }

                return buyerReferenceError.code;
            } catch {
                return undefined;
            }
        },
        [props.orderId, props.type, props.customerId]
    );

    const [field, meta] = useField({
        name: props.name,
        validate,
    });

    const disabled = !props.customerId;

    const placeholder = disabled
        ? translate('fuelEnquiry.first.buyerReferenceDisabledPlaceholder')
        : translate('fuelEnquiry.first.buyerReferencePlaceholder');

    return (
        <Box>
            <FormikTextInput
                label={translate('fuelEnquiry.first.buyerReferenceLabel')}
                placeholder={placeholder}
                disabled={disabled}
                {...field}
                {...props}
            />
            {meta.error ? (
                <Box marginTop="1px">
                    <Callout
                        variant="negative"
                        description={translate('fuelEnquiry.first.buyerReferenceDuplicateError')}
                    />
                </Box>
            ) : null}
        </Box>
    );
};

FormikBuyerReferenceInput.propTypes = {
    name: PropTypes.string.isRequired,
    customerId: PropTypes.string,
    orderId: PropTypes.string,
    type: PropTypes.string,
};
