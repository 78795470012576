import { CustomerSettingsMessage } from '../../../../types/CustomerSettingsMessage';
import { useRole } from '../../../common/hooks/useRole';
import { OfferModel } from '../../model/OfferModel';
import { StateSwitchContextProvider } from '../common/components/StateSwitchContextProvider';

import { LubesAdminOfferDetailStatusBar } from './LubesAdminOfferDetailStatusBar';
import { LubesCustomerOfferDetailStatusBar } from './LubesCustomerOfferDetailStatusBar';
import { LubesSupplierOfferDetailStatusBar } from './LubesSupplierOfferDetailStatusBar';

interface LubesOfferDetailStatusBarProps {
    offer: OfferModel;
    customerSettings: CustomerSettingsMessage;
}

export const LubesOfferDetailStatusBar = ({ offer, customerSettings }: LubesOfferDetailStatusBarProps) => {
    const role = useRole();

    if (role.isCustomer()) {
        return (
            <StateSwitchContextProvider>
                <LubesCustomerOfferDetailStatusBar offer={offer} customerSettings={customerSettings} />
            </StateSwitchContextProvider>
        );
    }

    if (role.isSupplier()) {
        return (
            <StateSwitchContextProvider>
                <LubesSupplierOfferDetailStatusBar offer={offer} />
            </StateSwitchContextProvider>
        );
    }

    if (role.isAdmin()) {
        return <LubesAdminOfferDetailStatusBar offer={offer} />;
    }

    return null;
};
