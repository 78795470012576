interface FormatMoneyConfig {
    value: number;
    currency: string;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
}

export const formatMoney = ({
    value,
    currency,
    minimumFractionDigits = 0,
    maximumFractionDigits,
}: FormatMoneyConfig) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits,
        maximumFractionDigits,
    }).format(value);
};
