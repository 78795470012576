import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { CompareOverview as OriginalCompareOverview } from './CompareOverview';

const CompareOverview = (props) => {
    return (
        <AppProviders>
            <OriginalCompareOverview {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(CompareOverview, 'reactCompareOverview');
