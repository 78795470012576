import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { FormikFormattedNumberInput } from '../../../../common/FormattedNumberInput/FormikFormattedNumberInput';
import { translate } from '../../../../common/helpers/translate.helper';
import { Box } from '../../../../common/ui/Box';
import { get } from '../../../../common/ui/get';
import { DeletableComponent } from '../../../../company/customer/detail/MultipleApproversSettingsForm/components/DeletableComponent';

import { FormikVesselConsumptionPresetsState } from './EditVesselConsumptionPresetsForm';
import { FormikVesselConsumptionPresetCategorySelect } from './FormikVesselConsumptionPresetCategorySelect';

const CategoryWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: ${get('space.7')}px;
    border-bottom: 1px solid;
    border-bottom-color: ${get('colors.border.muted')};
`;

interface FormikVesselConsumptionPresetProps {
    index: number;
    onDeleteClick: () => void;
}

export const FormikVesselConsumptionPreset = ({ index, onDeleteClick }: FormikVesselConsumptionPresetProps) => {
    const { values } = useFormikContext<FormikVesselConsumptionPresetsState>();

    return (
        <CategoryWrapper paddingY={7}>
            {values.presets.length === 1 && !values.presets[index].productGroup ? (
                <FormikVesselConsumptionPresetCategorySelect index={index} />
            ) : (
                <DeletableComponent isDeletable={true} onDeleteClick={onDeleteClick}>
                    <FormikVesselConsumptionPresetCategorySelect index={index} />
                </DeletableComponent>
            )}
            {values.presets[index].productGroup ? (
                <>
                    <FormikFormattedNumberInput
                        label={translate('vessel.fuelStocks.editPresetsForm.averageDailyConsumption')}
                        name={`presets[${index}].averageDailyConsumption`}
                        decimalsLimit={4}
                        trailingVisual={translate('vessel.fuelStocks.editPresetsForm.mT')}
                    />
                    <FormikFormattedNumberInput
                        label={translate('vessel.fuelStocks.editPresetsForm.safetyReserve')}
                        name={`presets[${index}].safetyReserve`}
                        allowDecimals={false}
                        trailingVisual={translate('vessel.fuelStocks.editPresetsForm.mT')}
                    />
                    <FormikFormattedNumberInput
                        label={translate('vessel.fuelStocks.editPresetsForm.maximumIntake')}
                        name={`presets[${index}].maximumIntake`}
                        allowDecimals={false}
                        trailingVisual={translate('vessel.fuelStocks.editPresetsForm.mT')}
                    />
                </>
            ) : null}
        </CategoryWrapper>
    );
};
