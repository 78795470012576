import { useState } from 'react';

import { Button } from '../../../../cdl/Button/Button';
import { Modal } from '../../../../cdl/Modal/Modal';
import { translate } from '../../../helpers/translate.helper';
import { IconPlus } from '../../../icons/cdl/Plus';

import { SampleKit, SampleKitForm } from './SampleKitForm';

interface OilSampleKitButtonProps {
    onSubmit: (sampleKit: SampleKit) => void;
}

export const SampleKitButton = ({ onSubmit }: OilSampleKitButtonProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const onAddOilSampleKitClick = () => {
        setIsOpen(true);
    };

    return (
        <div>
            <Button type="button" onClick={onAddOilSampleKitClick} leadingVisual={<IconPlus height={16} width={16} />}>
                {translate('offer.edit.lubes.addOilSampleKit')}
            </Button>

            <Modal
                size="small"
                isOpen={isOpen}
                onDismiss={() => setIsOpen(false)}
                dismissible={false}
                scrollable={false}
            >
                <SampleKitForm
                    onDismiss={() => setIsOpen(false)}
                    onSubmit={(samplekit) => {
                        onSubmit(samplekit);
                        setIsOpen(false);
                    }}
                />
            </Modal>
        </div>
    );
};
