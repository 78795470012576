import { AppProviders } from '../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../common/helpers/createDirectiveFromReactComponent';

import { CustomersOverviewPage as OriginalCustomersOverviewPage } from './CustomersOverviewPage';

const CustomersOverviewPage = (props) => {
    return (
        <AppProviders>
            <OriginalCustomersOverviewPage {...props} />
        </AppProviders>
    );
};

export default createDirectiveFromReactComponent(CustomersOverviewPage, 'reactCustomersOverviewPage');
