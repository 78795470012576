import { useField } from 'formik';

import { CountryMessage } from '../../../../types/CountryMessage';

import { CountrySelect, CountrySelectState } from './CountrySelect';

interface FormikCountrySelectProps {
    name: string;
    onSelect?: (country: CountryMessage) => void;
}

export const FormikCountrySelect = (props: FormikCountrySelectProps) => {
    const [field, , helpers] = useField(props.name);

    const onChange = (state: CountrySelectState) => {
        helpers.setValue(state.value);
        props.onSelect?.(state.country);
    };

    return <CountrySelect countryId={field.value} onChange={onChange} />;
};
