import styled from 'styled-components';

import { LiteOverlayProps } from '../LiteOverlay';

export const LiteInformationContent = styled.div<{ variant: LiteOverlayProps['variant'] }>`
    position: absolute;
    top: 0;
    left: 10%;
    right: 10%;
    bottom: 0;
    opacity: 0;
    gap: ${(props) => (props.variant === 'small' ? '20px' : '24px')};
    display: flex;
    flex-direction: ${(props) => (props.variant === 'wide' ? 'row' : 'column')};
    align-items: center;
    justify-content: center;
    z-index: 2;
    transform: translateY(8px);
    transition:
        opacity 150ms ease-out,
        transform 150ms ease-out;
`;
