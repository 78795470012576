import css from '@styled-system/css';
import styled from 'styled-components';

const activeStyles = (props) => {
    if (!props.active) {
        return null;
    }

    return css({
        color: 'bold-blueish.0',
        borderBottom: 3,
        fontWeight: 'semiBold',
    });
};

export const Tab = styled.button(
    css({
        border: 0,
        paddingBottom: 3,
        color: 'dark-blue.2',
        fontWeight: 'normal',
        ':hover': {
            color: 'bold-blueish.0',
        },
    }),
    activeStyles
);
