import { useNavigation } from '../../common/hooks/useNavigation';
import { TabSwitch } from '../../common/TabSwitch/TabSwitch';

import { DemandTab } from './DemandTab';

interface PrePlanningTabSwitchProps {
    currentTab: DemandTab;
}

export const PrePlanningTabSwitch = ({ currentTab }: PrePlanningTabSwitchProps) => {
    const { navigate } = useNavigation();

    const handleTabSwitch = (selectedTab: DemandTab) => {
        if (currentTab === selectedTab) {
            return;
        }

        switch (selectedTab) {
            case DemandTab.VESSELS:
                void navigate('base.fuel-planning');
                break;
            case DemandTab.BUNKER_PLAN:
                void navigate('base.fuel-planning-bunker-plan');
                break;
        }
    };

    return (
        <TabSwitch
            selectedContext={currentTab}
            onSelect={handleTabSwitch}
            totals={{
                [DemandTab.VESSELS]: null,
                [DemandTab.BUNKER_PLAN]: null,
            }}
        />
    );
};
