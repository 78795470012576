import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';

import { ActionIconListItem } from '../../../common/ActionBar/ActionIconListItem';
import { ActionIconListWrapper } from '../../../common/ActionBar/ActionIconListWrapper';
import { ActionListItem } from '../../../common/ActionBar/ActionListItem';
import { queryKeys } from '../../../common/api/queryKeys';
import { translate } from '../../../common/helpers/translate.helper';
import { useAngularDependencies } from '../../../common/hooks/useAngularDependencies';
import { useNavigation } from '../../../common/hooks/useNavigation';
import { useRole } from '../../../common/hooks/useRole';
import { IconCopy } from '../../../common/icons/cdl/Copy';
import { IconHistory } from '../../../common/icons/cdl/History.tsx';
import { IconList } from '../../../common/icons/cdl/List.tsx';
import { IconPencil } from '../../../common/icons/cdl/Pencil';
import { IconX } from '../../../common/icons/cdl/X';
import { OfferCancelPopup } from '../../../common/OfferCancelPopup/OfferCancelPopup';
import { OfferExportDropdown } from '../common/components/OfferExportDropdown';
import { useOfferUpdate } from '../common/hooks/useOfferUpdate';

import { LubesInitialEnquiryPopup } from './LubesInitialEnquiryPopup';

export const LubesOfferDetailActionBar = ({ offer }) => {
    const queryClient = useQueryClient();

    const [showInitialEnquiryPopup, setShowInitialEnquiryPopup] = useState(false);

    const [showCancelPopup, setShowCancelPopup] = useState(false);

    const updateOfferMutation = useOfferUpdate();

    const role = useRole();
    const { navigate } = useNavigation();
    const { $state } = useAngularDependencies();

    const navigateToEditPage = () => {
        navigate('base.offerlist-offer-edit', {
            id: offer.id,
        });
        queryClient.removeQueries(queryKeys.offer(offer.id));
    };

    const prefillOrder = (order) => {
        void navigate('lubes-create-enquiry', {
            order,
            origin: { stateName: $state.current.name, params: { id: offer.id } },
        });
    };

    const navigateToOfferOverview = () => {
        navigate('base.orderlist-order', {
            id: offer.orderId,
        });
    };

    const hasOfferWriteRights = (offer) => {
        return role.hasWriteRights(offer.customerId) || role.hasWriteRights(offer.supplierId);
    };

    const determineCancelState = (currentState) => {
        const enquiryStates = ['ENQUIRY', 'QUOTED'];

        if (enquiryStates.includes(currentState)) {
            if (role.isSupplier()) {
                return 'ENQUIRY_DECLINED';
            }
            if ('ENQUIRY' === currentState) {
                return 'ENQUIRY_CANCELED';
            }
            return 'QUOTE_CANCELED';
        }

        return 'CANCELED';
    };

    const onCancelPopupSubmit = async ({ cancelReason }) => {
        const newState = determineCancelState(offer.state);
        await updateOfferMutation.mutateAsync({
            offer: {
                ...offer,
                state: newState,
                cancelReason,
            },
            offerId: offer.id,
        });

        setShowCancelPopup(false);
    };

    const iconList = [];

    if (!role.isAdmin() && offer.isEditable() && hasOfferWriteRights(offer)) {
        iconList.push(
            <ActionIconListItem
                onClick={navigateToEditPage}
                tooltip={translate('offer.detail.header.actions.editProducts')}
                ariaLabel="Edit"
            >
                <IconPencil />
            </ActionIconListItem>
        );
    }

    if (role.isCustomer() && role.hasWriteRights(offer.customerId)) {
        iconList.push(
            <ActionIconListItem
                ariaLabel="Copy order"
                onClick={() => {
                    prefillOrder({
                        vessel: offer.vessel,
                        vesselId: offer.vesselId,
                        dateDelivery: offer.dateDelivery,
                        agent: offer.agent,
                        agentId: offer.agentId,
                        customerId: offer.customerId,
                        portId: offer.portId,
                        port: offer.port,
                        buyerReference: offer.buyerReference,
                        items: offer.items,
                        samplekits: offer.samplekits,
                        receiverSupplierIds: [offer.supplierId],
                        spot: offer.spot,
                        attributes: offer.attributes,
                    });
                }}
                tooltip={translate('offer.detail.header.actions.copy')}
            >
                <IconCopy />
            </ActionIconListItem>
        );
    }

    iconList.push(
        <ActionIconListItem
            onClick={() => setShowInitialEnquiryPopup(true)}
            tooltip={translate('offer.detail.header.actions.initialOrder')}
        >
            <IconHistory />
        </ActionIconListItem>
    );

    if (!role.isSupplier()) {
        iconList.push(
            <ActionIconListItem
                onClick={navigateToOfferOverview}
                tooltip={translate('offer.detail.header.actions.spotOverview')}
            >
                <IconList />
            </ActionIconListItem>
        );
    }

    iconList.push(
        <ActionListItem>
            <OfferExportDropdown offer={offer} />
        </ActionListItem>
    );

    if (role.isCustomer() && !offer.isFinished() && hasOfferWriteRights(offer)) {
        iconList.push(
            <ActionIconListItem
                onClick={() => setShowCancelPopup(true)}
                tooltip={translate('offer.detail.header.actions.cancel')}
            >
                <IconX />
            </ActionIconListItem>
        );
    }

    if (role.isSupplier() && !offer.isFinished() && hasOfferWriteRights(offer)) {
        const tooltip =
            offer.state === 'QUOTED'
                ? translate('offer.detail.header.actions.cancel')
                : translate('offer.detail.header.actions.decline');

        iconList.push(
            <ActionIconListItem onClick={() => setShowCancelPopup(true)} tooltip={tooltip}>
                <IconX />
            </ActionIconListItem>
        );
    }

    return (
        <>
            <ActionIconListWrapper>
                {iconList.map((entry, index) => (
                    <Fragment key={index}>{entry}</Fragment>
                ))}
            </ActionIconListWrapper>

            {showInitialEnquiryPopup ? (
                <LubesInitialEnquiryPopup
                    offerId={offer.id}
                    onDismiss={() => setShowInitialEnquiryPopup(false)}
                    isOpen={showInitialEnquiryPopup}
                />
            ) : null}

            {showCancelPopup ? (
                <OfferCancelPopup
                    isOpen={showCancelPopup}
                    onSubmit={onCancelPopupSubmit}
                    onDismiss={() => setShowCancelPopup(false)}
                    isSubmitting={updateOfferMutation.isPending}
                    offerState={offer.state}
                />
            ) : null}
        </>
    );
};

LubesOfferDetailActionBar.propTypes = {
    offer: PropTypes.object.isRequired,
};
