import { useField } from 'formik';

import { TimeMinutesInput, TimeMinutesInputProps } from './TimeMinutesInput';

interface FormikValidityInputProps extends TimeMinutesInputProps {
    name: string;
}

export const FormikTimeMinutesInput = (props: FormikValidityInputProps) => {
    const [field, meta, helpers] = useField(props.name);

    const handleOnValueChange = (value?: string) => {
        helpers.setValue(value ?? '');
    };

    return (
        <TimeMinutesInput
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : props.caption}
            onValueChange={handleOnValueChange}
            {...props}
            {...field}
            // remove the onChange handler from Formik
            onChange={undefined}
        />
    );
};
