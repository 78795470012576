import { PortMessage } from '../../../../types/PortMessage';
import { getLatestPorts } from '../../../common/api/clients/gateway.api';
import { formatPort } from '../../../common/helpers/formatPort.helper';
import { Schedule } from '../../../common/hooks/useSchedules';
import { PortOptionSelectWithSchedule } from '../PortDetailSelectWithSchedule';

export const getPortOptionsWithLatestPorts = async (
    schedule: Schedule[],
    vesselId?: string
): Promise<PortOptionSelectWithSchedule[]> => {
    const response = await getLatestPorts();

    const portsWithSchedule: PortOptionSelectWithSchedule[] = [];

    schedule
        ?.filter((scheduleEntry) => !!scheduleEntry.port)
        .forEach((scheduleEntry) => {
            if (scheduleEntry.port) {
                portsWithSchedule.push({
                    type: 'schedule',
                    label: formatPort({ port: scheduleEntry.port, short: true }),
                    value: scheduleEntry.port.id,
                    port: scheduleEntry.port,
                    vesselId,
                    scheduleEntry,
                    schedulesLength: schedule.length,
                });
            }
        });

    const portsWithoutSchedule = response
        .filter((port: PortMessage) => !schedule || !schedule.some((entry) => entry.port?.id === port.id))
        .map((port: PortMessage) => {
            return {
                type: 'noSchedule',
                label: formatPort({ port, short: true }),
                value: port.id,
                port,
                vesselId,
            };
        });

    return [...portsWithSchedule, ...portsWithoutSchedule];
};
