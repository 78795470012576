import css from '@styled-system/css';
import styled from 'styled-components';

import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { translate } from '../helpers/translate.helper';
import { useProductContext } from '../hooks/useProductContext';
import { useRole } from '../hooks/useRole';
import { IconSettings } from '../icons/cdl/Settings';
import { IconUsers } from '../icons/cdl/Users';
import { IconDiamond } from '../icons/Diamond';
import { IconLogoLite } from '../icons/LogoLite';
import { NavigationItem } from '../Sidebar/components/NavigationItem';
import { Flex } from '../ui/Flex';

import { GlobalSearch } from './GlobalSearch';
import { Notifications } from './Notifications/Notifications';
import { ProductContextSwitch } from './ProductContextSwitch';
import { UserMenu } from './UserMenu';

const TopbarItem = styled(Flex)(
    css({
        paddingX: 7,
        borderRight: 1,
        alignItems: 'center',
        ':last-of-type': {
            borderRight: 'none',
        },
    }),
    {
        [NavigationItem]: {
            borderRadius: '100%',
            svg: {
                marginRight: 0,
            },
        },
    }
);

// We decrease the padding here since icons have a protected area
// Otherwise, the spacing would look too large
const IconsTopbarItem = styled(TopbarItem)(
    css({
        paddingX: 6,
        span: {
            marginRight: 4,

            ':last-of-type': {
                marginRight: 0,
            },
        },
    })
);

export const Topbar = () => {
    const role = useRole();
    const { possibleContexts } = useProductContext();

    const userSpecifics = [];

    if (role.isSupplier() && role.hasAnyAdminRights()) {
        userSpecifics.push(
            <Tooltip label={translate('navigation.tooltips.suppliergroup')} key="base.supplier-groups-supplier-group">
                <span>
                    <NavigationItem to="base.supplier-groups-supplier-group" borderRadius="100%">
                        <IconSettings />
                    </NavigationItem>
                </span>
            </Tooltip>
        );
    }

    if (role.isCustomer() && role.hasAnyAdminRights() && !role.usesCloselinkLite()) {
        userSpecifics.push(
            <Tooltip label={translate('navigation.tooltips.customergroup')} key="base.customer-groups-customer-group">
                <span>
                    <NavigationItem to="base.customer-groups-customer-group" borderRadius="100%">
                        <IconSettings />
                    </NavigationItem>
                </span>
            </Tooltip>
        );
    }

    if (role.isAdmin() || (role.hasAnyAdminRights() && !role.usesCloselinkLite())) {
        userSpecifics.push(
            <Tooltip label={translate('navigation.tooltips.users')} key="base.users">
                <span>
                    <NavigationItem to="base.users" borderRadius="100%">
                        <IconUsers />
                    </NavigationItem>
                </span>
            </Tooltip>
        );
    }

    const isLubesOnly = !role.getPossibleContexts().includes('FUEL');

    return (
        <Flex
            padding={5}
            backgroundColor="white"
            height="64px"
            alignItems="center"
            borderBottom={1}
            variant="shadow"
            position="fixed"
            top="0"
            left="0"
            right="0"
            // this was the topbar's previous z-index, using the same index for
            // compatibility:
            zIndex="2025"
        >
            <Flex width="100%" alignItems="center">
                <TopbarItem paddingY={4}>
                    {role.usesCloselinkLite() ? (
                        <IconLogoLite width={67} height={21} />
                    ) : (
                        <IconDiamond width={32} height={20} />
                    )}
                </TopbarItem>

                {possibleContexts.length > 1 ? (
                    <TopbarItem>
                        <ProductContextSwitch />
                    </TopbarItem>
                ) : null}

                <TopbarItem background="clear-blue.4" flexGrow="1">
                    <GlobalSearch />
                </TopbarItem>

                {userSpecifics.length ? <IconsTopbarItem>{userSpecifics}</IconsTopbarItem> : null}

                {!role.isAdmin() && !isLubesOnly ? (
                    <IconsTopbarItem>
                        <Notifications />
                    </IconsTopbarItem>
                ) : null}

                <TopbarItem>
                    <UserMenu />
                </TopbarItem>
            </Flex>
        </Flex>
    );
};
