import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createSupplierGroup } from '../../../common/api/clients/supplierGroups.api';
import { queryKeys } from '../../../common/api/queryKeys';

export const useSupplierGroupCreate = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createSupplierGroup,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKeys.supplierGroups() }),
    });
};
