import { Formik, FormikErrors } from 'formik';
import React, { useState } from 'react';

import plattsLogoUrl from '../../../../../images/platts_logo.svg';
import { PlattsCredentialsRequestMessage } from '../../../../../types/PlattsCredentialsRequestMessage';
import { Modal } from '../../../../cdl/Modal/Modal';
import { Text } from '../../../../cdl/Text/Text';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { PlattsCredentialsServerErrorCode } from '../../../../common/api/clients/plattsCredentials.api';
import { translate } from '../../../../common/helpers/translate.helper';
import { Box } from '../../../../common/ui/Box';

import { usePlattsCredentialsCreate } from './hooks/usePlattsCredentialsCreate';
import { usePlattsCredentialsRemove } from './hooks/usePlattsCredentialsRemove';
import { usePlattsCredentialsUpdate } from './hooks/usePlattsCredentialsUpdate';
import { PlattsCredentialsForm, PlattsCredentialsFormState } from './PlattsCredentialsForm';
import { PlattsCredentialsManual } from './PlattsCredentialsManual';

const PlattsLogo = () => <img src={plattsLogoUrl} height={70} alt="Platts" title="Platts" />;

interface PlattsCredentialsPopupProps {
    data: PlattsCredentialsFormState;
    isOpen: boolean;
    credentialsExists: boolean;
    onDismiss: () => void;
}

export interface PlattsCredentialsServerError {
    errorCode: PlattsCredentialsServerErrorCode;
    credentials: PlattsCredentialsRequestMessage;
}

export const PlattsCredentialsPopup = ({ data, isOpen, onDismiss, credentialsExists }: PlattsCredentialsPopupProps) => {
    const [error, setError] = useState<PlattsCredentialsServerError>();

    const { addErrorToast } = useToasts();
    const plattsCredentialsCreateMutation = usePlattsCredentialsCreate();
    const plattsCredentialsUpdateMutation = usePlattsCredentialsUpdate();
    const plattsCredentialsRemoveMutation = usePlattsCredentialsRemove();

    const onSubmit = async (values: PlattsCredentialsFormState) => {
        const credentials: PlattsCredentialsRequestMessage = {
            customerId: values.customerId,
            username: values.username,
            password: values.password,
            appKey: values.appKey,
        };

        const mutation = !credentialsExists ? plattsCredentialsCreateMutation : plattsCredentialsUpdateMutation;

        await mutation.mutateAsync(credentials, {
            onError: (error) => {
                if (error.response && error.response.status === 400) {
                    setError({
                        credentials,
                        errorCode: error.response.data.code,
                    });
                } else {
                    addErrorToast(translate('global.genericToastError'));
                }
            },
        });

        onDismiss();
    };

    const onRemove = async () => {
        await plattsCredentialsRemoveMutation.mutateAsync(
            {
                customerId: data.customerId,
            },
            {
                onError: () => {
                    addErrorToast(translate('global.genericToastError'));
                },
            }
        );
        onDismiss();
    };

    const validate = (values: PlattsCredentialsFormState): FormikErrors<PlattsCredentialsFormState> => {
        const errors: FormikErrors<PlattsCredentialsFormState> = {};

        if (
            error &&
            error.credentials.username === values.username &&
            error.credentials.password === values.password &&
            error.errorCode === PlattsCredentialsServerErrorCode.INVALID_CREDENTIALS
        ) {
            errors.username = translate('integrationSettings.platts.error.credentialsInvalid');
        }

        if (
            error &&
            error.credentials.appKey === values.appKey &&
            error.errorCode === PlattsCredentialsServerErrorCode.INVALID_APP_KEY
        ) {
            errors.appKey = translate('integrationSettings.platts.error.appKeyInvalid');
        }

        if (!values.username || values.username.length === 0) {
            errors.username = translate('integrationSettings.platts.error.requiredUsername');
        }

        if (!values.password || values.password.length === 0) {
            errors.password = translate('integrationSettings.platts.error.requiredPassword');
        }

        if (!values.appKey || values.appKey.length === 0) {
            errors.appKey = translate('integrationSettings.platts.error.requiredAppKey');
        }
        return errors;
    };

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} dismissible={false}>
            <Box padding={6} display="grid" rowGap={5}>
                <Text variant="title">
                    {translate(
                        !credentialsExists
                            ? 'integrationSettings.platts.enablePopupHeadline'
                            : 'integrationSettings.platts.editPopupHeadline'
                    )}
                </Text>
                <Box display="flex" justifyContent="space-between" columnGap={6}>
                    <PlattsCredentialsManual />
                    <PlattsLogo />
                </Box>
                <Formik initialValues={data} onSubmit={onSubmit} validate={validate}>
                    <PlattsCredentialsForm
                        credentialsExists={credentialsExists}
                        onCancel={onDismiss}
                        onRemove={onRemove}
                        error={error}
                    />
                </Formik>
            </Box>
        </Modal>
    );
};
