import { theme } from '../../common/ui/theme';

import { CalloutVariant } from './Callout';

export const getVariantColors = (variant: CalloutVariant) => {
    switch (variant) {
        case 'default':
            return {
                background: theme.colors.background.subtle,
                border: theme.colors.border.muted,
                color: theme.colors.foreground.muted,
            };
        case 'accent':
            return {
                background: theme.colors.accent.background,
                border: theme.colors.accent.border,
                color: theme.colors.accent.foreground,
            };
        case 'attention':
            return {
                background: theme.colors.attention.background,
                border: theme.colors.attention.border,
                color: theme.colors.attention.foreground,
            };
        case 'negative':
            return {
                background: theme.colors.negative.background,
                border: theme.colors.negative.border,
                color: theme.colors.negative.foreground,
            };
    }
};
