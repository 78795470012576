import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
    removePlattsCredentials,
    RemovePlattsCredentialsParams,
} from '../../../../../common/api/clients/plattsCredentials.api';
import { queryKeys } from '../../../../../common/api/queryKeys';

export const usePlattsCredentialsRemove = () => {
    const queryClient = useQueryClient();

    return useMutation<void, AxiosError, RemovePlattsCredentialsParams>({
        mutationFn: removePlattsCredentials,
        onSuccess: async (_, variables) => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: queryKeys.plattsCredentials(variables.customerId) }),
                queryClient.invalidateQueries({ queryKey: queryKeys.schedulesWithPrices() }),
                queryClient.invalidateQueries({ queryKey: queryKeys.fuelContractProducts() }),
            ]);
        },
    });
};
