import { Text } from '../../../../../../cdl/Text/Text';
import { Tooltip } from '../../../../../../cdl/Tooltip/Tooltip';
import { translate } from '../../../../../../common/helpers/translate.helper';
import { OrderState } from '../../../../../model/OrderModel';

interface NoticeDaysDisplayProps {
    noticeDays?: number;
    dateDelivery: number;
    orderState: OrderState;
}

export const NoticeDaysDisplay = ({ noticeDays, dateDelivery, orderState }: NoticeDaysDisplayProps) => {
    if (!noticeDays) {
        return null;
    }

    const noticeDaysExceeded = (dateDelivery: number, noticeDays: number) => {
        if (orderState !== OrderState.OPEN) {
            return false;
        }

        const oneDayInMs = 86_400_000;

        const differenceInMs = new Date(dateDelivery).getTime() - new Date().getTime();
        const differenceInDays = Math.ceil(differenceInMs / oneDayInMs);

        return differenceInDays < noticeDays;
    };

    const noticeDaysString = translate('order.detail.daysNotice', { days: noticeDays.toString() });

    if (noticeDaysExceeded(dateDelivery, noticeDays)) {
        return (
            <Tooltip label={translate('order.detail.etdIsBelowNoticeDays')} side="right" showArrow>
                <Text variant="extraSmall" color="attention.foreground" fontWeight="semiBold">
                    {noticeDaysString}
                </Text>
            </Tooltip>
        );
    }

    return <Text variant="extraSmall">{noticeDaysString}</Text>;
};
