import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { Page } from '../../common/Page/Page';
import { PageGrid } from '../../common/PageGrid';

import { VesselOverviewTableControls } from './components/VesselOverviewTableControls';
import { VesselOverviewState } from './VesselOverviewState';
import { VesselOverviewTable } from './VesselOverviewTable';
import { Box } from '../../common/ui/Box';
import { CreateVesselButton } from '../CreateVessel/CreateVesselButton';
import { useRole } from '../../common/hooks/useRole';

export const VesselOverviewPage = () => {
    return (
        <VesselOverviewState>
            <VesselOverviewPageContent />
        </VesselOverviewState>
    );
};

const VesselOverviewPageContent = () => {
    useDocumentTitle(translate('vessel.overviewpagetitle'));
    const role = useRole();

    return (
        <Page>
            <PageGrid>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Text variant="headline">{translate('vessel.overviewpagetitle')}</Text>

                    {role.hasAnyAdminRights() ? <CreateVesselButton /> : null}
                </Box>

                <VesselOverviewTableControls />

                <VesselOverviewTable />
            </PageGrid>
        </Page>
    );
};
