import styled from 'styled-components';

import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { translate } from '../helpers/translate.helper';
import { Box } from '../ui/Box';

const StyledMore = styled.span`
    margin-left: 7px;
`;

interface ListProps {
    list: string[];
}

const List = ({ list }: ListProps) => {
    return (
        <>
            {list.map((listItem, index) => (
                <div key={index}>{listItem}</div>
            ))}
        </>
    );
};

interface ListingWithMoreProps {
    children?: string[];
    showCount?: number;
}

export const ListingWithMore = ({ children: list = [], showCount = 1 }: ListingWithMoreProps) => {
    const shownListItems = list.slice(0, showCount).join(', ');

    if (list.length > showCount) {
        return (
            <Tooltip label={<List list={list} />}>
                <Box>
                    {shownListItems},
                    <StyledMore>
                        {translate('global.seeMore', {
                            count: (list.length - showCount).toString(),
                        })}
                    </StyledMore>
                </Box>
            </Tooltip>
        );
    }

    return <>{shownListItems}</>;
};
