import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';

import { ProductContext } from '../../../../types/ProductContext';
import { getMagicBellUserConfig, MagicBellUserConfigResponse } from '../../api/clients/magicBellUserConfig.api';
import { queryKeys } from '../../api/queryKeys';
import { useRole } from '../../hooks/useRole';

type UseMagicBellUserConfig = UseQueryResult<MagicBellUserConfigResponse> & {
    prefetch: () => void;
};

export const useMagicBellUserConfig = (): UseMagicBellUserConfig => {
    const role = useRole();
    const queryClient = useQueryClient();

    const isEnabled = () => {
        if (!role) {
            return false;
        }

        if (role.isAdmin()) {
            return false;
        }

        return role.getPossibleContexts().includes(ProductContext.FUEL);
    };

    const queryResult = useQuery({
        queryKey: queryKeys.magicBellUserConfig(),
        queryFn: () => getMagicBellUserConfig(),
        enabled: isEnabled(),
        staleTime: Infinity,
    });

    const prefetch = () => {
        if (isEnabled()) {
            queryClient.prefetchQuery({
                queryKey: queryKeys.magicBellUserConfig(),
                queryFn: () => getMagicBellUserConfig(),
                staleTime: Infinity,
            });
        }
    };

    return { prefetch, ...queryResult };
};
