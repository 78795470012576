import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createMessage } from '../../common/api/clients/anonymousQuotation.api';
import { queryKeys } from '../../common/api/queryKeys';

export const useAnonymousQuotationMessageCreate = (anonymousQuotationId) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createMessage,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.anonymousQuotation(anonymousQuotationId) });
        },
    });
};
