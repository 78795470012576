import isEmpty from 'lodash/isEmpty';

export const formatPort = ({ port, short = false, country = true, locode = true }) => {
    if (typeof port !== 'object' || isEmpty(port)) {
        return undefined;
    }

    let portString = port.name;
    if (!short) {
        if (country) {
            portString += ', ' + port.country.name;
        }
        if (locode) {
            portString += ` (${port.locCode})`;
        }
    }
    return portString;
};
