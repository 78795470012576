import { FormattedNumberInput, FormattedNumberInputProps } from '../FormattedNumberInput/FormattedNumberInput';

export interface MoneyInputProps
    extends Omit<FormattedNumberInputProps, 'prefix' | 'step' | 'maxLength' | 'decimalScale' | 'decimalsLimit'> {
    decimals?: number;
}

export const MoneyInput = ({ decimals = 2, ...rest }: MoneyInputProps) => {
    return (
        <FormattedNumberInput
            {...rest}
            leadingVisual="$"
            prefix={undefined}
            step={1}
            maxLength={10}
            decimalScale={decimals}
            decimalsLimit={decimals}
            groupSeparator=","
            decimalSeparator="."
        />
    );
};
