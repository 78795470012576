import { ProductContext } from '../../../types/ProductContext';
import { DataTable } from '../../common/DataTable/DataTable';
import { formatCompanyName } from '../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../common/helpers/formatDate.helper';
import { formatFuelMoney } from '../../common/helpers/formatFuelMoney.helper';
import { formatLubesPrice } from '../../common/helpers/formatLubesPrice.helper';
import { formatMoney } from '../../common/helpers/formatMoney.helper';
import { formatVessel } from '../../common/helpers/formatVessel.helper';
import { translate } from '../../common/helpers/translate.helper';
import { translateOfferState } from '../../common/helpers/translateOfferState.helper';
import { useNavigation } from '../../common/hooks/useNavigation';
import { useRole } from '../../common/hooks/useRole';
import { IconMail } from '../../common/icons/cdl/Mail';
import { IconMailOpened } from '../../common/icons/cdl/MailOpened';
import { theme } from '../../common/ui/theme';
import { OfferModel } from '../../offer/model/OfferModel';
import { Port } from '../../port/Port';

interface SearchResultOfferTableProps {
    offers: OfferModel[];
}
export const SearchResultOfferTable = ({ offers }: SearchResultOfferTableProps) => {
    const role = useRole();
    const { navigate } = useNavigation();

    const columns = [
        {
            label: translate('order.enquiryorder'),
            width: '10%',
            value: (offer: OfferModel) => offer.offerNumber || '-',
        },
        {
            label: '',
            condition: role.isAdmin(),
            value: function renderHasReadIcon(offer: OfferModel) {
                if (offer.supplierRead) {
                    return <IconMailOpened height={16} width={16} color={theme.colors.foreground.subtle} />;
                } else {
                    return <IconMail height={16} width={16} />;
                }
            },
        },
        {
            label: role.isSupplier() ? translate('offer.company') : translate('offer.supplier'),
            width: '15%',
            condition: !role.isOneCompanyUser(),
            value: (offer: OfferModel) => formatCompanyName({ company: offer.supplier, includeType: role.isAdmin() }),
        },
        {
            label: translate('offer.customer'),
            width: '15%',
            value: (offer: OfferModel) => formatCompanyName({ company: offer.customer, includeType: role.isAdmin() }),
        },
        {
            label: translate('order.vessel'),
            width: '15%',
            value: (offer: OfferModel) => formatVessel({ vessel: offer.vessel, short: true }),
        },
        {
            label: translate('order.port'),
            width: '15%',
            value: function renderPort(offer: OfferModel) {
                if (!offer.port) {
                    return '-';
                }

                return <Port port={offer.port} vesselId={offer.vesselId} locode={false} showTooltip />;
            },
        },
        {
            label: translate('order.dateDeliveryShort'),
            width: '10%',
            value: function renderDeliveryDate(offer: OfferModel) {
                if (!offer.dateDelivery) {
                    return '-';
                }
                return formatDate({
                    date: offer.dateDelivery,
                    timeZone: 'UTC',
                });
            },
        },
        {
            label: translate('order.ppl'),
            width: '5%',
            condition: role.isAdmin(),
            value: function renderDeliveryDate(offer: OfferModel) {
                if (!offer.ppl || offer.ppl.value === 0) {
                    return '-';
                }
                if (offer.type === ProductContext.FUEL) {
                    return formatFuelMoney({
                        value: offer.ppl.value,
                        currency: offer.ppl.currency,
                    });
                }
                return formatLubesPrice(offer.ppl);
            },
        },
        {
            label: translate('order.total'),
            width: '5%',
            condition: role.isAdmin(),
            value: function renderDeliveryDate(offer: OfferModel) {
                if (!offer.total || offer.total.value === 0) {
                    return '-';
                }
                return formatMoney({
                    value: offer.total.value,
                    currency: offer.total.currency,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
            },
        },
        {
            label: translate('order.status'),
            width: '10%',
            value: function renderOfferState(offer: OfferModel) {
                const offerState = translateOfferState({
                    offer,
                    userType: role.user.userType,
                });

                return offerState || '-';
            },
        },
    ].filter(({ condition = true }) => condition);

    const onRowClick = (offer: OfferModel) => {
        if (offer.type === ProductContext.LUBES) {
            navigate('base.offerlist-offer', {
                id: offer.id,
            });
        }

        if (offer.type === ProductContext.FUEL) {
            navigate('base.fuel-offerlist-offer', {
                id: offer.id,
            });
        }
    };

    return (
        <DataTable
            columns={columns}
            rows={offers}
            onRowClick={({ row: offer }) => onRowClick(offer)}
            isRowUnread={({ row: offer }) => role.isSupplier() && !offer.supplierRead}
        />
    );
};
