import { animated, config, useSpring } from '@react-spring/web';
import useComponentSize from '@rehooks/component-size';
import { useRef, useState } from 'react';

import { CountryMessage } from '../../../../../types/CountryMessage';
import { IconButton } from '../../../../cdl/IconButton/IconButton';
import { Switch } from '../../../../cdl/Switch/Switch';
import { useEnums } from '../../../../common/hooks/useEnums';
import { IconChevronDown } from '../../../../common/icons/cdl/ChevronDown';
import { IconChevronRight } from '../../../../common/icons/cdl/ChevronRight';
import { Box } from '../../../../common/ui/Box';

import { CountryList } from './CountryList';
import { RegionState } from './RegionState';

interface RegionProps {
    region: string;
    state: RegionState;
    countries: CountryMessage[];
    onRegionSwitchChange: (checked: boolean, region: string) => void;
    isCountryActive: (countryCode: string) => boolean;
    onCountrySwitchChange: (checked: boolean, countryCode: string) => void;
}

export const Region = (props: RegionProps) => {
    const [expanded, setExpanded] = useState(false);
    const { getHumanReadableValue } = useEnums();
    const bodyRef = useRef<HTMLDivElement>(null);
    const { height } = useComponentSize(bodyRef);

    const formattedRegion = getHumanReadableValue('region', props.region);

    const springProps = useSpring({
        scY: expanded ? -1 : 1,
        opacity: expanded ? 1 : 0,
        maxHeight: expanded ? `${height}px` : '0px',
        config: {
            ...config.default,
            tension: 300,
            clamp: true,
        },
    });

    return (
        <div>
            <Box display="flex">
                <IconButton
                    emphasis="transparent"
                    icon={expanded ? IconChevronDown : IconChevronRight}
                    onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
                />

                <Switch
                    checked={props.state === RegionState.ACTIVE || props.state === RegionState.INDETERMINATE}
                    indeterminate={props.state === RegionState.INDETERMINATE}
                    onChange={(checked) => props.onRegionSwitchChange(checked, props.region)}
                    label={formattedRegion}
                />
            </Box>
            <animated.div
                style={{
                    overflow: 'hidden',
                    ...springProps,
                }}
            >
                <Box ref={bodyRef}>
                    <CountryList
                        key={props.region}
                        countries={props.countries}
                        isCountryActive={props.isCountryActive}
                        onCountrySwitchChange={props.onCountrySwitchChange}
                    />
                </Box>
            </animated.div>
        </div>
    );
};
