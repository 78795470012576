import { ClearIndicatorProps, components } from 'react-select';
import styled from 'styled-components';

import { IconX } from '../../../common/icons/cdl/X';
import { get } from '../../../common/ui/get';

const StyledClearIndicator = styled(components.ClearIndicator)`
    && {
        height: 24px;
        cursor: pointer;
        padding: 2px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${get('colors.foreground.subtle')};
        transition: color 75ms ease-in-out;

        &:hover {
            color: ${get('colors.foreground.default')};
        }
    }
`;

interface CustomClearIndicatorProps extends ClearIndicatorProps {}

export const CustomClearIndicator = (props: CustomClearIndicatorProps) => {
    return (
        <StyledClearIndicator {...props}>
            <IconX width={16} height={16} />
        </StyledClearIndicator>
    );
};
