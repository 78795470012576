import moment from 'moment';
import styled from 'styled-components';

import { DemandInformationStop } from '../../../../../types/DemandInformationMessage';
import { CategoryState } from '../../../../../types/ReachInformationMessage';
import { Tag } from '../../../../cdl/Tag/Tag';
import { Tooltip } from '../../../../cdl/Tooltip/Tooltip';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconAlertTriangle } from '../../../../common/icons/cdl/AlertTriangle';
import { Box } from '../../../../common/ui/Box';
import { theme } from '../../../../common/ui/theme';

const UnorderedList = styled.ul`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 16px;
`;

interface MultipleStopsProps {
    stops: DemandInformationStop[];
}

export const MultipleStops = ({ stops }: MultipleStopsProps) => {
    const tooltipLabel = (
        <UnorderedList>
            {stops.map((stop) => {
                const notInReach = stop.categories.some(
                    (category) => category.categoryState === CategoryState.OUT_OF_REACH
                );
                const daysUntilEta = moment(stop.eta).diff(moment(), 'days');

                if (notInReach) {
                    return (
                        <li key={stop.scheduleId}>
                            <Box display="inline-flex" alignItems="center" columnGap={1}>
                                <span>
                                    {translate('demand.vesselSchedule.portNotInReach', {
                                        port: stop.portName,
                                    })}
                                </span>
                                <Box display="inline-block" minWidth="16px">
                                    <IconAlertTriangle
                                        width={16}
                                        height={16}
                                        color={theme.colors.foreground.onEmphasis}
                                    />
                                </Box>
                            </Box>
                        </li>
                    );
                }

                if (daysUntilEta === 1) {
                    return (
                        <li key={stop.scheduleId}>
                            {translate('demand.vesselSchedule.portInDay', {
                                port: stop.portName,
                            })}
                        </li>
                    );
                }

                return (
                    <li key={stop.scheduleId}>
                        {translate('demand.vesselSchedule.portInDays', {
                            port: stop.portName,
                            days: daysUntilEta.toString(),
                        })}
                    </li>
                );
            })}
        </UnorderedList>
    );

    const hasOutOfReachStops = stops.some((it) =>
        it.categories.some((category) => category.categoryState === CategoryState.OUT_OF_REACH)
    );

    return (
        <Tooltip label={tooltipLabel}>
            <div>
                <Tag variant={hasOutOfReachStops ? 'negative' : 'default'} emphasis={hasOutOfReachStops}>
                    {stops.length}
                </Tag>
            </div>
        </Tooltip>
    );
};
