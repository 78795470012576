import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from 'chart.js';

import { CustomerSpecificStatisticsMessage } from '../../../types/StatisticsMessage';

import { SuppliersTabFilter } from './SuppliersTabFilter';
import { SuppliersTabStatistics } from './SuppliersTabStatistics';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend);

interface SuppliersTabContentProps {
    supplierStatistics: CustomerSpecificStatisticsMessage['supplier'];
}

export const SuppliersTabContent = ({ supplierStatistics }: SuppliersTabContentProps) => {
    return (
        <SuppliersTabFilter allEntries={supplierStatistics}>
            {(filteredEntries) => <SuppliersTabStatistics supplierStatistics={filteredEntries} />}
        </SuppliersTabFilter>
    );
};
