import { FuelVesselConsumptionPreset } from '../../../../../types/FuelVesselConsumptionPreset';
import { useDrawer } from '../../../../cdl/Drawer/useDrawer.hook';
import { TextButton } from '../../../../common/buttons/TextButton';
import { translate } from '../../../../common/helpers/translate.helper';
import { useFuelVesselConsumptionPresets } from '../hooks/useFuelVesselConsumptionPresets';
import { useFuelVesselConsumptionPresetsUpdate } from '../hooks/useFuelVesselConsumptionPresetsUpdate';

import { EditVesselConsumptionPresetsForm } from './EditVesselConsumptionPresetsForm';

interface EditVesselConsumptionPresetsProps {
    vesselId: string;
    customerId: string;
}

export const EditVesselConsumptionPresets = ({ vesselId, customerId }: EditVesselConsumptionPresetsProps) => {
    const { push, pop } = useDrawer();

    const vesselConsumptionPresetsQuery = useFuelVesselConsumptionPresets(vesselId);
    const vesselConsumptionPresetMutation = useFuelVesselConsumptionPresetsUpdate();

    const handleSubmit = (data: { presets: FuelVesselConsumptionPreset[] }) => {
        const updateRequestBody = {
            vesselId,
            customerId,
            presets: data.presets,
        };
        vesselConsumptionPresetMutation.mutate(
            { vesselId, data: updateRequestBody },
            {
                onSuccess: () => pop(),
            }
        );
    };

    const formData = {
        presets:
            vesselConsumptionPresetsQuery.data && vesselConsumptionPresetsQuery.error?.response?.status !== 404
                ? vesselConsumptionPresetsQuery.data.presets
                : [],
    };

    return (
        <TextButton
            type="button"
            onClick={() =>
                push({
                    width: '15vw',
                    content: <EditVesselConsumptionPresetsForm onSubmit={handleSubmit} data={formData} />,
                })
            }
        >
            {translate('vessel.fuelStocks.editPresets')}
        </TextButton>
    );
};
