import { AppProviders } from '../../../common/AppProviders';
import { createDirectiveFromReactComponent } from '../../../common/helpers/createDirectiveFromReactComponent';

import { CustomerDetailPage as OriginalCustomerDetailPage } from './CustomerDetailPage';

const CustomerDetailPage = (props) => (
    <AppProviders>
        <OriginalCustomerDetailPage {...props} />
    </AppProviders>
);

export default createDirectiveFromReactComponent(CustomerDetailPage, 'reactCustomerDetailPage');
