import { Tooltip } from '../../cdl/Tooltip/Tooltip';

import { CircleValidityContent } from './components/CircleValidityContent';
import { formatValidUntil } from './utils/formatValidUntil';

interface CircleValidityProps {
    validityTime: number;
    validUntil: string;
    background?: string;
    size?: 'small' | 'default';
}

export const CircleValidity = ({ validityTime, validUntil, background, size = 'default' }: CircleValidityProps) => {
    return (
        <Tooltip label={formatValidUntil(validUntil)}>
            <span>
                <CircleValidityContent
                    validityTime={validityTime}
                    validUntil={validUntil}
                    background={background}
                    size={size}
                />
            </span>
        </Tooltip>
    );
};
