import PropTypes from 'prop-types';

import { Modal } from '../../../../cdl/Modal/Modal';
import { formatDateDelivery } from '../../../../common/helpers/formatDateDelivery.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { LoadingIndicator } from '../../../../common/LoadingIndicator/LoadingIndicator';
import { Box } from '../../../../common/ui/Box';
import { Heading } from '../../../../common/ui/Heading';
import { Port } from '../../../../port/Port';

import { LabelValueInformation } from './LabelValueInformation';

export const InitialEnquiryPopup = ({ isOpen, onDismiss, offer, isLoading, children }) => {
    if (isLoading) {
        return (
            <Modal isOpen={isOpen} onDismiss={onDismiss} scrollable={false} width={1000}>
                <Box padding={8}>
                    <LoadingIndicator />
                </Box>
            </Modal>
        );
    }

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} width={1000}>
            <Box padding={8}>
                <Heading level="uppercase" marginBottom={4}>
                    {translate('order.history.drawerTitle')}
                </Heading>

                <Box marginTop={5} marginBottom={5}>
                    <LabelValueInformation
                        label={translate('order.port')}
                        value={<Port port={offer.port} vesselId={offer.vesselId} />}
                    />

                    <LabelValueInformation
                        label={translate('order.dateDeliveryConfirmed')}
                        value={formatDateDelivery({ date: offer.dateDelivery })}
                    />
                </Box>

                {children}
            </Box>
        </Modal>
    );
};

InitialEnquiryPopup.propTypes = {
    offer: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool,
    onDismiss: PropTypes.func,
    children: PropTypes.node,
};
