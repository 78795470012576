import styled from 'styled-components';

import { Text } from '../../../../cdl/Text/Text';
import { get } from '../../../../common/ui/get';

export const MetaInformationCaption = styled(Text).attrs({ variant: 'extraSmall' })`
    color: ${get('colors.foreground.muted')};
    font-size: 10px;
    font-weight: 500;
`;
