function UserFactory() {
    function User() {}

    User.prototype.fullName = function () {
        if (this.firstname && this.lastname) return this.firstname + ' ' + this.lastname;
        if (this.firstname) return this.firstname;
        if (this.lastname) return this.lastname;
        return '';
    };

    return User;
}

export default {
    name: 'User',
    fn: UserFactory,
};
