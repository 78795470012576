import PropTypes from 'prop-types';

import { CoreData } from '../../common/CoreData/CoreData';
import { CardVariation } from '../../common/ui/CardVariation';

import { useApprovalOfferFields } from './useApprovalOfferFields';

export const CustomerApprovalOfferCoreData = ({ offer }) => {
    const fields = useApprovalOfferFields();

    const isFuel = offer.type === 'FUEL';
    const isLubes = offer.type === 'LUBES';

    return (
        <CardVariation>
            <CoreData
                data={offer}
                baseFields={[
                    fields.vessel,
                    fields.customer,
                    fields.supplier,
                    fields.port,
                    fields.dateDelivery,
                    isFuel ? fields.eta : null,
                    isFuel ? fields.etd : null,
                    offer.agent ? fields.legacyAgent : fields.agent,
                    isLubes ? fields.noticeDays : null,
                    fields.created,
                    fields.buyerReference,
                    fields.supplyMode,
                    offer.isSpot() || isFuel ? fields.paymentTerms : null,
                    isLubes ? fields.offerNumber : null,
                ].filter(Boolean)}
                columnWidth={1 / 4}
            />
        </CardVariation>
    );
};

CustomerApprovalOfferCoreData.propTypes = {
    offer: PropTypes.object.isRequired,
};
