import { CustomDoubleLineOption, CustomDoubleLineOptionProps } from '../cdl/Select/components/CustomDoubleLineOption';
import { formatPort } from '../common/helpers/formatPort.helper';
import { formatVessel } from '../common/helpers/formatVessel.helper';
import { translate } from '../common/helpers/translate.helper';

import { IntegrationOfferSelectOption } from './IntegrationOfferSelect';

interface IntegrationOfferOptionDoubleLineOptionProps extends CustomDoubleLineOptionProps {
    data: IntegrationOfferSelectOption;
}

export const IntegrationOfferOptionDoubleLineOption = (props: IntegrationOfferOptionDoubleLineOptionProps) => {
    const vesselName = formatVessel({ vessel: props.data.offer.vessel });
    const portName = formatPort({ port: props.data.offer.port });
    const vesselWithPortName = translate('integrationsOverview.orderOption', { vesselName, portName });

    return <CustomDoubleLineOption {...props} description={vesselWithPortName} />;
};
