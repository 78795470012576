import { Spinner } from '../../../common/Spinner/Spinner';
import { Box } from '../../../common/ui/Box';
import { get } from '../../../common/ui/get';

export const CustomLoadingMessage = () => {
    return (
        <Box marginY="10px" display="flex" justifyContent="center" height="20px">
            <Spinner color={get('colors.accent.emphasis')} />
        </Box>
    );
};
