import css from '@styled-system/css';
import styled from 'styled-components';

import { Box } from '../../common/ui/Box';

export const StyledMessage = styled(Box)(
    css({
        padding: '6px 12px',
        borderRadius: 1,
        margin: '4px 8px 6px',
        maxWidth: '65%',
        wordWrap: 'break-word',
        position: 'relative',
    })
);
