import { useField } from 'formik';

import { ProductMessage } from '../../../../types/ProductMessage';
import { ProductOption, ProductSelect, ProductSelectProps } from '../ProductSelect/ProductSelect';

export interface FormikProductSelectProps extends Omit<ProductSelectProps, 'onChange' | 'value'> {
    name: string;
    onSelect: (product: ProductMessage) => void;
}

export const FormikProductSelect = (props: FormikProductSelectProps) => {
    const [field, meta, helpers] = useField(props.name);

    const onChange = (option: ProductOption) => {
        helpers.setValue(option.value);
        props.onSelect(option.product);
    };

    return (
        <ProductSelect
            {...props}
            onBlur={() => (!meta.touched ? helpers.setTouched(true) : null)}
            negative={meta.touched && !!meta.error}
            value={field.value}
            onChange={onChange}
        />
    );
};
