import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';

import { FuelVesselConsumptionPresetsMessage } from '../../../../../types/FuelVesselConsumptionPresetsMessage';
import { updateFuelVesselConsumptionPresets } from '../../../../common/api/clients/fuelStocks.api';
import { queryKeys } from '../../../../common/api/queryKeys';
import { queries } from '../../../../common/api/queryKeys/queries';

export const useFuelVesselConsumptionPresetsUpdate = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateFuelVesselConsumptionPresets,
        onSuccess: (data: FuelVesselConsumptionPresetsMessage) => {
            return queryClient.invalidateQueries({
                predicate: (query) => {
                    return (
                        isEqual(
                            query.queryKey,
                            queries.vessels.detail(data.vesselId)._ctx.fuelConsumptionPresets.queryKey
                        ) ||
                        isEqual(query.queryKey, queryKeys.reachInformation(data.vesselId)) ||
                        isEqual(query.queryKey, queryKeys.schedulesWithPrices(data.vesselId))
                    );
                },
            });
        },
    });
};
