import { FormikDebug } from '../../common/form-elements/formik/FormikDebug';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { IconCircle3 } from '../../common/icons/cdl/Circle3';
import { StatusBar } from '../../common/StatusBar/StatusBar';
import { StatusBarType } from '../../common/StatusBar/StatusBarType';
import { Box } from '../../common/ui/Box';
import { CardVariation } from '../../common/ui/CardVariation';

import { DataSummary } from './DataSummary';
import { FormikFuelEnquiryHeader } from './FormikFuelEnquiryHeader';
import { FuelEnquiryReceiversInformation } from './FuelEnquiryReceiversInformation';
import { ProductSummary } from './ProductSummary';

export const FuelEnquiryFlow = () => {
    useDocumentTitle(translate('fuelEnquiry.enquiryTitle'));

    return (
        <Box>
            <FormikFuelEnquiryHeader title={translate('fuelEnquiry.enquiryTitle')} />

            <Box display="grid" gap={6} padding={6}>
                <CardVariation paddingX={0} paddingY={0}>
                    <StatusBar
                        headline={translate('fuelEnquiry.third.statusBarEnquiry.headline')}
                        subHeadline={translate('fuelEnquiry.third.statusBarEnquiry.subHeadline')}
                        type={StatusBarType.WHITE}
                        icon={<IconCircle3 />}
                    />
                </CardVariation>

                <CardVariation>
                    <Box paddingTop={4}>
                        <DataSummary />
                    </Box>
                </CardVariation>

                <CardVariation>
                    <ProductSummary />
                </CardVariation>

                <CardVariation>
                    <FuelEnquiryReceiversInformation />
                </CardVariation>

                <FormikDebug />
            </Box>
        </Box>
    );
};
